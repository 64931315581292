import React, { Component } from "react";
import { Box, Text, ResponsiveContext } from "grommet";
import ResponsiveGrid from "../util/responsiveGrid";
import { findPriceForUserUnit, formatPrice } from "../util/formatMoney";

class BidNext extends Component {
  render() {
    const columns = {
      small: ["65%", "35%"],
      medium: ["65%", "35%"],
      large: ["90%", "auto"],
    };

    const shouldDisplaySecondUnit = this.props.unit !== "eth";
    const price = findPriceForUserUnit(this.props.eth, this.props.fiat, this.props.unit);

    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            height="64px"
            border={{ color: "#E8E8E8", size: "xsmall" }}
            background={{ color: "white" }}
            style={{ borderRadius: "12px" }}
            justify={"center"}
          >
            <ResponsiveGrid columns={columns} rows={["auto"]}>
              <Box direction={"row"} justify={"start"} align={"center"} margin={{ left: "medium" }}>
                <Text size={"medium"} weight={600} color={"#0165c5"}>
                  Next bid
                </Text>
              </Box>
              <Box direction={"column"} justify={"center"} align={"end"} margin={{ right: "medium" }}>
                <Box direction={"row"} justify={"center"}>
                  <Text size={"medium"} weight={600} color={"#0165c5"}>
                    {price}
                  </Text>
                </Box>
                {shouldDisplaySecondUnit && (
                  <Box direction={"row"} justify={"center"}>
                    <Text size={"small"} weight={600} color={"#0165c5"}>
                      {formatPrice(this.props.eth, "eth")}
                    </Text>
                  </Box>
                )}
              </Box>
            </ResponsiveGrid>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export default BidNext;
