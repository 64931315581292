import React from "react";
import { getValuationOrigin } from "../util/valuationOrigin";
import { withUser } from "../../userContext";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatPriceSize } from "../util/formatPriceSide";
import NewCardIcon from "../util/newCardIcon";

function PlayerSearchPrice(props) {
  const a = props.rt;
  const bmp = props.bmp;
  let unit = props.currency || props.user.preferredUnit;
  let bmp_price = formatBestMarketPrice(bmp, "eth");
  let average = formatRealTimeAverage(a, "eth");
  if (unit !== "eth") {
    bmp_price = formatBestMarketPrice(bmp, unit);
    average = formatRealTimeAverage(a, unit);
  }

  return (
    <div className={"text-center font-semibold space-y-2"}>
      <div
        className={"rounded-xl flex flex-row space-x-1 justify-between px-2 p-1"}
        style={{ backgroundColor: props.bgColor }}
        data-tip={getValuationOrigin(a.ValuationOrigin)}
      >
        <div className={"self-center w-2.5 h-4 rounded-lg"}>
          <NewCardIcon scarcity={props.color} />
        </div>
        <p className={"text-xs self-center font-semibold text-brand-black"}>
          {formatPriceSize(
            average,
            true,
            false,
            "text-xs self-center font-semibold text-brand-black",
            "text-xxs font-semibold text-brand-black",
          )}
        </p>
      </div>
      <div
        className={"rounded-xl flex flex-row space-x-1 justify-between px-2 p-1"}
        style={{ backgroundColor: props.bgColor }}
        data-tip={"Floor price"}
      >
        <div className={"self-center w-2.5 h-4 rounded-lg"}>
          <NewCardIcon scarcity={props.color} />
        </div>
        <p className={"text-xs self-center font-semibold text-center text-brand-black"}>
          {formatPriceSize(
            bmp_price,
            true,
            false,
            "text-xs self-center font-semibold text-brand-black",
            "text-xxs font-semibold text-brand-black",
          )}
        </p>
      </div>
    </div>
  );
}

export default withUser(PlayerSearchPrice);
