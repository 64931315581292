import React, { PureComponent } from "react";

export default class CustomizedAxisTickHistogram extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text fontSize={"small"} x={12} y={0} dy={12} textAnchor="end" fill="#666" transform="">
          {payload.value}
        </text>
      </g>
    );
  }
}
