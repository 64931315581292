import React from "react";
import LineSeparator from "../../img/line-separator.svg";

function BaseballTypePicker(props) {
  const picked = props.type;

  const pickedStyle = "text-brand";
  const notPickedStyle = "text-textGrey4";
  return (
    <div className={"font-headers text-center font-semibold bg-white flex flex-row space-x-6 rounded-full text-base px-6 py-2"}>
      <div className={"cursor-pointer"} onClick={() => props.change("hitting")}>
        <p className={picked === "hitting" ? pickedStyle : notPickedStyle}>Hitting</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("pitching")}>
        <p className={picked === "pitching" ? pickedStyle : notPickedStyle}>Pitching</p>
      </div>
    </div>
  );
}

export default BaseballTypePicker;
