import React, { Component } from "react";
import ProductBoxes from "./ProductBox";

class SubscriptionPage extends Component {
  render() {
    return (
      <div>
        <div>
          <ProductBoxes />
        </div>
      </div>
    );
  }
}

export default SubscriptionPage;
