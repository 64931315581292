import React from "react";
import { withUser } from "../../userContext";
import Locker from "../util/orangeOpenLockerBrandBg";
import { Button } from "../util/button";

function PlayerBlockedVideo(props) {
  return (
    <div className={"flex items-center justify-center space-y-8"}>
      <div className="bg-combo-upgrade w-combo-upgrade h-combo-upgrade rounded-md">
        <div className="flex flex-row w-full justify-center items-center h-full space-x-8">
          <div className="flex flex-col w-1/2 h-full">
            <Locker containerStyle="mt-14 mb-10 ml-14 h-10 w-10" />
            <div className="ml-14 my-2">
              <p className="text-black text-left text-xl font-black font-headers">{props.title}</p>
            </div>
            <div className="ml-14 my-2">
              <p className="text-black text-left font-semibold text-sm">{props.description}</p>
              {props.description2 && <p className="text-black text-left font-semibold text-sm">{props.description2}</p>}
            </div>
            <div className="ml-14 mt-10">
              <a href={"/product"}>
                <Button label={"Upgrade"} />
              </a>
            </div>
          </div>
          <div className="flex w-1/2 h-full items-center justify-center">
            <iframe
              className="w-11/12 h-5/6"
              src={props.videoLink}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withUser(PlayerBlockedVideo);
