import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { MobileModale } from "./mobileModale";
import { XIcon } from "@heroicons/react/outline";

export const StandaloneMenu = (props) => {
  const ref = useRef(null);
  const { onClickOutside } = props;
  const items = props.items || [];
  const title = props.title || "Menu";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  const itemsElem = items.map((item, i) => {
    return (
      <div
        key={item.key}
        className={`${
          i !== 0 ? "border-t" : ""
        } border-transparent-inverse-surface-low border-opacity-10 text-on-surface hover:opacity-80 px-4 py-2 ${item.className}`}
        onClick={item.onClick}
      >
        {item.href ? <a href={item.href}>{item.label}</a> : item.label}
      </div>
    );
  });

  if (isMobile) {
    return (
      <MobileModale open={props.show} close={onClickOutside ? () => onClickOutside() : undefined}>
        <div className={"bg-surface-container text-on-surface rounded-t-lg text-left"}>
          <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
            <div className={"flex flex-row justify-between px-4 py-4"}>
              <h3 className={"font-headers font-semibold m-0"}>{title}</h3>
              <div className={"flex flex-row gap-3"}>
                <XIcon
                  className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
                  aria-hidden="true"
                  onClick={() => onClickOutside && onClickOutside()}
                />
              </div>
            </div>
          </div>
          <div className={"max-h-[80vh] overflow-y-auto text-sm w-full"}>{itemsElem}</div>
        </div>
      </MobileModale>
    );
  }

  if (!props.show) return null;

  return (
    <div
      style={props.style}
      ref={ref}
      className={
        "overflow-hidden shadow-lg rounded-2xl py-0.5 bg-surface-container absolute text-sm flex flex-col gap-0 z-30 " +
        (props.className ? props.className : "")
      }
    >
      {itemsElem}
    </div>
  );
};
