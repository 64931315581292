import React, { useEffect, useRef, useState } from "react";
import NewCardIcon from "./newCardIcon";
import { scarcities_colors, scarcities_objects_not_all } from "./scarcities";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as Circle } from "../../img/icons-circle.svg";

export const ScarcityDropdownPicker = (props) => {
  const { onChange } = props;
  const selectedScarcity = props.scarcity || "";
  const allowedScarcities = props.allowedScarcities || scarcities_objects_not_all;
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleChange = (value) => {
    setFocus(false);
    onChange && onChange(value);
  };

  return (
    <div className={"relative h-full z-20"} ref={ref}>
      <button
        onClick={() => setFocus(!focus)}
        className={`h-full text-md my-auto bg-white px-3 py-2 w-80 rounded-md ${focus ? "shadow" : ""} flex flex-row justify-between gap-2`}
      >
        {selectedScarcity && (
          <Circle
            className={`flex justify-center items-center self-center w-2.5 h-2.5`}
            style={{ fill: scarcities_colors[selectedScarcity.toLowerCase()] }}
          />
        )}
        <span className={"my-auto w-5/6 truncate text-left"}>{allowedScarcities.find((v) => v.value === selectedScarcity)?.name}</span>
        <div className={"flex flex-row my-auto gap-2 w-1/6 justify-end"}>
          <div className={"flex flex-col justify-center"}>
            <SortArrowIcon className={`fill-brand-black h-3 w-3 ${focus ? "transform rotate-180" : ""}`} />
          </div>
        </div>
      </button>
      {focus && (
        <div className={"absolute bg-white rounded-md mt-2 w-80 shadow overflow-hidden"}>
          <ul className={"h-full overflow-y-scroll"} style={{ maxHeight: "23em" }}>
            {allowedScarcities.map((d, i) => (
              <ScarcityDropdownPickerItem key={i} scarcity={d} onChange={handleChange} selected={selectedScarcity} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const ScarcityDropdownPickerItem = (props) => {
  const { scarcity, onChange, selected } = props;
  const isThisTheSelected = selected && scarcity.value === selected;
  return (
    <li onClick={() => onChange(scarcity)} className={`border-b border-grey-e9 hover:bg-brand-pastel cursor-pointer pr-1`}>
      <div className={`w-full h-full px-3 py-2 ${isThisTheSelected ? "font-semibold text-brand bg-brand-pastel" : ""}`}>
        <button value={scarcity.value} className={`w-full text-brand-black cursor-pointer text-left text-sm flex flex-row gap-2`}>
          <Circle
            className={`flex justify-center items-center self-center w-2.5 h-2.5`}
            style={{ fill: scarcities_colors[scarcity.value.toLowerCase()] }}
          />
          <span className={`w-5/6 truncate my-auto`}>{scarcity.name}</span>
        </button>
      </div>
    </li>
  );
};

function ScarcityPicker(props) {
  let scarcities = ["limited", "rare", "super rare", "unique"];
  if (props.customSeries) {
    scarcities = scarcities.concat("custom series");
  }
  const selectedScarcity = props.scarcity || "";

  let cols = "grid-cols-" + scarcities.length;
  return (
    <div
      className={
        "bg-focus rounded-md text-sm font-sans font-semibold text-textGrey4 grid " +
        cols +
        " self-center justify-items-center overflow-hidden p-1 divide-x divide-grey-e9"
      }
    >
      {scarcities.map((scarcity) => {
        return (
          <div
            key={scarcity}
            className={"self-center px-3 py-1 flex w-full justify-center"}
            onClick={() => props.onChange(scarcity.toUpperCase())}
          >
            <div
              className={`align-middle h-7 cursor-pointer hover:opacity-80 ${
                selectedScarcity.toUpperCase() !== scarcity.toUpperCase() ? "opacity-20" : ""
              }`}
            >
              <div className={"w-4"}>
                <NewCardIcon scarcity={scarcity.toLowerCase()} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ScarcityPicker;
