import React, { Component } from "react";
import { Box, Text, Grid } from "grommet";
import PlayerLink from "../players/playerLink";
import CardLink from "../cards/cardLink";

class PlayersInOfferLine extends Component {
  render() {
    const players = this.props.players;
    const manager = this.props.manager;
    const etherAmount = this.props.etherAmount;
    const amountLabel = this.props.amountLabel;
    return (
      <Box direction={"row"} align={"center"} margin={"auto"}>
        <Grid rows={["auto", "auto", "auto", "auto", "auto"]}>
          <Box direction={"row"} justify={"center"} align={"center"} margin={"auto"}>
            <Text size={"small"}>{manager.Nickname}</Text>
          </Box>
          {players !== null &&
            players.map((p, i) => {
              return (
                <Box direction={"row"} justify={"center"} margin={"auto"}>
                  <Text textAlign={"center"}>
                    <PlayerLink player={p.player} /> <CardLink card={p.card} sport={p.player.Sport} />
                  </Text>
                </Box>
              );
            })}
          {(etherAmount > 0 || (players !== null && players.length === 0)) && (
            <Box direction={"row"} justify={"center"} align={"center"} margin={"auto"}>
              <p className={"text-base font-bold"}>{amountLabel}</p>
            </Box>
          )}
        </Grid>
      </Box>
    );
  }
}

export default PlayersInOfferLine;
