import { withUser } from "../../userContext";
import { isFree, isTier1, isTier2 } from "../util/handleSubscriptionTier";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import { errorCatcher } from "../util/errors";
import React, { useEffect, useState } from "react";
import Modal from "../util/modal";
import { Button } from "../util/button";
import format from "date-fns/format";
import SubscriptionBox from "../user/subscriptionBox";
import { useHistory } from "react-router-dom";

const ModalTier1Deletion = "modal_tier1_deletion";
const Modal72hAccessExpired = "modal_72h_expired";

export const ProductAnnouncementModales = withUser((props) => {
  const [productAnnouncements, setProductAnnouncements] = useState([]);
  const getProductAnnouncements = () => {
    props
      .fetch("/apiv2/user/productModals")
      .then((response) => response.json())
      .then(async (res) => {
        setProductAnnouncements(res);
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    getProductAnnouncements();
  }, []);

  const annoucementModales = [];
  if (productAnnouncements.length > 0) {
    productAnnouncements.forEach((pa) => {
      if (pa.modalShown) return; // already shown for user
      if (pa.modalId === Modal72hAccessExpired) {
        annoucementModales.push(Expired72hAccessModale);
      }
      if (pa.modalId === ModalTier1Deletion && (isTier1(props.user.tier) || isTier2(props.user.tier))) {
        // Only people seen as pro/star can see this
        annoucementModales.push(DeletionProSubscriptionModale);
      }
    });
  }

  if (annoucementModales.length > 0) {
    // Will display all not viewed announcements one by one
    const AnnouncementModale = annoucementModales[0];
    return <AnnouncementModale />;
  }
  return null;
});

const DeletionProSubscriptionModale = withUser((props) => {
  const [plan, setPlan] = useState(undefined);
  const [open, setOpen] = useState(false);
  const getSubscription = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/schedule", {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        setPlan(res);
        if (res.current_plan === props.user.tier && isTier2(props.user.tier)) {
          // if user is really a star, and not a pro converted to star, it will avoid useless api calls next time
          setProductModalToShown();
        } else {
          setOpen(true);
        }
      })
      .catch(errorCatcher());
  };
  const setProductModalToShown = () => {
    props
      .fetch("/apiv2/user/productModals", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          modalId: ModalTier1Deletion,
        }),
      })
      .then((response) => response.json())
      .catch(errorCatcher());
  };
  useEffect(() => {
    getSubscription();
  }, []);

  const close = () => {
    setOpen(false);
    setProductModalToShown();
  };

  if (plan === undefined || plan === null) return null; // waiting for api call to finish
  if (plan.current_plan === props.user.tier) return null; // user is not a pro, or is a pro converted to star
  if (isFree(plan.current_plan) || isTier2(plan.current_plan)) return null; // user is a rookie or already a real star

  const end = plan.current_end_date;
  const endDate = format(new Date(end), "MMMM do yyyy");
  const wasOnAutoRenewAndRenewalWasCanceledByUs = plan.canceled_by_us === true;
  return (
    <Modal
      open={open}
      noShowClose={true}
      close={close}
      padding={"p-0"}
      content={
        <>
          <div
            className={"p-3 border-b border-transparent-inverse-surface-low border-opacity-10 flex flex-row justify-between items-center"}
          >
            <h2 className={"text-xl font-semibold font-headers m-0 text-on-surface px-1"}>You are now a Star! ⭐️</h2>
            <div className={"cursor-pointer"} onClick={close}>
              <CloseIcon className="fill-on-surface cursor-pointer h-6 w-6 hover:opacity-80" />
            </div>
          </div>
          <div className={"flex flex-col gap-4 py-4 px-6"}>
            <SubscriptionBox
              plan={plan.current_plan}
              amount={plan.current_price / 100}
              amount_no_discount={plan.current_price_no_discount !== undefined ? plan.current_price_no_discount / 100 : undefined}
              currency={plan.current_currency}
              end={plan.current_end_date}
              renewal={!plan.cancel_at_current_end}
              period={plan.current_frequency}
              trial={plan.is_current_trial}
              nextPlan={plan.next_plan}
              nextAmount={plan.next_price / 100}
              customerBalance={plan.customer_balance / 100}
              nextPeriod={plan.next_frequency}
              planCanceledByUs={plan.canceled_by_us}
            />
            <div className={"text-center text-on-surface text-xs space-y-2"}>
              <p className={"text-sm  font-semibold"}>You have been upgraded to a Star membership!</p>
              <p className={"text-on-surface-variant font-medium"}>
                The Football <b>Pro</b> membership will be transitioning and won&apos;t be available for extension or renewal.
                <br />
                {wasOnAutoRenewAndRenewalWasCanceledByUs && (
                  <span>
                    For this reason, your auto-renewal has been deactivated.
                    <br />
                  </span>
                )}
                The bright side? We&apos;re upgrading you to our football <b>Star</b> membership, starting today and for the rest of your
                subscription (until {endDate}). That&apos;s more features with no price change!
              </p>
              <p className={"text-primary hover:opacity-80 font-semibold"}>
                <a
                  className={"border-none"}
                  target={"_blank"}
                  href={"https://medium.com/@soraredata/soraredata-memberships-update-acf45ef2fcd3"}
                  rel="noreferrer"
                >
                  Read our Medium article
                </a>
              </p>
            </div>
            <div className={"flex justify-center"}>
              <Button label={"Got it!"} onClick={close} />
            </div>
          </div>
        </>
      }
    />
  );
});

const Expired72hAccessModale = withUser((props) => {
  const [open, setOpen] = useState(true);
  const history = useHistory();

  const setProductModalToShown = () => {
    props
      .fetch("/apiv2/user/productModals", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          modalId: Modal72hAccessExpired,
        }),
      })
      .then((response) => response.json())
      .catch(errorCatcher());
  };

  const close = () => {
    setOpen(false);
    setProductModalToShown();
  };

  const confirm = () => {
    setOpen(false);
    setProductModalToShown();
    history.push("/product?downgradable=true", { currentFrequency: "72h" });
  };

  return (
    <Modal
      open={open}
      noShowClose={true}
      close={close}
      padding={"p-0"}
      content={
        <>
          <div
            className={"p-3 border-b border-transparent-inverse-surface-low border-opacity-10 flex flex-row justify-between items-center"}
          >
            <h2 className={"text-xl font-semibold font-headers m-0 text-on-surface px-1"}>Your 72 hour access is over</h2>
            <div className={"cursor-pointer"} onClick={close}>
              <CloseIcon className="fill-on-surface cursor-pointer h-6 w-6 hover:opacity-80" />
            </div>
          </div>
          <div className={"flex flex-col gap-4 py-4 px-6"}>
            <div className={"text-center text-on-surface text-base space-y-2"}>
              <p className={"text-on-surface-variant"}>
                You Star membership have expired. If you want to continue your experience as a Star user, check out our offers.
              </p>
            </div>
            <div className={"flex justify-center space-x-4"}>
              <Button label={"Cancel"} onClick={close} context="secondary" />
              <Button label={"View membership offers"} onClick={confirm} />
            </div>
          </div>
        </>
      }
    />
  );
});
