import BoxWithToggle from "../util/boxWithToggle";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { customOptionRender } from "../util/customSelectSearchRender";
import React from "react";

export const GameweekFilter = (props) => {
  const { filterByGw, onChangeFilterByGw, gameweeks, gw, onChangeGw, closeOnSelect } = props;
  const label = props.label || "Has a game in gameweek";
  return (
    <div className={"shadow space-y-2 py-1.5 rounded-md bg-focus"}>
      <div>
        <BoxWithToggle label={label} isActive={filterByGw} setActive={onChangeFilterByGw} />
      </div>
      {filterByGw && (
        <div className={`z-50 px-2 ${props.className ? props.className : ""}`}>
          <SelectSearch
            options={gameweeks}
            className={"select-search border border-grey-f2 z-50 rounded-md"}
            value={gw}
            closeOnSelect={closeOnSelect}
            onChange={onChangeGw}
            printOptions={"on-focus"}
            filterOptions={fuzzySearch}
            placeholder="Filter by gameweek"
            renderOption={customOptionRender}
          />
        </div>
      )}
    </div>
  );
};
