import React, { useEffect, useState } from "react";
import useComponentVisible from "./outsideAlerter";
import { Range } from "rc-slider";
import ToggleWithLabel from "./toggleWithLabel";

function SerialNumberFilter(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [value, setValue] = useState([1, props.limit]);
  const [specialEditions, setSpecialEditions] = useState(false);

  const onSliderChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    setValue([value[0], props.limit]);
  }, [props.limit]);

  return (
    <div>
      <div className={"rounded-md bg-white py-2 shadow cursor-pointer"} onClick={() => setIsComponentVisible(!isComponentVisible)}>
        <p className={"text-sm px-4"}># number/edition</p>
      </div>
      {isComponentVisible && (
        <div ref={ref} className={"bg-white mt-2 absolute w-80 z-10 py-4 rounded-xl shadow"}>
          <div className={"w-10/12 mx-auto space-y-4 text-base"}>
            <div className={""}>
              <Range
                trackStyle={[{ backgroundColor: "#f45206" }]}
                handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                step={1}
                value={value}
                min={1}
                max={props.limit}
                allowCross={false}
                onChange={onSliderChange}
              />
              <div className={"justify-between flex flex-row text-base font-semibold"}>
                <span>{value[0]}</span>
                <span>{value[1]}</span>
              </div>
            </div>
            <div className={""}>
              <ToggleWithLabel
                isActive={specialEditions}
                label={"Special editions only"}
                setActive={() => setSpecialEditions(!specialEditions)}
              />
            </div>
            <div></div>
            <div className={"mx-auto text-center"}>
              <div
                className={"bg-brand px-2 py-2 w-24 mx-auto rounded-3xl cursor-pointer font-semibold hover:font-bold"}
                onClick={() => props.serialOptions(value, specialEditions)}
              >
                <span className={"text-white"}>Apply</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SerialNumberFilter;
