import React, { useState } from "react";
import formatDistance from "date-fns/formatDistance";
import WatchlistPlayerTypeIcon from "../../img/icon-watchlists-type-player.svg";
import WatchlistManagerTypeIcon from "../../img/icon-watchlists-type-manager.svg";
import Locker from "../../img/icons-locker-close-grey.svg";
import PinIcon from "../../img/icons-pin.svg";
import { ReactComponent as MenuDotIcon } from "../../img/icon-menu-dot.svg";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { withUser } from "../../userContext";
import format from "date-fns/format";
import { Link, withRouter } from "react-router-dom";
import { WatchlistPrivacyLabel } from "./privacyLabel";
import WatchlistFollowingButton from "./watchlistFollowingButton";
import { StandaloneMenu } from "../util/standaloneMenu";
import { XIcon } from "@heroicons/react/outline";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { ManagerVerifiedCheckMark } from "../util/manager";

function WatchlistCard(props) {
  const {
    id,
    owner,
    ownerDisplayName,
    pictureUrl,
    name,
    privacy,
    type,
    created,
    enabled,
    nbItems,
    nbFollowers,
    isFollowed,
    isDefault,
    isPinned,
    sport,
  } = props.watchlist;
  const { closeable, onClose, onCloseTitle } = props;
  const { onReactivate } = props;
  const limitations = (props.limitations ? props.limitations[type] : {}) || {};

  const [showMenu, setShowMenu] = useState(false);
  const [hover, setHover] = useState(false);

  if (id === "") {
    return null;
  }

  // This watchlist has been disabled following a downgrade of your subscription plan. Upgrade plan and reactivate watchlists to get access
  let canActivate = false;
  let title = "";
  if (!enabled) {
    if (isFollowed) {
      title = "Your membership does not allow the following of watchlists. Upgrade plan to get access";
    } else {
      // This watchlist has been disabled because Pro members have access to up to 5 player watchlists.
      // Please upgrade to a Star membership for unlimited player watchlists, or delete one active watchlist.
      if (limitations.activationAuthorized) {
        canActivate = true;
        title =
          limitations.max !== undefined
            ? `This watchlist has been disabled because you reached the maximum of ${
                limitations.max
              } ${type} watchlists for your membership. You can reactivate up to ${
                limitations.max - limitations.currentActive
              } different ${type} watchlists, or upgrade your membership.`
            : `This watchlist has been disabled because you reached the maximum of ${type} watchlists for your membership at some point. But now, you can reactivate them all.`;
      } else {
        title =
          limitations.max === 0
            ? `This watchlist has been disabled because you can't have any ${type} watchlists for your membership. Please upgrade your membership for more watchlists`
            : `This watchlist has been disabled because you reached the maximum of ${limitations.max} ${type} watchlists for your membership. Please upgrade your membership for more watchlists, or delete one active watchlist`;
      }
    }
  }

  const secondLine = (
    <div className={"relative w-full " + (enabled ? "cursor-pointer" : "opacity-50")}>
      <div className={"text-xl font-semibold flex flex-row items-center justify-start"}>
        <span className={"mr-2"}>{name}</span>
        <WatchlistPrivacyLabel privacy={privacy} />
      </div>
      <div className={"mt-0.5"}>
        {sport === "multi" ? (
          <p className={"text-sm font-sans font-semibold text-textGrey4"}>{nbItems + (type === "player" ? " players" : " managers")}</p>
        ) : (
          <div className={"flex flex-row gap-1"}>
            <p className={"text-sm font-sans font-semibold text-textGrey4"}>{nbItems + (type === "player" ? " players" : " managers")}</p>
            <span className={"text-textGrey4"}>{`•`}</span>
            {sport === sorareBaseball && <span className={"my-auto text-sm font-semibold"}>⚾️️</span>}
            {sport === sorareFootball && <span className={"my-auto text-sm font-semibold"}>⚽</span>}
            {sport === sorareBasketball && <span className={"my-auto text-sm font-semibold"}>🏀</span>}
          </div>
        )}
      </div>
    </div>
  );

  const ownerIcon = <img className={"inline w-7 h-7 object-cover rounded-full"} src={pictureUrl ? pictureUrl : EmptyAvatar} />;

  const connectedUserIsOwner = owner === props.user.sorareSlug;

  let menuItems = [];
  if (enabled) {
    menuItems = [
      {
        key: "pin",
        label: isPinned ? "Unpin" : "Pin",
        className: "cursor-pointer w-full sm:w-52",
        onClick: () => {
          props.onPin && props.onPin(props.watchlist);
          setShowMenu(false);
        },
      },
      {
        key: "default",
        label: isDefault ? "Remove from default" : "Set as default",
        className: "cursor-pointer w-full sm:w-52",
        onClick: () => {
          props.onSelectAsDefault && props.onSelectAsDefault(props.watchlist);
          setShowMenu(false);
        },
      },
    ];
    if (connectedUserIsOwner) {
      menuItems = [
        {
          key: "edit",
          label: "Edit",
          className: "cursor-pointer w-full sm:w-52",
          onClick: () => {
            props.onEdit && props.onEdit(props.watchlist);
            setShowMenu(false);
          },
        },
        ...menuItems,
        {
          key: "delete",
          label: "Delete",
          className: "cursor-pointer text-live-red w-full sm:w-52",
          onClick: () => {
            props.onDelete && props.onDelete(props.watchlist);
            setShowMenu(false);
          },
        },
      ];
    }
  } else {
    if (connectedUserIsOwner) {
      menuItems = [
        {
          key: "delete",
          label: "Delete",
          className: "cursor-pointer text-live-red w-full sm:w-52",
          onClick: () => {
            props.onDelete && props.onDelete(props.watchlist);
            setShowMenu(false);
          },
        },
      ];
    }
  }

  return (
    <div
      disabled={!enabled}
      title={title}
      className={`relative bg-focus mx-auto rounded-lg w-full border ${isPinned ? "border-brand" : "border-grey-e9"}`}
      onMouseEnter={enabled || !canActivate ? undefined : () => setHover(true)}
      onMouseLeave={enabled || !canActivate ? undefined : () => setHover(false)}
    >
      {!closeable && !enabled && (
        <div
          className={`absolute rounded-lg z-20 w-full h-full bg-transparent flex flex justify-center ${
            !canActivate ? "cursor-not-allowed" : ""
          }`}
        >
          {canActivate && hover && (
            <button
              className={"my-auto font-semibold bg-brand text-md text-focus py-2.5 px-4 rounded-md"}
              onClick={() => onReactivate && onReactivate(props.watchlist)}
            >
              Reactivate
            </button>
          )}
        </div>
      )}
      <div className={"space-y-4 p-4 flex flex-col justify-between h-full"}>
        <div className={"flex flex-row place-content-between p-0"}>
          <div title={`Watchlist of ${type}s`}>
            <img src={type === "player" ? WatchlistPlayerTypeIcon : WatchlistManagerTypeIcon} />
          </div>
          <div></div>
          <div className={"flex flex-row gap-2"}>
            {!closeable && isPinned && (
              <span>
                <img className={`h-7 w-7 ${enabled ? "" : "opacity-50"}`} src={PinIcon} />
              </span>
            )}
            {!closeable && !enabled && (
              <span>
                <img className={`h-6 w-6 ${enabled ? "" : "opacity-50"}`} src={Locker} />
              </span>
            )}
            {!closeable && (
              <div className={`relative ${!enabled ? "z-30" : ""} ${isPinned ? "pt-1" : ""}`}>
                {menuItems.length > 0 && (
                  <>
                    <MenuDotIcon className="cursor-pointer fill-on-surface" onClick={() => setShowMenu(true)} />
                    <StandaloneMenu
                      className={"top-0 -left-36 2xl:left-8 "}
                      show={showMenu}
                      onClickOutside={() => setShowMenu(false)}
                      items={menuItems}
                    />
                  </>
                )}
              </div>
            )}
            {closeable && (
              <button
                title={onCloseTitle}
                type="button"
                className="bg-transparent focus:outline-none"
                onClick={onClose ? () => onClose(props.watchlist) : undefined}
              >
                <XIcon className="cursor-pointer h-6 w-6 stroke-textGrey4 hover:opacity-80" />
              </button>
            )}
          </div>
        </div>
        {enabled && <Link to={`/watchlists/id/${id}`}>{secondLine}</Link>}
        {!enabled && secondLine}
        <div className={`flex flex-row place-content-between ${enabled ? "" : "opacity-50"}`}>
          <div className={"flex gap-1"}>
            <span className={"rounded-full self-center"}>
              {enabled && <a href={"/manager/" + owner}>{ownerIcon}</a>}
              {!enabled && ownerIcon}
            </span>
            <span className={"text-sm font-medium self-center"}>
              {enabled && <a href={"/manager/" + owner}>{ownerDisplayName || owner}</a>}
              {!enabled && <>{ownerDisplayName || owner}</>}
            </span>
            <ManagerVerifiedCheckMark managerSlug={owner} />
            <span
              title={`Created on ${format(new Date(created), "dd/MM/yyyy")}`}
              className={"text-sm font-semibold text-textGrey4 self-center"}
            >
              {"• " +
                formatDistance(new Date(created), new Date(), {
                  addSuffix: true,
                })}
            </span>
          </div>
          <WatchlistFollowingButton
            id={id}
            enabled={enabled}
            nbFollowers={nbFollowers}
            isFollowed={isFollowed}
            connectedUserIsOwner={connectedUserIsOwner}
            withLabel
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(withUser(WatchlistCard));
