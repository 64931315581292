import React, { useCallback, useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import CustomLineup from "./customLineup";
import GameLivePageHeader from "../games/gameLivePageHeader";
import { ReactComponent as LineSeparator } from "../../img/line-separator-no-color.svg";
import PlayerAvatar from "../util/playerAvatar";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import ScoreAverage from "./scoreAverage";
import OppScore from "./oppScore";
import GameInLine from "./gameInLine";
import PlayerAvailability from "../players/playerAvailability";
import { sorareFootball } from "../util/sports";
import { CompetitionMultiSelectMenu } from "./competitionMultiSelect";
import { getRarityDisplayName } from "../util/divisions";

function PickPct(props) {
  let color = "brand-black";
  let pct = 0;
  let trail = "#E5E5E5";
  if (props.value > 0) {
    pct = 100 * props.value;
    if (pct < 20) {
      color = "#FF3232";
      trail = "#FFEAEA";
    } else if (pct < 40) {
      color = "#F89F31";
      trail = "#FEF1E0";
    } else if (pct < 60) {
      color = "#E7B62C";
      trail = "#FAF0D5";
    } else if (pct < 80) {
      color = "#81BF50";
      trail = "#F0F7EA";
    } else if (pct <= 100) {
      color = "#169A54";
      trail = "#DCF0E5";
    }
  } else {
    color = "#292929";
  }
  return (
    <div className={"w-11"}>
      <CircularProgressbarWithChildren
        value={Number(pct)}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: "round",
          pathColor: color,
          trailColor: trail,
        })}
      >
        <p className={"text-xs font-semibold"} style={{ color: color }}>
          {pct <= 0 ? "NA" : pct.toFixed(0) + "%"}
        </p>
      </CircularProgressbarWithChildren>
    </div>
  );
}

const getCompetitionOption = (comp) => {
  if (comp === undefined || comp === null) {
    return null;
  }
  // let lineupsCompletion = comp.current_lineups;
  return {
    id: comp.id,
    label: comp.fullNameNoScarcity,
    logo: comp.logoUrl,
    subtitle: getRarityDisplayName(comp.rarity),
    tagLabel: comp.lineupCount,
    ...comp,
  };
};

export const CommunityBuilding = withUser((props) => {
  const [lineups, setLineups] = useState([]);
  const [odds, setOdds] = useState([]);
  const [standings, setStandings] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [compsAndLineups, setCompsAndLineups] = useState([]);
  const [divisionStats, setDivisionStats] = useState([]);
  const [mostPicked, setMostPicked] = useState([]);
  const [positionPicked, setPositionPicked] = useState("Goalkeeper");
  const [loading, setLoading] = useState(false);

  const [isCompetitionsLoaded, setIsCompetitionsLoaded] = useState(false);
  const [selectAllOption, setSelectAllOption] = useState({ id: "all", label: "All teams" });
  const [selectedCompIds, setSelectedCompIds] = useState([]);
  const [compIdFromDivisionId, setCompIdFromDivisionId] = useState({});

  useEffect(() => {
    if (props.gw) {
      loadCompetitions();
      getLineups(props.gw);
    }
  }, [props.gw]);

  useEffect(() => {
    const countByDivId = lineups?.reduce((acc, l) => {
      const divId = l.comp.DivisionId;
      if (acc[divId] === undefined) {
        acc[divId] = 0;
      }
      acc[divId]++;
      return acc;
    }, {});
    const newCompIdFromDivisionId = {};
    const newComps =
      competitions
        ?.map((c) => {
          let lineupCount = 0;
          if (c.isMultiEntry) {
            if (c.isPromotionRelegation) {
              // Potentially different division id for each lineup in the competition
              lineupCount =
                Object.entries(countByDivId).reduce((acc, [divId, count]) => {
                  // If comp id is prefixed of divId
                  if (divId.startsWith(c.id)) {
                    newCompIdFromDivisionId[divId] = c.id;
                    return acc + count;
                  }
                  return acc;
                }, 0) || 0;
            } else {
              // Same division id for all lineups in the competition
              newCompIdFromDivisionId[c.id] = c.id;
              lineupCount = countByDivId[c.id] || 0;
            }
          } else {
            newCompIdFromDivisionId[c.singleEntryTeam?.competitionId] = c.id;
            lineupCount = countByDivId[c.singleEntryTeam?.competitionId] || 0;
          }
          return {
            ...c,
            lineupCount: lineupCount,
          };
        })
        .filter((c) => c.lineupCount > 0) || [];
    const totalLineups = newComps.reduce((acc, c) => acc + c.lineupCount, 0);
    setSelectAllOption({
      ...selectAllOption,
      tagLabel: totalLineups > 1 ? `${totalLineups} lineups` : `${totalLineups} lineup`,
    });
    setCompIdFromDivisionId(newCompIdFromDivisionId);
    setCompsAndLineups(newComps);
    if (newComps?.length > 0 && selectedCompIds.length === 0) {
      setSelectedCompIds([selectAllOption.id, ...newComps.map((c) => c.id)]);
    }
  }, [competitions, lineups]);

  const targetUserId = props.targetUserId;

  const loadCompetitions = () => {
    if (props.gw) {
      setLoading(true);
      props
        .fetch(`/decision-api/decision/lub/drafts/id/default/lineups/v2?sport=${sorareFootball}&gwNumber=${props.gw}`)
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setCompetitions(res.competitions || []);
          }
          setLoading(false);
          setIsCompetitionsLoaded(true);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
            setIsCompetitionsLoaded(true);
          }),
        );
    }
  };

  const onChangeDivision = useCallback(
    (divId) => {
      setSelectedCompIds((prev) => {
        if (divId === selectAllOption.id) {
          return prev.includes(selectAllOption.id) ? [] : [selectAllOption.id, ...competitions.map(getCompetitionOption).map((c) => c.id)];
        }
        const index = prev.indexOf(divId);
        const selectedCompetitionsWithoutSelectAll = prev.filter((c) => c !== selectAllOption.id);
        if (index !== -1) {
          if (selectedCompetitionsWithoutSelectAll.length === 1) {
            return [];
          }
          return prev.filter((item) => item !== divId);
        }
        if (selectedCompetitionsWithoutSelectAll.length === competitions.length - 1) {
          return [selectAllOption.id, ...selectedCompetitionsWithoutSelectAll, divId];
        }
        return [...prev, divId];
      });
    },
    [competitions],
  );

  const getLineups = (gw) => {
    let gwValue = gw || props.gw;
    if (gwValue.value) {
      gwValue = gwValue.value;
    }
    if (gwValue) {
      const params = new URLSearchParams();
      if (targetUserId) params.append("managerSorareId", targetUserId);
      params.append("gwNumber", gwValue);
      props
        .fetch("/decision-api/decision/communityLineups?" + params.toString())
        .then((response) => response.json())
        .then((res) => {
          if (res !== null) {
            setLineups(res.lineups || []);
            setOdds(res.odds);
            setStandings(res.standings);
            setMostPicked(res.most_picked || []);
            setDivisionStats(res.divisions_stats);
          } else {
            setLineups([]);
          }
        })
        .catch(errorCatcher());
    }
  };

  const competitionOptions = [selectAllOption, ...compsAndLineups.map(getCompetitionOption)];

  const positionClass = "flex flex-row space-x-2";
  const insideClass = "w-10/12 flex flex-row justify-center text-on-surface-variant text-sm font-medium hover:font-semibold cursor-pointer";
  const insideActiveClass = "w-10/12 flex flex-row justify-center text-sm text-primary font-semibold";
  return (
    <div className={"h-full w-full bg-surface"}>
      <div className={"mx-4 mb-8 4xl:mx-auto 4xl:w-10/12 4.5xl:w-9/12"}>
        <div className={"z-10"}></div>
        <div className={"flex flex-row space-x-4"}>
          <div className={"w-6/12 space-y-4"}>
            <div className={"self-center w-8/12 2xl:w-6/12 3xl:w-4/12 z-10 h-10"}>
              <CompetitionMultiSelectMenu
                loading={loading}
                competitions={competitionOptions}
                selectedIds={selectedCompIds}
                onChange={onChangeDivision}
                allOption={selectAllOption}
                isCompetitionsLoaded={isCompetitionsLoaded}
              />
            </div>
            {lineups !== null &&
              lineups
                .filter((l) => {
                  return selectedCompIds.length == 0 || selectedCompIds.includes(compIdFromDivisionId[l.comp.DivisionId]);
                })
                .map((l) => {
                  return (
                    <CustomLineup
                      lineup={l.cards}
                      comp={l.comp}
                      title={l.comp.DisplayName + " · " + l.userNickname}
                      divisionStats={divisionStats}
                      odds={odds}
                      standings={standings}
                    />
                  );
                })}
            {lineups === null && <div className={"text-on-surface-variant"}>No lineups</div>}
          </div>
          <div className={"w-6/12 space-y-4"}>
            <GameLivePageHeader label={"Most picked players"} />
            <div className={"grid grid-cols-4 bg-surface-container rounded-lg py-3"}>
              <div className={positionClass} onClick={() => setPositionPicked("Goalkeeper")}>
                <div className={positionPicked === "Goalkeeper" ? insideActiveClass : insideClass}>Goalkeepers</div>
                <LineSeparator className={"stroke-transparent-inverse-surface-low opacity-10"} />
              </div>
              <div className={positionClass} onClick={() => setPositionPicked("Defender")}>
                <div className={positionPicked === "Defender" ? insideActiveClass : insideClass}>Defenders</div>
                <LineSeparator className={"stroke-transparent-inverse-surface-low opacity-10"} />
              </div>
              <div className={positionClass} onClick={() => setPositionPicked("Midfielder")}>
                <div className={positionPicked === "Midfielder" ? insideActiveClass : insideClass}>Midfielders</div>
                <LineSeparator className={"stroke-transparent-inverse-surface-low opacity-10"} />
              </div>
              <div className={positionClass} onClick={() => setPositionPicked("Forward")}>
                <div className={positionPicked === "Forward" ? insideActiveClass : insideClass}>Forwards</div>
              </div>
            </div>
            {mostPicked !== null && lineups !== null && (
              <table>
                <thead>
                  <tr className="text-center text-on-surface-variant">
                    <th className="rounded-tl-lg on-surface-variant bg-surface-container-high border-b border-transparent-inverse-surface-low border-opacity-10 mx-auto py-2 pl-4 text-left w-4/12 font-bold uppercase text-xs">
                      Player
                    </th>
                    <th className="bg-surface-container-high border-b border-transparent-inverse-surface-low border-opacity-10 mx-auto py-3 text-center w-2/12 font-bold uppercase text-xs">
                      Pick pct
                    </th>
                    <th className="bg-surface-container-high border-b border-transparent-inverse-surface-low border-opacity-10 mx-auto py-3 text-center w-1/12 font-bold uppercase text-xs">
                      L5
                    </th>
                    <th className="bg-surface-container-high border-b border-transparent-inverse-surface-low border-opacity-10 mx-auto py-3 text-center w-1/12 font-bold uppercase text-xs">
                      L15
                    </th>
                    <th className="bg-surface-container-high border-b border-transparent-inverse-surface-low border-opacity-10 mx-auto py-3 text-center w-3/12 font-bold uppercase text-xs">
                      Matchup
                    </th>
                    <th className="rounded-tr-lg  bg-surface-container-high border-b border-transparent-inverse-surface-low border-opacity-10 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs">
                      Opp. score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mostPicked.map((mp) => {
                    if (mp.card.Position === positionPicked) {
                      const card = mp.card;
                      const player = mp.player;
                      return (
                        <tr
                          className={
                            "bg-surface-container border-b border-transparent-inverse-surface-low border-opacity-10 text-on-surface"
                          }
                        >
                          <td>
                            <div className={"flex flex-row space-x-2"}>
                              <div className={"py-1"}>
                                <PlayerAvatar player={player} />
                              </div>
                              <div className={"flex flex-col space-y-0.5 self-center"}>
                                <div className={"flex flex-row space-x-2"}>
                                  <p className={"text-sm 2xl:text-base font-semibold"}>{player.DisplayName}</p>
                                  <div className={"w-4 h-4 self-center"}>
                                    <PlayerAvailability availability={mp.player_status} />
                                  </div>
                                </div>
                                <p className={"text-xs 2xl:text-sm font-medium text-on-surface-variant"}>
                                  {player.Age} - {card.Position} -{" "}
                                  <span className={"text-primary font-semibold"}>{(parseFloat(card.Power) * 100 - 100).toFixed(1)}%</span>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className={"flex flex-row justify-center"}>
                              <PickPct value={mp.count / lineups.length} />
                            </div>
                          </td>
                          <td>
                            <ScoreAverage avg={mp.last_5} period={5} played={mp.l5} power={card.Power} />
                          </td>
                          <td>
                            <ScoreAverage avg={mp.last_15} period={15} played={mp.last_15_played} power={card.Power} />
                          </td>
                          <td>
                            <div className={"flex flex-row self-center justify-center py-2"}>
                              <GameInLine
                                playerTeam={player.TeamId}
                                playerNationalTeam={player.NationalTeam}
                                team1={mp.player_team}
                                team2={mp.opposing_team}
                                game={mp.next_game}
                                odds={odds[mp.next_game.GameId]}
                                standings={standings}
                              />
                            </div>
                          </td>
                          <td>
                            <OppScore entry={mp} />
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
});
