import { withUser } from "../../userContext";
import React, { useCallback, useEffect, useState } from "react";
import SDLoading from "../util/SDLoading";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import { findPriceForUserUnit } from "../util/formatMoney";
import { formatDistanceToNowStrict } from "date-fns";
import { capitalize } from "../util/capitalize";
import EmptyClub from "../../img/icon-empty-club.svg";
import ConfirmModal from "../util/confirmModal";
import { getScarcityInfo } from "../util/scarcities";
import clsx from "clsx";
import PlayerAvailability from "../players/playerAvailability";
import { PlayingStatus } from "../util/playingStatus";
import { yearsSince } from "../util/date";
import { PriceAlertDeleteButton, PriceAlertEditButton } from "../priceAlerts/priceAlertModal";
import { editions, seasons } from "../priceAlerts/enum";
import { errorCatcher } from "../util/errors";
import { ReactComponent as IconTrash } from "../../img/icons-trash.svg";
import { Button } from "../util/button";
import Snackbar from "../util/snackbar";

export const PriceAlertsSection = withUser((props) => {
  const [alerts, setAlerts] = useState([]);
  const [alertsCount, setAlertsCount] = useState(0);
  const [alertsLoading, setAlertsLoading] = useState(false);
  const [order, setOrder] = useState(["rank", "asc"]);

  const enrichAlert = (res) => {
    return (alert) => ({
      ...alert,
      players: alert.playerIds.map((id) => res?.players[id]),
      floorPrices: alert.playerIds.map((id) => res?.floorPrices[id]),
      latestValuations: alert.playerIds.map((id) => res?.latestValuations[id]),
    });
  };
  const fetchPriceAlerts = useCallback(() => {
    setAlertsLoading(true);
    props
      .fetch("/apiv2/user/priceAlerts", {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        const alertsEnriched = res?.alertConfigs?.map(enrichAlert(res)) ?? [];
        setAlerts(alertsEnriched);
        setAlertsCount(alertsEnriched?.length);
      })
      .catch(errorCatcher())
      .finally(() => {
        setAlertsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchPriceAlerts();
  }, [order]);

  return (
    <div className={GLOBAL_MARGIN_NO_BG_NO_PAD + " mt-4 space-y-4 z-0 pb-10"}>
      <div>
        <h1 className="text-2.5xl font-bold text-black">My price alerts</h1>
        <div className="flex justify-between items-center">
          <p className="text-on-surface-variant text-xs">*Prices displayed do not take card edition into account</p>
          <DeleteAllAlertsButton count={alertsCount} onDeleteCallback={() => setAlerts([])} onUndoCallback={fetchPriceAlerts} />
        </div>
      </div>
      {alertsLoading ? (
        <div className="py-10">
          <SDLoading />
        </div>
      ) : (
        <div>
          <table className="border-collapse overflow-hidden rounded-lg table-fixed whitespace-no-wrap mx-auto min-w-full">
            <thead>
              <tr className="text-center bg-surface-container-high text-on-surface-variant h-12 font-semibold">
                <th className="cursor-pointer text-center text-xs w-max lg:w-1/5">Player</th>
                <th className="py-3 px-1 text-xs text-center">Scarcity</th>
                <th className="py-3 px-1 text-xs text-center">Season</th>
                <th className="py-3 px-1 text-xs text-center">Edition*</th>
                <th className="py-3 px-4 text-xs text-center">Source</th>
                <th className="py-3 px-4 text-xs text-center">Alert price</th>
                <th className="py-3 px-4 text-xs text-center">Floor</th>
                <th className="py-3 px-4 text-xs text-center">Latest val.</th>
                <th className="py-3 px-4 text-xs text-center">Triggered</th>
                <th className="py-3 px-4 text-xs text-center">Edited</th>
                <th className="w-1/12" />
              </tr>
            </thead>
            <tbody className="space-y-2 bg-surface-container text-on-surface">
              {!alerts.length ? (
                <tr>
                  <td colSpan="10" className="text-center text-sm p-4 w-full">
                    No price alert found.
                  </td>
                </tr>
              ) : (
                alerts.map((alert) => {
                  const player = alert?.players?.[0];
                  const scarcityInfo = getScarcityInfo(alert.scarcity);
                  let season = "-";
                  if (alert.seasons?.length >= yearsSince(2018)) {
                    season = "All seasons";
                  } else if (alert.seasons?.length > 1) {
                    season = "Multi";
                  } else if (alert.seasons?.length === 1) {
                    season = seasons.find((s) => s.value === alert.seasons?.[0])?.name;
                  }
                  let edition = "-";
                  if (alert.editions?.length === editions.length) {
                    edition = "All editions";
                  } else if (alert.editions?.length > 1) {
                    edition = "Multi";
                  } else if (alert.editions?.length === 1) {
                    edition = editions.find((s) => s.value === alert.editions?.[0])?.name;
                  }
                  return (
                    <tr key={alert.alertConfigId} className="h-16 py-2 border-t border-outline-variant">
                      <td className="text-center">
                        {alert.players.length > 0 ? (
                          <div className="flex items-center gap-2 pl-3">
                            <div className="bg-transparent-inverse-surface-low bg-opacity-10 w-10 h-10 rounded-full flex items-center justify-center flex-shrink-0">
                              <img src={player.avatar} className="rounded-full h-10" />
                            </div>
                            <div className="flex flex-col">
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-2">
                                  <a href={"/player/" + player?.playerId}>
                                    <p className="font-semibold text-sm truncate">{player?.shortName}</p>
                                  </a>
                                  <img
                                    className="object-contain object-center h-4 w-4 flex-shrink-0"
                                    src={player.team.pictureUrl || EmptyClub}
                                    alt={player.team.shortName}
                                  />
                                  <PlayerAvailability availability={player.status} size={"w-4 h-4"} position="center" />
                                </div>
                                <div className="text-left text-xs text-on-surface-variant">
                                  {player.position} | <PlayingStatus status={player.playingStatus} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">{capitalize(alert.scarcity)}</p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">{season}</p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">{edition}</p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">{capitalize(alert.listingType)}</p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">
                          {alert.computedPrice.eth > 0 ? (
                            <span className={clsx("font-semibold text-sm", scarcityInfo.color)}>
                              {findPriceForUserUnit(alert.computedPrice.eth, alert.computedPrice, props.user.preferredUnit)}
                            </span>
                          ) : (
                            "-"
                          )}
                        </p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">
                          {alert.computedFloorPrice.eth > 0 ? (
                            <span className={scarcityInfo.color}>
                              {findPriceForUserUnit(alert.computedFloorPrice.eth, alert.computedFloorPrice, props.user.preferredUnit)}
                            </span>
                          ) : (
                            "-"
                          )}
                        </p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">
                          {alert.computedLatestValuation.eth > 0 ? (
                            <span className={scarcityInfo.color}>
                              {findPriceForUserUnit(
                                alert.computedLatestValuation.eth,
                                alert.computedLatestValuation,
                                props.user.preferredUnit,
                              )}
                            </span>
                          ) : (
                            "-"
                          )}
                        </p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">
                          {alert.lastSent ? formatDistanceToNowStrict(new Date(alert.lastSent), { addSuffix: true }) : "Never"}
                        </p>
                      </td>
                      <td className="text-center">
                        <p className="font-semibold text-sm">
                          {alert.lastUpdated ? formatDistanceToNowStrict(new Date(alert.lastUpdated), { addSuffix: true }) : "Never"}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center justify-around">
                          <PriceAlertEditButton
                            priceAlert={alert}
                            playerId={player?.playerId}
                            playerName={player.name}
                            onSubmitCallback={fetchPriceAlerts}
                          />
                          <PriceAlertDeleteButton
                            alertConfigId={alert.alertConfigId}
                            onDeleteCallback={fetchPriceAlerts}
                            setLoading={setAlertsLoading}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
});

const DeleteAllAlertsButton = withUser((props) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [undoId, setUndoId] = useState(undefined);
  const [open, setOpen] = useState(false);
  const { count, onDeleteCallback, onUndoCallback } = props;

  const onDelete = () => {
    props
      .fetch("/apiv2/user/priceAlerts/all", {
        method: "DELETE",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Erreur lors de la suppression");
      })
      .then(({ undoId }) => {
        setUndoId(undoId);
        onDeleteCallback();
        setOpen(false);
        setIsSnackbarOpen(true);
      })
      .catch(errorCatcher());
  };

  const undo = () => {
    if (undoId === undefined) {
      return;
    }
    props
      .fetch(`/apiv2/user/priceAlerts/all/undo?id=${undoId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.ok) {
          onUndoCallback();
          setIsSnackbarOpen(false);
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div>
      <Snackbar
        message={`${count} price alert${count > 1 ? "s" : ""} deleted.`}
        isOpen={isSnackbarOpen}
        onAction={undo}
        duration={5000}
        onClose={() => setIsSnackbarOpen(false)}
      />{" "}
      {count > 0 && (
        <Button
          label="Delete all alerts"
          context="secondary"
          onClick={() => setOpen(true)}
          icon={<IconTrash className="w-4 h-4 my-auto fill-on-disable" />}
        />
      )}
      <ConfirmModal
        title="Delete all price alerts ?"
        message={<>All price alerts will be deleted</>}
        open={open}
        onConfirm={onDelete}
        confirmLabel="Delete all"
        confirmingLabel="Deleting..."
        onCancel={() => setOpen(false)}
      />
    </div>
  );
});
