import React, { useCallback, useEffect, useRef, useState } from "react";
import { errorCatcher } from "../util/errors";
import PlayerAvatar from "../util/playerAvatar";
import { sorareFootball } from "../util/sports";
import { withUser } from "../../userContext";
import { abbrv_football_positions } from "../util/positions";
import { detailedPositionAbreviations, detailedPositionFullName } from "../util/detailedFootballPositions";
import { PickScoreDetails } from "../util/pickScore";
import { isFree, t1OrBelow } from "../util/handleSubscriptionTier";
import SorareScoreAverage from "../decision/sorareScoreAverage";
import PlayerAvailability from "../players/playerAvailability";
import ScoreAverage from "../decision/scoreAverage";
import { PositionPicker } from "../util/positionPicker";
import Spinner, { Spinning } from "../loader/spinner";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { CommunityPredictionSummaryIndicator, PlayerCommunityProjectionWithHover } from "../util/communityPrediction";
import CirclePct from "../util/circlePct";
import OppScore from "../decision/oppScore";
import ordinal_suffix_of from "../util/ordinalNumber";
import HomeIcon from "../../img/homeIcon.svg";
import AwayIcon from "../../img/awayIcon.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as Circle } from "../../img/icons-circle.svg";
import { ReactTooltip } from "../util/tooltip.js";
import PriceIndicator from "../players/priceIndicator";
import LimitedPoint from "../../img/limited-point.png";
import RarePoint from "../../img/rare-point.png";
import SuperRarePoint from "../../img/sr-point.png";
import UniquePoint from "../../img/unique-point.png";
import { Range } from "rc-slider";
import GLOBAL_MARGIN from "../util/margin";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import AbortController from "abort-controller";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { getColorFromDivisionId } from "../util/divisions";
import { FormSearch } from "grommet-icons";
import GreyLockerIcon from "../../img/grey-locker.svg";
import { ScarcityDropdownPicker } from "../util/scarcityPicker";
import { scarcities_objects_not_all } from "../util/scarcities";
import { ReactComponent as IconCarretFilled } from "../../img/icons-carret-filled.svg";
import { sortData, withSortableProps } from "../util/sorting";
import { ReactComponent as HourGlass } from "../../img/icons-hourglass.svg";
import UnknownClub from "../../img/unknown_club.png";
import { searchText } from "../util/strings";
import { endOnboarding, getFirstEnabledOnboardingForPage, nextOnboarding, Onboarding } from "../onboarding/onboardingContext";
import {
  allStarDefaultCompetition,
  EligibilitySorareCompetitionsFilter,
  initialEligibilityCompetitionValue,
} from "../competitions/eligibilityCompetitionsPicker";

const PreferentialPositions = (props) => {
  const { cardPosition, detailedPositions } = props;
  const cardPositionAbreviation = abbrv_football_positions[cardPosition];
  const elems = [cardPositionAbreviation || "UKN"];
  let positionsAndCountMsg = "Player is considered as " + cardPosition;
  if (detailedPositions !== undefined && detailedPositions !== null && detailedPositions.length > 0) {
    const mainDetailedPosition = detailedPositions?.[0];
    const mainDetailedPositionAbbrv = mainDetailedPosition ? detailedPositionAbreviations[mainDetailedPosition] : undefined;
    const mainDetailedPositionFullName = mainDetailedPosition ? detailedPositionFullName[mainDetailedPosition] : undefined;
    if (mainDetailedPositionAbbrv !== undefined && mainDetailedPositionAbbrv !== cardPositionAbreviation) {
      elems.push(mainDetailedPositionAbbrv);
    }
    if (mainDetailedPositionFullName !== undefined) {
      positionsAndCountMsg += "; plays mainly as " + mainDetailedPositionFullName;
    }
  }
  return (
    <span className={"text-sm font-medium text-textGrey3"} title={positionsAndCountMsg}>
      {elems.filter((p) => p !== undefined && p !== "").join(" · ")}
    </span>
  );
};

const TableHeaderLine = (props) => {
  const { label, width, first, last, tip, sortable, sorted, onboarding } = props;
  return (
    <th
      title={tip}
      onClick={sortable ? props.onSort : undefined}
      className={`bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-center font-bold uppercase text-xs ${
        sortable ? "cursor-pointer hover:text-brand hover:text-opacity-90" : ""
      } ${width} ${first ? "rounded-tl-md" : ""} ${last ? "rounded-tr-md" : ""} ${sorted ? "text-brand" : "text-white"}`}
    >
      <div className={`flex flex-col gap-1 ${onboarding ? onboarding : ""}`}>
        <span>{label}</span>
        {sortable && sorted ? (
          <span className={"flex flex-row justify-center gap-2 items-center"}>
            <IconCarretFilled className={`h-3 w-3 fill-brand ${sorted == "asc" ? "transform rotate-180" : ""}`} />
          </span>
        ) : null}
      </div>
    </th>
  );
};

function GwPickScores(props) {
  const [pickScores, setPickScores] = React.useState(undefined);
  const [filteredPickScores, setFilteredPickScores] = React.useState(undefined);
  const [displayedScores, setDisplayedScores] = React.useState(undefined);
  const [positions, setPositions] = React.useState(["Forward"]);
  const [eligibilityCompetitions, setEligibilityCompetitions] = React.useState(initialEligibilityCompetitionValue);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [games, setGames] = React.useState([]);
  const [standings, setStandings] = React.useState([]);
  const [scarcity, setScarcity] = React.useState(props.user.preferredScarcity || "LIMITED");
  const [costFilter, setCostFilter] = React.useState([0, 100]);
  const [loadingPickScores, setLoadingPickScores] = React.useState(false);
  const [abortController, setAbortController] = React.useState(new AbortController());
  const [sort, setSort] = useState({
    field: "computed.pickScoreRank",
    order: "desc",
  });

  useEffect(() => {
    if (props.gwNumber > 0) {
      getPickScores();
    }
  }, [props.gwNumber]);

  useEffect(() => {
    if (filteredPickScores !== undefined) {
      const sorted = sortPickScores(sort, filteredPickScores);
      setFilteredPickScores(sorted);
      setDisplayedScores(sorted.slice(0, 50));
    }
  }, [sort]);

  const onClickSort = useCallback(
    (field) => {
      const order = sort.field === field ? (sort.order === "asc" ? "desc" : "asc") : "desc";
      const newSort = { field, order };
      setSort(newSort);
    },
    [sort],
  );

  const sortPickScores = (sort, pickScores) => {
    return sortData(sort, pickScores);
  };

  const getPickScores = (p, s, ep) => {
    p = p || positions;
    s = s || scarcity;
    ep = ep || eligibilityCompetitions || {};
    const params = new URLSearchParams();
    params.append("gw", props.gwNumber);
    params.append("scarcity", s);
    Object.keys(ep)
      .filter((k) => k !== allStarDefaultCompetition.id)
      .forEach((k) => params.append("competitionsEligible", k));
    p.forEach((pos) => params.append("position", pos));
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
    setLoadingPickScores(true);
    props
      .fetch("/apiv2/SO5/gwPickScores?" + params.toString(), {
        signal: newController.signal,
      })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.pickScores === null) {
          setPickScores([]);
          setDisplayedScores([]);
          setFilteredPickScores([]);
        } else {
          const newPickScores = res.pickScores.map((ps, i) => {
            return {
              ...ps,
              computed: {
                pickScoreRank: -1 * (i + 1), // to have AAA first in desc order
              },
            };
          });
          setPickScores(newPickScores);
          setGames(res.games);
          setStandings(res.standings);
          setScarcity(res.scarcity);
          filterScores(newPickScores, searchFilter);
        }
        setLoadingPickScores(false);
      })
      .catch(
        errorCatcher(() => {
          setLoadingPickScores(false);
        }),
      );
  };

  const filterScores = (data, s, cost) => {
    data = data || pickScores;
    cost = cost || costFilter;
    if (s === undefined) {
      s = searchFilter;
    }
    let filtered = [...data];
    filtered = filtered.filter((p) => {
      return searchText(s, p.player.DisplayName) && p.cost >= cost[0] && p.cost <= cost[1];
    });
    filtered = sortPickScores(sort, filtered);
    setFilteredPickScores(filtered);
    setDisplayedScores(filtered.slice(0, 50));
    ReactTooltip.rebuild();
  };

  if (!props.user.tier) {
    return null;
  }

  if (isFree(props.user.tier)) {
    return (
      <div className={GLOBAL_MARGIN + " space-y-4"}>
        <PlayerBlockedVideo
          title="Want more insights?"
          description="Become Star member to unlock pick scores for all divisions."
          videoLink="https://www.youtube.com/embed/cKnd0ZFeUQo"
        />
      </div>
    );
  }

  return (
    <div className={"flex flex-col gap-4"}>
      <ReactTooltip />
      {t1OrBelow(props.user.tier) && (
        <div className={" w-10/12 mx-auto"}>
          <UpgradeLimitBox
            className={"bg-white bg-upgrade-bg-pick-score-page bg-cover h-upgrade-banner-l w-full"}
            title={"Want more insights?"}
            description={"Become a Star member to unlock pick scores for all divisions."}
            video={{
              url: "https://www.youtube.com/watch?v=cKnd0ZFeUQo",
              msg: "Learn more about pick scores",
            }}
          />
        </div>
      )}
      <div className={"flex flew-row items-center justify-center w-10/12 mx-auto relative h-11"}>
        <div className={"flex flex-row gap-4 relative h-full items-center justify-center w-full"}>
          <div className={"z-20 h-full min-w-[20em]"}>
            <EligibilitySorareCompetitionsFilter
              minWidth={"min-w-[6rem]"}
              displayOptions={{ shadow: "shadow" }}
              selected={eligibilityCompetitions}
              onSelect={(v) => {
                let newLeagues = {};
                if (v === allStarDefaultCompetition.id) {
                  newLeagues = initialEligibilityCompetitionValue;
                } else {
                  newLeagues = { [v]: true };
                }
                setEligibilityCompetitions(newLeagues);
                getPickScores(undefined, undefined, newLeagues);
              }}
              multiple={false}
            />
          </div>
          <div className={"h-full"}>
            <PositionPicker
              positions={positions}
              changePositions={(p) => {
                getPickScores(p);
                setPositions(p);
              }}
            />
          </div>
          <div className={"w-2/12 flex flex-col h-full justify-center mx-2 gap-1"}>
            <p className={"space-x-2 text-sm text-textGrey3 font-medium"}>
              <span>
                Cost:{" "}
                <span className={"font-semibold text-brand-black"}>
                  {costFilter[0]}-{costFilter[1]}
                </span>
              </span>
            </p>
            <Range
              trackStyle={[
                {
                  backgroundColor: "#475bde",
                  border: "",
                },
              ]}
              handleStyle={[{ border: "solid 1px #475bde" }, { border: "solid 1px #475bde" }]}
              step={1}
              min={0}
              max={100}
              value={costFilter}
              onChange={(value) => {
                setCostFilter(value);
                filterScores(undefined, undefined, value);
              }}
            />
          </div>
          <div className={"relative rounded-md bg-white p-0 px-2 flex flex-row gap-1 w-2/12 h-full shadow-sm"}>
            <div className="flex items-center pointer-events-none">
              <FormSearch className="h-5 w-5" aria-hidden="true" />
            </div>
            {searchFilter && (
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => {
                  filterScores(undefined, "");
                  setSearchFilter("");
                }}
              >
                <img src={CloseOutlineIcon} className="opacity-80 h-4 w-4" aria-hidden="true" />
              </div>
            )}
            <input
              className={`w-full h-full block bg-transparent font-medium p-1 border-0 text-sm border-0 focus:ring-0 ${
                searchFilter !== "" ? "text-brand-black" : "text-grey-82"
              }`}
              type={"text"}
              placeholder="Search player"
              value={searchFilter}
              onChange={(e) => {
                filterScores(undefined, e.target.value);
                setSearchFilter(e.target.value);
              }}
            />
          </div>
          <div className={"z-20 h-full"}>
            <ScarcityDropdownPicker
              scarcity={scarcity}
              allowedScarcities={scarcities_objects_not_all}
              onChange={(v) => {
                getPickScores(undefined, v.value);
                setScarcity(v.value);
              }}
            />
          </div>
        </div>
        <div className={"absolute right-0 translate-x-full flex flex-row items-center"}>
          {loadingPickScores && displayedScores !== undefined && <Spinner />}
        </div>
      </div>
      <div className={"w-10/12 mx-auto"}>
        <InfiniteScroll
          dataLength={displayedScores?.length || 0}
          style={{ overflowY: "hidden" }}
          className={""}
          hasMore={displayedScores !== undefined && displayedScores.length > 0}
          next={() => {
            setDisplayedScores(filteredPickScores.slice(0, displayedScores.length + 50));
          }}
          loader={displayedScores !== undefined && displayedScores.length < filteredPickScores?.length && <SDLoading />}
        >
          <table className={"border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap"}>
            <thead>
              <tr>
                <TableHeaderLine first width={"w-3/12"} label={"Player"} />
                <TableHeaderLine width={"w-1/12"} label={"Opp."} />
                <TableHeaderLine width={"w-1/24"} label={"Cost"} {...withSortableProps("cost", onClickSort, sort)} />
                <TableHeaderLine
                  width={"w-1/12"}
                  label={"Pick Score"}
                  {...withSortableProps("computed.pickScoreRank", onClickSort, sort)}
                />
                <TableHeaderLine width={"w-1/24"} label={"L5"} {...withSortableProps("l5", onClickSort, sort)} />
                <TableHeaderLine width={"w-1/24"} label={"L15"} {...withSortableProps("l15", onClickSort, sort)} />
                <TableHeaderLine width={"w-1/18"} label={"Opp. score"} {...withSortableProps("oppA", onClickSort, sort)} />
                <TableHeaderLine width={"w-1/18"} label={"% win"} {...withSortableProps("oddWin", onClickSort, sort)} />
                <TableHeaderLine width={"w-1/18"} label={"% CS"} {...withSortableProps("oddCS", onClickSort, sort)} />
                <TableHeaderLine width={"w-1/18"} label={"% 3GA"} {...withSortableProps("odd3GA", onClickSort, sort)} />
                <TableHeaderLine width={"w-1/18"} label={"% 3GS"} {...withSortableProps("odd3GS", onClickSort, sort)} />
                <TableHeaderLine width={"w-1/12"} label={"Floor price"} {...withSortableProps("bmp.Price", onClickSort, sort)} />
                <TableHeaderLine
                  last
                  width={"w-1.5/12"}
                  label={"Latest valuation"}
                  {...withSortableProps("rt.Average", onClickSort, sort)}
                />
              </tr>
            </thead>
            <tbody>
              {displayedScores?.map((pickScore) => {
                let player = pickScore.player;

                let opponent = games[pickScore.teamId]?.home_team_id;
                let oppLogo = games[pickScore.teamId]?.home_team_logo;
                let oppStandings = standings[opponent + games[pickScore.teamId]?.game.CompSlug]?.rank;
                let oppName = games[pickScore.teamId]?.home_team;
                if (games[pickScore.teamId]?.home_team_id === pickScore.teamId) {
                  opponent = games[pickScore.teamId]?.away_team_id;
                  oppLogo = games[pickScore.teamId]?.away_team_logo;
                  oppName = games[pickScore.teamId]?.away_team;
                  oppStandings = standings[opponent + games[pickScore.teamId]?.game.CompSlug]?.rank;
                }
                if (oppStandings === undefined) {
                  oppStandings = "-";
                } else {
                  oppStandings = ordinal_suffix_of(oppStandings);
                }
                let point = LimitedPoint;
                if (pickScore.bmp.Scarcity === "RARE") {
                  point = RarePoint;
                } else if (pickScore.bmp.Scarcity === "SUPER RARE") {
                  point = SuperRarePoint;
                } else if (pickScore.bmp.Scarcity === "UNIQUE") {
                  point = UniquePoint;
                }

                return (
                  <tr>
                    <td>
                      <div className={"flex flex-row space-x-2"}>
                        <PlayerAvatar player={pickScore.player} />
                        <div className={"self-center flex flex-col space-y-0.5"}>
                          <div className={"flex flex-row space-x-1.5 text-sm font-semibold"}>
                            <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                              <p className={"text-left hover:font-bold"}>{pickScore.player.DisplayName}</p>
                            </a>
                            <div className={"h-5 w-5 self-center"}>
                              <a href={"/team/" + player.TeamId} target={"_blank"} rel="noreferrer">
                                <img className={"object-contain h-5 w-5"} src={pickScore.teamLogo} />
                              </a>
                            </div>
                            <div className={"self-center w-4 h-4"}>
                              <PlayerAvailability availability={pickScore.player_status} />
                            </div>
                          </div>
                          <div className={"flex flex-row space-x-2"}>
                            <PreferentialPositions cardPosition={pickScore.pickScore.position} detailedPositions={pickScore.positions} />
                            <PlayerCommunityProjectionWithHover
                              projection={pickScore.projection}
                              playerTeamId={player.TeamId}
                              sport={sorareFootball}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col space-y-1.5 items-center"}>
                        <div className={"w-6 h-6"}>
                          <a href={"/team/" + opponent} target={"_blank"} rel="noreferrer">
                            <img src={oppLogo ? oppLogo : UnknownClub} data-tip={oppName} className={"w-full h-full object-contain"} />
                          </a>
                        </div>
                        <div className={"flex flex-row space-x-1 justify-center"}>
                          {oppStandings !== "-" && <p className={"text-xs self-center font-semibold"}>{oppStandings}</p>}
                          <img className={"h-4 w-4"} src={pickScore.field === "home" ? HomeIcon : AwayIcon} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center items-center"}>
                        <div className={"w-10 h-10"}>
                          <SorareScoreAverage avg={pickScore.cost} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <PickScoreDetails
                        main={pickScore.pickScore.score}
                        revealInfo={{ authorized: true, max: null, current: 0 }}
                        detailed={pickScore.pickScore.Radar}
                      />
                    </td>
                    <td>
                      <div className={"py-2 self-center"}>
                        <ScoreAverage avg={pickScore.l5} period={5} played={pickScore.gms5} sAvg={pickScore.l5} sApps={pickScore.gms5} />
                      </div>
                    </td>
                    <td>
                      <div className={"py-2 self-center"}>
                        <ScoreAverage
                          avg={pickScore.l15}
                          period={15}
                          played={pickScore.gms15}
                          sAvg={pickScore.l15}
                          sApps={pickScore.gms15}
                        />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <OppScore score={pickScore.oppA} />
                      </div>
                    </td>

                    <td className={"text-center"}>
                      <div className={"text-center flex flex-row justify-center self-center"}>
                        <CirclePct value={pickScore.oddWin} odds={false} />
                      </div>
                    </td>
                    <td className={"text-center"}>
                      <div className={"text-center flex flex-row justify-center self-center"}>
                        <CirclePct value={pickScore.oddCS} odds={false} />
                      </div>
                    </td>
                    <td className={"text-center"}>
                      <div className={"text-center flex flex-row justify-center self-center"}>
                        <CirclePct value={pickScore.odd3GA} odds={false} />
                      </div>
                    </td>
                    <td className={"text-center"}>
                      <div className={"text-center flex flex-row justify-center self-center"}>
                        <CirclePct value={pickScore.odd3GS} odds={false} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <PriceIndicator
                          scarcity={pickScore.bmp.Scarcity?.toLowerCase().replace(" ", "-")}
                          avg={pickScore.rt}
                          bmp={pickScore.bmp}
                          user={props.user}
                          showValo={false}
                          showBmp={true}
                          icon={point}
                        />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <PriceIndicator
                          scarcity={pickScore.rt.Scarcity?.toLowerCase().replace(" ", "-")}
                          avg={pickScore.rt}
                          bmp={pickScore.bmp}
                          user={props.user}
                          showValo={true}
                          icon={point}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
        {displayedScores === undefined && loadingPickScores && (
          <div className={"p-4 flex justify-center items-center bg-grey-f2"}>
            <div className={"flex flex-col gap-3 justify-center items-center"}>
              <SDLoading />
            </div>
          </div>
        )}
        {displayedScores !== undefined && displayedScores.length === 0 && (
          <div className={"p-4 flex justify-center items-center"}>
            <div className={"flex flex-col gap-3 justify-center items-center"}>
              <HourGlass className="fill-textGrey3 h-8 w-8" />
              <p className={"text-md text-textGrey3 font-semibold"}>No pick score yet for this gameweek. Please check back later.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(GwPickScores);
