import clsx from "clsx";
import React from "react";

export const SegmentedButton = ({ className, selected, onFormChange, options }) => {
  const pickedStyle = "text-xs sm:text-sm text-primary self-center";
  const notPickedStyle = "text-xs sm:text-sm text-on-surface-variant self-center";
  return (
    <div className={clsx(className, "w-full")}>
      <div className="flex flex-row justify-center select-none">
        <div className="font-headers font-semibold bg-white flex flex-row gap-3 justify-evenly rounded-full text-base items-center  w-full px-4">
          {options.map(({ value, name, Icon }, index) => (
            <React.Fragment key={value}>
              <div
                className="flex items-center justify-center cursor-pointer w-full h-full text-center px-2 py-2.5"
                onClick={() => onFormChange(value)}
              >
                <p className={selected === value ? pickedStyle : notPickedStyle}>{name}</p>
              </div>
              {index < options.length - 1 && <div className="!h-5 bg-outline-variant w-px flex-shrink-0 rounded-full" />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
