import { useElementSize } from "usehooks-ts";
import React, { useEffect, useState } from "react";

const SorareCostIcon = (props) => {
  const { negative, style } = props;
  return (
    <svg width={style.width} height={style.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.73205C10.8564 0.660254 13.1436 0.660254 15 1.73205L19.3923 4.26795C21.2487 5.33975 22.3923 7.32051 22.3923 9.4641V14.5359C22.3923 16.6795 21.2487 18.6603 19.3923 19.7321L15 22.268C13.1436 23.3397 10.8564 23.3397 9 22.2679L4.6077 19.732C2.75129 18.6603 1.6077 16.6795 1.6077 14.5359V9.4641C1.6077 7.32051 2.75129 5.33975 4.6077 4.26795L9 1.73205Z"
        fill={`${negative ? "#E73107" : "url(#paint0_linear_0_3)"}`}
      />
      {(negative == undefined || negative === false) && (
        <defs>
          <linearGradient id="paint0_linear_0_3" x1="5.57576" y1="3.39394" x2="20.6061" y2="18.9091" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2F42C5" />
            <stop offset="1" stopColor="#5D72F6" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};

const SorareScoreAverage = ({ avg, negative, fontSize, dontNormalize }) => {
  let fixedAvg;
  if (avg !== undefined && avg !== null && (dontNormalize || avg != -1)) {
    fixedAvg = avg.toFixed(0);
  } else {
    fixedAvg = 0;
  }
  const fontSizeRatio = fontSize === "lg" ? 2.5 : 2.8;
  const [refContainer, { width, height }] = useElementSize();
  const [size, setSize] = useState(0);

  useEffect(() => {
    const newSize = [width, height].reduce((min, cur) => (cur != 0 ? (cur < min ? cur : min) : min), width); // keep smallest dimension so it stays in the container (but avoid 0 value)
    setSize(newSize);
  }, [width, height]);

  return (
    <div ref={refContainer} className={"relative w-full h-full flex items-center justify-center"}>
      <SorareCostIcon style={{ width: size, height: size }} negative={negative} />
      <div
        className={`absolute text-white font-semibold`}
        style={{
          fontSize: size / fontSizeRatio,
          margin: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
        }}
      >
        <span className={"text-center"}>{fixedAvg}</span>
      </div>
    </div>
  );
};

export default SorareScoreAverage;
