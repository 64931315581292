import React from "react";
import GameLivePageHeader from "./gameLivePageHeader";
import UnknownClub from "../../img/unknown_club.png";

function GameTeamStatsLine(props) {
  const label = props.label;
  const unit = props.unit;

  if (!(props.homeStats && props.awayStats)) {
    return null;
  }

  let homeStats = props.homeStats[props.statKey];
  let awayStats = props.awayStats[props.statKey];

  if (!(homeStats && awayStats)) {
    return null;
  }

  const statStyle = "text-base font-bold self-end";
  let homePct = (homeStats * 100) / (homeStats + awayStats).toFixed(0) + "%";
  let awayPct = (100 - (homeStats * 100) / (homeStats + awayStats)).toFixed(0) + "%";
  let homeRounded = "rounded-l-xl";
  let awayRounded = "rounded-r-xl";
  let spacing = "space-x-1";
  if (Number(awayStats) === 0 && Number(homeStats === 0)) {
    homePct = "50%";
    awayPct = "50%";
  } else {
    if (Number(awayStats) === 0) {
      homeRounded = "rounded-xl";
      homePct = "100%";
      awayPct = "0%";
      spacing = "";
    }
    if (Number(homeStats) === 0) {
      awayRounded = "rounded-xl";
      homePct = "0%";
      awayPct = "100%";
      spacing = "";
    }
  }

  if (unit === "%") {
    homeStats = parseFloat(homeStats).toFixed(0);
    awayStats = parseFloat(awayStats).toFixed(0);
  }
  return (
    <div className={"flex flex-col space-y-1"}>
      <div className={"flex flex-row " + spacing}>
        <div className={"w-2/12 flex justify-start"}>
          <p className={statStyle}>
            {homeStats}
            {unit}
          </p>
        </div>
        <div className={"w-8/12 self-center flex flex-row justify-center"}>
          <p className={"uppercase text-textGrey3 text-xs font-semibold"}>{label}</p>
        </div>
        <div className={"w-2/12 flex justify-end"}>
          <p className={statStyle}>
            {awayStats}
            {unit}
          </p>
        </div>
      </div>
      <div className={"flex flex-row " + spacing}>
        <div style={{ width: homePct }} className={"h-3 bg-brand-green " + homeRounded}></div>
        <div style={{ width: awayPct }} className={"h-3 rounded-r-lg bg-brand " + awayRounded}></div>
      </div>
    </div>
  );
}

function GameTeamStats(props) {
  const stats = props.stats;
  const homeTeam = props.homeTeam;
  const awayTeam = props.awayTeam;
  let homeStats;
  let awayStats;
  if (stats) {
    stats.map((s) => {
      if (s.team_id === homeTeam.TeamId) homeStats = s;
      else awayStats = s;
    });
    return (
      <div className={"space-y-4"}>
        <GameLivePageHeader label={"Team stats"} />
        <div className={"bg-white rounded-lg space-y-4 p-6"}>
          <div className={"flex flex-row justify-between"}>
            <div className={"flex flex-row justify-start"}>
              <div className={"w-10 h-10"}>
                <img className={"w-full h-full object-contain"} src={homeTeam.PictureUrl !== "" ? homeTeam.PictureUrl : UnknownClub} />
              </div>
            </div>
            <div className={"flex flex-row justify-end"}>
              <div className={"w-10 h-10"}>
                <img className={"w-full h-full object-contain"} src={awayTeam.PictureUrl !== "" ? awayTeam.PictureUrl : UnknownClub} />
              </div>
            </div>
          </div>
          <GameTeamStatsLine label={"Possession"} unit={"%"} statKey={"possession"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Total shots"} unit={""} statKey={"shots"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Big chances"} unit={""} statKey={"big_chances"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Shots on target"} unit={""} statKey={"shots_on_target"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Pass accuracy"} unit={"%"} statKey={"pass_pct"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Ball touches"} unit={""} statKey={"touches"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Duels won"} unit={"%"} statKey={"duel_won_pct"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Fouls"} unit={""} statKey={"fouls"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Corners"} unit={""} statKey={"corners"} awayStats={awayStats} homeStats={homeStats} />
          <GameTeamStatsLine label={"Offsides"} unit={""} statKey={"offsides"} awayStats={awayStats} homeStats={homeStats} />
        </div>
      </div>
    );
  } else return null;
}

export default GameTeamStats;
