import React, { useState } from "react";
import ScoreEllipse from "../util/scoreEllipse";
import PlayerScoreModal from "../players/playerScoreModal";
import GoalIcon from "../../img/ICN_Ballon.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import PenaltySave from "../../img/PenaltySave.svg";
import CleanSheet from "../../img/cleansheet.svg";
import UnknownPlayer from "../../img/unknown_player.png";
import GameLivePageHeader from "./gameLivePageHeader";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { withUser } from "../../userContext";
import UpgradeLimitBox from "../util/upgradeLimitBox";

function GameMyPlayers(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayStats, setOverlayStats] = useState({});
  const [overlayLineups, setOverlayLineups] = useState({});

  const homePlayers = props.homeTeam.positions;
  const awayPlayers = props.awayTeam.positions;
  let allPlayers = [];
  let allOwnedPlayers = [];
  if (homePlayers) {
    if (homePlayers.lineup !== null) {
      homePlayers.lineup.map((l) => {
        allPlayers = allPlayers.concat(l);
      });
    }
    if (homePlayers.substitutes !== null) {
      allPlayers = allPlayers.concat(homePlayers.substitutes);
    }
    if (homePlayers.bench !== null) {
      allPlayers = allPlayers.concat(homePlayers.bench);
    }
    if (homePlayers.out !== null) {
      allPlayers = allPlayers.concat(homePlayers.out);
    }
  }
  if (awayPlayers) {
    if (awayPlayers.lineup !== null) {
      awayPlayers.lineup.map((l) => {
        allPlayers = allPlayers.concat(l);
      });
    }
    if (awayPlayers.substitutes !== null) {
      allPlayers = allPlayers.concat(awayPlayers.substitutes);
    }
    if (awayPlayers.bench !== null) {
      allPlayers = allPlayers.concat(awayPlayers.bench);
    }
    if (awayPlayers.out !== null) {
      allPlayers = allPlayers.concat(awayPlayers.out);
    }
  }
  allPlayers.map((p) => {
    if (p.manager_details.in_gallery) {
      allOwnedPlayers = allOwnedPlayers.concat(p);
    }
  });
  allOwnedPlayers.sort(function (a, b) {
    if (a.manager_details?.lineups !== null && b.manager_details?.lineups !== null) {
      if (a.SO5Score > b.SO5Score) {
        return -1;
      } else {
        return 1;
      }
    } else {
      if (a.manager_details?.lineups !== null) {
        return -1;
      } else if (b.manager_details?.lineups !== null) {
        return 1;
      } else {
        if (a.SO5Score > b.SO5Score) {
          return -1;
        } else {
          return 1;
        }
      }
    }
  });
  allPlayers.sort(function (a, b) {
    if (a.SO5Score > b.SO5Score) {
      return -1;
    } else {
      return 1;
    }
  });

  const game = props.game;

  const displayBox = (p, g) => {
    let background = "bg-white";
    if (p.manager_details?.lineups !== null && p.manager_details?.lineups !== undefined) {
      background = "bg-player-halo";
    } else if (p.manager_details?.in_gallery) {
      background = "bg-grey-e0";
    }
    let icons = [];
    let stats = p;
    let divStyle = "w-5 h-5 self-center rounded-full";
    let imgStyle = "w-full h-full object-contain shadow rounded-full";
    for (let i = 0; i < stats.Goals; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={GoalIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AdjustedGoalAssist; i++) {
      let left = icons.length * 2;
      let z = 10 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={AssistIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AssistPenaltyWon; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={Whistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ClearanceOffLine + stats.LastManTackle; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={AngelBall} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltySaves + stats.PenaltySave; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={PenaltySave} />
        </div>,
      );
    }
    for (let i = 0; i < stats.RedCard; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedCard} />
        </div>,
      );
    }
    for (let i = 0; i < stats.OwnGoal; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={OwnGoal} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltyConceded; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedWhistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ErrorLeadToGoal; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedShoe} />
        </div>,
      );
    }
    if (stats.Position === "Goalkeeper") {
      for (let i = 0; i < stats.CleanSheet; i++) {
        let left = icons.length * 2;
        let z = 100 + icons.length;
        icons.push(
          <div className={divStyle}>
            <img className={imgStyle} src={CleanSheet} />
          </div>,
        );
      }
    }
    return (
      <div
        className={"relative flex flex-col"}
        onClick={() => {
          setOverlayStats(p);
          setOverlayLineups(p.manager_details);
          setShowOverlay(true);
        }}
      >
        <div className={"flex flex-col items-center -mb-4 z-10"}>
          <div className={"w-16 h-16 flex flex-row justify-center rounded-full self-end mx-auto border-2 border-white " + background}>
            <img className={"pt-1.5 w-14 h-14 object-contain z-0 rounded-full self-end"} src={p.Avatar !== "" ? p.Avatar : UnknownPlayer} />
          </div>
        </div>
        <div className={"space-y-2 bg-white rounded-xl px-4 pt-6 pb-4 relative"}>
          <div className={"space-y-1"}>
            <p className={"text-sm font-semibold text-center"}>{p.MatchName}</p>
            <p className={"text-xs font-medium text-textGrey3 text-center"}>
              {p.Position} - {p.Age}
            </p>
          </div>
          <div className={"w-14 flex flex-row justify-center mx-auto"}>
            <ScoreEllipse
              score={p.SO5Score}
              minsPlayed={p.mins_played}
              size={"text-sm"}
              formationPlace={p.FormationPlace}
              power={1}
              onGameSheet={p.OnGameSheet}
              game={g}
            />
          </div>
        </div>
        <div className={"items-center flex flex-col -mt-2 z-10"}>
          <div className={"flex flex-row justify-center mx-auto z-10"}>{icons}</div>
        </div>
      </div>
    );
  };
  return (
    <div className={"space-y-6"}>
      <PlayerScoreModal open={showOverlay} stats={overlayStats} lineups={overlayLineups} close={() => setShowOverlay(false)} game={game} />
      {allOwnedPlayers.length > 0 && t1OrAbove(props.user.tier) && (
        <div className={"space-y-4"}>
          <GameLivePageHeader label={"My players"} />
          <div className={"grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 4xl:grid-cols-6 gap-4 rounded-lg cursor-pointer"}>
            {allOwnedPlayers.map((p) => {
              return displayBox(p, game.game);
            })}
          </div>
        </div>
      )}
      {!t1OrAbove(props.user.tier) && (
        <div className={"space-y-4"}>
          <UpgradeLimitBox
            className={"bg-white bg-upgrade-bg-my-players bg-cover h-upgrade-banner-s w-full"}
            title={"Want more insights?"}
            description={"Become a Star member to unlock My players."}
          />
        </div>
      )}
      {allPlayers.length > 0 && props.game.HasLineups && (
        <div className={"space-y-4"}>
          <GameLivePageHeader label={"Top scorers of the game"} />
          <div className={"grid lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 rounded-lg cursor-pointer"}>
            {allPlayers.map((p, i) => {
              if (i <= 4) {
                return displayBox(p, game.game);
              } else return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default withUser(GameMyPlayers);
