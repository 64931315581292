import React, { useState } from "react";
import Bg from "../../img/background-landing.png";
import LogoLanding from "../../img/logo-landing.svg";
import { Button } from "../util/button";
import GoogleLanding from "../../img/google-landing.svg";
import iOSLanding from "../../img/ios-landing.svg";
import LandingScreen from "../../img/landing-screen.png";
import LandingScreen2 from "../../img/landing-screen-2.png";
import LandingScreen3 from "../../img/landing-screen-3.png";
import LandingScreen4 from "../../img/landing-screen-4.png";
import BuildLineups from "../../img/build-lineups-landing.png";
import MobileBg from "../../img/mobile-landing-bg.png";
import LiveAction from "../../img/live-action-landing.png";
import Tools from "../../img/tools-landing.png";
import Bulb from "../util/bulb";
import Eye from "../util/eye";
import BarChartIcon from "../util/barChart";
import MobileVisuel from "../../img/mobile-landing-visuel.png";
import WhiteLogo from "../../img/sd-logo-white.svg";
import { Cursor, useTypewriter } from "react-simple-typewriter";

function LandingPage(props) {
  const [activeBox, setActiveBox] = useState("lineups");

  let activeBoxClass = "border-2 border-green-landing rounded-lg space-y-4 p-6 cursor-pointer";
  let inactiveBoxClass = "border-2 border-grey-e5 rounded-lg space-y-4 p-6 cursor-pointer";

  const { text } = useTypewriter({
    words: ["football", "baseball", "basketball", "sports"],
    loop: 1, // Infinit
  });

  return (
    <div className={"bg-white"}>
      <div
        className={"pt-8"}
        style={{
          backgroundImage: "url('" + Bg + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={"flex flex-col space-y-8"}>
          <div className={"flex flex-row justify-between lg:mx-24 4xl:w-10/12 4xl:mx-auto mx-6"}>
            <div>
              <img src={LogoLanding} />
            </div>
            <div className={"flex flex-row space-x-4"}>
              <a href={"/login"}>
                <Button label={"Sign in"} />
              </a>
              <a href={"/login"}>
                <Button context={"neutral"} label={"Sign up"} />
              </a>
            </div>
          </div>
          <div className={"flex flex-col w-10/12 xl:w-9/12 2xl:w-7/12 2.5xl:w-6.5/12 mx-auto space-y-12"}>
            <div className={"space-y-8"}>
              <p className={"text-white text-4xl xl:text-5xl xl:text-6xl text-center font-headers font-bold leading-tight"}>
                Take your fantasy{" "}
                <span
                  style={text !== "sports" ? { background: "rgba(255, 255, 255, .3)" } : undefined}
                  className={text !== "sports" ? "rounded-lg px-2" : ""}
                >
                  {text}
                  {text !== "sports" && <Cursor />}
                </span>{" "}
                experience to the next level
              </p>
              <p className={"text-white text-base lg:text-xl font-medium text-center"}>
                Get real-time player analytics and market data designed for managers of all levels
              </p>
            </div>
            <div className={"flex flex-col items-center space-y-8"}>
              <div className={"flex flex-row justify-center"}>
                <a href={"/login"}>
                  <button
                    type="button"
                    {...props}
                    className="inline-flex items-center px-6 py-3 border border-brand text-base font-semibold rounded-full
                                        shadow-sm text-white bg-brand hover:bg-brand-light hover:border-brand-light focus:outline-none focus:ring-0"
                  >
                    <div className={"flex flex-row space-x-3"}>
                      <div className={"h-6"}>
                        <img src={WhiteLogo} className={"w-full h-full object-contain"} />
                      </div>
                      <p className={"font-bold self-center"}>Become a better manager</p>
                    </div>
                  </button>
                </a>
              </div>
              <div className={"flex flex-row space-x-4 justify-center"}>
                <div className={"w-36 h-12"}>
                  <a target={"_blank"} href={"https://play.google.com/store/apps/details?id=com.soraredata"} rel="noreferrer">
                    <img className={"w-full h-full object-contain"} src={GoogleLanding} />
                  </a>
                </div>
                <div className={"w-36 h-12"}>
                  <a target={"_blank"} href={"https://apps.apple.com/us/app/soraredata/id1590662758"} rel="noreferrer">
                    <img className={"w-full h-full object-contain"} src={iOSLanding} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={"flex flex-col w-9/12 mx-auto items-center"}>
            <div>
              <img src={LandingScreen} />
            </div>
          </div>
        </div>
      </div>
      <div className={"z-10 py-4 lg:py-16 bg-white"}>
        <div className={"flex flex-col lg:flex-row lg:space-x-16 space-y-8 lg:space-y-0 mx-auto w-10/12 lg:mx-24 4xl:w-10/12 4xl:mx-auto "}>
          <div className={"lg:w-6/12 pt-8 space-y-8 lg:space-y-16 self-center"}>
            <div className={"space-y-4"}>
              <p style={{ color: "#10AC98" }} className={"text-base text-left font-bold"}>
                SCOUT
              </p>
              <p className={"font-headers text-4xl font-bold text-brand-black"}>The tools you need</p>
            </div>
            <div className={"flex flex-col space-y-8"}>
              <div className={"w-10/12 lg:w-9/12 space-y-2"}>
                <p className={"text-xl font-semibold font-headers"}>Detailed statistics</p>
                <p className={"text-lg font-medium text-textGrey3"}>
                  Browse official statistics and injury information, and get real-time match and player alerts.
                </p>
              </div>

              <div className={"w-10/12 lg:w-9/12 space-y-2"}>
                <p className={"text-xl font-semibold font-headers"}>Research tools</p>
                <p className={"text-lg font-medium text-textGrey3"}>
                  Create custom watchlists to monitor player scores and market prices, track other managers, and scout the players that will
                  drive you to success.
                </p>
              </div>
            </div>
          </div>
          <div className={"lg:w-6/12 self-center"}>
            <img src={LandingScreen2} />
          </div>
        </div>
      </div>
      <div className={"bg-white py-4 lg:py-16 space-y-12"}>
        <div className={"space-y-4"}>
          <p style={{ color: "#10AC98" }} className={"text-base font-bold text-center"}>
            PLAY
          </p>
          <p className={"font-headers text-4xl text-center font-bold text-brand-black"}>Become more competitive</p>
        </div>
        <div className={"grid grid-cols-1 lg:grid-cols-3 w-10/12 4xl:w-8/12 mx-auto gap-y-4 lg:gap-x-8"}>
          <div
            className={activeBox === "lineups" ? activeBoxClass : inactiveBoxClass}
            onClick={() => setActiveBox("lineups")}
            style={
              activeBox === "lineups"
                ? {
                    background: "linear-gradient(71.12deg, #FFFFFF 28.25%, #87FFDB 250.49%)",
                  }
                : undefined
            }
          >
            <div>
              <Bulb color={activeBox === "lineups" ? "#10AC98" : undefined} />
            </div>
            <p className={"text-lg font-semibold font-headers"}>Build better lineups</p>
            <p className={"text-base font-medium text-textGrey3"}>
              Use our lineup builder to leverage data insights including form, matchup assessments and winning probabilities to create more
              competitive lineups.
            </p>
          </div>
          <div
            className={activeBox === "live" ? activeBoxClass : inactiveBoxClass}
            onClick={() => setActiveBox("live")}
            style={
              activeBox === "live"
                ? {
                    background: "linear-gradient(71.12deg, #FFFFFF 28.25%, #87FFDB 250.49%)",
                  }
                : undefined
            }
          >
            <div>
              <Eye color={activeBox === "live" ? "#10AC98" : undefined} />
            </div>
            <p className={"text-lg font-semibold font-headers"}>Follow live action</p>
            <p className={"text-base font-medium text-textGrey3"}>Get real-time official stats and be alerted when major moments happen.</p>
          </div>
          <div
            className={activeBox === "tools" ? activeBoxClass : inactiveBoxClass}
            onClick={() => setActiveBox("tools")}
            style={
              activeBox === "tools"
                ? {
                    background: "linear-gradient(71.12deg, #FFFFFF 28.25%, #87FFDB 250.49%)",
                  }
                : undefined
            }
          >
            <div>
              <BarChartIcon color={activeBox === "tools" ? "#10AC98" : undefined} />
            </div>
            <p className={"text-lg font-semibold font-headers"}>Proven tools</p>
            <p className={"text-base font-medium text-textGrey3"}>
              80 percent of active Sorare managers use SorareData, including 86 percent of all reward winners.
            </p>
          </div>
        </div>
        <div className={"mx-auto"}>
          {activeBox === "lineups" && (
            <div>
              <img src={BuildLineups} />
            </div>
          )}
          {activeBox === "live" && (
            <div className={"pt-8"}>
              <img src={LiveAction} />
            </div>
          )}
          {activeBox === "tools" && (
            <div className={"pt-8"}>
              <img src={Tools} />
            </div>
          )}
        </div>
      </div>
      <div className={"z-10 py-4 lg:py-16 bg-white"}>
        <div className={"flex flex-col lg:flex-row lg:space-x-16 lg:mx-24 4xl:w-10/12 4xl:mx-auto  w-10/12 mx-auto"}>
          <div className={"lg:w-6/12 self-center"}>
            <img src={LandingScreen3} />
          </div>
          <div className={"lg:w-6/12 pt-8 space-y-8 lg:space-y-16 self-center"}>
            <div className={"space-y-4"}>
              <p style={{ color: "#10AC98" }} className={"text-base text-left font-bold"}>
                MANAGE
              </p>
              <p className={"font-headers text-4xl font-bold text-brand-black"}>Enhanced gallery tracking</p>
            </div>
            <div className={"flex flex-col space-y-8"}>
              <div className={"w-10/12 lg:w-9/12 space-y-2"}>
                <p className={"text-xl font-semibold font-headers"}>Key performance metrics</p>
                <p className={"text-lg font-medium text-textGrey3"}>
                  From valuation evolution to historical rewards stats, measure your success as a fantasy player.
                </p>
              </div>

              <div className={"w-10/12 lg:w-9/12 space-y-2"}>
                <p className={"text-xl font-semibold font-headers"}>Portfolio overview</p>
                <p className={"text-lg font-medium text-textGrey3"}>
                  Monitor your assets with comprehensive tools using detailed statistics, form, injuries, and upcoming matchups.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"z-10 pb-4 lg:pb-16 bg-white"}>
        <div className={"flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-16 "}>
          <div className={"lg:w-6/12 pt-16 space-y-8 lg:space-y-16 self-baseline lg:ml-24 w-10/12 mx-auto 3xl:self-center"}>
            <div className={"space-y-4"}>
              <p style={{ color: "#10AC98" }} className={"text-base text-left font-bold"}>
                MARKET
              </p>
              <p className={"font-headers text-4xl font-bold text-brand-black"}>Detailed market data</p>
            </div>
            <div className={"flex flex-col space-y-8"}>
              <div className={"w-10/12 lg:w-9/12 space-y-2"}>
                <p className={"text-xl font-semibold font-headers"}>Detailed statistics</p>
                <p className={"text-lg font-medium text-textGrey3"}>
                  Through transactions history, price alerts and comprehensive graphs, analyze every single data point to own the market.
                </p>
              </div>

              <div className={"w-10/12 lg:w-9/12 space-y-2"}>
                <p className={"text-xl font-semibold font-headers"}>Trends & charts</p>
                <p className={"text-lg font-medium text-textGrey3"}>
                  Stay up-to-date with market trends and dynamics to always be ahead of the curve.
                </p>
              </div>
            </div>
          </div>
          <div className={"lg:w-6/12 self-center"}>
            <img src={LandingScreen4} />
          </div>
        </div>
      </div>
      <div className={"lg:flex grid grid-cols-1 grid-rows-2 lg:flex-row w-full p-0 m-0"}>
        <div
          className={"w-full lg:w-6/12 h-full font-headers text-white px-16 py-16 lg:py-24 flex flex-col space-y-12"}
          style={{
            backgroundImage: "url('" + MobileBg + "')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className={"flex flex-col space-y-4"}>
            <p className={"font-medium text-4xl"}>Experience SorareData</p>
            <p className={"font-semibold text-4xl"}>everywhere</p>
          </div>
          <div className={"flex flex-col space-y-4"}>
            <p className={"text-xl font-medium text-white"}>AVAILABLE ON</p>
            <div className={"flex flex-row space-x-4 w-full"}>
              <div className={"w-48"}>
                <a target={"_blank"} href={"https://play.google.com/store/apps/details?id=com.soraredata"} rel="noreferrer">
                  <img className={"w-full h-full object-contain"} src={GoogleLanding} />
                </a>
              </div>
              <div className={"w-48"}>
                <a target={"_blank"} href={"https://apps.apple.com/us/app/soraredata/id1590662758"} rel="noreferrer">
                  <img className={"w-full h-full object-contain"} src={iOSLanding} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"w-full h-full lg:h-auto lg:w-6/12"}
          style={{
            backgroundImage: "url('" + MobileVisuel + "')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
}

export default LandingPage;
