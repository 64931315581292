import React, { useCallback } from "react";
import { ReactComponent as TuneIcon } from "../../img/icons-tune.svg";
import { XIcon } from "@heroicons/react/outline";
import { ReactComponent as ResetIcon } from "../../img/icons-reset.svg";
import BoxWithToggle from "../util/boxWithToggle";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { MobileModale } from "../util/mobileModale";
import { useTailwindMediaQueries } from "../util/mediaQueries";

export const defaultFootballLUBPreferences = {
  competitionAdjustedScore: false,
  teamAdjustedScore: false,
  homeAwayAdjustedScore: false,
  powerAdjustedScore: false,
  // pickScore: "global",
  showL15Scores: false,
  showPickedPlayers: false,
  showCost: false,
  showAffordablePlayersOnly: false,
  highlightNewSeason: true,
  customDivisionOrder: false,
  darkMode: false,
  ignoreUnsavedLineupChanges: false,
};

export const getPreferencesFromLocalStorage = () => {
  const preferences = localStorage.getItem("footballLUBPreferences");
  if (preferences) {
    try {
      return { ...defaultFootballLUBPreferences, ...JSON.parse(preferences) };
    } catch (e) {
      return defaultFootballLUBPreferences;
    }
  }
  return defaultFootballLUBPreferences;
};

export const setPreferenceToLocalStorage = (preferences) => {
  localStorage.setItem("footballLUBPreferences", JSON.stringify(preferences));
};

const isPrefEnabled = (pref, isCapped, isNewSeasonAwareDivision, tier) => {
  if (pref === "showAffordablePlayersOnly") {
    return isCapped;
  }
  if (pref === "highlightNewSeason") {
    return isNewSeasonAwareDivision;
  }
  if (pref === "showCost") {
    return t1OrAbove(tier);
  }
  return true;
};

export const LUBFootballPreferences = (props) => {
  const { onOpenChange, division, open, tier } = props;
  const preferences = props.preferences || defaultFootballLUBPreferences;
  const rules = division?.rules;
  const isCapped = rules?.isCapped !== undefined ? rules.isCapped : false;
  const isNewSeasonAwareDivision =
    (rules?.seasonLimits !== null && rules?.seasonLimits !== undefined) ||
    (rules?.seasonBonusLimits !== null && rules?.seasonBonusLimits !== undefined);
  const nbPreferencesSet =
    Object.entries(preferences)?.filter(
      ([pref, val]) => isPrefEnabled(pref, isCapped, isNewSeasonAwareDivision, tier) && val !== defaultFootballLUBPreferences[pref],
    )?.length || 0;
  const mdQueries = useTailwindMediaQueries();

  const onPreferenceChange = useCallback(
    (pref, newVal) => {
      if (props.onPreferenceChange) {
        props.onPreferenceChange(pref, newVal);
      }
    },
    [props.onPreferenceChange],
  );

  const onReset = useCallback(() => {
    props.onReset && props.onReset();
  }, [props.onReset]);

  const contentProps = {
    onOpenChange,
    onReset,
    preferences,
    onPreferenceChange,
    isCapped,
    tier,
    isNewSeasonAwareDivision,
  };
  return (
    <div className={"relative h-full z-20"}>
      <button
        onClick={onOpenChange ? () => onOpenChange(!open) : undefined}
        className={`h-full text-md my-auto bg-surface-container text-on-surface px-3 py-2 rounded-3xl border ${
          open ? "shadow border-primary" : "border-surface-container"
        } flex flex-row items-center justify-between gap-2 hover:outline hover:outline-1 hover:outline-primary`}
      >
        {nbPreferencesSet === 0 ? (
          <TuneIcon className={`${open ? "fill-primary" : "fill-on-surface"} h-5 w-5`} />
        ) : (
          <div className="flex flex-row my-auto h-5 w-5 justify-center">
            <span
              className="text-xxs font-bold rounded-full flex justify-center items-center self-center text-on-primary bg-primary w-4 h-4"
              title={`${nbPreferencesSet} preferences activated`}
            >
              {nbPreferencesSet}
            </span>
          </div>
        )}
        <div className={"my-auto"}>
          <span className={`text-left font-medium ${open || nbPreferencesSet > 0 ? "text-primary" : ""}`}>Preferences</span>
        </div>
      </button>
      {open && (
        <div className={"hidden sm:block absolute bg-surface-container top-12 right-0 rounded-xl w-80 shadow-lg text-on-surface"}>
          <PreferencesPanel {...contentProps} />
        </div>
      )}
      <MobileModale open={!mdQueries["md"] && open} close={onOpenChange ? () => onOpenChange(!open) : undefined}>
        <div className={"bg-surface-container text-on-surface rounded-t-2xl text-left"}>
          <PreferencesPanel {...contentProps} maxSize={"max-h-[80vh]"} />
        </div>
      </MobileModale>
    </div>
  );
};

const PreferencesPanel = (props) => {
  const { onOpenChange, onReset, preferences, onPreferenceChange, isCapped, tier, maxSize, isNewSeasonAwareDivision } = props;
  return (
    <>
      <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
        <div className={"flex flex-row justify-between px-4 py-4"}>
          <h3 className={"font-headers font-semibold m-0"}>Preferences</h3>
          <div className={"flex flex-row gap-3"}>
            <ResetIcon className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface" onClick={onReset} />
            <XIcon
              className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
              aria-hidden="true"
              onClick={onOpenChange ? () => onOpenChange(false) : undefined}
            />
          </div>
        </div>
      </div>
      <div className={`flex flex-col gap-3 w-full px-4 py-4 overflow-y-auto ${maxSize}`}>
        <div className={"flex flex-col gap-2"}>
          <h4 className={"m-0 mb-1 text-md font-semibold text-on-surface-variant"}>Score values</h4>
          <PreferenceToggle
            label={"Current team only"}
            preferenceField={"teamAdjustedScore"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
          <PreferenceToggle
            label={"Competition adjusted"}
            preferenceField={"competitionAdjustedScore"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
          <PreferenceToggle
            label={"Home/Away adjusted"}
            preferenceField={"homeAwayAdjustedScore"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
          <PreferenceToggle
            label={"Power adjusted"}
            preferenceField={"powerAdjustedScore"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
        </div>
        <div className={"flex flex-col gap-2"}>
          <h4 className={"m-0 mb-1 text-md font-semibold text-on-surface-variant"}>Table view</h4>
          <PreferenceToggle
            label={"Show last 15 games scores"}
            preferenceField={"showL15Scores"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
          <PreferenceToggle
            label={"Show picked players"}
            preferenceField={"showPickedPlayers"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
          {t1OrAbove(tier) && (
            <PreferenceToggle
              label={"Always show Cost"}
              preferenceField={"showCost"}
              preferences={preferences}
              onPreferenceChange={onPreferenceChange}
            />
          )}
          {isCapped && (
            <PreferenceToggle
              label={"Affordable players only"}
              preferenceField={"showAffordablePlayersOnly"}
              preferences={preferences}
              onPreferenceChange={onPreferenceChange}
            />
          )}
          {isNewSeasonAwareDivision && (
            <PreferenceToggle
              label={"Highlight new season cards"}
              preferenceField={"highlightNewSeason"}
              preferences={preferences}
              onPreferenceChange={onPreferenceChange}
            />
          )}
        </div>
        <div className={"flex flex-col gap-2"}>
          <h4 className={"m-0 mb-1 text-md font-semibold text-on-surface-variant"}>Lineup edition</h4>
          <PreferenceToggle
            label={"Ignore unsaved lineup changes"}
            preferenceField={"ignoreUnsavedLineupChanges"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
        </div>
        <div className={"flex flex-col gap-2"}>
          <h4 className={"m-0 mb-1 text-md font-semibold text-on-surface-variant"}>Other</h4>
          <PreferenceToggle
            label={"Activate dark mode"}
            preferenceField={"darkMode"}
            preferences={preferences}
            onPreferenceChange={onPreferenceChange}
          />
        </div>
      </div>
    </>
  );
};

const PreferenceToggle = (props) => {
  const { preferenceField, preferences, label, onPreferenceChange } = props;
  return (
    <div className={"border border-transparent-inverse-surface-low border-opacity-10 rounded-lg py-2"}>
      <BoxWithToggle
        wrap={"text-wrap"}
        maxWidthLabel={"max-w-[12em]"}
        padding="px-2"
        color="text-on-surface"
        label={label}
        isActive={preferences[preferenceField]}
        setActive={() => onPreferenceChange(preferenceField, !preferences[preferenceField])}
      />
    </div>
  );
};
