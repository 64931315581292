import React from "react";
import LineSeparator from "../../img/line-separator.svg";
import format from "date-fns/format";
import OppScore from "../decision/oppScore";
import UnknownClub from "../../img/unknown_club.png";
import { withUser } from "../../userContext";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import NBAProjectedScore from "../util/nbaProjectionScore";
import NBAProjectedMinutes from "../util/nbaProjectedMinutes";
import PlayerAvatar from "../util/playerAvatar";
import Hourglass from "../../img/hourglass.svg";
import RotowireCredit from "../util/rotowireCredit";

function PlayerNextGame(props) {
  let details = props.details;
  let isHome = details.home;
  if (props.sport === sorareBaseball) {
    let games = details.games;
    return (
      <div className={"bg-white flex flex-row space-x-2"}>
        <div className={"bg-white rounded-lg flex flex-col border border-background-grey"}>
          <div className={"text-black-4D font-semibold text-sm flex flex-row justify-between border-b border-background-grey p-2 h-10"}>
            <div className={"self-center"}>
              {details.gw_number > 0 && <p>{details.gw_display_name}</p>}
              {details.gw_number === 0 && <p>Rest of season</p>}
            </div>
            {details.is_projected && (
              <div className={"flex flex-row space-x-2 self-center"}>
                <p className={"self-center"}>Projected score</p>

                <NBAProjectedScore
                  size={"w-7 h-7"}
                  font={"text-sm"}
                  scale={"baseball_gw"}
                  score={details.sum_proj}
                  fixed={details.sum_proj < 10 ? 1 : 0}
                />
              </div>
            )}
          </div>
          <div className={"p-4 flex flex-row space-x-4"}>
            {games.map((gm) => {
              isHome = gm.game.HomeTeamId === props.playerTeamId;
              return (
                <div className={"rounded-lg border border-background-grey p-2 space-y-2 w-32"}>
                  <div className={"flex flex-row space-x-2 justify-between px-1"}>
                    <div className={"bg-grey-f2 rounded text-xs text-black-4D font-semibold p-1"}>
                      <p>{format(new Date(gm.game.GameTime), "dd/LL")}</p>
                    </div>
                    <div className={"bg-grey-f2 rounded text-xs text-black-4D font-semibold p-1"}>
                      <p>MLB</p>
                    </div>
                  </div>
                  <div className={"flex flex-col items-center space-y-2"}>
                    <div className={"w-8 h-8"}>
                      <img className={"w-full h-full object-contain"} src={gm.opponent_logo} />
                    </div>
                    <div className={"flex flex-row space-x-2 justify-center"}>
                      <p className={"text-black font-semibold text-sm"}>{gm.opponent_name}</p>
                      <div
                        className={
                          isHome
                            ? "self-center flex flex-col items-center px-1 py-0.5 rounded bg-super-rare font-semibold text-xxs text-white"
                            : "self-center flex flex-col items-center rounded bg-limited font-semibold text-xxs text-white px-1 py-0.5"
                        }
                      >
                        <p>{isHome ? "H" : "A"}</p>
                      </div>
                    </div>
                  </div>
                  <div className={"flex flex-row space-x-4 justify-center"}>
                    {gm.projected && (
                      <NBAProjectedScore
                        size={"w-10 h-10"}
                        fixed={gm.projected_score < 10 ? 1 : 0}
                        font={"text-base"}
                        scale={"baseball"}
                        score={gm.projected_score}
                      />
                    )}
                    {gm.opponent_probable_id === "" && (
                      <div className={"h-10 w-10"} data-tip={"Probable pitcher TBD"}>
                        <img src={Hourglass} className={"w-full h-full object-contain"} />
                      </div>
                    )}
                    {gm.opponent_probable_id !== "" && (
                      <div className={"cursor-pointer"} data-tip={gm.opponent_probable}>
                        <PlayerAvatar
                          imgSize={"w-10 h-10"}
                          size={"w-10 h-10"}
                          fullOpacity
                          sport={sorareBaseball}
                          player={{
                            Avatar: gm.opponent_probable_avatar,
                            PlayerId: gm.opponent_probable_id,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"flex flex-row space-x-2 py-4 justify-center w-56 self-center"}>
        <div className={"flex flex-col space-y-2 items-center mx-auto"}>
          <div className={"flex flex-row justify-center space-x-1 self-center"}>
            <div className={"bg-grey-f2 rounded text-xxs text-textGrey3 font-semibold p-1"}>
              {details.gw_number > 0 && <p>GW #{details.gw_number}</p>}
              {details.gw_number === 0 && <p>{format(new Date(details.game.GameTime), "dd/LL")}</p>}
            </div>
            <div className={"bg-grey-f2 rounded text-xxs text-textGrey3 font-semibold p-1"}>
              <p>{details.game.CompDisplayName}</p>
            </div>
          </div>
          <div className={"flex flex-col items-center space-y-1 mx-auto"}>
            <div className={"flex flex-row justify-center space-x-3"}>
              <div className={"w-8 h-8 rounded-full"}>
                <a href={"/team/" + details.opponent_id} target={"_blank"} rel="noreferrer">
                  <img
                    className={"w-full h-full object-contain"}
                    src={details.opponent_logo !== "" ? details.opponent_logo : UnknownClub}
                  />
                </a>
              </div>
              {props.sport === sorareBaseball && details.projected === true && (
                <div>
                  <NBAProjectedScore fixed={details.projected_score >= 10 ? 0 : 1} score={details.projected_score} />
                </div>
              )}
            </div>

            <div className={"flex flex-row justify-center space-x-2"}>
              <p className={"text-xs font-semibold self-center"}>
                <a href={"/team/" + details.opponent_id} target={"_blank"} rel="noreferrer">
                  {details.opponent_name}
                </a>
              </p>
              <div
                className={
                  isHome
                    ? "self-center flex flex-col items-center px-1 py-0.5 rounded bg-super-rare font-semibold text-xxs text-white"
                    : "self-center flex flex-col items-center rounded bg-limited font-semibold text-xxs text-white px-1 py-0.5"
                }
              >
                <p>{isHome ? "H" : "A"}</p>
              </div>
            </div>
          </div>
          {t1OrAbove(props.user?.tier) && props.sport === sorareFootball && (
            <div>
              <OppScore score={details.opp_score ? details.opp_score : 0} />
            </div>
          )}
          {props.sport === sorareBaseball && details.team_probable_id === props.playerId && (
            <div>
              <p className={"text-xs font-bold"}>Probable start</p>
            </div>
          )}
          {props.sport === sorareBaseball && details.opponent_probable !== "" && (
            <div>
              <p className={"text-xs font-semibold"}>vs. {details.opponent_probable}</p>
            </div>
          )}
          {props.sport === sorareBaseball && details.opponent_probable === "" && (
            <div>
              <p className={"text-xs font-semibold"}>No probable pitcher yet</p>
            </div>
          )}
          {props.sport === sorareBasketball && details.projected === true && (
            <div className={"flex flex-row justify-center space-x-2"}>
              <div className={"flex"}>
                <NBAProjectedScore tip={true} score={details.projected_score} />
              </div>
              <div className={"self-center"} data-tip={"Projected minutes (powered by RotoWire)"}>
                <NBAProjectedMinutes minutes={details.projected_minutes} />
              </div>
            </div>
          )}
        </div>
        <div className={"self-center"}>
          <img src={LineSeparator} />
        </div>
      </div>
    );
  }
}

function PlayerNextGames(props) {
  const info = props.nextGames;
  const nextGames = props.nextGames.games;
  const sport = props.sport;

  if (nextGames !== undefined && nextGames !== null) {
    return (
      <div className={"space-y-4"}>
        <div className={"flex flex-row justify-between"}>
          <p className={"font-headers text-brand font-semibold text-xl"}>Next games</p>
          {props.sport === sorareBaseball && <RotowireCredit credit={"Projections by"} />}
        </div>
        <div className={"flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-8"}>
          <div className={"flex flex-row flex-1 w-full md:w-10/12 bg-white rounded-lg overflow-y-auto"}>
            {nextGames.map((ng, i) => {
              return (
                <div className={sport === sorareBaseball ? "flex p-4 " : "w-56 flex self-center"}>
                  <PlayerNextGame {...props} playerId={props.playerId} sport={sport} details={ng} last={i === nextGames.length - 1} />
                </div>
              );
            })}
          </div>
          {t1OrAbove(props.user?.tier) && sport === sorareFootball && (
            <div className={"w-full md:w-3/12 lg:w-2/12 flex flex-col space-y-4 bg-white items-center mx-auto p-2 rounded-lg"}>
              {info?.next_1_avg >= 0 && (
                <div className={"flex flex-col space-y-2"}>
                  <OppScore score={info.next_1_avg} />
                  <p className={"uppercase text-xs text-textGrey3 font-semibold text-center"}>
                    Next {info.next_1_nb} opponent scores average
                  </p>
                </div>
              )}
              {info?.next_2_avg >= 0 && (
                <div className={"flex flex-col space-y-2"}>
                  <OppScore score={info.next_2_avg} />
                  <p className={"uppercase text-xs text-textGrey3 font-semibold text-center"}>
                    Next {info.next_2_nb} opponent scores average
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        {isFree(props.user?.tier) && sport === sorareFootball && (
          <UpgradeLimitBox
            className={"bg-white bg-upgrade-bg-next-games bg-cover h-upgrade-banner-s w-full"}
            title={"Want more insights?"}
            description={"Become a Star member to unlock next opponent scores"}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default withUser(PlayerNextGames);
