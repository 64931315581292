import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { withRouter } from "react-router-dom";
import SDLoading from "../util/SDLoading";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { StandaloneMenu } from "../util/standaloneMenu";
import Spinner from "../loader/spinner";
import SO5GameweekPicker from "../SO5/SO5GameweekPicker";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { ReactComponent as IconCircle } from "../../img/icons-circle.svg";
import { ReactComponent as Trophy } from "../../img/icons-trophy-alternative.svg";
import NewCardIcon from "../util/newCardIcon";
import ordinal_suffix_of from "../util/ordinalNumber";
import AddSearchableItemsModal from "../search/addSearchableItemsModal";
import FavoriteBubble from "../util/favoriteBubble";
import DeletionBubble from "../util/deletionBubble";
import RankLabel from "../util/rankLabel";
import { checkSecondUnitShouldBeDisplayed, findPriceForUserUnit } from "../util/formatMoney";
import SO5AvatarLineup from "../SO5/SO5AvatarLineup";
import { formatNumber } from "../util/formatNumber";
import { Form } from "grommet";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { searchText } from "../util/strings";
import WatchlistPicker from "./watchlistPicker";
import SO5DivisionPicker from "../util/so5DivisionPicker";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareBasketball, sorareFootball } from "../util/sports";
import BasketballAvatarLineup from "../SO5/basketballAvatarLineup";
import { Button } from "../util/button";

const sortByLabels = {
  "rank asc": "Rank - First to Last",
  "rank desc": "Rank - Last to First",
  "name asc": "Name - A to Z",
  "name desc": "Name - Z to A",
  "rewardsLimited asc": "Limited rewards - Smallest to Highest",
  "rewardsLimited desc": "Limited rewards - Highest to Smallest",
  "rewardsRare asc": "Rare rewards - Smallest to Highest",
  "rewardsRare desc": "Rare rewards - Highest to Smallest",
  "rewardsSuperRare asc": "Super Rare rewards - Smallest to Highest",
  "rewardsSuperRare desc": "Super Rare rewards - Highest to Smallest",
  "rewardsUnique asc": "Unique rewards - Smallest to Highest",
  "rewardsUnique desc": "Unique rewards - Highest to Smallest",
  "so5Wins asc": "SO5 Wins - Least to Most",
  "so5Wins desc": "SO5 Wins - Most to Least",
  "ethEarned asc": "GW Eth earned - Least to Most",
  "ethEarned desc": "GW Eth earned - Most to Least",
  "added asc": "Least recently added",
  "added desc": "Most recently added",
};

const sortByLabelsDivision = {
  "rank asc": "Rank - First to Last",
  "rank desc": "Rank - Last to First",
  "name asc": "Name - A to Z",
  "name desc": "Name - Z to A",
  "lineup asc": "Lineup - Best to Worse",
  "lineup desc": "Lineup - Worse to Best",
  "rewards asc": "Rewards - Smallest to Highest",
  "rewards desc": "Rewards - Highest to Smallest",
  "gwRank asc": "SO5 rank - First to Last",
  "gwRank desc": "SO5 rank - Last to First",
  "gwPoints asc": "SO5 score - Smallest to Highest",
  "gwPoints desc": "SO5 score - Highest to Smallest",
  "added asc": "Least recently added",
  "added desc": "Most recently added",
};

const sortStyle = (column, sortableColumns, sortBy) => {
  let style = [];
  if (sortableColumns.includes(column)) {
    style.push("hover:opacity-70 cursor-pointer");
  }
  if (sortBy.includes(column)) {
    style.push("bg-brand-black-light font-bold");
  } else {
    style.push("bg-brand-black");
  }
  return style.join(" ");
};
const sortArrowStyle = (column, sortableColumns, sortBy) => {
  let style = ["fill-white my-auto"];
  if (sortableColumns.includes(column) && sortBy.includes(column)) {
    style.push("visible");
    if (sortBy.includes("desc")) {
      style.push("transform rotate-180");
    }
  } else {
    style.push("invisible");
  }
  return style.join(" ");
};

const CardRewards = (props) => {
  const { scarcity, rewards } = props;
  const total = rewards?.total || 0;
  return (
    <div className={"flex flex-row gap-3 justify-center"}>
      {rewards?.tiers === undefined ? (
        <span className={"text-on-surface-variant"}>No reward</span>
      ) : (
        <>
          <div className={"flex flex-row gap-1"}>
            <span className={`my-auto ${total === 0 ? "text-on-surface-variant" : "text-on-surface"}`}>{total}x</span>
            <span className={"h-5 w-5 my-auto"}>
              <NewCardIcon scarcity={scarcity.toLowerCase()} />
            </span>
          </div>
          <div>
            <div className={"flex flex-col text-right text-on-surface"}>
              {rewards?.tiers.map((nbCards, tier) => {
                if (nbCards === undefined || nbCards === 0) {
                  return null;
                }
                return (
                  <p key={tier}>
                    {nbCards}x Tier {tier}
                  </p>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const WatchlistsGWPerformanceListByDivision = withUser((props) => {
  const {
    displayedGwPerformanceRes,
    onChangeSort,
    sortBy,
    connectedUserIsOwner,
    watchlist,
    removeManagerFromWatchlist,
    games,
    stats,
    sport,
  } = props;
  const sortableColumns = ["rank", "name", "lineup", "rewards", "gwRank", "gwPoints", "added"];
  const sortingStyle = (column) => sortStyle(column, sortableColumns, sortBy);
  const sortingArrowStyle = (column) => sortArrowStyle(column, sortableColumns, sortBy);
  const primaryCurrency = props.user.preferredUnit;
  const secondaryCurrency = props.user.secondUnit;
  const shouldDisplaySecondCurrency = checkSecondUnitShouldBeDisplayed(primaryCurrency, secondaryCurrency);
  return (
    <table className="table-auto rounded bg-surface-container rounded-lg h-auto overflow-hidden text-xs font-semibold w-full overflow-auto text-center text-on-surface">
      <thead>
        <tr>
          <th
            style={{ width: "6em" }}
            onClick={() => onChangeSort("rank")}
            className={"p-1 sm:p-3 text-white z-20 sticky left-0 text-left " + sortingStyle("rank")}
          >
            <div className={"flex flex-row justify-between"}>
              <p>Rank</p>
              <SortArrowIcon className={sortingArrowStyle("rank")} />
            </div>
          </th>
          <th
            style={{ minWidth: "10em", left: "4em" }}
            onClick={() => onChangeSort("name")}
            className={"p-1 sm:p-3 text-white z-20 sticky " + sortingStyle("name")}
          >
            <div className={"flex flex-row justify-between"}>
              <p>Name</p>
              <SortArrowIcon className={sortingArrowStyle("name")} />
            </div>
          </th>
          <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("lineup")} onClick={() => onChangeSort("lineup")}>
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span>Lineup</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("lineup")} />
              </span>
            </div>
          </th>
          <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("rewards")} onClick={() => onChangeSort("rewards")}>
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span>Rewards</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("rewards")} />
              </span>
            </div>
          </th>
          <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("gwRank")} onClick={() => onChangeSort("gwRank")}>
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span>SO5 rank</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("gwRank")} />
              </span>
            </div>
          </th>
          <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("gwPoints")} onClick={() => onChangeSort("gwPoints")}>
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span>SO5 score</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("gwPoints")} />
              </span>
            </div>
          </th>
          <th className={"p-3 bg-brand-black"}></th>
        </tr>
      </thead>
      <tbody>
        {displayedGwPerformanceRes?.map((res) => {
          const rewards = [];
          Object.keys(res.rewards).forEach((scarcity) => {
            const r = res.rewards[scarcity];
            if (r !== undefined && r.total > 0) {
              rewards.push({ scarcity, reward: r });
            }
          });
          let score = res.lineup?.lineup?.score;
          if (score === undefined) {
            score = res.lineup?.lineup?.Score;
          }
          let rank = res.lineup?.lineup?.rank;
          if (rank === undefined) {
            rank = res.lineup?.lineup?.Rank;
          }
          const hasRewards = rewards.length > 0 || res.gamingYield?.["eth"] > 0;
          const moneyValuePrimary = findPriceForUserUnit(res.gamingYield?.["eth"], res.gamingYield, primaryCurrency);
          const moneyValueSecondary = findPriceForUserUnit(res.gamingYield?.["eth"], res.gamingYield, secondaryCurrency);
          return (
            <tr key={res.rank} className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <td
                className={"p-1 sm:p-2 xl:p-3  text-left z-20 left-0 bg-surface-container sticky"}
                style={{ width: "6em", backgroundClip: "padding-box" }}
              >
                <span className={"my-auto text-sm font-semibold flex justify-center"}>
                  <RankLabel rank={res.rank} />
                </span>
              </td>
              <td
                className={"p-1 sm:p-2 xl:p-3 text-left z-20 bg-surface-container sticky"}
                style={{ left: "4em", backgroundClip: "padding-box" }}
              >
                <span className={"flex flex-row"} title={res.manager.Nickname || res.manager.Slug}>
                  <span className={"my-auto hidden md:inline rounded-full mr-2 w-5 h-5 xl:w-7 xl:h-7"}>
                    <a href={"/manager/" + res.manager.Slug}>
                      <img
                        className={"rounded-full inline w-5 h-5 xl:w-7 xl:h-7 object-cover cursor-pointer"}
                        src={res.manager.PictureUrl || EmptyAvatar}
                      />
                    </a>
                  </span>
                  <span className={"text-xs xl:text-sm font-semibold mr-2 flex flex-col"}>
                    <a className={"truncate"} href={"/manager/" + res.manager.Slug}>
                      {res.manager.Nickname || res.manager.Slug}
                    </a>
                    <p className={"text-on-surface-variant truncate text-xs"} style={{ maxWidth: "13em" }} title={res.manager.TeamName}>
                      <a href={"/manager/" + res.manager.Slug}>{res.manager.TeamName}</a>
                    </p>
                  </span>
                </span>
              </td>
              <td className={"p-3"}>
                {sport === sorareFootball && (
                  <span className={"my-auto text-sm font-semibold"}>
                    <SO5AvatarLineup
                      players={res.lineup?.players}
                      comp={res.lineup?.competition}
                      lineup={res.lineup?.lineup}
                      stats={stats}
                      games={games}
                    />
                  </span>
                )}
                {sport === sorareBasketball && (
                  <span className={"my-auto text-sm font-semibold"}>
                    <BasketballAvatarLineup
                      players={res.lineup?.players}
                      comp={res.lineup?.competition}
                      lineup={res.lineup?.lineup}
                      stats={stats}
                      games={games}
                    />
                  </span>
                )}
              </td>
              <td className={"p-3"}>
                {hasRewards ? (
                  <span className={"my-auto text-sm font-semibold flex flex-col gap-1 my-auto justify-center"}>
                    {rewards.map((r, i) => {
                      return <CardRewards key={i} scarcity={r.scarcity} rewards={r.reward} />;
                    })}
                    <div className={"space-x-2"}>
                      {res.gamingYield?.[primaryCurrency] > 0 && <span>{moneyValuePrimary}</span>}
                      {shouldDisplaySecondCurrency && res.gamingYield?.[secondaryCurrency] > 0 && (
                        <span className={"text-on-surface-variant text-xs"}>{moneyValueSecondary}</span>
                      )}
                    </div>
                  </span>
                ) : (
                  <span className={"text-sm text-on-surface-variant"}>No reward</span>
                )}
              </td>
              <td className={"p-3"}>
                {rank > 1 || (rank === 1 && score > 0) ? (
                  <span className={"my-auto text-sm font-semibold"}>{ordinal_suffix_of(rank)}</span>
                ) : (
                  <span className={"my-auto text-sm text-textGrey4"}>{"-"}</span>
                )}
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>{formatNumber(score)} pts</span>
              </td>
              <td className={"p-3"} style={{ width: "3em" }}>
                <div className={"flex flex-row justify-center m-auto"}>
                  <div className={"h-9 w-9"}>
                    <FavoriteBubble type="manager" manager={res.manager.Slug} favorite={res.favorite} />
                  </div>
                  {connectedUserIsOwner && (
                    <div className={"h-9 w-9"}>
                      <DeletionBubble
                        onClick={() => removeManagerFromWatchlist(res.manager)}
                        title={`Click to remove "${res.manager.Nickname}" from "${watchlist.name}" watchlist`}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

const WatchlistsGWPerformanceList = withUser((props) => {
  const { displayedGwPerformanceRes, onChangeSort, sortBy, connectedUserIsOwner, watchlist, removeManagerFromWatchlist } = props;
  const sortableColumns = [
    "rank",
    "name",
    "rewardsLimited",
    "rewardsRare",
    "rewardsSuperRare",
    "rewardsUnique",
    "so5Wins",
    "ethEarned",
    "eurEarned",
    "usdEarned",
    "gbpEarned",
    "added",
  ];
  const sortingStyle = (column) => sortStyle(column, sortableColumns, sortBy);
  const sortingArrowStyle = (column) => sortArrowStyle(column, sortableColumns, sortBy);
  const primaryCurrency = props.user.preferredUnit;
  const secondaryCurrency = props.user.secondUnit;
  const shouldDisplaySecondCurrency = checkSecondUnitShouldBeDisplayed(primaryCurrency, secondaryCurrency);
  return (
    <table className="table-auto rounded bg-surface-container rounded-lg h-auto overflow-hidden text-xs font-semibold w-full overflow-auto text-center text-on-surface">
      <thead>
        <tr>
          <th
            style={{ width: "6em" }}
            onClick={() => onChangeSort("rank")}
            className={"p-1 sm:p-3 text-white z-20 sticky left-0 text-left " + sortingStyle("rank")}
          >
            <div className={"flex flex-row justify-between"}>
              <p>Rank</p>
              <SortArrowIcon className={sortingArrowStyle("rank")} />
            </div>
          </th>
          <th
            style={{ minWidth: "10em", left: "4em" }}
            onClick={() => onChangeSort("name")}
            className={"p-1 sm:p-3 text-white z-20 sticky " + sortingStyle("name")}
          >
            <div className={"flex flex-row justify-between"}>
              <p>Name</p>
              <SortArrowIcon className={sortingArrowStyle("name")} />
            </div>
          </th>
          <th
            className={"p-2 bg-brand-black text-white text-center " + sortingStyle("rewardsLimited")}
            onClick={() => onChangeSort("rewardsLimited")}
          >
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span className={"fill-limited self-center"}>
                  <IconCircle />
                </span>
                <span>Rewards</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("rewardsLimited")} />
              </span>
            </div>
          </th>
          <th
            className={"p-2 bg-brand-black text-white text-center " + sortingStyle("rewardsRare")}
            onClick={() => onChangeSort("rewardsRare")}
          >
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span className={"fill-rare self-center"}>
                  <IconCircle />
                </span>
                <span>Rewards</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("rewardsRare")} />
              </span>
            </div>
          </th>
          <th
            className={"p-2 bg-brand-black text-white text-center " + sortingStyle("rewardsSuperRare")}
            onClick={() => onChangeSort("rewardsSuperRare")}
          >
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span className={"fill-super-rare self-center"}>
                  <IconCircle />
                </span>
                <span>Rewards</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("rewardsSuperRare")} />
              </span>
            </div>
          </th>
          <th
            className={"p-2 bg-brand-black text-white text-center " + sortingStyle("rewardsUnique")}
            onClick={() => onChangeSort("rewardsUnique")}
          >
            <div className={"relative m-auto w-full"}>
              <div className={"flex flex-row gap-1 justify-center"}>
                <span className={"fill-unique self-center"}>
                  <IconCircle />
                </span>
                <span>Rewards</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("rewardsUnique")} />
              </span>
            </div>
          </th>
          <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("so5Wins")} onClick={() => onChangeSort("so5Wins")}>
            <div className={"relative m-auto w-full"}>
              <div className={"flex justify-center"}>
                <span>SO5 Wins</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle("so5Wins")} />
              </span>
            </div>
          </th>
          <th
            className={"p-2 bg-brand-black text-white text-center " + sortingStyle(`${primaryCurrency}Earned`)}
            onClick={() => onChangeSort(`${primaryCurrency}Earned`)}
          >
            <div className={"relative m-auto w-full"}>
              <div className={"flex justify-center"}>
                <span>Gw gaming yield</span>
              </div>
              <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                <SortArrowIcon className={sortingArrowStyle(`${primaryCurrency}Earned`)} />
              </span>
            </div>
          </th>
          <th className={"p-3 bg-brand-black"}></th>
        </tr>
      </thead>
      <tbody>
        {displayedGwPerformanceRes?.map((res) => {
          const moneyValuePrimary = findPriceForUserUnit(res.gamingYield?.["eth"], res.gamingYield, primaryCurrency);
          const moneyValueSecondary = findPriceForUserUnit(res.gamingYield?.["eth"], res.gamingYield, secondaryCurrency);
          return (
            <tr key={res.rank} className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <td
                className={"p-1 sm:p-2 xl:p-3  text-left z-20 left-0 sticky bg-surface-container"}
                style={{ width: "6em", backgroundClip: "padding-box" }}
              >
                <span className={"my-auto text-sm font-semibold flex justify-center"}>
                  <RankLabel rank={res.rank} />
                </span>
              </td>
              <td
                className={"p-1 sm:p-2 xl:p-3 text-left z-20 sticky bg-surface-container"}
                style={{ left: "4em", backgroundClip: "padding-box" }}
              >
                <span className={"flex flex-row"} title={res.manager.Nickname || res.manager.Slug}>
                  <span className={"my-auto hidden md:inline rounded-full mr-2 w-5 h-5 xl:w-7 xl:h-7"}>
                    <a href={"/manager/" + res.manager.Slug}>
                      <img
                        className={"rounded-full inline w-5 h-5 xl:w-7 xl:h-7 object-cover cursor-pointer"}
                        src={res.manager.PictureUrl || EmptyAvatar}
                      />
                    </a>
                  </span>
                  <span className={"text-xs xl:text-sm font-semibold mr-2 flex flex-col"}>
                    <a className={"truncate"} href={"/manager/" + res.manager.Slug}>
                      {res.manager.Nickname || res.manager.Slug}
                    </a>
                    <p className={"text-textGrey4 truncate text-xs"} style={{ maxWidth: "13em" }} title={res.manager.TeamName}>
                      <a href={"/manager/" + res.manager.Slug}>{res.manager.TeamName}</a>
                    </p>
                  </span>
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardRewards scarcity={"LIMITED"} rewards={res.rewards["LIMITED"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardRewards scarcity={"RARE"} rewards={res.rewards["RARE"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardRewards scarcity={"SUPER RARE"} rewards={res.rewards["SUPER RARE"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardRewards scarcity={"UNIQUE"} rewards={res.rewards["UNIQUE"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold flex flex-row gap-1 my-auto justify-center"}>
                  <span>{res.so5Wins}</span>
                  {res.so5Wins > 0 && <Trophy className={"fill-gold my-auto"} />}
                </span>
              </td>
              <td className={"p-3"}>
                <div className={"flex flex-col gap-0.5 items-center"}>
                  <span className={"my-auto text-sm font-semibold"}>{moneyValuePrimary}</span>
                  {shouldDisplaySecondCurrency && (
                    <span className={"my-auto text-xs font-semibold text-on-surface-variant"}>{moneyValueSecondary}</span>
                  )}
                </div>
              </td>
              <td className={"p-3"} style={{ width: "3em" }}>
                <div className={"flex flex-row justify-center m-auto"}>
                  <div className={"h-9 w-9"}>
                    <FavoriteBubble type="manager" manager={res.manager.Slug} favorite={res.favorite} />
                  </div>
                  {connectedUserIsOwner && (
                    <div className={"h-9 w-9"}>
                      <DeletionBubble
                        onClick={() => removeManagerFromWatchlist(res.manager)}
                        title={`Click to remove "${res.manager.Nickname}" from "${watchlist.name}" watchlist`}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

function WatchlistManagersGWPerformance(props) {
  const { withGameweekPicker, withManagerSearch, withDivisionPicker, withWatchlistsPicker, withManagerAdder } = props;

  const [watchlist, setWatchlist] = useState(props.watchlist);
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState(undefined);
  const [filteredManagers, setFilteredManagers] = useState(undefined);
  const [displayedManagers, setDisplayedManagers] = useState(undefined);
  const [gameweek, setGameweek] = useState("");
  const [gameweekNumber, setGameweekNumber] = useState(0);
  const [division, setDivision] = useState("");
  const [stats, setStats] = useState({});
  const [games, setGames] = useState({});
  const [sortBy, setSortBy] = useState("rank asc");
  const [searchQuery, setSearchQuery] = useState("");

  const [managersForDiv, setManagersForDiv] = useState(undefined);
  const [filteredManagersForDiv, setFilteredManagersForDiv] = useState(undefined);
  const [displayedManagersForDiv, setDisplayedManagersForDiv] = useState(undefined);
  const [showSortMenuDiv, setShowSortMenuDiv] = useState(false);
  const [sortByDiv, setSortByDiv] = useState("rank asc");

  const [savingAddManagers, setSavingAddManagers] = useState(false);
  const [openAddManagersModal, setOpenAddManagersModal] = useState(false);

  const [showSortMenu, setShowSortMenu] = useState(false);
  const connectedUserIsOwner = watchlist && watchlist.owner === props.user.sorareSlug;

  let sport = sorareFootball;
  if (props.sport) {
    sport = props.sport;
  }

  useEffect(() => {
    setWatchlist(props.watchlist);
  }, [props.watchlist]);

  useEffect(() => {
    console.log(props.gameweek);
    if (props.gameweek !== undefined) {
      setGameweek(props.gameweek);
    }
  }, [props.gameweek]);

  useEffect(() => {
    if (props.gwNumber !== undefined) {
      setGameweekNumber(props.gwNumber);
    }
  }, [props.gwNumber]);

  useEffect(() => {
    getManagerPerformances();
  }, [sortBy, sortByDiv, gameweek, watchlist, props.watchlist, division]);

  useEffect(() => {
    if (managersForDiv !== undefined) {
      const filtered = managersForDiv.filter((m) => searchText(searchQuery, m.manager.Nickname));
      setFilteredManagersForDiv(filtered);
      setDisplayedManagersForDiv(filtered.slice(0, 50));
    }
    if (managers !== undefined) {
      const filtered = managers.filter((m) => searchText(searchQuery, m.manager.Nickname));
      setFilteredManagers(filtered);
      setDisplayedManagers(filtered.slice(0, 50));
    }
  }, [searchQuery]);

  const getManagerPerformances = () => {
    if (watchlist?.id && gameweekNumber && gameweek) {
      setLoading(true);
      if (division !== "") {
        props
          .fetch(
            `/apiv2/watchlists/id/${watchlist.id}/managers/gwPerformance/so5div/${
              gameweek + "-" + division
            }?sortBy=${sortByDiv}&gameweek=${gameweek}&sport=${sport}`,
          )
          .then((response) => response.json())
          .then((res) => {
            if (res.error === undefined) {
              const ms = res.managers || [];
              setManagersForDiv(ms);
              const filteredMs = ms.filter((m) => searchText(searchQuery, m.manager.Nickname));
              setFilteredManagersForDiv(filteredMs);
              setDisplayedManagersForDiv(filteredMs.slice(0, 50));

              setStats(res.stats ? res.stats : {});
              setGames(res.games ? res.games : {});
            }
            setLoading(false);
          })
          .catch(
            errorCatcher(() => {
              setLoading(false);
            }),
          );
      } else {
        props
          .fetch(`/apiv2/watchlists/id/${watchlist.id}/managers/gwPerformance?sortBy=${sortBy}&gameweek=${gameweek}&sport=${sport}`)
          .then((response) => response.json())
          .then((res) => {
            if (res.error === undefined) {
              const ms = res.managers || [];
              setManagers(ms);
              const filteredMs = ms.filter((m) => searchText(searchQuery, m.manager.Nickname));
              setFilteredManagers(filteredMs);
              setDisplayedManagers(filteredMs.slice(0, 50));
            }
            setLoading(false);
          })
          .catch(
            errorCatcher(() => {
              setLoading(false);
            }),
          );
      }
    }
  };

  const onSaveAddManagers = (playerIds) => {
    setSavingAddManagers(true);
    return props
      .fetch(`/apiv2/watchlists/id/${watchlist.id}/addItems`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(playerIds),
      })
      .then((res) => {
        setSavingAddManagers(false);
        if (res.error === undefined) {
          setOpenAddManagersModal(false);
          props.onAddManagers && props.onAddManagers();
          withWatchlistsPicker && getManagerPerformances();
        } else {
          console.log(res.error);
        }
      })
      .catch(
        errorCatcher(() => {
          setSavingAddManagers(false);
        }),
      );
  };

  const onRemoveManager = (managerToRemove) => {
    if (watchlist.id && managerToRemove) {
      setLoading(true);
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}/removeItems`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify([managerToRemove.Slug]),
        })
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.error === undefined) {
            getManagerPerformances();
          }
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const sortMenuItems = (labels, sorter, onClick) =>
    Object.keys(labels).map((key) => {
      const label = labels[key];
      return {
        key: key,
        label: label,
        className: "w-full sm:w-72 cursor-pointer " + (sorter === key ? "font-semibold" : ""),
        onClick: () => onClick(key),
      };
    });

  const sortMenuItemsGlobal = sortMenuItems(sortByLabels, sortBy, (key) => {
    setShowSortMenu(false);
    setSortBy(key);
  });

  const sortMenuItemsDivision = sortMenuItems(sortByLabelsDivision, sortByDiv, (key) => {
    setShowSortMenuDiv(false);
    setSortByDiv(key);
  });

  const sortByStatus = (field, sortBy, sortbySetter) => {
    let sort = "";
    if (sortBy.includes(field)) {
      sort = field + (sortBy.includes("asc") ? " desc" : " asc");
    } else {
      sort = field + " asc";
    }
    sortbySetter(sort);
  };

  return (
    <div className={"h-full"}>
      <div className={"relative mb-4 flex flex-col sm:flex-row justify-between gap-2 z-30"}>
        <div
          className={"text-sm font-semibold text-primary cursor-pointer flex flex-row my-auto"}
          onClick={division === "" ? () => setShowSortMenu(true) : () => setShowSortMenuDiv(true)}
        >
          Sort by: {division === "" ? sortByLabels[sortBy] : sortByLabelsDivision[sortByDiv]}
          <span className={"ml-2 my-auto"}>
            <SortArrowIcon className={"fill-primary " + (showSortMenu ? "transform rotate-180" : "")} />
          </span>
        </div>
        <div className="flex flex-col xl:flex-row gap-2 justify-end">
          {loading && <Spinner />}
          {withManagerSearch && (
            <Form className="font-semibold text-sm">
              <div
                className={
                  "m-auto relative flex h-9 bg-surface-container overflow-hidden border border-transparent-inverse-surface-low focus:border-transparent-inverse-surface-low border-opacity-10 rounded-lg"
                }
              >
                <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                  <FormSearch className="h-5 w-5" aria-hidden="true" />
                </div>
                {searchQuery && (
                  <div
                    className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  >
                    <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
                  </div>
                )}
                <input
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  className={`h-9 block w-full pl-10 overflow-hidden bg-surface-container placeholder-on-surface-variant text-sm border-0 focus:border-0 focus:ring-0 ${
                    searchQuery !== "" ? "text-on-surface font-medium" : "text-on-surface-variant"
                  }`}
                  placeholder="Search a manager"
                />
              </div>
            </Form>
          )}
          {withWatchlistsPicker && (
            <WatchlistPicker
              limit={20}
              types={["manager"]}
              options={{
                pinListFirst: true,
                defaultListFirst: true,
                followedListFirst: true,
                ownedListFirst: true,
                selectFirst: true,
              }}
              placeholder={"Select a watchlist"}
              selected={watchlist}
              onSelect={(w) => setWatchlist(w)}
            />
          )}
          {withDivisionPicker && (
            <SO5DivisionPicker
              gwNumber={gameweekNumber}
              sport={sport}
              withAll
              withRegisteredLineupsOnly
              selected={division}
              onSelect={(v) => {
                // clean manager list when table display changes, to avoid blinking between data types
                if (division !== "" && v === "") {
                  // set from indiv tournament to all tournaments
                  setDisplayedManagersForDiv(undefined);
                  setManagersForDiv(undefined);
                } else if (division === "" && v !== "") {
                  // set from all to indiv tournament
                  setDisplayedManagers(undefined);
                  setManagers(undefined);
                }
                setDivision(v);
              }}
            />
          )}
          {withGameweekPicker && (
            <SO5GameweekPicker
              onSelect={(gw, _, gwNumber) => {
                setGameweek(gw);
                setGameweekNumber(gwNumber);
              }}
            />
          )}
          {withManagerAdder && connectedUserIsOwner && (
            <Button onClick={() => setOpenAddManagersModal(true)}>{`+ Add ${watchlist.type}s`}</Button>
          )}
        </div>
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
          show={showSortMenu}
          onClickOutside={() => setShowSortMenu(false)}
          items={sortMenuItemsGlobal}
        />
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
          show={showSortMenuDiv}
          onClickOutside={() => setShowSortMenuDiv(false)}
          items={sortMenuItemsDivision}
        />
      </div>
      <div className={"h-full mb-8"}>
        {watchlist === undefined && gameweek !== "" ? (
          isFree(props.user.tier) ? (
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-watchlists bg-cover h-upgrade-banner-l w-full mb-4"}
              title={"Want more watchlists?"}
              description={"Become a Star member to unlock unlimited watchlists."}
            />
          ) : (
            <div
              className={
                "flex flex-col justify-center w-full h-full bg-surface-container rounded-lg text-center border border-transparent-inverse-surface-low border-opacity-10 text-on-surface"
              }
            >
              <div className={"m-auto py-36"}>
                <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
                <p className={"text-lg font-headers text-center font-bold mb-2"}>No watchlist selected</p>
                <p className={"text-sm text-center font-semibold text-on-surface-variant mb-6"}>
                  <a className={"text-primary hover:underline"} href={"/watchlists/overview"}>
                    Create
                  </a>
                  {" or "}
                  <a className={"text-primary hover:underline"} href={"/watchlists/community"}>
                    follow
                  </a>{" "}
                  manager watchlists to see manager results
                </p>
              </div>
            </div>
          )
        ) : (
          <>
            {displayedManagers !== undefined && displayedManagers.length === 0 && (
              <div className={"flex flex-col justify-center w-full h-full bg-focus rounded-lg text-center border border-grey-e9"}>
                <div className={"m-auto py-36"}>
                  <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
                  <p className={"text-lg font-headers text-center font-bold mb-6"}>
                    {connectedUserIsOwner ? "Your watchlist is empty" : "Watchlist is empty"}
                  </p>
                  {withManagerAdder && connectedUserIsOwner && (
                    <button
                      onClick={() => setOpenAddManagersModal(true)}
                      type="button"
                      className="inline-flex items-center px-12 py-2.5 border border-transparent text-lg font-semibold rounded-lg
                shadow-sm text-white bg-brand hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-brand-light"
                    >
                      {`+ Add ${watchlist.type}s`}
                    </button>
                  )}
                </div>
              </div>
            )}
            {displayedManagers !== undefined && displayedManagers.length > 0 && division == "" && (
              <div>
                <InfiniteScroll
                  next={() => setDisplayedManagers(filteredManagers.slice(0, displayedManagers.length + 50))}
                  hasMore={displayedManagers.length < filteredManagers.length}
                  loader={<SDLoading />}
                  dataLength={displayedManagers.length}
                >
                  <WatchlistsGWPerformanceList
                    watchlist={watchlist}
                    displayedGwPerformanceRes={displayedManagers}
                    onChangeSort={(field) => sortByStatus(field, sortBy, setSortBy)}
                    sortBy={sortBy}
                    connectedUserIsOwner={connectedUserIsOwner}
                    removeManagerFromWatchlist={onRemoveManager}
                  />
                </InfiniteScroll>
              </div>
            )}
            {displayedManagersForDiv !== undefined && displayedManagersForDiv.length > 0 && division !== "" && (
              <div>
                <InfiniteScroll
                  next={() => setDisplayedManagersForDiv(filteredManagersForDiv.slice(0, displayedManagersForDiv.length + 50))}
                  hasMore={displayedManagersForDiv.length < filteredManagersForDiv.length}
                  loader={<SDLoading />}
                  dataLength={displayedManagersForDiv.length}
                >
                  <WatchlistsGWPerformanceListByDivision
                    watchlist={watchlist}
                    division={division}
                    games={games}
                    sport={sport}
                    stats={stats}
                    displayedGwPerformanceRes={displayedManagersForDiv}
                    onChangeSort={(field) => sortByStatus(field, sortByDiv, setSortByDiv)}
                    sortBy={sortByDiv}
                    connectedUserIsOwner={connectedUserIsOwner}
                    removeManagerFromWatchlist={onRemoveManager}
                  />
                </InfiniteScroll>
              </div>
            )}
          </>
        )}
      </div>
      <AddSearchableItemsModal
        type="manager"
        title={"Add to watchlist"}
        open={openAddManagersModal}
        onSave={onSaveAddManagers}
        saving={savingAddManagers}
        onCancel={() => setOpenAddManagersModal(false)}
      />
    </div>
  );
}

export default withRouter(withUser(WatchlistManagersGWPerformance));
