import React, { useEffect, useState } from "react";
import SearchFilters from "./searchFilters";
import { ReactTooltip } from "../util/tooltip.js";
import { errorCatcher } from "../util/errors";
import InfiniteScroll from "react-infinite-scroll-component";
import SDLoading from "../util/SDLoading";
import { withUser } from "../../userContext";
import CardLayout from "../cards/cardLayout";
import { getValuationOrigin } from "../util/valuationOrigin";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatPrice } from "../util/formatMoney";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import AbortController from "abort-controller";
import { allStarDefaultCompetition } from "../competitions/eligibilityCompetitionsPicker";

function CardsAdvancedSearch(props) {
  const [teamMap, setTeamMap] = useState([]);
  const [searchResults, setResults] = useState([]);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(props.user.preferredUnit);
  const [gw, setGw] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [showFilters, setShowFilters] = useState(true);
  const [abortController, setAbortController] = React.useState(new AbortController());
  const [noResult, setNoResult] = useState(false);

  useEffect(() => {
    setCurrency(props.user.preferredUnit);
  }, [props.user]);

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, []);

  const findCards = (obj) => {
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
    setLoading(true);
    let {
      positions,
      playingStatus,
      availabilityStatus,
      leagues,
      eligibilityCompetitions,
      teams,
      u23,
      l5Filter,
      l15Filter,
      l40Filter,
      age,
      floor,
      average,
      scarcity,
      gw,
      currency,
      serialNumber,
      filterName,
      isFloor,
      onSale,
      currentPrice,
      seasons,
      cardPositions,
      cardStatus,
      filterByGw,
      filterOwner,
      sortOption,
      sortOrder,
      powerRange,
      sport,
      editions,
      watchlists,
    } = obj;
    let curPrice = [];
    let pr = [];
    let best = [];
    let sr = [];
    let power = [];
    if (average.enabled) pr = [parseFloat(average.min), parseFloat(average.max)];
    if (floor.enabled) best = [parseFloat(floor.min), parseFloat(floor.max)];
    if (serialNumber.enabled) sr = [parseInt(serialNumber.min), parseInt(serialNumber.max)];
    if (currentPrice.enabled) curPrice = [parseFloat(currentPrice.min), parseFloat(currentPrice.max)];
    if (powerRange.enabled) power = [parseFloat(powerRange.min), parseFloat(powerRange.max)];
    setCurrency(currency);
    setGw(gw);
    setLoading(true);
    props
      .fetch("/apiv2/cards/newFindCards", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          position: positions,
          license: ["licensed"],
          playing_status: playingStatus,
          availability_status: availabilityStatus,
          teams: teams,
          leagues: leagues,
          competitionsEligible: Object.keys(eligibilityCompetitions).filter((k) => k !== allStarDefaultCompetition.id),
          u23: u23,
          use5: l5Filter.enabled,
          use15: l15Filter.enabled,
          use40: l40Filter.enabled,
          aa5: l5Filter.aaRange,
          da5: l5Filter.daRange,
          so5: l5Filter.scoreRange,
          games_played5: l5Filter.gmsPlayed,
          games_started5: l5Filter.gmsStarted,
          aa15: l15Filter.aaRange,
          da15: l15Filter.daRange,
          so15: l15Filter.scoreRange,
          games_played15: l15Filter.gmsPlayed,
          games_started15: l15Filter.gmsStarted,
          aa40: l40Filter.aaRange,
          da40: l40Filter.daRange,
          so40: l40Filter.scoreRange,
          games_played40: l40Filter.gmsPlayed,
          games_started40: l40Filter.gmsStarted,
          age: age,
          price_range: pr,
          bmp: best,
          scarcity: scarcity.toUpperCase(),
          gw: filterByGw ? gw : 0,
          sorare_slug: props.user.sorareSlug,
          currency: currency,
          is_bmp: isFloor,
          on_sale: onSale,
          current_price: curPrice,
          seasons: seasons,
          card_positions: cardPositions,
          serial_number: sr,
          filter_name: filterName,
          card_status: cardStatus,
          owner_filter: filterOwner,
          sort_option: sortOption,
          sort_order: sortOrder,
          power_range: power,
          card_editions: editions,
          sport: sport,
          watchlists: Object.keys(watchlists),
        }),
        signal: newController.signal,
      })
      .then((response) => response.json())
      .then((res) => {
        if (res === null) {
          setResults([]);
          setNoResult(true);
          setLoading(false);
          setDisplayedResults([]);
          ReactTooltip.rebuild();
        } else {
          if (res.error) {
            if (res.error === "request too large") {
              setErrorMsg(
                "Your request is too large and contains " +
                  res.length +
                  " cards. Please reduce the number of cards in your query to less than 1000.",
              );
            } else {
              setErrorMsg("We cannot handle this request right now. It is probably too large. Please try again.");
            }
            setLoading(false);
            return;
          }
          setResults(res.cards);
          setNoResult(false);
          setLoading(false);
          setDisplayedResults(res.cards.slice(0, 40));
          setTeamMap(res.team_map);
          ReactTooltip.rebuild();
        }
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          setResults([]);
          setNoResult(true);
          setDisplayedResults([]);
          ReactTooltip.rebuild();
        }),
      );
  };

  return (
    <div className={"space-y-2 pb-8"}>
      <div onClick={() => setShowFilters(!showFilters)}>
        <p className={"text-xs text-brand font-semibold hover:font-bold cursor-pointer"}>Show/hide filters</p>
      </div>
      <div className={"flex flex-row space-x-8"}>
        {showFilters && (
          <div className={"w-4/12 xl:w-3/12 flex flex-col space-y-4"}>
            <SearchFilters sport={props.sport} filter={(obj) => findCards(obj)} mode={"cards"} />
            {searchResults !== null && searchResults.length > 0 && (
              <p className={"text-sm text-center font-semibold"}>{searchResults.length} results</p>
            )}
          </div>
        )}
        <div className={showFilters ? "w-8/12 xl:w-9/12 flex flex-col" : "flex flex-col w-full"}>
          {isFree(props.user.tier) && (
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-advanced-search bg-cover h-upgrade-banner-l w-full mb-4"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock players status, premium filters and unlimited presets"}
            />
          )}
          {loading && <SDLoading />}
          {!loading && noResult && <p className={"text-base text-center mt-4"}>No cards found.</p>}
          {errorMsg !== "" && <p className={"font-semibold text-center text-base"}>{errorMsg}</p>}
          {searchResults !== null && (
            <InfiniteScroll
              dataLength={displayedResults.length}
              next={() => setDisplayedResults(searchResults.slice(0, displayedResults.length + 50))}
              hasMore={displayedResults.length < searchResults.length}
              onMore={() => setDisplayedResults(searchResults.slice(0, displayedResults.length + 50))}
              loader={<SDLoading />}
            >
              <div className={"flex flex-row justify-start"}>
                <div
                  className={
                    showFilters
                      ? "justify-start grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 2xl:grid-cols-4 2.5xl:grid-cols-5 gap-4"
                      : "justify-start grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 1.5xl:grid-cols-5 2xl:grid-cols-6 2.5xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-8 gap-4"
                  }
                >
                  {displayedResults.map((card, index) => {
                    let gamesPlayedL5 = card.l5;
                    let so5AvgL5 = card.avg5;
                    let gamesPlayedL20 = card.l20;
                    let so5AvgL20 = card.avg20;
                    let rahPrice = formatRealTimeAverage(card.average, currency);
                    let marketPrice = formatBestMarketPrice(card.bmp, currency);
                    let lastPrice = card.card.LastPrice;
                    let lastStatus = card.card.LastStatus;
                    let displayStatus = lastStatus;
                    if (lastStatus === "Sold") {
                      displayStatus = formatPrice(lastPrice, "eth");
                    }
                    let owner = card.owner;
                    if (card.offer.OfferId !== "") {
                      let dateToDisplay = new Date(card.offer.EndingAt);
                      let priceToDisplay = formatPrice(card.offer.ReceiveAmountInWei, "eth");
                      if (currency !== "eth") {
                        if (currency === "eur") {
                          priceToDisplay = formatPrice(card.eur, "eur");
                        } else if (currency === "usd") {
                          priceToDisplay = formatPrice(card.usd, "usd");
                        } else if (currency === "gbp") {
                          priceToDisplay = formatPrice(card.gbp, "gbp");
                        }
                      }
                      return (
                        <div>
                          <CardLayout
                            imageLink={"/card/" + card.card.TokenId}
                            picture={card.card.PictureUrl}
                            monthPrice={rahPrice}
                            marketPrice={marketPrice}
                            valuationOrigin={getValuationOrigin(card.average.ValuationOrigin)}
                            currentPrice={priceToDisplay}
                            date={dateToDisplay}
                            avgPrice={owner}
                            card={card.card}
                            player={card.player}
                            l5={so5AvgL5}
                            l20={so5AvgL20}
                            gms5={gamesPlayedL5}
                            gms20={gamesPlayedL20}
                            availability={card.player_status}
                            countdown={true}
                            width={props.width}
                            height={props.height}
                            s5={card.s5Avg}
                            s15={card.s15Avg}
                            s5Apps={card.s5Apps}
                            s15Apps={card.s15Apps}
                            gwGames={teamMap}
                            gwNumber={gw}
                          />
                        </div>
                      );
                    }
                    return (
                      <div>
                        <CardLayout
                          imageLink={"/card/" + card.card.TokenId}
                          picture={card.card.PictureUrl}
                          monthPrice={rahPrice}
                          marketPrice={marketPrice}
                          valuationOrigin={getValuationOrigin(card.average.ValuationOrigin)}
                          currentPrice={displayStatus}
                          date={false}
                          avgPrice={owner}
                          countdown={false}
                          card={card.card}
                          player={card.player}
                          gwGames={teamMap}
                          gwNumber={gw}
                          l5={so5AvgL5}
                          l20={so5AvgL20}
                          gms5={gamesPlayedL5}
                          s5={card.s5Avg}
                          s15={card.s15Avg}
                          s5Apps={card.s5Apps}
                          s15Apps={card.s15Apps}
                          gms20={gamesPlayedL20}
                          availability={card.player_status}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </InfiniteScroll>
          )}
        </div>
        <ReactTooltip />
      </div>
    </div>
  );
}

export default withUser(CardsAdvancedSearch);
