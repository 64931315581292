import React, { useEffect, useState } from "react";
import SearchFilters from "./searchFilters";
import PlayerSearchResult from "../players/playerSearchResult";
import { ReactTooltip } from "../util/tooltip.js";
import { errorCatcher } from "../util/errors";
import InfiniteScroll from "react-infinite-scroll-component";
import SDLoading from "../util/SDLoading";
import { withUser } from "../../userContext";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import AbortController from "abort-controller";
import { allStarDefaultCompetition } from "../competitions/eligibilityCompetitionsPicker";

function PlayersAdvancedSearch(props) {
  const [teamMap, setTeamMap] = useState([]);
  const [searchResults, setResults] = useState([]);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(props.user.preferredUnit);
  const [gw, setGw] = useState(0);
  const [showFilters, setShowFilters] = useState(true);
  const [abortController, setAbortController] = React.useState(new AbortController());
  const [noResult, setNoResult] = useState(false);

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, []);

  useEffect(() => {
    setCurrency(props.user.preferredUnit);
  }, [props.user]);

  const searchPlayer = (obj) => {
    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);
    setLoading(true);
    let positions = obj.positions;
    let playingStatus = obj.playingStatus;
    let availabilityStatus = obj.availabilityStatus;
    let filteredTeams = obj.teams;
    let leagues = obj.leagues;
    let u23 = obj.u23;
    let l5Filter = obj.l5Filter;
    let l15Filter = obj.l15Filter;
    let l40Filter = obj.l40Filter;
    let age = obj.age;
    let pr = [];
    let best = [];
    let floorObject = obj.floor;
    let averageObject = obj.average;
    if (averageObject.enabled) pr = [parseFloat(averageObject.min), parseFloat(averageObject.max)];
    if (floorObject.enabled) best = [parseFloat(floorObject.min), parseFloat(floorObject.max)];
    let scarcity = obj.scarcity;
    let gw = obj.gw;
    let currency = obj.currency;
    let filterByGw = obj.filterByGw;
    let filterName = obj.filterName;
    let ownerFilter = obj.filterOwner;
    let sortOption = obj.sortOption;
    let sortOrder = obj.sortOrder;
    let sport = obj.sport;
    let watchlists = obj.watchlists;
    if (filterByGw) {
      setGw(gw);
    } else setGw(0);
    setCurrency(currency);
    props
      .fetch("/apiv2/players/search", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          position: positions,
          license: ["licensed"],
          playing_status: playingStatus,
          availability_status: availabilityStatus,
          teams: filteredTeams,
          leagues: leagues,
          competitionsEligible: Object.keys(obj.eligibilityCompetitions).filter((k) => k !== allStarDefaultCompetition.id),
          u23: u23,
          use5: l5Filter.enabled,
          use15: l15Filter.enabled,
          use40: l40Filter.enabled,
          aa5: l5Filter.aaRange,
          da5: l5Filter.daRange,
          so5: l5Filter.scoreRange,
          games_played5: l5Filter.gmsPlayed,
          games_started5: l5Filter.gmsStarted,
          aa15: l15Filter.aaRange,
          da15: l15Filter.daRange,
          so15: l15Filter.scoreRange,
          games_played15: l15Filter.gmsPlayed,
          games_started15: l15Filter.gmsStarted,
          aa40: l40Filter.aaRange,
          da40: l40Filter.daRange,
          so40: l40Filter.scoreRange,
          games_played40: l40Filter.gmsPlayed,
          games_started40: l40Filter.gmsStarted,
          age: age,
          price_range: pr,
          bmp: best,
          scarcity: scarcity.toUpperCase(),
          gw: filterByGw ? gw : 0,
          sorare_slug: props.user.sorareSlug,
          currency: currency,
          filter_name: filterName,
          owner_filter: ownerFilter,
          sort_option: sortOption,
          sort_order: sortOrder,
          sport: sport,
          watchlists: Object.keys(watchlists),
        }),
        signal: newController.signal,
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.players === null) {
          setResults([]);
          setNoResult(true);
          setDisplayedResults([]);
        } else {
          setResults(res.players);
          setNoResult(false);
          setDisplayedResults(res.players.slice(0, 75));
        }
        setTeamMap(res.team_map);
        setLoading(false);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          setResults([]);
          setNoResult(true);
          setDisplayedResults([]);
          ReactTooltip.rebuild();
        }),
      );
  };

  return (
    <div className={"space-y-2"}>
      <div onClick={() => setShowFilters(!showFilters)}>
        <p className={"text-xs text-brand font-semibold hover:font-bold cursor-pointer"}>Show/hide filters</p>
      </div>
      <div className={showFilters ? "flex flex-row space-x-8" : "flex flex-row"}>
        <div className={showFilters ? "w-4/12 xl:w-3/12 flex flex-col space-y-4" : "w-0"}>
          <SearchFilters
            sport={props.sport}
            loading={displayedResults.length > 0 && loading}
            hidden={!showFilters}
            filter={(obj) => searchPlayer(obj)}
            mode={"players"}
          />
          {showFilters && searchResults !== null && searchResults.length > 0 && (
            <p className={"text-sm text-center font-semibold"}>{searchResults.length} results</p>
          )}
        </div>
        <div className={showFilters ? "w-8/12 xl:w-9/12 flex flex-col" : "flex flex-col w-full"}>
          {isFree(props.user.tier) && (
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-advanced-search bg-cover h-upgrade-banner-l w-full mb-4"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock players status, premium filters and unlimited presets"}
            />
          )}
          {loading && displayedResults?.length === 0 && <SDLoading />}
          {!loading && noResult && <p className={"text-base text-center mt-4"}>No players found.</p>}
          {searchResults !== null && (
            <InfiniteScroll
              next={() => setDisplayedResults(searchResults.slice(0, displayedResults.length + 75))}
              hasMore={displayedResults.length < searchResults.length}
              loader={SDLoading}
              dataLength={displayedResults.length}
            >
              <div
                className={
                  showFilters
                    ? "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2.5xl:grid-cols-3 gap-4"
                    : "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2.5xl:grid-cols-4 gap-4"
                }
              >
                {displayedResults.map((r) => {
                  return <PlayerSearchResult showRank={false} currency={currency} result={r} teamMap={teamMap} gw={gw} />;
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(PlayersAdvancedSearch);
