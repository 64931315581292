import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import SO5Live from "./SO5Live";
import { GLOBAL_MARGIN_DARK } from "../util/margin";
import SelectSearch from "react-select-search";
import NotOptimized from "../util/notOptimized";
import { errorCatcher } from "../util/errors";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import SportsPicker from "../util/sportsPicker";
import BaseballLiveLineups from "../SO7/baseballLiveLineups";
import BasketballLiveLineups from "./basketballLiveLineups";
import { useQuery } from "../util/useQuery";
import { Dropdown } from "../util/dropdown";
import { SportNotSupported } from "../util/sportNotSupported";

function SO5Wrapper(props) {
  const query = useQuery();

  const [gwIds, setGwIds] = useState([]);
  const [gwValue, setGwValue] = useState(0);
  const [sport, setSport] = useState(query.get("sport") || sorareFootball);
  const [managerInfo, setManagerInfo] = useState({});

  let username = props.match?.params.username;
  if (!username) {
    username = props.username;
  }

  useEffect(() => {
    let user = props.match?.params.username;
    if (!user) {
      user = props.username;
    }
    props
      .fetch("/apiv2/manager/info/" + user)
      .then((response) => response.json())
      .then((res) => {
        setManagerInfo(res);
      })
      .catch(errorCatcher());
  }, [props.username, props.match?.params?.username]);

  useEffect(() => {
    props
      .fetch("/apiv2/gw/resultsList?sport=" + sport)
      .then((response) => response.json())
      .then((res) => {
        let gwIds = [];
        let newGwValue = res[0].GwNumber;
        setGwValue(newGwValue);
        res.map((gw) => {
          gwIds.push({ value: gw.GwNumber, name: gw.DisplayName });
          return null;
        });
        setGwIds(gwIds);
      })
      .catch(errorCatcher());
    document.title = "SO5 results - " + username;
  }, [sport]);

  let isThere = false;
  gwIds.map((gw) => {
    if (gw.value === gwValue) {
      isThere = true;
      return null;
    }
  });

  const managerInfoName = managerInfo?.manager?.Nickname || "";
  return (
    <div className={"bg-black-14"}>
      <div className={GLOBAL_MARGIN_DARK}>
        <NotOptimized />
        <div className={"flex flex-row justify-between pt-8 pb-2"}>
          <p className={"font-semibold font-headers text-2xl text-white self-center"}>
            {managerInfoName ? (username !== props.user.sorareSlug ? managerInfoName + " Sorare results" : "My Sorare results") : null}
          </p>
          <div className={"flex flex-row space-x-4"}>
            <div className={"flex flex-row justify-center "}>
              <SportsPicker
                dark={true}
                picked={"text-brand-dark"}
                bg={"text-[#CCCCCC] bg-[#1F1F1F]"}
                font={"font-sans"}
                change={(s) => setSport(s)}
                sport={sport}
                showAll={false}
              />
            </div>
            <div className={"space-y-2 z-10 self-center"}>
              <div className={"text-center w-300 mx-auto z-10"}>
                <Dropdown
                  dark
                  displayOptions={{
                    dark: true,
                    roundedTopFocus: "rounded-t-2xl",
                    rounded: "rounded-3xl",
                    roundedBottomFocus: "rounded-b-xl",
                  }}
                  selected={gwValue}
                  options={gwIds}
                  onChange={(v) => setGwValue(v)}
                />
              </div>
            </div>
          </div>
        </div>

        {sport === sorareFootball && (
          <div>{gwIds === undefined || gwIds.length === 0 ? <p /> : <SO5Live gw={gwValue} username={username} />}</div>
        )}

        {sport === sorareBaseball && isThere && (
          <div>{gwIds === undefined || gwIds.length === 0 ? <p /> : <BaseballLiveLineups gw={gwValue} username={username} />}</div>
        )}

        {sport === sorareBasketball && isThere && <SportNotSupported sport={sport} featureName="Sorare Results" />}
      </div>
    </div>
  );
}

export default withUser(SO5Wrapper);
