import React from "react";
import Locker from "../../img/locker-icon.svg";

function CardLocker(props) {
  let tip = props.tip;
  if (tip === undefined) {
    tip = "Star membership needed";
  }
  let bg = props.bg;
  if (bg === undefined) {
    bg = "bg-builder-header";
  }
  return (
    <div className={"flex flex-row justify-center rounded-b-lg space-x-1 py-2 " + bg} data-tip={tip}>
      <div className="bg-grey-e0 rounded-lg w-11 h-2 justify-center align-center items-center flex">
        <img src={Locker}></img>
      </div>
    </div>
  );
}

export default CardLocker;
