import React from "react";
import CommonCard from "../../img/common-card.svg";
import LimitedCard from "../../img/limited-card.svg";
import RareCard from "../../img/rare-card.svg";
import SuperRareCard from "../../img/sr-card.svg";
import UniqueCard from "../../img/unique-card.svg";
import CustomSeriesCard from "../../img/custom-series-card.svg";

const NewCardIcon = (props) => {
  const scarcity = props.scarcity?.toLowerCase();
  if (scarcity === "common") {
    return <img className={"w-full h-full object-contain"} src={CommonCard} />;
  } else if (scarcity === "limited") {
    return <img className={"w-full h-full object-contain"} src={LimitedCard} />;
  } else if (scarcity === "rare") {
    return <img className={"w-full h-full object-contain"} src={RareCard} />;
  } else if (scarcity === "super rare") {
    return <img className={"w-full h-full object-contain"} src={SuperRareCard} />;
  } else if (scarcity === "unique") {
    return <img className={"w-full h-full object-contain"} src={UniqueCard} />;
  } else if (scarcity === "custom series") {
    return <img className={"w-full h-full object-contain"} src={CustomSeriesCard} />;
  }
  return <img className={"w-full h-full object-contain"} src={LimitedCard} />;
};

export default NewCardIcon;
