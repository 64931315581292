import { ReactComponent as IconCircle } from "../../img/icons-circle.svg";
import { playing_status_labels } from "../util/playingStatus";
import ScoreAverage from "../decision/scoreAverage";
import PlayerRollingAverage from "./playerRollingAverage";
import PlayerBestMarketPrice from "./playerBestMarketPrice";
import format from "date-fns/format";
import React, { useState } from "react";
import { withUser } from "../../userContext";
import { units_objects } from "../util/units";
import CardSelected from "../../img/card-selected.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import PriceAlertInput from "../util/priceAlertInput";
import { scarcities_objects_not_all } from "../util/scarcities";
import ordinal_suffix_of from "../util/ordinalNumber";
import PlayerAvatar from "../util/playerAvatar";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import PlayerAvailability from "./playerAvailability";
import DisableDimmer from "../user/priceAlertDisableDimmer";
import { sorareBaseball } from "../util/sports";

const PlayerSearchListResult = (props) => {
  const { sortBy, displayedPlayers, selectedPlayers } = props;
  const { notifications, onChangeNotifications, onDeleteNotification, notificationLimitations, onActivatePriceAlert } = props;
  const sortableColumns = props.sortableColumns || [
    "rankingPosition",
    "rankingOverall",
    "l5",
    "l15",
    "l40",
    "realTimeLimited",
    "bmpLimited",
    "realTimeRare",
    "bmpRare",
    "realTimeSuperRare",
    "bmpSuperRare",
    "realTimeUnique",
    "bmpUnique",
  ];
  const columns = props.columns || [
    "name",
    "rankingPosition",
    "rankingOverall",
    "l5",
    "l15",
    "l40",
    "realTimeLimited",
    "bmpLimited",
    "realTimeRare",
    "bmpRare",
    "realTimeSuperRare",
    "bmpSuperRare",
    "realTimeUnique",
    "bmpUnique",
  ];
  const actionItems = props.actionItems || [];
  const selectable = props.selectable || false;
  const onSelect = props.onSelect;

  const [hoverOnPlayer, setHoverOnPlayer] = useState("");

  const unit = units_objects.find((obj) => obj.value === props.user.preferredUnit)?.name || "";

  const sortingStyle = (column) => {
    let style = [];
    if (sortableColumns.includes(column)) {
      style.push("hover:opacity-70 cursor-pointer");
    }
    if (sortBy && sortBy.includes(column)) {
      style.push("opacity-90 font-bold");
    }
    return style.join(" ");
  };
  const labelStyle = (column) => (sortableColumns.includes(column) ? "w-full" : "");
  const sortArrowStyle = (column) => {
    let style = ["fill-white my-auto"];
    if (sortableColumns.includes(column) && sortBy && sortBy.includes(column)) {
      style.push("visible");
      if (sortBy.includes("desc")) {
        style.push("transform rotate-180");
      }
    } else {
      style.push("hidden");
    }
    return style.join(" ");
  };

  const onChangeSort = (column) => {
    if (sortableColumns.includes(column)) {
      props.onChangeSort && props.onChangeSort(column);
    }
  };

  const changeNotification = (notification) => {
    columns.includes("notifications") && onChangeNotifications && onChangeNotifications(notification);
  };

  const deletePriceAlert = (playerId, scarcity) => {
    columns.includes("notifications") && onDeleteNotification && onDeleteNotification(playerId, scarcity);
  };

  const activatePriceAlert = (playerId, scarcity) => {
    columns.includes("notifications") && onActivatePriceAlert && onActivatePriceAlert(playerId, scarcity);
  };

  return (
    <table className="table-auto bg-focus shadow rounded-lg h-auto overflow-hidden text-xs font-semibold w-full overflow-auto">
      <thead>
        <tr>
          {columns.includes("name") && (
            <th
              style={{ minWidth: "16em" }}
              className={"col-span-3 p-4 bg-brand-black text-white bg-brand-black text-left z-10 sticky left-0" + sortingStyle("name")}
            >
              <div className={"m-auto flex flex-row w-full gap-1"}>
                <p className={labelStyle("name")}>Name</p>
                <span className={"my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("name")} />
                </span>
              </div>
            </th>
          )}
          {actionItems.length > 0 && props.actionFirst && <th className={"p-2 bg-brand-black w-0"}></th>}
          {columns.includes("notifications") && (
            <th colSpan={4} className={"p-2 bg-brand-black"}>
              <div className={"flex flex-row justify-evenly"}>
                <div
                  style={{ width: "14em" }}
                  className={"p-2 bg-brand-black text-white text-center " + sortingStyle("priceAlertLimited")}
                  onClick={() => onChangeSort("priceAlertLimited")}
                  title={"Price alert for 'Limited' scarcity"}
                >
                  <div className={"relative m-auto w-full"}>
                    <div className={"flex flex-col gap-1"}>
                      <span className={"fill-limited self-center"}>
                        <IconCircle />
                      </span>
                      <span className={labelStyle("realTimeUnique")}>Price alert</span>
                    </div>
                    <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                      <SortArrowIcon className={sortArrowStyle("priceAlertLimited")} />
                    </span>
                  </div>
                </div>
                <div
                  style={{ width: "14em" }}
                  className={"p-2 bg-brand-black text-white text-center " + sortingStyle("priceAlertRare")}
                  onClick={() => onChangeSort("priceAlertRare")}
                  title={"Price alert for 'Rare' scarcity"}
                >
                  <div className={"relative m-auto w-full"}>
                    <div className={"flex flex-col gap-1"}>
                      <span className={"fill-rare self-center"}>
                        <IconCircle />
                      </span>
                      <span className={labelStyle("priceAlertRare")}>Price alert</span>
                    </div>
                    <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                      <SortArrowIcon className={sortArrowStyle("priceAlertRare")} />
                    </span>
                  </div>
                </div>
                <div
                  style={{ width: "14em" }}
                  className={"p-2 bg-brand-black text-white text-center " + sortingStyle("priceAlertSuperRare")}
                  onClick={() => onChangeSort("priceAlertSuperRare")}
                  title={"Price alert for 'Super Rare' scarcity"}
                >
                  <div className={"relative m-auto w-full"}>
                    <div className={"flex flex-col gap-1"}>
                      <span className={"fill-super-rare self-center"}>
                        <IconCircle />
                      </span>
                      <span className={labelStyle("priceAlertSuperRare")}>Price alert</span>
                    </div>
                    <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                      <SortArrowIcon className={sortArrowStyle("priceAlertSuperRare")} />
                    </span>
                  </div>
                </div>
                <div
                  style={{ width: "14em" }}
                  className={"p-2 bg-brand-black text-white text-center " + sortingStyle("priceAlertUnique")}
                  onClick={() => onChangeSort("priceAlertUnique")}
                  title={"Price alert for 'Unique' scarcity"}
                >
                  <div className={"relative m-auto w-full"}>
                    <div className={"flex flex-col gap-1"}>
                      <span className={"fill-unique self-center"}>
                        <IconCircle />
                      </span>
                      <span className={labelStyle("priceAlertUnique")}>Price alert</span>
                    </div>
                    <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                      <SortArrowIcon className={sortArrowStyle("priceAlertUnique")} />
                    </span>
                  </div>
                </div>
              </div>
            </th>
          )}
          {columns.includes("l5") && (
            <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("l5")} onClick={() => onChangeSort("l5")}>
              <div className={"relative m-auto w-full"}>
                <p className={labelStyle("l5")}>L5</p>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("l5")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("l15") && (
            <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("l15")} onClick={() => onChangeSort("l15")}>
              <div className={"relative m-auto w-full"}>
                <p className={labelStyle("l15")}>L15</p>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("l15")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("l40") && (
            <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle("l40")} onClick={() => onChangeSort("l40")}>
              <div className={"relative m-auto w-full"}>
                <p className={labelStyle("l40")}>L40</p>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("l40")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("rankingPosition") && (
            <th
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("rankingPosition")}
              onClick={() => onChangeSort("rankingPosition")}
            >
              <div className={"relative m-auto w-full"}>
                <p className={labelStyle("rankingPosition")}>Position</p>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("rankingPosition")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("rankingOverall") && (
            <th
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("rankingOverall")}
              onClick={() => onChangeSort("rankingOverall")}
            >
              <div className={"relative m-auto w-full"}>
                <p className={labelStyle("rankingOverall")}>Overall</p>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("rankingOverall")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("realTimeLimited") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("realTimeLimited")}
              onClick={() => onChangeSort("realTimeLimited")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-limited self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("realTimeLimited")}>Valuation {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("realTimeLimited")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("bmpLimited") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("bmpLimited")}
              onClick={() => onChangeSort("bmpLimited")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-limited self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("bmpLimited")}>Floor {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("bmpLimited")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("realTimeRare") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("realTimeRare")}
              onClick={() => onChangeSort("realTimeRare")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-rare self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("realTimeRare")}>Valuation {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("realTimeRare")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("bmpRare") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("bmpRare")}
              onClick={() => onChangeSort("bmpRare")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-rare self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("bmpRare")}>Floor {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("bmpRare")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("realTimeSuperRare") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("realTimeSuperRare")}
              onClick={() => onChangeSort("realTimeSuperRare")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-super-rare self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("realTimeSuperRare")}>Valuation {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("realTimeSuperRare")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("bmpSuperRare") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("bmpSuperRare")}
              onClick={() => onChangeSort("bmpSuperRare")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-super-rare self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("bmpSuperRare")}>Floor {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("bmpSuperRare")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("realTimeUnique") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("realTimeUnique")}
              onClick={() => onChangeSort("realTimeUnique")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-unique self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("realTimeUnique")}>Valuation {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("realTimeUnique")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("bmpUnique") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("bmpUnique")}
              onClick={() => onChangeSort("bmpUnique")}
            >
              <div className={"relative m-auto w-full"}>
                <div className={"flex flex-col gap-1"}>
                  <span className={"fill-unique self-center"}>
                    <IconCircle />
                  </span>
                  <span className={labelStyle("bmpUnique")}>Floor {unit}</span>
                </div>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("bmpUnique")} />
                </span>
              </div>
            </th>
          )}
          {columns.includes("added") && (
            <th
              style={{ minWidth: "7em" }}
              className={"p-2 bg-brand-black text-white text-center " + sortingStyle("added")}
              onClick={() => onChangeSort("added")}
            >
              <div className={"relative m-auto w-full"}>
                <p className={labelStyle("added")}>Added on</p>
                <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
                  <SortArrowIcon className={sortArrowStyle("added")} />
                </span>
              </div>
            </th>
          )}
          {actionItems.length > 0 && !props.actionFirst && <th className={"p-2 bg-brand-black w-0"}></th>}
        </tr>
      </thead>
      <tbody>
        {displayedPlayers.map((p, i) => {
          const isSelected = selectable && selectedPlayers[p.player.PlayerId];
          const isHover = hoverOnPlayer === p.player.PlayerId;
          const cardIconStyle = isHover || isSelected ? (isHover && !isSelected ? "opacity-60" : "") : "invisible";
          const notifsForPlayer = notifications?.[p.player.PlayerId];
          const notificationActive =
            notifsForPlayer !== undefined ? (Object.values(notifsForPlayer).find((notif) => !notif.active) ? false : true) : undefined;
          const playingStatus =
            (t1OrAbove(props.user?.tier) || p.player.Sport === sorareBaseball) && playing_status_labels[p.player.PlayingStatus];
          if (!props.onlySorare || p.Licensed) {
            return (
              <tr
                key={p.player.PlayerId}
                className={`${notificationActive ? "" : "relative"} border-b border-grey-e9 ${selectable ? " cursor-pointer " : ""} ${
                  selectable && isHover ? " bg-grey-f2 " : ""
                }`}
                onClick={selectable ? () => onSelect(p.player) : undefined}
                onMouseEnter={selectable ? () => setHoverOnPlayer(p.player.PlayerId) : undefined}
                onMouseLeave={selectable ? () => setHoverOnPlayer("") : undefined}
              >
                {columns.includes("name") && (
                  <td
                    style={{ minWidth: "16em", backgroundClip: "padding-box" }}
                    className={
                      "px-1 sm:px-3 border-r border-grey-e9 z-20 sticky left-0 " + (selectable && isHover ? " bg-grey-f2 " : "bg-focus")
                    }
                  >
                    <div className={"flex flex-row"}>
                      {selectable && <img className={"w-5 h-5 my-auto mr-2 " + cardIconStyle} src={CardSelected} />}
                      <div className={"flex self-center"}>
                        <PlayerAvatar player={p.player} />
                      </div>
                      <div className={"flex flex-col ml-3 self-center"}>
                        <div className={"flex flex-row self-left items-center"}>
                          <p className={"text-left text-sm font-semibold hover:font-bold"}>
                            <a href={"/player/" + p.player.PlayerId} target={"_blank"} rel="noreferrer">
                              {p.player.DisplayName}
                            </a>
                          </p>
                          <PlayerAvailability position="center bottom" availability={p.availability_status} size={"ml-1 w-4"} />
                        </div>
                        <p className={"text-left text-xs font-normal"}>
                          {p.player.Position} - {p.player.Age} {playingStatus && `- ` + playingStatus}
                        </p>
                      </div>
                    </div>
                  </td>
                )}
                {actionItems.length > 0 && props.actionFirst && <td className={"px-2"}>{actionItems[i]}</td>}
                {columns.includes("notifications") && (
                  <td colSpan={scarcities_objects_not_all.length}>
                    <div className={`${notificationActive ? "" : "relative"} flex flex-row justify-evenly`}>
                      <DisableDimmer
                        active={notificationActive}
                        playerId={p.player.PlayerId}
                        sport={p.player.Sport}
                        limitations={notificationLimitations}
                        onActivate={activatePriceAlert}
                      />
                      <>
                        {scarcities_objects_not_all.map((o) => {
                          const scarcity = o.value;
                          const notif = notifications?.[p.player.PlayerId] ? notifications[p.player.PlayerId][scarcity] : undefined;
                          return (
                            <div key={scarcity} style={{ width: "14em" }}>
                              <div className={"w-full flex justify-center"}>
                                <PriceAlertInput
                                  scarcity={scarcity}
                                  player={p.player}
                                  notification={notif}
                                  onChange={changeNotification}
                                  onDelete={deletePriceAlert}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    </div>
                  </td>
                )}
                {columns.includes("l5") && (
                  <td className={"py-2"}>
                    <ScoreAverage
                      avg={p.scores_5.AverageNoDnp}
                      powerAdj={false}
                      sport={p.player.Sport}
                      period={5}
                      sAvg={p.scores_5.SorareAverage}
                      sApps={p.scores_5.SorareApps}
                      played={p.scores_5.GamesPlayed}
                    />
                  </td>
                )}
                {columns.includes("l15") && (
                  <td className={"py-2"}>
                    <ScoreAverage
                      avg={p.scores_15.AverageNoDnp}
                      sport={p.player.Sport}
                      powerAdj={false}
                      period={15}
                      sAvg={p.scores_15.SorareAverage}
                      sApps={p.scores_15.SorareApps}
                      played={p.scores_15.GamesPlayed}
                    />
                  </td>
                )}
                {columns.includes("l40") && (
                  <td className={"py-2"}>
                    <ScoreAverage
                      avg={p.scores_40.AverageNoDnp}
                      sport={p.player.Sport}
                      powerAdj={false}
                      period={40}
                      played={p.scores_40.GamesPlayed}
                    />
                  </td>
                )}
                {columns.includes("rankingPosition") && (
                  <td className={"py-2"}>
                    <p className={"text-xs 2.5xl:text-sm font-semibold text-center"}>
                      {p.player_ranking.ranking_position > 0 ? ordinal_suffix_of(p.player_ranking.ranking_position) : "No rank"}
                    </p>
                  </td>
                )}
                {columns.includes("rankingOverall") && (
                  <td className={"py-2"}>
                    <p className={"text-xs 2.5xl:text-sm font-semibold text-center"}>
                      {p.player_ranking.ranking_overall > 0 ? ordinal_suffix_of(p.player_ranking.ranking_overall) : "No rank"}
                    </p>
                  </td>
                )}
                {columns.includes("realTimeLimited") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerRollingAverage
                      color={"#ecbc0c"}
                      direction={"col"}
                      average={p.real_time_limited}
                      period={p.real_time_limited.ValuationOrigin}
                    />
                  </td>
                )}
                {columns.includes("bmpLimited") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerBestMarketPrice color={"#ecbc0c"} direction={"col"} bmp={p.bmp_limited} />
                  </td>
                )}
                {columns.includes("realTimeRare") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerRollingAverage
                      color={"#cd3434"}
                      direction={"col"}
                      average={p.real_time_rare}
                      period={p.real_time_rare.ValuationOrigin}
                    />
                  </td>
                )}
                {columns.includes("bmpRare") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerBestMarketPrice color={"#cd3434"} direction={"col"} bmp={p.bmp_rare} />
                  </td>
                )}
                {columns.includes("realTimeSuperRare") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerRollingAverage
                      color={"#1fa1ff"}
                      direction={"col"}
                      average={p.real_time_super_rare}
                      period={p.real_time_super_rare.ValuationOrigin}
                    />
                  </td>
                )}
                {columns.includes("bmpSuperRare") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerBestMarketPrice color={"#1fa1ff"} direction={"col"} bmp={p.bmp_sr} />
                  </td>
                )}
                {columns.includes("realTimeUnique") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerRollingAverage
                      color={"#4F4F4F"}
                      direction={"col"}
                      average={p.real_time_unique}
                      period={p.real_time_unique.ValuationOrigin}
                    />
                  </td>
                )}
                {columns.includes("bmpUnique") && (
                  <td className={"px-2"} style={{ minWidth: "7em" }}>
                    <PlayerBestMarketPrice color={"#4F4F4F"} direction={"col"} bmp={p.bmp_u} />
                  </td>
                )}
                {columns.includes("added") && (
                  <td className={"px-2"}>
                    <div className={"flex flex-row justify-center"}>{format(new Date(p.added), "dd/MM/yyyy")}</div>
                  </td>
                )}
                {actionItems.length > 0 && !props.actionFirst && <td className={"px-2"}>{actionItems[i]}</td>}
              </tr>
            );
          } else {
            return <tr></tr>;
          }
        })}
      </tbody>
    </table>
  );
};

export default withUser(PlayerSearchListResult);
