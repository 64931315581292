import React, { useCallback, useEffect, useRef, useState } from "react";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { Spinning } from "../loader/spinner";
import AbortController from "abort-controller";
import orderBy from "lodash-es/orderBy";

const CircularPlayerCount = (props) => {
  const { total_players } = props;
  if (total_players === undefined || total_players === null) {
    return null;
  }
  return (
    <span
      className={`${
        total_players === 0 ? "opacity-30" : ""
      } text-xxs font-bold rounded-full flex justify-center items-center text-on-primary bg-primary w-5 h-5`}
      title={`${total_players} available players in league`}
    >
      <span className={"my-auto"}>{total_players}</span>
    </span>
  );
};

const CustomSelect = ({ loading, options, selected, onChange, gw }) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const selectedObj = options.find(({ value }) => selected === value);
  return (
    <div className={"relative w-full"} ref={ref}>
      <button
        onClick={gw && !loading ? () => setFocus(!focus) : undefined}
        className={`h-full text-md my-auto bg-surface-container px-3 py-2 w-full ${
          focus
            ? "rounded-t-md border-x border-t border-primary border-opacity-70"
            : "border border-transparent-inverse-surface-low border-opacity-10 hover:border-primary rounded-md"
        } flex flex-row justify-between`}
      >
        <span className={"my-auto w-5/6 truncate text-left font-medium leading-5 text-on-surface"}>
          {gw && selected ? selectedObj?.name || "" : ""}
        </span>
        <div className={"flex flex-row my-auto gap-2"}>
          {loading ? (
            <Spinning className={"h-5 w-5"} />
          ) : gw && selected ? (
            <CircularPlayerCount total_players={selectedObj?.count} />
          ) : null}
          <div className={"flex flex-col justify-center"}>
            <SortArrowIcon className={`fill-on-surface h-3 w-3 ${focus ? "transform rotate-180" : ""}`} />
          </div>
        </div>
      </button>
      {focus && (
        <div
          className={"absolute bg-surface-container rounded-b-md w-full overflow-hidden border-x border-b border-primary border-opacity-70"}
        >
          <ul className={"h-full overflow-y-scroll"} style={{ maxHeight: "15em" }}>
            {options.map((option, i) => {
              return (
                <li
                  key={option.value}
                  onClick={() => {
                    setFocus(false);
                    onChange(option.value);
                  }}
                  className={`${i !== 0 ? "border-t" : ""} border-transparent-inverse-surface-low border-opacity-10 ${
                    selected && option.value === selected
                      ? "bg-containers-surface-focus-active-primarytint font-semibold text-primary"
                      : "text-on-surface"
                  } hover:bg-containers-surface-hover-primarytint cursor-pointer`}
                >
                  <div className="w-full h-full px-3 py-2">
                    <button
                      value={option.value}
                      className={`w-full ${option.level === 1 ? "pl-4" : ""} ${
                        option.count > 0 ? "font-semibold" : ""
                      } cursor-pointer text-left text-sm flex flex-row justify-between`}
                    >
                      <span className={"w-5/6 truncate"}>{option.name}</span>
                      <div className={"flex flex-row gap-2 my-auto"}>
                        <CircularPlayerCount total_players={option.count} />
                        {/* for circular count to be aligned with the one in the dropdown value*/}
                        <div className={"invisible flex flex-col justify-center"}>
                          <SortArrowIcon className={`fill-on-surface h-3 w-3`} />
                        </div>
                      </div>
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

const LineupBuilderFootballLeaguePicker = (props) => {
  const { gw, divId, draftId, targetUserId, filters, allowedLeagues, selectedPosition } = props;
  const [loading, setLoading] = useState(false);
  const [leagueCount, setLeagueCount] = useState({});
  const [abortController, setAbortController] = useState(new AbortController());
  useEffect(() => {
    if (divId == "" || divId == undefined || divId == null || draftId == "" || draftId == undefined || draftId == null) {
      return;
    }
    setLoading(true);
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    props
      .fetch("/decision-api/decision/lub/breakdown/splitLeagues", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userSorareId: targetUserId,
          lineupPosition: selectedPosition,
          position: filters.position,
          scarcity: filters.scarcity,
          availabilityStatus: filters.availabilityStatus,
          not_playing: filters.showNGPlayers,
          division_full_id: divId,
          draft_id: draftId,
          include_picked_players: true,
        }),
        signal: newAbortController.signal,
      })
      .then((response) => response.json())
      .then((res) => {
        setLeagueCount(res);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  }, [
    gw,
    divId,
    draftId,
    targetUserId,
    selectedPosition,
    // all filters except for league
    filters.position,
    filters.scarcity,
    filters.availabilityStatus,
    filters.showNGPlayers,
    filters.communityProjections,
    filters.cost,
    filters.l5,
    filters.l15,
    filters.l40,
  ]);

  const onChange = useCallback(
    (v) => {
      props.onChange && props.onChange(v);
    },
    [props.onChange],
  );

  let leaguesOptions = [];
  if (allowedLeagues) {
    let ll = leaguesOptions.concat(
      ...Object.keys(allowedLeagues).map((k) => {
        return {
          value: k,
          name: k,
          level: 0,
          count: leagueCount[k] || 0,
        };
      }),
    );
    ll = orderBy(ll, ["count", "name"], ["desc", "asc"]);
    ll.forEach((entry) => {
      leaguesOptions.push(entry);
      const subLeagues = allowedLeagues[entry.value];
      if (subLeagues) {
        let subLeaguesOptions = subLeagues.map((sl) => {
          return {
            value: sl,
            name: sl,
            level: 1,
            count: leagueCount[sl] || 0,
          };
        });
        subLeaguesOptions = orderBy(subLeaguesOptions, ["count", "name"], ["desc", "asc"]);
        leaguesOptions.push(...subLeaguesOptions);
      }
    });
  }
  leaguesOptions = [{ value: "all", name: "All leagues", level: 0 }, ...leaguesOptions];

  return (
    <div>
      <div className={"flex flex-row gap-2"}>
        <CustomSelect gw={gw} loading={loading} options={leaguesOptions} selected={filters.league} onChange={onChange} />
      </div>
    </div>
  );
};

export default withUser(LineupBuilderFootballLeaguePicker);
