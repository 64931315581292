import React from "react";
import { withUser } from "../../userContext";
import { formatPrice } from "../util/formatMoney";
import { capitalize } from "../util/capitalize";
import FloorPrice from "../../img/best-price.svg";

function PlayerBestMarketPrice(props) {
  let img = FloorPrice;
  let tip = "Floor price";
  let bmp = props.bmp;
  let displayBmp;
  if (bmp === null || bmp === undefined || bmp.Price === -1 || bmp.Price === 0) {
    displayBmp = "---";
  } else {
    const unit = props.user.preferredUnit;
    if (unit === "eth") {
      displayBmp = formatPrice(bmp.Price, unit);
    } else {
      displayBmp = formatPrice(bmp?.["Price" + capitalize(unit)], unit);
      if (bmp?.["Price" + capitalize(unit)] === -1) {
        displayBmp = "---";
      }
    }
  }

  let direction = props.direction || "row";
  return (
    <div className={`w-full flex flex-${direction} justify-between`}>
      <div className={direction === "row" ? "w-5 h-5" : "self-center"} data-tip={tip}>
        <img src={img} className={"w-full h-full"} />
      </div>
      <p className={"font-semibold text-sm self-center"} style={{ color: props.color }}>
        {displayBmp}
      </p>
    </div>
  );
}

export default withUser(PlayerBestMarketPrice);
