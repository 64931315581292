import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import CardIcon from "../util/cardIcon";
import ordinal_suffix_of from "../util/ordinalNumber";
import { ReactTooltip } from "../util/tooltip.js";
import BoxWithToggle from "../util/boxWithToggle";
import SelectSearch from "react-select-search";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { capitalize } from "../util/capitalize";
import MultiScarcityPicker from "../util/multiScarcityPicker";
import { errorCatcher } from "../util/errors";
import { t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";

function PlayerTierGraph(props) {
  const [filteredGraph, setFilteredGraph] = useState([]);

  const graph = props.graph;
  const range = props.range;
  const scarcities = props.scarcities;

  useEffect(() => {
    if (props.graph.length > 0) {
      setFilteredGraph(props.graph);
    }
  }, [props.graph]);

  const filterWithKeys = (d) => {
    if (d.id === "RARE") {
      return "#cd3434";
    } else if (d.id === "SUPER RARE") {
      return "#1fa1ff";
    } else if (d.id === "LIMITED") {
      return "#E7B62C";
    }
  };

  let hasRewards = false;
  filteredGraph.map((d) => {
    d.data.map((e) => {
      if (e.reward === true) hasRewards = true;
    });
  });

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
    if (payload.reward) {
      return (
        <svg x={cx - 6} y={cy - 6} width={32} height={32} fill={stroke}>
          <g transform="translate(6 6)">
            <circle r="6" fill={"#27A260"} />
          </g>
        </svg>
      );
    }
    return (
      <svg x={cx - 4} y={cy - 4} width={8} height={8} fill={stroke}>
        <g transform="translate(4 4)">
          <circle r="2" fill={stroke} />
        </g>
      </svg>
    );
  };

  return (
    <div>
      {filteredGraph !== null && filteredGraph?.length > 0 && (
        <div style={{ height: "25rem" }} className={"w-full bg-white rounded-lg relative"}>
          <ResponsiveContainer className={"relative"}>
            <LineChart animate={false} margin={{ top: 30, right: 30, bottom: 10, left: 10 }}>
              <CartesianGrid stroke={"#dddddd"} />
              <XAxis
                dataKey="x"
                type="number"
                allowDuplicatedCategory={false}
                domain={[range[0], range[1]]}
                axisLine={{ stroke: "white" }}
                style={{
                  fontSize: "0.65rem",
                  fontFamily: "Inter, sans-serif",
                  marginTop: "10px",
                  fill: "#000000",
                  fontWeight: 500,
                }}
              />
              <Legend
                wrapperStyle={{
                  fontSize: "0.75rem",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 500,
                  marginTop: "16px",
                }}
                style={{
                  fontSize: "0.65rem",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 500,
                  marginTop: "10px",
                }}
              />
              <YAxis
                reversed={true}
                tickFormatter={(v) => {
                  if (v === 99) return "Tier 0";
                  else if (v === 199) return "Tier 1";
                  else if (v === 299) return "Tier 2";
                  else if (v === 399) return "Tier 3";
                  else if (v === 499) return "Tier 4";
                  else if (v === 599) return "Tier 5";
                  else if (v === 1) return "Top tier 0";
                }}
                ticks={[1, 99, 199, 299, 399, 499, 599]}
                domain={[1, 599]}
                axisLine={{ stroke: "white" }}
                type="number"
                style={{
                  fontSize: "0.65rem",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 500,
                  marginTop: "10px",
                  fill: "#000000",
                }}
              />
              {filteredGraph.map((l) => {
                let color = filterWithKeys(l);
                if (scarcities.indexOf(l.id) >= 0) {
                  return (
                    <Line
                      dot={<CustomizedDot />}
                      strokeWidth={2}
                      stroke={color}
                      fill={color}
                      connectNulls={true}
                      data={l.data}
                      type={"monotone"}
                      dataKey={"y"}
                      name={capitalize(l.id.toLowerCase())}
                      key={l.id}
                    />
                  );
                }
              })}
              {hasRewards && (
                <Line
                  strokeWidth={2}
                  stroke={"#27A260"}
                  fill={"#27A260"}
                  type={"monotone"}
                  dataKey={"y"}
                  name={"Your rewards"}
                  key={"rewards"}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}

function PlayerTiers(props) {
  const [tiers, setTiers] = useState([]);
  const [graph, setGraph] = useState([]);
  const [graphRange, setGraphRange] = useState([]);
  const [graphChoices, setGraphChoices] = useState([]);
  const [graphChoice, setGraphChoice] = useState("");
  const [hideNotInPool, setHideNotInPool] = useState(false);
  const [period, setPeriod] = useState("40");
  const [scarcities, setScarcities] = useState(["LIMITED", "RARE"]);

  useEffect(() => {
    getTiers();
  }, [props.player]);

  const changePeriod = (p) => {
    setPeriod(p);
    getTiers(graphChoice, p);
  };

  const getTiers = (c, p) => {
    let graph = "";
    if (c !== undefined) {
      graph = "&graph=" + c;
    }
    let periodValue = p || period;
    props
      .fetch("/apiv2/players/tiers?playerId=" + props.player.PlayerId + graph + "&period=" + periodValue)
      .then((response) => response.json())
      .then((res) => {
        let graphOptions = [];
        if (res.graph_choices !== null) {
          res.graph_choices.map((c) => {
            graphOptions.push({ name: c, value: c });
          });
          if (c === undefined) {
            setGraphChoices(graphOptions);
            setGraphChoice(graphOptions[0].value);
          }
        }
        setTiers(res.stats);
        setGraphRange(res.graph_range);
        setGraph(res.graph);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  const changeScarcity = (s) => {
    let newScarcities = [...scarcities];
    if (newScarcities.indexOf(s) >= 0) {
      newScarcities = newScarcities.filter(function (value, index, arr) {
        return value !== s;
      });
    } else {
      newScarcities.push(s);
    }
    setScarcities(newScarcities);
  };

  let activeClass = "border-b-4 border-brand h-full font-semibold text-sm text-brand focus:outline-none self-center";
  let inactiveClass =
    "bg-focus border-b-4 border-white hover:text-brand text-textGrey4 h-full rounded self-center font-semibold text-sm focus:outline-none";

  return (
    <div className={"w-11/12 mx-auto 3xl:w-10/12 4xl:w-9/12 space-y-4 mb-6"}>
      {t2OrAbove(props.user.tier) && (
        <div className="space-y-4">
          <div className={"flex flex-row space-x-4"}>
            <div>
              <SelectSearch
                options={graphChoices}
                value={graphChoice}
                onChange={(v) => {
                  getTiers(v);
                  setGraphChoice(v);
                }}
              />
            </div>
            <div className={"w-full flex flex-col xl:flex-row mx-auto text-base justify-between space-y-4 xl:space-y-0 xl:space-x-4"}>
              <div className={"md:space-x-2 md:flex md:flex-row grid grid-cols-4 gap-2 bg-white px-4 rounded"}>
                <button className={period === "15" ? activeClass : inactiveClass} onClick={() => changePeriod("15")}>
                  L15
                </button>
                <button className={period === "40" ? activeClass : inactiveClass} onClick={() => changePeriod("40")}>
                  L40
                </button>
                <button className={period === "60" ? activeClass : inactiveClass} onClick={() => changePeriod("60")}>
                  L60
                </button>
                <button className={period === "80" ? activeClass : inactiveClass} onClick={() => changePeriod("80")}>
                  L80
                </button>
                <button className={period === "100" ? activeClass : inactiveClass} onClick={() => changePeriod("100")}>
                  L100
                </button>
                <button className={period === "all" ? activeClass : inactiveClass} onClick={() => changePeriod("all")}>
                  All
                </button>
              </div>
            </div>
            <div>
              <MultiScarcityPicker noUnique={true} scarcity={scarcities} onChange={(s) => changeScarcity(s)} />
            </div>
          </div>
          <div>
            <PlayerTierGraph scarcities={scarcities} graph={graph} range={graphRange} period={period} />
          </div>
          <div className={"grid grid-cols-2 2xl:grid-cols-3"}>
            <div className={"py-2 bg-white rounded-lg"}>
              <BoxWithToggle
                isActive={hideNotInPool}
                setActive={() => setHideNotInPool(!hideNotInPool)}
                label={"Hide gameweeks where player is not in pools"}
              />
            </div>
          </div>
        </div>
      )}
      {t1OrBelow(props.user.tier) && (
        <UpgradeLimitBox
          className={"bg-upgrade-bg-tiers-player bg-cover h-upgrade-banner-s w-full"}
          title={"Want more insights?"}
          description={"Become a Star member to unlock Tiers full history & graphs"}
        />
      )}
      <div>
        <table className={"border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}>
          <thead>
            <tr className="text-center">
              <th className="rounded-tl-lg text-white bg-brand-black border-b border-gray-200 mx-auto py-3 pl-2 text-left w-1/7 font-bold uppercase text-xs">
                Gameweek
              </th>
              <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/7 font-bold uppercase text-xs">
                Limited pools
              </th>
              <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/7 font-bold uppercase text-xs">
                Limited rewards
              </th>
              <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/7 font-bold uppercase text-xs">
                Rare pools
              </th>
              <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/7 font-bold uppercase text-xs">
                Rare rewards
              </th>
              <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/7 font-bold uppercase text-xs">
                Super rare pools
              </th>
              <th className="rounded-tr-lg text-white  bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/7 font-bold uppercase text-xs">
                Super rare rewards
              </th>
            </tr>
          </thead>
          <tbody>
            {tiers !== undefined &&
              tiers !== null &&
              tiers.map((t) => {
                const gw = t.gameweek;
                if (hideNotInPool && t.limited_stats === null && t.rare_stats === null && t.super_rare_stats === null) return null;
                return (
                  <tr className={"border-b border-grey-e5 text-sm"}>
                    <td>
                      <div className={"flex flex-col space-y-0.5 text-sm py-2 pl-2"}>
                        <p className={"font-semibold text-brand"}>GW {gw.GwNumber}</p>
                        <p className={"font-medium text-xs"}>{gw.DisplayName.split(":")[1]}</p>
                      </div>
                    </td>
                    <td>
                      <div className={"py-2"}>
                        {t.limited_stats === null && <p>Not in pool</p>}
                        {t.limited_stats !== null &&
                          t.limited_stats.map((l) => {
                            return (
                              <div>
                                <p>
                                  <span className={"font-semibold"}>Tier {l.tier}</span>{" "}
                                  <span className={"font-medium text-xs"}>
                                    ({l.division} - <span data-tip={"Position in pool"}>{l.pool_position}%</span>)
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <div className={"py-2"}>
                        {t.limited_rewards === null && <p>Not rewarded</p>}
                        {t.limited_rewards !== null &&
                          t.limited_rewards.map((l) => {
                            let icons = [];
                            for (let i = 0; i < l.count; i++) {
                              icons.push(
                                <div>
                                  <CardIcon scarcity={"limited"} />
                                </div>,
                              );
                            }
                            let positions = [];
                            l.positions.map((p, i) => {
                              positions.push(
                                <span>
                                  {ordinal_suffix_of(p)}
                                  {i < l.positions.length - 1 && ", "}
                                </span>,
                              );
                            });
                            return (
                              <div className={"flex flex-row space-x-1"}>
                                {icons}
                                <p>
                                  <span className={"font-semibold"}>T{l.tier}</span>{" "}
                                  <span className={"font-medium text-xs"}>
                                    ({l.division} - {positions})
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <div className={"py-2"}>
                        {t.rare_stats === null && <p>Not in pool</p>}
                        {t.rare_stats !== null &&
                          t.rare_stats.map((l) => {
                            return (
                              <div>
                                <p>
                                  <span className={"font-semibold"}>Tier {l.tier}</span>{" "}
                                  <span className={"font-medium text-xs"}>
                                    ({l.division} - <span data-tip={"Position in pool"}>{l.pool_position}%</span>)
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <div className={"py-2"}>
                        {t.rare_rewards === null && <p>Not rewarded</p>}
                        {t.rare_rewards !== null &&
                          t.rare_rewards.map((l) => {
                            let icons = [];
                            for (let i = 0; i < l.count; i++) {
                              icons.push(
                                <div>
                                  <CardIcon scarcity={"rare"} />
                                </div>,
                              );
                            }
                            let positions = [];
                            l.positions.map((p, i) => {
                              positions.push(
                                <span>
                                  {ordinal_suffix_of(p)}
                                  {i < l.positions.length - 1 && ", "}
                                </span>,
                              );
                            });
                            return (
                              <div className={"flex flex-row space-x-1"}>
                                {icons}
                                <p>
                                  <span className={"font-semibold"}>T{l.tier}</span>{" "}
                                  <span className={"font-medium text-xs"}>
                                    ({l.division} - {positions})
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <div className={"py-2"}>
                        {t.super_rare_stats === null && <p>Not in pool</p>}
                        {t.super_rare_stats !== null &&
                          t.super_rare_stats.map((l) => {
                            return (
                              <div>
                                <p>
                                  <span className={"font-semibold"}>Tier {l.tier}</span>{" "}
                                  <span className={"font-medium text-xs"}>
                                    ({l.division} - <span data-tip={"Position in pool"}>{l.pool_position}%</span>)
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <div className={"py-2"}>
                        {t.super_rare_rewards === null && <p>Not rewarded</p>}
                        {t.super_rare_rewards !== null &&
                          t.super_rare_rewards.map((l) => {
                            let icons = [];
                            for (let i = 0; i < l.count; i++) {
                              icons.push(
                                <div>
                                  <CardIcon scarcity={"super rare"} />
                                </div>,
                              );
                            }
                            let positions = [];
                            l.positions.map((p, i) => {
                              positions.push(
                                <span>
                                  {ordinal_suffix_of(p)}
                                  {i < l.positions.length - 1 && ", "}
                                </span>,
                              );
                            });
                            return (
                              <div className={"flex flex-row space-x-1"}>
                                {icons}
                                <p>
                                  <span className={"font-semibold"}>T{l.tier}</span>{" "}
                                  <span className={"font-medium text-xs"}>
                                    ({l.division} - {positions})
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default withUser(PlayerTiers);
