export function getNextGameweeks(props) {
  let fetchFunc = props?.fetch;
  if (fetchFunc === undefined) {
    fetchFunc = fetch;
  }
  return fetchFunc("/apiv2/gw/next")
    .then((response) => response.json())
    .then(async (res) => {
      return res;
    });
}

export function getNextGameweeksObjects(props) {
  let fetchFunc = props?.fetch;
  if (fetchFunc === undefined) {
    fetchFunc = fetch;
  }
  return fetchFunc("/apiv2/gw/next")
    .then((response) => response.json())
    .then(async (res) => {
      let tab = [];
      res.map((gw) => {
        tab.push({ value: gw.GwNumber, name: gw.DisplayName });
      });
      return tab;
    });
}

export function getAllGameweeksObjects(props, sport) {
  let fetchFunc = props?.fetch;
  if (fetchFunc === undefined) {
    fetchFunc = fetch;
  }
  let sportQuery = "";
  if (sport) {
    sportQuery = "?sport=" + sport;
  }
  return fetchFunc("/apiv2/gw/all" + sportQuery)
    .then((response) => response.json())
    .then(async (res) => {
      return res;
    });
}

export function fetchGameweekGames(props, gw) {
  let fetchFunc = props?.fetch;
  if (fetchFunc === undefined) {
    fetchFunc = fetch;
  }
  return fetchFunc("/apiv2/games/teamMap/" + gw)
    .then((response) => response.json())
    .then((res) => {
      console.log(res);
      return res;
    });
}
