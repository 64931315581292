import React, { useEffect, useRef, useState } from "react";
import { getThemeColor, useTheme } from "../../themeContext";
import { formatNumber } from "../util/formatNumber";
import LiveCircle from "../util/liveCircle";
import { ReactComponent as PlayersLeftIcon } from "../../img/players-left-icon.svg";
import PopperPortal from "../players/helpers/popper";
import { ControlledShortLineup } from "../lineup/shortLineup";
import { sorareFootball } from "../util/sports";

export const BestGws = (props) => {
  const { scores, size, withHover, sport } = props;
  return (
    <div className={`flex flex-row ${size === "sm" ? "gap-0.5 sm:gap-1" : "gap-2"}`}>
      {scores.map((s, i) => {
        return (
          <ScoreTagWithLineup
            key={i}
            width={size === "sm" ? "w-8 xs:w-10" : undefined}
            textSize={size === "sm" ? "text-xxxs xs:text-xxs" : undefined}
            score={s?.score}
            lineupId={s?.lineupId}
            withHover={withHover}
            sport={sport}
          />
        );
      })}
    </div>
  );
};

export const ScoreTagWithLineup = (props) => {
  const { lineupId, score, withHover, width, textSize, sport } = props;
  const [focus, setFocus] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const divNode = divRef.current;

    const handleEvent = (event) => {
      if (divNode) {
        if (divNode.contains(event.target)) {
          setTimeout(() => setFocus(true), 250);
        } else {
          setFocus(false);
        }
      }
    };

    document.addEventListener("mouseover", handleEvent);

    return () => {
      document.removeEventListener("mouseover", handleEvent);
    };
  }, [divRef]);

  const sportIsSupported = sport === sorareFootball;
  if (
    score === null ||
    score === undefined ||
    lineupId === null ||
    lineupId === undefined ||
    lineupId === "" ||
    withHover === undefined ||
    withHover === false ||
    !sportIsSupported
  ) {
    return <ScoreTag score={score} width={width} textSize={textSize} />;
  }
  return (
    <div ref={divRef} className={"relative flex flex-col justify-center self-center cursor-pointer h-full"}>
      {focus && (
        <PopperPortal active={focus}>
          <ControlledShortLineup sport={sport} lineupId={lineupId} />
        </PopperPortal>
      )}
      <ScoreTag score={score} width={width} textSize={textSize} />
    </div>
  );
};

export const BestScoresByGw = (props) => {
  const { scores, league, dataTipId } = props;
  const { startGw, sorareStartGw, sorareSeason, disabledGws } = league;
  return (
    <div className={"flex gap-1 justify-center flex-nowrap px-1"}>
      {scores.map((s, i) => {
        const gw = i + startGw;
        const gwToDisplay = sorareStartGw ? `${i + sorareStartGw} ${sorareSeason}` : gw;
        if (disabledGws && disabledGws.includes(gw)) {
          return null;
        }
        return <BestScoresByGwItem key={i} score={s} nbBestLineups={league.nbBestLineups} gw={gwToDisplay} dataTipId={dataTipId} />;
      })}
    </div>
  );
};

export const getBestScoresFromAllScores = (scores, nbBestLineups) => {
  const res =
    scores
      ?.filter((s) => s?.best)
      ?.sort((a, b) => {
        const aScore = parseFloat(a.score);
        const bScore = parseFloat(b.score);
        if (aScore < bScore) {
          return 1;
        } else if (aScore > bScore) {
          return -1;
        } else {
          return 0;
        }
      })
      ?.slice(0, nbBestLineups) || [];
  if (res.length < nbBestLineups) {
    for (let i = res.length; i < nbBestLineups; i++) {
      res.push(null);
    }
  }
  return res;
};

export const getScoresNormalized = (scores, endGw, startGw) => {
  let scoresNormalized = scores;
  if (scoresNormalized === null || scoresNormalized === undefined) {
    const nbGw = endGw - startGw + 1;
    scoresNormalized = [];
    for (let i = 0; i < nbGw; i++) {
      scoresNormalized.push(null);
    }
  }
  return scoresNormalized;
};

const formatScore = (score) => {
  if (score === null || score === undefined) {
    return "";
  }
  return formatNumber(parseFloat(score), undefined, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });
};

export const BestScoresByGwItem = (props) => {
  const { score, nbBestLineups, gw, dataTipId } = props;
  const { theme } = useTheme();
  const outlineVariant = getThemeColor(theme, "outline-variant");
  const outline = getThemeColor(theme, "outline");
  const scaleExcellent = getThemeColor(theme, "quality-scale-excellent-quality-container-bright-fixed");
  const scaleTextExcellent = getThemeColor(theme, "quality-scale-excellent-quality-container-dim-fixed");
  const notPlayed = score === null || score === undefined;
  const best = !notPlayed && score?.best === true;
  const playedButNotBest = !notPlayed && score?.best === false;
  const scoreFormatted = formatScore(score?.score);
  const tip =
    "<b>GW #" +
    gw +
    ": </b>" +
    (notPlayed
      ? "Remaining, or not played"
      : best
      ? "Played with " + scoreFormatted + " points, part of the best " + nbBestLineups
      : "Played with " + scoreFormatted + " points but not part of the best " + nbBestLineups);
  return (
    <div className={"w-2 xs:w-3"} data-tip={tip} data-html={true} data-for={dataTipId}>
      <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_752_60751)">
          <circle
            cx="5"
            cy="5"
            r={notPlayed ? "4.5" : "5"}
            fill={playedButNotBest ? outlineVariant : best ? scaleExcellent : "none"}
            stroke={notPlayed ? outlineVariant : "none"}
          />
          {best && <path d="M4 7.2L2 5.2L2.7 4.5L4 5.8L7.3 2.5L8 3.2L4 7.2Z" fill={scaleTextExcellent} />}
          {playedButNotBest && (
            <path
              d="M4.99935 5.58366L2.95768 7.62533C2.88129 7.70171 2.78407 7.73991 2.66602 7.73991C2.54796 7.73991 2.45074 7.70171 2.37435 7.62533C2.29796 7.54894 2.25977 7.45171 2.25977 7.33366C2.25977 7.2156 2.29796 7.11838 2.37435 7.04199L4.41602 5.00033L2.37435 2.95866C2.29796 2.88227 2.25977 2.78505 2.25977 2.66699C2.25977 2.54894 2.29796 2.45171 2.37435 2.37533C2.45074 2.29894 2.54796 2.26074 2.66602 2.26074C2.78407 2.26074 2.88129 2.29894 2.95768 2.37533L4.99935 4.41699L7.04102 2.37533C7.1174 2.29894 7.21463 2.26074 7.33268 2.26074C7.45074 2.26074 7.54796 2.29894 7.62435 2.37533C7.70074 2.45171 7.73893 2.54894 7.73893 2.66699C7.73893 2.78505 7.70074 2.88227 7.62435 2.95866L5.58268 5.00033L7.62435 7.04199C7.70074 7.11838 7.73893 7.2156 7.73893 7.33366C7.73893 7.45171 7.70074 7.54894 7.62435 7.62533C7.54796 7.70171 7.45074 7.73991 7.33268 7.73991C7.21463 7.73991 7.1174 7.70171 7.04102 7.62533L4.99935 5.58366Z"
              fill={outline}
            />
          )}
        </g>
        <defs>
          <clipPath id="clip0_752_60751">
            <rect width="10" height="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const ScoreTag = (props) => {
  const { score } = props;
  const width = props.width || "w-12";
  const textSize = props.textSize || "text-xs";
  const hasScore = score !== null && score !== undefined;
  const bg = props.bg || "bg-transparent-inverse-surface-low bg-opacity-10";
  const scoreFormatted = hasScore ? formatScore(score) : "";
  return (
    <div
      className={`flex px-1 py-1 text-on-surface font-semibold ${textSize} ${
        hasScore ? bg : "border border-outline-variant"
      } rounded-3xl ${width} text-center justify-center items-center`}
    >
      {scoreFormatted === "" ? <span className={"invisible"}>0</span> : isNaN(parseFloat(scoreFormatted)) ? "-" : scoreFormatted}
    </div>
  );
};

export const CurrentGWLineupStats = (props) => {
  const { lineup, sport } = props;
  const lineupId = lineup?.LineupId || lineup?.lineupId;
  const hasLineup = lineupId !== undefined && lineupId !== "";
  const score = lineup?.Score || lineup?.score;
  const playersLeftFeatureSupported = lineup?.CardsLeft !== undefined && lineup?.CardsLeft !== null;
  const playersLeft = (lineup?.CardsLeft || 0) + (lineup?.CardsLive || 0);
  const liveLabelPosition = props.liveLabelPosition || "right";
  const size = props.size || "md";
  return (
    <div className={"relative rounded-lg border border-outline-variant"}>
      <div
        className={`flex flex-row gap-2 items-center justify-center ${size === "sm" ? "py-1 px-1" : "p-2"} ${hasLineup ? "" : "invisible"}`}
      >
        <ScoreTagWithLineup withHover sport={sport} score={score} lineupId={lineup?.LineupId} />
        {playersLeftFeatureSupported && (
          <div className={`relative ${playersLeft === 0 ? "opacity-30" : ""}`}>
            <PlayersLeft
              textLeftSize={size === "md" ? "text-xxs" : "text-xxxs"}
              textCardsSize={size === "md" ? "text-xs" : "text-xxs"}
              cardsLeft={lineup?.CardsLeft}
              cardsLive={lineup?.CardsLive}
            />
          </div>
        )}
        {liveLabelPosition === "right" && playersLeftFeatureSupported && <LiveStatus playersLeft={playersLeft} />}
      </div>
      {!hasLineup && (
        <div
          className={`absolute h-full top-0 w-full flex justify-center items-center text-on-surface-variant ${
            size === "md" ? "text-xs" : "text-xxs"
          } font-semibold`}
        >
          <p>No lineup</p>
        </div>
      )}
      {liveLabelPosition === "top" && playersLeftFeatureSupported && (
        <div className={"absolute top-0 w-full flex justify-center items-center text-on-surface-variant font-semibold"}>
          <div className={"bg-surface-container px-1 -mt-1.5"}>
            <LiveStatus textSize={"text-xxs"} playersLeft={playersLeft} />
          </div>
        </div>
      )}
    </div>
  );
};

const LiveStatus = (props) => {
  const { playersLeft } = props;
  const { theme } = useTheme();
  const textSize = props.textSize || "text-xs";
  const noPlayersLeft = playersLeft === undefined || playersLeft === null || playersLeft === 0;
  return (
    <div
      className={`flex flex-row font-semibold ${textSize} gap-2 ${
        noPlayersLeft ? "opacity-30 text-on-surface-variant" : "text-live-on-surface"
      }`}
    >
      <div className={"self-center"}>
        <LiveCircle color={getThemeColor(theme, playersLeft === 0 ? "on-surface-variant" : "live-on-surface")} />
      </div>
      Live
    </div>
  );
};

const PlayersLeft = (props) => {
  const { cardsLeft, cardsLive } = props;
  const totalCards = cardsLeft + cardsLive;
  const textCardsSize = props.textCardsSize || "text-xs";
  const textLeftSize = props.textLeftSize || "text-xxs";
  return (
    <div className={"flex flex-col gap-0.5 items-center self-center justify-center"}>
      <div className={"relative"}>
        <PlayersLeftIcon className={`w-6 object-contain fill-on-surface-variant`} />
        <p
          className={`absolute ${textCardsSize} font-semibold text-surface-container left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2`}
        >
          {totalCards}
        </p>
      </div>
      <p className={`${textLeftSize} font-semibold text-center text-on-surface-variant`}>left</p>
    </div>
  );
};
