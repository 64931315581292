import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { findPriceForUserUnit, formatPrice } from "../util/formatMoney";
import SDLoading from "../util/SDLoading";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import CardLayout from "../cards/cardLayout";
import { getValuationOrigin } from "../util/valuationOrigin";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import PrivateOfferBox from "./privateOfferBox";
import TradeListView from "./tradeListView";

function PrivateOfferSide(props) {
  const [sortedCards, setSortedCards] = useState(props.cards);
  const [sortHow, setSortHow] = useState(1);

  useEffect(() => {
    setSortedCards(props.cards);
  }, [props.cards]);

  const cards = props.cards;
  const unit = props.user.preferredUnit;
  const totalValue = props.totalValue;
  const offer = props.offer;
  const listView = props.listView;

  const sortData = (w) => {
    let newCards = [...cards];
    let how = sortHow * -1;
    setSortHow(how);
    let key = "";
    if (w === "l5") {
      key = "avg5";
    } else if (w === "l15") {
      key = "avg20";
    } else if (w === "bonus") {
      key = "Power";
      newCards.sort(function (a, b) {
        if (a.card[key] < b.card[key]) {
          return -1 * how;
        } else if (a.card[key] > b.card[key]) {
          return how;
        }
        return 0;
      });
      setSortedCards(newCards);
      return;
    } else if (w === "floor") {
      key = "Price";
      newCards.sort(function (a, b) {
        if (a.bmp[key] < b.bmp[key]) {
          return -1 * how;
        } else if (a.bmp[key] > b.bmp[key]) {
          return how;
        }
        return 0;
      });
      setSortedCards(newCards);
      return;
    } else if (w === "rt") {
      key = "Average";
      newCards.sort(function (a, b) {
        if (a.rt[key] < b.rt[key]) {
          return -1 * how;
        } else if (a.rt[key] > b.rt[key]) {
          return how;
        }
        return 0;
      });
      setSortedCards(newCards);
      return;
    }
    newCards.sort(function (a, b) {
      if (a[key] < b[key]) {
        return -1 * how;
      } else if (a[key] > b[key]) {
        return how;
      }
      return 0;
    });
    setSortedCards(newCards);
  };

  return (
    <div className={"space-y-4 w-full"}>
      {cards !== undefined ? (
        <div className={"flex flex-col space-y-4 bg-white p-4 rounded-lg"}>
          <div className={offer.Status !== "ONGOING" ? "grid grid-cols-4 gap-4" : "grid grid-cols-3 gap-4"}>
            <PrivateOfferBox
              label={"Cash sent"}
              value={formatPrice(totalValue["value_" + unit].eth, unit)}
              secondaryValue={
                unit === "eth" ? formatPrice(totalValue["value_eur"].eth, "eur") : formatPrice(totalValue["value_eth"].eth, "eth")
              }
            />
            {offer.Status !== "ONGOING" && (
              <PrivateOfferBox
                label={"Valuation that day"}
                value={formatPrice(totalValue["value_" + unit].valuation_history, unit)}
                secondaryValue={
                  unit === "eth"
                    ? formatPrice(totalValue["value_eur"].valuation_history, "eur")
                    : formatPrice(totalValue["value_eth"].valuation_history, "eth")
                }
                warning={totalValue.history_incomplete}
                tip={"Be careful, some cards do not have historical valuations."}
              />
            )}
            <PrivateOfferBox
              label={offer.Status === "ONGOING" ? "Cash + floor prices" : "Cash + floors now"}
              value={formatPrice(totalValue["value_" + unit].floor, unit)}
              secondaryValue={
                unit === "eth" ? formatPrice(totalValue["value_eur"].floor, "eur") : formatPrice(totalValue["value_eth"].floor, "eth")
              }
              warning={totalValue.incomplete}
              tip={"Be careful, some cards do not currently have floor prices."}
            />
            <PrivateOfferBox
              brand={true}
              label={offer.Status === "ONGOING" ? "Cash + last averages" : "Cash + averages now"}
              value={formatPrice(totalValue["value_" + unit].cards, unit)}
              secondaryValue={
                unit === "eth" ? formatPrice(totalValue["value_eur"].cards, "eur") : formatPrice(totalValue["value_eth"].cards, "eth")
              }
              warning={totalValue.average_incomplete}
              tip={"Be careful, some cards do not have valuations as they have not been sold publicly."}
            />
          </div>
          {!listView && (
            <div className={"flex flex-row justify-start"}>
              <div className={"grid grid-cols-2 2xl:grid-cols-3 3.2xl:grid-cols-4 gap-4"}>
                {sortedCards !== null &&
                  !listView &&
                  sortedCards.map((cp) => {
                    let lastPrice = cp.card.LastPrice || cp.card_last_move?.details.price;
                    let lastStatus = cp.card.LastStatus;
                    let displayStatus = lastStatus;
                    let ethCurrentPrice = "";
                    if (lastStatus === "Sold" || lastStatus === "") {
                      displayStatus = findPriceForUserUnit(lastPrice, cp.card_last_move?.details, unit);
                      ethCurrentPrice = formatPrice(lastPrice, "eth");
                    }
                    return (
                      <div key={cp.card.Slug}>
                        <CardLayout
                          card={cp.card}
                          lastPrice={displayStatus}
                          border={" border border-grey-e5"}
                          imageLink={"/card/" + cp.card.TokenId}
                          picture={cp.card.PictureUrl}
                          monthPrice={formatRealTimeAverage(cp.rt, props.user.preferredUnit)}
                          marketPrice={formatBestMarketPrice(cp.bmp, props.user.preferredUnit)}
                          valuationOrigin={
                            props.user.preferredUnit === "eth"
                              ? getValuationOrigin(cp.rt.ValuationOrigin)
                              : getValuationOrigin(cp.rt.ValuationOrigin)
                          }
                          ethCurrentPrice={ethCurrentPrice}
                          currentPrice={displayStatus}
                          date={false}
                          countdown={false}
                          avgPrice={""}
                          player={cp.player}
                          gwNumber={props.gwNumber}
                          l5={cp.avg5}
                          l20={cp.avg20}
                          gms5={cp.gms5}
                          gms20={cp.gms20}
                          s5={cp.s5Avg}
                          s15={cp.s15Avg}
                          s5Apps={cp.s5Apps}
                          s15Apps={cp.s15Apps}
                          availability={cp.status}
                          gwGames={props.games}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {listView && sortedCards !== null && sortedCards.length > 0 && (
            <div>
              <table
                className={"z-0 min-h-48 border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}
              >
                <thead>
                  <th className="rounded-tl-lg text-white bg-brand-black border-b border-gray-200 mx-auto py-3 pl-2 text-left w-3/12 font-bold uppercase text-xs">
                    Card
                  </th>
                  <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/24 font-bold uppercase text-xs"></th>
                  <th
                    onClick={() => sortData("l5")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1/12 font-bold uppercase text-xs"
                  >
                    L5
                  </th>
                  <th
                    onClick={() => sortData("l15")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                  >
                    L15
                  </th>
                  <th
                    onClick={() => sortData("floor")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1.5/12 font-bold uppercase text-xs"
                  >
                    Floor
                  </th>
                  <th
                    onClick={() => sortData("rt")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1.5/12 font-bold uppercase text-xs"
                  >
                    Average
                  </th>
                  <th
                    onClick={() => sortData("bonus")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                  >
                    Bonus
                  </th>
                  <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-2/12 font-bold uppercase text-xs">
                    Next game
                  </th>
                  <th className="text-center rounded-tr-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"></th>
                </thead>
                <tbody>
                  {sortedCards &&
                    sortedCards.map((cp) => {
                      return <TradeListView showRemove={false} info={cp} teamMap={props.teamMap} unit={unit} />;
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ) : (
        <div>
          <SDLoading />
        </div>
      )}
    </div>
  );
}

export default withUser(PrivateOfferSide);
