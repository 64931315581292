import format from "date-fns/format";
import { Link } from "react-router-dom";
import EmptyAvatar from "../../img/empty-avatar.svg";
import WatchlistPlayerTypeIcon from "../../img/icon-watchlists-type-player.svg";
import WatchlistManagerTypeIcon from "../../img/icon-watchlists-type-manager.svg";
import WatchlistFollowingButton from "./watchlistFollowingButton";
import React, { useEffect, useState } from "react";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { labelFromNumber } from "../util/formatNumber";
import { sorareBaseball, sorareBasketball, sorareFootball, sportLabelsSimple } from "../util/sports";
import { withUser } from "../../userContext";
import { ReactTooltip } from "../util/tooltip.js";
import { ManagerVerifiedCheckMark } from "../util/manager";

const WatchlistsList = (props) => {
  const { displayedWatchlists, onChangeSort, sortBy } = props;
  const [nbFollowersById, setNbFollowersById] = useState({});
  const sortableColumns = ["updated", "created", "nbFollowers", "nbItems", "name"];

  useEffect(() => ReactTooltip.rebuild(), [displayedWatchlists]);

  const onChangeFollow = (id, nbFollowers) => {
    setNbFollowersById({ ...nbFollowersById, [id]: nbFollowers });
  };

  const sortingStyle = (column) => {
    let style = [];
    if (sortableColumns.includes(column)) {
      style.push("hover:opacity-70 cursor-pointer");
    }
    if (sortBy.includes(column)) {
      style.push("bg-brand-black-light font-bold");
    } else {
      style.push("bg-brand-black");
    }
    return style.join(" ");
  };
  const sortArrowStyle = (column) => {
    let style = ["fill-white my-auto"];
    if (sortableColumns.includes(column) && sortBy.includes(column)) {
      style.push("visible");
      if (sortBy.includes("desc")) {
        style.push("transform rotate-180");
      }
    } else {
      style.push("invisible");
    }
    return style.join(" ");
  };

  return (
    <>
      <ReactTooltip />
      <table className="table-auto rounded- bg-focus rounded-lg h-auto overflow-hidden text-xs font-semibold w-full overflow-auto text-left">
        <thead>
          <tr>
            <th
              style={{ minWidth: "16em" }}
              onClick={() => onChangeSort("name")}
              className={"p-3 text-white text-left z-20 sticky left-0 " + sortingStyle("name")}
            >
              <div className={"flex flex-row justify-between"}>
                <p>Name</p>
                <SortArrowIcon className={sortArrowStyle("name")} />
              </div>
            </th>
            <th className={"p-3 text-white " + sortingStyle("owner")}>
              <p>Owner</p>
            </th>
            <th className={"p-3 text-white " + sortingStyle("sport")}>
              <p>Sport</p>
            </th>
            <th className={"p-3 text-white " + sortingStyle("type")}>
              <p>Type</p>
            </th>
            <th className={"p-3 text-white " + sortingStyle("nbItems")} onClick={() => onChangeSort("nbItems")}>
              <div className={"flex flex-row justify-between"}>
                <p>Content</p>
                <SortArrowIcon className={sortArrowStyle("nbItems")} />
              </div>
            </th>
            <th className={"p-3 text-white " + sortingStyle("nbFollowers")} onClick={() => onChangeSort("nbFollowers")}>
              <div className={"flex flex-row justify-between"}>
                <p>Followers</p>
                <SortArrowIcon className={sortArrowStyle("nbFollowers")} />
              </div>
            </th>
            <th className={"p-3 text-white " + sortingStyle("created")} onClick={() => onChangeSort("created")}>
              <div className={"flex flex-row justify-between"}>
                <p>Created on</p>
                <SortArrowIcon className={sortArrowStyle("created")} />
              </div>
            </th>
            <th className={"p-3 text-white " + sortingStyle("updated")} onClick={() => onChangeSort("updated")}>
              <div className={"flex flex-row justify-between"}>
                <p>Updated on</p>
                <SortArrowIcon className={sortArrowStyle("updated")} />
              </div>
            </th>
            <th className={"p-3 bg-brand-black"}></th>
          </tr>
        </thead>
        <tbody>
          {displayedWatchlists.map((w) => {
            const connectedUserIsOwner = w.owner === props.user.sorareSlug;
            return (
              <tr key={w.id} className={"border-b border-grey-e9"}>
                <td style={{ minWidth: "16em", backgroundClip: "padding-box" }} className={"p-3 z-20 sticky left-0 bg-focus"}>
                  {w.enabled ? (
                    <Link to={`/watchlists/id/${w.id}`}>
                      <span className="text-sm font-semibold">{w.name}</span>
                    </Link>
                  ) : (
                    <span data-tip="Star membership" className="text-sm font-semibold text-textGrey3">
                      {w.name}
                    </span>
                  )}
                </td>
                <td className={"p-3"}>
                  <span className={"whitespace-nowrap"} style={{ maxWidth: "10em" }} title={w.ownerDisplayName || w.owner}>
                    <span className="flex items-center">
                      <span className={"rounded-full mr-2"}>
                        <img className={"rounded-full inline w-7 h-7 object-cover"} src={w.pictureUrl ? w.pictureUrl : EmptyAvatar} />
                      </span>
                      <span className={"text-sm font-semibold mr-2"}>
                        <span className="flex gap-1">
                          <a href={"/manager/" + w.owner}>{w.ownerDisplayName || w.owner}</a>
                          <ManagerVerifiedCheckMark managerSlug={w.owner} />
                        </span>
                      </span>
                    </span>
                  </span>
                </td>
                <td className={"p-3"}>
                  <div className={"flex flex-row gap-2"}>
                    {w.sport === sorareBaseball && <span className={"my-auto text-sm font-semibold"}>⚾️️</span>}
                    {w.sport === sorareFootball && <span className={"my-auto text-sm font-semibold"}>⚽</span>}
                    {w.sport === sorareBasketball && <span className={"my-auto text-sm font-semibold"}>🏀</span>}
                    <span className={"my-auto text-sm font-semibold"}>
                      {sportLabelsSimple.find((sport) => sport.value === w.sport).name}
                    </span>
                  </div>
                </td>
                <td className={"p-3"}>
                  <span className={"flex flex-row gap-2 my-auto"}>
                    <span className={"inline hidden 2xl:inline"}>
                      <img
                        className={"w-7 h-7"}
                        style={{ minWidth: "fit-content" }}
                        src={w.type === "player" ? WatchlistPlayerTypeIcon : WatchlistManagerTypeIcon}
                      />
                    </span>
                    <span className={"my-auto capitalize text-sm font-semibold"}>{w.type}</span>
                  </span>
                </td>
                <td className={"p-3"}>
                  <span className="text-sm xl:text-base font-semibold">{labelFromNumber(w.nbItems)}</span>
                </td>
                <td className={"p-3"}>
                  <span className="text-sm xl:text-base font-semibold">
                    {labelFromNumber(nbFollowersById[w.id] !== undefined ? nbFollowersById[w.id] : w.nbFollowers)}
                  </span>
                </td>
                <td className={"p-3"}>
                  <span className="text-sm xl:text-base font-semibold">{format(new Date(w.created), "dd/MM/yyyy")}</span>
                </td>
                <td className={"p-3"}>
                  <span className="text-sm xl:text-base font-semibold">{format(new Date(w.updated), "dd/MM/yyyy")}</span>
                </td>
                <td className={"p-3"}>
                  <span className={"my-auto"}>
                    <WatchlistFollowingButton
                      onFollow={onChangeFollow}
                      onUnfollow={onChangeFollow}
                      id={w.id}
                      enabled={w.enabled}
                      connectedUserIsOwner={connectedUserIsOwner}
                      nbFollowers={w.nbFollowers}
                      isFollowed={w.isFollowed}
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default withUser(WatchlistsList);
