import { withUser } from "../../userContext";
import React from "react";
import { formatNumber } from "../util/formatNumber";
import IconSorareTarget from "../../img/icon-sorare-target.svg";
import clsx from "clsx";

function Cap240Indicator(props) {
  const { indicators, isLub } = props;
  const containerClass = clsx("flex items-center text-center", isLub ? "space-y-1 flex-col" : "space-x-1");

  return (
    <div className={containerClass}>
      {isLub ? (
        <span className="text-white font-semibold text-xs sm:text-sm flex justify-center">
          <img className="fill-surface-container mr-1" src={IconSorareTarget} alt="" width="16" height="16" />
          Odds
        </span>
      ) : (
        <span className="font-semibold text-xs text-on-surface-variant">% thresholds odds:</span>
      )}
      <div className={isLub ? "space-y-1 text-xxs sm:text-sm" : "flex items-center justify-center space-x-1 text-xs"}>
        {indicators?.map((indicator, index) => (
          <div className="bg-surface-container-low text-on-surface bg-opacity-70 rounded-full py-0.5 px-2 font-semibold" key={index}>
            <span>{indicator.score} · </span>{" "}
            {formatNumber(indicator.percentage, undefined, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
            %
          </div>
        ))}
      </div>
    </div>
  );
}

export default withUser(Cap240Indicator);
