import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { Button } from "../util/button";

function EmailSetup(props) {
  const [alertMsg, setAlertMsg] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(props.connectedUser.Email);
  }, [props.connectedUser]);

  const existingEmail = props.connectedUser.Email;
  const existingEmailVerified = props.connectedUser.Verified;

  const sendVerificationEmail = () => {
    props
      .fetch("/apiv2/user/email_verification", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setAlertMsg("An email was sent to " + email);
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"text-left text-base space-y-4 mt-8 text-sm font-medium"}>
      <p className={"text-xl text-primary font-headers font-semibold"}>Email settings</p>
      {existingEmail !== "" && (
        <p>
          Your current email address is <b>{existingEmail}</b> and is <b>{existingEmailVerified ? "verified" : "not verified"}</b>. If you
          want to change it, verify a new one.
        </p>
      )}
      {(existingEmail === "" || !existingEmailVerified) && (
        <p>
          Verify your email to start receiving notifications about your favorite players and content to help you thrive in your Sorare
          career.
        </p>
      )}
      <div className={"text-left"}>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={"py-1 px-4 w-64 bg-surface-container-lowest"}
          type={"email"}
          placeholder={"Enter your email address"}
        />
      </div>
      {existingEmail === email && existingEmailVerified ? (
        <Button disabled={true} label={"Verify email"} title={"Email already verified"} />
      ) : (
        <Button
          label={existingEmail !== "" && existingEmail === email && !existingEmailVerified ? "Resend verification email" : "Verify email"}
          onClick={() => sendVerificationEmail()}
        ></Button>
      )}
      <p className={"text-center"}>{alertMsg}</p>
    </div>
  );
}

export default withUser(EmailSetup);
