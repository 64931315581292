import React, { Component } from "react";
import { Anchor, Box, Grid, Image, Text } from "grommet";
import ResponsiveGrid from "../util/responsiveGrid";

class SO5SelectionTeam extends Component {
  render() {
    const team = this.props.team.team;
    const stats = this.props.team;
    return (
      <Box>
        <Box direction={"row"}>
          <ResponsiveGrid columns={["60px", "flex", "xsmall"]} rows={["auto"]} fill={true} gap={{ column: "medium", row: "medium" }}>
            <Box style={{ borderRadius: "50%" }} height={"70px"} align={"center"} justify={"center"}>
              <Image src={team.PictureUrl} width={"50px"} style={{ borderRadius: "50%" }} />
            </Box>
            <Box direction={"row"} align={"center"}>
              <Grid columns={"auto"} rows={["auto", "auto"]}>
                <Anchor href={"/team/" + team.TeamId}>
                  <Text color={"#292929"} size={"large"} weight={500} margin={{ right: "xsmall" }}>
                    {team.DisplayName}
                  </Text>
                </Anchor>
                <Text size={"small"}>{team.League}</Text>
              </Grid>
            </Box>
            <Box direction={"row"} align={"center"} justify={"center"} flex={true}>
              <Grid columns={"auto"} rows={["auto", "auto"]}>
                <Text size={"large"} weight={600}>
                  {parseFloat(stats.rate * 100).toFixed(2)}%
                </Text>
                <Text size={"xsmall"} weight={400}>
                  {stats.cards_selected}/{stats.cards_eligible} cards
                </Text>
              </Grid>
            </Box>
          </ResponsiveGrid>
        </Box>
      </Box>
    );
  }
}

export default SO5SelectionTeam;
