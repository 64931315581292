import React, { Component } from "react";
import { Box, Form, Text, TextInput } from "grommet";
import { withUser } from "../../userContext";
import GreenButton from "../util/greenButton";
import { errorCatcher } from "../util/errors";

class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPwd2: "",
      oldPwd: "",
      newPwd1: "",
      errorMessage: "",
    };
  }

  handleChangeOldPwd = (event) => {
    this.setState({ oldPwd: event.target.value });
  };

  handleChangeNewPwd1 = (event) => {
    this.setState({ newPwd1: event.target.value });
  };

  handleChangeNewPwd2 = (event) => {
    this.setState({ newPwd2: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.newPwd1 === this.state.newPwd2) {
      this.props
        .fetch("/apiv2/user/changePassword", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: this.props.user.username,
            old_password: this.state.oldPwd,
            new_password: this.state.newPwd1,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            this.props.logout();
            this.props.history.push("/login");
          } else {
            this.setState({ errorMessage: "Sign up failed: " + res.error });
          }
        })
        .catch(errorCatcher());
    } else {
      this.setState({ errorMessage: "The two new passwords don't match." });
    }
  };

  render() {
    let errorMsg = this.state.errorMessage;
    return (
      <div className={"mt-8"}>
        <div className={"space-y-4"}>
          <p className={"text-xl text-primary font-headers font-semibold"}>Change your password</p>
          <Form onSubmit={this.handleSubmit}>
            <TextInput type="password" name="oldPwd" placeholder="Your old password" onChange={this.handleChangeOldPwd} />
            <br />
            <TextInput type="password" name="newPwd1" placeholder="Your new password" onChange={this.handleChangeNewPwd1} />
            <br />
            <TextInput type="password" name="newPwd2" placeholder="Your new password (again)" onChange={this.handleChangeNewPwd2} />
            <br />
            <Box align={"center"} margin={"auto"}>
              <GreenButton type="submit" label={"Change password"} onSubmit={this.handleSubmit} />
            </Box>
          </Form>
          <Box margin={"small"}>
            <Text>{errorMsg}</Text>
          </Box>
        </div>
      </div>
    );
  }
}

export default withUser(PasswordChange);
