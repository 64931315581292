import React, { forwardRef } from "react";
import CurrencyPicker from "../util/currencyPicker";
import { withUser } from "../../userContext";
import { Dropdown } from "../util/dropdown";
import { FloatingPanelBoxWithToggle, FloatingPanelBoxWithToggleAndExpand } from "../util/floatingPanelFilter";
import { seasons_objects_int } from "../util/seasons";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { isSportFree } from "../util/sports";
import { DropdownDS } from "../util/dropdownDS";

export const PriceGraphAdvancedFiltersPanel = withUser((props) => {
  const { filters, positionOptions, sport, onFilterChange } = props;
  const maxHeight = props.maxHeight || "25em";
  const isPaywall = !t1OrAbove(props.user.tier) && !isSportFree(props.sport);
  return (
    <div>
      <div
        className={"flex flex-col gap-3 w-full px-4 py-4 border-b border-transparent-inverse-surface-low border-opacity-10"}
        style={{ maxHeight: maxHeight }}
      >
        <div className={"flex flex-col gap-2 bg-surface-container-high"}>
          <div className={"z-20"}>
            <DropdownDS
              width="w-full"
              paywalled={isPaywall}
              options={positionOptions}
              selected={filters.positions}
              onChange={(v) => {
                if (!filters.positions.includes(v)) {
                  onFilterChange("positions", [...filters.positions, v]);
                } else {
                  onFilterChange(
                    "positions",
                    filters.positions.filter((pos) => pos !== v),
                  );
                }
              }}
              placeholder="All positions"
              multiple
            />
          </div>
          <div className={"z-10"}>
            <DropdownDS
              width="w-full"
              paywalled={isPaywall}
              options={seasons_objects_int}
              selected={filters.seasons}
              onChange={(v) => {
                if (!filters.seasons.includes(v)) {
                  onFilterChange("seasons", [...filters.seasons, v]);
                } else {
                  onFilterChange(
                    "seasons",
                    filters.seasons.filter((season) => season !== v),
                  );
                }
              }}
              placeholder={"All seasons cards"}
              multiple
            />
          </div>
          <FloatingPanelBoxWithToggleAndExpand
            label={"Specific date range"}
            isActive={filters.rangeDateEnabled}
            onActiveChange={() => onFilterChange("rangeDateEnabled", !filters.rangeDateEnabled)}
          >
            <div className="border-t border-transparent-inverse-surface-low border-opacity-10 mt-2" />
            <div className="grid grid-cols-2 p-3 text-sm text-on-surface-variant font-medium">
              <div className="grid grid-cols-1">
                <span>From</span>
                <DatePicker
                  selected={filters.startDate}
                  onChange={(date) => {
                    onFilterChange("startDate", date);
                  }}
                  customInput={<DatePickerCustomInput />}
                />
                <span className="text-center">MM/DD/YYYY</span>
              </div>
              <div className="grid grid-cols-1">
                <span>To</span>
                <DatePicker
                  selected={filters.endDate}
                  onChange={(date) => {
                    onFilterChange("endDate", date);
                  }}
                  customInput={<DatePickerCustomInput />}
                />
                <span className="text-center">MM/DD/YYYY</span>
              </div>
            </div>
          </FloatingPanelBoxWithToggleAndExpand>
          <FloatingPanelBoxWithToggle label={"Expired listings"} field={"showExpired"} filters={filters} onFilterChange={onFilterChange} />
        </div>
      </div>
    </div>
  );
});

export const defaultPriceGraphPreferences = {
  currency: "",
  showLateralPriceDetails: false,
  highlightInSeason: false,
};

export const getPriceGraphPreferencesFromLocalStorage = () => {
  const preferences = localStorage.getItem("priceGraphPreferences");
  if (preferences) {
    try {
      return { ...defaultPriceGraphPreferences, ...JSON.parse(preferences) };
    } catch (e) {
      return defaultPriceGraphPreferences;
    }
  }
  return defaultPriceGraphPreferences;
};

export const setPriceGraphPreferencesToLocalStorage = (preferences) => {
  localStorage.setItem("priceGraphPreferences", JSON.stringify(preferences));
};

export const PriceGraphPreferencesPanel = (props) => {
  const { filters, onFilterChange, maxSize, withLateralDisplay, disabledHighlight } = props;
  return (
    <>
      <div className={`flex flex-col gap-3 w-full px-4 py-4 overflow-y-auto ${maxSize}`}>
        <div className={"flex flex-col gap-2"}>
          <CurrencyPicker
            currency={filters.currency}
            onChange={(c) => {
              onFilterChange("currency", c);
            }}
            containerClassName="border border-transparent-inverse-surface-low border-opacity-10 w-full"
          />
        </div>
        {withLateralDisplay && (
          <div className={"flex flex-col gap-2"}>
            <FloatingPanelBoxWithToggle
              label={"Supply and price details"}
              field={"showLateralPriceDetails"}
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </div>
        )}
        <FloatingPanelBoxWithToggle
          label={"Highlight in-season cards"}
          field={"highlightInSeason"}
          disabled={disabledHighlight}
          filters={filters}
          onFilterChange={onFilterChange}
        />
      </div>
    </>
  );
};

const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
  <input
    className={
      "w-full text-center cursor-pointer py-2 my-1 text-on-surface-variant bg-transparent-inverse-surface-low bg-opacity-10 font-small rounded-3xl focus:outline-none"
    }
    onClick={onClick}
    ref={ref}
    value={format(new Date(value), "MM/dd/yyyy")}
    readOnly
  />
));
DatePickerCustomInput.displayName;
