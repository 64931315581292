import React, { useCallback, useEffect, useRef, useState } from "react";
import { withUser } from "../../userContext";
import { GameInLineVsWithDetails } from "./gameInLine";
import ScoreAverage from "./scoreAverage";
import { ReactComponent as Bulb } from "../../img/bulb.svg";
import { ReactComponent as ShareIcon } from "../../img/icons-share-no-color.svg";
import { ReactComponent as DeleteIcon } from "../../img/icons-trash-no-color.svg";
import { ReactComponent as DiscardIcon } from "../../img/icons-person-off-no-color.svg";
import { ReactComponent as LockIcon } from "../../img/icons-lock-no-color.svg";
import { ReactComponent as UnlockIcon } from "../../img/icons-lock-open-no-color.svg";
import { ReactComponent as ArrowIcon } from "../../img/icons-arrow-circle-left-no-color.svg";
import { ReactComponent as CloseIcon } from "../../img/icons-close.svg";
import { ReactComponent as HourGlass } from "../../img/icons-hourglass.svg";
import { ReactComponent as LockNotAuthorizedIcon } from "../../img/locker-icon-no-color.svg";
import { ReactComponent as CheckIcon } from "../../img/icons-check.svg";
import { isIE, isMobile, isSafari, isTablet } from "react-device-detect";
import { ReactComponent as DiscardShowDetailsButton } from "../../img/icons-group-off-no-color.svg";
import { ReactComponent as AddCircleIcon } from "@material-design-icons/svg/filled/add_circle.svg";
import { getBackgroundGradientFromDivisionId } from "../util/divisions";
import { PlayerCommunityProjectionWithHover } from "../util/communityPrediction";
import { PickScoreDetails } from "../util/pickScore";
import SorareScoreAverage from "./sorareScoreAverage";
import { useElementSize } from "usehooks-ts";
import { abbrv_football_positions } from "../util/positions";
import PlayerAvailability from "../players/playerAvailability";
import { ReactComponent as WarningIcon } from "../../img/icons-warning-alternative-no-color.svg";
import { ReactTooltip } from "../util/tooltip.js";
import { useMountTransition } from "../util/animations";
import { useInView } from "react-intersection-observer";
import { ProtectedPrizePool } from "./prizePool";
import { ProtectedHistoricalGraph } from "./lineupBuilderHistoricalGraph";
import { toJpeg } from "html-to-image";
import { errorCatcher } from "../util/errors";
import { urlNoCache } from "../util/url";
import { LabelSwitch } from "../util/labelSwitch";
import { Button } from "../util/button";
import { RulesIndicator } from "./lineupBuilderRules";
import { LineupStrength } from "./lineupStrength";
import { computeLineupRawStrength, computeLineupStrength } from "../util/lineupStrength";
import Cap240Indicator from "./cap240Indicator";
import { getDivisionDisplayNameSubTitleV2, getDivisionDisplayNameV2 } from "./lineupBuilderDivisionOrLineupPicker";
import { Tag } from "../util/tag";
import { sorareFootball } from "../util/sports";
import { getThemeColor, useTheme } from "../../themeContext";
import clsx from "clsx";
import { sdDark } from "../../themeColors";

export const positionsList = ["Goalkeeper", "Defender", "Midfielder", "Forward", "Extra"];

const getShowCaptain = (captain, divRules, lineup) => {
  let showCaptain = undefined;
  if (divRules?.withCaptain === undefined || divRules.withCaptain) {
    if (captain === null) {
      let hasOneNull = false;
      showCaptain = lineup.map((entry) => {
        if (entry === null) {
          hasOneNull = true;
          return false;
        }
        // show captain if in allowed captain scarcities or if no specified allowed captain scarcities
        const scarcity = entry.card?.Scarcity;
        return (
          divRules?.captainAllowedScarcities === undefined ||
          divRules?.captainAllowedScarcities?.length === 0 ||
          divRules?.captainAllowedScarcities?.includes(scarcity)
        );
      });
      if (hasOneNull) {
        showCaptain = lineup.map(() => false);
      }
    }
  }
  return showCaptain;
};

export const LineupBuilderLineup = withUser((props) => {
  const {
    team,
    lineupInfo,
    selectedLineup,
    draftId,
    division,
    historicalPerformance,
    projections,
    preferences,
    pickScoreReveal,
    suggestion,
    onDiscard,
    onSuggest,
    removeLockedCard,
    addLockedCard,
    onBrowsePrevious,
    onBrowseNext,
    suggestionCombinationHasNext,
    onChangeHistoricalGraphOlderPage,
    onChangeHistoricalGraphNewerPage,
    gw,
    targetUserId,
    onDisplayDiscardDetails,
    refOnDiscardDetailsDisplay,
    isDisplayingDiscardDetails,
    onChangeDiscardState,
    onRevertToPreviousLineup,
    loadingComps,
  } = props;
  const lineup = lineupInfo?.current;
  const captain = lineupInfo?.captain;
  const previousLineup = lineupInfo?.previous;
  const position = lineupInfo?.position;
  const savingLineup = lineupInfo?.saving;
  const lineupHasChangesToSave = lineupInfo ? lineupInfo.hasChangesToSave : false;
  const lineupIsNotCreated = lineupInfo?.lineupId === undefined || lineupInfo?.lineupId === null || lineupInfo?.lineupId === "";

  const divId = division?.divisionId;
  const loading = division?.loading;
  const divName = getDivisionDisplayNameV2(selectedLineup);
  const divLogo = division?.team?.logoUrl || division?.comp?.logoUrl || "";
  const divRules = division?.comp?.rules;
  const rarity = division?.comp?.rarity;
  const suggestionLimits = division?.suggestionLimits;
  const prizePool = division?.prizePool;
  const divisionMaxScore = division?.maxScores;

  const suggestionMode = suggestion.mode;
  const suggestionType = suggestion.results.type;
  const discardedCards = suggestion.discardedCards;
  const lockedCards = suggestion.lockedCards;
  const suggesting = suggestion.suggesting;
  const suggestionBrowseInfo = suggestion.browseInfo;

  const historicalGraph = historicalPerformance?.data;
  const loadingHistoricalPerformance = historicalPerformance?.loading;

  const [pickedTab, setPickedTab] = useState({ tab: "lineup", type: "manual" });
  const [sharing, setSharing] = useState(false);
  const powerAdjusted = (preferences?.powerAdjustedScore && divRules?.withCardPower) || false;
  const showCaptain = getShowCaptain(captain, divRules, lineup);
  const themeCtx = useTheme();
  const theme = preferences?.darkMode ? sdDark : themeCtx.theme;

  const onChangePickedTab = (tab, type) => {
    setPickedTab({ tab, type });
    props.onChangePickedTab && props.onChangePickedTab(tab, type);
  };

  useEffect(() => {
    setSharing(false); // stop sharing if the lineup changes
  }, [targetUserId, draftId, divId, gw]);

  if (lineup === undefined) return null;
  const [bgHeaderFrom, bgHeaderTo] = getBackgroundGradientFromDivisionId(rarity);

  const atLeastOnePlayerPicked = lineup.filter((e) => e !== null).length > 0;
  const allPlayersPicked = lineup.filter((e) => e !== null).length === lineup.length;
  const isCapped = divRules?.isCapped !== undefined ? divRules.isCapped : false;
  const hasCostRestriction = isCapped || divRules?.atLeastCardsWithAverage !== undefined || divRules?.atMostCardsWithAverage !== undefined;
  const withPower = divRules?.withCardPower !== undefined ? divRules.withCardPower : true;
  const captainPower = divRules?.captainPower;
  const scarcityLimitations = divRules?.scarcityLimits;
  const lineupPoints = lineup.reduce((acc, p) => acc + (p?.cost !== undefined ? p.cost : 0), 0);

  const onDownloaded = useCallback(() => {
    setSharing(false);
  }, []);

  const cardProps = {
    powerAdjusted,
    isCapped,
    hasCostRestriction,
    withPower,
    captainPower,
    scarcityLimitations,
    projections,
    odds: props.odds,
    standings: props.standings,
    tier: props.user.tier,
    showCaptain,
    setCaptain: props.setCaptain,
    removeCaptain: props.removeCaptain,
    changePosition: props.changePosition,
    removeCard: props.removeCard,
    pickCard: (c, p) => props.pickCard(c, p),
    pickScoreReveal,
    captain,
    loading,
    suggestionMode,
    suggestionType,
    onDiscard,
    onSuggest,
    discardedCards,
    removeLockedCard,
    lockedCards,
    addLockedCard,
    suggestionBrowseInfo,
    onBrowsePrevious,
    onBrowseNext,
  };
  const disabledShare = isSafari || isIE || isMobile;
  const canShare = draftId && divId && !loading && !disabledShare;

  const [lineupStrength, setLineupStrength] = useState({});
  useEffect(() => {
    const lStrength = computeLineupStrength(lineup, divRules, captain, divisionMaxScore);
    setLineupStrength(lStrength);
  }, [lineup, captain, divisionMaxScore]);

  const [lineupRawStrength, setLineupRawStrength] = useState({});
  useEffect(() => {
    const rawStrength = computeLineupRawStrength(lineup, divRules, captain);
    setLineupRawStrength(rawStrength);
  }, [lineup, captain]);

  const divShortName = division?.comp?.fullNameNoScarcity || "";
  const lineupSubtitle = getDivisionDisplayNameSubTitleV2(selectedLineup);
  const divisionKnown = division?.comp !== undefined;
  return (
    <div className={`relative rounded-md ${isDisplayingDiscardDetails ? "dim-associate" : ""}`}>
      {sharing && (
        <LineupToShare
          targetUserId={targetUserId}
          cardProps={cardProps}
          divName={divName}
          gwNumber={gw}
          lineup={lineup}
          draftId={draftId}
          divId={divId}
          lineupStrength={lineupStrength}
          onDownloaded={onDownloaded}
          onDownloadedError={onDownloaded}
          hasCaptain={captain !== null && captain !== undefined}
        />
      )}
      <div
        className={`rounded-t-md text-white`}
        style={{
          background: `linear-gradient(180deg, ${getThemeColor(theme, bgHeaderFrom)} 0%, ${getThemeColor(theme, bgHeaderTo)} 100%)`,
        }}
      >
        <div className={"py-2 flex flex-row gap-1 justify-between"}>
          <div className={"self-center flex flex-row pl-2 space-x-2"}>
            <div
              className={clsx(
                "h-full px-1 py-1 rounded-md self-center",
                divisionKnown ? "bg-transparent-surface-container-high bg-opacity-70" : "bg-skeleton-from bg-opacity-10",
              )}
            >
              <div className="w-8 h-8 flex justify-center">
                {divisionKnown && divLogo && <img src={divLogo} className="w-full h-full object-contain" />}
              </div>
            </div>
            {divisionKnown ? (
              <div className="self-center">
                <p className="text-sm font-semibold text-surface-container md:text-base">{divShortName}</p>
                <p className="text-xs sm:text-sm font-semibold text-surface-container text-opacity-80">{lineupSubtitle}</p>
              </div>
            ) : (
              <div className={"flex flex-col justify-center gap-0.5"}>
                <span className={"h-5 w-32 sm:w-44 rounded animate-pulse-sm bg-skeleton-from bg-opacity-10"}></span>
                <span className={"h-4 w-24 sm:w-36 rounded animate-pulse-sm bg-skeleton-from bg-opacity-10"}></span>
              </div>
            )}
          </div>
          <div className={"flex flex-row gap-4 self-center pr-2"}>
            <div className={"flex flex-row gap-2 self-center"}>
              <SaveLineup
                hasChangesToSave={lineupHasChangesToSave}
                isNotCreated={!divisionKnown || lineupIsNotCreated}
                saving={savingLineup}
                loading={!divisionKnown || loadingComps}
                skeleton={!divisionKnown}
                onSave={savingLineup ? undefined : () => props.saveLineup()}
              />
              {!divisionKnown ? (
                <div className={"rounded-full h-8 w-8 animate-pulse self-center bg-skeleton-from bg-opacity-10"}></div>
              ) : (
                <div
                  className={`aspect-square rounded-full bg-opacity-70 bg-transparent-surface-container-high p-1.5 flex flew-row justify-center items-center ${
                    canShare ? (sharing ? "cursor-progress" : "cursor-pointer hover:opacity-80") : ""
                  }`}
                  data-delay-show={disabledShare ? "100" : "700"}
                  data-tip={disabledShare ? "Sharing is not supported on this browser" : "Export your lineup in picture format"}
                  onClick={canShare ? () => setSharing(true) : undefined}
                >
                  <div className={"h-5 w-5 flex flew-row justify-center items-center text-on-surface"}>
                    <ShareIcon className={`w-full -mt-0.5 h-full object-contain fill-on-surface ${sharing && "animate-pulse"}`} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          suggestionMode
            ? "bg-lub-lineup-black bg-textGrey2"
            : "sd-light:bg-lub-lineup sd-dark:bg-lub-lineup-dark sd-light:bg-brand-light-light-green sd-dark:bg-brand-light-green"
        } bg-center bg-cover w-full rounded-b-md pb-2`}
      >
        <LineupCaroussel
          team={team}
          pickedTab={pickedTab}
          suggestionMode={suggestionMode}
          isCapped={isCapped}
          lineupPoints={lineupPoints}
          divId={divId}
          divRules={divRules}
          divName={divName}
          divLogo={divLogo}
          allPlayersPicked={allPlayersPicked}
          loading={loading}
          suggesting={suggesting}
          atLeastOnePlayerPicked={atLeastOnePlayerPicked}
          cardProps={cardProps}
          position={position}
          lineup={lineup}
          previousLineup={previousLineup}
          lineupStrength={lineupStrength}
          lineupRawStrength={lineupRawStrength}
          suggestionLimits={suggestionLimits}
          suggestionCombinationHasNext={suggestionCombinationHasNext}
          onSuggest={onSuggest}
          positionsList={positionsList}
          suggestionBrowseInfo={suggestionBrowseInfo}
          onBrowsePrevious={onBrowsePrevious}
          onBrowseNext={onBrowseNext}
          prizePool={prizePool}
          rarity={rarity}
          historicalGraph={historicalGraph}
          onChangeHistoricalGraphOlderPage={onChangeHistoricalGraphOlderPage}
          onChangeHistoricalGraphNewerPage={onChangeHistoricalGraphNewerPage}
          loadingHistoricalPerformance={loadingHistoricalPerformance}
          onChangePickedView={(tab) => onChangePickedTab(tab, "scroll")}
          onDisplayDiscardDetails={onDisplayDiscardDetails}
          refOnDiscardDetailsDisplay={refOnDiscardDetailsDisplay}
          onChangeDiscardState={onChangeDiscardState}
          onRevertToPreviousLineup={onRevertToPreviousLineup}
          resetLineup={props.resetLineup}
          division={division}
          selectedLineup={selectedLineup}
          darkMode={preferences?.darkMode}
          gwNumber={gw}
        />
        <div className={"py-1 px-6"}>
          <div
            className={`font-semibold bg-transparent-surface-container-high bg-opacity-70 grid grid-cols-3 rounded-lg text-xs sm:text-sm py-2 items-center w-full`}
          >
            <div
              className={`py-1 text-center ${
                pickedTab.tab === "lineup"
                  ? "text-primary"
                  : "text-on-surface-variant cursor-pointer hover:text-primary hover:text-opacity-80"
              }`}
              onClick={pickedTab.tab !== "lineup" ? () => onChangePickedTab("lineup", "manual") : undefined}
            >
              <p>Current lineup</p>
            </div>
            <div
              className={`py-1 border-x border-transparent-inverse-surface-low border-opacity-10 text-center ${
                pickedTab.tab === "prize-pool"
                  ? "text-primary"
                  : "text-on-surface-variant cursor-pointer hover:text-primary hover:text-opacity-80"
              }`}
              onClick={pickedTab.tab !== "prize-pool" ? () => onChangePickedTab("prize-pool", "manual") : undefined}
            >
              <p>Prize pool</p>
            </div>
            <div
              className={`py-1 text-center ${
                pickedTab.tab === "historical"
                  ? "text-primary"
                  : "text-on-surface-variant cursor-pointer hover:text-primary hover:text-opacity-80"
              }`}
              onClick={pickedTab.tab !== "historical" ? () => onChangePickedTab("historical", "manual") : undefined}
            >
              <p>Historical</p>
            </div>
          </div>
        </div>
      </div>
      {props.validationMessages?.length > 0 && (
        <LineupMessages messages={props.validationMessages} onCloseValidationMessage={props.onCloseValidationMessage} />
      )}
    </div>
  );
});

const SaveLineup = (props) => {
  const { onSave, saving, hasChangesToSave, isNotCreated, loading, skeleton } = props;
  const disabled = saving || loading || (isNotCreated && !hasChangesToSave);
  if (skeleton) {
    return <div className={"rounded-full h-8 w-16 animate-pulse self-center bg-skeleton-from bg-opacity-10"}></div>;
  }
  if (hasChangesToSave || isNotCreated) {
    return (
      <Button
        data-delay-show="700"
        disabled={disabled}
        textSize="text-md font-semibold"
        context={"neutral"}
        onClick={disabled ? undefined : onSave}
        label={"Save"}
      />
    );
  } else {
    return (
      <div className={"flex items-center"}>
        <Tag context="emphasized-opaque" label={"Saved"} icon={<CheckIcon className={"fill-on-surface-variant"} />} />
      </div>
    );
  }
};

export const LineupToShare = withUser((props) => {
  const {
    cardProps,
    divName,
    gwNumber,
    lineup,
    lineupStrength,
    draftId,
    divId,
    onDownloaded,
    targetUserId,
    onDownloadedError,
    hasCaptain,
  } = props;
  const [managerInfo, setManagerInfo] = useState({
    name: undefined,
    picture: undefined,
  });
  const [lineupCardsReady, setLineupCardsReady] = useState(lineup.map((e) => e === null));
  const [managerPictureReady, setManagerPictureReady] = useState(false);

  const onCompleteAllCards = useCallback(
    (index) => {
      const newLineupCardsReady = [...lineupCardsReady];
      newLineupCardsReady[index] = true;
      setLineupCardsReady(newLineupCardsReady);
    },
    [lineupCardsReady],
  );

  const ref = useRef(null);
  useEffect(() => {
    if (targetUserId) {
      props
        .fetch("/apiv2/manager/infoById/" + targetUserId)
        .then((response) => response.json())
        .then((res) => {
          setManagerInfo({
            name: res.manager.Nickname,
            picture: res.manager.PictureUrl,
          });
        })
        .catch(errorCatcher());
    } else if (targetUserId === "") {
      props
        .fetch("/apiv2/manager/info")
        .then((response) => response.json())
        .then((res) => {
          setManagerInfo({
            name: res.manager.Nickname,
            picture: res.manager.PictureUrl,
          });
        })
        .catch(errorCatcher());
    }
  }, [targetUserId]);

  useEffect(() => {
    const assetsReady = managerPictureReady && lineupCardsReady.every((ready) => ready);
    const isReadyToDownload = managerInfo.name && managerInfo.picture && assetsReady;
    if (isReadyToDownload) {
      const timeout = setTimeout(() => {
        if (ref?.current === null || ref?.current === undefined) {
          return;
        }
        const id = `soraredata-lineup-builder-${draftId}-${divId}`;
        const scale = 3;
        const node = ref.current;
        const style = {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
          width: node.offsetWidth + "px",
          height: node.offsetHeight + "px",
        };

        const param = {
          height: node.offsetHeight * scale,
          width: node.offsetWidth * scale,
          quality: 0.95,
          style,
        };
        toJpeg(node, param)
          .then(function (dataUrl) {
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = dataUrl;
            a.download = id + ".jpg";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(dataUrl);
          })
          .then(() => {
            onDownloaded();
          })
          .catch((e) => {
            console.error(e);
            onDownloadedError();
          });
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [managerInfo, lineupCardsReady, managerPictureReady]);

  const onLoadManagerPicture = useCallback(() => {
    setManagerPictureReady(true);
  }, []);

  const managerPicUrl = useCallback(() => {
    return urlNoCache(managerInfo.picture);
  }, [managerInfo.picture]);

  if (managerInfo.name === undefined || managerInfo.picture === undefined) {
    return null;
  }

  // When exporting the lineup to an image, all images loaded from external domain
  // have to be loaded with a random query string to avoid caching + using crossOrigin="anonymous" to avoid CORS
  // see: https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
  // return <div className={"absolute z-500"} style={{width: "500px"}}>
  return (
    <div className={"absolute z-500 mt-[100000px]"} style={{ width: "500px" }}>
      <div
        ref={ref}
        className={`sd-light:bg-lub-lineup sd-dark:bg-lub-lineup-dark sd-light:bg-brand-light-light-green sd-dark:bg-brand-light-green bg-center bg-cover w-full pb-2`}
      >
        <div className={"px-4"} style={{ height: "90px" }}>
          <div
            className={"flex flex-row h-full justify-between items-center bg-center bg-no-repeat bg-sd-logo-sm"}
            style={{ backgroundSize: "5%" }}
          >
            <div className={"text-white font-semibold break-words"} style={{ maxWidth: "45%" }}>
              <p className={"text-md break-words"}>{divName}</p>
              <p className={"text-xxs"}>GW #{gwNumber}</p>
            </div>
            <div className={"text-white font-semibold flex flex-row items-center gap-2 break-words"} style={{ maxWidth: "45%" }}>
              <p className={"text-md break-words"}>{managerInfo.name}</p>
              {managerInfo.picture && (
                <img
                  crossOrigin="anonymous"
                  className={"rounded-full w-6 h-6"}
                  src={managerPicUrl()}
                  onLoad={managerPictureReady ? undefined : onLoadManagerPicture}
                />
              )}
            </div>
          </div>
        </div>
        <div className={`bg-lub-football-pitch bg-center bg-contain bg-no-repeat mt-11 w-full pb-4`}>
          <div className={"absolute mt-28 pt-1 top-0 flex flex-row justify-end w-full"}>
            <div className={"flex flex-col justify-end gap-2 pr-4"}>
              <div className={"flex flex-col items-end"}>
                <div className={"flex flex-col justify-center items-center"}>
                  <LineupStrength
                    lineupScore={lineupStrength.lineupScore}
                    divisionMaxScore={hasCaptain ? lineupStrength.divisionMaxScore : lineupStrength.divisionMaxScoreNoCaptain}
                    size={"md"}
                    isLub
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"flex flex-col items-center justify-center gap-2"}>
            <div className={"flex flex-row justify-center gap-2 pt-1 -mt-12"}>
              {positionsListAlternativePart1.map((p, i) => {
                const positionIndex = positionsList.findIndex((x) => x === p);
                const card = lineup[positionIndex];
                return (
                  <div style={{ width: "100px" }}>
                    <LineupBuilderCard
                      key={i}
                      forExport={true}
                      readOnly={true}
                      onCardLoaded={lineupCardsReady[positionIndex] ? undefined : onCompleteAllCards(positionIndex)}
                      {...cardProps}
                      showCaptain={false}
                      position={p}
                      card={card}
                    />
                  </div>
                );
              })}
            </div>
            <div className={"flex flex-row justify-center gap-2 pt-1"}>
              {positionsListAlternativePart2.map((p, i) => {
                const positionIndex = positionsList.findIndex((x) => x === p);
                const card = lineup[positionIndex];
                return (
                  <div
                    style={
                      i === 1
                        ? { width: "100px", marginTop: "20px" }
                        : {
                            width: "100px",
                            marginBottom: "20px",
                          }
                    }
                  >
                    <LineupBuilderCard
                      key={i}
                      forExport={true}
                      readOnly={true}
                      onCardLoaded={lineupCardsReady[positionIndex] ? undefined : onCompleteAllCards(positionIndex)}
                      {...cardProps}
                      showCaptain={false}
                      position={p}
                      card={card}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const positionsListAlternativePart1 = ["Forward", "Extra"];
const positionsListAlternativePart2 = ["Defender", "Goalkeeper", "Midfielder"];
const LineupCaroussel = withUser((props) => {
  const {
    pickedTab,
    suggestionMode,
    isCapped,
    divRules,
    suggestionLimits,
    allPlayersPicked,
    loading,
    suggesting,
    atLeastOnePlayerPicked,
    onSuggest,
    cardProps,
    position,
    lineup,
    previousLineup,
    positionsList,
    suggestionCombinationHasNext,
    suggestionBrowseInfo,
    onBrowsePrevious,
    onBrowseNext,
    prizePool,
    rarity,
    historicalGraph,
    onChangeHistoricalGraphOlderPage,
    onChangeHistoricalGraphNewerPage,
    loadingHistoricalPerformance,
    onChangePickedView,
    divId,
    divName,
    divLogo,
    lineupStrength,
    lineupRawStrength,
    onDisplayDiscardDetails,
    onChangeDiscardState,
    onRevertToPreviousLineup,
    team,
    division,
    selectedLineup,
    darkMode,
    gwNumber,
  } = props;
  const [discardState, setDiscardState] = useState("current");
  const [shouldCardReco, setShouldCardReco] = useState(null);
  const [refContainer, { width }] = useElementSize();
  const carousel = useRef(null);

  const { ref, entry } = useInView({
    trackVisibility: true,
    threshold: 0.75,
    delay: 100,
  });
  useEffect(() => {
    if (entry?.isVisible) {
      onChangePickedView && onChangePickedView("lineup");
    }
  }, [entry?.isVisible]);

  useEffect(() => {
    if (pickedTab.type === "manual" && carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * (["lineup", "prize-pool", "historical"].indexOf(pickedTab.tab) || 0);
    }
    ReactTooltip.rebuild();
  }, [pickedTab]);

  useEffect(() => {
    setDiscardState("current");
  }, [lineup]);

  const onChangeDiscard = (newState) => {
    setDiscardState(newState);
    onChangeDiscardState && onChangeDiscardState(newState);
  };

  const widthCard = Math.max(width / 4.5, 90);
  const atLeastOnePositionSuggesting = suggesting.some((s) => s === true);

  const displaySuggestButton =
    suggestionLimits?.authorized !== undefined &&
    (suggestionLimits?.reasons === undefined || !suggestionLimits.reasons.includes("not_yourself")) &&
    !allPlayersPicked;
  const discardCardsArray = Object.values(cardProps.discardedCards || {});

  const displayDiscardIndicator = cardProps.suggestionType === "discard" && discardCardsArray?.length > 0;
  const canGoToPreviousLineup = cardProps.suggestionType === "discard" && discardCardsArray?.length > 0;
  const isOnDiscardPrevious = canGoToPreviousLineup && discardState === "previous";
  const emptyLineup = lineup.every((e) => e === null);
  const captain = cardProps.captain;
  const isOnMobile = isMobile || isTablet;

  // Cap 240 indicator
  const displayCap240Indicator = atLeastOnePlayerPicked && divName.includes("Cap 240");
  const [cap240Indicator, setCap240Indicator] = React.useState(0);
  const [cap240IndicatorLoaded, setCap240IndicatorLoaded] = React.useState(false);
  const [lineupStrengthNotRelevant, setLineupStrengthNotRelevant] = React.useState(false);
  useEffect(() => {
    if (lineupRawStrength > 0 && divRules?.isCapped) {
      props
        .fetch(`/decision-api/decision/lub/cap240Indicator?divisionId=${divId}&rawStrength=${lineupRawStrength}`)
        .then((response) => response.json())
        .then(async (res) => {
          setCap240Indicator(res);
        })
        .catch(errorCatcher())
        .finally(() => setCap240IndicatorLoaded(true));
    }
  }, [lineupRawStrength]);

  useEffect(() => {
    const lineupCards = lineup?.map((c) => (c ? c.card.TokenId : null)) || [];
    const nbCards = lineupCards.filter((c) => c !== null).length;
    if (nbCards >= 4) {
      props
        .fetch(`/decision-api/decision/lub/cardRecommendations/should`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            divisionId: divId,
            lineup: lineupCards,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          if (res?.error) {
            setShouldCardReco(null);
          } else {
            setShouldCardReco(res);
          }
        });
    } else {
      setShouldCardReco(null);
    }
  }, [lineup, captain]);

  const onRuleChange = (isValid) => {
    setLineupStrengthNotRelevant(!isValid);
  };

  const indexOfCaptain = captain ? lineup?.findIndex((c) => c !== null && c !== undefined && c.card.TokenId == captain.card.TokenId) : -1;
  const captainPosition = indexOfCaptain !== -1 ? positionsList[indexOfCaptain] : "";
  return (
    <div ref={carousel} className={"relative flex flex-row overflow-auto scroll-smooth snap-x snap-mandatory no-scroll-bar"}>
      <div className={"snap-center"}>
        {isOnDiscardPrevious && pickedTab.tab === "lineup" && (
          <div className={"absolute w-full h-[99%] bg-full-black opacity-50 pointer-events-none z-[11] rounded-b-xl"} />
        )}
        <div ref={ref} className={"w-[95vw] xs:w-[25em] lg:w-[30em] relative"}>
          <LineupActionMainBar
            {...{
              suggestionMode,
              canGoToPreviousLineup,
              onChangeDiscard,
              discardState,
              atLeastOnePositionSuggesting,
              allPlayersPicked,
              suggestionLimits,
              atLeastOnePlayerPicked,
              onSuggest,
              onRevertToPreviousLineup,
              divRules,
              loading,
              lineup,
              captain,
              displaySuggestButton,
              isCapped,
              emptyLineup,
              resetLineup: props.resetLineup,
              unavailableComp: team?.hasLineup ? team.lineup.inUnavailableCompetition : false,
              onRuleChange,
            }}
          />
          <div
            className={`${
              suggestionMode ? "bg-lub-football-pitch-black" : "bg-lub-football-pitch"
            } bg-center bg-contain bg-no-repeat mt-11 w-full pb-4`}
          >
            <LineupActionSecondaryBar
              {...{
                atLeastOnePlayerPicked,
                displaySuggestButton,
                suggestionMode,
                lineupStrength,
                displayCap240Indicator,
                cap240Indicator,
                cap240IndicatorLoaded,
                displayDiscardIndicator,
                discardCardsArray,
                onDisplayDiscardDetails,
                atLeastOnePositionSuggesting,
                shouldCardReco,
                divisionId: divId,
                lineupCards: lineup,
                captainPosition,
                division,
                selectedLineup,
                darkMode,
                divLogo,
                gwNumber,
                lineupStrengthNotRelevant,
                hasCaptain: captainPosition !== "",
              }}
            />
            <div className={"flex flex-col items-center justify-center gap-2"} ref={refContainer}>
              <div className={"flex flex-row justify-center gap-2 pt-1 -mt-12"}>
                {positionsListAlternativePart1.map((p, i) => {
                  const cardIndex = positionsList.findIndex((x) => x === p);
                  const card = isOnDiscardPrevious ? previousLineup[cardIndex] : lineup[cardIndex];
                  return (
                    <div style={{ width: widthCard }}>
                      <LineupBuilderCard
                        key={i}
                        {...cardProps}
                        showCaptain={cardProps.showCaptain?.[cardIndex]}
                        selected={position === p}
                        position={p}
                        suggesting={suggesting[cardIndex]}
                        card={card}
                        hasPrev={suggestionBrowseInfo.hasPrev[cardIndex]}
                        hasNext={suggestionBrowseInfo.hasNext[cardIndex]}
                        onBrowsePrevious={() => onBrowsePrevious(p)}
                        onBrowseNext={() => onBrowseNext(p)}
                        suggestionCombinationHasNext={suggestionCombinationHasNext[cardIndex]}
                        readOnly={isOnDiscardPrevious}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={"flex flex-row justify-center gap-2 pt-1"}>
                {positionsListAlternativePart2.map((p, i) => {
                  const cardIndex = positionsList.findIndex((x) => x === p);
                  return (
                    <div
                      style={
                        i === 1
                          ? {
                              width: widthCard,
                              marginTop: widthCard / 5,
                            }
                          : { width: widthCard, marginBottom: widthCard / 5 }
                      }
                    >
                      <LineupBuilderCard
                        key={i}
                        {...cardProps}
                        showCaptain={cardProps.showCaptain?.[cardIndex]}
                        selected={position === p}
                        position={p}
                        suggesting={suggesting[cardIndex]}
                        card={isOnDiscardPrevious ? previousLineup[cardIndex] : lineup[cardIndex]}
                        hasPrev={suggestionBrowseInfo.hasPrev[cardIndex]}
                        hasNext={suggestionBrowseInfo.hasNext[cardIndex]}
                        onBrowsePrevious={() => onBrowsePrevious(p)}
                        onBrowseNext={() => onBrowseNext(p)}
                        suggestionCombinationHasNext={suggestionCombinationHasNext[cardIndex]}
                        readOnly={isOnDiscardPrevious}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"snap-center"}>
        <div
          className={`p-2 sm:p-4 w-[90vw] xs:w-[25em] lg:w-[30em] max-h-[600px] xs:max-h-[650px] ${
            isOnMobile ? "lg:max-h-[750px]" : "sm:max-h-[none]"
          } overflow-y-auto`}
        >
          <ProtectedPrizePool prizePool={prizePool} onVisible={() => onChangePickedView("prize-pool")} />
        </div>
      </div>
      <div className={"snap-center"}>
        <div
          className={`p-2 sm:p-4 w-[90vw] xs:w-[25em] lg:w-[30em] max-h-[600px] xs:max-h-[650px] ${
            isOnMobile ? "lg:max-h-[750px]" : "sm:max-h-[none]"
          } overflow-y-auto`}
        >
          <ProtectedHistoricalGraph
            lineup={lineup}
            captain={cardProps.captain}
            historicalGraph={historicalGraph}
            divName={divName}
            divLogo={divLogo}
            divRarity={rarity}
            isCapped={isCapped}
            hasThreshold={divRules?.cappedThreshold > 0}
            onChangeHistoricalGraphOlderPage={onChangeHistoricalGraphOlderPage}
            onChangeHistoricalGraphNewerPage={onChangeHistoricalGraphNewerPage}
            loadingHistoricalPerformance={loadingHistoricalPerformance}
            onVisible={() => onChangePickedView("historical")}
          />
        </div>
      </div>
    </div>
  );
});

const LineupActionMainBar = (props) => {
  const {
    suggestionMode,
    canGoToPreviousLineup,
    onChangeDiscard,
    discardState,
    atLeastOnePositionSuggesting,
    allPlayersPicked,
    suggestionLimits,
    atLeastOnePlayerPicked,
    onSuggest,
    onRevertToPreviousLineup,
    divRules,
    loading,
    lineup,
    captain,
    displaySuggestButton,
    isCapped,
    emptyLineup,
    resetLineup,
    unavailableComp,
    onRuleChange,
  } = props;
  const splitInTwo = displaySuggestButton && isCapped && !isMobile;
  const elems = [];
  const hasCentralButtonElem = displaySuggestButton || (suggestionMode && canGoToPreviousLineup);
  if (displaySuggestButton) {
    elems.push(
      <div className={`flex ${splitInTwo ? "" : "col-span-2"} justify-center items-center h-full`}>
        <SuggestionButton
          {...{
            suggestionLimits,
            allPlayersPicked,
            loading,
            suggesting: atLeastOnePositionSuggesting,
            atLeastOnePlayerPicked,
            onSuggest,
          }}
        />
      </div>,
    );
  } else if (suggestionMode && canGoToPreviousLineup) {
    elems.push(
      <div className={`z-[11] col-span-2 flex items-center justify-center`}>
        <DiscardStateSwitch state={discardState} onChange={onChangeDiscard} />
      </div>,
    );
  }
  if (canGoToPreviousLineup && discardState === "previous") {
    elems.push(
      <div className={"flex justify-end z-[11]"}>
        <Button context="secondary" onClick={onRevertToPreviousLineup} textSize="text-xs font-semibold" label={"Revert"} />
      </div>,
    );
  } else {
    elems.push(
      <div className={"flex justify-end"}>
        <Button
          context={"neutral"}
          data-delay-show="700"
          data-tip={"Remove all players from lineup, but does not save"}
          disabled={emptyLineup}
          icon={<DeleteIcon className={"w-full h-full object-contain fill-on-surface"} />}
          onClick={() => !emptyLineup && resetLineup && resetLineup()}
        />
      </div>,
    );
  }

  return (
    <div className={`grid ${splitInTwo ? "grid-cols-2" : "grid-cols-4"} grid-rows-1 items-center py-3 px-2 sm:px-4 gap-2`}>
      {!hasCentralButtonElem && <div />}
      <div className={`flex ${!hasCentralButtonElem ? "justify-center col-span-2" : "justify-start"}`}>
        <RulesIndicator
          unavailableComp={unavailableComp}
          mode={hasCentralButtonElem && !splitInTwo ? "minimal" : "detailed"}
          rules={divRules}
          loading={loading}
          lineup={lineup}
          captain={captain}
          onChange={onRuleChange}
        />
      </div>
      {splitInTwo ? <div className={"flex flex-row justify-between items-center pl-2"}>{elems}</div> : elems}
    </div>
  );
};

const LineupActionSecondaryBar = (props) => {
  const {
    atLeastOnePlayerPicked,
    displaySuggestButton,
    suggestionMode,
    lineupStrength,
    displayCap240Indicator,
    cap240Indicator,
    cap240IndicatorLoaded,
    displayDiscardIndicator,
    discardCardsArray,
    onDisplayDiscardDetails,
    atLeastOnePositionSuggesting,
    shouldCardReco,
    divisionId,
    lineupCards,
    captainPosition,
    selectedLineup,
    division,
    darkMode,
    divLogo,
    gwNumber,
    lineupStrengthNotRelevant,
    hasCaptain,
  } = props;
  const divShortName = division?.comp?.shortNameNoScarcity || "";
  const lineupSubtitle = getDivisionDisplayNameSubTitleV2(selectedLineup, true);
  const titleCardRecommendation = divShortName + " · " + lineupSubtitle;
  return (
    <div className={"absolute mt-14 pt-1 top-0 grid grid-cols-4 w-full"}>
      <div className={"px-4 flex justify-start"}>
        <div>
          {displayDiscardIndicator && (
            <DiscardedPlayersIndicator discardCardsArray={discardCardsArray} onDisplayDiscardDetails={onDisplayDiscardDetails} />
          )}
        </div>
      </div>
      <div className={"col-span-2"}></div>
      <div className={"flex flex-col justify-end gap-2 pr-2 sm:pr-4"}>
        {suggestionMode && (
          <div className={"flex justify-end"}>
            <Tag textSize="text-xxs sm:text-sm" context="emphasized-opaque">
              {atLeastOnePositionSuggesting ? "Suggesting..." : "Suggested"}
            </Tag>
          </div>
        )}
        {atLeastOnePlayerPicked && (
          <div className={"flex flex-col items-end"}>
            <div className={"flex flex-col justify-center items-center"}>
              <LineupStrength
                lineupScore={lineupStrength.lineupScore}
                divisionMaxScore={hasCaptain ? lineupStrength.divisionMaxScore : lineupStrength.divisionMaxScoreNoCaptain}
                isTopCorner={!displaySuggestButton && !suggestionMode}
                size={isMobile ? "xs" : "md"}
                clickable
                withLock
                withCap240Indicator={displayCap240Indicator}
                cap240Indicators={cap240Indicator?.indicators}
                shouldCardRecommendation={shouldCardReco}
                divisionId={divisionId}
                lineupCards={lineupCards}
                captainPosition={captainPosition}
                title={titleCardRecommendation}
                darkMode={darkMode}
                divLogo={divLogo}
                notRelevant={lineupStrengthNotRelevant}
              />
              {displayCap240Indicator && cap240IndicatorLoaded && (
                <Cap240Indicator isTopCorner={!displaySuggestButton && !suggestionMode} indicators={cap240Indicator?.indicators} isLub />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DiscardedPlayersIndicator = (props) => {
  const { discardCardsArray, onDisplayDiscardDetails } = props;
  return (
    <div
      className={
        "p-1 sm:p-2 rounded-full border border-inverse-on-surface border-dashed cursor-pointer flex flex-col gap-2 sm:gap-3 w-9 sm:w-12 z-[11]"
      }
      style={{
        background: "linear-gradient(0deg, rgba(245, 82, 5, 0.15), rgba(245, 82, 5, 0.15)), rgba(255, 255, 255, 0.15)",
      }}
      onClick={onDisplayDiscardDetails}
    >
      <div className={"bg-surface-container rounded-full hover:shadow p-1 sm:p-2 aspect-square drop-shadow-popover"}>
        <DiscardShowDetailsButton className={"fill-primary h-full w-full"} />
      </div>
      <div className={"px-2"}>
        <div className={"border-b border-inverse-on-surface"}></div>
      </div>
      <div
        className={
          "relative border-2 border-inverse-on-surface rounded-full flex justify-center items-center overflow-hidden aspect-square opacity-80"
        }
      >
        <img
          className={"absolute top-1/2 left-1/2 object-cover min-w-[110%] min-h-[110%] transform -translate-x-1/2 -translate-y-1/2"}
          src={discardCardsArray[0].card.AvatarUrl}
        />
      </div>
      {discardCardsArray.length > 1 && (
        <div
          className={
            "relative border-2 border-inverse-on-surface rounded-full flex justify-center items-center overflow-hidden aspect-square"
          }
        >
          <p className={"font-semibold text-xs sm:text-sm text-inverse-on-surface"}>+{discardCardsArray.length - 1}</p>
        </div>
      )}
    </div>
  );
};

const DiscardStateSwitch = (props) => {
  return (
    <LabelSwitch
      {...props}
      globalBgColor="bg-full-black"
      bgColor="bg-full-black"
      activeBgColor="bg-white"
      textColor="text-grey-d9"
      activeTextColor="text-full-black"
      options={[
        { value: "previous", label: "Previous" },
        { value: "current", label: "Current" },
      ]}
    />
  );
};

const LineupMessages = (props) => {
  return (
    <div className={"absolute bottom-0 p-3 w-full"}>
      <div className={"flex flex-col gap-1"}>
        {props.messages
          .slice()
          .reverse()
          .map((p) => {
            return <LineupMessage key={p.id} {...p} onCloseValidationMessage={props.onCloseValidationMessage} />;
          })}
      </div>
    </div>
  );
};

const LineupMessage = (props) => {
  const { msg, warning, id, timeout } = props;
  const [isMounted, setIsMounted] = useState(false);
  const hasTransitionedIn = useMountTransition(isMounted, timeout);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <div
      key={id}
      style={{ transition: "opacity 1s ease" }}
      className={`${
        hasTransitionedIn ? "opacity-100" : "opacity-0"
      } bg-textGrey1 rounded p-3 text-white font-semibold text-xs flex flex-row justify-between`}
    >
      <div className={"flex flex-row gap-2 items-center"}>
        {warning && <WarningIcon className={"w-4 h-4 fill-white cursor-pointer"} />}
        <span>{msg}</span>
      </div>
      <CloseIcon
        className={"w-4 h-4 inline stroke-white cursor-pointer hover:opacity-80"}
        onClick={() => props.onCloseValidationMessage && props.onCloseValidationMessage(id)}
      />
    </div>
  );
};

const SuggestionButton = (props) => {
  const { allPlayersPicked, loading, suggesting, atLeastOnePlayerPicked, suggestionLimits, onSuggest } = props;
  const [ref, { width }] = useElementSize();
  const isSuggestionAuthorized = suggestionLimits.authorized;
  const canSuggest = isSuggestionAuthorized && !allPlayersPicked && !loading && !suggesting;
  let msg;
  if (!isSuggestionAuthorized) {
    if (suggestionLimits.reasons?.includes("unavailable")) {
      msg = "Lineup suggestions are not available yet for this gameweek. Come later";
    } else if (suggestionLimits.reasons?.includes("tier1")) {
      msg = "Star membership needed";
    } else if (suggestionLimits.reasons?.includes("tier2")) {
      msg = "Star membership needed";
    } else if (suggestionLimits.reasons?.includes("not_yourself")) {
      msg = "You can't suggest a lineup for another manager";
    } else {
      console.error("Unknown reasons for suggestion limits", suggestionLimits);
      msg = "Lineup suggestion unavailable for an unknown reason, please contact the support";
    }
  } else {
    msg = canSuggest
      ? atLeastOnePlayerPicked
        ? "Click to to complete the lineup with a suggestion"
        : "Click to suggest a full lineup"
      : "";
  }
  return (
    <div title={msg} ref={ref}>
      <Button
        padding={"px-4 py-2"}
        disabled={!canSuggest}
        onClick={canSuggest ? () => onSuggest() : undefined}
        icon={
          isSuggestionAuthorized ? (
            <Bulb className={"w-4 h-4 my-auto fill-on-primary"} />
          ) : suggestionLimits.reasons?.includes("unavailable") ? (
            <HourGlass className={"w-4 h-4 my-auto fill-on-disable"} />
          ) : (
            <LockNotAuthorizedIcon className={"w-4 h-4 my-auto fill-on-disable"} />
          )
        }
      >
        <span className={`my-auto text-xs`} style={width > 0 ? { fontSize: Math.max(width / 18, 13) } : {}}>
          <span className={"hidden sm:block"}>{atLeastOnePlayerPicked ? "Complete the lineup" : "Suggest a lineup"}</span>
          <span className={"block sm:hidden"}>{atLeastOnePlayerPicked ? "Complete" : "Suggest"}</span>
        </span>
      </Button>
    </div>
  );
};

const LineupBuilderCard = (props) => {
  const {
    powerAdjusted,
    isCapped,
    hasCostRestriction,
    withPower,
    captainPower,
    scarcityLimitations,
    selected,
    changePosition,
    standings,
    loading,
    position,
    showCaptain,
    readOnly,
    pickScoreReveal,
    suggestionMode,
    suggesting,
    lockedCards,
    suggestionType,
    removeLockedCard,
    addLockedCard,
    hasPrev,
    hasNext,
    onBrowsePrevious,
    onBrowseNext,
    suggestionCombinationHasNext,
    forExport,
    onCardLoaded,
  } = props;
  const entry = props.card;
  const [refContainer, sizes] = useElementSize();
  const width = sizes.width || 105;
  const [isHover, setIsHover] = useState(false);

  const changePositionPossible = !readOnly && !loading && !selected;
  if (suggesting && (entry === null || lockedCards[position] !== entry.card.TokenId)) {
    return (
      <div
        style={{ aspectRatio: "1 / 2.7" }}
        className={`relative flex flex-col rounded-xl justify-center items-center w-full h-full sd-light:bg-lub-lineup sd-dark:bg-lub-lineup-dark bg-cover bg-center bg-no-repeat`}
      >
        <div className={"absolute h-full w-full rounded-xl overflow-hidden flex flex-row justify-center items-center"}>
          <div className={"bg-sd-watermark bg-center bg-cover bg-no-repeat opacity-60 h-[125%] w-[125%]"}></div>
        </div>
        <div ref={refContainer} className={"self-center flex justify-center items-center w-full h-full"}>
          <div className={"h-full w-full"}>
            <SuggestionLoader />
          </div>
        </div>
      </div>
    );
  }

  if (entry === null) {
    return (
      <div
        onClick={!readOnly ? () => changePosition && changePosition(position) : undefined}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{ aspectRatio: "1 / 2.7" }}
        className={`flex flex-col rounded-xl justify-center items-center w-full h-full shadow-md border-2 ${
          loading ? "animate-pulse-xs" : ""
        } ${isHover && changePositionPossible ? "sd-light:bg-opacity-30 sd-dark:bg-opacity-30 opacity-80 cursor-pointer" : ""} ${
          selected
            ? "border-primary bg-transparent-surface-container-high bg-opacity-70"
            : "sd-light:bg-transparent-surface-container-low sd-dark:bg-transparent-inverse-surface-low sd-light:bg-opacity-10 sd-dark:bg-opacity-10 sd-light:border-outline-variant sd-dark:border-outline"
        }`}
      >
        <div ref={refContainer} className={"relative self-center flex justify-center items-center w-full"}>
          <div className={"absolute flex flex-col justify-center items-center gap-2"}>
            {!readOnly && <AddCircleIcon className={`w-7 h-7 ${selected ? "fill-primary" : "fill-inverse-on-surface opacity-70"}`} />}
            <p
              className={`font-semibold ${selected ? "text-primary" : "text-inverse-on-surface text-opacity-70"}`}
              style={{ fontSize: width / 8 || 12 }}
            >
              {position}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const card = props.card.card;
  const isCaptain = props.captain !== null ? props.captain.card.TokenId === card.TokenId : false;
  const cardPower = (+entry.card.Power - 1) * 100;
  const captainPowerNormalized = isCaptain ? captainPower || 0 : 0;
  const cardPowerBecauseOfScarcity = scarcityLimitations?.[card.Scarcity]?.power || 0;
  const totalCardPower = (cardPower + captainPowerNormalized + cardPowerBecauseOfScarcity).toFixed(1);
  let odds = props.odds !== undefined ? props.odds[entry.next_game.GameId] : undefined;
  if (odds !== undefined) {
    odds = odds["full_time0"];
  }
  let mainPickScore =
    entry.pick_score_group !== undefined && entry.pick_score_group !== null
      ? entry.pick_score_group
      : pickScoreReveal?.players?.[entry.player.PlayerId + "-" + card.Position]?.score;
  if (entry.next_game?.GameId === undefined || entry.next_game.GameId === "") {
    mainPickScore = "NG";
  }
  const pickScoreDetails =
    entry.pick_score_details !== undefined && entry.pick_score_details !== null
      ? entry.pick_score_details
      : pickScoreReveal?.players?.[entry.player.PlayerId + "-" + card.Position]?.details;

  const hasPickScore = mainPickScore !== null && mainPickScore !== undefined;
  const hhh = width / 3;
  const locked = suggestionType === "discard" && lockedCards[position] === entry.card.TokenId;

  const cannotHover = isMobile || isTablet;

  return (
    <div
      onClick={(e) => (!readOnly ? changePosition && changePosition(position) : undefined)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ boxShadow: "0px 0.1em 0.5em rgba(0, 0, 0, 0.16)" }}
      className={`flex flex-col bg-surface-container rounded-xl justify-center items-center w-full h-full overflow-hidden ${
        isCaptain ? "border-3" : "border-2"
      } ${isHover && !selected && !readOnly ? "opacity-90 cursor-pointer" : ""} ${
        selected ? "border-primary" : isCaptain ? "border-limited" : "border-surface-container"
      }`}
    >
      <div ref={refContainer} className={`relative self-center flex flex-col justify-between items-center w-full h-full overflow-hidden`}>
        <div className={`w-full relative`}>
          {forExport ? (
            <img
              crossOrigin="anonymous"
              onLoad={onCardLoaded}
              onError={onCardLoaded}
              className={`rounded-xl object-contain`}
              style={{ padding: "2.5%" }}
              src={urlNoCache(card.PictureUrl)}
            />
          ) : (
            <img
              className={`rounded-xl object-contain ${
                suggestionMode
                  ? locked && !readOnly
                    ? isHover
                      ? "brightness-[40%]"
                      : "brightness-[60%]"
                    : isHover && !readOnly
                    ? "brightness-[60%]"
                    : ""
                  : ""
              }`}
              style={{ padding: "2.5%" }}
              src={card.PictureUrl}
            />
          )}
          <div className={"absolute z-10 top-0 w-full h-full grid grid-cols-1 grid-rows-4 justify-between"}>
            <div className={`grid grid-cols-2 z-10 top-0 w-full`}>
              <div className={"flex w-full justify-center"}>
                {showCaptain && !readOnly && (
                  <CaptainLabel
                    onClick={(e) => {
                      e.stopPropagation();
                      props.setCaptain && props.setCaptain(entry, position.toLowerCase());
                    }}
                  />
                )}
                {isCaptain && (
                  <CaptainLabel
                    isCaptain
                    onClick={(e) => {
                      e.stopPropagation();
                      props.removeCaptain && !readOnly && props.removeCaptain();
                    }}
                  />
                )}
              </div>
              {!readOnly && isHover && (
                <div className={"pt-2 pr-2 flex flew-row justify-end self-start "}>
                  <div
                    className="w-[50%] h-auto cursor-pointer hover:opacity-90"
                    title={"Remove card from lineup"}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.removeCard && props.removeCard(entry, position);
                      setIsHover(false);
                    }}
                  >
                    <div className={"bg-surface-container aspect-square rounded-full p-1.5"}>
                      <CloseIcon className={"w-full h-full stroke-primary"} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={"row-span-2"}>
              {!readOnly && suggestionMode && suggestionType === "discard" && (isHover || locked || cannotHover) && (
                <div
                  className={`flex flex-col justify-center items-start ${
                    cannotHover ? "pl-1" : "pl-0 sm:items-center"
                  } gap-1 sm:gap-2 w-full h-full`}
                >
                  {(isHover || cannotHover) && !locked && (
                    <div
                      className={`bg-surface-container hover:opacity-90 ${
                        suggestionCombinationHasNext ? "cursor-pointer" : "opacity-80"
                      } rounded-full p-1.5`}
                      title={suggestionCombinationHasNext ? "Discard card" : `No suggestion combination left for position "${position}"`}
                      style={{
                        width: Math.max(width / 3.2, 28),
                        height: Math.max(width / 3.2, 28),
                      }}
                      onClick={(e) => {
                        if (suggestionCombinationHasNext) {
                          e.stopPropagation();
                          props.onDiscard && props.onDiscard(entry, position);
                          setIsHover(false);
                        }
                      }}
                    >
                      <DiscardIcon className={`w-full h-full fill-primary ${!suggestionCombinationHasNext && "grayscale"}`} />
                    </div>
                  )}
                  {(locked || (!locked && (isHover || cannotHover))) && (
                    <LockComponent
                      locked={locked}
                      width={width}
                      isGlobalHover={isHover || cannotHover}
                      onRemoveLock={() => {
                        removeLockedCard(entry.card.TokenId);
                        setIsHover(false);
                      }}
                      onLock={() => {
                        addLockedCard(entry.card.TokenId, position);
                        setIsHover(false);
                      }}
                    />
                  )}
                </div>
              )}
              {!readOnly && suggestionMode && (isHover || cannotHover) && suggestionType === "browse" && (
                <div className={`grid grid-cols-2 grid-rows-1 items-center gap-2 w-full h-full`}>
                  <div className={"flex flex-row justify-start items-center  w-full h-full"}>
                    <div
                      className={`shadow flex justify-center items-center bg-surface-container bg-opacity-95 cursor-pointer rounded-tr-full rounded-br-full p-1.5 ${
                        hasPrev ? "hover:bg-opacity-80 cursor-pointer" : "cursor-not-allowed"
                      }`}
                      title={hasPrev ? "Previous choice" : "No previous choice"}
                      style={{ width: width / 2.3, height: width / 2.4 }}
                      onClick={(e) => {
                        e.stopPropagation(); // avoid click on the whole card
                        hasPrev && onBrowsePrevious && onBrowsePrevious();
                      }}
                    >
                      <div
                        style={{
                          width: Math.round(width / 4.7) + "px",
                          height: Math.round(width / 4.7) + "px",
                        }}
                      >
                        <ArrowIcon className={`w-full h-full ${hasPrev ? "fill-primary" : "fill-on-disable opacity-30"}`} />
                      </div>
                    </div>
                  </div>
                  <div className={"flex flex-row justify-end items-center w-full h-full"}>
                    <div
                      className={`shadow flex justify-center items-center bg-surface-container bg-opacity-95 cursor-pointer rounded-tl-full rounded-bl-full p-1.5 ${
                        hasNext ? "hover:bg-opacity-80 cursor-pointer" : "cursor-not-allowed"
                      }`}
                      title={hasNext ? "Next choice" : "No next choice"}
                      style={{ width: width / 2.3, height: width / 2.4 }}
                      onClick={(e) => {
                        e.stopPropagation(); // avoid click on the whole card
                        hasNext && onBrowseNext && onBrowseNext();
                      }}
                    >
                      <div
                        style={{
                          width: Math.round(width / 4.7) + "px",
                          height: Math.round(width / 4.7) + "px",
                        }}
                      >
                        <ArrowIcon
                          className={`w-full h-full transform rotate-180 ${hasNext ? "fill-primary" : "fill-on-disable opacity-30"}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
        <div
          className={"flex flex-col w-full gap-0.5"}
          style={
            isCaptain
              ? {
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  paddingBottom: "calc(5% + 2px)",
                }
              : { paddingLeft: "5%", paddingRight: "5%", paddingBottom: "5%" }
          }
        >
          <div
            className={"relative border-t border-transparent-inverse-surface-low border-opacity-10 flex justify-center"}
            style={{ marginTop: "10%", marginBottom: "10%" }}
          >
            <div
              className={
                "absolute -top-[1px] -translate-y-1/2 bg-surface-container text-on-surface-variant px-2 font-semibold flex flex-row justify-center items-center gap-1"
              }
              style={{
                lineHeight: 1,
                fontSize: width / 10,
                paddingLeft: "7%",
                paddingRight: "7%",
              }}
            >
              <div className={"w-3 h-3 self-center"}>
                <PlayerAvailability availability={entry.player_status || entry.availability_status} />
              </div>
              <span>{withPower ? `+${totalCardPower}%` : abbrv_football_positions[position]}</span>
            </div>
          </div>
          <div
            className={`${
              hasPickScore
                ? "grid grid-rows-1 grid-cols-2 justify-evenly items-center gap-x-[0.375rem]"
                : "flex justify-center items-center"
            } `}
            style={{ height: width / 3.5 }}
          >
            <div
              className={`${hasPickScore ? "h-full w-full" : "h-full mx-auto"}`}
              style={hasPickScore ? undefined : { width: "calc(50% - (0.375rem /2))" }}
            >
              {hasCostRestriction ? (
                <SorareScoreAverage avg={entry.cost} />
              ) : (
                <ScoreAverage
                  hidePct
                  size="adapting"
                  avg={entry.last_15}
                  period={15}
                  played={entry.last_15_played}
                  power={entry.card.Power}
                  powerAdj={powerAdjusted}
                  sAvg={entry.s15Avg}
                  sApps={entry.s15Apps}
                />
              )}
            </div>
            {hasPickScore && <PickScoreDetails main={mainPickScore} detailed={pickScoreDetails} isCapped={isCapped} />}
          </div>
          <div className={"flex self-center py-1 items-center w-full"} style={{ height: hhh + "px" }}>
            <div className={"px-[5%] w-full"}>
              <GameInLineVsWithDetails
                adapting={true}
                playerTeam={entry.player.TeamId}
                team1={entry.player_team}
                team2={entry.opposing_team}
                game={entry.next_game}
                playerNationalTeam={entry.player.NationalTeam}
                odds={odds}
                standings={standings}
                forExport={forExport}
              />
            </div>
          </div>
          <div className={"flex justify-center items-center"} style={{ height: width / 4 + "px" }}>
            <div className={"px-[5%] w-full"}>
              <PlayerCommunityProjectionWithHover
                adapting={true}
                playerTeamId={entry.player.TeamId}
                projection={props.projections ? props.projections[entry.player.PlayerId] : undefined}
                sport={sorareFootball}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LockComponent = (props) => {
  const { locked, width, isGlobalHover, onRemoveLock, onLock } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`bg-surface-container cursor-pointer rounded-full p-1.5 hover:opacity-90`}
      title={locked ? "Unlock" : "Lock"}
      style={{
        width: Math.max(width / 3.2, 28),
        height: Math.max(width / 3.2, 28),
      }}
      onClick={() => {
        if (locked) {
          onRemoveLock();
        } else {
          onLock();
        }
        setIsHover(false);
      }}
    >
      {locked ? (
        isHover ? (
          <UnlockIcon className={"w-full h-full fill-on-surface"} />
        ) : (
          <LockIcon className={"w-full h-full fill-on-surface"} />
        )
      ) : isGlobalHover ? (
        <LockIcon className={"w-full h-full fill-on-surface"} />
      ) : null}
    </div>
  );
};

export const CaptainLabel = (props) => {
  // replace by svg
  const { onClick, isCaptain, borderWidth } = props;
  const [refContainer, { width }] = useElementSize();
  const [dynamicInfo, setDynamicInfo] = useState({
    containerBorder: borderWidth !== undefined ? borderWidth : 0,
    innerContainerBorder: 0,
    fontSize: 0,
  });

  useEffect(() => {
    setDynamicInfo({
      containerBorder: borderWidth !== undefined ? borderWidth : width / 14,
      innerContainerBorder: width / 20,
      fontSize: width / 3,
    });
  }, [width]);

  return (
    <div
      ref={refContainer}
      id={"captain-label"}
      className={
        "relative cursor-pointer origin-top-left w-[75%] h-full -translate-y-[50%] border-surface-container bg-surface-container overflow-hidden"
      }
      style={{ borderRadius: "30%", borderWidth: dynamicInfo.containerBorder }}
      onClick={onClick}
    >
      <div
        className={`absolute border-limited w-full h-full font-headers font-semibold ${
          isCaptain ? "text-brand-black bg-limited" : "text-limited bg-white"
        } flex flex-row justify-center overflow-hidden`}
        style={{
          fontSize: dynamicInfo.fontSize,
          lineHeight: "normal",
          borderRadius: "30%",
          borderWidth: dynamicInfo.innerContainerBorder,
        }}
      >
        <div className={"grid grid-rows-2 grid-cols-1 w-full h-full"}>
          <div></div>
          <div className={"relative"}>
            <span className={"absolute top-0 text-center flex justify-center w-full h-full items-center"} style={{ lineHeight: 1 }}>
              C
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuggestionLoader = () => {
  return (
    <div className="relative bg-transparent min-h-full">
      <div className={"absolute left-0 top-0 flex flex-row justify-center items-center w-full h-full animate-suggestion-loader-outer"}>
        <svg className={"m-auto "} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            className={"animate-suggestion-loader-outer-color"}
            cx="40"
            cy="40"
            r="38"
            fill="#39FEE4"
            fillOpacity="0.4"
            stroke="white"
            strokeWidth="3"
          />
        </svg>
      </div>
      <div className={"absolute left-0 top-0 flex flex-row justify-center items-center w-full h-full"}>
        <svg className={"m-auto"} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.6" cx="24.9" cy="24.9" r="24.9" fill="#12F4D6" />
          <path
            d="M26.2482 20.2578H26.1734V39.2593H26.2482C31.4955 39.2593 35.7505 35.0052 35.7505 29.7571C35.7505 24.509 31.4963 20.2548 26.2482 20.2548"
            fill="#F55205"
          />
          <path d="M35.7496 19.5761V9.9999H26.1734C26.1734 15.2879 30.462 19.5761 35.7496 19.5761Z" fill="white" />
          <path
            d="M19.7877 29.6845C18.8407 29.6846 17.915 29.9655 17.1277 30.4917C16.3403 31.0179 15.7267 31.7657 15.3644 32.6406C15.002 33.5155 14.9073 34.4782 15.0921 35.407C15.2769 36.3358 15.7329 37.1889 16.4026 37.8585C17.0722 38.5281 17.9254 38.9841 18.8542 39.1688C19.7829 39.3535 20.7457 39.2587 21.6205 38.8962C22.4954 38.5338 23.2432 37.9201 23.7693 37.1327C24.2954 36.3453 24.5762 35.4196 24.5762 34.4727C24.5761 33.2027 24.0716 31.9849 23.1735 31.0869C22.2755 30.189 21.0576 29.6845 19.7877 29.6845Z"
            fill="white"
          />
          <path
            d="M15 19.5015C15 24.7492 19.2541 29.0038 24.5022 29.0038H24.5771V10.0014H24.5022C19.2546 10.0014 15 14.2556 15 19.5036"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
