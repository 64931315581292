import SDIcon from "../../img/sd-logo.svg";
import NewCardIcon from "./newCardIcon";
import React from "react";
import { ReactComponent as RaffleTicketIcon } from "../../img/icons-raffle-ticket-no-color.svg";
import Ethereum from "../../img/ethereum-icon-white.svg";
import { findPriceForUserUnit } from "./formatMoney";
import { withUser } from "../../userContext";

export const RewardsBox = withUser((props) => {
  const { loading, cards, money, raffleTicket, bg, title, label } = props;
  const width = props.width || "w-80";
  const height = props.height || "h-40";
  if (loading) {
    return (
      <div
        className={`rounded-xl animate-pulse-sm flex flex-col bg-black-4D bg-center bg-no-repeat bg-cover bg-projection-leaderboard-result overflow-hidden ${width} p-5 text-white gap-1 ${height}`}
      ></div>
    );
  }

  // group by scarcity and tier and count rewards
  const rewardsByScarcity = {};
  cards?.forEach((reward) => {
    const scarcity = reward.scarcity;
    const tier = reward.tier;
    if (rewardsByScarcity[scarcity] === undefined) {
      rewardsByScarcity[scarcity] = {};
    }
    if (rewardsByScarcity[scarcity][tier] === undefined) {
      rewardsByScarcity[scarcity][tier] = 0;
    }
    rewardsByScarcity[scarcity][tier] += 1;
  });

  return (
    <div
      className={`relative rounded-xl flex flex-col bg-center bg-no-repeat bg-cover ${bg} overflow-hidden ${width} p-5 text-white gap-1 ${height}`}
    >
      <div className={"absolute flex flex-row right-0 bottom-0 transform -translate-x-1/2 -translate-y-1/2 justify-center self-center"}>
        <div className={"w-6 h-6"}>
          <img className={"w-full h-full object-contain"} src={SDIcon} />
        </div>
      </div>
      <div className={"flex flex-col gap-2 h-full justify-center"}>
        {title ? (
          <div className={"flex flex-row justify-between h-full"}>
            <div className={"flex flex-col items-center justify-center gap-2 h-full w-full"}>
              <p className={"text-4xl"}>{title}</p>
              <p className={"text-xl font-semibold text-[#2C2E41]"}>{label || ""}</p>
            </div>
          </div>
        ) : (
          <div className={"flex flex-row"}>
            <h3 className={"m-0 text-lg"}>{label || ""}</h3>
          </div>
        )}
        {(rewardsByScarcity || raffleTicket || (money && money.eth > 0)) && (
          <div className={"flex flex-col gap-2"}>
            {rewardsByScarcity &&
              Object.entries(rewardsByScarcity)
                .sort()
                .map(([scarcity, rewardsByTier]) => {
                  const scarcityRewards = Object.values(rewardsByTier).reduce((a, b) => a + b, 0);
                  return (
                    <div key={scarcity} className={"flex flex-row gap-1.5 text-sm text-white items-center font-semibold"}>
                      <div className={"py-0.5 px-2 text-sm bg-white bg-opacity-20 rounded flex flex-row gap-1"}>
                        <div>{scarcityRewards}x</div>
                        <div className={"w-2.5"}>
                          <NewCardIcon scarcity={scarcity?.toLowerCase()} />
                        </div>
                      </div>
                      <div className={"flex flex-row text-xs"}>
                        {rewardsByTier &&
                          Object.entries(rewardsByTier).map(([tier, count], index) => {
                            return (
                              <div key={tier} className={`${index > 0 ? "border-l border-white border-opacity-30" : ""} px-1`}>
                                {count}x Tier {tier > 0 ? tier : "Star"}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            {money && money.eth > 0 && (
              <div className={"flex flex-row gap-1.5 text-sm text-white items-center font-semibold"}>
                <div className={"py-0.5 px-2 text-sm bg-white bg-opacity-20 rounded flex flex-row gap-1"}>
                  {props.user.preferredUnit === "eth" && (
                    <div className={"w-4 my-auto"}>
                      <img src={Ethereum} className={"w-4"} />
                    </div>
                  )}
                  <div>{findPriceForUserUnit(money.eth, money, props.user.preferredUnit, true)}</div>
                </div>
              </div>
            )}
            {raffleTicket && (
              <div className={"flex flex-row gap-1.5 text-sm text-white items-center font-semibold"}>
                <div className={"py-0.5 px-2 text-sm bg-white bg-opacity-20 rounded flex flex-row gap-1"}>
                  <RaffleTicketIcon className={"w-4 h-4 fill-white"} />
                  1x Raffle ticket
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
