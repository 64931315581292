import React, { useEffect, useState } from "react";
import ordinal_suffix_of from "../util/ordinalNumber";
import LineSeparator from "../../img/line-separator.svg";
import { playing_status_labels, PlayingStatus } from "../util/playingStatus";
import ScoreGauge from "./scoreGauge";
import LimitedPoint from "../../img/limited-point.png";
import RarePoint from "../../img/rare-point.png";
import SuperRarePoint from "../../img/sr-point.png";
import { withUser } from "../../userContext";
import { useHistory } from "react-router-dom";
import FavoriteBubble from "../util/favoriteBubble";
import NotificationBubble from "../util/notificationBubble";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import UnknownPlayer from "../util/unknownPlayer";
import { convertToBaseballAverage } from "../util/baseballAverage";
import NBAProjectedMinutes from "../util/nbaProjectedMinutes";
import PriceIndicator from "./priceIndicator";

const Separator = () => {
  return (
    <div className={"w-4 hidden md:inline-block justify-center text-center self-center"}>
      <img src={LineSeparator} />
    </div>
  );
};

const ScoreAndIndicator = (props) => {
  let score;
  if (props.noFixed) {
    score = props.score;
  } else {
    score = props.score.toFixed(1);
  }
  return (
    <div className={"flex flex-col justify-center space-y-2 text-center self-center"}>
      <p className={"text-xs 2xl:text-sm text-textGrey4 font-semibold"}>{props.title}</p>
      <p className={"text-sm 2xl:text-med font-semibold"}>{score}</p>
    </div>
  );
};

const BasketballScore = (props) => {
  let score;
  if (props.noFixed) {
    score = props.score;
  } else {
    score = props.score.toFixed(1);
  }
  return (
    <div className={"flex flex-col justify-center text-center self-center"}>
      <p className={"text-sm font-semibold"}>{score}</p>
    </div>
  );
};

function PlayerRankingLine(props) {
  const line = props.ranking;
  const rank = props.rank;
  const player = line.player;
  const position = props.position;

  const [favorited, setFavorited] = useState(line.favorited);
  const [hasPriceAlerts, setHasPriceAlerts] = useState(line.has_price_alerts);

  useEffect(() => {
    setFavorited(props.ranking.favorited);
    setHasPriceAlerts(props.ranking.has_price_alerts);
  }, [props]);

  let history = useHistory();
  const sport = props.sport;

  const indicatorsClass =
    "flex flex-row 3xl:space-x-8 2xl:space-x-6 xl:space-x-4 xl:justify-around 2xl:justify-center px-1 xl:w-2/12 lg:w-1/12";
  const aaDaClass = "hidden xl:inline-block self-center";

  const { apg5, apg15, apg40, apgseason } = line;
  const { era5, era15, era40, eraseason } = line;
  const { woba5, woba15, woba40, wobaseason } = line;
  const { ip5, ip15, ip40, ipseason } = line;
  const { bavg5, bavg15, bavg40, bavgseason } = line;
  const { fip5, fip15, fip40, fipseason } = line;
  const avgSeason = line.avgseason;
  const title1 = props.title1;
  const title2 = props.title2;
  const title3 = props.title3;
  let placeHolder1_5 = apg5.toFixed(1);
  let placeHolder2_5 = convertToBaseballAverage(woba5);
  let placeHolder3_5 = convertToBaseballAverage(bavg5);
  let placeHolder1_15 = apg15.toFixed(1);
  let placeHolder2_15 = convertToBaseballAverage(woba15);
  let placeHolder3_15 = convertToBaseballAverage(bavg15);
  let placeHolder1_40 = apg40.toFixed(1);
  let placeHolder2_40 = convertToBaseballAverage(woba40);
  let placeHolder3_40 = convertToBaseballAverage(bavg40);
  let placeHolder1_S = apgseason.toFixed(1);
  let placeHolder2_S = convertToBaseballAverage(wobaseason);
  let placeHolder3_S = convertToBaseballAverage(bavgseason);
  if (props.value1 === "ip") {
    placeHolder1_5 = ip5.toFixed(1);
    placeHolder2_5 = era5.toFixed(2);
    placeHolder3_5 = fip5.toFixed(2);
    placeHolder1_15 = ip15.toFixed(1);
    placeHolder2_15 = era15.toFixed(2);
    placeHolder3_15 = fip15.toFixed(2);
    placeHolder1_40 = ip40.toFixed(1);
    placeHolder2_40 = era40.toFixed(2);
    placeHolder3_40 = fip40.toFixed(2);
    placeHolder1_S = ipseason.toFixed(1);
    placeHolder2_S = eraseason.toFixed(2);
    placeHolder3_S = fipseason.toFixed(2);
  }
  const { points10, points40, pointsSeason } = line;
  const { rebounds10, rebounds40, reboundsSeason } = line;
  const { assists10, assists40, assistsSeason } = line;
  const { minutes10, minutes40, minutesSeason } = line;
  const { fppm10, fppm40, fppmSeason } = line;
  let placeHolder4_5 = 0;
  let placeHolder4_15 = 0;
  let placeHolder4_S = 0;
  let placeHolder5_5 = 0;
  let placeHolder5_15 = 0;
  let placeHolder5_S = 0;
  if (props.sport === sorareBasketball) {
    placeHolder1_5 = minutes10;
    placeHolder2_5 = points10.toFixed(1);
    placeHolder3_5 = rebounds10.toFixed(1);
    placeHolder4_5 = assists10.toFixed(1);
    placeHolder5_5 = fppm10.toFixed(2);
    placeHolder1_15 = minutes40;
    placeHolder2_15 = points40.toFixed(1);
    placeHolder3_15 = rebounds40.toFixed(1);
    placeHolder4_15 = assists40.toFixed(1);
    placeHolder5_15 = fppm40.toFixed(2);
    placeHolder1_S = minutesSeason;
    placeHolder2_S = pointsSeason.toFixed(1);
    placeHolder3_S = reboundsSeason.toFixed(1);
    placeHolder4_S = assistsSeason.toFixed(1);
    placeHolder5_S = fppmSeason.toFixed(2);
  }

  const newMatrix = props.newMatrix;

  if (sport === sorareBaseball) {
    return (
      <tr className={"bg-white border-b border-grey-e5"}>
        <td>
          <div className={"flex flex-col items-center"}>
            <p className={"3xl:text-lg 2xl:text-base xl:text-med md:text-sm text-xs font-semibold w-6 md:w-auto"}>
              {ordinal_suffix_of(rank)}
            </p>
            <p className={"hidden lg:inline-block text-xxxs xl:text-xxs font-medium"}>PLACE</p>
          </div>
        </td>
        <td>
          <a
            className={"flex flex-row self-center xl:space-x-4 cursor-pointer py-2"}
            href={"/player/" + player.PlayerId}
            target={"_blank"}
            rel="noreferrer"
          >
            <div className={"hidden xl:w-10 self-center xl:inline-block"}>
              {player.Avatar !== "" && <img src={player.Avatar} className={"h-auto object-contain"} />}
              {player.Avatar === "" && <UnknownPlayer size={"w-full h-full"} />}
            </div>
            <div className={"flex flex-col justify-center space-y-1 w-32 md:w-full"}>
              <p className={"2xl:text-med 3xl:text-med xl:text-sm text-xs font-semibold"}>{player.DisplayName}</p>
              <p className={"text-textGrey4 text-xxs 2xl:text-xs font-medium"}>
                {player.Position} - <PlayingStatus player={player} status={player.PlayingStatus} /> - {player.Age}
              </p>
            </div>
          </a>
        </td>
        <td>
          <div className={"flex flex-col space-y-2 py-2"}>
            <div className={"flex mx-auto self-center justify-center"}>
              <ScoreGauge sport={sport} circleSize={"w-14"} score={line.avg20} />
            </div>
            {/*
                        <p className={"text-xxs text-center text-textGrey3 font-medium"}>{(line.l20 / 20 * 100).toFixed(0)}% <span
                            className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED</p>*/}
          </div>
        </td>
        <td>
          <ScoreAndIndicator title={title1} noFixed={true} score={placeHolder1_15} />
        </td>
        <td>
          <ScoreAndIndicator title={title2} noFixed={true} score={placeHolder2_15} />
        </td>
        <td>
          <ScoreAndIndicator title={title3} noFixed={true} score={placeHolder3_15} />
        </td>
        <td>
          <div className={"flex flex-col space-y-2"}>
            <div className={"flex mx-auto self-center justify-center"}>
              <ScoreGauge sport={sport} circleSize={"w-14"} score={line.avg40} />
            </div>
            {/*
                        <p className={"text-xxs text-center text-textGrey3 font-medium"}>{(line.l40 / 40 * 100).toFixed(0)}% <span
                            className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED</p>*/}
          </div>
        </td>
        <td>
          <ScoreAndIndicator title={title1} noFixed={true} score={placeHolder1_40} />
        </td>
        <td>
          <ScoreAndIndicator title={title2} noFixed={true} score={placeHolder2_40} />
        </td>
        <td>
          <ScoreAndIndicator title={title3} noFixed={true} score={placeHolder3_40} />
        </td>
        <td>
          <div className={"flex flex-col space-y-2"}>
            <div className={"flex mx-auto self-center justify-center"}>
              <ScoreGauge sport={sport} circleSize={"w-14"} score={avgSeason} />
            </div>
            {/*
                        <p className={"text-xxs text-center text-textGrey3 font-medium"}>{(line.l40 / 40 * 100).toFixed(0)}% <span
                            className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED</p>*/}
          </div>
        </td>
        <td>
          <ScoreAndIndicator title={title1} noFixed={true} score={placeHolder1_S} />
        </td>
        <td>
          <ScoreAndIndicator title={title2} noFixed={true} score={placeHolder2_S} />
        </td>
        <td>
          <ScoreAndIndicator title={title3} noFixed={true} score={placeHolder3_S} />
        </td>
        <td>
          <div className={"w-full flex flex-row justify-center"}>
            <PriceIndicator
              scarcity={"limited"}
              avg={line.l_avg}
              bmp={line.bmpl}
              user={props.user}
              showValo={props.showValo}
              sport={sport}
              icon={LimitedPoint}
              lastSales={line.last_sales_limited}
              showBmp={props.showBmp}
            />
          </div>
        </td>
        <td>
          <PriceIndicator
            scarcity={"rare"}
            avg={line.r_avg}
            bmp={line.bmp_r}
            user={props.user}
            icon={RarePoint}
            showValo={props.showValo}
            sport={sport}
            lastSales={line.last_sales_rare}
            showBmp={props.showBmp}
          />
        </td>
        <td>
          <PriceIndicator
            scarcity={"super-rare"}
            avg={line.sr_avg}
            bmp={line.bmp_sr}
            user={props.user}
            showValo={props.showValo}
            sport={sport}
            icon={SuperRarePoint}
            lastSales={line.last_sales_super_rare}
            showBmp={props.showBmp}
          />
        </td>
        <td>
          <div className={"my-auto flex flex-row justify-center cursor-pointer"} style={{ minWidth: "60px" }}>
            <FavoriteBubble className={"w-9 h-9"} favorite={favorited} player={player} />
            <NotificationBubble className={"w-9 h-9"} hasNotifications={hasPriceAlerts} player={player} />
          </div>
        </td>
      </tr>
    );
  } else if (sport === sorareBasketball) {
    return (
      <tr className={"bg-white border-b border-grey-e5"}>
        <td>
          <div className={"flex flex-col items-center"}>
            <p className={"3xl:text-lg 2xl:text-base xl:text-med md:text-sm text-xs font-semibold w-6 md:w-auto"}>
              {ordinal_suffix_of(rank)}
            </p>
            <p className={"hidden lg:inline-block text-xxxs xl:text-xxs font-medium"}>PLACE</p>
          </div>
        </td>
        <td>
          <a
            className={"flex flex-row self-center xl:space-x-4 cursor-pointer py-2"}
            href={"/player/" + player.PlayerId}
            target={"_blank"}
            rel="noreferrer"
          >
            <div className={"hidden xl:w-10 self-center xl:inline-block"}>
              {player.Avatar !== "" && <img src={player.Avatar} className={"h-auto object-contain"} />}
              {player.Avatar === "" && <UnknownPlayer size={"w-full h-full"} />}
            </div>
            <div className={"flex flex-col justify-center space-y-1 w-32 md:w-full"}>
              <p className={"2xl:text-med 3xl:text-med xl:text-sm text-xs font-semibold"}>{player.DisplayName}</p>
              <p className={"text-textGrey4 text-xxs 2xl:text-xs font-medium"}>
                {player.Position} - <PlayingStatus player={player} status={player.PlayingStatus} /> - {player.Age}
              </p>
            </div>
          </a>
        </td>
        <td>
          <div className={"flex flex-col space-y-2 py-2"}>
            <div className={"flex mx-auto self-center justify-center"}>
              <ScoreGauge sport={sport} circleSize={"w-14"} score={line.avg5} />
            </div>
            {/*
                        <p className={"text-xxs text-center text-textGrey3 font-medium"}>{(line.l20 / 20 * 100).toFixed(0)}% <span
                            className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED</p>*/}
          </div>
        </td>
        <td>
          <div className={"flex flex-row justify-center"}>
            <NBAProjectedMinutes minutes={placeHolder1_5} real={true} precision={0} />
          </div>
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder2_5} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder3_5} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder4_5} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder5_5} />
        </td>
        <td>
          <div className={"flex flex-col space-y-2"}>
            <div className={"flex mx-auto self-center justify-center"}>
              <ScoreGauge sport={sport} circleSize={"w-14"} score={line.avg20} />
            </div>
            {/*
                        <p className={"text-xxs text-center text-textGrey3 font-medium"}>{(line.l40 / 40 * 100).toFixed(0)}% <span
                            className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED</p>*/}
          </div>
        </td>
        <td>
          <div className={"flex flex-row justify-center"}>
            <NBAProjectedMinutes minutes={placeHolder1_15} real={true} precision={0} />
          </div>
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder2_15} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder3_15} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder4_15} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder5_15} />
        </td>
        <td>
          <div className={"flex flex-col space-y-2"}>
            <div className={"flex mx-auto self-center justify-center"}>
              <ScoreGauge sport={sport} circleSize={"w-14"} score={line.avg40} />
            </div>
            {/*
                        <p className={"text-xxs text-center text-textGrey3 font-medium"}>{(line.l40 / 40 * 100).toFixed(0)}% <span
                            className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED</p>*/}
          </div>
        </td>
        <td>
          <div className={"flex flex-row justify-center"}>
            <NBAProjectedMinutes minutes={placeHolder1_S} real={true} precision={0} />
          </div>
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder2_S} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder3_S} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder4_S} />
        </td>
        <td>
          <BasketballScore noFixed={true} score={placeHolder5_S} />
        </td>
        <td>
          <div className={"w-full flex flex-row justify-center"}>
            <PriceIndicator
              scarcity={"limited"}
              avg={line.l_avg}
              bmp={line.bmpl}
              user={props.user}
              showValo={props.showValo}
              sport={sport}
              icon={LimitedPoint}
              lastSales={line.last_sales_limited}
              showBmp={props.showBmp}
            />
          </div>
        </td>
        <td>
          <PriceIndicator
            scarcity={"rare"}
            avg={line.r_avg}
            bmp={line.bmp_r}
            user={props.user}
            icon={RarePoint}
            showValo={props.showValo}
            sport={sport}
            lastSales={line.last_sales_rare}
            showBmp={props.showBmp}
          />
        </td>
        <td>
          <PriceIndicator
            scarcity={"super-rare"}
            avg={line.sr_avg}
            bmp={line.bmp_sr}
            user={props.user}
            showValo={props.showValo}
            sport={sport}
            icon={SuperRarePoint}
            lastSales={line.last_sales_super_rare}
            showBmp={props.showBmp}
          />
        </td>
        <td>
          <div className={"my-auto flex flex-row justify-center cursor-pointer"} style={{ minWidth: "60px" }}>
            <FavoriteBubble className={"w-9 h-9"} favorite={favorited} player={player} />
            <NotificationBubble className={"w-9 h-9"} hasNotifications={hasPriceAlerts} player={player} />
          </div>
        </td>
      </tr>
    );
  } else {
    let diff = line.previous_rank - line.current_rank;
    if (diff === 0) {
      diff = "=";
    } else if (diff > 0) {
      diff = "+" + diff;
    }
    const playingStatus = (t1OrAbove(props.user?.tier) || player.Sport === sorareBaseball) && playing_status_labels[player.PlayingStatus];
    return (
      <div
        className={
          "bg-white flex flex-row rounded-xl p-2 md:space-x-2 space-x-4 hover:shadow-custom overflow-y-auto md:overflow-y-hidden no-scroll-bar w-full"
        }
      >
        {newMatrix && (
          <div className={"3xl:w-16 2xl:w-12 xl:w-8 flex flex-col justify-center text-center w-6 space-y-1"}>
            <p className={"3xl:text-lg 2xl:text-base xl:text-med md:text-sm text-xs font-semibold w-6 md:w-auto"}>
              {ordinal_suffix_of(line.current_rank)}
            </p>
            <p className={"text-xs text-center"}>({diff})</p>
          </div>
        )}
        {!newMatrix && (
          <div className={"3xl:w-16 2xl:w-12 xl:w-8 flex flex-col justify-center text-center w-6 space-y-1"}>
            <p className={"3xl:text-lg 2xl:text-base xl:text-med md:text-sm text-xs font-semibold w-6 md:w-auto"}>
              {ordinal_suffix_of(rank)}
            </p>
          </div>
        )}
        <Separator />
        <a
          className={"flex flex-row self-center xl:space-x-4 cursor-pointer md:w-2/12"}
          href={"/player/" + player.PlayerId}
          target={"_blank"}
          rel="noreferrer"
        >
          <div className={"hidden xl:w-10 self-center xl:inline-block"}>
            {player.Avatar !== "" && <img src={player.Avatar} className={"h-auto object-contain"} />}
            {player.Avatar === "" && <UnknownPlayer size={"w-full h-full"} />}
          </div>
          <div className={"flex flex-col justify-center space-y-1 w-32 md:w-full"}>
            <p className={"2xl:text-med 3xl:text-med xl:text-sm text-xs font-semibold"}>{player.DisplayName}</p>
            <p className={"text-textGrey4 text-xxs 2xl:text-xs font-medium"}>
              {player.Position}{" "}
              {playingStatus && (
                <>
                  <span>{`- `}</span>
                  <PlayingStatus status={player.PlayingStatus} player={player} />
                </>
              )}{" "}
              - {player.Age}
            </p>
          </div>
        </a>

        <Separator />
        <div className={indicatorsClass}>
          <div className={"flex flex-col space-y-2"}>
            <div className={"flex mx-auto self-center justify-center"}>
              <ScoreGauge
                noCircle={true}
                sport={sport}
                circleSize={"w-14"}
                score={newMatrix ? line.newAvg5 : line.avg5}
                aaScore={newMatrix ? line.newAA5 : line.aa5}
                decisiveScore={newMatrix ? line.newD5 : line.d5}
              />
            </div>
            <p className={"text-xxs text-center text-textGrey3 font-medium"}>
              {((line.l5 / 5) * 100).toFixed(0)}% <span className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED
            </p>
          </div>
          {t1OrAbove(props.user.tier) && (
            <div className={aaDaClass}>
              <ScoreAndIndicator title={"DS5"} score={newMatrix ? line.newD5 : line.d5} />
            </div>
          )}
          {t1OrAbove(props.user.tier) && (
            <div className={aaDaClass}>
              <ScoreAndIndicator title={"AA5"} score={newMatrix ? line.newAA5 : line.aa5} />
            </div>
          )}
        </div>
        <Separator />
        <div className={indicatorsClass}>
          <div className={"flex flex-col space-y-2"}>
            <div className={"mx-auto"}>
              <ScoreGauge
                sport={sport}
                noCircle={true}
                circleSize={"w-14"}
                score={newMatrix ? line.newAvg20 : line.avg20}
                aaScore={newMatrix ? line.newAA20 : line.aa20}
                decisiveScore={newMatrix ? line.newD20 : line.d20}
              />
            </div>
            <p className={"text-xxs text-center text-textGrey3 font-medium"}>
              {((line.l20 / 15) * 100).toFixed(0)}% <span className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED
            </p>
          </div>
          {t1OrAbove(props.user.tier) && (
            <div className={aaDaClass}>
              <ScoreAndIndicator title={"DS15"} score={newMatrix ? line.newD20 : line.d20} />
            </div>
          )}
          {t1OrAbove(props.user.tier) && (
            <div className={aaDaClass}>
              <ScoreAndIndicator title={"AA15"} score={newMatrix ? line.newAA20 : line.aa20} />
            </div>
          )}
        </div>
        <Separator />
        <div className={indicatorsClass}>
          <div className={"flex flex-col space-y-2"}>
            <div className={"mx-auto"}>
              <ScoreGauge
                sport={sport}
                noCircle={true}
                circleSize={"w-14"}
                score={newMatrix ? line.newAvg40 : line.avg40}
                aaScore={newMatrix ? line.newAA40 : line.aa40}
                decisiveScore={newMatrix ? line.newD40 : line.d40}
              />
            </div>
            <p className={"text-xxs text-center text-textGrey3 font-medium"}>
              {((line.l40 / 40) * 100).toFixed(0)}% <span className={"hidden 2xl:inline-block"}> GAMES</span> PLAYED
            </p>
          </div>
          {t1OrAbove(props.user.tier) && (
            <div className={aaDaClass}>
              <ScoreAndIndicator title={"DS40"} score={newMatrix ? line.newD40 : line.d40} />
            </div>
          )}
          {t1OrAbove(props.user.tier) && (
            <div className={aaDaClass}>
              <ScoreAndIndicator title={"AA40"} score={newMatrix ? line.newAA40 : line.aa40} />
            </div>
          )}
        </div>
        <Separator />
        <div className={"flex flex-row 3xl:space-x-8 2xl:space-x-6 lg:space-x-4 md:space-x-2 space-x-4 xl:w-3/12 lg:w-6/12 md:w-4/12"}>
          <PriceIndicator
            scarcity={"limited"}
            avg={line.l_avg}
            bmp={line.bmpl}
            user={props.user}
            showValo={props.showValo}
            icon={LimitedPoint}
            lastSales={line.last_sales_limited}
            showBmp={props.showBmp}
          />
          <PriceIndicator
            scarcity={"rare"}
            avg={line.r_avg}
            bmp={line.bmp_r}
            user={props.user}
            icon={RarePoint}
            showValo={props.showValo}
            lastSales={line.last_sales_rare}
            showBmp={props.showBmp}
          />
          <PriceIndicator
            scarcity={"super-rare"}
            avg={line.sr_avg}
            bmp={line.bmp_sr}
            user={props.user}
            showValo={props.showValo}
            icon={SuperRarePoint}
            lastSales={line.last_sales_super_rare}
            showBmp={props.showBmp}
          />
        </div>
        <div className={"my-auto flex flex-row justify-center cursor-pointer"} style={{ minWidth: "60px" }}>
          <FavoriteBubble className={"w-9 h-9"} favorite={favorited} player={player} />
          <NotificationBubble className={"w-9 h-9"} hasNotifications={hasPriceAlerts} player={player} />
        </div>
      </div>
    );
  }
}

export default withUser(PlayerRankingLine);
