import React, { Component } from "react";
import { Box } from "grommet";
import HomeStats from "../stats/homeStats";
import AuctionVolumeHistory from "./auctionVolumeHistory";
import OfferVolumeHistory from "./offerVolumeHistory";
import LineupHistory from "./lineupHistory";
import CurrentMarketState from "./currentMarketState";

class StatsPage extends Component {
  render() {
    return (
      <Box align={"center"} margin={{ top: "small" }}>
        <Box width={"80%"}>
          <CurrentMarketState />
          <HomeStats />
          <AuctionVolumeHistory />
          <OfferVolumeHistory />
          <LineupHistory />
        </Box>
      </Box>
    );
  }
}

export default StatsPage;
