import React, { useState } from "react";
import LogoMobile from "../../img/logo-sd-mobile.svg";
import HomeSearch from "./homeSearch";
import SidebarItem from "./sidebarItem";
import MobileSearch from "../mobile-search/MobileSearch";
import MobileMenu from "../mobile-menu/MobileMenu";
import builder from "../../img/lineup_builder_icon.svg";
import so5 from "../../img/scoreboard.svg";
import cards from "../../img/sell_white.svg";
import stats from "../../img/query_stats.svg";
import NavBarBlackBg from "../../img/nav-bar-black-bg.png";
import NavBarBlackBgStar from "../../img/nav-bar-black-bg-star.png";
import NavBarBlackBgMarket from "../../img/bg-nav-black-market.png";
import BgBlackBuilder from "../../img/bg-nav-bar-builder.png";
import BgBlackGallery from "../../img/bg-nav-black-gallery.png";
import { withUser } from "../../userContext";
import { connectedUserExtendedNavItems, SideBarUserFooter, SideBarUserHeader } from "./navbarComponents";

function HeaderBar(props) {
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleSearchToggle = () => {
    setShowSearch(!showSearch);
    setShowMenu(false);
  };

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
    setShowSearch(false);
  };

  const isAuth = props.user.username !== undefined && props.user.username !== null && props.user.username !== "";
  const isHomePage = document.location.pathname === "/";
  const menuItems = connectedUserExtendedNavItems(props.user);

  if (!isAuth && isHomePage) {
    return null;
  }

  return (
    <header className="flex items-center bg-brand-light-green h-16">
      <div className="lg:hidden flex flex-1 flex-row items-center justify-between px-4 py-3">
        <div className="justify-center self-center">
          <a href="/">
            <img src={LogoMobile} width="32px" alt="SorareData homepage" />
          </a>
        </div>

        <div className="flex lg:hidden self-center">
          <MobileSearch showSearch={showSearch} onSearchToggle={handleSearchToggle} />
          <MobileMenu showMenu={showMenu} onMenuToggle={handleMenuToggle} user={props.user} />
        </div>
      </div>

      <div className="hidden lg:flex lg:flex-row lg:space-x-4 w-11/12 mx-auto">
        <div className="flex flex-1 items-center space-x-8">
          <a href="/">
            <img src={LogoMobile} className="w-8" alt="SorareData homepage" />
          </a>

          <HomeSearch />
        </div>

        <div className="flex flex-row space-x-4">
          <SidebarItem
            menu={menuItems.so5Items}
            title={menuItems.so5Items.title}
            extendedTitle={menuItems.so5Items.extendedTitle}
            tagLine={menuItems.so5Items.tagLine}
            boxTitle={menuItems.so5Items.boxTitle}
            boxTagLine={menuItems.so5Items.boxTagline}
            boxLink={menuItems.so5Items.boxLink}
            boxBg={NavBarBlackBg}
            secondaryBoxTitle={menuItems.so5Items.secondaryBoxTitle}
            secondaryBoxTagLine={menuItems.so5Items.secondaryBoxTagline}
            secondaryBoxLink={menuItems.so5Items.secondaryBoxLink}
            secondaryBoxAction={menuItems.so5Items.secondaryBoxAction}
            secondaryBoxBg={menuItems.so5Items.secondaryBoxBg}
            image={so5}
          />
          <SidebarItem
            menu={menuItems.scoutItems}
            title={menuItems.scoutItems.title}
            extendedTitle={menuItems.scoutItems.extendedTitle}
            tagLine={menuItems.scoutItems.tagLine}
            boxTitle={menuItems.scoutItems.boxTitle}
            boxTagLine={menuItems.scoutItems.boxTagline}
            boxLink={menuItems.scoutItems.boxLink}
            boxBg={NavBarBlackBgStar}
            image={stats}
          />
          <SidebarItem
            menu={menuItems.builderItems}
            title={menuItems.builderItems.title}
            extendedTitle={menuItems.builderItems.extendedTitle}
            tagLine={menuItems.builderItems.tagLine}
            boxTitle={menuItems.builderItems.boxTitle}
            boxTagLine={menuItems.builderItems.boxTagline}
            boxLink={menuItems.builderItems.boxLink}
            boxBg={BgBlackBuilder}
            image={builder}
          />
          <SidebarItem
            menu={menuItems.marketItems}
            title={menuItems.marketItems.title}
            extendedTitle={menuItems.marketItems.extendedTitle}
            tagLine={menuItems.marketItems.tagLine}
            boxTitle={menuItems.marketItems.boxTitle}
            boxTagLine={menuItems.marketItems.boxTagline}
            boxLink={menuItems.marketItems.boxLink}
            boxBg={NavBarBlackBgMarket}
            image={cards}
          />
          <SidebarItem
            menu={menuItems.userItems}
            title={props.user.username === undefined ? "Login" : props.user.username}
            extendedTitle={props.user.username === undefined ? "Login" : props.user.username}
            tagLine={"All your cards, manager stats and account details."}
            boxTitle={"My gallery"}
            boxTagLine={"View all your players and roster stats."}
            boxLink={"/manager/" + props.user.sorareSlug}
            boxBg={BgBlackGallery}
            profilePictureUrl={props.user.sorarePictureUrl}
            noSports={true}
            tier={props.user.tier}
            header={
              props.user.username === undefined ? undefined : <SideBarUserHeader username={props.user.username} tier={props.user.tier} />
            }
            footer={props.user.username === undefined ? undefined : <SideBarUserFooter />}
            link={props.user.username !== undefined ? undefined : "/login"}
          />
        </div>
      </div>
    </header>
  );
}

export default withUser(HeaderBar);
