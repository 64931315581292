import React from "react";
import InfoNBAGames from "../../img/info-nba-games.svg";
import NBAProjectedScore from "../util/nbaProjectionScore";
import NBAProjectedMinutes from "../util/nbaProjectedMinutes";
import format from "date-fns/format";
import PopHoverWrapper from "../util/popHoverWrapper";

function NBAGamesHover(props) {
  const games = props.games;
  const player = props.player;
  if (games === null) {
    return (
      <div className={"flex flex-col justify-center text-center"}>
        <p className={"text-sm text-center font-semibold"}>No games</p>
      </div>
    );
  } else {
    return (
      <div className={"space-y-4"}>
        <div className={"flex flex-row space-x-6 px-4"}>
          {games.map((g) => {
            let isHome = true;
            if (g.awayTeam.TeamId === player.player.TeamId) {
              isHome = false;
            }
            let gameDate = new Date(g.game.GameTime);
            return (
              <div className={"flex flex-col items-center space-y-2 w-32"}>
                <div className={"flex flex-row space-x-2"}>
                  <div className={"w-6 h-6"}>
                    <img className={"w-full h-full object-contain"} src={g.homeTeam.PictureUrl} />
                  </div>
                  <p className={"text-xs self-center font-semibold"}>-</p>
                  <div className={"w-6 h-6"}>
                    <img className={"w-full h-full object-contain"} src={g.awayTeam.PictureUrl} />
                  </div>
                </div>
                <div className={"flex flex-row space-x-2"}>
                  <div className={"self-center"}>
                    <NBAProjectedScore score={g.projection.score} size={"w-7 h-7"} />
                  </div>
                  <NBAProjectedMinutes real={false} minutes={g.projection.minutes} />
                </div>
                <div className={"flex flex-row space-x-2 justify-center self-center"}>
                  <div
                    className={
                      isHome
                        ? "self-center flex flex-col items-center px-1.5 py-0.5 rounded bg-super-rare font-semibold text-xs text-white"
                        : "self-center flex flex-col items-center rounded bg-limited font-semibold text-xs text-white px-1.5 py-0.5"
                    }
                  >
                    <p>{isHome ? "H" : "A"}</p>
                  </div>
                  <div className={"flex self-center"}>
                    <div className={"px-1 bg-grey-e5 rounded w-full self-center py-0.5"}>
                      <p className={"text-xs font-medium text-textGrey3"}>{format(gameDate, "MMM dd")}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function NbaGamesDetails(props) {
  const player = props.player;
  const games = props.games;

  if (games === null) {
    return (
      <div className={"flex flex-row justify-center"}>
        <div className={"flex flex-row justify-center self-center"}>
          <p className={"text-sm text-center font-semibold"}>NG</p>
        </div>
      </div>
    );
  } else {
    const title = games?.length > 0 ? `${games.length} Game${games.length >= 2 ? "s" : ""}` : "No game";
    return (
      <div className={"relative"}>
        <PopHoverWrapper hoverTitle={title} hover={<NBAGamesHover games={games} player={player} />}>
          <div className={"flex flex-row justify-center "}>
            <div className={"flex flex-row justify-center self-center space-x-2 rounded bg-grey-f2 px-2 cursor-pointer py-0.5"}>
              <p className={"text-xs text-center font-semibold"}>{games.length}</p>
              <div className={"w-3 h-3  self-center"}>
                <img className={"self-center w-full h-full object-contain"} src={InfoNBAGames} />
              </div>
            </div>
          </div>
        </PopHoverWrapper>
      </div>
    );
  }
}

export default NbaGamesDetails;
