import { Box } from "grommet";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import ResponsiveGrid from "../util/responsiveGrid";
import HomeDashboardEntry from "../home/homeDashboardEntry";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import SelectSearch from "react-select-search";
import { scarcities_objects, scarcities_objects_with_custom_series } from "../util/scarcities";
import ToggleWithLabel from "../util/toggleWithLabel";
import ordinal_suffix_of from "../util/ordinalNumber";
import CardLocker from "../cards/cardLocker";
import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import SDLoading from "../util/SDLoading";
import SportsPicker from "../util/sportsPicker";
import { sorareBasketball, sorareFootball } from "../util/sports";
import { checkSecondUnitShouldBeDisplayed, findPriceForUserUnit } from "../util/formatMoney";

const ManagerSO5Rewards = (props) => {
  const [stats, setStats] = useState(undefined);
  const [scarcity, setScarcity] = useState(scarcities_objects_with_custom_series[0].value);
  const [keptPlayers, setKeptPlayers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sport, setSport] = useState(sorareFootball);

  useEffect(() => getManagerStats(), []);

  const getManagerStats = (s) => {
    if (s === undefined) s = sport;
    setLoading(true);
    props
      .fetch("/apiv2/manager/so5-stats-sport/" + props.manager.Slug + "?sport=" + s)
      .then((response) => response.json())
      .then((res) => {
        setStats(res);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher())
      .finally(() => setLoading(false));
  };

  const columns = {
    small: ["small"],
    medium: ["small", "small", "small"],
    large: ["auto", "auto", "auto", "auto", "auto"],
  };

  const rows = {
    small: ["flex", "flex", "flex", "flex"],
    medium: ["flex", "flex", "flex"],
    large: ["auto", "auto"],
  };

  const primaryCurrency = props.user.preferredUnit;
  const secondaryCurrency = props.user.secondUnit;
  const showSecondaryUnit = checkSecondUnitShouldBeDisplayed(primaryCurrency, secondaryCurrency);

  const earningsPrimary = findPriceForUserUnit(
    stats?.total_earnings_details?.["eth"],
    stats?.total_earnings_details,
    primaryCurrency,
    undefined,
    undefined,
    2,
  );
  const earningsSecondary = showSecondaryUnit
    ? findPriceForUserUnit(
        stats?.total_earnings_details?.["eth"],
        stats?.total_earnings_details,
        secondaryCurrency,
        undefined,
        undefined,
        2,
      )
    : undefined;
  const totalRewardsValuationPrimary = findPriceForUserUnit(
    stats?.total_rewards_valuation_details?.["eth"],
    stats?.total_rewards_valuation_details,
    primaryCurrency,
    undefined,
    undefined,
    2,
  );
  const totalRewardsValuationSecondary = showSecondaryUnit
    ? findPriceForUserUnit(
        stats?.total_rewards_valuation_details?.["eth"],
        stats?.total_rewards_valuation_details,
        secondaryCurrency,
        undefined,
        undefined,
        2,
      )
    : undefined;
  const totalRewardsKeptValuationPrimary = findPriceForUserUnit(
    stats?.total_rewards_kept_valuation_details?.["eth"],
    stats?.total_rewards_kept_valuation_details,
    primaryCurrency,
    undefined,
    undefined,
    2,
  );
  const totalRewardsKeptValuationSecondary = showSecondaryUnit
    ? findPriceForUserUnit(
        stats?.total_rewards_kept_valuation_details?.["eth"],
        stats?.total_rewards_kept_valuation_details,
        secondaryCurrency,
        undefined,
        undefined,
        2,
      )
    : undefined;

  const globalLoading = loading || stats === undefined;
  return (
    <>
      <Box>
        <div className={"flex flex-row justify-center my-4"}>
          <SportsPicker
            sport={sport}
            change={(s) => {
              setSport(s);
              getManagerStats(s);
            }}
          />
        </div>
        {t1OrAbove(props.user.tier) && (
          <div className={"mx-auto"}>
            <ResponsiveGrid rows={rows} columns={columns} gap="small" count={"fill"}>
              <HomeDashboardEntry
                value={earningsPrimary}
                secondText={earningsSecondary}
                description={"Total gaming yield"}
                loading={globalLoading}
              />
              <HomeDashboardEntry loading={globalLoading} value={stats?.total_rewards} unit={""} description={"Total rewards"} />
              <HomeDashboardEntry
                loading={globalLoading}
                value={totalRewardsValuationPrimary}
                secondText={totalRewardsValuationSecondary}
                description={"Total rewards current valuation"}
              />
              <HomeDashboardEntry loading={globalLoading} value={stats?.total_rewards_kept} unit={""} description={"Total rewards kept"} />
              <HomeDashboardEntry
                loading={globalLoading}
                value={totalRewardsKeptValuationPrimary}
                secondText={totalRewardsKeptValuationSecondary}
                description={"Total rewards kept current valuation"}
              />
            </ResponsiveGrid>
          </div>
        )}
        {isFree(props.user.tier) && (
          <UpgradeLimitBox
            className={"bg-white bg-upgrade-bg-gallery-so5-stats bg-cover h-upgrade-banner-s w-full"}
            title={"Want more insights?"}
            description={"Become a Star member to unlock SO5 stats and SO5 reward tiers"}
          />
        )}
      </Box>

      <Box>
        <Box>
          {t1OrAbove(props.user.tier) && (
            <Box margin={{ vertical: "small", horizontal: "auto" }} gap="small" direction={"row"} justify={"center"} align={"center"}>
              <Box>
                <SelectSearch options={scarcities_objects_with_custom_series} value={scarcity} onChange={(v) => setScarcity(v)} />
              </Box>
              <div className={"self-end"}>
                <ToggleWithLabel isActive={keptPlayers} setActive={() => setKeptPlayers(!keptPlayers)} label="Highlight kept players" />
              </div>
            </Box>
          )}

          <div className={"grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 4xl:grid-cols-8 gap-4"}>
            {stats?.cards?.map((c) => {
              let display = true;
              let kept = true;
              let opacity = 1;
              if (scarcity !== scarcities_objects[0].value) {
                if (c.card.Scarcity !== scarcity.toUpperCase()) display = false;
              }
              if (props.manager.SorareAddress.toLowerCase() !== c.card.CardOwner.toLowerCase()) kept = false;
              if (keptPlayers && !kept) opacity = 0.3;
              if (display) {
                return (
                  <div className={"space-y-4"}>
                    <div>
                      <a href={"/card/" + c.card.TokenId} target={"_blank"} rel="noreferrer">
                        <img className={"w-auto"} src={c.card.PictureUrl} style={{ opacity: opacity }} />
                      </a>
                    </div>
                    <div className={"flex flex-row space-x-4 text-base justify-center"}>
                      <div className={"w-14 rounded-lg shadow-sm self-start bg-focus px-2 py-1 flex flex-col items-center"}>
                        <p className={"text-sm font-semibold text-center"}>{ordinal_suffix_of(c.lineup.Rank)}</p>
                        {t1OrAbove(props.user.tier) && <p className={"font-medium text-xxs"}>Tier {c.tier}</p>}
                        {isFree(props.user.tier) && (
                          <div>
                            <CardLocker tip={"SO5 tiers available for Star members"} />
                          </div>
                        )}
                      </div>
                      <div className={"w-auto self-center space-y-0.5"}>
                        <p className={"text-sm"}>
                          <span className={"font-semibold"}>{c.lineup.Score} pts</span> -&nbsp;
                          {sport === sorareBasketball ? (
                            <>
                              GW {c.sorare_gw_number} - {c.sorare_gw_season}
                            </>
                          ) : (
                            <>GW {c.sorare_gw_number || c.gameweek.SorareGwNumber || c.gameweek.GwNumber}</>
                          )}
                        </p>
                        <p className={"text-xs"}>{c.competition_name}</p>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {stats?.cards !== undefined && stats?.cards !== null && stats?.cards.length === 0 && (
            <div className={"w-full flex justify-center text-textGrey3 text-md p-2"}>
              <span className={"py-5 text-center"}>No reward for this manager</span>
            </div>
          )}
          {stats?.cards === undefined && stats?.cards !== null && loading && (
            <div className={"w-full flex justify-center"}>
              <SDLoading />
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default withUser(ManagerSO5Rewards);
