import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import NewCardIcon from "../util/newCardIcon";

const freeScarcities = ["limited"];

function ChooseScarcityPicker(props) {
  const scarcities = ["limited", "rare", "super rare", "unique"];
  return (
    <div className="bg-surface-container rounded-3xl text-sm font-sans font-semibold text-on-surface-variant flex flex-row justify-items-center overflow-hidden p-1 py-2 divide-x divide-transparent-inverse-surface-low divide-opacity-10">
      {scarcities.map((scarcity) => {
        if (t1OrAbove(props.tier) || freeScarcities.indexOf(scarcity) >= 0) {
          return (
            <div key={scarcity} className={"table w-full self-center px-3"} onClick={() => props.onChange(scarcity.toUpperCase())}>
              <div
                className={
                  "table-cell align-middle  h-7 cursor-pointer hover:opacity-80 " +
                  (props.scarcity !== scarcity.toUpperCase() ? "opacity-20" : "")
                }
              >
                <NewCardIcon scarcity={scarcity.toLowerCase()} />
              </div>
            </div>
          );
        } else {
          return (
            <div key={scarcity} className={"table w-full self-center px-3"} data-tip={"Star membership is needed"}>
              <div
                className={
                  "table-cell align-middle h-7 cursor-not-allowed " + (props.scarcity !== scarcity.toUpperCase() ? "opacity-20" : "")
                }
              >
                <NewCardIcon scarcity={scarcity.toLowerCase()} />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

function ChooseScarcity(props) {
  const [scarcity, setScarcity] = useState(props.user.preferredScarcity || "limited");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (props.isConnected()) {
      checkScarcity();
    }
  }, [props.user]);

  const checkScarcity = () => {
    props
      .fetch("/apiv2/user/check_scarcity", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res !== false && res !== undefined) {
          setScarcity(res.toUpperCase());
        }
      })
      .catch(errorCatcher());
  };

  const changeScarcity = (s) => {
    props
      .fetch("/apiv2/user/changeScarcity", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: s.toUpperCase(),
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res === false) {
          setMsg("Failed to change scarcity. Please try again or log out and log back in.");
        } else if (res === true) {
          setMsg("Preferred scarcity changed to " + s.toLowerCase() + ".");
          props.changeScarcity(s);
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"flex flex-col items-start space-y-2"}>
      <p className={"text-center uppercase text-sm text-on-surface-variant font-semibold"}>Scarcity preference</p>
      <div className={"flex flex-col space-y-4"}>
        <p className={"text-sm font-medium"}>
          This scarcity will be used to display players price graphs with your preferred scarcity directly.
        </p>
        <div className={"items-start w-full md:w-80"}>
          <ChooseScarcityPicker
            onChange={(s) => {
              if (s !== props.user.scarcity) {
                setScarcity(s);
                changeScarcity(s);
              }
            }}
            scarcity={scarcity}
            tier={props.user.tier}
          />
        </div>
        {msg !== "" && <p className={"text-sm text-center font-semibold"}>{msg}</p>}
      </div>
    </div>
  );
}

export default withUser(ChooseScarcity);
