/* GENERATED FILE - DO NOT EDIT */
/* See ./dev/designSystemToTailwind.js */
export const sdLight = "sd-light";
export const sdDark = "sd-dark";

export const designSystemFunctionalColors = {
  [sdLight]: {
    "transparent-surface-container-low": "rgba(255, 255, 255, 0.1500)",
    "transparent-inverse-surface-low": "rgba(77, 88, 178, 0.1000)",
    "transparent-surface-container-high": "rgba(255, 255, 255, 0.7000)",
    "inverse-on-surface": "#edeef7",
    "inverse-surface": "#14151f",
    "inverse-primary": "#fb7637",
    "on-disable": "#282a3e",
    "on-surface-variant": "#494c6f",
    "on-surface": "#14151f",
    "surface-disable": "#b8bce0",
    "surface-dim": "#d4d7ec",
    "surface-bright": "#ffffff",
    surface: "#f4f4fa",
    "surface-container": "#ffffff",
    "surface-container-low": "#f4f4fa",
    "surface-container-high": "#edeef7",
    "surface-container-highest": "#e3e5f3",
    "outline-variant": "#d4d7ec",
    outline: "#959cd1",
    "on-secondary-fixed": "#ffffff",
    "secondary-fixed": "#056155",
    "on-primary": "#ffffff",
    primary: "#f55205",
    "tertiary-fixed": "#0ac2aa",
    "on-tertiary-fixed": "#03312b",
    "scrim-fixed": "rgba(0, 0, 0, 0.3200)",
    error: "#cc0000",
    success: "#2d8650",
    "skeleton-from": "rgba(73, 76, 111, 0.1000)",
    "skeleton-to": "rgba(73, 76, 111, 0.1500)",
    "linear-overlay-start": "rgba(184, 188, 224, 0.0000)",
    "linear-overlay-end": "#b8bce0",
    "scarcity-common-on-surface-container": "#737373",
    "scarcity-common-container-dim-fixed": "#f2f2f2",
    "scarcity-common-on-dim-container-fixed": "#666666",
    "scarcity-common-container-transparent": "rgba(115, 115, 115, 0.4000)",
    "scarcity-common-linear-start": "#8c8c8c",
    "scarcity-common-linear-end": "#737373",
    "scarcity-limited-on-surface-container": "#ad651a",
    "scarcity-limited-container-dim-fixed": "#fcf4e8",
    "scarcity-limited-on-dim-container-fixed": "#9b5b17",
    "scarcity-limited-container-transparent": "rgba(218, 166, 88, 0.4000)",
    "scarcity-limited-linear-start": "#daa658",
    "scarcity-limited-linear-end": "#ad651a",
    "scarcity-rare-on-surface-container": "#c02139",
    "scarcity-rare-container-dim-fixed": "#feebe6",
    "scarcity-rare-on-dim-container-fixed": "#c62a06",
    "scarcity-rare-container-transparent": "rgba(192, 33, 57, 0.4000)",
    "scarcity-rare-linear-start": "#ed372d",
    "scarcity-rare-linear-end": "#c02139",
    "scarcity-super-rare-on-surface-container": "#0375de",
    "scarcity-super-rare-container-dim-fixed": "#e6f3ff",
    "scarcity-super-rare-on-dim-container-fixed": "#036ac9",
    "scarcity-super-rare-container-transparent": "rgba(3, 117, 222, 0.4000)",
    "scarcity-super-rare-linear-start": "#6857db",
    "scarcity-super-rare-linear-end": "#0375de",
    "scarcity-unique-on-surface-container": "#343333",
    "scarcity-unique-container-dim-fixed": "#f2f2f2",
    "scarcity-unique-on-dim-container-fixed": "#343333",
    "scarcity-unique-container-transparent": "rgba(52, 51, 51, 0.4000)",
    "scarcity-unique-linear-start": "#4d4c4c",
    "scarcity-unique-linear-end": "#343333",
    "scarcity-multi-on-surface-container": "#8d32e4",
    "scarcity-multi-container-dim-fixed": "#f2e8fc",
    "scarcity-multi-on-dim-container-fixed": "#8d32e4",
    "scarcity-multi-container-transparent": "rgba(95, 45, 210, 0.4000)",
    "scarcity-multi-linear-start": "#5f2dd2",
    "scarcity-multi-linear-end": "#8d32e4",
    "live-live-container-fixed": "#ff3333",
    "live-on-surface": "#cc0000",
    "cost-linear-start-fixed": "#2f42c5",
    "cost-linear-end-fixed": "#6e81f7",
    "time-long-time-container-fixed": "#70c6f5",
    "time-long-on-surface": "#032030",
    "time-long-on-time-container-fixed": "#032030",
    "time-medium-time-container-fixed": "#a9ddf9",
    "time-medium-on-surface": "#07405f",
    "time-medium-on-time-container-fixed": "#07405f",
    "time-short-time-container-fixed": "#d7f0fe",
    "time-short-on-surface": "#0a608f",
    "time-short-on-time-container-fixed": "#0a608f",
    "sorare-brand": "#2f42c5",
    "graphic-grass-field-background": "#35985c",
    "graphic-grass-field-bench": "#287145",
    "graphic-podium-first-podium-container-fixed": "#ffc857",
    "graphic-podium-first-podium-outline-fixed": "#b38933",
    "graphic-podium-first-on-podium-container-fixed": "#201809",
    "graphic-podium-first-on-podium-container-variant-fixed": "#3c2e11",
    "graphic-podium-second-podium-container-fixed": "#ffffff",
    "graphic-podium-second-podium-outline-fixed": "#737373",
    "graphic-podium-second-on-podium-container-fixed": "#141414",
    "graphic-podium-second-on-podium-container-variant-fixed": "#262626",
    "graphic-podium-third-podium-container-fixed": "#c78a53",
    "graphic-podium-third-podium-outline-fixed": "#b36e33",
    "graphic-podium-third-on-podium-container-fixed": "#201409",
    "graphic-podium-third-on-podium-container-variant-fixed": "#3c2511",
    "graphic-charts-default-chart-1": "#f55205",
    "graphic-charts-default-chart-2": "#0ac2aa",
    "graphic-charts-default-chart-3": "#494c6f",
    "graphic-charts-default-chart-4": "#fc9869",
    "graphic-charts-default-chart-5": "#0df2d4",
    "graphic-charts-default-chart-6": "#4d58b2",
    "graphic-charts-default-transparent-chart-1": "rgba(245, 82, 5, 0.4000)",
    "graphic-charts-default-transparent-chart-2": "rgba(10, 194, 170, 0.4000)",
    "graphic-charts-default-transparent-chart-3": "rgba(73, 76, 111, 0.4000)",
    "graphic-charts-default-transparent-chart-4": "rgba(252, 152, 105, 0.4000)",
    "graphic-charts-default-transparent-chart-5": "rgba(13, 242, 212, 0.4000)",
    "graphic-charts-default-transparent-chart-6": "rgba(77, 88, 178, 0.4000)",
    "graphic-charts-scarcity-common": "#737373",
    "graphic-charts-scarcity-limited": "#daa658",
    "graphic-charts-scarcity-rare": "#c02139",
    "graphic-charts-scarcity-super-rare": "#0375de",
    "graphic-charts-scarcity-unique": "#343333",
    "graphic-charts-scarcity-transparent-common": "rgba(115, 115, 115, 0.4000)",
    "graphic-charts-scarcity-transparent-limited": "rgba(218, 166, 88, 0.4000)",
    "graphic-charts-scarcity-transparent-rare": "rgba(192, 33, 57, 0.4000)",
    "graphic-charts-scarcity-transparent-super-rare": "rgba(3, 117, 222, 0.4000)",
    "graphic-charts-scarcity-transparent-unique": "rgba(52, 51, 51, 0.4000)",
    "graphic-charts-scarcity-multi": "#5f2dd2",
    "graphic-charts-scarcity-transparent-multi": "rgba(95, 45, 210, 0.4000)",
    "quality-scale-very-good-quality-container-bright-fixed": "#63cb8c",
    "quality-scale-very-good-quality-container-dim-fixed": "#d9f2e3",
    "quality-scale-very-good-on-surface-container": "#267344",
    "quality-scale-very-good-on-bright-quality-container": "#0a1f12",
    "quality-scale-very-good-on-dim-quality-container-fixed": "#194d2e",
    "quality-scale-excellent-quality-container-bright-fixed": "#25cbaf",
    "quality-scale-excellent-quality-container-dim-fixed": "#d4f7f1",
    "quality-scale-excellent-on-surface-container": "#157464",
    "quality-scale-excellent-on-bright-quality-container": "#082b25",
    "quality-scale-excellent-on-dim-quality-container-fixed": "#10564a",
    "quality-scale-good-quality-container-bright-fixed": "#93c86a",
    "quality-scale-good-quality-container-dim-fixed": "#e4f1da",
    "quality-scale-good-on-surface-container": "#487029",
    "quality-scale-good-on-bright-quality-container": "#131e0b",
    "quality-scale-good-on-dim-quality-container-fixed": "#37561f",
    "quality-scale-average-good-quality-container-bright-fixed": "#e9ce3f",
    "quality-scale-average-good-quality-container-dim-fixed": "#faf3d1",
    "quality-scale-average-good-on-surface-container": "#77660e",
    "quality-scale-average-good-on-bright-quality-container": "#453b08",
    "quality-scale-average-good-on-dim-quality-container-fixed": "#5c4f0a",
    "quality-scale-average-bad-quality-container-bright-fixed": "#e9bc3f",
    "quality-scale-average-bad-quality-container-dim-fixed": "#faefd1",
    "quality-scale-average-bad-on-surface-container": "#80620f",
    "quality-scale-average-bad-on-bright-quality-container": "#3b2e07",
    "quality-scale-average-bad-on-dim-quality-container-fixed": "#5c4f0a",
    "quality-scale-bad-quality-container-bright-fixed": "#fab561",
    "quality-scale-bad-quality-container-dim-fixed": "#fde8ce",
    "quality-scale-bad-on-surface-container": "#995705",
    "quality-scale-bad-on-bright-quality-container": "#4a2a02",
    "quality-scale-bad-on-dim-quality-container-fixed": "#633803",
    "quality-scale-very-bad-quality-container-bright-fixed": "#ff8c68",
    "quality-scale-very-bad-quality-container-dim-fixed": "#ffd8cc",
    "quality-scale-very-bad-on-surface-container": "#c22f00",
    "quality-scale-very-bad-on-bright-quality-container": "#421000",
    "quality-scale-very-bad-on-dim-quality-container-fixed": "#801f00",
    "quality-scale-terrible-quality-container-bright-fixed": "#ff6868",
    "quality-scale-terrible-quality-container-dim-fixed": "#ffcccc",
    "quality-scale-terrible-on-surface-container": "#cc0000",
    "quality-scale-terrible-on-bright-quality-container": "#1f0000",
    "quality-scale-terrible-on-dim-quality-container-fixed": "#800000",
    white: "#ffffff",
    black: "#000000",
    "containers-primary-hover": "#f66019",
    "containers-primary-focus-active": "#f66723",
    "containers-primary-press": "#f66a28",
    "containers-primary-hover-primarytint-transparent": "rgba(246, 96, 25, 0.1500)",
    "containers-primary-focus-active-primarytint-transparent": "rgba(246, 103, 35, 0.2000)",
    "containers-surface-hover-primarytint": "#ffdecc",
    "containers-surface-focus-active-primarytint": "#fbdaca",
    "containers-surface-press-primarytint": "#fcd6c5",
    "containers-surface-hover-neutraltint": "#ecedf3",
    "containers-surface-focus-active-neutraltint": "#e6e7ef",
    "containers-surface-press-neutraltint": "#e0e1eb",
    "containers-surface-transparent-surface-hover": "rgba(255, 255, 255, 0.8000)",
    "containers-surface-transparent-surface-focus-active": "rgba(255, 255, 255, 0.8500)",
    "containers-surface-transparent-surface-press": "rgba(255, 255, 255, 0.9000)",
    "containers-surface-transparent-inverse-surface-hover": "rgba(16, 17, 24, 0.1000)",
    "containers-surface-transparent-inverse-surface-focus-active": "rgba(16, 17, 24, 0.1200)",
    "containers-surface-transparent-inverse-surface-press": "rgba(16, 17, 24, 0.1500)",
    "containers-surface-transparent-white-hover-fixed": "rgba(245, 245, 245, 0.1000)",
    "containers-surface-transparent-white-focus-active-fixed": "rgba(245, 245, 245, 0.1500)",
    "containers-surface-transparent-white-press-fixed": "rgba(245, 245, 245, 0.2000)",
    "containers-surface-inverse-surface-hover": "#202232",
    "containers-surface-inverse-surface-focus-active": "#282a3e",
    "containers-surface-inverse-surface-focus-press": "#30334a",
    "text-primary-hover": "#fb6823",
    "text-primary-focus-active": "#fb6f2d",
    "text-primary-press": "#fb7637",
    "text-on-surface-hover": "#32354d",
    "text-on-surface-focus-active": "#2e3147",
    "text-on-surface-press": "#282b3e",
    "text-white-fixed-hover-fixed": "#ebebeb",
    "text-white-fixed-focus-active-fixed": "#dbdbdb",
    "text-white-fixed-press-fixed": "#cccccc",
  },
  [sdDark]: {
    "transparent-surface-container-low": "rgba(31, 31, 31, 0.1500)",
    "transparent-inverse-surface-low": "rgba(245, 245, 245, 0.1000)",
    "transparent-surface-container-high": "rgba(31, 31, 31, 0.7000)",
    "inverse-on-surface": "#1a1a1a",
    "inverse-surface": "#f5f5f5",
    "inverse-primary": "#f55205",
    "on-disable": "#4d4d4d",
    "on-surface-variant": "#cccccc",
    "on-surface": "#e6e6e6",
    "surface-disable": "#b3b3b3",
    "surface-dim": "#141414",
    "surface-bright": "#3d3d3d",
    surface: "#141414",
    "surface-container": "#1f1f1f",
    "surface-container-low": "#292929",
    "surface-container-high": "#292929",
    "surface-container-highest": "#333333",
    "outline-variant": "#4d4d4d",
    outline: "#999999",
    "on-secondary-fixed": "#ffffff",
    "secondary-fixed": "#056155",
    "on-primary": "#321101",
    primary: "#fb7637",
    "tertiary-fixed": "#0ac2aa",
    "on-tertiary-fixed": "#03312b",
    "scrim-fixed": "rgba(0, 0, 0, 0.3200)",
    error: "#ff6868",
    success: "#63cb8c",
    "skeleton-from": "rgba(245, 245, 245, 0.1000)",
    "skeleton-to": "rgba(245, 245, 245, 0.1500)",
    "linear-overlay-start": "rgba(41, 41, 41, 0.0000)",
    "linear-overlay-end": "#292929",
    "scarcity-common-on-surface-container": "#cccccc",
    "scarcity-common-container-dim-fixed": "#f2f2f2",
    "scarcity-common-on-dim-container-fixed": "#666666",
    "scarcity-common-container-transparent": "rgba(204, 204, 204, 0.4000)",
    "scarcity-common-linear-start": "#e6e6e6",
    "scarcity-common-linear-end": "#cccccc",
    "scarcity-limited-on-surface-container": "#e79c2b",
    "scarcity-limited-container-dim-fixed": "#fcf4e8",
    "scarcity-limited-on-dim-container-fixed": "#9b5b17",
    "scarcity-limited-container-transparent": "rgba(218, 166, 88, 0.4000)",
    "scarcity-limited-linear-start": "#daa658",
    "scarcity-limited-linear-end": "#e79c2b",
    "scarcity-rare-on-surface-container": "#e15151",
    "scarcity-rare-container-dim-fixed": "#feebe6",
    "scarcity-rare-on-dim-container-fixed": "#c62a06",
    "scarcity-rare-container-transparent": "rgba(225, 81, 81, 0.4000)",
    "scarcity-rare-linear-start": "#f37272",
    "scarcity-rare-linear-end": "#e15151",
    "scarcity-super-rare-on-surface-container": "#1c91fc",
    "scarcity-super-rare-container-dim-fixed": "#e6f3ff",
    "scarcity-super-rare-on-dim-container-fixed": "#036ac9",
    "scarcity-super-rare-container-transparent": "rgba(28, 145, 252, 0.4000)",
    "scarcity-super-rare-linear-start": "#7b6cdf",
    "scarcity-super-rare-linear-end": "#1c91fc",
    "scarcity-unique-on-surface-container": "#878787",
    "scarcity-unique-container-dim-fixed": "#f2f2f2",
    "scarcity-unique-on-dim-container-fixed": "#343333",
    "scarcity-unique-container-transparent": "rgba(135, 135, 135, 0.4000)",
    "scarcity-unique-linear-start": "#a6a6a6",
    "scarcity-unique-linear-end": "#878787",
    "scarcity-multi-on-surface-container": "#b478ed",
    "scarcity-multi-container-dim-fixed": "#f2e8fc",
    "scarcity-multi-on-dim-container-fixed": "#8d32e4",
    "scarcity-multi-container-transparent": "rgba(127, 87, 219, 0.4000)",
    "scarcity-multi-linear-start": "#7f57db",
    "scarcity-multi-linear-end": "#b478ed",
    "live-live-container-fixed": "#ff3333",
    "live-on-surface": "#ff6868",
    "cost-linear-start-fixed": "#2f42c5",
    "cost-linear-end-fixed": "#6e81f7",
    "time-long-time-container-fixed": "#70c6f5",
    "time-long-on-surface": "#70c6f5",
    "time-long-on-time-container-fixed": "#032030",
    "time-medium-time-container-fixed": "#a9ddf9",
    "time-medium-on-surface": "#a9ddf9",
    "time-medium-on-time-container-fixed": "#07405f",
    "time-short-time-container-fixed": "#d7f0fe",
    "time-short-on-surface": "#d7f0fe",
    "time-short-on-time-container-fixed": "#0a608f",
    "sorare-brand": "#6e81f7",
    "graphic-grass-field-background": "#35985c",
    "graphic-grass-field-bench": "#287145",
    "graphic-podium-first-podium-container-fixed": "#ffc857",
    "graphic-podium-first-podium-outline-fixed": "#b38933",
    "graphic-podium-first-on-podium-container-fixed": "#201809",
    "graphic-podium-first-on-podium-container-variant-fixed": "#3c2e11",
    "graphic-podium-second-podium-container-fixed": "#ffffff",
    "graphic-podium-second-podium-outline-fixed": "#737373",
    "graphic-podium-second-on-podium-container-fixed": "#141414",
    "graphic-podium-second-on-podium-container-variant-fixed": "#262626",
    "graphic-podium-third-podium-container-fixed": "#c78a53",
    "graphic-podium-third-podium-outline-fixed": "#b36e33",
    "graphic-podium-third-on-podium-container-fixed": "#201409",
    "graphic-podium-third-on-podium-container-variant-fixed": "#3c2511",
    "graphic-charts-default-chart-1": "#fb7637",
    "graphic-charts-default-chart-2": "#0ac2aa",
    "graphic-charts-default-chart-3": "#cccccc",
    "graphic-charts-default-chart-4": "#c84304",
    "graphic-charts-default-chart-5": "#089180",
    "graphic-charts-default-chart-6": "#999999",
    "graphic-charts-default-transparent-chart-1": "rgba(251, 118, 55, 0.4000)",
    "graphic-charts-default-transparent-chart-2": "rgba(10, 194, 170, 0.4000)",
    "graphic-charts-default-transparent-chart-3": "rgba(204, 204, 204, 0.4000)",
    "graphic-charts-default-transparent-chart-4": "rgba(200, 67, 4, 0.4000)",
    "graphic-charts-default-transparent-chart-5": "rgba(8, 145, 128, 0.4000)",
    "graphic-charts-default-transparent-chart-6": "rgba(153, 153, 153, 0.4000)",
    "graphic-charts-scarcity-common": "#cccccc",
    "graphic-charts-scarcity-limited": "#daa658",
    "graphic-charts-scarcity-rare": "#e15151",
    "graphic-charts-scarcity-super-rare": "#0375de",
    "graphic-charts-scarcity-unique": "#878787",
    "graphic-charts-scarcity-transparent-common": "rgba(204, 204, 204, 0.4000)",
    "graphic-charts-scarcity-transparent-limited": "rgba(218, 166, 88, 0.4000)",
    "graphic-charts-scarcity-transparent-rare": "rgba(225, 81, 81, 0.4000)",
    "graphic-charts-scarcity-transparent-super-rare": "rgba(28, 145, 252, 0.4000)",
    "graphic-charts-scarcity-transparent-unique": "rgba(135, 135, 135, 0.4000)",
    "graphic-charts-scarcity-multi": "#7f57db",
    "graphic-charts-scarcity-transparent-multi": "rgba(127, 87, 219, 0.4000)",
    "quality-scale-very-good-quality-container-bright-fixed": "#63cb8c",
    "quality-scale-very-good-quality-container-dim-fixed": "#d9f2e3",
    "quality-scale-very-good-on-surface-container": "#63cb8c",
    "quality-scale-very-good-on-bright-quality-container": "#0a1f12",
    "quality-scale-very-good-on-dim-quality-container-fixed": "#194d2e",
    "quality-scale-excellent-quality-container-bright-fixed": "#25cbaf",
    "quality-scale-excellent-quality-container-dim-fixed": "#d4f7f1",
    "quality-scale-excellent-on-surface-container": "#25cbaf",
    "quality-scale-excellent-on-bright-quality-container": "#082b25",
    "quality-scale-excellent-on-dim-quality-container-fixed": "#10564a",
    "quality-scale-good-quality-container-bright-fixed": "#93c86a",
    "quality-scale-good-quality-container-dim-fixed": "#e4f1da",
    "quality-scale-good-on-surface-container": "#93c86a",
    "quality-scale-good-on-bright-quality-container": "#131e0b",
    "quality-scale-good-on-dim-quality-container-fixed": "#37561f",
    "quality-scale-average-good-quality-container-bright-fixed": "#e9ce3f",
    "quality-scale-average-good-quality-container-dim-fixed": "#faf3d1",
    "quality-scale-average-good-on-surface-container": "#e9ce3f",
    "quality-scale-average-good-on-bright-quality-container": "#251f04",
    "quality-scale-average-good-on-dim-quality-container-fixed": "#5c4f0a",
    "quality-scale-average-bad-quality-container-bright-fixed": "#e9bc3f",
    "quality-scale-average-bad-quality-container-dim-fixed": "#faefd1",
    "quality-scale-average-bad-on-surface-container": "#e9bc3f",
    "quality-scale-average-bad-on-bright-quality-container": "#251c04",
    "quality-scale-average-bad-on-dim-quality-container-fixed": "#654d0c",
    "quality-scale-bad-quality-container-bright-fixed": "#fab561",
    "quality-scale-bad-quality-container-dim-fixed": "#fde8ce",
    "quality-scale-bad-on-surface-container": "#fab561",
    "quality-scale-bad-on-bright-quality-container": "#281601",
    "quality-scale-bad-on-dim-quality-container-fixed": "#633803",
    "quality-scale-very-bad-quality-container-bright-fixed": "#ff8c68",
    "quality-scale-very-bad-quality-container-dim-fixed": "#ffd8cc",
    "quality-scale-very-bad-on-surface-container": "#ff8c68",
    "quality-scale-very-bad-on-bright-quality-container": "#290a00",
    "quality-scale-very-bad-on-dim-quality-container-fixed": "#801f00",
    "quality-scale-terrible-quality-container-bright-fixed": "#ff6868",
    "quality-scale-terrible-quality-container-dim-fixed": "#ffcccc",
    "quality-scale-terrible-on-surface-container": "#ff6868",
    "quality-scale-terrible-on-bright-quality-container": "#1f0000",
    "quality-scale-terrible-on-dim-quality-container-fixed": "#800000",
    white: "#ffffff",
    black: "#000000",
    "containers-primary-hover": "#fb8750",
    "containers-primary-focus-active": "#fb915f",
    "containers-primary-press": "#fc9869",
    "containers-primary-hover-primarytint-transparent": "rgba(251, 135, 80, 0.1500)",
    "containers-primary-focus-active-primarytint-transparent": "rgba(251, 145, 95, 0.2000)",
    "containers-surface-hover-primarytint": "#493d37",
    "containers-surface-focus-active-primarytint": "#54423b",
    "containers-surface-press-primarytint": "#5b473e",
    "containers-surface-hover-neutraltint": "#474747",
    "containers-surface-focus-active-neutraltint": "#4d4d4d",
    "containers-surface-press-neutraltint": "#525252",
    "containers-surface-transparent-surface-hover": "rgba(31, 31, 31, 0.8000)",
    "containers-surface-transparent-surface-focus-active": "rgba(31, 31, 31, 0.8500)",
    "containers-surface-transparent-surface-press": "rgba(31, 31, 31, 0.9000)",
    "containers-surface-transparent-inverse-surface-hover": "rgba(245, 245, 245, 0.1500)",
    "containers-surface-transparent-inverse-surface-focus-active": "rgba(245, 245, 245, 0.2000)",
    "containers-surface-transparent-inverse-surface-press": "rgba(245, 245, 245, 0.2500)",
    "containers-surface-transparent-white-hover-fixed": "rgba(245, 245, 245, 0.1000)",
    "containers-surface-transparent-white-focus-active-fixed": "rgba(245, 245, 245, 0.1500)",
    "containers-surface-transparent-white-press-fixed": "rgba(245, 245, 245, 0.2000)",
    "containers-surface-inverse-surface-hover": "#ebebeb",
    "containers-surface-inverse-surface-focus-active": "#e6e6e6",
    "containers-surface-inverse-surface-focus-press": "#dbdbdb",
    "text-primary-hover": "#fb8750",
    "text-primary-focus-active": "#fc9869",
    "text-primary-press": "#fca982",
    "text-on-surface-hover": "#d9d9d9",
    "text-on-surface-focus-active": "#e0e0e0",
    "text-on-surface-press": "#e6e6e6",
    "text-white-fixed-hover-fixed": "#ebebeb",
    "text-white-fixed-focus-active-fixed": "#dbdbdb",
    "text-white-fixed-press-fixed": "#cccccc",
  },
};
