import React, { useState } from "react";
import { ReactComponent as HelpIconNew } from "../../img/help-new.svg";
import ConfirmModal from "./confirmModal";

export const HelpModal = ({ onConfirm }) => {
  const [openHelpModal, setOpenHelpModal] = useState(false);
  return (
    <div className="self-center">
      <HelpIconNew className="h-5 w-5 fill-on-surface cursor-pointer" onClick={() => setOpenHelpModal(true)} />
      <ConfirmModal
        title="Help"
        message={<>You can change the default scarcity and currency of this page by editing your profile page settings.</>}
        open={openHelpModal}
        onConfirm={onConfirm}
        confirmLabel={"Ok, got it"}
        onCancel={undefined}
        hideCancel
        hideClose
      />
    </div>
  );
};
