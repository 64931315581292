import React, { useEffect, useRef, useState } from "react";
import { errorCatcher } from "../util/errors";
import { sorareBaseball } from "../util/sports";
import { withUser } from "../../userContext";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import ordinal_suffix_of from "../util/ordinalNumber";
import SDIcon from "../../img/sd-logo-dark-mode.svg";
import PlayerScoreModal from "../players/playerScoreModal";
import { ReactTooltip } from "../util/tooltip.js";
import { format } from "date-fns";
import CardIcon from "../util/cardIcon";
import InfoIconGrey from "../../img/info-icon-grey.svg";
import EthereumIconWhite from "../../img/ethereum_white.svg";

function BaseballLineupCard(props) {
  const [isOpen, setOverlay] = useState(false);

  const g = props.g;
  const position = props.position;
  const player = props.player;
  let score = 0.0;
  if (position === "SP" || position === "RP") {
    score += g.PitchingScore;
  } else if (position === "MI" || position === "CI" || position === "OF" || position === "EXH") {
    score += g.BattingScore;
  } else {
    score += g.Score;
  }
  let playingFor = g.TeamId;
  if (g.TeamId === "") {
    playingFor = player.TeamId;
  }
  let opponent = g.away_team_id;
  let opponentLogo = g.away_team_logo;
  if (playingFor !== g.home_team_id) {
    opponent = g.home_team_id;
    opponentLogo = g.home_team_logo;
  }
  let last = props.last;
  let size = "160px";
  let probable = false;
  if (g.game.ProbablePitcherAway === player.PlayerId || g.game.ProbablePitcherHome === player.PlayerId) {
    probable = true;
    size = "160px";
  }
  let isOut = g.game.HasLineups && !g.OnRoster;
  let isSub = g.game.HasLineups && g.OnRoster && !g.Started;
  let starts = g.game.HasLineups && g.OnRoster && g.Started;
  let gameOngoing = g.game.Status === "inprogress";
  let status = "DNP";
  if (player.Position !== "SP" && player.Position !== "RP") {
    if (isOut) {
      status = "OUT";
    } else if (isSub) {
      status = "SUB";
    } else if (starts) {
      status = "#" + g.LineupPosition;
      size = "160px";
    }
  }

  let precision = 0;
  if (gameOngoing) {
    size = "180px";
    if (!g.Played && !g.Started && g.OnRoster) {
      score = "SUB";
      precision = -1;
      size = "140px";
    } else if (!g.Played && !g.Started && !g.OnRoster) {
      score = "OUT";
      precision = -1;
      size = "140px";
    }
  }
  return (
    <div>
      <PlayerScoreModal
        dark={true}
        open={isOpen}
        stats={g}
        sport={sorareBaseball}
        player={player}
        position={player.Position}
        close={() => setOverlay(false)}
        game={g}
      />
      <div className={"flex flex-row space-x-2"}>
        <div className={"flex flex-col space-y-2 items-center"}>
          <div className={"self-center"}>
            <div className={"w-4"}>
              <img src={opponentLogo} className={"w-full h-full object-contain"} />
            </div>
          </div>
          {g.game.Status !== "scheduled" && (
            <div className={"flex flex-row justify-center cursor-pointer"} onClick={() => setOverlay(true)}>
              <div className={"w-7"}>
                <BaseballScoreCircle dark ongoing={gameOngoing} noCircle={true} size={size} score={score} precision={precision} />
              </div>
            </div>
          )}
          {g.game.Status === "scheduled" && (
            <div className={"flex flex-row justify-center cursor-pointer"} onClick={() => setOverlay(true)}>
              <div className={"w-7"}>
                <BaseballScoreCircle
                  dark
                  ongoing={gameOngoing}
                  noCircle={true}
                  size={size}
                  score={probable ? "PP" : status}
                  precision={-1}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function BaseballLineup(props) {
  const [overview, setOverview] = useState(false);

  const lineup = props.lineup;
  const division = lineup?.division;
  const cards = lineup?.cards;
  let colorRank = "text-green-dark";
  let rank = lineup.lineup.rank;
  let lineupScore = lineup.lineup.score;
  let paidRank = lineup.division.paidRankLimit;
  if (rank > paidRank) {
    colorRank = "text-brand-dark";
  }
  let icon = lineup.division.logo;
  let textRewards = "";
  if (lineup.currentReward.scarcity !== "") {
    textRewards = "Tier " + lineup.currentReward.tier + " " + lineup.currentReward.scarcity.toLowerCase();
  } else {
    textRewards = "No reward";
  }
  let nextRewardText = "";
  if (lineup.currentReward.nextScarcity !== "") {
    nextRewardText = "Tier " + lineup.currentReward.nextTier + " " + lineup.currentReward.nextScarcity.toLowerCase();
    if (lineup.currentReward.nextEth > 0) {
      nextRewardText += " + " + lineup.currentReward.nextEth.toFixed(4) + " Ξ";
    }
  } else {
    if (lineup.currentReward.nextEth > 0) {
      nextRewardText += lineup.currentReward.nextEth.toFixed(4) + " Ξ";
    }
  }

  let rewardsOverview = lineup.rewardOverview;
  return (
    <div id={division.DivisionId} className={"bg-black-1f rounded-lg px-8 pt-2 pb-6"} onMouseEnter={() => setOverview(false)}>
      <div className={"flex flex-row py-2 space-x-2 w-full relative"}>
        <div className={"self-center"}>
          <img className={"w-10 h-10"} src={icon} />
        </div>
        <div className={"flex flex-col w-6/12 self-center"}>
          <div>
            <p className={"text-[#CCCCCC] text-left font-medium text-sm uppercase"}>{division.displayName}</p>
          </div>
          <div className={"text-left flex flex-row space-x-2"}>
            <p>
              <span className={"text-xl font-semibold " + colorRank}>{ordinal_suffix_of(rank)}</span>
              <span data-tip={"Lineup score"} className={"text-xl font-semibold text-[#E6E6E6]"}>
                {" "}
                - {parseFloat(lineupScore).toFixed(2)} pts
              </span>
            </p>
            {/*<div className={"flex self-center pt-1"}><span data-tip={"Best to worst possible ranks"}
                                                                        className={"self-center text-textGrey3 font-semibold text-sm"}> ({maxRanks})</span>
                        </div>*/}
          </div>
        </div>
        <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 justify-center self-center"}>
          <div className={"w-8 h-8"}>
            <img className={"w-full h-full object-contain"} src={SDIcon} />
          </div>
        </div>
        {division.divisionType !== "common-contender" && (
          <div className={"relative flex justify-end self-end w-6/12 pb-1"}>
            <div className={"flex flex-col space-y-2"}>
              <div className={"flex flex-row justify-end"}>
                {lineup.currentReward && lineup.currentReward.scarcity !== "" && (
                  <div className={"flex flex-row space-x-2"}>
                    <div className={"px-2 py-0.5 rounded flex flex-row space-x-2"}>
                      <div className={"self-center"}>
                        <CardIcon size={"lg"} scarcity={lineup.currentReward.scarcity.toLowerCase()} />
                      </div>
                      <p className={"uppercase font-medium text-sm text-white text-center self-center"}>Tier {lineup.currentReward.tier}</p>
                    </div>
                  </div>
                )}
                {lineup.currentReward.eth > 0 && (
                  <>
                    {lineup.currentReward && lineup.currentReward.scarcity !== "" && (
                      <div className={"border-l border-black-4D self-center h-4"}></div>
                    )}
                    <div className={"rounded text-sm text-white px-2 font-semibold flex flex-row space-x-1.5"}>
                      <img className={"self-center"} src={EthereumIconWhite} style={{ height: "16px" }} />
                      <p className={"self-center"}>{lineup.currentReward.eth.toFixed(4)}</p>
                    </div>
                  </>
                )}
              </div>

              <div className={" flex justify-center lg:justify-start self-end lg:pl-6 space-x-2"}>
                {rank > 1 && (
                  <span className={"text-[#CCCCCC] text-sm font-medium self-center"}>
                    {lineup.currentReward.scoreToNext.toFixed(2)} pts to {nextRewardText}
                  </span>
                )}
                <div className={"w-4 self-center cursor-pointer"} onMouseEnter={() => setOverview(true)}>
                  <img className={"w-full object-contain"} src={InfoIconGrey} />
                </div>
              </div>
            </div>
            {overview === true && division.divisionType !== "common-contender" && (
              <div
                onMouseLeave={() => setOverview(false)}
                className="absolute flex w-auto mt-2 p-4 rounded-md shadow-lg bg-[#292929] focus:outline-none z-20"
              >
                <div className="py-1 text-left" role="none">
                  {rewardsOverview.map((r, i) => {
                    if (r.scarcity.toLowerCase() === "common" && r.eth === 0) {
                      return null;
                    }
                    let to = "to";
                    let color = "text-brand-dark";
                    let gap = r.toScore - lineup.lineup.score;
                    if (gap <= 0) {
                      to = "from";
                      color = "text-green-dark";
                      gap = gap * -1;
                    }
                    let custom = "";
                    let border = "border-b border-black-4D";
                    if (i === 0) {
                      custom = "rounded-t-lg";
                    }
                    if (i === rewardsOverview.length - 1) {
                      border = "";
                      custom = "rounded-b-lg";
                    }
                    let bg = "bg-black-1f";
                    let startRange = r.to;
                    let endRange = r.from;
                    if (endRange === startRange) endRange = 0;
                    if (rank <= startRange && rank >= endRange) {
                      bg = "bg-black-33";
                    }
                    textRewards = "Tier " + r.tier + " " + r.scarcity.toLowerCase();
                    return (
                      <div className={"flex py-1.5 px-2 flex-row justify-between " + border + " " + custom + " " + bg}>
                        <div className={"self-center"}>
                          <p className={"text-xs font-semibold text-[#E6E6E6]"}>
                            {endRange > 0 && <span>{endRange}-</span>}
                            {startRange}
                          </p>
                        </div>
                        <div className={"w-16"}></div>
                        <div className={"flex flex-row space-x-2"}>
                          <p className={color + " font-medium text-xs self-center"}>
                            {gap.toFixed(2)} pts {to}
                          </p>
                          {r.eth > 0 && (
                            <div
                              className={
                                "rounded text-sm bg-[#F5F5F5] bg-opacity-10 text-[#E6E6E6] text-white px-2 font-semibold flex flex-row space-x-1.5"
                              }
                            >
                              <img className={"self-center"} src={EthereumIconWhite} style={{ height: "16px" }} />
                              <p className={"self-center"}>{r.eth.toFixed(4)}</p>
                            </div>
                          )}
                          {r.scarcity !== "" && (
                            <div className={"px-2 py-0.5 rounded bg-[#F5F5F5] bg-opacity-10 text-[#E6E6E6] flex flex-row space-x-2"}>
                              <div className={"self-center"}>
                                <CardIcon size={"lg"} scarcity={r.scarcity.toLowerCase()} />
                              </div>
                              <p className={"font-semibold text-sm text-center self-center"}>Tier {r.tier}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={"grid grid-cols-7 gap-x-4 mt-1"}>
        {cards !== null &&
          cards.map((c, ind) => {
            const card = c.card;
            let score = 0;
            let isHome = true;
            const position = c.lineupPlayer.position;
            let showcasedGame;
            let onBase = false;
            let atBat = false;
            let pitching = false;
            let defending = false;
            let nextAtBat = false;
            let finalScore = false;
            if (c.games !== null) {
              c.games.map((g, i) => {
                if (position === "SP" || position === "RP") {
                  score += g.PitchingScore;
                } else if (position === "MI" || position === "CI" || position === "OF" || position === "EXH") {
                  score += g.BattingScore;
                } else {
                  score += g.Score;
                }
                if (i !== c.games.length - 1) {
                  if (g.game.Status !== "closed") {
                    if (
                      showcasedGame?.game.Status !== "scheduled" &&
                      showcasedGame?.game.Status !== "inprogress" &&
                      showcasedGame?.game.Status !== "wdelay"
                    ) {
                      showcasedGame = g;
                    }
                  }
                } else {
                  if (showcasedGame?.game.Status === "closed" || showcasedGame === undefined) {
                    showcasedGame = g;
                  }
                }
              });
            }
            if (showcasedGame && showcasedGame.game.Status === "inprogress") {
              if (showcasedGame.game.AwayTeamId === showcasedGame.TeamId) {
                isHome = false;
              }
              if (showcasedGame.game.LiveDetails.half === "T") {
                if (isHome && showcasedGame.LineupPosition) {
                  defending = true;
                }
              } else if (showcasedGame.game.LiveDetails.half === "B") {
                if (!isHome && showcasedGame.LineupPosition) {
                  defending = true;
                }
              }
              if (showcasedGame.game.LiveDetails?.atBat === card.PlayerId) {
                atBat = true;
              }
              if (
                defending === false &&
                atBat === false &&
                showcasedGame.LineupPosition > 0 &&
                showcasedGame.game.LiveDetails?.lineupPosition > 0
              ) {
                if (showcasedGame.game.LiveDetails?.lineupPosition > showcasedGame.LineupPosition) {
                  nextAtBat = 9 - (showcasedGame.game.LiveDetails?.lineupPosition - showcasedGame.LineupPosition);
                } else {
                  nextAtBat = showcasedGame.LineupPosition - showcasedGame.game.LiveDetails?.lineupPosition;
                }
              }
              if (showcasedGame.game.LiveDetails?.pitching === card.PlayerId) {
                pitching = true;
              }
              if (showcasedGame.game.LiveDetails?.runners !== null) {
                showcasedGame.game.LiveDetails?.runners.map((r) => {
                  if (r.playerId === card.PlayerId) {
                    onBase = r.base;
                  }
                });
              }
            }
            finalScore = showcasedGame?.game.Status === "closed" || showcasedGame?.game.Status === "complete";
            let nextGame = showcasedGame?.game.Status === "scheduled" || showcasedGame?.game.Status === "created";

            let power = c.lineupPlayer.power;
            let totalScore = score * power;
            if (score <= 0) {
              totalScore = score;
            }
            let inning = showcasedGame?.game.LiveDetails?.half + showcasedGame?.game.LiveDetails?.inning;
            let isLive = showcasedGame?.game.Status === "inprogress" || showcasedGame?.game.Status === "wdelay";
            return (
              <div key={ind} className={"flex flex-col items-center space-y-2"}>
                <div className={"flex flex-col items-center"}>
                  {card.Scarcity !== "COMMON" ? (
                    <a target={"_blank"} href={`/card/${card.TokenId}`} rel="noreferrer">
                      <img src={card.PictureUrl} />
                    </a>
                  ) : (
                    <img src={card.PictureUrl} />
                  )}
                </div>
                <div className={"flex flex-row justify-center space-x-2"}>
                  <p className={"text-xs text-[#CCCCCC] font-semibold"}>{position}</p>
                  <div className={"border border-black-4D"} />
                  <p className={"text-xs font-semibold text-[#CCCCCC]"}>
                    <span className={"text-[#E6E6E6]"}>{score.toFixed(1)}</span> x {power}
                  </p>
                </div>
                <div className={"flex flex-row justify-center"}>
                  <div className={"w-12 self-center"}>
                    {<BaseballScoreCircle cr={"180"} dark score={totalScore} size={"120px"} precision={0} />}
                    {/*<ScoreEllipse size={"text-base"} score={totalScore} sport={sorareBaseball} power={power} isCaptain={false} formationPlace={1}
                                                  onGameSheet={true} minsPlayed={1} />*/}
                  </div>
                </div>
                <div className={"flex flex-row justify-between w-full space-x-2"}>
                  <div className={"w-2/12 h-0 border border-black-4D self-center"}></div>
                  <div className={"w-8/12 flex self-center flex-row justify-center text-center text-[#CCCCCC]"}>
                    {isLive && <p className={"font-semibold text-center text-xs"}>{inning}</p>}
                    {atBat && (
                      <p className={"font-semibold text-center text-xs"}>
                        At bat ({showcasedGame.game.LiveDetails.balls}B - {showcasedGame.game.LiveDetails.strikes}S,{" "}
                        {showcasedGame.game.LiveDetails.runners !== null ? showcasedGame.game.LiveDetails.runners.length : 0} on,{" "}
                        {showcasedGame.game.LiveDetails.outs} out)
                      </p>
                    )}
                    {defending && <p className={"font-semibold text-center text-xs"}>, in the field</p>}
                    {pitching && (
                      <p className={"font-semibold text-center text-xs"}>
                        Pitching ({showcasedGame.game.LiveDetails.balls}B - {showcasedGame.game.LiveDetails.strikes}S,{" "}
                        {showcasedGame.game.LiveDetails.runners !== null ? showcasedGame.game.LiveDetails.runners.length : 0} on,{" "}
                        {showcasedGame.game.LiveDetails.outs} out)
                      </p>
                    )}
                    {onBase !== false && <p className={"font-semibold text-center text-xs"}>On {ordinal_suffix_of(onBase)} base</p>}
                    {nextAtBat !== false && nextAtBat > 2 && (
                      <p className={"font-semibold text-center text-xs"}>, due up in {ordinal_suffix_of(nextAtBat)}</p>
                    )}
                    {nextAtBat === 2 && <p className={"font-semibold text-center text-xs"}>, in the hole</p>}
                    {nextAtBat === 1 && <p className={"font-semibold text-center text-xs"}>, on deck</p>}
                    {finalScore && <p className={"font-semibold text-center text-xs"}>Final score</p>}
                    {nextGame && (
                      <p className={"font-semibold text-center text-xs"}>
                        {format(new Date(showcasedGame.game.GameTime), "EEE d - HH:mm")}
                      </p>
                    )}
                  </div>

                  <div className={"w-2/12 h-0 border border-black-4D self-center"}></div>
                </div>
                <div className={"flex flex-row justify-center space-x-1.5 bg-[#292929] rounded-lg px-4 py-2 w-full"}>
                  {c.games !== null &&
                    c.games.map((g, i) => {
                      return <BaseballLineupCard g={g} position={position} player={c.player} last={i === c.games.length - 1} />;
                    })}
                </div>
              </div>
            );
          })}
      </div>
      <ReactTooltip />
    </div>
  );
}

function BaseballLiveLineups(props) {
  const [lineups, setLineups] = useState([]);
  const [gw, setGw] = useState(props.gw);
  const [refresh, setRefresh] = useState(0);

  const state = useRef();
  state.current = gw;

  const getLineups = (gw) => {
    if (gw === undefined) gw = state.current;
    props
      .fetch("/apiv2/SO5/baseballLineups?gw=" + gw + "&username=" + props.username)
      .then((response) => response.json())
      .then(async (res) => {
        setLineups(res.lineups);
        setGw(gw);
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (props.gw > 0 && props.gw < 360) {
      setGw(props.gw);
      getLineups(props.gw);
    }
  }, [props.gw]);

  useEffect(() => {
    const interval = setInterval(() => {
      getLineups();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={"mt-8 space-y-8"}>
      {lineups !== null &&
        lineups.map((l) => {
          return <BaseballLineup lineup={l} />;
        })}
    </div>
  );
}

export default withUser(BaseballLiveLineups);
