import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";
import { Form } from "grommet";
import Spinner from "../loader/spinner";
import { withUser } from "../../userContext";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";

const WatchlistPicker = (props) => {
  const size = props.size || "sm";
  const limit = props.limit || 100;
  const types = props.types || [];
  const sports = props.sports || [];
  const options = props.options || {};
  const pinListFirst = options.pinListFirst || false;
  const defaultListFirst = options.defaultListFirst || false;
  const followedListFirst = options.followedListFirst || false;
  const ownedListFirst = options.ownedListFirst || false;
  const selectFirst = options.selectFirst || false;
  const selected = props.selected;
  const placeholder = props.placeholder || "Search...";

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebounceSearchQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState(undefined);
  const [displayedResults, setDisplayedResults] = useState(undefined);
  const [displayResults, setDisplayResults] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isHoverResults, setIsHoverResults] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const [selectableIndex, setSelectableIndex] = useState(-1);

  useDebounce(
    () => {
      setDebounceSearchQuery(searchQuery);
    },
    250,
    [searchQuery],
  );

  useEffect(
    () => {
      searchWatchlists();
    },
    [debouncedSearchQuery], // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (!displayResults) {
      setSelectableIndex(-1);
    } else {
      searchWatchlists();
    }
  }, [displayResults]);

  useEffect(() => {
    if (isFocus) {
      setDisplayResults(true);
    } else if (isHoverResults) {
      setDisplayResults(true);
    }
  }, [isFocus, isHoverResults]);

  const onKeyDownInputSearch = (e) => {
    if (displayResults) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          if (prev <= 0) {
            const resultLength = results?.length || 0;
            return resultLength - 1;
          } else {
            return prev - 1;
          }
        });
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          const resultLength = results?.length || 0;
          if (prev >= resultLength - 1) {
            return 0;
          } else {
            return prev + 1;
          }
        });
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (results && selectableIndex >= 0 && selectableIndex < results.length) {
          onSelect && onSelect(results[selectableIndex]);
        }
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onSelect = (w) => {
    inputRef.current.blur();
    setDisplayResults(false);
    props.onSelect && props.onSelect(w);
  };

  const searchWatchlists = () => {
    setSearching(true);
    props
      .fetch(`/apiv2/watchlists/interesting/search`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchQuery: searchQuery,
          limit: limit,
          order: "relevance",
          pinListFirst: pinListFirst,
          defaultListFirst: defaultListFirst,
          ownedListFirst: ownedListFirst,
          followedListFirst: followedListFirst,
          types: types,
          sports: sports,
          hideDisabled: true,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          const results = res || [];
          setResults(results);
          setDisplayedResults(results);
          if (selectFirst && results.length > 0 && (selected === undefined || selected === "")) {
            props.onSelect && props.onSelect(results[0]);
          }
        }
        setSearching(false);
      })
      .catch((res) => {
        setSearching(false);
      });
  };

  const roundSearch = displayResults ? "rounded-t" : "rounded";
  const placeholderStyle = `overflow-hidden pr-6 block w-full py-2 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0 
    ${roundSearch} 
    ${displayResults ? "" : (selected !== undefined ? "font-semibold text-medium" : "") + " cursor-pointer"} 
    `;
  return (
    <div className={"relative"}>
      <Form className={"z-0"}>
        <div className={"flex"}>
          <div style={{ width: size === "lg" ? "28em" : "18em" }} className={"m-auto relative flex h-9 " + roundSearch} ref={ref}>
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
              {searching && (
                <div className="pr-2 flex items-center">
                  <span className={"h-3 w-3"}>
                    <Spinner className={"w-4 h-4"} />
                  </span>
                </div>
              )}
              <SortArrowIcon className={`my-auto ${displayResults ? "transform rotate-180" : ""}`} />
            </div>
            <input
              ref={inputRef}
              type="text"
              onChange={(v) => {
                setSearchQuery(v.target.value);
              }}
              onFocus={(e) => {
                e.stopPropagation();
                setIsFocus(true);
              }}
              onBlur={(e) => {
                e.stopPropagation();
                setIsFocus(false);
              }}
              onKeyDown={onKeyDownInputSearch}
              value={displayResults ? searchQuery : selected?.name || ""}
              className={placeholderStyle}
              placeholder={placeholder}
            />
            <div className={"z-500 absolute w-full top-9"}>
              {displayResults && (
                <div className={"rounded-b border-b border-l border-r border-grey-e9 bg-white z-50"}>
                  <div className={"space-y-2 z-50"}>
                    {results?.length > 0 && (
                      <div
                        className={"space-y-0"}
                        onMouseEnter={() => setIsHoverResults(true)}
                        onMouseLeave={() => setIsHoverResults(false)}
                      >
                        <div className={""}>
                          {results?.map((item, index) => {
                            if (size === "lg") {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSelect(item);
                                  }}
                                  className={`z-50 flex flex-row p-2 justify-between hover:bg-grey-f9 rounded cursor-pointer ${
                                    selectableIndex === index ? "bg-grey-f2" : ""
                                  }`}
                                >
                                  <div className={"flex flex-row space-x-2 self-center"}>
                                    <div className={"flex flex-col self-center justify-start items-start"}>
                                      <p
                                        title={item.name}
                                        className={"text-sm font-medium text-start truncate"}
                                        style={{ maxWidth: "20em" }}
                                      >
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className={"flex"}>
                                    <p className={"text-textGrey3 text-xs font-semibold self-center"}>
                                      Created by: {item.ownerDisplayName || item.owner}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                            return (
                              <div
                                key={index}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onSelect(item);
                                }}
                                className={`z-50 flex flex-col p-2 hover:bg-grey-f9 cursor-pointer ${
                                  selectableIndex === index ? "bg-grey-f2" : ""
                                }`}
                              >
                                <div className={"flex flex-row space-x-2"}>
                                  <div className={"flex flex-col self-center justify-start items-start"}>
                                    <p title={item.name} className={"text-sm font-medium text-start truncate"} style={{ maxWidth: "20em" }}>
                                      {item.name}
                                    </p>
                                  </div>
                                </div>
                                <div className={"flex"}>
                                  <p className={"text-textGrey3 text-xs font-semibold"}>
                                    Created by: {item.ownerDisplayName || item.owner}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {results?.length === 0 && (
                      <div className={"space-y-0"}>
                        <div className={"space-y-1"}>
                          <div className={"z-50 flex flex-row justify-between rounded text-textGrey4 text-sm m-auto"}>
                            <p className={"my-auto p-2"}>0 results</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default withUser(WatchlistPicker);
