import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import SelectSearch from "react-select-search";
import { generic_so5_divisions } from "./leagues";
import { sorareBasketball, sorareFootball } from "./sports";

function SO5DivisionPicker(props) {
  const { onSelect, withAll, gwNumber } = props;
  const withRegisteredLineupsOnly = props.withRegisteredLineupsOnly || false;
  const [selected, setSelected] = useState("");
  const [divisions, setDivisions] = useState([]);

  let sport = sorareFootball;
  if (props.sport) {
    sport = props.sport;
  }

  useEffect(() => {
    setSelected(props.selected || "");
  }, [props.selected]);

  useEffect(() => {
    getDivisionsForGw();
  }, [gwNumber]);

  const getDivisionsForGw = () => {
    if (gwNumber !== undefined && gwNumber > 0) {
      props
        .fetch(`/apiv2/gw/divisionsFull/${gwNumber}?withRegisteredLineupsOnly=${withRegisteredLineupsOnly}&sport=${sport}`)
        .then((response) => response.json())
        .then((res) => {
          setDivisions(res || []);
        })
        .catch(errorCatcher());
    }
  };

  let options = [];
  if (withAll && divisions.length > 0) {
    options.push({ value: "", name: "All tournaments" });
  }
  if (sport === sorareFootball) {
    options.push(
      ...divisions.map((division) => {
        return {
          // Use on second part of division id as dropdown key, so we can keep track of the type of tournament
          // when use changes of gameweek
          value: division.DivisionId.replace(division.GameWeek + "-", ""),
          name: division.DisplayName,
        };
      }),
    );
  } else if (sport === sorareBasketball) {
    options.push(
      ...divisions.map((division) => {
        return {
          // Use on second part of division id as dropdown key, so we can keep track of the type of tournament
          // when use changes of gameweek
          value: division.divisionId.replace(division.gwId + "-", ""),
          name: division.displayName,
        };
      }),
    );
  }

  const absenceMsg = `No '${generic_so5_divisions[selected]}' on gw`;
  return (
    <SelectSearch
      placeholder={withAll ? absenceMsg : selected === "" ? "" : absenceMsg}
      options={options}
      allowEmpty={true}
      value={selected}
      onChange={(v) => onSelect(v)}
    />
  );
}

export default withUser(SO5DivisionPicker);
