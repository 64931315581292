import React from "react";
import { withUser } from "../../userContext";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { sorareBaseball } from "../util/sports";

function PlayerRankingHeaderLine(props) {
  const titleClass = "text-xs text-white font-semibold text-center self-center";
  const indicatorsClass =
    "flex flex-row 2xl:space-x-6 xl:space-x-4 xl:justify-around 2xl:justify-center px-1 xl:w-2/12 lg:w-1/12 md:w-2/12";
  if (props.sport === sorareBaseball) {
    return null;
  } else {
    return (
      <div
        className={
          "bg-brand-black flex flex-row rounded-xl p-2 xl:px-4 md:space-x-2 space-x-8 md:overflow-y-hidden overflow-y-auto no-scroll-bar w-full"
        }
      >
        <div className={"3xl:w-16 2xl:w-8 flex flex-col justify-center text-center w-6"}>
          <p className={titleClass + " hidden md:inline-block"}>Rank</p>
        </div>
        <div className={"flex flex-row self-center xl:space-x-4 cursor-pointer lg:w-2/12 px-8 md:w-3/12 w-4/12"}>
          <p className={titleClass + " w-full"}>Player</p>
        </div>
        <div className={"w-6 justify-center text-center self-center"} />
        <div className={indicatorsClass}>
          <div className={"flex flex-col self-center xl:w-7/12 lg:w-full lg:pl-16 xl:pl-0"}>
            <p
              className={titleClass + " md:w-16 w-16 cursor-pointer hover:font-bold hover:text-textGrey3"}
              onClick={() => {
                if (props.newMatrix) {
                  props.sort("newAvg5");
                } else {
                  props.sort("avg5");
                }
              }}
            >
              L5
            </p>
          </div>
          {t1OrAbove(props.user.tier) && (
            <div className={"hidden xl:flex xl:flex-row space-x-10 w-5/12"}>
              <p
                className={titleClass + " cursor-pointer hover:font-bold hover:text-textGrey3 "}
                onClick={() => {
                  if (props.newMatrix) {
                    props.sort("newD5");
                  } else {
                    props.sort("d5");
                  }
                }}
              >
                DS5
              </p>
              <p
                className={titleClass + " cursor-pointer hover:font-bold hover:text-textGrey3 "}
                onClick={() => {
                  if (props.newMatrix) {
                    props.sort("newAA5");
                  } else {
                    props.sort("aa5");
                  }
                }}
              >
                AA5
              </p>
            </div>
          )}
        </div>
        <div className={"w-4 justify-center text-center self-center"} />

        <div className={indicatorsClass}>
          <div className={"flex flex-col self-center xl:w-7/12 lg:w-full lg:pl-16 xl:pl-0"}>
            <p
              className={titleClass + " md:w-16 w-16 cursor-pointer hover:font-bold hover:text-textGrey3"}
              onClick={() => {
                if (props.newMatrix) {
                  props.sort("newAvg20");
                } else {
                  props.sort("avg20");
                }
              }}
            >
              L15
            </p>
          </div>
          {t1OrAbove(props.user.tier) && (
            <div className={"hidden xl:flex xl:flex-row space-x-10 w-5/12"}>
              <p
                className={titleClass + " cursor-pointer hover:font-bold hover:text-textGrey3 hidden lg:inline-block"}
                onClick={() => {
                  if (props.newMatrix) {
                    props.sort("newD20");
                  } else {
                    props.sort("d20");
                  }
                }}
              >
                DS15
              </p>
              <p
                className={titleClass + " cursor-pointer hover:font-bold hover:text-textGrey3 hidden lg:inline-block"}
                onClick={() => {
                  if (props.newMatrix) {
                    props.sort("newAA20");
                  } else {
                    props.sort("aa20");
                  }
                }}
              >
                AA15
              </p>
            </div>
          )}
        </div>
        <div className={"w-4 justify-center text-center self-center"} />
        <div className={indicatorsClass}>
          <div className={"flex flex-col self-center xl:w-7/12 lg:w-full lg:pl-16 xl:pl-0"}>
            <p
              className={titleClass + " md:w-16 w-16 cursor-pointer hover:font-bold hover:text-textGrey3"}
              onClick={() => {
                if (props.newMatrix) {
                  props.sort("newAvg40");
                } else {
                  props.sort("avg40");
                }
              }}
            >
              L40
            </p>
          </div>
          {t1OrAbove(props.user.tier) && (
            <div className={"hidden xl:flex xl:flex-row space-x-10 w-5/12"}>
              <p
                className={titleClass + " cursor-pointer hover:font-bold hover:text-textGrey3 hidden lg:inline-block"}
                onClick={() => {
                  if (props.newMatrix) {
                    props.sort("newD40");
                  } else {
                    props.sort("d40");
                  }
                }}
              >
                DS40
              </p>
              <p
                className={titleClass + " cursor-pointer hover:font-bold hover:text-textGrey3 hidden lg:inline-block"}
                onClick={() => {
                  if (props.newMatrix) {
                    props.sort("newAA40");
                  } else {
                    props.sort("aa40");
                  }
                }}
              >
                AA40
              </p>
            </div>
          )}
        </div>
        <div
          className={
            "flex flex-row 3xl:space-x-8 2xl:space-x-6 lg:space-x-6 md:space-x-2 space-x-4 md:w-4/12 xl:w-3/12 lg:w-6/12 pl-4 lg:pl-8 lg:justify-around"
          }
        >
          <p
            className={titleClass + " md:w-32 w-20 cursor-pointer hover:font-bold hover:text-textGrey3"}
            onClick={() => props.sort(props.showBmp && !props.showValo ? "bmpl" : "l_avg", props.showValo, props.showBmp)}
          >
            Limited value
          </p>
          <p
            className={titleClass + " md:w-32 w-20 cursor-pointer hover:font-bold hover:text-textGrey3"}
            onClick={() => props.sort(props.showBmp && !props.showValo ? "bmp_r" : "r_avg", props.showValo, props.showBmp)}
          >
            Rare value
          </p>
          <p
            className={titleClass + " md:w-32 w-20 cursor-pointer hover:font-bold hover:text-textGrey3"}
            onClick={() => props.sort(props.showBmp && !props.showValo ? "bmp_sr" : "sr_avg", props.showValo, props.showBmp)}
          >
            Super rare value
          </p>
        </div>
        <div className={"md:w-10 w-32 flex flex-row justify-center cursor-pointer"}></div>
      </div>
    );
  }
}

export default withUser(PlayerRankingHeaderLine);
