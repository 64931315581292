import React, { useEffect, useState } from "react";
import { GLOBAL_MARGIN, GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import Scorers from "../teams/scorers";
import LeagueStandings from "../teams/leagueStandings";
import { all_positions_objects } from "../util/positions";
import SelectSearch from "react-select-search";
import TeamStatsTable from "../teams/teamStatsTable";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { Link } from "react-router-dom";
import { sorareFootball } from "../util/sports";
import BgCard from "../../img/sd-player-header.svg";

function CompetitionHome(props) {
  const activeTab = props.match?.params?.tab || "overview";
  const [nbGames, setNbGames] = useState(15);
  const [compInfo, setCompInfo] = useState([]);
  const [bestPlayer, setBestPlayer] = useState(undefined);
  const [startingPrices, setStartingPrices] = useState(undefined);
  const [position, setPosition] = useState("all");

  useEffect(() => {
    getCompInfo();
  }, []);

  const getCompInfo = (nb, pos) => {
    if (nb === undefined) nb = nbGames;
    if (pos === undefined) pos = position;
    props
      .fetch("/apiv2/comp/info/" + props.match.params.id + "/" + nb + "/" + pos)
      .then((response) => response.json())
      .then((res) => {
        //document.title = res.team.DisplayName;
        setCompInfo(res);
        setBestPlayer(res.best_player);
        setStartingPrices(res.starting_prices);
      })
      .catch(errorCatcher());
  };

  const colorStart = "#132D8F";
  const colorEnd = "#1D3EBD";

  const topAverages = compInfo?.top_averages;
  const topAllAround = compInfo?.top_all_around;
  const topMinutes = compInfo?.top_minutes;
  const topScorers = compInfo?.top_scorers;
  const topAssists = compInfo?.top_assists;
  const topCorners = compInfo?.top_corner_takers;
  const topDecisiveActions = compInfo?.top_decisive;
  const topPK = compInfo?.top_penalty_takers;
  const topFK = compInfo?.direct_freekick_takers;
  const topFKA = compInfo?.top_deadball_assists;

  const inactiveClass =
    "text-base border-b-4 border-brand-black py-2 px-6 block font-medium text-textGrey4 hover:text-white focus:outline-none";
  const activeClass = "text-base border-b-4 border-brand py-2 px-6 font-medium focus:outline-none";

  const active =
    "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-brand-pastel text-xs md:text-sm font-medium text-gray-700 focus:outline-none";
  const inactive =
    "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-xs md:text-sm font-medium text-gray-700 hover:bg-brand-pastel focus:outline-none";

  return (
    <div>
      <div
        className={"md:h-team-header"}
        style={{
          background: "linear-gradient(to left," + colorStart + ", " + colorEnd + ")",
        }}
      >
        <div className={"absolute w-full md:h-team-header z-0 text-focus overflow-hidden"} style={{ opacity: "10%" }}>
          <div className={"h-player flex w-full md:h-team-header bg-gradient-to-b md:bg-gradient-to-r from-white to-full-black z-0"}>
            <img className={"absolute transform left-1/2 -translate-x-1/2 object-contain z-0"} src={BgCard} />
          </div>
        </div>

        <div
          className={
            GLOBAL_MARGIN_NO_BG_NO_PAD + " h-auto pb-4 md:pb-0 md:h-team-header space-x-4 mx-auto flex flex-col md:flex-row justify-between"
          }
        >
          <div className={"flex flex-row justify-center my-4 md:my-0 space-x-8 z-10"}>
            <div className={"self-center flex flex-col text-white space-y-2"}>
              <p className={"text-white text-xl md:text-4xl font-semibold"}>{compInfo.comps?.length > 0 ? compInfo.comps[0].name : ""}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={"bg-brand-black"}>
        <div className={GLOBAL_MARGIN_NO_BG_NO_PAD + " mb-6 text-focus"}>
          <nav className="lg:flex lg:flex-row lg:block grid grid-cols-2">
            <Link to={`/comp/${props.match.params.id}/sections/overview`}>
              <button className={activeTab === "overview" ? activeClass : inactiveClass}>Overview</button>
            </Link>
            <Link to={`/comp/${props.match.params.id}/sections/stats`}>
              <button className={activeTab === "stats" ? activeClass : inactiveClass}>Stats</button>
            </Link>
          </nav>
        </div>
      </div>
      {activeTab === "overview" && (
        <div className={GLOBAL_MARGIN + " space-y-8"}>
          <div className={"flex flex-row justify-between"}>
            <SelectSearch
              options={all_positions_objects}
              className={"select-search z-20"}
              printOptions={"on-focus"}
              placeholder={"Select one or more positions..."}
              value={position}
              closeOnSelect={true}
              onChange={(v) => {
                setPosition(v);
                getCompInfo(undefined, v);
              }}
            />
            <div>
              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <button
                  onClick={() => {
                    setNbGames(5);
                    getCompInfo(5);
                  }}
                  type="button"
                  className={nbGames === 5 ? "rounded-l-lg " + active : "rounded-l-lg " + inactive}
                >
                  Last 5
                </button>
                <button
                  onClick={() => {
                    setNbGames(15);
                    getCompInfo(15);
                  }}
                  type="button"
                  className={nbGames === 15 ? active : inactive}
                >
                  Last 15
                </button>
                <button
                  onClick={() => {
                    setNbGames(40);
                    getCompInfo(40);
                  }}
                  type="button"
                  className={nbGames === 40 ? "rounded-r-lg " + active : "rounded-r-lg " + inactive}
                >
                  Last 40
                </button>
              </span>
            </div>
          </div>

          <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-3 gap-4"}>
            <Scorers scorers={topAverages} title={"Top SO5 averages"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topAllAround} title={"All-around averages"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topDecisiveActions} title={"Top net decisive actions"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topMinutes} title={"Top minutes played"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topScorers} title={"Top goalscorers"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topAssists} title={"Top assists"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topPK} title={"Penalty kicks"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topFK} title={"Freekick scorers"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topFKA} title={"Freekick assists"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers scorers={topCorners} title={"Corner takers"} bgStart={colorStart} bgEnd={colorEnd} />
          </div>
          {compInfo?.standings !== undefined && compInfo?.standings !== null && (
            <div className={"w-full"}>
              <LeagueStandings standings={compInfo?.standings} />
            </div>
          )}
        </div>
      )}
      {activeTab === "stats" && (
        <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
          <TeamStatsTable leagueId={props.match.params.id} sport={sorareFootball} league={true} />
        </div>
      )}
    </div>
  );
}

export default withUser(CompetitionHome);
