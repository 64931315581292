import React from "react";
import { withUser } from "../../userContext";

function PlayerLink(props) {
  let size = "text-base";
  if (props.size !== undefined) size = props.size;
  let target = "";
  if (props.target !== undefined) target = "_blank";
  let weight = props.weight || 500;
  return (
    <span>
      <a target={target} href={"/player/" + props.player.PlayerId}>
        <span className={size + " font-semibold"}>{props.player.DisplayName}</span>
      </a>
    </span>
  );
}

export default withUser(PlayerLink);
