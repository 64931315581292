import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { useTheme } from "../../themeContext";
import { sdLight } from "../../themeColors";
import { lineupDecisiveActionIcons, LineupScore } from "../SO5/SO5LineupCard";
import { CaptainLabel } from "../decision/lineupBuilderLineup";
import SDIcon from "../../img/sd-logo.svg";
import { getNormalizedInfoOfLineupPlayer } from "../SO5/SO5LineupCards";
import Spinner from "../loader/spinner";
import ordinal_suffix_of from "../util/ordinalNumber";
import GamePreview from "../games/gamePreview";

export const ControlledShortLineup = withUser((props) => {
  const { lineupId } = props;

  const [lineup, setLineup] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lineupId === undefined || lineupId === null || lineupId === "") {
      return;
    }
    props
      .fetch("/apiv2/SO5/lineups/football/id/" + lineupId)
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setLineup(res || {});
        } else {
          setLineup({});
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  }, [lineupId]);
  return <LineupWithResults lineup={lineup} loading={loading} />;
});

const LineupWithResults = (props) => {
  const { loading } = props;
  const { theme } = useTheme();

  const players = props.lineup?.players;
  const lineup = props.lineup?.lineupInfo;
  const competition = props.lineup?.competition;
  const rank = lineup?.Rank || 16;
  const score = lineup?.Score || 360;
  return (
    <div
      className={`rounded-2xl px-2 bg-top bg-cover ${
        theme === sdLight ? "bg-lub-lineup bg-brand-light-light-green" : "bg-lub-lineup-dark bg-brand-light-green"
      }`}
      style={{ width: "500px" }}
    >
      <div
        className={`relative py-5 flex flex-row justify-between items-center text-sm font-semibold text-white px-1 ${
          props.lineup === null || loading ? "h-20" : ""
        }`}
      >
        <div className={"absolute w-full h-full flex justify-center items-center"}>
          <div className={"w-8 h-8"}>
            <img className={"w-full h-full object-contain"} src={SDIcon} />
          </div>
        </div>
        {props.lineup !== null && (
          <>
            <div className={"flex flex-col gap-1"}>
              <h3 className={"m-0 text-md font-bold"}>{competition?.DisplayName}</h3>
              <h4 className={"m-0 text-sm"}>GW #{competition?.GwNumber}</h4>
            </div>
            <div className={"bg-black bg-opacity-8 rounded-3xl py-2 px-3 flex flex-row items-center gap-2"}>
              <span className={"text-xs"}>{ordinal_suffix_of(rank)}</span>
              <div className={"h-4 border-r border-white border-opacity-10"}></div>
              <span className={"text-md"}>{score} pts</span>
            </div>
          </>
        )}
      </div>
      <div className={`bg-lub-football-pitch bg-top bg-cover bg-no-repeat h-w-full ${props.lineup === null || loading ? "h-48" : ""}`}>
        <div className={"grid grid-cols-5 gap-x-1 pt-12 pb-6 -mt-8"}>
          {players?.map((playerInLineup, i) => {
            const playerPower = playerInLineup?.lineupPower;
            const captain = playerInLineup?.isCaptain;
            const findGameWithBestScore = playerInLineup.games?.find(({ scoreUsedInLineup }) => scoreUsedInLineup === true) || {};
            const gamesByTeamId = {
              [findGameWithBestScore.info?.home_team_id]: findGameWithBestScore?.info,
              [findGameWithBestScore.info?.away_team_id]: findGameWithBestScore?.info,
            };
            const playerStats = findGameWithBestScore?.playerStats;
            const { SO5Score, totalPoints, game, power, floatSO5Score } = getNormalizedInfoOfLineupPlayer(
              playerInLineup,
              lineup,
              gamesByTeamId,
              playerStats,
              playerPower,
            );
            return (
              <LineupItem
                key={i}
                game={game}
                totalPoints={totalPoints}
                player={playerInLineup.player}
                power={power}
                SO5Score={SO5Score}
                floatSO5Score={floatSO5Score}
                stats={playerStats}
                card={playerInLineup.card}
                isCaptain={captain}
                lineup={lineup}
              />
            );
          })}
        </div>
        {props.lineup === null || loading ? (
          <div className={"p-8 justify-center items-center"}>
            <Spinner />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const LineupItem = (props) => {
  const { card, game, stats, power, SO5Score, floatSO5Score, isCaptain } = props;
  const icons = lineupDecisiveActionIcons(
    stats,
    card.Position,
    "w-4 h-4 self-center rounded-full",
    "w-full h-full object-contain shadow rounded-full",
  );
  return (
    <div
      className={`flex flex-col bg-surface-container rounded-xl justify-center items-center w-full h-full overflow-hidden ${
        isCaptain ? "border-3" : "border p-[1px]"
      }  ${isCaptain ? "border-limited" : "border-surface-container"}`}
    >
      <div
        className={`relative self-center flex flex-col justify-between items-center w-full h-full overflow-hidden ${
          isCaptain ? "" : "p-[1px]"
        }`}
      >
        <div className={`w-full relative`}>
          <img className={"rounded-xl object-contain"} style={{ padding: "2.5%" }} src={card.PictureUrl} />
          <div className={`absolute grid grid-cols-2 z-10 top-0 w-full h-full`}>
            <div className={"flex w-full justify-center h-[28%]"}>{isCaptain && <CaptainLabel isCaptain={true} />}</div>
          </div>
          <div className={"items-center flex flex-col -mt-3 z-10 h-6"}>
            <div className={"flex flex-row justify-center mx-auto z-10 -space-x-0.5"}>{icons}</div>
          </div>
        </div>
        <div
          className={"flex flex-col justify-center w-full gap-2"}
          style={
            isCaptain
              ? {
                  paddingLeft: "5%",
                  paddingRight: "5%",
                }
              : { paddingLeft: "5%", paddingRight: "5%" }
          }
        >
          <div className={"relative flex justify-center"}>
            <div className={"font-semibold text-xs text-on-surface-variant"}>
              <span className={"text-on-surface"}>{SO5Score === "DNP" ? "" : SO5Score}</span> x {power}
            </div>
          </div>
          <div className={`flex flex-row justify-center`}>
            <LineupScore game={game} floatSO5Score={floatSO5Score} power={power} player={props.player} stats={stats} size={"text-sm"} />
          </div>
          <div className={"flex self-center items-center w-full px-2"}>
            <GamePreview
              hideDate={true}
              noCircle={true}
              logoSize={"w-6 h-6"}
              size="text-xs"
              textColor={"text-on-surface"}
              game={game}
              showScore={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
