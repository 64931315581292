import React, { useEffect, useState } from "react";
import PlayerStatsGraph from "./playerStatsGraph";
import PlayerStatsLines from "./playerStatsLines";
import PlayerNextGames from "./playerNextGames";
import { ReactTooltip } from "../util/tooltip.js";
import LineSeparator from "../../img/line-separator.svg";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { positions_objects } from "../util/positions";
import ScoreGauge from "./scoreGauge";
import GoalIcon from "../../img/ICN_Ballon.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import CleanSheet from "../../img/cleansheet.svg";
import PenaltySave from "../../img/PenaltySave.svg";
import NotInSquad from "../../img/notInSquad.svg";
import CameIn from "../../img/cameIn.svg";
import Bench from "../../img/benchNotCameIn.svg";
import Started from "../../img/starter.svg";
import AwayIcon from "../../img/awayIcon.svg";
import HomeIcon from "../../img/homeIcon.svg";
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis } from "recharts";
import DecisiveActionBox from "../util/decisiveActionBox";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import BaseballStatIcon from "../util/baseballStatIcon";
import { convertToBaseballAverage } from "../util/baseballAverage";
import Pentagon from "../util/pentagon";

function PlayerScoresTab(props) {
  const [position, setPosition] = useState(props.position || props.info.player.Position);
  const [period, setPeriod] = useState(props.info.player.Sport === sorareBaseball ? "ytd" : "1y");
  const [teams, setTeams] = useState([{ name: "All teams", value: "all" }]);
  const [comps, setComps] = useState([{ name: "All competitions", value: "all" }]);
  const [teamId, setTeamId] = useState("all");
  const [comp, setComp] = useState("all");
  const [started, setStarted] = useState(true);
  const [subbedIn, setSubbedIn] = useState(true);
  const [benched, setBenched] = useState(true);
  const [notInSquad, setNotInSquad] = useState(true);
  const [playerPositions, setPlayerPositions] = useState([]);
  const [allPositions, setAllPositions] = useState([]);
  const [home, setHome] = useState(true);
  const [away, setAway] = useState(true);
  const [newMatrix, setNewMatrix] = useState(true);
  const [playerData, setData] = useState({
    stats: [],
    decisive_actions: {},
    decisive_average: 0,
    all_around_average: 0,
    average_no_dnp: 0,
    average_dnp: 0,
    games_played: 0,
    games_started: 0,
    score_chart: [],
    stat_chart: [],
    teams: [],
    competitions: [],
    next_games: {},
    total_games: 0,
    not_on_game_sheets: 0,
    games_on_bench: 0,
  });

  useEffect(() => {
    if (position !== undefined) {
      if (position === "SP") {
        setBenched(false);
        fetchInfo(position, undefined, undefined, undefined, undefined, undefined, undefined, false);
      } else {
        fetchInfo(position);
      }
    }
    ReactTooltip.rebuild();
  }, [position]);

  const playerId = props.info.player.PlayerId;
  const player = props.info.player;
  const sport = player.Sport;

  let colors;
  if (sport === sorareFootball) {
    colors = {
      "90-100": "#429559",
      "80-90": "#429559",
      "70-80": "#5DC09B",
      "60-70": "#5DC09B",
      "50-60": "#B1C345",
      "40-50": "#B1C345",
      "30-40": "#EBCF4A",
      "20-30": "#F2994A",
      "10-20": "#E73107",
      "0-10": "#E73107",
      DNP: "#BDBDBD",
    };
  } else if (sport === sorareBaseball) {
    colors = {
      "40+": "#429559",
      "35-40": "#429559",
      "30-35": "#429559",
      "25-30": "#429559",
      "20-25": "#5DC09B",
      "15-20": "#5DC09B",
      "10-15": "#B1C345",
      "5-10": "#EBCF4A",
      "0-5": "#F2994A",
      "-0": "#E73107",
      DNP: "#BDBDBD",
    };
  } else if (sport === sorareBasketball) {
    colors = {
      "80+": "#429559",
      "70-80": "#429559",
      "60-70": "#5DC09B",
      "50-60": "#B1C345",
      "40-50": "#B1C345",
      "30-40": "#B1C345",
      "20-30": "#EBCF4A",
      "10-20": "#EBCF4A",
      "0-10": "#F2994A",
      "-0": "#E73107",
      DNP: "#BDBDBD",
    };
  }

  const fetchInfo = (p, pd, c, t, pp, st, su, b, n, h, a, sc) => {
    if (!p) p = position;
    if (!pd) pd = period;
    if (!c) c = comp;
    if (!t) t = teamId;
    if (!pp) pp = playerPositions;
    if (st === undefined) st = started;
    if (su === undefined) su = subbedIn;
    if (b === undefined) b = benched;
    if (n === undefined) n = notInSquad;
    if (h === undefined) h = home;
    if (a === undefined) a = away;
    if (sc === undefined) sc = newMatrix;
    let scoringValue = "old";
    if (sc === true) scoringValue = "new";
    props
      .fetch(
        "/apiv2/players/detailedStats/" +
          playerId +
          "?position=" +
          p +
          "&teamId=" +
          t +
          "&comp=" +
          c +
          "&period=" +
          pd +
          "&fullPositions=" +
          pp +
          "&started=" +
          st +
          "&subbed=" +
          su +
          "&benched=" +
          b +
          "&notInSquad=" +
          n +
          "&home=" +
          h +
          "&away=" +
          a +
          "&scoring=" +
          scoringValue +
          "&sport=" +
          sport,
      )
      .then((response) => response.json())
      .then((res) => {
        setData(res);
        if (res.competitions !== null && res.competitions?.length > 0) {
          setComps([{ name: "All competitions", value: "all" }, ...res.competitions]);
        }
        if (res.teams !== null && res.teams.length > 0) {
          setTeams([{ name: "All teams", value: "all" }, ...res.teams]);
        }
        if (res.all_positions !== null && res.all_positions.length > 0) {
          setAllPositions([...res.all_positions]);
        }
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  const changePeriod = (p) => {
    setPeriod(p);
    fetchInfo(undefined, p);
  };

  const changeScoring = (sc) => {
    setNewMatrix(sc);
    fetchInfo(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, sc);
  };

  const changeComp = (c) => {
    setComp(c);
    fetchInfo(undefined, undefined, c);
  };

  const changeTeam = (t) => {
    setTeamId(t);
    fetchInfo(undefined, undefined, undefined, t);
  };

  const changeSubbed = (s) => {
    setSubbedIn(s);
    fetchInfo(undefined, undefined, undefined, undefined, undefined, undefined, s);
  };

  const changeStarted = (s) => {
    setStarted(s);
    fetchInfo(undefined, undefined, undefined, undefined, undefined, s, undefined);
  };

  const changeNotInSquad = (s) => {
    setNotInSquad(s);
    fetchInfo(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, s);
  };

  const changeBenched = (s) => {
    setBenched(s);
    fetchInfo(undefined, undefined, undefined, undefined, undefined, undefined, undefined, s);
  };

  const changeFullPosition = (pp) => {
    setPlayerPositions(pp);
    fetchInfo(undefined, undefined, undefined, undefined, pp);
  };

  const changeHome = (s) => {
    setHome(s);
    fetchInfo(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, s);
  };

  const changeAway = (s) => {
    setAway(s);
    fetchInfo(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, s);
  };

  let activeClass = "border-b-4 border-brand h-full font-semibold text-sm text-brand focus:outline-none self-center";
  let inactiveClass =
    "bg-focus border-b-4 border-white hover:text-brand text-textGrey4 h-full rounded self-center font-semibold text-sm focus:outline-none";

  let compName = "All competitions";
  if (comp !== "all") {
    comps.map((c) => {
      if (c.value === comp) {
        compName = c.name;
      }
    });
  }

  let totalGames = 0;
  let pct = "NO GAME PLAYED";
  if (playerData.stats !== null) {
    totalGames = playerData.stats.length;
    pct = ((playerData.games_played / totalGames) * 100).toFixed(0) + "% GAMES PLAYED";
  }

  return (
    <div className={"space-y-4"}>
      <div>
        <PlayerNextGames playerId={playerId} playerTeamId={player.TeamId} sport={sport} nextGames={playerData.next_games} />
      </div>
      <div className={"space-y-4"}>
        <div className={"flex flex-row justify-between"}>
          <div>
            <p className={"font-headers text-brand font-semibold text-xl"}>Past games</p>
          </div>
        </div>
        <div className={"w-full flex flex-col xl:flex-row mx-auto text-base justify-between space-y-4 xl:space-y-0 xl:space-x-4"}>
          <div className={"md:space-x-2 md:flex md:flex-row grid grid-cols-4 gap-2 bg-white px-4 rounded"}>
            <button className={period === "1m" ? activeClass : inactiveClass} onClick={() => changePeriod("1m")}>
              1m
            </button>
            <button className={period === "3m" ? activeClass : inactiveClass} onClick={() => changePeriod("3m")}>
              3m
            </button>
            <button className={period === "6m" ? activeClass : inactiveClass} onClick={() => changePeriod("6m")}>
              6m
            </button>
            <button className={period === "ytd" ? activeClass : inactiveClass} onClick={() => changePeriod("ytd")}>
              YTD
            </button>
            <button className={period === "1y" ? activeClass : inactiveClass} onClick={() => changePeriod("1y")}>
              1y
            </button>
            {sport === sorareBaseball && (
              <button className={period === "2023" ? activeClass : inactiveClass} onClick={() => changePeriod("2023")}>
                2023
              </button>
            )}
            {sport === sorareBaseball && (
              <button className={period === "2022" ? activeClass : inactiveClass} onClick={() => changePeriod("2022")}>
                2022
              </button>
            )}
            {sport === sorareBaseball && (
              <button className={period === "2021" ? activeClass : inactiveClass} onClick={() => changePeriod("2021")}>
                2021
              </button>
            )}
            {sport === sorareBaseball && (
              <button className={period === "2020" ? activeClass : inactiveClass} onClick={() => changePeriod("2020")}>
                2020
              </button>
            )}

            <button className={period === "all" ? activeClass : inactiveClass} onClick={() => changePeriod("all")}>
              All
            </button>
            <img alt="" className={"hidden md:block py-2"} src={LineSeparator} />
            {sport !== sorareBasketball && (
              <button className={period === "L5" ? activeClass : inactiveClass} onClick={() => changePeriod("L5")}>
                L5
              </button>
            )}
            {sport === sorareBasketball && (
              <button className={period === "L10" ? activeClass : inactiveClass} onClick={() => changePeriod("L10")}>
                L10
              </button>
            )}
            {sport !== sorareBasketball && (
              <button className={period === "L15" ? activeClass : inactiveClass} onClick={() => changePeriod("L15")}>
                L15
              </button>
            )}
            <button className={period === "L40" ? activeClass : inactiveClass} onClick={() => changePeriod("L40")}>
              L40
            </button>
            {sport === sorareBasketball && (
              <button className={period === "2021" ? activeClass : inactiveClass} onClick={() => changePeriod("2021")}>
                2021
              </button>
            )}
            {sport === sorareBasketball && (
              <button className={period === "2022" ? activeClass : inactiveClass} onClick={() => changePeriod("2022")}>
                2022
              </button>
            )}
            {sport === sorareBasketball && (
              <button className={period === "2023" ? activeClass : inactiveClass} onClick={() => changePeriod("2023")}>
                2023
              </button>
            )}
          </div>
          <div className={"flex flex-col space-y-2 lg:space-y-0 lg:justify-end lg:items-end lg:flex-row lg:space-x-2"}>
            {
              <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0 lg:space-x-2 lg:items-end lg:justify-end">
                {(t2OrAbove(props.user?.tier) || sport === sorareBaseball || sport === sorareBasketball) && (
                  <SelectSearch options={teams} value={teamId} onChange={(v) => changeTeam(v)} />
                )}
                {t2OrAbove(props.user?.tier) && sport === sorareFootball && (
                  <SelectSearch options={comps} value={comp} onChange={(v) => changeComp(v)} />
                )}
                {(t2OrAbove(props.user?.tier) || sport === sorareBaseball || sport === sorareBasketball) && (
                  <SelectSearch
                    multiple
                    options={allPositions}
                    value={playerPositions}
                    closeOnSelect={false}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder={"Select one field position or more..."}
                    onChange={(v) => changeFullPosition(v)}
                  />
                )}
              </div>
            }
            {sport === sorareFootball && (
              <div className={"flex flex-row space-x-2"}>
                <div className={"w-40"}>
                  <SelectSearch options={positions_objects} value={position} onChange={(v) => setPosition(v)} />
                </div>
              </div>
            )}
            {(t2OrAbove(props.user?.tier) || sport === sorareBaseball || sport === sorareBasketball) && (
              <div className={"bg-white rounded py-1.5 space-x-2 px-3 flex flex-row self-end"}>
                <div className={"w-6 h-6 self-center"} onClick={() => changeStarted(!started)}>
                  <img
                    alt=""
                    data-tip={"Select games where player has started the game"}
                    src={Started}
                    className={
                      started ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"
                    }
                  />
                </div>
                <img alt="" src={LineSeparator} />
                <div className={"w-6 h-6 self-center"} onClick={() => changeSubbed(!subbedIn)}>
                  <img
                    alt=""
                    data-tip={"Select games where player has subbed in the game"}
                    src={CameIn}
                    className={
                      subbedIn ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"
                    }
                  />
                </div>
                <img alt="" src={LineSeparator} />
                <div className={"w-6 h-6 self-center"} onClick={() => changeBenched(!benched)}>
                  <img
                    alt=""
                    data-tip={"Select games where player has been benched the whole game"}
                    src={Bench}
                    className={
                      benched ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"
                    }
                  />
                </div>
                <img alt="" src={LineSeparator} />
                <div className={"w-6 h-6 self-center"} onClick={() => changeNotInSquad(!notInSquad)}>
                  <img
                    alt=""
                    data-tip={"Select games where player has not been in the squad"}
                    src={NotInSquad}
                    className={
                      notInSquad ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"
                    }
                  />
                </div>
                <img alt="" src={LineSeparator} />
                <div className={"w-6 h-6 self-center"} onClick={() => changeHome(!home)}>
                  <img
                    alt=""
                    data-tip={"Select games at home"}
                    src={HomeIcon}
                    className={
                      home ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"
                    }
                  />
                </div>
                <img alt="" src={LineSeparator} />
                <div className={"w-6 h-6 self-center"} onClick={() => changeAway(!away)}>
                  <img
                    alt=""
                    data-tip={"Select games away"}
                    src={AwayIcon}
                    className={
                      away ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {(t2OrAbove(props.user?.tier) || sport === sorareBaseball) && (
          <div className={"flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full"}>
            <div className={"flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full lg:w-7/12"}>
              <div className={"bg-white px-4 py-6 rounded-lg flex flex-col space-y-6 items-center justify-center w-full md:w-7/12"}>
                <div className={"rounded-lg flex flex-row space-x-4 3xl:space-x-8 self-center justify-center align-middle w-full px-2"}>
                  {sport === sorareFootball && (
                    <div
                      className={"space-y-1 bg-grey-f2 px-2 py-1 flex flex-col self-center justify-start items-center rounded-lg w-4/12"}
                    >
                      <p className={"text-xs font-semibold text-textGrey3 text-center"}>{compName}</p>
                    </div>
                  )}
                  <div className={"flex flex-col space-y-3 justify-center self-center"}>
                    <div className={"mx-auto"}>
                      {sport === sorareFootball && (
                        <ScoreGauge
                          score={playerData.average_no_dnp}
                          fontSize={"text-2xl"}
                          size={"w-24"}
                          height={"45px"}
                          sport={sport}
                          aaScore={playerData.all_around_average}
                          decisiveScore={playerData.decisive_average}
                        />
                      )}
                      {sport === sorareBaseball && (
                        <div className={"w-24"}>
                          <BaseballScoreCircle score={playerData.average_no_dnp} />
                        </div>
                      )}
                      {sport === sorareBasketball && (
                        <div className={"flex flex-row space-x-6 justify-center mx-auto"}>
                          <div className={"w-32 self-center flex flex-col space-y-2"}>
                            <div className={"w-20 h-20 mx-auto"}>
                              <BaseballScoreCircle scale={"basketball"} score={playerData.average_no_dnp} />
                            </div>
                            <p className={"text-center text-xs font-semibold justify-end"}>F. points per game</p>
                          </div>
                          <div className={"w-32 self-center flex flex-col space-y-2"}>
                            <div className={"w-18 h-20 mx-auto self-end flex"}>
                              <Pentagon value={playerData?.minutes_per_game?.toFixed(0)} />
                            </div>
                            <p className={"text-center text-xs font-semibold justify-end"}>Minutes per game</p>
                          </div>
                        </div>
                      )}
                    </div>
                    {sport !== sorareBasketball && <p className={"text-xxs text-center text-textGrey3 font-semibold"}>{pct}</p>}
                  </div>
                  {sport === sorareFootball && (
                    <div className={"w-4/12 flex flex-row justify-center self-center"}>
                      <div
                        className={
                          "box-content space-y-1 bg-grey-f2 px-4 py-1.5 flex flex-col self-center justify-center items-center rounded-lg w-auto"
                        }
                      >
                        <p className={"text-textGrey3 font-semibold text-sm"}>
                          DS: <span className={"font-bold"}>{playerData.decisive_average.toFixed(1)}</span>
                        </p>
                        <p className={"text-textGrey3 font-semibold text-sm"}>
                          AA: <span className={"font-bold"}>{playerData.all_around_average.toFixed(1)}</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={"grid grid-cols-4 gap-4 self-center"}>
                  <DecisiveActionBox img={Started} value={playerData.games_started} tip={"Games started"} />
                  <DecisiveActionBox
                    img={CameIn}
                    value={playerData.games_played - playerData.games_started}
                    tip={"Entered from the bench"}
                  />
                  <DecisiveActionBox img={Bench} value={playerData.games_on_bench} tip={"Stayed on bench"} />
                  <DecisiveActionBox img={NotInSquad} value={playerData.not_on_game_sheets} tip={"Not in squad"} />
                </div>
                {sport === sorareBasketball && (
                  <div>
                    <p className={"text-xxs text-center text-textGrey3 font-semibold"}>{pct}</p>
                  </div>
                )}
              </div>

              {sport === sorareFootball && playerData.decisive_actions.goals !== undefined && (
                <div className={"w-full md:w-5/12 bg-white flex flex-col space-y-6 rounded-lg items-center justify-center p-4"}>
                  <div>
                    <div
                      className={"space-y-1 bg-grey-f2 px-2 py-1 flex flex-col self-center justify-center items-center rounded-lg w-auto"}
                    >
                      <p className={"text-xs font-semibold text-textGrey3 text-center"}>Decisive actions</p>
                    </div>
                  </div>
                  <div className={"flex flex-col space-y-4"}>
                    <div className={position === "Goalkeeper" ? "grid grid-cols-6 gap-4" : "grid grid-cols-4 gap-4"}>
                      <DecisiveActionBox img={GoalIcon} value={playerData.decisive_actions.goals} tip={"Goals"} />
                      <DecisiveActionBox img={AssistIcon} value={playerData.decisive_actions.assists} tip={"Goal assists"} />
                      <DecisiveActionBox img={Whistle} value={playerData.decisive_actions.penalties_won} tip={"Penalty kicks won"} />
                      <DecisiveActionBox
                        img={AngelBall}
                        value={playerData.decisive_actions.last_man_tackles + playerData.decisive_actions.clearances_off_line}
                        tip={"Last man tackles & clearances off line"}
                      />
                      {position === "Goalkeeper" && (
                        <DecisiveActionBox img={CleanSheet} value={playerData.decisive_actions.clean_sheets} tip={"Clean sheets"} />
                      )}
                      {position === "Goalkeeper" && (
                        <DecisiveActionBox img={PenaltySave} value={playerData.decisive_actions.penalty_saves} tip={"Penalty saves"} />
                      )}
                    </div>
                    <div className={"grid grid-cols-4 gap-4"}>
                      <DecisiveActionBox img={RedCard} value={playerData.decisive_actions.red_cards} tip={"Red cards"} />
                      <DecisiveActionBox img={OwnGoal} value={playerData.decisive_actions.own_goals} tip={"Own goals"} />
                      <DecisiveActionBox img={RedShoe} value={playerData.decisive_actions.errors_led_to_goal} tip={"Errors led to goal"} />
                      <DecisiveActionBox
                        img={RedWhistle}
                        value={playerData.decisive_actions.penalties_conceded}
                        tip={"Penalty kicks conceded"}
                      />
                    </div>
                  </div>
                </div>
              )}
              {sport === sorareBaseball && playerData?.decisive_actions?.hits !== undefined && (
                <div className={"w-full md:w-5/12 bg-white flex flex-col space-y-6 rounded-lg items-center justify-center p-4"}>
                  {!(position === "RP" || position === "SP") && (
                    <div className={"grid grid-cols-6 gap-6"}>
                      <BaseballStatIcon name={"AVG"} value={convertToBaseballAverage(playerData.decisive_actions.battingAverage)} />
                      <BaseballStatIcon name={"1B"} value={playerData.decisive_actions.singles} />
                      <BaseballStatIcon name={"2B"} value={playerData.decisive_actions.doubles} />
                      <BaseballStatIcon name={"3B"} value={playerData.decisive_actions.triples} />
                      <BaseballStatIcon name={"HR"} value={playerData.decisive_actions.homeRuns} />
                      <BaseballStatIcon name={"RBI"} value={playerData.decisive_actions.rbis} />
                      <BaseballStatIcon name={"AB"} value={playerData.decisive_actions.atBats} />
                      <BaseballStatIcon name={"R"} value={playerData.decisive_actions.runs} />
                      <BaseballStatIcon name={"SB"} value={playerData.decisive_actions.stolenBases} />
                      <BaseballStatIcon name={"BB"} value={playerData.decisive_actions.walks} />
                      <BaseballStatIcon name={"K"} value={playerData.decisive_actions.battingStrikeouts} />
                      <BaseballStatIcon name={"HBP"} value={playerData.decisive_actions.hitsByPitch} />
                    </div>
                  )}
                  {(position === "RP" || position === "SP") && (
                    <div className={"grid grid-cols-6 gap-6"}>
                      <BaseballStatIcon name={"IP"} value={playerData.decisive_actions.inningsPitched.toFixed(1)} />
                      <BaseballStatIcon name={"ERA"} value={playerData.decisive_actions.era.toFixed(2)} />
                      <BaseballStatIcon name={"W"} value={playerData.decisive_actions.wins} />
                      <BaseballStatIcon name={"H"} value={playerData.decisive_actions.holds} />
                      <BaseballStatIcon name={"S"} value={playerData.decisive_actions.saves} />
                      <BaseballStatIcon name={"K"} value={playerData.decisive_actions.strikeouts} />
                      <BaseballStatIcon name={"1B"} value={playerData.decisive_actions.singlesAllowed} />
                      <BaseballStatIcon name={"2B"} value={playerData.decisive_actions.doublesAllowed} />
                      <BaseballStatIcon name={"3B"} value={playerData.decisive_actions.triplesAllowed} />
                      <BaseballStatIcon name={"HR"} value={playerData.decisive_actions.homeRunsAllowed} />
                      <BaseballStatIcon name={"BB"} value={playerData.decisive_actions.walksAllowed} />
                      <BaseballStatIcon name={"HBP"} value={playerData.decisive_actions.hbpAllowed} />
                    </div>
                  )}
                </div>
              )}

              {sport === sorareBasketball && playerData?.decisive_actions?.points !== undefined && (
                <div className={"w-full md:w-5/12 bg-white flex flex-col space-y-6 rounded-lg items-center justify-center p-4"}>
                  <div className={"grid grid-cols-4 gap-6"}>
                    <BaseballStatIcon name={"PPG"} value={playerData.decisive_actions.points.toFixed(1)} />
                    <BaseballStatIcon name={"RPG"} value={playerData.decisive_actions.rebounds.toFixed(1)} />
                    <BaseballStatIcon name={"APG"} value={playerData.decisive_actions.assists.toFixed(1)} />
                    <BaseballStatIcon name={"SPG"} value={playerData.decisive_actions.steals.toFixed(1)} />
                    <BaseballStatIcon name={"BPG"} value={playerData.decisive_actions.blocks.toFixed(1)} />
                    <BaseballStatIcon name={"3PPG"} value={playerData.decisive_actions.threePts.toFixed(1)} />
                    <BaseballStatIcon name={"TOPG"} value={playerData.decisive_actions.turnovers.toFixed(1)} />
                    <BaseballStatIcon name={"FFPM"} value={playerData.decisive_actions.fantasyPointsPerMinutes.toFixed(1)} />
                  </div>
                </div>
              )}
            </div>

            <div className={"w-full lg:w-5/12 rounded-lg bg-white"}>
              <ResponsiveContainer>
                <BarChart data={playerData.score_chart}>
                  <Bar dataKey="count" fill="white" radius={[2, 2, 2, 2]}>
                    <LabelList
                      dataKey="count"
                      position="insideCenter"
                      style={{
                        fill: "white",
                        fontSize: "10px",
                        fontWeight: 700,
                      }}
                    />
                    {playerData.score_chart.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[entry.label]} />
                    ))}
                  </Bar>
                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="label"
                    style={{
                      fontSize: "0.65rem",
                      fontFamily: "Inter, sans-serif",
                      marginTop: "1px",
                      fill: "#292929",
                      fontWeight: 500,
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        <PlayerStatsGraph
          sport={sport}
          data={playerData.stat_chart}
          playerId={playerId}
          position={position}
          period={period}
          changePeriod={(p) => {
            setPeriod(p);
            fetchInfo(undefined, p);
          }}
        />
        {t1OrBelow(props.user?.tier) && sport === sorareFootball && (
          <UpgradeLimitBox
            className={"bg-white bg-upgrade-bg-decisive bg-cover h-upgrade-banner-l w-full"}
            title={"Want more insights?"}
            description={"Become a Star member to unlock statistics by team, competition and position on the pitch"}
          />
        )}

        <div>
          <PlayerStatsLines
            {...props}
            stats={playerData.stats}
            gameStat={true}
            player={props.info.player}
            position={position}
            newMatrix={newMatrix}
          />
        </div>
      </div>
    </div>
  );
}

export default withUser(PlayerScoresTab);
