import IconBellCircle from "../../img/icons-bell-circle.svg";
import IconBellActiveCircle from "../../img/icons-bell-active-circle.svg";
import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import NotificationSettingsModal from "./notificationSettingsModal";
import { errorCatcher } from "./errors";

function NotificationBubble(props) {
  const { player, bg, hasNotifications } = props;
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsActive(hasNotifications !== undefined ? hasNotifications : false);
  }, [player?.PlayerId, hasNotifications]);

  const reloadNotificationStatus = () => {
    props
      .fetch(`/apiv2/notifications/cards/offers/players/${player?.PlayerId}/hasPriceAlerts`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setIsActive(res.hasPriceAlerts !== undefined ? res.hasPriceAlerts : false);
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div
      title={isActive ? `Click to modify "${player.DisplayName}" price alerts` : `Click to add price alerts to "${player.DisplayName}"`}
      className={`hover:opacity-80 ${bg ? "rounded-full w-full border border-" + bg : ""} ${props.className ? props.className : ""}`}
    >
      <img
        className={"cursor-pointer h-full w-full"}
        onClick={() => setOpen(true)}
        src={isActive ? IconBellActiveCircle : IconBellCircle}
      />
      {open && (
        <NotificationSettingsModal
          player={player}
          close={() => {
            setOpen(false);
          }}
          open={open}
          onSaveSuccess={reloadNotificationStatus}
        />
      )}
    </div>
  );
}

export default withUser(NotificationBubble);
