import React, { useEffect, useState } from "react";
import EmptyPlayer from "../../img/empty-player.svg";
import ConceptPicker from "../../img/concept-picker.svg";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { positions_objects } from "../util/positions";
import {
  challenger_europe_leagues_objects,
  champion_america_leagues_objects,
  champion_asia_leagues_objects,
  champion_europe_leagues_objects,
  leagues_with_divisions_objects_not_all,
  so5_division_types_objects,
  so5_division_types_objects_no_rp,
  so5_leagues_objects,
} from "../util/leagues";
import { PlayerStatusSelector } from "../util/playingStatus";
import { teams } from "../util/teams";
import AgeRange, { DEFAULT_AGE_RANGE } from "../util/ageRange";
import PriceFilter from "../util/priceRange";
import ScoreFilter from "../util/scoreFilter";
import { Button } from "../util/button";
import SDLoading from "../util/SDLoading";
import PlayerSearchResult from "../players/playerSearchResult";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactTooltip } from "../util/tooltip.js";
import { withUser } from "../../userContext";
import ScoreAverage from "../decision/scoreAverage";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatPrice } from "../util/formatMoney";
import Remove from "../../img/remove-card-lineup.svg";
import { capitalize } from "../util/capitalize";
import { getNextGameweeksObjects } from "../util/nextGws";
import CombinedStatsGraph from "./combinedStatsGraph";
import { formatSO5LeagueToId } from "../util/formatSO5LeagueToId";
import GreenButton from "../util/greenButton";
import LineupPriceGraph from "./lineupPriceGraph";
import StackedStatsGraph from "./stackedStatsGraph";
import { errorCatcher } from "../util/errors";
import { isFree } from "../util/handleSubscriptionTier";
import Locker from "../../img/brand-close-locker-nude.svg";
import { GameweekFilter } from "../players/gameweekFilter";
import BoxWithToggle from "../util/boxWithToggle";
import NBALineupPointsRemaining from "../decision/NBARemainingPoints";

function CardPlaceHolder(props) {
  const [showRemove, setShowRemove] = useState(false);
  const [power, setPower] = useState(props.power);

  useEffect(() => {
    if (props.result) {
      //setPower(default_power)
    }
  }, [props.result]);

  useEffect(() => {
    props.setPower(power);
  }, [power]);

  useEffect(() => {
    setPower(props.power);
  }, [props.power]);

  let padding = "py-8";
  if (props.capped) {
    padding = "py-4";
  }

  if (props.result) {
    let card = props.result.card;
    let r = props.result.r;
    let scarcity = card?.Scarcity.toLowerCase().replace(" ", "_");
    let avg = formatRealTimeAverage(r["real_time_" + scarcity], props.user.preferredUnit);
    let bmp = formatBestMarketPrice(r["bmp_" + scarcity], props.user.preferredUnit);
    if (scarcity === "super_rare") {
      bmp = formatBestMarketPrice(r["bmp_sr"], props.user.preferredUnit);
    } else if (scarcity === "unique") {
      bmp = formatBestMarketPrice(r["bmp_u"], props.user.preferredUnit);
    }
    let bg_url = "";
    if (card.PictureUrl) {
      bg_url = card.PictureUrl;
    }
    let max_power = 15;
    if (scarcity === "super_rare") {
      max_power = 35;
    } else if (scarcity === "unique") {
      max_power = 55;
    }
    let bgCaptain = "bg-grey-e5";
    if (props.isCaptain) {
      bgCaptain = "bg-limited";
    }
    return (
      <div className={"space-y-4 w-card-in-header"}>
        <div
          className={"w-card-in-header h-card-in-header bg-cover flex flex-col z-0"}
          style={{ backgroundImage: "url('" + bg_url + "')" }}
          onMouseOver={() => setShowRemove(true)}
          onMouseLeave={() => setShowRemove(false)}
        >
          {showRemove && (
            <div
              className="flex z-10 cursor-pointer transform w-10 -mt-4 -mx-2 justify-end"
              onClick={() => {
                props.remove();
                setShowRemove(false);
              }}
            >
              <img className={"self-end"} src={Remove} />
            </div>
          )}
        </div>
        <div className={"flex flex-col space-y-2 w-full"}>
          {!props.capped && (
            <div className={"flex flex-row space-x-4 w-11/12 mx-auto justify-between"}>
              <div className="relative rounded-md w-5/12 self-center">
                <input
                  className="focus:border-white text-center focus:outline-none block w-full py-1 rounded-md pl-2 font-semibold text-xs border-white"
                  type={"number"}
                  min={0}
                  max={max_power}
                  value={power.toFixed(1)}
                  step={0.5}
                  onChange={(v) => setPower(parseFloat(v.target.value))}
                />
                <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                  <span className="font-semibold text-xs">%</span>
                </div>
              </div>
              <div
                className={"flex flex-row self-center rounded-full w-6 h-6 items-center cursor-pointer justify-center " + bgCaptain}
                onClick={props.setCaptain}
              >
                {props.isCaptain && <p className={"text-sm font-semibold text-brand-black self-center"}>C</p>}
                {!props.isCaptain && <p className={"text-sm font-semibold text-textGrey3 self-center"}>C</p>}
              </div>
            </div>
          )}

          <div className={"flex flex-row space-x-2 justify-center bg-white py-2 self-center rounded-lg w-full"}>
            <ScoreAverage avg={r.scores_5.SorareAverage} played={r.scores_5.SorareApps} period={5} />
            <ScoreAverage avg={r.scores_15.SorareAverage} played={r.scores_15.SorareApps} period={15} />
            <ScoreAverage avg={r.scores_40.AverageNoDnp} played={r.scores_40.GamesPlayed} period={40} />
          </div>
          <div className={"flex flex-row space-x-1 w-full"}>
            <div className={"w-6/12 bg-white text-center space-y-1 flex flex-col py-2 rounded-lg"}>
              <p className={"font-semibold text-xxs text-textGrey3"}>Floor price</p>
              <p className={"font-semibold text-sm text-" + scarcity.replace("_", "-")}>{bmp}</p>
            </div>
            <div className={"w-6/12 bg-white text-center space-y-1 flex flex-col py-2 rounded-lg"}>
              <p className={"font-semibold text-xxs text-textGrey3"}>Recent average</p>
              <p className={"font-semibold text-sm text-" + scarcity.replace("_", "-")}>{avg}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"space-y-4"}>
        <div
          className={
            "space-y-4 cursor-pointer w-card-in-header h-card-in-header bg-grey-e0 text-textGrey3 hover:text-brand hover:bg-brand-pastel hover:border hover:border-brand " +
            "flex flex-col items-center justify-center align-middle mx-auto rounded-lg"
          }
          onClick={() => props.setPosition(props.position)}
        >
          <img src={ConceptPicker} />
          <p className={"text-xs font-semibold uppercase self-center"}>{props.position}</p>
        </div>
        <div className={"flex flex-col w-card-in-header items-center bg-white rounded-lg px-4 " + padding}>
          <img src={EmptyPlayer} />
          <p className={"w-11/12 text-center text-textGrey3 text-xs font-medium"}>Pick a player to discover their stats</p>
        </div>
      </div>
    );
  }
}

function ConceptBuilder(props) {
  const [goalkeeper, setGoalkeeper] = useState(undefined);
  const [defender, setDefender] = useState(undefined);
  const [midfielder, setMidfielder] = useState(undefined);
  const [forward, setForward] = useState(undefined);
  const [extra, setExtra] = useState(undefined);
  const [captain, setCaptain] = useState("");

  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState([]);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [teamMap, setTeamMap] = useState({});
  const [nextGameweeks, setGameweeks] = useState([]);
  const [scarcitiesOptions, setScarcitiesOptions] = useState([{ value: "LIMITED", name: "Limited" }]);
  const [leaguesOptions, setLeaguesOptions] = useState(leagues_with_divisions_objects_not_all);
  const [scarcityFilter, setScarcityFilter] = useState([]);
  const [league, setLeague] = useState([]);
  const [position, setPosition] = useState([]);
  const [playingStatus, setPlayingStatus] = useState("all");
  const [clubs, setClubs] = useState([]);
  const [bmpFilter, setBmpFilter] = useState(false);
  const [avgFilter, setAvgFilter] = useState(false);
  const [scoreFilters, setScoreFilters] = useState({
    l5: {
      enabled: false,
      average_range: [0, 100],
      decisive_range: [0, 100],
      all_around_range: [-20, 100],
    },
    l15: {
      enabled: false,
      average_range: [0, 100],
      decisive_range: [0, 100],
      all_around_range: [-20, 100],
    },
    l40: {
      enabled: false,
      average_range: [0, 100],
      decisive_range: [0, 100],
      all_around_range: [-20, 100],
    },
  });
  const [bmpRange, setBmpRange] = useState([0, 1]);
  const [avgRange, setAvgRange] = useState([0, 1]);
  const [ageRange, setAgeRange] = useState(DEFAULT_AGE_RANGE);
  const [u23Only, setU23Only] = useState(false);
  const [gwFilter, setGwFilter] = useState(false);
  const [gwValue, setGwValue] = useState("");
  const [SO5LeagueValue, setSO5LeagueValue] = useState("Cap 240");
  const [SO5DivisionValue, setSO5DivisionValue] = useState("Limited");
  const [period, setPeriod] = useState("3m");
  const [multiPlayerGraph, setMultiPlayersGraph] = useState([]);
  const [lineupPriceGraph, setLineupPriceGraph] = useState([]);
  const [combinedGraph, setCombinedGraph] = useState([]);
  const [showZero, setShowZero] = useState(false);
  const [showFiveOnly, setFiveOnly] = useState(true);
  const [inWatchlist, setInWatchlist] = useState(false);
  const [inGallery, setInGallery] = useState(false);
  const [playerFilter, setPlayerFilter] = useState("");
  const [powers, setPowers] = useState([0, 0, 0, 0, 0]);
  const [info, setInfo] = useState({ rewards: 0, gws: 0, all_played: 0 });
  const [priceGraphs, setPriceGraphs] = useState(false);

  useEffect(() => {
    if (SO5DivisionValue === "Limited") {
      setScarcitiesOptions([{ value: "LIMITED", name: "Limited" }]);
    } else if (SO5DivisionValue === "Rare") {
      setScarcitiesOptions([{ value: "RARE", name: "Rare" }]);
    } else if (SO5DivisionValue === "Rare Pro") {
      setScarcitiesOptions([
        { value: "RARE", name: "Rare" },
        { value: "SUPER RARE", name: "Super rare" },
      ]);
    } else if (SO5DivisionValue === "Super rare") {
      if (SO5LeagueValue === "Capped") {
        setScarcitiesOptions([{ value: "SUPER RARE", name: "Super rare" }]);
      } else {
        setScarcitiesOptions([
          { value: "RARE", name: "Rare" },
          {
            value: "SUPER RARE",
            name: "Super rare",
          },
          { value: "UNIQUE", name: "Unique" },
        ]);
      }
    } else if (SO5DivisionValue === "Unique") {
      setScarcitiesOptions([
        { value: "SUPER RARE", name: "Super rare" },
        { value: "UNIQUE", name: "Unique" },
      ]);
    }
    reset();
  }, [SO5DivisionValue]);

  useEffect(() => {
    if (SO5LeagueValue === "Global All-Star") {
      setLeaguesOptions(leagues_with_divisions_objects_not_all);
    } else if (SO5LeagueValue === "Global U23") {
      setLeaguesOptions(leagues_with_divisions_objects_not_all);
      setU23Only(true);
    } else if (SO5LeagueValue === "Champion Europe") {
      setLeague(["Champion Europe"]);
      setLeaguesOptions(champion_europe_leagues_objects);
    } else if (SO5LeagueValue === "Challenger Europe") {
      setLeague(["Challenger Europe"]);
      setLeaguesOptions(challenger_europe_leagues_objects);
    } else if (SO5LeagueValue === "America") {
      setLeague(["America"]);
      setLeaguesOptions(champion_america_leagues_objects);
    } else if (SO5LeagueValue === "Asia") {
      setLeague(["Asia"]);
      setLeaguesOptions(champion_asia_leagues_objects);
    }
    reset();
  }, [SO5LeagueValue]);

  const searchPlayers = () => {
    setLoading(true);
    setResults([]);
    setDisplayedResults([]);
    let sc = [...scarcityFilter];
    console.log(scarcityFilter, scarcitiesOptions);
    if (scarcityFilter.length === 0) {
      scarcitiesOptions.map((s) => sc.push(s.value));
    }
    props
      .fetch("/apiv2/decision/concept", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scarcity: sc,
          position: position,
          league: league,
          bmp_range: bmpFilter ? [parseFloat(bmpRange[0]), parseFloat(bmpRange[1])] : [],
          avg_range: avgFilter ? [parseFloat(avgRange[0]), parseFloat(avgRange[1])] : [],
          u23: u23Only,
          age_range: ageRange,
          l5: scoreFilters.l5,
          l15: scoreFilters.l15,
          l40: scoreFilters.l40,
          playing_status: playingStatus,
          teams: clubs,
          gw_number: gwValue === "" ? 0 : parseInt(gwValue),
          gw_filter: gwFilter,
          player_filter: playerFilter,
          watchlist: inWatchlist,
          five_only: showFiveOnly,
          in_gallery: inGallery,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.players === null) {
          setResults([]);
          setDisplayedResults([]);
        } else {
          setResults(res.players);
          setDisplayedResults(res.players.slice(0, 50));
        }
        setNotifications(res.notifications);
        setTeamMap(res.team_map);
        let gws = [];
        res.next_gameweeks.map((gw, index) => {
          gws.push({
            name: gw.DisplayName,
            value: gw.GwNumber,
            disabled: isFree(props.user.tier) && index !== 0,
            photo: Locker,
          });
        });
        setGameweeks(gws);
        setLoading(false);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  useEffect(() => {
    async function fetchGw() {
      let nextGws = await getNextGameweeksObjects(props);
      setGameweeks(nextGws);
    }

    fetchGw();
  }, []);

  const getFirstEdition = (p, s) => {
    return props
      .fetch("/apiv2/players/firstEdition/" + p + "/" + s, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        ReactTooltip.rebuild();
        return res;
      });
  };

  const getGraphStats = (pid, s, f) => {
    if (s === undefined) s = showZero;
    if (f === undefined) f = showFiveOnly;
    const divisionId = formatSO5LeagueToId(SO5LeagueValue, SO5DivisionValue);
    props
      .fetch("/apiv2/decision/lineupStatsConcept", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          players: pid,
          period: period,
          show_zero: s,
          show_five_only: f,
          division_id: divisionId,
          powers: powers,
          captain: captain,
          scarcity: [goalkeeper, defender, midfielder, forward, extra].map((p) => {
            if (p) {
              return p.card.Scarcity;
            } else return "";
          }),
          currency: props.user.preferredUnit,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setMultiPlayersGraph(res.players_graph);
        setLineupPriceGraph(res.price_graph);
        setCombinedGraph(res.combined_graph);
        setInfo({
          rewards: res.rewards,
          gws: res.total_gameweek,
          all_played: res.gameweek_all_played,
        });
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  const pickPlayer = async (s, r) => {
    let card = await getFirstEdition(r.player.PlayerId, s);
    r = { r, card: card, scarcity: s };
    let scarcity = card?.Scarcity.toLowerCase().replace(" ", "_");
    let default_power = 5;
    if (scarcity === "super_rare") {
      default_power = 25;
    } else if (scarcity === "unique") {
      default_power = 45;
    }
    if (SO5LeagueValue === "Cap 240") {
      default_power = 0;
    }
    let p = r.r.player.Position;
    let newPowers = powers;
    if (p === "Goalkeeper") {
      if (powers[0] === 0) {
        newPowers[0] = default_power;
        setPowers(newPowers);
      }
      setGoalkeeper(r);
    } else if (p === "Defender") {
      if (defender) {
        if (powers[4] === 0) {
          newPowers[4] = default_power;
          setPowers(newPowers);
        }
        setExtra(r);
      } else {
        if (powers[1] === 0) {
          newPowers[1] = default_power;
          setPowers(newPowers);
        }
        setDefender(r);
      }
    } else if (p === "Midfielder") {
      if (midfielder) {
        if (powers[4] === 0) {
          newPowers[4] = default_power;
          setPowers(newPowers);
        }
        setExtra(r);
      } else {
        if (powers[2] === 0) {
          newPowers[2] = default_power;
          setPowers(newPowers);
        }
        setMidfielder(r);
      }
    } else if (p === "Forward") {
      if (forward) {
        if (powers[4] === 0) {
          newPowers[4] = default_power;
          setPowers(newPowers);
        }
        setExtra(r);
      } else {
        if (powers[3] === 0) {
          newPowers[3] = default_power;
          setPowers(newPowers);
        }
        setForward(r);
      }
    }
  };

  const reset = () => {
    setGoalkeeper(undefined);
    setDefender(undefined);
    setMidfielder(undefined);
    setForward(undefined);
    setExtra(undefined);
    setPowers([0, 0, 0, 0, 0]);
    setCaptain("");
  };
  const changePower = (p, i) => {
    let curPowers = powers;
    powers[i] = p;
    setPowers(curPowers);
  };

  useEffect(() => {
    if (captain !== "") {
      getGraphStats(pids);
    }
  }, [captain]);

  let totalBuyNowPrice = 0.0;
  let recentAvg = 0.0;
  let nonBuyable = 0;

  const players = [goalkeeper, defender, midfielder, forward, extra].map((p) => {
    if (p !== undefined) {
      let scarcity = p?.card?.Scarcity.toLowerCase().replace(" ", "_");
      let bmp = p?.r["bmp_" + scarcity];
      if (scarcity === "super_rare") {
        bmp = p?.r["bmp_sr"];
      } else if (scarcity === "unique") {
        bmp = p?.r["bmp_u"];
      }
      let avg = p?.r["real_time_" + scarcity];
      if (bmp !== undefined) {
        if (bmp.Price <= 0) nonBuyable++;
        else {
          if (props.user.preferredUnit !== "eth") {
            totalBuyNowPrice += bmp["Price" + capitalize(props.user.preferredUnit)];
          } else {
            totalBuyNowPrice += bmp.Price;
          }
        }
      } else {
        nonBuyable++;
      }
      if (avg !== undefined) {
        if (props.user.preferredUnit !== "eth") {
          recentAvg += avg[capitalize(props.user.preferredUnit) + "Average"];
        } else {
          recentAvg += avg.Average;
        }
      }
      return p.r?.player;
    }
  });

  const atLeastOne = goalkeeper || defender || midfielder || forward || extra;
  const pids = [goalkeeper, defender, midfielder, forward, extra].map((p) => {
    if (p !== undefined) {
      return p.r?.player.PlayerId;
    }
  });

  let points = 0;
  [goalkeeper, defender, midfielder, forward, extra].map((p) => {
    if (p !== undefined) {
      points += p.r?.scores_15.SorareAverage;
    }
  });

  const capped = SO5LeagueValue.includes("Cap 2");

  const activeClass = "rounded border border-brand text-brand bg-brand-pastel px-4 py-2 font-semibold text-sm focus:outline-none";
  const inactiveClass =
    "bg-focus rounded border border-grey-e5 px-4 py-2 font-semibold text-sm hover:border-brand hover:bg-brand-pastel hover:text-brand focus:outline-none";

  useEffect(() => {
    if (goalkeeper || defender || midfielder || forward || extra) {
      getGraphStats(pids);
    }
  }, [goalkeeper, defender, midfielder, forward, extra, period]);

  let divisionTypes = so5_division_types_objects_no_rp;
  if (!capped) {
    divisionTypes = so5_division_types_objects;
  }

  let cap = 0;
  if (SO5LeagueValue.includes("240")) {
    cap = 240;
  } else if (SO5LeagueValue.includes("270")) {
    cap = 270;
  } else if (SO5LeagueValue.includes("220")) {
    cap = 220;
  }

  console.log(scarcityFilter, scarcitiesOptions);

  return (
    <div className={"space-y-4 mx-6 4xl:mx-auto 4xl:w-10/12 pb-8"}>
      <div className={"mt-4 flex flex-row justify-between"}>
        <div>
          <p className={"font-semibold text-xl text-brand font-headers"}>Concept lineup builder (beta)</p>
        </div>
        <div className={"flex flex-row space-x-4"}>
          <SelectSearch
            options={[
              { name: "Cap 240", value: "Cap 240" },
              { name: "Cap 220", value: "Cap 220" },
              { name: "Cap 270", value: "Cap 270" },
            ].concat(so5_leagues_objects)}
            value={SO5LeagueValue}
            onChange={(v) => setSO5LeagueValue(v)}
          />
          <SelectSearch options={divisionTypes} value={SO5DivisionValue} onChange={(v) => setSO5DivisionValue(v)} />
        </div>
      </div>
      <div className={"flex flex-row justify-center space-x-4"}>
        <div className={"flex flex-row space-x-4 "}>
          <CardPlaceHolder
            user={props.user}
            position={"Goalkeeper"}
            result={goalkeeper}
            power={powers[0]}
            setPower={(p) => changePower(p, 0)}
            capped={SO5LeagueValue === "Capped"}
            isCaptain={captain === "goalkeeper"}
            setCaptain={() => setCaptain("goalkeeper")}
            remove={() => setGoalkeeper(undefined)}
            setPosition={(s) => setPosition([s])}
          />
          <CardPlaceHolder
            user={props.user}
            position={"Defender"}
            result={defender}
            setPower={(p) => changePower(p, 1)}
            isCaptain={captain === "defender"}
            setCaptain={() => setCaptain("defender")}
            power={powers[1]}
            capped={SO5LeagueValue === "Capped"}
            remove={() => setDefender(undefined)}
            setPosition={(s) => setPosition([s])}
          />
          <CardPlaceHolder
            user={props.user}
            position={"Midfielder"}
            result={midfielder}
            setPower={(p) => changePower(p, 2)}
            isCaptain={captain === "midfielder"}
            setCaptain={() => setCaptain("midfielder")}
            power={powers[2]}
            capped={SO5LeagueValue === "Capped"}
            remove={() => setMidfielder(undefined)}
            setPosition={(s) => setPosition([s])}
          />
          <CardPlaceHolder
            user={props.user}
            position={"Forward"}
            result={forward}
            capped={SO5LeagueValue === "Capped"}
            isCaptain={captain === "forward"}
            setPower={(p) => changePower(p, 3)}
            remove={() => setForward(undefined)}
            setPosition={(s) => setPosition([s])}
            setCaptain={() => setCaptain("forward")}
            power={powers[3]}
          />
          <CardPlaceHolder
            user={props.user}
            position={"Extra"}
            result={extra}
            isCaptain={captain === "extra"}
            setCaptain={() => setCaptain("extra")}
            setPower={(p) => changePower(p, 4)}
            power={powers[4]}
            capped={SO5LeagueValue === "Capped"}
            remove={() => setExtra(undefined)}
            setPosition={() => setPosition(["Defender", "Midfielder", "Forward"])}
          />
        </div>
        <div className={"bg-white text-center pt-2 rounded-lg flex flex-col space-y-4"}>
          {capped && (
            <div className={"px-4"}>
              <NBALineupPointsRemaining cap={cap} points={points} />
            </div>
          )}
          <p className={"font-semibold text-sm"}>Lineup metrics</p>
          <div className={"bg-grey-f2 space-y-1 rounded-lg p-3 flex flex-col items-center w-11/12 mx-auto"}>
            <p className={"text-lg font-semibold"}>{formatPrice(totalBuyNowPrice, props.user.preferredUnit)}</p>
            <p className={"text-xs font-medium"}>Lineup floor price</p>
            {nonBuyable > 0 && <p className={"text-xxs font-semibold"}>({nonBuyable} not on the market)</p>}
          </div>
          <div className={"bg-grey-f2 space-y-1 rounded-lg p-3 flex flex-col items-center w-11/12 mx-auto"}>
            <p className={"text-lg font-semibold"}>{formatPrice(recentAvg, props.user.preferredUnit)}</p>
            <p className={"text-xs font-medium"}>Lineup recent price average</p>
          </div>
          {!showFiveOnly && (
            <div className={"bg-grey-f2 space-y-1 rounded-lg p-3 flex flex-col items-center w-11/12 mx-auto"}>
              <p className={"text-lg font-semibold"}>
                {info.rewards}{" "}
                <span className={"font-medium text-sm"}>{info.gws > 0 && "(" + ((info.rewards * 100) / info.gws).toFixed(0) + "%)"}</span>
              </p>
              <p className={"text-xs font-medium"}>Rewards over the past {info.gws} gameweeks</p>
            </div>
          )}
          {showFiveOnly && (
            <div className={"bg-grey-f2 space-y-1 rounded-lg p-3 flex flex-col items-center w-11/12 mx-auto"}>
              <p className={"text-lg font-semibold"}>
                {info.rewards}{" "}
                <span className={"font-medium text-sm"}>
                  {info.all_played > 0 && "(" + ((info.rewards * 100) / info.all_played).toFixed(0) + "%)"}
                </span>
              </p>
              <p className={"text-xs font-medium"}>Rewards when all players play</p>
            </div>
          )}
          <div className={"bg-grey-f2 space-y-1 rounded-lg p-3 flex flex-col items-center w-11/12 mx-auto"}>
            <p className={"text-lg font-semibold"}>
              {info.all_played}{" "}
              <span className={"font-medium text-sm"}>{info.gws > 0 && "(" + ((info.all_played * 100) / info.gws).toFixed(0) + "%)"}</span>
            </p>
            <p className={"text-xs font-medium"}>Gameweeks where all players played</p>
          </div>
          {!capped && (
            <div className={"w-11/12 mx-auto"}>
              <GreenButton label={"Apply power"} onClick={() => getGraphStats(pids)} />
            </div>
          )}
        </div>
      </div>

      {atLeastOne && (
        <div className={"flex flex-row space-x-8"}>
          <div className={"w-full space-y-2"}>
            <div className={"flex flex-row space-x-4 justify-start"}>
              <div className={"text-base space-x-2"}>
                <button className={period === "1m" ? activeClass : inactiveClass} onClick={() => setPeriod("1m")}>
                  1m
                </button>
                <button className={period === "3m" ? activeClass : inactiveClass} onClick={() => setPeriod("3m")}>
                  3m
                </button>
                <button className={period === "6m" ? activeClass : inactiveClass} onClick={() => setPeriod("6m")}>
                  6m
                </button>
                <button className={period === "ytd" ? activeClass : inactiveClass} onClick={() => setPeriod("ytd")}>
                  YTD
                </button>
                <button className={period === "1y" ? activeClass : inactiveClass} onClick={() => setPeriod("1y")}>
                  1y
                </button>
                <button className={period === "all" ? activeClass : inactiveClass} onClick={() => setPeriod("all")}>
                  All
                </button>
              </div>
              <div className={"flex flex-row space-x-4 self-end"}>
                <div className={"bg-white py-1.5 rounded-lg"}>
                  <BoxWithToggle
                    isActive={showZero}
                    label={"Show gameweeks without games"}
                    setActive={() => {
                      getGraphStats(pids, !showZero);
                      setShowZero(!showZero);
                    }}
                  />
                </div>
                <div className={"bg-white py-1.5 rounded-lg"}>
                  <BoxWithToggle
                    isActive={showFiveOnly}
                    label={"Show only gameweeks where all played"}
                    setActive={() => {
                      getGraphStats(pids, undefined, !showFiveOnly);
                      setFiveOnly(!showFiveOnly);
                    }}
                  />
                </div>
                <div className={"bg-white py-1.5 rounded-lg"}>
                  <BoxWithToggle
                    isActive={priceGraphs}
                    label={"Show price details graph"}
                    setActive={() => {
                      setPriceGraphs(!priceGraphs);
                    }}
                  />
                </div>
              </div>
            </div>

            <CombinedStatsGraph
              keys={[goalkeeper, defender, midfielder, forward, extra]}
              combined={true}
              data={combinedGraph}
              setPeriod={setPeriod}
              players={players}
              capped={capped}
              period={period}
              scarcity={() => {
                return SO5DivisionValue;
              }}
              powers={powers}
            />
            {priceGraphs && (
              <div className={"flex flex-row space-x-4"}>
                <div className={"w-6/12"}>
                  <LineupPriceGraph
                    keys={[goalkeeper, defender, midfielder, forward, extra]}
                    data={lineupPriceGraph}
                    period={period}
                    players={players}
                  />
                </div>
                <div className={"w-6/12"}>
                  <StackedStatsGraph
                    keys={[goalkeeper, defender, midfielder, forward, extra]}
                    data={multiPlayerGraph}
                    period={period}
                    players={players}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        <div>
          <div className={"space-y-2 mb-4"}>
            <div className={"2xl:w-10/12 md:grid md:grid-cols-2 md:gap-2 lg:grid-cols-3 xl:grid-cols-4 space-y-1 md:space-y-0"}>
              <div className={"z-40"}>
                <SelectSearch
                  multiple
                  closeOnSelect={false}
                  options={scarcitiesOptions}
                  value={scarcityFilter}
                  printOptions={"on-focus"}
                  filterOptions={fuzzySearch}
                  placeholder="Select one scarcity or more"
                  onChange={(v) => setScarcityFilter(v)}
                />
              </div>
              <div className={"z-50"}>
                <SelectSearch
                  multiple
                  closeOnSelect={false}
                  options={positions_objects}
                  value={position}
                  printOptions={"on-focus"}
                  filterOptions={fuzzySearch}
                  placeholder="Select one position or more"
                  onChange={(v) => setPosition(v)}
                />
              </div>
              <div className={"z-40"}>
                <SelectSearch
                  multiple
                  closeOnSelect={false}
                  options={leaguesOptions}
                  value={league}
                  printOptions={"on-focus"}
                  filterOptions={fuzzySearch}
                  placeholder="Select one league or more"
                  onChange={(v) => setLeague(v)}
                />
              </div>
              <div className={"z-40"}>
                <PlayerStatusSelector playingStatus={playingStatus} onChange={(v) => setPlayingStatus(v)} tier={props.user.tier} />
              </div>
              <div className={"z-30"}>
                <SelectSearch
                  options={teams}
                  onChange={(v) => setClubs(v)}
                  search
                  multiple
                  value={clubs}
                  closeOnSelect={false}
                  printOptions={"on-focus"}
                  filterOptions={fuzzySearch}
                  placeholder="Select one club or more"
                />
              </div>
              <div className={"z-40"}>
                <AgeRange range={ageRange} setU23={() => setU23Only(!u23Only)} u23={u23Only} setRange={(ar) => setAgeRange(ar)} />
              </div>
              <PriceFilter
                tip={scarcityFilter.length === 0 && "(pick scarcities first)"}
                watchlist={true}
                bmp={bmpFilter}
                bmpRange={bmpRange}
                avg={avgFilter}
                avgRange={avgRange}
                tier={props.user.tier}
                updateFilters={async (c, cr, bmp, bmpr, avg, avgr, ubmp, uavg) => {
                  await setBmpFilter(bmp);
                  await setBmpRange(bmpr);
                  await setAvgFilter(avg);
                  await setAvgRange(avgr);
                }}
              />
              <ScoreFilter
                l5={scoreFilters.l5}
                l15={scoreFilters.l15}
                l40={scoreFilters.l40}
                setScoreFilters={async (f) => {
                  await setScoreFilters(f);
                }}
              />
            </div>
            <div className={"flex flex-col md:flex-row space-y-2 md:space-y-0 self-start md:space-x-8 md:self-center pt-2"}>
              <div>
                <input
                  className="focus:ring-brand block w-full py-2 rounded-md pl-4 shadow sm:text-sm border-grey-e5"
                  value={playerFilter}
                  placeholder={"Filter by player name"}
                  onKeyDown={(key) => {
                    if (key.key === "Enter") {
                      searchPlayers();
                    }
                  }}
                  onChange={(v) => setPlayerFilter(v.target.value)}
                />
              </div>
              <div className={"flex flex-row self-center space-x-4"}>
                <div className={"w-72"}>
                  <GameweekFilter
                    label={"Plays during gameweek"}
                    className={"z-20 w-full"}
                    filterByGw={gwFilter}
                    onChangeFilterByGw={() => setGwFilter(!gwFilter)}
                    gameweeks={nextGameweeks}
                    gw={gwValue}
                    onChangeGw={(v) => setGwValue(v)}
                  />
                </div>
                <div className={"self-end z-20 py-1.5 bg-white rounded-lg shadow"}>
                  <BoxWithToggle
                    label={"In your watchlist"}
                    isActive={inWatchlist}
                    setActive={() => {
                      setInWatchlist(!inWatchlist);
                    }}
                  />
                </div>
                <div className={"self-end z-20 py-1.5 bg-white rounded-lg shadow"}>
                  <BoxWithToggle
                    label={"In your gallery"}
                    isActive={inGallery}
                    setActive={() => {
                      setInGallery(!inGallery);
                    }}
                  />
                </div>
              </div>
              <div>
                <Button label={"Search"} onClick={() => searchPlayers()} />
              </div>
            </div>
          </div>
        </div>
        <div>
          {results !== null && (
            <InfiniteScroll
              next={() => setDisplayedResults(results.slice(0, displayedResults.length + 50))}
              hasMore={displayedResults.length < results.length}
              loader={SDLoading}
              dataLength={displayedResults.length}
            >
              <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4"}>
                {displayedResults.map((p) => {
                  return (
                    <PlayerSearchResult
                      pickScarcities={scarcitiesOptions}
                      pick={(s, r) => pickPlayer(s, r)}
                      teamMap={teamMap}
                      result={p}
                      showRank={false}
                      gw={gwValue}
                      notification={true}
                      notifications={notifications}
                    />
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
          {results.length === 0 && loading && <SDLoading />}
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(ConceptBuilder);
