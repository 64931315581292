import React from "react";

function FrequencyPicker(props) {
  const sameStyle = "text-center cursor-pointer w-full py-2 px-4 flex items-center justify-center ";
  const activeStyle = sameStyle + "text-white bg-brand";
  const inactiveStyle = sameStyle + "bg-white text-black";

  return (
    <div className="flex flex-col space-y-1.5 bg-white rounded-full p-1">
      <div className="relative w-full grid grid-cols-3 self-center">
        <div
          className={props.frequency === "72h" ? activeStyle + " rounded-full" : inactiveStyle + " rounded-full"}
          onClick={() => props.handleFrequency("72h")}
        >
          <p className="text-sm font-semibold text-center">72h access</p>
        </div>

        <div
          className={props.frequency === "month" ? activeStyle + " rounded-full" : inactiveStyle + " rounded-full"}
          onClick={() => props.handleFrequency("month")}
        >
          <p className="text-sm font-semibold text-center">Monthly</p>
        </div>

        <div
          className={(props.frequency === "year" ? activeStyle : inactiveStyle) + " rounded-full flex items-center space-x-2"}
          onClick={() => props.handleFrequency("year")}
        >
          <p className="text-sm font-semibold text-center">Yearly</p>
          <span
            className={
              "flex items-center justify-center text-[10px] font-semibold h-[18px] w-8 " +
              (props.frequency === "year" ? "bg-white text-primary rounded-md" : "bg-primary text-white rounded-md")
            }
          >
            -16%
          </span>
        </div>
      </div>
    </div>
  );
}

export default FrequencyPicker;
