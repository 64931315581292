import React, { useState } from "react";
import { abbrv_football_positions, positions } from "../util/positions";
import { detailedPositionAbreviations } from "../util/detailedFootballPositions";
import PopHoverWrapper from "../util/popHoverWrapper";
import { OppScoreModaleLegend } from "./footballLineupTableLegend";
import { LockerButton } from "../util/button";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";

function OppScore(props) {
  let opp = 0;
  if (props.entry) {
    const r = props.entry;
    const position = r?.card.Position;
    if (r.opposing_team_stats !== undefined) {
      if (position === "Goalkeeper") {
        opp = r.opposing_team_stats.gkAverage;
      } else if (position === "Defender") {
        opp = r.opposing_team_stats.defAverage;
      } else if (position === "Midfielder") {
        opp = r.opposing_team_stats.midAverage;
      } else if (position === "Forward") {
        opp = r.opposing_team_stats.fwdAverage;
      } else if (position === positions[0]) {
        if (r.player.Position === "Goalkeeper") {
          opp = r.opposing_team_stats.gkAverage;
        } else if (r.player.Position === "Defender") {
          opp = r.opposing_team_stats.defAverage;
        } else if (r.player.Position === "Midfielder") {
          opp = r.opposing_team_stats.midAverage;
        } else if (r.player.Position === "Forward") {
          opp = r.opposing_team_stats.fwdAverage;
        }
      }
    }
  } else {
    if (props.score) {
      opp = props.score;
    }
  }

  let floatSO5Score = opp;
  let scoreColorStart;
  if (floatSO5Score < 25) {
    scoreColorStart = "horrible-score";
  } else if (floatSO5Score < 33) {
    scoreColorStart = "bad-score";
  } else if (floatSO5Score < 45) {
    scoreColorStart = "ok-score";
  } else if (floatSO5Score < 53) {
    scoreColorStart = "good-score";
  } else if (floatSO5Score < 60) {
    scoreColorStart = "great-score";
  } else if (floatSO5Score <= 100) {
    scoreColorStart = "amazing-score";
  }
  scoreColorStart = scoreColorStart + "-start";
  let text = floatSO5Score.toFixed(0);
  if (floatSO5Score === -1 || floatSO5Score === 0) {
    scoreColorStart = "brand-black";
    text = "NA";
  }
  let size = "w-8 h-8 md:w-9 md:h-9 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8 2.5xl:w-9 2.5xl:h-9";
  if (props.size) {
    size = props.size;
  }
  let fontSize = "text-md md:text-base xl:text-sm 2xl:text-md 2.5xl:text-base font-semibold";
  if (props.fontSize) fontSize = props.fontSize;
  return (
    <div
      data-tip={props.tip ? "Opponent score" : undefined}
      className={"flex flex-row self-center " + size + " rounded border border-" + scoreColorStart}
    >
      <p className={"flex mx-auto self-center justify-center " + fontSize + " text-center text-" + scoreColorStart}>{text}</p>
    </div>
  );
}

const OppScoreLocker = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className={"flex justify-center items-center"}>
      <LockerButton onClick={() => setOpen(true)} type={"square"} />
      {open && <OppScoreModaleLegend open={open} close={() => setOpen(false)} />}
    </div>
  );
};

export const OppScoreWithDetails = (props) => {
  const { entry, detailed, cardPosition, locked, noHelpIcon } = props;
  const detailedOppScoresWithPosition = [];

  if (locked) {
    return <OppScoreLocker />;
  }

  if (
    detailed !== undefined &&
    detailed !== null &&
    entry.positions_adjusted !== undefined &&
    entry.positions_adjusted !== null &&
    entry.positions_adjusted.length > 0
  ) {
    detailedOppScoresWithPosition.push(
      ...entry.positions_adjusted.map((pos) => {
        return {
          position: detailedPositionAbreviations[pos.Position],
          count: pos.Count,
          info: detailed[pos.Position],
        };
      }),
    );
  }
  if (detailed !== undefined && detailed !== null && detailedOppScoresWithPosition.length === 0) {
    const abbrevPosition = abbrv_football_positions[cardPosition] || "UKN";
    detailedOppScoresWithPosition.push({
      position: abbrevPosition,
      count: -1,
      info: {
        Average: entry.opp_score_adjusted,
        Nb: -1,
        StdDev: -1,
      },
    });
  }

  if (detailedOppScoresWithPosition.length < 2) {
    return (
      <div className={"flex justify-center items-center gap-2 h-3/4 self-center"} title={"Opponent score"}>
        <OppScore score={entry.opp_score_adjusted} />
      </div>
    );
  }

  return (
    <PopHoverWrapper
      hoverTitle="Opponent score details"
      hover={
        <>
          <div className={"flex justify-center gap-2 h-full"}>
            {detailedOppScoresWithPosition.map((pos, i) => {
              return (
                <div key={i} className={"flex flex-col gap-1 justify-center self-center"}>
                  <OppScore score={pos.info.Average} />
                  <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>{pos.position}</p>
                </div>
              );
            })}
          </div>
        </>
      }
    >
      <div className={"relative flex justify-center items-center gap-2 h-3/4 self-center"} title={"Opponent score"}>
        <OppScore score={entry.opp_score_adjusted} />
        {(noHelpIcon === undefined || noHelpIcon === false) && (
          <div className={"absolute transform translate-x-full -right-2 top-1/2 -translate-y-1/2"}>
            <IconInfoHelp className={"fill-outline-variant my-auto"} />
          </div>
        )}
      </div>
    </PopHoverWrapper>
  );
};

export default OppScore;
