import React from "react";
import HelpIcon from "../../img/help.svg";
import HelpIconDark from "../../img/help-dark.svg";

function HelpTip(props) {
  return (
    <div className={"self-center cursor-pointer"} data-id={props.id} data-html={props.html} data-tip={props.tip}>
      {props.isDark ? <img src={HelpIconDark} style={{ maxWidth: "inherit" }} /> : <img src={HelpIcon} style={{ maxWidth: "inherit" }} />}
    </div>
  );
}

export default HelpTip;
