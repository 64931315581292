import React, { Component } from "react";
import { Anchor, Box, Grid, Image, Text } from "grommet";
import ResponsiveGrid from "../util/responsiveGrid";

class SO5SelectionLine extends Component {
  render() {
    const player = this.props.player;
    return (
      <Box>
        <Box direction={"row"}>
          <ResponsiveGrid columns={["60px", "flex", "xsmall"]} rows={["auto"]} fill={true} gap={{ column: "medium", row: "medium" }}>
            <Box style={{ borderRadius: "50%" }} height={"70px"}>
              <Anchor href={"/player/" + player.player.PlayerId} target={"_blank"}>
                <Image src={player.player.Avatar} width={"50px"} height={"70px"} style={{ borderRadius: "50%" }} />
              </Anchor>
            </Box>
            <Box direction={"row"} align={"center"}>
              <Grid columns={"auto"} rows={["auto", "auto"]}>
                <Anchor color={"#292929"} href={"/player/" + player.player.PlayerId} target={"_blank"} margin={{ right: "xsmall" }}>
                  <Text size={"large"} weight={500}>
                    {player.player.DisplayName}
                  </Text>
                </Anchor>

                <Text size={"small"}>
                  {player.player.Position} - {player.player.Age}
                </Text>
              </Grid>
            </Box>
            <Box direction={"row"} align={"center"} justify={"center"} flex={true}>
              <Grid columns={"auto"} rows={["auto", "auto"]}>
                <Text size={"large"} weight={600}>
                  {parseFloat(player.rate * 100).toFixed(2)}%
                </Text>
                <Text size={"xsmall"} weight={400}>
                  {player.cards_selected}/{player.cards_eligible} cards
                </Text>
              </Grid>
            </Box>
          </ResponsiveGrid>
        </Box>
      </Box>
    );
  }
}

export default SO5SelectionLine;
