import React, { useEffect, useState } from "react";
import ToggleWithLabel from "./toggleWithLabel";
import useComponentVisible from "./outsideAlerter";
import { isFree } from "./handleSubscriptionTier";
import { isSportFree, sorareFootball } from "./sports";
import { ReactTooltip } from "../util/tooltip.js";
import BoxWithToggle from "./boxWithToggle";

function PriceRange(props) {
  return (
    <div className={"space-y-2 xl:flex xl:flex-row xl:space-x-8 xl:justify-around"}>
      <div className={"flex flex-row space-x-4 xl:self-center"}>
        <div className={"w-28"}>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              name="price"
              id="price"
              value={props.value[0]}
              onChange={(v) => props.setRange([v.target.value, props.value[1]])}
              className="focus:ring-brand focus:border-brand block w-full pl-4 pr-12 text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              aria-describedby="price-currency"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 text-sm md:text-base" id="price-currency">
                ETH
              </span>
            </div>
          </div>
        </div>
        <span className={"self-center"}> - </span>
        <div className={"w-28"}>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              name="price"
              id="price"
              value={props.value[1]}
              onChange={(v) => props.setRange([props.value[0], v.target.value])}
              className="focus:ring-brand focus:border-brand block w-full pl-4 pr-12 text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              aria-describedby="price-currency"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 text-sm md:text-base" id="price-currency">
                ETH
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PriceFilter(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [bmp, setBmp] = useState(props.bmp);
  const [cur, setCur] = useState(props.current);
  const [bmpRange, setBmpRange] = useState(props.bmpRange);
  const [currentPrice, setCurrentPrice] = useState(props.currentRange);
  const [avg, setAvg] = useState(props.avg);
  const [underAvg, setUnderAvg] = useState(props.underAvg);
  const [underBmp, setUnderBmp] = useState(props.underBmp);
  const [avgRange, setAvgRange] = useState(props.avgRange);
  const [jerseyMint, setJerseyMint] = useState(props.jerseyMint);
  const [oneJerseyNumber, setOneJerseyNumber] = useState(props.oneJerseyNumber);

  useEffect(() => {
    setCur(props.current);
    setCurrentPrice(props.currentRange);
    setBmp(props.bmp);
    setBmpRange(props.bmpRange);
    setAvg(props.avg);
    setUnderAvg(props.underAvg);
    setUnderBmp(props.underBmp);
    setAvgRange(props.avgRange);
    ReactTooltip.rebuild();
  }, [props]);

  const premiumNeeded = isFree(props.tier) && (props.sport === undefined || !isSportFree(props.sport));
  if (premiumNeeded) {
    return (
      <div className={`bg-focus shadow py-1.5 rounded-md`}>
        <ReactTooltip id={"player-status-selector-component"} />
        <BoxWithToggle
          label={"Price filter"}
          isActive={false}
          disabled={premiumNeeded}
          disabledTip={"Star membership is needed"}
          className={"h-full"}
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className={"rounded-md bg-white py-2 shadow cursor-pointer"}
        onClick={() => {
          setIsComponentVisible(!isComponentVisible);
        }}
      >
        <p className={"text-sm px-4"}>
          Price filter / card edition <span className={"font-semibold"}>{props.tip}</span>
        </p>
      </div>
      {isComponentVisible && (
        <div ref={ref} className={"bg-white mt-2 absolute w-80 z-20 py-4 rounded-xl shadow"}>
          <div className={"w-10/12 mx-auto space-y-4 text-base"}>
            <div className={""}></div>
            <div className={"space-y-4"}>
              {props.showCur && (
                <div className={"space-y-4"}>
                  <ToggleWithLabel isActive={cur} label={"Filter by current price"} setActive={() => setCur(!cur)} />
                  <PriceRange value={currentPrice} setRange={(v) => setCurrentPrice(v)} />
                </div>
              )}
              <div className={"space-y-4"}>
                <ToggleWithLabel isActive={bmp} label={"Filter by floor price"} setActive={() => setBmp(!bmp)} />
                <PriceRange value={bmpRange} setRange={(v) => setBmpRange(v)} />
              </div>
              <div className={"space-y-4"}>
                <ToggleWithLabel isActive={avg} label={"Filter by most recent average"} setActive={() => setAvg(!avg)} />
                <PriceRange value={avgRange} setRange={(v) => setAvgRange(v)} />
              </div>
            </div>
            {props.watchlist !== true && (
              <div>
                <ToggleWithLabel
                  isActive={underBmp}
                  label={props.type === "offers" ? "Floor price" : "Under floor price"}
                  setActive={() => setUnderBmp(!underBmp)}
                />
                <ToggleWithLabel isActive={underAvg} label={"Under most recent average"} setActive={() => setUnderAvg(!underAvg)} />
              </div>
            )}
            {(props.sport === "all" || props.sport === sorareFootball) && (
              <div>
                <ToggleWithLabel isActive={jerseyMint} label={"Jersey mint"} setActive={() => setJerseyMint(!jerseyMint)} />
                <ToggleWithLabel isActive={oneJerseyNumber} label={"Serial #1"} setActive={() => setOneJerseyNumber(!oneJerseyNumber)} />
              </div>
            )}
            <div className={"mx-auto text-center"}>
              <div
                onClick={() =>
                  props.updateFilters(cur, currentPrice, bmp, bmpRange, avg, avgRange, underBmp, underAvg, jerseyMint, oneJerseyNumber)
                }
                className={"bg-brand px-2 py-2 w-24 mx-auto rounded-3xl cursor-pointer font-semibold hover:font-bold"}
              >
                <span className={"text-white"}>Apply</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PriceFilter;
