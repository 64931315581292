import { Dropdown } from "../util/dropdown";
import React from "react";

export const PeriodSelector = (props) => {
  const { periods, selected, onSelect, loading } = props;
  const options =
    periods?.map((period) => {
      return {
        value: period.slug,
        name: period.name,
        title: period.name,
      };
    }) || [];
  return (
    <Dropdown
      loading={loading}
      options={options}
      selected={selected}
      onChange={onSelect}
      title={"Periods"}
      displayOptions={{
        height: "h-full",
        width: "w-auto min-w-[75%] max-w-[100%]",
        roundedTopFocus: "rounded-t-2xl",
        rounded: "rounded-3xl",
        roundedBottomFocus: "rounded-b-xl",
      }}
    />
  );
};

export const LeagueSelector = (props) => {
  const { loading, leagues, selected, onSelect, onFocus, onBlur } = props;
  const options =
    leagues?.map((league) => {
      return {
        value: league.id,
        name: league.name,
        title: league.name,
      };
    }) || [];
  return (
    <Dropdown
      loading={loading}
      options={options}
      selected={selected}
      onChange={onSelect}
      onFocus={onFocus}
      onBlur={onBlur}
      title={"Leagues"}
      displayOptions={{
        height: "h-full",
        width: "w-auto",
        roundedTopFocus: "rounded-t-2xl",
        rounded: "rounded-3xl",
        roundedBottomFocus: "rounded-b-xl",
      }}
    />
  );
};
