import React, { useState, useEffect } from "react";
import clsx from "clsx";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { getScarcityInfo } from "../util/scarcities";
import { abbrv_football_positions } from "../util/positions";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import SelectSearch from "react-select-search";
import WatchlistMultiplePicker from "../watchlists/watchlistMultiplePicker";
import { withJSONPagination, getPaginatedJSONResponse } from "../util/pagination";
import { checkSecondUnitShouldBeDisplayed, findPriceForUserUnit } from "../util/formatMoney";
import useDebounce from "../../hooks/useDebounce";
import NewCardIcon from "../util/newCardIcon";
import BronzeMedal from "../../img/bronze-medal.svg";
import SilverMedal from "../../img/silver-medal.svg";
import GoldMedal from "../../img/gold-medal.svg";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { ReactComponent as Visibility } from "@material-design-icons/svg/filled/visibility.svg";
import { ReactComponent as IconCarretFilled } from "../../img/icons-carret-filled.svg";
import { SelectWithIcon } from "../util/select";

export const SHOW_REWARDS_ID = "showAuthenticatedUserRewards";
export const ITEMS_PER_PAGE = 50;

export function HiddenItem(props) {
  const commonClassName = "rounded-full bg-transparent-inverse-surface-low bg-opacity-10";
  return props.variant === "card" ? (
    <div className="flex items-center gap-2">
      <div className={clsx("h-10 w-10", commonClassName)} />
      <div className="flex flex-col gap-1">
        <div className={clsx("h-5 w-24", commonClassName)} />
        <div className={clsx("h-4 w-32", commonClassName)} />
      </div>
    </div>
  ) : (
    <div className={clsx("h-5 max-w-4/5 mx-auto", commonClassName, props?.className)} />
  );
}

export function HiddableItem(props) {
  return !props.showReward && props.isMe ? (
    <HiddenItem variant={props?.variant ?? "default"} className={props.hiddenItemClassName} />
  ) : (
    props.children
  );
}

export function ToggleYourRewards(props) {
  return (
    <div
      onClick={props.onToggleShowRewards}
      className={clsx("flex items-center gap-1 cursor-pointer", props.showRewards ? "text-outline-variant" : "text-primary")}
    >
      <Visibility className="w-4 h-4 fill-current" />
      <p className="text-xs">{`${props.showRewards ? "Hide" : "Reveal"} your rewards`}</p>
    </div>
  );
}

export function SortableColumn(props) {
  return (
    <div className={clsx(props.className, "relative flex flex-col items-center")}>
      <span className={clsx(props.titleClassName, "hover:text-primary", { "text-primary": props.isSorting })}>{props.title}</span>
      {props.isSorting && (
        <IconCarretFilled className={clsx("absolute -bottom-2 h-2 w-3 fill-primary", { "transform rotate-180": props.isOrderAsc })} />
      )}
    </div>
  );
}

function StatsRewardsBox(props) {
  let value = props.value;
  let scarcity = props.scarcity;
  const { color } = getScarcityInfo(scarcity);
  return (
    <div className={"bg-surface-container p-4 flex flex-col self-center items-center space-y-2 rounded-xl " + color}>
      <p className={"text-3xl text-center font-headers font-bold"}>{value}</p>
      <p className={"text-base text-center font-semibold"}>{scarcity} rewards</p>
    </div>
  );
}

function ScarcityTag(props) {
  const { color, bgColor } = getScarcityInfo(props.scarcity, props.sport);
  return (
    <div
      className={`
        w-min flex gap-2 justify-center items-center
        text-xs font-semibold whitespace-nowrap px-2 py-0.5 rounded-full
        ${color} ${bgColor} ${props?.className}
      `}
    >
      {props?.icon && <img src={props.icon} />}
      {props.children}
    </div>
  );
}

const defaultFilters = {
  division: "all",
  playerNameSearch: "",
  managerNameSearch: "",
};
const defaultStats = {
  stats: {
    LIMITED: 0,
    RARE: 0,
    "SUPER RARE": 0,
    UNIQUE: 0,
  },
};

const CardsSection = (props) => {
  const defaultShowRewards = !!localStorage.getItem(SHOW_REWARDS_ID);

  const [cards, setCards] = useState([]);
  const [compAndStatsLoading, setCompAndStatsLoading] = useState(false);
  const [cardsLoading, setCardsLoading] = useState(false);
  const [stats, setStats] = useState(defaultStats);
  const [competitions, setCompetitions] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [watchlists, setWatchlists] = useState({});
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [showRewards, setShowRewards] = useState(defaultShowRewards);
  const [managerNoPicture, setManagerNoPicture] = useState({});
  const [order, setOrder] = useState(["rank", "asc"]);

  const getParams = () => {
    const params = new URLSearchParams();

    params.append("sport", props.sport);
    params.append("sort", order.join(","));

    if (filters?.playerNameSearch) params.append("playerQuery", filters.playerNameSearch);
    if (filters?.managerNameSearch) params.append("managerQuery", filters.managerNameSearch);

    const watchlistKeys = Object.keys(watchlists);
    if (watchlistKeys.length) watchlistKeys.forEach((wId) => params.append("watchlists", wId));

    return params.toString();
  };

  const isFootball = props.sport === "sr_football";
  const hasFetchedComp = !compAndStatsLoading && !!competitions;

  const url = `/apiv2/rewards/gw/${props.gameWeek}/${filters.division}/cards?${getParams()}`;
  const fetchCards = async () =>
    await props
      .fetchApi(url, {
        method: "GET",
        headers: withJSONPagination(offset, ITEMS_PER_PAGE),
      })
      .then((res) => getPaginatedJSONResponse(res))
      .catch((e) => console.error(`Fetching error: ${e}`));

  const debouncedFetch = useDebounce(() => {
    fetchCards().then(({ res, hasNext }) => {
      setOffset(0);
      setHasMore(hasNext);
      setCards([...(res?.rewards ? res.rewards : [])]);
      setCardsLoading(false);
    });
  }, 300);

  useEffect(() => {
    if (props.gameWeek) {
      const fetchCompAndStats = async () => {
        const fetchCompetitions = props.fetchApi(`/apiv2/rewards/gw/${props.gameWeek}/all/cards/competitions?sport=${props.sport}`, {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        });
        const fetchStats = props.fetchApi(`/apiv2/rewards/gw/${props.gameWeek}/all/cards/stats?sport=${props.sport}`, {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        });

        const [competitionResponse, statsResponse] = await Promise.all([fetchCompetitions, fetchStats]);
        const competitionData = await competitionResponse.json();
        const statsData = await statsResponse.json();
        setCompetitions(competitionData.competitions ?? []);
        setFilters((prev) => {
          const allStarLimited = competitionData.competitions?.find(
            (comp) => comp.divisionId === `${props.gameWeek}-global-all_star-division-5`,
          );
          const firstComp = competitionData.competitions?.[0];
          return { ...prev, division: allStarLimited?.divisionId ?? firstComp?.divisionId ?? defaultFilters.division };
        });
        setStats(statsData);
        setCompAndStatsLoading(false);
      };

      setCompAndStatsLoading(true);
      fetchCompAndStats();
    }
  }, [props.gameWeek, props.sport]);

  useEffect(() => {
    if (hasFetchedComp) {
      debouncedFetch();
    }
  }, [filters?.playerNameSearch, filters?.managerNameSearch]);

  useEffect(() => {
    if (hasFetchedComp) {
      setCardsLoading(true);
      fetchCards().then(({ res, hasNext }) => {
        setOffset(0);
        setHasMore(hasNext);
        setCards([...(res?.rewards ? res.rewards : [])]);
        setCardsLoading(false);
      });
    }
  }, [order, watchlists, filters.division]);

  useEffect(() => {
    if (hasFetchedComp) {
      if (offset === 0) {
        setCardsLoading(true);
      }
      fetchCards().then(({ res, hasNext }) => {
        setCards([...(offset === 0 ? [] : cards), ...(res.rewards ? res.rewards : [])]);
        setHasMore(hasNext);
        setCardsLoading(false);
      });
    }
  }, [compAndStatsLoading, props.sport, offset, hasFetchedComp]);

  const compOptions = [
    {
      label: "All divisions",
      value: "all",
    },
    ...(competitions ?? []).map((competition) => ({
      label: competition.displayName,
      value: competition.divisionId,
      icon: competition.logo,
    })),
  ];

  const onFilter = (value, key) => setFilters((prev) => ({ ...prev, [key]: value }));

  const onSelectWatchlist = (watchlist) => {
    let newWatchlists = { ...watchlists };
    if (watchlists[watchlist.id]) {
      delete newWatchlists[watchlist.id];
    } else {
      newWatchlists[watchlist.id] = watchlist;
    }
    setWatchlists(newWatchlists);
  };

  const onSort = (name) =>
    setOrder((prev) => {
      if (prev[0] !== name) return [name, "asc"];
      return [name, prev[1] === "desc" ? "asc" : "desc"];
    });

  const onLoadMore = () => {
    setOffset((prev) => prev + ITEMS_PER_PAGE);
  };

  const onToggleShowRewards = () => {
    if (showRewards) {
      localStorage.removeItem(SHOW_REWARDS_ID);
      setShowRewards(false);
    } else {
      localStorage.setItem(SHOW_REWARDS_ID, true);
      setShowRewards(true);
    }
  };

  const rankLabel = (rank) => {
    const imgClassName = "w-6 h-6 mx-auto";
    if (rank === 1) return <img src={GoldMedal} className={imgClassName} />;
    if (rank === 2) return <img src={SilverMedal} className={imgClassName} />;
    if (rank === 3) return <img src={BronzeMedal} className={imgClassName} />;
    return rank;
  };

  return compAndStatsLoading ? (
    <div className="py-10">
      <SDLoading />
    </div>
  ) : (
    <div className={GLOBAL_MARGIN_NO_BG_NO_PAD + " mt-4 space-y-4 z-0 pb-10"}>
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"}>
        <StatsRewardsBox value={stats.stats.LIMITED} scarcity="Limited" />
        <StatsRewardsBox value={stats.stats.RARE} scarcity="Rare" />
        <StatsRewardsBox value={stats.stats["SUPER RARE"]} scarcity="Super rare" />
        <StatsRewardsBox value={stats.stats.UNIQUE} scarcity="Unique" />
      </div>
      <div>
        <div className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="flex flex-col md:flex-row gap-4">
            <SelectWithIcon
              options={compOptions}
              selectedValue={filters.division}
              onChange={({ value }) => onFilter(value, "division")}
              containerClasses="rounded-md md:w-60"
            />
            <input
              className="text-sm text-textGrey3 font-medium truncate flex-1 focus:ring-brand block w-full py-2 rounded-md px-4 shadow sm:text-sm border-grey-e5"
              value={filters?.playerNameSearch}
              placeholder={"Filter by player name"}
              onChange={(e) => onFilter(e.target.value, "playerNameSearch")}
            />
            <input
              className="text-sm text-textGrey3 font-medium truncate flex-1 focus:ring-brand block w-full py-2 rounded-md px-4 shadow sm:text-sm border-grey-e5"
              value={filters?.managerNameSearch}
              placeholder={"Filter by manager name"}
              onChange={(e) => onFilter(e.target.value, "managerNameSearch")}
            />
          </div>
          <WatchlistMultiplePicker
            className="md:w-80"
            types={["player", "manager"]}
            fetchOnMount={true}
            options={{
              pinListFirst: true,
              defaultListFirst: true,
              followedListFirst: true,
              ownedListFirst: true,
            }}
            size={"100%"}
            placeholder={"Filter by watchlist(s)"}
            selected={watchlists}
            onSelect={onSelectWatchlist}
          />
        </div>
      </div>
      {cardsLoading ? (
        <div className="py-10">
          <SDLoading />
        </div>
      ) : (
        <div>
          <InfiniteScroll
            className="w-full overflow-x-scroll"
            dataLength={cards.length}
            scrollableTarget="window"
            next={onLoadMore}
            hasMore={hasMore}
            useWindow={true}
            loader={<SDLoading />}
          >
            <table className={"border-collapse overflow-hidden rounded-lg table-fixed whitespace-no-wrap mx-auto min-w-full"}>
              <thead>
                <tr className="text-center bg-surface-container-high text-on-surface-variant h-12 font-semibold">
                  <th onClick={() => onSort("rank")} className="cursor-pointer text-left text-center text-xs w-12">
                    <SortableColumn className="px-2" title="Rank" isSorting={order[0] === "rank"} isOrderAsc={order[1] === "asc"} />
                  </th>
                  <th className="w-px">
                    <div className="h-8 w-px bg-outline-variant" />
                  </th>
                  <th className="rounded-tr-lg py-3 px-4 text-left text-xs md:w-1/4 lg:1/3">Manager</th>
                  <th className="py-3 text-center text-left text-xs w-1/4">Card</th>
                  <th onClick={() => onSort("tier")} className="cursor-pointer py-3 px-1 text-left text-xs text-center">
                    <SortableColumn title="Tier" isSorting={order[0] === "tier"} isOrderAsc={order[1] === "asc"} />
                  </th>
                  <th onClick={() => onSort("valuation")} className="cursor-pointer py-3 px-4 text-left text-xs text-center">
                    <SortableColumn title="Valuation" isSorting={order[0] === "valuation"} isOrderAsc={order[1] === "asc"} />
                  </th>
                  <th className="py-3 px-4 text-left text-xs text-center w-1/6">Competition</th>
                  <th className="w-12" />
                </tr>
              </thead>
              <tbody className="space-y-2 bg-surface-container text-on-surface">
                {!cards.length ? (
                  <tr>
                    <td colSpan="8" className="text-center text-sm p-4">
                      No result was found on the selected gameweek or with the selected filters.
                    </td>
                  </tr>
                ) : (
                  cards.map((c, i) => {
                    let lineup = c.lineup;
                    let player = c.player;
                    let card = c.card;
                    let valuation = c.valuation;

                    const isMe = c.winnerSlug === props.user.sorareSlug;
                    const { supply } = getScarcityInfo(card.Scarcity, props.sport);

                    const price =
                      valuation.Valuation > 0
                        ? findPriceForUserUnit(
                            valuation.Valuation,
                            {
                              eth: valuation.Eth,
                              eur: valuation.Eur,
                              gbp: valuation.Gbp,
                              usd: valuation.Usd,
                            },
                            props.user.preferredUnit,
                          )
                        : "-";
                    const secondPrice =
                      valuation.Valuation > 0
                        ? findPriceForUserUnit(
                            valuation.Valuation,
                            {
                              eth: valuation.Eth,
                              eur: valuation.Eur,
                              gbp: valuation.Gbp,
                              usd: valuation.Usd,
                            },
                            props.user.secondUnit,
                          )
                        : "-";
                    const shouldDisplaySecondUnit = checkSecondUnitShouldBeDisplayed(props.user.preferredUnit, props.user.secondUnit);
                    const position = isFootball ? abbrv_football_positions[player.Position] : player.Position;

                    return (
                      <tr key={c.card.AssetId + i} className="h-16 py-2 border-t border-outline-variant">
                        <td className="text-center font-semibold text-base align-middle w-max">{rankLabel(lineup.Rank)}</td>
                        <td className="w-px">
                          <div className="h-8 w-px bg-outline-variant" />
                        </td>
                        <td className="font-semibold text-sm px-4">
                          <div className="flex gap-2 items-center w-max">
                            <a href={`/manager/${c.winnerSlug}`} className="flex-shrink-0">
                              <img
                                src={managerNoPicture[c.winnerSlug] || !c?.winnerPicUrl ? EmptyAvatar : c.winnerPicUrl}
                                onError={() => setManagerNoPicture((prev) => ({ ...prev, [c.winnerSlug]: true }))}
                                className="rounded-full object-cover object-center w-10 h-10"
                              />
                            </a>
                            <div className="flex flex-col gap-1 pr-2">
                              <a href={`/manager/${c.winnerSlug}`} className="truncate">
                                <span>{c.winner}</span>
                              </a>
                              {isMe && <ToggleYourRewards showRewards={showRewards} onToggleShowRewards={onToggleShowRewards} />}
                            </div>
                          </div>
                        </td>
                        <td>
                          <HiddableItem variant="card" isMe={isMe} showReward={showRewards} hiddenItemClassName="w-32">
                            <div className="flex items-center gap-2">
                              <div className="bg-transparent-inverse-surface-low bg-opacity-10 w-10 h-10 rounded-full flex items-center justify-center flex-shrink-0">
                                <a href={"/player/" + player.PlayerId}>
                                  <img src={player.Avatar} className="rounded-full h-10" />
                                </a>
                              </div>
                              <div className={"flex flex-col gap-1 px-4"}>
                                <div className="flex items-center gap-2">
                                  <a href={"/player/" + player.PlayerId}>
                                    <p className={"font-semibold text-sm truncate"}>{player.DisplayName}</p>
                                  </a>
                                  <img
                                    className="object-contain object-center h-4 w-4 flex-shrink-0"
                                    src={c?.cardTeamLogoURL}
                                    alt={c?.cardTeamDisplayName}
                                  />
                                </div>
                                <ScarcityTag sport={props.sport} scarcity={card.Scarcity}>
                                  <div className="inline">
                                    <a
                                      href={"/card/" + card.TokenId}
                                      target="_blank"
                                      rel="noreferrer"
                                    >{`${card.SerialNumber}/${supply}`}</a>
                                    {` · ${card.Season} · ${position}`}
                                  </div>
                                </ScarcityTag>
                              </div>
                            </div>
                          </HiddableItem>
                        </td>
                        <td className={"self-center font-semibold text-sm text-center"}>
                          <HiddableItem isMe={isMe} showReward={showRewards} hiddenItemClassName="w-16">
                            <ScarcityTag className="mx-auto !w-16" sport={props.sport} scarcity={card.Scarcity}>
                              <div className="w-2">
                                <NewCardIcon scarcity={card.Scarcity} />
                              </div>
                              <span>{c.tier === 0 ? "Star" : `Tier ${c.tier}`}</span>
                            </ScarcityTag>
                          </HiddableItem>
                        </td>
                        <td className={"self-center font-semibold text-center text-sm"}>
                          <HiddableItem isMe={isMe} showReward={showRewards} hiddenItemClassName="w-10">
                            <div>
                              <p className={"font-semibold text-base text-center"}>{price}</p>
                              {secondPrice !== "" && shouldDisplaySecondUnit && (
                                <p className={"font-semibold text-xs text-textGrey2 text-center"}>{secondPrice}</p>
                              )}
                            </div>
                          </HiddableItem>
                        </td>
                        <td className={"self-center font-semibold text-sm text-center whitespace-nowrap px-2"}>{c.comp_display_name}</td>
                        <td />
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default CardsSection;
