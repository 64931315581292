import React from "react";
import { Box } from "grommet";

import CardLayout from "../cards/cardLayout";
import { getValuationOrigin } from "../util/valuationOrigin";
import { formatPrice } from "../util/formatMoney";
import { withUser } from "../../userContext";
import { sorareFootball } from "../util/sports";

function OfferCard(props) {
  let o = props.offer;
  let display = true;

  const filterPlayers = props.filter;

  if (filterPlayers.length > 0 && filterPlayers.indexOf(o.card.PlayerId) === -1) {
    display = false;
  }

  let dateToDisplay = new Date(o.offer.EndingAt);
  if (o.offer.Status === "ENDED") dateToDisplay = new Date(o.offer.AcceptationDate);
  const bmp = o.best_market_price_split?.Price || o.bmp;
  const r30 = o.real_time_player_average_split?.Price || o.r30;
  const split = o.best_market_price_split?.Split;
  let price = formatPrice(o.offer.ReceiveAmountInWei, "eth");
  let bestPrice = formatPrice(bmp, "eth");
  let ethPrice = formatPrice(o.offer.ReceiveAmountInWei, "eth");
  if (props.user.preferredUnit !== "eth") {
    price = formatPrice(o.offer.ReceiveAmountInWei * props.priceInfo[props.user.preferredUnit], props.user.preferredUnit);
    bestPrice = formatPrice(bmp * props.priceInfo[props.user.preferredUnit], props.user.preferredUnit);
  }
  let averagePrice = formatPrice(r30, "eth");
  if (props.user.preferredUnit !== "eth") {
    averagePrice = formatPrice(parseFloat(o[props.user.preferredUnit]), props.user.preferredUnit);
  }
  let gamesPlayedL5 = o.l5;
  let so5AvgL5 = o.avg5;
  let gamesPlayedL20 = o.l20;
  let so5AvgL20 = o.avg20;
  if (props.newMatrix && o.player.Sport === sorareFootball) {
    so5AvgL5 = o.newAvg5;
    so5AvgL20 = o.newAvg20;
  }
  if (r30 === -1 || o.valuation_origin === "starting_price") {
    averagePrice = "None";
  }
  if (bmp === -1) {
    bestPrice = "None";
  }
  if (display) {
    return (
      <Box>
        {display && (
          <Box>
            <CardLayout
              imageLink={"/card/" + o.card.TokenId}
              picture={o.card.PictureUrl}
              onSale={true}
              valuationOrigin={getValuationOrigin(o.valuation_origin)}
              ethCurrentPrice={ethPrice}
              monthPrice={props.averagePrice || averagePrice}
              marketPrice={props.marketPrice || bestPrice}
              border={props.border}
              currentPrice={price}
              date={dateToDisplay}
              countdown={true}
              card={o.card}
              player={o.player}
              sport={o.player.Sport}
              width={props.width}
              height={props.height}
              l5={so5AvgL5}
              l20={so5AvgL20}
              gms5={gamesPlayedL5}
              gms20={gamesPlayedL20}
              s5={o.s5Avg}
              s15={o.s15Avg}
              s5Apps={o.s5Apps}
              s15Apps={o.s15Apps}
              availability={o.player_status}
              gwGames={props.gwGames}
              gwNumber={props.gwNumber}
              split={split}
            />
          </Box>
        )}
      </Box>
    );
  } else {
    return null;
  }
}

export default withUser(OfferCard);
