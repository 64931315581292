import React, { useState } from "react";
import UnknownPlayer from "../../img/unknown_player2.png";
import { playing_status_labels, PlayingStatus } from "../util/playingStatus";
import PlayerAvailability from "./playerAvailability";
import PlayerSearchPrice from "./playerSearchPrice";
import Jersey from "../../img/jersey.svg";
import UnknownTeam from "../../img/unknown_club.png";
import NoGameIcon from "../../img/no-game-icon.svg";
import FavoriteBubble from "../util/favoriteBubble";
import NotificationBubble from "../util/notificationBubble";
import ordinal_suffix_of from "../util/ordinalNumber";
import ConceptPicker from "../util/conceptPicker";
import AverageAndGames from "../util/averageAndGames";
import SelectionBubble from "../util/selectionBubble";
import DeletionBubble from "../util/deletionBubble";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { withUser } from "../../userContext";
import { isSportFree, sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import NBAProjectedScore from "../util/nbaProjectionScore";

function PlayerSearchResult(props) {
  const r = props.result;

  const actions = props.actions || ["favorite", "notification"];
  const isSelected = props.isSelected;
  const selectable = actions.includes("selection");
  const [isHover, setIsHover] = useState(false);

  let colorStart = "#1D3EBD";
  let colorEnd = "#132D8F";
  if (r.player.ColorLeft !== "") {
    colorStart = r.player.ColorLeft;
  }
  if (r.player.ColorRight !== "") {
    colorEnd = r.player.ColorRight;
  }
  const teamMap = props.teamMap;
  let nextGame;
  if (r.player.NationalTeam !== undefined && r.player.NationalTeam !== "") {
    nextGame = teamMap[r.player.NationalTeam];
  }
  if (nextGame === undefined) {
    nextGame = teamMap[r.player.TeamId];
  }
  let nbOfGames = teamMap[r.player.TeamId]?.nb_of_games;
  let logos = teamMap[r.player.TeamId]?.opponent_logos;
  let projectedScore = 0;
  if (teamMap[r.player.TeamId]?.projected_scores !== null) {
    teamMap[r.player.TeamId]?.projected_scores.map((pit) => {
      if (pit.playerId === r.player.PlayerId) projectedScore = pit.score;
    });
  }

  let showRank = true;
  if (props.showRank === false) {
    showRank = false;
  }

  const onSelect = () => {
    if (props.onSelect) {
      props.onSelect(r.player);
    }
  };

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(r.player);
    }
  };

  const sport = r.player.Sport;

  let borderStyle = "";
  if (isSelected) {
    borderStyle = "border-brand";
  } else if (isHover && selectable) {
    borderStyle = "border-brand-light";
  } else {
    borderStyle = "border-background-grey";
  }
  console.log(nextGame);
  const playingStatus = (t1OrAbove(props.user?.tier) || isSportFree(r.player.Sport)) && playing_status_labels[r.player.PlayingStatus];
  return (
    <div
      className={"bg-focus mx-auto rounded-xl overflow-hidden w-full border-2 " + borderStyle + (selectable ? " cursor-pointer" : "")}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onSelect}
    >
      <div
        className={"relative h-header opacity-100"}
        style={{
          background: "linear-gradient(" + colorStart + ", " + colorEnd + ")",
        }}
      >
        <div className={"rounded-t-xl h-header z-0 text-focus overflow-hidden"} style={{ opacity: "15%" }}>
          <div className={"h-player rounded-t-xl flex h-header bg-gradient-to-b from-white to-full-black z-10"} />
        </div>
        <div className={"absolute text-white opacity-100 z-10 w-full h-header bottom-0 top-0 inset-x-0"}>
          {r.player.Sport == sorareFootball && (
            <div className={"h-header opacity-90 absolute z-0 " + (actions.length >= 3 ? "right-24" : "right-14")}>
              {
                <img
                  className={"object-cover h-full pt-2"}
                  src={r.player.SquaredPictureUrl !== "" ? r.player.SquaredPictureUrl : UnknownPlayer}
                />
              }
            </div>
          )}
          {r.player.Sport == sorareBaseball && (
            <div className={"h-header opacity-90 absolute z-0 " + (actions.length >= 3 ? "right-24" : "right-14")}>
              {<img className={"object-cover h-full pt-2"} src={r.player.Avatar !== "" ? r.player.Avatar : UnknownPlayer} />}
            </div>
          )}
          {r.player.Sport === sorareBasketball && (
            <div className={"h-header opacity-90 absolute z-0  py-4  rounded-lg " + (actions.length >= 3 ? "right-24" : "right-20")}>
              {<img className={"object-contain h-full rounded-lg"} src={r.player.Avatar !== "" ? r.player.Avatar : UnknownPlayer} />}
            </div>
          )}
          <div className={"ml-4 h-header z-10 absolute flex flex-col justify-start pt-4 space-y-1"}>
            <div className={"flex flex-row justify-start space-x-2"}>
              <p className={"text-center text-base font-semibold hover:font-bold"}>
                <a href={"/player/" + r.player.PlayerId} target={"_blank"} rel="noreferrer">
                  {r.player.DisplayName}
                </a>
              </p>
              <div className={"self-center"}>
                <PlayerAvailability position="center bottom" availability={r.availability_status} size={"w-4"} />
              </div>
            </div>
            <div className={"space-y-1"}>
              <p className={"text-xs font-medium"}>
                {r.player.Age} - {r.player.Position}{" "}
                {playingStatus && (
                  <>
                    <span>{`- `}</span>
                    <PlayingStatus status={r.player.PlayingStatus} player={r.player} />
                  </>
                )}
              </p>
              {r.team.DisplayName !== "" ? (
                <p className={"text-xs font-semibold"}>
                  <a className={"hover:font-bold"} target={"_blank"} href={"/team/" + r.team.TeamId} rel="noreferrer">
                    {r.team.DisplayName}
                  </a>{" "}
                  ({r.team.League})
                </p>
              ) : (
                <p className={"text-xs font-semibold"}>No club</p>
              )}
            </div>
          </div>
          <div className={"absolute top-0 right-3 mt-4 flex z-20 flex-row justify-end space-x-3"}>
            {actions.includes("favorite") && <FavoriteBubble className={"h-6 w-6"} player={r.player} favorite={r.favorite} />}
            {actions.includes("notification") && (
              <NotificationBubble className={"h-6 w-6"} player={r.player} hasNotifications={r.has_price_alerts} />
            )}
            {actions.includes("deletion") && <DeletionBubble className={"h-6 w-6"} onClick={onDelete} title={props.deleteTitle} />}
            {selectable && (isSelected || isHover) && (
              <SelectionBubble player={r.player} isHover={isHover} isSelected={isSelected} onSelect={onSelect} />
            )}
          </div>
        </div>
      </div>

      <div className={showRank ? "mt-2" : "mt-2 space-y-2"}>
        <div className={"py-1 flex flex-row"}>
          <div className={"w-6/12 flex flex-row justify-center text-center self-center 2xl:space-x-4 space-x-4"}>
            <AverageAndGames
              avg={props.user.sorareAverages ? r.scores_5.SorareAverage : r.scores_5.AverageNoDnp}
              gms={props.user.sorareAverages ? r.scores_5.SorareApps : r.scores_5.GamesPlayed}
              totalGms={5}
              sport={r.player.Sport}
            />
            <AverageAndGames
              avg={props.user.sorareAverages ? r.scores_15.SorareAverage : r.scores_15.AverageNoDnp}
              gms={props.user.sorareAverages ? r.scores_15.SorareApps : r.scores_15.GamesPlayed}
              totalGms={15}
              sport={r.player.Sport}
            />
            <AverageAndGames avg={r.scores_40.AverageNoDnp} gms={r.scores_40.GamesPlayed} totalGms={40} sport={r.player.Sport} />
          </div>
          {sport === sorareFootball && (
            <div className={"w-6/12 flex flex-col pt-1"}>
              <div className={"text-center"}>
                {nextGame === undefined && (
                  <div className={"relative flex flex-row justify-center pt-1"}>
                    <p className={"font-semibold text-xs z-10 self-center pt-3"}>No game</p>
                    <div className={"absolute z-0"}>
                      <img className={"w-14"} src={NoGameIcon} />
                    </div>
                  </div>
                )}
                {nextGame && (
                  <div
                    className={"flex flex-row space-x-4 self-center justify-center mt-2"}
                    data-tip={nextGame.home_team + " - " + nextGame.away_team}
                  >
                    <div className={"w-7 h-7 self-center flex justify-center"}>
                      <img className={"object-scale-down"} src={nextGame.home_team_logo !== "" ? nextGame.home_team_logo : UnknownTeam} />
                    </div>
                    <p className={"text-xs font-semibold self-center justify-center flex text-center"}>-</p>
                    <div className={"w-7 h-7 self-center flex justify-center"}>
                      <img className={"object-scale-down"} src={nextGame.away_team_logo !== "" ? nextGame.away_team_logo : UnknownTeam} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {sport === sorareBasketball && (
            <div className={"w-6/12 flex flex-col pt-1 self-center"}>
              <div className={"flex flex-col items-center space-y-2 self-center"}>
                <div data-tip={"Opponents next gameweek"} className={"flex flex-row space-x-2 justify-center"}>
                  {logos !== undefined &&
                    logos !== null &&
                    logos.map((logo) => {
                      return <img className={"w-6"} src={logo} />;
                    })}
                </div>
                <div data-tip={"Best projected score next gameweek"}>
                  <NBAProjectedScore size={"w-8 h-8"} font={"text-sm"} score={projectedScore} />
                </div>
              </div>
            </div>
          )}
        </div>
        {showRank && (
          <div className={"flex flex-row py-2"}>
            <div className={"w-6/12 text-textGrey3 text-xxs font-semibold space-y-1 ml-4 self-center"}>
              <p className={"self-center"}>RANKING</p>
              <p className={"self-center"}>OVER THE LAST 15 GAMES</p>
            </div>
            <div className={"w-6/12 flex flex-row justify-around pb-2"}>
              <div className={"relative flex flex-col mx-auto items-center align-middle"}>
                <div className={"z-10 pt-2"}>
                  <p className={"z-10 text-xs font-semibold text-center"}>
                    {r.player_ranking.ranking_position > 0 ? ordinal_suffix_of(r.player_ranking.ranking_position) : "No rank"}
                  </p>
                  <p className={"z-10 text-textGrey3 text-center font-semibold text-xxs"}>{r.player.Position}</p>
                </div>
                <div className={"absolute z-0"}>{<img className={"object-cover h-full pt-2 z-0"} src={Jersey} />}</div>
              </div>
              <div className={"relative flex flex-col mx-auto items-center align-middle"}>
                <div className={"z-10 pt-2"}>
                  <p className={"z-10 text-xs font-semibold text-center"}>
                    {r.player_ranking.ranking_overall > 0 ? ordinal_suffix_of(r.player_ranking.ranking_overall) : "No rank"}
                  </p>
                  <p className={"z-10 text-textGrey3 font-semibold text-xxs text-center"}>Overall</p>
                </div>
                <div className={"absolute z-0"}>{<img className={"object-cover h-full pt-2 z-0"} src={Jersey} />}</div>
              </div>
            </div>
          </div>
        )}
        <div className={props.pick ? "grid grid-cols-4 grid-rows-1 gap-x-2 mx-2" : "grid grid-cols-4 grid-rows-1 gap-x-2 mx-2 pb-4"}>
          <PlayerSearchPrice currency={props.currency} rt={r.real_time_limited} bmp={r.bmp_limited} color={"limited"} bgColor={"#FDF8EA"} />
          <PlayerSearchPrice currency={props.currency} rt={r.real_time_rare} bmp={r.bmp_rare} color={"rare"} bgColor={"#FFE9E4"} />
          <PlayerSearchPrice
            currency={props.currency}
            rt={r.real_time_super_rare}
            bmp={r.bmp_sr}
            color={"super rare"}
            bgColor={"#EBF5FF"}
          />
          <PlayerSearchPrice currency={props.currency} rt={r.real_time_unique} bmp={r.bmp_u} color={"unique"} bgColor={"#F2F2F2"} />
        </div>
        {props.pick && (
          <div className={"grid grid-cols-4 grid-rows-1 gap-x-2 mx-2 pb-2"}>
            <div className={"flex flex-row justify-center"} onClick={() => props.pick("limited", r)}>
              {props.pickScarcities?.find((s) => s.value === "LIMITED") && <ConceptPicker scarcity={"limited"} />}
            </div>
            <div className={"flex flex-row justify-center"} onClick={() => props.pick("rare", r)}>
              {props.pickScarcities?.find((s) => s.value === "RARE") && <ConceptPicker scarcity={"rare"} />}
            </div>
            <div className={"flex flex-row justify-center"} onClick={() => props.pick("super rare", r)}>
              {props.pickScarcities?.find((s) => s.value === "SUPER RARE") && <ConceptPicker scarcity={"super rare"} />}
            </div>
            <div className={"flex flex-row justify-center"} onClick={() => props.pick("unique", r)}>
              {props.pickScarcities?.find((s) => s.value === "UNIQUE") && <ConceptPicker scarcity={"unique"} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(PlayerSearchResult);
