import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { ReactTooltip } from "../util/tooltip.js";
import { useLocation } from "react-router-dom";
import { GLOBAL_MARGIN_NO_BG } from "../util/margin";
import PlayerAvatar from "../util/playerAvatar";
import { Button } from "../util/button";
import CommunityPrediction from "../util/communityPrediction";
import UserPrediction from "../util/userPrediction";
import StartIcon from "../../img/starter.svg";
import Bench from "../../img/benchNotCameIn.svg";
import NotInSquad from "../../img/notInSquad.svg";
import CameIn from "../../img/cameIn.svg";
import { sorareFootball } from "../util/sports";
import SquaredScore from "../util/squaredScore";
import format from "date-fns/format";
import BoxWithToggle from "../util/boxWithToggle";
import Doubtful from "../../img/doubtful.svg";
import SDLoading from "../util/SDLoading";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function GameStarter(props) {
  const [status, setStatus] = useState(props.player.probable.status);

  const changeStatus = (s) => {
    props.changeStatus(s);
    setStatus(s);
  };

  const player = props.player;
  const scores = player.last5Scores || [];
  let reversedScores = [];
  if (scores.length > 0) {
    reversedScores = scores.slice().reverse();
  }
  console.log(scores);
  return (
    <tr>
      <td>
        <div className={"flex flex-row space-x-4"}>
          <PlayerAvatar player={player.player} />
          <div className={"self-center"}>
            <p className={"self-center font-medium text-sm"}>{player.player.DisplayName}</p>
            <p className={"self-center text-xs"}>{player.player.Position}</p>
          </div>
        </div>
      </td>
      <td>
        <div className={"flex flex-row space-x-2 justify-center px-1"}>
          {reversedScores.map((s) => {
            let icon = StartIcon;
            if (s.OnGameSheet === false) {
              icon = NotInSquad;
            }
            if (s.SubbedOn === true) {
              icon = CameIn;
            }
            if (s.OnGameSheet === true && s.mins_played === 0) {
              icon = Bench;
            }
            return (
              <div className={"flex flex-col items-center space-y-0.5"}>
                <div className={"w-7 h-7"}>
                  <img src={icon} className={"w-full h-full"} />
                </div>
                <p className={"text-xxs text-textGrey3 font-semibold"}>{format(new Date(s.game.GameTime), "dd/MM")}</p>
              </div>
            );
          })}
        </div>
      </td>
      <td>
        <div className={"flex flex-row space-x-2 justify-center"}>
          {reversedScores.map((s) => {
            return (
              <div className={"flex flex-col items-center space-y-0.5"}>
                <SquaredScore font={"text-sm font-semibold"} size={"w-7 h-7"} avg={s.NewScore} sport={sorareFootball} />
                <p className={"text-xxs text-textGrey3 font-semibold"}>{format(new Date(s.game.GameTime), "dd/MM")}</p>
              </div>
            );
          })}
        </div>
      </td>
      <td>
        <div className={"pr-2 flex flex-row justify-center"}>
          <CommunityPrediction pred={player.lineup} />
        </div>
      </td>
      <td>
        <div className={"px-2 flex flex-row justify-center"}>
          <UserPrediction status={status} changeStatus={(s) => changeStatus(s)} />
        </div>
      </td>
    </tr>
  );
}

const TableHeader = (props) => {
  return (
    <thead className={""}>
      <tr className={"bg-brand-black text-white text-sm font-semibold p-2 rounded-lg"}>
        <th className={"text-left w-4/12 rounded-tl-lg pl-2"}>Player</th>
        <th className={"text-left w-2/12 text-center"}>Last 5 games</th>
        <th className={"text-left w-2/12 text-center"}>Last 5 scores</th>
        <th className={"text-left w-2/12 text-center"}>Community</th>
        <th className={"text-left w-2/12 rounded-tr-lg text-center py-2"}>Your prediction</th>
      </tr>
    </thead>
  );
};

function TeamSumUp(props) {
  if (props.players === undefined || props.players === null) return null;

  let starters = 0;
  let doubts = 0;
  let benches = 0;
  let notInSquad = 0;

  props.players.map((p) => {
    if (p.probable.status === "STARTER") {
      starters++;
    }
    if (p.probable.status === "MIGHT_NOT") {
      doubts++;
    }
    if (p.probable.status === "NOT_STARTER") {
      benches++;
    }
    if (p.probable.status === "OUT") {
      notInSquad++;
    }
  });

  let divStyle = "flex flex-row space-x-2 justify-center self-center";
  let pStyle = "self-center font-semibold text-sm";
  return (
    <div className={"bg-white rounded-lg py-1.5 px-4 flex flex-row space-x-2"}>
      <div className={divStyle}>
        <div className={"w-6 h-6 self-center"}>
          <img src={StartIcon} className={"w-full h-full"} />
        </div>
        <p className={pStyle}>{starters}</p>
      </div>
      <div className={divStyle}>
        <div className={"w-6 h-6"}>
          <img src={Doubtful} className={"w-full h-full"} />
        </div>
        <p className={pStyle}>{doubts}</p>
      </div>
      <div className={divStyle}>
        <div className={"w-6 h-6"}>
          <img src={Bench} className={"w-full h-full"} />
        </div>
        <p className={pStyle}>{benches}</p>
      </div>
      <div className={divStyle}>
        <div className={"w-6 h-6"}>
          <img src={NotInSquad} className={"w-full h-full"} />
        </div>
        <p className={pStyle}>{notInSquad}</p>
      </div>
    </div>
  );
}

function GameStarters(props) {
  const [homeTeamStarters, setHomeTeamStarters] = useState([]);
  const [awayTeamStarters, setAwayTeamStarters] = useState([]);
  const [licensedOnly, setLicensedOnly] = useState(true);
  const [msg, setMsg] = useState("");
  const [game, setGame] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  const query = useQuery();
  const gameId = query.get("gameId");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    props
      .fetch("/projections-api/SO5/probableGameStarters?gameId=" + gameId)
      .then((response) => response.json())
      .then((res) => {
        setHomeTeamStarters(res.homeStarters);
        setAwayTeamStarters(res.awayStarters);
        setGame(res.game);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const submitStarters = (st) => {
    let theStarters = [];
    let starters = 0;
    let doubts = 0;
    let gkStarters = 0;
    st.map((s) => {
      if (s.probable.status === "STARTER") starters++;
      if (s.probable.status === "STARTER" && s.player.Position === "Goalkeeper") gkStarters++;
      if (s.probable.status === "MIGHT_NOT") doubts++;
      if (s.probable.status !== "") {
        theStarters.push({
          playerId: s.player.PlayerId,
          status: s.probable.status,
        });
      } else theStarters.push({ playerId: s.player.PlayerId, status: "NO_STATUS" });
    });
    if (starters > 11) {
      setMsg("You can't have more than 11 starters.");
      return;
    } else if (starters === 11 && doubts > 0) {
      setMsg("You can't have doubtful players if you have 11 starters.");
      return;
    } else if (gkStarters > 1) {
      setMsg("You can't have more than 1 starting goalkeepers.");
      return;
    }
    props
      .fetch("/projections-api/SO5/changeStartingStatus", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: gameId,
          players: theStarters,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setMsg("Your predictions have been saved.");
      })
      .catch(errorCatcher(() => {}));
  };

  let submitDisabled = false;

  if (loading) {
    return <SDLoading />;
  }

  if (game !== null) {
    submitDisabled = new Date(game?.game.GameTime) < new Date();
    return (
      <div>
        <ReactTooltip />
        <div className={GLOBAL_MARGIN_NO_BG + " p-8 rounded-lg space-y-8"}>
          <p className={"text-center text-brand font-semibold text-xl"}>
            {game.home_team} - {game.away_team}
          </p>
          <div className={"flex flex-row justify-center py-1.5 rounded-lg bg-white w-6/12 mx-auto"}>
            <BoxWithToggle
              isActive={licensedOnly}
              setActive={() => setLicensedOnly(!licensedOnly)}
              label={"Show only players with Sorare cards"}
            />
          </div>
          <div className={"flex flex-col space-y-8"}>
            <div className={"w-full"}>
              <div className={"flex flex-row justify-center mb-4 space-x-4 self-center"}>
                <div className={"flex flex-row justify-center self-center"}>
                  <Button label={"Submit " + game.home_team} disabled={submitDisabled} onClick={() => submitStarters(homeTeamStarters)} />
                </div>
                <div className={"flex flex-row self-center"}>
                  <TeamSumUp players={homeTeamStarters} refresh={refresh} />
                </div>
                <p className={"font-semibold text-sm self-center"}>{msg}</p>
              </div>

              <div className={"space-y-2"}>
                <table className={"w-full"}>
                  <TableHeader />
                  <tbody className={"bg-white"}>
                    {homeTeamStarters.map((player, i) => {
                      if (player.player.Licensed === true || licensedOnly === false) {
                        return (
                          <GameStarter
                            player={player}
                            changeStatus={(s) => {
                              homeTeamStarters[i].probable.status = s;
                              setHomeTeamStarters(homeTeamStarters);
                              setRefresh(refresh + 1);
                            }}
                          />
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className={"flex flex-row justify-center mb-4 space-x-4 self-center"}>
                <div className={"flex flex-row justify-center self-center"}>
                  <Button label={"Submit " + game.away_team} disabled={submitDisabled} onClick={() => submitStarters(awayTeamStarters)} />
                </div>
                <div className={"flex flex-row self-center"}>
                  <TeamSumUp players={awayTeamStarters} />
                </div>
              </div>
              <table className={"w-full"}>
                <TableHeader />
                <tbody className={"bg-white rounded-b-lg"}>
                  {awayTeamStarters.map((player, i) => {
                    if (player.player.Licensed === true || licensedOnly === false) {
                      return (
                        <GameStarter
                          player={player}
                          changeStatus={(s) => {
                            awayTeamStarters[i].probable.status = s;
                            setAwayTeamStarters(awayTeamStarters);
                          }}
                        />
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default withUser(GameStarters);
