import orderBy from "lodash-es/orderBy";
import { positions_not_all } from "./positions";

export const compareCardPositions = (a, b) => {
  const aPosIndex = positions_not_all.findIndex((val) => a.card.Position === val);
  const bPosIndex = positions_not_all.findIndex((val) => b.card.Position === val);
  return aPosIndex < bPosIndex ? -1 : 1;
};

// Use to populate props on a component that is sortable
export const withSortableProps = (key, onClickSort, sorting) => {
  return {
    sortable: true,
    onSort: () => onClickSort(key),
    sorted: sorting.field === key ? sorting.order : undefined,
  };
};

export const sortData = (sort, data) => {
  const whatToSort = sort.field;
  const howToSort = sort.order;
  return orderBy(data, [whatToSort], [howToSort]);
};
