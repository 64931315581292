export const detailedPositionAbreviations = {
  gk: "GK",
  leftDef: "LB",
  rightDef: "RB",
  centreDef: "CB",
  leftDefMid: "LDM",
  rightDefMid: "RDM",
  centreDefMid: "DM",
  leftMid: "LM",
  rightMid: "RM",
  centreMid: "CM",
  leftAttMid: "LAM",
  rightAttMid: "RAM",
  centreAttMid: "CAM",
  rightFwd: "RF",
  leftFwd: "LF",
  centreFwd: "CF",
};

export const detailedPositionFullName = {
  gk: "Goalkeeper",
  leftDef: "Left Back",
  rightDef: "Right Back",
  centreDef: "Centre Back",
  leftDefMid: "Left Defensive Midfielder",
  rightDefMid: "Right Defensive Midfielder",
  centreDefMid: "Centre Defensive Midfielder",
  leftMid: "Left Midfielder",
  rightMid: "Right Midfielder",
  centreMid: "Centre Midfielder",
  leftAttMid: "Left Attacking Midfielder",
  rightAttMid: "Right Attacking Midfielder",
  centreAttMid: "Centre Attacking Midfielder",
  rightFwd: "Right Forward",
  leftFwd: "Left Forward",
  centreFwd: "Centre Forward",
};
