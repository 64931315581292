export const dateDiffInDays = (a, b) => {
  // https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const getPreviousDay = (date = new Date()) => {
  return getNPreviousDay(date, 1);
};

export const getNPreviousDay = (date = new Date(), numberPreviousDays) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - numberPreviousDays);
  return previous;
};

export const yearsSince = (year) => {
  const currentYear = new Date().getFullYear();
  return currentYear - year;
};
