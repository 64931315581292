import React, { useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { useQuery } from "../util/useQuery";
import LiveCircle from "../util/liveCircle";
import { capitalize } from "../util/capitalize";
import { LIMITED_COLOR, RARE_COLOR, SUPER_RARE_COLOR, UNIQUE_COLOR } from "../util/colors";
import LimitedHeader from "../../img/limited-header-collection.png";
import RareHeader from "../../img/rare-header-collection.png";
import SuperRareHeader from "../../img/super-rare-collection-header.png";
import UniqueHeader from "../../img/unique-header-collection.png";
import BgCard from "../../img/sd-player-header.svg";
import Arrow from "../../img/arrow-left-icon.svg";
import LineSeparator from "../../img/line-separator.svg";
import GridIcon from "../../img/grid-icon.svg";
import SortIcon from "../../img/sort-icon.svg";
import CustomSelect from "../util/customSelect";
import Modal from "../util/modal";
import ManagerCollectionBox from "./managerCollectionBox";
import ManagerCollectionCard from "./managerCollectionCard";

function ManagerCollectionHeaderBox(props) {
  return (
    <div className={"flex flex-col space-y-1"}>
      <p className={"text-xs font-semibold text-[#CCCCCC]"}>{props.title}</p>
      <p className={"text-white font-semibold text-base"}>{props.text}</p>
    </div>
  );
}

function CollectionPart(props) {
  const roster = props.roster;
  const position = props.position;
  let owned = 0;
  if (roster !== null && roster !== undefined) {
    roster.map((c) => {
      if (c.isOwned) {
        owned += 1;
      }
    });
    return roster.map((c, index) => {
      if (position !== "all") {
        if (c.position !== position) {
          return null;
        }
      }
      return (
        <ManagerCollectionCard collection={props.collection} manager={props.manager} user={props.user} key={index + c.position} card={c} />
      );
    });
  } else return null;
}

function BarProgress(props) {
  return (
    <div className={"bg-[#D9D9D9] " + props.rounded + " h-1 w-full self-center"}>
      <div className={"bg-super-rare h-1 " + props.rounded} style={{ width: props.progress + "%" }}></div>
    </div>
  );
}

function PercentageInBar(props) {
  if (props.done) {
    return (
      <div className={"bg-super-rare rounded-full px-1 py-0.5 self-center"}>
        <p className={"text-xxs font-semibold text-white"}>{props.text}</p>
      </div>
    );
  } else {
    return (
      <div className={"bg-[#D9D9D9] rounded-full px-1 py-0.5 self-center"}>
        <p className={"text-xxs font-semibold text-[#666666]"}>{props.text}</p>
      </div>
    );
  }
}

function ScarcityPicker(props) {
  const picked = props.scarcity;
  const rounded = props.rounded || "rounded";

  const pickedStyle = "text-[#FC9869]";
  const notPickedStyle = "text-[#B3B3B3]";
  return (
    <div className={"text-center font-semibold bg-[#333333] flex flex-row space-x-6 text-base px-6 py-2 " + rounded}>
      <div className={"cursor-pointer"} onClick={() => props.change("Limited")}>
        <p className={picked === "Limited" ? pickedStyle : notPickedStyle}>Limited</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("Rare")}>
        <p className={picked === "Rare" ? pickedStyle : notPickedStyle}>Rare</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("Super rare")}>
        <p className={picked === "Super rare" ? pickedStyle : notPickedStyle}>Super rare</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("Unique")}>
        <p className={picked === "Unique" ? pickedStyle : notPickedStyle}>Unique</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("all")}>
        <p className={picked === "all" ? pickedStyle : notPickedStyle}>All</p>
      </div>
    </div>
  );
}

function PositionPicker(props) {
  const picked = props.position;
  const rounded = props.rounded || "rounded";

  const pickedStyle = "text-[#FC9869]";
  const notPickedStyle = "text-[#B3B3B3]";
  return (
    <div className={"text-center font-semibold bg-black-4D flex flex-row space-x-6 text-base px-6 py-2 " + rounded}>
      <div className={"cursor-pointer"} onClick={() => props.change("Goalkeeper")}>
        <p className={picked === "Goalkeeper" ? pickedStyle : notPickedStyle}>Goalkeeper</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("Defender")}>
        <p className={picked === "Defender" ? pickedStyle : notPickedStyle}>Defender</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("Midfielder")}>
        <p className={picked === "Midfielder" ? pickedStyle : notPickedStyle}>Midfielder</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("Forward")}>
        <p className={picked === "Forward" ? pickedStyle : notPickedStyle}>Forward</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change("all")}>
        <p className={picked === "all" ? pickedStyle : notPickedStyle}>All</p>
      </div>
    </div>
  );
}

function ManagerCollection(props) {
  let query = useQuery();

  const filterOptions = [
    { name: "Power impact", id: "power", value: "Power impact" },
    { name: "Position", id: "position", value: "Position" },
    { name: "Shirt Number", id: "shirt", value: "Shirt Number" },
  ];

  const manager = query.get("manager");
  const teamId = query.get("teamId");
  const scarcity = query.get("scarcity");
  const season = query.get("season");

  const [collectionDetails, setCollectionDetails] = useState([]);
  const [filteredCollection, setFilteredCollection] = useState([]);
  const [position, setPosition] = useState("all");
  const [filterOption, setFilterOption] = useState(filterOptions[0]);
  const [showModal, setShowModal] = useState(false);
  const [scarcityFilter, setScarcityFilter] = useState("all");
  const [teamCollections, setTeamCollections] = useState([]);

  const getCollection = () => {
    props
      .fetch("/apiv2/manager/collection?manager=" + manager + "&team=" + teamId + "&scarcity=" + scarcity + "&season=" + season)
      .then((response) => response.json())
      .then((res) => {
        setCollectionDetails(res);
        filter(res);
      })
      .catch(errorCatcher(() => {}));
  };

  useEffect(() => {
    getCollection();
  }, []);

  const getTeamCollections = () => {
    props
      .fetch("/apiv2/manager/teamCollections?manager=" + manager + "&team=" + teamId)
      .then((response) => response.json())
      .then((res) => {
        setTeamCollections(res);
        filter(res);
      })
      .catch(errorCatcher(() => {}));
  };

  const team = collectionDetails?.team;
  let color = "";
  let header = UniqueHeader;
  if (scarcity === "LIMITED") {
    color = LIMITED_COLOR;
    header = LimitedHeader;
  } else if (scarcity === "RARE") {
    color = RARE_COLOR;
    header = RareHeader;
  } else if (scarcity === "SUPER RARE") {
    color = SUPER_RARE_COLOR;
    header = SuperRareHeader;
  } else if (scarcity === "UNIQUE") {
    color = UNIQUE_COLOR;
  }

  const filter = (data, f) => {
    f = f || filterOption;
    data = data || collectionDetails;
    let cards = [...data.allCards];
    if (f.id === "power") {
      cards.sort((a, b) => {
        if (b.cards !== null && a.cards !== null) {
          return b.cards[0].cardCollectionMetadata.total - a.cards[0].cardCollectionMetadata.total;
        } else return 0;
      });
    } else if (f.id === "position") {
      cards.sort((a, b) => {
        if (b.cards !== null && a.cards !== null) {
          return b.cards[0].card.Position - a.cards[0].card.Position;
        } else return 0;
      });
    } else if (f.id === "shirt") {
      cards.sort((a, b) => {
        return a.shirtNumber - b.shirtNumber;
      });
    }
    setFilteredCollection(cards);
  };

  return (
    <div className={"bg-black-1e min-h-screen"}>
      <div
        className={"relative h-64"}
        style={{
          backgroundImage: "url('" + header + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={" w-full mx-auto absolute flex flex-row opacity-5 z-0"}>
          <div className={"left-1/2 transform -translate-x-1/2 md:absolute h-64"}>
            <img className={"w-full object-contain z-0 h-full"} src={BgCard} />
          </div>
        </div>
        <div className={"w-11/12 mx-auto py-10 space-y-6"}>
          <a href={"/manager/" + manager + "/sections/collections"}>
            <div className={"flex flex-row space-x-3"}>
              <img src={Arrow} />
              <p className={"cursor-pointer text-white text-base font-semibold"}>Back to collections</p>
            </div>
          </a>

          <div className={"flex flex-row justify-between"}>
            <div className={"flex flex-row space-x-4"}>
              <div className={"w-16 h-16"}>
                <img src={team?.PictureUrl} />
              </div>
              <div className={"flex flex-col space-y-2 self-center"}>
                <p className={"font-semibold text-2xl text-white font-headers"}>{team?.DisplayName}</p>
                <div className={"flex flex-row space-x-2"}>
                  <div className={"bg-[#FAFAFA] font-semibold text-xs font-semibold px-1.5 py-0.5 rounded-full flex-row flex space-x-1"}>
                    <div className={"self-center"}>
                      <LiveCircle color={color} />
                    </div>
                    <div>
                      <p>{capitalize(scarcity)}</p>
                    </div>
                  </div>
                  <div className={"bg-[#FAFAFA] font-semibold text-xs font-semibold px-1.5 py-0.5 rounded-full"}>{season}</div>
                </div>
              </div>
            </div>
            <div className={"flex flex-row space-x-4"}>
              <button
                type="button"
                {...props}
                onClick={() => {
                  getTeamCollections();
                  setShowModal(true);
                }}
                className="bg-white text-[#1A1A1A] px-4 py-1 h-10 text-sm font-semibold rounded-lg"
              >
                <div className={"flex flex-row space-x-1"}>
                  <img src={GridIcon} />
                  <p>More from this club</p>
                </div>
              </button>
            </div>
          </div>
          {showModal && (
            <Modal
              bgModal={"bg-black-4D"}
              bg={"bg-black-4D"}
              open={showModal}
              size={"w-8/12"}
              content={
                <div className={"bg-black-4D font-sans flex flex-col space-y-4"}>
                  <p className={"text-white font-headers text-xl font-semibold"}>{team?.DisplayName} collections</p>
                  <div className={"flex flex-row"}>
                    <ScarcityPicker scarcity={scarcityFilter} change={(s) => setScarcityFilter(s)} />
                  </div>
                  <div className={"bg-[#1A1A1A] p-4 rounded-lg"}>
                    <div className={"grid grid-cols-4 gap-4"}>
                      {teamCollections !== null &&
                        teamCollections?.map((c, index) => {
                          if (scarcityFilter !== "all") {
                            if (scarcityFilter.toLowerCase() !== c.scarcity.toLowerCase()) {
                              return null;
                            }
                          }
                          return <ManagerCollectionBox c={c} index={index} manager={manager} />;
                        })}
                    </div>
                  </div>
                </div>
              }
              maxSize={"w-8/12"}
              close={() => setShowModal(false)}
            />
          )}
          <div className={"flex flex-row justify-between"}>
            {collectionDetails.ownedCards !== undefined && (
              <div className={"flex flex-row space-x-10"}>
                <ManagerCollectionHeaderBox title={"Players"} text={collectionDetails.players + "/" + collectionDetails.totalCards} />
                <ManagerCollectionHeaderBox title={"Cards"} text={collectionDetails.ownedCards} />
                <ManagerCollectionHeaderBox title={"Bonus"} text={"+" + collectionDetails.bonus + "%"} />
                <div>
                  <div className={"border h-full border-[#B3B3B3]"}></div>
                </div>
                <ManagerCollectionHeaderBox title={"Serial #1"} text={collectionDetails.firstSerial + "/" + collectionDetails.totalCards} />
                <ManagerCollectionHeaderBox
                  title={"Jersey mint"}
                  text={collectionDetails.jerseyMint + "/" + collectionDetails.totalCards}
                />
                <ManagerCollectionHeaderBox title={"Only owner"} text={collectionDetails.onlyOwner + "/" + collectionDetails.totalCards} />
                <ManagerCollectionHeaderBox title={"Held 90 days"} text={collectionDetails.held + "/" + collectionDetails.totalCards} />
                <ManagerCollectionHeaderBox
                  title={"Special Edition"}
                  text={collectionDetails.specialEdition + "/" + collectionDetails.totalCards}
                />
              </div>
            )}
            <div className={"flex flex-row space-x-4 w-4/12"}>
              <div className={"flex flex-col space-y-1"}>
                <p className={"text-xs font-semibold text-[#CCCCCC]"}>Score</p>
                <p className={"text-white font-semibold text-base"}>
                  {collectionDetails.totalScore}
                  <span className={"text-xs text-[#B3B3B3]"}>/{collectionDetails.nextLevel}</span>
                </p>
              </div>
              <div className={"flex flex-col space-y-2 w-full self-center"}>
                <div className={"flex flex-row justify-between text-xs text-[#B3B3B3] font-semibold"}>
                  {collectionDetails.bonus < 5 && (
                    <p>
                      {collectionDetails.nextLevel - collectionDetails.totalScore} pts to level {collectionDetails.bonus + 1}
                    </p>
                  )}
                  {collectionDetails.bonus === 5 && <p>At max level</p>}
                  {collectionDetails.bonus < 5 && <p>{750 - collectionDetails.totalScore} pts to max</p>}
                </div>
                <div className={"flex flex-row"}>
                  <BarProgress
                    rounded={"rounded-l-full"}
                    progress={collectionDetails.bonus >= 1 ? 100 : collectionDetails.bonus === 0 ? collectionDetails.progress : 0}
                  />
                  <PercentageInBar done={collectionDetails.bonus >= 1} text={"1%"} />
                  <BarProgress
                    rounded={""}
                    progress={collectionDetails.bonus >= 2 ? 100 : collectionDetails.bonus === 1 ? collectionDetails.progress : 0}
                  />
                  <PercentageInBar done={collectionDetails.bonus >= 2} text={"2%"} />
                  <BarProgress
                    rounded={""}
                    progress={collectionDetails.bonus >= 3 ? 100 : collectionDetails.bonus === 2 ? collectionDetails.progress : 0}
                  />
                  <PercentageInBar done={collectionDetails.bonus >= 3} text={"3%"} />
                  <BarProgress
                    rounded={""}
                    progress={collectionDetails.bonus >= 4 ? 100 : collectionDetails.bonus === 3 ? collectionDetails.progress : 0}
                  />
                  <PercentageInBar done={collectionDetails.bonus >= 4} text={"4%"} />
                  <BarProgress
                    rounded={""}
                    progress={collectionDetails.bonus >= 5 ? 100 : collectionDetails.bonus === 4 ? collectionDetails.progress : 0}
                  />
                  <PercentageInBar done={collectionDetails.bonus >= 5} text={"5%"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"bg-black-1e w-11/12 mx-auto py-8 space-y-8"}>
        <div className={"flex flex-row justify-between"}>
          <div className={"w-48"}>
            <CustomSelect
              icon={<img src={SortIcon} />}
              onChange={(o) => {
                setFilterOption(o);
                filter(collectionDetails, o);
              }}
              options={filterOptions}
              bg={"bg-[#333333]"}
              font={"text-white font-semibold"}
            />
          </div>
          <div>
            <PositionPicker position={position} change={(p) => setPosition(p)} />
          </div>
        </div>
        <div className={"grid grid-cols-6 gap-4"}>
          <CollectionPart
            collection={collectionDetails}
            manager={manager}
            user={props.user}
            position={position}
            roster={filteredCollection}
          />
        </div>
      </div>
    </div>
  );
}

export default withUser(ManagerCollection);
