import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withUser } from "../../userContext";
import { formatPrice } from "../util/formatMoney";
import { capitalize } from "../util/capitalize";
import { Chart } from "react-chartjs-2";
import "./helpers/customGraph.css";
import { errorCatcher } from "../util/errors";
import { positions_objects } from "../util/positions";
import "chartjs-adapter-date-fns";
import { CUSTOM_SERIES_COLOR, RARE_COLOR, SUPER_RARE_COLOR, UNIQUE_COLOR } from "../util/colors";
import Locker from "../../img/brand-close-locker-nude.svg";
import TooltipPaywallPriceChart from "../../img/tooltip-paywall-price-chart.png";
import { t1OrAbove, t2OrAbove } from "../util/handleSubscriptionTier";
import { isSportFree, sorareBaseball, sorareBasketball } from "../util/sports";
import { dateDiffInDays, getNPreviousDay } from "../util/date";
import SalesDot from "../../img/icons-chart-dot-sales.svg";
import SalesDotClassic from "../../img/icons-chart-dot-sales-classic.svg";
import SalesDotLimited from "../../img/icons-chart-dot-sales-limited.svg";
import SalesDotRare from "../../img/icons-chart-dot-sales-rare.svg";
import SalesDotSuperRare from "../../img/icons-chart-dot-sales-super-rare.svg";
import SalesDotUnique from "../../img/icons-chart-dot-sales-unique.svg";
import PurchasesDot from "../../img/icons-chart-dot-purchases.svg";
import PurchasesDotClassic from "../../img/icons-chart-dot-purchases-classic.svg";
import PurchasesDotLimited from "../../img/icons-chart-dot-purchases-limited.svg";
import PurchasesDotRare from "../../img/icons-chart-dot-purchases-rare.svg";
import PurchasesDotSuperRare from "../../img/icons-chart-dot-purchases-super-rare.svg";
import PurchasesDotUnique from "../../img/icons-chart-dot-purchases-unique.svg";
import clsx from "clsx";
import { getThemeColor, useTheme } from "../../themeContext";
import { PriceGraphFilters } from "./priceGraphFilters";
import { CardPriceDetailsByScarcity } from "./playerPriceDetails";
import {
  defaultPriceGraphPreferences,
  getPriceGraphPreferencesFromLocalStorage,
  setPriceGraphPreferencesToLocalStorage,
} from "./priceGraphFilterPanels";
import { split_price_all } from "../util/splits";
import { getScarcityInfo } from "../util/scarcities";

function isDataSetAvailable(id, tier, sport) {
  switch (id) {
    case "Current floor price":
      return t1OrAbove(tier) || isSportFree(sport);
    case "Valuation history":
      return t1OrAbove(tier) || isSportFree(sport);
    case "Your purchases":
      return t2OrAbove(tier) || isSportFree(sport);
    case "Your sales":
      return t2OrAbove(tier) || isSportFree(sport);
    default:
      return true;
  }
}

const PlayerPriceGraph = withUser((props) => {
  const now = new Date();
  const before = new Date();
  const yearToDate = new Date(now.getFullYear(), 0, 1);
  const [graph, setGraph] = useState([]);
  const [startDate, setStartDate] = useState(new Date(before.setMonth(before.getMonth() - 12)));
  const [endDate, setEndDate] = useState(now);
  const [filteredGraph, setFilteredGraph] = useState([]);
  const [showPrivate, setShowPrivate] = useState(true);
  const [valHistory, setValHistory] = useState({});
  const [valHistories, setValHistories] = useState([]);
  const [floor, setFloor] = useState(0);
  const [customSeriesType, setCustomSeriesType] = useState("all");
  const [positionOptions, setPositionOptions] = useState(props.positions || positions_objects);
  const [selectedSplit, setSelectedSplit] = useState(undefined);
  const [graphCurrency, setGraphCurrency] = useState("");

  // filters
  const emptyFilters = {
    period: "1m",
    scarcity: props.scarcity || props.user.preferredScarcity,
    seasonality: split_price_all,
    showExpired: false,
    positions: [],
    seasons: [],
    startDate: yearToDate,
    endDate: now,
    rangeDateEnabled: false,
  };
  const [filters, setFilters] = useState(emptyFilters);

  // preferences
  const [preferences, setPreferences] = useState(getPriceGraphPreferencesFromLocalStorage);
  useEffect(() => {
    setPriceGraphPreferencesToLocalStorage(preferences);
  }, [preferences]);

  const { theme } = useTheme();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let newCurrency = preferences.currency;
    if (preferences.currency === "") {
      newCurrency = "eth";
      if (props.user.preferredUnit !== "eth") {
        if (props.user.preferredUnit === "eur") {
          newCurrency = "eur";
        } else if (props.user.preferredUnit === "usd") {
          newCurrency = "usd";
        } else if (props.user.preferredUnit === "gbp") {
          newCurrency = "gbp";
        }
        setPreferences({ ...preferences, currency: newCurrency });
      }
    }
    let start = new Date(); // add arguments as needed
    let end = new Date();
    let ytd = new Date(new Date().getFullYear(), 0, 1);

    switch (filters.period) {
      case "1w": {
        start.setTime(start.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      }
      case "1m": {
        start.setTime(start.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      }
      case "3m": {
        start.setTime(start.getTime() - 3 * 30 * 24 * 60 * 60 * 1000);
        break;
      }
      case "6m": {
        start.setTime(start.getTime() - 6 * 30 * 24 * 60 * 60 * 1000);
        break;
      }
      case "1y": {
        start.setTime(start.getTime() - 365 * 24 * 60 * 60 * 1000);
        break;
      }
      case "ytd": {
        start.setTime(ytd.getTime());
        break;
      }
      case "all": {
        if (props.sport === sorareBasketball) {
          // 18/10/2022 - first basketball auction
          start.setTime(1666044010000);
        } else if (props.sport === sorareBaseball) {
          // 19/07/2022 - first baseball auction
          start.setTime(1658181610000);
        } else {
          // April 1st 2019
          start.setTime(1554073210000);
        }
        break;
      }
    }
    if (filters.rangeDateEnabled) {
      start = filters.startDate;
      end = filters.endDate;
    }
    setStartDate(start);
    setEndDate(end);
    getGraph(signal, start, now, newCurrency);
    if (props.split) {
      setSelectedSplit(props.split[filters.scarcity]);
    }
    return () => {
      abortController.abort();
    };
  }, [filters, preferences, props.player?.PlayerId]);

  const shouldHighlightInSeason = (filters) => {
    return preferences.highlightInSeason && filters.seasonality === split_price_all;
  };

  const getGraph = (signal, s, e, c, ex, sc, se, p, ty, sf, hs) => {
    let start = s || startDate;
    let end = e || endDate;
    start = start.toISOString();
    end = end.toISOString();
    if (!c) c = preferences.currency;
    if (ex === undefined) ex = filters.showExpired;
    if (sc === undefined) sc = filters.scarcity;
    if (sf === undefined) sf = filters.seasonality;
    if (hs === undefined) hs = preferences.highlightInSeason;
    // disable highlightInSeason when split is not all
    if (!shouldHighlightInSeason(filters)) {
      hs = false;
    }
    let pos = p || filters.positions;
    let sea = se || filters.seasons;
    ty = ty || customSeriesType;
    props
      .fetch("/apiv2/players/price-graph-chart", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          player_id: props.player.PlayerId,
          scarcity: sc,
          start_date: start,
          end_date: end,
          currency: c,
          sorare_slug: props.user.sorareSlug,
          expired: ex,
          seasons: sea,
          positions: pos,
          custom_series_type: ty,
          seasonality_filter: sf,
          split_graphs: hs,
        }),
        signal: signal,
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.prices !== null) {
          setGraph(res.prices);
          setGraphCurrency(c);
          setFilteredGraph(res.prices);
          setFloor(res.floor);
          filterGraph(res.prices, ex);
        }
        setValHistory(res.history);
        setValHistories(res.histories || []);
      })
      .catch(errorCatcher());
  };

  const highlightInfo = getHighlightGraphInfo(theme, filters.scarcity);
  const filterGraph = (g, ex) => {
    let newGraph = graph;
    if (g) newGraph = g;
    if (ex === undefined) ex = filters.showExpired;
    let filtered =
      newGraph?.filter(function (value, index, arr) {
        if (value.data === null && isDataSetAvailable(value.id, props.user.tier, props.sport)) {
          return false;
        }
        if (value.id === "Auctions" || value.id === "Public offers") return true;
        else if (value.id === "Direct offers") {
          return showPrivate;
        } else if (value.id === "Expired listings") {
          return ex === true;
        }
        return true;
      }) || [];
    filtered = filtered.map(mapChartData);

    if (shouldHighlightInSeason(filters)) {
      filtered = filtered.map(mapChartDataHighlighted(highlightInfo));
    }
    if (filtered.length === 0) {
      setFilteredGraph([]);
    } else {
      setFilteredGraph(filtered);
    }
  };

  useEffect(() => filterGraph(), [showPrivate]);

  let min = startDate;
  if (filters.period === "all") {
    const minFromData = graph && graph.length > 0 ? graph[0].min_date : undefined;
    if (minFromData !== undefined) {
      try {
        const minDateFromData = new Date(Date.parse(minFromData));
        const diffDays = dateDiffInDays(minDateFromData, endDate);
        // Use a start day with an offset, to avoid the first auction/offers pins to be cut off
        // The more days, the more offset
        min = getNPreviousDay(minDateFromData, diffDays > 365 ? 3 : diffDays > 182 ? 2 : 1);
      } catch (e) {
        min = "auto";
      }
    } else {
      min = "auto";
    }
  }

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        min: min,
        max: endDate,
        ticks: {
          maxTicksLimit: 20,
        },
      },
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return formatPrice(value, graphCurrency);
          },
        },
        min: 0,
      },
    },
    defaults: {
      font: "Poppins",
    },
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          mode: "xy",
        },
        limits: {
          y: { min: 0, max: "original" },
          x: { min: 0, max: "original" },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          boxHeight: 6,
          fontColor: getThemeColor(theme, "on-surface"),
          generateLabels: function (chart) {
            const data = chart.data;
            const locker = new Image(12, 12);
            locker.src = Locker;

            if (data.datasets.length) {
              return data.datasets.map(function (ds, i) {
                const meta = chart.getDatasetMeta(i);
                const pointStyle = ds.pointStyle ? ds.pointStyle : ds.type === "scatter" ? "rect" : "line";
                const width = pointStyle?.width || 0;
                return {
                  text: (width > 10 ? "  " : "") + ds.label,
                  hidden: meta?.hidden,
                  index: i,
                  usePointStyle: true,
                  boxWidth: 14,
                  boxHeight: 14,
                  fontColor: getThemeColor(theme, "on-surface"),
                  lineWidth: ds.borderColor ? ds.borderWidth : 0,
                  datasetIndex: i,
                  pointStyle: ds.available ? pointStyle : locker,
                  strokeStyle: ds.borderColor,
                  fillStyle: ds.backgroundColor,
                  available: ds.available,
                };
              });
            }
            return [];
          },
        },
        onClick: function (e, legendItem, legend) {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          if (!legendItem.available) {
            return;
          }

          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
          } else {
            ci.show(index);
            legendItem.hidden = false;
          }
        },
      },
      datalabels: {
        color: "white",
        font: {
          size: 8,
          family: "Inter",
          weight: "bold",
        },
        formatter: function (value, context) {
          return "";
        },
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          if (context.tooltip.dataPoints && context.tooltip.dataPoints.length > 0 && context.tooltip.dataPoints[0].raw.card) {
            const node = context.tooltip.dataPoints[0].raw;
            let price = formatPrice(node.y, "eth");
            if (preferences.currency !== "eth") {
              price = formatPrice(node.y, preferences.currency);
            }
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip");

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.innerHTML = "<table></table>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add("no-transform");
            }

            // Set Text
            if (tooltipModel.body) {
              let innerHtml = "<PopperPortal class='z-500' active={true}>\n";
              if (t1OrAbove(props.user?.tier) || isSportFree(props.sport)) {
                innerHtml += '<div class="sd-light bg-white font-sans z-500 px-3 py-3 space-y-2 text-sm rounded shadow-md">\n';
                innerHtml += '<div class="flex justify-center">\n';
                innerHtml += '<img class="w-32" src="' + node.card.PictureUrl + '"/></div>';
                innerHtml += '<div class="text-center space-y-0.5">';
                innerHtml += "<p>" + capitalize(node.type) + ' - <span class="font-semibold">' + price + "</span></p>";
                innerHtml += "<p>#" + node.card.SerialNumber + " - " + node.card.Season + "</p>";
                innerHtml += "<p class='text-xs'>" + new Date(node.x).toLocaleString() + "</p>";
                if (node.buyer !== "" && node.seller !== "") {
                  innerHtml += "<p class='text-xs'>" + node.seller + " <==> " + node.buyer + "</p>";
                }
                if (node.buyer !== "" && node.seller === "") {
                  innerHtml += "<p class='text-xs'>Bought by " + node.buyer + "</p>";
                }
                if (node.seller !== "" && node.buyer === "") {
                  innerHtml += "<p class='text-xs'>Sold by " + node.seller + "</p>";
                }
                innerHtml += "</div></div></PopperPortal>";
              } else {
                innerHtml +=
                  "<div style=\"background-repeat:'no-repeat'; background-size: 'cover'; background-image: url('" +
                  TooltipPaywallPriceChart +
                  "'); background-position:center;background-size:'no-repeat'\" " +
                  'class="w-48 z-500 h-auto space-y-2 text-sm rounded shadow-md">\n';
                innerHtml += "<div class='flex flex-col items-center space-y-4 py-10 px-4'>";
                innerHtml += "<p class='text-center font-extrabold text-base font-headers text-brand-black'>Want more insights?</p>";
                innerHtml +=
                  "<p class='text-center font-medium text-sm text-brand-black'>Become a Star member to unlock transaction details.</p>";
                innerHtml += "<p class='text-center font-semibold text-sm text-brand-black'>Click the banner below to upgrade.</p>";
                innerHtml += "</div></PopperPortal>";
              }

              let tableRoot = tooltipEl.querySelector("table");
              tableRoot.innerHTML = innerHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 5 + "px";
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 150 + "px";
            tooltipEl.style.font = "Inter";
            tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.zIndex = "500";
          }
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        borderWidth: 0,
        label: "",
      },
    },
    maintainAspectRatio: false,
  };

  let backgroundColor = "rgba(231, 182, 44, 0.1)";
  let borderColor = "#E7B62C";
  if (filters.scarcity === "RARE") {
    backgroundColor = "rgba(205, 52, 52, 0.1)";
    borderColor = RARE_COLOR;
  } else if (filters.scarcity === "SUPER RARE") {
    backgroundColor = "rgba(31, 161, 255, 0.1)";
    borderColor = SUPER_RARE_COLOR;
  } else if (filters.scarcity === "UNIQUE") {
    backgroundColor = "rgba(69, 40, 1, 0.1)";
    borderColor = UNIQUE_COLOR;
  } else if (filters.scarcity === "CUSTOM SERIES") {
    backgroundColor = "rgba(141, 50, 228, 0.1)";
    borderColor = CUSTOM_SERIES_COLOR;
  }

  let datasets = filteredGraph;
  if (floor > 0) {
    datasets = datasets.concat({
      id: "Current floor price",
      label: "Current floor price",
      type: "line",
      pointRadius: 0,
      backgroundColor: "white",
      borderColor: borderColor,
      borderDash: [7, 11],
      borderWidth: 2,
      fill: false,
      data: [
        { x: startDate, y: floor },
        { x: endDate, y: floor },
      ],
    });
  }

  if (!shouldHighlightInSeason(filters)) {
    datasets = datasets.concat({
      data: valHistory.data || [],
      label: "Daily valuation history",
      type: "scatter",
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      pointRadius: 1,
      fill: "origin",
      id: "Valuation history",
    });
  } else {
    valHistories.forEach((h, i) => {
      if (h.id === "Valuation history new season") {
        datasets = datasets.concat({
          data: h.data || [],
          label: "Daily valuation history in-season",
          type: "scatter",
          borderColor: getOpacity15(highlightInfo.inSeasonColor),
          backgroundColor: getOpacity15(highlightInfo.inSeasonColor),
          pointRadius: 1,
          fill: "origin",
          id: "Valuation history new season",
        });
      } else if (h.id === "Valuation history classic") {
        datasets = datasets.concat({
          data: h.data || [],
          label: "Daily valuation history classic",
          type: "scatter",
          borderColor: getOpacity10(highlightInfo.classicColor),
          backgroundColor: getOpacity10(highlightInfo.classicColor),
          pointRadius: 1,
          fill: "origin",
          id: "Valuation history classic",
        });
      }
    });
  }

  //Add available boolean
  datasets = datasets
    .map((ds, i) => {
      var available = isDataSetAvailable(ds.id, props.user.tier, props.sport);
      return {
        ...ds,
        available: available,
        data: available ? ds.data : [],
      };
    })
    .sort((prev, next) => {
      return prev.available === true && next.available === false ? -1 : 0;
    });

  const data = {
    datasets: datasets,
  };

  const onFilterChange = useCallback(
    (key, value) =>
      setFilters((prev) => ({
        ...prev,
        [key]: value,
      })),
    [],
  );
  const onResetFilters = useCallback(() => setFilters(emptyFilters), []);

  const onPreferenceChange = useCallback(
    (key, value) =>
      setPreferences((prev) => ({
        ...prev,
        [key]: value,
      })),
    [],
  );
  const onResetPreferences = useCallback(() => setPreferences(defaultPriceGraphPreferences), []);
  const withLateralDisplay = props.split !== undefined;

  return (
    <div>
      <div className="space-y-2 rounded-lg my-4">
        <PriceGraphFilters
          filters={filters}
          onFilterChange={onFilterChange}
          onResetFilters={onResetFilters}
          preferences={preferences}
          onPreferenceChange={onPreferenceChange}
          onResetPreferences={onResetPreferences}
          sport={props.sport}
          positionOptions={positionOptions}
          withLateralDisplay={withLateralDisplay}
          tier={props.user.tier}
        />
      </div>
      <div>
        {filteredGraph?.length === 0 && (
          <div className={"text-sm text-center py-8"}>
            <p>No sale was made during the provided time frame.</p>
          </div>
        )}

        <div className={"bg-surface-container rounded-lg shadow p-4 relative grid grid-cols-4"}>
          <div
            className={clsx(
              "h-full min-h-[25rem] md:min-h-[33rem] col-span-4",
              withLateralDisplay && preferences.showLateralPriceDetails ? "md:col-span-3" : "",
            )}
          >
            <Chart type={"scatter"} options={options} data={data} datasetIdKey={"id"} />
          </div>
          {preferences.showLateralPriceDetails && withLateralDisplay && (
            <div className="border-l border-outline-variant h-full hidden md:block md:px-3">
              <CardPriceDetailsByScarcity split={selectedSplit} scarcity={filters.scarcity} sport={props.sport} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default withUser(PlayerPriceGraph);

const mapChartData = (m) => {
  if (m.id === "Auctions") {
    m.backgroundColor = "#2F42C5";
    m.borderColor = "#056BCA";
    m.borderWidth = 1;
    m.pointRadius = 7;
    m.label = "Auctions";
    m.type = "scatter";
    m.pointStyle = "triangle";
  } else if (m.id === "Instant buys") {
    m.backgroundColor = "transparent";
    m.borderColor = "#2F42C5";
    m.borderWidth = 2;
    m.pointRadius = 7;
    m.label = "Instant buys";
    m.type = "scatter";
    m.pointStyle = "triangle";
  } else if (m.id === "Public offers") {
    m.backgroundColor = "#0AC2AA";
    m.borderColor = "#0BB19B";
    m.borderWidth = 1;
    m.label = "Public offers";
    m.type = "scatter";
    m.pointStyle = "circle";
  } else if (m.id === "Direct offers") {
    m.borderColor = "#0AC2AA";
    m.borderWidth = 2;
    m.backgroundColor = "transparent";
    m.label = "Direct offers";
    m.type = "scatter";
    m.pointStyle = "circle";
    m.fill = false;
  } else if (m.id === "Your purchases") {
    m.backgroundColor = "#F55205";
    m.label = "Your purchases";
    m.type = "scatter";
    const img = new Image(20, 20);
    img.src = PurchasesDot;
    m.pointStyle = img;
  } else if (m.id === "Your sales") {
    m.borderColor = "#F55205";
    m.label = "Your sales";
    m.type = "scatter";
    const img = new Image(20, 20);
    img.src = SalesDot;
    m.pointStyle = img;
  } else if (m.id === "Expired listings") {
    m.backgroundColor = "#B3B3B3";
    m.label = "Expired listings";
    m.type = "scatter";
    m.pointStyle = "circle";
  }
  return m;
};

const getHighlightGraphInfo = (theme, scarcity) => {
  const removePrefix = (str, prefix) => str.replace(new RegExp(`^${prefix}`), "");
  const { color, bgColor } = getScarcityInfo(scarcity);
  const themeColor = removePrefix(color, "text-");
  const inSeasonColor = getThemeColor(theme, themeColor);
  const classicColor = getThemeColor(theme, "on-surface-variant");
  let purchaseIcon = PurchasesDot;
  let saleIcon = SalesDot;
  switch (scarcity) {
    case "LIMITED":
      purchaseIcon = PurchasesDotLimited;
      saleIcon = SalesDotLimited;
      break;
    case "RARE":
      purchaseIcon = PurchasesDotRare;
      saleIcon = SalesDotRare;
      break;
    case "SUPER RARE":
      purchaseIcon = PurchasesDotSuperRare;
      saleIcon = SalesDotSuperRare;
      break;
    case "UNIQUE":
      purchaseIcon = PurchasesDotUnique;
      saleIcon = SalesDotUnique;
      break;
  }

  return { inSeasonColor, classicColor, purchaseIcon, saleIcon };
};

const mapChartDataHighlighted = ({ inSeasonColor, classicColor, purchaseIcon, saleIcon }) => {
  return (m) => {
    if (m.id === "Auctions new season") {
      m.backgroundColor = inSeasonColor;
      m.borderColor = inSeasonColor;
      m.borderWidth = 1;
      m.pointRadius = 7;
      m.label = "Auctions in-season";
      m.type = "scatter";
      m.pointStyle = "triangle";
    } else if (m.id === "Auctions classic") {
      m.backgroundColor = classicColor;
      m.borderColor = classicColor;
      m.borderWidth = 1;
      m.pointRadius = 7;
      m.label = "Auctions classic";
      m.type = "scatter";
      m.pointStyle = "triangle";
    } else if (m.id === "Instant buys new season") {
      m.backgroundColor = "transparent";
      m.borderColor = inSeasonColor;
      m.borderWidth = 2;
      m.pointRadius = 7;
      m.label = "Instant buys in-season";
      m.type = "scatter";
      m.pointStyle = "triangle";
    } else if (m.id === "Instant buys classic") {
      m.backgroundColor = "transparent";
      m.borderColor = classicColor;
      m.borderWidth = 2;
      m.pointRadius = 7;
      m.label = "Instant buys classic";
      m.type = "scatter";
      m.pointStyle = "triangle";
    } else if (m.id === "Public offers new season") {
      m.backgroundColor = inSeasonColor;
      m.borderColor = inSeasonColor;
      m.borderWidth = 1;
      m.label = "Public offers in-season";
      m.type = "scatter";
      m.pointStyle = "circle";
    } else if (m.id === "Public offers classic") {
      m.backgroundColor = classicColor;
      m.borderColor = classicColor;
      m.borderWidth = 1;
      m.label = "Public offers classic";
      m.type = "scatter";
      m.pointStyle = "circle";
    } else if (m.id === "Direct offers new season") {
      m.borderColor = inSeasonColor;
      m.borderWidth = 2;
      m.backgroundColor = "transparent";
      m.label = "Direct offers in-season";
      m.type = "scatter";
      m.pointStyle = "circle";
      m.fill = false;
    } else if (m.id === "Direct offers classic") {
      m.borderColor = classicColor;
      m.borderWidth = 2;
      m.backgroundColor = "transparent";
      m.label = "Direct offers classic";
      m.type = "scatter";
      m.pointStyle = "circle";
      m.fill = false;
    } else if (m.id === "Your purchases new season") {
      m.backgroundColor = inSeasonColor;
      m.label = "Your purchases in-season";
      m.type = "scatter";
      const img = new Image(20, 20);
      img.src = purchaseIcon;
      m.pointStyle = img;
    } else if (m.id === "Your purchases classic") {
      m.label = "Your purchases classic";
      const img = new Image(20, 20);
      img.src = PurchasesDotClassic;
      m.pointStyle = img;
    } else if (m.id === "Your sales new season") {
      m.label = "Your sales in-season";
      m.type = "scatter";
      const img = new Image(20, 20);
      img.src = saleIcon;
      m.pointStyle = img;
    } else if (m.id === "Your sales classic") {
      m.label = "Your sales classic";
      m.type = "scatter";
      const img = new Image(20, 20);
      img.src = SalesDotClassic;
      m.pointStyle = img;
    } else if (m.id === "Expired listings new season") {
      m.backgroundColor = getOpacity40(inSeasonColor);
      m.label = "Expired listings in-season";
      m.type = "scatter";
      m.pointStyle = "circle";
    } else if (m.id === "Expired listings classic") {
      m.backgroundColor = getOpacity40(classicColor);
      m.label = "Expired listings classic";
      m.type = "scatter";
      m.pointStyle = "circle";
    }
    return m;
  };
};

const getOpacity40 = (hexColor) => {
  return hexColor + "66";
};

const getOpacity15 = (hexColor) => {
  return hexColor + "26";
};

const getOpacity10 = (hexColor) => {
  return hexColor + "1A";
};
