import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import SDLoading from "../util/SDLoading";
import GLOBAL_MARGIN from "../util/margin";
import UnknownClub from "../../img/unknown_club.png";
import UnknownPlayer from "../../img/unknown_player2.png";
import { errorCatcher } from "../util/errors";

function PlayerSearchBox(props) {
  const item = props.item;
  return (
    <a href={"/player/" + item.player.PlayerId}>
      <div className={"bg-white rounded-md hover:bg-grey-f2 shadow-sm flex flex-row items-center justify-between p-4 space-x-4"}>
        <div>
          <div className={"w-12 h-12 rounded-full"}>
            <img
              className={"h-full w-full rounded-full object-contain pt-1 bg-grey-f2"}
              src={item.player.Avatar !== "" ? item.player.Avatar : UnknownPlayer}
            />
          </div>
        </div>

        <div className={"flex flex-col self-center w-10/12"}>
          <p className={"font-semibold text-sm"}>{item.player.DisplayName}</p>
          <p className={"text-brand font-semibold text-xs"}>{item.team.ClubName !== "" ? item.team.ClubName : "No club"}</p>
        </div>
        <div className={"w-8 h-8 self-center"}>
          <img src={item.team.PictureUrl !== "" ? item.team.PictureUrl : UnknownClub} className={"w-full h-full object-contain"} />
        </div>
      </div>
    </a>
  );
}

function UserSearchBox(props) {
  const user = props.item;
  return (
    <a href={"/manager/" + user.Slug}>
      <div className={"bg-white rounded-md hover:bg-grey-f2 shadow-sm flex flex-row items-center justify-between p-4 space-x-4"}>
        <div>
          <div className={"w-12 h-12 rounded-full"}>
            <img
              className={"h-full w-full rounded-full object-contain pt-1 bg-grey-f2"}
              src={user.PictureUrl !== "" ? user.PictureUrl : UnknownClub}
            />
          </div>
        </div>

        <div className={"flex flex-col self-center w-10/12"}>
          <p className={"font-semibold text-sm"}>{user.Nickname}</p>
          <p className={"text-brand font-semibold text-xs"}>{user.TeamName}</p>
        </div>
      </div>
    </a>
  );
}

function TeamSearchBox(props) {
  const team = props.item;
  return (
    <a href={"/team/" + team.TeamId}>
      <div className={"bg-white rounded-md hover:bg-grey-f2 shadow-sm flex flex-row items-center justify-start p-4 space-x-4"}>
        <div>
          <div className={"w-12 h-12 rounded-full"}>
            <img
              className={"h-full w-full rounded-full object-contain pt-1 bg-grey-f2"}
              src={team.PictureUrl !== "" ? team.PictureUrl : UnknownPlayer}
            />
          </div>
        </div>

        <div className={"flex flex-col self-center"}>
          <p className={"font-semibold text-sm"}>{team.ClubName}</p>
          <p className={"text-brand font-semibold text-xs"}>{team.League}</p>
        </div>
      </div>
    </a>
  );
}

function PlayerSearch(props) {
  const [licensedPlayers, setLicensedPlayers] = useState([]);
  const [unlicensedPlayers, setUnlicensedPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [managers, setManagers] = useState([]);
  const [show, setShow] = useState([true, true, true, true]);
  const [loading, setLoading] = useState(true);

  useEffect(() => searchPlayers(), [props.match.params.playerName]);

  const searchPlayers = () => {
    setLoading(true);
    props
      .fetch("/apiv2/players/search/" + props.match.params.playerName)
      .then((response) => response.json())
      .then((res) => {
        setShow([true, true, true, true]);
        if (res.several_entries_found === false) {
          if (res.licensed_players !== null && res.licensed_players.length > 0) {
            props.history.push("/player/" + res.licensed_players[0].player.PlayerId);
          } else if (res.unlicensed_players !== null && res.unlicensed_players.length > 0) {
            props.history.push("/player/" + res.unlicensed_players[0].player.PlayerId);
          } else if (res.teams !== null && res.teams.length > 0) {
            props.history.push("/team/" + res.teams[0].TeamId);
          } else if (res.managers !== null && res.managers.length > 0) {
            props.history.push("/manager/" + res.managers[0].Slug);
          }
        } else {
          if (res.licensed_players !== null) {
            setLicensedPlayers(res.licensed_players);
          }
          if (res.unlicensed_players !== null) {
            setUnlicensedPlayers(res.unlicensed_players);
          }
          if (res.teams !== null) {
            setTeams(res.teams);
          }
          if (res.managers !== null) {
            setManagers(res.managers);
          }
        }
      })
      .catch(errorCatcher())
      .finally(() => setLoading(false));
  };

  const activeClass = "bg-white p-2 shadow-sm text-sm font-semibold rounded cursor-pointer";
  const inactiveClass = "bg-white p-2 shadow-sm text-sm font-semibold rounded cursor-pointer opacity-50";

  const changeShow = (index) => {
    let newShow = [...show];
    newShow[index] = !newShow[index];
    setShow(newShow);
  };

  return (
    <div className={GLOBAL_MARGIN}>
      <div className={"mt-4 space-y-4"}>
        <div>
          <p className={"font-headers text-xl font-semibold"}>Search results for &quot;{props.match.params.playerName}&quot;</p>
        </div>
        <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 text-center"}>
          <div onClick={() => changeShow(0)} className={licensedPlayers.length > 0 && show[0] === true ? activeClass : inactiveClass}>
            <p>Players with Sorare cards ({licensedPlayers.length})</p>
          </div>
          <div onClick={() => changeShow(1)} className={unlicensedPlayers.length > 0 && show[1] === true ? activeClass : inactiveClass}>
            <p>Players without Sorare cards ({unlicensedPlayers.length})</p>
          </div>
          <div onClick={() => changeShow(2)} className={managers.length > 0 && show[2] === true ? activeClass : inactiveClass}>
            <p>Sorare managers ({managers.length})</p>
          </div>
          <div onClick={() => changeShow(3)} className={teams.length > 0 && show[3] === true ? activeClass : inactiveClass}>
            <p>Football teams ({teams.length})</p>
          </div>
        </div>
        <div>
          {loading && <SDLoading />}
          {licensedPlayers.length + unlicensedPlayers.length + managers.length + teams.length > 1 && (
            <div className={"flex flex-col space-y-6"}>
              {show[0] === true && licensedPlayers.length > 0 && (
                <div className={"space-y-4"}>
                  <p className={"text-md font-semibold"}>Players with Sorare cards ({licensedPlayers.length})</p>
                  <div className={"grid gris-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4"}>
                    {licensedPlayers.map((p) => {
                      return <PlayerSearchBox item={p} />;
                    })}
                  </div>
                </div>
              )}
              {show[1] === true && unlicensedPlayers.length > 0 && (
                <div className={"space-y-4"}>
                  <p className={"text-md font-semibold"}>Players without Sorare cards ({unlicensedPlayers.length})</p>
                  <div className={"grid gris-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4"}>
                    {unlicensedPlayers.map((p) => {
                      return <PlayerSearchBox item={p} />;
                    })}
                  </div>
                </div>
              )}
              {show[2] === true && managers.length > 0 && (
                <div className={"space-y-4"}>
                  <p className={"text-md font-semibold"}>Sorare managers ({managers.length})</p>
                  <div className={"grid gris-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4"}>
                    {managers.map((p) => {
                      return <UserSearchBox item={p} />;
                    })}
                  </div>
                </div>
              )}
              {show[3] === true && teams.length > 0 && (
                <div className={"space-y-4"}>
                  <p className={"text-md font-semibold"}>Football teams ({teams.length})</p>
                  <div className={"grid gris-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4"}>
                    {teams.map((p) => {
                      return <TeamSearchBox item={p} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withUser(PlayerSearch);
