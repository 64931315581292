/**
 * @deprecated use division id, division type or division rules to get such information
 */
export function formatSO5LeagueToId(league, division) {
  let result = "";
  if (league.includes("Cap")) {
    if (league === "Cap 240") {
      if (division === "Limited") {
        result = "global-cap-division-11";
      } else if (division === "Rare") {
        result = "global-cap-division-8";
      } else if (division === "Super Rare") {
        result = "global-cap-division-5";
      } else if (division === "Unique") {
        result = "global-cap-division-2";
      }
    } else if (league === "Cap 220") {
      if (division === "Limited") {
        result = "global-cap-division-12";
      } else if (division === "Rare") {
        result = "global-cap-division-9";
      } else if (division === "Super Rare") {
        result = "global-cap-division-6";
      } else if (division === "Unique") {
        result = "global-cap-division-3";
      }
    } else if (league === "Cap 270") {
      if (division === "Limited") {
        result = "global-cap-division-10";
      } else if (division === "Rare") {
        result = "global-cap-division-7";
      } else if (division === "Super Rare") {
        result = "global-cap-division-4";
      } else if (division === "Unique") {
        result = "global-cap-division-1";
      }
    }
  } else {
    if (league === "Global All-Star") {
      result += "global-all_star";
    } else if (league === "Global U23") {
      result += "global-under_twenty_one";
    } else if (league === "Champion Europe") {
      result += "champion-europe";
    } else if (league === "Challenger Europe") {
      result += "challenger-europe";
    } else if (league === "Asia") {
      result += "champion-asia";
    } else if (league === "America") {
      result += "champion-america";
    }
    if (division === "Limited") {
      result += "-division-5";
    } else if (division === "Rare") {
      result += "-division-4";
    } else if (division === "Rare Pro") {
      result += "-division-3";
    } else if (division === "Super rare") {
      result += "-division-2";
    } else if (division === "Unique") {
      result += "-division-1";
    }
  }

  return result;
}
