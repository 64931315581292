import React, { Component } from "react";
import { Text, Anchor, Grid, Box } from "grommet";
import AuctionDetails from "../auctions/auctionDetails";
import OfferDetails from "../offers/offerDetails";
import ManagerLink from "../manager/managerLink";
import GLOBAL_MARGIN from "../util/margin";
import StatMeter from "../auctions/statMeter";
import { getValuationOrigin } from "../util/valuationOrigin";
import { findPriceForUserUnit } from "../util/formatMoney";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import { CardsOwnedForPlayer } from "../players/cardsOwnedForPlayer";

class CardDisplay extends Component {
  render() {
    let slug = this.props.player.Slug;
    let link =
      "https://sorare.com/football/cards/" +
      slug +
      "-" +
      this.props.card.Season +
      "-" +
      this.props.card.Scarcity.toLowerCase() +
      "-" +
      this.props.card.SerialNumber +
      "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1";
    if (this.props.player.Sport === sorareBaseball) {
      link =
        "https://sorare.com/mlb/cards/" +
        slug +
        "-" +
        this.props.card.Season +
        "-" +
        this.props.card.Scarcity.toLowerCase() +
        "-" +
        this.props.card.SerialNumber +
        "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1";
    } else if (this.props.player.Sport === sorareBasketball) {
      link =
        "https://sorare.com/nba/cards/" +
        slug +
        "-" +
        this.props.card.Season +
        "-" +
        this.props.card.Scarcity.toLowerCase() +
        "-" +
        this.props.card.SerialNumber +
        "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1";
    }
    link = link.replace(" ", "_");

    const card = this.props.card;
    const player = this.props.player;
    const team = this.props.team;
    const auction = this.props.auction;
    const offer = this.props.offer;
    const info = this.props.info;
    const owner = this.props.owner;
    let ownerText = "";
    if (owner !== undefined) {
      if (owner.Nickname !== "" && card.CardOwner !== "0x0000000000000000000000000000000000000000") {
        ownerText = (
          <p>
            Currently under contract with <ManagerLink manager={owner} />
          </p>
        );
      }
    }
    const { PriceEur: eur, PriceUsd: usd, PriceGbp: gbp } = this.props.bmp;
    const bmpFiat = { eur, usd, gbp };
    const bmp = findPriceForUserUnit(this.props.bmp.Price, bmpFiat, this.props.unit || "eth");
    return (
      <div className={"grid lg:grid-cols-10 lg:grid-rows-1 grid-cols-1 grid-rows-2 mt-8 " + GLOBAL_MARGIN}>
        <div className={"lg:col-span-2 space-y-2 text-center w-10/12 md:w-10/12 mx-auto lg:mt-8"}>
          <a href={"/card/" + card.TokenId}>
            <img alt={card.Slug} src={card.PictureUrl} width={"100%"} />
          </a>
          <a target={"_blank"} className={"text-brand text-base text-center font-semibold hover:font-bold"} href={link} rel="noreferrer">
            View card on Sorare
          </a>
        </div>
        <div className={"lg:col-span-8 lg:w-10/12 mx-auto space-y-8"}>
          <div className={"text-center space-y-4"}>
            <h3 className={"mb-4"}>
              <a className={"text-brand hover:font-bold"} target={"_blank"} href={"/player/" + player.PlayerId} rel="noreferrer">
                {player.DisplayName}
              </a>{" "}
              - {card.Season + " "}- {card.Scarcity} - {card.SerialNumber}
            </h3>
            <Text size={"small"} alignSelf={"center"}>
              {player.Position},{" " + player.Age} -{" "}
              <Anchor color={"#292929"} href={"/team/" + team.TeamId}>
                {team.DisplayName} ({team.League})
              </Anchor>
            </Text>
            {this.props.stat && (
              <div className={"xl:w-6/12 2xl:w-5/12 w-10/12 md:w-8/12 mx-auto space-y-1 mt-4"}>
                <StatMeter
                  value={this.props.info.avg_5}
                  avg={this.props.info.gms_5}
                  max={10}
                  gms={5}
                  player={true}
                  description={"SD score for the past 5 games"}
                  gap={"medium"}
                  fontSize={"medium"}
                  rightSize={"medium"}
                  size={"small"}
                  thickness={"small"}
                  timeline={"L5"}
                  tip={"over the past 5 games"}
                />
                <StatMeter
                  value={this.props.info.avg_15}
                  avg={this.props.info.gms_15}
                  max={10}
                  player={true}
                  description={"SD score for the past 20 games"}
                  gap={"medium"}
                  fontSize={"medium"}
                  gms={15}
                  rightSize={"medium"}
                  size={"small"}
                  thickness={"small"}
                  timeline={"L15"}
                  tip={"for the past 15 games"}
                />
              </div>
            )}
          </div>

          <div className={"mt-8 space-y-4"}>
            <div className={"space-y-1"}>
              <p>
                Level: <span className={"font-semibold"}>{card.Level}</span>
              </p>
              <p>
                Bonus: <span className={"font-semibold"}>{((parseFloat(card.Power) - 1) * 100).toFixed(1)}%</span>
              </p>
              <p>
                Experience points: <span className={"font-semibold"}>{card.Exp}</span>
              </p>
            </div>
            <div className={"space-y-1"}>
              <p>
                {this.props.valuationOrigin ? getValuationOrigin(this.props.valuationOrigin) : "Card average price"}:{" "}
                <span className={"font-bold"}>{this.props.estimatedPrice}</span>
              </p>
              {ownerText}
              {offer ? <OfferDetails offer={info} /> : <Text></Text>}
              {this.props.bmp && getValuationOrigin(this.props.valuationOrigin) !== "Best market price" && (
                <p>
                  Floor price: <span className={"font-bold"}>{bmp}</span>
                </p>
              )}
            </div>
          </div>
          {auction ? <AuctionDetails noTitle={this.props.noTitle} auction={auction} player={player} /> : <p />}
          <Box margin={{ top: "small" }}>
            <CardsOwnedForPlayer player={player} />
          </Box>
        </div>
      </div>
    );
  }
}

export default CardDisplay;
