import React, { useState } from "react";
import Modal from "../util/modal";
import PlayerPriceGraph from "./chartJSPlayerPriceGraph";
import CardLayout from "../cards/cardLayout";
import LineSeparator from "../../img/line-separator.svg";
import PlayerScoresTab from "./playerScoreTab";

function PlayerPriceGraphModal(props) {
  const [cardProps, setCardProps] = useState(props.cardProps);
  const [activeTab, setActiveTab] = useState("history");

  const inactiveClass = "py-4 px-6 block hover:text-blue-500 focus:outline-none text-textGrey4 cursor-pointer w-6/12 text-center";
  const activeClass = "py-4 px-6 text-blue-500 focus:outline-none cursor-pointer text-brand w-6/12 text-center";

  return (
    <div>
      <Modal
        maxSize={"max-w-full"}
        size={"w-full xl:w-11/12 4xl:w-10/12"}
        open={props.open}
        close={() => props.close()}
        content={
          <div className={"w-full px-4 py-2 relative"}>
            <div className="bg-white mx-auto mt-2 mb-4 flex flex-row self-center xl:w-4/12 lg:w-5/12 md:w-7/12 w-11/12 text-base justify-center font-semibold rounded-xl">
              <div className={activeTab === "history" ? activeClass : inactiveClass} onClick={() => setActiveTab("history")}>
                <p>Price history</p>
              </div>
              <img src={LineSeparator} />
              <div className={activeTab === "scores" ? activeClass : inactiveClass} onClick={() => setActiveTab("scores")}>
                <p>SO5 scores</p>
              </div>
            </div>
            <div className={"flex flex-row space-x-8 w-full relative"}>
              <div className={activeTab === "history" ? "w-2/12 self-center" : "w-2/12 self-start sticky top-0"}>
                <CardLayout
                  noClick={true}
                  imageLink={cardProps.imageLink}
                  picture={cardProps.picture}
                  monthPrice={cardProps.monthPrice}
                  marketPrice={cardProps.marketPrice}
                  valuationOrigin={cardProps.valuationOrigin}
                  currentPrice={cardProps.currentPrice}
                  date={cardProps.date}
                  countdown={cardProps.countdown}
                  avgPrice={cardProps.avgPrice}
                  card={cardProps.card}
                  player={cardProps.player}
                  gwNumber={cardProps.gwNumber}
                  l5={cardProps.l5}
                  l20={cardProps.l20}
                  gms5={cardProps.gms5}
                  gms20={cardProps.gms20}
                  availability={cardProps.availabilityStatus}
                  gwGames={cardProps.gwGames}
                />
              </div>
              <div className={"w-10/12"}>
                {activeTab === "history" && (
                  <div className={"mb-12"}>
                    <PlayerPriceGraph scarcity={cardProps.card.Scarcity} player={cardProps.player} />
                  </div>
                )}
                {activeTab === "scores" && (
                  <div>
                    <PlayerScoresTab
                      info={{ player: cardProps.player }}
                      position={cardProps.card.Position}
                      active={activeTab === "scores"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default PlayerPriceGraphModal;
