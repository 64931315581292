import React from "react";
import ComboSelectionListItem from "./comboSelectionListItem";

function ComboSelectionList(props) {
  const players = props.players;
  const classTitles = "font-medium hover:font-semibold cursor-pointer";
  return (
    <div className={"space-y-1"}>
      <div className={"md:h-14 grid grid-cols-8 bg-white text-xxs md:text-xs gap-x-2 rounded"}>
        <div className={"col-span-2 self-center"}></div>
        <div className={"self-center text-center"}>
          <p className={classTitles} onClick={() => props.sort("gms")}>
            Common games
          </p>
        </div>
        <div className={"self-center text-center"}>
          <p className={classTitles} onClick={() => props.sort("mins")}>
            Minutes played average
          </p>
        </div>
        <div className={"self-center text-center"}>
          <p className={classTitles} onClick={() => props.sort("avg")}>
            Combined score average
          </p>
        </div>
        <div className={"self-center text-center"}>
          <p className={classTitles} onClick={() => props.sort("avg_div")}>
            Combined score per player
          </p>
        </div>
        <div className={"self-center text-center"}>
          <p className={classTitles} onClick={() => props.sort("above")}>
            % of common games 60+ points each
          </p>
        </div>
        <div className={"self-center text-center"}>
          <p className={classTitles}>Add to combo</p>
        </div>
      </div>
      {players !== null &&
        players?.map((p) => {
          return (
            <div key={p.player.PlayerId} className={"bg-white border border-light-grey rounded"}>
              <ComboSelectionListItem stats={p} addToCombo={props.addToCombo} sort={props.sort} />
            </div>
          );
        })}
    </div>
  );
}

export default ComboSelectionList;
