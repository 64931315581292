import React, { useState } from "react";
import format from "date-fns/format";
import SDLogo from "../../img/sd logo field.svg";
import PlayerScoreModal from "../players/playerScoreModal";
import FieldAvatar from "../games/fieldAvatar";
import LineInLineup from "../games/lineInLineup";
import UnknownTeam from "../../img/unknown_club.png";

function TeamLineup(props) {
  const game = props.game;
  const lineup = props.lineup;

  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayStats, setOverlayStats] = useState({});

  let spacing = "space-y-8";
  let fieldHeight = "h-50 xl:h-60 2.5xl:h-70";
  if (lineup?.lineup?.length === 4) {
    fieldHeight = "h-4-line-field";
  } else if (lineup?.lineup?.length === 5) {
    fieldHeight = "h-5-line-field";
  } else if (lineup?.lineup?.length === 6) {
    fieldHeight = "h-6-line-field";
    spacing = "space-y-4";
  }
  let fieldRound = true;

  return (
    <div>
      {showOverlay && (
        <div className={"z-50"}>
          <PlayerScoreModal sport={props.sport} open={showOverlay} stats={overlayStats} game={game} close={() => setShowOverlay(false)} />
        </div>
      )}
      {game?.game !== undefined && (
        <div className={"bg-brand-black text-white rounded-t-xl p-4 items-center mx-auto"}>
          <div className={"flex flex-row justify-around self-center space-x-8"}>
            <div className={"flex flex-row space-x-6 mt-6 w-4/12 justify-start"}>
              <div className={"w-8 h-8 self-center"}>
                <img
                  className={"object-contain w-full h-full"}
                  src={game?.home_team?.PictureUrl !== "" ? game?.home_team?.PictureUrl : UnknownTeam}
                />
              </div>
              <span className={"text-white text-lg font-semibold self-center"}>{game?.home_team?.ClubName}</span>
            </div>
            <div className={"flex flex-row space-x-8 self-center w-3/12 justify-center"}>
              <div className={"flex flex-col items-center mx-auto space-y-2"}>
                <div className={"flex flex-row justify-center"}>
                  <span className={"text-sm font-semibold"}>{format(new Date(game?.game?.GameTime), "dd/MM/yy")}</span>
                </div>
                <div className={"flex flex-row space-x-4"}>
                  <span className={"text-4xl self-center font-bold"}>{game?.game?.HomeGoals}</span>
                  <span className={"self-center text-5xl"}>-</span>
                  <span className={"text-4xl self-center font-bold"}>{game?.game?.AwayGoals}</span>
                </div>
              </div>
            </div>
            <div className={"flex flex-row space-x-6 mt-6 w-4/12 justify-end"}>
              <span className={"text-white text-lg font-semibold self-center"}>{game?.away_team?.ClubName}</span>
              <div className={"w-8 h-8 self-center"}>
                <img
                  className={"object-contain w-full h-full"}
                  src={game?.away_team?.PictureUrl !== "" ? game?.away_team?.PictureUrl : UnknownTeam}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={"relative"}>
        <div className={fieldHeight + " w-full absolute flex flex-col z-10"}>
          <div className={"h-full flex flex-col items-center justify-center w-full " + spacing}>
            {lineup.lineup?.map((l) => {
              return (
                <LineInLineup
                  horizontal={true}
                  reverse={true}
                  noSO5={true}
                  line={l}
                  overlay={(s) => {
                    setOverlayStats(s);
                    setShowOverlay(true);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className={"z-0 flex flex-col " + fieldHeight}>
          <div
            className={fieldRound ? "bg-game-field-opacity " + fieldHeight + " w-full" : "bg-game-field-borders " + fieldHeight + " w-1/12"}
          />
          <div className={"absolute border-4 border-t-0 border-white/10 z-5 h-20 self-center justify-center w-3/12 rounded-b-lg"} />
          <div className={"absolute border-4 border-t-0 border-white/10 z-5 h-48 self-center justify-center w-6/12 rounded-b-lg"} />

          <div className={"bg-game-field-borders " + fieldHeight + " w-full"} />
          <div className={"bg-game-field-opacity " + fieldHeight + " w-full"} />
          <div className={"bg-game-field-borders " + fieldHeight + " w-full flex flex-row"}>
            <div className={"absolute flex flex-row justify-center pr-4 w-full h-2/12 self-center"}>
              <div
                className={"border-4 flex border-white/10 align-middle mx-auto z-0 h-56 rounded-full self-center justify-center w-56 "}
              />
            </div>
            <div className={"absolute border-2 flex border-white/10 flex-row justify-center pr-4 w-full h-1 self-center"}></div>
            <img className={"mx-auto align-middle self-end justify-center"} src={SDLogo} />
          </div>
        </div>
        <div className={"relative flex flex-row justify-around w-full self-center mx-auto h-40 py-6 bg-game-field-opacity rounded-b-xl"}>
          {lineup?.substitutes?.map((l) => {
            return (
              <FieldAvatar
                stats={l}
                overlay={(s) => {
                  setOverlayStats(s);
                  setShowOverlay(true);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TeamLineup;
