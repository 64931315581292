import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import SelectSearch from "react-select-search";
import { ReactTooltip } from "../util/tooltip.js";
import { withUser } from "../../userContext";
import { isSportFree, sorareBaseball, sorareBasketball, sorareFootball, sportLabelsSingleSimple, sportsLabels } from "../util/sports";
import { isFree, t2OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";

function NewWatchlistModal(props) {
  const privacyLabels = [
    { value: "private", name: "Private" },
    { value: "unlisted", name: "Unlisted" },
    { value: "public", name: "Public" },
  ];

  const privacyDescription = {
    private: "The watchlist will be visible only by you",
    unlisted: "The watchlist will be visible by anyone with direct link, but will not be displayed in community listing",
    public: "The watchlist will be visible by anyone and will be displayed in community listing",
  };

  const defaultSports = props.sports || [];
  const [type, setType] = useState(props.type || "player");
  const [name, setName] = useState("");
  const [privacy, setPrivacy] = useState("private");
  const [sport, setSport] = useState(
    type === "player"
      ? defaultSports.find((v) => v === sorareBaseball)
        ? sorareBaseball
        : defaultSports.find((v) => v === sorareBasketball)
        ? sorareBasketball
        : sorareFootball
      : "multi",
  );
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState("");
  const [authorization, setAuthorization] = useState({});
  const isFormValid =
    type !== undefined &&
    type !== "" &&
    name !== undefined &&
    name !== "" &&
    privacy !== undefined &&
    privacy !== "" &&
    sport !== undefined &&
    sport !== "";
  const limitations = authorization[type];
  const isAuthorized = isSportFree(sport) ? true : limitations ? limitations.creationAuthorized : false;
  const authorizedMax = isSportFree(sport) ? undefined : limitations ? limitations.max : undefined;
  const nbCurrent = limitations ? limitations.current : undefined;
  const nbActive = limitations ? limitations.currentActive : undefined;
  const nbDisabled = limitations ? limitations.currentDisabled : undefined;
  const canValidate = isAuthorized && !creating && isFormValid;
  const validateButtonTitle = isAuthorized
    ? isFormValid
      ? ""
      : "A type, a name, a sport and a privacy are required"
    : authorizedMax !== undefined
    ? `You reached the maximum of ${authorizedMax} ${type} watchlists for your membership`
    : "Not authorized";

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (type === "manager") {
      setSport("multi"); // Force sport to multi for manager watchlists
    } else if (type === "player") {
      const defaultSports = props.sports || [];
      const sp =
        type === "player"
          ? defaultSports.find((v) => v === sorareBaseball)
            ? sorareBaseball
            : defaultSports.find((v) => v === sorareBasketball)
            ? sorareBasketball
            : sorareFootball
          : "multi";
      setSport(sp);
    }
  }, [type]);

  useEffect(() => {
    const defaultSports = props.sports || [];
    const sp =
      type === "player"
        ? defaultSports.find((v) => v === sorareBaseball)
          ? sorareBaseball
          : defaultSports.find((v) => v === sorareBasketball)
          ? sorareBasketball
          : sorareFootball
        : "multi";
    setSport(sp);
  }, [props.sports]);

  useEffect(() => {
    ReactTooltip.rebuild();
    if (props.open) {
      props
        .fetch("/apiv2/watchlists/new/can")
        .then((res) => res.json())
        .then((response) => {
          if (response.error === undefined) {
            setAuthorization(response);
          } else {
            console.error(response.error);
          }
        })
        .catch((res) => {
          console.error(res);
        });
    }
  }, [props.open]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [authorization, type, name, privacy, sport]);

  const close = () => {
    props.onClose();
    setType("player");
    setName("");
    setPrivacy("private");
    setError("");
    setSport("sr_football");
  };

  const createWatchlist = () => {
    setCreating(true);
    props
      .fetch("/apiv2/watchlists/new", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: type,
          privacy: privacy,
          name: name,
          sport: sport,
        }),
      })
      .then((response) => {
        if (response.status >= 500) {
          throw Error(response);
        } else {
          return response.json();
        }
      })
      .then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          close();
          if (props.onCreate) {
            props.onCreate(res);
          }
        }
        setCreating(false);
      })
      .catch((res) => {
        setError("Internal error, please retry later");
        setCreating(false);
      });
  };

  return (
    <div>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={close}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="relative bg-grey-f8 rounded-lg py-2">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                      onClick={close}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className={"w-auto mt-8"}>
                    <div className={"w-full border-b-2 border-grey-e5 pb-4"}>
                      <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>New watchlist</h2>
                      <div className={"w-full"}>
                        <h3 className="text-center text-lg font-semibold mb-2">Type</h3>
                        <div className={"flex flex-row space-x-2 self-center justify-center gap-2"}>
                          <div
                            onClick={() => setType("player")}
                            className={
                              "cursor-pointer rounded-md w-1/3 p-3 border border-3 hover:border-brand " +
                              (type === "player" ? "border-brand bg-brand-pastel text-brand-text" : "border-grey-e9")
                            }
                          >
                            <h4 className="font-semibold text-sm m-0">Players</h4>
                            <p className={"text-sm text-textGrey3 m-0"}>Scout players</p>
                          </div>
                          <div
                            onClick={() => setType("manager")}
                            className={
                              "rounded-md w-1/3 p-3 border border-3 hover:border-brand cursor-pointer " +
                              (type === "manager" ? "border-brand bg-brand-pastel text-brand-text" : "border-grey-e9")
                            }
                          >
                            <h4 className="font-semibold text-sm m-0">Managers</h4>
                            <p className={"text-sm text-textGrey3 m-0"}>Scout your friends</p>
                          </div>
                        </div>
                      </div>
                      {nbCurrent !== undefined && (
                        <div className={"p-4 text-center"}>
                          {isAuthorized ? (
                            <>
                              {authorizedMax !== undefined ? (
                                <p className="text-sm">
                                  You can create <b>{`up to ${authorizedMax}`}</b>
                                  {` ${type} watchlists; you have `}
                                  <b>{`${authorizedMax - nbCurrent} remaining`}</b>
                                </p>
                              ) : isSportFree(sport) && !t2OrAbove(props.user.tier) ? (
                                <p className="text-sm">
                                  You have <b>unlimited</b>
                                  {` ${sportLabelsSingleSimple.find((s) => s.value === sport).name} ${type} watchlists`}
                                </p>
                              ) : (
                                <p className="text-sm">
                                  You have <b>unlimited</b>
                                  {` ${type} watchlists`}
                                </p>
                              )}
                            </>
                          ) : (
                            <div className={"space-y-1"}>
                              {authorizedMax !== undefined && (
                                <p className={"text-sm"}>
                                  You reached the <b>{`maximum of ${authorizedMax}`}</b>
                                  {` ${type} watchlists for your membership`}
                                </p>
                              )}
                              <p className={"text-sm"}>
                                You have currently
                                <b>{` ${nbCurrent}`}</b>
                                {` ${type} watchlists (${nbActive} active, ${nbDisabled} disabled)`}
                              </p>
                              {authorizedMax !== undefined && authorizedMax > 0 && (
                                <p className={"text-xs mx-auto flex flex-col justify-center"}>
                                  <ul className={"list-decimal mx-auto text-left"} style={{ maxWidth: "60%" }}>
                                    <li>
                                      <a
                                        href={"/product"}
                                        target={"_blank"}
                                        className={"text-brand hover:text-brand-text cursor-pointer"}
                                        rel="noreferrer"
                                      >
                                        Upgrade your membership
                                      </a>
                                    </li>
                                    {nbDisabled > 0 ? (
                                      <li>{`Remove all ${nbDisabled} disabled watchlists and some active watchlists to create new ones`}</li>
                                    ) : (
                                      <li>{`Remove some active watchlists to create new ones`}</li>
                                    )}
                                    {nbDisabled > 0 && (
                                      <li>{`Remove some active watchlists and reactivate some of the ${nbDisabled} disabled watchlists`}</li>
                                    )}
                                    {type === "player" && !isSportFree(sport) && (
                                      <li>{`Choose Baseball or Basketball player watchlist, they are unlimited`}</li>
                                    )}
                                  </ul>
                                </p>
                              )}
                              {authorizedMax === 0 && nbDisabled > 0 && (
                                <p className={"text-xs mx-auto flex flex-col justify-center"}>
                                  <ul className={"list-decimal mx-auto text-left"} style={{ maxWidth: "60%" }}>
                                    <li>
                                      <a
                                        href={"/product"}
                                        target={"_blank"}
                                        className={"text-brand hover:text-brand-text cursor-pointer"}
                                        rel="noreferrer"
                                      >
                                        Upgrade your membership
                                      </a>
                                      {` to reactivate some of your ${nbDisabled} disabled watchlists`}
                                    </li>
                                    {type === "player" && !isSportFree(sport) && (
                                      <li>{`Choose Baseball or Basketball player watchlist, they are unlimited`}</li>
                                    )}
                                  </ul>
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={"mx-full mb-8 px-6 pt-6"}>
                      <div>
                        <h3 className={"font-semibold text-lg mb-1"}>Watchlist information</h3>
                      </div>
                      <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                        <div className={"w-full"}>
                          <p className={"text-sm font-semibold"}>
                            Name<span className={"text-live-red"}>*</span>
                          </p>
                          <input
                            className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
                            type={"text"}
                            placeholder={"EU best forwards..."}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className={"hidden sm:block"}></div>
                        <div className={"w-full"}>
                          <p className={"text-sm font-semibold"}>
                            Sport<span className={"text-live-red"}>*</span>
                          </p>
                          <div
                            title={
                              type === "manager"
                                ? `Manager watchlists are necessarily ${sportsLabels.find((s) => s.value === sport).name}`
                                : undefined
                            }
                          >
                            <SelectSearch
                              disabled={type === "manager"}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              closeOnSelect={false}
                              options={sportsLabels}
                              value={sport}
                              printOptions={"on-focus"}
                              onChange={(v) => setSport(v)}
                            />
                          </div>
                        </div>
                        <div className={"w-full"}>
                          <p className={"text-sm font-semibold"}>
                            Privacy<span className={"text-live-red"}>*</span>
                          </p>
                          <SelectSearch
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            closeOnSelect={false}
                            options={privacyLabels}
                            value={privacy}
                            printOptions={"on-focus"}
                            onChange={(v) => setPrivacy(v)}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm">{privacyDescription[privacy]}</p>
                      </div>
                      <div className={"flex flex-row mt-4"}>
                        <div className={"ml-auto mr-0 inline"}>
                          <ReactTooltip id={"new-watchlist-validation"} />
                          <p className={"inline align-middle text-sm mr-3"}>
                            <span className={"text-live-red"}>*</span> indicates a required field
                          </p>
                          <div className={"inline"} data-tip={validateButtonTitle}>
                            <button
                              disabled={!canValidate}
                              data-for={"new-watchlist-validation"}
                              className={
                                "text-white p-2 px-16 text-sm font-medium rounded-lg " +
                                (canValidate
                                  ? "bg-brand focus:outline-none hover:bg-brand-light cursor-pointer"
                                  : isAuthorized
                                  ? "bg-brand-light cursor-default"
                                  : "bg-gray-300 cursor-default")
                              }
                              onClick={canValidate && createWatchlist}
                            >
                              {creating ? <span>Creating...</span> : <span>Create</span>}
                            </button>
                          </div>
                        </div>
                      </div>
                      {error && <p className={"text-live-red"}>{error}</p>}
                    </div>
                  </div>
                </div>
                <div className="bg-grey-f8 h-auto rounded-lg mt-4">
                  {isFree(props.user.tier) && (
                    <UpgradeLimitBox
                      size={"sm"}
                      className={"bg-white bg-upgrade-bg-watchlists bg-cover h-upgrade-banner-l w-full mb-4"}
                      title={"Want more watchlists?"}
                      description={"Become a Star member to unlock unlimited watchlists."}
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withUser(NewWatchlistModal);
