import React from "react";

const splitPrice = (p, currency, unitStyle, sidePricesStyle) => {
  let split = p.split(currency);
  const parts = split.map((part, i) => {
    if (part === "") {
      return (
        <span key={i} className={unitStyle}>
          {currency}
        </span>
      );
    }
    return <span key={i}>{part}</span>;
  });
  return <p className={sidePricesStyle}>{parts}</p>;
};

export function formatPriceSize(p, side, secondary, customStyle, customUnitStyle) {
  let sidePricesStyle = "text-xs font-semibold text-textGrey3";
  let unitStyle = "text-xxs font-semibold  self-center";
  if (!side) {
    sidePricesStyle = "text-base font-semibold text-center";
    unitStyle = "text-sm";
  }
  if (secondary) {
    sidePricesStyle = "text-xs font-semibold text-textGrey3";
    unitStyle = "text-xxs";
  }
  if (customStyle) {
    sidePricesStyle = customStyle;
  }
  if (customUnitStyle) {
    unitStyle = customUnitStyle;
  }
  if (p === undefined || p === null || p === "") {
    return p;
  }
  if (p.includes("Ξ")) {
    return splitPrice(p, "Ξ", unitStyle, sidePricesStyle);
  } else if (p.includes("€")) {
    return splitPrice(p, "€", unitStyle, sidePricesStyle);
  } else if (p.includes("$")) {
    return splitPrice(p, "$", unitStyle, sidePricesStyle);
  } else if (p.includes("£")) {
    return splitPrice(p, "£", unitStyle, sidePricesStyle);
  }
  return <p className={sidePricesStyle}>{p}</p>;
}
