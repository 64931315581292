import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import CardLayout from "../cards/cardLayout";
import { errorCatcher } from "../util/errors";
import { t1OrAbove } from "../util/handleSubscriptionTier";

function LayoutPreference(props) {
  const [layout, setLayoutValue] = useState(props.user.preferredLayout || "white");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (props.isConnected()) {
      setLayoutValue(props.user.preferredLayout);
    }
  }, [props.user]);

  const changeCardBackground = (c) => {
    setMsg("");
    props
      .fetch("/apiv2/user/changeCardBackground", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: c.toLowerCase(),
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          setMsg("Layout change failed. Please try again later.");
        } else {
          props.changeLayout(c);
          setMsg("Layout changed.");
        }
      })
      .catch(errorCatcher());
  };

  let card = { Scarcity: "LIMITED", Season: 2022, Power: 1.07 };
  let player = {
    PlayerId: "76253167934688389357378258612130667741947406180434381024180528909820375684138",
    PlayingStatus: "starter",
  };
  let availabilityStatus = { status: "Available" };

  let inactiveDiv = "space-y-2 opacity-70 hover:opacity-100 cursor-pointer";
  let activeDiv = "space-y-2 opacity-100";

  let activeText = "text-xs uppercase text-center font-semibold text-primary";
  let inactiveText = "text-xs uppercase text-center text-on-surface-variant font-semibold";

  return (
    <div className={"flex flex-col items-start space-y-2"}>
      <p className={"text-center uppercase text-sm text-on-surface-variant font-semibold"}>Layout preference</p>
      <div className={"flex flex-col space-y-4"}>
        <p className={"text-sm font-medium"}>This layout will be used to display cards on the platform.</p>
        <div className={"items-center md:items-start flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 "}>
          <div
            className={layout === "white" ? activeDiv : inactiveDiv}
            onClick={() => {
              if (t1OrAbove(props.user?.tier)) {
                changeCardBackground("white");
                setLayoutValue("white");
              }
            }}
          >
            <div
              className={layout === "white" ? "border-2 border-brand rounded-lg" : ""}
              data-tip={t1OrAbove(props.user?.tier) ? "" : "Star membership is needed"}
            >
              <CardLayout
                layout={"white"}
                picture={
                  "https://assets.sorare.com/carddata/1b4dc717-28c1-42f4-8f24-d020afd53c93/picture/040ec25319eef5b48a13f11c087925a0.png"
                }
                monthPrice={"1.123 Ξ"}
                marketPrice={"1.123 Ξ"}
                valuationOrigin={""}
                currentPrice={"1.123 Ξ"}
                date={false}
                countdown={false}
                avgPrice={"1.123 Ξ"}
                l5={70}
                l20={70}
                gms5={5}
                gms20={15}
                card={card}
                availability={availabilityStatus}
                player={player}
              />
            </div>
            <p className={layout === "white" ? activeText : inactiveText}>Neutral background</p>
          </div>
          <div
            className={layout === "colored" ? activeDiv : inactiveDiv}
            onClick={() => {
              if (t1OrAbove(props.user?.tier)) {
                changeCardBackground("colored");
                setLayoutValue("colored");
              }
            }}
            data-tip={t1OrAbove(props.user?.tier) ? "" : "Star membership is needed"}
          >
            <div className={layout === "colored" ? "border-2 border-primary rounded-lg" : ""}>
              <CardLayout
                layout={"colored"}
                picture={
                  "https://assets.sorare.com/carddata/1b4dc717-28c1-42f4-8f24-d020afd53c93/picture/040ec25319eef5b48a13f11c087925a0.png"
                }
                monthPrice={"1.123 Ξ"}
                marketPrice={"1.123 Ξ"}
                valuationOrigin={""}
                currentPrice={"1.123 Ξ"}
                date={false}
                countdown={false}
                avgPrice={"1.123 Ξ"}
                l5={70}
                l20={70}
                gms5={5}
                gms20={15}
                card={card}
                player={player}
                availability={availabilityStatus}
              />
            </div>
            <p className={layout === "colored" ? activeText : inactiveText}>Colored background</p>
          </div>
        </div>
        {msg !== "" && <p className={"text-sm text-center font-semibold"}>{msg}</p>}
      </div>
    </div>
  );
}

export default withUser(LayoutPreference);
