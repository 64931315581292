import React from "react";
import FieldAvatar from "./fieldAvatar";

function LineInLineup(props) {
  let line = props.line !== null && props.line !== undefined ? [...props.line] : null; // copy line to ensure it's reversing only the copy
  if (props.reverse && line !== null) {
    line = line.reverse();
  }
  const horizontal = props.horizontal;
  let style = "";
  if (horizontal && line !== null) {
    if (line.length === 1) {
      style = "flex flex-row justify-around w-full";
    } else if (line.length === 2) {
      style = "flex flex-row justify-around mx-auto w-9/12";
    } else if (line.length === 3) {
      style = "flex flex-row justify-around mx-auto w-11/12";
    } else if (line.length === 4) {
      style = "flex flex-row justify-around mx-auto w-full";
    } else if (line.length === 5) {
      style = "flex flex-row justify-around w-full";
    }
  } else if (line !== null) {
    if (line.length === 1) {
      style = "w-24 flex flex-col space-y-2 justify-center";
    } else if (line.length === 2) {
      style = "w-24 flex flex-col space-y-16 justify-center";
    } else if (line.length === 3) {
      style = "w-24 flex flex-col space-y-16 justify-center";
    } else if (line.length === 4) {
      style = "w-24 flex flex-col space-y-12 justify-center";
    } else if (line.length === 5) {
      style = "w-24 flex flex-col justify-around 2.5xl:justify-center 2.5xl:space-y-6";
    }
  }

  if (line !== null) {
    return (
      <div className={style}>
        {line.map((p) => (
          <FieldAvatar
            hideScores={props.hideScores}
            showProjectionInfo={props.showProjectionInfo}
            noSO5={props.noSO5}
            stats={p}
            linkToPlayerPage={props.linkToPlayerPage}
            overlay={props.overlay}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
}

export default LineInLineup;
