import React, { useEffect, useRef, useState } from "react";
import { Menu } from "@headlessui/react";
import BellCard from "../../img/icons-bell-card.svg";
import { formatPrice } from "./formatMoney";
import { Form } from "grommet";
import { scarcities_colors } from "./scarcities";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { units, units_objects } from "./units";
import { ReactComponent as IconTrash } from "../../img/icons-trash.svg";
import { formatDistance } from "date-fns";
import { withUser } from "../../userContext";

function PriceAlertInput(props) {
  const { player, scarcity, notification, pendingNotification, onChange, onDelete } = props;
  const playerId = player.PlayerId;
  const type = props.type || "offer";
  const [editing, setEditing] = useState(false);
  const [price, setPrice] = useState(undefined);
  const [unit, setUnit] = useState(props.user.preferredUnit || "eth");
  const [showUnitMenu, setShowUnitMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const input = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (pendingNotification) {
      setPrice(pendingNotification.price || undefined);
      setUnit(pendingNotification.unit || props.user.preferredUnit || "eth");
    } else if (notification) {
      setPrice(notification.price || undefined);
      setUnit(notification.unit || props.user.preferredUnit || "eth");
    } else {
      setPrice(undefined);
      setUnit(props.user.preferredUnit);
    }
  }, [notification, pendingNotification]);

  useEffect(() => {
    if (editing && input.current) {
      input.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (!editing) {
      return;
    }
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onSubmit();
        setMenuOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [editing, unit, price]);

  const onSubmit = () => {
    const notif = {
      sale_type: type,
      scarcity: scarcity,
      price: price,
      unit: unit,
      player_id: playerId,
    };
    onChange && onChange(notif);
    setEditing(false);
  };

  const onInputChange = (v) => {
    const val = v.target.value;
    if (isNaN(val) || val === "") {
      setPrice(undefined);
    } else {
      setPrice(+val >= 0 ? +val : 0);
    }
  };

  const onCancel = () => {
    setPrice(notification?.price);
    setUnit(notification?.unit || props.user.preferredUnit || "eth");
    setEditing(false);
  };

  const canBeDeleted = price !== undefined;

  return (
    <div
      ref={ref}
      className="bg-focus rounded border border-grey-e9 text-sm font-sans font-semibold flex flex-row justify-items-center overflow-hidden text-textGrey4"
      style={{
        color: price !== undefined ? scarcities_colors[scarcity.toLowerCase()] : undefined,
        width: "fit-content",
      }}
    >
      <div className={"flex flex-row justify-between p-2 border-r border-grey-e9 grow gap-2"}>
        <img src={BellCard} />
        {editing ? (
          <div className={"w-20 h-6"}>
            <Form onSubmit={onSubmit}>
              <input
                step={"any"}
                className={
                  "h-6 p-0 m-0 text-sm font-sans font-semibold bg-transparent border-none outline-none ring-0 focus:ring-0 focus:outline-none w-full appearance-none"
                }
                type="number"
                value={price !== undefined ? price : ""}
                ref={input}
                placeholder={"0"}
                onKeyUp={(e) => {
                  if (e.key === "Escape") {
                    onCancel();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Tab") {
                    e.preventDefault();
                    onSubmit();
                    return false;
                  }
                }}
                onChange={onInputChange}
              />
            </Form>
          </div>
        ) : (
          <div
            className={"w-20 h-6 cursor-text flex flex-col justify-center"}
            title={
              price === undefined
                ? `Click to add price alert on "${scarcity}" scarcity for "${player.DisplayName}"`
                : `${
                    notification?.updated_at
                      ? `Updated ${formatDistance(new Date(notification.updated_at), new Date(), {
                          addSuffix: true,
                        })}. \n`
                      : ""
                  }Click to edit price alert on "${scarcity}" scarcity for "${player.DisplayName}"`
            }
            onClick={() => setEditing(true)}
          >
            {formatPrice(price !== undefined ? price : 0.0, unit)}
          </div>
        )}
      </div>
      <div className={"flex p-0.5"} style={{ width: "37px" }}>
        {editing ? (
          <Menu as="div" className="inline-block m-auto z-10" open={menuOpen}>
            <div>
              <Menu.Button as={"div"} className="flex m-auto">
                <div className={"text-sm font-semibold text-brand-black cursor-pointer flex flex-row my-auto "}>
                  {units[unit]}
                  <span className={"ml-1 my-auto"}>
                    <SortArrowIcon
                      style={{ width: "10px", height: "10px" }}
                      className={"h-3 w-3 fill-brand-black " + (showUnitMenu ? "transform rotate-180" : "")}
                    />
                  </span>
                </div>
              </Menu.Button>
            </div>
            <Menu.Items
              className="absolute border-b border-l border-r border-grey-e9 transform -translate-x-1/5 divide-y divide-gray-100 rounded-bl rounded-br bg-white shadow-sm ring-0 ring-opacity-5 focus:outline-none py-1"
              style={{ width: "37px" }}
            >
              <div className="">
                {units_objects.map((obj) => {
                  return (
                    <Menu.Item key={obj.value}>
                      <button
                        onClick={() => {
                          setUnit(obj.value);
                          setMenuOpen(false);
                        }}
                        className={`${
                          obj.value === unit ? "font-semibold" : "opacity-50"
                        } hover:opacity-90 hover:bg-grey-f2 w-full items-center align-center text-lg py-1 text-brand-black`}
                      >
                        {obj.name}
                      </button>
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Menu>
        ) : (
          <div
            className={`rounded-full h-5 w-5 m-auto flex bg-grey-f2 ${canBeDeleted ? "cursor-pointer hover:opacity-80" : "opacity-50"}`}
            onClick={canBeDeleted ? () => onDelete && onDelete(player.PlayerId, scarcity) : undefined}
            title={
              canBeDeleted
                ? `Click to remove price alert on "${scarcity}" scarcity for "${player.DisplayName}"`
                : "No price alert to delete"
            }
          >
            <IconTrash className={`h-3 w-3 m-auto`} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(PriceAlertInput);
