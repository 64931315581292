import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { withRouter } from "react-router-dom";
import SDLoading from "../util/SDLoading";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { StandaloneMenu } from "../util/standaloneMenu";
import Spinner from "../loader/spinner";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { ReactComponent as IconCircle } from "../../img/icons-circle.svg";
import NewCardIcon from "../util/newCardIcon";
import AddSearchableItemsModal from "../search/addSearchableItemsModal";
import FavoriteBubble from "../util/favoriteBubble";
import DeletionBubble from "../util/deletionBubble";
import { checkSecondUnitShouldBeDisplayed, formatPrice } from "../util/formatMoney";
import { formatPercentage } from "../util/formatPercentage";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { Form } from "grommet";
import { searchText } from "../util/strings";
import { Button } from "../util/button";

const sortByLabels = {
  "nbCards asc": "Cards owned - Least to Most",
  "nbCards desc": "Cards owned - Most to Least",
  "name asc": "Name - A to Z",
  "name desc": "Name - Z to A",
  "cardsLimited asc": "Limited cards - Smallest to Highest",
  "cardsLimited desc": "Limited cards - Highest to Smallest",
  "cardsRare asc": "Rare cards - Smallest to Highest",
  "cardsRare desc": "Rare cards - Highest to Smallest",
  "cardsSuperRare asc": "Super Rare cards - Smallest to Highest",
  "cardsSuperRare desc": "Super Rare cards - Highest to Smallest",
  "cardsUnique asc": "Unique cards - Smallest to Highest",
  "cardsUnique desc": "Unique cards - Highest to Smallest",
  "winningLineupPercentage asc": "Total winning lineup percentage - Lowest to Highest",
  "winningLineupPercentage desc": "Total winning lineup percentage - Highest to Lowest",
  "totalRewards asc": "Total rewards - Least to Most",
  "totalRewards desc": "Total rewards - Most to Least",
  "totalEarning asc": "Total earning - Least to Most",
  "totalEarning desc": "Total earning - Most to Least",
  "spentInMarket asc": "Total spent on market - Lowest to Highest",
  "spentInMarket desc": "Total spent on market - Highest to Lowest",
  "receivedFromMarket asc": "Total received from market - Least to Most",
  "receivedFromMarket desc": "Total received from market - Most to Least",
  "valuationKeptRewards asc": "Total valuation kept rewards - Least to Most",
  "valuationKeptRewards desc": "Total valuation kept rewards - Most to Least",
  "totalValuation asc": "Total valuation - Least to Most",
  "totalValuation desc": "Total valuation - Most to Least",
  "added asc": "Least recently added",
  "added desc": "Most recently added",
};

const CardAmount = (props) => {
  const { scarcity, amount } = props;
  const total = amount || 0;
  return (
    <div className={"flex flex-row gap-3 justify-center"}>
      <div className={"flex flex-row gap-1"}>
        <span className={"h-5 w-5 my-auto"}>
          <NewCardIcon scarcity={scarcity.toLowerCase()} />
        </span>
        <span className={`my-auto ${total === 0 ? "text-on-surface-variant" : "text-on-surface"}`}>{total}</span>
      </div>
    </div>
  );
};

const WatchlistsGalleryStatsList = (props) => {
  const {
    displayedGalleryStatsRes,
    onChangeSort,
    sortBy,
    connectedUserIsOwner,
    watchlist,
    removeManagerFromWatchlist,
    primaryCurrency,
    secondaryCurrency,
  } = props;
  const shouldDisplaySecondary = checkSecondUnitShouldBeDisplayed(primaryCurrency, secondaryCurrency);

  const sortableColumns = [
    "nbCards",
    "name",
    "cardsLimited",
    "cardsRare",
    "cardsSuperRare",
    "cardsUnique",
    "winningLineupPercentage",
    "totalRewards",
    "totalEarning",
    "spentInMarket",
    "receivedFromMarket",
    "valuationKeptRewards",
    "totalValuation",
    "added",
  ];

  const sortingStyle = (column) => {
    let style = [];
    if (sortableColumns.includes(column)) {
      style.push("hover:opacity-70 cursor-pointer");
    }
    if (sortBy.includes(column)) {
      style.push("bg-brand-black-light font-bold");
    } else {
      style.push("bg-brand-black");
    }
    return style.join(" ");
  };
  const sortArrowStyle = (column) => {
    let style = ["fill-white my-auto"];
    if (sortableColumns.includes(column) && sortBy.includes(column)) {
      style.push("visible");
      if (sortBy.includes("desc")) {
        style.push("transform rotate-180");
      }
    } else {
      style.push("invisible");
    }
    return style.join(" ");
  };

  const basicHeader = (key, title) => {
    return (
      <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle(key)} onClick={() => onChangeSort(key)}>
        <div className={"relative m-auto w-full"}>
          <div className={"flex justify-center"}>
            <span>{title}</span>
          </div>
          <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
            <SortArrowIcon className={sortArrowStyle(key)} />
          </span>
        </div>
      </th>
    );
  };

  const rewardsHeader = (scarcity, key) => {
    return (
      <th className={"p-2 bg-brand-black text-white text-center " + sortingStyle(key)} onClick={() => onChangeSort(key)}>
        <div className={"relative m-auto w-full"}>
          <div className={"flex flex-row gap-1 justify-center"}>
            <span className={`fill-${scarcity} self-center`}>
              <IconCircle />
            </span>
            <span>Cards</span>
          </div>
          <span className={"absolute right-0 top-2/4 transform -translate-y-2/4 my-auto"}>
            <SortArrowIcon className={sortArrowStyle(key)} />
          </span>
        </div>
      </th>
    );
  };

  return (
    <table className="table-auto rounded bg-surface-container rounded-lg h-auto overflow-hidden text-xs font-semibold w-full overflow-auto text-center">
      <thead>
        <tr>
          <th
            style={{ minWidth: "10em", left: "0em" }}
            onClick={() => onChangeSort("name")}
            className={"p-1 sm:p-3 text-white z-20 sticky " + sortingStyle("name")}
          >
            <div className={"flex flex-row justify-between"}>
              <p>Name</p>
              <SortArrowIcon className={sortArrowStyle("name")} />
            </div>
          </th>
          {rewardsHeader("limited", "cardsLimited")}
          {rewardsHeader("rare", "cardsRare")}
          {rewardsHeader("super-rare", "cardsSuperRare")}
          {rewardsHeader("unique", "cardsUnique")}
          {basicHeader("winningLineupPercentage", "%age winning lineups")}
          {basicHeader("totalRewards", "Rewards earned")}
          {basicHeader("totalEarning", "Gaming yield")}
          {basicHeader("spentInMarket", "Spent on market")}
          {basicHeader("receivedFromMarket", "Received from market")}
          {basicHeader("valuationKeptRewards", "Estimated val. kept rewards")}
          {basicHeader("totalValuation", "Estimated valuation")}
          <th className={"p-3 bg-brand-black"}></th>
        </tr>
      </thead>
      <tbody>
        {displayedGalleryStatsRes?.map((res, i) => {
          return (
            <tr key={i} className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <td
                className={"p-1 sm:p-2 xl:p-3 text-left z-20 sticky bg-surface-container"}
                style={{ left: "0em", backgroundClip: "padding-box" }}
              >
                <span className={"flex flex-row"} title={res.manager.Nickname || res.manager.Slug}>
                  <span className={"my-auto hidden md:inline rounded-full mr-2 w-5 h-5 xl:w-7 xl:h-7"}>
                    <a href={"/manager/" + res.manager.Slug}>
                      <img
                        className={"rounded-full inline w-5 h-5 xl:w-7 xl:h-7 object-cover cursor-pointer"}
                        src={res.manager.PictureUrl || EmptyAvatar}
                      />
                    </a>
                  </span>
                  <span className={"text-xs xl:text-sm font-semibold mr-2 flex flex-col"}>
                    <a className={"truncate"} href={"/manager/" + res.manager.Slug}>
                      {res.manager.Nickname || res.manager.Slug}
                    </a>
                    <p className={"text-on-surface-variant truncate text-xs"} style={{ maxWidth: "13em" }} title={res.manager.TeamName}>
                      <a href={"/manager/" + res.manager.Slug}>{res.manager.TeamName}</a>
                    </p>
                  </span>
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardAmount scarcity={"LIMITED"} amount={res.cards["LIMITED"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardAmount scarcity={"RARE"} amount={res.cards["RARE"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardAmount scarcity={"SUPER RARE"} amount={res.cards["SUPER RARE"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>
                  <CardAmount scarcity={"UNIQUE"} amount={res.cards["UNIQUE"]} />
                </span>
              </td>
              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold whitespace-nowrap"}>{formatPercentage(res.winningLineupPercentage)}</span>
              </td>

              <td className={"p-3"}>
                <span className={"my-auto text-sm font-semibold"}>{res.totalRewards}</span>
              </td>
              <td className={"p-3"}>
                <div className={"flex flex-col items-center gap-0.5"}>
                  <span className={"my-auto text-sm font-semibold whitespace-nowrap"}>
                    {formatPrice(res.totalEarning, primaryCurrency)}
                  </span>
                  {shouldDisplaySecondary && (
                    <span className={"my-auto text-sm font-semibold whitespace-nowrap text-on-surface-variant text-xs"}>
                      {formatPrice(res.totalEarningSecondary, secondaryCurrency)}
                    </span>
                  )}
                </div>
              </td>
              <td className={"p-3"}>
                {res.marketStatsPublic ? (
                  <div className={"flex flex-col items-center gap-0.5"}>
                    <span className={"my-auto text-sm font-semibold whitespace-nowrap"}>
                      {formatPrice(res.spentInMarket, primaryCurrency)}
                    </span>
                    {shouldDisplaySecondary && (
                      <span className={"my-auto text-sm font-semibold whitespace-nowrap text-on-surface-variant text-xs"}>
                        {formatPrice(res.spentInMarketSecondary, secondaryCurrency)}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className={"flex flex-col items-center text-sm font-semibold text-on-surface-variant"}>Private</div>
                )}
              </td>
              <td className={"p-3"}>
                {res.marketStatsPublic ? (
                  <div className={"flex flex-col items-center gap-0.5"}>
                    <span className={"my-auto text-sm font-semibold whitespace-nowrap"}>
                      {formatPrice(res.receivedFromMarket, primaryCurrency)}
                    </span>
                    {shouldDisplaySecondary && (
                      <span className={"my-auto text-sm font-semibold whitespace-nowrap text-on-surface-variant text-xs"}>
                        {formatPrice(res.receivedFromMarketSecondary, secondaryCurrency)}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className={"flex flex-col items-center text-sm font-semibold text-on-surface-variant"}>Private</div>
                )}
              </td>
              <td className={"p-3"}>
                <div className={"flex flex-col items-center gap-0.5"}>
                  <span className={"my-auto text-sm font-semibold whitespace-nowrap"}>
                    {formatPrice(res.valuationKeptRewards, primaryCurrency)}
                  </span>
                  {shouldDisplaySecondary && (
                    <span className={"my-auto text-sm font-semibold whitespace-nowrap text-on-surface-variant text-xs"}>
                      {formatPrice(res.valuationKeptRewardsSecondary, secondaryCurrency)}
                    </span>
                  )}
                </div>
              </td>
              <td className={"p-3"}>
                <div className={"flex flex-col items-center gap-0.5"}>
                  <span className={"my-auto text-sm font-semibold whitespace-nowrap"}>
                    {formatPrice(res.totalValuation, primaryCurrency)}
                  </span>
                  {shouldDisplaySecondary && (
                    <span className={"my-auto text-sm font-semibold whitespace-nowrap text-on-surface-variant text-xs"}>
                      {formatPrice(res.totalValuationSecondary, secondaryCurrency)}
                    </span>
                  )}
                </div>
              </td>
              <td className={"p-3"} style={{ width: "3em" }}>
                <div className={"flex flex-row justify-center m-auto"}>
                  <div className={"h-9 w-9"}>
                    <FavoriteBubble type="manager" manager={res.manager.Slug} favorite={res.favorite} />
                  </div>
                  {connectedUserIsOwner && (
                    <div className={"h-9 w-9"}>
                      <DeletionBubble
                        onClick={() => removeManagerFromWatchlist(res.manager)}
                        title={`Click to remove "${res.manager.Nickname}" from "${watchlist.name}" watchlist`}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

function WatchlistManagersGalleryStats(props) {
  const watchlist = props.watchlist;

  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState(undefined);
  const [filteredManagers, setFilteredManagers] = useState(undefined);
  const [displayedManagers, setDisplayedManagers] = useState(undefined);
  const [sortBy, setSortBy] = useState("nbCards desc");
  const [searchQuery, setSearchQuery] = useState("");

  const [savingAddManagers, setSavingAddManagers] = useState(false);
  const [openAddManagersModal, setOpenAddManagersModal] = useState(false);

  const [showSortMenu, setShowSortMenu] = useState(false);
  const connectedUserIsOwner = watchlist.owner === props.user.sorareSlug;
  const primaryCurrency = props.user.preferredUnit;
  const secondaryCurrency = props.user.secondUnit;

  useEffect(() => {
    getManagerGalleryStats();
  }, [sortBy, watchlist]);

  useEffect(() => {
    if (managers !== undefined) {
      const filtered = managers.filter((m) => searchText(searchQuery, m.manager.Nickname));
      setFilteredManagers(filtered);
      setDisplayedManagers(filtered.slice(0, 50));
    }
  }, [searchQuery]);

  const getManagerGalleryStats = () => {
    if (watchlist.id) {
      setLoading(true);
      props
        .fetch(
          `/apiv2/watchlists/id/${watchlist.id}/managers/galleryStats?sortBy=${sortBy}&primaryCurrency=${primaryCurrency}&secondaryCurrency=${secondaryCurrency}`,
        )
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            const ms = res.managers || [];
            setManagers(ms);
            const filteredMs = ms.filter((m) => searchText(searchQuery, m.manager.Nickname));
            setFilteredManagers(filteredMs);
            setDisplayedManagers(filteredMs.slice(0, 50));
          }
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const onSaveAddManagers = (playerIds) => {
    setSavingAddManagers(true);
    return props
      .fetch(`/apiv2/watchlists/id/${watchlist.id}/addItems`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(playerIds),
      })
      .then((res) => {
        setSavingAddManagers(false);
        if (res.error === undefined) {
          setOpenAddManagersModal(false);
          props.onAddManagers && props.onAddManagers();
        } else {
          console.log(res.error);
        }
      })
      .catch(
        errorCatcher(() => {
          setSavingAddManagers(false);
        }),
      );
  };

  const onRemoveManager = (managerToRemove) => {
    if (watchlist.id && managerToRemove) {
      setLoading(true);
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}/removeItems`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify([managerToRemove.Slug]),
        })
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.error === undefined) {
            getManagerGalleryStats();
          }
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const sortMenuItems = Object.keys(sortByLabels).map((key) => {
    const label = sortByLabels[key];
    return {
      key: key,
      label: label,
      className: "w-80 cursor-pointer " + (sortBy === key ? "font-semibold" : ""),
      onClick: () => {
        setShowSortMenu(false);
        setSortBy(key);
      },
    };
  });

  const sortByStatus = (field) => {
    let sort = "";
    if (sortBy.includes(field)) {
      sort = field + (sortBy.includes("asc") ? " desc" : " asc");
    } else {
      sort = field + " asc";
    }
    setSortBy(sort);
  };

  return (
    <div className={"h-full text-on-surface"}>
      <div className={"relative mb-4 flex flex-col sm:flex-row justify-between gap-2"}>
        <div className={"text-sm font-semibold text-primary cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
          Sort by: {sortByLabels[sortBy]}
          <span className={"ml-2 my-auto"}>
            <SortArrowIcon className={"fill-primary " + (showSortMenu ? "transform rotate-180" : "")} />
          </span>
        </div>
        <div className="flex flex-col sm:flex-row gap-2 justify-end">
          {loading && <Spinner />}
          <Form className="font-semibold text-sm">
            <div
              className={
                "m-auto relative flex h-9 bg-surface-container overflow-hidden border border-transparent-inverse-surface-low focus:border-transparent-inverse-surface-low border-opacity-10 rounded-lg"
              }
            >
              <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                <FormSearch className="h-5 w-5" aria-hidden="true" />
              </div>
              {searchQuery && (
                <div
                  className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                >
                  <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
                </div>
              )}
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                className={`h-9 block w-full pl-10 overflow-hidden bg-surface-container placeholder-on-surface-variant text-sm border-0 focus:border-0 focus:ring-0 ${
                  searchQuery !== "" ? "text-on-surface font-medium" : "text-on-surface-variant"
                }`}
                placeholder="Search a manager"
              />
            </div>
          </Form>
          {connectedUserIsOwner && <Button onClick={() => setOpenAddManagersModal(true)}>{`+ Add ${watchlist.type}s`}</Button>}
        </div>
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
          show={showSortMenu}
          onClickOutside={() => setShowSortMenu(false)}
          items={sortMenuItems}
        />
      </div>
      <div className={"h-full mb-8"}>
        {displayedManagers !== undefined && displayedManagers.length === 0 && (
          <div
            className={
              "flex flex-col justify-center w-full h-full bg-surface-container rounded-lg text-center border border-transparent-inverse-surface-low border-opacity-10"
            }
          >
            <div className={"m-auto py-36"}>
              <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
              <p className={"text-lg font-headers text-center font-bold mb-6"}>
                {connectedUserIsOwner ? "Your watchlist is empty" : "Watchlist is empty"}
              </p>
              {connectedUserIsOwner && <Button onClick={() => setOpenAddManagersModal(true)}>{`+ Add ${watchlist.type}s`}</Button>}
            </div>
          </div>
        )}
        {displayedManagers !== undefined && displayedManagers.length > 0 && (
          <div>
            <InfiniteScroll
              next={() => setDisplayedManagers(filteredManagers.slice(0, displayedManagers.length + 50))}
              hasMore={displayedManagers.length < filteredManagers.length}
              loader={<SDLoading />}
              dataLength={displayedManagers.length}
            >
              <WatchlistsGalleryStatsList
                watchlist={watchlist}
                displayedGalleryStatsRes={displayedManagers}
                onChangeSort={sortByStatus}
                sortBy={sortBy}
                connectedUserIsOwner={connectedUserIsOwner}
                removeManagerFromWatchlist={onRemoveManager}
                primaryCurrency={primaryCurrency}
                secondaryCurrency={secondaryCurrency}
              />
            </InfiniteScroll>
          </div>
        )}
      </div>
      <AddSearchableItemsModal
        type="manager"
        title={"Add to watchlist"}
        open={openAddManagersModal}
        onSave={onSaveAddManagers}
        saving={savingAddManagers}
        onCancel={() => setOpenAddManagersModal(false)}
      />
    </div>
  );
}

export default withRouter(withUser(WatchlistManagersGalleryStats));
