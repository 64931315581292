import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { withUser } from "../../userContext";
import { linearGradientDef } from "@nivo/core";

function StackedStatsGraph(props) {
  const [data, setData] = useState(props.data || []);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const filterWithKeys = (d) => {
    if (props.keys) {
      if (d.id === props.keys[0]?.r?.player?.DisplayName) {
        return "#1f77b4";
      } else if (d.id === props.keys[1]?.r?.player?.DisplayName) {
        return "#ff7f0e";
      } else if (d.id === props.keys[2]?.r?.player?.DisplayName) {
        return "#2ca02c";
      } else if (d.id === props.keys[3]?.r?.player?.DisplayName) {
        return "#d62728";
      } else if (d.id === props.keys[4]?.r?.player?.DisplayName) {
        return "#9467bd";
      }
    } else return { scheme: "category10" };
  };

  return (
    <div className={"rounded"}>
      <div className={"w-full mx-auto my-4 text-sm bg-focus rounded self-end flex"} style={{ height: "25rem" }}>
        <ResponsiveLine
          data={data}
          isInteractive={true}
          className={"rounded"}
          colors={props.keys ? (id) => filterWithKeys(id) : { scheme: "category10" }}
          margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            min: "0",
            max: "auto",
            reverse: false,
            stacked: true,
          }}
          enableSlices={"x"}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Lineup score",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={4}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-5}
          theme={{
            background: "#ffffff",
            textColor: "#333333",
            fontSize: 10,
            fontWeight: "semibold",
          }}
          defs={[
            linearGradientDef("gradientA", [
              { offset: 0, color: "inherit" },
              { offset: 100, color: "inherit", opacity: 0 },
            ]),
          ]}
          fill={[{ match: "*", id: "gradientA" }]}
          enableArea={true}
          curve={"linear"}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 40,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 130,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          layers={["markers", "axes", "areas", "crosshair", "points", "lines", "mesh", "legends"]}
        />
      </div>
    </div>
  );
}

export default withUser(StackedStatsGraph);
