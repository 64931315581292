import React from "react";

function BaseballStatIcon(props) {
  return (
    <div className={"flex flex-col space-y-2 items-center"}>
      <div className={"w-7 h-7 rounded-full bg-grey-f2 flex flex-col items-center justify-center"}>
        <p className={"self-center justify-center uppercase text-xs font-extrabold text-center text-textGrey3"}>{props.name}</p>
      </div>
      <div className={"self-center"}>
        <p className={"text-sm font-semibold"}>{props.value}</p>
      </div>
    </div>
  );
}

export default BaseballStatIcon;
