import ScoreAverage from "../decision/scoreAverage";
import React from "react";
import { sorareBaseball, sorareBasketball } from "./sports";
import { withUser } from "../../userContext";

function AverageAndGames(props) {
  let upStyle = "text-xxs font-semibold text-textGrey3 text-center";
  if (props.style === "player") {
    upStyle = "text-xs font-semibold text-center";
  }
  let showPct = props.showPct;
  if (props.sport === sorareBaseball || sorareBasketball === props.sport) {
    showPct = true;
  }
  let label = "L" + props.totalGms;
  if (props.sport === sorareBasketball) {
    label = "L10";
    if (props.totalGms === 5) {
      label = "L10";
    } else if (props.totalGms === 15) {
      label = "L40";
    } else if (props.totalGms === 40) {
      label = "Season";
    }
  }
  return (
    <div className={"flex flex-col space-y-1"}>
      <p className={upStyle}>{label}</p>
      <ScoreAverage
        sport={props.sport}
        showPct={showPct}
        font={props.font}
        style={props.style}
        size={props.size}
        avg={props.avg}
        powerAdj={false}
        period={props.totalGms}
        played={props.gms}
      />
    </div>
  );
}

export default withUser(AverageAndGames);
