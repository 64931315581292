import { SO5LiveDivision } from "../SO5/SO5LiveDivision";
import React from "react";

export const LeagueResultTiles = (props) => {
  const { leagues, userSlug, onSelectLeague } = props;
  return (
    <>
      {leagues?.map((league) => {
        return <LongTermLeagueResultTiles league={league} user={userSlug} onClick={() => onSelectLeague(league.league.id)} />;
      })}
    </>
  );
};

export const LongTermLeagueResultTiles = (props) => {
  const { user, onClick, link, dark, hideComp, unhideComp } = props;
  if (props.league === undefined || props.league === null) {
    return null;
  }
  const league = props.league?.league;
  const hidden = props.league.hidden !== undefined ? props.league.hidden : false;
  const currentLeaderboard = props.league.currentRanking.leaderboard;
  const currentRewards = props.league.currentRanking.rewards;
  const nextLeaderboard = props.league.nextRanking.leaderboard;
  const nextRewards = props.league.nextRanking.rewards;
  const gap = props.league.nextRanking.gap;
  const leagueConvertedAsLineup = {
    competition: {
      DivisionId: league.id,
      DisplayName: league.name,
      LogoUrl: league.pictureUrl,
      RewardType: "ranking",
      IsLive: league.status === "STARTED",
    },
    lineup: {
      LineupId: league.id,
      SorareUserSlug: user,
      Score: currentLeaderboard.totalScore,
      Top: 0,
      Rank: currentLeaderboard.rank,
    },
    players: [],
    hidden: hidden,
  };
  const currentRewardsConverted = {
    division_id: league.id,
    rank: currentLeaderboard.rank,
    score: currentLeaderboard.totalScore,
    reward_cards: currentRewards.cards,
    cards: currentRewards.cards,
    lineupId: league.id,
    eth: currentRewards.money?.["eth"] || 0,
    fiat: {
      usd: currentRewards.money?.["usd"] || 0,
      eur: currentRewards.money?.["eur"] || 0,
      gbp: currentRewards.money?.["gbp"] || 0,
    },
    coins: currentRewards.coins,
  };
  const nextRewardsConverted = {
    gap: gap,
    rank: nextLeaderboard.rank,
    score: nextLeaderboard.totalScore,
    reward_cards: nextRewards.cards,
    cards: nextRewards.cards,
    eth: nextRewards.money?.["eth"] || 0,
    fiat: {
      usd: nextRewards.money?.["usd"] || 0,
      eur: nextRewards.money?.["eur"] || 0,
      gbp: nextRewards.money?.["gbp"] || 0,
    },
    coins: nextRewards.coins,
  };

  const summary = {
    rewards: [currentRewardsConverted],
    next_rewards: {
      [league.id]: nextRewardsConverted,
    },
    lineups: [leagueConvertedAsLineup],
  };
  return (
    <div className={onClick ? "cursor-pointer hover:opacity-80" : ""} onClick={onClick}>
      <SO5LiveDivision
        info={summary}
        rewards={{
          current: currentRewardsConverted,
          next: nextRewardsConverted,
        }}
        lu={leagueConvertedAsLineup}
        link={link}
        dark={dark}
        hideLineup={hideComp}
        unhideLineup={unhideComp}
      />
    </div>
  );
};
