import React from "react";
import "./App.css";
import "./react-search.css";
import "./select-search.css";
import "rc-slider/assets/index.css";
import { Grommet } from "grommet";
import PlayerPage from "./components/players/playerPage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UserProvider from "./userContext";
import Login from "./components/user/login";
import PrivateRoute from "./components/privateRoute/privateRoute";
import Logout from "./components/user/logout";
import AuctionPage from "./components/auctions/auctionPage";
import SO5Live from "./components/SO5/SO5Live";
import OfferPage from "./components/offers/offerPage";
import ManagerPage from "./components/manager/managerPage";
import EndedAuctions from "./components/auctions/endedAuctions";
import StatsPage from "./components/stats/statsPage";
import FooterSD from "./components/footer/footer";
import SO5Wrapper from "./components/SO5/SO5Wrapper";
import PlayerSearch from "./components/players/playerSearch";
import UserProfile from "./components/user/profile";
import { PlayerRankingWrapper } from "./components/players/playerRankingWrapper";
import PlayerCards from "./components/players/playerCards";
import SO5ResultsPage from "./components/SO5/SO5ResultsPage";
import BundleDetails from "./components/auctions/bundleDetails";
import SO5FullSelectionStats from "./components/SO5/SO5FullSelectionStats";
import AskForPasswordReset from "./components/user/askForPasswordReset";
import ResetPassword from "./components/user/resetPassword";
import SorareLogin from "./components/user/sorareLogin";
import AcceptedOffers from "./components/offers/acceptedOffers";
import { BACKGROUND_GREY, BRAND_COLOR, HOVER_GREEN, MAIN_GREEN } from "./components/util/colors";
import LatestSoldBundles from "./components/auctions/latestSoldBundles";
import JoinSorare from "./components/home/joinSorare";
import HeaderBar from "./components/home/headerBar";
import VerifyEmail from "./components/user/verifyEmail";
import MobileAppLogin from "./components/user/mobileAppLogin";
import GameLivePage from "./components/games/gameLivePage";
import NewCardPage from "./components/cards/newCardPage";
import ConceptBuilder from "./components/concept/conceptBuilder";
import NewTeamPage from "./components/teams/newTeamPage";
import CompetitionHome from "./components/competitions/competitionHome";
import NewRewardsPage from "./components/rewards/newRewardsPage";
import AdvancedSearch from "./components/search/advancedSearch";
import SubscriptionPage from "./components/stripe/SubscriptionPage";
import ProductPage from "./components/stripe/productPage";
import Watchlists from "./components/watchlists/watchlists";
import Watchlist from "./components/watchlists/watchlist";
import ProjectionLeaderboardPage from "./components/probableStarters/lineupProjectionLeaderboard";
import {
  ArcElement,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import WatchlistRedirection from "./components/watchlists/watchlistRedirection";
import MyTrades from "./components/offers/myTrades";
import TradeBuilder from "./components/offers/tradeBuilder";
import HomeWrapper from "./components/home/homeWrapper";
import zoomPlugin from "chartjs-plugin-zoom";
import LiveMarket from "./components/market/liveMarket";
import BaseballGameLive from "./components/baseballGame/baseballGameLive";
import TermsOfUse from "./components/home/termsOfUse";
import ValidateRegistration from "./components/user/validateRegistration";
import DeprecatedAccount from "./components/user/deprecatedAccount";
import GameStarters from "./components/probableStarters/gameStarters";
import GamesList from "./components/probableStarters/gamesList";
import LineupBuilder from "./components/decision/lineupBuilder";
import BasketballGame from "./components/basketballGame/basketballGame";
import JohnNellis from "./components/ambassadors/johnnellis";
import Phils from "./components/ambassadors/phils";
import ZeNoob from "./components/ambassadors/zenoob";
import SO5CommunityLineup from "./components/SO5/SO5CommunityLineup";
import Cani from "./components/ambassadors/cani";
import ManagerCollection from "./components/manager/managerCollection";
import { ThemeContext, ThemeProvider } from "./themeContext";
import { ProductAnnouncementModales } from "./components/announcements/productAnnouncementsModale";
import { LongTermLeaguesLeaderboardPage } from "./components/longTermLeagues/longTermLeaguesLeaderboard";
import PrivacyPolicy from "./components/home/privacyPolicy";

ChartJS.register(
  ArcElement,
  Tooltip,
  ChartDataLabels,
  LineController,
  ScatterController,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  TimeScale,
  zoomPlugin,
  Filler,
);
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = 500;
ChartJS.defaults.color = "#292929";
ChartJS.defaults.font.family = "Inter";
ChartJS.overrides["pie"].plugins.legend.display = false;

const theme = {
  global: {
    font: {
      family: "Inter",
      size: "18px",
      height: "20px",
    },
    control: {
      background: {
        color: "white",
      },
      border: {
        radius: "0.25rem",
        color: "#e9e9ee",
      },
    },
    colors: {
      brand: BRAND_COLOR,
      focus: "white",
      rare: "#cd3434",
      "super-rare": "#1fa1ff",
      unique: "#452801",
      "accent-1": "#fa6800",
      "accent-2": "#128023",
      "accent-3": "#0050ef",
      "accent-4": "#d80073",
    },
    focus: {
      border: {
        color: "none",
      },
    },
    breakpoints: {
      small: {
        value: 800,
      },
      medium: {
        value: 1200,
      },
    },
  },
  heading: {
    level: {
      1: {
        font: { family: "Poppins" },
      },
      2: {
        font: { family: "Poppins", color: BRAND_COLOR },
        color: BRAND_COLOR,
        extend: "color: " + BRAND_COLOR + ";",
      },
    },
  },
  anchor: {
    hover: {
      textDecoration: "none",
    },
    focus: {
      color: "none",
    },
  },
  textInput: {
    extend: () => `background: white;`,
    container: {
      extend: () => `
        border-radius: 12px;
      `,
    },
  },
  accordion: {
    border: {
      side: "top",
      color: "#E5E5E5",
    },
    icons: {
      color: "dark-1",
    },
    hover: {
      heading: {
        color: "dark-1",
      },
    },
  },
  button: {
    border: {
      color: "none",
    },
    hover: {
      background: { color: HOVER_GREEN },
      primary: {
        extend: `background-color: #339379;`,
      },
      secondary: {
        border: { color: HOVER_GREEN },
      },
    },
    primary: {
      color: MAIN_GREEN,
      extend: `border: 0px solid ` + MAIN_GREEN + `;`,
    },
  },
  formField: {
    border: {
      color: BACKGROUND_GREY,
    },
    focus: {
      border: {
        color: BACKGROUND_GREY,
        side: "none",
      },
    },
  },
  select: {
    control: {
      extend: "background-color: white;",
      open: {
        radius: "24px",
        border: "2px solid " + BRAND_COLOR,
      },
    },
  },
  checkBox: {
    hover: {
      border: {
        color: "brand",
      },
    },
    color: "#f2f2f2",
    check: {
      extend: ({ theme, checked }) => `
        ${checked && `background-color: #f45206; border-color:#f45206;`}
        `,
    },
  },
  background: {
    color: "#f2f2f2",
  },
};
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      showSidebar: false,
    };
  }

  render() {
    const LoginFromRoute = (props) => {
      return <Login {...props} />;
    };
    let hidden = "hidden";
    let asideClass = "";
    let fullClass = "min-h-screen";
    if (this.state.showSidebar) {
      asideClass = "w-full sticky";
      fullClass = "hidden";
    } else {
      asideClass = "hidden w-0";
      fullClass = "min-h-screen";
    }
    return (
      <UserProvider>
        <ThemeProvider>
          <Grommet theme={theme}>
            <BrowserRouter>
              <ThemeContext.Consumer>
                {({ theme }) => (
                  <div id="root-theme" className={`${theme} bg-surface`}>
                    <ProductAnnouncementModales />
                    <div className={fullClass}>
                      <div>
                        <HeaderBar />
                      </div>
                      {/* enable when we have page that needs to be scrolled to top: <ScrollToTop paths={["/xxx"]}/> */}
                      <Switch>
                        <Route exact path="/termsOfUse" component={TermsOfUse} />
                        <Route exact path="/terms-of-use" component={TermsOfUse} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/validateRegistration" component={ValidateRegistration} />
                        <Route exact path="/deprecatedAccount" component={DeprecatedAccount} />
                        <PrivateRoute exact path="/fullSelectionStats" component={SO5FullSelectionStats} />
                        <PrivateRoute exact path="/player/:id" component={PlayerPage} />
                        <PrivateRoute exact path="/player/:id/sections/:tab" component={PlayerPage} />
                        <PrivateRoute exact path="/sold-bundles" component={LatestSoldBundles} />
                        <PrivateRoute exact path="/bundle/:bundleId" component={BundleDetails} />
                        <PrivateRoute exact path="/manager/:manager" component={ManagerPage} />
                        <PrivateRoute exact path="/manager/:manager/sections/:tab" component={ManagerPage} />
                        <PrivateRoute exact path="/endedAuctions" component={EndedAuctions} />
                        <Route exact path="/logout" component={Logout} />
                        <PrivateRoute exact path="/card/:id" component={NewCardPage} />
                        <PrivateRoute exact path="/auction/:id" component={AuctionPage} />
                        <PrivateRoute exact path="/offer/:id" component={OfferPage} />
                        <PrivateRoute exact path="/SO5/:username/:gw" component={SO5Live} />
                        <PrivateRoute exact path="/stats" component={StatsPage} />
                        <PrivateRoute exact path="/playerSearch/:playerName" component={PlayerSearch} />
                        <PrivateRoute exact path="/rankings" component={PlayerRankingWrapper} />
                        <PrivateRoute exact path="/advancedSearch" component={AdvancedSearch} />
                        <PrivateRoute exact path="/profile" component={UserProfile} />
                        <PrivateRoute exact path="/player/:playerId/cards" component={PlayerCards} />
                        <PrivateRoute exact path="/latestOffers/" component={AcceptedOffers} />
                        <PrivateRoute exact path="/team/:id" component={NewTeamPage} />
                        <PrivateRoute exact path="/team/:id/sections/:tab" component={NewTeamPage} />
                        <PrivateRoute exact path="/comp/:id" component={CompetitionHome} />
                        <PrivateRoute exact path="/comp/:id/sections/:tab" component={CompetitionHome} />
                        <PrivateRoute exact path="/game/:gameId" component={GameLivePage} />
                        <Route exact path="/" component={HomeWrapper} />
                        <Route exact path="/sorare-login" component={SorareLogin} />
                        <PrivateRoute exact path="/rewards/sections/:tab" component={NewRewardsPage} />
                        <PrivateRoute exact path="/SO5results" component={SO5ResultsPage} />
                        <PrivateRoute exact path="/SO5results/sections/:tab" component={SO5ResultsPage} />
                        <PrivateRoute exact path="/SO5results/:username" component={SO5Wrapper} />
                        <PrivateRoute exact path="/SO5-community-lineups" component={SO5CommunityLineup} />
                        <Route exact path="/login" component={LoginFromRoute} />
                        <Route exact path="/mobile-app-login" component={MobileAppLogin} />
                        <Route exact path="/reset-password/:otp" component={ResetPassword} />
                        <Route exact path="/ask-reset-password" component={AskForPasswordReset} />
                        <Route exact path="/join-sorare" component={JoinSorare} />
                        <PrivateRoute exact path="/email_verify/:email/:otp" component={VerifyEmail} />
                        <PrivateRoute exact path="/watchlists" component={Watchlists} />
                        <PrivateRoute exact path="/watchlists/:tab" component={Watchlists} />
                        <PrivateRoute exact path="/watchlists/id/:id" component={Watchlist} />
                        <PrivateRoute exact path="/watchlists/id/:id/:tab" component={Watchlist} />
                        <PrivateRoute exact path="/w/:shortid" component={WatchlistRedirection} />
                        <PrivateRoute exact path="/concept" component={ConceptBuilder} />
                        <PrivateRoute exact path="/subscription" component={SubscriptionPage} />
                        <PrivateRoute exact path="/product" component={ProductPage} />
                        <PrivateRoute exact path="/myTrades" component={MyTrades} />
                        <PrivateRoute exact path="/tradeBuilder" component={TradeBuilder} />
                        <PrivateRoute exact path="/liveMarket" component={LiveMarket} />
                        <PrivateRoute exact path="/baseballGame" component={BaseballGameLive} />
                        <PrivateRoute exact path="/gameStarters" component={GameStarters} />
                        <PrivateRoute exact path="/collection" component={ManagerCollection} />
                        <PrivateRoute exact path="/probableGameList" component={GamesList} />
                        <PrivateRoute exact path="/lineupBuilder" component={LineupBuilder} />
                        <PrivateRoute exact path="/lineupBuilder/sections/:tab" component={LineupBuilder} />
                        <PrivateRoute exact path="/lineupBuilder/sections/:tab/sport/:sport" component={LineupBuilder} />
                        <PrivateRoute exact path="/lineupProjections/leaderboard" component={ProjectionLeaderboardPage} />
                        <PrivateRoute exact path="/longTermLeagues/:sport/leaderboard" component={LongTermLeaguesLeaderboardPage} />
                        <PrivateRoute exact path="/basketballGame" component={BasketballGame} />
                        <PrivateRoute exact path="/JohnNellis" component={JohnNellis} />
                        <PrivateRoute exact path="/johnnellis" component={JohnNellis} />
                        <PrivateRoute exact path="/phils" component={Phils} />
                        <PrivateRoute exact path="/Phils" component={Phils} />
                        <PrivateRoute exact path="/zenoob" component={ZeNoob} />
                        <PrivateRoute exact path="/ZeNoob" component={ZeNoob} />
                        <PrivateRoute exact path="/Cani" component={Cani} />
                        <PrivateRoute exact path="/cani" component={Cani} />
                      </Switch>
                    </div>
                    <div>
                      <FooterSD />
                    </div>
                    <div id="headlessui-portal-root">
                      {/* So Headless UI dialogs are put under the theme context */}
                      <div data-headlessui-portal=""></div>
                    </div>
                  </div>
                )}
              </ThemeContext.Consumer>
            </BrowserRouter>
          </Grommet>
        </ThemeProvider>
      </UserProvider>
    );
  }
}

export default App;
