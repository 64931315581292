export const normalizeText = (text) => {
  if (text === undefined) {
    return "";
  }
  return text
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();
};

export const searchText = (search, text) => normalizeText(text).includes(normalizeText(search));

export const snakeToCamel = (str) =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));
