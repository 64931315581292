import React from "react";

const CaptainBadge = (props) => {
  return (
    <svg width={13} height={14} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 13.514a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm-2.99-8.34c-.306.54-.46 1.153-.46 1.84s.154 1.3.46 1.84c.314.54.737.963 1.27 1.27.54.3 1.144.45 1.81.45.787 0 1.47-.197 2.05-.59.587-.4.997-.957 1.23-1.67H8.26a1.72 1.72 0 01-.68.76c-.286.167-.62.25-1 .25-.406 0-.77-.093-1.09-.28a2.025 2.025 0 01-.74-.81 2.736 2.736 0 01-.26-1.22c0-.467.087-.873.26-1.22.18-.347.427-.613.74-.8.32-.193.684-.29 1.09-.29.38 0 .714.083 1 .25.294.167.52.417.68.75h1.61c-.233-.707-.643-1.26-1.23-1.66-.586-.4-1.27-.6-2.05-.6a3.6 3.6 0 00-1.81.46 3.32 3.32 0 00-1.27 1.27z"
        fill={props.color}
      />
    </svg>
  );
};

export default CaptainBadge;
