import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { withRouter } from "react-router-dom";
import SDLoading from "../util/SDLoading";
import SelectionWatchlistCard from "./selectionWatchlistCard";
import { searchText } from "../util/strings";
import { TextInput } from "grommet";
import { FormSearch } from "grommet-icons";
import NewWatchlistModal from "./newWatchlistModal";
import { sorareFootball, sportsLabels } from "../util/sports";

function EditWatchlistLinksModal(props) {
  const playerId = props.playerId;
  const manager = props.manager;
  const type = props.type || "player";
  const sports = props.sports || [sorareFootball];
  const [open, setOpen] = useState(false);
  const [watchlists, setWatchlists] = useState([]);
  const [displayedWatchlists, setdisplayedWatchlists] = useState([]);
  const [watchlistsStatuses, setWatchlistsStatuses] = useState({});
  const [watchlistsAdded, setWatchlistsAdded] = useState({});
  const [watchlistsRemoved, setWatchlistsRemoved] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [saving, setSaving] = useState(false);

  const [newWatchlistOpen, setNewWatchlistOpen] = useState(false);

  useEffect(() => {
    setMsg("");
    setOpen(props.open);
    if (props.open) {
      getWatchlists();
    }
  }, [props.open]);

  useEffect(() => {
    let res = {};
    watchlists.forEach((w) => {
      res = { ...res, [w.id]: w.isItemInWatchlist };
    });
    setWatchlistsStatuses(res);
  }, [watchlists]);

  useEffect(() => {
    setMsg("");
    setdisplayedWatchlists(watchlists.filter((w) => searchText(search, w.name)));
  }, [search]);

  const close = () => {
    if (!newWatchlistOpen) {
      props.onClose();
      setOpen(false);
      setError("");
      setMsg("");
      setWatchlists([]);
      setWatchlistsStatuses({});
      setWatchlistsAdded({});
      setWatchlistsRemoved({});
    }
  };
  const canSave = Object.keys(watchlistsAdded).length > 0 || Object.keys(watchlistsRemoved).length > 0;
  const getWatchlists = () => {
    setLoading(true);
    setMsg("");
    setSearch("");
    const spParams = new URLSearchParams();
    sports.forEach((s) => {
      spParams.append("sport", s);
    });
    props
      .fetch(`/apiv2/watchlists/links/${type}/${type === "player" ? `${playerId}?${spParams.toString()}` : manager}`)
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          setError(res.error);
        } else {
          setWatchlists(res || []);
          setdisplayedWatchlists(res || []);
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const onSelectCard = (id, selected) => {
    setMsg("");
    if (watchlistsStatuses[id]) {
      // Is initially in watchlist
      if (selected) {
        const newRemoved = { ...watchlistsRemoved };
        delete newRemoved[id];
        setWatchlistsRemoved(newRemoved);
      } else {
        setWatchlistsRemoved({ ...watchlistsRemoved, [id]: true });
      }
    } else {
      // Is initially in watchlist
      if (selected) {
        setWatchlistsAdded({ ...watchlistsAdded, [id]: true });
      } else {
        const newAdded = { ...watchlistsAdded };
        delete newAdded[id];
        setWatchlistsAdded(newAdded);
      }
    }
  };

  const onSave = () => {
    setSaving(true);
    setMsg("");
    let newWatchlistIds = [];
    Object.keys(watchlistsStatuses).forEach((watchlistId) => {
      const wasInWatchlist = watchlistsStatuses[watchlistId];
      if (wasInWatchlist && !watchlistsRemoved[watchlistId]) {
        newWatchlistIds.push(watchlistId);
      }
    });
    Object.keys(watchlistsAdded).forEach((watchlistId) => {
      newWatchlistIds.push(watchlistId);
    });

    props
      .fetch(`/apiv2/watchlists/links/${type}`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          itemId: type === "player" ? playerId : manager,
          watchlistIds: newWatchlistIds,
        }),
      })
      .then((res) => {
        if (res.status < 300) {
          setMsg("Saved successfully");
          const newWatchlists = watchlists.map((w) => {
            w.isItemInWatchlist = w.isItemInWatchlist ? (watchlistsRemoved[w.id] ? false : true) : watchlistsAdded[w.id] ? true : false;
            if (watchlistsRemoved[w.id]) {
              w.nbItems = w.nbItems - 1;
            }
            if (watchlistsAdded[w.id]) {
              w.nbItems = w.nbItems + 1;
            }
            return w;
          });
          setWatchlists(newWatchlists);
          setWatchlistsAdded({});
          setWatchlistsRemoved({});
          if (props.onSaveSuccess) {
            props.onSaveSuccess();
          }
        } else {
          const resJson = res.json();
          if (resJson && resJson.error) {
            setError(resJson.error);
          }
        }
        setSaving(false);
      })
      .catch(
        errorCatcher(() => {
          setSaving(false);
        }),
      );
  };

  const onCreateWatchlist = () => {
    getWatchlists();
  };

  if ((playerId === undefined || playerId === "") && (manager === undefined || manager === "")) {
    return null;
  }

  const compatibleSports = sportsLabels.filter(({ name, value }) => {
    const res = sports.find((s) => s === value);
    return res;
  });

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0" onClose={newWatchlistOpen ? () => {} : close}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full p-0">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={close}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"w-auto mt-8"}>
                  <div className={"grid grid-cols-3 w-full border-b-2 border-grey-e5 pb-3"}>
                    <div className={"pl-3 w-60"}>
                      <TextInput
                        placeholder={"Search..."}
                        onChange={(v) => {
                          setSearch(v.target.value);
                        }}
                        value={search}
                        reverse
                        size="small"
                        icon={<FormSearch />}
                      />
                    </div>
                    <h2 className={"text-center font-headers text-brand-black text-2xl font-bold mt-0"}>Manage inclusion to watchlists</h2>
                  </div>
                  <div className={"w-full bg-background-grey"}>
                    {loading && (
                      <div className={"w-full h-full pt-10 pb-20 m-auto w-auto"}>
                        <SDLoading />
                      </div>
                    )}
                    {!loading && displayedWatchlists.length > 0 && (
                      <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 overflow-auto p-3 pb-28 max-h-modal-content"}>
                        {displayedWatchlists.map((watchlist) => {
                          const isSelected = watchlist.isItemInWatchlist
                            ? watchlistsRemoved[watchlist.id]
                              ? false
                              : true
                            : watchlistsAdded[watchlist.id]
                            ? true
                            : false;
                          return (
                            <SelectionWatchlistCard
                              key={watchlist.id}
                              watchlist={watchlist}
                              isSelected={isSelected}
                              onSelect={onSelectCard}
                            />
                          );
                        })}
                      </div>
                    )}
                    {!loading && displayedWatchlists.length === 0 && (
                      <div className={"overflow-auto p-5 pb-20 max-h-modal-content"}>
                        <p className={"text-sm text-center"}>
                          {`No compatible watchlist found for ${compatibleSports.map((s) => s.name).join(" or ")}`}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={"absolute w-full h-24 bottom-0 mx-full pb-4 mb-0 px-3 pt-3 bg-gradient-to-t from-white via-white"}>
                    <div className={"grid grid-cols-2 gap-4 text-center pt-8"}>
                      <div className={"flex justify-end"}>
                        <button
                          className={
                            "text-brand h-10 p-2 w-40 text-sm font-medium rounded-lg bg-brand-pastel cursor-pointer focus:outline-none"
                          }
                          onClick={() => setNewWatchlistOpen(true)}
                        >
                          New watchlist
                        </button>
                      </div>
                      <div className={"flex justify-start"}>
                        <div className={"inline"}>
                          <button
                            disabled={!canSave}
                            title={canSave ? "Create watchlist" : "No change to save"}
                            className={
                              "text-white h-10 p-2 w-40 text-sm font-medium rounded-lg " +
                              (canSave
                                ? "bg-brand focus:outline-none hover:bg-brand-light cursor-pointer"
                                : "bg-brand-light cursor-default")
                            }
                            onClick={onSave}
                          >
                            {saving ? <span>Saving...</span> : <span>Save</span>}
                          </button>
                          {error && <p className={"inline align-middle ml-2 text-live-red justify-end"}>{error}</p>}
                          {msg && <p className={"inline align-middle ml-2 text-success-green justify-end"}>{msg}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
          <NewWatchlistModal
            sports={sports}
            type={type}
            open={newWatchlistOpen}
            onCreate={onCreateWatchlist}
            onClose={() => setNewWatchlistOpen(false)}
          />
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withRouter(withUser(EditWatchlistLinksModal));
