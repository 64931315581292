import React, { useState } from "react";
import UnknownClub from "../../img/unknown_club.png";
import PlayerScoreModal from "./playerScoreModal";
import GoalIcon from "../../img/ICN_Ballon.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import YellowCard from "../../img/yellow-card.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import CleanSheet from "../../img/cleansheet.svg";
import NotInSquad from "../../img/notInSquad.svg";
import CameIn from "../../img/cameIn.svg";
import Bench from "../../img/benchNotCameIn.svg";
import Started from "../../img/starter.svg";
import LockerWithBg from "../../img/locker-squared-bg.svg";
import { t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import { Button } from "../util/button";
import PenaltySave from "../../img/PenaltySave.svg";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { baseballScale, basketballScale, footballScale } from "../util/scales";
import format from "date-fns/format";
import { withUser } from "../../userContext";

function PlayerSO5StatsLine(props) {
  const [isOpen, setOverlay] = useState(false);
  const [isHoverLocker, setHoverLocker] = useState(false);

  const sport = props.player.Sport;
  const stat = props.stat;

  let SO5Score = parseFloat(stat.stats.SO5Score);
  const newMatrix = props.newMatrix;
  if (newMatrix) {
    SO5Score = parseFloat(stat.stats.NewScore);
  }
  if (sport === sorareBaseball) {
    SO5Score = parseFloat(stat.stats.Score);
  }
  if (sport === sorareBasketball) {
    SO5Score = parseFloat(stat.stats.score);
  }
  if (props.old && sport === sorareFootball) {
    if (stat.player.Position === "Goalkeeper") {
      SO5Score = parseFloat(stat.stats.SO5ScoreGK);
    } else if (stat.player.Position === "Defender") {
      SO5Score = parseFloat(stat.stats.SO5ScoreDF);
    } else if (stat.player.Position === "Midfielder") {
      SO5Score = parseFloat(stat.stats.SO5ScoreMD);
    } else if (stat.player.Position === "Forward") {
      SO5Score = parseFloat(stat.stats.SO5ScoreFW);
    }
    if (SO5Score === 0) {
      SO5Score = parseFloat(stat.stats.SO5Score);
    }
  }
  let level = stat.stats.Level;
  let allAround = stat.stats.AllAroundScore;
  if (props.position) {
    if (props.position === "Goalkeeper") {
      SO5Score = parseFloat(stat.stats.SO5ScoreGK);
      level = stat.stats.LevelGK || stat.stats.Level;
      allAround = stat.stats.AaGK || stat.stats.AllAroundScore;
      if (newMatrix) {
        SO5Score = parseFloat(stat.stats.NewScoreGK);
        level = stat.stats.NewLevelGK;
        allAround = stat.stats.NewAaGK;
      }
    } else if (props.position === "Defender") {
      SO5Score = parseFloat(stat.stats.SO5ScoreDF);
      level = stat.stats.LevelFD || stat.stats.Level;
      allAround = stat.stats.AaDF || stat.stats.AllAroundScore;
      if (newMatrix) {
        SO5Score = parseFloat(stat.stats.NewScoreDF);
        level = stat.stats.NewLevelFD;
        allAround = stat.stats.NewAaDF;
      }
    } else if (props.position === "Midfielder") {
      SO5Score = parseFloat(stat.stats.SO5ScoreMD);
      level = stat.stats.LevelFD || stat.stats.Level;
      allAround = stat.stats.AaMD || stat.stats.AllAroundScore;
      if (newMatrix) {
        SO5Score = parseFloat(stat.stats.NewScoreMD);
        level = stat.stats.NewLevelFD;
        allAround = stat.stats.NewAaMD;
      }
    } else if (props.position === "Forward") {
      SO5Score = parseFloat(stat.stats.SO5ScoreFW);
      level = stat.stats.LevelFD || stat.stats.Level;
      allAround = stat.stats.AaFW || stat.stats.AllAroundScore;
      if (newMatrix) {
        SO5Score = parseFloat(stat.stats.NewScoreFW);
        level = stat.stats.NewLevelFD;
        allAround = stat.stats.NewAaFW;
      }
    }
  }
  let bgColor;
  let scale = footballScale;
  if (sport === sorareBaseball) {
    scale = baseballScale;
  } else if (sport === sorareBasketball) {
    scale = basketballScale;
  }
  if (SO5Score < scale[0]) {
    bgColor = "horrible-score";
  } else if (SO5Score < scale[1]) {
    bgColor = "bad-score";
  } else if (SO5Score < scale[2]) {
    bgColor = "ok-score";
  } else if (SO5Score < scale[3]) {
    bgColor = "good-score";
  } else if (SO5Score < scale[4]) {
    bgColor = "great-score";
  } else {
    bgColor = "amazing-score";
  }
  SO5Score = SO5Score.toFixed(2);
  let fontColor = "focus";
  if (sport === sorareFootball && stat.stats.mins_played === 0) {
    level = (0.0).toFixed(2);
    SO5Score = "DNP";
    bgColor = "bar-grey";
    fontColor = "brand-black";
  }
  if (sport === sorareBaseball && stat.stats.Played === false) {
    SO5Score = "DNP";
    bgColor = "bar-grey";
    fontColor = "brand-black";
  }
  let homeLogo = stat.home_team_logo;
  let awayLogo = stat.away_team_logo;
  if (homeLogo === "") {
    homeLogo = UnknownClub;
  }
  if (awayLogo === "") {
    awayLogo = UnknownClub;
  }
  let stats = stat.stats;
  let icons = [];
  let divStyle = "w-5 h-5 self-center";
  let imgStyle = "w-full h-full object-contain";
  if (sport === sorareFootball) {
    for (let i = 0; i < stats.Goals; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={GoalIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.OwnGoals; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={OwnGoal} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AdjustedGoalAssist; i++) {
      let left = icons.length * 2;
      let z = 10 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={AssistIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AssistPenaltyWon; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={Whistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ClearanceOffLine + stats.LastManTackle; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={AngelBall} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltySaves + stats.PenaltySave; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={PenaltySave} />
        </div>,
      );
    }
    for (let i = 0; i < stats.RedCard; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedCard} />
        </div>,
      );
    }
    for (let i = 0; i < stats.OwnGoal; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={OwnGoal} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltyConceded; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedWhistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ErrorLeadToGoal; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedShoe} />
        </div>,
      );
    }
    for (let i = 0; i < stats.YellowCard; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={YellowCard} />
        </div>,
      );
    }
    if (stat.player.Position === "Goalkeeper") {
      for (let i = 0; i < stats.CleanSheet; i++) {
        let left = icons.length * 2;
        let z = 100 + icons.length;
        icons.push(
          <div className={divStyle}>
            <img className={imgStyle} src={CleanSheet} />
          </div>,
        );
      }
    }
  }
  let statusIcon = NotInSquad;
  let bgBoxColor = "#FF8D73";
  let boxFontColor = "#E73107";
  let tip = "Out of the squad.";
  if (sport === sorareFootball) {
    if (stats.FormationPlace > 0 || stats.Started) {
      statusIcon = Started;
      tip = "Started the game.";
      bgBoxColor = "#ABD8FF";
      boxFontColor = "#0380F3";
    } else if (stats.mins_played > 0 || stats.Played) {
      statusIcon = CameIn;
      tip = "Came from the bench.";
      bgBoxColor = "#FFD89E";
      boxFontColor = "#e78934";
    } else if (stats.OnGameSheet || stats.OnRoster) {
      statusIcon = Bench;
      tip = "Stayed on the bench.";
      bgBoxColor = "#FF8D73";
      boxFontColor = "#E73107";
    }
  }
  if (sport === sorareBasketball) {
    if (stats.started) {
      statusIcon = Started;
      tip = "Started the game.";
      bgBoxColor = "#ABD8FF";
      boxFontColor = "#0380F3";
    } else if (stats.played) {
      statusIcon = CameIn;
      tip = "Came from the bench.";
      bgBoxColor = "#FFD89E";
      boxFontColor = "#e78934";
    } else if (stats.onRoster) {
      statusIcon = Bench;
      tip = "Stayed on the bench.";
      bgBoxColor = "#FF8D73";
      boxFontColor = "#E73107";
    } else {
      statusIcon = NotInSquad;
      tip = "Out of the squad.";
      bgBoxColor = "#FF8D73";
      boxFontColor = "#E73107";
    }
  }
  let rounded = "";
  if (props.last) {
    rounded = " rounded-b-lg";
  }
  let isHome = stat.PlayerTeamId === stat.game.HomeTeamId;
  let hasWon = true;
  let scoreTextColor = " text-win-text";
  let scoreBgColor = " bg-win-bg";
  let hasLost = false;
  let homeScore = stat.game.HomeGoals;
  let awayScore = stat.game.AwayGoals;
  if (sport === sorareBaseball) {
    homeScore = stat.game.HomeScore;
    awayScore = stat.game.AwayScore;
  } else if (sport === sorareBasketball) {
    homeScore = stat.game.HomeScore;
    awayScore = stat.game.AwayScore;
  }
  if (isHome) {
    if (homeScore < awayScore) {
      hasWon = false;
      hasLost = true;
    }
  } else {
    if (homeScore > awayScore) {
      hasWon = false;
      hasLost = true;
    }
  }
  if (homeScore === awayScore) {
    hasWon = false;
    hasLost = false;
  }
  if (hasLost) {
    scoreTextColor = " text-loss-text";
    scoreBgColor = " bg-loss-bg";
  } else if (!hasLost && !hasWon) {
    scoreTextColor = " text-draw-text";
    scoreBgColor = " bg-draw-bg";
  }
  let fullPosition = stat.stats.FullPosition;
  let abbreviated = "";
  let positionTip = "";
  if (sorareFootball === sport) {
    if (fullPosition === " Substitute") {
      abbreviated = "SUB";
      positionTip = "Entered as a substitute.";
    } else if (fullPosition === "" && stat.stats.mins_played === 0) {
      abbreviated = "DNP";
      positionTip = "Did not play in that game.";
    } else if (fullPosition === "" && stat.stats.mins_played > 0) {
      abbreviated = "UNK";
      positionTip = "Unknown position.";
    } else {
      fullPosition.split(" ").map((l) => {
        if (l !== undefined && l.length > 0) {
          abbreviated += l[0];
        } else {
          abbreviated = "UNK";
          positionTip = "Unknown position.";
        }
      });
      positionTip = fullPosition;
      if (fullPosition === "Left Defender") {
        abbreviated = "LB";
        positionTip = "Left Back (Left Defender)";
      } else if (fullPosition === "Right Defender") {
        abbreviated = "RB";
        positionTip = "Right Back (Right Defender)";
      } else if (fullPosition === "Centre Defender") {
        abbreviated = "CB";
        positionTip = "Centre Back (Centre Defender)";
      } else if (fullPosition === "Left/Centre Defender") {
        abbreviated = "CB";
        positionTip = "Centre Back (Left/Centre Defender)";
      } else if (fullPosition === "Centre/Right Defender") {
        abbreviated = "CB";
        positionTip = "Centre Back (Centre/Right Defender)";
      } else if (fullPosition === "Left/Centre Midfielder") {
        abbreviated = "CM";
        positionTip = "Centre Midfielder (Left/Centre Midfielder)";
      } else if (fullPosition === "Centre/Right Midfielder") {
        abbreviated = "CM";
        positionTip = "Centre Midfielder (Centre/Right Midfielder)";
      } else if (fullPosition === "Left/Centre Defensive Midfielder") {
        abbreviated = "CDM";
        positionTip = "Centre Defensive Midfielder (Left/Centre Defensive Midfielder)";
      } else if (fullPosition === "Centre/Right Defensive Midfielder") {
        abbreviated = "CDM";
        positionTip = "Centre Defensive Midfielder (Centre/Right Defensive Midfielder)";
      } else if (fullPosition === "Centre Defensive Midfielder") {
        abbreviated = "CDM";
        positionTip = "Centre Defensive Midfielder (Centre Defensive Midfielder)";
      } else if (fullPosition === "Left/Centre Attacking Midfielder") {
        abbreviated = "CAM";
        positionTip = "Centre Attacking Midfielder (Left/Centre Attacking Midfielder)";
      } else if (fullPosition === "Centre/Right Attacking Midfielder") {
        abbreviated = "CAM";
        positionTip = "Centre Attacking Midfielder (Centre/Right Attacking Midfielder)";
      } else if (fullPosition === "Left Attacking Midfielder") {
        abbreviated = "LAM";
        positionTip = "Left Attacking Midfielder";
      } else if (fullPosition === "Right Attacking Midfielder") {
        abbreviated = "RAM";
        positionTip = "Right Attacking Midfielder";
      } else if (fullPosition === "Centre Striker") {
        abbreviated = "ST";
        positionTip = "Centre Striker";
      } else if (fullPosition === "Left/Centre Striker") {
        abbreviated = "ST";
        positionTip = "Left/Centre Striker";
      } else if (fullPosition === "Centre/Right Striker") {
        abbreviated = "ST";
        positionTip = "Centre/Right Striker";
      } else if (fullPosition === "Left Striker") {
        abbreviated = "ST";
        positionTip = "Left Striker";
      } else if (fullPosition === "Right Striker") {
        abbreviated = "ST";
        positionTip = "Right Striker";
      } else if (fullPosition === "Centre Goalkeeper") {
        abbreviated = "GK";
        positionTip = "Goalkeeper";
      }
    }
    if (abbreviated === undefined || abbreviated.toLowerCase() === "undefined") {
      abbreviated = "UNK";
      positionTip = "Position unknown";
    }
  }
  let gw = stat.gameweek;
  let gwUp = "";
  let gwDown = "";
  if (sorareFootball === sport) {
    let splitGw = gw.split(":");
    gwUp = splitGw[0];
    gwDown = splitGw[1];
  } else if (sorareBaseball === sport) {
    gwUp = "No gameweek";
    gwDown = format(new Date(stat.game.GameTime), "MMM do yyyy");
    abbreviated = stat.stats.Position;
    if (stat.stats.Started) {
      statusIcon = Started;
      tip = "Started the game.";
      bgBoxColor = "#ABD8FF";
      boxFontColor = "#0380F3";
    } else if (stat.stats.Played) {
      statusIcon = CameIn;
      tip = "Came from the bench.";
      bgBoxColor = "#FFB89A";
      boxFontColor = "#F55205";
    } else if (stat.stats.OnRoster) {
      statusIcon = Bench;
      tip = "Stayed on the bench.";
      bgBoxColor = "#FF8D73";
      boxFontColor = "#E73107";
    }
    if (stat.stats.Position === "") {
      if (!stat.stats.Played) abbreviated = "SUB";
      if (!stat.stats.OnRoster) abbreviated = "OUT";
    }
  } else if (sport === sorareBasketball) {
    abbreviated = stat.stats.position;
    gwUp = "No gameweek";
    gwDown = format(new Date(stat.game.GameTime), "MMM do yyyy");
  }

  let played = stat.stats.mins_played > 0 || stat.stats.Played;

  let scoreWidth = " w-14 ";
  if (sorareBasketball === sport) {
    scoreWidth = " w-18 ";
  }

  return (
    /*<div>
     */
    <tr className={"bg-focus w-full py-4 border-b-2 px-2 border-light-border" + rounded}>
      <td>
        <div className={"pl-2 py-4 w-full xl:block text-title-grey text-xs font-medium self-center justify-center flex flex-col"}>
          <p className={"text-brand-black"}>{gwUp}</p>
          <p>{gwDown}</p>
        </div>
      </td>
      <td>
        <div className={"w-full flex flex-row space-x-4 justify-center"}>
          <div className={"w-6/12 flex flex-row justify-end space-x-4"}>
            <a href={"/team/" + stat.game.HomeTeamId} target={"_blank"} rel="noreferrer">
              <span className={"hidden md:block text-base text-right font-medium self-center"}>{stat.home_display_name}</span>
            </a>
            <div className={"w-6 h-6 self-center"}>
              <img className={"w-full h-full object-contain"} src={homeLogo} />
            </div>
          </div>
          <div className={"w-1/12 flex flex-row justify-center self-center"}>
            <a
              href={
                sport === sorareBasketball
                  ? "/basketballGame?gameId=" + stat.game.GameId
                  : sport === sorareBaseball
                  ? "/baseballGame?gameId=" + stat.game.GameId
                  : "/game/" + stat.game.GameId
              }
            >
              <div
                className={
                  "flex flex-row p-1 rounded-lg space-x-1 self-center justify-center text-sm font-semibold " +
                  scoreWidth +
                  scoreTextColor +
                  scoreBgColor
                }
              >
                <p>{homeScore}</p>
                <p>-</p>
                <p>{awayScore}</p>
              </div>
            </a>
          </div>
          <div className={"w-5/12 flex flex-row justify-start space-x-4"}>
            <div className={"w-6 h-6 self-center"}>
              <img className={"w-full h-full object-contain"} src={awayLogo} />
            </div>
            <a href={"/team/" + stat.game.AwayTeamId} target={"_blank"} rel="noreferrer">
              <span className={"hidden md:block text-base text-left font-medium self-center"}>{stat.away_display_name}</span>
            </a>
          </div>
        </div>
      </td>
      {sport === sorareBaseball || (sport === sorareBasketball && <td />)}
      {sport === sorareBaseball || (sport === sorareBasketball && <td />)}
      {sport === sorareBaseball && <td />}
      {sport === sorareBaseball && <td />}
      {(t2OrAbove(props.user?.tier) || props.i === 0) && sport === sorareFootball && (
        <td>
          <div className={"self-center flex flex-row space-x-2 justify-end mx-auto pr-2"}>
            {icons.length > 0 && <div className={"flex flex-row self-center justify-end"}>{icons}</div>}
            {played && sorareFootball === sport ? (
              <div className={"w-12 flex flex-row space-x-1 pr-1 rounded justify-around"} style={{ backgroundColor: bgBoxColor }}>
                <div className={"w-6 h-6 justify-end self-center"}>
                  <img className={"w-full h-full object-contain"} data-tip={tip} src={statusIcon} />
                </div>
                <p className={"text-xs text-left font-semibold self-center"} style={{ color: boxFontColor }}>
                  {stat.stats.mins_played}&apos;
                </p>
              </div>
            ) : (
              <div className={"w-12 flex flex-row space-x-1 rounded justify-center"}>
                <div className={"w-6 h-6 justify-end self-center"}>
                  <img className={"w-full h-full object-contain"} data-tip={tip} src={statusIcon} />
                </div>
              </div>
            )}
            <div
              data-tip={positionTip}
              className={"w-12 justify-end text-center font-bold text-xs uppercase bg-grey-e5 px-2 py-1 self-center rounded-lg"}
            >
              <p>{abbreviated}</p>
            </div>
          </div>
        </td>
      )}
      {t1OrBelow(props.user?.tier) && props.i > 0 && sport === sorareFootball && (
        <td>
          <div className={"relative flex flex-row justify-end cursor-pointer self-center"}>
            <div
              className="h-6 mr-1"
              onMouseEnter={() => {
                setHoverLocker(true);
              }}
              onMouseLeave={() => {
                setHoverLocker(false);
              }}
            >
              <img alt="" src={LockerWithBg} />
            </div>
            {isHoverLocker && (
              <div
                className="flex flex-col z-20 absolute rounded-lg shadow justify-end items-center bg-focus overflow-hidden translate-x-1/2"
                onMouseEnter={() => {
                  setHoverLocker(true);
                }}
                onMouseLeave={() => {
                  setHoverLocker(false);
                }}
              >
                <div
                  style={{
                    background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(243,252,251,1) 65%, rgba(220,255,251,1) 100%)",
                  }}
                  className={"px-2 pt-8 pb-4 flex flex-col gap-2 w-72"}
                >
                  <div className="flex justify-center items-center gap-2 mb-4">
                    <img className={"h-5 w-5"} src={GoalIcon} />
                    <img className={"h-5 w-5"} src={AssistIcon} />
                    <img className={"h-5 w-5"} src={Started} />
                    <img className={"h-5 w-5"} src={CameIn} />
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="w-4/5 text-sm text-center font-black font-headers">Want more insights?</p>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="w-4/5 text-xs text-center text-black font-semibold">
                      Become a Star member to unlock playing status, decisive actions and position on the pitch.
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <a href={"/product"}>
                      <Button label={"Upgrade"} />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </td>
      )}

      {sport === sorareFootball && (
        <td>
          <div
            className={
              "bg-bar-grey text-center rounded text-title-grey justify-center mx-auto flex flex-row w-16 lg:w-20 p-1.5 font-medium text-base"
            }
          >
            {parseFloat(level).toFixed(2)}
          </div>
        </td>
      )}
      {sport === sorareFootball && (
        <td>
          <div
            className={
              "bg-bar-grey text-center rounded text-title-grey w-16 lg:w-20 p-1.5 font-medium flex flex-row space-x-1 justify-center mx-auto text-base self-center"
            }
          >
            {parseFloat(allAround).toFixed(2)}
          </div>
        </td>
      )}
      {sport === sorareBaseball && (
        <td>
          <div className={"w-full self-center flex flex-row justify-end mx-auto space-x-1"}>
            {icons.length > 0 && <div className={"flex flex-row self-center justify-end"}>{icons}</div>}
            {stat.stats.LineupPosition > 0 ? (
              <div className={"w-12 flex flex-row space-x-1 mr-1 rounded justify-center"}>
                <div className={"w-12 flex flex-row space-x-1 pr-1 rounded justify-around"} style={{ backgroundColor: bgBoxColor }}>
                  <div className={"w-6 h-6 justify-end self-center"}>
                    <img className={"w-full h-full object-contain"} data-tip={tip} src={statusIcon} />
                  </div>
                  <p data-tip={"Lineup position"} className={"text-xs text-left font-semibold self-center"} style={{ color: boxFontColor }}>
                    #{stat.stats.LineupPosition}
                  </p>
                </div>
              </div>
            ) : (
              <div className={"w-12 flex flex-row space-x-1 rounded justify-center"}>
                <div className={"w-6 h-6 justify-end self-center"}>
                  <img className={"w-full h-full object-contain"} data-tip={tip} src={statusIcon} />
                </div>
              </div>
            )}

            <div
              data-tip={positionTip}
              className={"w-12 justify-end text-center font-bold text-xs uppercase bg-grey-e5 px-2 py-1 self-center rounded-lg"}
            >
              <p>{abbreviated}</p>
            </div>
          </div>
        </td>
      )}
      {sport === sorareBasketball && (
        <td>
          <div className={"w-full flex flex-row justify-end space-x-2"}>
            {stat.stats.played ? (
              <div className={"w-12 flex flex-row space-x-1 pr-1 rounded justify-end"} style={{ backgroundColor: bgBoxColor }}>
                <div className={"w-6 h-6 justify-end self-center"}>
                  <img className={"w-full h-full object-contain"} data-tip={tip} src={statusIcon} />
                </div>
                <p className={"text-xs text-left font-semibold self-center"} style={{ color: boxFontColor }}>
                  {stat.stats.minutes}&apos;
                </p>
              </div>
            ) : (
              <div className={"w-12 flex flex-row space-x-1 rounded justify-center"}>
                <div className={"w-6 h-6 justify-end self-center"}>
                  <img className={"w-full h-full object-contain"} data-tip={tip} src={statusIcon} />
                </div>
              </div>
            )}
            <div
              data-tip={positionTip}
              className={"w-12 justify-end text-center font-bold text-xs uppercase bg-grey-e5 px-2 py-1 self-center rounded-lg"}
            >
              <p>{abbreviated}</p>
            </div>
          </div>
        </td>
      )}

      <td>
        <div>
          {isOpen && (
            <PlayerScoreModal
              open={isOpen}
              sport={sport}
              stats={stat.stats}
              player={props.player}
              position={props.position}
              newMatrix={props.newMatrix}
              close={() => setOverlay(false)}
              game={stat}
            />
          )}
          <div className={"self-center flex flex-row mx-auto justify-end w-20"}>
            <div
              className={
                "cursor-pointer text-center bg-gradient rounded bg-gradient-to-l from-" +
                bgColor +
                "-start to-" +
                bgColor +
                "-end text-" +
                fontColor +
                " lg:w-20 p-1.5 font-semibold text-base"
              }
              onClick={() => setOverlay(true)}
            >
              {SO5Score}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default withUser(PlayerSO5StatsLine);
