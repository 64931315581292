import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withUser } from "../../userContext";
import queryString from "query-string";

class Logout extends Component {
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.allDevices) {
      this.props.logoutAllDevices();
    } else {
      this.props.logout();
    }
  }

  render() {
    return <Redirect to={"/"} />;
  }
}

export default withUser(Logout);
