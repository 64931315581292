import React, { useState } from "react";
import { withUser } from "../../userContext";
import UnknownClub from "../../img/unknown_club.png";
import { ReactComponent as Carret } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as Invalid } from "../../img/icons-circle-invalid-no-color.svg";
import Summer2024PrizePool from "../../img/projections-summer-2024-banner-prize-pool.png";
import { ProjectedLineupStats } from "../util/communityPrediction";
import { ReactTooltip } from "../util/tooltip.js";
import PopperPortal from "../players/helpers/popper";
import { toFixedIfNecessary } from "../util/formatNumber";
import { searchText } from "../util/strings";
import { formatNumber } from "../util/formatNumber";
import { Tag } from "../util/tag";

export const ProjectionRatioIndicator = (props) => {
  const { loading, voted, eligible, hidePct } = props;
  const fontSize = props.fontSize || "text-sm";
  const alt = props.alt || "low";
  const neutralText = props.neutralText || (alt === "high" || alt === "normal" ? "text-on-surface" : "text-on-surface-variant");
  if (loading) {
    return <div className={`rounded-full animate-pulse bg-surface-container-high text-on-surface-variant w-16 h-5`}></div>;
  }

  const isNotApplicable = eligible === 0;
  const ratio = eligible > 0 ? (voted / eligible) * 100 : 0;
  const leagueRatioIndicatorColor =
    ratio < 0
      ? "text-quality-scale-terrible-on-dim-quality-container-fixed bg-quality-scale-terrible-quality-container-dim-fixed"
      : ratio === 0
      ? `${alt === "high" ? `bg-surface-container-high ${neutralText}` : `bg-transparent-inverse-surface-low bg-opacity-10 ${neutralText}`}`
      : ratio < 50
      ? "text-quality-scale-bad-on-dim-quality-container-fixed bg-quality-scale-bad-quality-container-dim-fixed"
      : ratio < 100
      ? "text-quality-scale-good-on-dim-quality-container-fixed bg-quality-scale-good-quality-container-dim-fixed"
      : "text-quality-scale-excellent-on-dim-quality-container-fixed bg-quality-scale-excellent-quality-container-dim-fixed";
  return (
    <div
      className={`rounded-full whitespace-nowrap w-fit ${fontSize} px-2 py-0.5 font-semibold ${leagueRatioIndicatorColor}`}
      data-tip={isNotApplicable ? "No minted players in the league" : ""}
    >
      {isNotApplicable ? "NA" : hidePct ? `${voted}/${eligible}` : `${voted}/${eligible} (${ratio.toFixed(0)}%)`}
    </div>
  );
};

export const ProjectionPointsIndicator = (props) => {
  const { points, totalPossiblePoints, eligible, details, isValid } = props;
  const alt = props.alt || "low";
  const [popHover, setPopHover] = useState(false);
  const displayPopHover = () => setPopHover(true);
  const hidePopHover = () => setPopHover(false);
  if (points === undefined || points === null) {
    return null;
  }
  const neutralText = props.neutralText || (alt === "high" || alt === "normal" ? "text-on-surface" : "text-on-surface-variant");
  const parsedPoints = parseFloat(points);
  const parsedTotalPossiblePoints = parseFloat(totalPossiblePoints);
  const isNotApplicable = eligible === 0;
  const ratio = parsedTotalPossiblePoints > 0 ? (parsedPoints / parsedTotalPossiblePoints) * 100 : 0;
  const leagueRatioIndicatorColor =
    ratio < 0
      ? "text-quality-scale-terrible-on-dim-quality-container-fixed bg-quality-scale-terrible-quality-container-dim-fixed"
      : ratio === 0
      ? `${alt === "high" ? `bg-surface-container-high ${neutralText}` : `bg-transparent-inverse-surface-low bg-opacity-10 ${neutralText}`}`
      : ratio < 50
      ? "text-quality-scale-bad-on-dim-quality-container-fixed bg-quality-scale-bad-quality-container-dim-fixed"
      : ratio < 100
      ? "text-quality-scale-good-on-dim-quality-container-fixed bg-quality-scale-good-quality-container-dim-fixed"
      : "text-quality-scale-excellent-on-dim-quality-container-fixed bg-quality-scale-excellent-quality-container-dim-fixed";
  const children = (
    <div
      className={`rounded-full w-fit text-sm px-2 py-0.5 font-semibold ${leagueRatioIndicatorColor}`}
      data-tip={isNotApplicable ? "No minted players" : ""}
    >
      {isNotApplicable
        ? "NA"
        : details
        ? `${toFixedIfNecessary(parsedPoints, 2)}/${toFixedIfNecessary(parsedTotalPossiblePoints, 2)}pts`
        : `${(parsedPoints < 0 ? "" : "+") + toFixedIfNecessary(parsedPoints, 2)}pts`}
    </div>
  );
  if (details && !isNotApplicable) {
    return (
      <div
        className={"relative flex flex-col gap-1 justify-center self-center cursor-default h-full"}
        onMouseEnter={displayPopHover}
        onMouseLeave={hidePopHover}
      >
        {popHover && (
          <PopperPortal active={popHover}>
            <div
              className={"flex flex-col border border-grey-f2 isolate z-30 bg-white rounded-md shadow-lg text-sm text-brand-black"}
              style={{ minWidth: "13em" }}
            >
              {isValid ? (
                <ul className={"text-sm font-semibold p-2"}>
                  <li className={"flex flex-row justify-between items-center gap-5"}>
                    <span className={"text-textGrey3"}>
                      Starter ({formatNumber(details.stats?.["STARTER"]?.good_picked || 0)}/
                      {formatNumber(details.stats?.["STARTER"]?.max_possible_good_picked || 0)}
                      ):{" "}
                    </span>
                    <span className={"text-black"}>{formatNumber(details.stats?.["STARTER"]?.points || 0)} pts</span>
                  </li>
                  <li className={"flex flex-row justify-between items-center gap-5"}>
                    <span className={"text-textGrey3"}>
                      Bench ({formatNumber(details.stats?.["NOT_STARTER"]?.good_picked || 0)}/
                      {formatNumber(details.stats?.["NOT_STARTER"]?.max_possible_good_picked || 0)}
                      ):
                    </span>
                    <span className={"text-black"}>{formatNumber(details.stats?.["NOT_STARTER"]?.points || 0)} pts</span>
                  </li>
                  <li className={"flex flex-row justify-between items-center gap-5"}>
                    <span className={"text-textGrey3"}>
                      Out of squad ({formatNumber(details.stats?.["OUT"]?.good_picked || 0)}/
                      {formatNumber(details.stats?.["OUT"]?.max_possible_good_picked || 0)}
                      ):{" "}
                    </span>
                    <span className={"text-black"}>{formatNumber(details.stats?.["OUT"]?.points || 0)} pts</span>
                  </li>
                  <li className={"flex flex-row justify-between items-center gap-5"}>
                    <span className={"text-textGrey3"}>Bonus & malus: </span>
                    <span className={"text-black"}>{formatNumber(details.bonus || 0)} pts</span>
                  </li>
                </ul>
              ) : (
                <p className={"text-brand text-sm font-semibold p-2"}>Lineup was invalidated</p>
              )}
            </div>
          </PopperPortal>
        )}
        {children}
      </div>
    );
  }
  return children;
};

export const ProjectionPointsIndicatorForGame = (props) => {
  const { stats } = props;
  if (stats?.manager_scoring === undefined || stats?.manager_scoring === null) {
    return null;
  }
  const scoring = stats.manager_scoring;
  return (
    <ProjectionPointsIndicator
      points={scoring.points}
      totalPossiblePoints={scoring.max_possible_points}
      details={scoring.details}
      eligible={stats.nb_eligible_players}
      isValid={stats.is_valid}
    />
  );
};

function LeagueList(props) {
  const { totalPoints, totalVotedPlayers, totalEligiblePlayers, totalPossiblePoints, hasAtLeastOneInvalid, region, teamRegions } = props;
  const league = props.league || {};
  const games = props.games || [];
  const searchFilter = props.searchFilter || "";
  const headerText = props.headerText || "";
  const carretColor = props.carretColor || "fill-brand-black";

  const [show, setShow] = useState(props.show !== undefined ? props.show : false);

  const leagueIcon = league ? league.pictureUrl || league.countryPictureUrl || "" : undefined;
  const showLeague = region.show || league.show || games.some((game) => game.show);
  if (!showLeague) {
    return null;
  }
  const allowedTeams = region.teams || {};
  return (
    <div>
      <ReactTooltip />
      <div
        className={`py-2 pl-6 pr-4 cursor-pointer bg-surface-container-high flex flex-row justify-between items-center gap-1 md:grid md:grid-cols-3`}
        onClick={() => setShow(!show)}
      >
        <div className={"flex flex-row gap-2 items-center"}>
          {leagueIcon !== undefined &&
            (leagueIcon !== "" ? (
              <img className={`h-6 w-6 ${league.pictureUrl ? "object-contain " : "object-cover rounded-full"}`} src={leagueIcon} />
            ) : (
              <span className={"text-lg"}>{region?.id?.includes("america") ? "🌎" : region.id?.includes("asia") ? "🌏" : "🌍"}</span>
            ))}
          <p className={`font-semibold text-xs text-on-surface-variant text-left uppercase ${headerText}`}>{league?.leagueName}</p>
        </div>
        <div className={"hidden md:flex flex-row justify-center items-center"}>
          <div className={`relative flex flex-row gap-1 justify-center ${totalPoints !== undefined ? "w-[20em]" : "w-[10em]"}`}>
            <ProjectionRatioIndicator voted={totalVotedPlayers} eligible={totalEligiblePlayers} />
            {totalPoints !== undefined && (
              <ProjectionPointsIndicator points={totalPoints} totalPossiblePoints={totalPossiblePoints} eligible={totalEligiblePlayers} />
            )}
            {hasAtLeastOneInvalid && (
              <div className={"absolute flex flex-row transform -right-2 translate-x-full translate-y-0.5 justify-start w-[15em]"}>
                <div className={"flex flex-row gap-1 items-center"}>
                  <Invalid className={"w-4 h-4 fill-red"} />
                  <span className={"text-sm text-red font-semibold"}>Contains invalid projection</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"flex flex-row items-center justify-end gap-1"}>
          <div className={"md:hidden flex flex flex-row justify-center items-center"}>
            <div className={"relative flex flex-row justify-center items-center gap-1"}>
              {hasAtLeastOneInvalid && <Invalid data-tip={"Contains invalid projection"} className={"w-4 h-4 fill-red"} />}
              <ProjectionRatioIndicator hidePct={true} voted={totalVotedPlayers} eligible={totalEligiblePlayers} />
              <ProjectionPointsIndicator points={totalPoints} totalPossiblePoints={totalPossiblePoints} eligible={totalEligiblePlayers} />
            </div>
          </div>
          <div className={"w-3 h-3"}>
            <Carret className={`${carretColor} transform ${show ? "rotate-180" : ""} h-3 w-3`} />
          </div>
        </div>
      </div>
      {(show || (searchFilter !== "" && showLeague)) && (
        <div>
          {games
            .filter((g) => region.show || league.show || g.show)
            .map((game, i) => {
              const isHomeScoreInAnotherRegion = !allowedTeams[game.game.HomeTeamId];
              const homeAnotherRegion = isHomeScoreInAnotherRegion ? teamRegions[game.game.HomeTeamId] : "";
              const isAwayScoreInAnotherRegion = !allowedTeams[game.game.AwayTeamId];
              const awayAnotherRegion = isAwayScoreInAnotherRegion ? teamRegions[game.game.AwayTeamId] : "";
              return (
                <div className={`py-2 ${i !== 0 ? "border-t border-grey-f2" : ""}`} key={i}>
                  <a href={"/game/" + game.game.GameId} target={"_blank"} rel="noreferrer">
                    <div className={"flex flex-row sm:grid sm:grid-cols-3 sm:grid-rows-1 justify-center gap-2"}>
                      <div
                        className={
                          "hidden sm:flex sm:flex-col-reverse sm:justify-center sm:items-end lg:flex-row sm:gap-2 lg:justify-end lg:items-center"
                        }
                      >
                        <div className={"flex flex-row justify-end items-center"}>
                          <ProjectionPointsIndicatorForGame
                            stats={allowedTeams[game.game.HomeTeamId] ? game.probable_starters_stats?.[game.game.HomeTeamId] : undefined}
                          />
                        </div>
                        <div className={"w-[10em]"}>
                          <ProjectedLineupStats
                            scoredInRegion={homeAnotherRegion}
                            stats={game.probable_starters_stats?.[game.game.HomeTeamId]}
                          />
                        </div>
                      </div>
                      <div className={"flex flex-row justify-center"}>
                        <div className={"flex flex-row w-6/12 justify-end space-x-4"}>
                          <p className={"text-sm font-semibold self-center text-right"}>{game.home_team}</p>
                          <div className={"w-7 h-7 self-center"}>
                            <img
                              className={"w-full h-full object-contain"}
                              src={game.home_team_logo !== "" ? game.home_team_logo : UnknownClub}
                            />
                          </div>
                        </div>
                        <div className={"w-16 flex-row flex justify-center self-center"}>
                          <p>-</p>
                        </div>
                        <div className={"flex flex-row w-6/12 justify-start space-x-4"}>
                          <div className={"w-7 h-7 self-center"}>
                            <img
                              className={"w-full h-full object-contain"}
                              src={game.away_team_logo !== "" ? game.away_team_logo : UnknownClub}
                            />
                          </div>
                          <p className={"text-sm font-semibold self-center text-left"}>{game.away_team}</p>
                        </div>
                      </div>
                      <div className={"hidden sm:flex sm:flex-col lg:flex-row sm:gap-2 justify-start"}>
                        <div className={"w-[10em]"}>
                          <ProjectedLineupStats
                            scoredInRegion={awayAnotherRegion}
                            stats={game.probable_starters_stats?.[game.game.AwayTeamId]}
                          />
                        </div>
                        <div className={"flex flex-row justify-start items-center"}>
                          <ProjectionPointsIndicatorForGame
                            stats={allowedTeams[game.game.AwayTeamId] ? game.probable_starters_stats?.[game.game.AwayTeamId] : undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

const enhanceRegionsInfo = {
  regionIds: ["euro", "copa"],
  label: "Summer 2024",
  id: "summer-2024",
};

function GamesList(props) {
  const { searchFilter } = props;
  const regions = props.games?.regions || [];
  const regionInfos = {};
  const teamRegions = {};
  const enhancedRegions = [];
  const normalRegions = [];
  regions.forEach((region) => {
    const isEnhanced = enhanceRegionsInfo.regionIds.includes(region.id);
    regionInfos[region.id] = {
      ...region,
      show: !searchFilter || searchText(searchFilter, region.name),
    };
    if (!region.teams) return;
    Object.entries(region.teams).forEach(([team, isIn]) => {
      if (isIn) {
        teamRegions[team] = isEnhanced ? region.name : enhanceRegionsInfo.label;
      }
    });
    if (isEnhanced) {
      enhancedRegions.push(region);
    } else {
      normalRegions.push(region);
    }
  });
  const enhanceRegionInfo = {
    id: enhanceRegionsInfo.id,
    name: enhanceRegionsInfo.label,
    show: !searchFilter || searchText(searchFilter, enhanceRegionsInfo.label),
  };
  enhancedRegions.forEach((region) => {
    enhanceRegionInfo.leagues = [...(enhanceRegionInfo.leagues || []), ...(region.leagues || [])];
    enhanceRegionInfo.teams = { ...enhanceRegionInfo.teams, ...region.teams };
  });
  const leagueInfos = {};
  Object.entries(props.games?.leaguesInfo || {}).forEach(([league, info]) => {
    leagueInfos[league] = {
      ...info,
      show: !searchFilter || searchText(searchFilter, info.leagueName),
    };
  });
  const gamesInfo = {};
  Object.entries(props.games?.games || {}).forEach(([league, games]) => {
    games.forEach((g) => {
      gamesInfo[league] = [
        ...(gamesInfo[league] || []),
        {
          ...g,
          show: !searchFilter || searchText(searchFilter, g.home_team) || searchText(searchFilter, g.away_team),
        },
      ];
    });
  });
  console.log(normalRegions, enhancedRegions);
  return (
    <div className={"flex flex-col"}>
      <div className={"w-full"}>
        <div className={"flex flex-col gap-3"}>
          {enhancedRegions.length > 0 && (
            <>
              <div
                className={`bg-projections-summer-2024-banner bg-center bg-cover bg-no-repeat w-full  p-4 rounded-lg flex flex-row justify-between items-center`}
              >
                <div className={"flex flex-col gap-3"}>
                  <p className={"font-headers text-white text-md font-semibold"}>
                    Take part in our summer lineup projection competition to win prizes!
                  </p>
                  <div className={"flex items-center flex-row gap-2"}>
                    <Tag context="emphasized-opaque" size="small" label={"Euro 2024"} />
                    <Tag context="emphasized-opaque" size="small" label={"Copa America 2024"} />
                  </div>
                </div>
                <div>
                  <img className={"h-16 hidden xl:block"} src={Summer2024PrizePool} />
                </div>
              </div>
              <RegionContainer
                enhance={true}
                region={enhanceRegionInfo}
                searchFilter={searchFilter}
                gamesInfo={gamesInfo}
                leagueInfos={leagueInfos}
                teamRegions={teamRegions}
              />
            </>
          )}
          {normalRegions.map((region, i) => {
            return (
              <RegionContainer
                key={i}
                region={regionInfos[region.id]}
                searchFilter={searchFilter}
                gamesInfo={gamesInfo}
                leagueInfos={leagueInfos}
                teamRegions={teamRegions}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const RegionContainer = (props) => {
  const { region, searchFilter, gamesInfo, leagueInfos, teamRegions, enhance } = props;
  const [show, setShow] = useState(true);
  const regionIcon = region?.pictureUrl;
  if (region === undefined) {
    return null;
  }
  const leagues = (region.leagues || []).map((l) => {
    return { id: l, ...leagueInfos[l] };
  });
  const allGamesInRegion = leagues.map((l) => gamesInfo[l.id]).flat(1);
  const showRegion = region.show || leagues.some((league) => league.show) || allGamesInRegion.some((game) => game?.show);
  if (!showRegion) {
    return null;
  }
  const totalPointsInLeague = {};
  const totalPossiblePointsInLeague = {};
  const totalVotedPlayersInLeague = {};
  const totalEligiblePlayersInLeague = {};
  const hasAtLeastOneInvalidInLeague = {};
  leagues.forEach((league) => {
    const allProbableStartersInLeague =
      gamesInfo[league.id]
        ?.map((game) =>
          Object.values(game.probable_starters_stats).filter((e) => {
            if (region.id === enhanceRegionsInfo.id) {
              return enhanceRegionsInfo.regionIds.includes(e.eligible_in_region);
            } else {
              return e.eligible_in_region === region.id;
            }
          }),
        )
        .flat(1) || [];
    const totalEligiblePlayers = allProbableStartersInLeague.map((starter) => starter.nb_eligible_players).reduce((a, b) => a + b, 0);
    const totalVotedPlayers = allProbableStartersInLeague
      .map((starter) => Object.values(starter.votes))
      .flat(1)
      .reduce((a, b) => a + b, 0);
    let totalPoints, totalPossiblePoints;
    allProbableStartersInLeague.forEach((starter) => {
      if (starter.manager_scoring !== null && starter.manager_scoring !== undefined) {
        if (totalPoints === undefined) {
          totalPoints = parseFloat(starter.manager_scoring.points);
        } else {
          totalPoints += parseFloat(starter.manager_scoring.points);
        }
        if (totalPossiblePoints === undefined) {
          totalPossiblePoints = parseFloat(starter.manager_scoring.max_possible_points);
        } else {
          totalPossiblePoints += parseFloat(starter.manager_scoring.max_possible_points);
        }
      }
    });
    const hasAtLeastOneInvalid = allProbableStartersInLeague.map((starter) => !starter.is_valid).reduce((a, b) => a || b, false);
    totalPointsInLeague[league.id] = totalPoints;
    totalPossiblePointsInLeague[league.id] = totalPossiblePoints;
    totalVotedPlayersInLeague[league.id] = totalVotedPlayers;
    totalEligiblePlayersInLeague[league.id] = totalEligiblePlayers;
    hasAtLeastOneInvalidInLeague[league.id] = hasAtLeastOneInvalid;
  });
  const totalPointsValues = Object.values(totalPointsInLeague);
  const totalPoints = totalPointsValues.every((v) => v === undefined) ? undefined : totalPointsValues.reduce((a, b) => a + b, 0);
  const totalPossiblePointsValues = Object.values(totalPossiblePointsInLeague);
  const totalPossiblePoints = totalPossiblePointsValues.every((v) => v === undefined)
    ? undefined
    : totalPossiblePointsValues.reduce((a, b) => a + b, 0);
  const totalVotedPlayers = Object.values(totalVotedPlayersInLeague).reduce((a, b) => a + b, 0);
  const totalEligiblePlayers = Object.values(totalEligiblePlayersInLeague).reduce((a, b) => a + b, 0);

  return (
    <div className={"rounded-lg overflow-hidden "}>
      <ReactTooltip />
      <div
        className={`${
          enhance ? "bg-projections-summer-2024-header" : "bg-surface-container-highest"
        } py-2 px-4 cursor-pointer flex flex-row justify-between items-center gap-1 md:grid md:grid-cols-3`}
        onClick={() => setShow(!show)}
      >
        <div className={"flex flex-row gap-2 items-center"}>
          {regionIcon ? <img className={`h-6 w-6 object-contain`} src={regionIcon} /> : <span className={"text-lg"}>🌍</span>}
          <p className={`font-semibold ${enhance ? "text-white" : "text-on-surface"} text-xs text-left uppercase`}>{region?.name}</p>
        </div>
        <div className={"hidden md:flex flex-row justify-center items-center"}>
          <div className={`relative flex flex-row gap-1 justify-center ${totalPoints !== undefined ? "w-[20em]" : "w-[10em]"}`}>
            <ProjectionRatioIndicator voted={totalVotedPlayers} eligible={totalEligiblePlayers} alt={enhance ? "high" : "normal"} />
            {totalPoints !== undefined && (
              <ProjectionPointsIndicator
                points={totalPoints}
                totalPossiblePoints={totalPossiblePoints}
                eligible={totalEligiblePlayers}
                alt={enhance ? "high" : "normal"}
              />
            )}
          </div>
        </div>
        <div className={"flex flex-row items-center justify-end gap-1"}>
          <div className={"md:hidden flex flex flex-row justify-center items-center"}>
            <div className={"relative flex flex-row justify-center items-center gap-1"}>
              <ProjectionRatioIndicator
                hidePct={true}
                voted={totalVotedPlayers}
                eligible={totalEligiblePlayers}
                alt={enhance ? "high" : "normal"}
              />
              <ProjectionPointsIndicator
                points={totalPoints}
                totalPossiblePoints={totalPossiblePoints}
                eligible={totalEligiblePlayers}
                alt={enhance ? "high" : "normal"}
              />
            </div>
          </div>
          <div className={"w-3 h-3"}>
            <Carret className={`${enhance ? "fill-white" : "fill-on-surface"} transform ${show ? "rotate-180" : ""} h-3 w-3`} />
          </div>
        </div>
      </div>
      <div className={"bg-white"}>
        {(show || (searchFilter !== "" && showRegion)) &&
          leagues.map((league, i) => {
            const voteInfo = {
              totalPoints: totalPointsInLeague[league.id],
              totalVotedPlayers: totalVotedPlayersInLeague[league.id],
              totalEligiblePlayers: totalEligiblePlayersInLeague[league.id],
              totalPossiblePoints: totalPossiblePointsInLeague[league.id],
              hasAtLeastOneInvalid: hasAtLeastOneInvalidInLeague[league.id],
            };
            return (
              <LeagueList
                key={i}
                games={gamesInfo[league.id] || []}
                league={league}
                region={region}
                searchFilter={searchFilter}
                teamRegions={teamRegions}
                {...voteInfo}
              />
            );
          })}
      </div>
    </div>
  );
};

export default withUser(GamesList);
