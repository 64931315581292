import * as React from "react";
import { CSSTransition } from "react-transition-group";
import "./slidingPanel.css";

// Inspired from https://github.com/BenedicteGiraud/react-sliding-side-panel

const getPanelGlassStyle = (type, size) => {
  const horizontal = type === "bottom" || type === "top";
  return {
    width: horizontal ? `100vw` : `calc(100vw - ${Number(size) ? size + "vw" : size})`,
    height: horizontal ? `calc(100vh - ${Number(size) ? size + "vh" : size})` : `100vh`,
    ...(type === "right" && { left: 0 }),
    ...(type === "top" && { bottom: 0 }),
    position: "inherit",
  };
};

const getPanelStyle = (type, size) => {
  const horizontal = type === "bottom" || type === "top";
  return {
    width: horizontal ? "100vw" : `${Number(size) ? size + "vw" : size}`,
    height: horizontal ? `${Number(size) ? size + "vh" : size}` : "100vh",
    ...(type === "right" && { right: 0 }),
    ...(type === "bottom" && { bottom: 0 }),
    position: "inherit",
    overflow: "auto",
  };
};

const SlidingPanel = ({
  type,
  size,
  panelContainerClassName,
  panelClassName,
  isOpen,
  onOpen,
  onOpening,
  onOpened,
  onClose,
  onClosing,
  onClosed,
  backdropClicked,
  children,
}) => {
  const glassBefore = type === "right" || type === "bottom";
  const horizontal = type === "bottom" || type === "top";
  return (
    <div>
      <div className={`sliding-panel-container ${isOpen ? "active" : ""}`}>
        <CSSTransition
          in={isOpen}
          timeout={500}
          classNames={`panel-container-${type}`}
          unmountOnExit
          onEnter={onOpen}
          onEntering={onOpening}
          onEntered={onOpened}
          onExit={onClose}
          onExiting={onClosing}
          onExited={onClosed}
          style={{ display: horizontal ? "block" : "flex" }}
        >
          <div>
            {glassBefore && (
              <div
                className={"glass"}
                style={getPanelGlassStyle(type, size)}
                onClick={(e) => {
                  if (backdropClicked) backdropClicked(e);
                }}
              />
            )}
            <div className={`panel ${panelContainerClassName || ""}`} style={getPanelStyle(type, size)}>
              <div className={`panel-content ${panelClassName || ""}`}>{children}</div>
            </div>
            {!glassBefore && (
              <div
                className={"glass"}
                style={getPanelGlassStyle(type, size)}
                onClick={(e) => {
                  if (backdropClicked) backdropClicked(e);
                }}
              />
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

SlidingPanel.defaultProps = {
  type: "left",
  size: null,
  width: null,
  panelClassName: "",
  panelContainerClassName: "",
  children: null,
};

export default SlidingPanel;
