import React, { useEffect, useState } from "react";
import ProfitabilityLine from "./profitabilityLine";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { ReactTooltip } from "../util/tooltip.js";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import Locker from "../../img/brand-close-locker-nude.svg";
import { customOptionRender } from "../util/customSelectSearchRender";
import { formatPrice } from "../util/formatMoney";

function CardProfitability(props) {
  const [info, setInfo] = useState([]);
  const [displayedInfo, setDisplayedInfo] = useState([]);
  const [managers, setManagers] = useState([]);
  const [managersFilter, setManagersFilter] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [divisionsFilter, setDivisionsFilter] = useState([]);

  useEffect(() => {
    getProfitability();
  }, [props.active]);

  useEffect(() => {
    let res = [];

    info.map((i) => {
      if (managersFilter.length > 0 && !managersFilter.includes(i.nickname)) {
        return null;
      }
      if (divisionsFilter.length > 0 && !divisionsFilter.includes(i.display_name)) {
        return null;
      }
      res.push(i);
    });

    setDisplayedInfo(res);
  }, [info, managersFilter, divisionsFilter]);

  const getProfitability = () => {
    props
      .fetch("/apiv2/cards/profit/" + props.card.Slug)
      .then((response) => response.json())
      .then((res) => {
        let m = [];
        let d = [];

        res.map((item) => {
          let mOpt = {
            name: item.nickname,
            value: item.nickname,
            disabled: !t1OrAbove(props.user.tier),
            photo: !t1OrAbove(props.user.tier) ? Locker : null,
          };
          if (!m.some((i) => i.value === mOpt.value)) {
            m.push(mOpt);
          }
          let dOpt = {
            name: item.display_name,
            value: item.display_name,
            disabled: !t1OrAbove(props.user.tier),
            photo: !t1OrAbove(props.user.tier) ? Locker : null,
          };
          if (!d.some((i) => i.value === dOpt.value)) {
            d.push(dOpt);
          }
        });

        setManagers(m);
        setDivisions(d);
        setInfo(res);
      })
      .catch(errorCatcher());
  };

  const unit = props.user.preferredUnit;

  let totalCards = 0;
  let averagePoints = 0;

  let totalEarned = 0;
  let totalEarnedWeighted = 0;
  let totalValueThen = 0;
  let totalValueThenWeighted = 0;
  let totalValueNow = 0;
  let totalValueNowWeighted = 0;

  function incrementWithEth(r) {
    totalEarned += r.eth_won;
    totalEarnedWeighted += r.eth_won * r.reward_share;
    totalValueThen += r.total_value_at_time;
    totalValueThenWeighted += r.total_value_at_time * r.reward_share;
    totalValueNow += r.total_value_now;
    totalValueNowWeighted += r.total_value_now * r.reward_share;
  }

  function incrementWithFiat(r) {
    totalEarned += r.fiat_won[unit];
    totalEarnedWeighted += r.fiat_won[unit] * r.reward_share;
    totalValueThen += r.total_value_at_time_fiat[unit];
    totalValueThenWeighted += r.total_value_at_time_fiat[unit] * r.reward_share;
    totalValueNow += r.total_value_now_fiat[unit];
    totalValueNowWeighted += r.total_value_now_fiat[unit] * r.reward_share;
  }

  if (info !== null) {
    return (
      <div className="flex flex-col justify-center">
        <ReactTooltip />
        {
          <div className="my-4 w-max">
            <div className="my-2">
              <p className="font-semibold">Filters</p>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
              <div>
                <SelectSearch
                  multiple={true}
                  options={managers}
                  value={managersFilter}
                  closeOnSelect={false}
                  onChange={(m) => {
                    setManagersFilter(m);
                  }}
                  printOptions={"on-focus"}
                  filterOptions={fuzzySearch}
                  placeholder="Choose managers"
                  renderOption={customOptionRender}
                />
              </div>
              <div>
                <SelectSearch
                  multiple={true}
                  options={divisions}
                  value={divisionsFilter}
                  closeOnSelect={false}
                  onChange={(d) => {
                    setDivisionsFilter(d);
                  }}
                  printOptions={"on-focus"}
                  filterOptions={fuzzySearch}
                  placeholder="Choose divisions"
                  renderOption={customOptionRender}
                />
              </div>
            </div>
          </div>
        }
        {!t1OrAbove(props.user.tier) && (
          <div className="my-4">
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-card-rewards bg-cover h-upgrade-banner-s w-full"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock premium filters and card P&L"}
            />
          </div>
        )}
        <div className="bg-focus rounded w-full">
          <ul className="divide-y divide-gray-300">
            <li className={"p-4 hover:bg-gray-50 bg-focus grid grid-cols-8 justify-center space-x-2 rounded-t-lg"}>
              <div className={"flex flex-row space-x-2 col-span-2"}>
                <p className={"text-sm font-semibold"}>SO5 Division</p>
              </div>
              <div className={"flex flex-row space-x-2 col-span-1 justify-center"}>
                <p className={"text-sm font-semibold "}>Points %</p>
              </div>
              <div className={"flex flex-row col-span-2 space-x-1 text-sm"}>
                <p className={"text-sm font-semibold"}>Cards won by the lineup</p>
              </div>
              <p className={"text-sm font-semibold flex justify-center"}>Gaming yield</p>
              {t1OrAbove(props.user.tier) && <p className={"text-sm font-semibold flex justify-center"}>Total value then</p>}
              {t1OrAbove(props.user.tier) && <p className={"text-sm font-semibold flex justify-center"}>Total value now</p>}
            </li>
            {displayedInfo.length > 0 &&
              displayedInfo.map((r) => {
                if (r.card_won !== null) totalCards += r.card_won.length;
                averagePoints += r.reward_share * 100;

                if (props.user.preferredUnit === "eth") {
                  incrementWithEth(r);
                } else {
                  incrementWithFiat(r);
                }

                return <ProfitabilityLine reward={r} tier={props.user.tier} unit={props.user.preferredUnit} sport={props.sport} />;
              })}
            <li className={"p-4 hover:bg-gray-50 bg-focus grid grid-cols-8 justify-center space-x-2 rounded-b-lg"}>
              <div className={"flex flex-row space-x-2 col-span-2 self-center"}>
                <p className={"flex justify-center text-base md:text-lg font-semibold"}>{info.length} competitions with rewards</p>
              </div>
              <p className={"flex justify-center text-base md:text-lg font-semibold self-center"}>
                {(averagePoints / info.length).toFixed(0)} %
              </p>
              <div className={"flex flex-row col-span-2 space-x-1 text-base md:text-lg font-semibold self-center"}>
                {totalCards} cards earned
              </div>
              <div className={"space-y-0.5 self-center"}>
                <p className={"text-base md:text-lg font-semibold flex justify-center"}>{formatPrice(totalEarned, unit)}</p>
                <p className={"text-sm md:text-base flex justify-center self-center"}>{formatPrice(totalEarnedWeighted, unit)}</p>
              </div>
              {t1OrAbove(props.user.tier) && (
                <div className={"space-y-0.5 self-center"}>
                  <p className={"text-base md:text-lg font-semibold flex justify-center"}>{formatPrice(totalValueThen, unit)}</p>
                  <p className={"text-sm md:text-base flex justify-center self-center"}>{formatPrice(totalValueThenWeighted, unit)}</p>
                </div>
              )}
              {t1OrAbove(props.user.tier) && (
                <div className={"space-y-0.5 self-center"}>
                  <p className={"text-base md:text-lg font-semibold flex justify-center"}>{formatPrice(totalValueNow, unit)}</p>
                  <p className={"text-sm md:text-base flex justify-center self-center"}>{formatPrice(totalValueNowWeighted, unit)}</p>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"flex justify-center"}>
        <p className={"flex text-sm self-center"}>No rewards found for this card</p>
      </div>
    );
  }
}

export default withUser(CardProfitability);
