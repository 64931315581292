import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import FrequencyPicker from "./frequencySelect";
import DropdownCurrencyPicker from "../util/dropdownCurrencyPicker";
import CurrencyPicker from "../util/newCurrencyPicker";
import GreenButton from "../util/greenButton";
import { formatPrice } from "../util/formatMoney";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "../util/modal";
import Euro from "../../img/euro.svg";
import Gbp from "../../img/gbp.svg";
import Dollar from "../../img/dollar.svg";
import PlayCircle from "../../img/play_circle.svg";
import IconChevronRight from "../../img/icon-chevron-right.svg";
import IconChevronDown from "../../img/icon-chevron-down.svg";
import TransparentTick from "../../img/transparent-tick-pricing-grid.svg";
import TransparentBrandTick from "../../img/transparent-tick-pricing-grid-brand.svg";
import { Button } from "../util/button";
import BackgroundProductBanner from "../../img/bg-product-banner.png";
import BackgroundProductBannerMobile from "../../img/bg-product-banner-mobile.png";
import BackgroundGreenSdWatermark from "../../img/bg-green-with-sd-watermark.png";
import BackgroundGreenSdWatermarkMobile from "../../img/bg-green-with-sd-watermark-mobile.png";
import ScreenPlayerData from "../../img/screen-player-data.png";
import ScreenPlayerDataMobile from "../../img/screen-player-data-mobile.png";
import ScreenTeamBuilder from "../../img/screen-team-builder.png";
import ScreenTeamBuilderMobile from "../../img/screen-team-builder-mobile.png";
import ScreenGamingStatistics from "../../img/screen-gaming-statistics.png";
import ScreenGamingStatisticsMobile from "../../img/screen-gaming-statistics-mobile.png";
import Check from "../../img/icon-check-circle.svg";
import IconCheckGreen from "../../img/icon-check-circle-green.svg";
import IconError from "../../img/icon-error.svg";
import Lock from "../../img/icon-lock.svg";
import Info from "../../img/icon-info.svg";
import InfoPrimary from "../../img/icon-info-primary.svg";
import InfoBlue from "../../img/icon-info-blue.svg";
import IconRocket from "../../img/icon-rocket.svg";
import IconRocketGrey from "../../img/icon-rocket-grey.svg";
import IconSell from "../../img/icon-sell.svg";
import IconSellGrey from "../../img/icon-sell-grey.svg";
import Rocket from "../../img/rocket.png";
import Like from "../../img/like.png";
import Trophy from "../../img/trophy.png";
import TwitterReply from "../../img/icon-reply.svg";
import TwitterRetweet from "../../img/icon-retweet.svg";
import TwitterLike from "../../img/icon-like.svg";
import TwitterShare from "../../img/icon-share.svg";
import TwitterKebabMenu from "../../img/icon-kebab-menu.svg";
import { HashLink } from "react-router-hash-link";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import { isFree, isStripeAllowed, isTier1, isTier2, t1OrBelow } from "../util/handleSubscriptionTier";
import { ReactComponent as SortArrowIcon, ReactComponent as Carret } from "../../img/sort-arrow-icon-asc.svg";
import HelpTip from "../util/helpTip";
import { renderToString } from "react-dom/server";
import { Menu } from "@headlessui/react";
import { units, fiatUnits } from "../util/units";
import { ReactTooltip } from "../util/tooltip.js";
import StarIcon from "../../img/star.svg";
import FAQCross from "../../img/faqCross.svg";
import { getDefaultCurrency, getNavigatorLang } from "../util/locale";
import { HashScroll } from "react-hash-scroll";
import { format, parseISO } from "date-fns";
import ls from "local-storage";
import { ScrollTo } from "../util/scroll";

const xPosts = [
  {
    avatar: "https://pbs.twimg.com/profile_images/1354851349310169088/ROzyRhc7_400x400.jpg",
    name: "Dáithí 🇮🇪",
    handle: "@DaithiSorare",
    content:
      'I was so hungover on Friday morning that I genuinely considered not entering any #sorare lineups, decided to just let <a style="color: #1D9BF0;" href="https://twitter.com/SorareData" target="_blank">@SorareData</a> lineup builder pick all my lineups.<br /><br />Safe to say I owe you one guys 👏🏻',
    replies: 10,
    retweets: 8,
    likes: 75,
  },
  {
    avatar: "https://pbs.twimg.com/profile_images/1633866508735528961/bFcDVv4r_400x400.jpg",
    name: "Thomsen",
    handle: "@Thomsen_NFT",
    content: "Have you used soraredata? Best site for sorare analysis, data, projections etc.",
    replies: 1,
    likes: 3,
  },
  {
    avatar: "https://pbs.twimg.com/profile_images/1681010240119095297/bdgU-3KD_200x200.jpg",
    name: "iam_floriger",
    handle: "@iam_floriger",
    content:
      'Okay I just deleted a Random Abonnement called <a style="color: #1D9BF0;" href="https://twitter.com/netflix" target="_blank">@netflix</a> for <a style="color: #1D9BF0;" href="https://twitter.com/SorareData" target="_blank">@SorareData</a> which I think is a Good Exchange 😂😂. What would you Guys rather have? 👇 <a style="color: #1D9BF0;" href="https://twitter.com/Sorare" target="_blank">@Sorare</a> <a style="color: #1D9BF0;" href="https://twitter.com/SorareNBA" target="_blank">@SorareNBA</a>',
    replies: 4,
    retweets: 2,
    likes: 56,
  },
  {
    avatar: "https://pbs.twimg.com/profile_images/1615310345022611459/H_TklrxB_400x400.jpg",
    name: "Dario Focardi",
    handle: "@Dario_Focardi",
    content:
      'And so I finally hitted Rare TS, after a very long time. I have to say thanks to the new <a style="color: #1D9BF0;" href="https://twitter.com/SorareData" target="_blank">@SorareData</a> tool, which was very very very useful.<br /><a style="color: #1D9BF0;" href="https://twitter.com/hashtag/Sorare?src=hashtag_click" target="_blank">#Sorare</a>',
    replies: 4,
    retweets: 1,
    likes: 15,
  },
  {
    avatar: "https://pbs.twimg.com/profile_images/1695518013095817216/KTVykvjo_400x400.jpg",
    name: "Daniel",
    handle: "@drc_52",
    content:
      "SorareData is your best friend. If you want to play without spending a tonne of time, it has this thing call pick scores which tells you the player with the best chance each game week based on their scoring history, match up etc!",
    likes: 2,
  },
  // {
  //   avatar: "https://pbs.twimg.com/profile_images/1600934935602335766/TAJNVqf0_400x400.jpg",
  //   name: "RareGoldPlayer Sorare",
  //   handle: "@RareGoldPlayer",
  //   content: "Star Membership of SorareData was  definitely  worth every cent we paid!<br /><br />Not for this new feature but for everything they implemented regarding NBA<br /><br />Keep improving guys!",
  //   likes: 3,
  //   quoteTweet: {
  //     avatar: "https://pbs.twimg.com/profile_images/1701912936266989568/Wa5jp2Lm_400x400.jpg",
  //     name: "SorareData",
  //     handle: "@SorareData",
  //     content: "🏀 is now available on the SorareData mobile app! Upgrade today to track your #SorareNBA lineups, use our *live game trackers* and so much more! 💥",
  //     url: "https://twitter.com/SorareData/status/1589633780746055680",
  //   },
  // },
];

const allXPosts = [...xPosts, ...xPosts, ...xPosts, ...xPosts];

const allFeaturesList = [
  {
    title: "Basic features",
    free: true,
    tier2: true,
    tier3: true,
    tip: [
      "Basic player information and scoring history",
      "Basic player rankings",
      "Basic search filters",
      "Basic team information",
      "Player's next games",
      "Basic GW center",
      "Basic live games and results",
      "Basic card page",
      "Rewards page",
      "Basic manager statistics",
    ],
  },
  {
    title: "Full player scoring",
    longTitle: "Full player scoring and live games",
    free: false,
    tier2: true,
    tier3: true,
    tip: [
      "Player injury and suspensions",
      "Player scoring per position",
      "Player scoring per club and competition",
      "Player scoring by home/away split",
      "Player scoring distribution",
      "Player position and playtime history",
      "Player decisive scores breakdown",
      "Team past starting lineups",
      "Team past formations",
      "Team details statistics",
      "Team conceded scores (opp. score)",
      "Live lineup: points to next rewards",
    ],
  },
  {
    title: "Full market data",
    longTitle: "Full market and gallery data",
    free: false,
    tier2: true,
    tier3: true,
    tip: [
      "Premium manager statistics",
      "Premium player info: player stacks, similar players, SO5 tiers",
      "Premium price chart: your acquisitions and sales",
      "Detailed roster stats",
      "Player tier history",
      "Manager's gaming statistics",
      "Card utility and gaming yield",
      "Unlimited price alerts",
      "Unlimited player watchlists",
      "Unlimited manager watchlists",
      "Premium trade builder",
      "Scarcity preference",
      "Card layout preference",
      "Player page header display preference",
    ],
  },
  {
    title: "Lineup builder tools",
    free: false,
    tier2: true,
    tier3: true,
    tip: [
      "Player pick scores",
      "Opponent score",
      "Matchup indicator",
      "Unlimited drafts",
      "Access to all past and future game weeks",
      "Historical lineups performances",
      "Historical division rewards thresholds",
      "Rewards breakdown",
      "Advanced player form data",
    ],
  },
  {
    title: "Lineup suggestions",
    longTitle: "Lineup suggestions and recommendations",
    free: false,
    tier2: true,
    tier3: true,
    tip: ["Lineup suggestion", "Lineup completion", "Lineup strength", "Soon: recommended divisions", "Soon: recommended players"],
  },
  {
    title: "Export lineups to Sorare",
    free: false,
    tier2: true,
    tier3: true,
    tip: ["Export all your lineups to Sorare with a single action"],
  },
];

let PlansMap = {
  free: {
    trial: 0,
    "72h": {
      eur: {
        weight: 0,
      },
      usd: {
        weight: 0,
      },
      gbp: {
        weight: 0,
      },
    },
    month: {
      eur: {
        weight: 0,
      },
      usd: {
        weight: 0,
      },
      gbp: {
        weight: 0,
      },
    },
    year: {
      eur: {
        weight: 0,
      },
      usd: {
        weight: 0,
      },
      gbp: {
        weight: 0,
      },
    },
    features: [
      {
        title: "Gaming",
        subfeatures: ["Games, stats, rankings & notifications"],
        link: "gaming",
      },
      {
        title: "Scouting",
        subfeatures: [
          "Transaction history, averages & floors",
          "Player scoring history",
          "Team roster and basic stats",
          "Basic scouting tools",
        ],
        link: "scouting",
      },
      {
        title: "Management",
        subfeatures: ["Gallery overview, valuation and rewards history"],
        link: "management",
      },
      {
        title: "NBA/MLB data & tools",
        subfeatures: ["All our NBA/MLB data & tools are currently free!"],
      },
    ],
    displayName: "Rookie",
  },
  tier1: {
    // Deprecated but have to keep it while people are still on it for the upgrade
    trial: 3,
    month: {
      eur: {
        price: 4.99,
        priceId: "",
        weight: 1,
      },
      usd: {
        price: 4.99,
        priceId: "",
        weight: 1,
      },
      gbp: {
        price: 4.99,
        priceId: "",
        weight: 1,
      },
    },
    year: {
      eur: {
        price: 49.99,
        priceId: "",
        weight: 2,
      },
      usd: {
        price: 49.99,
        priceId: "",
        weight: 2,
      },
      gbp: {
        price: 49.99,
        priceId: "",
        weight: 2,
      },
    },
    features: [
      {
        title: "Gaming",
        subfeatures: ["Leaderboards details", "Gap from/to rewards", "Premium lineup building tools"],
        link: "gaming",
      },
      {
        title: "Scouting",
        subfeatures: ["Transaction details", "Unlimited price alerts", "Premium scouting tools", "Up to 5 presets and player watchlists"],
        link: "scouting",
      },
      {
        title: "Others",
        subfeatures: ["Custom display settings", "Community & social features"],
        link: "others",
      },
    ],
    displayName: "Pro",
  },
  tier2: {
    trial: 14,
    "72h": {
      eur: {
        price: 2.99,
        priceId: "",
        weight: 2,
      },
      usd: {
        price: 2.99,
        priceId: "",
        weight: 2,
      },
      gbp: {
        price: 2.99,
        priceId: "",
        weight: 2,
      },
    },
    month: {
      eur: {
        price: 9.99,
        priceId: "",
        weight: 3,
      },
      usd: {
        price: 9.99,
        priceId: "",
        weight: 3,
      },
      gbp: {
        price: 9.99,
        priceId: "",
        weight: 3,
      },
    },
    year: {
      eur: {
        price: 99.99,
        priceId: "",
        weight: 4,
      },
      usd: {
        price: 99.99,
        priceId: "",
        weight: 4,
      },
      gbp: {
        price: 99.99,
        priceId: "",
        weight: 4,
      },
    },
    features: [
      {
        title: "Gaming",
        subfeatures: ["Game winning probabilities", "Advanced lineup building tools"],
        link: "gaming",
      },
      {
        title: "Scouting",
        subfeatures: ["Unlimited presets and watchlists", "Player and team detailed stats", "Stacks and injuries"],
        link: "scouting",
      },
      {
        title: "Management",
        subfeatures: ["Card utility"],
        link: "management",
      },
    ],
    displayName: "Star",
  },
};

let currencies = [
  {
    src: Euro,
    value: "eur",
    unavailable_data_tip: "You cannot change currency on an active membership",
    available: true,
  },
  {
    src: Dollar,
    value: "usd",
    unavailable_data_tip: "You cannot change currency on an active membership",
    available: true,
  },
  {
    src: Gbp,
    value: "gbp",
    unavailable_data_tip: "You cannot change currency on an active membership",
    available: true,
  },
];

const frequencyMapping = {
  year: "Yearly",
  month: "Monthly",
  "72h": "72h access",
};

const productMapping = {
  year: "tier2_365",
  month: "tier2_30",
  "72h": "tier2_3",
};

const priceMapping = {
  year: "99.99",
  month: "9.99",
  "72h": "2.99",
};

const currencyMapping = {
  gbp: "U.K. pounds",
  eur: "euros",
  usd: "U.S. dollars",
};

function FAQQuestion(props) {
  const [isOpen, setIsOpen] = useState(false);
  const q = props.question;
  return (
    <div className={"flex flex-col space-y-4 pb-6 border-b border-faq-border px-8 lg:px-0"} onClick={() => setIsOpen(!isOpen)}>
      <div className={"flex flex-row space-x-8 items-center"}>
        <div className={"cursor-pointer"} style={{ minWidth: "20px", maxWidth: "20px" }}>
          <img src={FAQCross} className={"self-center w-full "} />
        </div>
        <div className={"self-center font-semibold text-base lg:text-lg space-y-4"}>
          <p className={"cursor-pointer"}>{q.question}</p>
        </div>
      </div>
      {isOpen && (
        <div>
          <p className={"text-base font-medium"}>{q.answer}</p>
        </div>
      )}
    </div>
  );
}

function FAQPart(props) {
  return (
    <div className={"space-y-8 lg:mx-auto lg:w-11/12"}>
      <div className="text-center lg:text-left">
        <p className={"text-base lg:text-4xl font-headers font-extrabold"}>{props.title}</p>
      </div>
      <div className={"space-y-6"}>
        {props.questions?.map((q, index) => {
          return <FAQQuestion key={index} question={q} />;
        })}
      </div>
    </div>
  );
}

function getAvailableCurrencies(userCurrency) {
  if (!userCurrency) {
    return currencies;
  }

  let availableCurrencies = [];

  currencies.forEach((currency) => {
    if (currency.value !== userCurrency) {
      availableCurrencies.push({
        ...currency,
        available: false,
      });
    } else {
      availableCurrencies.push(currency);
    }
  });

  return availableCurrencies;
}

function VideoModal(props) {
  return (
    <div>
      <Modal
        tier={props.tier}
        frequency={props.frequency}
        currency={props.currency}
        maxSize={"max-w-full"}
        size={"mx-auto"}
        open={props.open}
        close={() => props.close()}
        content={
          <div className={"flex flex-col gap-3"}>
            <h4 className={"m-0 font-semibold font-header"}>Product tour</h4>
            <div className={"flex flex-row justify-center"}>
              <div className={"flex justify-center px-8"}>
                <iframe
                  src="https://www.youtube.com/embed/LQMq36TDji4"
                  style={{ aspectRatio: "16/9" }}
                  className={"rounded-xl w-[85vw] lg:w-[70vw] xl:w-[50vw]"}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className={"flex justify-center items-center ml-auto"}>
              <Button
                onClick={() => {
                  props.close();
                }}
                label={"Got it!"}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

function UpgradeModal(props) {
  return (
    <div>
      <Modal
        tier={props.tier}
        frequency={props.frequency}
        currency={props.currency}
        maxSize={"max-w-full"}
        size={"mx-auto"}
        open={props.open}
        close={() => props.close()}
        content={
          <div className="flex flex-col justify-center items-center">
            <div>
              <p className="text-lg text-center font-semibold xs:mb-2 md:mb-4">Extend your membership</p>
              <p className="text-base">We will invoice you for the difference in price.</p>
              <p className="text-base font-semibold xs:mb-2 md:mb-4">Your new subscription will start immediately.</p>
              <p className="text-base">
                New Price: {formatPrice(PlansMap[props.tier][props.frequency][props.currency]["price"], props.currency)}
              </p>
              {props.preview?.subscription_id && (
                <>
                  <p className="text-base">
                    Discount applied for Unused time of current plan:{" "}
                    {formatPrice(props.preview.unused_time_discount / 100.0, props.currency)}
                  </p>
                  {props.preview.starting_customer_balance != props.preview.ending_customer_balance && (
                    <div>
                      <p className="text-base">
                        Discount applied from outstanding balance:{" "}
                        {formatPrice(
                          (props.preview.starting_customer_balance - props.preview.ending_customer_balance) / 100.0,
                          props.currency,
                        )}
                      </p>
                    </div>
                  )}
                  {props.preview.total_next_invoice < 0.0 && (
                    <p className="text-xs">Any unused part of discount will be applied to next invoices.</p>
                  )}
                  <p className="text-base font-semibold mt-1 xs:mb-2 md:mb-4">
                    Total:{" "}
                    {formatPrice(
                      (props.preview.total_next_invoice + props.preview.starting_customer_balance - props.preview.ending_customer_balance) /
                        100.0,
                      props.currency,
                    )}
                  </p>
                </>
              )}
            </div>
            <div className={"mt-1"}>
              <Button
                label={"Confirm"}
                onClick={() => {
                  props.confirm(PlansMap[props.tier][props.frequency][props.currency]["priceId"]);
                  props.close();
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

function CurrencyModal(props) {
  return (
    <div>
      <Modal
        tier={props.tier}
        frequency={props.frequency}
        currency={props.currency}
        maxSize={"max-w-full"}
        size={"mx-auto"}
        open={props.open}
        close={() => props.close()}
        content={
          <div className="flex flex-col justify-center items-center">
            <div>
              <p className="text-lg text-center font-semibold xs:mb-2 md:mb-4">Confirm your payment currency</p>
              <div className="w-64 m-auto">
                <CurrencyPicker
                  units={fiatUnits}
                  onChange={(currency) => {
                    props.setCurrency(currency);
                  }}
                  currency={props.currency}
                />
              </div>
              <p className="text-base font-semibold xs:mb-2 md:mb-4 mt-4">
                Payment currencies <strong>cannot be changed</strong> in the future; please confirm you would like your membership
                subscription to be charged in {currencyMapping[props.currency]}.
              </p>
            </div>
            <div className={"mt-1"}>
              <Button
                label={"Confirm"}
                onClick={() => {
                  props.confirm(PlansMap[props.tier][props.frequency][props.currency]["priceId"]);
                  props.close();
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

function YearlyGain(props) {
  const totalPriceWithoutDiscount = PlansMap[props.tier]["month"][props.currency]["price"] * 12;

  return (
    <div className="flex flex-col md:flex-row gap-1 justify-center">
      <span className="flex flex-col sm:flex-row text-xs text-secondary-fixed mx-auto sm:my-auto md:mx-0">
        <span className="text-xl font-bold line-through">{formatPrice(totalPriceWithoutDiscount, props.currency, undefined, 2)}</span>
      </span>
    </div>
  );
}

const scrollToMemberships = () => {
  const element = document.getElementById("memberships");

  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
};

const handleQueryParamsAfterLogin = () => {
  const history = useHistory();
  const lsQueryParams = ls.get("productQueryParams");
  if (lsQueryParams) {
    history.push(`/product${lsQueryParams}`);
    ls.remove("productQueryParams");
  }
};

const couponErrorMessageMap = {
  no_discount_eligible: "You don't fulfill the requirements to benefit from this code.",
  coupon_already_used: "This code has already been used.",
  global_usage_limit_reached: "This code has reached its global usage limit.",
};

function ProductPage(props) {
  // After login we restore the query params in the URL (stored before the login)
  handleQueryParamsAfterLogin();

  const query = new URLSearchParams(window.location.search);
  let downgradable = query.get("downgradable");
  const queryCode = query.get("code");
  const queryFrequency = query.get("frequency");

  const [currency, setCurrency] = useState(getDefaultCurrency());
  const [frequency, setFrequency] = useState("year");
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [newTier, setNewTier] = useState("");
  const [userPlan, setUserPlan] = useState({});
  const [previewUpdate, setPreviewUpdate] = useState({});
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [currencyModal, setCurrencyModal] = useState(false);
  const [downgradeModal, setDowngradeModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [codeModal, setCodeModal] = useState(false);
  const [message, setMessage] = useState("");
  const [code, setCode] = useState(queryCode ?? "");
  const [validationStatus, setValidationStatus] = useState(null);
  const [couponDetails, setCouponDetails] = useState(null);
  const [updateWhen, setUpdateWhen] = useState("end");
  const [canTrial, setCanTrial] = useState(false);
  const [priceLoaded, setPriceLoaded] = useState(false);
  const [stripePlans, setStripePlans] = useState([]);
  const [verified, setVerified] = useState(undefined);
  const [checkingOutSession, setCheckingOutSession] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState("");
  const [affiliateMessage, setAffiliateMessage] = useState("");
  const [couponDiscounts, setCouponDiscounts] = useState({});
  const mdQueries = useTailwindMediaQueries();
  const history = useHistory();
  const location = useLocation();
  const initialFrequency = location.state?.currentFrequency;

  // init with query params
  useEffect(() => {
    if (queryFrequency && ["72h", "month", "year"].includes(queryFrequency)) {
      setFrequency(queryFrequency);
    }
  }, [queryFrequency]);
  useEffect(() => {
    if (queryCode) {
      setCode(queryCode);
      applyCouponCode(queryCode);
    }
  }, [queryCode]);

  useEffect(() => {
    if (initialFrequency) {
      setFrequency(initialFrequency);
    }
  }, [initialFrequency]);

  const submitReferral = (code) => {
    let now = new Date();
    // add 12 hours to now
    now.setTime(now.getTime() + 12 * 60 * 60 * 1000);
    props
      .fetch("/apiv2/referrals/new", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
          expiration_date: now,
        }),
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        else return null;
      })
      .then((res) => {
        if (res !== null) {
          if (code === "") {
            setAffiliateMessage("");
          } else {
            setAffiliateMessage("All set! Thanks for supporting our community.");
          }
          setAffiliateCode(code);
        } else {
          if (code === "") {
            setAffiliateMessage("");
          } else {
            setAffiliateMessage("Oh-oh. Wrong code. Try another one!");
          }
          setAffiliateCode("");
        }
      })
      .catch(errorCatcher());
  };

  const getPreviewUpdate = (priceID) => {
    props
      .fetch("/apiv2/stripe/subscriptions/previewUpdate", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price_id: priceID,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setPreviewUpdate(res);
      })
      .catch(errorCatcher());
  };

  const getReferral = () => {
    props
      .fetch("/apiv2/referrals/last")
      .then((response) => {
        if (response.status === 200) return response.json();
        else return null;
      })
      .then((res) => {
        if (res !== null && res !== "") {
          setAffiliateCode(res.toUpperCase());
          setAffiliateMessage("All set! Thanks for supporting our community.");
        }
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [mdQueries]);

  useEffect(() => {
    if (props.affiliate) {
      submitReferral(props.affiliate);
    } else {
      getReferral();
    }

    getVerified();
    getSubscription();
    setAvailableCurrencies(getAvailableCurrencies(userPlan.current_currency));
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    for (let plan of stripePlans) {
      let period = plan.billing_period === 365 ? "year" : "month";
      if (plan.plan_name in PlansMap) {
        PlansMap[plan.plan_name][period][plan.currency]["priceId"] = plan.price_id;
      }
    }
    setPriceLoaded(true);
  }, [stripePlans]);

  useEffect(() => {
    if (newTier && updateWhen === "now") {
      setPreviewUpdate({});
      getPreviewUpdate(PlansMap[newTier][frequency][currency]["priceId"]);
    }
    ReactTooltip.rebuild();
  }, [newTier, frequency, currency]);

  const getSubscription = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/schedule", {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        setUserPlan(res);
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (userPlan.current_currency) {
      setCurrency(userPlan.current_currency);
    }
  }, [userPlan]);

  const getVerified = () => {
    props
      .fetch("/apiv2/auth/profile")
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setVerified(res.user.Verified);
        }
      })
      .catch(errorCatcher());
  };

  const applyCouponCode = (couponCode) => {
    setCouponDetails(null);
    fetch(`/apiv2/stripe/coupon?code=${couponCode}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setValidationStatus("success");
          setMessage("Code applied successfully!");
          setCouponDetails(response);
        } else {
          var message = "Invalid code. Please try another.";
          if (couponErrorMessageMap[response.error]) {
            message = couponErrorMessageMap[response.error];
          }
          setValidationStatus("error");
          setMessage(message);
        }
      })
      .catch(errorCatcher());
  };

  const handleMembershipChange = (frequency, currency, startSubscription, currencyConfirmed = false) => {
    const priceId = getPriceIdFromUserPlan(frequency, currency);

    if (priceId) {
      const isAutoRenewable = getIsAutoRenewableFromUserPlan(frequency, currency);
      const whenValue = getWhenValueFromPlanLinks(priceId);
      const hasProration = getProrationFromPlanLinks(priceId);

      if (!userPlan.current_currency && !currencyConfirmed) {
        setCurrencyModal(true);
        return;
      }

      if (hasProration && whenValue === "now") {
        setUpgradeModal(true);
      }

      if (isAutoRenewable !== null) {
        startSubscription(priceId, isAutoRenewable);
      } else {
        console.error("Could not determine the auto-renewable status.");
      }

      if (whenValue) {
        updateSubscription(priceId, whenValue);
      } else {
        console.error('Could not determine the "when" value.');
      }
    } else {
      console.error("No matching price ID found.");
    }
  };

  const getProrationFromPlanLinks = (priceId) => {
    const planLink = userPlan.plan_links.find((link) => link.to_price_id === priceId);
    return planLink ? planLink.with_proration : null;
  };

  const getWhenValueFromPlanLinks = (priceId) => {
    const planLink = userPlan.plan_links.find((link) => link.to_price_id === priceId);
    return planLink ? planLink.when : null;
  };

  const getPriceIdFromUserPlan = (frequency, currency) => {
    const productTier = productMapping[frequency];

    const planLink = userPlan?.plan_links?.find((link) => {
      const priceId = link.to_price_id;
      return userPlan.product_map[productTier]?.[currency] === priceId;
    });

    return planLink ? planLink.to_price_id : null;
  };

  const findDiscount = () => {
    const priceId = getPriceIdFromUserPlan(frequency, currency);
    const discountObj = couponDetails?.discounts.find((d) => d.price_id === priceId);

    if (!discountObj) return 0;

    if (discountObj.discount_type === "percent") {
      return (PlansMap["tier2"][frequency][currency]["price"] * discountObj.discount_value) / 100;
    } else if (discountObj.discount_type === "amount") {
      return discountObj.discount_value / 100;
    } else {
      return 0;
    }
  };

  const findDiscountInfo = (_frequency) => {
    const priceId = getPriceIdFromUserPlan(_frequency, currency);
    const discountObj = couponDetails?.discounts.find((d) => d.price_id === priceId);

    if (!discountObj) return;

    return {
      durationLabel: discountObj.discount_duration_label,
      duration: discountObj.discount_duration,
    };
  };

  const discountInfo = findDiscountInfo(frequency);
  const monthlyDiscountInfo = findDiscountInfo("month");
  const yearlyDiscountInfo = findDiscountInfo("year");

  const getStaticPriceId = (selectedCurrency, period) => {
    const periodMap = {
      "72h": "tier2_3",
      month: "tier2_30",
      year: "tier2_365",
    };

    return userPlan.product_map && userPlan.product_map[periodMap[period]]
      ? userPlan.product_map[periodMap[period]][selectedCurrency]
      : null;
  };

  const get72hPriceId = (selectedCurrency) => {
    if (userPlan.product_map && userPlan.product_map["tier2_3"]) {
      return userPlan.product_map["tier2_3"][selectedCurrency];
    }
    return null;
  };

  const getMonthlyPriceId = (selectedCurrency) => {
    if (userPlan.product_map && userPlan.product_map["tier2_30"]) {
      return userPlan.product_map["tier2_30"][selectedCurrency];
    }
    return null;
  };

  const getYearlyPriceId = (selectedCurrency) => {
    if (userPlan.product_map && userPlan.product_map["tier2_365"]) {
      return userPlan.product_map["tier2_365"][selectedCurrency];
    }
    return null;
  };

  const findStaticDiscount = (priceId, period, selectedCurrency) => {
    const discountObj = couponDetails?.discounts.find((d) => d.price_id === priceId);

    if (!discountObj) return 0;

    if (discountObj.discount_type === "percent") {
      return (PlansMap["tier2"][period][selectedCurrency]["price"] * discountObj.discount_value) / 100;
    } else if (discountObj.discount_type === "amount") {
      return discountObj.discount_value / 100;
    } else {
      return 0;
    }
  };

  const calculatePricing = (selectedCurrency, period) => {
    const priceId = getStaticPriceId(selectedCurrency, period);
    const basePrice = PlansMap["tier2"][period][selectedCurrency]["price"];
    const discount = findStaticDiscount(priceId, period, selectedCurrency);
    return formatPrice(basePrice - discount, selectedCurrency, undefined, 2);
  };

  const pricingFor72hSub = calculatePricing(currency, "72h");
  const pricingForMonthlySub = calculatePricing(currency, "month");
  const pricingForYearlySub = calculatePricing(currency, "year");

  const currentPriceId = getPriceIdFromUserPlan(frequency, currency);
  const currentPrice = PlansMap["tier2"][frequency][currency]["price"];
  const discountedPrice = currentPrice - findDiscount(currentPriceId);
  const newPrice = formatPrice(discountedPrice, currency, undefined, 2);

  const getIsAutoRenewableFromUserPlan = (frequency, currency) => {
    const priceId = getPriceIdFromUserPlan(frequency, currency);
    return priceId ? userPlan.plans_map[priceId].is_auto_renewable : null;
  };

  const startSubscription = (priceId, isAutoRenewable) => {
    props
      .fetch("/apiv2/stripe/checkoutSession", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: priceId,
          autoRenew: isAutoRenewable,
          couponCode: code,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        let url = "/subscription/cancel";

        if (res.redirectUrl !== "") {
          url = res.redirectUrl;
          window.location.href = url;
          return;
        }
        history.push(url);
      })
      .catch(errorCatcher());
  };

  const updateSubscription = (priceId, when) => {
    props
      .fetch("/apiv2/stripe/subscriptions/update", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price_id: priceId,
          when: when,
          proration_date: when === "now" ? previewUpdate.proration_date : null,
        }),
      })
      .then((response) => response.json())
      .then(() => {
        history.push("/profile?target=subscription");
      })
      .catch(errorCatcher());
  };

  const deleteSubscription = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/cancelNow", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .catch(errorCatcher());
  };

  const isVerified =
    props.user.username !== undefined &&
    props.user.username !== null &&
    props.user.username !== "" &&
    props.user.email !== "" &&
    verified !== undefined &&
    verified;

  const isCurrentStar72h = userPlan.current_plan === "tier2" && !userPlan.current_frequency;
  const isCurrentStarMonthly = userPlan.current_plan === "tier2" && userPlan.current_frequency === "month";
  const isCurrentStarYearly = userPlan.current_plan === "tier2" && userPlan.current_frequency === "year";
  const isAllowedByStripe = isStripeAllowed(userPlan.store);
  const isUnableToSubscribe = !isVerified || !isAllowedByStripe;
  const isDisabledFor72h = isUnableToSubscribe || userPlan.current_frequency === "month" || userPlan.current_frequency === "year";
  const isDisabledForMonth = isUnableToSubscribe || userPlan.current_frequency === "month" || userPlan.current_frequency === "year";
  const isDisabledForYear = isUnableToSubscribe || userPlan.current_frequency === "year";

  const formatPriceWithStyle = (price, unit, lang, precision, isPromoPrice) => {
    const formattedPrice = formatPrice(price, unit, lang, precision);
    const currencySymbol = formattedPrice.replace(/[0-9,. ]+/g, ""); // Extract currency symbol
    const currencyValue = formattedPrice.replace(/[^0-9,.]/g, ""); // Extract numerical value

    const symbolAfterLocales = ["fr-FR", "de-DE"];
    const localeLang = lang || getNavigatorLang();

    return (
      <>
        {symbolAfterLocales.includes(localeLang) ? (
          <>
            <span>{currencyValue}</span>
            {currencySymbol}
          </>
        ) : (
          <>
            <span className={`${isPromoPrice ? "" : "text-lg"}`}>{currencySymbol}</span>
            {currencyValue}
          </>
        )}
      </>
    );
  };

  const tipToText = (tips) => {
    tips = tips || [];
    if (tips.length === 0) {
      return { tipText: "", tipHtml: false };
    }
    if (tips.length === 1) {
      return { tipText: tips[0], tipHtml: false };
    }
    if (typeof tips[0] === "string") {
      // table of string
      const text = renderToString(
        <ul className={"space-y-1 list-disc"}>
          {tips.map((tipLine, i) => {
            return <li key={i}>{tipLine}</li>;
          })}
        </ul>,
      );
      return { tipText: text, tipHtml: true };
    }
    const text = renderToString(
      <div>
        {tips.map((tipLineObj, i) => {
          return (
            <div key={i}>
              {Object.entries(tipLineObj).map(([k, v], j) => {
                return (
                  <div key={j}>
                    <h4>{k}</h4>
                    <ul className={"space-y-1 list-disc"}>
                      {v.map((tipLine, i) => {
                        return <li key={i}>{tipLine}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>,
    );
    return { tipText: text, tipHtml: true };
  };

  function formattedDate(dateString) {
    const endDate = parseISO(dateString);

    if (isNaN(endDate)) {
      console.error("Invalid date string:", dateString);
      return "Invalid Date";
    }

    return format(endDate, "MMMM do yyyy");
  }

  if (userPlan.current_plan !== undefined && priceLoaded) {
    return (
      <div className={"antialiased bg-surface pb-8"}>
        {userPlan.current_frequency !== userPlan.next_frequency && userPlan.next_plan !== "" && (
          <div className="flex items-center justify-center space-x-4 mt-5 fixed bottom-5 left-4 right-4 z-40 bg-white p-4 shadow max-w-5xl rounded-xl border border-[#4FA9FD] m-auto">
            <img src={InfoBlue} alt="" width="24" height="24" />

            <p className="text-sm">
              You have requested a change in your subscription that will be applied on {formattedDate(userPlan.current_end_date)}
            </p>
          </div>
        )}
        {!isVerified && (
          <div className="flex items-center justify-center space-x-4 mt-5 fixed bottom-5 left-4 right-4 z-40 bg-white p-4 shadow max-w-5xl rounded-xl border border-primary m-auto">
            <img src={InfoPrimary} alt="" width="24" height="24" />

            <p className="text-sm">
              Please verify your email address to be able to upgrade your plan or receive notifications. Check your email or{" "}
              <a href={"/profile?target=email"} className={"text-brand-text cursor-pointer"}>
                resend a new one.
              </a>
            </p>
          </div>
        )}
        {!isStripeAllowed(userPlan.store) && (
          <div className="flex items-center justify-center space-x-4 mt-5 fixed bottom-5 left-4 right-4 z-40 bg-white p-4 shadow max-w-5xl rounded-xl border border-primary m-auto">
            <img src={InfoPrimary} alt="" width="24" height="24" />

            <p className="text-sm">Your membership was activated through our mobile app, please submit any changes there.</p>
          </div>
        )}
        {code !== "" && validationStatus === "success" && !codeModal && (
          <div className="flex items-center justify-between space-x-4 mt-5 fixed bottom-5 left-4 right-4 z-40 bg-white p-4 shadow max-w-5xl rounded-xl border border-primary m-auto">
            <div className="flex space-x-2">
              <img src={IconSellGrey} width={18} height={18} alt="" />
              <span className="text-sm text-on-surface-variant font-medium">The code &ldquo;{code}&rdquo; is currently applied.</span>
            </div>

            <button
              onClick={() => {
                setCode("");
                setMessage("");
                setCouponDetails(null);
              }}
              className="text-on-surface text-sm font-semibold border-l border-outline-variant pl-4 ml-4"
            >
              Remove code
            </button>
          </div>
        )}
        <div className="space-y-12">
          <div
            className="relative flex items-center justify-center h-96 md:h-[calc(100vh-65px)] bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${mdQueries.md ? BackgroundProductBanner : BackgroundProductBannerMobile})`,
            }}
          >
            {(userPlan.current_frequency === userPlan.next_frequency || userPlan.next_plan === "") && userPlan.current_plan !== "tier2" && (
              <button
                className="absolute top-5 right-5 bg-surface-container rounded-full flex items-center space-x-2 px-4 py-2.5"
                onClick={() => setCodeModal(true)}
              >
                <img src={IconSell} alt="" width="18" height="18" />
                <span className="font-semibold text-sm">Use a code</span>
              </button>
            )}

            {mdQueries.lg ? (
              <svg
                className="absolute bottom-0 left-0 right-0 w-full rotate-180"
                height="80"
                viewBox="0 0 500 80"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="shape">
                    <path d="M0, 0 L0,40 Q250, 120 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape)" />
              </svg>
            ) : (
              <svg
                className="absolute bottom-0 left-0 right-0 w-full rotate-180"
                height="80"
                viewBox="0 0 500 80"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="shape">
                    <path d="M0, 0 L0,40 Q250, 72 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape)" />
              </svg>
            )}

            <div className="w-9/12 md:w-6/12 flex flex-col items-center space-y-4 md:space-y-10">
              <p className="font-headers text-2xl md:text-6xl font-bold text-center !leading-tight">Find the membership that suits you</p>

              <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4">
                <Button context="secondary" onClick={() => setVideoModal(true)}>
                  <img className={"w-4 h-4 object-contain"} src={PlayCircle} alt="" />
                  Watch product tour
                </Button>
                <Button onClick={() => scrollToMemberships()}>See memberships</Button>
              </div>
            </div>

            {videoModal && (
              <VideoModal
                open={videoModal}
                close={() => {
                  setVideoModal(false);
                }}
              />
            )}
          </div>

          <div className="relative pb-20 px-4 lg:px-0">
            <div className="container mx-auto lg:w-8/12 3xl:w-6/12 3.5xl:w-7/12 4xl:w-6/12 max-w-3xl">
              <style>
                {`
                .slick-slider--features .slick-dots {
                  transform: translateY(15px);
                }

                .slick-dots li {
                  margin-left: 4px;
                  margin-right: 4px;
                  width: 32px !important;
                  height: 8px !important;
                }

                .slick-dots .slick-active button:before {
                  background-color: #F55205 !important;
                }

                .slick-dots button:before {
                  background-color: #E6E6E6 !important;
                  font-size: 0 !important;
                  width: 32px !important;
                  height: 8px !important;
                  border-radius: 999px;
                  opacity: 1 !important;
                }
              `}
              </style>

              <Slider
                className="slick-slider--features"
                dots={true}
                arrows={false}
                autoplaySpeed={5000}
                autoplay={true}
                fade={true}
                cssEase="linear"
              >
                <div className="relative bg-white border-4 border-inverse-primary rounded-2xl h-[310px] !flex flex-col justify-between p-8">
                  <div className="space-y-2 md:w-5/12">
                    <h2 className="text-2xl text-on-surface m-0">Player data</h2>
                    <p className="text-on-surface-variant text-base max-w-sm">
                      Sales, scores, news.
                      <br />
                      Every info about your players and those you plan to buy
                    </p>

                    {mdQueries.md ? (
                      <img className="absolute right-0 top-0 bottom-0 m-auto" src={ScreenPlayerData} alt="" width="348" height="278" />
                    ) : (
                      <img className="absolute right-0 bottom-2" src={ScreenPlayerDataMobile} alt="" width="170" height="140" />
                    )}
                  </div>

                  {/* <button className="flex items-center space-x-1 text-primary text-sm font-semibold" onClick={() => setVideoModal(true)}>
                    <span>Learn More</span>
                    <img src={IconChevronRight} alt="" />
                  </button> */}
                </div>

                <div className="relative bg-white border-4 border-inverse-primary rounded-2xl h-[310px] !flex flex-col justify-between p-8">
                  <div className="space-y-2">
                    <h2 className="text-2xl text-on-surface m-0">Lineup builder</h2>
                    <p className="text-on-surface-variant text-base max-w-sm">
                      Experience Sorare with the best tools available, from prize pool analysis to lineup suggestions
                    </p>

                    {mdQueries.md ? (
                      <img className="absolute right-0 top-0 bottom-0 m-auto" src={ScreenTeamBuilder} alt="" width="348" height="278" />
                    ) : (
                      <img className="absolute right-2 bottom-2" src={ScreenTeamBuilderMobile} alt="" width="141" height="133" />
                    )}
                  </div>

                  {/* <button className="flex items-center space-x-1 text-primary text-sm font-semibold" onClick={() => setVideoModal(true)}>
                    <span>Learn More</span>
                    <img src={IconChevronRight} alt="" />
                  </button> */}
                </div>

                <div className="relative bg-white border-4 border-inverse-primary rounded-2xl h-[310px] !flex flex-col justify-between p-8">
                  <div className="space-y-2">
                    <h2 className="text-2xl text-on-surface m-0">Gaming statistics</h2>
                    <p className="text-on-surface-variant text-base max-w-sm">Follow your progress…</p>

                    {mdQueries.md ? (
                      <img
                        className="absolute right-0 top-0 bottom-0 m-auto"
                        src={ScreenGamingStatistics}
                        alt=""
                        width="348"
                        height="278"
                      />
                    ) : (
                      <img className="absolute right-0 bottom-2" src={ScreenGamingStatisticsMobile} alt="" width="170" height="140" />
                    )}
                  </div>

                  {/* <button className="flex items-center space-x-1 text-primary text-sm font-semibold" onClick={() => setVideoModal(true)}>
                    <span>Learn More</span>
                    <img src={IconChevronRight} alt="" />
                  </button> */}
                </div>
              </Slider>
            </div>

            {videoModal && (
              <VideoModal
                open={videoModal}
                close={() => {
                  setVideoModal(false);
                }}
              />
            )}

            {mdQueries.lg ? (
              <svg className="absolute top-[105%] left-0 right-0 w-full z-30" height="80" viewBox="0 0 500 80" preserveAspectRatio="none">
                <defs>
                  <clipPath id="shape-flipped">
                    <path d="M0, 0 L0,40 Q250, 100 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape-flipped)" />
              </svg>
            ) : (
              <svg className="absolute top-[105%] left-0 right-0 w-full z-30" height="80" viewBox="0 0 500 80" preserveAspectRatio="none">
                <defs>
                  <clipPath id="shape-flipped">
                    <path d="M0, 0 L0,40 Q250, 60 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape-flipped)" />
              </svg>
            )}
          </div>

          <div
            id="pricing"
            className="relative flex items-center justify-center h-screen bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${mdQueries.lg ? BackgroundGreenSdWatermark : BackgroundGreenSdWatermarkMobile})`,
            }}
          >
            {mdQueries.lg ? (
              <svg
                className="absolute bottom-0 left-0 right-0 w-full rotate-180"
                height="80"
                viewBox="0 0 500 80"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="shape">
                    <path d="M0, 0 L0,40 Q250, 120 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape)" />
              </svg>
            ) : (
              <svg
                className="absolute bottom-0 left-0 right-0 w-full rotate-180"
                height="80"
                viewBox="0 0 500 80"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="shape">
                    <path d="M0, 0 L0,40 Q250, 72 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape)" />
              </svg>
            )}

            <div className="w-full space-y-4 lg:space-y-8">
              <div className="w-11/12 lg:w-7/12 m-auto">
                <h2 className="m-0 text-black font-headers text-2xl lg:text-[40px] font-bold text-center !leading-tight">
                  Take your Football Sorare experience to the next level
                </h2>

                <h3 className="text-black font-headers text-sm lg:text-[28px] font-medium text-center !leading-tight">
                  Star membership features, price based on duration
                </h3>
              </div>

              <style>
                {`
                .slick-slider--testimonials .slick-slide > div,
                .slick-slider--membership .slick-slide > div {
                  margin: 10px;
                }

                .slick-slider--membership .slick-dots :not(.slick-active) button:before {
                  background-color: #FFFFFF !important;
                }
              `}
              </style>

              {mdQueries.lg ? (
                <div className="container mx-auto">
                  <div className="mx-auto xl:w-8/12 3xl:w-6/12">
                    <div className="grid grid-cols-3 gap-16">
                      <div
                        className="flex flex-col justify-between p-6 rounded-2xl h-72 w-[280px]"
                        style={{
                          border: "2.5px solid rgba(255, 255, 255, 0.30)",
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), radial-gradient(107.04% 141.42% at 0% 0%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)",
                          backdropFilter: "blur(21px)",
                        }}
                      >
                        <div>
                          <h4 className="text-secondary-fixed text-xl font-semibold">Star · 72h Access</h4>
                          <p className="text-secondary-fixed text-sm font-medium">
                            Discover all of SorareData for 72 hours with no further commitment.
                          </p>
                        </div>

                        <div className="space-y-6">
                          <div className="text-black text-[40px] font-bold leading-tight">
                            {!userPlan.trial_used && userPlan.next_price === 0 ? (
                              <div className="space-x-4">
                                <span>{formatPriceWithStyle(userPlan.next_price, currency, undefined, 2)}</span>

                                <span className="text-xl text-secondary-fixed line-through">
                                  {formatPriceWithStyle(PlansMap["tier2"]["72h"][currency]["price"], currency, undefined, 2, true)}
                                </span>
                              </div>
                            ) : (
                              <div className="space-x-4">
                                <span>{pricingFor72hSub}</span>

                                {findStaticDiscount(get72hPriceId(currency), "72h", currency) > 0 && (
                                  <span className="text-xl text-secondary-fixed line-through">
                                    {formatPrice(PlansMap["tier2"]["72h"][currency]["price"], currency, undefined, 2)}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                          {userPlan.current_plan === "tier2" && userPlan.current_frequency === "72h" ? (
                            <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                              Current membership
                            </span>
                          ) : (
                            <Button
                              onClick={() => {
                                handleMembershipChange("72h", currency, startSubscription);
                                setFrequency("72h");
                              }}
                              style={{ width: "100%" }}
                              context="secondary"
                              disabled={
                                isUnableToSubscribe || userPlan.current_frequency === "month" || userPlan.current_frequency === "year"
                              }
                            >
                              Purchase for 72h
                            </Button>
                          )}
                        </div>
                      </div>

                      <div
                        className="flex flex-col justify-between p-6 rounded-2xl h-72 w-[280px]"
                        style={{
                          border: "2.5px solid rgba(255, 255, 255, 0.30)",
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), radial-gradient(107.04% 141.42% at 0% 0%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)",
                          backdropFilter: "blur(21px)",
                        }}
                      >
                        <div>
                          <h4 className="text-secondary-fixed text-xl font-semibold">Star · Monthly</h4>
                          <p className="text-secondary-fixed text-sm font-medium">
                            Enjoy the best of SorareData for a month. Maximum flexibility!
                          </p>
                        </div>

                        <div className="space-y-6">
                          <div className="text-black text-[40px] font-bold leading-tight">
                            <div className="space-x-4">
                              <span>{pricingForMonthlySub}</span>

                              {findStaticDiscount(getMonthlyPriceId(currency), "month", currency) > 0 && (
                                <span className="text-xl text-secondary-fixed line-through">
                                  {formatPrice(PlansMap["tier2"]["month"][currency]["price"], currency, undefined, 2)}
                                </span>
                              )}
                            </div>
                          </div>

                          {discountInfo?.duration === "once" && (
                            <span className="text-xs font-medium text-on-surface">{`Then ${formatPrice(
                              PlansMap["tier2"]["month"][currency]["price"],
                              currency,
                              undefined,
                              2,
                            )} /${monthlyDiscountInfo?.durationLabel}`}</span>
                          )}

                          {userPlan.current_plan === "tier2" && userPlan.current_frequency === "month" ? (
                            <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                              Current membership
                            </span>
                          ) : userPlan.current_frequency === "year" && userPlan.current_plan === "tier2" ? (
                            <Button
                              onClick={() => handleMembershipChange("month", currency, startSubscription)}
                              disabled={!userPlan.plan_links}
                              style={{ width: "100%" }}
                              context="secondary"
                            >
                              Change membership
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                handleMembershipChange("month", currency, startSubscription);
                                setFrequency("month");
                              }}
                              style={{ width: "100%" }}
                              context="secondary"
                              disabled={isDisabledForMonth}
                            >
                              {isCurrentStar72h || isCurrentStarMonthly ? "Extend membership" : "Upgrade membership"}
                            </Button>
                          )}
                        </div>
                      </div>

                      <div
                        className="relative flex flex-col justify-between p-6 rounded-2xl bg-white h-72 w-[280px]"
                        style={{
                          boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
                          border: "3.5px solid #F55205",
                          backdropFilter: "blur(21px)",
                        }}
                      >
                        <span className="flex text-xs text-white font-semibold rounded-full bg-primary w-[72px] h-5 items-center justify-center uppercase absolute -top-2.5 left-5">
                          Popular
                        </span>

                        <div>
                          <h4 className="text-black text-xl font-semibold">Star · Yearly</h4>
                          <p className="text-black text-sm font-medium">The best features at the best price!</p>
                        </div>

                        <div className="space-y-6">
                          <div className="flex items-center text-black text-[40px] font-bold leading-tight space-x-3">
                            {!findStaticDiscount(getYearlyPriceId(currency), "year", currency) > 0 ? (
                              <>
                                <span>{pricingForYearlySub}</span>
                                <span className="flex text-xs text-white font-semibold rounded-full bg-primary w-12 h-5 items-center justify-center mt-2">
                                  -16%
                                </span>
                              </>
                            ) : (
                              <div className="flex space-x-4">
                                <span>{pricingForYearlySub}</span>

                                <div className="flex flex-col items-center space-y-1">
                                  <span className="text-sm line-through">
                                    {formatPrice(PlansMap["tier2"]["year"][currency]["price"], currency, undefined, 2)}
                                  </span>

                                  <span className="flex text-xs text-white font-semibold rounded-full bg-primary w-12 h-5 items-center justify-center mt-2">
                                    -16%
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>

                          {discountInfo?.duration === "once" && (
                            <span className="text-xs font-medium text-on-surface">{`Then ${formatPrice(
                              PlansMap["tier2"]["year"][currency]["price"],
                              currency,
                              undefined,
                              2,
                            )} /${yearlyDiscountInfo?.durationLabel}`}</span>
                          )}

                          {userPlan.current_plan === "tier2" && userPlan.current_frequency === "year" ? (
                            <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                              Current membership
                            </span>
                          ) : (
                            <Button
                              onClick={() => {
                                handleMembershipChange("year", currency, startSubscription);
                                setFrequency("year");
                              }}
                              disabled={isDisabledForYear}
                              style={{ width: "100%" }}
                            >
                              <img src={isDisabledForYear ? IconRocketGrey : IconRocket} alt="" width="18" height="18" />
                              <span>{isCurrentStar72h || isCurrentStarMonthly ? "Extend membership" : "Upgrade membership"}</span>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Slider
                  className="slick-slider--membership"
                  dots={true}
                  arrows={false}
                  initialSlide={2}
                  centerMode={true}
                  slidesToShow={1}
                  slidesToScroll={1}
                >
                  <div>
                    <div
                      className="flex flex-col justify-between p-6 rounded-2xl h-72"
                      style={{
                        border: "2.5px solid rgba(255, 255, 255, 0.30)",
                        background:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), radial-gradient(107.04% 141.42% at 0% 0%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)",
                        backdropFilter: "blur(21px)",
                      }}
                    >
                      <div>
                        <h4 className="text-secondary-fixed text-xl font-semibold">Star · 72h Access</h4>
                        <p className="text-secondary-fixed text-sm font-medium">
                          Discover all of SorareData for 72 hours with no further commitment.
                        </p>
                      </div>

                      <div className="space-y-6">
                        <div className="text-black text-[40px] font-bold leading-tight">
                          {!userPlan.trial_used && userPlan.next_price === 0 ? (
                            <div className="space-x-4">
                              <span>{formatPriceWithStyle(userPlan.next_price, currency, undefined, 2)}</span>

                              <span className="text-xl text-secondary-fixed line-through">
                                {formatPriceWithStyle(PlansMap["tier2"]["72h"][currency]["price"], currency, undefined, 2, true)}
                              </span>
                            </div>
                          ) : (
                            <div className="space-x-4">
                              <span>{pricingFor72hSub}</span>

                              {findStaticDiscount(get72hPriceId(currency), "72h", currency) > 0 && (
                                <span className="text-xl text-secondary-fixed line-through">
                                  {formatPrice(PlansMap["tier2"]["72h"][currency]["price"], currency, undefined, 2)}
                                </span>
                              )}
                            </div>
                          )}
                        </div>

                        {userPlan.current_plan === "tier2" && userPlan.current_frequency === "" ? (
                          <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                            Current membership
                          </span>
                        ) : (
                          <Button
                            onClick={() => handleMembershipChange("72h", currency, startSubscription)}
                            style={{ width: "100%" }}
                            context="secondary"
                            disabled={
                              isUnableToSubscribe || userPlan.current_frequency === "month" || userPlan.current_frequency === "year"
                            }
                          >
                            Purchase for 72h
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className="flex flex-col justify-between p-6 rounded-2xl h-72"
                      style={{
                        border: "2.5px solid rgba(255, 255, 255, 0.30)",
                        background:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), radial-gradient(107.04% 141.42% at 0% 0%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)",
                        backdropFilter: "blur(21px)",
                      }}
                    >
                      <div>
                        <h4 className="text-secondary-fixed text-xl font-semibold">Star · Monthly</h4>
                        <p className="text-secondary-fixed text-sm font-medium">
                          Enjoy the best of SorareData for a month. Maximum flexibility!
                        </p>
                      </div>

                      <div className="space-y-6">
                        <div className="text-black text-[40px] font-bold leading-tight">
                          <div className="space-x-4">
                            <span>{pricingForMonthlySub}</span>

                            {findStaticDiscount(getMonthlyPriceId(currency), "month", currency) > 0 && (
                              <span className="text-xl text-secondary-fixed line-through">
                                {formatPrice(PlansMap["tier2"]["month"][currency]["price"], currency, undefined, 2)}
                              </span>
                            )}
                          </div>
                        </div>

                        {discountInfo?.duration === "once" && (
                          <span className="text-xs font-medium text-on-surface">{`Then ${formatPrice(
                            PlansMap["tier2"]["month"][currency]["price"],
                            currency,
                            undefined,
                            2,
                          )} /${monthlyDiscountInfo?.durationLabel}`}</span>
                        )}

                        {userPlan.current_plan === "tier2" && userPlan.current_frequency === "month" ? (
                          <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                            Current membership
                          </span>
                        ) : userPlan.current_frequency === "year" && userPlan.current_plan === "tier2" ? (
                          <Button
                            onClick={() => handleMembershipChange("month", currency, startSubscription)}
                            style={{ width: "100%" }}
                            context="secondary"
                          >
                            Change membership
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleMembershipChange("month", currency, startSubscription)}
                            style={{ width: "100%" }}
                            context="secondary"
                            disabled={isDisabledForMonth}
                          >
                            {isCurrentStar72h || isCurrentStarMonthly ? "Extend membership" : "Upgrade membership"}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className="relative flex flex-col justify-between p-6 rounded-2xl bg-white h-72"
                      style={{
                        border: "3.5px solid #F55205",
                        backdropFilter: "blur(21px)",
                      }}
                    >
                      <span className="flex text-xs text-white font-semibold rounded-full bg-primary w-[72px] h-5 items-center justify-center uppercase absolute -top-2.5 left-5">
                        Popular
                      </span>

                      <div>
                        <h4 className="text-black text-xl font-semibold">Star · Yearly</h4>
                        <p className="text-black text-sm font-medium">The best features at the best price!</p>
                      </div>

                      <div className="space-y-6">
                        <div className="flex items-center text-black text-[40px] font-bold leading-tight space-x-3">
                          {!findStaticDiscount(getYearlyPriceId(currency), "year", currency) > 0 ? (
                            <>
                              <span>{pricingForYearlySub}</span>
                              <span className="flex text-xs text-white font-semibold rounded-full bg-primary w-12 h-5 items-center justify-center mt-2">
                                -16%
                              </span>
                            </>
                          ) : (
                            <div className="flex space-x-4">
                              <span>{pricingForYearlySub}</span>

                              <div className="flex flex-col items-center space-y-1">
                                <span className="text-sm line-through">
                                  {formatPrice(PlansMap["tier2"]["year"][currency]["price"], currency, undefined, 2)}
                                </span>

                                <span className="flex text-xs text-white font-semibold rounded-full bg-primary w-12 h-5 items-center justify-center mt-2">
                                  -16%
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {discountInfo?.duration === "once" && (
                          <span className="text-xs font-medium text-on-surface">{`Then ${formatPrice(
                            PlansMap["tier2"]["year"][currency]["price"],
                            currency,
                            undefined,
                            2,
                          )} /${yearlyDiscountInfo?.durationLabel}`}</span>
                        )}

                        {userPlan.current_plan === "tier2" && userPlan.current_frequency === "year" ? (
                          <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                            Current membership
                          </span>
                        ) : (
                          <Button
                            onClick={() => handleMembershipChange("year", currency, startSubscription)}
                            disabled={isDisabledForYear}
                            style={{ width: "100%" }}
                          >
                            <img
                              className="!hidden lg:!block"
                              src={isDisabledForYear ? IconRocketGrey : IconRocket}
                              alt=""
                              width="18"
                              height="18"
                            />
                            <span>{isCurrentStar72h || isCurrentStarMonthly ? "Extend membership" : "Upgrade membership"}</span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Slider>
              )}

              {mdQueries.lg && (
                <div className="flex items-center justify-center">
                  <button className="flex items-center text-base font-medium text-center space-x-2" onClick={() => scrollToMemberships()}>
                    <span>View membership details</span>
                    <img src={IconChevronDown} alt="" width="24" height="24" />
                  </button>
                </div>
              )}
            </div>
          </div>

          {queryCode && queryFrequency && <ScrollTo id="memberships" />}
          <div id="memberships" className="relative flex">
            <div className="flex flex-col w-11/12 lg:w-10/12 mx-auto space-y-8 pt-5 pb-20">
              <div className="flex flex-col justify-center space-y-6">
                <h2 className="m-0 text-black font-headers text-2xl lg:text-[40px] font-bold text-center !leading-tight">
                  Football Star membership details
                </h2>

                <div className="self-center m-2 space-y-4 lg:space-y-0 lg:space-x-4 flex flex-col lg:flex-row items-center">
                  <FrequencyPicker
                    frequency={frequency}
                    handleFrequency={(value) => {
                      setFrequency(value);
                    }}
                  />

                  {!userPlan.current_currency && (
                    <DropdownCurrencyPicker
                      fontUnselected={"text-on-surface-variant"}
                      currency={currency}
                      onChange={(currency) => {
                        setCurrency(currency);
                      }}
                      units={fiatUnits}
                      variant="small"
                    />
                  )}
                </div>
              </div>

              <div className="container mx-auto lg:w-9/12">
                {mdQueries.md ? (
                  <table className="min-w-full">
                    <thead className="flex justify-end border-b border-[#1414141A] pb-4 mb-4">
                      <tr className="flex space-x-12">
                        <th align="right">
                          <div className="bg-white flex justify-center items-center rounded-xl h-[134px] w-[200px] lg:w-[225px] p-4">
                            <div className="flex flex-col items-center justify-center">
                              <p className="text-base text-center font-bold text-on-surface-variant">Rookie</p>

                              <div className="text-black text-[40px] font-bold leading-tight">{formatPrice(0, currency, undefined, 2)}</div>

                              <span className="text-xl font-bold">Free</span>
                            </div>
                          </div>
                        </th>
                        <th align="right">
                          <div
                            className="flex justify-center items-center rounded-xl h-[134px] w-[200px] lg:w-[225px] p-4"
                            style={{
                              background: "linear-gradient(248deg, #03A98E 13.02%, #8DDAD5 91.34%)",
                            }}
                          >
                            <div className="flex flex-col items-center justify-center">
                              <p className="text-base text-center font-bold text-black">Star · {frequencyMapping[frequency]}</p>

                              <div className="text-black text-[40px] font-bold leading-tight">
                                {!userPlan.trial_used && userPlan.next_price === 0 && frequency === "72h" ? (
                                  <div className="flex flex-col items-center">
                                    <span>{formatPriceWithStyle(userPlan.next_price, currency, undefined, 2, true)}</span>

                                    <span className="text-xl text-secondary-fixed line-through">
                                      {formatPriceWithStyle(PlansMap["tier2"]["72h"][currency]["price"], currency, undefined, 2, true)}
                                    </span>
                                  </div>
                                ) : (
                                  newPrice
                                )}
                              </div>

                              {findDiscount() > 0 && !(discountInfo?.duration === "once") && (
                                <div className="flex flex-col justify-center md:flex-row gap-1 justify-center">
                                  <span className="flex flex-col sm:flex-row text-xs text-secondary-fixed mx-auto sm:my-auto md:mx-0">
                                    <span className="text-xl font-bold line-through">
                                      {formatPrice(currentPrice, currency, undefined, 2)}
                                    </span>
                                  </span>
                                </div>
                              )}
                              {frequency === "year" && !(findDiscount() > 0) && <YearlyGain tier={"tier2"} currency={currency} />}
                              {discountInfo?.duration === "once" && (
                                <span className="text-sm font-bold text-secondary-fixed">{`Then ${formatPrice(
                                  PlansMap["tier2"][frequency][currency]["price"],
                                  currency,
                                  undefined,
                                  2,
                                )} /${discountInfo?.durationLabel}`}</span>
                              )}
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="flex flex-col space-y-4">
                      {allFeaturesList.map((feature, index) => {
                        const { tipText, tipHtml } = tipToText(feature.tip);

                        return (
                          <tr key={index} className="flex border-b border-[#1414141A] pb-4 last:border-none">
                            <td className="flex flex-grow items-center text-sm font-semibold space-x-1.5">
                              <span>{mdQueries.lg ? feature.longTitle || feature.title : feature.title}</span>

                              {tipText && (
                                <span className="flex flex-row self-center h-3 w-3 my-auto">
                                  <HelpTip tip={tipText} html={tipHtml} isDark />
                                </span>
                              )}
                            </td>
                            <td className="w-[200px] lg:w-[225px]" align="center">
                              {feature.free ? (
                                <img className={"h-6 w-6"} src={Check} alt="" />
                              ) : (
                                <img className={"h-6 w-6"} src={Lock} alt="" />
                              )}
                            </td>
                            <td className="w-[200px] lg:w-[225px] ml-12" align="center">
                              {feature.tier2 ? (
                                <img className={"h-6 w-6"} src={Check} alt="" />
                              ) : (
                                <img className={"h-6 w-6"} src={Lock} alt="" />
                              )}
                            </td>
                          </tr>
                        );
                      })}

                      <tr className="flex">
                        <td className="flex flex-grow items-center text-sm font-semibold space-x-1.5"></td>
                        {isVerified &&
                          isStripeAllowed(userPlan.store) &&
                          userPlan.current_plan === "free" &&
                          !(isCurrentStar72h || isCurrentStarMonthly || isCurrentStarYearly) && (
                            <td className="flex items-center justify-center w-[200px] lg:w-[225px]" align="center">
                              <span className="text-sm text-on-surface-variant font-medium">Current membership</span>
                            </td>
                          )}
                        <td className="w-[200px] lg:w-[225px] ml-12" align="center">
                          {userPlan.current_plan === "tier2" && userPlan.current_frequency === "year" && frequency === "month" ? (
                            <Button
                              onClick={() => handleMembershipChange("month", currency, startSubscription)}
                              disabled={!userPlan.plan_links}
                              style={{ width: "100%" }}
                              context="secondary"
                            >
                              Change membership
                            </Button>
                          ) : userPlan.current_frequency === frequency && userPlan.current_plan === "tier2" ? (
                            <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                              Current membership
                            </span>
                          ) : (
                            <Button
                              className="flex space-x-2"
                              onClick={() => {
                                if ((isCurrentStarMonthly || isCurrentStar72h) && frequency === "year") {
                                  setUpgradeModal(true);
                                } else {
                                  handleMembershipChange(frequency, currency, startSubscription);
                                }
                              }}
                              disabled={
                                frequency === "72h"
                                  ? isDisabledFor72h
                                  : frequency === "month"
                                  ? isDisabledForMonth
                                  : frequency === "year"
                                  ? isDisabledForYear
                                  : isUnableToSubscribe
                              }
                              style={{ width: "100%" }}
                            >
                              <img
                                src={
                                  (
                                    frequency === "72h"
                                      ? isDisabledFor72h
                                      : frequency === "month"
                                      ? isDisabledForMonth
                                      : frequency === "year"
                                      ? isDisabledForYear
                                      : isUnableToSubscribe
                                  )
                                    ? IconRocketGrey
                                    : IconRocket
                                }
                                alt=""
                                width="18"
                                height="18"
                              />

                              <span>
                                {isCurrentStarMonthly || isCurrentStar72h
                                  ? "Extend membership"
                                  : frequency === "72h"
                                  ? "Purchase for 72h"
                                  : "Upgrade membership"}
                              </span>
                            </Button>
                          )}
                        </td>
                      </tr>

                      <tr className="block">
                        <td
                          className="flex items-center justify-center border border-outline-variant rounded-xl p-4 space-x-2.5"
                          align="center"
                        >
                          <img src={Info} alt="" width="24" height="24" />
                          <span className="text-base text-on-surface-variant font-medium">
                            Basketball and baseball features are free to use, including with a Rookie membership.
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <div className="space-y-6">
                    <div className="flex space-x-2">
                      <div className="bg-white flex justify-center items-center rounded-xl w-[225px] p-4">
                        <div className="flex flex-col items-center justify-center">
                          <p className="text-base text-center font-bold text-on-surface-variant">Rookie</p>

                          <div className="text-black text-[40px] font-bold leading-tight">{formatPrice(0, currency, undefined, 2)}</div>

                          <span className="text-xl font-bold">Free</span>
                        </div>
                      </div>
                      <div
                        className="flex justify-center items-center rounded-xl w-[225px] p-4"
                        style={{
                          background: "linear-gradient(248deg, #03A98E 13.02%, #8DDAD5 91.34%)",
                        }}
                      >
                        <div className="flex flex-col items-center justify-center">
                          <p className="text-base text-center font-bold text-black">Star · {frequencyMapping[frequency]}</p>

                          <div className="text-black text-[40px] font-bold leading-tight">
                            {!userPlan.trial_used && userPlan.next_price === 0 && frequency === "72h" ? (
                              <div className="flex flex-col items-center">
                                <span>{formatPriceWithStyle(userPlan.next_price, currency, undefined, 2, true)}</span>

                                <span className="text-xl text-secondary-fixed line-through">
                                  {formatPriceWithStyle(PlansMap["tier2"]["72h"][currency]["price"], currency, undefined, 2, true)}
                                </span>
                              </div>
                            ) : (
                              newPrice
                            )}
                          </div>

                          {findDiscount() > 0 && !(discountInfo?.duration === "once") && (
                            <div className="flex flex-col justify-center md:flex-row gap-1 justify-center">
                              <span className="flex flex-col sm:flex-row text-xs text-secondary-fixed mx-auto sm:my-auto md:mx-0">
                                <span className="text-xl font-bold line-through">{formatPrice(currentPrice, currency, undefined, 2)}</span>
                              </span>
                            </div>
                          )}
                          {frequency === "year" && !(findDiscount() > 0) && <YearlyGain tier={"tier2"} currency={currency} />}
                          {discountInfo?.duration === "once" && (
                            <span className="text-sm font-bold text-secondary-fixed">{`Then ${formatPrice(
                              PlansMap["tier2"][frequency][currency]["price"],
                              currency,
                              undefined,
                              2,
                            )} /${discountInfo?.durationLabel}`}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="space-y-4">
                      {allFeaturesList.map((feature, index) => {
                        const { tipText, tipHtml } = tipToText(feature.tip);

                        return (
                          <div key={index} className="flex border-b border-[#1414141A] pb-4 last:border-none">
                            <div className="w-2/12">
                              {feature.free ? (
                                <img className={"h-6 w-6"} src={Check} alt="" />
                              ) : (
                                <img className={"h-6 w-6"} src={Lock} alt="" />
                              )}
                            </div>

                            <div className="w-8/12 flex justify-center">
                              <div className="flex items-center text-sm font-semibold space-x-1.5">
                                <span>{feature.title}</span>

                                {tipText && (
                                  <span className="flex flex-row self-center h-3 w-3 my-auto">
                                    <HelpTip tip={tipText} html={tipHtml} isDark />
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="w-2/12">
                              {feature.tier2 ? (
                                <img className={"h-6 w-6"} src={Check} alt="" />
                              ) : (
                                <img className={"h-6 w-6"} src={Lock} alt="" />
                              )}
                            </div>
                          </div>
                        );
                      })}

                      <div className="flex justify-center">
                        {userPlan.current_plan === "tier2" && userPlan.current_frequency === "year" && frequency === "month" ? (
                          <Button
                            onClick={() => handleMembershipChange("month", currency, startSubscription)}
                            disabled={!userPlan.plan_links}
                            context="secondary"
                          >
                            Change membership
                          </Button>
                        ) : userPlan.current_frequency === frequency && userPlan.current_plan === "tier2" ? (
                          <span className="flex justify-center px-6 py-2 text-sm text-on-surface-variant font-medium">
                            Current membership
                          </span>
                        ) : (
                          <Button
                            className="flex space-x-2"
                            onClick={() => handleMembershipChange(frequency, currency, startSubscription)}
                            disabled={
                              frequency === "72h"
                                ? isDisabledFor72h
                                : frequency === "month"
                                ? isDisabledForMonth
                                : frequency === "year"
                                ? isDisabledForYear
                                : isUnableToSubscribe
                            }
                          >
                            <img
                              src={
                                (
                                  frequency === "72h"
                                    ? isDisabledFor72h
                                    : frequency === "month"
                                    ? isDisabledForMonth
                                    : frequency === "year"
                                    ? isDisabledForYear
                                    : isUnableToSubscribe
                                )
                                  ? IconRocketGrey
                                  : IconRocket
                              }
                              alt=""
                              width="18"
                              height="18"
                            />
                            <span>
                              {isCurrentStarMonthly || isCurrentStar72h
                                ? "Extend membership"
                                : frequency === "72h"
                                ? "Purchase for 72h"
                                : "Upgrade membership"}
                            </span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <svg className="absolute top-full left-0 right-0 w-full z-30" height="80" viewBox="0 0 500 80" preserveAspectRatio="none">
                <defs>
                  <clipPath id="shape-flipped">
                    <path d="M0, 0 L0,40 Q250, 100 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape-flipped)" />
              </svg>
            </div>
          </div>

          <div
            className="relative flex flex-col items-center justify-center bg-cover pt-24 pb-32 bg-no-repeat bg-center space-y-10"
            style={{
              backgroundImage: `url(${mdQueries.lg ? BackgroundGreenSdWatermark : BackgroundGreenSdWatermarkMobile})`,
            }}
          >
            {mdQueries.lg ? (
              <svg
                className="absolute bottom-0 left-0 right-0 w-full rotate-180"
                height="80"
                viewBox="0 0 500 80"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="shape">
                    <path d="M0, 0 L0,40 Q250, 120 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape)" />
              </svg>
            ) : (
              <svg
                className="absolute bottom-0 left-0 right-0 w-full rotate-180"
                height="80"
                viewBox="0 0 500 80"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="shape">
                    <path d="M0, 0 L0,40 Q250, 72 500, 40 L500, 0 Z" />
                  </clipPath>
                </defs>
                <rect x="0" y="0" width="500" height="80" fill="#F5F5F5" clipPath="url(#shape)" />
              </svg>
            )}

            <div className={`${mdQueries.lg ? "flex flex-col items-center justify-center" : ""} relative w-full space-y-4 lg:space-y-8`}>
              <div className="w-10/12 m-auto">
                <h2 className="m-0 text-black font-headers text-2xl lg:text-[40px] font-bold text-center !leading-tight">
                  They said it&apos;s better with us
                </h2>
              </div>

              <div className="container mx-auto">
                {mdQueries.lg ? (
                  <div className="mx-auto 2xl:w-9/12">
                    <div className="grid grid-cols-3 gap-4">
                      <div
                        className="flex flex-col justify-between px-6 py-4 rounded-2xl h-36 overflow-hidden relative"
                        style={{
                          background: "linear-gradient(80deg, #008F97 14.96%, #005E78 87.01%)",
                        }}
                      >
                        <h4 className="text-white text-6xl font-bold m-0">96%</h4>
                        <p className="text-white text-sm lg:text-xs 2xl:text-sm font-medium w-2/4">
                          of users are satisfied with their subscription*
                        </p>
                        <img className="absolute bottom-0 right-0" src={Rocket} width="150" height="150" alt="" />
                      </div>

                      <div
                        className="flex flex-col justify-between px-6 py-4 rounded-2xl h-36 overflow-hidden relative"
                        style={{
                          background: "linear-gradient(80deg, #008F97 14.96%, #005E78 87.01%)",
                        }}
                      >
                        <h4 className="text-white text-6xl font-bold m-0">97.4%</h4>
                        <p className="text-white text-sm lg:text-xs 2xl:text-sm font-medium w-2/4">
                          of users recommend us to their Sorare friends*
                        </p>
                        <img className="absolute bottom-0 right-0" src={Like} width="170" height="147" alt="" />
                      </div>

                      <div
                        className="flex flex-col justify-between px-6 py-4 rounded-2xl h-36 overflow-hidden relative"
                        style={{
                          background: "linear-gradient(80deg, #008F97 14.96%, #005E78 87.01%)",
                        }}
                      >
                        <h4 className="text-white text-6xl font-bold m-0">85.6%</h4>
                        <p className="text-white text-sm lg:text-xs 2xl:text-sm font-medium w-3/4">
                          of users think we contribute to their good performance on Sorare*
                        </p>
                        <img className="absolute bottom-0 right-0" src={Trophy} width="170" height="147" alt="" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Slider className="slick-slider--testimonials" dots={true} arrows={false}>
                    <div>
                      <div
                        className="flex flex-col justify-between px-6 py-4 rounded-2xl h-36 overflow-hidden relative"
                        style={{
                          background: "linear-gradient(80deg, #008F97 14.96%, #005E78 87.01%)",
                        }}
                      >
                        <h4 className="text-white text-6xl font-bold m-0">96%</h4>
                        <p className="text-white text-sm font-medium w-2/4">of users are satisfied with their subscription*</p>
                        <img className="absolute bottom-0 right-0" src={Rocket} width="150" height="150" alt="" />
                      </div>
                    </div>

                    <div>
                      <div
                        className="flex flex-col justify-between px-6 py-4 rounded-2xl h-36 overflow-hidden relative"
                        style={{
                          background: "linear-gradient(80deg, #008F97 14.96%, #005E78 87.01%)",
                        }}
                      >
                        <h4 className="text-white text-6xl font-bold m-0">97.4%</h4>
                        <p className="text-white text-sm font-medium w-2/4">of users recommend us to their Sorare friends*</p>
                        <img className="absolute bottom-0 right-0" src={Like} width="170" height="147" alt="" />
                      </div>
                    </div>

                    <div>
                      <div
                        className="flex flex-col justify-between px-6 py-4 rounded-2xl h-36 overflow-hidden relative"
                        style={{
                          background: "linear-gradient(80deg, #008F97 14.96%, #005E78 87.01%)",
                        }}
                      >
                        <h4 className="text-white text-6xl font-bold m-0">85.6%</h4>
                        <p className="text-white text-sm font-medium w-3/4">
                          of users think we contribute to their good performance on Sorare*
                        </p>
                        <img className="absolute bottom-0 right-0" src={Trophy} width="170" height="147" alt="" />
                      </div>
                    </div>
                  </Slider>
                )}
              </div>
            </div>

            <style>
              {`
                @keyframes scrollLeft {
                  from { transform: translateX(0); }
                  to { transform: translateX(-200%); }
                }
              `}
            </style>

            <div className="overflow-hidden w-full">
              <div
                className="flex w-[200%] space-x-4 items-start"
                style={{
                  animation: mdQueries.lg ? "scrollLeft 120s linear infinite" : "scrollLeft 40s linear infinite",
                }}
              >
                {allXPosts.map((post, index) => (
                  <div className="p-5 bg-gray-100 rounded-lg flex space-x-4 w-2/4 min-w-[500px]" key={index}>
                    <img src={post.avatar} alt={`${post.name} avatar`} className="w-12 h-12 rounded-full" />
                    <div className="flex flex-col flex-grow space-y-4">
                      <div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-lg font-semibold">{post.name}</span>
                            <span className="ml-2 text-gray-600">{post.handle}</span>
                          </div>
                          <div className="text-gray-600 cursor-pointer">
                            <img src={TwitterKebabMenu} alt="" width="20" height="20" />
                          </div>
                        </div>

                        <p className="text-base text-gray-800" dangerouslySetInnerHTML={{ __html: post.content }} />
                      </div>

                      {post.quoteTweet && (
                        <div className="border border-gray-300 rounded-md p-3">
                          <div className="flex flex-col flex-grow">
                            <div className="flex items-center mb-2">
                              <img src={post.quoteTweet.avatar} alt={`${post.quoteTweet.name} avatar`} className="w-5 h-5 rounded-full" />
                              <div className="flex items-center">
                                <span className="text-sm ml-1 font-semibold">{post.quoteTweet.name}</span>
                                <span className="text-sm ml-1 text-gray-600">{post.quoteTweet.handle}</span>
                              </div>
                            </div>

                            <p
                              className="text-md text-gray-800 mb-4"
                              dangerouslySetInnerHTML={{
                                __html: post.quoteTweet.content,
                              }}
                            />

                            <a
                              className="text-md"
                              href={post.quoteTweet.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#1D9BF0" }}
                            >
                              Show more
                            </a>
                          </div>
                        </div>
                      )}

                      {post.image && (
                        <img
                          src={post.image}
                          alt=""
                          className="border border-gray-300 w-full rounded-md max-h-[430px] object-cover object-top"
                        />
                      )}

                      <div className="flex justify-between text-gray-600">
                        <div className="flex items-center space-x-1">
                          <img src={TwitterReply} alt="" width="20" height="20" />
                          {post.replies && <span className="text-sm">{post.replies}</span>}
                        </div>
                        <div className="flex items-center space-x-1">
                          <img src={TwitterRetweet} alt="" width="20" height="20" />
                          {post.retweets && <span className="text-sm">{post.retweets}</span>}
                        </div>
                        <div className="flex items-center space-x-1">
                          <img src={TwitterLike} alt="" width="20" height="20" />
                          {post.likes && <span className="text-sm">{post.likes}</span>}
                        </div>
                        <div className="flex items-center space-x-1">
                          <img src={TwitterShare} alt="" width="20" height="20" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-10/12 m-auto">
              <div className="flex items-center justify-center">
                <p className="text-white text-base italic font-medium text-center">
                  *source : public survey answered by 1073 SorareData users.
                </p>
              </div>
            </div>
          </div>
        </div>

        <HashScroll hash="#faq">
          <div className="mt-8 bg-white rounded-xl shadow w-10/12 mx-auto space-y-10 lg:space-y-24 lg:px-8 pt-8 pb-8">
            <div className="px-4 lg:mt-8">
              <p className="font-headers font-extrabold text-2xl lg:text-5xl text-center">Frequently asked questions</p>
            </div>

            <div className="space-y-10">
              <FAQPart
                title={"Payment Options"}
                questions={[
                  {
                    question: "Can I request a refund after my trial period?",
                    answer: "No, it is not possible to request a refund after your trial period.",
                  },
                  {
                    question: "Can we pay our membership in crypto currency?",
                    answer:
                      "We would like to accept crypto currency payments, but we are dependent on our payment processor for that. While we do not have a timeline of when crypto payments will be accepted, we plan to implement it when available.",
                  },
                  {
                    question: "Can we pay via Sorare?",
                    answer: "Sorare is not a payment provider, so it is not possible to pay via Sorare.",
                  },
                  {
                    question: "Can we choose the currency we want to use to pay our membership?",
                    answer:
                      "The default value is linked to your browser language, but you can choose the currency you want to use. Once a currency has been chosen for a membership, it cannot be changed in the future, even if the membership is canceled.",
                  },
                  {
                    question: "Is my membership renewal automatic?",
                    answer:
                      "Yes. Your membership renewal is automatically extended at the end of your membership. If you do not wish to renew your membership, you need to cancel the current membership.",
                  },
                  {
                    question: "How do I renew my Star membership?",
                    answer:
                      "If you want to keep your membership, you don't have to do anything! Your registered credit card will be automatically debited for the membership you already have.",
                  },
                ]}
              />
              <FAQPart
                title={"Memberships"}
                questions={[
                  {
                    question: "Can I use SorareData for free?",
                    answer:
                      "Of course! The Rookie membership is available at no cost, and it includes basic features that allow users to benefit from some of SorareData's tools.",
                  },
                  {
                    question: "What are your different membership options?",
                    answer:
                      "The Star membership is available in various durations: 72-hour access, monthly, or yearly. It is the only membership we offer.",
                  },
                  {
                    question: "How is the 72-hour access different from the Star membership?",
                    answer:
                      "The difference lies solely in the duration of the membership. The features remain the same as the Star membership.",
                  },
                  {
                    question: "When does my 72-hour access become active?",
                    answer:
                      "The 72-hour access becomes active at the time of your payment. For example, if you purchase your membership on Tuesday at 10 AM, it will expire on Friday at 10 AM.",
                  },
                  {
                    question: "Does the 72h access automatically renew?",
                    answer:
                      "The 72h access is for single use; it does not automatically renew at the end of your period. So, you can choose to get another one or not continue.",
                  },
                  {
                    question: "Is there a free trial for the Star membership?",
                    answer:
                      "Yes, we offer a 72-hour free trial for new users. You will not be automatically renewed at the end of your trial.",
                  },
                ]}
              />
            </div>
          </div>
        </HashScroll>

        {currencyModal && (
          <div className="text-center justify-between flex xs:p-4 md:p-8 mt-4 space-x-5">
            <CurrencyModal
              userPlan={userPlan}
              open={currencyModal}
              close={() => {
                setCurrencyModal(false);
              }}
              startAtEndOfContract={userPlan?.current_plan !== props.user.tier}
              preview={previewUpdate}
              tier={userPlan.current_plan}
              frequency={frequency}
              currency={currency}
              confirm={() => {
                handleMembershipChange(frequency, currency, startSubscription, true);
              }}
              setCurrency={setCurrency}
            />
          </div>
        )}

        {codeModal && (
          <div className="text-center justify-between flex xs:p-4 md:p-8 mt-4 space-x-5">
            <div>
              <Modal
                maxSize={"max-w-full w-[500px]"}
                size={"mx-auto"}
                open={codeModal}
                close={() => setCodeModal(false)}
                content={
                  <div className="flex flex-col justify-center items-center">
                    <div>
                      <p className="text-lg text-center font-semibold xs:mb-2 md:mb-4">Use a code</p>
                    </div>
                    <div className="relative mb-4">
                      <img src={IconSell} alt="" width="18" height="18" className="absolute left-3 top-1/2 transform -translate-y-1/2" />
                      <input
                        className={`px-9 focus:outline-none focus:ring-0 block w-full py-2 rounded-full sm:text-sm ${
                          couponDetails && validationStatus === "success"
                            ? "border-[#2D8650]"
                            : couponDetails && validationStatus === "error"
                            ? "border-live-on-surface"
                            : "border-grey-f2"
                        }`}
                        type="text"
                        placeholder="Enter your code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                      {couponDetails && validationStatus === "success" ? (
                        <img
                          src={IconCheckGreen}
                          alt=""
                          width="18"
                          height="18"
                          className="absolute right-3 top-1/2 transform -translate-y-1/2"
                        />
                      ) : couponDetails && validationStatus === "error" ? (
                        <img
                          src={IconError}
                          alt=""
                          width="18"
                          height="18"
                          className="absolute right-3 top-1/2 transform -translate-y-1/2"
                        />
                      ) : null}
                    </div>
                    {message === "" && !couponDetails && (
                      <p className="text-sm text-on-surface-variant">Enter a code to redeem a membership or a discount.</p>
                    )}
                    {message && <p className={`text-sm ${validationStatus === "error" ? "text-live-on-surface" : ""}`}>{message}</p>}

                    <div className={"mt-4 flex space-x-4 justify-end w-full"}>
                      <Button
                        context="secondary"
                        label={"Cancel"}
                        onClick={() => {
                          setCodeModal(false);
                        }}
                      />
                      <Button label={"Use code"} onClick={() => applyCouponCode(code)} />
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        )}

        {upgradeModal && (
          <div className="text-center justify-between flex xs:p-4 md:p-8 mt-4 space-x-5">
            <UpgradeModal
              userPlan={userPlan}
              open={upgradeModal}
              close={() => {
                setUpgradeModal(false);
              }}
              startAtEndOfContract={userPlan?.current_plan !== props.user.tier}
              preview={previewUpdate}
              tier={userPlan.current_plan}
              frequency={frequency}
              currency={currency}
              confirm={() => handleMembershipChange(frequency, currency, startSubscription)}
            />
          </div>
        )}
        <ReactTooltip effect="solid" />
      </div>
    );
  } else return null;
}

export default withUser(ProductPage);
export { PlansMap };
