import React from "react";
import { getTotalScoreColors } from "./helpers/getTotalScoreColors";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import BaseballScoreCircle from "../util/baseballScoreCircle";

function ScoreGauge(props) {
  let { score, aaScore, decisiveScore } = props;
  let display = score?.toFixed(1);
  if (score === undefined || score < 0) {
    score = 0;
    display = "NA";
  }
  const [scoreColor, scoreBackgroundColor] = getTotalScoreColors(score);

  const stroke = scoreBackgroundColor;
  const background = "#E5E5E5";

  let size = props.size || "w-20";

  let height = props.height || "40px";

  let fontSize = props.fontSize || "";

  const sport = props.sport;

  if (sport === sorareBaseball || sport === sorareBasketball) {
    if (props.noCircle) {
      return (
        <div className={"self-center"}>
          <p className={"text-lg self-center font-semibold"}>{score}</p>
        </div>
      );
    }
    return (
      <div className={props.circleSize}>
        <BaseballScoreCircle scale={sport === sorareBasketball ? "basketball" : "baseball"} score={score} />
      </div>
    );
  } else {
    return (
      <div className={size} style={{ height: height }}>
        <CircularProgressbarWithChildren
          value={score}
          className={""}
          circleRatio={0.5}
          strokeWidth={6}
          styles={buildStyles({
            rotation: 3 / 4,
            strokeLinecap: "round",
            pathColor: scoreColor,
            trailColor: background,
          })}
        >
          <CircularProgressbar
            value={score > 0 ? (decisiveScore / (decisiveScore + aaScore)) * score : 0}
            circleRatio={0.5}
            strokeWidth={6}
            styles={buildStyles({
              rotation: 3 / 4,
              strokeLinecap: "round",
              pathColor: scoreBackgroundColor,
            })}
          />
          <p className={"font-bold -mt-6 " + fontSize} style={{ position: "absolute" }}>
            {display}
          </p>
        </CircularProgressbarWithChildren>
      </div>
    );
  }
}

export default ScoreGauge;
