import React, { useCallback, useEffect, useState } from "react";
import StartIcon from "../../img/starter.svg";
import Bench from "../../img/benchNotCameIn.svg";
import NotInSquad from "../../img/notInSquad.svg";
import Doubtful from "../../img/doubtful.svg";
import StartChoiceIcon from "../../img/start-choice-icon.svg";
import BenchChoiceIcon from "../../img/bench-choice-icon.svg";
import OutChoiceIcon from "../../img/out-choice-icon.svg";
import DoubtChoiceIcon from "../../img/doubt-choice-icon.svg";
import NoPrediction, { ReactComponent as NoPredictionElem } from "../../img/waiting-predictions-icon.svg";
import { ReactComponent as IconDoubt } from "../../img/icon-lineup-doubt.svg";
import { ReactComponent as IconNotInSquad } from "../../img/icon-lineup-out.svg";
import { ReactComponent as IconStarter } from "../../img/icon-lineup-starter.svg";
import { ReactComponent as IconBench } from "../../img/icon-lineup-bench.svg";
import { ReactComponent as StartIconElem } from "../../img/starter-outline.svg";
import { ReactComponent as BenchElem } from "../../img/benchNotCameIn-outline.svg";
import { ReactComponent as DoubtElem } from "../../img/doubtful-outline.svg";
import { ReactComponent as NotInSquadElem } from "../../img/notInSquad-outline.svg";
import { ReactComponent as IconTrustLowNoColor } from "../../img/icon-trust-low-no-color.svg";
import { ReactComponent as IconTrustMidNoColor } from "../../img/icon-trust-medium-no-color.svg";
import { ReactComponent as IconTrustHighNoColor } from "../../img/icon-trust-high-no-color.svg";
import { ReactComponent as IconCertified } from "../../img/icons-certified.svg";
import { capitalize } from "./capitalize";
import { defaultFootballLUBFilters } from "../decision/footballFilters";
import { useElementSize } from "usehooks-ts";
import PopHoverWrapper from "./popHoverWrapper";
import { withUser } from "../../userContext";
import { errorCatcher } from "./errors";
import { ReactTooltip } from "../util/tooltip.js";
import { ReactComponent as Check } from "../../img/icons-circle-check-v2-no-color.svg";
import { ReactComponent as Invalid } from "../../img/icons-circle-invalid-no-color.svg";
import { ReactComponent as HourGlass } from "../../img/icons-hourglass.svg";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";
import { getThemeColor, useTheme } from "../../themeContext";
import { Range } from "./range";
import { sorareFootball } from "./sports";
import { isTeamEmpty } from "./teams";
import clsx from "clsx";

const probableStatuses = ["STARTER", "MIGHT_NOT", "NOT_STARTER", "OUT"];
const statusesLabels = {
  starter: "Starter",
  doubtful: "Doubtful",
  substitute: "Substitute",
  outOfSquad: "Out of squad",
};
const statusesLabelAlternative = {
  STARTER: statusesLabels.starter,
  MIGHT_NOT: statusesLabels.doubtful,
  NOT_STARTER: statusesLabels.substitute,
  OUT: statusesLabels.outOfSquad,
};
const probableStatusesWithNoStatus = [...probableStatuses, "NO_STATUS"];
const allBgColors = ["#ABD8FF", "#FFD89E", "#F8C1B4", "#F8C1B4"];
const allBgColorsClasses = ["bg-[#ABD8FF]", "bg-[#FFD89E]", "bg-[#F8C1B4]", "bg-[#F8C1B4]"];
const allTextColors = ["#0380F3", "#F55205", "#E73107", "#E73107"];
const allBorderColorsClasses = ["border-[#0380F3]", "border-[#F2994A]", "border-[#E73107]", "border-[#E73107]"];

const trustScores = {
  high: 1,
  medium: 0.9,
  low: 0.75,
};

export const getLineupProjectionBgColor = (status) => {
  const index = probableStatusesWithNoStatus.indexOf(status);
  if (index >= 0) {
    return allBgColors[index];
  }
  return "";
};

export const predicitionsInfo = (pred) => {
  if (pred == undefined) {
    return undefined;
  }
  const guesses = pred.guesses;
  let startPct = pred.pickPctAdjusted !== undefined && pred.pickPctAdjusted !== null ? pred.pickPctAdjusted * 100 : 0;
  let doubtPct = pred.doubtPctAdjusted !== undefined && pred.doubtPctAdjusted !== null ? pred.doubtPctAdjusted * 100 : 0;
  let missPct = pred.missPctAdjusted !== undefined && pred.missPctAdjusted !== null ? pred.missPctAdjusted * 100 : 0;
  let outPct = pred.outPctAdjusted !== undefined && pred.outPctAdjusted !== null ? pred.outPctAdjusted * 100 : 0;
  if (startPct === 0 && doubtPct === 0 && missPct === 0 && outPct === 0) {
    //Pct Adjusted not compute already - Use fallback pct
    startPct = pred.pct;
    doubtPct = guesses > 0 ? (pred.doubt * 100) / guesses : 0;
    missPct = guesses > 0 ? (pred.miss * 100) / guesses : 0;
    outPct = guesses > 0 ? (pred.out * 100) / guesses : 0;
  }
  const allStatuses = [startPct, doubtPct, missPct, outPct];
  const allIcons = [StartIconElem, DoubtElem, BenchElem, NotInSquadElem];
  const newIcons = [IconStarter, IconDoubt, IconBench, IconNotInSquad];
  const mostVotedIndex = allStatuses.reduce((accumulator, current, index) => {
    return current > allStatuses[accumulator] ? index : accumulator;
  }, 0);
  return {
    startPct: startPct,
    startGuesses: pred.pick,
    doubtPct: doubtPct,
    doubtGuesses: pred.doubt,
    missPct: missPct,
    missGuesses: pred.miss,
    outPct: outPct,
    outGuesses: pred.out,
    mostPickedInfo: {
      type: probableStatuses[mostVotedIndex],
      value: allStatuses[mostVotedIndex],
      Icon: allIcons[mostVotedIndex],
      NewIcon: newIcons[mostVotedIndex],
      bgColor: allBgColors[mostVotedIndex],
      textColor: allTextColors[mostVotedIndex],
    },
    reliabilityLevel: pred.reliabilityLevel,
    trustScore: trustScores[pred.reliabilityLevel], // for sorting
    guesses: guesses,
  };
};

export const CommunityPredictionSummary = (props) => {
  const { pred, adapting, vertical } = props;
  const [refContainer, { width }] = useElementSize();
  const [fontSize, setFontSize] = useState(9);
  useEffect(() => {
    setFontSize(
      width > 0
        ? props.hideReliability
          ? vertical
            ? width / 4
            : width / 5
          : vertical
          ? width / 4
          : width / 7
        : props.hideReliability
        ? 8
        : 10,
    );
  }, [width]);
  if (pred === undefined || pred.guesses === 0) {
    let content;
    const noPredictElement = (
      <div className={`${adapting ? "" : "w-5 h-5"} justify-center rounded bg-grey-e0`}>
        <div className={`flex flex-row items-center ${adapting ? "" : "w-5 h-5"} `}>
          <NoPredictionElem className={`${adapting ? "w-full h-full aspect-square" : "w-5 h-5"}`} />
        </div>
      </div>
    );
    const noGame = (
      <p
        className={`font-medium my-auto text-on-surface-variant ${adapting ? "" : "text-xs"}`}
        style={adapting ? { fontSize: fontSize, lineHeight: 1 } : undefined}
      >
        {props.gameId ? "Waiting" : "No game"}
      </p>
    );
    if (vertical) {
      content = (
        <div className={"grid grid-rows-2 grid-cols-1"}>
          <div className={`flex flex-row justify-center`}>{noPredictElement}</div>
          <div className={`flex justify-center items-center`}>{noGame}</div>
        </div>
      );
    } else {
      content = (
        <div className={`grid grid-rows-1 grid-cols-4 flex-row`}>
          <div className={`flex flex-row justify-center`}>{noPredictElement}</div>
          <div className={`ml-[10%] col-span-3 flex ${adapting ? "justify-center" : "justify-start"} items-center`}>{noGame}</div>
        </div>
      );
    }

    return (
      <div ref={refContainer} className={"w-full"}>
        {props.gameId ? (
          <a href={"/game/" + props.gameId} target={"_blank"} rel="noreferrer">
            {content}
          </a>
        ) : (
          <>{content}</>
        )}
      </div>
    );
  }
  const info = predicitionsInfo(pred);
  const { Icon, bgColor, value } = info.mostPickedInfo;
  const trust = info.reliabilityLevel;
  const predElement = (
    <div className={"flex flex-row justify-center aspect-square rounded-[20%]"} style={{ backgroundColor: bgColor }}>
      <div className={`flex flex-row items-center ${adapting ? "w-full h-full" : "w-5 h-5"} ${value >= 50 ? "" : "opacity-50"}`}>
        <Icon className={adapting ? "w-full h-full" : "w-5 h-5"} style={adapting ? { aspectRatio: "1/1" } : undefined} />
      </div>
    </div>
  );
  const pctElement = (
    <div className={"flex flex-row justify-center items-center col-span-2 w-full"}>
      <p
        className={`font-medium ${adapting ? "ml-[10%]" : "text-sm ml-1"}  text-on-surface-variant`}
        style={adapting ? { fontSize: fontSize } : undefined}
      >
        {value.toFixed(0)}%
      </p>
    </div>
  );
  const reliabilityElement = (
    <div className={"flex justify-center items-center aspect-square"}>
      <div className={adapting ? "w-full h-full" : "w-5 h-5"} style={adapting ? { aspectRatio: "1/1" } : undefined}>
        {trust === "high" ? (
          <IconTrustHighNoColor className={"w-full h-full fill-on-surface"} />
        ) : trust === "medium" ? (
          <IconTrustMidNoColor className={"w-full h-full fill-on-surface"} />
        ) : (
          <IconTrustLowNoColor className={"w-full h-full fill-on-surface"} />
        )}
      </div>
    </div>
  );
  let content;
  if (vertical) {
    content = (
      <div className={"grid grid-cols-1 grid-rows-2"}>
        {props.hideReliability ? (
          { predElement }
        ) : (
          <div className={"grid grid-cols-2 grid-rows-1"}>
            {predElement}
            {reliabilityElement}
          </div>
        )}
        <div className={"pt-0.5"}>
          <div className={"bg-surface-container-highest rounded"}>{pctElement}</div>
        </div>
      </div>
    );
  } else {
    content = (
      <div
        className={`grid ${props.hideReliability ? "grid-cols-3" : "grid-cols-4"} grid-rows-1 ${adapting ? "" : "gap-0.5"} items-center`}
      >
        {predElement}
        {pctElement}
        {(props.hideReliability === undefined || !props.hideReliability) && reliabilityElement}
      </div>
    );
  }
  return (
    <div ref={refContainer} className={"w-full"}>
      {props.gameId ? (
        <a href={"/game/" + props.gameId} target={"_blank"} rel="noreferrer">
          {content}
        </a>
      ) : (
        <>{content}</>
      )}
    </div>
  );
};

export const CommunityPredictionFilters = (props) => {
  const { filters, onChange } = props;
  const statuses = filters.status || defaultFootballLUBFilters.communityProjections.status;
  const reliabilities = filters.reliability || defaultFootballLUBFilters.communityProjections.reliability;
  const allStatusIcons = [StartIconElem, DoubtElem, BenchElem, NotInSquadElem];
  const allTrustIcons = [IconTrustLowNoColor, IconTrustMidNoColor, IconTrustHighNoColor];
  const { theme } = useTheme();

  // local state for display only
  // So range values can be updated visually, but only validated when user blur the handles
  const [probability, setProbability] = useState(statuses.probability);

  useEffect(() => {
    setProbability(statuses.probability);
  }, [statuses.probability]);

  const onStatusChange = useCallback(
    (key, val) => {
      const newVal = {
        ...filters,
        status: {
          enabled: {
            ...filters.status.enabled,
            [key]: val,
          },
          probability: filters.status.probability,
        },
      };
      onChange(newVal);
    },
    [onChange, filters],
  );

  const onTrustChange = useCallback(
    (trust, val) => {
      const newVal = { ...filters.reliability };
      newVal[trust] = val;
      onChange({ ...filters, reliability: newVal });
    },
    [onChange, filters],
  );

  const onRangeChangeLocal = useCallback(
    (range) => {
      setProbability(range);
    },
    [probability],
  );

  const onRangeChange = useCallback(
    (range) => {
      const newVal = { ...filters.status, probability: range };
      onChange({ ...filters, status: newVal });
    },
    [filters, onChange],
  );
  const statusesSelected = Object.keys(statuses.enabled).filter((key) => statuses.enabled[key]) || [];
  return (
    <div className={"flex flex-col gap-2"}>
      <div className={"border border-transparent-inverse-surface-low border-opacity-10 rounded-md py-2 flex flex-col gap-2"}>
        <div className={"grid grid-cols-4 justify-evenly divide-x divide-transparent-inverse-surface-low divide-opacity-10"}>
          {Object.entries(statuses.enabled).map(([status, isSelected], i) => {
            const Icon = allStatusIcons[i];
            const bgColor = allBgColorsClasses[i];
            const borderColor = isSelected ? allBorderColorsClasses[i] : "border-surface-container";
            return (
              <div key={status} className={`flex flex-row justify-center items-center w-full`} title={statusesLabels[status]}>
                <div
                  onClick={() => onStatusChange(status, !isSelected)}
                  className={`w-6 h-6 flex items-center rounded justify-center cursor-pointer border ${bgColor} ${borderColor} ${
                    isSelected ? "hover:opacity-90" : "opacity-50 hover:opacity-100"
                  }`}
                >
                  <Icon className={"w-6 h-6"} />
                </div>
              </div>
            );
          })}
        </div>
        {statusesSelected.length > 0 && (
          <div
            className={"flex flex-col gap-1 w-full px-3"}
            title={
              statusesSelected.length === 1
                ? `Would display players that are '${statusesLabels[statusesSelected[0]]}' with a probability between ${
                    probability[0]
                  }% and ${probability[1]}%`
                : `Would display players whose sum of '${statusesSelected
                    .map((e) => statusesLabels[e])
                    .join("+")}' probabilities is between ${probability[0]}% and ${probability[1]}%`
            }
          >
            <h5 className={"text-xs text-on-surface-variant font-medium flex flex-row gap-1"}>
              <span>{`${statusesSelected.length === 1 ? statusesLabels[statusesSelected[0]] : "Cumulative"} probability`}</span>
              <span className={"text-on-surface font-bold"}>
                {probability[0]}% - {probability[1]}%
              </span>
            </h5>
            <div className={`w-full px-2"`}>
              <Range
                step={1}
                value={probability}
                min={0}
                max={100}
                allowCross={false}
                onChange={(newVal) => onRangeChangeLocal(newVal)}
                onAfterChange={(newVal) => onRangeChange(newVal)}
              />
            </div>
          </div>
        )}
      </div>

      <div className={"border border-transparent-inverse-surface-low border-opacity-10 rounded-md py-2"}>
        <div className={"grid grid-cols-3 justify-evenly divide-x divide-transparent-inverse-surface-low divide-opacity-10"}>
          {Object.keys(reliabilities).map((trust, i) => {
            const Icon = allTrustIcons[i];
            const isSelected = reliabilities[trust];
            return (
              <div
                key={i}
                className={`flex flex-row justify-center items-center w-full `}
                title={capitalize(trust) + " projection reliability"}
              >
                <div
                  onClick={() => onTrustChange(trust, !isSelected)}
                  className={`flex flex-col items-center rounded justify-center cursor-pointer ${
                    isSelected ? "hover:opacity-90" : "opacity-50 hover:opacity-100"
                  }`}
                >
                  <Icon className={"w-4 h-4 fill-on-surface-variant"} />
                  <span className={`text-xxs font-semibold`}>{capitalize(trust)}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const CommunityPredictionIndicator = withUser((props) => {
  const {
    pred,
    gameId,
    withGuesses,
    textColor,
    reliabilityColor,
    majorChoiceColored,
    selected,
    votable,
    effectiveResult,
    playerId,
    onReloadStatus,
    onVoteChange,
    gwLiveOrClosed,
    textSize,
    iconSize,
  } = props;
  const [reload, setReload] = useState(0);
  const [projection, setProjection] = useState(pred);
  const [vote, setVote] = useState(selected);
  const [voting, setVoting] = useState("");

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [projection, vote]);

  useEffect(() => {
    if (reload > 0 && votable && playerId && gameId) {
      props
        .fetch(`/apiv2/players/lineupProjection/${playerId}/${gameId}`)
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setProjection(res.projection);
            setVote(res.vote);
            onReloadStatus && onReloadStatus(playerId, gameId, res.projection, res.vote);
          }
        })
        .catch(errorCatcher(() => {}));
    }
  }, [votable, reload]);
  useEffect(() => {
    setProjection(pred);
  }, [pred]);
  useEffect(() => {
    setVote(selected);
  }, [selected]);

  const onCommunityProjChange = useCallback(
    (status) => {
      if (votable && playerId && gameId) {
        setVoting(status);
        props
          .fetch("/projections-api/SO5/changeStartingStatus", {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              gameId: gameId,
              players: [{ playerId, status }],
            }),
          })
          .then((response) => response.json())
          .then((res) => {
            setVoting("");
            setReload(reload + 1);
            onVoteChange && onVoteChange(playerId, gameId, status);
          })
          .catch(
            errorCatcher(() => {
              setVoting("");
              setReload(reload + 1);
            }),
          );
      }
    },
    [reload, votable, playerId, gameId],
  );

  const info = predicitionsInfo(projection);
  return (
    <div className={"flex flex-col justify-center gap-2"}>
      <CommunityPrediction
        pred={projection}
        votable={votable}
        voting={voting}
        effectiveResult={effectiveResult}
        gameId={gameId}
        textColor={textColor}
        reliabilityColor={reliabilityColor}
        majorChoiceColored={majorChoiceColored}
        selected={vote}
        onChange={onCommunityProjChange}
        gwLiveOrClosed={gwLiveOrClosed}
        textSize={textSize}
        iconSize={iconSize}
      />
      {withGuesses && (
        <p className={"italic text-xxs text-on-surface-variant font-semibold text-center"}>
          <span>{info?.guesses > 0 ? `${info?.guesses} predictions` : "No prediction"}</span>
        </p>
      )}
    </div>
  );
});

export const CommunityPredictionSummaryIndicator = (props) => {
  const { pred, gameId } = props;
  return (
    <PopHoverWrapper
      hoverTitle="Community projections"
      hover={<CommunityPredictionIndicator pred={pred} gameId={gameId} withGuesses={true} />}
    >
      <div className={"flex justify-center items-center"}>
        <CommunityPredictionSummary {...props} />
      </div>
    </PopHoverWrapper>
  );
};

const errorCodesToMessage = {
  too_many_gk: "you can't have more than 1 starting goalkeepers (counting minted players only)",
  too_many_starters: "you can't have more than 11 starters (counting minted players only)",
  too_many_starters_doubtful: "you can't have doubtful players if you have 11 starters (counting minted players only)",
  too_many_gk_with_unlicensed: "you can't have more than 1 starting goalkeepers, including non-minted players",
  too_many_starters_with_unlicensed: "you can't have more than 11 starters, including non-minted players",
  too_many_starters_doubtful_with_unlicensed: "you can't have doubtful players if you have 11 starters, including non-minted players",
};

const getMessageFromErrorCodes = (status, errorCodes, nbVotes, nbVotesIncludingUnlicensed) => {
  let messages = [];
  const nbVotesUnlicensed = nbVotesIncludingUnlicensed - nbVotes;
  errorCodes.forEach((code) => {
    if (status === "STARTER") {
      if (code.includes("too_many_gk")) {
        if (code.includes("with_unlicensed")) {
          messages.push("Including non-minted players, you have more than 1 goalkeeper.");
        } else {
          messages.push(`With minted players only, you have more than 1 goalkeeper.`);
        }
      } else if (code.includes("too_many_starters_doubtful")) {
        if (code.includes("with_unlicensed")) {
          messages.push(
            `Including non-minted players, you have selected doubtful players while your have selected at least 11 starters (${nbVotesIncludingUnlicensed} starters of which ${nbVotesUnlicensed} non-minted).`,
          );
        } else {
          messages.push(
            `With minted players only, you have selected doubtful players while your have selected at least 11 starters (${nbVotes} starters minted).`,
          );
        }
      } else if (code.includes("too_many_starters")) {
        if (code.includes("with_unlicensed")) {
          messages.push(
            `Including non-minted players you have selected more than 11 (${nbVotesIncludingUnlicensed} of which ${nbVotesUnlicensed} non-minted).`,
          );
        } else {
          messages.push(`With minted players only you have selected more than 11 (${nbVotes} minted).`);
        }
      }
    }
    if (status === "MIGHT_NOT") {
      if (code.includes("too_many_starters_doubtful")) {
        if (code.includes("with_unlicensed")) {
          messages.push(
            `Including non-minted players, you have selected doubtful players while your have selected at least 11 starters (${nbVotesIncludingUnlicensed} doubtfuls of which ${nbVotesUnlicensed} non-minted).`,
          );
        } else {
          messages.push(
            `With minted players only, you have selected doubtful players while your have selected at least 11 starters (${nbVotes} doubtfuls minted).`,
          );
        }
      }
    }
  });
  messages.push("This projections is invalid.");
  return messages.join("<br/>");
};

export const ProjectedLineupStats = (props) => {
  const { stats, scoredInRegion } = props;

  if (stats === null || stats === undefined) {
    return null;
  }
  const { nb_eligible_players, votes, votes_including_unlicensed, is_valid, error_codes } = stats;
  const errorCodes = error_codes || [];
  const notApplicable = nb_eligible_players === 0;
  const nbVotes = Object.values(votes).reduce((a, b) => a + b, 0);
  const isStarted = nbVotes > 0;
  const isComplete = nb_eligible_players == nbVotes;
  const allStatusIcons = [IconStarter, IconDoubt, IconBench, IconNotInSquad];
  const tipOnOverallStatus = is_valid
    ? isComplete
      ? "All minted players have been projected for this team"
      : isStarted
      ? "Some minted players are still missing projection for this team"
      : "No projection on this team yet. Start projecting!"
    : "Projection for this team is invalid: " + errorCodes.map((code) => errorCodesToMessage[code]).join(", ");

  if (scoredInRegion !== undefined && scoredInRegion !== null && scoredInRegion != "") {
    return (
      <div
        className={
          "bg-transparent-inverse-surface-low bg-opacity-10 rounded px-3 py-1 flex flex-row gap-2 justify-center items-center h-full"
        }
      >
        <div className={"w-3 h-3"}>
          <IconInfoHelp className={"w-3 h-3 fill-on-surface"} />
        </div>
        <p className={"text-xs font-semibold text-on-surface text-center"}>Scored in {scoredInRegion}</p>
      </div>
    );
  }

  return (
    <div className={"bg-transparent-inverse-surface-low bg-opacity-10  rounded px-2 py-1 flex flex-row gap-2 justify-evenly items-center"}>
      <ReactTooltip />
      <div className={"grid grid-cols-4 gap-2"}>
        {probableStatuses.map((status, i) => {
          const nbVotes = votes?.[status] || 0;
          const nbVotesIncludingUnlicensed = votes_including_unlicensed?.[status] || 0;
          const label = statusesLabelAlternative[status];
          const isStatusInvalid =
            !is_valid && (status === "STARTER" || (status === "MIGHT_NOT" && errorCodes.includes("too_many_starters_doubtful")));
          const tip = notApplicable
            ? "No minted players for this team"
            : nbVotes > 0
            ? `You have selected ${nbVotes} minted players as '${label}' in this team` +
              (isStatusInvalid ? ".<br/>" + getMessageFromErrorCodes(status, errorCodes, nbVotes, nbVotesIncludingUnlicensed) : "")
            : `You did not select any minted player as '${label}' in this team`;
          const Icon = allStatusIcons[i];
          return (
            <div key={i} className={`flex flex-row justify-center items-center`} data-tip={tip} data-html={true}>
              <div className={`flex flex-col items-center justify-center gap-0.5`}>
                <Icon className={"w-5 h-5 fill-on-surface"} />
                <span className={`text-xs font-semibold ${isStatusInvalid ? "text-red" : "text-on-surface-variant"}`}>{nbVotes}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className={"flex justify-center items-center px-2"}>
        {notApplicable ? (
          <span className={"text-sm text-on-surface-variant font-semibold"} data-tip="No minted players for this team">
            NA
          </span>
        ) : (
          <div className={"flex flex-col gap-0.5 justify-center items-center"} data-tip={tipOnOverallStatus}>
            {is_valid ? (
              <>
                {isComplete || isStarted ? (
                  <Check
                    className={`w-5 h-5 ${
                      isComplete
                        ? "fill-quality-scale-very-good-quality-container-bright-fixed"
                        : "fill-quality-scale-average-good-quality-container-bright-fixed"
                    }`}
                  />
                ) : (
                  <HourGlass className={"w-5 h-5 fill-on-surface-variant"} />
                )}
                <div
                  className={`text-xs font-semibold ${
                    isComplete
                      ? "text-quality-scale-very-good-on-surface-container"
                      : isStarted
                      ? "text-quality-scale-average-good-on-surface-container"
                      : "text-on-surface-variant"
                  }`}
                >
                  {nbVotes}/{nb_eligible_players}
                </div>
              </>
            ) : (
              <>
                <Invalid className={"w-4 h-4 fill-error"} />
                <div className={"text-error text-xs font-semibold"}>ERR</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function CommunityPrediction(props) {
  const { theme } = useTheme();
  if (props.pred !== undefined) {
    const pred = props.pred;
    const majorChoiceColored = props.majorChoiceColored !== undefined ? props.majorChoiceColored : true;
    const textColor = props.textColor !== undefined ? props.textColor : "text-on-surface-variant";
    const reliabilityColor = props.reliabilityColor !== undefined ? props.reliabilityColor : getThemeColor(theme, "on-surface-variant");
    const guesses = pred.guesses;
    const votable = props.votable;
    const voting = props.voting;
    const effectiveResult = props.effectiveResult;
    const gwLiveOrClosed = props.gwLiveOrClosed;
    const textSize = props.textSize || "text-xxs";
    const iconSize = props.iconSize || "w-6 h-6";
    if (guesses === 0 && (!votable || gwLiveOrClosed)) {
      return (
        <a href={props.gameId ? "/game/" + props.gameId : undefined} target={"_blank"} rel="noreferrer">
          <div className={"flex flex-row space-x-2"}>
            <div className={iconSize}>
              <img src={NoPrediction} className={"w-full h-full"} />
            </div>
            <p className={"text-xs font-semibold text-on-surface-variant self-center"}>
              {props.gameId ? "Waiting for projections" : "No game"}
            </p>
          </div>
        </a>
      );
    }
    const info = predicitionsInfo(pred);
    const { startPct, startGuesses, doubtPct, doubtGuesses, missPct, missGuesses, outPct, outGuesses, reliabilityLevel } = info;
    const selected = props.selected;
    const onChange = props.onChange;
    const data = [
      {
        name: "Starter",
        status: "STARTER",
        value: startPct,
        valueGuesses: startGuesses,
        color: "text-super-rare",
        selectedIcon: StartChoiceIcon,
        unselectedIcon: StartIcon,
      },
      {
        name: "Doubtful",
        status: "MIGHT_NOT",
        value: doubtPct,
        valueGuesses: doubtGuesses,
        color: "text-doubt-orange",
        selectedIcon: DoubtChoiceIcon,
        unselectedIcon: Doubtful,
      },
      {
        name: "Benched",
        status: "NOT_STARTER",
        value: missPct,
        valueGuesses: missGuesses,
        color: "text-rare",
        selectedIcon: BenchChoiceIcon,
        unselectedIcon: Bench,
      },
      {
        name: "Out of squad",
        status: "OUT",
        value: outPct,
        valueGuesses: outGuesses,
        color: "text-rare",
        selectedIcon: OutChoiceIcon,
        unselectedIcon: NotInSquad,
      },
    ];

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isProjectionWrong =
      effectiveResult !== undefined && effectiveResult.status !== "" && votable && selected !== effectiveResult.status;
    const content = (
      <div className={"flex flex-row space-x-2"}>
        {data.map((d, i) => {
          const sel = selected === d.status;
          const tColor = sel && majorChoiceColored ? d.color : textColor;
          const guesses = d.valueGuesses || 0;
          const hint = votable
            ? sel
              ? `${guesses} guesses for ${d.name} projection, including yours.</br/>${
                  gwLiveOrClosed ? "GW is live or closed : projections can no longer be submited" : "Click to remove it"
                }`
              : `${guesses} guesses for ${d.name} projection.<br/>${
                  gwLiveOrClosed
                    ? "GW is live or closed : projections can no longer be submited"
                    : `Click to project that the player is going to be ${d.name}`
                }`
            : undefined;
          return (
            <div
              key={i}
              className={`flex flex-col items-center gap-0.5 ${voting === d.status ? "animate-pulse" : ""} ${
                votable && !gwLiveOrClosed ? "cursor-pointer" : ""
              }`}
              data-delay-show="800"
              data-html={true}
              data-tip={isMobile ? "" : hint}
              onClick={votable && !gwLiveOrClosed ? () => onChange && onChange(sel ? "NO_STATUS" : d.status) : undefined}
            >
              <div
                className={`${iconSize} ${
                  isProjectionWrong && d.status === effectiveResult.status
                    ? effectiveResult.licenced
                      ? "border-[1.5px] border-[#00bc00] rounded-[0.33rem]"
                      : "border-[1.5px] border-textGrey3 rounded-[0.33rem]"
                    : ""
                }`}
              >
                <img
                  className={`w-full h-full ${votable ? (sel ? "" : "opacity-50") : "opacity-50"}`}
                  src={votable ? (sel ? d.selectedIcon : d.unselectedIcon) : d.unselectedIcon}
                />
              </div>
              <p
                className={`${textSize} ${tColor} ${
                  votable
                    ? sel
                      ? "font-bold"
                      : (tColor === "text-white" ? "opacity-50" : "") + " font-semibold"
                    : (tColor === "text-white" ? "opacity-50" : "") + " font-semibold"
                }`}
              >
                {d.value.toFixed(0)}%
              </p>
            </div>
          );
        })}
        {reliabilityLevel && (
          <div className={"flex justify-center items-center ml-1"} data-tip={"Reliability is: " + reliabilityLevel}>
            <div className={iconSize}>
              {reliabilityLevel === "high" ? (
                <IconTrustHighNoColor className={iconSize} style={{ fill: reliabilityColor }} />
              ) : reliabilityLevel === "medium" ? (
                <IconTrustMidNoColor className={iconSize} style={{ fill: reliabilityColor }} />
              ) : (
                <IconTrustLowNoColor className={iconSize} style={{ fill: reliabilityColor }} />
              )}
            </div>
          </div>
        )}
      </div>
    );
    if (props.gameId && !votable) {
      return (
        <a href={"/game/" + props.gameId} target={"_blank"} rel="noreferrer">
          {content}
        </a>
      );
    }
    return content;
  } else return null;
}

export const PlayerCommunityProjection = (props) => {
  const { sport, next_game_projection, playerTeamId } = props;
  const [refContainer, { width }] = useElementSize();

  const adapting = props.adapting || false;
  const size = props.size || "md";
  const iconSizeClass = adapting ? "" : size === "md" ? "w-[1.2rem] h-[1.2rem]" : "w-[0.9rem] h-[0.9rem]";
  const iconSizeStyle = adapting ? (width > 0 ? width / 7 : 8) : undefined;
  const iconStyle = {
    width: iconSizeStyle,
    height: iconSizeStyle,
  };
  const textStyle = adapting
    ? { fontSize: width > 0 ? width / 8 + "px" : "8px", lineHeight: width > 0 ? width / 70 + "em" : "0.75em" }
    : undefined;
  const barStyle = adapting ? { height: width / 120 + "em" } : undefined;
  const info = predicitionsInfo(next_game_projection);
  const mostPicked = info?.mostPickedInfo?.type || "";
  const waiting = info === undefined || info?.mostPickedInfo?.value === 0;
  const { NewIcon, value } = info?.mostPickedInfo || {};
  const reliabilityLevel = info?.reliabilityLevel || "";
  const isGood = !waiting && reliabilityLevel === "high" && mostPicked === "STARTER" && value !== undefined && value.toFixed(0) >= 100;
  const isBad = !waiting && !isGood && mostPicked !== "STARTER";

  if (sport !== sorareFootball) {
    return null;
  }

  if (isTeamEmpty(playerTeamId)) {
    return null;
  }

  const tagClass = clsx(
    "rounded-full w-fit flex flex-row text-base",
    isGood
      ? "bg-quality-scale-excellent-quality-container-dim-fixed"
      : isBad
      ? "bg-quality-scale-very-bad-quality-container-dim-fixed"
      : "bg-transparent-inverse-surface-low bg-opacity-10",
    adapting ? "px-[10%] space-x-1 py-0.5" : size === "md" ? "px-3 space-x-2 py-1" : "px-1 xs:px-2 space-x-1 py-0.5",
  );

  const trustIconClass = clsx(
    iconSizeClass,
    isGood
      ? "fill-quality-scale-excellent-on-dim-quality-container-fixed"
      : isBad
      ? "fill-quality-scale-very-bad-on-dim-quality-container-fixed"
      : "fill-on-surface-variant",
  );

  const playingIconClass = clsx(
    iconSizeClass,
    "self-center",
    isGood
      ? "fill-quality-scale-excellent-on-dim-quality-container-fixed"
      : isBad
      ? "fill-quality-scale-very-bad-on-dim-quality-container-fixed"
      : "fill-on-surface-variant",
  );

  const textClass = clsx(
    "self-center font-semibold",
    isGood
      ? "text-quality-scale-excellent-on-dim-quality-container-fixed"
      : isBad
      ? "text-quality-scale-very-bad-on-dim-quality-container-fixed"
      : "text-on-surface-variant",
    adapting ? "" : size === "sm" ? "text-xs" : "",
  );

  return (
    <div ref={refContainer} className={adapting ? "w-full h-full flex justify-center items-center" : ""}>
      <div className={tagClass}>
        {waiting ? (
          <>
            <HourGlass style={iconStyle} className={clsx(iconSizeClass, "text-on-surface-variant self-center fill-on-surface-variant")} />
            <span className={`self-center font-semibold text-on-surface-variant ${size === "sm" ? "text-xs" : ""}`}>Waiting</span>
          </>
        ) : (
          <>
            <div className={"flex justify-center items-center"} data-tip={"Reliability is: " + reliabilityLevel}>
              <div>
                {reliabilityLevel === "high" ? (
                  <IconTrustHighNoColor style={iconStyle} className={trustIconClass} />
                ) : reliabilityLevel === "medium" ? (
                  <IconTrustMidNoColor style={iconStyle} className={trustIconClass} />
                ) : (
                  <IconTrustLowNoColor style={iconStyle} className={trustIconClass} />
                )}
              </div>
            </div>
            <div
              className={clsx(
                "border-l border-transparent-inverse-surface-low border-opacity-10 self-center",
                adapting ? "" : "h-2 sm:h-3",
              )}
              style={barStyle}
            />
            <div className="flex flex-row gap-1 self-center">
              <NewIcon style={iconStyle} className={playingIconClass} />
              <span className={textClass} style={textStyle}>
                {value !== undefined ? value.toFixed(0) : 0}%
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const PlayerCommunityProjectionWithHover = (props) => {
  const { projection, playerTeamId, sport, adapting } = props;
  return (
    <PopHoverWrapper hoverTitle="Lineup projection" hover={<CommunityProjectionHover projection={projection} />}>
      <div className={"flex justify-center items-center"}>
        <PlayerCommunityProjection
          adapting={adapting}
          size="sm"
          sport={sport}
          playerTeamId={playerTeamId}
          next_game_projection={projection}
        />
      </div>
    </PopHoverWrapper>
  );
};

export const CommunityProjectionHover = (props) => {
  const { projection } = props;
  const info = predicitionsInfo(projection);
  const waiting = info === undefined || info.guesses === 0;
  if (waiting) {
    return (
      <div
        className={
          "relative flex flex-col justify-center items-center p-2 gap-2 bg-surface-container-low rounded-lg text-on-surface font-normal min-w-[15em]"
        }
      >
        <IconStarter className={"w-16 h-16 fill-transparent-inverse-surface-low opacity-10"} />
        <div className={"absolute w-full h-full flex justify-center items-center"}>
          <p className={"text-on-surface-variant font-medium text-sm"}>Waiting for projections</p>
        </div>
      </div>
    );
  }
  const iconClassName = "w-5 h-5 fill-on-surface";
  const reliabilityLevel = info.reliabilityLevel;
  return (
    <div className={"flex flex-col justify-center items-center p-3 gap-2 bg-surface-container-low rounded-lg text-on-surface font-normal"}>
      <div className={"flex flex-col gap-2 justify-center items-center"}>
        <LineupProjectionDetails info={info} />
        <div className="border-b border-transparent-inverse-surface-low border-opacity-10 w-[80%]" />
      </div>
      <div className={"flex flex-row gap-2 px-1"}>
        <span>
          {reliabilityLevel === "high" ? (
            <IconTrustHighNoColor className={iconClassName} />
          ) : reliabilityLevel === "medium" ? (
            <IconTrustMidNoColor className={iconClassName} />
          ) : (
            <IconTrustLowNoColor className={iconClassName} />
          )}
        </span>
        <span className={"text-sm font-base"}>Reliability {capitalize(info.reliabilityLevel)}</span>
      </div>
      {reliabilityLevel === "high" && (
        <div className={"flex flex-row gap-1 text-sm px-1"}>
          <span>Source:</span>
          <span className={"flex flex-row gap-1 items-center font-semibold"}>
            <span>League Specialists</span>
            <IconCertified className={"fill-tertiary-fixed"} />
          </span>
        </div>
      )}
      {(reliabilityLevel === "medium" || reliabilityLevel === "high") && (
        <p className={"text-xs text-on-surface-variant font-semibold max-w-[20em] px-1 text-center"}>
          Only projections from the {reliabilityLevel === "high" ? 10 : 25} best projectors of this league are used
        </p>
      )}
    </div>
  );
};

export const LineupProjectionDetails = (props) => {
  const { info } = props;
  const data = [
    {
      name: "Starter",
      status: "STARTER",
      value: info.startPct,
      valueGuesses: info.startGuesses,
      icon: IconStarter,
    },
    {
      name: "Doubtful",
      status: "MIGHT_NOT",
      value: info.doubtPct,
      valueGuesses: info.doubtGuesses,
      icon: IconDoubt,
    },
    {
      name: "Benched",
      status: "NOT_STARTER",
      value: info.missPct,
      valueGuesses: info.missGuesses,
      icon: IconBench,
    },
    {
      name: "Out of squad",
      status: "OUT",
      value: info.outPct,
      valueGuesses: info.outGuesses,
      icon: IconNotInSquad,
    },
  ];
  const mostPicked = info.mostPickedInfo;
  return (
    <div className={"flex flex-row gap-3 justify-center items-center"}>
      {data.map((d) => {
        const Icon = d.icon;
        const isMostPicked = d.status === mostPicked.type;
        return (
          <div key={d.status} className={"flex flex-col items-center gap-1"}>
            <Icon className={clsx("w-5 h-5", isMostPicked ? "fill-on-surface" : "fill-on-surface-variant")} />
            <span className={clsx("text-xs font-semibold", isMostPicked ? "text-on-surface" : "text-on-surface-variant")}>
              {d.value?.toFixed(0) || 0}%
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default withUser(CommunityPrediction);
