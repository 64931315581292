import React from "react";
import ComboSelectedListItem from "./comboSelectedListItem";

function ComboSelectedList(props) {
  const players = props.players;
  return (
    <div className={""}>
      <div className={"grid grid-flow-col"}>
        {players !== null &&
          players.map((p) => {
            return (
              <div className={"bg-white border border-light-grey rounded"}>
                <ComboSelectedListItem
                  player={p}
                  key={p.PlayerId}
                  removeFromCombo={props.removeFromCombo}
                  lock={props.lock === p.PlayerId}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ComboSelectedList;
