import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { custom_series_types } from "../util/scarcities";
import { seasons_objects } from "../util/seasons";
import { sell_status_objects } from "../util/sellStatus";
import { Button } from "../util/button";
import { findPriceForUserUnit, formatPrice } from "../util/formatMoney";
import { getValuationOrigin } from "../util/valuationOrigin";
import CardLayout from "../cards/cardLayout";
import SerialNumberFilter from "../util/serialNumberFilter";
import PowerFilter from "../util/powerFilter";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import MultiScarcityPicker from "../util/multiScarcityPicker";
import scarcityChange from "../util/scarcityChange";
import So5UtilityFilter from "../util/so5UtilityFilter";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import Spinner from "../loader/spinner";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import BoxWithToggle from "../util/boxWithToggle";

const limitAPI = 50;
const tag_filters = [
  { name: "Not for sale", value: "NOT_SALE" },
  { name: "Fixed price", value: "FIXED" },
  { name: "Dynamic", value: "DYN" },
];

function PlayerCards(props) {
  const [cardsSupply, setCardsSupply] = useState({
    byScarcity: undefined,
    total: undefined,
  });
  const [cards, setCards] = useState({ list: [], hasNext: false, offset: 0 });
  const [loadingCards, setLoadingCards] = useState(false);
  const [scarcity, setScarcity] = useState(["LIMITED", "RARE", "SUPER RARE", "UNIQUE"]);
  const [season, setSeason] = useState([]);
  const [status, setStatus] = useState([]);
  const [specialEditions, setSpecialEditions] = useState(false);
  const [serialFilter, setSerialFilter] = useState([1, 1000]);
  const [powerRange, setPowerRange] = useState([0.0, 55.0]);
  const [so5UtilityRange, setSO5UtilityRange] = useState([]);
  const [so5UtilityPlayed, setSO5UtilityPlayed] = useState([]);
  const [so5UtilityStarted, setSO5UtilityStarted] = useState([]);
  const [so5DisplaySetting, setSO5DisplaySetting] = useState("eligible");
  const [customSeriesType, setCustomSeriesType] = useState("all");
  const [games, setGames] = useState([]);
  const [tagFilter, setTagFilter] = useState([]);
  const [onSale, setOnSale] = useState(false);

  useEffect(() => {
    getCards({});
  }, []);

  const handleSetCardsSupply = (resSupply) => {
    const limitedSupply = scarcity.indexOf("LIMITED") >= 0 ? resSupply.playables["LIMITED"] || 0 : undefined;
    const rareSupply = scarcity.indexOf("RARE") >= 0 ? resSupply.playables["RARE"] || 0 : undefined;
    const superRareSupply = scarcity.indexOf("SUPER RARE") >= 0 ? resSupply.playables["SUPER RARE"] || 0 : undefined;
    const uniqueSupply = scarcity.indexOf("UNIQUE") >= 0 ? resSupply.playables["UNIQUE"] || 0 : undefined;
    const customSeriesSupply =
      scarcity.indexOf("CUSTOM SERIES") >= 0
        ? resSupply.collectibles["CUSTOM SERIES"]
          ? Object.keys(resSupply.collectibles["CUSTOM SERIES"]).reduce((accumulator, val) => {
              return accumulator + resSupply.collectibles["CUSTOM SERIES"][val];
            }, 0)
          : 0
        : undefined;
    const cardsSupply = {
      byScarcity: {},
      total: undefined,
    };
    if (limitedSupply !== undefined) cardsSupply.byScarcity["LIMITED"] = limitedSupply;
    if (rareSupply !== undefined) cardsSupply.byScarcity["RARE"] = rareSupply;
    if (superRareSupply !== undefined) cardsSupply.byScarcity["SUPER RARE"] = superRareSupply;
    if (uniqueSupply !== undefined) cardsSupply.byScarcity["UNIQUE"] = uniqueSupply;
    if (customSeriesSupply !== undefined) cardsSupply.byScarcity["CUSTOM SERIES"] = customSeriesSupply;
    const sumSupply = [rareSupply, superRareSupply, limitedSupply, uniqueSupply, customSeriesSupply]
      .filter((v) => v !== undefined)
      .reduce((accumulator, val) => {
        return accumulator + val;
      }, 0);
    cardsSupply.total = sumSupply;
    setCardsSupply(cardsSupply);
  };

  const getCards = ({ v, s, p, so5, pl, st, o }) => {
    let sp = specialEditions;
    if (s !== undefined) sp = s;
    let sr = serialFilter;
    if (v) sr = v;
    let pr = powerRange;
    if (p) pr = p;
    let so5Utility = so5 || so5UtilityRange;
    let so5Played = pl || so5UtilityPlayed;
    let so5Started = st || so5UtilityStarted;
    if (o === undefined) o = cards.offset;
    setLoadingCards(true);
    props
      .fetch("/apiv2/players/cards/v2", {
        method: "POST",
        headers: withJSONPagination(o, limitAPI),
        body: JSON.stringify({
          scarcities: scarcity,
          playerId: props.player.PlayerId || props.match?.params?.playerId,
          status: status,
          seasons: season,
          special_only: sp,
          serial_filter: sr,
          power_range: pr,
          so5_utility: so5Utility,
          so5_utility_played: so5Played,
          so5_utility_started: so5Started,
          custom_series_type: customSeriesType,
          tag_filter: tagFilter,
          on_sale: onSale,
        }),
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        const resCards = res.cards || [];
        const newCards = {
          list: o === 0 ? resCards : [...cards.list, ...resCards],
          hasNext: hasNext,
          offset: o + limitAPI,
        };
        setCards(newCards);
        setGames(res.games || []);
        handleSetCardsSupply(res.supply);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher())
      .finally(() => setLoadingCards(false));
  };

  const changeSerialOptions = async (v, s) => {
    setSerialFilter(v);
    setSpecialEditions(s);
  };

  const changePowerOptions = async (p) => {
    setPowerRange(p);
  };

  const changeSO5UtilityFilter = async (r, pl, st, ds) => {
    setSO5UtilityRange(r);
    setSO5UtilityPlayed(pl);
    setSO5UtilityStarted(st);
    setSO5DisplaySetting(ds);
  };

  let limitSerial = 1000;
  let limitPower = 50;
  if (scarcity.length > 0) {
    if (scarcity.indexOf("UNIQUE") >= 0) {
      limitPower = 50.0;
    } else if (scarcity.indexOf("SUPER RARE") >= 0) {
      limitPower = 40.0;
    } else if (scarcity.indexOf("RARE") >= 0 || scarcity.indexOf("LIMITED") >= 0) {
      limitPower = 20.0;
    }
  }
  if (scarcity.length > 0) {
    if (scarcity.indexOf("LIMITED") >= 0) {
      limitSerial = 1000;
    } else if (scarcity.indexOf("RARE") >= 0) {
      limitSerial = 100;
    } else if (scarcity.indexOf("SUPER RARE") >= 0) {
      limitSerial = 10;
    } else if (scarcity.indexOf("UNIQUE") >= 0) {
      limitSerial = 1;
    }
  }

  const changeScarcity = (s) => {
    let newScarcities = scarcityChange(s, scarcity);
    setScarcity(newScarcities);
  };

  return (
    <div>
      <div className={"space-y-4"}>
        <div>
          <h2 className={"text-center"}>
            All {cardsSupply.total !== undefined ? cardsSupply.total + " " : ""}
            cards available of {props.player?.DisplayName}
          </h2>
          <div className={""}>
            <p className={"text-base font-medium text-center flex justify-center"}>
              {cardsSupply.byScarcity === undefined ? (
                <div role="status" className="animate-pulse flex flex-col w-1/2">
                  <div className="h-5 bg-grey-e0 rounded-lg dark:bg-grey-e9 w-full"></div>
                </div>
              ) : (
                Object.entries(cardsSupply.byScarcity)
                  .map(([s, supply], i) => {
                    return (
                      <span key={i} className={`text-${s.toLowerCase().replaceAll(" ", "-")}`}>
                        {supply} {s.toLowerCase()} cards
                      </span>
                    );
                  })
                  .reduce((acc, cur, currentIndex) => {
                    const isLast =
                      currentIndex === Object.entries(cardsSupply.byScarcity).length - 2; /* last - 1 because we get rid of last one */
                    const interElement = isLast ? <span>&nbsp;{"and"}&nbsp;</span> : <span>{","}&nbsp;</span>;
                    return acc.concat([cur, interElement]);
                  }, [])
                  .slice(0, -1)
              )}
            </p>
          </div>
        </div>
        <div className={"flex flex-col items-center space-y-2"}>
          <div className={"grid grid-cols-3 gap-4 xl:gap-0 xl:flex xl:flex-row space-x-2 justify-center"}>
            <div className={"self-center"}>
              <MultiScarcityPicker customSeries={true} scarcity={scarcity} onChange={(s) => changeScarcity(s)} />
            </div>
            {scarcity.indexOf("CUSTOM SERIES") > -1 && (
              <div className={"self-center"}>
                <SelectSearch
                  options={custom_series_types}
                  value={customSeriesType}
                  multiple={false}
                  closeOnSelect={true}
                  printOptions={"on-focus"}
                  filterOptions={fuzzySearch}
                  placeholder="Filter by custom series type"
                  onChange={(v) => {
                    setCustomSeriesType(v);
                  }}
                />
              </div>
            )}
            <div className={"self-center"}>
              <SelectSearch
                options={seasons_objects}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                placeholder="Select one season or more"
                multiple={true}
                value={season}
                closeOnSelect={false}
                onChange={(v) => setSeason(v)}
              />
            </div>
            <div className={"self-center"}>
              <SelectSearch
                options={sell_status_objects}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                placeholder="Select one status or more"
                multiple={true}
                value={status}
                closeOnSelect={false}
                onChange={(v) => setStatus(v)}
              />
            </div>
            <div className={"self-center"}>
              <SelectSearch
                options={tag_filters}
                value={tagFilter}
                multiple={true}
                placeholder={"Filter by market status"}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                closeOnSelect={false}
                onChange={(v) => setTagFilter(v)}
              />
            </div>
          </div>
          <div className={"flex flex-row justify-center space-x-4"}>
            <div className={"grid grid-cols-4 space-x-2"}>
              <div className={"self-center py-1.5 rounded bg-white"}>
                <BoxWithToggle label={"On sale on Sorare"} isActive={onSale} setActive={() => setOnSale(!onSale)} />
              </div>
              <div className={"self-center"}>
                <So5UtilityFilter powerOptions={(v, w, x, ds) => changeSO5UtilityFilter(v, w, x, ds)} limit={100} />
              </div>
              <div className={"self-center"}>
                <SerialNumberFilter serialOptions={(v, s) => changeSerialOptions(v, s)} limit={limitSerial} />
              </div>
              <div className={"self-center"}>
                <PowerFilter powerOptions={(v) => changePowerOptions(v)} limit={limitPower} />
              </div>
            </div>

            <div className={"relative flex flex-row text-center self-center"}>
              <Button
                label={"Filter"}
                onClick={() => {
                  getCards({ o: 0 });
                }}
              />
              <div className={"absolute flex flex-row transform -right-10 translate-y-1/2 justify-center self-center"}>
                {loadingCards && <Spinner className={"transform -translate-y-1/2"} />}
              </div>
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-center"}>
          <InfiniteScroll
            next={() => {
              getCards({});
            }}
            hasMore={cards.hasNext}
            loader={<SDLoading />}
            dataLength={cards.list.length}
            height={props.infiniteScrollHeight ? props.infiniteScrollHeight : undefined}
          >
            <div
              className={
                "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-8 gap-4"
              }
            >
              {cards?.list?.map((card) => {
                let c = card.card;
                let description = "";
                let ethCurrentPrice = "";
                let lastPrice = c.LastPrice || card.card_last_move?.details.price;
                if (lastPrice > 0) {
                  description = findPriceForUserUnit(lastPrice, card.card_last_move?.details, props.user.preferredUnit);
                  ethCurrentPrice = formatPrice(lastPrice, "eth");
                } else if (c.LastStatus === "Reward") {
                  description = "Reward";
                } else if (c.LastStatus === "Sorare") {
                  description = "Sorare";
                } else if (c.LastStatus === "Trade") {
                  description = "Trade";
                } else if (c.LastStatus === "Bundle") {
                  description = "Bundle";
                } else {
                  description = c.LastStatus;
                }
                let rahPrice = formatRealTimeAverage(card.r30, props.user.preferredUnit);
                let bmp = formatBestMarketPrice(card.bmp, props.user.preferredUnit);
                let valuationOrigin = card.r30.ValuationOrigin;
                if (props.user.preferredUnit !== "eth") {
                  valuationOrigin = card.r30.ValuationOriginFiat;
                }
                let stats = card.card_stats;

                let dateToDisplay = new Date(card.offer.offer.EndingAt);
                let ethPrice = formatPrice(card.offer.offer.ReceiveAmountInWei, "eth");
                let price = formatPrice(card.offer.offer.ReceiveAmountInWei, "eth");
                if (props.user.preferredUnit !== "eth") {
                  price = formatPrice(
                    card.offer.offer.ReceiveAmountInWei * card.price_info[props.user.preferredUnit],
                    props.user.preferredUnit,
                  );
                }
                return (
                  <div
                    key={c.TokenId}
                    onClick={() => {
                      if (props.onClick) {
                        props.onClick(c.TokenId);
                      }
                    }}
                  >
                    <>
                      {card.offer.offer.OfferId !== "" ? (
                        <CardLayout
                          imageLink={props.onClick ? undefined : "/card/" + c.TokenId}
                          picture={c.PictureUrl}
                          monthPrice={rahPrice}
                          marketPrice={bmp}
                          hover={props.hover}
                          onSale={true}
                          ethCurrentPrice={ethPrice}
                          currentPrice={price}
                          valuationOrigin={getValuationOrigin(valuationOrigin)}
                          avgPrice={card.owner}
                          date={dateToDisplay}
                          countdown={true}
                          card={c}
                          so5Utility={card.player.Sport === sorareFootball && stats}
                          rt={card.r30}
                          bmp={card.bmp}
                          so5Display={so5DisplaySetting}
                          so5Use={card.inSO5Use}
                          player={card.player}
                          tag={card.card_tag}
                          offer={card.offer}
                          centerLink={
                            card.player.Sport === sorareBasketball
                              ? "https://sorare.com/nba/cards/" + card.card.Slug
                              : card.player.Sport === sorareBaseball
                              ? "https://sorare.com/mlb/cards/" + card.card.Slug
                              : "https://sorare.com/football/cards/" +
                                card.card.Slug +
                                "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1"
                          }
                          sport={card.player.Sport}
                          l5={card.avg5}
                          l20={card.avg20}
                          gms5={card.l5}
                          gms20={card.l20}
                          availability={card.player_status}
                          gwGames={games}
                        />
                      ) : (
                        <CardLayout
                          imageLink={props.onClick ? undefined : "/card/" + c.TokenId}
                          picture={c.PictureUrl}
                          monthPrice={rahPrice}
                          marketPrice={bmp}
                          hover={props.hover}
                          valuationOrigin={getValuationOrigin(valuationOrigin)}
                          ethCurrentPrice={ethCurrentPrice}
                          currentPrice={description}
                          date={false}
                          avgPrice={card.owner}
                          countdown={false}
                          card={c}
                          so5Utility={card.player.Sport === sorareFootball && stats}
                          rt={card.r30}
                          bmp={card.bmp}
                          so5Display={so5DisplaySetting}
                          so5Use={card.inSO5Use}
                          player={card.player}
                          tag={card.card_tag}
                          offer={card.offer}
                          centerLink={
                            card.player.Sport === sorareBasketball
                              ? "https://sorare.com/nba/cards/" + card.card.Slug
                              : card.player.Sport === sorareBaseball
                              ? "https://sorare.com/mlb/cards/" + card.card.Slug
                              : "https://sorare.com/football/cards/" +
                                card.card.Slug +
                                "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1"
                          }
                          sport={card.player.Sport}
                          l5={card.avg5}
                          l20={card.avg20}
                          gms5={card.l5}
                          gms20={card.l20}
                          availability={card.player_status}
                          gwGames={games}
                        />
                      )}
                    </>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(PlayerCards);
