export const not_all_basketball_clubs_objects = [
  { value: "atlanta-hawks", name: "Atlanta Hawks" },
  { value: "boston-celtics", name: "Boston Celtics" },
  { value: "brooklyn-nets", name: "Brooklyn Nets" },
  { value: "charlotte-hornets", name: "Charlotte Hornets" },
  { value: "chicago-bulls", name: "Chicago Bulls" },
  { value: "cleveland-cavaliers", name: "Cleveland Cavaliers" },
  { value: "dallas-mavericks", name: "Dallas Mavericks" },
  { value: "denver-nuggets", name: "Denver Nuggets" },
  { value: "detroit-pistons", name: "Detroit Pistons" },
  { value: "golden-state-warriors", name: "Golden State Warriors" },
  { value: "houston-rockets", name: "Houston Rockets" },
  { value: "indiana-pacers", name: "Indiana Pacers" },
  { value: "los-angeles-clippers", name: "Los Angeles Clippers" },
  { value: "los-angeles-lakers", name: "Los Angeles Lakers" },
  { value: "memphis-grizzlies", name: "Memphis Grizzlies" },
  { value: "miami-heat", name: "Miami Heat" },
  { value: "milwaukee-bucks", name: "Milwaukee Bucks" },
  { value: "minnesota-timberwolves", name: "Minnesota Timberwolves" },
  { value: "new-orleans-pelicans", name: "New Orleans Pelicans" },
  { value: "new-york-knicks", name: "New York Knicks" },
  { value: "oklahoma-city-thunder", name: "Oklahoma City Thunder" },
  { value: "orlando-magic", name: "Orlando Magic" },
  { value: "philadelphia-76ers", name: "Philadelphia 76ers" },
  { value: "phoenix-suns", name: "Phoenix Suns" },
  { value: "portland-trail-blazers", name: "Portland Trail Blazers" },
  { value: "sacramento-kings", name: "Sacramento Kings" },
  { value: "san-antonio-spurs", name: "San Antonio Spurs" },
  { value: "toronto-raptors", name: "Toronto Raptors" },
  { value: "utah-jazz", name: "Utah Jazz" },
  { value: "washington-wizards", name: "Washington Wizards" },
];

export const basketball_clubs_objects = [{ value: "all", name: "All clubs" }, ...not_all_basketball_clubs_objects];
