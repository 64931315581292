import React from "react";
import GridBrand from "../../img/grid-view-brand.svg";
import GridGrey from "../../img/grid-view-grey.svg";
import ListBrand from "../../img/list-view-brand.svg";
import ListGrey from "../../img/list-view-grey.svg";

function ListViewPicker(props) {
  const mode = props.mode;
  return (
    <div className={"flex flex-row space-x-4 self-center"}>
      <div className={"bg-white rounded-lg p-2 cursor-pointer shadow-sm"} onClick={() => props.change(false)}>
        <div className={"self-center w-8 h-8"}>
          <img className={"w-full h-full object-contain"} src={mode === false ? GridBrand : GridGrey} />
        </div>
      </div>
      <div className={"bg-white rounded-lg p-2 cursor-pointer shadow-sm"} onClick={() => props.change(true)}>
        <div className={"self-center w-8 h-8"}>
          <img className={"w-full h-full object-contain"} src={mode === true ? ListBrand : ListGrey} />
        </div>
      </div>
    </div>
  );
}

export default ListViewPicker;
