import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import BoxWithToggle from "../util/boxWithToggle";
import { errorCatcher } from "../util/errors";

function CardsPreference(props) {
  const [hideCommonCards, setHideCommonCards] = useState(props.user.hideCommonCards || false);
  const [hideIneligibleCards, setHideIneligibleCards] = useState(props.user.hideIneligibleCards || false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (props.isConnected()) {
      setHideCommonCards(props.user.hideCommonCards);
      setHideIneligibleCards(props.user.hideIneligibleCards);
    }
  }, [props.user]);

  const changeHideCommonCards = (c) => {
    setMsg("");
    props
      .fetch("/apiv2/user/changeHideCommonCards", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: c,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          setMsg("Cards preference change failed. Please try again later.");
        } else {
          props.changeHideCommonCards(c);
          setMsg("Cards preference changed.");
        }
      })
      .catch(errorCatcher());
  };

  const changeHideIneligibleCards = (c) => {
    setMsg("");
    props
      .fetch("/apiv2/user/changeHideIneligibleCards", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: c,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          setMsg("Cards preference change failed. Please try again later.");
        } else {
          props.changeHideIneligibleCards(c);
          setMsg("Cards preference changed.");
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"space-y-2"}>
      <p className={"text-left uppercase text-sm text-on-surface-variant font-semibold"}>Common/ineligible cards preference</p>
      <div className={"flex flex-col space-y-4"}>
        <p className={"text-sm font-medium"}>
          Choose whether you want or not to have those scarcities displayed in your gallery and Gameweek Center.
        </p>
      </div>
      <div className={"flex flex-col space-y-2"}>
        <div className={"bg-surface-container rounded-lg py-1.5 w-6/12"}>
          <BoxWithToggle
            label={"Hide ineligible SO5 cards (custom series)"}
            isActive={hideIneligibleCards}
            setActive={() => changeHideIneligibleCards(!hideIneligibleCards)}
          />
        </div>
        <div className={"bg-surface-container rounded-lg py-1.5 w-6/12"}>
          <BoxWithToggle
            label={"Hide common draft cards"}
            isActive={hideCommonCards}
            setActive={() => changeHideCommonCards(!hideCommonCards)}
          />
        </div>
      </div>

      {msg && <p className={"text-center font-semibold text-sm"}>{msg}</p>}
    </div>
  );
}

export default withUser(CardsPreference);
