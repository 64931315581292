import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { format } from "date-fns";
import TeamLineup from "./teamLineup";
import ShowLineup from "../../img/show.svg";
import UnknownTeam from "../../img/unknown_club.png";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { sorareFootball } from "../util/sports";

function TeamFormations(props) {
  const [formationsMap, setFormationsMap] = useState([]);
  const [formations, setFormations] = useState([]);
  const [formation, setFormation] = useState("");
  const [games, setGames] = useState([]);
  const [gameDetails, setGameDetails] = useState({
    lineup: {},
    game: {},
    home_team: {},
    away_team: {},
  });

  useEffect(() => {
    getTeamFormations();
  }, []);

  useEffect(() => {
    if (formationsMap[formation] !== undefined) {
      setGames(formationsMap[formation]);
      getTeamLineup(formationsMap[formation][0].game.GameId);
    }
  }, [formation]);

  const getTeamFormations = () => {
    props
      .fetch("/apiv2/teams/formations/" + props.teamId)
      .then((response) => response.json())
      .then((res) => {
        setFormationsMap(res.formation_map);
        setFormations(res.formations);
        setFormation(res.formations[0]);
      })
      .catch(errorCatcher());
  };

  const getTeamLineup = (gameId) => {
    props
      .fetch("/apiv2/teams/lineup/" + gameId + "/" + props.teamId)
      .then((response) => response.json())
      .then((res) => {
        setGameDetails(res);
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"flex lg:flex-row flex-col space-y-8 lg:space-x-8 lg:max-w-full"}>
      <div className={"w-full lg:w-6/12 space-y-4"}>
        <div className={"w-4/12 z-20"}>
          <div className={"z-20"}>
            <SelectSearch
              className={"select-search z-20"}
              printOptions={"on-focus"}
              value={formation}
              closeOnSelect={true}
              options={formations.map((f) => {
                return {
                  name: f + " (" + formationsMap[f].length + ")",
                  value: f,
                };
              })}
              onChange={(v) => setFormation(v)}
            />
          </div>
        </div>
        {gameDetails.game.GameId !== undefined && (
          <div>
            <TeamLineup sport={sorareFootball} lineup={gameDetails.lineup} game={gameDetails} />
          </div>
        )}
      </div>
      <div className={"w-full lg:w-6/12 space-y-4"}>
        <div className={"bg-brand-pastel text-brand font-semibold text-sm p-4 rounded-lg text-center"}>
          <p>Games where the formation was used</p>
        </div>
        <div>
          {games !== undefined &&
            games.map((g, i) => {
              let rounded = "";
              if (i === 0) {
                rounded += " rounded-t-lg";
              }
              if (i === games.length - 1) {
                rounded += " rounded-b-lg";
              }
              let isHome = props.teamId === g.game.HomeTeamId;
              let hasWon = true;
              let scoreTextColor = " text-win-text";
              let scoreBgColor = " bg-win-bg";
              let hasLost = false;
              if (isHome) {
                if (g.game.HomeGoals < g.game.AwayGoals) {
                  hasWon = false;
                  hasLost = true;
                }
              } else {
                if (g.game.HomeGoals > g.game.AwayGoals) {
                  hasWon = false;
                  hasLost = true;
                }
              }
              if (g.game.HomeGoals === g.game.AwayGoals) {
                hasWon = false;
                hasLost = false;
              }
              if (hasLost) {
                scoreTextColor = " text-loss-text";
                scoreBgColor = " bg-loss-bg";
              } else if (!hasLost && !hasWon) {
                scoreTextColor = " text-draw-text";
                scoreBgColor = " bg-draw-bg";
              }
              return (
                <div className={"px-4 py-4 flex flex-row justify-between bg-white border-b border-b-grey-e5" + rounded}>
                  <div className={"flex flex-col self-center justify-start w-2/12 space-y-0.5"}>
                    <div className={"p-1 bg-grey-e5 rounded-lg w-16"}>
                      <p className={"text-xs font-semibold text-textGrey3 text-center"}>{format(new Date(g.game.GameTime), "dd/MM/yy")}</p>
                    </div>
                    {formation === "All formations" && (
                      <div className={"w-16"}>
                        <p className={"text-xxs font-semibold text-center"}>{isHome ? g.game.FormationHome : g.game.FormationAway}</p>
                      </div>
                    )}
                  </div>
                  <div className={"flex flex-row self-center space-x-4 justify-center w-8/12"}>
                    <div className={"flex flex-row space-x-4 self-center justify-end w-5/12"}>
                      <p className={"self-center font-semibold text-sm"}>{g.home_team.ShortName}</p>
                      <div className={"w-6 h-6 self-center"}>
                        <img
                          className={"w-full h-full object-contain self-center"}
                          src={g.home_team.PictureUrl !== "" ? g.home_team.PictureUrl : UnknownTeam}
                        />
                      </div>
                    </div>

                    <a href={"/game/" + g.game.GameId}>
                      <div
                        className={
                          "flex flex-row p-1 rounded-lg space-x-1 self-center justify-center text-sm font-semibold w-14" +
                          scoreTextColor +
                          scoreBgColor
                        }
                      >
                        <p>{g.game.HomeGoals}</p>
                        <p>-</p>
                        <p>{g.game.AwayGoals}</p>
                      </div>
                    </a>

                    <div className={"flex flex-row space-x-4 self-center justify-start w-5/12"}>
                      <div className={"w-6 h-6 self-center"}>
                        <img
                          className={"w-full h-full object-contain self-center"}
                          src={g.away_team.PictureUrl !== "" ? g.away_team.PictureUrl : UnknownTeam}
                        />
                      </div>
                      <p className={"self-center font-semibold text-sm"}>{g.away_team.ShortName}</p>
                    </div>
                  </div>
                  <div className={"flex flex-row self-center justify-end w-3/12 space-x-4"}>
                    <div className={"text-xs font-semibold text-textGrey3 w-10/12"}>{g.game.CompDisplayName}</div>
                    {g.game.GameId !== gameDetails.game.GameId && (
                      <div onClick={() => getTeamLineup(g.game.GameId)} className={"cursor-pointer flex flex-row self-center w-2/12"}>
                        <img src={ShowLineup} />
                      </div>
                    )}
                    {g.game.GameId === gameDetails.game.GameId && <div className={"cursor-pointer flex flex-row self-center w-2/12"}></div>}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default withUser(TeamFormations);
