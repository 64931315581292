import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import CurrencyPicker from "../util/newCurrencyPicker";
import { errorCatcher } from "../util/errors";
import { ethUnit, fiatUnits, noneUnit } from "../util/units";
import { isFiat } from "../util/formatMoney";
import ChooseScarcity from "./chooseScarcity";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import PlayerPriceEntry from "../players/PlayerPriceEntry";
import { RosterValuation } from "../manager/managerPage";

export const ChooseCurrencyAndScarcity = withUser((props) => {
  const [currencyValue, setCurrencyValue] = useState(props.user.preferredUnit);
  const [secondaryCurrencyValue, setSecondaryCurrencyValue] = useState(props.user.secondUnit);
  const [msgPrimary, setMsgPrimary] = useState("");
  const [msgSecondary, setMsgSecondary] = useState("");

  useEffect(() => {
    setCurrencyValue(props.user.preferredUnit);
  }, [props.user.preferredUnit]);

  useEffect(() => {
    setSecondaryCurrencyValue(props.user.secondUnit);
  }, [props.user.secondUnit]);

  const changeCurrency = (c, s) => {
    props
      .fetch("/apiv2/user/changeCurrency", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: c,
          secondary: s,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          changeMessage("Currency change failed. Please try again later.");
        } else {
          props.changeCurrency(res.currency);
          props.changeSecondUnit(res.secondary);
          if (currencyValue !== res.currency) {
            setCurrencyValue(res.currency);
            changeMessage("Primary currency changed.");
          }
          if (secondaryCurrencyValue !== res.secondary) {
            setSecondaryCurrencyValue(res.secondary);
            changeSecondaryMessage("Secondary currency changed.");
          }
        }
      })
      .catch(errorCatcher());
  };

  const changeMessage = (msg) => {
    setMsgPrimary(msg);
    setTimeout(() => {
      setMsgPrimary("");
    }, 2000);
  };

  const changeSecondaryMessage = (msg) => {
    setMsgSecondary(msg);
    setTimeout(() => {
      setMsgSecondary("");
    }, 2000);
  };

  const secondaryUnits = {
    ...noneUnit,
    ...(isFiat(currencyValue) ? ethUnit : fiatUnits),
  };

  return (
    <div className={"mx-6 w-4/6"}>
      <div className={"mt-8 space-y-4"}>
        <p className={"text-xl text-primary font-headers font-semibold"}>Scarcity & Currency</p>
        <div className={"flex flex-row gap-5"}>
          <div className={"w-10/12"}>
            <div className={"space-y-8"}>
              {isFree(props.user.tier) && (
                <UpgradeLimitBox
                  className={"bg-white bg-upgrade-bg-scarcity-fiat bg-cover h-upgrade-banner-m w-full mr-8"}
                  title={"Want personalization?"}
                  description={"Become a Star member to unlock scarcity personalization."}
                  button={"Upgrade"}
                />
              )}
              <ChooseScarcity />
              <div className={"flex flex-col items-start space-y-2"}>
                <p className={"text-center uppercase text-sm  text-on-surface-variant font-semibold"}>Currency preference</p>
                <div className={"flex flex-col space-y-4"}>
                  <p className={"text-sm font-medium"}>This currency will be used when prices are displayed on the platform.</p>
                  <div className={"flex flex-row gap-2 items-center"}>
                    <div className={"items-start w-full md:w-80"}>
                      <CurrencyPicker
                        currency={currencyValue}
                        onChange={(c) => {
                          changeCurrency(c, secondaryCurrencyValue);
                        }}
                      />
                    </div>
                    {msgPrimary !== "" && <p className={"text-sm text-center font-semibold"}>{msgPrimary}</p>}
                  </div>
                </div>
              </div>
              <div className={"flex flex-col items-start space-y-2"}>
                <p className={"text-center uppercase text-sm text-on-surface-variant font-semibold"}>Second currency preference</p>
                <div className={"flex flex-col space-y-4"}>
                  <p className={"text-sm font-medium"}>
                    This currency will be used as a secondary currency on player pages and card moves.
                  </p>
                  <div className={"flex flex-row gap-2 items-center"}>
                    <div className={"items-start w-full md:w-80"}>
                      <CurrencyPicker
                        currency={secondaryCurrencyValue}
                        units={secondaryUnits}
                        onChange={(c) => {
                          changeCurrency(currencyValue, c);
                        }}
                      />
                    </div>
                    {msgSecondary !== "" && <p className={"text-sm text-center font-semibold"}>{msgSecondary}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"flex justify-center items-center"}>
            <div className={"bg-surface-container-highest rounded-lg flex flex-col gap-2 py-3 px-4 w-80"}>
              <h3 className={"text-center text-on-surface-variant text-sm m-0"}>Examples</h3>
              <PlayerPriceEntry {...examplePriceData} primaryUnit={currencyValue} secondaryUnit={secondaryCurrencyValue} />
              <RosterValuation
                info={rosterExample}
                loading={false}
                primaryUnit={currencyValue}
                secondaryUnit={secondaryCurrencyValue}
                manager={props.user.sorareSlug}
                hideValuations={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const examplePriceData = {
  scarcity: "LIMITED",
  precision: 4,
  color: "#ecbc0c",
  bestPrice: {
    PlayerId: "115502783806294754635756980129263996521966392394151697039885723299742156540155",
    Scarcity: "LIMITED",
    Price: 0.0998,
    PriceUsd: 186.0821,
    PriceEur: 168.6111,
    PriceGbp: 145.1082,
  },
  supplyAndAverage: {
    card_supply: {
      player_id: "115502783806294754635756980129263996521966392394151697039885723299742156540155",
      scarcity: "LIMITED",
      season: 0,
      count: 444,
    },
    average: [
      {
        PlayerId: "115502783806294754635756980129263996521966392394151697039885723299742156540155",
        Scarcity: "LIMITED",
        Period: 3,
        Average: 0.0987,
        UsdAverage: 183.26,
        EurAverage: 165.35,
        GbpAverage: 142.71,
        ValuationOrigin: "",
        ValuationOriginFiat: "",
        details: null,
      },
      {
        PlayerId: "115502783806294754635756980129263996521966392394151697039885723299742156540155",
        Scarcity: "LIMITED",
        Period: 7,
        Average: 0.0946,
        UsdAverage: 178.35,
        EurAverage: 160.15,
        GbpAverage: 138.43,
        ValuationOrigin: "",
        ValuationOriginFiat: "",
        details: null,
      },
      {
        PlayerId: "115502783806294754635756980129263996521966392394151697039885723299742156540155",
        Scarcity: "LIMITED",
        Period: 14,
        Average: 0.1014,
        UsdAverage: 193.32,
        EurAverage: 173.26,
        GbpAverage: 149.04,
        ValuationOrigin: "",
        ValuationOriginFiat: "",
        details: null,
      },
      {
        PlayerId: "115502783806294754635756980129263996521966392394151697039885723299742156540155",
        Scarcity: "LIMITED",
        Period: 30,
        Average: 0.1062,
        UsdAverage: 201.71,
        EurAverage: 182.83,
        GbpAverage: 156.99,
        ValuationOrigin: "",
        ValuationOriginFiat: "",
        details: null,
      },
    ],
  },
  valuation: {
    PlayerId: "115502783806294754635756980129263996521966392394151697039885723299742156540155",
    Scarcity: "LIMITED",
    Period: -1,
    Average: 0.0987,
    UsdAverage: 183.26,
    EurAverage: 165.35,
    GbpAverage: 142.71,
    ValuationOrigin: "3d",
    ValuationOriginFiat: "3d",
    details: null,
  },
  description: "Limited",
};

const rosterExample = {
  roster_valuation: {
    eth: 0.8789,
    usd: 1635.87,
    eur: 1477.71,
    gbp: 1272.85,
  },
};
