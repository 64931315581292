import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as FilterIcon } from "../../img/icons-filter.svg";
import { XIcon } from "@heroicons/react/outline";
import { ReactComponent as ResetIcon } from "../../img/icons-reset.svg";
import { AvailabilityStatusSelectorNew } from "../util/availabilityStatus";
import { playable_scarcities_object_not_all } from "../util/scarcities";
import { withUser } from "../../userContext";
import BoxWithToggle from "../util/boxWithToggle";
import LineupBuilderFootballLeaguePicker from "./footballLeaguePicker";
import { Dropdown } from "../util/dropdown";
import { CommunityPredictionFilters } from "../util/communityPrediction";
import { isEqual } from "lodash-es";
import { Button } from "../util/button";
import { RangeWithLabel } from "../util/range";
import { MobileModale } from "../util/mobileModale";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import { seasons_objects_with_all, seasons_types_objects_with_all } from "../util/seasons";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { leagues_no_div_objects, leagues_no_div_objects_not_all } from "../util/leagues";
import {
  allStarDefaultCompetition,
  EligibilitySorareCompetitionsFilter,
  initialEligibilityCompetitionValue,
} from "../competitions/eligibilityCompetitionsPicker";

export const defaultFootballLUBFilters = {
  // General
  showNGPlayers: false,
  scarcity: "all",
  position: "all",
  eligibilityCompetitions: initialEligibilityCompetitionValue,
  league: "all",
  availabilityStatus: "all",
  cardSeasonType: "",
  // Community projections
  communityProjections: {
    status: {
      enabled: {
        starter: false,
        doubtful: false,
        substitute: false,
        outOfSquad: false,
      },
      probability: [50, 100],
    },
    reliability: {
      low: false,
      medium: false,
      high: false,
    },
  },
  // Scores
  cost: [0, 100],
  l5: {
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  },
  l15: {
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  },
  l40: {
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  },
};

export const LUBFootballFilters = withUser((props) => {
  const { onOpenChange, onApplyFilters, division, pickedPosition, gw, draftId, targetUserId, preferences } = props;
  const divId = division?.division_id;
  const [filters, setFilters] = useState(defaultFootballLUBFilters);
  const [savedFilters, setSavedFilters] = useState(defaultFootballLUBFilters);
  const rules = division?.rules;
  const isCapped = rules?.isCapped !== undefined ? rules.isCapped : false;
  const showCost = preferences?.showCost;
  const open = props.open;
  const mdQueries = useTailwindMediaQueries();

  useEffect(() => {
    setFilters(defaultFootballLUBFilters);
    setSavedFilters(defaultFootballLUBFilters);
  }, [division]);

  const onReset = useCallback(() => {
    setFilters(defaultFootballLUBFilters);
  }, []);

  const hasChangesNotSaved = !isEqual(filters, savedFilters);
  const onFilter = () => {
    if (hasChangesNotSaved) {
      if (onApplyFilters) {
        onApplyFilters({ ...filters });
      }
      setSavedFilters({ ...filters });
    }
  };
  const nbFiltersSet = Object.entries(savedFilters)?.filter(([pref, val]) => !isEqual(val, defaultFootballLUBFilters[pref]))?.length || 0;
  const positionsOptions = useMemo(() => {
    const positions = [{ name: "All positions", value: "all" }];
    if (pickedPosition === "Extra") {
      positions.push(
        { value: "Defender", name: "Defender" },
        {
          value: "Midfielder",
          name: "Midfielder",
        },
        { value: "Forward", name: "Forward" },
      );
    } else {
      positions.push({ value: pickedPosition, name: pickedPosition });
    }
    return positions;
  }, [pickedPosition]);
  const scarcitiesOptions = [{ value: "all", name: "All scarcities" }];
  if (rules) {
    scarcitiesOptions.push(...playable_scarcities_object_not_all.filter((obj) => rules.allowedScarcities.includes(obj.value)));
  }

  const content = (
    <FootballFiltersPanel
      onReset={onReset}
      onOpenChange={onOpenChange}
      filters={filters}
      setFilters={setFilters}
      scarcitiesOptions={scarcitiesOptions}
      positionsOptions={positionsOptions}
      rules={rules}
      gw={gw}
      divId={divId}
      draftId={draftId}
      targetUserId={targetUserId}
      pickedPosition={pickedPosition}
      isCapped={isCapped}
      showCost={showCost}
      onFilter={onFilter}
      hasChangesNotSaved={hasChangesNotSaved}
      maxHeight={!mdQueries["md"] ? "70vh" : undefined}
    />
  );

  return (
    <div className={"relative h-full z-20"}>
      <button
        onClick={onOpenChange ? () => onOpenChange(!open) : undefined}
        className={`h-full text-md text-on-surface my-auto bg-surface-container px-3 py-2 rounded-3xl border ${
          open ? "shadow border-primary" : "border-surface-container"
        } flex flex-row items-center justify-between gap-2 hover:outline hover:outline-1 hover:outline-primary`}
      >
        {hasChangesNotSaved ? (
          <div className="flex flex-row my-auto h-5 w-5 justify-center">
            <span
              className={`text-xxs font-bold rounded-full flex justify-center items-center self-center ${
                open ? "bg-primary" : "bg-on-surface"
              }  w-2.5 h-2.5`}
              title={`Changes not saved`}
            ></span>
          </div>
        ) : nbFiltersSet === 0 ? (
          <FilterIcon className={`${open ? "fill-primary" : "fill-on-surface"} h-5 w-5`} />
        ) : (
          <div className="flex flex-row my-auto h-5 w-5 justify-center">
            <span
              className="text-xxs font-bold rounded-full flex justify-center items-center self-center text-on-primary bg-primary w-4 h-4"
              title={`${nbFiltersSet} filters activated`}
            >
              {nbFiltersSet}
            </span>
          </div>
        )}
        <div className={"my-auto"}>
          <span className={`text-left font-medium ${open || (!hasChangesNotSaved && nbFiltersSet > 0) ? "text-primary" : ""}`}>
            Filters
          </span>
        </div>
      </button>
      {open && (
        <div className={"hidden sm:block absolute bg-surface-container top-12 right-0 rounded-xl w-80 shadow-lg text-on-surface"}>
          {content}
        </div>
      )}
      <MobileModale open={!mdQueries["md"] && open} close={onOpenChange ? () => onOpenChange(!open) : undefined}>
        <div className={"bg-surface-container text-on-surface rounded-t-2xl text-left"}>{content}</div>
      </MobileModale>
    </div>
  );
});

const FootballFiltersPanel = withUser((props) => {
  const {
    onReset,
    onOpenChange,
    filters,
    setFilters,
    scarcitiesOptions,
    positionsOptions,
    isCapped,
    showCost,
    onFilter,
    hasChangesNotSaved,
  } = props;
  const maxHeight = props.maxHeight || "25em";
  return (
    <div>
      <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
        <div className={"flex flex-row justify-between px-4 py-4"}>
          <h3 className={"font-headers font-semibold m-0"}>Filters</h3>
          <div className={"flex flex-row gap-3"}>
            <ResetIcon className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface" onClick={onReset} />
            <XIcon
              className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
              aria-hidden="true"
              onClick={onOpenChange ? () => onOpenChange(false) : undefined}
            />
          </div>
        </div>
      </div>
      <div
        className={
          "flex flex-col gap-3 w-full px-4 py-4 border-b border-transparent-inverse-surface-low border-opacity-10 overflow-y-auto "
        }
        style={{ maxHeight: maxHeight }}
      >
        <div className={"flex flex-col gap-2"}>
          <h4 className={"m-0 mb-1 text-md font-semibold text-on-surface-variant"}>General</h4>
          <div className={"border border-transparent-inverse-surface-low border-opacity-10 rounded-md py-2"}>
            <BoxWithToggle
              padding="px-2"
              color="text-on-surface"
              label={"Show NG players"}
              isActive={filters.showNGPlayers}
              setActive={() =>
                setFilters({
                  ...filters,
                  showNGPlayers: !filters.showNGPlayers,
                })
              }
            />
          </div>
          {scarcitiesOptions.length > 2 && (
            <div className={"z-50"}>
              <Dropdown
                displayOptions={{ borders: true }}
                options={scarcitiesOptions}
                selected={filters.scarcity}
                onChange={(v) => setFilters({ ...filters, scarcity: v })}
              />
            </div>
          )}
          {positionsOptions.length > 2 && (
            <div className={"z-40"}>
              <Dropdown
                options={positionsOptions}
                displayOptions={{ borders: true }}
                selected={filters.position}
                onChange={(v) => setFilters({ ...filters, position: v })}
              />
            </div>
          )}
          <div className={"z-35"}>
            <EligibilitySorareCompetitionsFilter
              width={"w-full"}
              maxHeightPanel={"100em"}
              selected={filters.eligibilityCompetitions}
              onSelect={(v) => {
                let newLeagues = {};
                if (v === allStarDefaultCompetition.id) {
                  newLeagues = initialEligibilityCompetitionValue;
                } else {
                  newLeagues = { ...filters.eligibilityCompetitions, [v]: true };
                  delete newLeagues[allStarDefaultCompetition.id]; // makes no sense to have the 'all' selected while others specific ones are selected
                }
                setFilters({ ...filters, eligibilityCompetitions: newLeagues });
              }}
              onUnselect={(v) => {
                const newLeagues = { ...filters.eligibilityCompetitions };
                delete newLeagues[v];
                if (Object.keys(newLeagues).length === 0) {
                  newLeagues[allStarDefaultCompetition.id] = true;
                }
                setFilters({ ...filters, eligibilityCompetitions: newLeagues });
              }}
              displayOptions={{ borders: true }}
            />
          </div>
          <div className={"z-30"}>
            <Dropdown
              options={leagues_no_div_objects}
              selected={filters.league}
              multiple={false}
              onChange={(v) => {
                setFilters({ ...filters, league: v });
              }}
              displayOptions={{ borders: true }}
              placeholder="All leagues"
            />
          </div>
          <div className={"z-20"}>
            <Dropdown
              options={seasons_types_objects_with_all}
              selected={filters.cardSeasonType}
              onChange={(v) => setFilters({ ...filters, cardSeasonType: v })}
              placeholder={"All seasons cards"}
              displayOptions={{ borders: true }}
            />
          </div>
          <div className={"z-10"}>
            <AvailabilityStatusSelectorNew
              tier={props.user.tier}
              placeholder={"Injury status"}
              availabilityStatus={filters.availabilityStatus}
              onChange={(v) => setFilters({ ...filters, availabilityStatus: v })}
            />
          </div>
        </div>
        <div className={"flex flex-col gap-2"}>
          <h4 className={"m-0 mb-1 text-md font-semibold text-on-surface-variant"}>Community projections</h4>
          <CommunityPredictionFilters
            filters={filters.communityProjections}
            onChange={(newF) => {
              setFilters({
                ...filters,
                communityProjections: newF,
              });
            }}
          />
        </div>
        <div className={"flex flex-col gap-2"}>
          <h4 className={"m-0 mb-1 text-md font-semibold text-on-surface-variant"}>Scores</h4>
          <div className={"flex flex-col gap-2"}>
            {(isCapped || showCost) && (
              <RangeWithLabel label={"Cost (Sorare L15)"} range={filters.cost} onChange={(v) => setFilters({ ...filters, cost: v })} />
            )}
            <ScoreFilterBox
              gms={5}
              stats={filters.l5}
              onChange={(s) =>
                setFilters({
                  ...filters,
                  l5: s.enabled ? s : defaultFootballLUBFilters.l5,
                })
              }
            />
            <ScoreFilterBox
              gms={15}
              stats={filters.l15}
              onChange={(s) =>
                setFilters({
                  ...filters,
                  l15: s.enabled ? s : defaultFootballLUBFilters.l15,
                })
              }
            />
            <ScoreFilterBox
              gms={40}
              stats={filters.l40}
              onChange={(s) =>
                setFilters({
                  ...filters,
                  l40: s.enabled ? s : defaultFootballLUBFilters.l40,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className={"px-5 py-4"}>
        <Button
          onClick={onFilter}
          adapting
          label="Apply"
          disabled={!hasChangesNotSaved}
          title={hasChangesNotSaved ? "" : "No change to save"}
        />
      </div>
    </div>
  );
});

const ScoreFilterBox = (props) => {
  const { stats, gms, onChange } = props;
  const label = "L" + gms;
  return (
    <div className={"space-y-2"}>
      <div className={"border border-transparent-inverse-surface-low border-opacity-10 rounded-lg py-2"}>
        <BoxWithToggle label={label} isActive={stats.enabled} setActive={() => onChange({ ...stats, enabled: !stats.enabled })} />
      </div>
      {stats.enabled && (
        <div className={"space-y-2"}>
          <RangeWithLabel label={"Score average"} range={stats.scoreRange} onChange={(v) => onChange({ ...stats, scoreRange: v })} />
          <RangeWithLabel label={"Decisive score average"} range={stats.daRange} onChange={(v) => onChange({ ...stats, daRange: v })} />
          <RangeWithLabel
            label={"All-around score average"}
            min={-20}
            range={stats.aaRange}
            onChange={(v) => onChange({ ...stats, aaRange: v })}
          />
          <RangeWithLabel label={"% of games started"} range={stats.gmsStarted} onChange={(v) => onChange({ ...stats, gmsStarted: v })} />
          <RangeWithLabel label={"% of games played"} range={stats.gmsPlayed} onChange={(v) => onChange({ ...stats, gmsPlayed: v })} />
        </div>
      )}
    </div>
  );
};
