import React, { useState } from "react";
import AdvancedSearchHeader from "../../img/advanced-search-header.png";
import LineSeparator from "../../img/line-separator.svg";
import PlayersAdvancedSearch from "./playersAdvancedSearch";
import CardsAdvancedSearch from "./cardsAdvancedSearch";
import { useQuery } from "../util/useQuery";
import { sorareFootball } from "../util/sports";

function AdvancedSearch(props) {
  const [mode, setMode] = useState(new URLSearchParams(props.location.search).get("mode") || "players");

  const query = useQuery();
  const sport = query.get("sport") || sorareFootball;

  const activeTextClass = "font-semibold text-base text-brand";
  const inactiveTextClass = "font-semibold text-base text-textGrey4";
  return (
    <div className={"space-y-4"}>
      <div
        className={"w-full lg:h-40 h-32 relative opacity-100 overflow-hidden"}
        style={{
          backgroundImage: "url('" + AdvancedSearchHeader + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={"z-10 self-center flex mx-16 lg:h-40 h-32 align-middle flex-row justify-between overflow-hidden"}>
          <p className={"self-center text-4xl font-headers font-bold text-left text-white z-10"}>Advanced search</p>
          <div className={"flex flex-row bg-grey-f2 space-x-12 self-center rounded-lg shadow py-2 px-12"}>
            <div className={"cursor-pointer self-center text-center w-5/12"} onClick={() => setMode("players")}>
              <p className={mode === "players" ? activeTextClass : inactiveTextClass}>Players</p>
            </div>
            <div className={"self-center w-1 h-6"}>
              <img src={LineSeparator} />
            </div>
            <div className={"cursor-pointer self-center text-center w-5/12"} onClick={() => setMode("cards")}>
              <p className={mode === "cards" ? activeTextClass : inactiveTextClass}>Cards</p>
            </div>
          </div>
        </div>
      </div>
      <div className={"mx-16"}>
        {mode === "players" && (
          <div>
            <PlayersAdvancedSearch sport={sport} />
          </div>
        )}
        {mode === "cards" && (
          <div>
            <CardsAdvancedSearch sport={sport} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdvancedSearch;
