import React from "react";
import LoadingGrey from "../../img/logoSDGrey.gif";
import LoadingDarker from "../../img/loader-darker.gif";

function SDLoading(props) {
  let size = props.size || "w-20";
  return (
    <div className={size + " mx-auto"}>
      <img src={props.dark ? LoadingDarker : LoadingGrey} />
    </div>
  );
}

export default SDLoading;
