import React, { useState } from "react";
import PlayerSO5StatsLine from "./playerSO5StatsLine";
import { ReactTooltip } from "../util/tooltip.js";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";

function PlayerStatsLines(props) {
  const [sortWhat, setSortWhat] = useState("date");
  const [sortOrder, setSortOrder] = useState(1);

  const stats = props.stats;
  const sport = props.player.Sport;

  const sortData = (w) => {
    let howToSort = 0;
    if (w === sortWhat) {
      howToSort = sortOrder * -1;
    } else {
      howToSort = -1;
    }
    setSortOrder(howToSort);
    setSortWhat(w);
    let whatToSort = "SO5Score";
    if (w === "level") {
      whatToSort = "Level";
    } else if (w === "aa") {
      whatToSort = "AllAroundScore";
    }
    if (sport === sorareBaseball) {
      if (w === "so5") {
        whatToSort = "Score";
      }
    }
    if (w === "date") {
      if (howToSort === 1) {
        stats.sort(function (a, b) {
          if (new Date(a.game.GameTime) < new Date(b.game.GameTime)) {
            return -1;
          } else if (new Date(a.game.GameTime) > new Date(b.game.GameTime)) {
            return 1;
          }
          return 0;
        });
      } else {
        stats.sort(function (a, b) {
          if (new Date(a.game.GameTime) > new Date(b.game.GameTime)) {
            return -1;
          } else if (new Date(a.game.GameTime) < new Date(b.game.GameTime)) {
            return 1;
          }
          return 0;
        });
      }
    } else {
      if (howToSort === 1) {
        stats.sort(function (a, b) {
          if (parseFloat(a.stats[whatToSort]) < parseFloat(b.stats[whatToSort])) {
            return -1;
          } else if (parseFloat(a.stats[whatToSort]) > parseFloat(b.stats[whatToSort])) {
            return 1;
          }
          return 0;
        });
      } else {
        stats.sort(function (a, b) {
          if (parseFloat(a.stats[whatToSort]) > parseFloat(b.stats[whatToSort])) {
            return -1;
          } else if (parseFloat(a.stats[whatToSort]) < parseFloat(b.stats[whatToSort])) {
            return 1;
          }
          return 0;
        });
      }
    }
  };

  if (stats !== undefined && stats !== null) {
    return (
      <table
        className={
          "z-0 min-h-48 overflow-hidden border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"
        }
      >
        <thead>
          <tr>
            <th className={"w-2/12"} onClick={() => sortData("date")}>
              <span className={"hidden xl:block text-base font-medium self-center text-left hover:font-semibold cursor-pointer pl-2 py-2"}>
                Gameweek
              </span>
            </th>
            <th className={"w-5/12"}></th>
            {sport === sorareFootball && <th className={"w-2/12"}></th>}

            {(sport === sorareBaseball || sport === sorareBasketball) && <th className={"w-1/12"}></th>}
            {(sport === sorareBaseball || sport === sorareBasketball) && <th className={"w-1/12"}></th>}
            {(sport === sorareBaseball || sport === sorareBasketball) && <th className={"w-2/12"}></th>}
            {sport === sorareFootball && (
              <th className={"w-1/12"}>
                <span
                  className={"self-center text-sm md:text-base text-right md:text-center font-medium hover:font-semibold cursor-pointer"}
                  onClick={() => sortData("level")}
                >
                  Decisive
                </span>
              </th>
            )}
            {sport === sorareFootball && (
              <th className={"w-1/12"}>
                <span
                  className={"self-center text-sm md:text-base text-center font-medium hover:font-semibold cursor-pointer"}
                  onClick={() => sortData("aa")}
                >
                  All-around
                </span>
              </th>
            )}
            <th className={"w-1/12"}>
              <span
                className={"self-center text-sm md:text-base text-center font-medium hover:font-semibold cursor-pointer"}
                onClick={() => sortData("so5")}
              >
                Total
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {stats.map((s, i) => {
            return (
              <PlayerSO5StatsLine
                last={i === stats.length - 1}
                i={i}
                stat={s}
                position={props.position}
                newMatrix={true}
                player={props.player}
              />
            );
          })}
        </tbody>

        <ReactTooltip />
      </table>
    );
  } else {
    return null;
  }
}

export default PlayerStatsLines;
