import React from "react";
import { getScoreColors } from "./scales";
import { sorareFootball } from "./sports";

export const MedianScore = (props) => {
  const { score } = props;
  const sport = props.sport || sorareFootball;
  const [scoreBgColor, scoreTextColor] = getScoreColors(score, sport);
  return (
    <div
      style={{ backgroundColor: scoreBgColor, color: scoreTextColor }}
      className={"flex justify-center px-2 py-1 rounded-full text-md w-10 h-7 font-semibold items-center"}
    >
      <span>{score !== undefined && !isNaN(Number(score)) ? score : "-"}</span>
    </div>
  );
};
