import countries from "i18n-iso-countries";

export const getPlayerCountryInfo = (country) => {
  if (!country) return {};
  const countryFlags = {
    "gb-eng": { countryLabel: "England", countryFlag: "_england" },
    "gb-wls": { countryLabel: "Wales", countryFlag: "_wales" },
    "gb-sct": { countryLabel: "Scotland", countryFlag: "_scotland" },
    "gb-nir": { countryLabel: "Northern Ireland", countryFlag: "_northern_ireland" },
  };

  const defaultCountryFlag = country.toUpperCase();
  const flagData = countryFlags[country] || {
    countryLabel: countries.getName(country.toUpperCase(), "en"),
    countryFlag: defaultCountryFlag,
  };

  const { countryLabel, countryFlag } = flagData;

  return {
    countryLabel,
    countryFlag: `https://sorare.com/img/flags/flags-iso/round/64/${countryFlag}.png`,
  };
};
