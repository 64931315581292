import { SavedLineups } from "./savedLineups";
import React, { useCallback, useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import { sorareFootball } from "../util/sports";
import { withUser } from "../../userContext";
import Spinner from "../loader/spinner";
import { ReactTooltip } from "../util/tooltip";
import { ReactComponent as Group } from "@material-design-icons/svg/filled/group.svg";
import { ReactComponent as SorareIcon } from "../../img/icon-sorare.svg";
import { ReactComponent as WarningIcon } from "../../img/emergency.svg";
import { ReactComponent as InfoIcon } from "../../img/icons-circle-info-no-color.svg";
import { ReactComponent as CancelIcon } from "@material-design-icons/svg/outlined/cancel.svg";
import { ReactComponent as ErrorIcon } from "@material-design-icons/svg/round/error_outline.svg";
import { ReactComponent as CircleIcon } from "@material-design-icons/svg/round/check_circle_outline.svg";
import { getBackgroundGradientFromDivisionId } from "../util/divisions";
import { Tag } from "../util/tag";
import { DropdownDS } from "../util/dropdownDS";
import AbortController from "abort-controller";
import { Button } from "../util/button";
import { ModalDS, ModalDSTitleHeader } from "../util/modalDS";
import PlayerAvatar from "../util/playerAvatar";
import { getThemeColor, useTheme } from "../../themeContext";
import { sdDark } from "../../themeColors";
import { LineupStrength } from "./lineupStrength";
import { computeLineupStrength } from "../util/lineupStrength";
import { format } from "date-fns";
import { getNavigatorLang } from "../util/locale";
import { enGB, enUS, fr } from "date-fns/locale";
import { CompetitionMultiSelectMenu, getCompetitionOption } from "./competitionMultiSelect";
import { labelInvalidReasons, LineupInvalidReasonIncomplete } from "./lineupBuilderRules";
import { BetaBanner } from "../util/betaBanner";
import { useQuery } from "../util/useQuery";

const defaultCanExport = {
  canExport: false,
  cannotExportReason: "NOT_READY",
};

const groupByOptions = [
  { value: "", label: "No group by" },
  { value: "league", label: "Group by league" },
  { value: "status", label: "Group by lineup status" },
];

export const MySavedLineups = withUser((props) => {
  const { gw, targetUserId, draftId, draftName, dark } = props;

  const [loading, setLoading] = useState(false);
  const [oddsAndStats, setOddsAndStats] = useState({});
  const [competitions, setCompetitions] = useState(undefined);
  const [leagues, setLeagues] = useState(undefined);
  const [statuses, setStatuses] = useState(undefined);
  const [isCompetitionsLoaded, setIsCompetitionsLoaded] = useState(false);
  const [divisionMaxScores, setDivisionMaxScores] = useState({});
  const [divisionMaxScoresNoCaptain, setDivisionMaxScoresNoCaptain] = useState({});
  const [allCompetitions, setAllCompetitions] = useState([]);
  const [selectAllOption, setSelectAllOption] = useState({ id: "all", label: "All teams" });
  const [selectedCompIds, setSelectedCompIds] = useState([]);
  const [projections, setProjections] = useState({});
  const [abortController, setAbortController] = useState(new AbortController());
  const [canExport, setCanExport] = useState(defaultCanExport);
  const [exporting, setExporting] = useState(false);
  const [exportingForced, setExportingForced] = useState(false);
  const [exportRes, setExportRes] = useState(undefined);
  const query = useQuery();
  const sport = sorareFootball;

  const [preferences, setPreferences] = useState(getMyTeamsPreferencesPreferencesFromLocalStorage());
  useEffect(() => {
    setMyTeamsPreferencesPreferencesToLocalStorage(preferences);
  }, [preferences]);

  useEffect(() => {
    getGwOdds();
  }, [gw]);

  useEffect(() => {
    loadSavedLineupsAndCompetitions();
    canExportLineups();
    setExportRes(undefined);
  }, [draftId, gw, preferences]);

  useEffect(() => {
    canExportLineups();
  }, [exportRes]);

  useEffect(() => {
    const totalDraftLineups = allCompetitions.reduce((total, obj) => total + obj.currentNbEntries, 0);
    setSelectAllOption({
      ...selectAllOption,
      tagLabel: totalDraftLineups > 1 ? `${totalDraftLineups} lineups` : `${totalDraftLineups} lineup`,
    });
  }, [allCompetitions, selectedCompIds]);

  const loadSavedLineupsAndCompetitions = () => {
    if (draftId && sport && gw) {
      setLoading(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      const params = new URLSearchParams();
      params.append("sport", sport);
      params.append("gwNumber", gw);
      params.append("createdFor", targetUserId);
      params.append("withDivisionMaxScores", "true");
      if (preferences.groupBy) {
        params.append("groupBy", preferences.groupBy);
      }
      props
        .fetch(`/decision-api/decision/lub/drafts/id/${draftId}/lineups/v2?${params.toString()}`, { signal: newAbortController.signal })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setCompetitions(res.competitions || undefined);
            setLeagues(res.leagues || undefined);
            setStatuses(res.statuses || undefined);
            let allCompetitions = [];
            if (res.competitions) {
              allCompetitions = res.competitions.filter((c) => c.currentNbEntries > 0).map(getCompetitionOption);
            } else if (res.leagues) {
              allCompetitions = res.leagues.flatMap((l) => l.competitions?.map(getCompetitionOption) || []);
            } else if (res.statuses) {
              allCompetitions = res.statuses.flatMap((s) => s.competitions?.map(getCompetitionOption) || []);
              allCompetitions = [...new Map(allCompetitions.map((item) => [item.id, item])).values()];
            }
            setAllCompetitions(allCompetitions);
            setSelectedCompIds([selectAllOption.id, ...allCompetitions.map((c) => c.id)]);
            setProjections(res.projectedLineups);
            setDivisionMaxScores(res.divisionMaxScores);
            setDivisionMaxScoresNoCaptain(res.divisionMaxScoresNoCaptain);
          }
          setLoading(false);
          setIsCompetitionsLoaded(true);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
            setIsCompetitionsLoaded(true);
          }),
        );
    }
  };

  const onDeleteLineup = useCallback(
    (lineupId) => {
      if (!draftId || !lineupId) {
        return;
      }
      props
        .fetch(`/decision-api/decision/lub/drafts/id/${draftId}/lineups/id/${lineupId}`, {
          method: "DELETE",
        })
        .then((response) => response.json())
        .then((res) => {
          loadSavedLineupsAndCompetitions();
        })
        .catch(errorCatcher());
    },
    [draftId, gw, targetUserId, preferences, targetUserId, sport, abortController],
  );

  const getGwOdds = () => {
    if (gw) {
      props
        .fetch(`/apiv2/stats/odds/${gw}`)
        .then((response) => response.json())
        .then((res) => {
          setOddsAndStats(res);
        })
        .catch(errorCatcher());
    }
  };

  const canExportLineups = () => {
    if (draftId && gw) {
      setCanExport(defaultCanExport);
      props
        .fetch(`/api2api-api/decision/lub/drafts/id/${draftId}/export/can?gwNumber=${gw}`)
        .then((response) => response.json())
        .then((res) => {
          if (res.error) {
            console.error(res.error);
          } else {
            setCanExport(res);
          }
        })
        .catch(errorCatcher());
    }
  };

  const exportLineups = (force) => {
    const f = force === true;
    if (gw && !exporting) {
      if (f) {
        setExportingForced(true);
      } else {
        setExporting(true);
      }
      props
        .fetch(`/api2api-api/decision/lub/drafts/id/${draftId}/export`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gwNumber: gw,
            force: f,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          setExportRes(res);
          if (f) {
            setExportingForced(false);
          } else {
            setExporting(false);
          }
        })
        .catch(
          errorCatcher(() => {
            if (f) {
              setExportingForced(false);
            } else {
              setExporting(false);
            }
          }),
        );
    }
  };

  const onChangeDivision = useCallback(
    (divId) => {
      setSelectedCompIds((prev) => {
        if (divId === selectAllOption.id) {
          return prev.includes(selectAllOption.id) ? [] : [selectAllOption.id, ...allCompetitions.map((c) => c.id)];
        }
        const index = prev.indexOf(divId);
        const selectedCompetitionsWithoutSelectAll = prev.filter((c) => c !== selectAllOption.id);
        if (index !== -1) {
          if (selectedCompetitionsWithoutSelectAll.length === 1) {
            return [];
          }
          return prev.filter((item) => item !== divId);
        }
        if (selectedCompetitionsWithoutSelectAll.length === allCompetitions.length - 1) {
          return [selectAllOption.id, ...selectedCompetitionsWithoutSelectAll, divId];
        }
        return [...prev, divId];
      });
    },
    [allCompetitions],
  );

  const getUsername = useCallback(() => {
    let u = props.username;
    if (!u) {
      u = query.get("manager");
      if (!u) {
        u = props.user.sorareSlug;
      }
    }
    return u;
  }, [props.username, query, props.user.sorareSlug]);

  const competitionOptions = [selectAllOption, ...allCompetitions];
  const nbLineups = allCompetitions.reduce((total, obj) => total + obj.currentNbEntries, 0);
  const username = getUsername();
  const isConnectedUser = username === undefined || username === "" || username === props.user.sorareSlug;
  return (
    <div className={"flex-col flex mx-4 md:mx-12 2xl:w-11/12 2xl:mx-auto 4xl:w-8/12 bg-surface pb-8 xl:w-11/12 xl:mx-auto gap-3"}>
      <div className={"flex flex-col sm:flex-row items-center justify-between my-auto gap-2 sm:h-10"}>
        <div className={"flex flex-col sm:flex-row items-center gap-2"}>
          <div className={"h-10 flex flex-row items-center"}>
            <CompetitionMultiSelectMenu
              loading={loading}
              competitions={competitionOptions}
              selectedIds={selectedCompIds}
              onChange={onChangeDivision}
              allOption={selectAllOption}
              isCompetitionsLoaded={isCompetitionsLoaded}
              withTip={true}
            />
          </div>
          <div className={"h-10 flex flex-row z-20"}>
            <DropdownDS
              width={"sm:w-[18rem]"}
              icon={Group}
              options={groupByOptions}
              selected={preferences.groupBy}
              onChange={(v) => setPreferences({ ...preferences, groupBy: v })}
            />
          </div>
        </div>
        <div>
          <CanExportButton
            canExport={canExport}
            exporting={exporting}
            exportingForced={exportingForced}
            exportRes={exportRes}
            onClick={() => exportLineups(false)}
            onClickForced={() => exportLineups(true)}
            nbLineups={nbLineups}
            draftName={draftName}
            dark={dark}
            notConnectedUser={!isConnectedUser}
            onClose={() => setExportRes(undefined)}
            draftId={draftId}
          />
        </div>
      </div>
      {isConnectedUser && <ExportBanner canExport={canExport} currentGw={gw} currentDraftId={draftId} />}
      <SavedLineups
        selectedCompIds={selectedCompIds}
        competitions={competitions}
        leagues={leagues}
        statuses={statuses}
        divisionMaxScores={divisionMaxScores}
        divisionMaxScoresNoCaptain={divisionMaxScoresNoCaptain}
        oddsAndStats={oddsAndStats}
        projections={projections}
        targetUserId={targetUserId}
        gw={gw}
        draftId={draftId}
        onDeleteLineup={onDeleteLineup}
        groupBy={preferences.groupBy}
        loading={loading}
      />
    </div>
  );
});

const GetExportLUBStatusPendingApproval = "PENDING_APPROVAL";
const GetExportLUBStatusInProcess = "IN_PROCESS";
const GetExportLUBStatusFailed = "FAILED";
const GetExportLUBStatusExpired = "EXPIRED";
const GetExportLUBStatusDeclined = "DECLINED";
const GetExportLUBStatusEndedSuccessfully = "ENDED_SUCCESSFULLY";
const GetExportLUBStatusEndedWithLineupErrors = "ENDED_WITH_LINEUP_ERRORS";

const bannerTitleByStatus = {
  [GetExportLUBStatusPendingApproval]: "Sorare Export pending for approval",
  [GetExportLUBStatusInProcess]: "Sorare Export in progress",
  [GetExportLUBStatusFailed]: "Sorare Export failed",
  [GetExportLUBStatusEndedSuccessfully]: "Sorare Export successful",
  [GetExportLUBStatusEndedWithLineupErrors]: "Sorare Export partially successful",
};

const languageToLocale = {
  "en-US": enUS,
  "en-GB": enGB,
  fr: fr,
};

const bannerSubtitle = (lastBatch, isSameGw, isSameDraft) => {
  const countLineups = lastBatch.countLineups;
  const { submitted, ignored, imported, refused } = countLineups;
  const locale = languageToLocale[getNavigatorLang()] || enGB;
  switch (lastBatch.status) {
    case GetExportLUBStatusPendingApproval:
      if (isSameDraft && isSameGw) {
        return "An export waiting for your approval is in progress on Sorare for this gameweek and draft";
      } else if (isSameGw) {
        return `An export is waiting for your approval on Sorare for the draft "${lastBatch.draftName}"`;
      } else {
        return `An export is waiting for your approval on Sorare for the GW #${lastBatch.sorareGwNumber} (draft "${lastBatch.draftName}")`;
      }
    case GetExportLUBStatusInProcess:
      if (isSameDraft && isSameGw) {
        return `Export is in progress on Sorare (${imported} / ${submitted} lineups), please wait a few minutes and refresh`;
      } else if (isSameGw) {
        return `An export is in progress on Sorare for the same gw but on draft ${lastBatch.draftName}. Please wait a few minutes and refresh`;
      } else {
        return `An export is in progress on Sorare for GW #${lastBatch.sorareGwNumber} and draft ${lastBatch.draftName}. Please wait a few minutes and refresh`;
      }
    case GetExportLUBStatusFailed:
      return "Export failed completely on Sorare, please try again";
    case GetExportLUBStatusEndedSuccessfully:
      return `${imported} lineups exported on ${format(new Date(lastBatch.date), "Pp", { locale })}`;
    case GetExportLUBStatusEndedWithLineupErrors:
      return `${imported} lineups exported on ${format(new Date(lastBatch.date), "Pp", { locale })}, but ${refused} lineups have failed`;
    default:
      return "";
  }
};

const ExportBanner = (props) => {
  const { canExport, currentGw, currentDraftId } = props;
  if (canExport === null || canExport === undefined) {
    return null;
  }
  const lastBatch = canExport.lastBatch;
  if (
    lastBatch === null ||
    lastBatch === undefined ||
    lastBatch.status === "" ||
    lastBatch.status === GetExportLUBStatusExpired ||
    lastBatch.status === GetExportLUBStatusDeclined
  ) {
    return null;
  }
  const status = lastBatch.status;
  const isPending = status === GetExportLUBStatusPendingApproval || status === GetExportLUBStatusInProcess;
  const isSameDraft = lastBatch.draft === currentDraftId;
  const isSameGw = lastBatch.gwNumber === currentGw;
  const background =
    status === GetExportLUBStatusFailed ||
    status === GetExportLUBStatusEndedWithLineupErrors ||
    status === GetExportLUBStatusPendingApproval
      ? `linear-gradient(90deg, #FE4444 0%, rgba(245, 82, 5, 0.6) 100%)`
      : `linear-gradient(90deg, #25CBAF 0%, rgba(51, 59, 251, 0.40) 100%)`;
  const action = isPending || status === GetExportLUBStatusEndedWithLineupErrors;
  return (
    <div className={"rounded-xl overflow-hidden flex flex-col justify-center relative"} style={{ background }}>
      <div className={"flex flex-row justify-between items-center bg-surface-container-high bg-opacity-70"}>
        <div className={"flex flex-col gap-1 p-4 justify-center"}>
          <h4 className={"m-0 font-headers text-medium text-on-surface"}>{bannerTitleByStatus[status]}</h4>
          <p className={"text-md text-on-surface-variant"}>{bannerSubtitle(lastBatch, isSameGw, isSameDraft)}</p>
        </div>
        <div className={"p-4"}>
          {action && <ActionButton isPending={isPending} isSameGw={isSameGw} isSameDraft={isSameDraft} lastBatch={lastBatch} />}
        </div>
      </div>
      {!action && (
        <div className={"absolute right-5 h-full flex flex-col justify-center"}>
          <SorareIcon className={"w-[90px] h-[90px] opacity-10"} />
        </div>
      )}
    </div>
  );
};

const ActionButton = (props) => {
  const { isPending, isSameGw, isSameDraft, lastBatch } = props;
  const status = lastBatch.status;
  if (isPending) {
    if (isSameGw && isSameDraft) {
      return (
        <a target="_blank" rel="noreferrer" href={lastBatch.url}>
          <Button
            context={status === GetExportLUBStatusPendingApproval ? "" : "neutral"}
            label={status === GetExportLUBStatusPendingApproval ? "Finalize export on Sorare" : "Check on Sorare"}
          />
        </a>
      );
    } else {
      return (
        <a href={`/lineupBuilder/sections/saved/sport/sr_football?draftId=${lastBatch.draft}&gw=${lastBatch.gwNumber}`}>
          <Button context={"neutral"} label={isSameGw ? `Open "${lastBatch.draftName}" draft` : `Open GW #${lastBatch.sorareGwNumber}`} />
        </a>
      );
    }
  } else if (status === GetExportLUBStatusEndedWithLineupErrors) {
    return (
      <a target="_blank" rel="noreferrer" href={lastBatch.url}>
        <Button context={"neutral"} label={"View failed lineups"} />
      </a>
    );
  }
  return null;
};

const CannotExportReasonCannotCompose = "CANNOT_COMPOSE"; // closed gw or gw not known on Sorare
const CannotExportReasonPendingExport = "PENDING_EXPORT";
const CannotExportReasonNotEnoughRights = "NOT_ENOUGH_RIGHTS";
const CannotExportExpiredSorareAuth = "EXPIRED_SORARE_AUTH";
const CannotExportNoValidLineup = "NO_VALID_LINEUP";
const CannotExportReasonSorareImportError = "SORARE_IMPORT_ERROR";

const CannotExportMessages = (errorCode, errorMessage) => {
  switch (errorCode) {
    case CannotExportReasonCannotCompose:
      return "You can't export your lineups to Sorare because the gameweek is closed or not opened on Sorare yet";
    case CannotExportReasonPendingExport:
      return "You can't export your lineups to Sorare because you have a pending export";
    case CannotExportReasonNotEnoughRights:
      return "You can't export your lineups to Sorare because you don't have enough rights";
    case CannotExportExpiredSorareAuth:
      return "You can't export because your authentication to Sorare has expired, logout and login from SorareData and come back here";
    case CannotExportNoValidLineup:
      return "None of your lineups are valid"; // could happen if the lineups changed between the preview and the export itself
    case CannotExportReasonSorareImportError:
      return `An error happened during the export to Sorare due to: "${errorMessage}"`;
    case "":
      return "";
    default:
      return "An error happened during the export. Please retry later.";
  }
};

const CanExportButton = (props) => {
  const { canExport, exporting, exportingForced, onClick, onClickForced, draftName, dark, exportRes, notConnectedUser, onClose, draftId } =
    props;
  const [openModal, setOpenModal] = useState(false);
  const nbLineups = props.nbLineups || 0;

  useEffect(() => {
    if (exporting) {
      setOpenModal(true);
    }
  }, [exporting]);
  useEffect(() => {
    if (!openModal) {
      onClose && onClose();
    }
  }, [openModal]);
  if (
    canExport == null ||
    canExport === undefined ||
    (!canExport.canExport && canExport.cannotExportReason === CannotExportReasonNotEnoughRights) ||
    notConnectedUser ||
    nbLineups === 0
  ) {
    return null; // Don't display the button if the user doesn't have the rights to export or we still don't know if they can export
  }
  const tip = canExport.canExport
    ? `Click to export your ${nbLineups} lineups to Sorare`
    : CannotExportMessages(canExport.cannotExportReason);
  const disabled = exporting || !canExport.canExport || nbLineups === 0;
  return (
    <div className={"h-10"}>
      <Button
        adapting={true}
        label={`Export ${nbLineups} lineup${nbLineups > 1 ? "s" : ""} to Sorare`}
        onClick={disabled ? undefined : () => onClick()}
        disabled={disabled}
        data-tip={tip}
        data-for={"export-lineups-to-sorare"}
        data-delay-show={250}
        loading={exporting}
        className={disabled ? "cursor-not-allowed" : ""}
        tag={
          <div className="absolute top-0 right-0 -mt-2 -mr-2">
            <Tag context="tertiary" size="small" label={"Beta"} padding={"py-0.5 px-2"} />
          </div>
        }
        icon={<SorareIcon className={`w-5 h-5 ${disabled ? "fill-on-disable" : "fill-on-primary"}`} />}
      />
      <ExportModalPreview
        canExport={canExport}
        open={openModal}
        close={() => setOpenModal(false)}
        draftName={draftName}
        onConfirm={() => onClickForced()}
        exporting={exporting}
        exportingForced={exportingForced}
        exportRes={exportRes}
        dark={dark}
        draftId={draftId}
      />
      <ReactTooltip id={"export-lineups-to-sorare"} />
    </div>
  );
};

const ExportModalPreview = (props) => {
  const { open, close, canExport, draftName, onConfirm, exportRes, exporting, exportingForced, dark, draftId } = props;
  const nbInvalidLineups = exportRes?.countLineups?.ignored || 0;
  const nbTotalLineups = exportRes?.countLineups?.total || 0;
  const nbValidLineups = nbTotalLineups - nbInvalidLineups;
  const ableToExportAnyway = (canExport.canExport && !exporting) || false;
  const exportTip = ableToExportAnyway
    ? `Click to export your ${nbValidLineups} lineup(s) to Sorare anyway`
    : CannotExportMessages(canExport.cannotExportReason);
  const firstMessage = (
    <p>
      You want to export{" "}
      <b>
        {nbTotalLineups} lineup{nbTotalLineups > 1 ? "s" : ""}
      </b>{" "}
      to Sorare.
    </p>
  );
  const message =
    nbValidLineups === 0 ? (
      <p>
        But, after careful check, <b>none of them are valid</b> so you can&apos;t export anything yet. Fix the invalid lineups below and
        retry
      </p>
    ) : (
      <p>
        After careful check,{" "}
        <b>
          only {nbValidLineups} {nbValidLineups > 1 ? "are" : "is"} valid
        </b>{" "}
        and will be exported to Sorare. Check ignored lineups below
      </p>
    );
  const withFooter = nbInvalidLineups > 0 && exportRes.invalidLeagues && !exporting && !exportingForced;
  return (
    <ModalDS
      open={open}
      close={close}
      maxSize={"sm:max-w-[35em]"}
      header={<ModalDSTitleHeader title={"Export teams to Sorare"} subtitle={`Draft "${draftName}"`} beta={true} />}
      footer={
        withFooter ? (
          <div className={"flex flex-col gap-2 p-4"}>
            <div className={"flex flex-row gap-2 items-center justify-end"}>
              <Button label={"Cancel"} onClick={close} context={"neutral"} />
              <Button
                data-tip={exportTip}
                data-for={"export-lineups-to-sorare-confirm"}
                data-delay-show={250}
                disabled={!ableToExportAnyway}
                label={"Export anyway"}
                onClick={ableToExportAnyway ? onConfirm : undefined}
                context={"primary"}
                className={exporting ? "cursor-progress" : ""}
              />
              <ReactTooltip id={"export-lineups-to-sorare-confirm"} />
            </div>
          </div>
        ) : null
      }
      content={
        exporting ? (
          <div
            className={"px-4 py-4 min-h-[20em] flex flex-col gap-4 items-center justify-center text-sm text-on-surface-variant font-medium"}
          >
            <div className={"flex flex-col gap-3 items-center justify-center"}>
              <Spinner className={"w-14 h-14"} />
              <div className={"flex flex-col gap-1 justify-center"}>
                <p>Checking validity of your lineups...</p>
              </div>
            </div>
          </div>
        ) : exportingForced ? (
          <div
            className={"px-4 py-4 min-h-[20em] flex flex-col gap-4 items-center justify-center text-sm text-on-surface-variant font-medium"}
          >
            <div className={"flex flex-col gap-3 items-center justify-center"}>
              <Spinner className={"w-14 h-14"} />
              <div className={"flex flex-col gap-1 justify-center"}>
                <p>Exporting valid lineups to Sorare...</p>
              </div>
            </div>
          </div>
        ) : nbInvalidLineups > 0 && exportRes.invalidLeagues ? (
          <div className={"flex flex-col gap-4 p-4"}>
            <div className={"font-medium text-md flex flex-col gap-1"}>
              {firstMessage}
              {message}
            </div>
            <div className={"flex flex-col gap-4"}>
              <div
                className={
                  "bg-quality-scale-terrible-quality-container-dim-fixed p-3 flex flex-row gap-2 items-center rounded-xl font-medium text-md"
                }
              >
                <WarningIcon className={"w-6 h-6 fill-on-surface"} />
                <p>
                  {nbInvalidLineups} of your team{nbInvalidLineups > 1 ? "s" : ""} {nbInvalidLineups > 1 ? "are" : "is"} invalid and will
                  not be exported.
                </p>
              </div>
              <div className={"flex flex-row gap-2 items-center"}>
                <h4 className={"font-header font-semibold m-0 text-lg"}>Invalid lineups</h4>
                <Tag size="small" textSize={`font-semibold text-xs`} context={"emphasized-transparent"} padding={"px-[8px] py-[2px]"}>
                  <span>{nbInvalidLineups}</span>
                </Tag>
              </div>
              <LineupsByLeagues
                leagues={exportRes?.invalidLeagues}
                dark={dark}
                divisionMaxScores={exportRes?.divisionMaxScores}
                divisionMaxScoresNoCaptain={exportRes?.divisionMaxScoresNoCaptain}
              />
            </div>
          </div>
        ) : (
          <ExportResPolling export={exportRes} close={close} draftId={draftId} />
        )
      }
    />
  );
};

const defaultExportStats = {
  total: 0,
  submitted: 0,
  ignored: 0,
  imported: 0,
  importing: 0,
  refused: 0,
  cancelled: 0,
};

const ExportResPolling = withUser((props) => {
  const { close, draftId } = props;
  const exp = props.export;
  const [exportStatus, setExportStatus] = useState("START");
  const [exportStats, setExportStats] = useState(defaultExportStats);
  const [exportUrl, setExportUrl] = useState("");
  const [exportId, setExportId] = useState("");
  const POLLING_INTERVAL = 2000;
  const fetchExportStatus = (id) => {
    if (id === undefined || id === null || id === "") {
      return;
    }
    props
      .fetch(`/api2api-api/decision/lub/drafts/id/${draftId}/export/id/${id}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          console.error(res.error);
        } else {
          setExportUrl(res.url);
          setExportStatus(res.status);
          setExportStats(res.countLineups);
        }
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const pendingStates = [GetExportLUBStatusPendingApproval, GetExportLUBStatusInProcess];
      if (exportId && pendingStates.includes(exportStatus)) {
        // Only fetch if we have an id and the export is not finished already
        fetchExportStatus(exportId);
      }
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [exportId, exportStatus]);

  useEffect(() => {
    if (exp) {
      setExportStatus(exp.status);
      setExportStats(exp.countLineups);
      if (exp.url) {
        setExportUrl(exp.url);
      }
      if (exp.id) {
        setExportId(exp.id);
      }
    }
  }, [exp]);

  if (exp === undefined || exp === null) {
    return null;
  }

  const unknownError = exp.error;

  return (
    <div className={"px-4 py-4 min-h-[20em] flex flex-col gap-6 items-center justify-center text-sm text-on-surface-variant font-medium"}>
      {unknownError || !exp.success ? (
        <>
          <div className={"flex flex-col gap-3 items-center justify-center text-error"}>
            <CancelIcon className={"w-14 h-14 fill-error"} />
            <div className={"flex flex-col gap-1 justify-center items-center max-w-[25em] text-center"}>
              <p>Export failed.</p>
              <p>{exp.error}</p>
            </div>
          </div>
          <Button context="neutral" label="Close" onClick={close} />
        </>
      ) : exportStatus === GetExportLUBStatusInProcess ? (
        <div className={"flex flex-col gap-3 items-center justify-center"}>
          <Spinner className={"w-14 h-14"} />
          <div className={"flex flex-col gap-1 justify-center items-center text-center max-w-[25em]"}>
            <p>
              Exporting {exportStats.imported + exportStats.refused}/{exportStats.submitted} teams to Sorare...
            </p>
            <p>Please wait</p>
          </div>
        </div>
      ) : exportStatus === GetExportLUBStatusPendingApproval ? (
        <>
          <div className={"flex flex-col gap-3 items-center justify-center"}>
            <SorareIcon className={"w-14 h-14 fill-on-surface-variant"} />
            <div className={"flex flex-col gap-1 justify-center items-center text-center max-w-[25em]"}>
              <p>{exportStats.submitted} teams have been sent to Sorare.</p>
              <p className={"text-on-surface font-semibold"}>Please click below to finalize the export on Sorare.</p>
            </div>
          </div>
          {exportUrl && (
            <a target="_blank" rel="noreferrer" href={exportUrl}>
              <Button label="Finalize export on Sorare" />
            </a>
          )}
        </>
      ) : exportStatus === GetExportLUBStatusExpired ? (
        <>
          <div className={"flex flex-col gap-3 items-center justify-center max-w-[25em]"}>
            <CancelIcon className={"w-14 h-14 fill-on-surface-variant"} />
            <p>Export has expired on Sorare because it has not been confirmed or declined in time</p>
          </div>
          <Button context="neutral" label="Close" onClick={close} />
        </>
      ) : exportStatus === GetExportLUBStatusDeclined ? (
        <>
          <div className={"flex flex-col gap-3 items-center justify-center "}>
            <CancelIcon className={"w-14 h-14 fill-on-surface-variant"} />
            <p>Export has been declined on Sorare.</p>
          </div>
          <Button context="neutral" label="Close" onClick={close} />
        </>
      ) : exportStatus === GetExportLUBStatusEndedWithLineupErrors ? (
        <>
          <div className={"flex flex-col gap-4 items-center justify-center text-quality-scale-bad-on-surface-container"}>
            <ErrorIcon className={"w-14 h-14 fill-quality-scale-bad-on-surface-container"} />
            <div className={"flex flex-col gap-1 justify-center items-center"}>
              <p>Export partially successful.</p>
              <p>
                {exportStats.refused}/{exportStats.submitted} lineups failed to export.
              </p>
            </div>
          </div>
          {exportUrl && (
            <a target="_blank" rel="noreferrer" href={exportUrl}>
              <Button context="neutral" label="View failed exports" />
            </a>
          )}
        </>
      ) : exportStatus === GetExportLUBStatusEndedSuccessfully ? (
        <>
          <div className={"flex flex-col gap-4 items-center justify-center text-quality-scale-excellent-on-surface-container"}>
            <CircleIcon className={"w-14 h-14 fill-quality-scale-excellent-on-surface-container"} />
            <p>Export successful</p>
          </div>
          <Button context="neutral" label="Close" onClick={close} />
        </>
      ) : null}
    </div>
  );
});

const LineupsByLeagues = (props) => {
  const { leagues, dark, divisionMaxScores, divisionMaxScoresNoCaptain } = props;
  if (leagues === undefined || leagues === null || leagues.length === 0) {
    return null;
  }
  return (
    <div className={"flex flex-col gap-2"}>
      {leagues.map((l) => (
        <LineupsByLeague
          key={l.id}
          league={l}
          dark={dark}
          divisionMaxScores={divisionMaxScores}
          divisionMaxScoresNoCaptain={divisionMaxScoresNoCaptain}
        />
      ))}
    </div>
  );
};

const LineupsByLeague = (props) => {
  const { league, dark, divisionMaxScores, divisionMaxScoresNoCaptain } = props;
  return (
    <div className={"flex flex-col p-4 bg-surface-container rounded-xl gap-3"}>
      <div className={"flex flex-row gap-2 items-center"}>
        <img src={league.logoUrl} className={"w-8 h-8"} />
        <h4 className={"font-headers font-semibold m-0 text-xl text-on-surface-variant"}>{league.fullName}</h4>
      </div>
      <div className={"flex flex-col gap-2"}>
        {league.competitions?.map((c) => (
          <LineupsByCompetition
            key={c.id}
            competition={c}
            dark={dark}
            divisionMaxScores={divisionMaxScores}
            divisionMaxScoresNoCaptain={divisionMaxScoresNoCaptain}
          />
        ))}
      </div>
    </div>
  );
};

const LineupsByCompetition = (props) => {
  const { competition, dark, divisionMaxScores, divisionMaxScoresNoCaptain } = props;
  const allTeams = [];
  if (competition.singleEntryTeam) {
    allTeams.push(competition.singleEntryTeam);
  } else if (competition.multiEntryTeams?.used) {
    allTeams.push(...competition.multiEntryTeams.used);
  }
  const divRules = competition.rules;
  return (
    <div className={"flex flex-col gap-3"}>
      <div className={"flex flex-row gap-2 text-on-surface-variant border-b border-outline-variant py-2 items-center"}>
        <h4 className={"font-semibold m-0 text-md text-on-surface-variant"}>{competition.fullName?.replaceAll("–", "·")}</h4>
        <Tag size="small" textSize={`font-semibold text-xs`} context={"emphasized-opaque"} padding={"px-[8px] py-[2px]"}>
          <span>{competition.currentNbEntries}</span>
        </Tag>
      </div>
      <div className={"flex flex-col gap-2"}>
        {allTeams.map((t) => {
          let divisionMaxScore = { normal: 0, noCaptain: 0 };
          if (divisionMaxScores) {
            divisionMaxScore = {
              normal: divisionMaxScores?.[t.competitionId],
              noCaptain: divisionMaxScoresNoCaptain?.[t.competitionId],
            };
          }
          return (
            <LineupsByTeam
              key={t.lineup.id || t.id}
              team={t}
              rarity={competition.rarity}
              dark={dark}
              divisionMaxScore={divisionMaxScore}
              divRules={divRules}
            />
          );
        })}
      </div>
    </div>
  );
};

const LineupsByTeam = (props) => {
  const { team, rarity, dark, divisionMaxScore, divRules } = props;
  const [bgHeaderFrom, bgHeaderTo] = getBackgroundGradientFromDivisionId(rarity);
  const { theme } = useTheme();
  if (!team.lineup) {
    return null;
  }
  const captain = team.lineup?.cards?.find((l) => l?.captain);
  const lStrength = computeLineupStrength(team.lineup.cards, divRules, captain, divisionMaxScore);
  const invalidReasons = team.lineup.invalidReasons?.includes(LineupInvalidReasonIncomplete)
    ? [LineupInvalidReasonIncomplete]
    : team.lineup.invalidReasons; // when the lineup is incomplete, we only show this error
  return (
    <div
      className={`rounded-xl overflow-hidden`}
      style={{
        background: `linear-gradient(180deg, ${getThemeColor(dark ? sdDark : theme, bgHeaderFrom)} 0%, ${getThemeColor(
          dark ? sdDark : theme,
          bgHeaderTo,
        )} 100%)`,
      }}
    >
      <div className={"flex flex-col"}>
        <div className={"ml-1.5 p-2 bg-surface-container-low flex flex-row gap-3 items-center justify-evenly"}>
          <img src={team.logoUrl} className={"w-10 h-10"} />
          <div className={"flex grid grid-cols-5 grid-rows-1 gap-3"}>
            {team.lineup?.cards?.map((c, i) => {
              const isCaptain = c && captain && c.card.Slug === captain.card.Slug;
              return (
                <PlayerAvatar
                  key={c?.player?.PlayerId || i}
                  player={c?.player}
                  isCaptain={isCaptain}
                  size={"w-13 h-13"}
                  imgSize={"w-11 h-11"}
                  halo={"bg-surface-container-highest"}
                />
              );
            })}
          </div>
          <div className={"border-r border-outline-variant h-8"} />
          <div className={"flex flex-row items-center justify-around text-md text-on-surface-variant font-semibold gap-4"}>
            <p>{lStrength.lineupScore && lStrength.lineupScore > 0 ? "Strength" : "No strength"}</p>
            {lStrength.lineupScore && lStrength.lineupScore > 0 ? (
              <LineupStrength lineupScore={lStrength.lineupScore} divisionMaxScore={lStrength.divisionMaxScore} size={"sm"} isLub />
            ) : null}
          </div>
        </div>
      </div>
      <div className={"ml-1.5 bg-surface-container-low"}>
        <div
          className={
            "bg-transparent-inverse-surface-low bg-opacity-10 flex flex-row items-center text-on-surface gap-2 p-2 text-xs font-medium"
          }
        >
          <WarningIcon className={"w-6 h-6 fill-on-surface"} />
          <p>{invalidReasons?.map((r) => labelInvalidReasons[r]).join(", ")}</p>
        </div>
      </div>
    </div>
  );
};

const defaultMyTeamsPreferences = {
  groupBy: "league",
};

const getMyTeamsPreferencesPreferencesFromLocalStorage = () => {
  const preferences = localStorage.getItem("lubMyTeamsPreferencesV2");
  if (preferences) {
    try {
      return { ...defaultMyTeamsPreferences, ...JSON.parse(preferences) };
    } catch (e) {
      return defaultMyTeamsPreferences;
    }
  }
  return defaultMyTeamsPreferences;
};

const setMyTeamsPreferencesPreferencesToLocalStorage = (preferences) => {
  localStorage.setItem("lubMyTeamsPreferencesV2", JSON.stringify(preferences));
};
