export default function scarcityChange(s, scarcityFilter) {
  let newScarcities = [...scarcityFilter];
  if (newScarcities.indexOf(s) >= 0) {
    newScarcities = newScarcities.filter(function (value, index, arr) {
      return value !== s;
    });
  } else {
    newScarcities.push(s);
  }
  newScarcities.sort(function (a, b) {
    if (a === "LIMITED") {
      return -1;
    } else if (a === "UNIQUE") {
      return 1;
    } else if (b === "LIMITED") {
      return 1;
    } else if (b === "UNIQUE") {
      return -1;
    } else if (a === "RARE" && b === "SUPER RARE") {
      return -1;
    } else if (b === "RARE" && a === "SUPER RARE") {
      return 1;
    }
  });
  return newScarcities;
}
