import React, { useState } from "react";
import { withUser } from "../../userContext";
import IconTrashCircle from "../../img/icons-trash-circle.svg";
import IconTrashCircleHover from "../../img/icons-trash-circle-hover.svg";

function DeletionBubble(props) {
  const [hover, setHover] = useState(false);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      title={props.title}
      onClick={onClick}
      className={`hover:opacity-80 ${props.className ? props.className : ""}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img className={"cursor-pointer h-full w-full"} src={hover ? IconTrashCircleHover : IconTrashCircle} />
    </div>
  );
}

export default withUser(DeletionBubble);
