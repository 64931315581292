import React, { useEffect, useState } from "react";
import PlayerMultiStatsGraph from "./playerMultiStatsGraph";
import ComboSelectionList from "./comboSelectionList";
import ComboSelectedList from "./comboSelectedList";
import ComboStats from "./comboStats";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function PlayerCombo(props) {
  const [targetPlayers, setTargetPlayers] = useState(new Array(props.player));
  const [players, setPlayers] = useState([]);
  const [period, setPeriod] = useState("6m");
  const [currentStats, setCurrentStats] = useState({});
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortWhat, setSortWhat] = useState("L15");

  useEffect(() => {
    getPlayers(targetPlayers);
  }, [period]);

  const getPlayers = (players) => {
    let ids = [];
    players.map((p) => ids.push(p.PlayerId));
    props
      .fetch("/apiv2/players/comboList", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          players: ids,
          period: period,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setPlayers(res.other_players);
        setCurrentStats(res.current_combo);
      })
      .catch(errorCatcher());
  };

  const addToCombo = (p) => {
    let newCombo = [];
    targetPlayers.map((p) => newCombo.push(p));
    newCombo.push(p);
    setTargetPlayers(newCombo);
    getPlayers(newCombo);
  };

  const removeFromCombo = (p) => {
    let newCombo = targetPlayers.filter(function (v, i, a) {
      return v.PlayerId !== p.PlayerId;
    });
    setTargetPlayers(newCombo);
    getPlayers(newCombo);
  };

  const sortData = (w) => {
    let howToSort = 0;
    if (w === sortWhat) {
      howToSort = sortOrder * -1;
      setSortOrder(howToSort);
    } else {
      howToSort = -1;
    }
    setSortWhat(w);
    let whatToSort = "average_combined_score";
    if (w === "avg_div") {
      whatToSort = "average_combined_score_divided";
    } else if (w === "gms") {
      whatToSort = "common_games";
    } else if (w === "above") {
      whatToSort = "avg_above_60";
    } else if (w === "mins") {
      whatToSort = "avg_minutes";
    }
    if (howToSort === 1) {
      players.sort(function (a, b) {
        if (parseFloat(a[whatToSort]) < parseFloat(b[whatToSort])) {
          return -1;
        } else if (parseFloat(a[whatToSort]) > parseFloat(b[whatToSort])) {
          return 1;
        }
        return 0;
      });
    } else {
      players.sort(function (a, b) {
        if (parseFloat(a[whatToSort]) > parseFloat(b[whatToSort])) {
          return -1;
        } else if (parseFloat(a[whatToSort]) < parseFloat(b[whatToSort])) {
          return 1;
        }
        return 0;
      });
    }
  };

  return (
    <div className={"space-y-8"}>
      <div className={"mx-2 lg:w-9/12 mx-auto"}>
        <ComboSelectedList players={targetPlayers} removeFromCombo={removeFromCombo} lock={props.player.PlayerId} />
        {currentStats && currentStats !== null && <ComboStats stats={currentStats} />}
      </div>
      <div>
        <PlayerMultiStatsGraph combined={false} players={targetPlayers} setPeriod={setPeriod} />
      </div>
      <div className={"lg:w-9/12 lg:mx-auto"}>
        <ComboSelectionList players={players} addToCombo={addToCombo} sort={sortData} />
      </div>
    </div>
  );
}

export default withUser(PlayerCombo);
