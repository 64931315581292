import { getNavigatorLang } from "./locale";

export function formatPercentage(value, lang) {
  if (value === undefined) {
    return "---";
  }
  const localeLang = lang || getNavigatorLang();
  return new Intl.NumberFormat(localeLang, {
    style: "percent",
    maximumSignificantDigits: 4,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(value);
}
