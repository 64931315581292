import React, { useState } from "react";
import formatDistance from "date-fns/formatDistance";
import WatchlistPlayerTypeIcon from "../../img/icon-watchlists-type-player.svg";
import WatchlistManagerTypeIcon from "../../img/icon-watchlists-type-manager.svg";
import EmptyAvatar from "../../img/empty-avatar.svg";

import { withUser } from "../../userContext";
import format from "date-fns/format";
import { withRouter } from "react-router-dom";
import { WatchlistPrivacyLabel } from "./privacyLabel";
import SelectionBubble from "../util/selectionBubble";
import WatchlistFollowingButton from "./watchlistFollowingButton";

function SelectionWatchlistCard(props) {
  const isSelected = props.isSelected;
  const { id, ownerDisplayName, owner, pictureUrl, name, privacy, type, created, enabled, nbItems, nbFollowers } = props.watchlist;
  const typeLabel = type === "player" ? "Player" : "Manager";
  const initialIsSelected = props.watchlist?.isItemInWatchlist || false;
  const [isHover, setIsHover] = useState(false);
  const connectedUserIsOwner = owner === props.user.sorareSlug;

  const onSelect = () => {
    if (enabled) {
      if (props.onSelect) {
        props.onSelect(id, !isSelected);
      }
    }
  };

  if (id === "") {
    return null;
  }

  let cardClasses = ["bg-focus mx-auto rounded-xl w-full grid gap-y-4 grid-cols-1 p-4"];
  if (enabled) {
    cardClasses.push("cursor-pointer border-2");
    if (isSelected) {
      if (isHover) {
        cardClasses.push("border-brand/50");
      } else {
        cardClasses.push("border-brand");
      }
    } else {
      if (isHover) {
        cardClasses.push("border-brand/50");
      } else {
        cardClasses.push("border-grey-e9");
      }
    }
  } else {
    cardClasses.push("opacity-50 cursor-not-allowed border-2 border-grey-e9");
  }

  return (
    <div
      onClick={onSelect}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={cardClasses.join(" ")}
      title={
        enabled
          ? ""
          : "This watchlist has been disabled following a downgrade of your membership. Upgrade membership and reactivate watchlists to get access"
      }
    >
      <div className={"flex flex-row place-content-between p-0"}>
        <div title={`Watchlist of ${type}s`}>
          <img src={type === "player" ? WatchlistPlayerTypeIcon : WatchlistManagerTypeIcon} />
        </div>
        <div>
          {enabled && initialIsSelected && (
            <span className="font-semibold text-xs text-brand">
              {!isSelected || isHover ? `${typeLabel} will be removed from this watchlist` : `${typeLabel} already in this watchlist`}
            </span>
          )}
        </div>
        <div>{enabled && (isSelected || isHover) && <SelectionBubble isHover={isHover} isSelected={isSelected} />}</div>
      </div>
      <div className={"relative w-full"}>
        <div className={"text-xl font-semibold flex flex-row items-center justify-start"}>
          <span className={"mr-2"}>{name}</span>
          <WatchlistPrivacyLabel privacy={privacy} />
        </div>
        <div className={"mt-0.5"}>
          <p className={"text-sm font-sans font-semibold text-textGrey4"}>{nbItems + (type === "player" ? " players" : " managers")}</p>
        </div>
      </div>
      <div className={"flex flex-row place-content-between"}>
        <div className={"space-y-1 gap-1"}>
          <span className={"rounded-full mr-2"}>
            <img className={"inline w-7 h-7 object-cover rounded-full"} src={pictureUrl ? pictureUrl : EmptyAvatar} />
          </span>
          <span className={"text-sm font-medium mr-2"}>{ownerDisplayName || owner}</span>
          <span title={`Created on ${format(new Date(created), "dd/MM/yyyy")}`} className={"text-sm font-semibold text-textGrey4 mr-2"}>
            {"• " +
              formatDistance(new Date(created), new Date(), {
                addSuffix: true,
              })}
          </span>
        </div>
        <div>
          <WatchlistFollowingButton
            id={id}
            enabled={false}
            nbFollowers={nbFollowers}
            isFollowed={false}
            connectedUserIsOwner={connectedUserIsOwner}
            withLabel
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(withUser(SelectionWatchlistCard));
