import React, { Fragment, useState } from "react";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const ConfirmationAccountDeletionModale = (props) => {
  const { open, onConfirm } = props;
  const [deleteConfirmationMsg, setDeleteConfirmationMsg] = useState("");
  const canConfirm = deleteConfirmationMsg?.toLowerCase() === "delete";

  const onCancel = () => {
    setDeleteConfirmationMsg("");
    props.onCancel();
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={onCancel}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={onCancel}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"w-auto mt-8"}>
                  <div className={"w-full border-b-2 border-grey-e5 pb-4"}>
                    <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>Confirmation required</h2>
                  </div>
                  <div className={"mx-full space-y-6 px-6"}>
                    <div className={"mt-4 rounded-md text-base text-left font-semibold bg-horrible-red p-4 text-focus"}>
                      <div>
                        <h3 className={"font-bold font-headers m-0"}>Are you sure ?</h3>
                      </div>
                      <div className={"text-md"}>
                        <p>This action is not reversible.</p>
                        <p>
                          All your private data including watchlists and settings will be lost and your membership, if any, will be
                          cancelled with immediate effect.
                        </p>
                      </div>
                    </div>
                    <div>
                      <h4>Type DELETE to confirm</h4>
                      <input
                        className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md text-horrible-red font-bold`}
                        type={"text"}
                        value={deleteConfirmationMsg}
                        onChange={(e) => setDeleteConfirmationMsg(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={"flex flex-row justify-center p-4 mx-auto border-t-2 border-grey-e5 mt-4"}>
                  <div className={"flex flex-row gap-2 justify-center my-auto"}>
                    <span className={"my-auto text-brand underline cursor-pointer hover:opacity-90"} onClick={onCancel}>
                      Cancel
                    </span>
                    <button
                      disabled={!canConfirm}
                      title={canConfirm ? "Click to confirm deletion" : "Please type DELETE to confirm"}
                      className={`text-white mx-2 p-2 px-16 text-sm font-medium rounded-md bg-textGrey4 focus:outline-none ${
                        canConfirm ? "shadow bg-horrible-red hover:opacity-80 cursor-pointer" : "cursor-not-allowed"
                      }`}
                      onClick={canConfirm ? onConfirm : undefined}
                    >
                      Delete my SorareData account
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

function AccountDeletion(props) {
  const [errorMsg, setErrorMsg] = useState("");
  const [confirmationModaleOpen, setConfirmationModaleOpen] = useState(false);

  const deleteAccount = () => {
    props
      .fetch("/apiv2/user/deleteAccount", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        setConfirmationModaleOpen(false);
        if (res === true) {
          props.logout();
        } else {
          setErrorMsg("Deletion failed. Please try again");
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"flex flex-col space-y-4 mt-8 items-center"}>
      <div className={"text-base text-left font-semibold"}>
        <p>This action is not reversible.</p>
        <p>
          All your private data including watchlists and settings will be lost and your membership, if any, will be cancelled with immediate
          effect.
        </p>
        <p>Press the button below to delete your account.</p>
      </div>
      <div>
        <button
          type="button"
          onClick={() => setConfirmationModaleOpen(true)}
          className={`inline-flex items-center px-6 py-2 border border-brand text-med font-semibold rounded-lg
                shadow-sm text-white bg-horrible-red focus:outline-none focus:ring-0 hover:bg-opacity-90 hover:border-b-horrible-score cursor-pointer`}
        >
          Delete my SorareData account
        </button>
      </div>
      {errorMsg !== "" && <p className={"text-sm font-semibold"}>{errorMsg}</p>}
      <ConfirmationAccountDeletionModale
        open={confirmationModaleOpen}
        onConfirm={() => deleteAccount()}
        onCancel={() => setConfirmationModaleOpen(false)}
      />
    </div>
  );
}

export default withUser(AccountDeletion);
