import { withUser } from "../../userContext";
import React, { useEffect, useRef, useState } from "react";
import PreviousGw from "../../img/previous-gw.svg";
import NextGw from "../../img/next-gw.svg";
import { errorCatcher } from "../util/errors";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { searchText } from "../util/strings";
import { sorareFootball } from "../util/sports";

function SO5GameweekPicker(props) {
  const { onSelect } = props;
  const [selected, setSelected] = useState(undefined);
  const [gws, setGws] = useState(undefined);
  const [displayedGws, setDisplayedGws] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectableIndex, setSelectableIndex] = useState(-1);
  const [sport, setSport] = useState(sorareFootball || props.sport);
  const ref = useRef(null);
  const inputRef = useRef(null);

  const [open, setOpen] = useState(false);

  const canNext = selected !== undefined && gws !== undefined ? gws.length > 0 && selected > 0 : false;
  const canPrevious = selected !== undefined && gws !== undefined ? gws.length > 0 && selected < gws.length - 1 : false;

  useEffect(() => {
    getAllGameweeks();
  }, []);

  useEffect(() => {
    if (!open) {
      setSearch("");
      setSelectableIndex(-1);
      gws !== undefined && setDisplayedGws(gws.slice(0, 20));
    }
  }, [open]);

  useEffect(() => {
    if (gws != undefined) {
      setSelectableIndex(-1);
      setDisplayedGws(filterGameweeks(search, 20));
    }
  }, [search]);

  useEffect(() => {
    if (selected !== undefined) {
      onSelect && gws !== undefined && onSelect(gws[selected].id, gws[selected].name, gws[selected].number);
    }
  }, [selected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const filterGameweeks = (search, limit) => {
    if (search === undefined || search === "") {
      return gws.slice(0, limit);
    }
    return gws
      .filter((g) => {
        return searchText(search, g.name);
      })
      .slice(0, limit);
  };

  const getAllGameweeks = () => {
    setLoading(true);
    props
      .fetch("/apiv2/gw/resultsList?sport=" + sport)
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          const gws = res
            ? res.map((item, i) => {
                return {
                  id: item.Id,
                  name: item.DisplayName,
                  number: item.GwNumber,
                };
              })
            : [];
          const newGws = gws.map((g, i) => {
            return { ...g, index: i };
          });
          setGws(newGws);
          setDisplayedGws(newGws.slice(0, 20));
          if (newGws.length > 0) {
            setSelected(0);
          }
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const next = () => {
    canNext && setSelected(selected - 1);
  };

  const previous = () => {
    canPrevious && setSelected(selected + 1);
  };

  let roundSearch = "rounded";
  if (gws?.length > 0 && open) {
    roundSearch = "rounded-t";
  }

  const onClickItem = (index) => {
    setSelected(index);
    setOpen(false);
  };

  const onKeyDownInputSearch = (e) => {
    if (open) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          if (prev > 0) {
            return prev - 1;
          }
          return prev;
        });
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          const resultLength = displayedGws?.length || 0;
          if (!(prev >= resultLength - 1)) {
            return prev + 1;
          }
          return prev;
        });
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (displayedGws) {
          if (selectableIndex >= 0 && selectableIndex < displayedGws.length) {
            onClickItem(displayedGws[selectableIndex].index);
          } else if (displayedGws.length === 1) {
            onClickItem(displayedGws[0].index);
          }
        }
      }
    }
  };

  return (
    <div className={"flex flex-row justify-end"}>
      <div className={"flex flex-row space-x-2 self-center"}>
        <div className={"m-auto relative flex h-9 " + roundSearch} style={{ minWidth: "10em" }} ref={ref}>
          <div
            className={"self-center py-2 px-4 rounded bg-white flex flex-row gap-2 cursor-pointer"}
            onClick={() => setOpen(!open)}
            style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
          >
            <p className={"font-semibold self-center text-xs sm:text-base"}>
              {loading ? "Loading..." : selected !== undefined && gws != undefined ? gws[selected].name : ""}
            </p>
            <SortArrowIcon className={`my-auto ${open ? "transform rotate-180" : ""}`} />
          </div>
          {open && (
            <div className={"z-500 absolute w-full top-9"}>
              <div className={"rounded-b bg-white z-50"}>
                <div>
                  <div className={"space-y-2 px-2 py-2 z-50 border-b border-grey-e9"}>
                    <input
                      ref={inputRef}
                      autoFocus
                      type="text"
                      onChange={(v) => setSearch(v.target.value)}
                      className={"block w-full p-2 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0"}
                      value={search}
                      onKeyDown={onKeyDownInputSearch}
                      placeholder="Search..."
                    />
                  </div>
                  <div id={"scrollableGameweekPicker"} className={"overflow-y-scroll"} style={{ maxHeight: "15em" }}>
                    <InfiniteScroll
                      dataLength={displayedGws.length}
                      next={() => setDisplayedGws(filterGameweeks(search, displayedGws.length + 20))}
                      hasMore={displayedGws.length < gws.length}
                      scrollableTarget={"scrollableGameweekPicker"}
                    >
                      <div>
                        <div>
                          {displayedGws.map((gameweek, i) => {
                            const gameweekId = gameweek.id;
                            const gameweekName = gameweek.name;
                            return (
                              <div
                                key={gameweekId}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onClickItem(gameweek.index);
                                }}
                                className={`z-50 flex flex-row text-sm py-1 px-2 justify-between hover:bg-grey-f9 cursor-pointer ${
                                  selectableIndex === i ? "bg-grey-f2" : selected === gameweek.index ? "bg-grey-e9 font-semibold" : ""
                                }`}
                              >
                                {gameweekName}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`bg-white px-4 py-3 flex flex-row justify-center rounded ${canPrevious ? "cursor-pointer" : "opacity-50"}`}
          title={canPrevious ? "Click to select previous gameweek" : "No previous gameweek"}
          onClick={() => previous()}
          style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
        >
          <img src={PreviousGw} />
        </div>
        <div
          className={`bg-white px-4 py-3 flex flex-row justify-center rounded ${canNext ? "cursor-pointer" : "opacity-60"}`}
          title={canNext ? "Click to select next gameweek" : "No next gameweek"}
          onClick={() => next()}
          style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
        >
          <img src={NextGw} />
        </div>
      </div>
    </div>
  );
}

export default withUser(SO5GameweekPicker);
