import React from "react";

export const WatchlistPrivacyLabel = (props) => {
  const privacyExplanations = {
    private: "Only owner of watchlist can see it",
    unlisted: "Publicly available watchlist but does not appear in community watchlist listing",
    public: "Publicly available watchlist and appears in community watchlist listing",
  };
  const { privacy } = props;
  return (
    <span
      title={privacyExplanations[privacy]}
      className={
        "flex capitalize align-middle font-semibold text-xs pl-3 pr-3 pt-0.5 pb-0.5 inline-flex items-baseline rounded-full " +
        (privacy === "private"
          ? "bg-brand-pastel text-brand-text"
          : privacy === "unlisted"
          ? "bg-purple-bg text-purple-text"
          : "bg-info-bg text-info-text")
      }
    >
      {privacy}
    </span>
  );
};
