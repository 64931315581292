import { Box } from "grommet";
import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import LineSeparator from "../../img/line-separator.svg";
import ManagerSO5Utility from "./managerSO5Utility";
import { ReactTooltip } from "../util/tooltip.js";
import ManagerSO5Rewards from "./managerSO5Rewards";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "../util/useQuery";

function ManagerSO5Stats(props) {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const [mode, setMode] = useState(query.get("mode") || "rewards");

  useEffect(() => {
    const params = new URLSearchParams({ ["mode"]: mode });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [mode]);

  const activeTextClass = "font-semibold text-base text-brand";
  const inactiveTextClass = "font-semibold text-base text-textGrey4";

  return (
    <Box>
      <div>
        <div className={"flex flex-row w-4/12 justify-center mx-auto bg-white space-x-12 self-center rounded-lg shadow py-2 px-12"}>
          <div
            className={"cursor-pointer self-center text-center w-5/12"}
            onClick={() => {
              setMode("rewards");
            }}
          >
            <p className={mode === "rewards" ? activeTextClass : inactiveTextClass}>Rewards</p>
          </div>
          <div className={"self-center w-1 h-6"}>
            <img src={LineSeparator} />
          </div>
          <div
            className={"cursor-pointer self-center text-center w-5/12"}
            onClick={() => {
              setMode("utility");
            }}
          >
            <p className={mode === "utility" ? activeTextClass : inactiveTextClass}>SO5 utility</p>
          </div>
        </div>
      </div>
      {mode === "rewards" && (
        <div>
          <ManagerSO5Rewards manager={props.manager} />
        </div>
      )}
      {mode === "utility" && (
        <div className={"mt-8"}>
          <ManagerSO5Utility manager={props.manager} />
        </div>
      )}
      <ReactTooltip />
    </Box>
  );
}

export default withUser(ManagerSO5Stats);
