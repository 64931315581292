export const sorareFootball = "sr_football";
export const sorareBaseball = "sr_baseball";
export const sorareBasketball = "sr_basketball";

export const sportLabelsSingleSimple = [
  { value: sorareFootball, name: "Football" },
  { value: sorareBaseball, name: "Baseball" },
  { value: sorareBasketball, name: "Basketball" },
];

export const sportLabelsSimple = [...sportLabelsSingleSimple, { value: "multi", name: "Multi Sports" }];

export const sportLabelsSingle = [
  { value: sorareFootball, name: "Sorare Football" },
  { value: sorareBaseball, name: "Sorare Baseball" },
  { value: sorareBasketball, name: "Sorare Basketball" },
];

export const sportsLabels = [...sportLabelsSingle, { value: "multi", name: "Multi Sports" }];

export const sportsOptions = [{ value: "", name: "All sports" }, ...sportsLabels];

export const allSportsOptions = [{ value: "all", name: "All sports" }, ...sportLabelsSingle];

export const isSportFree = (sport) => {
  return sport === sorareBaseball || sport === sorareBasketball;
};
