import Toggle from "./toggle";
import React from "react";
import Locker from "../../img/brand-close-locker-nude.svg";
import HelpTip from "./helpTip";

function BoxWithToggle(props) {
  let label = props.label;
  const size = props.size || "text-sm";
  const textColor = props.color || "text-on-surface-variant";
  const hint = props.hint;
  const bg = props.bg || "bg-surface-container";
  const padding = props.padding || "px-4";
  const border = props.border || "";
  const rounded = props.rounded || "rounded";
  const wrap = props.wrap || "truncate";
  const maxWidthLabel = props.maxWidthLabel || "";
  return (
    <div
      className={`${rounded} flex flex-row ${bg} w-full gap-2 justify-between ${padding} ${border} ` + props.className}
      data-tip={props.disabledTip}
      data-for={props.disabledTipId}
    >
      <div className={`self-center justify-start ${maxWidthLabel}`}>
        <span className={"flex flex-row gap-1"}>
          <p className={`font-medium ${wrap} ${size} ${textColor}`}>{label}</p>
          {hint && <HelpTip tip={hint} />}
        </span>
      </div>
      <div className={"w-1/12 justify-end flex flex-row self-center"}>
        {!props.disabled && <Toggle setActive={() => props.setActive && props.setActive()} isActive={props.isActive} />}
        {props.disabled && <img className={"h-3 w-3 self-center"} alt="" src={Locker} />}
      </div>
    </div>
  );
}

export default BoxWithToggle;
