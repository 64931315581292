import { playing_status_labels } from "../util/playingStatus";
import ScoreAverage from "../decision/scoreAverage";
import { withUser } from "../../userContext";
import PlayerAvatar from "../util/playerAvatar";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import PlayerAvailability from "./playerAvailability";
import { sorareBaseball } from "../util/sports";
import React from "react";

const PlayerNoCard = (props) => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
      {props.displayedPlayers.map((p, i) => {
        const playingStatus =
          (t1OrAbove(props.user?.tier) || p.player.Sport === sorareBaseball) && playing_status_labels[p.player.PlayingStatus];
        return (
          <div className="flex flex-row bg-white rounded-lg justify-around" key={i}>
            <div className="flex justify-center mx-2">
              <div className={"flex flex-row"}>
                <div className={"flex self-center"}>
                  <PlayerAvatar player={p.player} />
                </div>
                <div className={"flex flex-col ml-3 self-center"}>
                  <div className={"flex flex-row self-left items-center"}>
                    <p className={"text-left text-sm font-semibold hover:font-bold"}>
                      <a href={"/player/" + p.player.PlayerId} target={"_blank"} rel="noreferrer">
                        {p.player.DisplayName}
                      </a>
                    </p>
                    <PlayerAvailability position="center bottom" availability={p.availability_status} size={"ml-1 w-4"} />
                  </div>
                  <p className={"text-left text-xs font-normal"}>
                    {p.player.Position} - {p.player.Age} {playingStatus && `- ` + playingStatus}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-2 mr-4 ml-4 my-4">
              <ScoreAverage avg={p.scores_5.AverageNoDnp} powerAdj={false} period={5} played={p.scores_5.GamesPlayed} />
              <ScoreAverage avg={p.scores_15.AverageNoDnp} powerAdj={false} period={15} played={p.scores_15.GamesPlayed} />
              <ScoreAverage avg={p.scores_40.AverageNoDnp} powerAdj={false} period={40} played={p.scores_40.GamesPlayed} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withUser(PlayerNoCard);
