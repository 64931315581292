import React from "react";
import LineSeparatorDark from "../../img/line-separator-4d.svg";
import LineSeparator from "../../img/line-separator.svg";

import { sorareBaseball, sorareBasketball, sorareFootball } from "./sports";
import { useTheme } from "../../themeContext";
import { sdDark } from "../../themeColors";

function SportsPicker(props) {
  const picked = props.sport;
  const { theme } = useTheme();
  const dark = props.dark || theme === sdDark;
  const rounded = props.rounded || "rounded-full";

  const pickedStyle = props.picked || "text-primary";
  const notPickedStyle = "text-on-surface-variant hover:text-primary";
  const noBaseball = props.noBaseball;
  const font = props.font || "font-headers";
  const bg = props.bg || "bg-surface-container";
  return (
    <div className={font + " text-center font-semibold " + bg + " flex flex-row self-center space-x-6 text-sm px-6 py-2 " + rounded}>
      {props.showAll === true && (
        <div className={"cursor-pointer self-center"} onClick={() => props.change("all")}>
          <p className={picked === "all" ? pickedStyle : notPickedStyle}>All</p>
        </div>
      )}
      {props.showAll === true && <img src={dark ? LineSeparatorDark : LineSeparator} />}
      <div className={"cursor-pointer self-center"} onClick={() => props.change(sorareFootball)}>
        <p className={picked === sorareFootball ? pickedStyle : notPickedStyle}>Football</p>
      </div>
      <img src={dark ? LineSeparatorDark : LineSeparator} />
      <div className={"cursor-pointer self-center"} onClick={() => props.change(sorareBasketball)}>
        <p className={picked === sorareBasketball ? pickedStyle : notPickedStyle}>Basketball</p>
      </div>
      {!noBaseball && <img src={dark ? LineSeparatorDark : LineSeparator} />}
      {!noBaseball && (
        <div className={"cursor-pointer self-center"} onClick={() => props.change(sorareBaseball)}>
          <p className={picked === sorareBaseball ? pickedStyle : notPickedStyle}>Baseball</p>
        </div>
      )}
    </div>
  );
}

export default SportsPicker;
