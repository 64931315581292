import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import { getTotalScoreColors } from "./helpers/getTotalScoreColors";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import Ball from "../../img/ICN_Ballon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import CleanSheet from "../../img/cleansheet.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Watermark from "../../img/watermark-modal.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import PenaltySave from "../../img/PenaltySave.svg";
import MainDetailedStatLine from "./mainDetailedStatLine";
import ordinal_suffix_of from "../util/ordinalNumber";
import LineSeparator from "../../img/line-separator.svg";
import ScoreEllipse from "../util/scoreEllipse";
import GamePreview from "../games/gamePreview";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import UnknownPlayer from "../util/unknownPlayer";
import { AllAroundScore } from "../util/allAroundScore";
import { DecisiveActionsScore } from "../util/decisiveScore";
import { format } from "date-fns";
import { getThemeColor, useTheme } from "../../themeContext";

function GeneralDetailedStats(props) {
  const category = props.category;
  let totalCategory = 0;
  const stats = props.stats;
  const position = props.position || stats.Position;
  const newMatrix = props.newMatrix;
  if (position === "Goalkeeper") {
    totalCategory =
      stats.YellowCard * -3 +
      stats.Fouls * 0 +
      stats.WasFouled * 0 +
      stats.ErrorLeadToShot * -5 +
      stats.DoubleDouble * 4 +
      stats.TripleDouble * 6 +
      stats.TripleTriple * 12;
  } else if (position === "Defender") {
    totalCategory =
      stats.CleanSheet * 10 +
      stats.YellowCard * -3 +
      stats.Fouls * -1 +
      stats.WasFouled * 0 -
      stats.ErrorLeadToShot +
      stats.DoubleDouble * 4 +
      stats.TripleDouble * 6 +
      stats.TripleTriple * 12;

    if (newMatrix) {
      totalCategory =
        stats.CleanSheet * 10 +
        stats.YellowCard * -3 +
        stats.Fouls * -2 +
        stats.WasFouled * 0 -
        stats.ErrorLeadToShot * 5 +
        stats.DoubleDouble * 4 +
        stats.TripleDouble * 6 +
        stats.TripleTriple * 12;
    }
  } else if (position === "Midfielder") {
    totalCategory =
      stats.YellowCard * -3 +
      stats.Fouls * -0.5 +
      stats.WasFouled * 0.5 -
      stats.ErrorLeadToShot +
      stats.DoubleDouble * 4 +
      stats.TripleDouble * 6 +
      stats.TripleTriple * 12;
    if (newMatrix) {
      totalCategory =
        stats.YellowCard * -3 +
        stats.Fouls * -1 +
        stats.WasFouled * 0.5 -
        stats.ErrorLeadToShot * 3 +
        stats.DoubleDouble * 4 +
        stats.TripleDouble * 6 +
        stats.TripleTriple * 12;
    }
  } else if (position === "Forward") {
    totalCategory =
      stats.YellowCard * -3 +
      stats.Fouls * 0 +
      stats.WasFouled * 1 -
      stats.ErrorLeadToShot +
      stats.DoubleDouble * 4 +
      stats.TripleDouble * 6 +
      stats.TripleTriple * 12;
    if (newMatrix) {
      totalCategory =
        stats.YellowCard * -3 +
        stats.Fouls * -0.5 +
        stats.WasFouled * 1 -
        stats.ErrorLeadToShot * 3 +
        stats.DoubleDouble * 4 +
        stats.TripleDouble * 6 +
        stats.TripleTriple * 12;
    }
  }
  return (
    <div className={"rounded-xl overflow-hidden w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={category} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl overflow-hidden" : "bg-on-surface rounded-xl overflow-hidden"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine
          name="Yellow card"
          value={stats.YellowCard}
          gk={-3}
          def={-3}
          mid={-3}
          fwd={-3}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Fouls"
          value={stats.Fouls}
          gk={0}
          def={newMatrix ? -2 : -1}
          mid={newMatrix ? -1 : -0.5}
          fwd={newMatrix ? -0.5 : 0}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Fouled"
          value={stats.WasFouled}
          gk={0}
          def={0}
          mid={newMatrix ? 1 : 0.5}
          fwd={1}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Clean sheet (60+ mins played)"
          value={stats.CleanSheet}
          gk={0}
          def={10}
          mid={0}
          fwd={0}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Double double"
          value={stats.DoubleDouble}
          gk={0}
          def={4}
          mid={4}
          fwd={4}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Triple double"
          value={stats.TripleDouble}
          gk={0}
          def={6}
          mid={6}
          fwd={6}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Triple triple"
          value={stats.TripleTriple}
          gk={0}
          def={12}
          mid={12}
          fwd={12}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Error led to shot"
          value={stats.ErrorLeadToShot}
          gk={-5}
          def={newMatrix ? -5 : -1}
          mid={newMatrix ? -3 : -1}
          fwd={newMatrix ? -3 : -1}
          position={position}
          dark={props.dark}
          last
        />
      </div>
    </div>
  );
}

function DefenseDetailedStats(props) {
  const category = props.category;
  let totalCategory = 0;
  const stats = props.stats;
  const position = props.position || stats.Position;
  const newMatrix = props.newMatrix;
  if (position === "Defender") {
    totalCategory =
      stats.GoalsConceded * -2 + stats.EffectiveClearance * 0.5 + stats.WonTackle * 3 + stats.BlockedCross + stats.OutfielderBlock * 2;
    if (newMatrix) {
      totalCategory =
        stats.GoalsConceded * -4 + stats.EffectiveClearance * 0.5 + stats.WonTackle * 3 + stats.BlockedCross + stats.OutfielderBlock * 2;
    }
  } else if (position === "Midfielder") {
    totalCategory = stats.GoalsConceded * -2 + stats.WonTackle * 3 + stats.OutfielderBlock + stats.BlockedCross;
  } else if (position === "Forward") {
    totalCategory = 0;
  }
  return (
    <div className={" w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={category} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl" : "bg-white rounded-xl"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine
          name="Goals conceded"
          value={stats.GoalsConceded}
          gk={newMatrix ? -3 : 0}
          def={newMatrix ? -4 : -2}
          mid={-2}
          fwd={0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Effective clearance"
          value={stats.EffectiveClearance}
          gk={0}
          def={0.5}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine name="Won tackle" value={stats.WonTackle} gk={0} def={3} mid={3} fwd={0} dark={props.dark} position={position} />
        <DetailedStatLine
          name="Blocked cross"
          value={stats.BlockedCross}
          gk={0}
          def={1}
          mid={1}
          fwd={0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Block"
          value={stats.OutfielderBlock}
          gk={0}
          def={2}
          mid={1}
          fwd={0}
          dark={props.dark}
          position={position}
          last
        />
      </div>
    </div>
  );
}

function PossessionDetailedStats(props) {
  const category = props.category;
  let totalCategory = 0;
  const stats = props.stats;
  const position = props.position || stats.Position;
  const newMatrix = props.newMatrix;
  if (position === "Goalkeeper") {
    totalCategory = stats.PossLostCtrl * -0.3 + stats.MissedPass * -0.2 + stats.AccurateLongBalls * 0.2;
    if (newMatrix) {
      totalCategory =
        stats.PossLostCtrl * -0.3 +
        stats.MissedPass * -0.2 +
        stats.AccurateLongBalls * 0.2 +
        stats.AccuratePass * 0.1 +
        stats.SuccessfulFinalThirdPasses * 0.5;
    }
  } else if (position === "Defender") {
    totalCategory =
      stats.PossLostCtrl * -1 +
      stats.PossWon * 0.5 +
      stats.DuelLost * -2 +
      stats.DuelWon * 1.5 +
      stats.InterceptionWon * 3 +
      stats.AccuratePass * 0.1 +
      stats.SuccessfulFinalThirdPasses * 0.5 +
      stats.AccurateLongBalls * 0.5 +
      stats.LongPassToOpposite * 0.5 +
      stats.MissedPass * -0.2;
    if (newMatrix) {
      totalCategory =
        stats.PossLostCtrl * -0.6 +
        stats.PossWon * 0.5 +
        stats.DuelLost * -2 +
        stats.DuelWon * 1.5 +
        stats.InterceptionWon * 3 +
        stats.AccuratePass * 0.08 +
        stats.SuccessfulFinalThirdPasses * 0.4 +
        stats.AccurateLongBalls * 0.5 +
        stats.LongPassToOpposite * 0.5 +
        stats.MissedPass * -0.2;
    }
  } else if (position === "Midfielder") {
    totalCategory =
      stats.PossLostCtrl * -0.5 +
      stats.PossWon * 0.5 +
      stats.DuelLost * -0.5 +
      stats.DuelWon * 0.5 +
      stats.InterceptionWon * 2 +
      stats.AccuratePass * 0.1 +
      stats.SuccessfulFinalThirdPasses * 0.3 +
      stats.AccurateLongBalls * 0.5 +
      stats.MissedPass * -0.2;
    if (newMatrix) {
      totalCategory =
        stats.PossLostCtrl * -0.5 +
        stats.PossWon * 0.5 +
        stats.DuelLost * -0.8 +
        stats.DuelWon * 0.8 +
        stats.InterceptionWon * 3 +
        stats.AccuratePass * 0.1 +
        stats.SuccessfulFinalThirdPasses * 0.3 +
        stats.AccurateLongBalls * 0.5 +
        stats.MissedPass * -0.3;
    }
  } else if (position === "Forward") {
    totalCategory =
      stats.PossLostCtrl * -0.1 +
      stats.DuelLost * -0.5 +
      stats.DuelWon * 0.5 +
      stats.AccuratePass * 0.1 +
      stats.SuccessfulFinalThirdPasses * 0.1;
    if (newMatrix) {
      totalCategory =
        stats.PossLostCtrl * -0.1 +
        stats.DuelLost * -1 +
        stats.DuelWon * 1 +
        stats.AccuratePass * 0.1 +
        stats.InterceptionWon * 3 +
        stats.SuccessfulFinalThirdPasses * 0.1;
    }
  }
  return (
    <div className={" w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={category} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl" : "bg-white rounded-xl"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine
          name="Possession lost"
          value={stats.PossLostCtrl}
          gk={-0.3}
          def={newMatrix ? -0.6 : -1}
          mid={-0.5}
          fwd={-0.1}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Possession won"
          value={stats.PossWon}
          gk={0}
          def={0.5}
          mid={0.5}
          fwd={0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Duel lost"
          value={stats.DuelLost}
          gk={0}
          def={-2}
          mid={newMatrix ? -0.8 : -0.5}
          fwd={newMatrix ? -1 : -0.5}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Duel won"
          value={stats.DuelWon}
          gk={0}
          def={1.5}
          mid={newMatrix ? 0.8 : 0.5}
          fwd={newMatrix ? 1 : 0.5}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Interception"
          value={stats.InterceptionWon}
          gk={0}
          def={3}
          mid={newMatrix ? 3 : 2}
          fwd={newMatrix ? 3 : 0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Accurate pass"
          value={stats.AccuratePass}
          gk={newMatrix ? 0.1 : 0}
          def={newMatrix ? 0.08 : 0.1}
          mid={0.1}
          fwd={0.1}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Accurate final third pass"
          value={stats.SuccessfulFinalThirdPasses}
          gk={newMatrix ? 0.5 : 0}
          def={newMatrix ? 0.4 : 0.5}
          mid={0.3}
          fwd={0.1}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Accurate long balls"
          value={stats.AccurateLongBalls}
          gk={0.2}
          def={0.5}
          mid={0.5}
          fwd={0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Long pass into opposition"
          value={stats.LongPassToOpposite}
          gk={0}
          def={0.5}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Missed pass"
          value={stats.MissedPass}
          gk={-0.2}
          def={-0.2}
          mid={newMatrix ? -0.3 : -0.2}
          fwd={0}
          dark={props.dark}
          position={position}
          last
        />
      </div>
    </div>
  );
}

function GoalkeepingDetailedStats(props) {
  const category = props.category;
  const stats = props.stats;
  const position = props.position || stats.Position;
  const newMatrix = props.newMatrix;
  let totalCategory =
    stats.SavedIbox * 2 +
    stats.GoodHighClaim * 1.5 +
    stats.Punches * 1.5 +
    stats.Saves * 2 +
    stats.DiveSave * 3 +
    stats.DiveCatch * 3.5 +
    stats.CrossNotClaimed * -3 +
    stats.GkSmother * 5 +
    stats.AccurateKeeperSweeper * 3 +
    stats.GoalsConceded * -3;
  if (!newMatrix) {
    totalCategory =
      stats.SavedIbox +
      stats.GoodHighClaim * 1.2 +
      stats.Punches * 1.2 +
      stats.Saves * 2 +
      stats.DiveSave * 3 +
      stats.DiveCatch * 3.5 +
      stats.CrossNotClaimed * -5 +
      stats.GkSmother * 5 +
      stats.AccurateKeeperSweeper * 5;
  }
  return (
    <div className={" w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={category} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl" : "bg-white rounded-xl"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine
          name="Goals conceded"
          value={stats.GoalsConceded}
          gk={newMatrix ? -3 : 0}
          def={newMatrix ? -4 : -2}
          mid={-2}
          fwd={0}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine name="Saves" value={stats.Saves} position={"Goalkeeper"} gk={2} def={0} mid={0} fwd={0} dark={props.dark} />
        <DetailedStatLine
          name="Saved shot from inside the box"
          value={stats.SavedIbox}
          position={"Goalkeeper"}
          gk={newMatrix ? 2 : 1}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
        />
        <DetailedStatLine
          name="Good high claim"
          value={stats.GoodHighClaim}
          gk={newMatrix ? 1.5 : 1.2}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={"Goalkeeper"}
        />
        <DetailedStatLine
          name="Punches"
          value={stats.Punches}
          gk={newMatrix ? 1.5 : 1.2}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={"Goalkeeper"}
        />
        <DetailedStatLine
          name="Diving save"
          value={stats.DiveSave}
          gk={3}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={"Goalkeeper"}
        />
        <DetailedStatLine
          name="Diving catch"
          value={stats.DiveCatch}
          gk={3.5}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={"Goalkeeper"}
        />
        <DetailedStatLine
          name="Cross not claimed"
          value={stats.CrossNotClaimed}
          gk={newMatrix ? -3 : -5}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={"Goalkeeper"}
        />
        <DetailedStatLine
          name="GK smother"
          value={stats.GkSmother}
          gk={5}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={"Goalkeeper"}
        />
        <DetailedStatLine
          name="Keeper sweeper"
          value={stats.AccurateKeeperSweeper}
          gk={newMatrix ? 3 : 5}
          def={0}
          mid={0}
          fwd={0}
          dark={props.dark}
          position={"Goalkeeper"}
          last
        />
      </div>
    </div>
  );
}

function AttackingDetailedStats(props) {
  const category = props.category;
  const stats = props.stats;
  const position = props.position || stats.Position;
  const newMatrix = props.newMatrix;
  let totalCategory = 0;
  if (position === "Goalkeeper") {
    totalCategory =
      stats.AdjustedOnTargetScoringAtt * 3 +
      stats.PenaltyKickMissed * -5 +
      stats.BigChanceMissed * -5 +
      stats.BigChanceCreated * 3 +
      stats.AdjustedTotalAttAssist * 2;
  } else if (position === "Defender") {
    totalCategory =
      stats.AdjustedOnTargetScoringAtt * 3 +
      stats.WonContest * 0.5 +
      stats.PenAreaEntries * 0.5 +
      stats.PenaltyKickMissed * -5 +
      stats.BigChanceMissed * -5 +
      stats.BigChanceCreated * 3 +
      stats.AdjustedTotalAttAssist * 2;
    if (newMatrix) {
      totalCategory =
        stats.AdjustedOnTargetScoringAtt * 3 +
        stats.WonContest * 0.5 +
        stats.PenAreaEntries * 0.5 +
        stats.PenaltyKickMissed * -5 +
        stats.BigChanceMissed * -5 +
        stats.BigChanceCreated * 3 +
        stats.AdjustedTotalAttAssist * 3;
    }
  } else if (position === "Midfielder") {
    totalCategory =
      stats.AdjustedOnTargetScoringAtt * 3 +
      stats.WonContest * 0.5 +
      stats.PenAreaEntries * 0.5 +
      stats.PenaltyKickMissed * -5 +
      stats.BigChanceMissed * -5 +
      stats.BigChanceCreated * 3 +
      stats.AdjustedTotalAttAssist * 2;
  } else if (position === "Forward") {
    totalCategory =
      stats.AdjustedOnTargetScoringAtt * 3 +
      stats.WonContest * 0.5 +
      stats.PenAreaEntries * 0.5 +
      stats.PenaltyKickMissed * -5 +
      stats.BigChanceMissed * -5 +
      stats.BigChanceCreated * 3 +
      stats.AdjustedTotalAttAssist * 2;
  }
  return (
    <div className={" w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={category} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl" : "bg-white rounded-xl"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine
          name="Shot on target"
          value={stats.AdjustedOnTargetScoringAtt}
          gk={3}
          def={3}
          mid={3}
          fwd={3}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Won contest"
          value={stats.WonContest}
          gk={0}
          def={0.5}
          mid={0.5}
          fwd={0.5}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Big chance created"
          value={stats.BigChanceCreated}
          gk={3}
          def={3}
          mid={3}
          fwd={3}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Attempted assist"
          value={stats.AdjustedTotalAttAssist}
          gk={2}
          def={newMatrix ? 3 : 2}
          mid={2}
          fwd={2}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Penalty area entries"
          value={stats.PenAreaEntries}
          gk={0}
          def={0.5}
          mid={0.5}
          fwd={0.5}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Penalty kick missed"
          value={stats.PenaltyKickMissed}
          gk={-5}
          def={-5}
          mid={-5}
          fwd={-5}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Big chance missed"
          value={stats.BigChanceMissed}
          gk={-5}
          def={-5}
          mid={-5}
          fwd={-5}
          dark={props.dark}
          position={position}
          last
        />
      </div>
    </div>
  );
}

function BattingDetailedStats(props) {
  const category = props.category;
  const stats = props.stats;
  const position = props.position || stats.Position;
  let totalCategory = 0;
  totalCategory =
    stats.Run * 3 +
    stats.Rbi * 3 +
    stats.Single * 2 +
    stats.Double * 5 +
    stats.Triple * 8 +
    stats.HomeRun * 10 +
    stats.Walk * 2 +
    stats.BattingStrikeout * -1 +
    stats.StolenBase * 5 +
    stats.Hbp * 2;

  return (
    <div className={" w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={category} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl" : "bg-white rounded-xl"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine name="Run scored" value={stats.Run} all={3} position={position} dark={props.dark} />
        <DetailedStatLine name="Run batted in" value={stats.Rbi} all={3} position={position} dark={props.dark} />
        <DetailedStatLine name="Single" value={stats.Single} all={2} position={position} dark={props.dark} />
        <DetailedStatLine name="Double" value={stats.Double} all={5} position={position} dark={props.dark} />
        <DetailedStatLine name="Triple" value={stats.Triple} all={8} position={position} dark={props.dark} />
        <DetailedStatLine name="Home run" value={stats.HomeRun} all={10} position={position} dark={props.dark} />
        <DetailedStatLine name="Walk" value={stats.Walk} all={2} position={position} dark={props.dark} />
        <DetailedStatLine name="Strikeout" value={stats.BattingStrikeout} all={-1} position={position} dark={props.dark} />
        <DetailedStatLine name="Stolen base" value={stats.StolenBase} all={5} position={position} dark={props.dark} />
        <DetailedStatLine name="Hit by pitch" value={stats.Hbp} all={2} dark={props.dark} position={position} last />
      </div>
    </div>
  );
}

function PitchingDetailedStats(props) {
  const category = props.category;
  const stats = props.stats;
  const position = props.position || stats.Position;
  let totalCategory = 0;
  totalCategory =
    stats.EliminatedBatters +
    stats.Strikeout * 2 +
    stats.HitAllowed * -0.5 +
    stats.EarnedRun * -2 +
    stats.WalkAllowed * -1 +
    stats.HbpIssued * -1 +
    stats.Win * 5 +
    stats.Hold * 5 +
    stats.Save * 10;

  return (
    <div className={" w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={category} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl" : "bg-white rounded-xl"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine
          name="Innings pitched"
          value={stats.InningsPitched.toFixed(1)}
          all={3}
          total={stats.EliminatedBatters}
          position={position}
          dark={props.dark}
        />
        <DetailedStatLine name="Strikeout" value={stats.Strikeout} all={2} position={position} dark={props.dark} />
        <DetailedStatLine name="Hit allowed" value={stats.HitAllowed} all={-0.5} position={position} dark={props.dark} />
        <DetailedStatLine name="Earned run" value={stats.EarnedRun} all={-2} position={position} dark={props.dark} />
        <DetailedStatLine name="Walk" value={stats.WalkAllowed} all={-1} position={position} dark={props.dark} />
        <DetailedStatLine name="Hit by pitch" value={stats.HbpIssued} all={-1} position={position} dark={props.dark} />
        <DetailedStatLine name="Win" value={stats.Win} all={5} position={position} dark={props.dark} />
        <DetailedStatLine name="Hold" value={stats.Hold} all={5} position={position} dark={props.dark} />
        <DetailedStatLine name="Save" value={stats.Save} all={10} position={position} dark={props.dark} last />
      </div>
    </div>
  );
}

function BasketballDetailedStats(props) {
  const category = props.category;
  const stats = props.stats;
  const position = props.position || stats.Position;
  let totalCategory = 0;
  totalCategory =
    stats.points +
    stats.totalRebounds * 1.2 +
    stats.assists * 1.5 +
    stats.blocks * 3 +
    stats.steals * 3 +
    stats.turnovers * -2 +
    stats.threePtMade;
  if (stats.doubleDouble) {
    totalCategory += 1;
  }
  if (stats.tripleDouble) {
    totalCategory += 1;
  }

  return (
    <div className={" w-11/12 mx-auto space-y-2"}>
      <MainStatLine dark={props.dark} category={""} total={totalCategory} />
      <div className={props.dark ? "bg-black-1f rounded-xl" : "bg-white rounded-xl"}>
        <HeaderStatsLine dark={props.dark} />
        <DetailedStatLine name="Points (PT)" value={stats.points} all={1} dark={props.dark} position={position} />
        <DetailedStatLine name="Made 3pt FG" value={stats.threePtMade} all={1} dark={props.dark} position={position} />
        <DetailedStatLine
          name="Rebounds (REB)"
          value={stats.totalRebounds}
          all={1.2}
          dark={props.dark}
          total={(stats.totalRebounds * 1.2).toFixed(1)}
          position={position}
        />
        <DetailedStatLine name="Assists (AST)" value={stats.assists} all={1.5} dark={props.dark} position={position} />
        <DetailedStatLine name="Blocks (BLK)" value={stats.blocks} all={3} dark={props.dark} position={position} />
        <DetailedStatLine name="Steals (STL)" value={stats.steals} all={3} dark={props.dark} position={position} />
        <DetailedStatLine name="Turnovers (TO)" value={stats.turnovers} all={-2} dark={props.dark} position={position} />
        <DetailedStatLine
          name="Double-double (DD)"
          value={stats.doubleDouble === true ? 1 : 0}
          all={1}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine
          name="Triple-double (TD)"
          value={stats.tripleDouble === true ? 1 : 0}
          all={1}
          dark={props.dark}
          position={position}
        />
        <DetailedStatLine name="Fouls" value={stats.fouls} all={0} dark={props.dark} position={position} last />
      </div>
    </div>
  );
}

function MainStatLine(props) {
  let color = "text-primary";
  if (props.dark) {
    color = "text-brand-dark";
  }
  const total = props.total;
  if (total === 0) {
    color = "text-on-surface";
    if (props.dark) {
      color = "text-white";
    }
  } else if (total > 0) {
    color = "text-success-green";
    if (props.dark) {
      color = "text-green-dark";
    }
  }
  let bg = "bg-surface-container";
  if (props.dark) {
    bg = "bg-black-1f";
  }
  return (
    <div className={bg + " flex px-10 flex-row py-4 rounded-xl"}>
      <div className={"w-6/12 self-center"}>
        <p
          className={
            props.dark
              ? "uppercase text-sm self-center font-semibold text-[#CCCCCC]"
              : "uppercase text-sm self-center font-semibold text-on-surface-variant"
          }
        >
          Total {props.category}
        </p>
      </div>
      <div className={"w-6/12 self-center"}>
        <p className={"text-right self-center text-2xl font-semibold " + color}>{props.total.toFixed(1)}</p>
      </div>
    </div>
  );
}

function DetailedStatLine(props) {
  const { name, value, gk, def, mid, fwd, all, position, main, last } = props;
  let points = 0;
  if (position === "Goalkeeper") {
    points = gk * value;
  } else if (position === "Defender") {
    points = def * value;
  } else if (position === "Midfielder") {
    points = mid * value;
  } else if (position === "Forward") {
    points = fwd * value;
  } else {
    points = all * value;
  }
  let color = "text-primary";
  if (props.dark) {
    color = "text-brand-dark";
  }
  if (points === 0) {
    color = "text-on-surface";
    if (props.dark) {
      color = "text-[#CCCCCC]";
    }
  } else if (points > 0) {
    color = "text-success-green";
    if (props.dark) {
      color = "text-green-dark";
    }
  }
  let border = "border-transparent-inverse-surface-low border-opacity-10";
  if (props.dark) {
    border = "border-b border-black-4D";
  }
  if (props.last) {
    border = " rounded-b-xl";
  }
  let total = Number.isInteger(points) ? points : points.toFixed(1);
  if (props.total) {
    total = props.total;
  }
  let bg = "bg-surface-container";
  if (props.dark) {
    bg = "bg-black-1f";
  }
  let centerColor = "text-on-surface";
  if (props.dark) {
    centerColor = "text-[#CCCCCC]";
  }
  let leftColor = "text-on-surface";
  if (props.dark) {
    leftColor = "text-[#E6E6E6]";
  }
  return (
    <div className={bg + " flex flex-row w-full justify-center py-1.5 " + border}>
      <div className={"w-2/12 self-center"}>
        <p className={leftColor + " text-left text-sm font-semibold"}>{props.value}</p>
      </div>
      <div className={"w-6/12 self-center"}>
        <p className={centerColor + " text-center text-sm items-end"}>{props.name}</p>
      </div>
      <div className={"w-2/12 self-center"}>
        <p className={"text-right text-sm font-semibold " + color}>{total}</p>
      </div>
    </div>
  );
}

function HeaderStatsLine(props) {
  let bg = "bg-surface-container";
  let textColor = "text-on-surface-variant";
  if (props.dark) {
    bg = "bg-black-1f";
    textColor = "text-[#CCCCCC]";
  }
  return (
    <div className={bg + " flex flex-row w-full justify-between px-10 py-3 rounded-t-xl"}>
      <div className={"w-2/12"}>
        <p className={"text-left text-xs font-semibold " + textColor}>STATS</p>
      </div>
      <div className={"w-2/12 self-center"}>
        <p className={"text-right text-xs font-semibold " + textColor}>POINTS</p>
      </div>
    </div>
  );
}

function ScoreGaugeModal(props) {
  let { score, aaScore, decisiveScore, sport } = props;
  const { theme } = useTheme();
  let display = score?.toFixed(1);
  if (sport === sorareFootball) {
    if (score === undefined || score < 0) {
      score = 0;
      display = "NA";
    } else if (score === 100) {
      display = "100";
    } else if (score === 0) {
      display = "0";
    }
  }

  const [scoreColor, scoreBackgroundColor] = getTotalScoreColors(score);

  const stroke = scoreBackgroundColor;
  let background = getThemeColor(theme, "transparent-inverse-surface-low");
  if (props.dark) {
    background = "#333333";
  }

  if (sport === sorareBaseball || sport === sorareBasketball) {
    return (
      <div className={"w-24 self-center"}>
        <BaseballScoreCircle dark={props.dark} score={score} sport={sport} />
      </div>
    );
  } else {
    return (
      <div className={"w-28"} style={{ height: "48px" }}>
        <CircularProgressbarWithChildren
          value={score}
          className={""}
          circleRatio={0.5}
          strokeWidth={6}
          styles={buildStyles({
            rotation: 3 / 4,
            strokeLinecap: "round",
            pathColor: scoreColor,
            trailColor: background,
          })}
        >
          <CircularProgressbar
            value={score > 0 ? (decisiveScore / (decisiveScore + aaScore)) * score : 0}
            circleRatio={0.5}
            strokeWidth={6}
            styles={buildStyles({
              rotation: 3 / 4,
              strokeLinecap: "round",
              pathColor: scoreBackgroundColor,
            })}
          />
          <p
            className={props.dark ? "text-[#E6E6E6] font-bold text-2.5xl -mt-8" : "font-bold text-2.5xl -mt-8 text-on-surface-variant"}
            style={{ position: "absolute" }}
          >
            {display}
          </p>
        </CircularProgressbarWithChildren>
      </div>
    );
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function PlayerScoreModal(props) {
  const [open, setOpen] = useState(false);
  const [game, setGame] = useState(props.game);
  const [activeTab, setActiveTab] = useState(
    props.sport === sorareFootball
      ? props.stats.Position === "Goalkeeper" || props.position === "Goalkeeper"
        ? "Goalkeeping"
        : "General"
      : props.sport === sorareBasketball
      ? "Basketball"
      : props.stats.Position === "SP" || props.stats.Position === "RP"
      ? "Pitching"
      : "Batting",
  );

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      getGameDetails();
    }
  }, [props.open]);

  useEffect(() => {
    setGame(props.game);
  }, [props.game]);

  const sport = props.sport;

  const getGameDetails = () => {
    if (props.stats && props.game === undefined && props.stats.GameId !== undefined) {
      props
        .fetch("/apiv2/games/gameDetails/" + props.stats.GameId)
        .then((response) => response.json())
        .then((res) => {
          setGame(res);
        })
        .catch(errorCatcher());
    } else {
      setGame(props.game);
    }
  };

  const stats = props.stats;
  const player = props.player || props.stats;
  const lineups = props.lineups;
  const position = props.position || stats.Position || props.player?.Position;

  let allAroundScore = stats.AllAroundScore;
  let level = stats.Level;
  let score = parseFloat(stats.SO5Score);

  const newMatrix = true;

  if (position === "Goalkeeper") {
    level = stats.LevelGK;
    allAroundScore = stats.AaGK;
    score = parseFloat(stats.SO5ScoreGK);
    if (newMatrix) {
      score = parseFloat(stats.NewScoreGK);
      level = stats.NewLevelGK;
      allAroundScore = stats.NewAaGK;
    }
  } else if (position === "Defender") {
    level = stats.LevelFD;
    allAroundScore = stats.AaDF;
    score = parseFloat(stats.SO5ScoreDF);
    if (newMatrix) {
      score = parseFloat(stats.NewScoreDF);
      level = stats.NewLevelFD;
      allAroundScore = stats.NewAaDF;
    }
  } else if (position === "Midfielder") {
    level = stats.LevelFD;
    allAroundScore = stats.AaMD;
    score = parseFloat(stats.SO5ScoreMD);
    if (newMatrix) {
      score = parseFloat(stats.NewScoreMD);
      level = stats.NewLevelFD;
      allAroundScore = stats.NewAaMD;
    }
  } else if (position === "Forward") {
    level = stats.LevelFD;
    allAroundScore = stats.AaFW;
    score = parseFloat(stats.SO5ScoreFW);
    if (newMatrix) {
      score = parseFloat(stats.NewScoreFW);
      level = stats.NewLevelFD;
      allAroundScore = stats.NewAaFW;
    }
  }

  let positive = 0;
  let negative = 0;

  if (sport === sorareBaseball) {
    score = parseFloat(stats.Score);
    negative +=
      stats.BattingStrikeout * -1 + stats.HitAllowed * -0.5 + stats.EarnedRun * -2 + stats.WalkAllowed * -1 + stats.HbpIssued * -1;
    positive += stats.EliminatedBatters + stats.Strikeout * 2 + stats.Win * 5 + stats.Save * 10 + stats.Hold * 5;
    positive +=
      stats.Run * 3 +
      stats.Rbi * 3 +
      stats.Single * 2 +
      stats.Double * 5 +
      stats.Triple * 8 +
      stats.HomeRun * 10 +
      stats.Walk * 2 +
      stats.StolenBase * 5 +
      stats.Hbp * 2;
  }

  if (sport === sorareBasketball) {
    score = parseFloat(stats.score);
  }

  let tabs = ["General", "Defense", "Possession", "Attack"];
  if (sport === sorareBaseball) {
    tabs = ["Batting", "Pitching"];
  } else {
    if (stats.Position === "Goalkeeper" || props.position === "Goalkeeper") {
      tabs = ["General", "Goalkeeping", "Possession", "Attack"];
    }
  }

  let hasDecisives = false;
  let bg = props.bg || "bg-surface-container-high";
  let insideBg = "bg-surface-container";
  if (props.dark) {
    bg = "bg-[#292929]";
    insideBg = "bg-black-1f";
  }

  if (sport === sorareFootball) {
    hasDecisives =
      stats?.Goals > 0 ||
      stats?.AdjustedGoalAssist > 0 ||
      stats?.AssistPenaltyWon > 0 ||
      stats?.ClearanceOffLine > 0 ||
      (stats?.CleanSheet > 0 && stats.Position === "Goalkeeper") ||
      stats?.PenaltySave + stats?.PenaltySaves > 0 ||
      stats?.LastManTackle > 0 ||
      stats?.RedCard > 0 ||
      stats?.OwnGoals > 0 ||
      stats?.PenaltyConceded > 0 ||
      stats?.ErrorLeadToGoal > 0;
  }
  const gwNumber = game?.sorareGwNumber || (game?.gwNumber ? game.gwNumber : game?.game?.GwNumber);
  const gameTime = game?.game?.GameTime ? format(new Date(game.game.GameTime), "LLL do, yyyy") : undefined;
  if (open) {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          style={{ zIndex: 1000 }}
          className="fixed inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
            setGame(undefined);
          }}
        >
          <div className={" flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={
                  bg +
                  " font-sans inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
                }
              >
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <div
                    className={"flex flex-row cursor-pointer"}
                    onClick={() => {
                      props.close();
                      setOpen(false);
                    }}
                  >
                    <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
                  </div>
                </div>
                <div className={bg + " sm:flex sm:items-start flex flex-col space-y-4"}>
                  <div className={"flex flex-col align-middle items-center mx-auto space-y-2 "}>
                    <div className={"w-20 h-20"}>
                      {player.Avatar !== "" && (
                        <img
                          className={
                            sport !== sorareFootball
                              ? "w-full h-full rounded-full object-cover " + insideBg
                              : "w-full h-full rounded-full object-contain pt-2 " + insideBg
                          }
                          src={player.Avatar}
                        />
                      )}
                      {player.Avatar === "" && <UnknownPlayer size={"w-full h-full"} bg={insideBg} />}
                    </div>
                    <p
                      className={
                        props.dark
                          ? "font-semibold text-[#E6E6E6] text-base cursor-pointer hover:font-bold"
                          : "font-semibold text-on-surface text-base cursor-pointer hover:font-bold"
                      }
                    >
                      <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                        {player.DisplayName}
                      </a>
                    </p>
                    <p className={props.dark ? "font-medium text-[#CCCCCC] text-md" : "font-medium text-on-surface-variant text-md"}>
                      {position} - {player.Age}
                    </p>
                  </div>
                  {game && (
                    <div className={"flex flex-col align-middle items-center mx-auto"}>
                      <GamePreview
                        textColor={props.dark ? "text-white" : "text-on-surface"}
                        dark
                        sport={props.sport || player.Sport}
                        game={game}
                      />
                      {gwNumber && (
                        <span className={props.dark ? "font-medium text-[#CCCCCC] text-sm" : "font-medium text-on-surface-variant text-sm"}>
                          GW #{gwNumber}
                          {gameTime ? " - " + gameTime : ""}
                        </span>
                      )}
                    </div>
                  )}
                  {sport === sorareFootball && (
                    <div className={insideBg + " w-11/12 px-4 py-4 mx-auto rounded-xl flex flex-col space-y-4"}>
                      <div
                        className={
                          hasDecisives ? "absolute w-10/12 self-center justify-center" : "absolute w-10/12 -my-4 self-center justify-center"
                        }
                      >
                        <img className={"self-center justify-center mx-auto h-full align-middle"} src={Watermark} />
                      </div>
                      <div className={"flex flex-row justify-center w-10/12 mx-auto"}>
                        <div className={"w-2/12 space-y-2"}>
                          <DecisiveActionsScore score={level} dark={props.dark} />
                          <p
                            className={
                              props.dark
                                ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                            }
                          >
                            Decisive score
                          </p>
                        </div>
                        <div className={"flex flex-col space-y-3 items-center w-6/12"}>
                          <ScoreGaugeModal score={score} aaScore={allAroundScore} dark={props.dark} decisiveScore={level} sport={sport} />
                          <p
                            className={
                              props.dark
                                ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                            }
                          >
                            in {stats.mins_played} minutes
                          </p>
                        </div>
                        <div className={"w-2/12 space-y-2"}>
                          <AllAroundScore score={allAroundScore} />
                          <p
                            className={
                              props.dark
                                ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                            }
                          >
                            All-around score
                          </p>
                        </div>
                      </div>
                      <div className={"flex flex-row justify-center space-x-4"}>
                        {stats?.Goals > 0 && <MainDetailedStatLine stat="Goals" dark={props.dark} value={stats?.Goals} icon={Ball} />}
                        {stats?.AdjustedGoalAssist > 0 && (
                          <MainDetailedStatLine stat="Assists" dark={props.dark} value={stats?.AdjustedGoalAssist} icon={AssistIcon} />
                        )}
                        {stats?.AssistPenaltyWon > 0 && (
                          <MainDetailedStatLine dark={props.dark} stat="Penalty won" value={stats?.AssistPenaltyWon} icon={Whistle} />
                        )}
                        {stats?.ClearanceOffLine > 0 && (
                          <MainDetailedStatLine
                            dark={props.dark}
                            stat="Clearance off line"
                            value={stats?.ClearanceOffLine}
                            icon={AngelBall}
                          />
                        )}
                        {stats?.CleanSheet > 0 && stats.Position === "Goalkeeper" && (
                          <MainDetailedStatLine dark={props.dark} stat="Clean sheet" value={stats?.CleanSheet} icon={CleanSheet} />
                        )}
                        {stats?.PenaltySave + stats?.PenaltySaves > 0 && (
                          <MainDetailedStatLine
                            stat="Penalty saves"
                            dark={props.dark}
                            value={stats?.PenaltySave + stats?.PenaltySaves}
                            icon={PenaltySave}
                          />
                        )}
                        {stats?.LastManTackle > 0 && (
                          <MainDetailedStatLine dark={props.dark} stat="Last man tackles" value={stats?.LastManTackle} icon={AngelBall} />
                        )}
                        {stats?.RedCard > 0 && (
                          <MainDetailedStatLine dark={props.dark} stat="Red card" value={stats?.RedCard} icon={RedCard} />
                        )}
                        {stats?.OwnGoals > 0 && (
                          <MainDetailedStatLine dark={props.dark} stat="Own goals" value={stats?.OwnGoals} icon={OwnGoal} />
                        )}
                        {stats?.PenaltyConceded > 0 && (
                          <MainDetailedStatLine
                            dark={props.dark}
                            stat="Conceded penalties"
                            value={stats?.PenaltyConceded}
                            icon={RedWhistle}
                          />
                        )}
                        {stats?.ErrorLeadToGoal > 0 && (
                          <MainDetailedStatLine dark={props.dark} stat="Error led to goal" value={stats?.ErrorLeadToGoal} icon={RedShoe} />
                        )}
                      </div>
                    </div>
                  )}
                  {sport === sorareBaseball && (
                    <div className={insideBg + " w-11/12 px-4 mx-auto rounded-xl flex flex-col space-y-4"}>
                      <div className={"absolute self-center justify-center"}>
                        <img className={"self-center justify-center mx-auto align-middle"} src={Watermark} />
                      </div>
                      <div className={"flex flex-row justify-center self-center w-10/12 mx-auto"}>
                        <div className={"w-2/12 space-y-2 self-center"}>
                          <div
                            style={{ backgroundColor: "#FFEAEA" }}
                            className={"flex text-sm font-semibold justify-center rounded-full text-center w-10 h-10 mx-auto"}
                          >
                            <span className={"self-center"}>{negative}</span>
                          </div>
                          <p
                            className={
                              props.dark
                                ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                            }
                          >
                            Negative scoring
                          </p>
                        </div>
                        <div className={"flex flex-col self-center items-center space-y-2 pb-2 w-6/12 h-full"}>
                          <ScoreGaugeModal score={score} aaScore={score} dark={props.dark} decisiveScore={0} sport={sport} />
                          {position !== "SP" && position !== "RP" && (
                            <p
                              className={
                                props.dark
                                  ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                  : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                              }
                            >
                              in {stats.PlateAppearance} plate appearances
                            </p>
                          )}
                          {(position === "SP" || position === "RP") && (
                            <p
                              className={
                                props.dark
                                  ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                  : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                              }
                            >
                              in {stats.InningsPitched} innings
                            </p>
                          )}
                        </div>
                        <div className={"w-2/12 space-y-2 self-center"}>
                          <div
                            style={{ backgroundColor: "#E5F5ED" }}
                            className={"flex text-sm font-semibold justify-center rounded-full text-center w-10 h-10 mx-auto"}
                          >
                            <span className={"self-center"}>{positive}</span>
                          </div>
                          <p
                            className={
                              props.dark
                                ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                            }
                          >
                            Positive scoring
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {sport === sorareBasketball && (
                    <div className={insideBg + " w-11/12 px-4 mx-auto rounded-xl flex flex-col space-y-4"}>
                      <div className={"absolute self-center justify-center"}>
                        <img className={"self-center justify-center mx-auto align-middle"} src={Watermark} />
                      </div>
                      <div className={"flex flex-row justify-center self-center w-10/12 mx-auto"}>
                        <div className={"flex flex-col space-y-2 items-center pb-2"}>
                          <div className={"flex flex-col self-center items-center space-y-2 w-6/12 h-full"}>
                            <ScoreGaugeModal score={score} aaScore={score} dark={props.dark} decisiveScore={0} sport={sport} />
                          </div>
                          <p
                            className={
                              props.dark
                                ? "text-xs text-center font-semibold text-[#CCCCCC] uppercase"
                                : "text-xs text-center font-semibold text-on-surface-variant uppercase"
                            }
                          >
                            In {stats.minutes} minutes
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {sport !== sorareBasketball && (
                    <div className={insideBg + " rounded-md justify-center flex flex-row w-11/12 mx-auto pt-2"}>
                      <div className="hidden">
                        <label htmlFor="tabs" className="sr-only">
                          Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                          id="tabs"
                          name="tabs"
                          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                          defaultValue={activeTab}
                        >
                          {tabs.map((tab) => (
                            <option key={tab}>{tab}</option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden sm:block font-headers">
                        <nav className="flex space-x-4" aria-label="Tabs">
                          {tabs.map((tab) => (
                            <a
                              key={tab}
                              className={classNames(
                                "hover:opacity-80",
                                activeTab === tab
                                  ? props.dark
                                    ? "border-b-2 border-brand text-brand-dark font-semibold"
                                    : "border-b-2 border-primary text-primary font-semibold"
                                  : props.dark
                                  ? "font-medium cursor-pointer text-[#CCCCCC]"
                                  : "font-medium cursor-pointer text-on-surface-variant",
                                "px-3 py-2 text-sm",
                              )}
                              onClick={() => setActiveTab(tab)}
                            >
                              {tab}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                  )}
                </div>
                <div className={"mt-4"}>
                  {activeTab === "General" && (
                    <GeneralDetailedStats
                      category={activeTab}
                      stats={stats}
                      newMatrix={newMatrix}
                      position={props.position}
                      dark={props.dark}
                    />
                  )}
                  {activeTab === "Defense" && (
                    <DefenseDetailedStats
                      category={activeTab}
                      stats={stats}
                      newMatrix={newMatrix}
                      position={props.position}
                      dark={props.dark}
                    />
                  )}
                  {activeTab === "Goalkeeping" && (
                    <GoalkeepingDetailedStats
                      category={activeTab}
                      stats={stats}
                      newMatrix={newMatrix}
                      position={props.position}
                      dark={props.dark}
                    />
                  )}
                  {activeTab === "Possession" && (
                    <PossessionDetailedStats
                      category={activeTab}
                      stats={stats}
                      newMatrix={newMatrix}
                      position={props.position}
                      dark={props.dark}
                    />
                  )}
                  {activeTab === "Attack" && (
                    <AttackingDetailedStats
                      category={activeTab}
                      stats={stats}
                      newMatrix={newMatrix}
                      position={props.position}
                      dark={props.dark}
                    />
                  )}
                  {activeTab === "Batting" && (
                    <BattingDetailedStats category={activeTab} stats={stats} position={props.position} dark={props.dark} />
                  )}
                  {activeTab === "Pitching" && (
                    <PitchingDetailedStats category={activeTab} stats={stats} position={props.position} dark={props.dark} />
                  )}
                  {activeTab === "Basketball" && (
                    <BasketballDetailedStats category={activeTab} stats={stats} position={props.position} dark={props.dark} />
                  )}
                </div>
                {lineups && lineups.lineups !== null && (
                  <div className={"mt-8 space-y-4"}>
                    <p className={"text-center uppercase font-semibold text-xs text-textGrey3"}>LINED UP IN</p>
                    <div className={"grid grid-cols-1 2xl:grid-cols-2 gap-4"}>
                      {lineups &&
                        lineups.lineups !== null &&
                        lineups.lineups.map((lu) => {
                          let rank = lu.lineup.Rank;
                          let comp = lu.competition.DisplayName;
                          let score = parseFloat(lu.lineup.Score).toFixed(2) || 0;
                          let top = lu.lineup.Top;
                          let rankingSystem = lu.competition.RewardType;
                          let colorPoints = "text-brand-black";
                          let colorRank = "text-brand-black";
                          let powerLeft = parseFloat(lu.lineup.PowerLeft);
                          let livePower = parseFloat(lu.lineup.LivePower);
                          let cardScore = stats.SO5Score;
                          if (stats.Position !== lu.position) {
                            if (lu.position === "Defender") {
                              cardScore = stats.SO5ScoreDF;
                            } else if (lu.position === "Midfielder") {
                              cardScore = stats.SO5ScoreMD;
                            } else if (lu.position === "Forward") {
                              cardScore = stats.SO5ScoreFW;
                            }
                          }
                          if (rankingSystem === "top_and_score") {
                            if (lu.competition.RewardThreshold1 > 0) {
                              if (score - lu.competition.RewardThreshold2 >= 0) {
                                colorPoints = "text-success-green";
                              } else if (score - lu.competition.RewardThreshold1 >= 0) {
                                colorPoints = "text-ok-green";
                              } else {
                                colorPoints = "text-ok-yellow-start";
                              }
                            }
                            if (rank <= lu.competition.PlaceThreshold2) {
                              colorRank = "text-success-green";
                            } else if (rank <= lu.competition.PlaceThreshold1) {
                              colorRank = "text-ok-green";
                            } else {
                              colorRank = "text-ok-yellow-start";
                            }
                          }
                          if (rankingSystem === "top") {
                            if (top <= lu.competition.PlaceThreshold2) {
                              colorRank = "text-success-green";
                            } else if (top <= lu.competition.PlaceThreshold1) {
                              colorRank = "text-ok-green";
                            } else {
                              colorRank = "text-ok-yellow-start";
                            }
                          }
                          if (rankingSystem === "ranking") {
                            if (rank <= lu.competition.PlaceThreshold2) {
                              colorRank = "text-success-green";
                            } else if (rank <= lu.competition.PlaceThreshold1) {
                              colorRank = "text-ok-green";
                            } else {
                              colorRank = "text-ok-yellow-start";
                            }
                          }
                          if (sport === sorareBasketball) {
                            rank = lu.lineup.rank;
                            comp = lu.competition.displayName;
                            score = parseFloat(lu.lineup.score).toFixed(2) || 0;
                            if (rank > lu.lineup.paidRankLimit) {
                              colorRank = "text-success-green";
                            } else {
                              colorRank = "text-warning-orange";
                            }
                            cardScore = stats.score;
                          }
                          return (
                            <div>
                              <div
                                className={
                                  "bg-focus rounded-xl" + " flex flex-row justify-center items-center content-center px-2 py-2 flex-1"
                                }
                              >
                                <div className={"text-center w-2/12 px-1"}>
                                  <p className={"font-semibold text-xs " + colorRank}>{ordinal_suffix_of(rank)}</p>
                                </div>
                                <div className={"mx-auto w-7/12 flex flex-row space-x-2 px-1"}>
                                  <img src={LineSeparator} />
                                  <div className={"w-11/12"}>
                                    <p className={"text-xs font-medium text-textGrey3"}>{comp}</p>
                                    <p className={"text-sm font-semibold " + colorPoints}>{score} pts</p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    "text-textGrey3 text-center w-3/12 self-center justify-center uppercase flex flex-row space-x-2"
                                  }
                                >
                                  <ScoreEllipse
                                    power={lu.lineup_player.Power}
                                    score={cardScore}
                                    size={"text-sm"}
                                    sport={sport}
                                    padding={"px-1.5 py-0.5"}
                                    isCaptain={lu.lineup_player.IsCaptain}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  } else {
    return null;
  }
}

export default withUser(PlayerScoreModal);
