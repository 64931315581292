import React, { useEffect, useState } from "react";

import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { Form } from "grommet";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { FormSearch } from "grommet-icons";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import WatchlistsList from "./watchlistsList";
import Spinner from "../loader/spinner";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { sportsOptions } from "../util/sports";
import { orderLabels, orderMenuItems, watchlistTypeOptions } from "./constants";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import { useDebounce } from "react-use";

function CommunityWatchlists(props) {
  const limitDisplayed = props.limitDisplayed || 50;
  const limit = props.limit || 1000;

  const [searchQuery, setSearchQuery] = useState("");
  const [debounceSearchQuery, setDebounceSearchQuery] = useState("");
  const [order, setOrder] = useState("created desc");
  const [sport, setSport] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [showSortMenu, setShowSortMenu] = useState(false);

  useDebounce(
    () => {
      setDebounceSearchQuery(searchQuery);
    },
    250,
    [searchQuery],
  );
  useEffect(() => {
    getCommunityWatchlists(searchQuery, limit, order, sport);
  }, [debounceSearchQuery]);

  useEffect(() => {
    getCommunityWatchlists(searchQuery, limit, order, sport);
  }, [order, sport, type]);

  const getCommunityWatchlists = (searchQuery, limit, sort, sport) => {
    setLoading(true);
    setOrder(order);
    props
      .fetch("/apiv2/watchlists/community/search", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchQuery: searchQuery,
          limit: limit,
          order: sort,
          sports: sport ? [sport] : [],
          types: type ? [type] : [],
        }),
      })
      .then((response) => response.json())
      .then(async (res) => {
        setItems(res || []);
        setDisplayedItems(res ? res.slice(0, limitDisplayed) : []);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const onChangeSort = (field) => {
    let sort = "";
    if (order.includes(field)) {
      sort = field + (order.includes("asc") ? " desc" : " asc");
    } else {
      sort = field + " asc";
    }
    setOrder(sort);
  };

  const onClickOnSortItem = (key) => {
    setShowSortMenu(false);
    setOrder(key);
  };

  return (
    <div className={"w-full mb-8"}>
      <div className="relative flex flex-col md:flex-row place-content-between p-0 pt-1 pb-3">
        <div className="font-semibold text-xl my-auto">
          {props.displayTitle && <span>Public watchlists</span>}
          {props.displayOrder && (
            <div className={"text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
              Sort by: {orderLabels.find((obj) => obj.value === order).name}
              <span className={"ml-2 my-auto"}>
                <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row place-content-between p-0 gap-2">
          {loading && <Spinner />}
          {props.displayTypeFilter && (
            <SelectSearch
              closeOnSelect={true}
              options={watchlistTypeOptions}
              value={type}
              filterOptions={fuzzySearch}
              onChange={(v) => setType(v)}
            />
          )}
          {props.displaySportFilter && (
            <SelectSearch
              closeOnSelect={true}
              options={sportsOptions}
              value={sport}
              filterOptions={fuzzySearch}
              onChange={(v) => setSport(v)}
            />
          )}
          <Form className="font-semibold text-sm">
            <div className={"m-auto relative flex h-9 "}>
              <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                <FormSearch className="h-5 w-5" aria-hidden="true" />
              </div>
              {searchQuery && (
                <div
                  className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                >
                  <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
                </div>
              )}
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                className={`h-9 rounded-lg block w-full pl-10 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0 ${
                  searchQuery !== "" ? "font-medium" : ""
                }`}
                placeholder="Search..."
              />
            </div>
          </Form>
        </div>
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
          show={showSortMenu}
          onClickOutside={() => setShowSortMenu(false)}
          items={orderMenuItems(order, onClickOnSortItem)}
        />
      </div>
      {limitDisplayed <= limit ? (
        <InfiniteScroll
          next={() => setDisplayedItems(items.slice(0, displayedItems.length + limitDisplayed))}
          hasMore={displayedItems.length < items.length}
          loader={<SDLoading />}
          dataLength={displayedItems.length}
        >
          <WatchlistsList displayedWatchlists={displayedItems} onChangeSort={onChangeSort} sortBy={order} />
        </InfiniteScroll>
      ) : (
        <WatchlistsList displayedWatchlists={displayedItems} onChangeSort={onChangeSort} sortBy={order} />
      )}
    </div>
  );
}

export default withUser(CommunityWatchlists);
