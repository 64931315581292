import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export const MobileModale = (props) => {
  const { children, open, close, onFinishOpenTransition, noTransition } = props;

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-100 bottom-0 left-0 right-0 max-h-[90vh]"
          onClose={() => {
            close && close();
          }}
        >
          <div className="flex flex-col items-end justify-center text-center sm:block sm:p-0 w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterEnter={() => {
                onFinishOpenTransition && onFinishOpenTransition();
              }}
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {noTransition ? (
              <div className={"relative inline-block w-full sm:w-[80%] align-middle"}>{children}</div>
            ) : (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-full"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-full"
              >
                <div className={"relative inline-block transform transition-all w-full sm:w-[80%] align-middle"}>{children}</div>
              </Transition.Child>
            )}
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
