import { withUser } from "../../userContext";
import React, { useState } from "react";
import LineSeparator from "../../img/line-separator.svg";
import ManagerInjuries from "./managerInjuries";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import GLOBAL_MARGIN from "../util/margin";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import ManagerNews from "./managerNews";

function ManagerNewsInjuries(props) {
  const [picked, setPicked] = useState("injuries");

  const rounded = props.rounded || "rounded-full";

  const pickedStyle = "text-brand";
  const notPickedStyle = "text-textGrey4";
  return (
    <div className={"space-y-4"}>
      <div className={"flex flex-row justify-center"}>
        <div className={"font-headers text-center font-semibold bg-white flex flex-row space-x-6 text-base px-6 py-2 " + rounded}>
          {
            <div className={"cursor-pointer"} onClick={() => setPicked("injuries")}>
              <p className={picked === "injuries" ? pickedStyle : notPickedStyle}>Injuries</p>
            </div>
          }
          <img src={LineSeparator} />
          <div className={"cursor-pointer"} onClick={() => setPicked("news")}>
            <p className={picked === "news" ? pickedStyle : notPickedStyle}>News</p>
          </div>
        </div>
      </div>

      <div>
        {picked === "injuries" && t1OrAbove(props.user.tier) && (
          <div className={GLOBAL_MARGIN}>
            <ManagerInjuries manager={props.manager} />
          </div>
        )}
        {picked === "injuries" && isFree(props.user.tier) && (
          <div className={GLOBAL_MARGIN}>
            <PlayerBlockedVideo
              title="Want more insights?"
              description="Become a Star member to unlock roster injuries view"
              videoLink="https://www.youtube.com/embed/0odiGkEEHbE"
            />
          </div>
        )}
        {picked === "news" && (
          <div className={GLOBAL_MARGIN}>
            <ManagerNews manager={props.manager} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(ManagerNewsInjuries);
