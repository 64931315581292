import React, { Fragment, useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { withUser } from "../../userContext";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Button } from "./button";
import { errorCatcher } from "./errors";
import Locker from "../../img/brand-close-locker-nude.svg";
import { leagues_no_div_objects_not_all } from "./leagues";
import { NewTag } from "../home/sidebarItem";

function SaveFiltersModal(props) {
  const limitations = props.limitations;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(props.name);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  const isCreation =
    (props.name === "" && name !== "") || // new preset from scratch
    (props.name !== "" && name !== props.name); // new preset from another one
  const canSave = name !== "" && (limitations.creationAuthorized || props.active);
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      props.close();
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"flex flex-col items-center mx-auto space-y-4"}>
                  <p className={"text-base font-semibold text-center"}>To save your preset, find the right name for it and save.</p>
                  <div
                    className="flex justify-center w-full"
                    title={
                      limitations.creationAuthorized
                        ? ""
                        : "You can only keep the same name as you reached the limit of presets for your membership"
                    }
                  >
                    <input
                      disabled={!limitations.creationAuthorized}
                      className={`${
                        limitations.creationAuthorized ? "" : "text-textGrey3 cursor-not-allowed"
                      } shadow-sm block w-full md:w-8/12 lg:w-6/12 sm:text-sm border-gray-300 rounded-md`}
                      type={"text"}
                      placeholder={"Example: cheap MLS players"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <Button disabled={!canSave} label={isCreation ? "Create preset" : "Save preset"} onClick={() => props.save(name)} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

function FilterItem(props) {
  const { optionProps, domProps, className, limitations } = props;
  const [isHover, setIsHover] = useState(false);
  const title = optionProps.disabled
    ? limitations.activationAuthorized
      ? limitations.max !== undefined
        ? `Preset has been disabled but you can reactivate up to ${limitations.max - limitations.currentActive} presets`
        : "Preset has been disabled but you can reactivate them all"
      : `You reached the limit of ${limitations.max} presets for your membership. You can reactivate some disabled ones, once you removed active presets first (select one and click on 'delete' button)`
    : "";
  return (
    <div
      className="z-30 relative h-full flex flex-row justify-between items-center"
      onMouseEnter={() => optionProps.disabled && setIsHover(true)}
      onMouseLeave={() => optionProps.disabled && setIsHover(false)}
      title={title}
    >
      <button
        {...optionProps}
        {...domProps}
        className={`${className} h-full ${optionProps.disabled ? "cursor-default text-textGrey3" : ""}`}
        label={optionProps.name}
        disabled={optionProps.disabled}
      >
        <span className="flex justify-between">
          {optionProps.name}
          {optionProps.filter?.isNew && <NewTag />}
        </span>
      </button>
      {optionProps.disabled && (
        <div className="absolute right-3 h-full my-auto flex flex-row gap-2">
          {isHover && limitations.activationAuthorized && (
            <button
              className={"my-auto bg-brand text-xs text-focus p-1 rounded-md hover:opacity-80"}
              onClick={() => optionProps.onActivate && optionProps.onActivate()}
            >
              Activate
            </button>
          )}
          <img className={"my-auto h-3 w-3"} alt="" src={optionProps.photo} />
        </div>
      )}
    </div>
  );
}

function SaveFilters(props) {
  const [filtersList, setFiltersList] = useState([]);
  const [filter, setFilter] = useState("");
  const [msg, setMsg] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const [limitations, setLimitations] = useState({});

  useEffect(() => {
    canAddFilter();
  }, [props.user.sorareSlug]);

  useEffect(() => {
    getFiltersList();
  }, [limitations]);

  const getFiltersList = () => {
    if (props.user.sorareSlug !== undefined && props.user.sorareSlug !== null && props.user.sorareSlug !== "") {
      props
        .fetch("/apiv2/user/filters")
        .then((response) => response.json())
        .then((res) => {
          let arrayFilters = [];
          res.map((e) => {
            let filter = e;
            filter.so5_filters.scoreRange = filter.so5_filters.scoreRange
              ? filter.so5_filters.scoreRange
              : filter.so5_filters.average_range;
            filter.so5_filters.daRange = filter.so5_filters.daRange ? filter.so5_filters.daRange : filter.so5_filters.decisive_range;
            filter.so5_filters.aaRange = filter.so5_filters.aaRange ? filter.so5_filters.aaRange : filter.so5_filters.all_around_range;
            filter.so15_filters.scoreRange = filter.so15_filters.scoreRange
              ? filter.so15_filters.scoreRange
              : filter.so15_filters.average_range;
            filter.so15_filters.daRange = filter.so15_filters.daRange ? filter.so15_filters.daRange : filter.so15_filters.decisive_range;
            filter.so15_filters.aaRange = filter.so15_filters.aaRange ? filter.so15_filters.aaRange : filter.so15_filters.all_around_range;
            filter.so40_filters.scoreRange = filter.so40_filters.scoreRange
              ? filter.so40_filters.scoreRange
              : filter.so40_filters.average_range;
            filter.so40_filters.daRange = filter.so40_filters.daRange ? filter.so40_filters.daRange : filter.so40_filters.decisive_range;
            filter.so40_filters.aaRange = filter.so40_filters.aaRange ? filter.so40_filters.aaRange : filter.so40_filters.all_around_range;
            arrayFilters.push({
              name: e.name,
              value: e.name,
              filter: filter,
              disabled: !e.active,
              photo: Locker,
            });
          });
          setFiltersList(arrayFilters);
        })
        .catch(errorCatcher());
    }
  };

  useEffect(() => {
    setMsg("");
    filtersList.map((e) => {
      if (e.name === filter && !e.disabled) {
        let f = e.filter;
        let scarcities = [];
        let positions = [];
        let leagues = f.leagues.split(", ");
        if (leagues[0] === "") {
          leagues = [];
        }
        leagues = leagues.filter((x) => leagues_no_div_objects_not_all.map((y) => y.value).includes(x));
        let ps = "all";
        let clubs = f.clubs.split(", ");
        if (clubs[0] === "") {
          clubs = [];
        }
        if (f.starter) {
          ps = "starter";
        } else if (f.substitute) {
          ps = "substitute";
        } else if (f.not_playing) {
          ps = "not_playing";
        }
        if (f.limited) {
          scarcities.push("LIMITED");
        }
        if (f.rare) {
          scarcities.push("RARE");
        }
        if (f.super_rare) {
          scarcities.push("SUPER RARE");
        }
        if (f.unique) {
          scarcities.push("UNIQUE");
        }
        if (f.goalkeeper) {
          positions.push("Goalkeeper");
        }
        if (f.defender) {
          positions.push("Defender");
        }
        if (f.midfielder) {
          positions.push("Midfielder");
        }
        if (f.forward) {
          positions.push("Forward");
        }
        props.updateFilters(
          scarcities,
          positions,
          clubs,
          leagues,
          [f.min_age, f.max_age],
          ps,
          f.u23,
          f.current_price,
          [parseFloat(f.current_price_min), parseFloat(f.current_price_max)],
          f.avg,
          [f.avg_min, f.avg_max],
          f.bmp,
          [f.bmp_min, f.bmp_max],
          f.under_bmp,
          f.under_avg,
          f.so5_filters,
          f.so15_filters,
          f.so40_filters,
          f.watchlists,
        );
      }
    });
  }, [filter]);

  const saveFilters = (name) => {
    props
      .fetch("/apiv2/user/addFilter", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scarcities: props.scarcities,
          positions: props.positions,
          leagues: props.leagues,
          eligibilityCompetitions: props.eligibilityCompetitions,
          clubs: props.clubs,
          age: props.age,
          age_range: props.ageRange,
          u23: props.u23,
          current_price: props.currentPrice,
          current_price_range: props.currentPriceRange,
          avg: props.avg,
          avg_range: props.avgRange,
          bmp: props.bmp,
          bmp_range: props.bmpRange,
          under_bmp: props.underBmp,
          under_avg: props.underAvg,
          so5: props.so5,
          so5_filters: JSON.stringify(props.so5Filters),
          so15: props.so15,
          so15_filters: JSON.stringify(props.so15Filters),
          so40: props.so40,
          so40_filters: JSON.stringify(props.so40Filters),
          player_status: props.playerStatus,
          currency: props.currency,
          name: name,
          watchlists: props.watchlists,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setMsg("Filter saved.");
        setShowOverlay(false);
        setFilter(name);
        getFiltersList();
        canAddFilter();
      })
      .catch(errorCatcher());
  };

  const removeFilter = () => {
    props
      .fetch("/apiv2/user/removeFilter", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: filter,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setMsg("Filter removed.");
        setFilter("");
        getFiltersList();
        canAddFilter();
        props.reset();
      })
      .catch(errorCatcher());
  };

  const canAddFilter = () => {
    props
      .fetch("/apiv2/user/canAddFilter")
      .then((response) => response.json())
      .then((res) => {
        setLimitations(res);
      })
      .catch(errorCatcher());
  };

  const activateFilter = (filter) => {
    props
      .fetch("/apiv2/user/enableFilter", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: filter,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        getFiltersList();
        canAddFilter();
      })
      .catch(errorCatcher());
  };

  const customValueRender = (valueProps) => {
    const option = filterOptions?.find((opt) => opt.name === valueProps.value);
    return valueProps.value ? (
      <button className="select-search__input" {...valueProps}>
        <span className="flex justify-between items-center">
          <span>{option?.name || valueProps.value}</span>
          {option?.filter?.isNew && <NewTag />}
        </span>
      </button>
    ) : (
      <input className="select-search__input" {...valueProps} />
    );
  };

  const customOptionRender = (domProps, optionProps, className, limitations) => {
    return <FilterItem domProps={domProps} optionProps={optionProps} className={className} limitations={limitations} />;
  };

  const filterOptions = filtersList.map((e) => {
    return {
      ...e,
      onActivate: () => {
        activateFilter(e.name);
      },
    };
  });

  const isSelectedActive = !filtersList.find((f) => f.name === filter)?.disabled;
  const canSave =
    (filter === "" && limitations.creationAuthorized) || // new filter from scratch
    (filter !== "" && isSelectedActive); // existing filter to save
  const canSaveMsg =
    !limitations.creationAuthorized && filter === ""
      ? `You reached the maximum of ${limitations.max} presets for your membership. Upgrade for more presets`
      : "";

  return (
    <div>
      <div
        className={
          props.filterMenu
            ? "flex flex-col self-center space-y-2"
            : "flex flex-col md:flex-row md:justify-between self-center space-y-2 md:space-y-0"
        }
      >
        <div className={`${props.filterMenu ? "" : "lg:w-5/12 xl:w-5/12 2xl:w-4/12"}`}>
          <SelectSearch
            options={filterOptions}
            closeOnSelect={true}
            value={filter}
            placeholder={"Select a filters preset"}
            renderOption={(domProps, optionProps, _, className) => {
              return customOptionRender(domProps, optionProps, className, limitations);
            }}
            renderValue={customValueRender}
            onChange={(v) => {
              setFilter(v);
            }}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
          />
        </div>
        <div className={"flex flex-row space-x-4 self-center"}>
          <div>
            <p className={"text-xs font-semibold"}>{msg}</p>
          </div>
          {filter !== "" && (
            <div>
              <p className={"text-brand text-xs font-semibold cursor-pointer"} onClick={() => removeFilter()}>
                Delete preset
              </p>
            </div>
          )}
          <div data-tip={canSaveMsg}>
            <p
              className={canSave ? "text-brand text-xs font-semibold cursor-pointer" : "text-gray-700 text-xs font-semibold cursor-pointer"}
              onClick={() => canSave && setShowOverlay(true)}
            >
              Save preset
            </p>
          </div>
          <div>
            <p
              className={"text-brand text-xs font-semibold cursor-pointer"}
              onClick={() => {
                setFilter("");
                props.reset();
              }}
            >
              Clear all filters
            </p>
          </div>
        </div>
      </div>
      {canSave && (
        <SaveFiltersModal
          name={filter}
          open={showOverlay}
          close={() => setShowOverlay(false)}
          save={(name) => {
            saveFilters(name);
          }}
          limitations={limitations}
          active={isSelectedActive}
        />
      )}
    </div>
  );
}

export default withUser(SaveFilters);
