import React, { useState } from "react";
import LogoutModale from "./logoutModale";
import { MembershipBadge } from "../util/handleSubscriptionTier";
import { useTheme } from "../../themeContext";
import { sdLight } from "../../themeColors";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import ProjectionSummer2024Box from "../../img/projections-summer-2024-box.png";

export const SideBarUserFooter = (props) => {
  const [showModale, setShowModale] = useState(false);

  return (
    <div className={"w-full hover:opacity-80 cursor-pointer"} onClick={() => setShowModale(true)}>
      <span className={"text-md inline-block font-semibold text-[#1A1A1A] w-full"}>Log out</span>
      <LogoutModale open={showModale} onCancel={() => setShowModale(false)} />
    </div>
  );
};

export const ThemeButton = (props) => {
  const { theme, toggleTheme, enabled } = useTheme();
  if (!enabled) {
    return null;
  }
  return (
    <button className={"p-1"} onClick={toggleTheme}>
      {theme === sdLight ? "🌙" : "🌞"}
    </button>
  );
};

export const SideBarUserHeader = (props) => {
  return (
    <div className={"flex flex-row justify-between p-4"}>
      <div className={"flex flex-row my-auto gap-1"}>
        <span className={"my-auto text-md font-semibold hover:opacity-90"}>
          <a href={"/profile"}>{props.username}</a>
        </span>
        <MembershipBadge tier={props.tier} />
      </div>
      <div className={"flex flex-row items-center"}>
        <ThemeButton />
      </div>
    </div>
  );
};

const notSupportSportURL = "https://medium.com/@soraredata/update-on-soraredata-mlb-and-nba-coverage-e27e08679591";

export const connectedUserExtendedNavItems = (user) => {
  let userItems = [
    {
      label: "My gallery",
      href: "/manager/" + user.sorareSlug,
    },
    {
      label: "My watchlists",
      href: "/watchlists/overview",
    },
    {
      label: "My collections",
      href: "/manager/" + user.sorareSlug + "/sections/collections",
    },
    {
      label: "My price alerts",
      href: "/watchlists/price-alerts",
    },
    {
      label: "Settings & Membership",
      href: "/profile",
    },
    {
      label: "Pricing",
      href: "/product?downgradable=true",
    },
  ];

  const so5Items = {
    title: "Gameweek",
    extendedTitle: "Gameweek center",
    tagLine: "Your premier destination for lineup preparation and live results.",
    boxTitle: "My Sorare results",
    boxLink: "/SO5results/" + user.sorareSlug,
    secondaryBoxTitle: "Special Summer Competition",
    secondaryBoxAction: "Project lineups",
    secondaryBoxLink: "/SO5Results/sections/community-lineups",
    secondaryBoxBg: ProjectionSummer2024Box,
    footballItems: [
      {
        label: "Sorare results",
        href: "/SO5results/" + user.sorareSlug,
      },
      {
        label: "Games",
        href: "/SO5results",
      },
      {
        label: "Pick scores",
        href: "/SO5Results/sections/pick-scores",
      },
      {
        label: "Leaderboards",
        href: "/SO5Results/sections/rankings",
      },
      {
        label: "Projected lineups",
        href: "/SO5Results/sections/community-lineups",
      },
      {
        label: "Rewards",
        href: "/rewards/sections/cards?sport=" + sorareFootball,
      },
    ],
    basketballItems: [
      {
        label: "Not supported anymore",
        href: notSupportSportURL,
      },
    ],
    baseballItems: [
      {
        label: "Sorare results",
        href: "/SO5results/" + user.sorareSlug + "?sport=sr_baseball",
      },
      {
        label: "Games",
        href: "/SO5results" + "?sport=sr_baseball",
      },
      {
        label: "Rewards",
        href: "/rewards/sections/cards?sport=" + sorareBaseball,
      },
    ],
  };

  const builderItems = {
    title: "Builder",
    extendedTitle: "Lineup builder",
    tagLine: "Build lineups in no time with our suggestions.",
    boxTitle: "Build your lineup",
    boxTagline: "Advanced tools to help you create the most competitive lineups.",
    boxLink: "/lineupBuilder",
    footballItems: [
      {
        label: "Lineup builder",
        href: "/lineupBuilder/sections/builder/sport/sr_football",
      },
      {
        label: "Concepts (beta)",
        href: "/concept",
      },
      {
        label: "My teams",
        href: "/lineupBuilder/sections/saved/sport/sr_football",
      },
    ],
    basketballItems: [
      {
        label: "Not supported anymore",
        href: notSupportSportURL,
      },
    ],
    baseballItems: [
      {
        label: "Lineup builder",
        href: "/lineupBuilder/sections/builder/sport/sr_baseball",
      },
      {
        label: "My saved lineups",
        href: "/lineupBuilder/sections/saved/sport/sr_baseball",
      },
    ],
  };

  const marketItems = {
    title: "Market",
    extendedTitle: "Market",
    tagLine: "Details on all auctions and secondary market transactions.",
    boxTitle: "Live market",
    boxTagline: "View real-time auctions, offers & bundles.",
    boxLink: "/liveMarket",
    footballItems: [
      {
        label: "Live market",
        href: "/liveMarket?sport=sr_football",
      },
      {
        label: "Completed auctions",
        href: "/endedAuctions?sport=sr_football",
      },
      {
        label: "Completed bundle auctions",
        href: "/sold-bundles",
      },
      {
        label: "Completed offers",
        href: "/latestOffers?sport=sr_football",
      },
      {
        label: "My trades",
        href: "/myTrades",
      },
      {
        label: "Trade builder",
        href: "/tradeBuilder",
      },
    ],
    basketballItems: [
      {
        label: "Live market",
        href: "/liveMarket?sport=sr_basketball",
      },
      {
        label: "Completed auctions",
        href: "/endedAuctions?sport=sr_basketball",
      },
      {
        label: "Completed offers",
        href: "/latestOffers?sport=sr_basketball",
      },
    ],
    baseballItems: [
      {
        label: "Live market",
        href: "/liveMarket?sport=sr_baseball",
      },
      {
        label: "Completed auctions",
        href: "/endedAuctions?sport=sr_baseball",
      },
      {
        label: "Completed offers",
        href: "/latestOffers?sport=sr_baseball",
      },
    ],
  };

  const scoutItems = {
    title: "Scout",
    extendedTitle: "Scout",
    tagLine: "Sophisticated tools to help you find the best players and values.",
    boxTitle: "Player rankings",
    boxTagline: "Check the best players over the last games.",
    boxLink: "/rankings",
    footballItems: [
      {
        label: "Player rankings",
        href: "/rankings?sport=sr_football",
      },
      {
        label: "Player & Card finder",
        href: "/advancedSearch?mode=players&sport=sr_football",
      },
      {
        label: "Watchlists",
        href: "/watchlists/overview?sport=sr_football",
      },
    ],
    basketballItems: [
      {
        label: "Player & Card finder",
        href: "/advancedSearch?mode=players&sport=sr_basketball",
      },
      {
        label: "Watchlists",
        href: "/watchlists/overview?sport=sr_basketball",
      },
    ],
    baseballItems: [
      {
        label: "Player rankings",
        href: "/rankings?sport=sr_baseball",
      },
      {
        label: "Player & Card finder",
        href: "/advancedSearch?mode=players&sport=sr_baseball",
      },
      {
        label: "Watchlists",
        href: "/watchlists/overview?sport=sr_baseball",
      },
    ],
  };

  const mobileItems = [
    {
      label: "Gameweek",
      icon: "calendar",
    },
    {
      label: "Scout",
      icon: "graph",
    },
    {
      label: "Builder",
      icon: "card-stack",
    },
    {
      label: "Market",
      icon: "tag",
    },
    {
      label: "Notifications",
      icon: "bell",
    },
    {
      label: "My account",
      icon: "user",
    },
  ];

  return {
    userItems,
    so5Items,
    builderItems,
    marketItems,
    scoutItems,
    mobileItems,
  };
};

export const menuData = {
  so5: {
    title: "Gameweek",
    name: "so5",
    children: [
      {
        title: "Football",
        name: "football",
        children: [
          {
            label: "Sorare results",
            href: (user) => `/SO5results/${user.sorareSlug}`,
          },
          {
            label: "Games",
            href: "/SO5results",
          },
          {
            label: "Leaderboards",
            href: "/SO5Results/sections/rankings",
          },
          {
            label: "Projected lineups",
            href: "/SO5Results/sections/community-lineups",
          },
          {
            label: "Pick scores",
            href: "/SO5Results/sections/pick-scores",
          },
          {
            label: "Rewards",
            href: "/rewards/sections/cards?sport=" + sorareFootball,
          },
        ],
      },
      {
        title: "Basketball",
        name: "basketball",
        children: [
          {
            label: "Not supported anymore",
            href: notSupportSportURL,
          },
        ],
      },
      {
        title: "Baseball",
        name: "baseball",
        children: [
          {
            label: "Sorare results",
            href: (user) => `/SO5results/${user.sorareSlug}?sport=sr_baseball`,
          },
          {
            label: "Games",
            href: "/SO5results" + "?sport=sr_baseball",
          },
          {
            label: "Rewards",
            href: "/rewards/sections/cards?sport=" + sorareBaseball,
          },
        ],
      },
    ],
  },
  scout: {
    title: "Scout",
    name: "scout",
    children: [
      {
        title: "Football",
        name: "football",
        children: [
          {
            label: "Player rankings",
            href: "/rankings?sport=sr_football",
          },
          {
            label: "Player & Card finder",
            href: "/advancedSearch?mode=players&sport=sr_football",
          },
          {
            label: "Watchlists",
            href: "/watchlists/overview?sport=sr_football",
          },
        ],
      },
      {
        title: "Basketball",
        name: "basketball",
        children: [
          {
            label: "Player & Card finder",
            href: "/advancedSearch?mode=players&sport=sr_basketball",
          },
          {
            label: "Watchlists",
            href: "/watchlists/overview?sport=sr_basketball",
          },
        ],
      },
      {
        title: "Baseball",
        name: "baseball",
        children: [
          {
            label: "Player rankings",
            href: "/rankings?sport=sr_baseball",
          },
          {
            label: "Player & Card finder",
            href: "/advancedSearch?mode=players&sport=sr_baseball",
          },
          {
            label: "Watchlists",
            href: "/watchlists/overview?sport=sr_baseball",
          },
        ],
      },
    ],
  },
  builder: {
    title: "Builder",
    name: "builder",
    children: [
      {
        title: "Football",
        name: "football",
        children: [
          {
            label: "Lineup builder",
            name: "lineup-builder",
            href: "/lineupBuilder/sections/builder/sport/sr_football",
          },
          {
            label: "Concepts (beta)",
            name: "concepts",
            href: "/concept",
          },
          {
            label: "My teams",
            name: "saved-lineups",
            href: "/lineupBuilder/sections/saved/sport/sr_football",
          },
        ],
      },
      {
        title: "Basketball",
        name: "basketball",
        children: [
          {
            label: "Not supported anymore",
            href: notSupportSportURL,
          },
        ],
      },
      {
        title: "Baseball",
        name: "baseball",
        children: [
          {
            label: "Lineup builder",
            name: "lineup-builder",
            href: "/lineupBuilder/sections/builder/sport/sr_baseball",
          },
          {
            label: "My saved lineups",
            name: "saved-lineups",
            href: "/lineupBuilder/sections/saved/sport/sr_baseball",
          },
        ],
      },
    ],
  },
  market: {
    title: "Market",
    name: "market",
    children: [
      {
        title: "Football",
        name: "football",
        children: [
          {
            label: "Live market",
            href: "/liveMarket?sport=sr_football",
          },
          {
            label: "Completed auctions",
            href: "/endedAuctions?sport=sr_football",
          },
          {
            label: "Completed bundle auctions",
            href: "/sold-bundles",
          },
          {
            label: "Completed offers",
            href: "/latestOffers?sport=sr_football",
          },
          {
            label: "My trades",
            href: "/myTrades",
          },
          {
            label: "Trade builder",
            href: "/tradeBuilder",
          },
        ],
      },
      {
        title: "Basketball",
        name: "basketball",
        children: [
          {
            label: "Live market",
            href: "/liveMarket?sport=sr_basketball",
          },
          {
            label: "Completed auctions",
            href: "/endedAuctions?sport=sr_basketball",
          },
          {
            label: "Completed offers",
            href: "/latestOffers?sport=sr_basketball",
          },
        ],
      },
      {
        title: "Baseball",
        name: "baseball",
        children: [
          {
            label: "Live market",
            href: "/liveMarket?sport=sr_baseball",
          },
          {
            label: "Completed auctions",
            href: "/endedAuctions?sport=sr_baseball",
          },
          {
            label: "Completed offers",
            href: "/latestOffers?sport=sr_baseball",
          },
        ],
      },
    ],
  },
  user: {
    title: "My Account",
    name: "user",
    children: [
      {
        label: "My gallery",
        href: (user) => `/manager/${user.sorareSlug}`,
      },
      {
        label: "My watchlists",
        href: "/watchlists/overview",
      },
      {
        label: "My collections",
        href: (user) => `/manager/${user.sorareSlug}/sections/collections`,
      },
      {
        label: "My price alerts",
        href: "/watchlists/price-alerts",
      },
      {
        label: "Settings & Membership",
        href: "/profile",
      },
      {
        label: "Pricing",
        href: "/product?downgradable=true",
      },
      {
        label: "Log out",
      },
    ],
  },
};
