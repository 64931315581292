import React, { useEffect } from "react";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { useInView } from "react-intersection-observer";
import GoldMedal from "../../img/gold-medal.svg";
import SilverMedal from "../../img/silver-medal.svg";
import BronzeMedal from "../../img/bronze-medal.svg";
import { ReactComponent as EtherIcon } from "../../img/ether-no-color.svg";
import { ReactComponent as Euro } from "../../img/euro-no-color.svg";
import { ReactComponent as Dollar } from "../../img/dollar-no-color.svg";
import { ReactComponent as Gbp } from "../../img/gbp-no-color.svg";
import NewCardIcon from "../util/newCardIcon";
import { withUser } from "../../userContext";
import { ReactComponent as CupIcon } from "../../img/icons-cup-no-color.svg";
import LockerRound from "../util/orangeOpenLockerBrandBg";
import LUBPrizePoolExample from "../../img/lub-prize-pool-example.png";
import { Button } from "../util/button";
import PlayCircle from "../../img/play_circle.svg";
import { formatPrice, formatPriceNoCurrency } from "../util/formatMoney";
import Coin from "../../img/coin.png";
import { NoGraphResultsImage } from "../util/noGraphResultImage";
import { getThemeColor } from "../../themeContext";
import { sdDark } from "../../themeColors";

export const ProtectedPrizePool = withUser((props) => {
  const { prizePool, onVisible } = props;
  if (t1OrAbove(props.user.tier)) {
    return <PrizePool prizePool={prizePool} onVisible={onVisible} />;
  }
  return <DummyPrizePool onVisible={onVisible} />;
});

const DummyPrizePool = (props) => {
  const { onVisible } = props;
  const { ref, entry } = useInView({
    trackVisibility: true,
    threshold: 0.75,
    delay: 100,
  });
  useEffect(() => {
    if (entry?.isVisible) {
      onVisible && onVisible();
    }
  }, [entry?.isVisible]);

  return (
    <div ref={ref} className={"relative"}>
      <MoreInsightBlockForm />
    </div>
  );
};

const MoreInsightBlockForm = () => {
  return (
    <div
      className={
        "flex flex-col justify-center items-center w-full shadow-xl rounded-xl bg-center bg-clip-border bg-cover bg-no-repeat bg-upgrade-bg-lub-form rounded p-5 gap-3"
      }
    >
      <div className={"flex flex-col items-center justify-center gap-2"}>
        <div className="flex flex-row justify-center self-center">
          <LockerRound containerStyle="w-7 h-7" />
        </div>
        <div className="flex flex-col justify-center text-center gap-1">
          <div>
            <p className={`text-center text-lg text-black font-black text-left font-headers`}>Want more insights?</p>
          </div>
          <div>
            <p className={`text-center text-xs font-semibold text-left`}>Become a Star member to unlock prize pool analysis</p>
          </div>
          <div>
            <a href={"https://youtu.be/kYLPKrHArtE"} target={"blank_"}>
              <div className={"flex flew-row gap-1 justify-center items-center hover:opacity-80 text-xs font-semibold"}>
                <p className={"text-primary "}>Learn more about the subscription tiers</p>
                <div className={"w-4 h-4 self-center inline-flex"}>
                  <img className={"w-full h-full object-contain"} src={PlayCircle} />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-3 w-full justify-center items-center">
        <a href={"/product"}>
          <Button label={"Upgrade"} />
        </a>
      </div>
      <div className={"py-8 px-16 flex flex-col justify-center items-center gap-3"}>
        <img className={"rounded-lg"} style={{ filter: "drop-shadow(0px 4px 36px rgba(0, 0, 0, 0.1))" }} src={LUBPrizePoolExample} />
      </div>
    </div>
  );
};

const PrizePool = withUser((props) => {
  const { prizePool, onVisible } = props;
  const rewards =
    prizePool?.cards?.map((c) => {
      const primary = c.moneyReward.primary;
      const primaryValue = formatPriceNoCurrency(primary.price, primary.currency, undefined, primary.currency !== "eth" ? 0 : undefined);
      const secondary = c.moneyReward.secondary;
      const secondaryValue = formatPrice(secondary.price, secondary.currency); // with currency displayed
      return {
        ...c,
        primaryValueFormatted: primaryValue,
        primaryValueFormattedLength: primaryValue ? primaryValue.length : 0,
        secondaryValueFormatted: secondaryValue,
        secondaryValueFormattedLength: secondaryValue ? secondaryValue.length : 0,
      };
    }) || [];
  const thresholds = prizePool?.thresholds || [];
  const { ref, entry } = useInView({
    trackVisibility: true,
    threshold: 0.75,
    delay: 100,
  });
  useEffect(() => {
    if (entry?.isVisible) {
      onVisible && onVisible();
    }
  }, [entry?.isVisible]);
  const lastReward = rewards.length > 0 ? rewards[rewards.length - 1] : {};
  const lastRewardPoints = lastReward.min_score || 0;
  const lengthReward = Math.max(...rewards.map((o) => o.primaryValueFormattedLength));
  const widthReward = lengthReward * 0.65 + "em";
  return (
    <div ref={ref} className={"bg-surface-container rounded-md"}>
      <div className={"flex flex-col gap-1 sm:gap-2 p-2"}>
        <div className={"flex flex-row gap-2 items-center"}>
          <div className={"h-7 w-7 flex justify-center items-center"}>
            <div className={"bg-primary h-7 w-7 rounded-sm p-1 flex items-center justify-center"}>
              <CupIcon className={"fill-on-primary"} />
            </div>
          </div>
          <div className={"flex flex-col justify-between"}>
            <p className={"text-primary text-sm font-semibold"}>Reward pool</p>
            {lastRewardPoints > 0 && (
              <p className={"text-on-surface font-normal text-xs"}>
                Avg. points needed for a reward: <span className={"text-primary font-semibold"}>{lastRewardPoints.toFixed(2)}</span>
              </p>
            )}
          </div>
        </div>
        {rewards.length === 0 && thresholds.length === 0 && (
          <div className={"flex flex-col justify-center items-center py-32"}>
            <div className={"flex flex-col justify-center items-center text-sm w-2/3 text-center gap-1"}>
              <div className={"h-32 w-32"}>
                <NoGraphResultsImage />
              </div>
              <p className={"font-semibold text-on-surface"}>No data for selected division</p>
              <p className={"font-normal text-on-surface-variant"}>
                The prize pool may not be available yet or this division does not have enough history for a reward pool analysis
              </p>
            </div>
          </div>
        )}
        {rewards?.map((s, i) => {
          let header;
          if (s.max_rank === s.min_rank) {
            if (s.min_rank === 1) {
              header = (
                <div className={"px-1"}>
                  <img className={"h-4 w-4"} src={GoldMedal} />
                </div>
              );
            } else if (s.min_rank === 2) {
              header = (
                <div className={"px-1"}>
                  <img className={"h-4 w-4"} src={SilverMedal} />
                </div>
              );
            } else if (s.min_rank === 3) {
              header = (
                <div className={"px-1"}>
                  <img className={"h-4 w-4"} src={BronzeMedal} />
                </div>
              );
            } else {
              header = <p className={"text-xs text-on-surface font-semibold"}>{s.max_rank}</p>;
            }
          } else {
            header = (
              <p className={"text-xs text-on-surface font-semibold"}>
                {s.min_rank}-{s.max_rank}
              </p>
            );
          }
          return (
            <div key={i} className={"flex flex-row justify-between items-center rounded bg-surface-container-high py-1 sm:py-1.5 px-2"}>
              <div>{header}</div>
              <div className={"flex flex-row gap-2 items-center"}>
                <div className={"flex flex-col sm:flex-row gap-x-2 gap-y-0 items-center"}>
                  <span className={"hidden sm:block text-on-surface-variant text-xxs font-semibold"}>AVERAGE SCORE</span>
                  <span className={"sm:hidden text-on-surface-variant text-xxs font-semibold"}>AVG. SCORE</span>
                  <span className={"text-on-surface text-xxs sm:text-xs font-semibold"}>{s.min_score.toFixed(2)} pts</span>
                </div>
                {s.moneyReward.primary.price > 0 && (
                  <MoneyReward
                    reward={s.moneyReward}
                    primaryValue={s.primaryValueFormatted}
                    secondaryValue={s.secondaryValueFormatted}
                    width={widthReward}
                  />
                )}
                {s.cardRewards?.map((cr, j) => {
                  return <CardReward key={j} scarcity={cr.scarcity} tier={cr.tier} />;
                })}
              </div>
            </div>
          );
        })}
        {thresholds.length > 0 && (
          <>
            <div className={"border-t border-transparent-inverse-surface-low border-opacity-10"}></div>
            {thresholds.map((t, i) => {
              const primary = t.moneyReward.primary;
              const primaryValue = formatPriceNoCurrency(
                primary.price,
                primary.currency,
                undefined,
                primary.currency !== "eth" ? 0 : undefined,
              );
              const secondary = t.moneyReward.secondary;
              const secondaryValue = formatPrice(secondary.price, secondary.currency); // with currency displayed
              const widthReward = primaryValue.length * 0.65 + "em";
              return (
                <div key={i} className={"flex flex-row justify-between items-center rounded bg-surface-container-high py-1.5 px-2"}>
                  <div>
                    <p className={"text-xs text-on-surface-variant font-semibold"}>{`≥ ${t.minScore} pts`}</p>
                  </div>
                  <div className={"flex flex-row gap-2 items-center"}>
                    <MoneyReward reward={t.moneyReward} primaryValue={primaryValue} secondaryValue={secondaryValue} width={widthReward} />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div
        className={
          "border-t border-transparent-inverse-surface-low border-opacity-10 flex flex-row justify-end px-2 py-3 items-center gap-1"
        }
      >
        <div>
          <p className={"text-xxs font-semibold text-on-surface-variant"}>
            <span className={"text-primary"}>{prizePool?.lineups || 0}</span> lineup registered until now
          </p>
        </div>
      </div>
    </div>
  );
});

export const MoneyReward = (props) => {
  const { reward, width, primaryValue, secondaryValue } = props;
  const primaryCurrency = reward.primary.currency;
  let Icon;
  let iconClass = "h-3 w-3";
  switch (primaryCurrency) {
    case "usd":
      Icon = Dollar;
      break;
    case "eur":
      Icon = Euro;
      break;
    case "gbp":
      Icon = Gbp;
      break;
    default: // "eth"
      iconClass = "h-4 w-4";
      Icon = EtherIcon;
      break;
  }
  return (
    <div data-tip={secondaryValue} className={"bg-black bg-opacity-10 p-1 rounded flex flex-row gap-1 items-center justify-between"}>
      <div className={iconClass}>
        <span>
          <Icon className={`${iconClass} fill-on-surface`} />
        </span>
      </div>
      <span className={"text-on-surface text-xs font-semibold text-center"} style={width ? { width: width } : {}}>
        {primaryValue}
      </span>
    </div>
  );
};

export const CardReward = (props) => {
  const { scarcity, tier, dark } = props;
  const fontSize = props.fontSize || "text-xs";
  const cardSize = props.cardSize || "w-2.5";
  const darkTextColor = getThemeColor(sdDark, "on-surface");
  const textColor = dark ? "" : "text-on-surface";
  const style = { width: "3.2em" };
  if (dark) style.color = darkTextColor;
  return (
    <div className={`bg-black bg-opacity-10 p-1 rounded flex flex-row gap-1 items-center justify-between`}>
      <div className={cardSize}>
        <NewCardIcon scarcity={scarcity?.toLowerCase()} />
      </div>
      <span className={`${textColor} ${fontSize} font-semibold text-center`} style={style}>
        {tier > 0 ? "Tier " + tier : "Star"}
      </span>
    </div>
  );
};

export const CoinReward = (props) => {
  const { coins } = props;
  return (
    <div className={"bg-black bg-opacity-10 p-1 rounded flex flex-row gap-1 items-center justify-between"}>
      <div className={"w-[1rem]"}>
        <img className={"self-center"} src={Coin} />
      </div>
      <span className={"text-on-surface text-xs font-semibold text-center"} style={{ width: "3.2em" }}>
        {coins}
      </span>
    </div>
  );
};
