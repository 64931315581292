import React from "react";
import { format } from "date-fns";
import UnknownClub from "../../img/unknown_club.png";
import LiveCircle from "../util/liveCircle";
import { sorareBaseball, sorareBasketball } from "../util/sports";

function GamePreview(props) {
  if (props.game !== undefined) {
    let homeScore = props.game.game.HomeScore;
    let awayScore = props.game.game.AwayScore;
    if (awayScore === undefined) {
      awayScore = props.game.game.AwayGoals;
    }
    if (homeScore === undefined) {
      homeScore = props.game.game.HomeGoals;
    }
    let text = homeScore + " - " + awayScore;
    let size = "text-base";
    if (props.size) {
      size = props.size;
    }
    let status = props.game.game.Status;
    let textColor = props.textColor || "text-brand-black";
    let gameTime = new Date(props.game.game.GameTime);
    if (status === "scheduled" || status === "created") {
      text = format(new Date(props.game.game.GameTime), "dd/MM - HH:mm");
      size = "text-xs";
      if (!props.showScore) {
        size = "text-xs hidden md:block";
      }
      if (props.hideDate) {
        text = "-";
      }
    }
    let gameEnd = new Date(props.game.game.GameTime);
    gameEnd.setUTCHours(gameTime.getUTCHours() + 2);
    let imgSize = "25px";
    if (props.imgSize) imgSize = props.imgSize;
    let dotColor = "#FF3232";
    if (status === "scheduled" && props.game.game.HasLineups === true) {
      dotColor = "#0380F3";
    }
    let margin = "md:mx-auto";
    if (props.noMargin) {
      margin = "";
    }
    let spacing = "space-x-1";
    if (status === "scheduled") {
      spacing = "space-x-2";
    }
    let logoSize = "w-8 h-8";
    if (props.logoSize) {
      logoSize = props.logoSize;
    }
    if (props.sport === sorareBaseball || props.sport === sorareBasketball) {
      return (
        <a
          href={
            props.sport === sorareBaseball
              ? "/baseballGame?gameId=" + props.game.game.GameId
              : "/basketballGame?gameId=" + props.game.game.GameId
          }
          target={"_blank"}
          rel="noreferrer"
        >
          <div className={"flex flex-row justify-center space-x-3"}>
            {status === "scheduled" && props.game.game.HasLineups === true && (
              <div className={"self-center"}>
                <LiveCircle color={dotColor} />
              </div>
            )}
            {(status === "inprogress" || status === "halftime") && (
              <div className={"self-center flex flex-row space-x-1"}>
                <div className={"self-center"}>
                  <LiveCircle color={dotColor} />
                </div>
              </div>
            )}
            {status === "wdelay" && (
              <div className={"self-center flex flex-row space-x-1"}>
                <div className={"self-center"}>
                  <LiveCircle color={"orange"} />
                </div>
              </div>
            )}
            <div
              className={
                props.dark
                  ? "flex flex-row justify-center mx-auto self-center space-x-2 text-white"
                  : "text-textGrey3 flex flex-row justify-center mx-auto self-center space-x-2"
              }
            >
              <div className={"w-4 h-4"}>
                {props.game.home_team_logo === "" ? (
                  <img data-tip={props.game.home_team} className={"w-full h-full object-contain"} src={UnknownClub} />
                ) : (
                  <img data-tip={props.game.home_team} className={"w-full h-full object-contain"} src={props.game.home_team_logo} />
                )}
              </div>
              {status !== "scheduled" && status !== "created" && (
                <p className={"font-semibold text-sm"}>
                  {homeScore} - {awayScore}
                </p>
              )}
              {(status === "scheduled" || status === "created") && (
                <div className={"flex rounded-2xl justify-center bg-grey-e5 self-center px-2 py-0.5"}>
                  <p className={size + " inline-block align-middle justify-center font-semibold hover:font-bold font-normal"}>{text}</p>
                </div>
              )}
              <div className={"w-4 h-4"}>
                {props.game.away_team_logo === "" ? (
                  <img data-tip={props.game.away_team} className={"w-full h-full object-contain"} src={UnknownClub} />
                ) : (
                  <img data-tip={props.game.away_team} className={"w-full h-full object-contain"} src={props.game.away_team_logo} />
                )}
              </div>
            </div>
            {status === "inprogress" && props.sport === sorareBaseball && (
              <div className={"self-center flex flex-row text-textGrey3"}>
                <p className={"self-center text-xs font-semibold"}>
                  {props.game.game.LiveDetails.half + "" + props.game.game.LiveDetails.inning}
                </p>
              </div>
            )}
            {status === "wdelay" && (
              <div className={"self-center flex flex-row text-textGrey3"}>
                <p className={"self-center text-xs font-semibold"} data-tip={"Weather delay"}>
                  WD
                </p>
              </div>
            )}
          </div>
        </a>
      );
    } else {
      return (
        <div className={"md:flex md:flex-row space-x-2 self-center md:justify-center h-8 " + margin}>
          {(status === "playing" || (status === "scheduled" && props.game.game.HasLineups === true)) && !props.noCircle && (
            <div className={"self-center pb-1.5"}>
              <LiveCircle color={dotColor} />
            </div>
          )}
          {status === "inprogress" && !props.noCircle && (
            <div className={"self-center flex flex-row space-x-1"}>
              <div className={"self-center pb-1.5"}>
                <LiveCircle color={dotColor} />
              </div>
              <p className={"self-center text-sm font-semibold"}>
                {props.game.game.LiveDetails.half + "" + props.game.game.LiveDetails.inning}
              </p>
            </div>
          )}
          <a
            href={props.sport === sorareBaseball ? "/baseballGame?gameId=" + props.game.game.GameId : "/game/" + props.game.game.GameId}
            target={"_blank"}
            rel="noreferrer"
          >
            <div className={"flex flex-row justify-center mx-auto self-center " + spacing + " " + textColor}>
              <div className={logoSize + " "}>
                {props.game.home_team_logo === "" ? (
                  <img className={"rounded-full w-full h-full object-contain"} src={UnknownClub} />
                ) : (
                  <img
                    data-tip={props.game.home_team}
                    className={"rounded-full w-full h-full object-contain"}
                    src={props.game.home_team_logo}
                  />
                )}
              </div>
              {(status === "playing" || status === "played" || status === "complete" || status === "closed" || status === "inprogress") && (
                <div className={"flex w-auto md:w-full text-center justify-center self-center"}>
                  <p className={size + " inline-block align-middle justify-center font-semibold hover:font-bold self-center"}>{text}</p>
                </div>
              )}
              {(status === "scheduled" || status === "created") && props.dark && (
                <div className={"flex rounded-2xl justify-center self-center"}>
                  <p
                    className={
                      "text-sm " +
                      size +
                      " inline-block align-middle self-center justify-center font-semibold hover:font-bold font-medium text-white"
                    }
                  >
                    {text}
                  </p>
                </div>
              )}
              {(status === "scheduled" || status === "created") && !props.dark && (
                <div className={"flex rounded-2xl md:w-11/12 justify-center bg-grey-e5 mt-1 self-center py-0.5"}>
                  <p
                    className={size + " inline-block align-middle justify-center font-semibold hover:font-bold font-normal text-textGrey3"}
                  >
                    {text}
                  </p>
                </div>
              )}
              <div className={logoSize + " "}>
                {props.game.away_team_logo === "" ? (
                  <img className={"rounded-full w-full h-full object-contain"} src={UnknownClub} />
                ) : (
                  <img
                    data-tip={props.game.away_team}
                    className={"rounded-full w-full h-full object-contain"}
                    src={props.game.away_team_logo}
                  />
                )}
              </div>
            </div>
          </a>
        </div>
      );
    }
  } else {
    let size = "small";
    let textColor = props.textColor || "text-brand-black";
    if (props.fontSize) size = props.fontSize;
    return (
      <div className={"flex flex-row justify-center self-start inline-block align-middle text-center py-1.5"}>
        <p className={"text-xs lg:text-sm " + textColor}>No game</p>
      </div>
    );
  }
}

export default GamePreview;
