import React from "react";
import AppStoreBadge from "../../img/apple-app-store.png";
import PlayStoreBadge from "../../img/google-play-store.png";
import { getNavigatorLang } from "./locale";

export const AppleAppStoreButton = (props) => {
  const navLang = getNavigatorLang();
  const url =
    navLang === "fr-FR" ? "https://apps.apple.com/fr/app/soraredata/id1590662758" : "https://apps.apple.com/us/app/soraredata/id1590662758";
  return <Badge width={props.width} href={url} img={AppStoreBadge} />;
};

export const GooglePlayStoreButton = (props) => {
  return <Badge width={props.width} href={"https://play.google.com/store/apps/details?id=com.soraredata"} img={PlayStoreBadge} />;
};

const Badge = (props) => {
  const width = props.width || "w-40";
  const href = props.href || "";
  const img = props.img || "";
  return (
    <a className={`${width} hover:opacity-80`} href={href} target={"_blank"} rel="noopener noreferrer">
      <img className={"border border-white rounded-lg"} src={img} />
    </a>
  );
};

export const StoreButtons = (props) => {
  return (
    <>
      <GooglePlayStoreButton />
      <AppleAppStoreButton />
    </>
  );
};
