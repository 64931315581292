import React from "react";
import { withUser } from "../../userContext";
import CaptainBadge from "../../img/captain-badge.svg";

function CustomLineupCard(props) {
  const card = props.card;
  if (card) {
    return (
      <div>
        <div
          className={
            "flex flex-col w-card-1.5xl h-card-1.5xl 4xl:w-card-4xl 4xl:h-card-4xl lg:w-card lg:h-card 2.5xl:w-card-2.5xl 2.5xl:h-card-2.5xl xl:w-card-xl xl:h-card-xl  1.5xl:w-card-1.5xl 1.5xl:h-card-1.5xl rounded-lg justify-content-center bg-cover z-0 -space-y-8"
          }
        >
          <div className={"flex flex-row justify-between w-full h-6"}>
            {props.captain && (
              <div className="flex z-10 origin-top-left transform w-7 justify-start -mt-6 -ml-1">
                <img className={"w-full h-full object-contain"} src={CaptainBadge} />
              </div>
            )}
          </div>
          <div className={"mt-2"}>
            <img src={card.PictureUrl} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={
          "flex flex-col bg-brand-black 4xl:w-card-4xl 4xl:h-card-4xl w-card-1.5xl h-card-1.5xl lg:w-card lg:h-card 2.5xl:w-card-2.5xl 2.5xl:h-card-2.5xl xl:w-card-xl xl:h-card-xl  1.5xl:w-card-1.5xl 1.5xl:h-card-1.5xl rounded-lg justify-content-center self-center -mt-4"
        }
      >
        <div className={"h-4/5 w-card self-center align-middle flex flex-row justify-center"}></div>
        <p className={"text-background-grey text-center text-base font-medium justify-end 4xl:pb-4"}>{props.position}</p>
      </div>
    );
  }
}

export default withUser(CustomLineupCard);
