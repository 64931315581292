import { useQuery } from "../util/useQuery";
import React, { useEffect } from "react";
import { ReactComponent as TrophyIcon } from "../../img/icons-trophy-no-color.svg";
import { withUser } from "../../userContext";
import RankLabel from "../util/rankLabel";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { getThemeColor, useTheme } from "../../themeContext";
import { ReactTooltip } from "../util/tooltip";
import Spinner from "../loader/spinner";
import PopHoverWrapper from "../util/popHoverWrapper";
import { CardReward, CoinsReward, MoneyReward } from "../SO5/SO5LiveDivision";
import { findPriceForUserUnit } from "../util/formatMoney";
import { NoCardResultsImage } from "../util/noCardResultsImage";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  BestGws,
  BestScoresByGw,
  CurrentGWLineupStats,
  getBestScoresFromAllScores,
  getScoresNormalized,
  ScoreTag,
} from "./longTermLeaguesLeaderboardScores";

export const LeaderboardTables = (props) => {
  const { leaderboard, authenticatedUserLeaderboard, userLeaderboard, league, userSlug, loading, onLoadMore, sport } = props;
  if (leaderboard === null || leaderboard.leaderboard === null) {
    return (
      <div className={"flex flex-col gap-3"}>
        <div className={"rounded-lg bg-surface-container h-20 animate-pulse-sm"} />
        <div className={"rounded-lg bg-surface-container h-20 animate-pulse-sm"} />
        <div className={"rounded-lg bg-surface-container flex flex-col animate-pulse-sm"}>
          <div className={"h-20 border-b border-outline-variant"} />
          <div className={"h-20 border-b border-outline-variant"} />
          <div className={"h-20"} />
        </div>
      </div>
    );
  }
  const globalLeaderboard = leaderboard.list;

  return (
    <div className={"flex flex-col gap-3"}>
      <LeaderboardTable
        sport={sport}
        border={authenticatedUserLeaderboard?.user === userSlug ? "border border-primary" : ""}
        rankings={[authenticatedUserLeaderboard]}
        league={league}
        dataTipId={"long-term-authenticated-user-leaderboard-gw-scores"}
      />
      {userLeaderboard && (
        <LeaderboardTable
          sport={sport}
          border={"border border-primary"}
          rankings={[userLeaderboard]}
          league={league}
          dataTipId={"long-term-user-leaderboard-gw-scores"}
        />
      )}
      {globalLeaderboard.length > 0 && (
        <InfiniteScroll
          dataLength={leaderboard.list}
          scrollableTarget={"window"}
          items={globalLeaderboard}
          next={onLoadMore}
          hasMore={leaderboard.hasNext}
          useWindow={true}
          loader={
            <div className={"flex justify-center items-center p-4"}>
              <Spinner />
            </div>
          }
        >
          <LeaderboardTable
            sport={sport}
            rankings={globalLeaderboard}
            league={league}
            dataTipId={"long-term-global-leaderboard-gw-scores"}
          />
        </InfiniteScroll>
      )}
      {globalLeaderboard.length === 0 && (
        <div className={"flex flex-col justify-center w-full bg-surface-container-high text-center py-8 rounded-xl"}>
          <div className={"flex flex-col justify-center"}>
            <div className={"m-auto mb-6"}>
              <NoCardResultsImage />
            </div>
            <p className={"text-lg font-headers text-center text-on-surface-variant font-bold mb-2"}>No manager with these filters</p>
          </div>
        </div>
      )}
    </div>
  );
};

export const LeaderboardTable = (props) => {
  const { rankings, league, dataTipId, border, sport } = props;
  const { theme } = useTheme();
  const bgColor = getThemeColor(theme, "surface-container");

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [rankings]);

  return (
    <div className={`rounded-xl overflow-x-hidden ${border}`}>
      <table cellSpacing="0" className={"border-collapse table-fixed w-full bg-surface-container whitespace-no-wrap"}>
        <tbody>
          {rankings.map((l, i) => {
            if (l === undefined || l === null || l.rank === null || l.rank === undefined) {
              return null;
            }
            return (
              <LeaderboardTableRow
                key={l.rank}
                rankingLine={l}
                bgColor={bgColor}
                last={i === rankings?.length - 1}
                league={league}
                dataTipId={dataTipId}
                sport={sport}
              />
            );
          })}
        </tbody>
      </table>
      <ReactTooltip id={dataTipId} />
    </div>
  );
};

const LeaderboardTableRow = (props) => {
  const { rankingLine, bgColor, last, league, dataTipId, sport } = props;
  const { rank, scores, sorareUser, currentGwLineup, totalScore, rewards } = rankingLine;
  const { nbBestLineups, startGw, endGw, status } = league;
  const query = useQuery();

  const ongoingLeague = status === "STARTED";
  const avatarBgImg =
    "linear-gradient(to right, " +
    bgColor +
    "D9 66%" +
    ", " +
    bgColor +
    "FF 100%), url('" +
    (sorareUser?.PictureUrl ? sorareUser.PictureUrl : EmptyAvatar) +
    "')";
  const bestScores = getBestScoresFromAllScores(scores, nbBestLineups);
  const scoresNormalized = getScoresNormalized(scores, endGw, startGw);

  const queryParameters = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
  queryParameters.set("sorareSlug", sorareUser.Slug);

  return (
    <tr
      className={`px-2 h-full hover:opacity-70 cursor-pointer ${last ? "" : "border-b border-outline-variant"}`}
      onClick={() => (document.location = `/longTermLeagues/${sport}/leaderboard?${queryParameters.toString()}`)}
    >
      <td className={"w-[25%] p-0 h-full"}>
        <div
          className={"relative flex flex-row items-center justify-center gap-2 w-full h-full"}
          style={{
            minHeight: "4em",
          }}
        >
          <div
            className={"w-full h-full bg-center bg-cover bg-no-repeat opacity-15"}
            style={{
              backgroundImage: avatarBgImg,
              minHeight: "inherit",
            }}
          />
          <div className={"absolute flex flex-row items-center justify-center gap-1 w-full pl-3 pr-1"}>
            <div className={"w-[20%] text-xs ml-auto flex flex-row justify-center font-semibold"}>
              {rank === 0 ? "-" : <RankLabel rank={rank} hashMode />}
            </div>
            <div className={"w-[80%] grid grid-cols-6 items-center gap-1"}>
              <div className={"flex justify-center items-center"}>
                <a href={`/manager/${sorareUser.Slug}`}>
                  <img
                    className={"inline w-7 h-7 object-cover rounded-full"}
                    onError={(e) => (e.target.src = EmptyAvatar)}
                    src={sorareUser?.PictureUrl ? sorareUser.PictureUrl : EmptyAvatar}
                  />
                </a>
              </div>
              <div className={"flex col-span-5"}>
                <div
                  className={
                    "rounded-3xl bg-transparent-surface-container-high bg-opacity-70 text-on-surface px-2 font-semibold py-0.5 my-auto text-xs truncate"
                  }
                  title={sorareUser?.Nickname || ""}
                >
                  {sorareUser?.Nickname || ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className={"w-[25%]"}>
        <div className={"flex flex-row justify-end px-1"}>
          <BestGws size={bestScores.length > 4 ? "sm" : "md"} scores={bestScores} withHover sport={sport} />
        </div>
      </td>
      <td className={"w-[20%]"}>
        {ongoingLeague && (
          <div className={"flex flex-row justify-start px-1 items-center"}>
            <div className={"h-5 border-r border-outline-variant"} />
            <div className={"p-2"}>
              <CurrentGWLineupStats lineup={currentGwLineup} />
            </div>
          </div>
        )}
      </td>
      <td className={"hidden lg:table-cell w-[15%]"}>
        <BestScoresByGw scores={scoresNormalized} league={league} dataTipId={dataTipId} />
      </td>
      <td className={"w-[5%]"}>
        <HasRewards rewards={rewards} />
      </td>
      <td className={"w-[10%]"}>
        <div className={"flex flex-row items-center justify-center pr-1"}>
          <ScoreTag width="w-14" score={rank === 0 ? "-" : totalScore} />
        </div>
      </td>
    </tr>
  );
};

const HasRewards = withUser((props) => {
  const { rewards } = props;
  if (rewards === undefined || rewards === null) {
    return <></>;
  }

  return (
    <PopHoverWrapper
      hoverTitle="Projected rewards (as it stands)"
      hover={
        <div className={"flex flex-row space-x-1.5"}>
          {rewards.cards?.map((card, i) => {
            return <CardReward key={i} scarcity={card.scarcity} tier={card.tier} />;
          })}
          {rewards.money && rewards.money["eth"] > 0 && (
            <MoneyReward money={findPriceForUserUnit(rewards.money["eth"], rewards.money, props.user.preferredUnit, true, 3)} />
          )}
          {rewards.coins > 0 && <CoinsReward coins={rewards.coins} />}
        </div>
      }
    >
      <div className={"flex flex-row justify-center items-center"}>
        <div className={"bg-transparent-inverse-surface-low bg-opacity-10 rounded-full p-1"}>
          <TrophyIcon className={"w-3 h-3 fill-scarcity-limited-on-surface-container"} />
        </div>
      </div>
    </PopHoverWrapper>
  );
});
