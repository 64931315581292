import { useEffect } from "react";

export const ScrollTo = ({ id }) => {
  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return null;
};
