import React from "react";
import { sportLabelsSingleSimple } from "./sports";

import NBAAndMLBNotSupported from "../../img/nba-mlb-not-supported.jpg";

export const SportNotSupported = (props) => {
  const { sport, featureName } = props;
  const sportLab = sportLabelsSingleSimple.find((s) => s.value === sport)?.name;
  const mediumLink = "https://medium.com/@soraredata/update-on-soraredata-mlb-and-nba-coverage-e27e08679591";
  return (
    <div className={"flex justify-center items-center"}>
      <div className={"p-5 m-5 rounded-xl bg-surface-container max-w-[700px] text-md flex flex-col gap-4 justify-center items-center"}>
        <img className={"object-contain w-full max-w-[300px]"} src={NBAAndMLBNotSupported} />
        <div className={"flex justify-center"}>
          <span className={"flex flex-col gap-2 items-center"}>
            <span>
              <b>{sportLab}</b> <b>{featureName}</b> feature is not supported anymore
            </span>
            <a href={mediumLink} className={"text-primary cursor-pointer font-semibold"}>
              Read our Medium article
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
