import React, { useState } from "react";
import ScoreEllipse from "../util/scoreEllipse";
import PlayerScoreModal from "../players/playerScoreModal";
import { sorareBasketball } from "../util/sports";

function BasketballAvatarLineupCard(props) {
  let { card, game, power, SO5Score, stats, player } = props;
  let isCaptain = props.captain;
  const [isOpen, setOverlay] = useState(false);
  return (
    <div>
      {stats !== undefined && (
        <PlayerScoreModal
          open={isOpen}
          stats={stats}
          player={player}
          position={card.Position}
          close={() => setOverlay(false)}
          game={game}
          sport={sorareBasketball}
        />
      )}
      <div className={"flex flex-col gap-1"}>
        <div className={"flex justify-center relative"}>
          {card.PlayerId !== undefined ? (
            <div className={"flex flex-row self-end justify-end"}>
              <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
                <img className={"object-cover h-10 w-10 rounded-full"} src={card.AvatarUrl || player.Avatar} />
              </a>
            </div>
          ) : (
            <img className={"object-cover"} src={card.AvatarUrl || player.Avatar} />
          )}
        </div>
        <div className={"flex flex-col self-center"}>
          <div onClick={() => setOverlay(true)} className={"flex flex-row self-center justify-center mx-auto cursor-pointer"}>
            <div className={"flex flex-row self-center justify-center items-baseline"}>
              <ScoreEllipse
                padding="px-1 py-0.5"
                size="text-xs"
                score={SO5Score}
                power={power}
                isCaptain={false}
                onGameSheet={stats?.onRoster}
                game={game?.game}
                sport={sorareBasketball}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasketballAvatarLineupCard;
