import { Text } from "grommet";
import React from "react";

function CardPlayingStatus(props) {
  let status = "";
  let size = props.size || "10px";
  let weight = props.weight || 400;
  if (props.status === "starter") {
    status = "Starter";
  } else if (props.status === "not_playing") {
    status = "Reserve";
  } else if (props.status === "substitute") {
    status = "Substitute";
  } else {
    status = props.status;
  }

  if (props.player.TeamId === "retired") {
    status = "No club";
  }
  return (
    <Text weight={weight} size={size} data-tip={"Player status over the past 5 games"} target={"_blank"}>
      {status}
    </Text>
  );
}

export default CardPlayingStatus;
