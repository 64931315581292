import NBAProjectedScore from "../util/nbaProjectionScore";
import NBAProjectedMinutes from "../util/nbaProjectedMinutes";
import React from "react";

function NBALineupBuilderTableGame(props) {
  const g = props.game;
  const game = g.game;
  const projection = g.projection;
  const homeTeam = g.homeTeam;
  const awayTeam = g.awayTeam;

  let score = projection.score;
  if (props.allOffense) {
    score = projection.offScore;
  } else if (props.allDefense) {
    score = projection.defScore;
  }

  if (props.powerAdj) {
    score = score * props.power;
  }

  return (
    <div className={"flex flex-col items-center space-y-1"}>
      <div className={"flex flex-row justify-center space-x-1"}>
        <div className={"w-4 h-4"}>
          <img className={"object-contain w-full h-full"} src={homeTeam.PictureUrl} />
        </div>
        <p className={"text-xxs font-semibold self-center"}>-</p>
        <div className={"w-4 h-4"}>
          <img className={"object-contain w-full h-full"} src={awayTeam.PictureUrl} />
        </div>
      </div>
      <div className={"flex flex-row justify-center space-x-1.5"}>
        <div className={"self-center"}>
          <NBAProjectedScore size={"w-5 h-5"} font={"text-xxs"} score={score} />
        </div>
        <div className={"self-center"}>
          <NBAProjectedMinutes size={"w-6"} font={"text-xxs"} minutes={projection.minutes} />
        </div>
      </div>
    </div>
  );
}

export default NBALineupBuilderTableGame;
