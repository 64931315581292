import React from "react";
import Emergency from "../../img/emergency.svg";

function CardInUse(props) {
  return (
    <div className={"flex flex-row justify-center"}>
      <div className={"py-0.5 px-2 rounded-full bg-[#FDBA9B] border border-[#333333] flex flex-row justify-center space-x-1"}>
        <img className={"self-center"} src={Emergency} />
        <p className={"text-xs font-semibold text-[#333333] self-center"}>Used</p>
      </div>
    </div>
  );
}

export default CardInUse;
