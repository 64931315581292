import React from "react";

function GameLivePageHeader(props) {
  return (
    <div
      className={
        "bg-containers-surface-focus-active-primarytint text-primary font-semibold flex flex-row justify-center w-full p-4 rounded-xl"
      }
    >
      <p className={"text-base self-center text-center"}>{props.label}</p>
    </div>
  );
}

export default GameLivePageHeader;
