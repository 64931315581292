import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import { Button } from "./button";

const ConfirmModal = (props) => {
  const open = props.open || false;
  const title = props.title || "";
  const message = props.message || "";
  const error = props.error || "";
  const confirming = props.confirming || false;
  const confirmLabel = props.confirmLabel || "Confirm";
  const confirmingLabel = props.confirmingLabel || "Confirming...";
  const hideCancel = props.hideCancel || false;
  const hideClose = props.hideClose || false;

  const onCancel = () => {
    props.onCancel();
  };

  const onConfirm = () => {
    props.onConfirm();
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={onCancel}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-surface-container-high rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  {!hideClose && (
                    <div className={"flex flex-row cursor-pointer"} onClick={onCancel}>
                      <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
                    </div>
                  )}
                </div>
                <div className={"w-auto mt-8 text-on-surface"}>
                  <div className={"w-full border-b-2 border-transparent-inverse-surface-low border-opacity-10 pb-4"}>
                    <h2 className={"text-center font-headers text-3xl font-bold mt-0"}>{title}</h2>
                  </div>
                  <div className={"mx-full mb-8 px-6"}>
                    <div className="mt-2 py-5 text-center">
                      <p className="text-med font-semibold">{message}</p>
                    </div>
                    <div className={"flex flex-row mt-4 gap-2"}>
                      <div className={"ml-auto mr-0 inline flex flex-row gap-2"}>
                        {!hideCancel && <Button context="neutral" label={"Cancel"} onClick={onCancel} />}
                        <Button label={confirming ? <span>{confirmingLabel}</span> : <span>{confirmLabel}</span>} onClick={onConfirm} />
                      </div>
                    </div>
                    {error && <p className={"text-live-live-container-fixed"}>{error}</p>}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ConfirmModal;
