import { CUSTOM_SERIES_COLOR, LIMITED_COLOR, RARE_COLOR, SUPER_RARE_COLOR, UNIQUE_COLOR } from "../util/colors";
import BgCollectionBox from "../../img/bg-collection-box.svg";
import UnknownClub from "../../img/unknown_club.png";
import LiveCircle from "../util/liveCircle";
import { capitalize } from "../util/capitalize";
import React from "react";

function ManagerCollectionBox(props) {
  let c = props.c;
  let index = props.index;
  let sc = c.scarcity.toLowerCase().replace(" ", "-");
  let color = "";
  if (c.scarcity === "LIMITED") {
    color = LIMITED_COLOR;
  } else if (c.scarcity === "RARE") {
    color = RARE_COLOR;
  } else if (c.scarcity === "SUPER RARE") {
    color = SUPER_RARE_COLOR;
  } else if (c.scarcity === "UNIQUE") {
    color = UNIQUE_COLOR;
  } else if (c.scarcity === "CUSTOM SERIES") {
    color = CUSTOM_SERIES_COLOR;
  }
  return (
    <a href={"/collection?manager=" + props.manager + "&teamId=" + c.teamId + "&scarcity=" + c.scarcity + "&season=" + c.season}>
      <div
        className={"rounded-lg relative cursor-pointer"}
        key={index}
        style={{
          background: "linear-gradient(169.15deg, #6A6A6A 0.46%, #212121 94.08%)",
        }}
      >
        <div className={"w-full rounded-t-lg h-2"} style={{ backgroundColor: color }}></div>
        <div
          className={"absolute w-full z-0 h-10"}
          style={{
            backgroundImage: "url('" + BgCollectionBox + "')",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className={"p-4"}>
          <div className={"flex flex-row justify-center mb-2 z-20"}>
            <div className={"w-12 h-12"}>
              <img className={"w-full h-full object-contain"} src={c.team.PictureUrl !== "" ? c.team.PictureUrl : UnknownClub} />
            </div>
          </div>
          <div className={"space-y-4"}>
            <div className={"flex flex-col items-center space-y-2"}>
              <p className={"text-center text-base font-semibold font-headers text-white"}>{c.team.ShortName}</p>
              <div className={"flex flex-row space-x-2"}>
                <div className={"bg-[#FAFAFA] font-semibold text-xs font-semibold px-1.5 py-0.5 rounded-full flex-row flex space-x-1"}>
                  <div className={"self-center"}>
                    <LiveCircle color={color} />
                  </div>
                  <div>
                    <p>{capitalize(c.scarcity)}</p>
                  </div>
                </div>
                <div className={"bg-[#FAFAFA] font-semibold text-xs font-semibold px-1.5 py-0.5 rounded-full"}>{c.season}</div>
              </div>
            </div>
            <div className={"flex flex-row justify-center space-x-6"}>
              <div>
                <p className={"text-xs font-semibold text-[#B3B3B3]"}>Cards</p>
                <p className={"text-xs font-medium text-center text-white"}>
                  {c.cardsNb}/{c.totalCards}
                </p>
              </div>
              <div>
                <p className={"text-xs font-semibold text-[#B3B3B3]"}>Score</p>
                <p className={"text-xs font-medium text-center text-white"}>{c.totalScore} pts</p>
              </div>
              <div>
                <p className={"text-xs font-semibold text-[#B3B3B3]"}>Bonus</p>
                <p className={"text-xs font-medium text-center text-white"}>{c.bonus}%</p>
              </div>
            </div>
            <div className={"w-10/12 mx-auto flex flex-row justify-center text-white text-xxs font-semibold space-x-2"}>
              <div className="bg-[#D9D9D9] rounded-full h-1 w-full self-center">
                <div className="bg-super-rare h-1 rounded-full" style={{ width: c.progress + "%" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default ManagerCollectionBox;
