import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import clsx from "clsx";

const Snackbar = ({ message, isOpen, onClose, onAction, duration = 3000, position = "bottom-center" }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose(); // Close the snackbar after the duration
      }, duration);
      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, [isOpen, onClose, duration]);

  const positionClasses = {
    "bottom-center": "bottom-4 left-1/2 transform -translate-x-1/2",
    "bottom-left": "bottom-4 left-4",
    "bottom-right": "bottom-4 right-4",
    "top-center": "top-4 left-1/2 transform -translate-x-1/2",
    "top-left": "top-4 left-4",
    "top-right": "top-4 right-4",
  };

  return (
    <div
      className={clsx(
        positionClasses[position],
        "fixed pl-4 pr-3 py-3 bg-inverse-surface text-white rounded-lg shadow-lg transition-opacity duration-300 ease-in-out",
        isOpen ? "" : "hidden pointer-events-none",
      )}
    >
      <div className="flex items-center justify-between space-x-4">
        <span className="text-sm">{message}</span>
        <div className="flex items-center space-x-2">
          {onAction && (
            <button onClick={onAction} className="text-inverse-primary hover:opacity-80 focus:outline-none text-sm font-semibold">
              Undo
            </button>
          )}
          <CloseIcon onClick={onClose} className="fill-inverse-on-surface h-6 w-6 cursor-pointer hover:opacity-80" />
        </div>
      </div>
    </div>
  );
};

export default Snackbar;
