import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import SO5PlayerLine from "./SO5PlayerLine";
import { withUser } from "../../userContext";
import { leagues_no_div_objects, leagues_with_divisions } from "../util/leagues";
import SDLoading from "../util/SDLoading";
import { GLOBAL_MARGIN_NO_BG } from "../util/margin";
import SelectSearch from "react-select-search";
import BoxWithToggle from "../util/boxWithToggle";
import { all_positions_objects } from "../util/positions";
import { errorCatcher } from "../util/errors";

function SO5PlayersPerformance(props) {
  const [userPerformances, setUserPerformances] = useState([]);
  const [positionValue, setPositionValue] = useState("all");
  const [leagueValue, setLeagueValue] = useState("all");
  const [u23Only, setU23Only] = useState(false);
  const [myPlayersOnly, setMyPlayersOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserPerformers();
  }, [props.gwNumber]);

  const getUserPerformers = (l, u23, mp, p) => {
    setIsLoading(true);
    let leagueVal = leagueValue;
    if (l !== undefined) {
      leagueVal = l;
    }
    let u23Val = u23Only;
    if (u23 !== undefined) {
      u23Val = u23;
    }
    let mpVal = myPlayersOnly;
    if (mp !== undefined) {
      mpVal = mp;
    }
    let positionVal = positionValue;
    if (p !== undefined) {
      positionVal = p;
    }
    if (positionValue === "Overall") positionVal = "all";
    if (leagueValue === leagues_with_divisions[0]) leagueVal = "all";
    if (mpVal === true) {
      props
        .fetch(`/apiv2/SO5/userPerformers/${positionVal}/${u23Val}/${props.gwNumber}/${props.user.sorareSlug}/${leagueVal}`)
        .then((response) => response.json())
        .then(async (res) => {
          setUserPerformances(res);
          setIsLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setIsLoading(false);
          }),
        );
    } else {
      props
        .fetch(`/apiv2/SO5/userPerformersAll/${positionVal}/${u23Val}/${props.gwNumber}/${props.user.sorareSlug}/${leagueVal}`)
        .then((response) => response.json())
        .then(async (res) => {
          setUserPerformances(res);
          setIsLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setIsLoading(false);
          }),
        );
    }
  };

  const changeU23Only = () => {
    getUserPerformers(undefined, !u23Only);
    setU23Only(!u23Only);
  };

  const changeMyPlayersOnly = () => {
    getUserPerformers(undefined, undefined, !myPlayersOnly);
    setMyPlayersOnly(!myPlayersOnly);
  };

  const changePositionValue = (p) => {
    getUserPerformers(undefined, undefined, undefined, p);
    setPositionValue(p);
  };

  const changeLeagueValue = (l) => {
    getUserPerformers(l);
    setLeagueValue(l);
  };

  if (isLoading) {
    return (
      <div className={GLOBAL_MARGIN_NO_BG}>
        {isLoading && (
          <div>
            <SDLoading />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={GLOBAL_MARGIN_NO_BG + " space-y-4"}>
        <div className={"grid grid-cols-4 gap-x-4"}>
          <div className={"z-20"}>
            <SelectSearch
              options={all_positions_objects}
              closeOnSelect={true}
              value={positionValue}
              onChange={(v) => {
                changePositionValue(v);
              }}
            />
          </div>
          <div>
            <SelectSearch
              options={leagues_no_div_objects}
              closeOnSelect={true}
              value={leagueValue}
              onChange={(v) => {
                changeLeagueValue(v);
              }}
            />
          </div>
          <BoxWithToggle label={"U23 eligible"} setActive={() => changeU23Only()} isActive={u23Only} />
          <BoxWithToggle label={"My players only"} setActive={() => changeMyPlayersOnly()} isActive={myPlayersOnly} />
        </div>
        <div>
          {userPerformances !== null && userPerformances.length > 0 ? (
            <div className={"grid grid-cols-2 gap-x-4 gap-y-2 w-full"}>
              {userPerformances.map((p, i) => {
                return (
                  <div key={p.player.PlayerId + i + p.player.Age}>
                    <SO5PlayerLine player={p} index={i + 1} />
                  </div>
                );
              })}
              <ReactTooltip />
            </div>
          ) : props.user.sorareSlug === undefined ? (
            <div className={"text-center text-base"}>
              <p>
                <a href={"/login"} className={"text-brand-text"}>
                  Log in
                </a>{" "}
                to find out how your players performed!
              </p>
            </div>
          ) : (
            <div className={"text-center text-base"}>
              <p>None of your players played this gameweek so far.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withUser(SO5PlayersPerformance);
