import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { useLocation } from "react-router-dom";
import NotOptimized from "../util/notOptimized";
import PlayerScoreModal from "../players/playerScoreModal";
import { sorareBaseball } from "../util/sports";
import UnknownClub from "../../img/unknown_club.png";
import LiveCircle from "../util/liveCircle";
import format from "date-fns/format";
import PlayerAvatar from "../util/playerAvatar";
import BaseballScoreCircle from "../util/baseballScoreCircle";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function HittersTable(props) {
  const [players, setPlayers] = useState(props.players);
  const [whatToSort, setWhatToSort] = useState("Score");
  const [howToSort, setHowToSort] = useState(-1);

  const sortData = (w, p) => {
    let newPlayers;
    if (p !== undefined) newPlayers = [...p];
    else if (players.length > 0) newPlayers = [...players];
    else return;
    let sort = howToSort;
    if (w === whatToSort) {
      sort = howToSort * -1;
    } else {
      sort = 1;
    }
    setHowToSort(sort);
    setWhatToSort(w);
    newPlayers.sort(function (a, b) {
      if (parseFloat(a[w]) > parseFloat(b[w])) {
        return -1 * sort;
      } else if (parseFloat(a[w]) < parseFloat(b[w])) {
        return sort;
      }
      return 0;
    });
    setPlayers(newPlayers);
  };

  useEffect(() => {
    setPlayers(props.players);
    if (props.players && props.players.length > 0) {
      sortData("Score", props.players);
    }
  }, [props.players]);

  const basicStatSize = "w-1/18 cursor-pointer";

  if (players !== null && players !== undefined) {
    return (
      <div className={"w-full rounded-b-lg"}>
        <table className={"bg-white rounded-b-lg"}>
          <thead className={"text-sm bg-brand-black text-white rounded-t-lg py-2"}>
            <tr className={"rounded-t-lg"}>
              <th className={"rounded-tl-lg w-2/12 py-2"}>Player</th>
              <th onClick={() => sortData("PlateAppearance")} className={basicStatSize}>
                PA
              </th>
              <th onClick={() => sortData("AtBats")} className={basicStatSize}>
                AB
              </th>
              <th onClick={() => sortData("Hit")} className={basicStatSize}>
                H
              </th>
              <th onClick={() => sortData("Single")} className={basicStatSize}>
                1B
              </th>
              <th onClick={() => sortData("Double")} className={basicStatSize}>
                2B
              </th>
              <th onClick={() => sortData("Triple")} className={basicStatSize}>
                3B
              </th>
              <th onClick={() => sortData("HomeRun")} className={basicStatSize}>
                HR
              </th>
              <th onClick={() => sortData("Rbi")} className={basicStatSize}>
                RBI
              </th>
              <th onClick={() => sortData("Run")} className={basicStatSize}>
                R
              </th>
              <th onClick={() => sortData("Walk")} className={basicStatSize}>
                BB
              </th>
              <th onClick={() => sortData("Hbp")} className={basicStatSize}>
                HBP
              </th>
              <th onClick={() => sortData("BattingStrikeout")} className={basicStatSize}>
                K
              </th>
              <th onClick={() => sortData("Score")} className={"cursor-pointer rounded-tr-lg w-1/12"}>
                Score
              </th>
            </tr>
          </thead>
          <tbody>
            {players.map((p, i) => {
              const basicStatStyle = "text-center";
              let rounded = "";
              if (i === players.length - 1) {
                rounded = "rounded-b-lg";
              }
              const player = p.player;
              return (
                <tr className={"font-medium text-med"}>
                  <td>
                    <div className={"flex flex-row space-x-4 py-1 pl-2"}>
                      <div className={"w-8 h-8 self-center"}>
                        <PlayerAvatar player={player} size={"w-full h-full"} imgSize={"w-8 h-8"} />
                      </div>
                      <div className={"text-xs font-semibold self-center"}>
                        <p>
                          <a href={"/player/" + player.PlayerId}>{player.MatchName}</a>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className={basicStatStyle}>{p.PlateAppearance}</td>
                  <td className={basicStatStyle}>{p.AtBats}</td>
                  <td className={basicStatStyle}>{p.Hit}</td>
                  <td className={basicStatStyle}>{p.Single}</td>
                  <td className={basicStatStyle}>{p.Double}</td>
                  <td className={basicStatStyle}>{p.Triple}</td>
                  <td className={basicStatStyle}>{p.HomeRun}</td>
                  <td className={basicStatStyle}>{p.Rbi}</td>
                  <td className={basicStatStyle}>{p.Run}</td>
                  <td className={basicStatStyle}>{p.Walk}</td>
                  <td className={basicStatStyle}>{p.Hbp}</td>
                  <td className={basicStatStyle}>{p.BattingStrikeout}</td>
                  <td className={rounded}>
                    <div className={"flex flex-row justify-center " + rounded}>
                      <div className={"w-12"}>
                        <BaseballScoreCircle score={p.Score} />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
}

function PitchersTable(props) {
  const [players, setPlayers] = useState(props.players);
  const [whatToSort, setWhatToSort] = useState("Score");
  const [howToSort, setHowToSort] = useState(-1);

  const sortData = (w, p) => {
    let newPlayers;
    if (p !== undefined) newPlayers = [...p];
    else if (players.length > 0) newPlayers = [...players];
    else return;
    let sort = howToSort;
    if (w === whatToSort) {
      sort = howToSort * -1;
    } else {
      sort = 1;
    }
    setHowToSort(sort);
    setWhatToSort(w);
    newPlayers.sort(function (a, b) {
      if (parseFloat(a[w]) > parseFloat(b[w])) {
        return -1 * sort;
      } else if (parseFloat(a[w]) < parseFloat(b[w])) {
        return sort;
      }
      return 0;
    });
    setPlayers(newPlayers);
  };

  useEffect(() => {
    setPlayers(props.players);
    if (props.players && props.players.length > 0) {
      sortData("Score", props.players);
    }
  }, [props.players]);

  const basicStatSize = "w-1/18 cursor-pointer";

  if (players !== null && players !== undefined) {
    return (
      <div className={"w-full rounded-b-lg"}>
        <table className={"bg-white rounded-b-lg"}>
          <thead className={"text-sm bg-brand-black text-white rounded-t-lg py-2"}>
            <tr className={"rounded-t-lg"}>
              <th className={"rounded-tl-lg w-2/12 py-2"}>Player</th>
              <th onClick={() => sortData("Position")} className={basicStatSize}>
                POS
              </th>
              <th onClick={() => sortData("InningsPitched")} className={basicStatSize}>
                IP
              </th>
              <th onClick={() => sortData("EarnedRun")} className={basicStatSize}>
                ER
              </th>
              <th onClick={() => sortData("HitAllowed")} className={basicStatSize}>
                H
              </th>
              <th onClick={() => sortData("SingleAllowed")} className={basicStatSize}>
                1B
              </th>
              <th onClick={() => sortData("DoubleAllowed")} className={basicStatSize}>
                2B
              </th>
              <th onClick={() => sortData("TripleAllowed")} className={basicStatSize}>
                3B
              </th>
              <th onClick={() => sortData("HomeRunAllowed")} className={basicStatSize}>
                HR
              </th>
              <th onClick={() => sortData("WalkIssued")} className={basicStatSize}>
                BB
              </th>
              <th onClick={() => sortData("HbpIssued")} className={basicStatSize}>
                HBP
              </th>
              <th onClick={() => sortData("Strikeout")} className={basicStatSize}>
                K
              </th>
              <th onClick={() => sortData("Win")} className={basicStatSize}>
                W
              </th>
              <th onClick={() => sortData("Hold")} className={basicStatSize}>
                H
              </th>
              <th onClick={() => sortData("Save")} className={basicStatSize}>
                S
              </th>
              <th onClick={() => sortData("Score")} className={"cursor-pointer rounded-tr-lg w-1/12"}>
                Score
              </th>
            </tr>
          </thead>
          <tbody>
            {players.map((p, i) => {
              const basicStatStyle = "text-center";
              let rounded = "";
              if (i === players.length - 1) {
                rounded = "rounded-b-lg";
              }
              const player = p.player;
              return (
                <tr className={"font-medium text-med"}>
                  <td>
                    <div className={"flex flex-row space-x-4 py-2 pl-2"}>
                      <div className={"w-8 h-8 self-center"}>
                        <PlayerAvatar player={player} size={"w-full h-full"} imgSize={"w-8 h-8"} />
                      </div>
                      <div className={"text-xs font-semibold self-center"}>
                        <p>
                          <a href={"/player/" + player.PlayerId}>{player.MatchName}</a>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className={basicStatStyle}>{p.Position}</td>
                  <td className={basicStatStyle}>{p.InningsPitched.toFixed(1)}</td>
                  <td className={basicStatStyle}>{p.EarnedRun}</td>
                  <td className={basicStatStyle}>{p.HitAllowed}</td>
                  <td className={basicStatStyle}>{p.SingleAllowed}</td>
                  <td className={basicStatStyle}>{p.DoubleAllowed}</td>
                  <td className={basicStatStyle}>{p.TripleAllowed}</td>
                  <td className={basicStatStyle}>{p.HomeRunAllowed}</td>
                  <td className={basicStatStyle}>{p.WalkAllowed}</td>
                  <td className={basicStatStyle}>{p.HbpIssued}</td>
                  <td className={basicStatStyle}>{p.Strikeout}</td>
                  <td className={basicStatStyle}>{p.Win}</td>
                  <td className={basicStatStyle}>{p.Hold}</td>
                  <td className={basicStatStyle}>{p.Save}</td>
                  <td className={rounded}>
                    <div className={"flex flex-row justify-center " + rounded}>
                      <div className={"w-12"}>
                        <BaseballScoreCircle score={p.Score} />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
}

function BaseballGameLive(props) {
  let query = useQuery();
  const gameId = query.get("gameId");

  const [gameInfo, setGameInfo] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayStats, setOverlayStats] = useState({});
  const [overlayLineups, setOverlayLineups] = useState({});

  const getInfo = () => {
    props
      .fetch("/apiv2/games/baseball?gameId=" + gameId)
      .then((response) => response.json())
      .then((res) => {
        setGameInfo(res);
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    console.log(gameId);
    if (gameId !== undefined) {
      getInfo();
    }
  }, [gameId]);

  const game = gameInfo?.game;
  const gameStatus = game?.game?.Status;
  const homeTeam = gameInfo?.home?.team;
  const awayTeam = gameInfo?.away?.team;
  const gameTime = game?.GameTime;
  const homeLineup = gameInfo?.home?.positions;
  const awayLineup = gameInfo?.away?.positions;

  return (
    <div>
      <NotOptimized />
      <div className={"mt-16 w-full xl:w-11/12 2.5xl:w-10/12 mx-auto space-y-8 pb-8"}>
        <PlayerScoreModal
          open={showOverlay}
          sport={sorareBaseball}
          stats={overlayStats}
          lineups={overlayLineups}
          close={() => setShowOverlay(false)}
          game={game}
        />
        <div className={"bg-brand-black flex flex-row rounded-xl px-8 h-28"}>
          <div className={"self-center w-5/12 flex flex-row space-x-8 justify-start"}>
            <div className={"w-12 self-center"}>
              <img
                className={"object-contain self-center"}
                src={homeTeam?.PictureUrl !== null && homeTeam?.PictureUrl !== "" ? homeTeam?.PictureUrl : UnknownClub}
              />
            </div>
            <p className={"text-white text-xl font-semibold hover:font-bold self-center"}>
              <a href={"/team/" + homeTeam?.TeamId}>{homeTeam?.ClubName}</a>
            </p>
          </div>
          <div className={"self-center w-2/12 text-center flex flex-col space-y-2"}>
            {gameStatus === "playing" && (
              <p className={"text-white font-semibold text-md text-live-red flex flex-row justify-center self-center space-x-1"}>
                <span className={"self-center"}>
                  <LiveCircle color={"#FF3232"} />
                </span>{" "}
                <span>{game?.TimeMin}&apos;</span>
              </p>
            )}
            {(gameStatus === "inprogress" || gameStatus === "closed") && (
              <p className={"text-white self-center"}>
                <span className={"text-5xl font-bold text-white"}>{game?.game?.HomeScore}</span>
                <span className={"self-center text-5xl"}> - </span>
                <span className={"text-5xl font-bold text-white"}>{game?.game?.AwayScore}</span>
              </p>
            )}
            {gameStatus === "scheduled" && (
              <div className={"space-y-2"}>
                <p className={"text-white self-center"}>
                  <span className={"text-base font-semibold text-white"}>{format(gameTime, "LLL do yyyy")}</span>
                </p>
                <p className={"text-white self-center"}>
                  <span className={"text-4xl font-semibold text-white"}>{format(gameTime, "HH:mm")}</span>
                </p>
              </div>
            )}
          </div>
          <div className={"self-center flex flex-row space-x-8 w-5/12 justify-end"}>
            <p className={"self-center text-white text-xl hover:font-bold font-semibold"}>
              <a href={"/team/" + awayTeam?.TeamId}>{awayTeam?.ClubName}</a>
            </p>
            <div className={"w-12 self-center"}>
              <img
                className={"object-contain self-center"}
                src={awayTeam?.PictureUrl !== null && awayTeam?.PictureUrl !== "" ? awayTeam?.PictureUrl : UnknownClub}
              />
            </div>
          </div>
        </div>
        <div className={"grid grid-cols-2 space-x-8"}>
          <div>
            <HittersTable players={homeLineup?.hitters} />
          </div>
          <div>
            <HittersTable players={awayLineup?.hitters} />
          </div>
        </div>
        <div className={"grid grid-cols-2 space-x-8"}>
          <div>
            <PitchersTable players={homeLineup?.pitchers} />
          </div>
          <div>
            <PitchersTable players={awayLineup?.pitchers} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withUser(BaseballGameLive);
