import React from "react";
const customOptionRender = (domProps, optionProps, snapshot, className) => {
  let color = "text-brand-black";
  if (optionProps.disabled) {
    color = "text-textGrey3 opacity-50";
  }
  return (
    <div className="w-full h-full flex flex-row justify-between items-center hover:bg-brand-pastel-hover">
      <button
        {...optionProps}
        {...domProps}
        className={`select-search__option_custom w-full hover:bg-brand-pastel bg-brand ${
          snapshot.selected ? "bg-brand-pastel" : ""
        } ${color}`}
        style={snapshot.selected ? { backgroundColor: "#f45206", color: "#fff" } : undefined}
      >
        {optionProps.name}
      </button>
      {optionProps.disabled && (
        <div className="h-3 w-3 mr-3">
          <img className={"h-3 w-3"} alt="" src={optionProps.photo} />
        </div>
      )}
    </div>
  );
};

const customValueRender = (valueProps, snapshot, className) => {
  return <input {...valueProps} {...snapshot} className={className} label={valueProps.name}></input>;
};

export { customValueRender, customOptionRender };
