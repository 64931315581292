import { withUser } from "../../userContext";
import React, { useEffect, useRef, useState } from "react";
import { errorCatcher } from "../util/errors";
import SDLoading from "../util/SDLoading";
import { useQuery } from "../util/useQuery";
import UnknownClub from "../../img/unknown_club.png";
import LiveCircle from "../util/liveCircle";
import format from "date-fns/format";
import { GLOBAL_MARGIN_NO_BG } from "../util/margin";
import BasketballField from "../../img/nba-field.png";
import ScoreEllipse from "../util/scoreEllipse";
import { sorareBasketball } from "../util/sports";
import LiveField from "../../img/live-court-nba.png";
import BonusIndicator from "../../img/bonus-nba.svg";
import Pusher from "pusher-js";
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from "../util/pusher";
import PlayerScoreModal from "../players/playerScoreModal";
import GameLivePageHeader from "../games/gameLivePageHeader";
import BasketballPlayByPlay from "./basketballPlayByPlay";
import BasketballTeamBoxScore from "./basketballTeamBoxScore";
import BasketballTeamStats from "./basketballTeamStats";

function BasketballFieldPlayer(props) {
  const [overlay, setOverlay] = useState(false);

  if (props.p === undefined) return null;

  const p = props.p.player;
  const item = props.p;
  let background = "bg-white";
  let textSize = "text-sm";
  let dotSize = "h-3 w-3";
  if (props.textSize) {
    textSize = props.textSize;
  }
  if (props.dotSize) {
    dotSize = props.dotSize;
  }
  let imgSize = " w-16 h-16 ";
  if (props.imgSize) {
    imgSize = props.imgSize;
  }
  let dotColor = "#E73107";
  if (item.stats.started === true) {
    dotColor = "#57B1FF";
  }
  let minSize = "text-xs";
  if (props.minSize) {
    minSize = props.minSize;
  }
  let border = "";
  if (item.manager_details?.inLineups) {
    border = " border-2 border-super-rare";
  }
  return (
    <div>
      {overlay && (
        <PlayerScoreModal
          close={() => setOverlay(false)}
          sport={sorareBasketball}
          stats={item.stats}
          player={item.player}
          open={overlay}
          game={props.game}
        />
      )}
      <div className={"flex flex-col items-center space-y-2 cursor-pointer"} onClick={() => setOverlay(true)}>
        <div className={"flex flex-col items-center"}>
          <div className={"absolute flex flex-row space-x-2 -mt-4 self-center z-100"}>
            <div className={"flex flex-row justify-center w-4"}>
              <p className={minSize + " font-semibold self-center text-white"}>{item.stats.minutes}&apos;</p>
            </div>
          </div>
          {p.Avatar !== "" && <img src={p.Avatar} className={"rounded-full " + imgSize + " object-cover " + background + border} />}
          {p.Avatar === "" && (
            <svg className={imgSize + " text-gray-300 rounded-full " + background + border} fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}
        </div>

        <div className={"flex flex-col space-y-1 items-center"}>
          <div className={"overflow-hidden"}>
            <p className={"text-white font-semibold text-ellipsis truncate " + textSize}>
              <span className={textSize + " font-semibold text-white opacity-50 self-center"}>{p.JerseyNumber} </span>
              {p.MatchName}
            </p>
          </div>
          <div className={"cursor-pointer"}>
            <ScoreEllipse
              sport={sorareBasketball}
              padding={"px-1.5"}
              shadow={"shadow-ellipse"}
              score={item.stats.score}
              size={"text-sm"}
              power={1}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function BasketballGame(props) {
  const [gameData, setGameData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [lastPlay, setLastPlay] = useState(undefined);
  const stateRef = useRef();

  stateRef.current = gameData;

  const query = useQuery();
  const gameId = query.get("gameId");

  const handleNewPlay = (data) => {
    const play = data.play;
    const msgGameId = data.gameId;
    const newHomeOnCourt = data.homeOnCourt;
    const newAwayOnCourt = data.awayOnCourt;
    let newGame = stateRef.current;
    if (gameId === msgGameId) {
      setLastPlay(play);
      newGame.game.game.LiveDetails.awayDetails.onCourt = newAwayOnCourt;
      newGame.game.game.LiveDetails.homeDetails.onCourt = newHomeOnCourt;
      newGame.game.game.LiveDetails.awayDetails.bonus = data.awayBonus;
      newGame.game.game.LiveDetails.homeDetails.bonus = data.homeBonus;
      //newGame.game.game.LiveDetails.lastPlay = play;
      newGame.game.game.HomeScore = play.homeScore;
      newGame.game.game.AwayScore = play.awayScore;
      newGame.game.game.Clock = play.clock;
      newGame.game.game.LiveDetails.quarter = play.quarter;
      if (data.gameStatus && data.gameStatus !== "") {
        newGame.game.game.Status = data.gameStatus;
      }
      let whereTo = "quarter" + play.quarter;
      if (play.quarter > 4) {
        whereTo = "ot";
      }
      if (newGame.playByPlay[whereTo] !== null) {
        if (newGame.playByPlay[whereTo][newGame.playByPlay[whereTo].length - 1].sequence < play.sequence) {
          newGame.playByPlay[whereTo].push(play);
        } else if (newGame.playByPlay[whereTo][newGame.playByPlay[whereTo].length - 1].number === play.number) {
          newGame.playByPlay[whereTo][newGame.playByPlay[whereTo].length - 1] = play;
        }
      } else {
        newGame.playByPlay[whereTo] = [];
        newGame.playByPlay[whereTo].push(play);
      }

      setGameData(newGame);
      setRefresh(Math.random());
    }
  };

  const handleNewStats = (newStats) => {
    if (newStats.gameId === gameId) {
      let newGame = stateRef.current;
      let s;
      let starter = newStats.started;
      if (newGame?.home?.team.TeamId !== newStats.teamId) {
        if (starter) {
          s = newGame.away.players.starters.findIndex((p) => p.player.PlayerId === newStats.playerId);
          if (s >= 0) {
            newGame.away.players.starters[s].stats = newStats;
          }
        } else {
          s = newGame.away.players.bench.findIndex((p) => p.player.PlayerId === newStats.playerId);
          if (s >= 0) {
            newGame.away.players.bench[s].stats = newStats;
          }
        }
      } else {
        if (starter) {
          s = newGame.home.players.starters.findIndex((p) => p.player.PlayerId === newStats.playerId);
          if (s >= 0) {
            newGame.home.players.starters[s].stats = newStats;
          }
        } else {
          s = newGame.home.players.bench.findIndex((p) => p.player.PlayerId === newStats.playerId);
          if (s >= 0) {
            newGame.home.players.bench[s].stats = newStats;
          }
        }
      }
      setGameData(newGame);
      setRefresh(Math.random());
    }
  };

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
    });
    getLiveData(pusher);
    return () => {
      pusher.disconnect();
    };
  }, []);

  const getLiveData = (pusher) => {
    props
      .fetch("/apiv2/games/basketball?gameId=" + gameId)
      .then((response) => response.json())
      .then((res) => {
        setGameData(res);
        setLastPlay(res.game.game.LiveDetails.lastPlay);
        const channel = pusher.subscribe("nba_games_" + gameId);
        channel.bind("nba_live_event", function (data) {
          handleNewPlay(data);
        });
        channel.bind("nba_live_stats", function (data) {
          handleNewStats(data);
        });
        document.title = res.home.team.DisplayName + " - " + res.away.team.DisplayName;
      })
      .catch(errorCatcher());
  };

  if (gameData.length === 0) {
    return <SDLoading />;
  }

  const gameDetails = gameData.game;
  const game = gameDetails.game;
  const homeTeam = gameData.home.team;
  const awayTeam = gameData.away.team;
  const gameStatus = game.Status;

  let homePlayers;
  let awayPlayers;

  if (gameData.home.players.bench !== null && gameData.home.players.starters !== null) {
    homePlayers = gameData.home.players.starters.concat(gameData.home.players.bench);
  }
  if (gameData.away.players.bench !== null && gameData.away.players.starters !== null) {
    awayPlayers = gameData.away.players.starters.concat(gameData.away.players.bench);
  }
  let homeBench = gameData.home.players.bench;
  if (homeBench === null) homeBench = [];
  let awayBench = gameData.away.players.bench;
  if (awayBench === null) awayBench = [];

  let maxBench = 10;

  let awayStarters = gameData.away.players.starters;
  let awayOnCourt = game?.LiveDetails.awayDetails.onCourt;

  let homeStarters = gameData.home.players.starters;
  let homeOnCourt = game?.LiveDetails.homeDetails.onCourt;
  let homeStarterForwards = [];
  let homeStarterGuards = [];
  let homeStarterCenter = [];
  if (homeOnCourt !== null && gameStatus === "inprogress" && homePlayers !== null) {
    homeStarterForwards = homePlayers.filter(
      (player) =>
        (player.player.Position === "F" || player.player.Position === "SF" || player.player.Position === "PF") &&
        homeOnCourt.find((p) => p.playerId === player.player.PlayerId),
    );
    homeStarterCenter = homePlayers.filter(
      (player) => player.player.Position === "C" && homeOnCourt.find((p) => p.playerId === player.player.PlayerId),
    );
    homeStarterGuards = homePlayers.filter(
      (player) =>
        (player.player.Position === "G" || player.player.Position === "SG" || player.player.Position === "PG") &&
        homeOnCourt.find((p) => p.playerId === player.player.PlayerId),
    );
    homeBench = homePlayers.filter((player) => !homeOnCourt.find((p) => p.playerId === player.player.PlayerId));
  } else if (homeStarters !== null) {
    homeStarterForwards = homeStarters.filter(
      (player) => player.player.Position === "F" || player.player.Position === "SF" || player.player.Position === "PF",
    );
    homeStarterCenter = homeStarters.filter((player) => player.player.Position === "C");
    homeStarterGuards = homeStarters.filter(
      (player) => player.player.Position === "G" || player.player.Position === "SG" || player.player.Position === "PG",
    );
  }

  while (homeStarterCenter !== null && homeStarterCenter.length === 0) {
    if (homeStarterForwards.length === 0) {
      break;
    }
    let tallestForward = homeStarterForwards[0];
    homeStarterForwards.forEach((forward) => {
      if (forward.player.Height > tallestForward.player.Height) {
        tallestForward = forward;
      }
    });
    // remove tallestGuard from homeStarterGuards
    homeStarterForwards = homeStarterForwards.filter((guard) => guard.player.PlayerId !== tallestForward.player.PlayerId);
    homeStarterCenter = homeStarterCenter.concat(tallestForward);
  }

  if (homeStarterCenter.length > 1) {
    let smallestCenter = homeStarterCenter[0];
    homeStarterCenter.forEach((center) => {
      if (center.player.Height > smallestCenter.player.Height) {
        smallestCenter = center;
      }
    });
    // remove smallestCenter from homeStarterCenter
    homeStarterCenter = homeStarterCenter.filter((center) => center.player.PlayerId !== smallestCenter.player.PlayerId);
    homeStarterForwards = homeStarterForwards.concat(smallestCenter);
  }

  while (homeStarterGuards !== null && homeStarterGuards.length > 2) {
    let tallestGuard = homeStarterGuards[0];
    homeStarterGuards.forEach((guard) => {
      if (guard.player.Height > tallestGuard.player.Height) {
        tallestGuard = guard;
      }
    });
    homeStarterGuards = homeStarterGuards.filter((guard) => guard.player.PlayerId !== tallestGuard.player.PlayerId);
    homeStarterForwards = homeStarterForwards.concat(tallestGuard);
  }

  while (homeStarterForwards !== null && homeStarterForwards.length > 2) {
    let lowestForward = homeStarterForwards[0];
    homeStarterForwards.forEach((forward) => {
      if (forward.player.Height < lowestForward.player.Height) {
        lowestForward = forward;
      }
    });
    // remove tallestGuard from homeStarterGuards
    homeStarterForwards = homeStarterForwards.filter((forward) => forward.player.PlayerId !== lowestForward.player.PlayerId);
    homeStarterGuards = homeStarterGuards.concat(lowestForward);
  }

  let awayStarterForwards = [];
  let awayStarterGuards = [];
  let awayStarterCenter = [];
  if (awayOnCourt !== null && gameStatus === "inprogress" && awayPlayers !== null) {
    awayStarterForwards = awayPlayers.filter(
      (player) =>
        (player.player.Position === "F" || player.player.Position === "SF" || player.player.Position === "PF") &&
        awayOnCourt.find((p) => p.playerId === player.player.PlayerId),
    );
    awayStarterCenter = awayPlayers.filter(
      (player) => player.player.Position === "C" && awayOnCourt.find((p) => p.playerId === player.player.PlayerId),
    );
    awayStarterGuards = awayPlayers.filter(
      (player) =>
        (player.player.Position === "G" || player.player.Position === "SG" || player.player.Position === "PG") &&
        awayOnCourt.find((p) => p.playerId === player.player.PlayerId),
    );
    awayBench = awayPlayers.filter((player) => !awayOnCourt.find((p) => p.playerId === player.player.PlayerId));
  } else if (awayStarters !== null) {
    awayStarterForwards = awayStarters.filter(
      (player) => player.player.Position === "F" || player.player.Position === "SF" || player.player.Position === "PF",
    );
    awayStarterCenter = awayStarters.filter((player) => player.player.Position === "C");
    awayStarterGuards = awayStarters.filter(
      (player) => player.player.Position === "G" || player.player.Position === "SG" || player.player.Position === "PG",
    );
  }

  while (awayStarterCenter !== null && awayStarterCenter.length === 0) {
    let tallestForward = awayStarterForwards[0];
    awayStarterForwards.forEach((forward) => {
      if (forward.player.Height > tallestForward.player.Height) {
        tallestForward = forward;
      }
    });
    // remove tallestGuard from homeStarterGuards
    awayStarterForwards = awayStarterForwards.filter((forward) => forward.player.PlayerId !== tallestForward.player.PlayerId);
    awayStarterCenter = awayStarterCenter.concat(tallestForward);
  }

  if (awayStarterCenter.length > 1) {
    let smallestCenter = awayStarterCenter[0];
    awayStarterCenter.forEach((center) => {
      if (center.player.Height > smallestCenter.player.Height) {
        smallestCenter = center;
      }
    });
    // remove smallestCenter from homeStarterCenter
    awayStarterCenter = awayStarterCenter.filter((center) => center.player.PlayerId !== smallestCenter.player.PlayerId);
    awayStarterForwards = awayStarterForwards.concat(smallestCenter);
  }

  while (awayStarterGuards !== null && awayStarterGuards.length > 2) {
    let tallestGuard = awayStarterGuards[0];
    awayStarterGuards.forEach((guard) => {
      if (guard.player.Height > tallestGuard.player.Height) {
        tallestGuard = guard;
      }
    });
    // remove tallestGuard from awayStarterGuards
    awayStarterGuards = awayStarterGuards.filter((guard) => guard.player.PlayerId !== tallestGuard.player.PlayerId);
    awayStarterForwards = awayStarterForwards.concat(tallestGuard);
  }

  while (awayStarterForwards !== null && awayStarterForwards.length > 2) {
    let lowestForward = awayStarterForwards[0];
    awayStarterForwards.forEach((forward) => {
      if (forward.player.Height < lowestForward.player.Height) {
        lowestForward = forward;
      }
    });
    // remove tallestGuard from awayStarterGuards
    awayStarterForwards = awayStarterForwards.filter((forward) => forward.player.PlayerId !== lowestForward.player.PlayerId);
    awayStarterGuards = awayStarterGuards.concat(lowestForward);
  }

  // sort homeBench by player.stats.score
  if (homeBench !== null) {
    homeBench = homeBench.filter((s) => {
      return s.stats.minutes > 0 || s.stats.started === true;
    });
    homeBench.sort((a, b) => (a.stats.score > b.stats.score ? -1 : 1));
  }
  if (awayBench !== null) {
    awayBench = awayBench.filter((s) => {
      return s.stats.minutes > 0 || s.stats.started === true;
    });
    awayBench.sort((a, b) => (a.stats.score > b.stats.score ? -1 : 1));
  }

  const gameTime = new Date(game.GameTime);

  return (
    <div className={GLOBAL_MARGIN_NO_BG + " mt-8"} key={refresh}>
      <div className={"flex flex-col w-bb-field-xl mx-auto space-y-4"}>
        <div className={"flex flex-col items-center w-full mx-auto"}>
          <div className={"bg-brand-black w-full flex flex-row rounded-t-xl px-8 h-28"}>
            <div className={"self-center w-4/12 flex flex-row space-x-8 justify-start"}>
              <div className={"w-12 self-center"}>
                <img
                  className={"object-contain self-center"}
                  src={homeTeam?.PictureUrl !== null && homeTeam?.PictureUrl !== "" ? homeTeam?.PictureUrl : UnknownClub}
                />
              </div>
              <p className={"text-white text-xl font-semibold hover:font-bold self-center"}>
                <a href={"/team/" + homeTeam?.TeamId}>{homeTeam?.ClubName}</a>
              </p>
            </div>
            <div className={"self-center w-4/12 text-center flex flex-col space-y-2 items-center"}>
              {gameStatus === "inprogress" && (
                <div className={"text-white font-semibold text-md text-live-red flex flex-row justify-center self-center space-x-1"}>
                  <span className={"self-center"}>
                    <LiveCircle color={"#FF3232"} />
                  </span>{" "}
                  <span>{game?.Clock}</span>
                </div>
              )}
              {(gameStatus === "inprogress" || gameStatus === "closed" || gameStatus === "complete" || gameStatus === "halftime") && (
                <div className={"flex self-center flex-row justify-center space-x-2 items-center text-white mx-auto"}>
                  <div className={"flex justify-end space-x-3 w-6/12"}>
                    {gameData.game.game.LiveDetails.homeDetails.bonus === true && gameStatus === "inprogress" && (
                      <div className={"self-center h-4 w-12"}>
                        <img src={BonusIndicator} className={"w-full h-full"} />
                      </div>
                    )}
                    <span className={"text-4xl font-bold text-white"}>{game?.HomeScore}</span>
                  </div>
                  <span className={"self-center text-4xl"}> - </span>
                  <div className={"flex justify-start space-x-3 flex-row w-6/12"}>
                    <span className={"text-4xl font-bold text-white"}>{game?.AwayScore}</span>
                    {gameData.game.game.LiveDetails.awayDetails.bonus === true && gameStatus === "inprogress" && (
                      <div className={"self-center h-4 w-12"}>
                        <img src={BonusIndicator} className={"w-full h-full"} />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {gameStatus === "inprogress" && (
                <p className={"text-textGrey4 text-xs font-semibold self-center"}>Q{game.LiveDetails.lastPlay.quarter}</p>
              )}
              {gameStatus === "halftime" && <p className={"text-textGrey4 text-xs font-semibold self-center"}>HT</p>}
              {gameStatus === "scheduled" && (
                <div className={"space-y-2"}>
                  <p className={"text-white self-center"}>
                    <span className={"text-base font-semibold text-white"}>{format(gameTime, "LLL do yyyy")}</span>
                  </p>
                  <p className={"text-white self-center"}>
                    <span className={"text-4xl font-semibold text-white"}>{format(gameTime, "HH:mm")}</span>
                  </p>
                </div>
              )}
              {/*<p className={"text-sm font-semibold"}>{gameDetails.stats.game.CompDisplayName}</p>*/}
            </div>
            <div className={"self-center flex flex-row space-x-8 w-4/12 justify-end"}>
              <p className={"self-center text-white text-xl hover:font-bold font-semibold"}>
                <a href={"/team/" + awayTeam?.TeamId}>{awayTeam?.ClubName}</a>
              </p>
              <div className={"w-12 self-center"}>
                <img
                  className={"object-contain self-center"}
                  src={awayTeam?.PictureUrl !== null && awayTeam?.PictureUrl !== "" ? awayTeam?.PictureUrl : UnknownClub}
                />
              </div>
            </div>
          </div>
          <div
            className={"w-full h-bb-field-xl bg-bb-field-bg flex flex-row justify-center"}
            style={{
              backgroundImage: "url('" + BasketballField + "')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className={"w-6/12 flex flex-row"}>
              <div className={"w-4/12 flex flex-col self-center justify-evenly h-bb-field-xl"}>
                {homeStarterGuards.map((player) => {
                  return <BasketballFieldPlayer game={gameDetails} p={player} />;
                })}
              </div>
              <div className={"w-3/12 flex flex-col self-center justify-evenly h-bb-field-xl"}>
                {homeStarterCenter.map((player) => {
                  return <BasketballFieldPlayer game={gameDetails} p={player} />;
                })}
              </div>
              <div className={"w-3/12 flex flex-col self-center justify-evenly h-bb-field-xl"}>
                {homeStarterForwards.map((player) => {
                  return <BasketballFieldPlayer game={gameDetails} p={player} />;
                })}
              </div>
            </div>
            <div className={"w-6/12 flex flex-row justify-end"}>
              <div className={"w-3/12 flex flex-col self-center justify-evenly h-bb-field-xl"}>
                {awayStarterForwards.map((player) => {
                  return <BasketballFieldPlayer game={gameDetails} p={player} />;
                })}
              </div>
              <div className={"w-3/12 flex flex-col self-center justify-evenly h-bb-field-xl"}>
                {awayStarterCenter.map((player) => {
                  return <BasketballFieldPlayer game={gameDetails} p={player} />;
                })}
              </div>
              <div className={"w-4/12 flex flex-col self-center justify-evenly h-bb-field-xl"}>
                {awayStarterGuards.map((player) => {
                  return <BasketballFieldPlayer game={gameDetails} p={player} />;
                })}
              </div>
            </div>
          </div>
          <div className={"bg-bb-field-bg w-full flex flex-row rounded-b-lg"} style={{ height: "310px" }}>
            <div className={"flex flex-col items-center ml-8 self-center space-y-4"}>
              {lastPlay !== undefined && (
                <div className={"w-full flex flex-row bg-textGrey1 p-2 rounded-lg"} style={{ width: "343px" }}>
                  <div className={"w-2/12 flex flex-row justify-center self-center"}>
                    {lastPlay.playerAvatar !== "" && (
                      <img src={lastPlay.playerAvatar} className={"rounded-full w-10 h-10 " + " object-cover "} />
                    )}
                  </div>
                  <div className={"w-8/12 flex flex-col space-y-1"}>
                    <div className={"w-full"}>
                      <p className={"text-white font-semibold text-xs"}>{lastPlay.description}</p>
                    </div>
                    <div>
                      <p className={"text-xs font-semibold text-textGrey3"}>{lastPlay.clock}</p>
                    </div>
                  </div>
                  <div className={"w-2/12 self-center flex flex-row justify-center"}>
                    {lastPlay.fantasyPoints !== 0 && (
                      <div
                        className={
                          lastPlay.fantasyPoints > 0
                            ? "flex flex-row justify-center self-center rounded-full w-9 h-9 bg-good-green"
                            : "flex flex-row justify-center self-center rounded-full w-9 h-9 bg-bad-orange"
                        }
                      >
                        <p className={"text-white text-sm font-semibold self-center justify-center text-center"}>
                          {lastPlay.fantasyPoints > 0 && "+"}
                          {lastPlay.fantasyPoints}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className={"flex flex-row justify-center rounded-xl self-center"}
                style={{
                  backgroundImage: "url('" + LiveField + "')",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundColor: "#383838",
                  backgroundRepeat: "no-repeat",
                  height: "174px",
                  width: "343px",
                }}
              ></div>
            </div>
            <div className={"flex-col p-8 space-y-4 flex mx-auto overflow-hidden self-center"}>
              {homeBench.length > 0 && (
                <div className={"bg-bb-bench-bg grid gap-x-2 p-4 rounded-lg grid-cols-" + maxBench}>
                  <div className={"w-12 self-center"}>
                    <img
                      className={"object-contain self-center"}
                      src={homeTeam?.PictureUrl !== null && homeTeam?.PictureUrl !== "" ? homeTeam?.PictureUrl : UnknownClub}
                    />
                  </div>
                  {homeBench.map((player) => {
                    return (
                      <BasketballFieldPlayer
                        game={gameDetails}
                        minSize={"text-xxs"}
                        dotSize={"h-2 w-2"}
                        imgSize={"w-10 h-10"}
                        p={player}
                        textSize={"text-xxs"}
                      />
                    );
                  })}
                </div>
              )}
              {awayBench.length > 0 && (
                <div className={"bg-bb-bench-bg gap-x-2 p-4 rounded-lg grid grid-cols-" + maxBench}>
                  <div className={"w-12 self-center"}>
                    <img
                      className={"object-contain self-center"}
                      src={awayTeam?.PictureUrl !== null && awayTeam?.PictureUrl !== "" ? awayTeam?.PictureUrl : UnknownClub}
                    />
                  </div>
                  {awayBench.map((player) => {
                    return (
                      <BasketballFieldPlayer
                        game={gameDetails}
                        imgSize={"w-10 h-10"}
                        minSize={"text-xxs"}
                        dotSize={"h-2 w-2"}
                        p={player}
                        textSize={"text-xxs"}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
        <div className={"flex flex-row w-full space-x-8"}>
          <div className={"w-8/12 space-y-4"}>
            <GameLivePageHeader label={"Play-by-play (last 10 plays)"} />
            <div>
              <BasketballPlayByPlay pbp={gameData.playByPlay} home={homeTeam} away={awayTeam} />
            </div>
          </div>
          <div className={"w-4/12 space-y-4"}>
            <div className={"space-y-4 w-full"}>
              <GameLivePageHeader label={"Box score"} />
              <div>
                <BasketballTeamBoxScore game={gameDetails} home={gameData.home} away={gameData.away} />
              </div>
            </div>
            <div className={"space-y-4 w-full"}>
              <GameLivePageHeader label={"Team stats"} />
              <div>
                <BasketballTeamStats
                  game={gameDetails}
                  home={homePlayers}
                  homeTeam={gameData.home}
                  away={awayPlayers}
                  awayTeam={gameData.away}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withUser(BasketballGame);
