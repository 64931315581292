import React, { useEffect, useState } from "react";
import MultiScarcityPicker from "../util/multiScarcityPicker";
import { PositionPicker } from "../util/positionPicker";
import { errorCatcher } from "../util/errors";
import { findPriceForUserUnit, formatPrice } from "../util/formatMoney";
import CardLayout from "../cards/cardLayout";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { getValuationOrigin } from "../util/valuationOrigin";
import { withUser } from "../../userContext";
import SDLoading from "../util/SDLoading";
import { ReactTooltip } from "../util/tooltip.js";
import { Button } from "../util/button";
import CardLink from "../cards/cardLink";
import PlayerAvailability from "../players/playerAvailability";
import ScoreAverage from "../decision/scoreAverage";
import GameOnCard from "../games/gameOnCard";
import Remove from "../../img/remove-card-lineup.svg";
import ConceptPicker from "../../img/concept-picker.svg";
import ListViewPicker from "../util/listViewPicker";
import SO5UtilityFilter from "../util/so5UtilityFilter";
import CirclePct from "../util/circlePct";
import { t2OrAbove } from "../util/handleSubscriptionTier";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import Spinner from "../loader/spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import WatchlistMultiplePicker from "../watchlists/watchlistMultiplePicker";

const limitAPI = 30;

function ManagerCardsView(props) {
  let displayDefault = t2OrAbove(props.user?.tier) ? "eligible" : "next_game";

  const [scarcities, setScarcities] = useState(["LIMITED", "RARE", "SUPER RARE", "UNIQUE", "CUSTOM SERIES"]);
  const [positions, setPositions] = useState(["Goalkeeper", "Defender", "Midfielder", "Forward"]);
  const [cards, setCards] = useState({
    list: [],
    hasNext: false,
    offset: 0,
    teamMap: [],
  });
  const [loading, setLoading] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [selectedCards, setSelectedCards] = useState(props.cards);
  const [listView, setListView] = useState(false);
  const [sort, setSort] = useState({ field: "l15", order: "desc" });
  const [watchlists, setWatchlists] = useState({});
  const [so5UtilityRange, setSO5UtilityRange] = useState([0, 100]);
  const [so5UtilityPlayed, setSO5UtilityPlayed] = useState([0, 100]);
  const [so5UtilityStarted, setSO5UtilityStarted] = useState([0, 100]);
  const [so5DisplaySetting, setSO5DisplaySetting] = useState(displayDefault);

  const manager = props.manager;
  const unit = props.user.preferredUnit;

  useEffect(() => {
    getManagerCards({});
  }, []);

  const getManagerCards = ({ s, p, w, r, pl, st, sb, o }) => {
    setLoading(true);
    const pos = p || positions;
    const sc = s || scarcities;
    if (w !== undefined) w = watchlists;
    let so5Utility = r || so5UtilityRange;
    let so5Played = pl || so5UtilityPlayed;
    let so5Started = st || so5UtilityStarted;
    if (so5Utility[0] === 0 && so5Utility[1] === 100) so5Utility = [];
    if (so5Played[0] === 0 && so5Played[1] === 100) so5Played = [];
    if (so5Started[0] === 0 && so5Started[1] === 100) so5Started = [];
    if (sb === undefined) sb = sort;
    if (o === undefined) o = cards.offset;
    const params = new URLSearchParams();
    params.append("manager", manager.Slug);
    Object.keys(watchlists).forEach((wa) => params.append("watchlist", wa));
    pos.forEach((p) => params.append("positions", p));
    sc.forEach((scarcity) => params.append("scarcities", scarcity));
    so5Utility.forEach((su) => params.append("so5_utility", su.toString()));
    so5Played.forEach((sp) => params.append("so5_played", sp.toString()));
    so5Started.forEach((ss) => params.append("so5_started", ss.toString()));
    params.append("sort_field", sb.field);
    params.append("sort_order", sb.order);
    params.append("so5_display_setting", so5DisplaySetting);
    params.append("player_name_search", filterString);
    props
      .fetch("/apiv2/manager/cards?" + params.toString(), {
        method: "GET",
        headers: withJSONPagination(o, limitAPI),
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        const resCards = res?.cards || [];
        const newCards = {
          list: o === 0 ? resCards : [...cards.list, ...resCards],
          hasNext: hasNext,
          offset: o + limitAPI,
          teamMap: res?.team_map || [],
        };
        setCards(newCards);
      })
      .catch(errorCatcher())
      .finally(() => {
        ReactTooltip.rebuild();
        setLoading(false);
      });
  };

  const sortData = (w) => {
    const newSort = {
      field: w,
      order: sort.field === w ? (sort.order === "asc" ? "desc" : "asc") : "desc",
    };
    setSort(newSort);
    getManagerCards({ sb: newSort, o: 0 });
  };

  const confirm = () => {
    props.confirm(selectedCards);
    props.close();
  };

  const addCard = (cp) => {
    let newSelected = [...selectedCards];
    if (selectedCards.indexOf(cp.card.TokenId) >= 0) {
      newSelected = newSelected.filter((c) => c !== cp.card.TokenId);
    } else {
      newSelected.push(cp.card.TokenId);
    }
    setSelectedCards(newSelected);
  };

  const scarcityChange = (s) => {
    let newScarcities = [...scarcities];
    if (newScarcities.indexOf(s) >= 0) {
      newScarcities = newScarcities.filter(function (value, index, arr) {
        return value !== s;
      });
    } else {
      newScarcities.push(s);
    }
    newScarcities.sort(function (a, b) {
      if (a === "LIMITED") {
        return -1;
      } else if (a === "UNIQUE") {
        return 1;
      } else if (b === "LIMITED") {
        return 1;
      } else if (b === "UNIQUE") {
        return -1;
      } else if (a === "RARE" && b === "SUPER RARE") {
        return -1;
      } else if (b === "RARE" && a === "SUPER RARE") {
        return 1;
      }
    });
    setScarcities(newScarcities);
  };

  const changeSO5UtilityFilter = async (r, pl, st, ds) => {
    setSO5UtilityRange(r);
    setSO5UtilityPlayed(pl);
    setSO5UtilityStarted(st);
    setSO5DisplaySetting(ds);
  };

  const filter = () => {
    getManagerCards({ o: 0 });
  };

  let nextGameTabName = "Next game";
  if (so5DisplaySetting === "eligible") {
    nextGameTabName = "SO5 pick % when player has a game";
  } else if (so5DisplaySetting === "played") {
    nextGameTabName = "SO5 pick % when player plays a game";
  } else if (so5DisplaySetting === "started") {
    nextGameTabName = "SO5 pick % when player starts a game";
  }

  const hasChange = props.cards.length !== selectedCards.length || props.cards.some((c) => selectedCards.indexOf(c) < 0);

  return (
    <div className={"space-y-8"}>
      <div className={"flex flex-row space-x-4 justify-between mx-6 self-center"}>
        <div className={"flex flex-row self-center space-x-4"}>
          <div className={"self-center"}>
            <MultiScarcityPicker customSeries scarcity={scarcities} onChange={(s) => scarcityChange(s)} />
          </div>
          <div className={"self-center"}>
            <PositionPicker
              positions={positions}
              changePositions={(p) => {
                setPositions(p);
              }}
            />
          </div>
          <div className={"self-center relative"}>
            {filterString && (
              <div
                className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                onClick={() => {
                  setFilterString("");
                }}
              >
                <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
              </div>
            )}
            <input
              className="focus:outline-none focus:ring-0 focus:border-brand block w-full py-2 rounded-md pl-4 sm:text-sm border-grey-f2"
              type={"text"}
              placeholder={"Filter by player name"}
              value={filterString}
              onChange={(v) => {
                setFilterString(v.target.value);
              }}
            />
          </div>
          <div>
            <WatchlistMultiplePicker
              types={["player"]}
              options={{
                pinListFirst: true,
                defaultListFirst: true,
                followedListFirst: true,
                ownedListFirst: true,
              }}
              size={"xs"}
              placeholder={"Filter by watchlist(s)"}
              selected={watchlists}
              onSelect={(w) => {
                let newWatchlists = { ...watchlists };
                if (watchlists[w.id]) {
                  delete newWatchlists[w.id];
                } else {
                  newWatchlists[w.id] = w;
                }
                setWatchlists(newWatchlists);
              }}
            />
          </div>
          <div>
            <SO5UtilityFilter powerOptions={(v, w, x, ds) => changeSO5UtilityFilter(v, w, x, ds)} limit={100} />
          </div>
          <div className={"relative"}>
            <Button label={"Filter"} onClick={() => filter()} />
            <div className="absolute top-0 -right-10 transform translate-y-1/4">{loading && cards.list.length > 0 && <Spinner />}</div>
          </div>
        </div>
        <div className={"flex flex-row self-center space-x-4"}>
          <ListViewPicker mode={listView} change={(c) => setListView(c)} />
          <div className={"self-center"}>
            <Button label={"Confirm"} data-tip={hasChange ? "" : "No change"} disabled={!hasChange} onClick={() => confirm()} />
          </div>
        </div>
      </div>
      <div className={"flex flex-row justify-center"}>
        {(cards === undefined || cards.list === undefined || cards.list.length === 0) && (
          <div className={"text-sm text-center p-5 text-textGrey3"}>
            <span className={"py-5"}>{loading ? <SDLoading /> : "No card with these filters"}</span>
          </div>
        )}
        {!listView && (
          <InfiniteScroll
            height="80vh"
            style={{ paddingRight: "1em" }}
            next={() => {
              getManagerCards({});
            }}
            hasMore={cards.hasNext}
            loader={<SDLoading />}
            dataLength={cards.list.length}
          >
            <div className={"grid lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-8 gap-4"}>
              {cards.list.map((cp) => {
                let lastPrice = cp.card.LastPrice || cp.card_last_move?.details.price;
                let lastStatus = cp.card.LastStatus;
                let displayStatus = lastStatus;
                let ethCurrentPrice = "";
                if (lastStatus === "Sold" || lastStatus === "") {
                  displayStatus = findPriceForUserUnit(lastPrice, cp.card_last_move?.details, unit);
                  ethCurrentPrice = formatPrice(lastPrice, "eth");
                }
                let hover = "hover:border-2 hover:border-brand border-2 border-background-grey cursor-pointer rounded-lg";
                if (selectedCards.indexOf(cp.card.TokenId) >= 0) hover = "border-2 border-brand cursor-pointer rounded-lg";
                return (
                  <div key={cp.card.Slug} onClick={() => addCard(cp)}>
                    <CardLayout
                      card={cp.card}
                      lastPrice={displayStatus}
                      hover={hover}
                      imageLink={undefined}
                      picture={cp.card.PictureUrl}
                      monthPrice={formatRealTimeAverage(cp.rt, unit)}
                      marketPrice={formatBestMarketPrice(cp.bmp, unit)}
                      so5Utility={cp.card_stats}
                      so5Display={so5DisplaySetting}
                      valuationOrigin={
                        unit === "eth" ? getValuationOrigin(cp.rt.ValuationOrigin) : getValuationOrigin(cp.rt.ValuationOrigin)
                      }
                      ethCurrentPrice={ethCurrentPrice}
                      currentPrice={displayStatus}
                      date={false}
                      countdown={false}
                      avgPrice={""}
                      player={cp.player}
                      gwNumber={props.gwNumber}
                      l5={cp.avg5}
                      l20={cp.avg20}
                      gms5={cp.gms5}
                      gms20={cp.gms20}
                      s5={cp.s5Avg}
                      s15={cp.s15Avg}
                      s5Apps={cp.s5Apps}
                      s15Apps={cp.s15Apps}
                      availability={cp.player_status}
                      gwGames={props.games}
                    />
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        )}
        {listView && (
          <div className={"2xl:w-10/12 mx-auto w-11/12"}>
            <InfiniteScroll
              height="80vh"
              style={{ paddingRight: "1em" }}
              next={() => {
                getManagerCards({});
              }}
              hasMore={cards.hasNext}
              loader={<SDLoading />}
              dataLength={cards.list.length}
            >
              <table
                className={"z-0 min-h-48 border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}
              >
                <thead>
                  <th className="rounded-tl-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 pl-2 text-left w-3/12 font-bold uppercase text-xs">
                    Card
                  </th>
                  <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/24 font-bold uppercase text-xs"></th>
                  <th
                    onClick={() => sortData("l5")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1/12 font-bold uppercase text-xs"
                  >
                    L5
                  </th>
                  <th
                    onClick={() => sortData("l15")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                  >
                    L15
                  </th>
                  <th
                    onClick={() => sortData("floor")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1.5/12 font-bold uppercase text-xs"
                  >
                    Floor
                  </th>
                  <th
                    onClick={() => sortData("rt")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1.5/12 font-bold uppercase text-xs"
                  >
                    Average
                  </th>
                  <th
                    onClick={() => sortData("bonus")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                  >
                    Bonus
                  </th>
                  <th
                    onClick={() => sortData("utility")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-2/12 font-bold uppercase text-xs"
                  >
                    {nextGameTabName}
                  </th>
                  <th className="text-center rounded-tr-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto px-4 text-left w-1/12 font-bold uppercase text-xs">
                    {cards?.list?.length > 0 && loading && <Spinner className={"h-4 w-4"} />}
                  </th>
                </thead>
                <tbody>
                  {cards?.list?.map((cp) => {
                    const card = cp.card;
                    const player = cp.player;
                    let selected = false;
                    if (selectedCards.indexOf(cp.card.TokenId) >= 0) selected = true;
                    let so5Utility = cp.card_stats;
                    let so5Value = so5Utility?.so_5_utility;
                    let so5Picked = so5Utility?.so_5_picked;
                    let so5Eligible = so5Utility?.so_5_eligible;
                    if (so5DisplaySetting === "played") {
                      so5Value = so5Utility.so_5_picked_played_ratio;
                      so5Picked = so5Utility.so_5_picked_played;
                      so5Eligible = so5Utility.so_5_played;
                    } else if (so5DisplaySetting === "started") {
                      so5Value = so5Utility.so_5_picked_started_ratio;
                      so5Picked = so5Utility.so_5_picked_started;
                      so5Eligible = so5Utility.so_5_started;
                    }

                    return (
                      <tr className={"border-b border-grey-e5"} key={card.TokenId}>
                        <td>
                          <div className={"py-2 flex flex-row space-x-3 pl-2"}>
                            <a href={"/card/" + card.TokenId}>
                              <div className={"h-14"}>
                                <img src={card.PictureUrl} className={"w-full h-full object-contain"} />
                              </div>
                            </a>

                            <div className={"flex flex-col space-y-0.5 self-center"}>
                              <a href={"/player/" + player.PlayerId}>
                                <p className={"text-sm font-semibold"}>{player.DisplayName}</p>
                              </a>
                              <CardLink size={"text-xs"} card={card} sport={player.Sport} />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={"flex flex-row justify-center"}>
                            <div className={"w-4 h-4 self-center"}>
                              <PlayerAvailability availability={cp.player_status} />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={"flex flex-row self-center justify-center"}>
                            <ScoreAverage
                              font={"text-sm font-semibold"}
                              size={"w-8 h-7"}
                              playedSize={"w-8"}
                              period={5}
                              sport={player.Sport}
                              played={cp.gms5}
                              sAvg={cp.s5Avg}
                              sApps={cp.s5Apps}
                              avg={cp.avg5}
                              playerId={player.PlayerId}
                            />
                          </div>
                        </td>
                        <td>
                          <div className={"flex flex-row self-center justify-start"}>
                            <ScoreAverage
                              font={"text-sm font-semibold"}
                              size={"w-8 h-7"}
                              playedSize={"w-8"}
                              period={15}
                              sport={player.Sport}
                              played={cp.gms20}
                              sAvg={cp.s15Avg}
                              sApps={cp.s15Apps}
                              avg={cp.avg20}
                              playerId={player.PlayerId}
                            />
                          </div>
                        </td>
                        <td>
                          <div className={"flex flex-row self-center justify-start"}>
                            <p className={"text-sm font-semibold"}>{formatBestMarketPrice(cp.bmp, unit)}</p>
                          </div>
                        </td>
                        <td>
                          <div className={"flex flex-row self-center justify-start"}>
                            <p className={"text-sm font-semibold"}>{formatRealTimeAverage(cp.rt, unit)}</p>
                          </div>
                        </td>
                        <td>
                          <div className={"flex flex-row self-center justify-start"}>
                            <p className={"text-sm font-semibold"}>{(100 * card.Power - 100).toFixed(1)}%</p>
                          </div>
                        </td>
                        {so5DisplaySetting === "next_game" && (
                          <td>
                            <div className={"flex flex-row justify-center"}>
                              <GameOnCard player={player} gwGames={cards.teamMap} fontSize={"10px"} gwNumber={undefined} />
                            </div>
                          </td>
                        )}
                        {so5DisplaySetting !== "next_game" && (
                          <td>
                            <div className={"flex flex-col items-center space-y-0.5"}>
                              <CirclePct odds={false} showZero={true} value={so5Eligible > 0 ? so5Value : undefined} />
                              <p className={"text-xxs font-semibold"}>
                                {so5Picked}/{so5Eligible}
                              </p>
                            </div>
                          </td>
                        )}
                        <td>
                          <div className={"flex flex-row justify-center"}>
                            <div className="w-4 h-4 self-center cursor-pointer" onClick={() => addCard(cp)}>
                              <img className={"w-full h-full object-contain"} src={selected ? Remove : ConceptPicker} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(ManagerCardsView);
