import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import BoxWithToggle from "../util/boxWithToggle";
import { errorCatcher } from "../util/errors";

function AveragePreference(props) {
  const [averageValue, setAverageValue] = useState(props.user.sorareAverages || false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (props.isConnected()) {
      setAverageValue(props.user.sorareAverages);
    }
  }, [props.user]);

  const changeSorareAverages = (c) => {
    setMsg("");
    props
      .fetch("/apiv2/user/changeSorareAverages", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: c,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          setMsg("Average preference change failed. Please try again later.");
        } else {
          props.changeSorareAverages(c);
          setMsg("Average preference changed.");
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"space-y-2"}>
      <p className={"text-left uppercase text-sm text-on-surface-variant font-semibold"}>Football SO5 averages preference</p>
      <div className={"flex flex-col space-y-4"}>
        <p className={"text-sm font-medium"}>
          Choose whether you want to see Sorare&apos;s SO5 last 5 & last 15 averages (updated after every gameweek) or SorareData&apos;s SO5
          last 5 & last 15 averages (updated every day).
          <br /> Using Sorare&apos;s averages is mainly useful for Underdog/Specialist scouting.
        </p>
      </div>
      <div className={"bg-surface-container rounded-lg py-1.5 w-6/12"}>
        <BoxWithToggle label={"Show Sorare's SO5 averages"} isActive={averageValue} setActive={() => changeSorareAverages(!averageValue)} />
      </div>
      {msg && <p className={"text-center font-semibold text-sm"}>{msg}</p>}
    </div>
  );
}

export default withUser(AveragePreference);
