import React, { useState } from "react";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";

export const Accordion = (props) => {
  const { header, children, defaultOpen } = props;
  const [open, setOpen] = useState(defaultOpen !== undefined ? defaultOpen : true);

  return (
    <div className={"w-full"}>
      <div
        onClick={() => setOpen(!open)}
        className={`flex flex-row justify-between items-center w-full bg-transparent-inverse-surface-low bg-opacity-10 rounded-xl px-4 py-3 cursor-pointer`}
      >
        <span className={"text-on-surface font-medium font-headers"}>{header}</span>
        <div className={"flex flex-row gap-2"}>
          <SortArrowIcon className={`fill-on-surface h-3 w-3 ${open ? "transform rotate-180" : ""}`} />
        </div>
      </div>
      <div className={`w-full pt-3 ${open ? "" : "hidden"}`}>{children}</div>
    </div>
  );
};
