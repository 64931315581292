import React, { useEffect, useState } from "react";
import BoxWithToggle from "../util/boxWithToggle";
import { withUser } from "../../userContext";
import { getNextGameweeksObjects } from "../util/nextGws";
import SelectSearch, { fuzzySearch } from "react-select-search";
import PlayerAvatar from "../util/playerAvatar";
import format from "date-fns/format";
import { formatDistance } from "date-fns";
import Injured100 from "../../img/injury-100.svg";
import Injured75 from "../../img/injury-75.svg";
import Injured25 from "../../img/injury-25.svg";
import { errorCatcher } from "../util/errors";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import { allSportsOptions } from "../util/sports";

function ManagerInjuries(props) {
  const [injuries, setInjuries] = useState([]);
  const [gw, setGw] = useState(0);
  const [filterGw, setFilterGw] = useState(false);
  const [gws, setGws] = useState([]);
  const [sortHow, setSortHow] = useState(1);
  const [forbidden, setForbidden] = useState(undefined);
  const [sport, setSport] = useState("all");

  useEffect(() => {
    async function fetchGw() {
      let nextGws = await getNextGameweeksObjects(props);
      setGws(nextGws);
    }

    fetchGw();
    getInjuries();
  }, [props.manager]);

  const sortData = (w) => {
    let newInjuries = [...injuries];
    let how = sortHow * -1;
    setSortHow(how);
    let key = "";
    if (w === "date") {
      key = "until";
      newInjuries.sort(function (a, b) {
        if (new Date(a.status[key]).getTime() === 0) return how;
        else if (new Date(b.status[key]).getTime() === 0) return -1 * how;
        if (new Date(a.status[key]).getTime() > new Date(b.status[key]).getTime()) return -1 * how;
        else if (new Date(a.status[key]).getTime() < new Date(b.status[key]).getTime()) return how;
        else return 0;
      });
    } else if (w === "reliability") {
      key = "reliabilityScore";
      newInjuries.sort(function (a, b) {
        if (a.status[key] > b.status[key]) return -1 * how;
        else if (a.status[key] < b.status[key]) return how;
        else return 0;
      });
    } else if (w === "cards") {
      newInjuries.sort(function (a, b) {
        if (a.cards.length > b.cards.length) return -1 * how;
        else if (a.cards.length < b.cards.length) return how;
        else return 0;
      });
    }

    setInjuries(newInjuries);
  };

  const getInjuries = (g, t, s) => {
    let gwValue = g || gw;
    let isOk = filterGw;
    if (t !== undefined) {
      isOk = t;
    }
    if (!isOk) gwValue = 0;
    let sportValue = s || sport;
    props
      .fetch("/apiv2/manager/injuries/" + props.manager.Slug + "?gw=" + gwValue + "&sport=" + sportValue)
      .then((response) => {
        if (response.status === 403) {
          setForbidden(true);
          throw new Error("Forbidden");
        } else {
          return response.json();
        }
      })
      .then((res) => {
        setForbidden(false);
        setInjuries(res);
      })
      .catch(errorCatcher());
  };

  if (forbidden === true) {
    return (
      <PlayerBlockedVideo
        title="Want more insights?"
        description="Become a Star member to unlock roster injuries view"
        videoLink="https://www.youtube.com/embed/0odiGkEEHbE"
      />
    );
  }

  return (
    <div className={"space-y-4"}>
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-white rounded-lg"}>
        <div className={"flex flex-row space-x-4 p-3 rounded-lg bg-white col-span-2"}>
          <div className={"w-6/12 self-center border border-grey-f2 p-1.5 rounded-lg"}>
            <BoxWithToggle
              label={"Filter by a gameweek"}
              isActive={filterGw}
              setActive={() => {
                if ((filterGw === false && gw > 0) || filterGw === true) {
                  getInjuries(undefined, !filterGw);
                }
                setFilterGw(!filterGw);
              }}
            />
          </div>
          <div className={"w-6/12 self-center"}>
            <SelectSearch
              options={gws}
              value={gw}
              onChange={(v) => {
                if (filterGw === true) {
                  getInjuries(v, undefined);
                }
                setGw(v);
              }}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Choose a gameweek"
            />
          </div>
        </div>
        <div className={"self-center"}>
          <SelectSearch
            options={allSportsOptions}
            value={sport}
            onChange={(v) => {
              getInjuries(undefined, undefined, v);
              setSport(v);
            }}
            printOptions={"on-focus"}
            filterOptions={fuzzySearch}
            placeholder="Choose a sport"
          />
        </div>
      </div>
      <div>
        <table className={"border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}>
          <thead>
            <tr className="text-center">
              <th className="rounded-tl-lg text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-3/12 font-bold uppercase text-xs">
                Player
              </th>
              <th
                onClick={() => sortData("cards")}
                className="cursor-pointer text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs"
              >
                Your cards
              </th>
              <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs">
                Injury
              </th>
              <th
                onClick={() => sortData("date")}
                className="cursor-pointer text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-1 text-left w-1/12 font-bold uppercase text-xs"
              >
                Until
              </th>
              <th
                onClick={() => sortData("reliability")}
                className="cursor-pointer text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs"
              >
                Sources
              </th>
              <th className="rounded-tr-lg text-center text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs">
                Last update
              </th>
            </tr>
          </thead>
          <tbody>
            {injuries !== null &&
              injuries.map((i) => {
                const injury = i.status;
                const player = i.player;
                const cards = i.cards;
                let until = new Date(injury.until);
                if (until.getTime() < 1000) {
                  until = undefined;
                }
                return (
                  <tr className={"border-b border-grey-e5"}>
                    <td>
                      <div className={"flex flex-row space-x-4 py-1"}>
                        <div className={"self-center"}>
                          <PlayerAvatar player={i.player} />
                        </div>
                        <div className={"self-center"}>
                          <a href={"/player/" + player.PlayerId}>
                            <p className={"text-sm font-semibold hover:font-bold"}>{player.DisplayName}</p>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row space-x-0.5 justify-center"}>
                        {cards.map((c) => {
                          return (
                            <div className={"h-14"}>
                              <a href={"/card/" + c.TokenId}>
                                <img className={"w-full h-full object-contain"} src={c.PictureUrl} />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row space-x-2"}>
                        <div className={"self-center"}>
                          <img
                            className={"w-4"}
                            src={injury.reliabilityScore >= 100 ? Injured100 : injury.reliabilityScore >= 75 ? Injured75 : Injured25}
                          />
                        </div>
                        <p className={"self-center text-sm font-semibold"}>{injury.description}</p>
                      </div>
                    </td>
                    <td>
                      <p className={"text-sm font-semibold text-center"}>{until !== undefined ? format(until, "LLLL do") : "Unknown"}</p>
                    </td>
                    <td>
                      <p className={"text-sm font-semibold text-center"}>{injury.origins}</p>
                    </td>
                    <td>
                      <p className={"text-sm font-semibold text-center"}>
                        {formatDistance(new Date(injury.updatedAt), new Date(), { addSuffix: true })}
                      </p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {injuries === null && (
          <div className={"pt-4"}>
            <p className={"text-sm text-center font-semibold"}>No one is injured in this gallery.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(ManagerInjuries);
