import React from "react";
import { withUser } from "../../userContext";
import CardSelected from "../../img/card-selected.svg";

function SelectionBubble(props) {
  const { onSelect, isSelected, isHover } = props;

  if (!isSelected && !isHover) {
    return null;
  }

  return (
    <div onClick={onSelect} className={"bg-focus rounded-full border-none w-full"}>
      <img className={"cursor-pointer w-full h-full " + (isHover && !isSelected ? "opacity-60" : "")} src={CardSelected} />
    </div>
  );
}

export default withUser(SelectionBubble);
