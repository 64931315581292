import React, { Fragment, useState } from "react";
import { Button } from "./button";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export const WatchDemoModale = (props) => {
  const { open, title, description, description2, videoLink } = props;

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={onCancel}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={onCancel}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"w-auto mt-8"}>
                  <div className={"w-full border-b-2 border-grey-e5 pb-2"}>
                    <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>{title}</h2>
                  </div>
                  <div className={"mx-full mb-4 px-3 pt-3"}>
                    <div className={"flex flex-col justify-center w-full gap-3"}>
                      <div className={"relative flex justify-center m-auto w-11/12 pb-[51%]"}>
                        <div className={"absolute py-3 px-6 w-full h-full"}>
                          <iframe
                            src={videoLink}
                            className={"rounded-2xl w-full h-full"}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={"text-center font-semibold text-textGrey3 text-md"}>{description}</div>
                      <div className={"text-center font-semibold text-textGrey3 text-md"}>{description2}</div>
                      <div className={"mx-auto mt-1"}>
                        <Button label={"Got it!"} onClick={onCancel} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export const WatchDemoBadge = (props) => {
  const text = props.text || "Watch demo";
  const title = props.title || "Watch demo";
  const description = props.description;
  const description2 = props.description2;
  const videoLink = props.videoLink;
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        onClick={() => setOpen(true)}
        className={`my-auto text-xxs color-white font-bold rounded-full flex justify-center cursor-pointer uppercase px-2 py-0.5 text-white bg-super-rare`}
      >
        {text}
      </div>
      <WatchDemoModale
        title={title}
        description={description}
        description2={description2}
        videoLink={videoLink}
        open={open}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};
