import React from "react";
import Help from "../../img/help-grey.svg";
import Dynamic from "../../img/dynamic.svg";
import LineSep from "../../img/line-sep.svg";
import { formatPrice } from "../util/formatMoney";
import { getDynamicValuation } from "../util/dynamicValuation";
import { getLowestValuation } from "../util/lowestValuation";
import Sell from "../../img/sell.svg";
import SellWhite from "../../img/sell-white.svg";
import Lock from "../../img/lock.svg";
import { withUser } from "../../userContext";

function CardTag(props) {
  if (!props.tag) {
    return null;
  }
  const tag = props.tag;
  const status = tag.status;
  if (props.onSale) {
    return (
      <div className={"flex flex-row justify-center"}>
        <div className={"py-0.5 px-2 rounded-full bg-[#FF6666] flex flex-row justify-center space-x-1"}>
          <img src={SellWhite} />
          <p className={"text-xs font-semibold text-white"}>On sale</p>
        </div>
      </div>
    );
  } else if (props.onSaleAndPrice) {
    return (
      <div className={"flex flex-row justify-center"}>
        <div className={"py-0.5 px-2 rounded-full border-white border bg-[#FF6666] flex flex-row justify-center space-x-2"}>
          <div className={"flex flex-row space-x-1"}>
            <img src={SellWhite} />
            <p className={"text-xs font-semibold text-white"}>On sale</p>
          </div>
          <div className={"border border-white"} />
          <div>
            <p className={"text-xs font-semibold text-white"}>{props.price}</p>
          </div>
        </div>
      </div>
    );
  } else if (status === "") {
    return (
      <div className={"flex flex-row justify-center"}>
        <div className={"py-0.5 px-2 bg-white rounded-full border border-[#E6E6E6] flex flex-row justify-center space-x-1"}>
          <img src={Help} />
          <p className={"text-xs font-semibold text-[#666666]"}>No price set</p>
        </div>
      </div>
    );
  } else if (status.includes("DYN")) {
    let text = "";
    if (status.includes("M20")) {
      text = "-20%";
    } else if (status.includes("M15")) {
      text = "-15%";
    } else if (status.includes("M10")) {
      text = "-10%";
    } else if (status.includes("M5")) {
      text = "-5%";
    } else if (status.includes("LAT")) {
      text = "Lat.";
    } else if (status.includes("DYN_5")) {
      text = "+5%";
    } else if (status.includes("DYN_10")) {
      text = "+10%";
    } else if (status.includes("DYN_15")) {
      text = "+15%";
    } else if (status.includes("20")) {
      text = "+20%";
    }
    let price = getLowestValuation(props.rt, props.bmp, tag.currency);
    let format = getDynamicValuation(status, tag.currency, price);
    if (props.long) {
      text = "Dyn. " + text;
    }
    return (
      <div className={"flex flex-row justify-center"}>
        <div className={"px-2 rounded-full bg-white border border-dashed border-[#333333] flex flex-row justify-center space-x-1"}>
          <img src={Dynamic} />
          <p className={"text-xs font-semibold text-black-4D self-center"}>{text}</p>
          {!props.long && <img src={LineSep} />}
          {!props.long && <p className={"text-xs font-semibold text-black-4D self-center"}>{format}</p>}
        </div>
      </div>
    );
  } else if (status === "FIXED") {
    let format = formatPrice(tag.price, tag.currency);
    return (
      <div className={"flex flex-row justify-center"}>
        <div className={"px-2 rounded-full border bg-white border-[#333333] flex flex-row justify-center space-x-2"}>
          <img src={Sell} />
          <img src={LineSep} />
          <p className={"text-xs font-semibold text-[#333333] self-center"}>{format}</p>
        </div>
      </div>
    );
  } else if (status === "NOT_FOR_SALE") {
    return (
      <div className={"flex flex-row justify-center"}>
        <div className={"px-2 py-0.5 bg-[#F2F2F2] rounded-full border border-[#F2F2F2] flex flex-row justify-center space-x-2"}>
          <img src={Lock} />
          <p className={"text-xs font-semibold text-[#666666] self-center"}>Not for sale</p>
        </div>
      </div>
    );
  }
  return <div></div>;
}

export default withUser(CardTag);
