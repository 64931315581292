import React from "react";

export const Tag = (props) => {
  // Button with design system
  const disabled = props.disabled || false;
  const adapting = props.adapting || false;
  const iconPosition = props.iconPosition || "left";
  const context = props.context || "emphasized-transparent";
  const size = props.size || "small";
  let textColor = "";
  const rounded = props.rounded || "rounded-full";
  const textSize = props.textSize || (size === "small" ? "text-xs" : "text-sm");
  const padding = props.padding ? props.padding : props.label || props.children ? (size === "small" ? "px-2 py-1" : "px-3 py-1") : "p-1";
  let divColors = "";
  if (disabled) {
    divColors = props.bg || "bg-surface-disable text-on-disable opacity-40";
  } else {
    if (context === "emphasized-transparent") {
      textColor = props.textColor || "text-on-surface";
      divColors = props.bg || "bg-transparent-surface-container-high bg-opacity-70";
    } else if (context === "emphasized-opaque") {
      textColor = props.textColor || "text-on-surface-variant";
      divColors = props.bg || "bg-surface-container-high bg-opacity-70";
    } else if (context === "neutral") {
      textColor = props.textColor || "text-on-surface-variant";
      divColors = props.bg || "bg-transparent-inverse-surface-low bg-opacity-10";
    } else if (context === "tertiary") {
      textColor = props.textColor || "text-on-tertiary-fixed";
      divColors = props.bg || "bg-tertiary-fixed";
    } else {
      // Dim
      textColor = props.textColor || "text-on-surface-variant";
      divColors = props.bg || "bg-transparent-surface-container-high bg-opacity-70";
    }
  }

  // Get from props all attributes that are handled by divs
  const divProps = Object.keys(props)
    .filter((key) =>
      [
        "onClick",
        "disabled",
        "className",
        "title",
        "style",
        "data-tip",
        "data-html",
        "data-for",
        "data-delay-show",
        "data-position",
      ].includes(key),
    )
    .reduce((obj, key) => {
      obj[key] = props[key];
      return obj;
    }, {});

  return (
    <div
      {...divProps}
      className={`inline-flex gap-1 font-semibold items-center ${
        adapting ? "w-full h-full" : ""
      } ${textColor} ${textSize} ${padding} ${divColors} ${rounded}  focus:outline-none`}
    >
      {iconPosition === "left" && props.icon}
      {props.label}
      {props.children}
      {iconPosition === "right" && props.icon}
    </div>
  );
};
