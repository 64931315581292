export function formatAllCurrencies(price, unit) {
  if (unit === "eth") {
    return price.toFixed(3) + " Ξ";
  } else {
    if (unit === "€") unit = "eur";
    else if (unit === "$") unit = "usd";
    else if (unit === "£") unit = "gbp";
    let max = 2;
    if (price >= 100) max = 0;
    if (price >= 1000) max = 0;
    if (unit === "usd") {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } else if (unit === "eur") {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } else if (unit === "gbp") {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    }
  }
}
