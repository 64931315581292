import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as RevertDiscardAll } from "@material-design-icons/svg/filled/group_add.svg";
import { ReactComponent as HelpIcon } from "../../img/icons-help-no-color.svg";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import { LineupBuilderTable, TableViewAndFormPicker } from "./lineupBuilderTable";
import PopHoverWrapper from "../util/popHoverWrapper";
import { LineupBuilderPlayerTiles } from "./footballLineupBuilderTiles";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import { MobileModale } from "../util/mobileModale";
import AbortController from "abort-controller";
import { ReactTooltip } from "../util/tooltip";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";

const Help = (props) => {
  return (
    <PopHoverWrapper
      hoverTitle="Help"
      hover={
        <div className={"flex flex-col gap-2 justify-center items-center w-80"}>
          <p>
            Discarded players are removed from the next suggestions for the current lineup. You can take them back from the discarded
            players panel.
          </p>
          <p>Discarding or taking a player back might replace all your unlocked player.</p>
        </div>
      }
    >
      <HelpIcon className={"fill-on-surface h-6 w-6 "} />
    </PopHoverWrapper>
  );
};

export const DiscardedPlayersModale = withUser((props) => {
  const { discardedCards, lineup, close, onDiscardRevert, onDiscardRevertAll, targetUserId, divId, gw, draftId, lineupId, onLoad, width } =
    props;

  const [pickedView, setPickedView] = useState("classic");
  const [pickedAverage, setPickedAverage] = useState("l15");
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [breakdown, setBreakdown] = useState(undefined);
  const mdQueries = useTailwindMediaQueries();

  const onChangeView = useCallback((view) => {
    setPickedView(view);
  }, []);

  const onChangeAverage = useCallback((avg) => {
    setPickedAverage(avg);
  }, []);

  const fetchBreakdown = useCallback(() => {
    const cardTokenIds = [Object.values(discardedCards).map((p) => p.card.TokenId), lineup.map((p) => p.card.TokenId)].flat();
    if (divId && gw > 0 && targetUserId !== undefined && cardTokenIds.length > 0) {
      setLoading(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      props
        .fetch("/breakdown-api/decision/lub/breakdown", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userSorareId: targetUserId,
            gwNumber: gw,
            division_full_id: divId,
            draft_id: draftId,
            include_picked_players: true,
            card_token_ids: cardTokenIds,
            lineup_id: lineupId,
          }),
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          setBreakdown(res);
          ReactTooltip.rebuild();
          setLoading(false);
          onLoad && onLoad();
        })
        .catch(
          errorCatcher(() => {
            ReactTooltip.rebuild();
            setLoading(false);
            onLoad && onLoad();
          }),
        );
    }
  }, [targetUserId, gw, divId, draftId, discardedCards, lineup]);

  useEffect(() => {
    fetchBreakdown();
  }, [targetUserId, gw, divId, draftId, discardedCards, lineup]);

  const discardedCardsValues = Object.values(discardedCards);
  const discardedPlayerInfo = discardedCardsValues
    .map((p) => breakdown?.breakdown?.find((b) => b.card.TokenId === p.card.TokenId))
    .filter((p) => p !== undefined);
  const tableDiscardProps = {
    players: breakdown ? discardedPlayerInfo : undefined,
    hideHeader: true,
    noActionLabel: true,
    ...props.tableProps,
    odds: breakdown?.full_odds,
    standings: breakdown?.standings,
    so5ScoresDetailed: breakdown?.so5_scores_detailed,
    oppScoresDetailed: breakdown?.opp_scores_detailed,
    placeholderAmount: 2,
    loading: loading,
  };
  const lineupInfo = lineup
    ?.map((p) => breakdown?.breakdown?.find((b) => b.card.TokenId === p.card.TokenId))
    .filter((p) => p !== undefined);
  const tableLineupProps = {
    players: breakdown ? lineupInfo : undefined,
    hideHeader: true,
    defaultSort: {
      field: "computed.lineupPosition",
      order: "asc",
      origin: "manual",
    },
    ...props.tableProps,
    odds: breakdown?.full_odds,
    standings: breakdown?.standings,
    so5ScoresDetailed: breakdown?.so5_scores_detailed,
    oppScoresDetailed: breakdown?.opp_scores_detailed,
    placeholderAmount: 2,
    loading: loading,
  };
  return (
    <div
      className={"relative bg-surface-container-high rounded-lg p-4 drop-shadow-popover flex-col gap-3 w-auto 2xl:w-[60em] hidden lg:flex"}
      style={{ width: width }}
    >
      <div
        className={
          "absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-[45%] bg-surface-container-high rotate-45 w-5 h-5 rounded-sm"
        }
      ></div>
      <div className={"flex flex-row gap-1 justify-between"}>
        <h2 className={"text-xl font-medium font-headers m-0 text-on-surface"}>Discarded players</h2>
        <div className={"flex flex-row gap-2 items-center"}>
          <div className={"flex flex-row gap-1 items-center"}>
            <Help />
            <RevertDiscardAll
              className={"fill-on-surface h-6 w-6 cursor-pointer hover:opacity-80"}
              title="Revert all cards to not discarded players"
              onClick={onDiscardRevertAll}
            />
          </div>
          <div className={"flex flex-row cursor-pointer"} onClick={close}>
            <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
          </div>
        </div>
      </div>
      <div className={"flex flex-col sm:flex-row gap-2 mb-2 justify-start"}>
        <TableViewAndFormPicker
          adapting={!mdQueries["xl"]}
          direction={"flex-col xl:flex-row"}
          {...{ pickedView, onChangeView, pickedAverage, onChangeAverage }}
        />
      </div>
      <div className={"flex flex-col xl:flex-row 2xl:flex-col gap-y-2 gap-x-4"}>
        <div className={"flex flex-col gap-2 w-full"}>
          <h3 className={"hidden xl:block 2xl:hidden font-headers font-medium m-0 text-on-surface text-sm"}>Discard players</h3>
          {discardedCardsValues && discardedCardsValues.length > 0 ? (
            mdQueries["2xl"] ? (
              <div className={"overflow-hidden rounded-lg"}>
                <LineupBuilderTable
                  {...tableDiscardProps}
                  noActionLabel={true}
                  onDiscardRevert={onDiscardRevert}
                  containerHeight={376}
                  containerOffset={"0px"}
                  pickedView={pickedView}
                  pickedAverage={pickedAverage}
                  noHeader={true}
                />
              </div>
            ) : (
              <div className={"block 2xl:hidden overflow-hidden"}>
                <LineupBuilderPlayerTiles
                  {...tableDiscardProps}
                  onDiscardRevert={onDiscardRevert}
                  containerHeight={600}
                  pickedView={pickedView}
                  pickedAverage={pickedAverage}
                  noHeader={true}
                  columns={"grid-cols-1"}
                />
              </div>
            )
          ) : (
            <div className={"w-full p-4 flex justify-center items-center"}>
              <p className={"text-on-surface-variant text-sm italic font-semibold"}>No discarded players</p>
            </div>
          )}
        </div>
        <div className={"flex flex-col gap-2 w-full"}>
          <h3 className={"font-headers font-medium m-0 text-on-surface text-sm"}>Current lineup</h3>
          {lineup && lineup.length > 0 ? (
            mdQueries["2xl"] ? (
              <div className={"overflow-hidden rounded-lg border border-primary bg-surface-container-highest"}>
                <LineupBuilderTable
                  {...tableLineupProps}
                  noActionLabel={true}
                  pickedView={pickedView}
                  pickedAverage={pickedAverage}
                  noHeader={true}
                />
              </div>
            ) : (
              <div className={"overflow-hidden "}>
                <LineupBuilderPlayerTiles
                  {...tableLineupProps}
                  pickedView={pickedView}
                  containerHeight={600}
                  pickedAverage={pickedAverage}
                  noHeader={true}
                  columns={"grid-cols-1"}
                />
              </div>
            )
          ) : (
            <div className={"w-full p-4 flex justify-center items-center"}>
              <p className={"text-on-surface-variant text-sm italic font-semibold"}>No players in lineups</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export const DiscardedPlayersModaleMobile = withUser((props) => {
  const { discardedCards, onDiscardRevert, onDiscardRevertAll, open, close, targetUserId, divId, lineupId, gw, draftId } = props;
  const [pickedView, setPickedView] = useState("classic");
  const [pickedAverage, setPickedAverage] = useState("l15");
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [breakdown, setBreakdown] = useState(undefined);

  const fetchBreakdown = useCallback(() => {
    const cardTokenIds = Object.values(discardedCards).map((p) => p.card.TokenId);
    if (divId && gw > 0 && targetUserId !== undefined && cardTokenIds.length > 0) {
      setLoading(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      props
        .fetch("/breakdown-api/decision/lub/breakdown", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userSorareId: targetUserId,
            gwNumber: gw,
            division_full_id: divId,
            draft_id: draftId,
            include_picked_players: true,
            card_token_ids: cardTokenIds,
            lineup_id: lineupId,
          }),
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          setBreakdown(res);
          ReactTooltip.rebuild();
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            ReactTooltip.rebuild();
            setLoading(false);
          }),
        );
    }
  }, [targetUserId, gw, divId, draftId, discardedCards]);

  useEffect(() => {
    open && fetchBreakdown();
  }, [open, targetUserId, gw, divId, draftId, discardedCards]);

  const onChangeView = useCallback((view) => {
    setPickedView(view);
  }, []);

  const onChangeAverage = useCallback((avg) => {
    setPickedAverage(avg);
  }, []);

  const discardedCardsValues = discardedCards ? Object.values(discardedCards) : [];
  const discardedPlayerInfo = discardedCardsValues
    .map((p) => breakdown?.breakdown?.find((b) => b.card.TokenId === p.card.TokenId))
    .filter((p) => p !== undefined);
  const tableDiscardProps = {
    players: breakdown ? discardedPlayerInfo : undefined,
    hideHeader: true,
    noActionLabel: true,
    ...props.tableProps,
    odds: breakdown?.full_odds,
    standings: breakdown?.standings,
    so5ScoresDetailed: breakdown?.so5_scores_detailed,
    oppScoresDetailed: breakdown?.opp_scores_detailed,
    placeholderAmount: 1,
    loading: loading,
  };

  return (
    <MobileModale open={open} close={close}>
      <div className={"flex flex-row gap-4 justify-center"}>
        <div className={"max-w-3xl relative align-bottom bg-surface-container rounded-t-2xl text-left shadow-xl w-full overflow-hidden"}>
          <div
            className={
              "flex flex-col gap-2 border-b border-transparent-inverse-surface-low border-opacity-10 bg-surface-container py-3 px-3"
            }
          >
            <div className={"flex flex-row gap-1 justify-between"}>
              <div className={"flex flex-row cursor-pointer"} onClick={close}>
                <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
              </div>
              <h2 className={"text-xl font-medium font-headers m-0 text-on-surface"}>Discarded players</h2>
              <div className={"flex flex-row gap-1 items-center"}>
                <Help />
                <RevertDiscardAll
                  className={"fill-on-surface h-6 w-6 cursor-pointer hover:opacity-80"}
                  title="Revert all cards to not discarded players"
                  onClick={onDiscardRevertAll}
                />
              </div>
            </div>
          </div>
          <div className={"bg-surface-container-high py-2 px-3 flex flex-col gap-2"}>
            <div className={"flex flex-col sm:flex-row gap-2 justify-start"}>
              <TableViewAndFormPicker
                adapting={true}
                direction={"flex-col"}
                {...{
                  pickedView,
                  onChangeView,
                  pickedAverage,
                  onChangeAverage,
                }}
              />
            </div>
            <div className={"mb-6 overflow-y-auto max-h-[70vh]"}>
              <LineupBuilderPlayerTiles
                {...tableDiscardProps}
                onDiscardRevert={onDiscardRevert}
                pickedView={pickedView}
                pickedAverage={pickedAverage}
                noHeader={true}
                columns={"grid-cols-1"}
              />
            </div>
          </div>
        </div>
      </div>
    </MobileModale>
  );
});
