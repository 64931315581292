import GoalIcon from "../../img/ICN_Ballon.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import PenaltySave from "../../img/PenaltySave.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import CleanSheet from "../../img/cleansheet.svg";
import React from "react";

export default function GetDaIcons(stats, divStyle) {
  let icons = [];
  if (divStyle === undefined) {
    divStyle = "w-6 h-6 self-center rounded-full";
  }
  let imgStyle = "w-full h-full object-contain shadow rounded-full";
  for (let i = 0; i < stats.Goals; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={GoalIcon} />
      </div>,
    );
  }
  for (let i = 0; i < stats.AdjustedGoalAssist; i++) {
    let left = icons.length * 2;
    let z = 10 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={AssistIcon} />
      </div>,
    );
  }
  for (let i = 0; i < stats.AssistPenaltyWon; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={Whistle} />
      </div>,
    );
  }
  for (let i = 0; i < stats.ClearanceOffLine + stats.LastManTackle; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={AngelBall} />
      </div>,
    );
  }
  for (let i = 0; i < stats.PenaltySaves + stats.PenaltySave; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={PenaltySave} />
      </div>,
    );
  }
  for (let i = 0; i < stats.RedCard; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={RedCard} />
      </div>,
    );
  }
  for (let i = 0; i < stats.OwnGoal; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={OwnGoal} />
      </div>,
    );
  }
  for (let i = 0; i < stats.PenaltyConceded; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={RedWhistle} />
      </div>,
    );
  }
  for (let i = 0; i < stats.ErrorLeadToGoal; i++) {
    let left = icons.length * 2;
    let z = 100 + icons.length;
    icons.push(
      <div className={divStyle}>
        <img className={imgStyle} src={RedShoe} />
      </div>,
    );
  }
  if (stats.Position === "Goalkeeper") {
    for (let i = 0; i < stats.CleanSheet; i++) {
      let left = icons.length * 2;
      let z = 100 + icons.length;
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={CleanSheet} />
        </div>,
      );
    }
  }
  return icons;
}
