import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import format from "date-fns/format";
import React from "react";
import ReactTooltip from "react-tooltip";
import { isFree } from "../util/handleSubscriptionTier";
import { PlansMap } from "../stripe/productPage.js";
import { Button } from "../util/button";

function removePendingUpdate(props) {
  props
    .fetch("/apiv2/stripe/subscriptions/removePendingUpdate", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
    .then((response) => response.json())
    .then((res) => {
      console.log(res);
      ReactTooltip.rebuild();
      props.reload(Date.now());
    })
    .catch(errorCatcher());
}

let RemoveUpdateBox = withUser((props) => {
  const currentPlanWeight = PlansMap[props.currentPlan][props.currentFrequency][props.currentCurrency]["weight"];
  const nextPlanWeight = PlansMap[props.nextPlan][props.nextFrequency][props.nextCurrency]["weight"];
  const isDowngrade = nextPlanWeight < currentPlanWeight;
  return (
    <div className="flex bg-cover w-full max-w-[40em] min-h-[15em] bg-[#EEE4C3] bg-no-repeat bg-center shadow-sm rounded-lg justify-self-auto justify-items-center text-center bg-subscription-free">
      <div className="m-auto flex flex-col gap-3 justify-center w-full h-full place-items-center text-sm">
        <div className="font-semibold">
          <p>
            Your {props.currentFrequency}ly {PlansMap[props.currentPlan]["displayName"]} membership will be changed to a{" "}
            {props.nextFrequency}ly {PlansMap[props.nextPlan]["displayName"]} membership after{" "}
            {format(new Date(props.cancelDate), "MMMM do yyyy")}
          </p>
          {props.currentPlan !== props.nextPlan && isDowngrade && (
            <p>which will result in a loss of access to enhanced tools and features.</p>
          )}
          <p>Do you want to cancel this change?</p>
        </div>
        <div className="font-semibold">
          <Button
            label={"Cancel change"}
            onClick={() => {
              removePendingUpdate(props);
            }}
          />
        </div>
      </div>
    </div>
  );
});

function ManageSubscriptionBox(props) {
  if (props.cancel === true) {
    return <div></div>;
  }
  if (props.plan && props.nextPlan !== props.plan && !isFree(props.plan)) {
    return (
      <div className="flex flex-col md:gap-y-4 gap-y-8">
        <RemoveUpdateBox
          cancel={props.cancel}
          cancelDate={props.cancelDate}
          reload={props.reload}
          currentPlan={props.plan}
          nextPlan={props.nextPlan}
          currentCurrency={props.currentCurrency}
          nextCurrency={props.nextCurrency}
          currentFrequency={props.currentFrequency}
          nextFrequency={props.nextFrequency}
        ></RemoveUpdateBox>
      </div>
    );
  }
  return <div></div>;
}

export default withUser(ManageSubscriptionBox);
