import React, { useEffect } from "react";
import { withUser } from "../../userContext";
import AllPagesPDFViewer from "./allPages";
import samplePDF from "./SORAREDATA_20220727_TERMS OF USE.pdf";

const TermsOfUse = (props) => {
  useEffect(() => {
    document.title = "Terms of Use";
  }, []);
  return (
    <div className={"mx-auto p-4"}>
      <h2 className={"my-0 text-brand-text text-center mx-auto uppercase"}>Terms of use</h2>
      <h3 className={"my-2 text-brand-black text-center mx-auto text-sm"}>August 2022</h3>
      <h4 className={"my-Z text-brand-black text-center mx-auto text-xs"}>Version 0.2</h4>
      <div className={"w-auto mx-auto bg-focus rounded-lg shadow overflow-y-scroll"} style={{ maxWidth: "fit-content" }}>
        <AllPagesPDFViewer pdf={samplePDF} />
      </div>
    </div>
  );
};

export default withUser(TermsOfUse);
