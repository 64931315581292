import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { Box, Text, TextInput } from "grommet";
import LoginBanner from "../../img/login-banner.png";
import SDLoading from "../util/SDLoading";
import queryString from "query-string";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { withRouter } from "react-router-dom";
import { errorCatcher } from "../util/errors";
import { Button } from "../util/button";
import ls from "local-storage";
import ValidateRegistrationPlanModal from "./validateRegistrationPlanModal";

function ValidateRegistration(props) {
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState({ user: {}, sorareUser: {} });
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState("");
  const [openPlanModal, setOpenPlanModal] = useState(false);

  const isFormValid = email !== undefined && email !== "" && areTermsAccepted;
  const validateButtonTitle = isFormValid ? "" : "You need to choose a valid email and accept the terms of use";

  useEffect(() => {
    if (!props.isConnected()) {
      props.history.push("/");
    } else if (props.isRegistrationValidated()) {
      onValidated();
    } else {
      setLoading(true);
      props
        .fetch("/apiv2/auth/profile")
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.error === undefined) {
            ls.set("email", res.user.Email);
            ls.set("termsAccepted", res.user.TermsAccepted);
            if (res.user.Email && res.user.TermsAccepted) {
              setLoading(false);
              if (res.rights.Plan === "free") {
                setOpenPlanModal(true);
              } else {
                setOpenPlanModal(false);
                onValidated();
              }
            } else {
              setProfile(res);
              setLoading(false);
            }
          } else {
            console.log(res.error);
            setLoading(false);
            props.history.push("/");
          }
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  }, []);

  useEffect(() => {
    setEmail(profile.user.Email);
  }, [profile]);

  const onValidated = () => {
    const parsed = queryString.parse(props.location.search);
    let dest = "";
    if (parsed.to) {
      dest = parsed.to;
    }
    props.history.push("/" + dest);
  };

  const validateTerms = () => {
    setValidating(true);
    props
      .fetch("/apiv2/user/validateRegistration", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          termsAccepted: areTermsAccepted,
        }),
      })
      .then((response) => {
        console.log(response);
        if (response.status >= 500) {
          throw Error(response);
        } else {
          return response.json();
        }
      })
      .then((res) => {
        ls.set("email", res.Email);
        ls.set("termsAccepted", res.TermsAccepted);
        if (res.error) {
          setError(res.error);
        } else if (res.Email && res.TermsAccepted) {
          const tier = ls.get("tier");
          if (tier === "free") {
            setOpenPlanModal(true);
          } else {
            onValidated();
          }
        } else {
          console.error(res);
        }
        setValidating(false);
      })
      .catch((res) => {
        console.log(res);
        setValidating(false);
      });
  };

  if (loading || !props.user.username || (!profile.user && !profile.sorareUser)) {
    return <SDLoading />;
  }

  return (
    <div className={"flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0 min-h-screen"}>
      <div className={"md:w-6/12"}>
        <div className={"space-y-3 text-left w-10/12 mx-auto mt-8 md:mt-16 self-center"}>
          <div className={"text-left mx-auto text-headers font-bold"}>
            <span className={"rounded-full mr-2"}>
              <img className={"rounded-full inline w-16 h-16 object-cover"} src={profile.sorareUser.PictureUrl || EmptyAvatar} />
            </span>
            <h3 className={"text-3xl font-bold font-headers mt-2 mb-1"}>Welcome {profile.sorareUser.Nickname || ""}</h3>
          </div>
          <div className={"my-2 space-y-2"}>
            <p className={"text-sm font-semibold"}>Please enter your email & approve our terms of use to continue.</p>
            <p className={"text-sm"}>
              We will only use your e-mail to contact you in relation to your account. We will not share your details with third parties as
              per our confidentiality policy.
            </p>
          </div>
          <div className={"w-10/12"} style={{ maxWidth: "30em" }}>
            <form
              className={"flex flex-col space-y-3"}
              onSubmit={(e) => {
                e.preventDefault();
                validateTerms();
              }}
            >
              <TextInput type="email" value={email} placeholder="Email address" onChange={(e) => setEmail(e.target.value)} />
              <label className={"font-normal space-x-2"}>
                <input
                  style={{ width: "0.75em", height: "0.75em" }}
                  type={"checkbox"}
                  onClick={() => setAreTermsAccepted(!areTermsAccepted)}
                  className={`focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus:outline-0 rounded border-textGrey4 bg-focus`}
                />
                <span className={"text-md"}>
                  I agree with the{" "}
                  <a className={"text-brand-text cursor-pointer font-semibold"} target={"_blank"} href={"/terms-of-use"} rel="noreferrer">
                    Terms of Use
                  </a>
                </span>
              </label>
              <div className={"flex flex-row pt-4"}>
                <Button
                  title={validateButtonTitle}
                  disabled={!isFormValid}
                  label={validating ? "Validating" : "Validate"}
                  onClick={(e) => {
                    e.preventDefault();
                    validateTerms();
                  }}
                />
              </div>
            </form>
          </div>
          <Box margin={{ top: "small" }}>
            <Text>{error}</Text>
          </Box>
        </div>
      </div>
      <div
        className={"h-screen md:h-auto md:w-6/12"}
        style={{
          backgroundImage: "url('" + LoginBanner + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <ValidateRegistrationPlanModal
        open={openPlanModal}
        onClose={() => {
          setOpenPlanModal(false);
          onValidated();
        }}
      />
    </div>
  );
}

export default withUser(withRouter(ValidateRegistration));
