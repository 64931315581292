import React from "react";
import { ReactComponent as WatchlistPlayerTypeIcon } from "../../img/icon-watchlists-type-player.svg";
import { ReactComponent as WatchlistManagerTypeIcon } from "../../img/icon-watchlists-type-manager.svg";
import { withUser } from "../../userContext";
import WatchlistFollowingButton from "./watchlistFollowingButton";
import { Link } from "react-router-dom";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";

function WatchlistCardSimple(props) {
  const { id, ownerDisplayName, owner, name, type, enabled, nbItems, sport, isFollowed, nbNewFollowers } = props.watchlist;

  const connectedUserIsOwner = owner === props.user.sorareSlug;

  if (id === "") {
    return null;
  }

  const info = (
    <div className={enabled ? "cursor-pointer" : ""}>
      <p className={"text-md font-semibold"}>{name}</p>
      <p className={"text-sm font-sans"}>
        <span>{nbItems + (type === "player" ? " players" : " managers")}</span>
        {sport !== "multi" && (
          <>
            <span>{" • "}</span>
            {sport === sorareBaseball && <span className={"my-auto text-sm font-semibold"}>⚾️️</span>}
            {sport === sorareFootball && <span className={"my-auto text-sm font-semibold"}>⚽</span>}
            {sport === sorareBasketball && <span className={"my-auto text-sm font-semibold"}>🏀</span>}
          </>
        )}
        <span>{" • "}</span>
        <span>{ownerDisplayName || owner}</span>
      </p>
    </div>
  );

  return (
    <div
      className={
        "bg-focus mx-auto rounded-lg w-full p-4 flex flex-row place-content-between gap-1 border border-grey-e9 " +
        (enabled ? "" : "opacity-50")
      }
    >
      <div className={"flex flex-row p-0 gap-3 items-center justify-start w-9/12"}>
        <div title={`Watchlist of ${type}s`}>{type === "player" ? <WatchlistPlayerTypeIcon /> : <WatchlistManagerTypeIcon />}</div>
        {enabled && <Link to={`/watchlists/id/${id}`}>{info}</Link>}
        {!enabled && info}
      </div>
      <div className={"my-auto w-3/12 flex flex-row justify-center"}>
        <WatchlistFollowingButton
          id={id}
          connectedUserIsOwner={connectedUserIsOwner}
          enabled={enabled}
          nbFollowers={nbNewFollowers}
          isFollowed={isFollowed}
          withLabel
          size={10}
        />
      </div>
    </div>
  );
}

export default withUser(WatchlistCardSimple);
