import { periodLabels } from "../util/periodPicker";
import { formatAllCurrencies } from "../util/formatAllCurrencies";
import { getValuationIcon, getValuationOrigin } from "../util/valuationOrigin";
import UnknownPlayer from "../../img/unknown_player2.png";
import PlayerAvailability from "./playerAvailability";
import { PlayingStatus } from "../util/playingStatus";
import FloorPrice from "../../img/best-price.svg";
import { scarcities_colors } from "../util/scarcities";
import { formatGrowth } from "../util/formatGrowth";
import React from "react";
import { withUser } from "../../userContext";

const PlayerPriceCard = (props) => {
  const { player, availabilityStatus, team, bestMarketPrice, firstValuation, firstValuationOrigin, lastValuation, lastValuationOrigin } =
    props.player;
  const scarcity = props.scarcity;
  const currency = props.currency;
  const period = periodLabels[props.period];
  let colorStart = "#1D3EBD";
  let colorEnd = "#132D8F";
  if (player.ColorLeft !== "") {
    colorStart = player.ColorLeft;
  }
  if (player.ColorRight !== "") {
    colorEnd = player.ColorRight;
  }

  const growth =
    firstValuation !== undefined && firstValuation != null && firstValuation != 0
      ? (lastValuation - firstValuation) / firstValuation
      : undefined;
  let growthStyle = "text-brand-black";
  if (growth !== undefined && growth !== 0) {
    growthStyle = growth > 0 ? "text-success-green" : "text-horrible-red";
  }

  const valuationDataTip =
    growth !== undefined
      ? `Valuation growth in period "${period}".<br/> ${formatAllCurrencies(firstValuation, currency)} (${getValuationOrigin(
          firstValuationOrigin,
        )}) → ${formatAllCurrencies(lastValuation, currency)} (${getValuationOrigin(lastValuationOrigin)})`
      : `No valuation`;

  return (
    <div className={"bg-focus mx-auto rounded-xl w-full"}>
      <div
        className={"relative h-card-xl rounded-xl opacity-100"}
        style={{
          background: "linear-gradient(" + colorStart + ", " + colorEnd + ")",
        }}
      >
        <div className={"rounded-xl h-card-xl z-0 text-focus overflow-hidden"} style={{ opacity: "15%" }}>
          <div className={"h-card-xl rounded-xl flex h-card-xl bg-gradient-to-b from-white to-full-black z-10"} />
        </div>
        <div className={"absolute text-white opacity-100 z-10 w-full  h-card-xl bottom-0 top-0 inset-x-0"}>
          <div className={"h-card-xl opacity-90 absolute z-0 right-28"}>
            {
              <img
                className={"object-cover h-full pt-2"}
                src={player.SquaredPictureUrl !== "" ? player.SquaredPictureUrl : UnknownPlayer}
              />
            }
          </div>
          <div className={"ml-4 pb-4 h-card-xl z-10 absolute flex flex-col justify-end pt-4"}>
            <div className={"flex flex-row justify-start space-x-2"}>
              <p className={"text-center text-lg font-semibold hover:font-bold"}>
                <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                  {player.DisplayName}
                </a>
              </p>
              <div className={"self-center"}>
                <PlayerAvailability size={"w-4"} availability={availabilityStatus} intl={player.InternationalTrip} />
              </div>
            </div>
            <div className={"space-y-1"}>
              <p className={"text-sm font-medium"}>
                {player.Age} - {player.Position}{" "}
                {player.PlayingStatus && (
                  <>
                    <span>{`- `}</span>
                    <PlayingStatus status={player.PlayingStatus} player={player} />
                  </>
                )}
              </p>
              <p className={"text-sm font-semibold"}>
                {team.DisplayName} ({team.League})
              </p>
            </div>
          </div>
          <div className={"absolute h-full right-6 flex flex-col justify-center gap-4"}>
            <div
              style={{ minWidth: "4em" }}
              className={"flex flex-col px-2 py-2 gap-1 w-full self-center rounded-md bg-white text-md"}
              data-tip={bestMarketPrice === undefined || bestMarketPrice === null ? "No floor price" : "Floor price"}
            >
              <div className={"w-5 h-5 self-center"}>
                <img src={FloorPrice} className={"w-full h-full"} />
              </div>
              <div className={"text-xs font-semibold self-center"} style={{ color: scarcities_colors[scarcity.toLowerCase()] }}>
                {bestMarketPrice === undefined || bestMarketPrice === null ? (
                  <>{"---"}</>
                ) : (
                  <>{formatAllCurrencies(bestMarketPrice, currency)}</>
                )}{" "}
              </div>
            </div>
            <div
              style={{ minWidth: "4em" }}
              className={"flex flex-col px-2 py-2 gap-1 w-full self-center rounded-md bg-white text-md"}
              data-html={true}
              data-tip={valuationDataTip}
            >
              <div className={"w-5 h-5 self-center"}>
                <img src={getValuationIcon(lastValuationOrigin)} className={"w-full h-full"} />
              </div>
              <div className={"text-xs font-semibold self-center " + growthStyle}>{formatGrowth(growth, currency)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUser(PlayerPriceCard);
