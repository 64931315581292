import { t1OrAbove } from "./handleSubscriptionTier";
import React from "react";
import { ReactTooltip } from "./tooltip";
import SelectSearch, { fuzzySearch } from "react-select-search";
import BoxWithToggle from "./boxWithToggle";

export const split_price_all = "ALL";
export const split_price_in_season = "IN_SEASON";
export const split_price_classic = "CLASSIC";

export const splitOptions = [
  { value: split_price_all, name: "All seasonalities" },
  { value: split_price_in_season, name: "In-season" },
  { value: split_price_classic, name: "Classic season" },
];

export const getSplitLabel = (split) => {
  let label = "";
  switch (split) {
    case split_price_all:
      label = "All season";
      break;
    case split_price_in_season:
      label = "New season";
      break;
    case split_price_classic:
      label = "Classic";
      break;
  }
  return label;
};

export const CardSplitDropdown = (props) => {
  const { seasonality, onChange, tier } = props;
  const placeholder = splitOptions.find((o) => o.value === split_price_all)?.name;
  const premiumNeeded = !t1OrAbove(tier);

  if (!premiumNeeded) {
    return (
      <SelectSearch
        className={"select-search border border-grey-f2 rounded"}
        options={splitOptions}
        printOptions={"on-focus"}
        filterOptions={fuzzySearch}
        value={seasonality}
        closeOnSelect={false}
        onChange={onChange}
      />
    );
  }
  return (
    <div className={`h-full bg-focus shadow py-2 rounded-md`}>
      <ReactTooltip id={"player-status-selector-component"} />
      <BoxWithToggle
        label={placeholder}
        isActive={false}
        disabled={premiumNeeded}
        disabledTip={"Star membership is needed"}
        className={"h-full"}
      />
    </div>
  );
};
