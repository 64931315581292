import { sorareBaseball, sorareBasketball } from "../util/sports";
import { ReactComponent as IconCommon } from "../../img/scarcity/icon-scarcity-common.svg";
import { ReactComponent as IconLimited } from "../../img/scarcity/icon-scarcity-limited.svg";
import { ReactComponent as IconRare } from "../../img/scarcity/icon-scarcity-rare.svg";
import { ReactComponent as IconSuperRare } from "../../img/scarcity/icon-scarcity-super-rare.svg";
import { ReactComponent as IconUnique } from "../../img/scarcity/icon-scarcity-unique.svg";

export const scarcities = ["All scarcities", "Limited", "Rare", "Super rare", "Unique"];
export const scarcities_no_limited = ["All scarcities", "Rare", "Super rare", "Unique"];
export const scarcities_objects_not_all = [
  { value: "LIMITED", name: "Limited" },
  {
    value: "RARE",
    name: "Rare",
  },
  { value: "SUPER RARE", name: "Super rare" },
  { value: "UNIQUE", name: "Unique" },
];
export const playable_scarcities_object_not_all = [{ value: "COMMON", name: "Common" }, ...scarcities_objects_not_all];
export const scarcities_objects = [
  { value: "all", name: "All scarcities" },
  {
    value: "LIMITED",
    name: "Limited",
  },
  { value: "RARE", name: "Rare" },
  { value: "SUPER RARE", name: "Super rare" },
  { value: "UNIQUE", name: "Unique" },
];
export const playable_scarcities_object = [{ value: "all", name: "All scarcities" }, ...playable_scarcities_object_not_all];
export const scarcities_objects_with_custom_series = [...scarcities_objects, { value: "CUSTOM SERIES", name: "Custom series" }];
export const scarcities_objects_not_all_with_custom_series = [
  ...scarcities_objects_not_all,
  { value: "CUSTOM SERIES", name: "Custom series" },
];
export const scarcities_objects_no_limited = [
  { value: "all", name: "All scarcities" },
  {
    value: "RARE",
    name: "Rare",
  },
  { value: "SUPER RARE", name: "Super rare" },
  { value: "UNIQUE", name: "Unique" },
];
export const scarcities_objects_not_all_and_cs = [...scarcities_objects_not_all, { value: "CUSTOM SERIES", name: "Custom Series" }];

export const scarcities_colors = {
  limited: "#E7B62C",
  rare: "#E73107",
  "super rare": "#0380F3",
  unique: "#4F4F4F",
  "custom series": "#8D32E4",
};

export const custom_series_types = [
  { value: "all", name: "All custom series" },
  { value: "diamond", name: "Diamond" },
  { value: "gold", name: "Gold" },
  { value: "silver", name: "Silver" },
  { value: "bronze", name: "Bronze" },
];

export const getScarcityInfo = (_scarcity, sport) => {
  let scarcity = _scarcity.toUpperCase();
  let color = "text-brand-black";
  let bgColor = "";
  let supply = "COMMON";
  let Icon = IconCommon;

  if (scarcity === "LIMITED") {
    color = "text-scarcity-limited-on-surface-container";
    bgColor = "bg-scarcity-limited-container-dim-fixed";
    Icon = IconLimited;
    supply = 1000;
    if (sport === sorareBaseball || sport === sorareBasketball) {
      supply = 5000;
    }
  } else if (scarcity === "RARE") {
    color = "text-scarcity-rare-on-surface-container";
    bgColor = "bg-scarcity-rare-container-dim-fixed";
    Icon = IconRare;
    supply = 100;
    if (sport === sorareBaseball || sport === sorareBasketball) {
      supply = 1000;
    }
  } else if (scarcity === "SUPER RARE") {
    color = "text-scarcity-super-rare-on-surface-container";
    bgColor = "bg-scarcity-super-rare-container-dim-fixed";
    Icon = IconSuperRare;
    supply = 10;
    if (sport === sorareBaseball || sport === sorareBasketball) {
      supply = 100;
    }
  } else if (scarcity === "UNIQUE") {
    color = "text-scarcity-unique-on-surface-container";
    bgColor = "bg-scarcity-unique-container-dim-fixed";
    Icon = IconUnique;
    supply = 1;
  } else if (scarcity === "CUSTOM SERIES") {
    color = "text-scarcity-custom-series-on-surface-container";
    bgColor = "bg-scarcity-custom-series-container-dim-fixed";
    supply = 2022;
  }

  return { color, bgColor, supply, Icon };
};

export const scarcities_objects_not_all_shortname = [
  { value: "LIMITED", name: "LTD", Icon: IconLimited },
  {
    value: "RARE",
    name: "Rare",
    Icon: IconRare,
  },
  { value: "SUPER RARE", name: "SR", Icon: IconSuperRare },
  { value: "UNIQUE", name: "UNQ", Icon: IconUnique },
];
