import React, { useState } from "react";
import { errorCatcher } from "../util/errors";
import InfiniteScroll from "react-infinite-scroll-component";
import SDLoading from "../util/SDLoading";
import BasicCard from "../cards/basicCard";
import CheckBox from "../../img/check-box-grey.svg";
import CheckBoxBrand from "../../img/check-box-brand.svg";
import MarketStatusPopover from "../cards/marketStatusPopover";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import { withUser } from "../../userContext";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";

function ManagerCardsAPILoadMore(props) {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [sortValue, setSortValue] = useState(props.sort);

  const selectPlayer = (c) => {
    if (selectedPlayers.includes(c)) {
      setSelectedPlayers(selectedPlayers.filter((p) => p !== c));
    } else {
      let newSelected = [...selectedPlayers];
      newSelected.push(c);
      setSelectedPlayers(newSelected);
    }
  };

  const selectAll = () => {
    if (selectedPlayers.length === props.players.length) {
      setSelectedPlayers([]);
    } else {
      let newSelected = [];
      props.players.map((p) => {
        newSelected.push(p.card.TokenId);
      });
      setSelectedPlayers(newSelected);
    }
  };

  const createTags = (tag, price, currency, open) => {
    price = price.replace("€", "").replace("$", "").replace("£", "").replace("Ξ", "");
    price = price.replace(",", ".");
    price = parseFloat(price);
    if (open && tag === "NOT_FOR_SALE") {
      tag = "";
    }
    if (price >= 0 && tag === "") {
      tag = "FIXED";
    }
    if (tag !== "FIXED") {
      price = 0;
    }
    if (!open) {
      tag = "NOT_FOR_SALE";
      price = 0;
      currency = "";
    }
    props
      .fetch("/apiv2/cards/createTags", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tokenId: selectedPlayers,
          tag: tag,
          price: price,
          currency: currency,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setSelectedPlayers([]);
        props.refresh();
      })
      .catch(errorCatcher());
  };

  const changeSortValue = (s) => {
    let changeOrder = false;
    if (s === sortValue) {
      changeOrder = true;
    }
    if (s === "floor") {
      if (changeOrder) {
        if (props.sort === "Floor - low to high") {
          props.setSort("Floor - high to low");
        } else {
          props.setSort("Floor - low to high");
        }
      } else {
        props.setSort("Floor - high to low");
      }
    } else if (s === "val") {
      if (changeOrder) {
        if (props.sort === "Latest val. - low to high") {
          props.setSort("Latest val. - high to low");
        } else {
          props.setSort("Latest val. - low to high");
        }
      } else {
        props.setSort("Latest val. - high to low");
      }
    } else if (s === "L15") {
      props.setSort("L15 - best to worst");
    } else if (s === "L5") {
      props.setSort("L5 - best to worst");
    }
    setSortValue(s);
  };

  const players = props.players || [];
  const gwGames = props.gwGames;
  const hasNext = props.hasNext !== undefined ? props.hasNext : false;
  const onNext = props.onNext;
  const loading = props.loading !== undefined ? props.loading : false;
  const display = props.display;
  const sport = props.sport;
  return (
    <div className={display === "grid" ? "flex items-start justify-start w-full" : ""}>
      {players !== undefined && players !== null && players.length > 0 ? (
        <InfiniteScroll next={onNext} hasMore={hasNext} loader={<SDLoading />} dataLength={players.length}>
          {display === "grid" && (
            <div
              className={
                "grid grid-cols-1 grid-rows-auto md:grid-cols-3 lg:grid-cols-4 " +
                "xl:grid-cols-5 2xl:grid-cols-5 2.5xl:grid-cols-6 4xl:grid-cols-7 gap-x-3 gap-y-6 3xl:gap-6"
              }
            >
              {players.map((p) => {
                return (
                  <BasicCard
                    setReload={props.setReload}
                    setStatus={props.isMe}
                    card={p}
                    games={gwGames}
                    gwNumber={props.gwNumber}
                    status={true}
                  />
                );
              })}
            </div>
          )}
          {display === "list" && (
            <div className={"w-full rounded-t-lg"}>
              <table className={"w-full rounded-t-lg"}>
                <thead className={"rounded-t-lg text-[#666666] font-semibold"}>
                  <tr className={"bg-builder-header px-1.5 py-4 text-xs text-center rounded-tl-lg"}>
                    <th>
                      {props.isMe && (
                        <div
                          onClick={() => selectAll()}
                          className={"rounded-t-lg py-3 bg-builder-header flex flex-row self-center justify-center cursor-pointer"}
                        >
                          {props.players.length !== selectedPlayers.length && <img src={CheckBox} />}
                          {props.players.length === selectedPlayers.length && <img src={CheckBoxBrand} />}
                        </div>
                      )}
                    </th>
                    <th className={"w-3/12"}>
                      {selectedPlayers.length === 0 && "Player"}
                      {selectedPlayers.length > 0 && (
                        <div className={"flex flex-row space-x-2 py-2"}>
                          <div className={"self-center"}>
                            <p className={"font-semibold text-sm"}>{selectedPlayers.length} players selected</p>
                          </div>
                          <MarketStatusPopover
                            multi={true}
                            createTags={createTags}
                            selectedPlayers={selectedPlayers}
                            players={props.players}
                            modal={true}
                          />
                        </div>
                      )}
                    </th>
                    {sport === sorareFootball && (
                      <th className={""}>
                        <div className={"py-3"}>Pick score</div>
                      </th>
                    )}
                    {sport === sorareBasketball && (
                      <th className={""}>
                        <div className={"py-3 cursor-pointer"} onClick={() => changeSortValue("L5")}>
                          L10
                        </div>
                      </th>
                    )}
                    {sport === sorareBaseball && (
                      <th className={""}>
                        <div className={"py-3 cursor-pointer"} onClick={() => changeSortValue("L15")}>
                          L15
                        </div>
                      </th>
                    )}
                    {sport === sorareFootball && <th className={"text-center"}>L5</th>}
                    {sport === "all" && <th className={"text-center"}></th>}
                    {sport !== sorareFootball && sport !== "all" && <th className={"text-center"}>Proj.</th>}
                    {sport === "all" && <th className={"text-center"}></th>}
                    {sport === sorareFootball && (
                      <th className={"text-center cursor-pointer"} onClick={() => changeSortValue("L15")}>
                        L15
                      </th>
                    )}
                    {sport === sorareBaseball && <th className={"text-center"}>Games</th>}
                    {sport === "all" && <th className={"text-center"}></th>}
                    {sport === sorareBasketball && <th className={"text-center"}>Diff.</th>}
                    {sport === sorareBasketball && <th className={"text-center"}>Games</th>}
                    {sport !== sorareBasketball && sport !== "all" && <th className={"text-center"}>Opposition</th>}
                    {sport === "all" && <th className={"text-center"}></th>}
                    <th className={"text-center"}>Acq. price</th>
                    <th className={"text-center cursor-pointer"} onClick={() => changeSortValue("val")}>
                      Latest val.
                    </th>
                    <th className={"text-center cursor-pointer"} onClick={() => changeSortValue("floor")}>
                      Floor
                    </th>
                    <th className={"text-center"}>Market status</th>
                    <th className={"text-center"}>Price tag</th>
                    {sport === sorareFootball && <th className={"text-center"}>Card use</th>}
                    {sport === "all" && <th className={"text-center"}>SO5 use</th>}
                  </tr>
                </thead>
                <tbody>
                  {players.map((p) => {
                    return (
                      <BasicCard
                        setReload={props.setReload}
                        decoy={props.refreshDecoy}
                        changeTag={props.changeTag}
                        all={sport === "all"}
                        setStatus={props.isMe}
                        card={p}
                        games={gwGames}
                        gwNumber={props.gwNumber}
                        status={true}
                        display={"list"}
                        select={selectPlayer}
                        selected={selectedPlayers}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </InfiniteScroll>
      ) : (
        <div className={"flex flex-col justify-center w-full text-center py-4 "} style={{ height: "20em" }}>
          {loading ? (
            <SDLoading />
          ) : (
            <div className={"m-auto"}>
              <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
              <span className={"text-lg font-headers text-center text-textGrey2 font-bold mb-6"}>No player with such characteristics</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default withUser(ManagerCardsAPILoadMore);
