import React, { useEffect, useState } from "react";
import Spinner from "../loader/spinner";
import GLOBAL_MARGIN from "../util/margin";
import { withRouter } from "react-router-dom";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { Button } from "../util/button";

function VerifyEmail(props) {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState("");

  useEffect(() => verifyEmail(), []);

  const verifyEmail = () => {
    props
      .fetch("/apiv2/user/verify_email", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          otp: props.match.params.otp,
          email: props.match.params.email,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res === true) {
          setMsg("Your email has been verified!");
        } else {
          setMsg("Ooo... something went wrong. Please try again!");
        }
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  if (loading) {
    return (
      <div className={GLOBAL_MARGIN + " text-center"}>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className={GLOBAL_MARGIN + " mt-4 text-center text-base space-y-4"}>
        <p>{msg}</p>
        <Button>
          <div className={"px-4 py-1"}>
            <a href={"/"}> Go back home</a>
          </div>
        </Button>
      </div>
    );
  }
}

export default withRouter(withUser(VerifyEmail));
