import { Text } from "grommet";
import React from "react";
import CardLayout from "./cardLayout";
import { format } from "date-fns";
import { getValuationOrigin } from "../util/valuationOrigin";
import { formatPrice } from "../util/formatMoney";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import { withUser } from "../../userContext";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";

function BasicCard(props) {
  const card = props.card;
  let gamesPlayedL5 = card.l5;
  let so5AvgL5 = card.avg5;
  let gamesPlayedL20 = card.l20;
  let so5AvgL20 = card.avg20;
  let rahPrice = formatRealTimeAverage(card.r30, props.user.preferredUnit);
  let marketPrice = formatBestMarketPrice(card.bmp, props.user.preferredUnit);
  let valuationOrigin = card.r30.ValuationOrigin;
  if (props.user.preferredUnit !== "eth") {
    valuationOrigin = card.r30.ValuationOriginFiat;
  }
  let lastPrice = card.card.LastPrice;
  let lastStatus = card.card.LastStatus;
  let displayStatus = lastStatus;
  if (lastStatus === "Sold") {
    displayStatus = formatPrice(lastPrice, "eth");
  }
  if (card.card_last_move) {
    if (card.card_last_move.details.price > 0) {
      displayStatus = formatPrice(card.card_last_move.details.price, "eth");
      if (props.user.preferredUnit !== "eth") {
        displayStatus = formatPrice(card.card_last_move.details[props.user.preferredUnit], props.user.preferredUnit);
      }
    } else {
      displayStatus = card.card_last_move.details.transferType;
    }
  }
  let firstInfo = <Text size={"14px"}>{format(new Date(card.card.CreationDate), "dd/MM - HH:mm")}</Text>;
  let size = "75%";
  let firstTip = "Creation date";
  if (props.status) {
    firstInfo = displayStatus;
    size = "120%";
    firstTip = "Last card move";
  }

  let dateToDisplay = new Date(card.offer.offer.EndingAt);
  let ethPrice = formatPrice(card.offer.offer.ReceiveAmountInWei, "eth");
  let price = formatPrice(card.offer.offer.ReceiveAmountInWei, "eth");
  if (props.user.preferredUnit !== "eth") {
    price = formatPrice(card.offer.offer.ReceiveAmountInWei * card.price_info[props.user.preferredUnit], props.user.preferredUnit);
  }

  return (
    <>
      {card.offer.offer.OfferId !== "" ? (
        <CardLayout
          imageLink={"/card/" + card.card.TokenId}
          picture={card.card.PictureUrl}
          valuationOrigin={getValuationOrigin(valuationOrigin)}
          ethCurrentPrice={ethPrice}
          onSale={true}
          display={props.display}
          monthPrice={rahPrice}
          marketPrice={marketPrice}
          border={props.border}
          tag={card.card_tag}
          teamPicture={card.team_picture}
          currentPrice={price}
          date={dateToDisplay}
          countdown={true}
          card={card.card}
          player={card.player}
          sport={card.player.Sport}
          width={props.width}
          height={props.height}
          select={props.select}
          l5={so5AvgL5}
          l20={so5AvgL20}
          gms5={gamesPlayedL5}
          gms20={gamesPlayedL20}
          bmp={card.bmp}
          selected={props.selected}
          setReload={props.setReload}
          rt={card.r30}
          setStatus={props.setStatus}
          all={props.all}
          lastMove={card.card_last_move}
          centerLink={
            card.player.Sport === sorareBasketball
              ? "https://sorare.com/nba/cards/" + card.card.Slug
              : card.player.Sport === sorareBaseball
              ? "https://sorare.com/mlb/cards/" + card.card.Slug
              : "https://sorare.com/football/cards/" + card.card.Slug + "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1"
          }
          s5={card.s5Avg}
          s15={card.s15Avg}
          s5Apps={card.s5Apps}
          s15Apps={card.s15Apps}
          availability={card.availability_status}
          gwGames={props.games}
          changeTag={props.changeTag}
          gwNumber={props.gwNumber}
        />
      ) : (
        <CardLayout
          imageLink={"/card/" + card.card.TokenId}
          picture={card.card.PictureUrl}
          lastMove={card.card_last_move}
          monthPrice={rahPrice}
          marketPrice={marketPrice}
          selected={props.selected}
          setReload={props.setReload}
          valuationOrigin={getValuationOrigin(valuationOrigin)}
          teamPicture={card.team_picture}
          currentPrice={firstInfo}
          date={false}
          countdown={false}
          avgPrice={""}
          tag={card.card_tag}
          display={props.display}
          select={props.select}
          changeTag={props.changeTag}
          card={card.card}
          player={card.player}
          gwNumber={props.gwNumber}
          bmp={card.bmp}
          rt={card.r30}
          so5Use={card.inSO5Use}
          setStatus={props.setStatus}
          all={props.all}
          centerLink={
            card.player.Sport === sorareBasketball
              ? "https://sorare.com/nba/cards/" + card.card.Slug
              : card.player.Sport === sorareBaseball
              ? "https://sorare.com/mlb/cards/" + card.card.Slug
              : "https://sorare.com/football/cards/" + card.card.Slug + "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1"
          }
          l5={so5AvgL5}
          l20={so5AvgL20}
          gms5={gamesPlayedL5}
          gms20={gamesPlayedL20}
          s5={card.s5Avg}
          s15={card.s15Avg}
          s5Apps={card.s5Apps}
          s15Apps={card.s15Apps}
          availability={card.availability_status}
          gwGames={props.games}
        />
      )}
    </>
  );
}

export default withUser(BasicCard);
