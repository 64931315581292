/**
 *   Usage is very weird, page using this info should get info from the existing API instead
 *   Because this list updated only on login, it's never up-to-date
 *   TO DEPRECATE
 *   @deprecated try to get rid of this
 */
export function getManagerPlayers(props, manager) {
  if (manager) {
    return props
      .fetch("/apiv2/manager/players/" + manager)
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((res) => {
        console.error(res);
        return [];
      });
  }
  return Promise.all([]);
}
