import React from "react";
import PentagonImg from "../../img/projectedMinutesPentagon.svg";
import SolidBluePentagon from "../../img/pentagon-solid-blue.svg";
import BluePentagon from "../../img/pentagon-blue.svg";
import LightBluePentagon from "../../img/pentagon-light-blue.svg";

function NBAProjectedMinutes(props) {
  let value = props.minutes;
  let real = props.real;
  let img = PentagonImg;
  let textColor = "#3D6DE7";
  if (real) {
    if (value >= 30) {
      img = SolidBluePentagon;
    } else if (value >= 15) {
      img = BluePentagon;
    } else {
      img = LightBluePentagon;
    }
    textColor = "white";
    if (props.precision !== undefined) {
      value = value.toFixed(props.precision);
    }
  }
  let size = props.size || "w-8";
  let font = props.font || "text-xs";

  return (
    <div className={"self-center z-10 relative self-center " + size}>
      <div className={"w-full h-full"}>
        <img src={img} className={"object-contain w-full h-full"} />
      </div>
      <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 justify-center self-center"}>
        <p style={{ color: textColor }} className={font + " font-bold self-center pt-0.5"}>
          {value}&apos;
        </p>
      </div>
    </div>
  );
}

export default NBAProjectedMinutes;
