import { sorareBaseball, sorareBasketball, sorareFootball } from "./sports";

export const positions = ["All positions", "Goalkeeper", "Defender", "Midfielder", "Forward"];
export const positions_not_all = ["Goalkeeper", "Defender", "Midfielder", "Forward"];
export const so5_positions = [...positions_not_all, "Extra"];
export const baseball_positions_not_all = ["SP", "RP", "OF", "MI", "CI"];
export const baseball_positions_not_all_objects = [
  { value: "SP", name: "Starting Pitcher" },
  { value: "RP", name: "Relief Pitcher" },
  { value: "OF", name: "Outfielders" },
  { value: "MI", name: "Middle Infielders" },
  { value: "CI", name: "Corner Infielders" },
];
export const positions_objects = [
  { value: "Goalkeeper", name: "Goalkeeper" },
  { value: "Defender", name: "Defender" },
  { value: "Midfielder", name: "Midfielder" },
  { value: "Forward", name: "Forward" },
];

export const abbrv_football_positions = {
  Goalkeeper: "GK",
  Defender: "DEF",
  Midfielder: "MID",
  Forward: "FWD",
  Extra: "EXT",
};

export const all_positions_objects = [
  { value: "all", name: "All positions" },
  { value: "Goalkeeper", name: "Goalkeeper" },
  { value: "Defender", name: "Defender" },
  { value: "Midfielder", name: "Midfielder" },
  { value: "Forward", name: "Forward" },
];

export const all_baseball_positions_objects = [
  { value: "Flex", name: "Flex" },
  { value: "Extra Hitter", name: "Extra Hitter" },
  { value: "Starting Pitcher", name: "Starting Pitcher" },
  { value: "Relief Pitcher", name: "Relief Pitcher" },
  { value: "Corner Infielder", name: "Corner Infielder" },
  { value: "Middle Infielder", name: "Middle Infielder" },
  { value: "Outfielder", name: "Outfielder" },
  { value: "1B", name: "1st Baseman" },
  { value: "2B", name: "2nd Baseman" },
  { value: "3B", name: "3rd Baseman" },
  { value: "SS", name: "Shortstop" },
  { value: "C", name: "Catcher" },
  { value: "RF", name: "Right Fielder" },
  { value: "CF", name: "Center Fielder" },
  { value: "LF", name: "Left Fielder" },
  { value: "DH", name: "Designated Hitter" },
];

export const basketball_positions_not_all = ["G", "F", "C"];

export const basketball_positions_not_all_objects = [
  { value: "C", name: "Center" },
  { value: "F", name: "Forward" },
  { value: "G", name: "Guard" },
];

export const basketball_positions_objects = [{ value: "all", name: "All positions" }, ...basketball_positions_not_all_objects];

export const abbrv_baseball_positions_objects = [
  { value: "H", name: "Hitters" },
  { value: "SP", name: "Starting Pitcher" },
  { value: "RP", name: "Relief Pitcher" },
  { value: "CI", name: "Corner Infielder" },
  { value: "MI", name: "Middle Infielder" },
  { value: "OF", name: "Outfielder" },
  { value: "1B", name: "1st Baseman" },
  { value: "2B", name: "2nd Baseman" },
  { value: "3B", name: "3rd Baseman" },
  { value: "SS", name: "Shortstop" },
  { value: "C", name: "Catcher" },
  { value: "RF", name: "Right Fielder" },
  { value: "CF", name: "Center Fielder" },
  { value: "LF", name: "Left Fielder" },
  { value: "DH", name: "Designated Hitter" },
];

export const abbrv_basketball_positions_objects = [
  { value: "C", name: "Center" },
  { value: "G", name: "Guard" },
  { value: "F", name: "Forward" },
  { value: "PG", name: "Point Guard" },
  { value: "SG", name: "Shooting Guard" },
  { value: "SF", name: "Small Forward" },
  { value: "PF", name: "Power Forward" },
];

export function getAllPositionsBasedOnSport(s) {
  if (s === sorareBaseball) {
    return all_baseball_positions_objects;
  } else if (s === sorareBasketball) {
    return basketball_positions_objects;
  } else if (s === "all") {
    return all_positions_objects.concat(abbrv_basketball_positions_objects).concat(all_baseball_positions_objects);
  }
  return all_positions_objects;
}

export function getPositionsBasedOnSport(s) {
  if (s === sorareBaseball) {
    return abbrv_baseball_positions_objects;
  } else if (s === sorareBasketball) {
    return abbrv_basketball_positions_objects;
  } else if (s === "all" || s === "multi") {
    return positions_objects.concat(abbrv_baseball_positions_objects).concat(abbrv_basketball_positions_objects);
  }
  return positions_objects;
}

export function getSimplePositionsBasedOnSport(s) {
  if (s === sorareBaseball) {
    return baseball_positions_not_all_objects;
  } else if (s === sorareBasketball) {
    return basketball_positions_not_all_objects;
  } else if (s === sorareFootball) {
    return positions_objects;
  } else {
    return positions_objects.concat(baseball_positions_not_all_objects).concat(basketball_positions_not_all_objects);
  }
}
