import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import ScarcityPicker from "../util/scarcityPicker";
import CurrencyPicker from "../util/currencyPicker";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { errorCatcher } from "../util/errors";
import { capitalize } from "../util/capitalize";
import { formatPrice } from "../util/formatMoney";
import { formatPriceSize } from "../util/formatPriceSide";
import Countdown from "react-countdown-now";
import LineSeparator from "../../img/line-separator.svg";
import SDLoading from "../util/SDLoading";
import NewCardLink from "../cards/newCardLink";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDistance } from "date-fns";
import { seasons_objects } from "../util/seasons";
import { card_editions_objects } from "../util/cardEditions";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import { sorareFootball } from "../util/sports";
import { custom_series_types, getScarcityInfo } from "../util/scarcities";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import Spinner from "../loader/spinner";
import { SorareVerifiedCheckMark } from "../util/manager";
import clsx from "clsx";
import EnabledWallets from "../util/enabledWallets";

function LiveMarketCard(props) {
  const { item, loading } = props;
  if (item === undefined && loading) {
    return (
      <div className={"flex flex-col items-center space-y-1"}>
        <div className={"flex flex-col items-center gap-1"}>
          <div className={"bg-surface-container-high rounded-sm animate-pulse-sm h-3 w-8"}></div>
          <div className={"bg-surface-container-high rounded-sm animate-pulse-sm h-3 w-20"}></div>
        </div>
        <div className={"w-card-market h-card-market"}>
          <div className={"bg-surface-container-high rounded-md animate-pulse-sm w-full h-full"}></div>
        </div>
        <div className={"flex flex-col items-center gap-1"}>
          <div className={"bg-surface-container-high rounded-sm animate-pulse-sm h-5 w-6"}></div>
          <div className={"bg-surface-container-high rounded-sm animate-pulse-sm h-3 w-10"}></div>
          <div className={"bg-surface-container-high rounded-sm animate-pulse-sm h-2 w-14"}></div>
        </div>
      </div>
    );
  }
  const card = item.card;
  const power = card.Power * 100 - 100;
  const currency = props.currency;
  let price = formatPrice(item.price, "eth");
  let nextBid = formatPrice(item.next_bid, "eth");
  if (currency === "eur") {
    price = formatPrice(item.eur, "eur");
    nextBid = formatPrice(item.next_bid_eur, "eur");
  } else if (currency === "usd") {
    price = formatPrice(item.usd, "usd");
    nextBid = formatPrice(item.next_bid_usd, "usd");
  } else if (currency === "gbp") {
    price = formatPrice(item.gbp, "gbp");
    nextBid = formatPrice(item.next_bid_gbp, "gbp");
  }
  let link = "/auction/" + item.item_id;
  if (item.item_type !== "auction") {
    link = "/card/" + card.TokenId;
  }
  const isAuction = item.item_type === "auction";
  let itemType = capitalize(item.item_type);
  if (item.item_type == "instant_buy") {
    itemType = "Instant buy";
  }
  const scarcityInfo = getScarcityInfo(item.card.Scarcity);

  let itemSellerInfo = {
    isSorareSeller: false,
    seller: item.sender?.Nickname || "",
    currenciesAccepted: item.currenciesAccepted || "",
  };

  switch (item.item_type) {
    case "auction":
    case "instant_buy":
      itemSellerInfo.isSorareSeller = true;
      itemSellerInfo.seller = "Sorare";
      itemSellerInfo.currenciesAccepted = "DUAL";
      break;
  }

  return (
    <div>
      <div className="bg-surface-container flex flex-col items-center rounded-t-xl justify-center px-2 pt-2">
        <div className="w-card-market h-card-market">
          <a href={link}>
            <img src={card.PictureUrl} className={"w-full h-full object-contain"} width={184} height={298} />
          </a>
        </div>
        <div className={"relative w-full flex flex-col items-center py-2"}>
          <div className="w-fit bg-surface-container-high rounded-full px-3 py-0.5 mt-1 text-on-surface-variant overflow-hidden font-semibold">
            {formatPriceSize(price, true, false, clsx("text-xs", scarcityInfo.color), clsx("text-xs", scarcityInfo.color))}
          </div>
          <div className="flex justify-around items-center gap-y-1.5 w-full mb-1 px-1 pt-2 text-on-surface-variant text-xs font-semibold">
            <span className="col-span-2">{itemType}</span>
            <div className="!h-3 bg-outline-variant w-px flex-shrink-0 rounded-full" />
            <span className="text-right">{power.toFixed(1)}%</span>
          </div>
          <div className="flex flex-col space-y-1.5">
            <span className="flex justify-center text-xs font-semibold">
              {itemSellerInfo.isSorareSeller ? (
                <>
                  <span>{itemSellerInfo.seller}</span>
                  <SorareVerifiedCheckMark className="ml-1" customIconSize="h-4 w-4" />
                </>
              ) : (
                <span className="truncate w-20 text-center">{itemSellerInfo.seller}</span>
              )}
            </span>
            {itemSellerInfo.currenciesAccepted && (
              <EnabledWallets
                enabledWallets={itemSellerInfo.currenciesAccepted}
                containerClassName="flex bg-outline-variant rounded-full px-2 justify-center items-center text-xs gap-1"
              />
            )}
          </div>
        </div>
      </div>
      <div className="rounded-b-xl bg-surface-container-highest px-4 py-1 flex justify-center">
        <span className="text-xs font-semibold">
          <Countdown daysInHours={true} date={item.ending_date} />
        </span>
      </div>
    </div>
  );
}

function PlayerSalesTab(props) {
  const [scarcity, setScarcity] = useState(props.user.preferredScarcity);
  const [currency, setCurrency] = useState(props.user.preferredUnit);
  const [typeFilter, setTypeFilter] = useState("all");
  const [completedTypeFilter, setCompletedTypeFilter] = useState("all");
  const [transactions, setTransactions] = useState({
    list: undefined,
    hasNext: false,
    offset: 0,
    sortField: "date",
    sortOrder: 1,
  });
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [abortController, setAbortController] = React.useState(new AbortController());
  const [liveMarket, setLiveMarket] = useState(undefined);
  const [loadingLiveMarket, setLoadingLiveMarket] = useState(false);
  const [filteredLiveMarket, setFilteredLiveMarket] = useState(undefined);
  const [seasons, setSeasons] = useState([]);
  const [editions, setEditions] = useState([]);
  const [customSeriesType, setCustomSeriesType] = useState("all");

  const typeFilters = [
    { name: "All", value: "all" },
    { name: "Auctions only", value: "auctions" },
    { name: "Offers only", value: "offers" },
    { name: "Instant buys only", value: "instant_buys" },
  ];
  const completedTypeFilters = [
    { name: "All moves", value: "all" },
    { name: "Auctions & offers", value: "a+o" },
    { name: "Auctions only", value: "auctions" },
    { name: "Offers only", value: "offers" },
    { name: "Instant buys only", value: "instant_buys" },
    { name: "Trades only", value: "trades" },
    { name: "Referrals only", value: "referrals" },
  ];

  const getLiveMarket = (s, t) => {
    let scarcityValue = scarcity;
    if (s !== undefined) scarcityValue = s;
    t = t || customSeriesType;
    setLoadingLiveMarket(true);
    props
      .fetch(`/apiv2/players/liveMarket/v2?playerId=${props.player.PlayerId}&scarcity=${scarcityValue}&customSeriesType=${t}`)
      .then((response) => response.json())
      .then((res) => {
        const newCards = res.cards || [];
        setLiveMarket(newCards);
        filterLiveMarket({ cards: newCards });
        setLoadingLiveMarket(false);
      })
      .catch(
        errorCatcher(() => {
          setLoadingLiveMarket(false);
        }),
      );
  };

  const getMoreTransactions = ({ s, t, seasonsFilter, editionsFilter, typesFilter, sortField, sortOrder }) => {
    const reset = !!s || !!t || !!sortField || sortOrder !== undefined || !!typesFilter || !!editionsFilter || !!seasonsFilter;
    let offset = reset ? 0 : transactions.offset || 0;

    s = s || scarcity;
    t = t || customSeriesType;
    seasonsFilter = seasonsFilter || seasons;
    editionsFilter = editionsFilter || editions;
    typesFilter = typesFilter || completedTypeFilter;
    sortField = sortField || transactions.sortField;
    sortOrder = sortOrder !== undefined ? sortOrder : transactions.sortOrder;

    abortController.abort();
    const newController = new AbortController();
    setAbortController(newController);

    const params = new URLSearchParams({});
    params.append("playerId", props.player.PlayerId);
    params.append("scarcity", s);
    seasonsFilter.forEach((v) => params.append("season", v));
    editionsFilter.forEach((v) => params.append("edition", v));
    params.append("types", typesFilter);
    params.append("customSeriesType", t);
    params.append("sort", sortField + "," + (sortOrder > 0 ? "desc" : "asc"));
    setLoadingTransactions(true);
    props
      .fetch(`/apiv2/players/transactions?${params.toString()}`, {
        headers: withJSONPagination(offset, 50),
        signal: newController.signal,
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        const prevTransactions = transactions.list || [];
        const transactionsRes = res.transactions || [];
        const override = reset || !offset;
        const newList = override ? transactionsRes : [...prevTransactions, ...transactionsRes];
        setTransactions({
          list: newList,
          hasNext: hasNext,
          offset: offset + 50,
          sortField: sortField,
          sortOrder: sortOrder,
        });
        setLoadingTransactions(false);
      })
      .catch(
        errorCatcher(() => {
          setLoadingTransactions(false);
        }),
      );
  };

  useEffect(() => {
    getLiveMarket();
    getMoreTransactions({});
  }, []);

  const filterLiveMarket = ({ cards, t }) => {
    cards = cards || liveMarket;
    let completedType = t || completedTypeFilter;
    let newTransactions = cards.filter((t) => {
      if (completedType !== "all") {
        if (completedType === "auctions" && t.item_type !== "auction") {
          return false;
        }
        if (completedType === "offers" && t.item_type !== "offer") {
          return false;
        }
        if (completedType === "instant_buys" && t.item_type !== "instant_buy") {
          return false;
        }
        if (completedType === "referrals" && t.item_type !== "referral") {
          return false;
        }
        if (completedType === "trades" && t.item_type !== "trade") {
          return false;
        }
        if (completedType === "a+o" && t.item_type !== "auction" && t.item_type !== "offer") {
          return false;
        }
      }
      return true;
    });

    setFilteredLiveMarket(newTransactions);
  };

  const sortData = (h) => {
    if (h == transactions.sortField) {
      getMoreTransactions({ sortOrder: -1 * transactions.sortOrder });
    } else {
      getMoreTransactions({ sortField: h });
    }
  };

  return (
    <div className={"space-y-10"}>
      <div className={"flex flex-row space-x-4 justify-start"}>
        <div>
          <ScarcityPicker
            customSeries
            onChange={(l) => {
              if (l === scarcity) return;
              getLiveMarket(l);
              getMoreTransactions({ s: l });
              setScarcity(l);
            }}
            scarcity={scarcity}
          />
        </div>
        {scarcity === "CUSTOM SERIES" && (
          <div className={"self-center"}>
            <SelectSearch
              options={custom_series_types}
              value={customSeriesType}
              multiple={false}
              closeOnSelect={true}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Filter by type"
              onChange={(v) => {
                getLiveMarket(undefined, v);
                getMoreTransactions({ t: v });
                setCustomSeriesType(v);
              }}
            />
          </div>
        )}
        <div className={"self-center py-0.5 bg-white rounded-lg"}>
          <CurrencyPicker onChange={(l) => setCurrency(l)} currency={currency} />
        </div>
      </div>
      <div>
        <div className={"flex flex-row justify-between mb-4"}>
          <p className={"font-headers text-brand text-xl font-semibold self-center"}>Live market</p>
          <div className={"z-30"}>
            <SelectSearch
              closeOnSelect={true}
              value={typeFilter}
              onChange={(v) => {
                filterLiveMarket({ t: v });
                setTypeFilter(v);
              }}
              options={typeFilters}
            />
          </div>
        </div>
        <div className={"rounded-lg overflow-x-auto"}>
          <div className={"flex flex-row gap-1.5 items-center"}>
            {liveMarket === undefined || loadingLiveMarket
              ? [...Array(3)].map((e, i) => {
                  return (
                    <div className={"flex"}>
                      <LiveMarketCard loading />
                    </div>
                  );
                })
              : filteredLiveMarket
                  ?.map((m, i) => {
                    return (
                      <div className={"flex"}>
                        <LiveMarketCard item={m} filter={typeFilter} currency={currency} />
                      </div>
                    ); // join the results with a separator
                  })
                  .reduce(
                    (prev, curr) =>
                      prev.length === 0
                        ? [curr]
                        : [
                            ...prev,
                            <div className={"w-1 h-6 self-center"}>
                              <img src={LineSeparator} />
                            </div>,
                            curr,
                          ],
                    [],
                  )}
          </div>
          {filteredLiveMarket?.length === 0 && (
            <div className={"flex flex-row justify-center"}>
              <p className={"font-semibold text-center text-sm"}>There are no cards currently on the market with such characteristics.</p>
            </div>
          )}
        </div>
      </div>
      {isFree(props.user.tier) && props.player.Sport === sorareFootball && (
        <UpgradeLimitBox
          className={"bg-white bg-upgrade-bg-todo bg-cover h-upgrade-banner-s w-full"}
          title={"Want more insights?"}
          description={"Become a Star member to unlock all completed transactions history"}
        />
      )}
      <div className={"space-y-4 min-h-30"}>
        {t1OrAbove(props.user.tier) && (
          <div className={"justify-between flex flex-row"}>
            <p className={"font-headers text-brand text-xl font-semibold self-center"}>Completed transactions</p>
            <div className={"flex flex-row space-x-4 self-center"}>
              {loadingTransactions && transactions.list !== undefined && <Spinner />}
              <div className={"z-20"}>
                <SelectSearch
                  className={"select-search z-20"}
                  value={editions}
                  multiple={true}
                  closeOnSelect={false}
                  printOptions={"on-focus"}
                  placeholder={"Filter with one or more editions"}
                  onChange={(v) => {
                    getMoreTransactions({ editionsFilter: v });
                    setEditions(v);
                  }}
                  options={card_editions_objects}
                />
              </div>
              <div className={"z-20"}>
                <SelectSearch
                  closeOnSelect={false}
                  className={"select-search z-20"}
                  value={seasons}
                  multiple={true}
                  printOptions={"on-focus"}
                  placeholder={"Filter with one or more seasons"}
                  onChange={(v) => {
                    getMoreTransactions({ seasonsFilter: v });
                    setSeasons(v);
                  }}
                  options={seasons_objects}
                />
              </div>
              <div className={"z-20"}>
                <SelectSearch
                  closeOnSelect={true}
                  className={"select-search z-20"}
                  value={completedTypeFilter}
                  printOptions={"on-focus"}
                  onChange={(v) => {
                    getMoreTransactions({ typesFilter: v });
                    setCompletedTypeFilter(v);
                  }}
                  options={completedTypeFilters}
                />
              </div>
            </div>
          </div>
        )}
        <div className={"min-h-48 z-0"}>
          {transactions?.list === undefined && <SDLoading />}
          {transactions?.list && (
            <InfiniteScroll
              dataLength={transactions.list.length}
              scrollableTarget={"window"}
              next={() => getMoreTransactions({})}
              hasMore={transactions.hasNext}
              useWindow={true}
              loader={<SDLoading />}
            >
              <table
                className={
                  "z-0 min-h-48 overflow-hidden border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"
                }
              >
                <thead>
                  <tr className="text-center">
                    <th
                      onClick={() => sortData("rank")}
                      className="rounded-tl-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-3/12 font-bold uppercase text-xs"
                    >
                      Card
                    </th>
                    <th
                      onClick={() => sortData("price")}
                      className="text-center cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"
                    >
                      Price
                    </th>
                    <th
                      onClick={() => sortData("buyer")}
                      className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                    >
                      Buyer
                    </th>
                    <th
                      onClick={() => sortData("seller")}
                      className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                    >
                      Seller
                    </th>
                    <th
                      onClick={() => sortData("type")}
                      className="text-center cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"
                    >
                      Type
                    </th>
                    <th
                      onClick={() => sortData("power")}
                      className="text-center cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"
                    >
                      Bonus
                    </th>
                    <th
                      onClick={() => sortData("season")}
                      className="text-center cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"
                    >
                      Season
                    </th>
                    <th
                      onClick={() => sortData("date")}
                      className="text-center cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs"
                    >
                      Date
                    </th>
                    <th className="text-center rounded-tr-lg text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"></th>
                  </tr>
                </thead>
                <tbody className={"space-y-2"}>
                  {transactions.list?.map((t) => {
                    let itemType = capitalize(t.item_type);
                    let price = formatPrice(t.price, "eth");
                    if (currency !== "eth") {
                      price = formatPrice(t[currency], currency);
                    }
                    if (t.price === 0 && t.item_type === "trade") {
                      price = "Trade";
                    }
                    if (t.item_type === "referral") {
                      price = "Referral";
                    }
                    if (t.item_type === "instant_buy") {
                      itemType = "Instant buy";
                    }
                    let seller = t.seller.nickname;
                    let isSorareSeller = false;
                    if (t.item_type === "auction" || t.item_type === "referral" || t.item_type === "instant_buy") {
                      seller = "Sorare";
                      isSorareSeller = true;
                    }
                    let sellerLink = undefined;
                    if (t.seller.slug !== "") {
                      sellerLink = "/manager/" + t.seller.slug;
                    }
                    let power = t.card.Power * 100 - 100;
                    let link = "";
                    if (t.item_id !== "") {
                      if (t.item_type === "trade" || t.item_type === "offer") {
                        link = "/offer/" + t.item_id;
                      } else if (t.item_type === "auction") {
                        link = "/auction/" + t.item_id;
                      }
                    }

                    if (t.item_type === "") {
                      if (t.card.LastStatus === "Bundle") {
                        seller = "Sorare";
                        price = "Bundle";
                        itemType = "Bundle";
                      } else if (t.card.LastStatus === "Trade") {
                        itemType = "Trade";
                        price = "Trade";
                      }
                    }
                    const id = t.card.TokenId + "-" + t.item_id + "-" + itemType + "-" + t.buyer.slug + "-" + seller;
                    return (
                      <tr key={id} className={"py-4 border border-b border-grey-e5 text-sm"}>
                        <td className={"pl-4 py-3 font-semibold"}>
                          {props.player.DisplayName} - <NewCardLink sport={props.player.Sport} card={t.card} />
                        </td>
                        <td className={"text-center font-semibold"}>{price}</td>
                        <td className={"pr-2 self-center font-semibold text-xs truncate text-ellipsis overflow-hidden"}>
                          <a href={"/manager/" + t.buyer.slug}>{t.buyer.nickname}</a>
                        </td>
                        <td className={"pr-2 self-center font-semibold text-xs truncate text-ellipsis overflow-hidden"}>
                          <a className="flex items-center" href={sellerLink}>
                            {seller}
                            {isSorareSeller && <SorareVerifiedCheckMark className="ml-1" />}
                          </a>
                        </td>
                        <td className={"self-center font-semibold text-sm text-center"}>{itemType}</td>
                        <td className={"self-center font-semibold text-sm text-center"}>{power.toFixed(1)}%</td>
                        <td className={"self-center font-semibold text-sm text-center"}>{t.card.Season}</td>
                        <td className={"self-center font-semibold text-center text-sm"}>
                          {formatDistance(new Date(t.ending_date), new Date(), {
                            addSuffix: true,
                          })}
                        </td>
                        {t.item_type === "instant_buy" ? (
                          <td className={"text-center font-semibold"}>-</td>
                        ) : (
                          <td className={"text-center hover:font-bold text-brand font-semibold"}>
                            <a href={link}>Details</a>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          )}
          {transactions?.list?.length === 0 && (
            <div className={"flex flex-row justify-center py-2"}>
              <p className={"font-semibold text-center text-sm"}>There are no transactions matching filters.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withUser(PlayerSalesTab);
