import React, { useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import moment from "moment";

const localStorageKey = "onboarding_done";

const onboardings = [
  {
    page: "player",
    name: "costAndComProj",
    enabled: true,
    maxDate: "2023-04-07",
    steps: [
      {
        target: ".player-page-cost-and-com-proj-1",
        placement: "top",
        placementBeacon: "bottom",
        content: "You can now see the fantasy cost of a player, as used in Cap divisions",
      },
      {
        target: ".player-page-cost-and-com-proj-2",
        placement: "top",
        placementBeacon: "bottom",
        content: "You can access the game page directly by clicking on the date",
      },
      {
        target: ".player-page-cost-and-com-proj-3",
        placement: "top",
        placementBeacon: "bottom",
        content: "You can now project directly from the player page",
      },
    ],
  },
  {
    page: "footballLUB",
    name: "commProj",
    maxDate: "2023-04-07",
    enabled: true,
    steps: [
      {
        target: ".football-lub-page-com-proj-1",
        placement: "top",
        placementBeacon: "bottom",
        content: "You can now project directly from the Lineup Builder",
      },
    ],
  },
  {
    page: "liveMarket",
    name: "smartSearchMarket2",
    maxDate: "2023-08-07",
    enabled: true,
    steps: [
      {
        target: ".smart-search-live-market",
        placement: "top",
        placementBeacon: "right",
        content: "You can now search the market with our smart search engine.",
        content2:
          'You can search for players, scarcities, leagues, teams, positions, u23, card editions, floor price (use the word "floor", offers only) & more! ',
        content3: 'Try searching for "u23 limited america" or "limited nantes lyon psg" to start!',
      },
    ],
  },
  {
    page: "gallery",
    name: "smartSearchGallery",
    maxDate: "2023-08-07",
    enabled: true,
    steps: [
      {
        target: ".smart-search-gallery",
        placement: "top",
        placementBeacon: "left",
        content: "You can now search your gallery with our smart search engine.",
        content2: "You can search for players, scarcities, leagues, teams, positions, u23, card editions & more! ",
        content3: 'Try searching for "u23 limited america" or "limited nantes lyon psg" to start!',
      },
    ],
  },
  {
    page: "footballLUB",
    name: "pickScoreSameForAllDivs",
    maxDate: "2024-01-31",
    enabled: true,
    steps: [
      {
        target: ".pick-score-same-all-divs",
        placement: "top",
        placementBeacon: "bottom",
        content:
          "Pick scores have changed for capped mode competitions. <a href='https://soraredata.medium.com/13af8851bebd' target='_blank' referrerpolicy='no-referrer' class='text-primary'>Click here for more info</a>",
        html: true,
      },
    ],
  },
];

const getEnabledOnboarding = () => {
  const itemFromLS = localStorage.getItem(localStorageKey);
  let doneOnboardings = [];
  if (itemFromLS) {
    try {
      doneOnboardings = JSON.parse(itemFromLS) || [];
    } catch (e) {
      console.error(e);
    }
  }
  const today = moment();
  const finalOnboardings = onboardings
    .map((e) => {
      const foundDone = doneOnboardings.find((done) => done.page === e.page && done.name === e.name);
      let enabled = e.enabled;
      if (enabled && e.maxDate !== undefined) {
        const maxDate = moment(e.maxDate);
        if (maxDate.isValid()) {
          enabled = today.isBefore(maxDate);
        }
      }
      return {
        ...e,
        enabled: enabled && (!foundDone || foundDone.index !== -1),
        index: foundDone?.index || 0,
      };
    })
    .filter((e) => e.enabled);
  return finalOnboardings;
};

export const getEnabledOnboardingForPage = (page) => {
  const enabledOnboardings = getEnabledOnboarding();
  const pageOnboardings = enabledOnboardings.filter((e) => e.page === page);
  return pageOnboardings;
};

export const endOnboarding = (page, name) => {
  nextOnboarding(page, name, -1);
};

export const nextOnboarding = (page, name, index) => {
  const itemFromLS = localStorage.getItem(localStorageKey);
  let doneOnboardings = [];
  if (itemFromLS) {
    try {
      doneOnboardings = JSON.parse(itemFromLS) || [];
    } catch (e) {
      console.error(e);
    }
  }
  const foundIndex = doneOnboardings.findIndex((done) => done.page === page && done.name === name);
  if (foundIndex >= 0) {
    const newDone = { ...doneOnboardings[foundIndex], index };
    doneOnboardings[foundIndex] = newDone;
  } else {
    doneOnboardings.push({ page, name, index });
  }
  localStorage.setItem(localStorageKey, JSON.stringify(doneOnboardings));
};

export const getFirstEnabledOnboardingForPage = (page) => {
  const pageOnboardings = getEnabledOnboardingForPage(page);
  return pageOnboardings.length > 0 ? pageOnboardings[0] : {};
};

export const Onboarding = (props) => {
  const { page, name, index, steps, onEnd, onSkip, onNext, scrolling } = props;
  const [data, setData] = useState({
    run: index > -1,
    stepIndex: index,
  });
  const disableScrolling = scrolling !== undefined ? !scrolling : true;
  return (
    <ReactJoyride
      stepIndex={data.stepIndex}
      run={data.run}
      steps={steps}
      disableScrolling={disableScrolling}
      callback={(params) => {
        const { action, index, status, type } = params;
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
          // Update state to advance the tour
          const newIndex = index + (action === ACTIONS.PREV ? -1 : 1);
          setData({ ...data, stepIndex: newIndex });
          onNext && onNext(page, name, newIndex);
        } else if (status === STATUS.FINISHED) {
          setData({ ...data, stepIndex: steps.length, run: false });
          onEnd && onEnd(page, name);
        } else if (status === STATUS.SKIPPED) {
          setData({ ...data, stepIndex: steps.length, run: false });
          onSkip && onSkip(page, name);
        }
      }}
      tooltipComponent={Tooltip}
      beaconComponent={Beacon}
    />
  );
};

const Tooltip = ({ step, skipProps, primaryProps, tooltipProps, isLastStep }) => {
  const contentElem = step.html ? <div dangerouslySetInnerHTML={{ __html: step.content }} /> : <div>{step.content}</div>;
  return (
    <div className={`sd-light`}>
      <div className={"bg-surface-container rounded-lg shadow-lg px-3 pt-3 pb-2 w-96 space-y-2 text-on-surface"} {...tooltipProps}>
        <div className={"flex flex-col space-y-2"}>
          <div className={"text-sm font-semibold"}>{contentElem}</div>
          {step.content2 && <div className={"text-sm font-semibold"}>{step.content2}</div>}
          {step.content3 && <div className={"text-sm font-semibold"}>{step.content3}</div>}
        </div>
        <div className={"flex flex-row justify-between"}>
          {isLastStep ? (
            <span></span>
          ) : (
            <a className={"text-on-surface-variant text-sm font-semibold"} {...skipProps}>
              Skip
              <span id="skip" />
            </a>
          )}
          <a className={"text-primary text-sm font-semibold"} {...primaryProps}>
            {isLastStep ? "Ok, got it!" : "Next"}
            <span id="next" />
          </a>
        </div>
      </div>
    </div>
  );
};

const Beacon = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="react-joyride__beacon relative bg-transparent border-0 border-radius-0 cursor-pointer line-height-1 p-1 appearance-none display-inline-block h-[80px] position-relative w-[80px] z-100 drop-shadow -mt-[25%]"
      type="button"
      aria-label="Start onboarding"
      title="Start onboarding"
    >
      <div className={"absolute left-0 top-0 flex flex-row justify-center items-center w-full h-full animate-onboarding-outer"}>
        <svg className={"m-auto"} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="18.5" fill="white" fillOpacity="0.4" stroke="white" strokeWidth="3" />
        </svg>
      </div>
      <div className={"absolute left-0 top-0 flex flex-row justify-center items-center w-full h-full"}>
        <svg
          className={"animate-onboarding-inner m-auto"}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="1" cx="12" cy="12" r="12" fill="#12F4D6" />
          <path
            d="M12.4206 9.94345H12.3845V19.1008H12.4206C14.9494 19.1008 17 17.0506 17 14.5214C17 11.9922 14.9498 9.94203 12.4206 9.94203"
            fill="#F55205"
          />
          <path d="M16.9996 9.61505V5H12.3845C12.3845 7.54845 14.4513 9.61505 16.9996 9.61505Z" fill="white" />
          <path
            d="M9.30732 14.4866C8.85095 14.4866 8.40483 14.622 8.02539 14.8756C7.64594 15.1292 7.35021 15.4896 7.17559 15.9112C7.00097 16.3328 6.9553 16.7968 7.04436 17.2444C7.13342 17.692 7.35321 18.1032 7.67593 18.4258C7.99865 18.7485 8.40981 18.9683 8.85742 19.0573C9.30503 19.1463 9.76899 19.1006 10.1906 18.926C10.6123 18.7513 10.9726 18.4555 11.2262 18.0761C11.4797 17.6966 11.615 17.2505 11.615 16.7941C11.615 16.1821 11.3718 15.5952 10.9391 15.1624C10.5063 14.7297 9.91933 14.4866 9.30732 14.4866V14.4866Z"
            fill="white"
          />
          <path
            d="M7 9.57923C7 12.1082 9.05019 14.1586 11.5794 14.1586H11.6155V5.00085H11.5794C9.05039 5.00085 7 7.05104 7 9.58024"
            fill="white"
          />
        </svg>
      </div>
    </button>
  );
};
