export const errorCatcher = (callback) => (res) => {
  if (res?.name === "AbortError") {
    console.warn("Request cancelled by the user");
  } else {
    console.error(res);
    if (callback !== undefined) {
      callback(res);
    }
  }
};
