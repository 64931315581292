import ordinal_suffix_of from "./ordinalNumber";
import { ReactComponent as GoldMedal } from "../../img/gold-medal.svg";
import { ReactComponent as SilverMedal } from "../../img/silver-medal.svg";
import { ReactComponent as BronzeMedal } from "../../img/bronze-medal.svg";
import React from "react";

const RankLabel = (props) => {
  const { rank, hashMode } = props;
  const label = hashMode ? "#" + rank : ordinal_suffix_of(rank);
  return (
    <div>
      {rank === 1 && <GoldMedal title={label} />}
      {rank === 2 && <SilverMedal title={label} />}
      {rank === 3 && <BronzeMedal title={label} />}
      {rank > 3 && <span>{label}</span>}
    </div>
  );
};

export default RankLabel;
