import Modal from "../util/modal";
import React, { useEffect, useState } from "react";
import NbaLineupBuilderLineup from "./nbaLineupBuilderLineup";
import { errorCatcher } from "../util/errors";
import { ReactComponent as Discard } from "../../img/icons-lineup-builder-discard.svg";
import { ReactComponent as Lock } from "../../img/icons-lineup-builder-lock.svg";
import { ReactComponent as Unlock } from "../../img/icons-lineup-builder-unlock.svg";
import PlayerAvatar from "../util/playerAvatar";
import PlayerAvailability from "../players/playerAvailability";
import BoxWithToggle from "../util/boxWithToggle";
import NBAProjectedScore from "../util/nbaProjectionScore";
import { Button } from "../util/button";
import NbaGamesDetails from "./nbaGamesDetails";
import { sorareBasketball } from "../util/sports";
import PlusMinus from "../../img/PlusMinus.svg";

function NbaSuggestionModal(props) {
  let initLockedPlayers = [];
  props.lineup.map((player) => {
    if (player !== null) {
      initLockedPlayers.push(player.card.TokenId);
    }
  });

  const [open, setOpen] = useState(props.open);
  const [lineup, setLineup] = useState(props.lineup);
  const [pickedCards, setPickedCards] = useState(props.pickedCards);
  const [lockedPlayers, setLockedPlayers] = useState(initLockedPlayers);
  const [concept, setConcept] = useState(false);
  const [excludedPlayers, setExcludedPlayers] = useState([]);
  const [preferredProj, setPreferredProj] = useState("regular");
  const [gamesImportance, setGamesImportance] = useState(2);
  const [questionablePlayers, setQuestionablePlayers] = useState(true);
  const [injuredPlayers, setInjuredPlayers] = useState(false);
  const [includePickedPlayers, setIncludePickedPlayers] = useState(false);

  useEffect(() => {
    optimizeLineup();
  }, []);

  const saveLineup = () => {
    props.setLineup(lineup);
    props.saveLineup(lineup);
    setOpen(false);
    props.close();
  };

  const divisionValue = props.divId;

  const addToLockedPlayers = (id) => {
    setLockedPlayers([...lockedPlayers, id]);
  };

  const removeFromLockedPlayers = (id) => {
    setLockedPlayers(lockedPlayers.filter((player) => player !== id));
  };

  const addToExcludedPlayers = (id) => {
    let newExcludedPlayers = [...excludedPlayers, id];
    optimizeLineup(preferredProj, gamesImportance, newExcludedPlayers);
    setExcludedPlayers(newExcludedPlayers);
  };

  const optimizeLineup = (pp, imp, disc, q, i, pick) => {
    pp = pp || preferredProj;
    imp = imp || gamesImportance;
    disc = disc || excludedPlayers;
    if (q === undefined) q = questionablePlayers;
    if (i === undefined) i = injuredPlayers;
    if (pick === undefined) pick = includePickedPlayers;
    const queryParams = new URLSearchParams({});
    lockedPlayers.map((c) => {
      queryParams.append("baseCards", c);
    });
    disc.map((p) => {
      queryParams.append("excludedPlayers", p);
    });
    if (!pick) {
      pickedCards.map((c) => {
        queryParams.append("pickedCards", c);
      });
    }
    if (concept) {
      queryParams.append("concept", "true");
    }
    queryParams.set("gw", props.gw);
    queryParams.set("division", divisionValue);
    queryParams.set("manager", props.manager);
    queryParams.set("preferredProj", pp);
    queryParams.set("gamesImportance", imp);
    queryParams.set("questionablePlayers", q);
    queryParams.set("injuredPlayers", i);
    queryParams.set("includePickedPlayers", pick);
    props
      .fetch(`/suggestions/decision/optimalLineup?${queryParams.toString()}`)
      .then((response) => response.json())
      .then((res) => {
        if (res !== null) {
          if (res.length === 5) {
            setLineup(res);
          } else {
            // fill with nulls
            let newLineup = [...res];
            let index = res.length;
            while (index < 5) {
              newLineup[index] = null;
              index++;
            }
            setLineup(newLineup);
          }
        }
      })
      .catch(errorCatcher());
  };

  const activeButtonClass = "text-brand font-medium py-2 px-2 rounded-lg bg-brand-pastel";
  const inactiveButtonClass = "text-textGrey3 font-medium";

  return (
    <Modal
      size={"w-10/12"}
      maxSize={"w-10/12"}
      open={true}
      close={() => {
        setOpen(false);
        props.close();
      }}
      content={
        <div className={"space-y-4 font-sans"}>
          <div className={"border-b border-grey-e5 text-center space-y-2 pb-4"}>
            <p className={"font-bold text-2xl font-headers"}>Suggested lineup</p>
            <p className={"font-semibold text-textGrey3 text-sm"}>Your optimal lineup based on the Lineup Builder criteria</p>
          </div>
          <div className={"flex flex-row space-x-6"}>
            <div className={"w-5/12"}>
              <NbaLineupBuilderLineup editOnly={true} {...props} lineup={lineup} suggestion={true} />
            </div>
            <div className={"w-7/12"}>
              <table className={"w-full"}>
                <thead className={"text-xs font-semibold text-center"} style={{ color: "#828282" }}>
                  <th className={"w-5/12"}></th>
                  <th>
                    <p>NB of games</p>
                  </th>
                  <th>
                    <p>DWN - UP proj.</p>
                  </th>
                  <th>
                    <p>FLR - CEIL proj.</p>
                  </th>
                  <th>
                    <p>VOL proj.</p>
                  </th>
                  <th className={"w-16"}></th>
                  <th className={"w-16"}></th>
                </thead>
                <tbody>
                  {lineup !== null &&
                    lineup.map((c) => {
                      if (c !== null) {
                        return (
                          <tr className={"border-b border-grey-e5"}>
                            <td>
                              <div className={"flex flex-row space-x-2 py-2"}>
                                <div>
                                  <a href={"/player/" + c.player.PlayerId} target={"_blank"} rel="noreferrer">
                                    <div className={"w-14 h-14 rounded-full"}>
                                      <img src={c.card.AvatarUrl} className={"object-cover w-full h-full rounded-full"} />
                                    </div>
                                  </a>
                                </div>
                                <div className={"self-center flex flex-col space-y-1"}>
                                  <div className={"flex flex-row space-x-2"}>
                                    <p className={"font-semibold text-sm"}>{c.player.MatchName}</p>
                                    <div className={"w-4 h-4 self-center"}>
                                      <PlayerAvailability availability={c.playerStatus} />
                                    </div>
                                    <p className={"font-semibold text-xs self-center"}>{((c.card.Power - 1) * 100).toFixed(1)}%</p>
                                  </div>
                                  <div className={"flex flex-row space-x-1"}>
                                    <p style={{ color: "#6F6F6F" }} className={"font-medium text-sm"}>
                                      {c.player.Position} |
                                    </p>
                                    <div className={"flex flex-row space-x-2 self-center"}>
                                      {c.projections != null &&
                                        c.projections.map((p) => {
                                          let opponent = p.homeTeam.PictureUrl;
                                          if (p.homeTeam.TeamId === c.player.TeamId) {
                                            opponent = p.awayTeam.PictureUrl;
                                          }
                                          return (
                                            <div className={"flex flex-row space-x-1"}>
                                              <div className={"w-4 h-4 self-center"}>
                                                <img className={"w-full h-full object-contain"} src={opponent} />
                                              </div>
                                              <NBAProjectedScore font={"text-xxs"} size={"w-5 h-5"} score={p.projection.score} />
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <NbaGamesDetails games={c.projections} player={c} />
                            </td>
                            <td>
                              <div className={"text-center text-base font-semibold self-center"} style={{ color: "#808080" }}>
                                <span style={{ color: "#E4C856" }} className={"font-semibold"}>
                                  {c.downside.toFixed(0)}
                                </span>{" "}
                                - <span style={{ color: "#60C595" }}>{c.upside.toFixed(0)}</span>
                              </div>
                            </td>
                            <td>
                              <div className={"text-center text-base font-semibold self-center"} style={{ color: "#808080" }}>
                                <span style={{ color: "#E4C856" }} className={"font-semibold"}>
                                  {c.floor.toFixed(0)}
                                </span>{" "}
                                - <span style={{ color: "#60C595" }}>{c.ceiling.toFixed(0)}</span>
                              </div>
                            </td>
                            <td>
                              <div className={"flex flex-row justify-center"}>
                                <div className={"flex flex-row justify-center bg-[#767676] w-auto px-2 py-0.5 space-x-1 rounded-full"}>
                                  <div className={""}>
                                    <img src={PlusMinus} className={"w-full h-full object-contain self-center"} />
                                  </div>
                                  <p className={"text-white font-semibold text-xs"}>{c.detailedStats.stdDev.toFixed(0)}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className={"cursor-pointer mx-auto self-center text-center items-center flex flex-col"}
                                onClick={() => {
                                  addToExcludedPlayers(c.player.PlayerId);
                                }}
                              >
                                <Discard />
                                <p style={{ color: "#E73107" }} className={"font-semibold text-xs text-center"}>
                                  Discard
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={"cursor-pointer mx-auto self-center text-center items-center flex flex-col"}
                                onClick={() => {
                                  if (lockedPlayers.includes(c.card.TokenId)) {
                                    removeFromLockedPlayers(c.card.TokenId);
                                  } else {
                                    addToLockedPlayers(c.card.TokenId);
                                  }
                                }}
                              >
                                {lockedPlayers.includes(c.card.TokenId) && <Lock />}
                                {!lockedPlayers.includes(c.card.TokenId) && <Unlock />}
                                {!lockedPlayers.includes(c.card.TokenId) && <p className={"font-semibold text-xs text-center"}>Lock</p>}
                                {lockedPlayers.includes(c.card.TokenId) && <p className={"font-semibold text-xs text-center"}>Unlock</p>}
                              </div>
                            </td>
                          </tr>
                        );
                      } else {
                        return null;
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className={"flex flex-row justify-center"}>
            <Button onClick={() => saveLineup()} label={"Save lineup"} />
          </div>
          <div className={"pt-8"}>
            <div className={"font-headers pb-6 border-b border-grey-e5"}>
              <p className={"text-center font-semibold text-2xl"}>Customize your settings</p>
            </div>
            <div className={"w-8/12 bg-white mx-auto rounded-lg mt-4 px-6 pb-6"}>
              <div className={"space-y-2 pt-4"}>
                <p className={"text-center text-textGrey3 font-semibold"}>Preferred projection</p>
                <div className={"grid grid-cols-5 border rounded-lg  border-grey-e5 w-full space-x-4 py-1"}>
                  <button
                    onClick={() => {
                      setPreferredProj("floor");
                      optimizeLineup("floor");
                    }}
                    className={preferredProj === "floor" ? activeButtonClass : inactiveButtonClass}
                  >
                    Floor
                  </button>
                  <button
                    onClick={() => {
                      setPreferredProj("downside");
                      optimizeLineup("downside");
                    }}
                    className={preferredProj === "downside" ? activeButtonClass : inactiveButtonClass}
                  >
                    Downside
                  </button>
                  <button
                    onClick={() => {
                      setPreferredProj("regular");
                      optimizeLineup("regular");
                    }}
                    className={preferredProj === "regular" ? activeButtonClass : inactiveButtonClass}
                  >
                    Regular
                  </button>
                  <button
                    onClick={() => {
                      setPreferredProj("upside");
                      optimizeLineup("upside");
                    }}
                    className={preferredProj === "upside" ? activeButtonClass : inactiveButtonClass}
                  >
                    Upside
                  </button>
                  <button
                    onClick={() => {
                      setPreferredProj("ceiling");
                      optimizeLineup("ceiling");
                    }}
                    className={preferredProj === "ceiling" ? activeButtonClass : inactiveButtonClass}
                  >
                    Ceiling
                  </button>
                </div>
              </div>
              <div>
                <div className={"space-y-2 pt-4"}>
                  <p className={"text-center text-textGrey3 font-semibold"}>Importance of number of games</p>
                  <div className={"grid grid-cols-3 border border-grey-e5 rounded-lg w-full space-x-4 py-1"}>
                    <button
                      onClick={() => {
                        optimizeLineup(preferredProj, 1);
                        setGamesImportance(1);
                      }}
                      className={gamesImportance === 1 ? activeButtonClass : inactiveButtonClass}
                    >
                      Not important
                    </button>
                    <button
                      onClick={() => {
                        optimizeLineup(preferredProj, 2);
                        setGamesImportance(2);
                      }}
                      className={gamesImportance === 2 ? activeButtonClass : inactiveButtonClass}
                    >
                      Moderately important
                    </button>
                    <button
                      onClick={() => {
                        optimizeLineup(preferredProj, 3);
                        setGamesImportance(3);
                      }}
                      className={gamesImportance === 3 ? activeButtonClass : inactiveButtonClass}
                    >
                      Very important
                    </button>
                  </div>
                </div>
              </div>
              <div className={"grid grid-cols-2 pt-4 gap-x-8 gap-y-4"}>
                <div className={"py-1.5 bg-white rounded-lg border border-grey-e5"}>
                  <BoxWithToggle
                    label={"Include questionable players"}
                    isActive={questionablePlayers}
                    setActive={() => {
                      optimizeLineup(preferredProj, gamesImportance, excludedPlayers, !questionablePlayers);
                      setQuestionablePlayers(!questionablePlayers);
                    }}
                  />
                </div>
                <div className={"py-1.5 bg-white rounded-lg border border-grey-e5"}>
                  <BoxWithToggle
                    label={"Include out players"}
                    isActive={injuredPlayers}
                    setActive={() => {
                      optimizeLineup(preferredProj, gamesImportance, excludedPlayers, questionablePlayers, !injuredPlayers);
                      setInjuredPlayers(!injuredPlayers);
                    }}
                  />
                </div>
                <div className={"py-1.5 bg-white rounded-lg border border-grey-e5"}>
                  <BoxWithToggle
                    label={"Include picked players"}
                    isActive={includePickedPlayers}
                    setActive={() => {
                      optimizeLineup(
                        preferredProj,
                        gamesImportance,
                        excludedPlayers,
                        questionablePlayers,
                        injuredPlayers,
                        !includePickedPlayers,
                      );
                      setIncludePickedPlayers(!includePickedPlayers);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default NbaSuggestionModal;
