import React from "react";
import LineSeparator from "../../img/line-separator.svg";
import { sorareBaseball, sorareBasketball, sorareFootball } from "./sports";

function SportsPickerInter(props) {
  const picked = props.sport;
  const rounded = props.rounded || "rounded";

  const pickedStyle = "text-brand";
  const notPickedStyle = "text-[#666666]";
  const noBaseball = props.noBaseball;
  return (
    <div className={"border border-[#E6E6E6] text-center font-semibold bg-white flex flex-row space-x-6 text-base px-6 py-2 " + rounded}>
      <div className={"cursor-pointer"} onClick={() => props.change(sorareFootball)}>
        <p className={picked === sorareFootball ? pickedStyle : notPickedStyle}>Football</p>
      </div>
      <img src={LineSeparator} />
      <div className={"cursor-pointer"} onClick={() => props.change(sorareBasketball)}>
        <p className={picked === sorareBasketball ? pickedStyle : notPickedStyle}>Basketball</p>
      </div>
      {!noBaseball && <img src={LineSeparator} />}
      {!noBaseball && (
        <div className={"cursor-pointer"} onClick={() => props.change(sorareBaseball)}>
          <p className={picked === sorareBaseball ? pickedStyle : notPickedStyle}>Baseball</p>
        </div>
      )}
      {props.showAll === true && <img src={LineSeparator} />}
      {props.showAll === true && (
        <div className={"cursor-pointer"} onClick={() => props.change("all")}>
          <p className={picked === "all" ? pickedStyle : notPickedStyle}>All</p>
        </div>
      )}
    </div>
  );
}

export default SportsPickerInter;
