import React, { useEffect } from "react";
import Home from "./home";
import LandingPage from "./landingPage";
import { withUser } from "../../userContext";
import { withRouter } from "react-router-dom";

function HomeWrapper(props) {
  useEffect(() => {
    if (props.user.username && (props.user.sorareSlug === undefined || props.user.sorareSlug === null || props.user.sorareSlug === "")) {
      console.log("DEPRECATED???");
      props.history.push("/deprecatedAccount");
    }
  }, [props.user]);

  if (props.user.username) {
    return <Home />;
  } else {
    return <LandingPage />;
  }
}

export default withRouter(withUser(HomeWrapper));
