import React, { useCallback, useState } from "react";
import { LUBFootballFilters } from "./footballFilters";
import { LUBFootballPreferences } from "./footballPreferences";

export const LUBFilterAndPreferencesToggle = (props) => {
  const { onApplyFilters, division, pickedPosition, gw, draftId, targetUserId, tier } = props;
  const [focus, setFocus] = useState("");

  const onOpenChange = useCallback((type, toOpen) => {
    if (toOpen) {
      setFocus(type);
    } else {
      setFocus("");
    }
  }, []);

  return (
    <div className={"flex flex-row gap-2"}>
      <div className={"self-center h-10"}>
        <LUBFootballFilters
          gw={gw}
          draftId={draftId}
          targetUserId={targetUserId}
          pickedPosition={pickedPosition}
          preferences={props.preferences}
          onApplyFilters={onApplyFilters}
          division={division}
          open={focus === "filters"}
          onOpenChange={(val) => onOpenChange("filters", val)}
        />
      </div>
      <div className={"self-center h-10"}>
        <LUBFootballPreferences
          division={division}
          open={focus === "preferences"}
          tier={tier}
          onOpenChange={(val) => {
            onOpenChange("preferences", val);
            props.onOpenPreferences && props.onOpenPreferences();
          }}
          preferences={props.preferences}
          onPreferenceChange={props.onPreferenceChange}
          onReset={props.onResetPreferences}
        />
      </div>
    </div>
  );
};
