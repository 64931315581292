import { errorCatcher } from "../util/errors";
import React, { useEffect, useRef, useState } from "react";
import { withUser } from "../../userContext";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { isMobile } from "react-device-detect";
import { MobileModale } from "../util/mobileModale";
import { XIcon } from "@heroicons/react/outline";
import Spinner from "../loader/spinner";
import { useElementSize } from "usehooks-ts";

export const allStarDefaultCompetition = {
  id: "all",
  name: "All Star",
  iconURL: " https://storage.googleapis.com/soraredata-pictures/competition/all-star.svg ",
};

export const initialEligibilityCompetitionValue = {
  [allStarDefaultCompetition.id]: true,
};

export const EligibilitySorareCompetitionsFilter = withUser((props) => {
  const { selected, widthPanel, displayOptions } = props;
  const multiple = props.multiple !== undefined ? props.multiple : true;
  const width = props.width || "w-full";
  const zIndex = props.zIndex || "z-20";
  const maxWidth = props.maxWidth || "";
  const minWidth = props.minWidth || "";
  const borders = displayOptions?.borders || false;
  const borderOnFocus = displayOptions?.borderOnFocus || false;
  const rounded = displayOptions?.rounded || "rounded-md";
  const shadow = displayOptions?.shadow || "";
  const maxHeightPanel = props.maxHeightPanel || "23em";
  const [newCompetitions, setNewCompetitions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [abortController, setAbortController] = useState(new AbortController());
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);
  const [refParent, { width: widthParent }] = useElementSize();

  const loadCompetitions = () => {
    setLoading(true);
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    props
      .fetch("/apiv2/cards/filters/competitionEligibility", {
        signal: newAbortController.signal,
      })
      .then((response) => response.json())
      .then((res) => {
        const newCompetitions = [];
        const legacyCompetitions = [];
        res?.filters?.forEach((comp) => {
          if (comp && !comp.isLegacy) {
            newCompetitions.push(comp);
          }
        });
        setNewCompetitions(newCompetitions);
        setLoading(false);
      })
      .catch(errorCatcher(() => setLoading(false)));
  };

  useEffect(() => {
    loadCompetitions();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onSelect = (values) => {
    if (!multiple) {
      setFocus(false);
    }
    props.onSelect && props.onSelect(values);
  };

  const onUnselect = (values) => {
    props.onUnselect && props.onUnselect(values);
  };

  const onChangeFocus = () => {
    const newFocus = !focus;
    if (newFocus) {
      onFocus();
    } else {
      onBlur();
    }
  };

  const onFocus = () => {
    setFocus(true);
    props.onFocus && props.onFocus();
  };

  const onBlur = () => {
    setFocus(false);
    props.onBlur && props.onBlur();
  };

  const selectedCompetitions = [];
  newCompetitions?.forEach((comp) => {
    if (selected[comp.id] !== undefined) {
      selectedCompetitions.push(comp);
    }
  });
  const selectedCompetitionLabel =
    selectedCompetitions.length > 0 ? selectedCompetitions.map((comp) => comp.name).join(", ") : allStarDefaultCompetition.name;

  return (
    <div className={`relative h-full ${zIndex} ${width} ${minWidth} ${maxWidth}`} ref={ref}>
      <div ref={refParent} className={"w-full h-full"}>
        <button
          onClick={onChangeFocus}
          className={`h-full ${width} text-md my-auto bg-surface-container ${shadow} px-3 py-2 flex flex-row justify-between gap-2 ${
            borderOnFocus ? "hover:border-primary" : borders && "border border-transparent-inverse-surface-low border-opacity-10"
          } ${rounded}`}
        >
          <span className={"my-auto w-5/6 truncate text-left font-medium leading-5 text-on-surface"}>{selectedCompetitionLabel}</span>
          <div className={"flex flex-row my-auto gap-2 w-1/6 justify-end"}>
            <div className={"flex flex-col justify-center"}>
              <SortArrowIcon className={`fill-on-surface h-2.5 w-2.5 ${focus ? "transform rotate-180" : ""}`} />
            </div>
          </div>
        </button>
      </div>
      {isMobile && (
        <MobileModale open={focus} close={onBlur}>
          <div className={"h-full bg-surface-container rounded-t-lg"}>
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <div className={"flex flex-row justify-between px-4 py-4"}>
                <h3 className={"font-headers text-on-surface font-semibold m-0"}>Divisions</h3>
                <div className={"flex flex-row gap-3"}>
                  <XIcon
                    className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
                    aria-hidden="true"
                    onClick={() => setFocus(false)}
                  />
                </div>
              </div>
            </div>
            <ul className={"max-h-[80vh] overflow-y-auto"}>
              <CompetitionsItems
                loading={loading}
                selected={selected}
                newCompetitions={newCompetitions}
                onSelect={onSelect}
                onUnselect={onUnselect}
              />
            </ul>
          </div>
        </MobileModale>
      )}
      {focus && !isMobile && (
        <div
          style={widthPanel ? {} : { width: (widthParent || 200) + "px" }}
          className={`absolute bg-surface-container rounded-md mt-2 shadow overflow-hidden ${widthPanel ? widthPanel : ""}`}
        >
          <ul className={" h-full overflow-y-scroll w-full"} style={{ maxHeight: maxHeightPanel }}>
            <CompetitionsItems
              loading={loading}
              selected={selected}
              newCompetitions={newCompetitions}
              onSelect={onSelect}
              onUnselect={onUnselect}
            />
          </ul>
        </div>
      )}
    </div>
  );
});

const CompetitionsItems = (props) => {
  const { loading, newCompetitions, selected, onSelect, onUnselect } = props;
  return (
    <>
      {loading && newCompetitions === null && (
        <div className={"flex items-center justify-center p-2"}>
          <Spinner />
        </div>
      )}
      <ul>
        <CompetitionsItem
          key={"all-star"}
          competition={allStarDefaultCompetition}
          onSelect={onSelect}
          onUnselect={onUnselect}
          isSelected={selected[allStarDefaultCompetition.id] !== undefined}
        />
      </ul>
      <ul>
        {newCompetitions?.map((comp, i) => {
          const isSelected = selected[comp.id] !== undefined;
          return <CompetitionsItem key={i} competition={comp} onSelect={onSelect} onUnselect={onUnselect} isSelected={isSelected} />;
        })}
      </ul>
    </>
  );
};

const CompetitionsItem = (props) => {
  const { competition, onSelect, onUnselect, isSelected } = props;
  const isCountryLogo = competition.iconURL && competition.iconURL.includes("flags");
  return (
    <li
      key={competition.id}
      onClick={() => (isSelected ? onUnselect && onUnselect(competition.id) : onSelect && onSelect(competition.id))}
      className={` border-b border-transparent-inverse-surface-low border-opacity-10 hover:bg-containers-surface-focus-active-primarytint cursor-pointer`}
    >
      <div
        className={`w-full h-full px-3 py-3 ${
          isSelected ? "font-semibold text-primary bg-containers-surface-focus-active-primarytint" : "text-on-surface"
        }`}
      >
        <button value={competition.name} className={`w-full cursor-pointer text-left text-sm flex flex-row items-center justify-between`}>
          <div className={"flex flex-row gap-3"}>
            {competition.iconURL && (
              <span>
                <img className={`h-5 w-5 ${isCountryLogo ? "rounded-full" : ""}`} src={competition.iconURL} />
              </span>
            )}
            <span className={"truncate items-start my-auto"}>{competition.name}</span>
          </div>
        </button>
      </div>
    </li>
  );
};
