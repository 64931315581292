import LimitedPoint from "../../img/limited-point.png";
import RarePoint from "../../img/rare-point.png";
import SuperRarePoint from "../../img/sr-point.png";
import UniquePoint from "../../img/unique-point.png";
import CommonPoint from "../../img/common-point.svg";
import CustomSeriesPoint from "../../img/custom-series-point.svg";
import React from "react";
import CardCountEntry from "../cards/cardCountEntry";

function CardCount(props) {
  const anchor = props.anchor;
  const rareCount = props.rareCount;
  const superRareCount = props.superRareCount;
  const uniqueCount = props.uniqueCount;
  const limitedCount = props.limitedCount;
  const commonCount = props.commonCount;
  const customSeriesCount = props.customSeriesCount;
  let space = props.space || "space-x-2";
  return (
    <div className={"flex flex-row " + space}>
      {commonCount > 0 && (
        <CardCountEntry img={CommonPoint} count={commonCount} anchor={anchor} color={"common"} tip={"Total common cards"} />
      )}
      {customSeriesCount > 0 && (
        <CardCountEntry
          img={CustomSeriesPoint}
          count={customSeriesCount}
          anchor={anchor}
          color={"custom-series"}
          tip={"Total custom series cards"}
        />
      )}
      {limitedCount > 0 && (
        <CardCountEntry img={LimitedPoint} count={limitedCount} anchor={anchor} color={"limited"} tip={"Total limited cards"} />
      )}
      {rareCount > 0 && <CardCountEntry img={RarePoint} count={rareCount} anchor={anchor} color={"rare"} tip={"Total rare cards"} />}
      {superRareCount > 0 && (
        <CardCountEntry img={SuperRarePoint} count={superRareCount} anchor={anchor} color={"super-rare"} tip={"Total super rare cards"} />
      )}
      {uniqueCount > 0 && (
        <CardCountEntry img={UniquePoint} count={uniqueCount} anchor={anchor} color={"unique"} tip={"Total unique cards"} />
      )}
    </div>
  );
}

export default CardCount;
