import React from "react";
import UnknownPlayer from "./unknownPlayer";
import { sorareBaseball, sorareBasketball } from "./sports";

function PlayerAvatar(props) {
  let player = props.player;
  let halo = props.halo || "bg-outline-variant";
  const border = props.border || "";
  if (props.scarcity) {
    halo = "bg-" + props.scarcity.toLowerCase() + "-halo";
    if (props.scarcity === "SUPER RARE") {
      halo = "bg-sr-halo";
    }
  }

  if (props.linedUpPlayer) {
    halo = "bg-player-halo";
    if (props.sport === sorareBasketball || props.sport === sorareBaseball) {
      halo = "border-2 border-super-rare";
    }
  } else {
    if ((props.sport === sorareBasketball || props.sport === sorareBaseball) && !props.fullOpacity) {
      halo = "bg-opacity-50";
    }
  }
  let link = player ? "/player/" + player.PlayerId : "";
  if (props.noLink) {
    link = undefined;
  }
  let size = "w-14 h-14";
  if (props.size) {
    size = props.size;
  }
  let imgSize = "w-12 h-12";
  if (props.imgSize) {
    imgSize = props.imgSize;
  }
  let imgStyle = `${imgSize} ${border} justify-center self-center rounded-full object-contain pt-1 ${halo}`;
  if (props.sport === sorareBasketball || props.sport === sorareBaseball) {
    imgStyle = `${imgSize} ${border} justify-center self-center rounded-full object-cover ${halo}`;
  }
  const isCaptain = props.isCaptain;
  const captainBorder = props.captainBorder || "border-2 border-surface-container-low";
  return (
    <a href={link}>
      <div className={`${isCaptain ? "relative" : ""} flex flex-row justify-center self-center`}>
        {isCaptain && (
          <div className={"absolute -left-2 -top-2 h-full"}>
            <div className={`w-5 h-5 flex items-center justify-center rounded-full p-0.5 bg-yellow text-black font-bold  ${captainBorder}`}>
              <div className={"w-full text-center inline-flex leading-none"} style={{ fontSize: "0.750rem", marginRight: "-0.25rem" }}>
                C
              </div>
            </div>
          </div>
        )}
        <div className={size + " flex flex-row justify-center self-center"}>
          {player && player.Avatar !== "" ? <img className={imgStyle} src={player.Avatar} /> : <UnknownPlayer size={imgSize} bg={halo} />}
        </div>
      </div>
    </a>
  );
}

export default PlayerAvatar;
