import React, { Component } from "react";
import { Box, Text, Heading } from "grommet";
import { withUser } from "../../userContext";
import Spinner from "../loader/spinner";
import Bundle from "./bundle";
import { getTeamMapNextGw } from "../util/teamMap";
import { errorCatcher } from "../util/errors";

class BundleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bundles: [],
      nextGwPlayers: [],
    };
  }

  getBundles = () => {
    this.props
      .fetch("/apiv2/auctions/bundles")
      .then((response) => response.json())
      .then((res) => {
        this.setState({ bundles: res });
      })
      .catch(errorCatcher());
  };

  componentDidMount() {
    this.getBundles();
    getTeamMapNextGw(this.props).then((r) => this.setState({ nextGwPlayers: r }));
    this.interval = setInterval(() => {
      this.getBundles();
    }, 45000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const bundles = this.state.bundles;
    return (
      <Box>
        <Box align={"center"}>
          <Heading textAlign={"center"} level={"2"} color={"brand"}>
            Bundle auctions
          </Heading>
        </Box>

        {bundles !== null && bundles.length > 0 ? (
          <Box gap={"medium"}>
            {bundles.map((bundle, index) => {
              return <Bundle bundle={bundle} where={"list"} nextGwPlayers={this.state.nextGwPlayers} />;
            })}
          </Box>
        ) : bundles === null ? (
          <Box align={"center"} margin={{ top: "small" }}>
            <Text>No bundle.</Text>
          </Box>
        ) : (
          <Box align={"center"} margin={{ top: "large" }}>
            <Spinner />
          </Box>
        )}
      </Box>
    );
  }
}

export default withUser(BundleList);
