import ConfirmModal from "../util/confirmModal";
import React, { useState } from "react";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";

const DeleteWatchlistModal = (props) => {
  const { watchlist, onDelete, open, onCancel } = props;
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState("");
  const connectedUserIsOwner = watchlist.owner === props.user.sorareSlug;

  const onConfirmDeleteWatchlist = () => {
    if (connectedUserIsOwner) {
      setDeleting(true);
      setError("");
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}`, { method: "DELETE" })
        .then((response) => {
          if (response.status >= 500) {
            setError("Internal error, please retry later");
          } else if (response.status === 204) {
            onDelete && onDelete(watchlist);
            setDeleting(false);
          } else {
            const res = response.json();
            if (res.error !== undefined) {
              setError(res.error);
            } else {
              setError("");
              onDelete && onDelete(watchlist);
            }
            setDeleting(false);
          }
        })
        .catch(errorCatcher());
    }
  };

  return (
    <ConfirmModal
      title={"Deletion confirmation"}
      error={error}
      message={`Do you want to delete watchlist "${watchlist.name}"? This action is irreversible.`}
      confirming={deleting}
      open={open}
      onConfirm={onConfirmDeleteWatchlist}
      onCancel={onCancel ? () => onCancel(watchlist) : undefined}
    />
  );
};

export default withUser(DeleteWatchlistModal);
