import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import InfiniteScroll from "react-infinite-scroll-component";
import AddLineupLine from "../../img/builder-pick.svg";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import NBAProjectedScore from "../util/nbaProjectionScore";
import LineSeparator from "../../img/line-separator.svg";
import PlayerAvailability from "../players/playerAvailability";
import { withUser } from "../../userContext";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import PlayerRollingAverage from "../players/playerRollingAverage";
import HomeIcon from "../../img/homeIcon.svg";
import AwayIcon from "../../img/awayIcon.svg";
import { SeasonTag } from "./lineupBuilderTable";
import { currentBaseballSeason } from "../util/seasons";

function BaseballLineupBuilderTableGame(props) {
  const game = props.game;
  if (game) {
    let isHome = true;
    if (game.awayTeam.TeamId === game.projection.teamId) {
      isHome = false;
    }
    let oppLogo = game.awayTeam.PictureUrl;
    if (!isHome) {
      oppLogo = game.homeTeam.PictureUrl;
    }
    let proj = game.projection.score;
    return (
      <div className={"flex flex-row space-x-1 justify-center"}>
        <div className={"self-center"}>
          <img className={"h-4 w-4"} src={isHome ? HomeIcon : AwayIcon} />
        </div>
        <div className={"self-center"}>
          <img className={"h-5 w-5"} src={oppLogo} />
        </div>
        <div className={"flex flex-row justify-center self-center"}>
          <div className={"self-center"}>
            <NBAProjectedScore font={"text-xxs"} scale={"baseball"} bold={"font-bold"} size={"w-5 h-5"} fixed={0} score={proj} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p className={"text-center text-sm font-semibold"}>-</p>
      </div>
    );
  }
}

function BaseballLineupBuilderTable(props) {
  const players = props.players;

  const [allPlayers, setAllPlayers] = useState(props.players);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortWhat, setSortWhat] = useState("L10");

  const powerAdjustedAverages = props.powerAdj;
  const odds = props.odds;
  const position = props.position;
  const concept = props.concept;

  const sortData = (w, keepOrder, pl) => {
    let howToSort = 0;
    if (w === sortWhat) {
      if (!keepOrder) {
        howToSort = sortOrder * -1;
        setSortOrder(howToSort);
      } else {
        howToSort = sortOrder;
      }
    } else {
      howToSort = -1;
      setSortOrder(-1);
    }
    setSortWhat(w);
    pl = pl || allPlayers;
    let breakdown = [...pl];
    let stat1 = "";
    let stat2 = "";
    let nbOfStat = 1;
    if (w === "L10") {
      stat1 = "average";
      stat2 = "AverageNoDnp";
      nbOfStat = 2;
    } else if (w === "L10Min") {
      stat1 = "average";
      stat2 = "GamesPlayed";
      nbOfStat = 2;
    } else if (w === "totalProjected") {
      stat1 = "totalProjected";
    } else if (w === "diffPerf") {
      stat1 = "projectionDiff";
    } else if (w === "bestMinutes") {
      stat1 = "bestMinutes";
    } else if (w === "minutesDiff") {
      stat1 = "minutesDiff";
    } else if (w === "bestProjection") {
      stat1 = "bestProjection";
    } else if (w === "upside") {
      stat1 = "upside";
    } else if (w === "ceiling") {
      stat1 = "ceiling";
    } else if (w === "vol") {
      stat1 = "detailedStats";
      stat2 = "stdDev";
      nbOfStat = 2;
    } else if (w === "games") {
      stat1 = "nbGames";
    } else {
      nbOfStat = 0;
    }
    if (w.startsWith("proj")) {
      const projNumber = +w.split("-")[1];
      breakdown.sort(function (a, b) {
        let aScore = -1;
        let bScore = -1;
        if (a.projections !== null && a.projections.length >= projNumber + 1) {
          aScore = a.projections[projNumber]?.projection?.score || 0;
        }
        if (b.projections !== null && b.projections.length >= projNumber + 1) {
          bScore = b.projections[projNumber]?.projection?.score || 0;
        }
        if (aScore > bScore) {
          return howToSort;
        } else if (aScore < bScore) {
          return howToSort * -1;
        } else {
          return 0;
        }
      });
    } else if (w === "totalProjected") {
      breakdown.sort((a, b) => {
        let aScore = a.totalProjected;
        let bScore = b.totalProjected;
        if (powerAdjustedAverages) {
          aScore = a.totalProjected * a.card.Power;
          bScore = b.totalProjected * b.card.Power;
        }
        if (aScore > bScore) {
          return howToSort;
        } else if (aScore < bScore) {
          return howToSort * -1;
        } else {
          return 0;
        }
      });
    } else if (nbOfStat === 1) {
      breakdown.sort(function (a, b) {
        if (a[stat1] < b[stat1]) {
          return howToSort * -1;
        } else if (a[stat1] > b[stat1]) {
          return howToSort;
        }
        return 0;
      });
    } else if (nbOfStat === 2) {
      breakdown.sort(function (a, b) {
        if (a[stat1][stat2] < b[stat1][stat2]) {
          return howToSort * -1;
        } else if (a[stat1][stat2] > b[stat1][stat2]) {
          return howToSort;
        }
        return 0;
      });
    } else if (w === "gamesNb") {
      breakdown.sort(function (a, b) {
        if (a.projections === null) {
          return howToSort * -1;
        }
        if (b.projections === null) {
          return howToSort;
        }
        if (a.projections.length < b.projections.length) {
          return howToSort * -1;
        } else if (a.projections.length > b.projections.length) {
          return howToSort;
        }
        return 0;
      });
    }

    setFilteredPlayers(breakdown);

    filterPlayers(breakdown);
    ReactTooltip.rebuild();
  };

  const filterPlayers = (pl) => {
    if (pl !== null && pl.length > 0) {
      let newPlayers = [...pl];
      if (props.onlyMyPlayers === true) {
        newPlayers = newPlayers.filter((player) => {
          return player.ownedCard.TokenId !== "";
        });
      }
      if (props.showEligible) {
        newPlayers = newPlayers.filter((player) => {
          return player.average.AverageNoDnp <= props.eligibleCap;
        });
      }
      setFilteredPlayers(newPlayers);
      if (newPlayers !== null) {
        setDisplayedPlayers(newPlayers.slice(0, 40));
      }
    } else {
      setFilteredPlayers([]);
      setDisplayedPlayers([]);
    }
  };

  useEffect(() => {
    setAllPlayers(props.players);
    sortData(sortWhat, true, props.players);
  }, [props.players]);

  useEffect(() => {
    sortData(sortWhat, true, props.players);
  }, [props.onlyMyPlayers, props.showEligible, props.refresh]);

  if (filteredPlayers === null || displayedPlayers === null) {
    return (
      <div>
        <p className={"text-center font-semibold text-sm"}>No players available.</p>
      </div>
    );
  }

  const divisionType = props.division?.divisionType;
  const allOffense = divisionType?.includes("all-offense");
  const allDefense = divisionType?.includes("all-defense");
  const powerAdj = props.powerAdj;
  const divisionWithSeasonRule = divisionType?.includes("champion");

  return (
    <div>
      <InfiniteScroll
        next={() => {
          setDisplayedPlayers(filteredPlayers.slice(0, displayedPlayers.length + 40));
          ReactTooltip.rebuild();
        }}
        hasMore={displayedPlayers.length < filteredPlayers.length}
        dataLength={displayedPlayers.length}
        scrollThreshold={0.7}
        scrollableTarget={"table_scroll"}
      >
        <table className={"w-full z-0"}>
          <thead className={"bg-builder-header text-black-6 text-xxs font-semibold"}>
            <tr>
              <th className={"w-1/24 text-center py-3 cursor-pointer"}>Add to lineup</th>
              <th className={"w-5/12 rounded-tl-lg"}>Player</th>
              <th className={"w-1/24 text-center cursor-pointer"} data-tip={"L15"} onClick={() => sortData("L10")}>
                L15
              </th>
              <th
                className={"w-1/24 text-center py-3 cursor-pointer"}
                data-tip={"Projected score points"}
                onClick={() => sortData("totalProjected")}
              >
                Proj. points
              </th>
              <th className={"w-1/64"}></th>
              <th className={"w-1/24 text-center cursor-pointer"} data-tip={"Nb of games"} onClick={() => sortData("games")}>
                Nb of games
              </th>
              <th className={"w-1/12 text-center cursor-pointer"} data-tip={"Projection of game #1"} onClick={() => sortData("proj-0")}>
                GM 1 Proj.
              </th>
              <th className={"w-1/12 text-center cursor-pointer"} data-tip={"Projection of game #2"} onClick={() => sortData("proj-1")}>
                GM 2 Proj.
              </th>
              <th className={"w-1/12 text-center cursor-pointer"} data-tip={"Projection of game #3"} onClick={() => sortData("proj-2")}>
                GM 3 Proj.
              </th>
              <th className={"w-1/12 text-center cursor-pointer"} data-tip={"Projection of game #4"} onClick={() => sortData("proj-3")}>
                GM 4 Proj.
              </th>
              <th className={"w-1/12 text-center cursor-pointer"} data-tip={"Projection of game #5"} onClick={() => sortData("proj-4")}>
                GM 5 Proj.
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedPlayers.map((entry, index) => {
              const player = entry.player;
              const card = entry.card;

              let projected = entry?.totalProjected;
              if (powerAdj) {
                projected = entry?.totalProjected * entry.card?.Power;
              }

              let power = (card.Power * 100 - 100).toFixed(1) + "%";
              let owned = false;
              if (concept) {
                if (entry.ownedCard.Power > 0) {
                  power = (entry.ownedCard.Power * 100 - 100).toFixed(1) + "%";
                  owned = true;
                }
              } else {
                owned = true;
              }
              let powerColor = "text-limited";
              if (card.Scarcity === "RARE") {
                powerColor = "text-rare";
              } else if (card.Scarcity === "SUPER RARE") {
                powerColor = "text-super-rare";
              } else if (card.Scarcity === "UNIQUE") {
                powerColor = "text-unique";
              } else if (card.Scarcity === "COMMON") {
                powerColor = "text-brand-black";
              }

              let canPick = props.canPick && props.checkPlayer(player.PlayerId);
              if (!props.checkPlayer(player.PlayerId) && !props.showPicked) {
                return null;
              }
              if (!props.checkCard(card.TokenId) && !props.showPicked) {
                return null;
              }
              let grayscale = "";
              if (!owned) {
                grayscale = "grayscale-70";
              }

              let subTitle = player.Position + " | " + player.Age;
              let spacing = "space-y-0.5";
              if (concept) {
                spacing = "space-y-1";
              }
              //let rt = entry.priceDetails.latest;
              //let bmp = entry.priceDetails.floor;
              let average = 0;
              if (entry.priceDetails.average) {
                average = entry.priceDetails.average;
              }

              const highlightSeason = card.Season === currentBaseballSeason && divisionWithSeasonRule;

              return (
                <>
                  <tr key={card.TokenId} className={"bg-white border-b border-grey-e5"}>
                    <td className={"border-r border-grey-e5"}>
                      {props.checkPlayer(entry.player.PlayerId) && (
                        <div className={"space-y-2 flex flex-col items-center"}>
                          <div className={"w-8 h-8 items-center justify-center self-center align-middle flex"}>
                            <img
                              className={"cursor-pointer w-full h-full object-contain"}
                              src={AddLineupLine}
                              onClick={() => props.pickCard(entry)}
                            />
                          </div>
                          {!props.checkCard(entry.card.TokenId) && (
                            <p className={"text-center font-semibold text-xxs text-textGrey3"}>Picked</p>
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      <div className={"flex flex-row justify-start space-x-2 py-2 pl-2 self-center"}>
                        <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                          <div className={"w-12 h-12 rounded-full"}>
                            <img src={card.AvatarUrl} className={"object-cover w-full h-full rounded-full " + grayscale} />
                          </div>
                        </a>

                        <div className={"flex flex-col self-center " + spacing}>
                          <div className={"flex-row space-x-1 flex"}>
                            <div className={"text-sm font-semibold"}>
                              <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                                <p>{player.MatchName}</p>
                              </a>
                            </div>
                            <div className={"w-3 self-center"}>
                              <PlayerAvailability availability={entry.playerStatus} />
                            </div>
                            <div className={"self-center"}>
                              <p className={"text-xs font-bold self-center "}>{power}</p>
                            </div>
                          </div>
                          {!concept && (
                            <div className={"flex flex-row space-x-1"}>
                              <p className={"text-xs text-textGrey3 font-medium self-center"}>{subTitle}</p>
                              <span className={"px-2 flex items-center"}>
                                <SeasonTag season={card.Season} highlight={highlightSeason} />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col items-center w-10 h-10 self-center"}>
                        <div className={"w-full h-full self-center"}>
                          <BaseballScoreCircle precision={0} size={"120"} scale={"baseball"} score={entry?.average?.AverageNoDnp} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center self-center"}>
                        <div className={"self-center"}>
                          <NBAProjectedScore
                            font={"text-xs"}
                            bold={"font-bold"}
                            size={"w-7 h-7"}
                            fixed={projected >= 10 ? 0 : 1}
                            score={projected}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <img className={"w-3 h-8"} src={LineSeparator} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center self-center"}>
                        <div className={"flex flex-col rounded bg-grey-e5 px-2 py-1 self-center"}>
                          <p className={"text-center text-black-4D text-xs font-semibold"}>{entry.nbGames}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <BaseballLineupBuilderTableGame
                        game={entry?.projections !== null && entry.projections.length >= 1 ? entry.projections[0] : undefined}
                      />
                    </td>
                    <td>
                      <BaseballLineupBuilderTableGame
                        game={entry?.projections !== null && entry.projections.length >= 2 ? entry.projections[1] : undefined}
                      />
                    </td>
                    <td>
                      <BaseballLineupBuilderTableGame
                        game={entry?.projections !== null && entry.projections.length >= 3 ? entry.projections[2] : undefined}
                      />
                    </td>
                    <td>
                      <BaseballLineupBuilderTableGame
                        game={entry?.projections !== null && entry.projections.length >= 4 ? entry.projections[3] : undefined}
                      />
                    </td>
                    <td>
                      <BaseballLineupBuilderTableGame
                        game={entry?.projections !== null && entry.projections.length >= 5 ? entry.projections[4] : undefined}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
      <ReactTooltip />
    </div>
  );
}

export default withUser(BaseballLineupBuilderTable);
