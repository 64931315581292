import React from "react";
import CardCount from "../players/cardCount";
import PlayerAvatar from "../util/playerAvatar";
import GamePreview from "./gamePreview";
import ScoreEllipse from "../util/scoreEllipse";
import GetDaIcons from "../util/daIcons";
import { sorareBasketball, sorareFootball } from "../util/sports";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import ordinal_suffix_of from "../util/ordinalNumber";

function ImpactPlayer(props) {
  if (props.sport === sorareFootball) {
    let player = props.player.player;
    let stats = props.player.stats;
    let game = props.player.game;
    let game_stats = props.player.game_stats;
    if (game_stats.game.GameId !== "") {
      game.game = game_stats.game;
    }
    let daIcons = GetDaIcons(game_stats.stats, '"w-5 h-5 self-center rounded-full"');

    return (
      <div>
        <div className={"flex flex-row space-x-4 px-4 py-3 rounded-lg"}>
          <div className={"w-2/12 self-center"}>
            <PlayerAvatar player={player} />
          </div>
          <div className={"self-center flex flex-col w-6/12"}>
            <div className={"flex flex-row space-x-4"}>
              <p className={"text-base font-semibold"}>
                <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                  {player.DisplayName}
                </a>
              </p>
              {game_stats.stats.PlayerId !== "" && (
                <div className={"flex flex-row space-x-2 self-center"}>
                  <ScoreEllipse
                    size={"text-sm"}
                    game={game.game}
                    power={1}
                    minsPlayed={game_stats.stats.mins_played}
                    score={game_stats.stats.SO5Score}
                    onGameSheet={game_stats.stats.OnGameSheet}
                  />
                  {daIcons && <div className={"flex flex-row justify-center mx-auto z-10 -space-x-0.5"}>{daIcons}</div>}
                </div>
              )}
            </div>

            {game.game.GameId !== "" && (
              <div className={"flex flex-row justify-start"}>
                <GamePreview size={"text-sm"} noMargin={true} game={game} />
              </div>
            )}
            {game.game.GameId === "" && (
              <div className={"flex flex-row justify-start"}>
                <p className={"text-sm font-medium"}>No game</p>
              </div>
            )}
          </div>
          <div className={"self-center flex flex-col items-end col-span-4 w-4/12"}>
            <p data-tip={"Total power of the player cards played in this division"} className={"font-semibold text-base"}>
              {stats.total_power.toFixed(1)}
              <span className={"text-sm"}>
                x ({((stats.total_power * 100) / props.gw.total_power).toFixed(2)}
                %)
              </span>
            </p>
            <div className={"flex flex-row justify-end"}>
              <CardCount
                limitedCount={stats.limited}
                commonCount={stats.commons}
                rareCount={stats.rares}
                superRareCount={stats.super_rares}
                uniqueCount={stats.uniques}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.sport === sorareBasketball) {
    let player = props.player.player;
    let stats = props.player.stats;
    let game = props.player.game;
    let gameStats = props.player.gameStats;
    if (gameStats?.gameId !== "") {
      game = gameStats;
    }
    const divStats = props.gw;

    console.log(props.player);

    return (
      <div>
        <div className={"flex flex-row space-x-4 px-4 py-3 rounded-lg"}>
          <div className={"w-2/12 self-center flex flex-row space-x-3"}>
            <div className={"self-center w-12"}>
              <p className={"font-semibold text-base"}>{ordinal_suffix_of(props.player.rank)}</p>
            </div>
            <PlayerAvatar player={player} sport={sorareBasketball} fullOpacity={true} />
          </div>
          <div className={"self-center flex flex-col w-5/12"}>
            <div className={"flex flex-row space-x-4"}>
              <p className={"text-base font-semibold"}>
                <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                  {player.DisplayName}
                </a>
              </p>
            </div>
            {game !== undefined && game?.game?.GameId !== "" && (
              <div className={"flex flex-row justify-start"}>
                <GamePreview size={"text-sm"} sport={sorareBasketball} noMargin={true} game={game} />
              </div>
            )}
            {(game === undefined || game?.game?.GameId === "") && (
              <div className={"flex flex-row justify-start"}>
                <p className={"text-sm font-medium"}>No game</p>
              </div>
            )}
          </div>

          <div className={"flex flex-row space-x-4 w-4/12 justify-end"}>
            {gameStats !== undefined && (
              <div className={"w-14 self-center"}>
                <BaseballScoreCircle score={gameStats.score} sport={sorareBasketball} />
              </div>
            )}
            <div className={"self-center flex flex-col items-end col-span-4 w-6/12"}>
              <p data-tip={"Total power of the player cards played in this division"} className={"font-semibold text-base"}>
                {stats?.totalPower?.toFixed(1)}
                <span className={"text-sm"}>
                  x ({((stats?.totalPower * 100) / divStats.total_power).toFixed(2)}
                  %)
                </span>
              </p>
              <div className={"flex flex-row justify-end"}>
                <CardCount
                  limitedCount={stats.limited}
                  rareCount={stats.rares}
                  superRareCount={stats.super_rares}
                  uniqueCount={stats.uniques}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImpactPlayer;
