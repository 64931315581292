import { withUser } from "../../userContext";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import SDLoading from "../util/SDLoading";

const WatchlistRedirection = (props) => {
  const shortid = props.match.params.shortid;
  const fetchRealID = () => {
    props
      .fetch(`/apiv2/watchlists/shortid/${shortid}`)
      .then((response) => {
        if (response.status >= 400) {
          throw Error(response);
        } else {
          return response.json();
        }
      })
      .then((res) => {
        props.history.push(`/watchlists/id/${res.id}`);
      })
      .catch(() => {
        props.history.push("/");
      });
  };

  useEffect(() => {
    fetchRealID();
  }, []);

  return (
    <div className="m-auto">
      <SDLoading />
    </div>
  );
};

export default withUser(withRouter(WatchlistRedirection));
