/**
 * @deprecated use URLSearchParams instead
 */
const getQueryString = (queries) => {
  return Object.keys(queries)
    .reduce((result, key) => {
      return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`];
    }, [])
    .join("&");
};

export default getQueryString;
