import React, { useState } from "react";
import GamePreview from "../games/gamePreview";
import ScoreEllipse from "../util/scoreEllipse";
import PlayerScoreModal from "../players/playerScoreModal";
import GoalIcon from "../../img/ICN_Ballon.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import PenaltySave from "../../img/PenaltySave.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import CleanSheet from "../../img/cleansheet.svg";
import LiveCircle from "../util/liveCircle";
import { format } from "date-fns";
import { CaptainLabel } from "../decision/lineupBuilderLineup";

export const LineupScore = (props) => {
  const { game, floatSO5Score, isCaptain, power, player, stats, dark } = props;
  const bgDate = dark ? "bg-black-33" : "bg-surface-container-high";
  const textDate = dark ? "text-[#E6E6E6]" : "text-on-surface";
  const size = props.size || "text-base";
  const isToday = game?.game?.GameTime !== undefined && new Date(game?.game?.GameTime).getDate() === new Date().getDate();
  return (
    <>
      {(game?.game?.HasLineups === true || game === undefined) && (
        <ScoreEllipse
          size={size}
          score={floatSO5Score}
          dnpTextColor={"text-[#1A1A1A]"}
          badgeColor={"#1A1A1A"}
          textColor={"text-[#1A1A1A]"}
          sport={player.Sport}
          power={power}
          isCaptain={isCaptain}
          formationPlace={stats?.FormationPlace}
          onGameSheet={stats?.OnGameSheet}
          minsPlayed={stats?.mins_played}
          game={game?.game}
        />
      )}
      {!(game?.game?.HasLineups === true || game === undefined) && (
        <div className={`rounded-full font-semibold ${size} ${bgDate} py-0.5 px-2`}>
          <span className={`${textDate} uppercase`}>
            {isToday ? format(new Date(game?.game?.GameTime), "HH:mm") : format(new Date(game?.game?.GameTime), "EEE dd")}
          </span>
        </div>
      )}
    </>
  );
};

function SO5LineupCard(props) {
  let card = props.card;
  let game = props.game;

  const [isOpen, setOverlay] = useState(false);

  let isCaptain = props.captain;

  let power = props.power;
  let SO5Score = props.SO5Score;
  const floatSO5Score = props.floatSO5Score;

  const stats = props.stats;
  const icons = lineupDecisiveActionIcons(stats, card.Position);

  return (
    <div>
      {props.stats !== undefined && (
        <PlayerScoreModal
          dark
          open={isOpen}
          stats={props.stats}
          sport={props.player.Sport}
          player={props.player}
          position={card.Position}
          close={() => setOverlay(false)}
          game={props.game}
        />
      )}
      <div className={"flex flex-col"}>
        <div className={""}>
          <div className={"h-full relative flex flex-col self-end justify-end"}>
            <div className={"absolute z-10 top-0 w-full h-[80%] grid grid-cols-1 grid-rows-6 justify-between overflow-hidden"}>
              <div className={`grid grid-cols-2 z-10 top-0`}>
                <div className={"flex w-full justify-center"}>
                  <div className={"w-1/2"}>{isCaptain && <CaptainLabel isCaptain />}</div>
                </div>
                <div></div>
              </div>
            </div>
            {card.PlayerId !== undefined ? (
              <div className={"flex flex-row self-end justify-end"}>
                <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
                  <img className={"object-contain"} src={card.PictureUrl} />
                </a>
              </div>
            ) : (
              <img className={"object-contain"} src={card.PictureUrl} />
            )}
            <div className={"items-center flex flex-col -mt-3 z-10 h-6"}>
              <div className={"flex flex-row justify-center mx-auto z-10 space-x-0.5"}>{icons}</div>
            </div>
          </div>

          {
            <div className={"space-y-2 flex flex-col self-center"}>
              <div
                onClick={() => setOverlay(true)}
                className={
                  "w-10/12 md:w-full flex flex-row self-center justify-center space-x-8 lg:space-x-4 xl:space-x-6 mb-2 pt-1.5 mx-auto cursor-pointer"
                }
              >
                <div className={"flex flex-row self-center justify-center items-baseline space-x-2 mt-1 "}>
                  <div className={"rounded-full text-[#CCCCCC] font-semibold text-sm bg-black-33 py-1 px-3 self-center"}>
                    <span className={"text-[#E6E6E6]"}>{SO5Score === "DNP" ? "" : SO5Score}</span> x {power}
                  </div>
                  <LineupScore
                    dark
                    game={game}
                    floatSO5Score={floatSO5Score}
                    isCaptain={isCaptain}
                    power={power}
                    player={props.player}
                    stats={stats}
                  />
                </div>
              </div>
              <div>
                <GamePreview noCircle={true} logoSize={"w-6 h-6"} dark textColor={"text-white"} game={game} showScore={true} />
                {game?.game?.Status === "playing" && props.stats && !props.stats.SubbedOff && (
                  <div className={"flex flex-row space-x-2 justify-center"}>
                    <div className={"self-center"}>
                      <LiveCircle color={"#FF6666"} />
                    </div>
                    <p className={"text-xs font-medium text-[#FF6666]"}>Live · {game?.game?.TimeMin}&apos;</p>
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export const lineupDecisiveActionIcons = (stats, cardPosition, divStyle, imgStyle) => {
  const icons = [];
  divStyle ||= "w-6 h-6 self-center rounded-full";
  imgStyle ||= "w-full h-full object-contain shadow rounded-full";
  if (stats !== undefined && stats !== null) {
    for (let i = 0; i < stats.Goals; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={GoalIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AdjustedGoalAssist; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={AssistIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AssistPenaltyWon; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={Whistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ClearanceOffLine + stats.LastManTackle; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={AngelBall} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltySaves + stats.PenaltySave; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={PenaltySave} />
        </div>,
      );
    }
    for (let i = 0; i < stats.RedCard; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedCard} />
        </div>,
      );
    }
    for (let i = 0; i < stats.OwnGoal; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={OwnGoal} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltyConceded; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedWhistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ErrorLeadToGoal; i++) {
      icons.push(
        <div className={divStyle}>
          <img className={imgStyle} src={RedShoe} />
        </div>,
      );
    }
    if (cardPosition === "Goalkeeper") {
      for (let i = 0; i < stats.CleanSheet; i++) {
        icons.push(
          <div className={divStyle}>
            <img className={imgStyle} src={CleanSheet} />
          </div>,
        );
      }
    }
  }
  return icons;
};

export default SO5LineupCard;
