export function getLowestValuation(rt, bmp, currency) {
  let lowestValuation = rt.Average;
  if (bmp.Price < rt.Average && bmp.Price > 0) {
    lowestValuation = bmp.Price;
  }
  if (currency === "eur") {
    lowestValuation = rt.EurAverage;
    if (bmp.PriceEur < rt.EurAverage && bmp.PriceEur > 0) {
      lowestValuation = bmp.PriceEur;
    }
  } else if (currency === "usd") {
    lowestValuation = rt.UsdAverage;
    if (bmp.PriceUsd < rt.UsdAverage && bmp.PriceUsd > 0) {
      lowestValuation = bmp.PriceUsd;
    }
  } else if (currency === "gbp") {
    lowestValuation = rt.GbpAverage;
    if (bmp.PriceGbp < rt.GbpAverage && bmp.PriceGbp > 0) {
      lowestValuation = bmp.PriceGbp;
    }
  }
  return lowestValuation;
}
