import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { useEvent } from "react-use";

const none = {};

function useLazyRef(init) {
  // not initialized yet
  const ref = useRef(none);
  // if it's not initialized (1st render)
  if (ref.current === none) {
    // we initialize it
    ref.current = init();
  }
  // new we return the initialized ref
  return ref;
}

export default function PopperPortal({ children, fixed, parentRef, offset }) {
  const [portalElement, setPortalElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const virtualElementRef = useLazyRef(() => new VirtualElement());
  const { styles, attributes, update } = usePopper(virtualElementRef.current, popperElement, POPPER_OPTIONS);
  const active = virtualElementRef.current.getBoundingClientRect().x > 0;
  useEffect(() => {
    const el = document.createElement("div");
    document.getElementById("root-theme").appendChild(el);
    setPortalElement(el);
    return () => el.remove();
  }, []);
  useEvent("mousemove", ({ clientX: x, clientY: y }) => {
    if (fixed && active) return;
    if (fixed && parentRef) {
      const rect = parentRef.current.getBoundingClientRect();
      x = rect.left + offset.x;
      y = rect.top + offset.y;
    }
    virtualElementRef.current.update(x, y);
    if (!(x > 0 && y > 0)) return;
    update === null || update === void 0 ? void 0 : update();
  });
  useEffect(() => {
    if (!active) return;
    update === null || update === void 0 ? void 0 : update();
  }, [active, update]);
  if (!portalElement) return null;
  return createPortal(
    React.createElement(
      "div",
      Object.assign({ ref: setPopperElement }, attributes.popper, {
        style: Object.assign(Object.assign({}, styles.popper), {
          zIndex: 1000,
          display: active ? "block" : "none",
        }),
      }),
      children,
    ),
    portalElement,
  );
}

class VirtualElement {
  constructor() {
    this.rect = {
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      x: 0,
      y: 0,
      display: "none",
      toJSON() {
        return this;
      },
    };
  }

  update(x, y) {
    this.rect.y = y;
    this.rect.top = y;
    this.rect.bottom = y;
    this.rect.x = x;
    this.rect.left = x;
    this.rect.right = x;
    this.rect.display = "block";
  }

  getBoundingClientRect() {
    return this.rect;
  }
}

const POPPER_OPTIONS = {
  placement: "right-start",
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [8, 8],
      },
    },
  ],
};
