import React, { useCallback, useEffect, useState } from "react";
import { withUser } from "../../userContext";
import ConfirmModal from "../util/confirmModal";
import {
  getDivisionDisplayNameSubTitleV2,
  getDivisionDisplayNameV2,
  getItemFromCompAndOptionalTeam,
  LineupBuilderDivisionPickerBadge,
} from "./lineupBuilderDivisionOrLineupPicker";
import { ReactComponent as MenuDotIcon } from "../../img/icon-menu-dot.svg";
import { ReactComponent as SDWatermark } from "../../img/sd-watermark-no-fill.svg";
import { ReactComponent as IconPlus } from "@material-design-icons/svg/filled/add_circle.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import { getBackgroundGradientFromDivisionId, getRarityDisplayName } from "../util/divisions";
import { isIE, isMobile, isSafari } from "react-device-detect";
import { computeLineupRawStrength, computeLineupStrength } from "../util/lineupStrength";
import { errorCatcher } from "../util/errors";
import { RulesIndicator } from "./lineupBuilderRules";
import clsx from "clsx";
import { LineupStrength } from "./lineupStrength";
import Cap240Indicator from "./cap240Indicator";
import { CaptainLabel, LineupToShare, positionsList } from "./lineupBuilderLineup";
import { useElementSize } from "usehooks-ts";
import PlayerAvailability from "../players/playerAvailability";
import { abbrv_football_positions } from "../util/positions";
import SorareScoreAverage from "./sorareScoreAverage";
import ScoreAverage from "./scoreAverage";
import { PickScoreDetails } from "../util/pickScore";
import { GameInLineVsWithDetails } from "./gameInLine";
import { PlayerCommunityProjectionWithHover } from "../util/communityPrediction";
import { sorareFootball } from "../util/sports";
import Spinner from "../loader/spinner";
import { Accordion } from "../util/accordion";
import { ReactTooltip } from "../util/tooltip";
import { Button } from "../util/button";
import { sdDark } from "../../themeColors";
import { getThemeColor, useTheme } from "../../themeContext";

export const SavedLineups = withUser((props) => {
  const { selectedCompIds, onDeleteLineup, competitions, leagues, statuses, groupBy, loading } = props;
  const [openConfirmationModalLineup, setOpenConfirmationModalLineup] = useState({});
  const getLineupsAndDivisions = useCallback(() => {
    let currentNbEntries = 0;
    selectedCompIds.forEach((compId) => {
      if (competitions !== undefined) {
        const comp = competitions.find((c) => c.id === compId);
        if (comp) {
          currentNbEntries += comp.currentNbEntries;
        }
      } else if (leagues !== undefined) {
        leagues.forEach((league) => {
          league.competitions.forEach((comp) => {
            if (comp.id === compId) {
              currentNbEntries += comp.currentNbEntries;
            }
          });
        });
      } else if (statuses !== undefined) {
        statuses.forEach((status) => {
          status.competitions.forEach((comp) => {
            if (comp.id === compId) {
              currentNbEntries += comp.currentNbEntries;
            }
          });
        });
      }
    });
    return {
      currentNbEntries,
    };
  }, [selectedCompIds, competitions, leagues, statuses]);

  const { currentNbEntries } = getLineupsAndDivisions();

  if ((competitions === undefined && leagues === undefined && statuses === undefined) || loading) {
    return (
      <div className={"p-4 flex items-center justify-center"}>
        <Spinner />
      </div>
    );
  }
  const nothingSelected = selectedCompIds.length === 0 || (selectedCompIds.length === 1 && selectedCompIds[0].id === "all");
  if (currentNbEntries === 0 && (leagues === undefined || nothingSelected) && (statuses === undefined || nothingSelected)) {
    return (
      <div>
        {nothingSelected ? (
          <p className={"text-base font-medium text-center mt-4 text-on-surface-variant"}>
            No lineup saved for this gameweek on the selected divisions. Build one and come back!
          </p>
        ) : (
          <p className={"text-base font-medium text-center mt-4 text-on-surface-variant"}>
            No lineup saved for this gameweek. Build some and come back!
          </p>
        )}
      </div>
    );
  }

  let lineupsComponent = [];
  if (groupBy === "league") {
    lineupsComponent = <SavedLineupsByLeague {...props} onDeleteLineup={setOpenConfirmationModalLineup} />;
  } else if (groupBy === "status") {
    lineupsComponent = <SavedLineupsByStatus {...props} onDeleteLineup={setOpenConfirmationModalLineup} />;
  } else {
    lineupsComponent = <SavedLineupsNoGroup {...props} onDeleteLineup={setOpenConfirmationModalLineup} />;
  }

  return (
    <div className={"flex flex-col gap-y-4"}>
      {lineupsComponent}
      <DeleteLineupModal
        team={openConfirmationModalLineup}
        onDelete={() => {
          setOpenConfirmationModalLineup({});
          onDeleteLineup(openConfirmationModalLineup.lineup?.id);
        }}
        onCancel={() => setOpenConfirmationModalLineup({})}
      />
      <ReactTooltip id={"lub-league-header"} />
    </div>
  );
});

const SavedLineupsByStatus = (props) => {
  const { statuses, selectedCompIds } = props;
  return (
    <>
      {statuses?.map((status) => {
        return <SavedLineupStatus key={status.id} {...props} status={status} />;
      })}
    </>
  );
};

const SavedLineupStatus = (props) => {
  const { status, selectedCompIds } = props;
  const selectedCompetitions = status.competitions.filter((c) => selectedCompIds.includes(c.id)) || [];
  const header = (
    <AccordionHeader
      title={status.fullName}
      currentEntries={status.currentNbEntries}
      hasInvalidLineup={status.hasInvalidLineup}
      hasLineupInUnavailableCompetition={status.hasLineupInUnavailableCompetition}
      lineupInvalidReasons={status.lineupInvalidReasons}
    />
  );
  return (
    <Accordion header={header}>
      {selectedCompetitions.length > 0 && (
        <div className={"grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4"}>
          {selectedCompetitions.flatMap((comp, i) => {
            const teams = [];
            if (status.id === "REMAINING") {
              teams.push(...(comp.multiEntryTeams?.all || []));
            } else {
              if (comp.currentNbEntries === 0) {
                return null;
              }
              if (comp.isMultiEntry) {
                teams.push(...(comp.multiEntryTeams?.all?.filter((t) => t.hasLineup) || []));
              } else if (comp.singleEntryTeam?.hasLineup) {
                teams.push(comp.singleEntryTeam);
              }
            }

            return teams.map((team, j) => (
              <Team key={team.competitionId + (team.lineupTeamNumber || "")} {...props} comp={comp} team={team} />
            ));
          })}
        </div>
      )}
      {selectedCompetitions.length === 0 && (
        <p className={"p-3 flex justify-center items-center text-md font-semibold text-on-surface-variant w-full"}>
          {status.competitions.length > 0 ? `No lineup in this state for selected competitions` : `No lineup in this state`}
        </p>
      )}
    </Accordion>
  );
};

const SavedLineupsByLeague = (props) => {
  const { leagues, selectedCompIds } = props;
  return (
    <>
      {leagues?.map((league) => {
        const selectedCompetitions = league.competitions.filter((c) => selectedCompIds.includes(c.id)) || [];
        if (selectedCompetitions.length === 0) {
          return null;
        }
        return <SavedLineupLeague key={league.id} {...props} league={league} />;
      })}
    </>
  );
};

const SavedLineupLeague = (props) => {
  const { league, selectedCompIds } = props;
  const selectedCompetitions = league.competitions.filter((c) => selectedCompIds.includes(c.id)) || [];
  const header = (
    <AccordionHeader
      title={league.fullName}
      logo={league.logoUrl}
      currentEntries={league.currentNbEntries}
      maxEntries={league.maxNbEntries}
      hasInvalidLineup={league.hasInvalidLineup}
      hasLineupInUnavailableCompetition={league.hasLineupInUnavailableCompetition}
      lineupInvalidReasons={league.lineupInvalidReasons}
    />
  );
  const seasonalCompetitions = selectedCompetitions.filter((c) => c.isPromotionRelegation);
  return (
    <Accordion header={header} defaultOpen={league.currentNbEntries > 0}>
      <div className={"flex flex-col gap-3"}>
        {seasonalCompetitions.map((comp) => {
          return (
            <div className="bg-surface-container rounded-xl p-3">
              <div className={"font-semibold font-sans text-xl text-on-surface-variant flex flex-row items-center gap-3 pb-4"}>
                {comp.logoUrl && <img src={comp.logoUrl} className={"w-8 h-8"} />}
                <span className={"my-auto font-headers"}>
                  {comp.fullNameNoScarcity} · {getRarityDisplayName(comp.rarity)}
                </span>
                <LineupBuilderDivisionPickerBadge
                  disabled={true}
                  competitionName={comp.fullName}
                  isInvalid={comp.hasInvalidLineup}
                  lineupInvalidReasons={comp.lineupInvalidReasons}
                  isLineupNotPossible={comp.hasLineupInUnavailableCompetition}
                  withTooltip={true}
                  remainingTeams={{ current: comp.currentNbEntries, max: comp.maxNbEntries }}
                  dataTipId={"lub-league-header"}
                  textSize={"text-[0.6em] leading-[1.4em]"}
                />
              </div>
              <div className={"grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4"}>
                {comp.multiEntryTeams?.all?.map((team) => (
                  <Team key={team.competitionId + (team.lineupTeamNumber || "")} {...props} comp={comp} team={team} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className={"grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4"}>
        {selectedCompetitions.flatMap((comp, i) => {
          if (comp.currentNbEntries === 0 || comp.isPromotionRelegation) {
            return null;
          }
          const teams = [];
          if (comp.isMultiEntry) {
            teams.push(...(comp.multiEntryTeams?.all?.filter((t) => t.hasLineup) || []));
          } else if (comp.singleEntryTeam?.hasLineup) {
            teams.push(comp.singleEntryTeam);
          }
          return teams.map((team, j) => (
            <Team key={team.competitionId + (team.lineupTeamNumber || "")} {...props} comp={comp} team={team} />
          ));
        })}
      </div>
    </Accordion>
  );
};

const AccordionHeader = (props) => {
  const { title, logo, currentEntries, maxEntries, hasInvalidLineup, hasLineupInUnavailableCompetition, lineupInvalidReasons } = props;
  return (
    <div className={"font-semibold font-sans text-md flex flex-row items-center gap-3"}>
      {logo && <img src={logo} className={"w-5 h-5"} />}
      <span className={"my-auto font-headers"}>{title}</span>
      <LineupBuilderDivisionPickerBadge
        competitionName={title}
        isInvalid={hasInvalidLineup}
        lineupInvalidReasons={lineupInvalidReasons}
        isLineupNotPossible={hasLineupInUnavailableCompetition}
        withTooltip={true}
        remainingTeams={currentEntries !== undefined ? { current: currentEntries, max: maxEntries } : undefined}
        dataTipId={"lub-league-header"}
        textSize={"text-[0.6em] leading-[1.4em]"}
      />
    </div>
  );
};

const SavedLineupsNoGroup = (props) => {
  const { competitions, selectedCompIds } = props;

  const selectedCompetitions = competitions?.filter((c) => selectedCompIds.includes(c.id)) || [];
  return (
    <div className={"grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4"}>
      {selectedCompetitions.flatMap((comp, i) => {
        if (comp.currentNbEntries === 0) {
          return null;
        }
        const teams = [];
        if (comp.isMultiEntry) {
          teams.push(...(comp.multiEntryTeams?.all?.filter((t) => t.hasLineup) || []));
        } else if (comp.singleEntryTeam?.hasLineup) {
          teams.push(comp.singleEntryTeam);
        }
        return teams.map((team, j) => <Team key={team.competitionId + (team.lineupTeamNumber || "")} {...props} comp={comp} team={team} />);
      })}
    </div>
  );
};

const Team = (props) => {
  const {
    team,
    comp,
    divisionMaxScores,
    divisionMaxScoresNoCaptain,
    oddsAndStats,
    targetUserId,
    gw,
    draftId,
    onDeleteLineup,
    projections,
  } = props;
  const divisionRules = comp?.rules || {};
  const odds = oddsAndStats?.full_odds;
  const standings = oddsAndStats?.standings;
  let captain = null;
  const lineup = [null, null, null, null, null];
  const lineupId = team.lineup?.id || "";
  team.lineup?.cards?.forEach((card) => {
    if (!card) {
      return;
    }
    if (card.captain) {
      captain = card;
    }
    if (card.lineup_position === "Goalkeeper") {
      lineup[0] = card;
    } else if (card.lineup_position === "Defender") {
      lineup[1] = card;
    } else if (card.lineup_position === "Midfielder") {
      lineup[2] = card;
    } else if (card.lineup_position === "Forward") {
      lineup[3] = card;
    } else {
      lineup[4] = card;
    }
  });
  const normalizedItem = getItemFromCompAndOptionalTeam(comp, team);
  const lineupName = getDivisionDisplayNameV2(normalizedItem);
  const lineupSubtitle = getDivisionDisplayNameSubTitleV2(normalizedItem);
  return (
    <SavedLineupBuilderLineup
      key={lineupId || team.competitionId + (team.lineupTeamNumber || "")}
      team={team}
      remainingPointsInHeader={true}
      divId={team.competitionId}
      divType={team.competitionType}
      divRules={divisionRules}
      divName={lineupName}
      divShortName={comp.fullNameNoScarcity}
      divLogo={team.logoUrl}
      subtitle={lineupSubtitle}
      divisionMaxScore={{ normal: divisionMaxScores?.[team.competitionId], noCaptain: divisionMaxScoresNoCaptain?.[team.competitionId] }}
      rarity={comp.rarity}
      odds={odds}
      standings={standings}
      lineup={lineup}
      lineupId={lineupId}
      captain={captain}
      projections={projections}
      targetUserId={targetUserId}
      gw={gw}
      draftId={draftId}
      resetLineup={() => onDeleteLineup({ ...team, comp })}
      unavailableComp={team.hasLineup ? team.lineup.inUnavailableCompetition : false}
    />
  );
};

const DeleteLineupModal = (props) => {
  const { team, onDelete, onCancel } = props;
  if (team?.lineup?.id === undefined) {
    return null;
  }
  return (
    <ConfirmModal
      title={"Delete lineup ?"}
      message={
        <>
          {team.name ? (
            <>
              Lineup <b>{`“${team.name}”`}</b> from <b>{`“${team.comp.fullName}”`}</b> will be deleted.
            </>
          ) : (
            <>
              This lineup from <b>{`“${team.comp.fullName}”`}</b> competition will be deleted.
            </>
          )}
          <br />
          This action is irreversible
        </>
      }
      open={!!team.lineup.id}
      onConfirm={onDelete}
      confirmLabel={"Delete"}
      confirmingLabel={"Deleting..."}
      onCancel={onCancel ? () => onCancel() : undefined}
    />
  );
};

const SavedLineupBuilderLineup = withUser((props) => {
  const {
    team,
    lineup,
    lineupId,
    targetUserId,
    gw,
    draftId,
    captain,
    divId,
    divName,
    divShortName,
    subtitle,
    divLogo,
    rarity,
    position,
    projections,
    divRules,
    preferences,
    loading,
    pickScoreReveal,
    divisionMaxScore,
    unavailableComp,
  } = props;
  const powerAdjusted = preferences?.powerAdjustedScore || false;
  const [sharing, setSharing] = useState(false);
  const themeCtx = useTheme();
  const theme = preferences?.darkMode ? sdDark : themeCtx.theme;

  useEffect(() => {
    setSharing(false); // stop sharing if the lineup changes
  }, [targetUserId, draftId, lineupId, gw]);

  if (lineup === undefined) return null;
  const [bgHeaderFrom, bgHeaderTo] = getBackgroundGradientFromDivisionId(rarity);

  const isCapped = divRules?.isCapped !== undefined ? divRules.isCapped : false;
  const hasCostRestriction = isCapped || divRules?.atLeastCardsWithAverage !== undefined || divRules?.atMostCardsWithAverage !== undefined;
  const withPower = divRules?.withCardPower !== undefined ? divRules.withCardPower : true;
  const captainPower = divRules?.captainPower;
  const scarcityLimitations = divRules?.scarcityLimits;

  const onDownloaded = useCallback(() => {
    setSharing(false);
  }, []);

  const cardProps = {
    powerAdjusted,
    isCapped,
    hasCostRestriction,
    withPower,
    captainPower,
    scarcityLimitations,
    projections,
    odds: props.odds,
    standings: props.standings,
    tier: props.user.tier,
    readOnly: true,
    setCaptain: props.setCaptain,
    removeCaptain: props.removeCaptain,
    changePosition: props.changePosition,
    removeCard: props.removeCard,
    pickCard: (c, p) => props.pickCard(c, p),
    pickScoreReveal,
    captain,
    loading,
  };
  const disabledShare = isSafari || isIE || isMobile;
  const canShare = draftId && divId && !loading && !disabledShare;

  const [lineupStrength, setLineupStrength] = useState({});
  useEffect(() => {
    const lStrength = computeLineupStrength(lineup, divRules, captain, divisionMaxScore);
    setLineupStrength(lStrength);
  }, [lineup, captain, divisionMaxScore]);

  const [lineupRawStrength, setLineupRawStrength] = useState({});
  useEffect(() => {
    const rawStrength = computeLineupRawStrength(lineup, divRules, captain);
    setLineupRawStrength(rawStrength);
  }, [lineup, captain]);

  // Cap 240 indicator
  const displayCap240Indicator = divName.includes("Cap 240");
  const [cap240Indicator, setCap240Indicator] = React.useState(0);
  const [cap240IndicatorLoaded, setCap240IndicatorLoaded] = React.useState(false);
  useEffect(() => {
    if (lineupRawStrength > 0) {
      props
        .fetch(`/decision-api/decision/breakdown/cap240Indicator?divisionId=${divId}&rawStrength=${lineupRawStrength}`)
        .then((response) => response.json())
        .then(async (res) => {
          setCap240Indicator(res);
        })
        .catch(errorCatcher())
        .finally(() => setCap240IndicatorLoaded(true));
    }
  }, [lineupRawStrength]);

  const emptyLineup = lineup.every((c) => c === null);

  return (
    <div className={"relative bg-surface-container-highest overflow-hidden rounded-xl flex flex-col justify-between"}>
      {sharing && (
        <LineupToShare
          targetUserId={targetUserId}
          cardProps={cardProps}
          divName={props.divName}
          gwNumber={gw}
          lineup={lineup}
          draftId={draftId}
          divId={divId}
          lineupStrength={lineupStrength}
          onDownloaded={onDownloaded}
          onDownloadedError={onDownloaded}
          hasCaptain={captain !== null && captain !== undefined}
        />
      )}
      <div
        className={`text-white`}
        style={{
          background: `linear-gradient(180deg, ${getThemeColor(theme, bgHeaderFrom)} 0%, ${getThemeColor(theme, bgHeaderTo)} 100%)`,
        }}
      >
        <div className={"py-2 flex flex-row justify-between h-14"}>
          <div className={"self-center flex flex-row pl-2 space-x-2"}>
            <div className="bg-transparent-surface-container-high bg-opacity-70 h-full px-1 py-1 rounded-md self-center">
              <div className="w-8 h-8 flex justify-center">
                <img src={divLogo} className="w-full h-full object-contain" />
              </div>
            </div>
            <div className="self-center">
              <p className="text-sm font-semibold font-headers text-surface-container md:text-base">{divShortName}</p>
              <p className="text-xs sm:text-sm font-semibold text-surface-container text-opacity-80">{subtitle}</p>
            </div>
          </div>
          <div className={"flex flex-row gap-4 self-center pr-2"}>
            <div className={"flex flex-row gap-2 self-center"}>
              <RulesIndicator unavailableComp={unavailableComp} rules={divRules} loading={loading} lineup={lineup} captain={captain} />
            </div>
            {!emptyLineup && (
              <div className={"flex flex-row gap-2 self-center"}>
                <LineupActions
                  team={team}
                  sharing={sharing}
                  canShare={canShare}
                  setSharing={setSharing}
                  resetLineup={props.resetLineup}
                  draftId={draftId}
                  gw={gw}
                  targetUserId={targetUserId}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {emptyLineup ? (
        <div className={"flex justify-center items-center min-h-[16em] md:min-h-[16em] 2xl:min-h-[20em] grow"}>
          <div className={"flex flex-col gap-6 items-center justify-center h-full"}>
            <p className={"text-sm text-on-surface-variant"}>No lineup built yet.</p>
            <a href={getLineupEditLink(team, targetUserId, draftId, gw)}>
              <Button context="neutral" icon={<IconPlus className={"h-5 w-5 fill-on-surface"} />} label={"Build a lineup"} />
            </a>
          </div>
        </div>
      ) : (
        <div className={"grid grid-cols-5 justify-center gap-1 px-5 pt-1 pb-3"}>
          {positionsList.map((p, i) => (
            <div key={i}>
              <SavedLineupBuilderCard key={i} {...cardProps} selected={position === p} position={p} card={lineup[i]} />
            </div>
          ))}
        </div>
      )}
      {!emptyLineup && (
        <div
          className={clsx(
            "bg-transparent-inverse-surface-low bg-opacity-10 py-1.5 px-3 flex items-center overflow-hidden",
            displayCap240Indicator ? "justify-between" : "justify-center",
          )}
        >
          {unavailableComp ? (
            <div className="flex flex-col items-center justify-center gap-1">
              <span className="font-semibold text-xs text-on-surface">Competition disabled</span>
              <span className="font-medium text-xs text-on-surface-variant">Sorare did not open this tournament</span>
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <span className="font-semibold text-xs text-on-surface-variant">LU Strength:</span>
              <LineupStrength lineupScore={lineupStrength.lineupScore} divisionMaxScore={lineupStrength.divisionMaxScore} size={"xs"} />
            </div>
          )}
          {!unavailableComp && displayCap240Indicator && cap240IndicatorLoaded && (
            <Cap240Indicator indicators={cap240Indicator?.indicators} />
          )}
        </div>
      )}
    </div>
  );
});

const SavedLineupBuilderCard = (props) => {
  const {
    powerAdjusted,
    isCapped,
    hasCostRestriction,
    withPower,
    captainPower,
    scarcityLimitations,
    standings,
    loading,
    position,
    readOnly,
    pickScoreReveal,
  } = props;
  const entry = props.card;
  const [refContainer, { width }] = useElementSize();
  const [isHover, setIsHover] = useState(false);

  const changePositionPossible = !readOnly && !loading && !props.selected;
  if (entry === null) {
    return (
      <div
        onClick={() => props.changePosition && props.changePosition(position)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          aspectRatio: "1 / 2.5",
          boxShadow: "0px 0.1em 0.5em rgba(0, 0, 0, 0.16)",
        }}
        className={`flex shadow-xl flex-col rounded-xl justify-center items-center w-full h-full ${
          loading ? "animate-pulse-sm" : ""
        } bg-on-disable`}
      >
        <div ref={refContainer} className={"relative self-center flex justify-center items-center w-full"}>
          <SDWatermark className={`w-[80%] h-fit opacity-70 ${props.selected ? "fill-primary" : "fill-inverse-on-surface"}`} />
          <div className={"absolute flex flex-col justify-center items-center gap-2"}>
            <p className={`font-semibold ${props.selected ? "text-primary" : "text-inverse-on-surface"}`} style={{ fontSize: width / 7 }}>
              {props.position}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const card = props.card.card;
  const isCaptain = props.captain !== null ? props.captain.card.TokenId === card.TokenId : false;
  const cardPower = (+entry.card.Power - 1) * 100;
  const captainPowerNormalized = isCaptain ? captainPower || 0 : 0;
  const cardPowerBecauseOfScarcity = scarcityLimitations?.[card.Scarcity]?.power || 0;
  const totalCardPower = (cardPower + captainPowerNormalized + cardPowerBecauseOfScarcity).toFixed(1);
  let odds = props.odds !== undefined ? props.odds[entry.next_game.GameId] : undefined;
  if (odds !== undefined) {
    odds = odds["full_time0"];
  }
  let mainPickScore =
    entry.pick_score_group !== undefined && entry.pick_score_group !== null
      ? entry.pick_score_group
      : pickScoreReveal?.players?.[entry.player.PlayerId + "-" + card.Position]?.score;
  if (entry.next_game?.GameId === undefined || entry.next_game.GameId === "") {
    mainPickScore = "NG";
  }
  const pickScoreDetails =
    entry.pick_score_details !== undefined && entry.pick_score_details !== null
      ? entry.pick_score_details
      : pickScoreReveal?.players?.[entry.player.PlayerId + "-" + card.Position]?.details;

  const hasPickScore = mainPickScore !== null && mainPickScore !== undefined;
  const hhh = width / 3;

  return (
    <div
      onClick={(e) => props.changePosition && props.changePosition(position)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ boxShadow: "0px 0.1em 0.5em rgba(0, 0, 0, 0.16)" }}
      className={`flex flex-col bg-surface-container rounded-xl justify-center items-center w-full h-full overflow-hidden ${
        isCaptain ? "border-3" : "border p-[1px]"
      }  ${isCaptain ? "border-limited" : "border-surface-container"}`}
    >
      <div
        ref={refContainer}
        className={`relative self-center flex flex-col justify-between items-center w-full h-full overflow-hidden ${
          isCaptain ? "" : "p-[1px]"
        }`}
      >
        <div className={`w-full relative`}>
          <img className={"rounded-xl object-contain"} style={{ padding: "2.5%" }} src={card.PictureUrl} />
          <div className={`absolute grid grid-cols-2 z-10 top-0 w-full h-full`}>
            <div className={"flex w-full justify-center h-[28%]"}>{isCaptain && <CaptainLabel isCaptain={true} />}</div>
          </div>
        </div>
        <div
          className={"flex flex-col w-full gap-1"}
          style={
            isCaptain
              ? {
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  paddingBottom: "calc(5% + 2px)",
                }
              : { paddingLeft: "5%", paddingRight: "5%", paddingBottom: "5%" }
          }
        >
          <div
            className={"relative border-t border-transparent-inverse-surface-low border-opacity-10 flex justify-center"}
            style={{ marginTop: "15%", marginBottom: "12%" }}
          >
            <div
              className={
                "absolute -top-[1px] -translate-y-1/2 bg-surface-container text-on-surface-variant px-2 font-semibold flex flex-row justify-center items-center gap-1"
              }
              style={{
                lineHeight: 1,
                fontSize: width / 8,
                paddingLeft: "7%",
                paddingRight: "7%",
              }}
            >
              <div className={"w-4 h-4 self-center"}>
                <PlayerAvailability availability={entry.player_status || entry.availability_status} />
              </div>
              <span>{withPower ? `+${totalCardPower}%` : abbrv_football_positions[position]}</span>
            </div>
          </div>
          <div
            className={`${
              hasPickScore
                ? "grid grid-rows-1 grid-cols-2 justify-evenly items-center gap-x-[0.375rem]"
                : "flex justify-center items-center"
            } `}
            style={{ height: width / 3 }}
          >
            <div
              className={`${hasPickScore ? "h-full w-full" : "h-full mx-auto"}`}
              style={hasPickScore ? undefined : { width: "calc(50% - (0.375rem /2))" }}
            >
              {hasCostRestriction ? (
                <SorareScoreAverage avg={entry.cost} />
              ) : (
                <ScoreAverage
                  hidePct
                  size="adapting"
                  avg={entry.last_15}
                  period={15}
                  played={entry.last_15_played}
                  power={entry.card.Power}
                  powerAdj={powerAdjusted}
                  sAvg={entry.s15Avg}
                  sApps={entry.s15Apps}
                />
              )}
            </div>
            {hasPickScore && <PickScoreDetails main={mainPickScore} detailed={pickScoreDetails} isCapped={isCapped} />}
          </div>
          <div className={"flex self-center py-1 items-center w-full"} style={{ height: hhh + "px" }}>
            <div className={"px-[5%] w-full"}>
              <GameInLineVsWithDetails
                adapting={true}
                playerTeam={entry.player.TeamId}
                team1={entry.player_team}
                team2={entry.opposing_team}
                game={entry.next_game}
                playerNationalTeam={entry.player.NationalTeam}
                odds={odds}
                standings={standings}
              />
            </div>
          </div>
          <div className={"flex justify-center items-center"} style={{ height: width / 4 + "px" }}>
            <div className={"px-[5%] w-full"}>
              <PlayerCommunityProjectionWithHover
                adapting={true}
                playerTeamId={entry.player.TeamId}
                sport={sorareFootball}
                projection={props.projections ? props.projections[entry.player.PlayerId] : undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LineupActions = (props) => {
  const { team, sharing, canShare, setSharing, resetLineup, targetUserId, draftId, gw } = props;
  const [showMenu, setShowMenu] = useState(false);

  let menuItems = [
    {
      key: "edit",
      label: "Edit lineup",
      className: "cursor-pointer w-full sm:w-40 hover:bg-containers-surface-focus-active-primarytint",
      onClick: () => {
        window.location.href = getLineupEditLink(team, targetUserId, draftId, gw);
      },
    },
    {
      key: "share",
      label: "Share lineup",
      className: clsx(
        "w-full sm:w-40 animate-pulse-sm",
        sharing ? "cursor-progress" : "cursor-pointer hover:bg-containers-surface-focus-active-primarytint",
      ),
      onClick: () => {
        setSharing(true);
      },
    },
    {
      key: "remove-lineup",
      label: "Remove lineup",
      className: "cursor-pointer w-full sm:w-40 hover:bg-containers-surface-focus-active-primarytint",
      onClick: () => {
        resetLineup && resetLineup();
      },
    },
  ];

  if (!canShare) {
    menuItems = menuItems.filter((item) => item.key !== "share");
  }

  return (
    <div>
      {menuItems.length > 0 && (
        <div className={"relative flex flex-row min-w-fit"}>
          <div
            className="rounded-full bg-opacity-70 bg-transparent-surface-container-high p-1.5 flex flew-row justify-center items-center cursor-pointer hover:opacity-80"
            onClick={() => setShowMenu(true)}
          >
            <MenuDotIcon className="h-5 w-5 fill-on-surface" />
          </div>
          <StandaloneMenu
            className={"top-8 right-0 overflow-y-auto max-h-60 pt-2 text-nowrap"}
            show={showMenu}
            onClickOutside={() => setShowMenu(false)}
            items={menuItems}
          />
        </div>
      )}
    </div>
  );
};

const getLineupEditLink = (team, targetUserId, draftId, gw) => {
  const params = new URLSearchParams();
  params.append("managerSorareId", targetUserId || "");
  params.append("divisionId", team.competitionId || "");
  params.append("gw", gw || "");
  params.append("draftId", draftId || "");
  if (team.hasLineup) {
    params.append("lineupId", team.lineup.id || "");
  }
  if (team.managerTeam?.id) {
    params.append("managerTeamId", team.managerTeam?.id || "");
  }
  if (team.lineupTeamNumber) {
    params.append("lineupTeamNumber", team.lineupTeamNumber || "");
  }
  return `/lineupBuilder/sections/builder/sport/sr_football?${params.toString()}`;
};
