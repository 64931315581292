import React, { useEffect, useRef, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import Locker from "../../img/brand-close-locker-nude.svg";
import { XIcon } from "@heroicons/react/outline";
import { Spinning } from "../loader/spinner";
import { useElementSize } from "usehooks-ts";
import { isMobile } from "react-device-detect";
import { MobileModale } from "./mobileModale";

export const Dropdown = (props) => {
  const { options, selected, onChange, placeholder, loading, disabled, multiple, displayOptions, title, dark, ...dropdownProps } = props;
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);
  const [refButton, { width }] = useElementSize();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    ReactTooltip.rebuild();
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (selected && focus) {
      // https://stackoverflow.com/questions/11039885/scrollintoview-causing-the-whole-page-to-move
      var target = selectedElement.current;
      if (target) {
        target.parentNode.scrollTop = target.offsetTop;
      }
      props.onFocus && props.onFocus();
    } else {
      props.onBlur && props.onBlur();
    }

    ReactTooltip.rebuild();
  }, [focus]);

  // find intersection of selected and options, selected is a table of values, options is a table of objects
  let selectedObjects = [];
  if (multiple) {
    selectedObjects = options.filter(({ value }) => selected.includes(value)) || [];
  } else {
    const selectedObj = options.find(({ value }) => selected === value);
    selectedObjects = selectedObj ? [selectedObj] : [];
  }
  const borders = displayOptions?.borders || false;
  const borderOnFocus = displayOptions?.borderOnFocus || false;
  const roundedTopFocus = displayOptions?.roundedTopFocus || "rounded-t-md";
  const rounded = displayOptions?.rounded || "rounded-md";
  const roundedBottomFocus = displayOptions?.roundedBottomFocus || "rounded-b-md";
  const height = displayOptions?.height || "";
  const widthContainer = displayOptions?.width || "w-full";
  const bg = displayOptions?.bg || (dark ? "bg-black-1f" : "bg-surface-container");
  const selectedElement = useRef(null);
  const textColor = dark ? "text-white" : "text-on-surface";
  const fillColor = dark ? "fill-white" : "fill-on-surface";
  const mobileBg = dark ? "bg-[#1f1f1f]" : "bg-surface-container";
  return (
    <div className={`relative ${widthContainer} ${height}`} ref={ref} {...dropdownProps}>
      <button
        onClick={
          disabled
            ? undefined
            : (e) => {
                e.stopPropagation();
                setFocus(!focus);
              }
        }
        className={`h-full text-md my-auto ${bg} w-full ${height} ${disabled ? "opacity-40" : ""} ${
          focus
            ? `${roundedTopFocus} ${
                borderOnFocus
                  ? "border-x border-t border-primary border-opacity-70"
                  : borders && "border border-transparent-inverse-surface-low border-opacity-10"
              }`
            : `${
                borderOnFocus ? "hover:border-primary" : borders && "border border-transparent-inverse-surface-low border-opacity-10 "
              } ${rounded}`
        }`}
      >
        <div
          ref={refButton}
          className={`flex flex-row justify-between ${rounded === "rounded-full" || rounded === "rounded-3xl" ? "px-4" : "px-3"} py-2`}
        >
          <span className={`my-auto w-5/6 truncate text-left font-medium leading-5 ${textColor}`}>
            {(selectedObjects.length > 0 ? selectedObjects.map((o) => o.name).join(", ") : placeholder) || ""}
          </span>
          <div className={"flex flex-row my-auto gap-2"}>
            {loading && <Spinning className={"h-5 w-5"} />}
            <div className={"flex flex-col justify-center"}>
              <SortArrowIcon className={`${fillColor} h-2.5 w-2.5 ${focus ? "transform rotate-180" : ""}`} />
            </div>
          </div>
        </div>
      </button>
      {isMobile && (
        <MobileModale open={focus} close={() => setFocus(false)}>
          <div className={`h-full ${mobileBg} rounded-t-lg`}>
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <div className={"flex flex-row justify-between px-4 py-4"}>
                <h3 className={`font-headers ${textColor} font-semibold m-0`}>{title || "Select in list"}</h3>
                <div className={"flex flex-row gap-3"}>
                  <XIcon
                    className={`my-auto h-6 w-6 hover:opacity-80 cursor-pointer ${fillColor}`}
                    aria-hidden="true"
                    onClick={() => setFocus(false)}
                  />
                </div>
              </div>
            </div>
            <ul className={"max-h-[80vh] overflow-y-auto"}>
              {options.map((option, i) => {
                return (
                  <DropdownItem
                    key={i}
                    dark={dark}
                    selectedObjects={selectedObjects}
                    option={option}
                    onChange={onChange}
                    selectedElement={selectedElement}
                    disabled={disabled}
                    setFocus={setFocus}
                    first={i === 0}
                  />
                );
              })}
            </ul>
          </div>
        </MobileModale>
      )}
      {focus && !isMobile && (
        <div
          className={`absolute ${bg} shadow-lg ${roundedBottomFocus} overflow-hidden ${
            borderOnFocus
              ? "border-x border-b border-primary border-opacity-70"
              : borders && "border-x border-b border-transparent-inverse-surface-low border-opacity-10"
          }`}
        >
          <ul className={"h-full overflow-y-scroll"} style={{ maxHeight: "25em", width }}>
            {options.map((option, i) => {
              return (
                <DropdownItem
                  key={i}
                  dark={dark}
                  selectedObjects={selectedObjects}
                  option={option}
                  onChange={onChange}
                  selectedElement={selectedElement}
                  disabled={disabled}
                  setFocus={setFocus}
                  first={i === 0}
                />
              );
            })}
          </ul>
        </div>
      )}
      <ReactTooltip id={"dropdown-option"} />
    </div>
  );
};

const DropdownItem = (props) => {
  const { selectedObjects, option, onChange, selectedElement, disabled, setFocus, first, dark } = props;
  const isSelected = selectedObjects.find(({ value }) => value === option.value);
  const isOptionDisabled = option.disabled || option.locked || false;
  const selectedClass = dark ? "bg-[#54423b] text-[#fb7637]" : "bg-containers-surface-focus-active-primarytint text-primary";
  const notSelectedClass = dark ? "text-white" : "text-on-surface";
  const enabledOptionClass = dark ? "hover:bg-[#54423b]" : "hover:bg-containers-surface-hover-primarytint cursor-pointer";
  const fillColor = dark ? "fill-white" : "fill-on-surface";
  return (
    <li
      key={option.value}
      onClick={(e) => {
        if (disabled || isOptionDisabled) return;
        onChange(option.value);
        setFocus(false);
        e.stopPropagation();
      }}
      title={option.title}
      data-for={"dropdown-option"}
      data-tip={option.tooltip}
      ref={isSelected ? selectedElement : null}
      className={`${first ? "" : "border-t"} border-transparent-inverse-surface-low border-opacity-10 ${
        isSelected ? `${selectedClass} font-semibold` : notSelectedClass
      } ${isOptionDisabled ? "cursor-not-allowed" : `${enabledOptionClass} cursor-pointer`}`}
    >
      <div className="w-full h-full px-4 py-2">
        <button
          value={option.value}
          className={`w-full text-left text-sm flex flex-row justify-between ${
            isOptionDisabled ? "cursor-not-allowed" : "cursor-pointer"
          } ${option.disabled ? "opacity-40" : ""}`}
        >
          <span className={`w-5/6 truncate ${option.locked && !option.disabled ? "opacity-40" : ""}`}>{option.name}</span>
          <div className={"flex flex-row gap-2 my-auto"}>
            <div className={`${option.icon || option.locked ? "" : "invisible"} flex flex-col justify-center`}>
              {option.locked || option.icon ? (
                <img src={option.locked ? Locker : option.icon} className={"h-3 w-3"} />
              ) : (
                <SortArrowIcon className={`${fillColor} h-3 w-3`} />
              )}
            </div>
          </div>
        </button>
      </div>
    </li>
  );
};
