import React, { useRef, useState } from "react";
import NoInjury from "../../img/no-injury.svg";
import Unknown from "../../img/questionm.svg";
import Injured100 from "../../img/injury-100.svg";
import Injured75 from "../../img/injury-75.svg";
import Injured25 from "../../img/injury-25.svg";
import IntlTrip from "../../img/intlTrip.svg";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import { getHostnameFromURL } from "../util/url";
import PopperPortal from "./helpers/popper";

function PlayerAvailability(props) {
  const [focus, setFocus] = useState(false);
  const readOnly = props.readOnly !== undefined ? props.readOnly : false;

  let availability = props.availability;
  let size = props.size;
  if (size === "xs") {
    size = "w-5";
  } else if (size === "sm") {
    size = "w-6";
  } else if (size === undefined) {
    size = "w-8 md:w-10";
  }
  if (!availability) {
    availability = {
      status: "Unknown",
    };
  }

  const originsArray = availability.origins ? availability.origins.split(", ") : [];
  const originSourcesArray = availability.sources ? availability.sources.split(", ") : [];

  const isOut =
    availability.status === "Injury" ||
    availability.status === "Suspension" ||
    availability.status === "Game-time decision" ||
    availability.status === "Out" ||
    availability.status === "Out for season"
      ? true
      : availability.status === "Available" || availability.status === "Unknown" || availability.status === ""
      ? false
      : undefined;

  const children = (
    <>
      {isOut ? (
        <img
          className={size + " relative"}
          src={
            availability.status === "Game-time decision"
              ? Injured25
              : availability.reliabilityScore >= 100
              ? Injured100
              : availability.reliabilityScore >= 75
              ? Injured75
              : Injured25
          }
        />
      ) : isOut === false ? (
        <img className={size + " relative"} src={availability.status === "Available" ? NoInjury : Unknown} />
      ) : null}
      {props.intl === true && (
        <div className={"relative"}>
          <img className={size + " relative"} src={IntlTrip} />
        </div>
      )}
    </>
  );

  if (readOnly) return children;

  const refParentPopper = useRef(null);
  let popperOffset = { x: 4, y: 4 };
  if (props.popperOffset) {
    popperOffset = props.popperOffset;
  }

  return (
    <div
      className={"relative flex flex-col gap-1 justify-center self-center cursor-default h-full"}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
      ref={refParentPopper}
    >
      {focus && (
        <PopperPortal active={focus} fixed={true} parentRef={refParentPopper} offset={popperOffset}>
          <div
            className={
              "flex flex-col border border-transparent-inverse-surface-low border-opacity-10 isolate z-30 bg-surface-container rounded-md shadow-lg text-sm text-on-surface"
            }
            style={{ minWidth: "13em" }}
          >
            {isOut ? (
              <div className={"flex flex-col gap-1 p-2"}>
                <div className="flex flex-col">
                  <p className="text-xs text-on-surface-variant font-semibold">STATUS</p>
                  <p className="font-semibold text-on-surface text-xs m-0">
                    {availability.description ? availability.status + " (" + availability.description + ")" : availability.status}
                  </p>
                </div>
                <div className="flex flex-col mt-1">
                  <p className="text-xs text-on-surface-variant font-semibold">SOURCE</p>
                  <p className="font-semibold text-on-surface text-xs m-0">
                    {originsArray.map((origin, i) => {
                      const separator = i !== originsArray.length - 1 ? ", " : "";
                      const originSource = originSourcesArray[i] || "";
                      const label = origin !== "Manual" ? origin : getHostnameFromURL(originSource) || "Confirmed";
                      if (originSource) {
                        return (
                          <span>
                            <a href={originSource} className={"text-primary hover:opacity-80"} target={"_blank"} rel="noreferrer">
                              {label}
                            </a>
                            {separator}
                          </span>
                        );
                      }
                      return (
                        <span>
                          {label}
                          {separator}
                        </span>
                      );
                    })}
                  </p>
                </div>
                <div className="flex flex-col mt-1">
                  <p className="text-xs text-on-surface-variant font-semibold">UNTIL</p>
                  <p className="font-semibold text-on-surface text-xs m-0">
                    {availability.until ? format(new Date(availability.until), "dd-MM-yyyy") : "Unknown"}
                  </p>
                </div>
                <div className="flex flex-col mt-1">
                  <p className="text-xs text-on-surface-variant font-semibold">UPDATED</p>
                  <p className="font-semibold text-on-surface text-xs m-0">
                    {availability.updatedAt ? formatDistance(new Date(availability.updatedAt), new Date(), { addSuffix: true }) : "Unknown"}
                  </p>
                </div>
              </div>
            ) : isOut === false ? (
              <p className="whitespace-nowrap p-2 w-auto font-semibold text-xs text-on-surface-variant">
                {availability.status === "Available" ? "No injury or suspension known" : "We do not know the health status of this player"}
              </p>
            ) : null}
          </div>
        </PopperPortal>
      )}
      {children}
    </div>
  );
}

export default PlayerAvailability;
