import React, { useEffect, useState } from "react";
import Scorers from "../teams/scorers";
import TeamStatsTable from "../teams/teamStatsTable";
import SelectSearch from "react-select-search";
import { all_positions_objects } from "../util/positions";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import SDLoading from "../util/SDLoading";
import { sorareBaseball, sorareFootball } from "../util/sports";
import BaseballTypePicker from "../util/baseballTypePicker";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";

function WatchlistPlayersRosterStats(props) {
  const watchlistId = props.watchlist?.id;
  const [rosterInfo, setRosterInfo] = useState({});
  const [nbGames, setNbGames] = useState(15);
  const [position, setPosition] = useState("all");
  const [loading, setLoading] = useState(false);

  const [baseballType, setBaseballType] = useState("hitting");

  useEffect(() => {
    getRosterStats();
  }, [props.watchlist]);

  useEffect(() => {
    getRosterStats();
  }, []);

  const getRosterStats = (nb, p) => {
    if (nb === undefined) nb = nbGames;
    if (p === undefined) p = position;
    if (watchlistId) {
      setLoading(true);
      props
        .fetch(`/apiv2/watchlists/id/${watchlistId}/players/aggregatedStats?games=${nb}&position=${p}`)
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          setRosterInfo(res);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  if (rosterInfo === undefined) {
    return null;
  }
  const sport = rosterInfo.sport;
  const topAssists = rosterInfo?.football_stats?.top_assists;
  const topAverages = rosterInfo?.football_stats?.top_averages;
  const topAllAround = rosterInfo?.football_stats?.top_all_around;
  const topDecisiveActions = rosterInfo?.football_stats?.top_decisive;
  const topMinutes = rosterInfo?.football_stats?.top_minutes;
  const topScorers = rosterInfo?.football_stats?.top_scorers;
  const topFK = rosterInfo?.football_stats?.direct_freekick_takers;
  const topPK = rosterInfo?.football_stats?.top_penalty_takers;
  const topFKA = rosterInfo?.football_stats?.top_deadball_assists;
  const topCorners = rosterInfo?.football_stats?.top_corner_takers;

  const topBattingAverages = rosterInfo?.baseball_stats?.top_averages;
  const topHomeRuns = rosterInfo?.baseball_stats?.top_home_runs;
  const topSingles = rosterInfo?.baseball_stats?.top_singles;
  const topDoubles = rosterInfo?.baseball_stats?.top_doubles;
  const topTriples = rosterInfo?.baseball_stats?.top_triples;
  const topRbis = rosterInfo?.baseball_stats?.top_rbis;
  const topWoba = rosterInfo?.baseball_stats?.top_woba;
  const topRuns = rosterInfo?.baseball_stats?.top_runs;
  const topWalks = rosterInfo?.baseball_stats?.top_walks;
  const topBattingStrikeouts = rosterInfo?.baseball_stats?.top_batting_strikeouts;
  const topStrikeouts = rosterInfo?.baseball_stats?.top_strikeouts;
  const topStolenBases = rosterInfo?.baseball_stats?.stolen_bases;
  const topWhip = rosterInfo?.baseball_stats?.top_whip;
  const topWalksAllowed = rosterInfo?.baseball_stats?.top_walks_allowed;
  const topFip = rosterInfo?.baseball_stats?.top_fip;
  const topEra = rosterInfo?.baseball_stats?.top_era;

  let colorStart = "";
  let colorEnd = "";
  if (colorStart === "") {
    colorStart = "#132D8F";
    colorEnd = "#1D3EBD";
  }

  const active = "relative inline-flex items-center px-4 py-2 border bg-brand-pastel text-sm font-medium text-gray-700 focus:outline-none";
  const inactive =
    "relative inline-flex items-center px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-brand-pastel focus:outline-none";
  if (sport === "multi") {
    return <p>Player statistics are not available for multi sport watchlists</p>;
  }
  if (
    (sport === sorareFootball && rosterInfo.football_stats.aggregated_stats) ||
    (sport === sorareBaseball && rosterInfo.baseball_stats.aggregated_stats)
  ) {
    return (
      <div className={"space-y-6"}>
        <div className={"space-y-4"}>
          <div className={"flex flex-row justify-between"}>
            <p className={"text-brand font-semibold font-headers text-xl"}>Top players by category</p>
            {sport == sorareBaseball && (
              <div className={"flex flex-row justify-end"}>
                <BaseballTypePicker type={baseballType} change={(t) => setBaseballType(t)} />
              </div>
            )}
            {sport == sorareFootball && (
              <div className={"flex flex-row space-x-4"}>
                <div>
                  <SelectSearch
                    className={"select-search z-20"}
                    options={all_positions_objects}
                    printOptions={"on-focus"}
                    value={position}
                    closeOnSelect={true}
                    onChange={(v) => {
                      setPosition(v);
                      getRosterStats(undefined, v);
                    }}
                  />
                </div>
                <div>
                  <span className="relative z-0 inline-flex rounded-md">
                    <button
                      onClick={() => {
                        setNbGames(5);
                        getRosterStats(5);
                      }}
                      type="button"
                      className={nbGames === 5 ? "rounded-l-lg " + active : "rounded-l-lg " + inactive}
                    >
                      Last 5
                    </button>
                    <button
                      onClick={() => {
                        setNbGames(15);
                        getRosterStats(15);
                      }}
                      type="button"
                      className={nbGames === 15 ? active : inactive}
                    >
                      Last 15
                    </button>
                    <button
                      onClick={() => {
                        setNbGames(40);
                        getRosterStats(40);
                      }}
                      type="button"
                      className={nbGames === 40 ? "rounded-r-lg " + active : "rounded-r-lg " + inactive}
                    >
                      Last 40
                    </button>
                  </span>
                </div>
              </div>
            )}
          </div>
          {sport === sorareFootball && (
            <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"}>
              <Scorers scorers={topAverages} title={"Top SO5 averages"} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topAllAround} title={"All-around averages"} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topDecisiveActions} title={"Top net decisive actions"} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topMinutes} title={"Top minutes played"} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topScorers} title={"Top goalscorers"} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topAssists} title={"Top assists"} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topPK} title={"Penalty kicks"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topFK} title={"Freekick scorers"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topFKA} title={"Freekick assists"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
              <Scorers scorers={topCorners} title={"Corner takers"} bgStart={colorStart} bgEnd={colorEnd} />
            </div>
          )}
          {sport === sorareBaseball && (
            <div className={"w-full space-y-4"}>
              {baseballType === "hitting" && (
                <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 gap-4"}>
                  <Scorers
                    scorers={topBattingAverages}
                    title={"Batting averages"}
                    bgStart={colorStart}
                    bgEnd={colorEnd}
                    baseball={true}
                    showPct={true}
                    sport={sport}
                  />
                  <Scorers scorers={topWoba} title={"wOBA"} bgStart={colorStart} bgEnd={colorEnd} baseball={true} sport={sport} />
                  <Scorers scorers={topHomeRuns} title={"Home runs"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topRbis} title={"RBIs"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topStolenBases} title={"Stolen Bases"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topRuns} title={"Runs"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topBattingStrikeouts} title={"Strikeouts"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topWalks} title={"Walks"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topTriples} title={"Triples"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topDoubles} title={"Doubles"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                  <Scorers scorers={topSingles} title={"Singles"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                </div>
              )}
              {baseballType === "pitching" && (
                <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 gap-4"}>
                  <Scorers scorers={topEra} title={"ERA"} bgStart={colorStart} bgEnd={colorEnd} precision={2} sport={sport} />
                  <Scorers scorers={topFip} title={"FIP"} bgStart={colorStart} bgEnd={colorEnd} precision={2} sport={sport} />
                  <Scorers scorers={topWhip} title={"WHIP"} bgStart={colorStart} bgEnd={colorEnd} precision={3} sport={sport} />
                  <Scorers scorers={topStrikeouts} title={"Strikeouts"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                </div>
              )}
            </div>
          )}
        </div>
        {isFree(props.user.tier) && !(sport === sorareBaseball) && (
          <UpgradeLimitBox
            className={"bg-white bg-upgrade-bg-scorers-stats bg-cover h-upgrade-banner-l w-full my-4"}
            title={"Want more insights?"}
            description={"Become a Star member to unlock all top player stats."}
          />
        )}
        <div className={"space-y-4"}>
          <TeamStatsTable
            sport={sport}
            comps={[{ name: "All competitions", value: "all" }]}
            watchlistId={watchlistId}
            stats={
              sport === sorareFootball
                ? rosterInfo.football_stats.aggregated_stats
                : sport === sorareBaseball
                ? rosterInfo.baseball_stats.aggregated_stats
                : []
            }
          />
        </div>
      </div>
    );
  } else {
    if (loading) {
      return <SDLoading />;
    }
    return null;
  }
}

export default withUser(WatchlistPlayersRosterStats);
