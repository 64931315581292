import React from "react";
import { getScarcityInfo, scarcities_objects_not_all } from "../util/scarcities";
import { formatDistanceToNowStrict } from "date-fns";
import { findPriceForUserUnit } from "../util/formatMoney";
import { withUser } from "../../userContext";
import clsx from "clsx";
import { PriceAlertDeleteButton, PriceAlertDeletePlayerAlertsButton, PriceAlertEditButton } from "./priceAlertModal";
import { editions, seasons } from "./enum";
import SDLoading from "../util/SDLoading";

export const PriceAlertSummary = withUser((props) => {
  const { priceAlerts, refreshAlerts, playerId, playerName, loading, setLoading } = props;
  return (
    <div className="lg:min-w-[30rem] px-5 space-y-4">
      <div className="flex justify-between">
        <span className="text-on-surface-variant text-base font-semibold">{priceAlerts?.length ?? 0} alerts for this player</span>
        {priceAlerts?.length > 0 && (
          <PriceAlertDeletePlayerAlertsButton playerId={playerId} onDeleteCallback={refreshAlerts} setLoading={setLoading} />
        )}
      </div>
      {loading ? (
        <div className="py-10">
          <SDLoading />
        </div>
      ) : (
        <div className="space-y-2">
          {priceAlerts?.map((alert) => (
            <PriceAlertSummaryItem
              key={alert.alertConfigId}
              alert={alert}
              preferredUnit={props.user.preferredUnit}
              playerName={playerName}
              playerId={playerId}
              refreshAlerts={refreshAlerts}
              setLoading={setLoading}
            />
          ))}
        </div>
      )}
    </div>
  );
});

export const PriceAlertSummaryItem = (props) => {
  const { alert, preferredUnit, playerId, playerName, hideActions } = props;
  if (!alert) {
    return null;
  }

  const refreshAlerts = props.refreshAlerts || (() => undefined);
  const setLoading = props.setLoading || (() => undefined);

  const { color: scarcityColor, bgColor: scarcityBgColor, Icon } = getScarcityInfo(alert.scarcity);
  const tagClassName = {
    container: "text-xs font-semibold rounded-full px-2 py-1",
    defaultColor: "bg-transparent-inverse-surface-low bg-opacity-10 text-on-surface-variant",
  };
  let listingTypeName = "";
  switch (alert.listingType) {
    case "auction":
      listingTypeName = "Auctions";
      break;
    case "offer":
      listingTypeName = "Live offers";
      break;
  }
  const scarcityName = scarcities_objects_not_all.find((scarcity) => alert.scarcity === scarcity.value)?.name;
  let priceFilterName = "";
  const priceFiltersCount = Object.values(alert.priceFilters ?? {}).length;
  if (priceFiltersCount === 1) {
    switch (Object.values(alert.priceFilters)?.[0]?.priceType) {
      case "last_avg":
        priceFilterName = "Below latest valuation";
        break;
      case "floor":
        priceFilterName = "Below floor price";
        break;
      case "custom":
        priceFilterName = "Below custom price";
        break;
    }
  }
  if (priceFiltersCount > 1) {
    priceFilterName = "Dynamic price";
  }
  const title = [listingTypeName, priceFilterName, scarcityName].filter((item) => item).join(" · ");
  const yearSeasons = alert?.seasons?.filter((season) => season !== "IN_SEASON" && season !== "CLASSIC");
  const splitSeasons = alert?.seasons?.filter((season) => season === "IN_SEASON" || season === "CLASSIC");
  return (
    <div key={alert.alertConfigId} className="flex flex-col bg-surface-container rounded-md space-y-2">
      <div className="grid grid-cols-4">
        <div className="col-span-3">
          <div className="flex items-center p-2 space-x-1">
            <Icon className="h-5 w-5" />
            <span className="text-on-surface font-semibold text-sm">{title}</span>
          </div>
          <div className="flex items-center px-2 space-x-1">
            {alert.computedPrice?.eth > 0 && (
              <span className={clsx(scarcityColor, scarcityBgColor, tagClassName.container)}>
                {findPriceForUserUnit(alert.computedPrice.eth, alert.computedPrice, preferredUnit)}
              </span>
            )}
            <span className={clsx(tagClassName.container, tagClassName.defaultColor)}>
              {scarcities_objects_not_all.find((scarcity) => alert.scarcity === scarcity.value)?.name}
            </span>
            {splitSeasons?.length === 1 && yearSeasons?.length === 0 && (
              <span className={clsx(tagClassName.container, tagClassName.defaultColor)}>
                {seasons.find((season) => splitSeasons[0] === season.value)?.name}
              </span>
            )}
            {yearSeasons?.length === 1 && <span className={clsx(tagClassName.container, tagClassName.defaultColor)}>{yearSeasons[0]}</span>}
            {alert?.editions?.length === 1 && (
              <span className={clsx(tagClassName.container, tagClassName.defaultColor)}>
                {editions.find((edition) => alert.editions[0] === edition.value)?.name}
              </span>
            )}
          </div>
        </div>
        {!hideActions && (
          <div className="flex items-center justify-around pt-2 px-2">
            <PriceAlertEditButton
              priceAlert={alert}
              playerId={playerId}
              playerName={playerName}
              onSubmitCallback={refreshAlerts}
              roundedButton
            />
            <PriceAlertDeleteButton
              alertConfigId={alert.alertConfigId}
              onDeleteCallback={refreshAlerts}
              setLoading={setLoading}
              roundedButton
            />
          </div>
        )}
      </div>
      <span className="h-1 w-full border-b border-outline-variant" />
      <span className="text-xs text-on-surface-variant font-medium p-2">
        Triggered {alert.lastSent ? formatDistanceToNowStrict(new Date(alert.lastSent), { addSuffix: true }) : "Never"}
        &nbsp;·&nbsp; Edited {alert.lastUpdated ? formatDistanceToNowStrict(new Date(alert.lastUpdated), { addSuffix: true }) : "Never"}
      </span>
    </div>
  );
};
