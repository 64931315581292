import React from "react";
import { ReactComponent as LockReset } from "@material-design-icons/svg/filled/lock.svg";
import clsx from "clsx";

// Ensure the parent element has the 'relative' class for proper icon positioning.
export const PaywallIcon = (props) => {
  const paywalledMessage = props.paywalledMessage || "Star membership is needed";
  const rightPosition = props.rightPosition || "right-0";

  return (
    <a href={"/product"}>
      <div
        title={paywalledMessage}
        className={clsx(rightPosition, "absolute -top-1 bg-surface-container-high p-0.5 rounded-full z-10 shadow")}
      >
        <LockReset className={"w-3 h-3 fill-on-surface-variant"} />
      </div>
    </a>
  );
};
