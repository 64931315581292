import React from "react";
import HomeDashboardEntry from "./homeDashboardEntry";
import { checkSecondUnitShouldBeDisplayed, formatPrice } from "../util/formatMoney";
import { withUser } from "../../userContext";

function HomeDashboard(props) {
  const dashboard = props.dashboard;

  const shouldSecondUnitShouldBeDisplayed = checkSecondUnitShouldBeDisplayed(props.user.preferredUnit, props.user.secondUnit);

  let chooseVolumeCurrency = (volumes, secondary) => {
    switch (secondary ? props.user.secondUnit : props.user.preferredUnit) {
      case "eth":
        return volumes.volume;
      case "eur":
        return volumes.volume_eur;
      case "usd":
        return volumes.volume_usd;
      case "gbp":
        return volumes.volume_gbp;
    }
  };

  return (
    <div
      className={
        "grid grid-cols-2 grid-rows-auto md:grid-cols-3 md:grid-rows-auto lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 " +
        " gap-4 xl:space-y-0 gap-4 2xl:gap-4 w-full"
      }
    >
      {dashboard.primary_market_volumes_today && (
        <HomeDashboardEntry
          value={formatPrice(chooseVolumeCurrency(dashboard.primary_market_volumes_today), props.user.preferredUnit, undefined, 2)}
          variation={dashboard.variation_yesterday_primary_market}
          description={"Primary market volume 24h"}
        />
      )}
      {dashboard.offer_volumes_today && (
        <HomeDashboardEntry
          value={formatPrice(chooseVolumeCurrency(dashboard.offer_volumes_today), props.user.preferredUnit, undefined, 2)}
          variation={dashboard.variation_yesterday_offer}
          description={"Offer volume 24h"}
        />
      )}
      {dashboard.primary_market_all_time_volume && (
        <HomeDashboardEntry
          value={formatPrice(chooseVolumeCurrency(dashboard.primary_market_all_time_volume), props.user.preferredUnit, undefined, 0)}
          description={"Primary market volume"}
          secondText={
            shouldSecondUnitShouldBeDisplayed &&
            formatPrice(chooseVolumeCurrency(dashboard.primary_market_all_time_volume, true), props.user.secondUnit, undefined, 0)
          }
        />
      )}
      {dashboard.offer_volume_alltime && (
        <HomeDashboardEntry
          value={formatPrice(chooseVolumeCurrency(dashboard.offer_volume_alltime), props.user.preferredUnit, undefined, 0)}
          description={"Offer volume"}
          secondText={
            shouldSecondUnitShouldBeDisplayed &&
            formatPrice(chooseVolumeCurrency(dashboard.offer_volume_alltime, true), props.user.secondUnit, undefined, 0)
          }
        />
      )}
    </div>
  );
}

export default withUser(HomeDashboard);
