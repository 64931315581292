import React, { useEffect, useState } from "react";
import UnknownClub from "../../img/unknown_club.png";
import ordinal_suffix_of_no_number from "../util/ordinal_suffix_no_number";
import { withUser } from "../../userContext";
import HomeIcon from "../../img/homeIcon.svg";
import AwayIcon from "../../img/awayIcon.svg";
import { useElementSize } from "usehooks-ts";
import PopHoverWrapper from "../util/popHoverWrapper";
import { urlNoCache } from "../util/url";
import { format } from "date-fns";

function OddsPlaceholder(props) {
  let bold = "font-normal";
  if (props.favorite) {
    bold = "font-bold";
  }
  let bg = "bg-surface-container-highest";
  if (props.bg) {
    bg = props.bg;
  }
  let value = props.odds.toFixed(0);
  if (props.smallStyle) {
    bg = props.bg || "bg-surface-container";
    if (props.favorite) {
      bg = "bg-containers-surface-focus-active-primarytint";
      bold = "text-primary font-bold";
    } else {
      bold = "text-on-surface-variant font-normal";
    }
    return (
      <div className={bg + " py-1 px-0.5 rounded self-center"}>
        <p className={"self-center text-xxs self-center text-center " + bold}>
          {value}
          <span className={"text-xxxs"}>%</span>
        </p>
      </div>
    );
  } else if (props.mediumStyle) {
    bg = props.bg || "bg-surface-container";
    if (props.favorite) {
      bg = "bg-containers-surface-focus-active-primarytint";
      bold = "text-primary font-bold";
    } else {
      bg = "bg-surface-container-highest";
      bold = "text-on-surface-variant font-normal";
    }
    return (
      <div className={bg + " py-2 px-1 rounded self-center"}>
        <p className={"self-center text-xs self-center text-center " + bold} style={{ fontSize: "min(max(0.65vw, 0.5em), 0.75em)" }}>
          {value}
          <span className={"text-xxs"}>%</span>
        </p>
      </div>
    );
  } else {
    return (
      <div className={bg + " p-1 rounded self-center"}>
        <p className={"self-center text-xs xl:text-xxs 2xl:text-xs self-center text-center text-on-surface-variant " + bold}>{value}%</p>
      </div>
    );
  }
}

function OddsFromGame(props) {
  const odds = props.odds;
  let home = (1 / odds.home) * 100;
  let draw = (1 / odds.draw) * 100;
  let away = (1 / odds.away) * 100;
  let total = home + draw + away;
  home = (home * 100) / total;
  away = (away * 100) / total;
  draw = (draw * 100) / total;
  return (
    <div className={"flex flex-row gap-[5%] justify-center self-center h-6"}>
      <OddsPlaceholder
        smallStyle={props.smallStyle}
        mediumStyle={props.mediumStyle}
        odds={home}
        favorite={props.favorite === "home"}
        bg={props.bg}
      />
      <OddsPlaceholder
        smallStyle={props.smallStyle}
        mediumStyle={props.mediumStyle}
        odds={draw}
        favorite={props.favorite === "draw"}
        bg={props.bg}
      />
      <OddsPlaceholder
        smallStyle={props.smallStyle}
        mediumStyle={props.mediumStyle}
        odds={away}
        favorite={props.favorite === "away"}
        bg={props.bg}
      />
    </div>
  );
}

function GameInLine(props) {
  const odds = props.odds;
  let home;
  let away;
  if (props.game.HomeTeamId === props.team1.TeamId) {
    home = props.team1;
    away = props.team2;
  } else {
    home = props.team2;
    away = props.team1;
  }
  let isHome = true;
  let homeStandings = props.standings?.[home.TeamId + home.LeagueSlug];
  let awayStandings = props.standings?.[away.TeamId + away.LeagueSlug];
  let homeRank = homeStandings?.rank > 0 ? homeStandings?.rank : "";
  let awayRank = awayStandings?.rank > 0 ? awayStandings?.rank : "";
  if (away.TeamId === props.playerTeam || away.TeamId === props.playerNationalTeam) isHome = false;
  let divClass = "w-5 h-7 md:w-6 md:h-8 xl:w-5 xl:h-7 2.5xl:w-6 2.5xl:h-8 flex flex-row justify-center rounded-full self-center";
  let imgClass = "w-full h-full object-contain";
  const smallStyle = props.smallStyle;
  const mediumStyle = props.mediumStyle;
  if (props.game.GameId !== "") {
    let tipMessage;
    if (props.game.GameTime) {
      tipMessage =
        "<p class='text-center'><b>" +
        home.ClubName +
        " - " +
        away.ClubName +
        "</b><br/>" +
        format(new Date(props.game.GameTime), "PPP") +
        " at " +
        format(new Date(props.game.GameTime), "p") +
        "</p>";
    } else {
      tipMessage = "<p class='text-center'><b>" + home.ClubName + " - " + away.ClubName + "</b></p>";
    }
    return (
      <div className={"space-y-2 cursor-pointer text-on-surface-variant"} data-html={true} data-tip={tipMessage}>
        <div className={"flex flex-row space-x-1 justify-center items-center text-on-surface-variant"}>
          {!smallStyle && !mediumStyle && (
            <a href={"/team/" + home.TeamId} target={"_blank"} rel="noreferrer">
              <span className={"font-bold self-center"}>
                <span className={"text-xs xl:text-xxs 2xl:text-xs"}>{homeRank}</span>
                <span className={"text-xxs 2xl:text-xxs xl:text-xxxs"}>{homeRank !== "" && ordinal_suffix_of_no_number(homeRank)}</span>
              </span>
            </a>
          )}
          <div className={divClass}>
            <a href={"/team/" + home.TeamId} target={"_blank"} rel="noreferrer">
              <img
                className={imgClass}
                onError={(e) => {
                  e.target.src = UnknownClub;
                }}
                src={home.PictureUrl ? home.PictureUrl : UnknownClub}
              />
            </a>
          </div>
          <span className={"text-xs self-center font-bold w-1"}>-</span>
          <div className={divClass}>
            <a href={"/team/" + away.TeamId} target={"_blank"} rel="noreferrer">
              <img
                className={imgClass}
                onError={(e) => {
                  e.target.src = UnknownClub;
                }}
                src={away.PictureUrl ? away.PictureUrl : UnknownClub}
              />
            </a>
          </div>
          {!smallStyle && !mediumStyle && (
            <a href={"/team/" + away.TeamId} target={"_blank"} rel="noreferrer">
              <span className={"font-bold self-center"}>
                <span className={"text-xs xl:text-xxs 2xl:text-xs"}>{awayRank}</span>
                <span className={"text-xxs 2xl:text-xxs xl:text-xxxs"}>{awayRank !== "" && ordinal_suffix_of_no_number(awayRank)}</span>
              </span>
            </a>
          )}
        </div>
        {odds && (
          <OddsFromGame mediumStyle={mediumStyle} smallStyle={smallStyle} odds={odds} favorite={isHome ? "home" : "away"} bg={props.bg} />
        )}
        {!odds && props.under && (
          <div className={"flex flex-row space-x-1 justify-center h-6"}>
            <p className={"text-xxs font-semibold self-center"}>{smallStyle ? "Available soon" : "Odds available soon"}</p>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={"space-y-0.5 py-5"}>
        <p className={"text-xs font-semibold text-center self-center py-0.5"}>No game 😭</p>
      </div>
    );
  }
}

export const GameInLineVsWithDetails = (props) => {
  return (
    <PopHoverWrapper hoverTitle="Game odds" hover={<GameInLine {...props} />}>
      <div className={"flex justify-center items-center"}>
        <GameInLineVs {...props} />
      </div>
    </PopHoverWrapper>
  );
};

export const GameInLineVs = (props) => {
  const [refContainer, { width }] = useElementSize();
  const [refInternalContainer, { width: widthInternal }] = useElementSize();
  const [fontSize, setFontSize] = useState(0);
  const [noGameFontSize, setnoGameFontSize] = useState(0);

  useEffect(() => {
    setFontSize(widthInternal / 5);
  }, [widthInternal]);

  useEffect(() => {
    setnoGameFontSize(width / 5);
  }, [width]);

  const odds = props.odds;
  if (props.game.GameId) {
    let home;
    let away;
    if (props.game.HomeTeamId === props.team1.TeamId) {
      home = props.team1;
      away = props.team2;
    } else {
      home = props.team2;
      away = props.team1;
    }
    let isHome = true;
    if (away.TeamId === props.playerTeam || away.TeamId === props.playerNationalTeam) isHome = false;
    let clubOdds, homeOdds, drawOdds, awayOdds;
    if (odds) {
      homeOdds = (1 / odds.home) * 100;
      drawOdds = (1 / odds.draw) * 100;
      awayOdds = (1 / odds.away) * 100;
      let total = homeOdds + drawOdds + awayOdds;
      homeOdds = (homeOdds * 100) / total;
      drawOdds = (drawOdds * 100) / total;
      awayOdds = (awayOdds * 100) / total;
      clubOdds = isHome ? homeOdds : awayOdds;
    }
    let title = home.ClubName + " vs " + away.ClubName;
    if (clubOdds && homeOdds && drawOdds && awayOdds) {
      title += `\n${homeOdds.toFixed(0)}% - ${drawOdds.toFixed(0)}% - ${awayOdds.toFixed(0)}%`;
    }
    // When exporting the lineup to an image, all images loaded from external domain have to be loaded with a random query string to avoid caching + using crossOrigin="anonymous" to avoid CORS
    // see: https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
    const homePicture = (props.forExport ? urlNoCache(home.PictureUrl) : home.PictureUrl) || UnknownClub;
    const awayPicture = (props.forExport ? urlNoCache(away.PictureUrl) : away.PictureUrl) || UnknownClub;
    return (
      <div
        ref={refContainer}
        className={"flex flex-row gap-1.5 text-on-surface-variant font-semibold justify-center w-full"}
        title={props.withTitle ? title : ""}
      >
        <div ref={refInternalContainer} className={"grid grid-cols-3 grid-rows-1 justify-center items-center w-full"}>
          <div className={"flex flex-row justify-center items-center"}>
            <img className={"h-5 w-5 mr-0.5"} src={isHome ? HomeIcon : AwayIcon} />
          </div>
          <div className={"flex flex-row justify-center items-center text-center"}>
            <span className={"my-auto"} style={{ fontSize: fontSize }}>
              vs
            </span>
          </div>
          <div className={"flex flex-row justify-center items-center"}>
            {props.forExport ? (
              <img
                crossOrigin="anonymous"
                className={"w-5 h-5 object-contain"}
                src={isHome ? awayPicture : homePicture}
                onError={(e) => {
                  e.target.src = UnknownClub;
                }}
              />
            ) : (
              <img className={"w-5 h-5 object-contain"} src={isHome ? away.PictureUrl || UnknownClub : home.PictureUrl || UnknownClub} />
            )}
          </div>
        </div>
        {odds && clubOdds && (
          <div className={"flex justify-center items-center text-center w-[25%]"}>
            <span style={{ lineHeight: 1, fontSize: fontSize }}>{clubOdds.toFixed(0)}%</span>
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      ref={refContainer}
      className={"flex flex-row justify-center items-center text-on-surface-variant font-semibold w-[70%]"}
      style={{ fontSize: noGameFontSize }}
    >
      <span>No game</span>
    </div>
  );
};

export default withUser(GameInLine);
