import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import React from "react";

function GamesPlayed(props) {
  let played = props.played;
  let period = props.period;
  let size = ((played * 100) / period).toFixed(0) + "%";
  let sizeNb = (played * 100) / period;
  let bg = "bg-super-rare";
  if (sizeNb < 30) {
    bg = "bg-rare";
  } else if (sizeNb < 70) {
    bg = "bg-limited";
  }
  const tier = props.tier;
  let tip = size + " games played over the past " + period + " games";
  if (isFree(tier) && !props.showPct && props.sport === sorareFootball) {
    tip = "Star members see games played directly below.";
  }
  let label = size;
  let labelStyle = "text-center font-semibold text-on-surface-variant text-xxs";
  if (props.label) {
    label = props.label;
    labelStyle = props.labelStyle;
  }
  if (props.sport === sorareBasketball) {
    //bg = "bg-super-rare"
    tip = props.played + " minutes played on average over the past " + props.gms + " games";
  }
  tip = props.tip || tip;
  return (
    <div className={"space-y-0.5 self-center"}>
      <div className="cursor-pointer w-full bg-grey-e5 rounded-full h-1" data-tip={tip}>
        <div className={bg + " h-1 rounded-full"} style={{ width: size }} />
      </div>
      {(t1OrAbove(tier) || props.showPct || props.sport === sorareBaseball || props.sport === sorareBasketball) && (
        <p className={labelStyle}>{label}</p>
      )}
    </div>
  );
}

export default GamesPlayed;
