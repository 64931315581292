import React, { useEffect, useState } from "react";
import { Anchor, Box, Heading } from "grommet";
import ManagerStats from "./managerStats";
import LatestManagerSignings from "./latestManagerSignings";
import ManagerCardsWrapper from "./managerCardsWrapper";
import { ReactTooltip } from "../util/tooltip.js";
import ManagerSO5Stats from "./managerSO5Stats";
import PriceList from "./priceList";
import GLOBAL_MARGIN from "../util/margin";
import SO5Wrapper from "../SO5/SO5Wrapper";
import RewardsIcon from "../../img/rewards-icon.svg";
import VictoriesIcon from "../../img/victories-icon.svg";
import { withUser } from "../../userContext";
import { checkSecondUnitShouldBeDisplayed, formatPrice } from "../util/formatMoney";
import CardCount from "../players/cardCount";
import SDLoading from "../util/SDLoading";
import ManagerRosterStats from "./managerRosterStats";
import ManagerTransactions from "./managerTransactions";
import { errorCatcher } from "../util/errors";
import { Link } from "react-router-dom";
import FavoriteBubble from "../util/favoriteBubble";
import EnabledWallets from "../util/enabledWallets";
import ManagerNewsInjuries from "./managerNewsInjuries";
import Visibility from "../../img/visibility.svg";
import VisibilityOff from "../../img/visibility_off.svg";
import ManagerCollections from "./managerCollections";
import { ManagerVerifiedCheckMark } from "../util/manager";

export const RosterValuation = withUser((props) => {
  const { info, loading, primaryUnit, secondaryUnit, onHideValuation, manager, hideValuations } = props;
  const shouldSecondaryBeDisplayed = checkSecondUnitShouldBeDisplayed(primaryUnit, secondaryUnit);
  return (
    <div className="relative bg-surface-container pt-4 px-4 pb-4 shadow rounded-lg overflow-hidden h-full">
      <div className={"flex flex-row space-x-2 self-center"}>
        <p className="text-sm font-medium text-on-surface-variant truncate">Roster valuation</p>
        <img
          className={"cursor-pointer w-4 self-center"}
          onClick={() => onHideValuation && onHideValuation()}
          src={hideValuations ? VisibilityOff : Visibility}
        />
      </div>
      <dd className="pb-6 flex items-baseline pt-2">
        {loading || hideValuations ? (
          <div
            role="status"
            className={
              hideValuations ? "max-w-sm flex flex-col gap-2 my-1 w-full" : "max-w-sm animate-pulse flex flex-col gap-2 my-1 w-full"
            }
          >
            <div className="h-6 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-full"></div>
            <div className="h-5 bg-grey-e5 rounded-lg dark:bg-grey-f2 3/4"></div>
          </div>
        ) : (
          <div className={"space-y-1"}>
            <p className="text-2xl font-semibold text-gray-900">{formatPrice(info.roster_valuation[primaryUnit], primaryUnit)}</p>
            {shouldSecondaryBeDisplayed && (
              <p className="text-base font-semibold text-gray-700">{formatPrice(info.roster_valuation[secondaryUnit], secondaryUnit)}</p>
            )}
          </div>
        )}
        {manager && (
          <Link to={`/manager/${manager}/sections/roster-prices`}>
            <div className="absolute bottom-0 inset-x-0 hover:bg-gray-200 bg-gray-100 px-2 py-2 sm:px-4 cursor-pointer">
              <div className="text-xs">
                <a className="font-medium text-indigo-600 hover:text-indigo-500">Card-by-card details</a>
              </div>
            </div>
          </Link>
        )}
      </dd>
    </div>
  );
});

function ManagerPage(props) {
  const activeTab = props.match.params.tab || "players";
  const [managerInfo, setManagerInfo] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    getManagerShortInfo();
    getManagerInfo();
    ReactTooltip.rebuild();
  }, [props.match.params.manager]);

  const getManagerInfo = () => {
    const params = new URLSearchParams();
    params.append("withCardCount", "true");
    params.append("withRosterValuation", "true");
    params.append("withBestCard", "true");
    params.append("withWinningPct", "true");
    props
      .fetch("/apiv2/manager/info/" + props.match.params.manager + "?" + params.toString())
      .then((response) => response.json())
      .then((res) => {
        setInfo(res);
      })
      .catch(errorCatcher());
  };

  const getManagerShortInfo = () => {
    props
      .fetch("/apiv2/manager/info/" + props.match.params.manager)
      .then((response) => response.json())
      .then((res) => {
        setManagerInfo(res.manager);
        document.title = res.manager.TeamName + " - Gallery";
      })
      .catch(errorCatcher());
  };

  const setHideValuations = () => {
    props
      .fetch("/apiv2/user/changeHideValuations", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: !props.user.hideValuations,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        props.changeHideValuations(!props.user.hideValuations);
      })
      .catch(errorCatcher());
  };

  let manager = null;
  if (managerInfo !== null) {
    manager = managerInfo;
  }
  const loading = info === null;
  const inactiveClass =
    "flex flex-row items-center xl:text-sm 2xl:text-base text-base border-b-4 border-brand-black py-2 px-6 block font-medium text-textGrey4 hover:text-white focus:outline-none";
  const activeClass =
    "flex flex-row items-center xl:text-sm 2xl:text-base  text-base border-b-4 border-brand py-2 px-6 font-medium focus:outline-none";
  const primaryUnit = props.user.preferredUnit || "usd";
  const secondaryUnit = props.user.secondUnit || "";
  return (
    <div>
      {managerInfo === null ? (
        <Box margin={{ vertical: "medium" }} align={"center"}>
          <SDLoading />
        </Box>
      ) : (
        <div>
          <div className={"bg-background-grey-lineup py-4"}>
            <div className={"xl:w-11/12 2xl:w-10/12 4xl:w-8/12 mx-auto flex flex-col xl:flex-row space-x-6"}>
              <div className={"flex flex-col md:flex-row space-y-4 md:space-y-0 lg:space-x-8 xl:w-5/12 lg:mx-auto md:justify-center"}>
                <div className={"flex justify-center space-x-4 xl:w-5/12 2xl:w-6/12  md:w-6/12"}>
                  <div className={"flex lg:w-32 lg:h-32 w-24 h-24 justify-center self-center"}>
                    <img src={manager?.PictureUrl} className={"object-contain"} />
                  </div>
                  <div className={"flex flex-col self-center lg:w-40 gap-2"}>
                    <div>
                      <div className={"text-2xl font-semibold flex flex-row justify-between gap-1"}>
                        <span>{manager?.Nickname}</span>
                        <ManagerVerifiedCheckMark managerSlug={manager?.Slug} />
                        <span className={"w-7 h-7 m-auto ml-1"} style={{ minWidth: "1em" }}>
                          <FavoriteBubble shadow type={"manager"} manager={props.match.params.manager} />
                        </span>
                      </div>
                      <p className={"text-title-grey text-base font-semibold"}>{manager?.TeamName}</p>
                    </div>
                    <p className={"text-sm"}>Since {new Date(manager.CreationDate).toLocaleDateString()}</p>
                    {!!manager.EnabledWallets && <EnabledWallets enabledWallets={manager.EnabledWallets} />}
                  </div>
                </div>

                <div className={"flex flex-col xl:w-7/12 2xl:w-6/12 self-center space-y-4 divide-y-2 divide-solid md:pl-12"}>
                  <div className={"flex flex-row space-x-4 justify-center 2xl:justify-start 2xl:self-start self-center text-base w-full"}>
                    {loading ? (
                      <div role="status" className="max-w-sm animate-pulse flex flex-col w-full">
                        <div className="h-4 bg-grey-e0 rounded-lg dark:bg-grey-e9 w-full"></div>
                      </div>
                    ) : (
                      <CardCount
                        space={"space-x-3"}
                        limitedCount={info.cards_count.limited_cards}
                        rareCount={info.cards_count.rare_cards}
                        superRareCount={info.cards_count.super_rare_cards}
                        uniqueCount={info.cards_count.unique_cards}
                        customSeriesCount={info.cards_count.custom_series_cards}
                      />
                    )}
                  </div>
                  <div className={"flex flex-row space-x-4 justify-center 2xl:justify-start 2xl:self-start self-center pt-4"}>
                    <div className={"flex flex-row space-x-2"} data-tip={"Total rewards won"}>
                      <img className={"self-center"} src={RewardsIcon} />
                      {loading ? (
                        <div role="status" className="max-w-sm animate-pulse flex flex-col w-7">
                          <div className="h-5 bg-grey-e0 rounded-lg dark:bg-grey-e9 w-full"></div>
                        </div>
                      ) : (
                        <p className={"text-base text-brand font-semibold"}>{info.winning_pct.won_cards}</p>
                      )}
                    </div>
                    <div className={"flex flex-row space-x-2"} data-tip={"Total SO5 victories"}>
                      <img className={"self-center"} src={VictoriesIcon} />
                      {loading ? (
                        <div role="status" className="max-w-sm animate-pulse flex flex-col w-7">
                          <div className="h-5 bg-grey-e0 rounded-lg dark:bg-grey-e9 w-full"></div>
                        </div>
                      ) : (
                        <p className={"text-base text-brand font-semibold"}>{info.winning_pct.victories}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex xl:justify-end md:justify-center pb-2 2xl:w-7/12 md:space-x-8 space-x-4 mt-4 md:pb-2 2xl:self-center self-start overflow-hidden no-scroll-bar overflow-x-scroll max-w-full w-11/12"
                }
              >
                <div>
                  <RosterValuation
                    info={info}
                    loading={loading}
                    primaryUnit={primaryUnit}
                    secondaryUnit={secondaryUnit}
                    onHideValuation={setHideValuations}
                    manager={props.match.params.manager}
                    hideValuations={props.user.hideValuations}
                  />
                </div>
                <div>
                  <div className="relative bg-white pb-4 px-4 pt-4 sm:px-4 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md">
                        {loading ? (
                          <div role="status" className="w-20 h-24 animate-pulse flex flex-col">
                            <div className="bg-grey-e5 rounded-lg dark:bg-grey-f2 w-16 h-full mb-2"></div>
                          </div>
                        ) : (
                          <a href={"/card/" + info?.best_card.card.TokenId}>
                            <div className={"flex w-24 h-24 pl-1"}>
                              <img className={"object-contain"} src={info?.best_card.card.PictureUrl} />
                            </div>
                          </a>
                        )}
                      </div>
                      <p className="ml-20 text-sm font-medium text-gray-500 truncate">Best SO5 card</p>
                    </dt>
                    <dd className="ml-20 pb-5 flex flex-col items-baseline pt-2">
                      <div className={"w-24"}>
                        {loading ? (
                          <div role="status" className={"max-w-sm animate-pulse flex flex-col gap-2 my-1 w-full"}>
                            <div className="h-6 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-1/2"></div>
                          </div>
                        ) : (
                          <p className="text-2xl font-semibold text-gray-900">{info?.best_card.won}</p>
                        )}
                        <p className="text-xs font-semibold text-gray-700">rewards won with this card</p>
                      </div>

                      <Link to={`/manager/${props.match.params.manager}/sections/so5-stats`}>
                        <div className="absolute bottom-0 inset-x-0 hover:bg-gray-200 bg-gray-100 px-2 py-2 sm:px-4 cursor-pointer">
                          <div className="text-xs">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                              {" "}
                              All SO5 stats
                            </a>
                          </div>
                        </div>
                      </Link>
                    </dd>
                  </div>
                </div>
                <div>
                  <div className="relative bg-white pt-5 px-4 pb-6 sm:pt-4 sm:px-4 shadow rounded-lg overflow-hidden">
                    <dt>
                      <p className="text-sm font-medium text-gray-500 truncate">Reward-winning lineups</p>
                    </dt>
                    <dd className="pb-5 md:pb-6 flex items-baseline pt-2">
                      {loading ? (
                        <div role="status" className="max-w-sm animate-pulse flex flex-col gap-2 my-1 w-full">
                          <div className="h-6 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-full"></div>
                          <div className="h-5 bg-grey-e5 rounded-lg dark:bg-grey-f2 3/4"></div>
                        </div>
                      ) : (
                        <div className={"space-y-1"}>
                          <p className="text-2xl font-semibold text-gray-900">
                            {info.winning_pct.total_lineups > 0
                              ? ((info.winning_pct.winning_lineups * 100) / info.winning_pct.total_lineups).toFixed(2)
                              : 0}
                            %
                          </p>
                          <p className="text-xs font-semibold text-gray-700">{info.winning_pct.winning_lineups} winning lineups</p>
                        </div>
                      )}
                      <Link to={`/manager/${props.match.params.manager}/sections/so5-results`}>
                        <div className="absolute bottom-0 inset-x-0 hover:bg-gray-200 bg-gray-100 px-2 py-2 sm:px-4 cursor-pointer">
                          <div className="text-xs">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                              All SO5 results
                            </a>
                          </div>
                        </div>
                      </Link>
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"bg-brand-black px-1/12"}>
            <div className="md:w-11/12 xl:w-11/12 2xl:w-11/12 4xl:w-8/12 mx-auto mb-6 text-focus text-base">
              <nav className="xl:flex xl:flex-row grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <Link to={`/manager/${props.match.params.manager}/sections/players`}>
                  <button className={activeTab === "players" ? activeClass : inactiveClass}>Players</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/roster-stats`}>
                  <button className={activeTab === "roster-stats" ? activeClass : inactiveClass}>Stats</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/transactions`}>
                  <button className={activeTab === "transactions" ? activeClass : inactiveClass}>Transactions</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/dnp-tracker`}>
                  <button className={activeTab === "dnp-tracker" ? activeClass : inactiveClass}>Injuries/news</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/collections`}>
                  <button className={activeTab === "collections" ? activeClass : inactiveClass}>Collections</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/roster-prices`}>
                  <button className={activeTab === "roster-prices" ? activeClass : inactiveClass}>Roster prices</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/so5-stats`}>
                  <button className={activeTab === "so5-stats" ? activeClass : inactiveClass}>SO5 stats</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/manager-stats`}>
                  <button className={activeTab === "manager-stats" ? activeClass : inactiveClass}>Manager stats</button>
                </Link>
                <Link to={`/manager/${props.match.params.manager}/sections/so5-results`}>
                  <button className={activeTab === "so5-results" ? activeClass : inactiveClass}>Sorare results</button>
                </Link>
                <Link
                  to={
                    info?.manager
                      ? `/lineupBuilder?manager=${props.match.params.manager}&managerSorareId=${info.manager.SorareId}`
                      : undefined
                  }
                >
                  <button className={activeTab === "lineup-builder" ? activeClass : inactiveClass}>Lineup builder</button>
                </Link>
              </nav>
            </div>
          </div>
          {manager.Slug === "null" && (
            <Heading level={"3"} margin={{ top: "none" }}>
              You&apos;re probably seeing this because you didn&apos;t link your Sorare account in your{" "}
              <Anchor href={"/profile"}>Profile</Anchor> page. If you already did, log out and log back in to apply the changes.
            </Heading>
          )}
          <div>
            {activeTab === "players" && (
              <div className={GLOBAL_MARGIN + " xl:w-11/12 xl:mx-auto"}>
                <ManagerCardsWrapper manager={manager} />
              </div>
            )}
            {activeTab === "roster-stats" && (
              <div className={GLOBAL_MARGIN}>
                <ManagerRosterStats manager={manager} />
              </div>
            )}
            {activeTab === "transactions" && (
              <div className={GLOBAL_MARGIN}>
                <ManagerTransactions manager={manager} />
              </div>
            )}
            {activeTab === "dnp-tracker" && <ManagerNewsInjuries manager={manager} />}
            {activeTab === "collections" && (
              <div className={GLOBAL_MARGIN}>
                <ManagerCollections manager={manager} />
              </div>
            )}
            {activeTab === "roster-prices" && (
              <div className={GLOBAL_MARGIN}>
                <PriceList manager={manager} />
              </div>
            )}
            {activeTab === "latest-signings" && (
              <div className={GLOBAL_MARGIN}>
                <LatestManagerSignings manager={manager} />
              </div>
            )}
            {activeTab === "so5-stats" && (
              <div className={GLOBAL_MARGIN}>
                <ManagerSO5Stats manager={manager} />
              </div>
            )}
            {activeTab === "manager-stats" && (
              <div className={GLOBAL_MARGIN}>
                <ManagerStats manager={manager} />
              </div>
            )}
            {activeTab === "so5-results" && (
              <div className={"-mt-6"}>
                <SO5Wrapper username={manager.Slug} noTitle={true} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withUser(ManagerPage);
