import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { withUser } from "../../userContext";
import BgSubscriptionAll from "../../img/subscription-schedule-bg-all.png";

// Only for free plan, when going the first time on a browser
function ValidateRegistrationPlanModal(props) {
  const close = () => {
    props.onClose && props.onClose();
  };

  return (
    <div>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={close}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-95 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4"></div>
                <div className={"w-auto mt-8"}>
                  <div className={"w-full border-b-2 border-grey-e5 pb-4"}>
                    <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>Introducing memberships</h2>
                  </div>
                  <div className={"mx-full flex flex-col gap-3 mb-8 px-6 pt-6"}>
                    <div className="mx-auto h-full w-full shadow-sm rounded-lg justify-self-auto justify-items-center text-center">
                      <img className={"rounded-lg"} src={BgSubscriptionAll} />
                    </div>
                    <div className={"rounded-xl bg-grey-f2 p-4 text-center"}>
                      <h4 className={"text-md text-brand-black"}>You currently have a Rookie membership</h4>
                      <p className={"text-sm text-textGrey3"}>
                        You will miss out on many great features to help you become a better Sorare manager. Check out our feature list and
                        start a free 14-day Star membership trial.
                      </p>
                      <a
                        className={"text-sm text-brand font-semibold"}
                        target={"_blank"}
                        href={"https://soraredata.medium.com/introducing-soraredata-memberships-14c340900fdf"}
                        rel="noreferrer"
                      >
                        Read our Medium article.
                      </a>
                    </div>
                    <div className={"flex flex-col gap-4 mx-auto w-1/2 mt-2"}>
                      <a href={"/product"}>
                        <button className={"font-headers mx-auto w-full rounded bg-brand text-white font-semibold p-2"}>
                          View Feature list
                        </button>
                      </a>
                      <button className={"font-headers font-semibold text-textGrey3 text-md"} onClick={close}>
                        Maybe later
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withUser(ValidateRegistrationPlanModal);
