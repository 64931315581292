import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../loader/spinner";
import { NoCardResultsImage } from "../util/noCardResultsImage";
import { getThemeColor, useTheme } from "../../themeContext";
import { ReactTooltip } from "../util/tooltip";
import { useQuery } from "../util/useQuery";
import EmptyAvatar from "../../img/empty-avatar.svg";
import RankLabel from "../util/rankLabel";
import React from "react";
import {
  BestGws,
  BestScoresByGw,
  CurrentGWLineupStats,
  getBestScoresFromAllScores,
  getScoresNormalized,
  ScoreTag,
} from "./longTermLeaguesLeaderboardScores";

export const LeaderboardTiles = (props) => {
  const { leaderboard, authenticatedUserLeaderboard, userLeaderboard, league, userSlug, loading, onLoadMore, sport } = props;

  if (leaderboard === null || leaderboard.leaderboard === null) {
    return (
      <div className={"flex flex-col gap-3"}>
        <div className={"rounded-lg bg-surface-container h-20 animate-pulse-sm"} />
        <div className={"rounded-lg bg-surface-container h-20 animate-pulse-sm"} />
        <div className={"rounded-lg bg-surface-container h-20 animate-pulse-sm"} />
      </div>
    );
  }

  const globalLeaderboard = leaderboard.list;

  return (
    <div className={"flex flex-col gap-3"}>
      <LeaderboardTileList
        sport={sport}
        border={authenticatedUserLeaderboard?.user === userSlug ? "border border-primary" : ""}
        rankings={[authenticatedUserLeaderboard]}
        league={league}
        dataTipId={"long-term-authenticated-user-leaderboard-gw-scores"}
      />
      {userLeaderboard && (
        <LeaderboardTileList
          sport={sport}
          border={"border border-primary"}
          rankings={[userLeaderboard]}
          league={league}
          dataTipId={"long-term-user-leaderboard-gw-scores"}
        />
      )}
      {globalLeaderboard.length > 0 && (
        <InfiniteScroll
          dataLength={leaderboard.list}
          scrollableTarget={"window"}
          items={globalLeaderboard}
          next={onLoadMore}
          hasMore={leaderboard.hasNext}
          useWindow={true}
          loader={
            <div className={"flex justify-center items-center p-4"}>
              <Spinner />
            </div>
          }
        >
          <LeaderboardTileList
            sport={sport}
            rankings={globalLeaderboard}
            league={league}
            dataTipId={"long-term-global-leaderboard-gw-scores"}
          />
        </InfiniteScroll>
      )}
      {globalLeaderboard.length === 0 && (
        <div className={"flex flex-col justify-center w-full bg-surface-container-high text-center py-8 rounded-xl"}>
          <div className={"flex flex-col justify-center"}>
            <div className={"m-auto mb-6"}>
              <NoCardResultsImage />
            </div>
            <p className={"text-lg font-headers text-center text-on-surface-variant font-bold mb-2"}>No manager with these filters</p>
          </div>
        </div>
      )}
    </div>
  );
};

export const LeaderboardTileList = (props) => {
  const { rankings, league, dataTipId, border, sport } = props;
  const { theme } = useTheme();
  const bgColor = getThemeColor(theme, "surface-container");

  return (
    <div className={`flex flex-col gap-2 overflow-x-hidden rounded-lg ${border}`}>
      {rankings.map((l, i) => {
        if (l === undefined || l === null || l.rank === null || l.rank === undefined) {
          return null;
        }
        return (
          <LeaderboardTile
            key={l.rank}
            rankingLine={l}
            bgColor={bgColor}
            last={i === rankings?.length - 1}
            league={league}
            dataTipId={dataTipId}
            sport={sport}
          />
        );
      })}
      <ReactTooltip id={dataTipId} />
    </div>
  );
};

export const LeaderboardTile = (props) => {
  const { rankingLine, bgColor, last, league, dataTipId, sport } = props;
  const { rank, scores, sorareUser, currentGwLineup, totalScore, rewards } = rankingLine;
  const { nbBestLineups, startGw, endGw, status } = league;
  const query = useQuery();
  const gradientSteps = [bgColor + "FF 10%", bgColor + "D9 40%", bgColor + "D9 60%", bgColor + "FF 90%"];
  const avatarBgImg =
    "linear-gradient(to right, " +
    gradientSteps.join(", ") +
    "), url('" +
    (sorareUser?.PictureUrl ? sorareUser.PictureUrl : EmptyAvatar) +
    "')";
  const bestScores = getBestScoresFromAllScores(scores, nbBestLineups);
  const scoresNormalized = getScoresNormalized(scores, endGw, startGw);
  const ongoingLeague = status === "STARTED";
  const queryParameters = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
  queryParameters.set("sorareSlug", sorareUser.Slug);

  return (
    <div
      className={"flex flex-col divide-y divide-outline-variant rounded-lg bg-surface-container"}
      onClick={() => (document.location = `/longTermLeagues/${sport}/leaderboard?${queryParameters.toString()}`)}
    >
      <div className={"grid grid-cols-2"}>
        <div className={"bg-left bg-no-repeat bg-cover"} style={{ backgroundImage: avatarBgImg }}>
          <div className={"flex flex-row gap-2 items-center px-2 py-1 h-full"}>
            <div className={"w-[20%] text-xxs ml-auto flex flex-row justify-center font-semibold"}>
              {rank === 0 ? "-" : <RankLabel rank={rank} hashMode />}
            </div>
            <div className={"w-[80%]"}>
              <div className={"flex"}>
                <div
                  className={
                    "rounded-3xl bg-transparent-surface-container-high bg-opacity-70 text-on-surface px-2 font-semibold py-0.5 my-auto text-xs truncate"
                  }
                  title={sorareUser?.Nickname || ""}
                >
                  {sorareUser?.Nickname || ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"flex flex-row gap-2 items-center px-1 justify-center"}>
          <div className={"py-2"}>
            {ongoingLeague && <CurrentGWLineupStats sport={sport} liveLabelPosition={"top"} size={"sm"} lineup={currentGwLineup} />}
          </div>
          <div>
            <ScoreTag width="w-14" score={rank === 0 ? "-" : totalScore} />
          </div>
        </div>
      </div>
      <div className={"px-2 py-2 grid grid-cols-2 items-center"}>
        <div className={"flex justify-center"}>
          <BestGws size="sm" scores={bestScores} withHover={false} sport={sport} />
        </div>
        <BestScoresByGw scores={scoresNormalized} league={league} dataTipId={dataTipId} />
      </div>
    </div>
  );
};
