import React from "react";
import { ReactComponent as IconSearch } from "../../img/icon-search.svg";
import HomeSearch from "../home/homeSearch";

const MobileSearch = ({ showSearch, onSearchToggle }) => (
  <>
    <button className="flex flex-col h-10 w-10 justify-center items-center" onClick={onSearchToggle}>
      <IconSearch className={"fill-on-primary"} alt="Toggle search" />
    </button>

    {showSearch && <HomeSearch autoFocus />}
  </>
);

export default MobileSearch;
