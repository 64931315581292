import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Privacy Policy";
  }, []);
  return (
    <div className={"p-4 text-on-surface mx-4 md:mx-12 lg:mx-20 2xl:w-6/12 2xl:mx-auto 4xl:w-6/12 "} readOnly="readonly">
      <h1 className={"text-center"}>Privacy Policy</h1>
      <p>
        Welcome to SorareData, an online service operated by Hg, a French company (SASU) registered in the Nanterre R.C.S. register under
        number 893 451 633, with its office located at 12 Rue de Landy, 92110 Clichy, France. This Privacy Policy outlines how we collect,
        use, protect, and share information about our users in connection with the services provided by SorareData (
        <a href="/terms-of-use">cf. Terms of Use</a>).
      </p>

      <h2>Information Collection</h2>
      <p>
        We collect information directly from our users when they use our services. The only personal information we collect is the{" "}
        <strong>email address</strong> of our users. This information is collected to authenticate user accounts and for communication
        purposes, including marketing and notifications about changes to our terms of use.
      </p>

      <h2>Use of Information</h2>
      <p>The email addresses we collect are used for the following purposes:</p>
      <ul>
        <li>To authenticate user accounts and ensure the security of user access.</li>
        <li>For marketing communications, to keep our users informed about our services and offers.</li>
        <li>To notify users about any changes to our terms of use or services.</li>
      </ul>
      <p>We do not use the collected information to make automated decisions within our application.</p>

      <h2>Data Security and Retention</h2>
      <p>
        We are committed to protecting the security of our users&apos; information. We employ standard encryption and security practices to
        protect our infrastructure and code from unauthorized access and use. User information is retained until the user decides to delete
        their account.
      </p>

      <h2>Users&apos; Rights</h2>
      <p>
        Users have the right to access and modify their email address within our services. Email preferences can be managed directly within
        the app settings. For additional inquiries or to exercise other rights, users can contact our support team at{" "}
        <a className={"text-primary cursor-pointer"} href="mailto:support@soraredata.com">
          support@soraredata.com
        </a>{" "}
        or{" "}
        <a className={"text-primary cursor-pointer"} href="mailto:contact@soraredata.com">
          contact@soraredata.com
        </a>
        .
      </p>

      <h2>Children&apos;s Privacy</h2>
      <p>
        Our service does not specifically target children, and we do not knowingly collect personal information from users under the age of
        consent. Given that we do not collect data such as age or name, we are unable to identify the age of our users.
      </p>

      <h2>Policy Updates</h2>
      <p>
        We may update this Privacy Policy from time to time. Users will be notified of any significant changes through the email addresses
        provided to us.
      </p>

      <h2>Contact Information</h2>
      <p>
        For any questions or concerns regarding this Privacy Policy or our data practices, please contact us at{" "}
        <a className={"text-primary cursor-pointer"} href="mailto:contact@soraredata.com">
          contact@soraredata.com
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
