import { getNavigatorLang } from "./locale";

export function formatNumber(value, lang, opts) {
  if (value === undefined) {
    return "---";
  }
  const options = opts || {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  };
  const localeLang = lang || getNavigatorLang();
  return new Intl.NumberFormat(localeLang, options).format(value);
}

export const labelFromNumber = (n) => {
  if (n < 1000) {
    return n;
  }
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  return (
    new Intl.NumberFormat(userLocale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(n / 1000) + "k"
  );
};

export const toFixedIfNecessary = (val, prec) => {
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  const valFloat = parseFloat(val);
  const precision = prec === undefined ? 2 : prec;
  return new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
  }).format(valFloat);
};
