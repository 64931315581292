import { capitalize } from "./capitalize";

export const getBackgroundGradientFromDivisionId = (rarity) => {
  let [bgHeader1, bgHeader2] = ["surface-container-highest", "surface-container-highest"];
  if (rarity) {
    // Use the rarity from Sorare API
    // So we use same color scheme as their website
    if (rarity === "mix") {
      [bgHeader1, bgHeader2] = ["scarcity-multi-linear-start", "scarcity-multi-linear-end"];
    } else if (rarity === "limited") {
      [bgHeader1, bgHeader2] = ["scarcity-limited-linear-start", "scarcity-limited-linear-end"];
    } else if (rarity === "rare" || rarity === "rare_pro") {
      [bgHeader1, bgHeader2] = ["scarcity-rare-linear-start", "scarcity-rare-linear-end"];
    } else if (rarity === "super_rare") {
      [bgHeader1, bgHeader2] = ["scarcity-super-rare-linear-start", "scarcity-super-rare-linear-end"];
    } else if (rarity === "unique") {
      [bgHeader1, bgHeader2] = ["scarcity-unique-linear-start", "scarcity-unique-linear-end"];
    } else if (rarity === "common") {
      [bgHeader1, bgHeader2] = ["scarcity-common-linear-start", "scarcity-common-linear-end"];
    }
  }
  return [bgHeader1, bgHeader2];
};

export const getBackgroundGradientFromDivisionIdLegacy = (divId, rarity) => {
  let [bgHeader1, bgHeader2] = ["#D1CECB", "#888685"];
  if (rarity) {
    // Use the rarity from Sorare API
    // So we use same color scheme as their website
    if (rarity === "mix") {
      [bgHeader1, bgHeader2] = ["#B51EB8", "#4F0A60"];
    } else if (rarity === "limited") {
      [bgHeader1, bgHeader2] = ["#D59A40", "#A9571D"];
    } else if (rarity === "rare" || rarity === "rare_pro") {
      [bgHeader1, bgHeader2] = ["#ED372D", "#C02139"];
    } else if (rarity === "super_rare") {
      [bgHeader1, bgHeader2] = ["#2D5DEB", "#5842D6"];
    } else if (rarity === "unique") {
      [bgHeader1, bgHeader2] = ["#3F3E3D", "#181818"];
    }
  } else {
    // Deprecated way by parsing the division id
    if (divId) {
      if (divId.includes("weekly")) {
        [bgHeader1, bgHeader2] = ["#B51EB8", "#4F0A60"];
      } else if (divId.includes("division-1") || divId.includes("unique")) {
        [bgHeader1, bgHeader2] = ["#3F3E3D", "#181818"];
      } else if (divId.includes("division-2") || divId.includes("super-rare")) {
        [bgHeader1, bgHeader2] = ["#2D5DEB", "#5842D6"];
      } else if (divId.includes("division-3")) {
        [bgHeader1, bgHeader2] = ["#ED372D", "#C02139"];
      } else if (divId.includes("division-4") || divId.includes("rare")) {
        [bgHeader1, bgHeader2] = ["#ED372D", "#C02139"];
      } else if (divId.includes("division-5") || divId.includes("limited")) {
        [bgHeader1, bgHeader2] = ["#D59A40", "#A9571D"];
      }
    }
  }
  return [bgHeader1, bgHeader2];
};

export const getColorFromDivisionId = (divId, rarity) => {
  let color = "#989898";
  if (rarity) {
    // Use the rarity from Sorare API
    // So we use same color scheme as their website
    if (rarity === "mix") {
      return "#8D32E4";
    } else if (rarity === "limited") {
      return "#E7B62C";
    } else if (rarity === "rare" || rarity === "rare_pro") {
      return "#E73107";
    } else if (rarity === "super_rare") {
      return "#0380F3";
    } else if (rarity === "unique") {
      return "#494949";
    }
  } else {
    // Deprecated way by parsing the division id
    if (divId) {
      if (divId.includes("weekly")) {
        return "#8D32E4";
      } else if (divId.includes("division-1") || divId.includes("unique")) {
        return "#494949";
      } else if (divId.includes("division-2") || divId.includes("super-rare")) {
        return "#0380F3";
      } else if (divId.includes("division-3")) {
        return "#E73107";
      } else if (divId.includes("division-4") || divId.includes("rare")) {
        return "#E73107";
      } else if (divId.includes("division-5") || divId.includes("limited")) {
        return "#E7B62C";
      }
    }
  }
  return color;
};

export const getColorFromScarcity = (scarcity) => {
  if (scarcity) {
    const normalizedScarcity = scarcity.toLowerCase();
    if (normalizedScarcity === "limited") {
      return "#E7B62C";
    } else if (normalizedScarcity === "rare") {
      return "#E73107";
    } else if (normalizedScarcity === "super rare" || normalizedScarcity === "super_rare") {
      return "#0380F3";
    } else if (normalizedScarcity === "unique") {
      return "#494949";
    } else if (normalizedScarcity === "custom series" || normalizedScarcity === "custom_series") {
      return "#8D32E4";
    }
  }
  return "#989898";
};

export const getRarityDisplayName = (_rarity) => {
  return capitalize(_rarity).replace("_", " ");
};

export const getDivisionAndRarityDisplayName = (comp) => {
  if (comp === undefined || comp === null) {
    return "";
  }
  return comp.DivisionType.startsWith("seasonal")
    ? `Division ${comp.DivisionNumber} · ${getRarityDisplayName(comp.RarityType)}`
    : `${getRarityDisplayName(comp.RarityType)}`;
};
