import React, { useEffect, useState } from "react";
import OfferCard from "../offers/offerCard";
import AuctionCard from "../auctions/auctionCard";
import Modal from "../util/modal";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function OngoingPlayerCardsSales(props) {
  const [sales, setSales] = useState({ auctions: [], offers: [] });
  const [ethPrice, setEthPrice] = useState([]);
  const [teamMap, setTeamMap] = useState([]);
  const [player, setPlayer] = useState(props.player);
  const [card, setCard] = useState(props.card);
  const [showAuctions, setShowAuctions] = useState(true);
  const [showOffers, setShowOffers] = useState(true);

  useEffect(() => {
    getOngoingPlayerSales();
  }, []);

  const getOngoingPlayerSales = () => {
    props
      .fetch("/apiv2/players/ongoingSales/" + player.PlayerId + "/" + card.Scarcity)
      .then((response) => response.json())
      .then((res) => {
        setSales({ auctions: res.auctions, offers: res.offers });
        setEthPrice(res.price_info);
        setTeamMap(res.team_map);
      })
      .catch(errorCatcher());
  };

  let gridClass = "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 1.5xl:grid-cols-5 2xl:grid-cols-5 3xl:grid-cols-6 gap-x-4 gap-y-6";

  return (
    <div>
      <Modal
        maxSize={"max-w-full"}
        size={"2xl:mx-6 lg:w-10/12 4xl:w-8/12 mx-auto"}
        open={props.open}
        close={() => props.close()}
        content={
          <div className={"w-full px-4 py-2 relative"}>
            <div className={"space-y-8"}>
              <div className={"flex flex-col space-y-4 items-start"}>
                <p className={"text-base font-semibold cursor-pointer"} onClick={() => setShowOffers(!showOffers)}>
                  {player.DisplayName} cards for sale on the secondary market ({sales.offers !== null ? sales.offers.length : 0})
                </p>
                <div className={gridClass}>
                  {showOffers &&
                    sales.offers !== null &&
                    sales.offers.map((o) => {
                      return (
                        <OfferCard key={o.offer.OfferId} offer={o} priceInfo={ethPrice} u23Only={false} filter={[]} gwGames={teamMap} />
                      );
                    })}
                </div>
              </div>

              <div className={"flex flex-col space-y-4"}>
                <p className={"text-base font-semibold cursor-pointer"} onClick={() => setShowAuctions(!showAuctions)}>
                  {player.DisplayName} upcoming card auctions ({sales.auctions !== null ? sales.auctions.length : 0})
                </p>
                <div className={gridClass}>
                  {showAuctions &&
                    sales.auctions !== null &&
                    sales.auctions.map((a) => {
                      return <AuctionCard key={a.AuctionId} auction={a} past={false} nextGwPlayers={teamMap} priceInfo={ethPrice} />;
                    })}
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default withUser(OngoingPlayerCardsSales);
