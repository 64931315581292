import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { GLOBAL_MARGIN_NO_BG } from "../util/margin";
import PlayerAvatar from "../util/playerAvatar";
import UnknownClub from "../../img/unknown_club.png";
import BoxWithToggle from "../util/boxWithToggle";
import { ReactTooltip } from "../util/tooltip.js";
import CirclePct from "../util/circlePct";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { so5_leagues_objects_no_U23 } from "../util/leagues";
import OppScore from "../decision/oppScore";
import { positions_objects } from "../util/positions";
import InfoModal from "../../img/info-modal.svg";
import Modal from "../util/modal";
import MatchupScore from "../util/matchupScore";
import {
  allStarDefaultCompetition,
  EligibilitySorareCompetitionsFilter,
  initialEligibilityCompetitionValue,
} from "../competitions/eligibilityCompetitionsPicker";
import { errorCatcher } from "../util/errors";
import Spinner from "../loader/spinner";

function SO5Matchups(props) {
  const [matchups, setMatchups] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [sortHow, setSortHow] = useState(1);
  const [position, setPosition] = useState([]);
  const [myMatchups, setMyMatchups] = useState(false);
  const [eligibilityCompetitions, setEligibilityCompetitions] = React.useState(initialEligibilityCompetitionValue);
  const [team, setTeam] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getMatchups();
  }, [props.gwNumber]);

  const getMatchups = (ep) => {
    if (props.gwNumber === undefined || props.gwNumber === null || props.gwNumber === 0) return;
    const params = new URLSearchParams();
    ep = ep || eligibilityCompetitions;
    Object.keys(ep)
      .filter((k) => k !== allStarDefaultCompetition.id)
      .forEach((k) => params.append("competitionsEligible", k));
    setLoading(true);
    props
      .fetch(`/apiv2/SO5/matchups/${props.gwNumber}?${params.toString()}`)
      .then((response) => response.json())
      .then((res) => {
        if (res?.error === undefined) {
          setMatchups(res || []);
        } else {
          console.error(props.gwNumber, res.error);
        }
        ReactTooltip.rebuild();
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const sortData = (w) => {
    let newMatchups = [...matchups];
    let how = sortHow * -1;
    setSortHow(how);
    let key = "";
    if (w === "win") {
      key = "win_pct";
    } else if (w === "3gs") {
      key = "scores_three_goals_pct";
    } else if (w === "3gc") {
      key = "three_goals_pct";
    } else if (w === "cs") {
      key = "cs_pct";
    } else {
      if (w === "players") {
        newMatchups.sort(function (a, b) {
          if (a.players === null) return -1 * how;
          else if (b.players === null) return how;
          if (a.players.length < b.players.length) return -1 * how;
          else if (a.players.length > b.players.length) return how;
          else return 0;
        });
        setMatchups(newMatchups);
        ReactTooltip.rebuild();
      } else if (w.includes("matchup")) {
        let index = 0;
        if (w === "def_matchup") index = 1;
        else if (w === "mid_matchup") index = 2;
        else if (w === "fwd_matchup") index = 3;
        newMatchups.sort(function (a, b) {
          if (a.matchup_scores[index] > b.matchup_scores[index]) return -1 * how;
          else if (a.matchup_scores[index] < b.matchup_scores[index]) return how;
          else return 0;
        });
        setMatchups(newMatchups);
        ReactTooltip.rebuild();
      } else if (w.includes("opp")) {
        let key = "gkAverage";
        if (w === "def_opp") key = "defAverage";
        else if (w === "mid_opp") key = "midAverage";
        else if (w === "fwd_opp") key = "fwdAverage";
        newMatchups.sort(function (a, b) {
          if (a.opp_score_against[key] > b.opp_score_against[key]) return -1 * how;
          else if (a.opp_score_against[key] < b.opp_score_against[key]) return how;
          else return 0;
        });
        setMatchups(newMatchups);
        ReactTooltip.rebuild();
      }
    }

    newMatchups.sort(function (a, b) {
      if (a[key] === 0) return -1 * how;
      else if (b[key] === 0) return how;
      if (a[key] > b[key]) return -1 * how;
      else if (a[key] < b[key]) return how;
      else return 0;
    });
    setMatchups(newMatchups);
    ReactTooltip.rebuild();
  };

  return (
    <div>
      {modalOpen && (
        <Modal
          content={
            <div className={"font-sans space-y-4 bg-white"}>
              <div className={"p-8 border-b border-grey-e5 bg-white"}>
                <p className={"text-3xl font-semibold text-center font-headers"}>Matchup indicators & opponent scores</p>
              </div>
              <div className={"space-y-4"}>
                <div>
                  <p className={"text-xl font-semibold text-center"}>Matchup indicators</p>
                  <div className={"flex flex-row space-x-4 justify-center font-semibold my-4"}>
                    <MatchupScore score={-5} label={"GK"} />
                    <MatchupScore score={0} label={"DEF"} />
                    <MatchupScore score={10} label={"MID"} />
                    <MatchupScore score={20} label={"FWD"} />
                  </div>
                  <div className={"text-sm text-left p-4"}>
                    <p>The matchup score is used to evaluate the differences between two teams in terms of SO5 scoring.</p>
                    <p className={"my-2"}>
                      It is the addition of two things: <br />
                      <ul className={"mt-2"}>
                        <li>
                          The club’s over/under-performance compared to league average in a specific position (i.e club SO5 average) -
                          league SO5 average.
                        </li>
                        <li>The opponent score over/under-performance compared to league average (i.e opp. score) - league SO5 average.</li>
                      </ul>
                    </p>
                    <p className={"my-4"}>For example, let&apos;s consider a Real Madrid-Barcelona game.</p>
                    <p>
                      Real Madrid forwards score 73 SO5 points on average at home during LaLiga games;
                      <br /> LaLiga SO5 average for forwards is 51;
                      <br />
                      Forwards score 56 on average against Barcelona when Barca is playing LaLiga games away.
                      <br />
                    </p>
                    <p className={"text-center font-semibold my-2"}>
                      <span className={"text-center"}>Matchup indicator is 73-51+56-51=27</span>
                    </p>
                    <p>
                      Real Madrid defenders score 44 SO5 points on average at home during LaLiga games;
                      <br />
                      LaLiga SO5 average for defenders is 49;
                      <br />
                      Defenders score 42 SO5 points on average against Barcelona when Barca is playing LaLiga games away.
                      <br />
                    </p>
                    <p className={"text-center font-semibold my-2"}>Matchup indicator is 44-49+42-49=-12</p>
                  </div>
                </div>
                <div>
                  <div>
                    <p className={"text-xl font-semibold text-center"}>Opponent scores</p>
                  </div>
                  <div className={"flex flex-row space-x-4 justify-center font-semibold self-center my-4"}>
                    <div className={"flex flex-col items-center space-y-1"}>
                      <p className={"text-xxs text-textGrey2"}>GK</p>
                      <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={40} />
                    </div>
                    <div className={"flex flex-col items-center space-y-1"}>
                      <p className={"text-xxs text-textGrey2"}>DEF</p>
                      <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={45} />
                    </div>
                    <div className={"flex flex-col items-center space-y-1"}>
                      <p className={"text-xxs text-textGrey2"}>MID</p>
                      <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={50} />
                    </div>
                    <div className={"flex flex-col items-center space-y-1"}>
                      <p className={"text-xxs text-textGrey2"}>FWD</p>
                      <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={55} />
                    </div>
                  </div>
                  <div className={"text-sm p-4"}>
                    <p>
                      The opponent score is the average of points allowed by a team&apos;s opponent over the last 8 games in the upcoming
                      game setup (competition/home or away).
                    </p>
                    <p className={"mt-2"}>
                      For example, if you&apos;re looking at PSG&apos;s line with the example above, a 55 forward opponent score means that
                      PSG&apos;s next opponent has allowed on average 55 SO5 points to forward starters who played 60 minutes or more in the
                      past 8 games in the same competition/home or away setup.
                    </p>
                    <p className={"my-4 font-semibold text-center"}>
                      Therefore, the higher the opponent score is, the better the SO5 matchup is.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }
          open={modalOpen}
          close={() => setModalOpen(false)}
        />
      )}
      <div className={GLOBAL_MARGIN_NO_BG}>
        <div className={"flex flex-col space-y-4"}>
          <div className={"relative grid grid-cols-4 gap-4"}>
            <div className={"py-1.5 bg-white rounded-lg"}>
              <BoxWithToggle label={"Only matchups with my players"} isActive={myMatchups} setActive={() => setMyMatchups(!myMatchups)} />
            </div>
            <div className={"z-50 self-center"}>
              <EligibilitySorareCompetitionsFilter
                minWidth={"min-w-[6rem]"}
                displayOptions={{ shadow: "shadow" }}
                selected={eligibilityCompetitions}
                onSelect={(v) => {
                  let newLeagues = {};
                  if (v === allStarDefaultCompetition.id) {
                    newLeagues = initialEligibilityCompetitionValue;
                  } else {
                    newLeagues = { ...eligibilityCompetitions, [v]: true };
                    delete newLeagues[allStarDefaultCompetition.id]; // makes no sense to have the 'all' selected while others specific ones are selected
                  }
                  setEligibilityCompetitions(newLeagues);
                  getMatchups(newLeagues);
                }}
                onUnselect={(v) => {
                  const newLeagues = { ...eligibilityCompetitions };
                  delete newLeagues[v];
                  if (Object.keys(newLeagues).length === 0) {
                    newLeagues[allStarDefaultCompetition.id] = true;
                  }
                  setEligibilityCompetitions(newLeagues);
                  getMatchups(newLeagues);
                }}
              />
            </div>
            <div className={"z-50 self-center"}>
              <SelectSearch
                options={positions_objects}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                placeholder={"Filter by your players position"}
                multiple={true}
                closeOnSelect={false}
                value={position}
                onChange={(v) => setPosition(v)}
              />
            </div>
            <div className={"self-center"}>
              <input
                className="block w-full py-2 rounded-md pl-4 shadow sm:text-sm border-grey-e5 focus:ring-none focus:outline-none"
                value={team}
                placeholder={"Filter by team"}
                onChange={(v) => {
                  setTeam(v.target.value);
                }}
              />
            </div>
            <div className={"absolute right-0 translate-x-full flex flex-row items-center pl-2"}>
              {loading && matchups !== undefined && <Spinner />}
            </div>
          </div>
          <div>
            <table className={"border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}>
              <thead>
                <tr className="text-center">
                  <th className="rounded-tl-lg text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs">
                    Team
                  </th>
                  <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-center w-1/18 font-bold uppercase text-xs">
                    Opp.
                  </th>
                  <th
                    onClick={() => sortData("players")}
                    className="cursor-pointer text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-3/12 font-bold uppercase text-xs"
                  >
                    Your players
                  </th>
                  <th className="text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-1 text-left w-2/12 font-bold uppercase text-xs">
                    <div className={"flex flex-col space-y-1"}>
                      <div className={"flex flex-row space-x-1 justify-center"}>
                        <p>Matchup indicators</p>
                        <img src={InfoModal} className={"cursor-pointer"} onClick={() => setModalOpen(true)} />
                      </div>
                      <div className={"flex flex-row space-x-5 justify-center"}>
                        <p className={"cursor-pointer"} onClick={() => sortData("gk_matchup")}>
                          GK
                        </p>
                        <p className={"cursor-pointer"} onClick={() => sortData("def_matchup")}>
                          DEF
                        </p>
                        <p className={"cursor-pointer"} onClick={() => sortData("mid_matchup")}>
                          MID
                        </p>
                        <p className={"cursor-pointer"} onClick={() => sortData("fwd_matchup")}>
                          FWD
                        </p>
                      </div>
                    </div>
                  </th>
                  <th className="text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-1 text-left w-2/12 font-bold uppercase text-xs">
                    <div className={"flex flex-col space-y-1"}>
                      <div className={"flex flex-row space-x-1 justify-center"}>
                        <p>Opp. scores</p>
                        <img src={InfoModal} className={"cursor-pointer"} onClick={() => setModalOpen(true)} />
                      </div>
                      <div className={"flex flex-row space-x-5 justify-center"}>
                        <p className={"cursor-pointer"} onClick={() => sortData("gk_opp")}>
                          GK
                        </p>
                        <p className={"cursor-pointer"} onClick={() => sortData("def_opp")}>
                          DEF
                        </p>
                        <p className={"cursor-pointer"} onClick={() => sortData("mid_opp")}>
                          MID
                        </p>
                        <p className={"cursor-pointer"} onClick={() => sortData("fwd_opp")}>
                          FWD
                        </p>
                      </div>
                    </div>
                  </th>
                  <th
                    onClick={() => sortData("win")}
                    data-tip={"Win probability"}
                    className="cursor-pointer text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-20 font-bold uppercase text-xs"
                  >
                    % win
                  </th>
                  <th
                    onClick={() => sortData("cs")}
                    data-tip={"Clean sheet probability"}
                    className="cursor-pointer text-white text-center bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-20 font-bold uppercase text-xs"
                  >
                    % CS
                  </th>
                  <th
                    onClick={() => sortData("3gs")}
                    data-tip={"Scoring 3 goals probability"}
                    className="cursor-pointer text-center text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-20 font-bold uppercase text-xs"
                  >
                    % 3GS
                  </th>
                  <th
                    onClick={() => sortData("3gc")}
                    data-tip={"Conceding 3 goals probability"}
                    className="rounded-tr-lg cursor-pointer text-center text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-20 font-bold uppercase text-xs"
                  >
                    % 3GC
                  </th>
                </tr>
              </thead>
              <tbody>
                {matchups === undefined && loading && (
                  <tr>
                    <td colSpan={8} className={"text-center"}>
                      <div className={"flex justify-center items-center p-3"}>
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}
                {matchups?.length === 0 && (
                  <tr>
                    <td colSpan={8} className={"text-center"}>
                      <div className={"flex justify-center items-center p-3 font-semibold"}>No matchups found</div>
                    </td>
                  </tr>
                )}
                {matchups?.map((m) => {
                  let isHome = true;
                  if (m.team.TeamId !== m.game.game.HomeTeamId) {
                    isHome = false;
                  }
                  let hasPosition = position.length === 0;
                  if (position.length > 0 && m.players !== null) {
                    m.players.map((p) => {
                      if (position.indexOf(p.Position) >= 0) {
                        hasPosition = true;
                      }
                    });
                  }
                  if (myMatchups && !hasPosition) return null;
                  if (myMatchups && (m.players === null || m.players.length === 0)) return null;
                  if (team !== "" && !m.team.DisplayName.toLowerCase().includes(team.toLowerCase())) return null;
                  return (
                    <tr key={`${m.team.TeamId}-${m.game.game.GameId}`} className={"text-sm font-semibold h-16 border-b border-grey-e5"}>
                      <td className={"pl-4"}>
                        <div className={"flex flex-row space-x-2 py-2"}>
                          <div className={"w-8 h-8 self-center"}>
                            <a href={"/team/" + m.team.TeamId}>
                              <img
                                src={m.team.PictureUrl !== "" ? m.team.PictureUrl : UnknownClub}
                                className={"w-full h-full object-contain"}
                              />
                            </a>
                          </div>
                          <div className={"flex flex-col "}>
                            <p className={"text-left hover:font-bold"}>
                              <a href={"/team/" + m.team.TeamId}>{m.team.ClubName}</a>
                            </p>
                            <p className={"text-xs font-medium"}>{m.game.game.CompDisplayName}</p>
                            <p className={"text-xs font-medium"}>
                              {m.divisions !== null &&
                                m.divisions.map((d, i) => {
                                  return (
                                    <span key={d}>
                                      {d}
                                      {m.divisions.length - 1 === i ? "" : " / "}
                                    </span>
                                  );
                                })}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={"flex flex-col items-center space-y-1"} data-tip={m.opp_team.DisplayName}>
                          <div className={"w-7 h-7 self-center"}>
                            <a href={"/team/" + m.opp_team.TeamId}>
                              <img
                                src={m.opp_team.PictureUrl !== "" ? m.opp_team.PictureUrl : UnknownClub}
                                className={"w-full h-full object-contain"}
                              />
                            </a>
                          </div>
                          <div
                            className={
                              isHome
                                ? "self-center flex flex-col items-center px-1 py-0 rounded bg-super-rare font-semibold text-xs text-white"
                                : "self-center flex flex-col items-center rounded bg-limited font-semibold text-xs text-white px-1 py-0"
                            }
                          >
                            <p>{isHome ? "H" : "A"}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={"flex flex-row justify-center"}>
                          {m.players !== null &&
                            m.players.map((p, i) => {
                              if (position.length > 0 && position.indexOf(p.Position) === -1) {
                                return null;
                              }
                              if (i < 8) {
                                return (
                                  <div
                                    key={p.PlayerId}
                                    style={{
                                      marginRight: "-16px",
                                      zIndex: i,
                                    }}
                                  >
                                    <PlayerAvatar size={"w-12 h-12"} imgSize={"w-10 h-10"} player={p} />
                                  </div>
                                );
                              }
                              return null;
                            })}
                          {m.players !== null && position.length === 0 && m.players.length > 8 && (
                            <div className={"self-center ml-4"}>
                              <p className={"text-sm self-center font-semibold"}> + {m.players.length - 8}</p>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={"flex flex-row space-x-3 justify-center"}>
                          {m.matchup_scores.map((s, i) => {
                            let label = "GK";
                            if (i === 1) label = "DEF";
                            else if (i === 2) label = "MID";
                            else if (i === 3) label = "FWD";
                            return <MatchupScore key={label} score={s} label={label} />;
                          })}
                        </div>
                      </td>
                      <td>
                        <div className={"flex flex-row space-x-4 justify-center"}>
                          <div className={"flex flex-col items-center space-y-1"}>
                            <p className={"text-xxs text-textGrey2"}>GK</p>
                            <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={m.opp_score_against.gkAverage} />
                          </div>
                          <div className={"flex flex-col items-center space-y-1"}>
                            <p className={"text-xxs text-textGrey2"}>DEF</p>
                            <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={m.opp_score_against.defAverage} />
                          </div>
                          <div className={"flex flex-col items-center space-y-1"}>
                            <p className={"text-xxs text-textGrey2"}>MID</p>
                            <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={m.opp_score_against.midAverage} />
                          </div>
                          <div className={"flex flex-col items-center space-y-1"}>
                            <p className={"text-xxs text-textGrey2"}>FWD</p>
                            <OppScore size={"w-7 h-7"} fontSize={"text-sm"} score={m.opp_score_against.fwdAverage} />
                          </div>
                        </div>
                      </td>
                      <td className={"text-center"}>
                        <div className={"text-center flex flex-row justify-center self-center"}>
                          <CirclePct value={m.win_prob} odds={false} />
                        </div>
                      </td>
                      <td className={"text-center"}>
                        <div className={"text-center flex flex-row justify-center self-center"}>
                          <CirclePct value={m.cs_prob} odds={false} />
                        </div>
                      </td>
                      <td className={"text-center"}>
                        <div className={"text-center flex flex-row justify-center self-center"}>
                          <CirclePct value={m.three_gs_prob} odds={false} />
                        </div>
                      </td>
                      <td className={"text-center"}>
                        <div className={"text-center flex flex-row justify-center self-center"}>
                          <CirclePct value={m.three_gc_prob} odds={false} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <ReactTooltip />
      </div>
    </div>
  );
}

export default withUser(SO5Matchups);
