import { formatPrice } from "./formatMoney";
import { getNavigatorLang } from "./locale";
import { units } from "./units";

export function formatRealTimeAverage(rt, unit, lang) {
  let localeLang = lang || getNavigatorLang();
  const supportedLocales = Intl.NumberFormat.supportedLocalesOf(localeLang);
  if (supportedLocales === undefined || supportedLocales.length === 0) {
    localeLang = "en-US";
  }
  if (rt === undefined) {
    return "No sale";
  }
  if (rt.ValuationOrigin === "starting_price") {
    return "No sale";
  } else if (rt.Average <= 0) {
    return "No sale";
  }
  if (unit === "eth") {
    return formatPrice(rt.Average, "eth");
  } else {
    let price;
    if (unit === "€" || unit === "eur") {
      unit = "eur";
      price = rt["EurAverage"];
    } else if (unit === "$" || unit === "usd") {
      unit = "usd";
      price = rt["UsdAverage"];
    } else if (unit === "£" || unit === "gbp") {
      unit = "gbp";
      price = rt["GbpAverage"];
    }
    let max = 2;
    if (price >= 100) max = 0;
    if (price >= 1000) max = 0;
    if (price <= 0) return "No sale";
    try {
      return new Intl.NumberFormat(localeLang, {
        style: "currency",
        currency: unit ? unit.toUpperCase() : "USD",
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } catch (_) {
      // If currency style and options are not supported by the browser, use plain simple number format
      return (
        new Intl.NumberFormat(localeLang, {
          maximumFractionDigits: max,
          minimumFractionDigits: max,
        }).format(price) +
        " " +
        units[unit?.toLowerCase() || "$"]
      );
    }
  }
}
