import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import format from "date-fns/format";
import Injured100 from "../../img/injury-100.svg";
import Injured25 from "../../img/injury-25.svg";
import RotowireCredit from "../util/rotowireCredit";

function PlayerNews(props) {
  const player = props.player;

  const [news, setNews] = useState([]);

  const getNews = () => {
    props
      .fetch("/apiv2/players/news?playerId=" + player.PlayerId)
      .then((response) => response.json())
      .then((res) => {
        if (res !== null) {
          setNews(res);
        }
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    getNews();
  }, [props.player]);

  return (
    <div className={"space-y-4"}>
      <div className={"flex flex-row justify-between"}>
        <p className={"text-xl text-brand font-semibold"}>Latest news</p>
        <RotowireCredit credit={"Provided by"} />
      </div>
      <div className={"grid grid-cols-2 gap-8 flex flex-col"}>
        {news.map((n) => {
          let theNews = n.news;
          let status = theNews.Injury.Status;
          let statusText = "";
          let icon = "";
          if (status === "GTD") {
            statusText = "Game-time decision";
            icon = Injured25;
          } else if (status === "OUT") {
            statusText = "Out";
            icon = Injured100;
          } else if (status === "OFS") {
            statusText = "Out for season";
            icon = Injured100;
          }
          let returnDate = theNews.Injury.ReturnDate;
          let returnDateText = "";
          if (returnDate !== "") {
            returnDateText = new Date(returnDate).toLocaleDateString();
          }
          return (
            <div className={"rounded-lg bg-white flex flex-col"}>
              <div className={"border-b border-grey-e5 h-14"}>
                <div className={"flex flex-row justify-between px-4 py-4"}>
                  <p className={"text-base font-semibold"}>{theNews.Headline}</p>
                  <p className={"font-semibold text-base text-textGrey3"}>{format(new Date(n.date), "d MMM yyyy")}</p>
                </div>
              </div>

              <div className={"px-4 py-4 space-y-4 h-full"}>
                <p className={"text-sm font-medium text-textGrey2"}>{theNews.Notes}</p>
                <div className={"space-y-2"}>
                  <p className={"text-sm font-semibold"}>ANALYSIS</p>
                  <p className={"text-sm font-medium text-textGrey2"}>{theNews.Analysis}</p>
                </div>
              </div>
              {theNews.Injury.Status !== "" && (
                <div className={"border-t border-grey-e5 justify-end items-end h-18"}>
                  <div className={"flex flex-row px-4 justify-between py-4"}>
                    <div className={"self-center"}>
                      <p className={"text-xs font-semibold"}>STATUS</p>
                      <div className={"flex flex-row space-x-1"}>
                        <p className={"font-semibold text-base"}>{statusText}</p>
                        {icon !== "" && <img className={"w-5 h-5 self-center"} src={icon} />}
                      </div>
                    </div>
                    {returnDate !== "" && (
                      <div className={"self-center"}>
                        <p className={"text-xs font-semibold text-right"}>UNTIL</p>
                        <p className={"font-semibold text-sm"}>{returnDateText}</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withUser(PlayerNews);
