import { withUser } from "../../userContext";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as Warn } from "@material-design-icons/svg/round/error.svg";
import { ReactComponent as LineupNotPossibleIcon } from "@material-design-icons/svg/round/event_busy.svg";
import { ReactComponent as IconChevronRight } from "@material-design-icons/svg/filled/chevron_right.svg";
import { ReactComponent as IconChevronLeft } from "@material-design-icons/svg/filled/chevron_left.svg";
import { ReactComponent as ShowIcon } from "@material-design-icons/svg/filled/visibility.svg";
import { ReactComponent as HideIcon } from "@material-design-icons/svg/filled/visibility_off.svg";

import { XIcon } from "@heroicons/react/outline";
import { Spinning } from "../loader/spinner";
import { isMobile } from "react-device-detect";
import { MobileModale } from "../util/mobileModale";
import { capitalize } from "../util/capitalize";
import { renderToString } from "react-dom/server";
import { ReactTooltip } from "../util/tooltip";
import { Tag } from "../util/tag";
import { getRarityDisplayName } from "../util/divisions";
import clsx from "clsx";
import { convertRemToPixels } from "../util/size";
import { ReactComponent as CheckIcon } from "../../img/icons-check.svg";
import useDebounce from "../../hooks/useDebounce";

const keyLubShowUnavailableCompetitions = "lubShowUnavailableCompetitions";

export const LineupBuilderDivisionOrLineupPicker = withUser((props) => {
  const { enteredCompetitions, availableCompetitions, disabledCompetitions, unavailableCompetitions, selectedItem, onChange, loading } =
    props;
  const [focus, setFocus] = useState(false);
  const [displayTeams, setDisplayTeams] = useState(null);
  const [showUnavailableCompetitions, setShowUnavailableCompetitions] = useState(
    localStorage.getItem(keyLubShowUnavailableCompetitions) === "true",
  );
  const onDisplayTeams = (competition) => {
    setDisplayTeams(competition || {});
  };
  const onDisplayCompetitions = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisplayTeams(null);
  };
  const onClose = () => {
    setFocus(false);
    setDisplayTeams(null);
  };

  const ref = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocus(false);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [focus]);

  const handleChange = (value) => {
    setFocus(false);
    onChange && onChange(value);
  };

  const onChangeShowUnavailableCompetitions = (value) => {
    setShowUnavailableCompetitions(value);
    localStorage.setItem(keyLubShowUnavailableCompetitions, value);
  };

  const selectedItemDisplayName = getDivisionDisplayNameV2(selectedItem);
  return (
    <div className={"relative h-full z-30"} ref={ref}>
      <button
        onClick={() => setFocus(!focus)}
        className={`h-full text-md my-auto  px-3 py-2 w-full sm:w-[23rem] rounded-full ${
          focus ? "bg-surface-container-high shadow" : "bg-surface-container"
        } flex flex-row justify-between gap-2 `}
        title={selectedItemDisplayName}
      >
        <div className={"flex flex-row items-center gap-2 w-full"}>
          {selectedItem?.logo && <img src={selectedItem?.logo} className={"w-[1.5rem] h-[1.5rem]"} />}
          <span className={"truncate items-center text-on-surface font-medium my-auto"}>{selectedItemDisplayName}</span>
        </div>
        <div className={"flex flex-row gap-2 my-auto w-5 justify-end"}>
          <LineupBuilderDivisionPickerBadge loading={loading} />
          <div className={"flex flex-col justify-center"}>
            <SortArrowIcon className={`fill-on-surface h-3 w-3 ${focus ? "transform rotate-180" : ""}`} />
          </div>
        </div>
      </button>
      {isMobile && (
        <MobileModale open={focus}>
          <div className={"h-full  bg-surface-container-high rounded-t-lg"}>
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <div className={"flex flex-row justify-between items-center px-4 py-4"}>
                {displayTeams && <IconChevronLeft className={"h-6 w-6 text-on-surface cursor-pointer"} onClick={onDisplayCompetitions} />}
                <h3 className={"font-headers text-on-surface font-semibold m-0"}>
                  {displayTeams ? displayTeams.shortNameNoScarcity : `Competitions`}
                </h3>
                <div className={"flex flex-row gap-3"}>
                  <XIcon className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface" aria-hidden="true" onClick={onClose} />
                </div>
              </div>
            </div>
            <ul className={"max-h-[80vh] overflow-y-auto"}>
              <LineupBuilderPickerItems
                enteredCompetitions={enteredCompetitions}
                availableCompetitions={availableCompetitions}
                disabledCompetitions={disabledCompetitions}
                unavailableCompetitions={unavailableCompetitions}
                selectedItem={selectedItem}
                handleChange={handleChange}
                displayTeams={displayTeams}
                onDisplayTeams={onDisplayTeams}
                showUnavailableCompetitions={showUnavailableCompetitions}
                onChangeShowUnavailableCompetitions={onChangeShowUnavailableCompetitions}
              />
            </ul>
          </div>
        </MobileModale>
      )}
      {focus && !isMobile && (
        <div className={"absolute bg-surface-container-high rounded-xl mt-2 w-[23rem] shadow"}>
          <ul className={"h-full overflow-y-scroll"} style={{ maxHeight: "23em" }}>
            <LineupBuilderPickerItems
              enteredCompetitions={enteredCompetitions}
              availableCompetitions={availableCompetitions}
              disabledCompetitions={disabledCompetitions}
              unavailableCompetitions={unavailableCompetitions}
              selectedItem={selectedItem}
              handleChange={handleChange}
              offsetInfo={ref?.current?.getBoundingClientRect()}
              showUnavailableCompetitions={showUnavailableCompetitions}
              onChangeShowUnavailableCompetitions={onChangeShowUnavailableCompetitions}
            />
          </ul>
          <ReactTooltip id="lub-division-picker" place={"right"} />
        </div>
      )}
    </div>
  );
});

const LineupBuilderPickerItems = (props) => {
  const {
    enteredCompetitions,
    availableCompetitions,
    disabledCompetitions,
    unavailableCompetitions,
    selectedItem,
    handleChange,
    offsetInfo,
    displayTeams,
    onDisplayTeams,
    showUnavailableCompetitions,
    onChangeShowUnavailableCompetitions,
  } = props;
  const [hover, setHover] = useState({ current: {}, temp: {} });
  const isOnMobile = isMobile;

  // Smartly handle the hover state of the submenus to have a better UX
  // So we can let the user hover another menu for a short time until the user hover the submenu of the first one
  // It avoids people to have to move the mouse exactly on the menu then submenu (with a reversed L shape)
  // Now they can move the mouse in a straight diagonal line from the menu to the item in the submenu and it will work
  const onHoverChange = (id, hoverMenu, hoverSubMenu) => {
    setHover((prevHover) => {
      if (prevHover.current.id === undefined || prevHover.current.id === null) {
        if (!hoverMenu && !hoverSubMenu) {
          // No hover at all
          const res = { current: {}, temp: {} };
          // console.log(id, "no-prev-no-hover", hoverMenu, hoverSubMenu, prevHover, res);
          return res;
        }
        const res = { current: { id, hoverMenu, hoverSubMenu, effective: hoverMenu || hoverSubMenu }, temp: { ...prevHover.temp } };
        // console.log(id, "no-prev", hoverMenu, hoverSubMenu, prevHover, res);
        return res;
      }
      if (prevHover.current.id === id) {
        // The hovered change item is the current one
        if (!hoverMenu && !hoverSubMenu) {
          // But the change means that the item is not hovered at all anymore
          // Put back the temp as the current
          if (prevHover.temp) {
            const res = { current: { ...prevHover.temp }, temp: {} };
            // console.log(id, "switch-temp-to-current", hoverMenu, hoverSubMenu, prevHover, res);
            return res;
          }
          const res = { current: {}, temp: {} };
          // console.log(id, "erase-all", hoverMenu, hoverSubMenu, prevHover, res);
          return res;
        }
        // The item is still hovered
        let temp = { ...prevHover };
        if (hoverSubMenu || temp.id === id) {
          // if we hover the submenu, we remove the temp completely
          temp = {};
        }
        const res = { current: { id, hoverMenu, hoverSubMenu, effective: hoverMenu || hoverSubMenu }, temp };
        // console.log(id, "same", hoverMenu, hoverSubMenu, prevHover, res);
        return res;
      }

      // If it's a different item, we just update the temp
      if (hoverMenu || hoverSubMenu) {
        const res = { current: { ...prevHover.current }, temp: { id, hoverMenu, hoverSubMenu, effective: hoverMenu || hoverSubMenu } };
        // console.log(id, "update-temp", hoverMenu, hoverSubMenu, prevHover, res);
        return res;
      }
      // if the previous item is not hovered anymore, we remove the temp all together
      const res = { current: { ...prevHover.current }, temp: {} };
      // console.log(id, "erase-temp", hoverMenu, hoverSubMenu, prevHover, res);
      return res;
    });
  };

  if (displayTeams && Object.keys(displayTeams).length > 0) {
    return <LineupBuilderPickerTeams competition={displayTeams} onChange={handleChange} selectedItem={selectedItem} />;
  }

  return (
    <>
      {enteredCompetitions?.length > 0 && (
        <div
          title={"Competitions where the user has already a lineup saved"}
          className={"font-semibold font-headers text-on-surface-variant text-md px-4 py-4 bg-surface-container-high"}
        >
          Entered competitions
        </div>
      )}
      {enteredCompetitions?.map((c, i) => (
        <LineupBuilderRootItem
          key={c.id}
          index={i}
          competition={c}
          onChange={handleChange}
          selectedItem={selectedItem}
          withBorder={i < enteredCompetitions.length - 1}
          offsetInfo={offsetInfo}
          onHoverChange={!isOnMobile ? (hover, hoverSubMenu) => onHoverChange(c.id, hover, hoverSubMenu) : undefined}
          effectiveHover={isOnMobile ? false : hover.current.id === c.id ? hover.current.effective : false}
          onDisplayTeams={onDisplayTeams}
        />
      ))}
      {availableCompetitions?.length > 0 && (
        <div
          title={"Competitions that are enabled for the gameweek and that are meaningful for the user"}
          className={"font-semibold font-headers text-on-surface-variant text-md px-4 py-4 bg-surface-container-high"}
        >
          Available competitions
        </div>
      )}
      {availableCompetitions?.map((c, i) => (
        <LineupBuilderRootItem
          key={c.id}
          index={i}
          competition={c}
          onChange={handleChange}
          selectedItem={selectedItem}
          withBorder={i < availableCompetitions.length - 1}
          offsetInfo={offsetInfo}
          onHoverChange={!isOnMobile ? (hover, hoverSubMenu) => onHoverChange(c.id, hover, hoverSubMenu) : undefined}
          effectiveHover={isOnMobile ? false : hover.current.id === c.id ? hover.current.effective : false}
          onDisplayTeams={onDisplayTeams}
        />
      ))}
      {disabledCompetitions?.length > 0 && (
        <div
          title={
            "Competitions disabled with a saved lineup, competitions that are supposedly not interesting for the user because he has no manager team yet"
          }
          className={"font-semibold font-headers  text-on-surface-variant text-md px-4 py-4 bg-surface-container-high"}
        >
          Disabled competitions
        </div>
      )}
      {disabledCompetitions?.map((c, i) => (
        <LineupBuilderRootItem
          key={c.id}
          index={i}
          competition={c}
          onChange={handleChange}
          selectedItem={selectedItem}
          withBorder={i < disabledCompetitions.length - 1}
          offsetInfo={offsetInfo}
          onHoverChange={(hover, hoverSubMenu) => onHoverChange(c.id, hover, hoverSubMenu)}
          effectiveHover={hover.current.id === c.id ? hover.current.effective : false}
          onDisplayTeams={onDisplayTeams}
        />
      ))}
      {unavailableCompetitions?.length > 0 && (
        <div
          title={
            "Competitions disabled with a saved lineup, competitions that are supposedly not interesting for the user because he has no manager team yet"
          }
          className={"font-semibold font-headers  text-on-surface-variant text-md px-4 py-4 bg-surface-container-high"}
        >
          Unavailable competitions
        </div>
      )}
      {showUnavailableCompetitions &&
        unavailableCompetitions?.map((c, i) => (
          <LineupBuilderRootItem
            key={c.id}
            index={i}
            competition={c}
            onChange={handleChange}
            selectedItem={selectedItem}
            withBorder={i < unavailableCompetitions.length - 1}
            offsetInfo={offsetInfo}
            onHoverChange={(hover, hoverSubMenu) => onHoverChange(c.id, hover, hoverSubMenu)}
            effectiveHover={hover.current.id === c.id ? hover.current.effective : false}
            onDisplayTeams={onDisplayTeams}
          />
        ))}
      <li className={`hover:bg-containers-surface-transparent-inverse-surface-hover hover:bg-opacity-10 cursor-pointer pr-1`}>
        <div onClick={() => onChangeShowUnavailableCompetitions(!showUnavailableCompetitions)} className={`w-full h-full px-3 py-4`}>
          <button className={`w-full cursor-pointer text-left text-sm flex flex-row justify-between gap-2 text-on-surface`}>
            <div className={"flex flex-row grow-0 items-center gap-3 w-full"}>
              {showUnavailableCompetitions ? (
                <HideIcon className={"w-[1.5rem] h-[1.5rem] fill-on-surface"} />
              ) : (
                <ShowIcon className={"w-[1.5rem] h-[1.5rem] fill-on-surface"} />
              )}
              <div className={"truncate items-center my-auto flex flex-col"}>
                <span className={"w-full truncate font-medium text-on-surface"}>
                  {showUnavailableCompetitions ? "Hide unavailable competitions" : "Show unavailable competitions"}
                </span>
              </div>
            </div>
          </button>
        </div>
      </li>
    </>
  );
};

export const LineupBuilderDivisionPickerBadge = (props) => {
  const {
    competitionName,
    isInvalid,
    isLineupNotPossible,
    remainingTeams,
    lineupInvalidReasons,
    notCreated,
    disabled,
    loading,
    withTooltip,
    onTeam,
    dataTipId,
  } = props;
  const textSize = props.textSize || "text-[0.7rem] leading-[1.2em]";
  if (loading) {
    return <Spinning className={"h-4 w-4"} />;
  }
  const hasSomething = isInvalid || isLineupNotPossible || (remainingTeams !== undefined && remainingTeams !== null) || notCreated;
  if (!hasSomething) {
    return null;
  }
  const showTooltip = withTooltip && (isInvalid || isLineupNotPossible || notCreated);
  let toolTipProps = {};
  if (showTooltip) {
    const tooltip = renderToString(
      <LineupBuilderDivisionPickerBadgeTip
        title={competitionName}
        onTeam={onTeam}
        isInvalid={isInvalid}
        lineupInvalidReasons={lineupInvalidReasons}
        isLineupNotPossible={isLineupNotPossible}
        notCreated={notCreated}
      />,
    );
    toolTipProps = {
      "data-tip": tooltip,
      "data-html": true,
      "data-for": dataTipId || "lub-division-picker",
      "data-delay-show": 500,
      "data-position": "bottom",
    };
  }

  return (
    <Tag
      textSize={`font-semibold ${textSize}`}
      context={disabled ? "neutral" : remainingTeams?.current === 0 ? "emphasized-transparent" : "dim"}
      padding={"px-[8px] py-[2px]"}
      {...toolTipProps}
      icon={
        isLineupNotPossible ? (
          <LineupNotPossibleIcon className={`w-4 h-4 ${disabled ? "fill-on-surface-variant" : "fill-on-surface"}`} />
        ) : isInvalid || notCreated ? (
          <Warn className={`w-4 h-4 ${disabled ? "fill-on-surface-variant" : "fill-on-surface"}`} />
        ) : remainingTeams && remainingTeams.current >= 1 && remainingTeams.max && remainingTeams.current === remainingTeams.max ? (
          <CheckIcon className={"h-3 w-3 fill-on-surface-variant"} />
        ) : undefined
      }
    >
      {remainingTeams &&
        (remainingTeams.max ? (
          <span>
            {remainingTeams.current}/{remainingTeams.max}
          </span>
        ) : (
          <span>{remainingTeams.current}</span>
        ))}
      {!isLineupNotPossible && !isInvalid && notCreated && <span className={"whitespace-nowrap"}>Not created</span>}
    </Tag>
  );
};

const LineupBuilderRootItem = (props) => {
  const { selectedItem, onChange, competition, withBorder, offsetInfo, onHoverChange, effectiveHover, onDisplayTeams } = props;
  const [hover, setHover] = useState(false);
  const [childHover, setChildHover] = useState(false);
  const ref = useRef(null);
  if (competition === undefined || competition === null) {
    return null;
  }
  const isOnMobile = isMobile;
  const isSelected = selectedItem && selectedItem.id === competition.id;
  const {
    fullName,
    fullNameNoScarcity,
    shortNameNoScarcity,
    rarity,
    logoUrl,
    hasMaxEntriesReached,
    hasInvalidLineup,
    lineupInvalidReasons,
    hasLineupInUnavailableCompetition,
    currentNbEntries,
    maxNbEntries,
    isMultiEntry,
    singleEntryTeam,
  } = competition;
  const disabled = hasMaxEntriesReached;
  const itemInfo = getItemFromCompAndOptionalTeam(competition, singleEntryTeam);
  // const tooltip = renderToString(<LineupBuilderDivisionPickerBadgeTip lineup={lineup} competition={competition} title={displayName} />); // TODO

  const currentTop = ref?.current?.getBoundingClientRect()?.y || 0;
  const currentLeft = ref?.current?.getBoundingClientRect()?.x || 0;
  const parentHeight = convertRemToPixels(3); // Height of the picker + margin

  const debouncedHoverFalse = useDebounce(() => setHover(false), 100);
  const onMouseLeave = isMultiEntry ? debouncedHoverFalse : () => setHover(false); // When multi entry, we debounce so the user has time to go to the submenu before the hover is removed
  const parentTop = offsetInfo?.top || 0;
  const parentWidth = offsetInfo?.width || 0;
  const parentX = offsetInfo?.x || 0;
  const marginLeftSubMenu = parentX + parentWidth - currentLeft;

  useEffect(() => {
    onHoverChange && onHoverChange(hover, childHover);
  }, [hover, childHover]);

  useEffect(() => {
    return () => onHoverChange && onHoverChange(false, false);
  }, []);

  const isHover = effectiveHover;
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isOnMobile) {
      // Click on a competition on mobile
      if (isMultiEntry) {
        onDisplayTeams && onDisplayTeams(competition);
      } else {
        onChange(itemInfo);
      }
    } else {
      if (isMultiEntry) {
        // click on competition, we go to the first team available
        const firstTeam = [...competition.multiEntryTeams.remaining, ...competition.multiEntryTeams.used];
        onChange(getItemFromCompAndOptionalTeam(competition, firstTeam[0]));
      } else {
        // click on team
        onChange(itemInfo);
      }
    }
  };
  return (
    <li
      ref={ref}
      className={`${
        withBorder ? "border-b border-outline-variant" : ""
      } hover:bg-containers-surface-transparent-inverse-surface-hover hover:bg-opacity-10 cursor-pointer pr-1`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={onMouseLeave}
    >
      <div onClick={onClick} className={` w-full h-full px-3 py-2 ${isSelected ? "font-semibold text-primary" : "text-on-surface"}`}>
        <button
          value={fullNameNoScarcity}
          className={`w-full cursor-pointer text-left text-sm flex flex-row justify-between gap-2 text-on-surface`}
        >
          <div className={"flex flex-row grow-0 items-center gap-3 w-full"}>
            {logoUrl ? <img src={logoUrl} className={"w-[1.5rem] h-[1.5rem]"} /> : <div className={"h-[1.5rem] w-[1.5rem]"}></div>}
            <div className={"truncate items-center my-auto flex flex-col"}>
              <span className={clsx("w-full truncate font-medium", isSelected ? "text-primary" : "text-on-surface")}>
                {shortNameNoScarcity}
              </span>
              <span className={clsx("w-full text-xs truncate font-medium", isSelected ? "text-primary" : "text-on-surface-variant")}>
                {getRarityDisplayName(rarity)}
              </span>
            </div>
          </div>
          <div className={"flex flex-row gap-3 my-auto justify-end items-center"}>
            <LineupBuilderDivisionPickerBadge
              competitionName={fullName}
              withTooltip={true}
              isInvalid={hasInvalidLineup}
              isLineupNotPossible={hasLineupInUnavailableCompetition}
              lineupInvalidReasons={lineupInvalidReasons}
              remainingTeams={{
                current: currentNbEntries,
                max: maxNbEntries,
              }}
              dataTipId={"lub-division-picker"}
              disabled={disabled}
              onTeam={!isMultiEntry}
            />
            <div className={"w-5 flex justify-center items-center"}>
              {isMultiEntry && <IconChevronRight className={clsx("h-5 w-5", isSelected ? "fill-primary" : "fill-on-surface")} />}
              {isSelected && !isMultiEntry && <CheckIcon className={"h-5 w-5 fill-primary"} />}
            </div>
          </div>
        </button>
      </div>
      {isMultiEntry && isHover && (
        <div
          style={{
            marginTop: currentTop - parentHeight - parentTop,
            marginLeft: marginLeftSubMenu,
          }}
          className={"absolute z-500 rounded-xl left-0 top-0 pl-1 w-80 overflow-hidden"}
        >
          <div
            className={"bg-surface-container-high rounded-xl shadow overflow-hidden w-full"}
            onMouseEnter={() => setChildHover(true)}
            onMouseLeave={() => setChildHover(false)}
          >
            <LineupBuilderPickerTeams competition={competition} onChange={onChange} selectedItem={selectedItem} />
          </div>
        </div>
      )}
    </li>
  );
};

const LineupBuilderPickerTeams = (props) => {
  const { competition, selectedItem, onChange } = props;
  const teams = competition.multiEntryTeams;
  const { remaining } = teams;
  const used = teams.used || [];
  return (
    <ul className={"pb-2"}>
      <ReactTooltip id="lub-division-picker-team" />
      {remaining?.length > 0 && (
        <div className={"font-semibold font-headers  text-on-surface-variant text-sm px-4 py-4 bg-surface-container-high"}>Remaining</div>
      )}
      {remaining?.map((t, i) => (
        <LineupBuilderPickerTeam
          key={t.name}
          competition={competition}
          team={t}
          onChange={onChange}
          withBorder={i < remaining.length - 1}
          selectedItem={selectedItem}
        />
      ))}
      <div className={"font-semibold font-headers text-on-surface-variant text-sm px-4 py-4 bg-surface-container-high"}>Saved</div>
      {used.map((t, i) => (
        <LineupBuilderPickerTeam
          key={t.lineup.id}
          competition={competition}
          team={t}
          onChange={onChange}
          withBorder={i < used.length - 1}
          selectedItem={selectedItem}
        />
      ))}
      {used.length === 0 && <div className={"px-4 text-xs text-on-surface-variant font-medium pb-3"}>No team built yet</div>}
    </ul>
  );
};

const checkIsSelectedTeam = (selectedItem, team) => {
  if (
    selectedItem === undefined ||
    selectedItem === null ||
    selectedItem.team === undefined ||
    selectedItem.team === null ||
    team === undefined ||
    team === null
  ) {
    return false;
  }
  // A lineup has been selected
  if (selectedItem.team.hasLineup) {
    if (team.hasLineup) {
      return selectedItem.team.lineup.id === team.lineup.id;
    }
    return false;
  }
  // No lineup but a manager team has been selected
  if (selectedItem.team.managerTeam) {
    if (team.managerTeam) {
      if (selectedItem.team.managerTeam.notCreated && team.managerTeam.notCreated) {
        return (
          selectedItem.team.managerTeam.activeDivision === team.managerTeam.activeDivision &&
          selectedItem.team.lineupTeamNumber === team.lineupTeamNumber
        );
      }
      return selectedItem.team.managerTeam.id === team.managerTeam.id;
    }
    return false;
  }
  // No lineup or manager team has been selected, but perhaps a team number for the same division id
  if (
    selectedItem.team.competitionId === team.competitionId &&
    selectedItem.team.lineupTeamNumber !== undefined &&
    selectedItem.team.lineupTeamNumber !== null &&
    team.lineupTeamNumber !== undefined &&
    team.lineupTeamNumber !== null
  ) {
    return selectedItem.team.lineupTeamNumber === team.lineupTeamNumber;
  }
  // No lineup or manager team or specific team number, we just compare division ids
  return selectedItem.id === team.competitionId;
};

const LineupBuilderPickerTeam = (props) => {
  const { competition, team, withBorder, onChange, selectedItem } = props;
  const { lineup, logoUrl, name, managerTeam } = team;
  const invalidLineup = lineup ? lineup.invalid : false;
  const invalidReasons = lineup ? lineup.invalidReasons : [];
  const hasLineupInUnavailableCompetition = lineup ? lineup.inUnavailableCompetition : false;
  const managerTeamNoCreated = managerTeam ? managerTeam.notCreated : false;
  const itemInfo = getItemFromCompAndOptionalTeam(competition, team);
  const isSelected = checkIsSelectedTeam(selectedItem, team);
  const hasBadge = invalidLineup || hasLineupInUnavailableCompetition || managerTeamNoCreated;

  return (
    <div
      className={clsx(
        "w-full h-full px-3 py-2 cursor-pointer hover:bg-containers-surface-transparent-inverse-surface-hover hover:bg-opacity-10",
        isSelected ? "font-semibold text-primary" : "text-on-surface",
        withBorder ? "border-b border-outline-variant" : "",
      )}
      onClick={() => onChange(itemInfo)}
    >
      <button className={`w-full cursor-pointer text-left text-sm flex flex-row justify-between gap-4 text-on-surface`}>
        <div className={"flex flex-row grow items-center gap-3 w-full"}>
          {logoUrl ? <img src={logoUrl} className={"w-[1.5rem] h-[1.5rem]"} /> : <div className={"h-[1.5rem] w-[1.5rem]"}></div>}
          <div className={clsx("min-w-[8em] truncate items-center flex flex-col overflow-hidden", managerTeam ? "" : "my-1")}>
            <span
              title={name}
              className={clsx(
                "w-full truncate font-medium",
                isSelected ? "text-primary " : "text-on-surface",
                isSelected && hasBadge && managerTeamNoCreated && "max-w-[8em]",
                isSelected && hasBadge && !managerTeamNoCreated && "max-w-[12em]",
                isSelected && !hasBadge && "max-w-[16em]",
                !isSelected && hasBadge && "max-w-[14em]",
                !isSelected && !hasBadge && "max-w-[18em]",
              )}
            >
              {name}
            </span>
            {managerTeam && (
              <span className={clsx("w-full text-xs truncate font-medium", isSelected ? "text-primary" : "text-on-surface-variant")}>
                Division {managerTeam.activeDivision}
              </span>
            )}
          </div>
        </div>
        <div className={"flex flex-row grow-0 gap-3 my-auto justify-end items-center"}>
          <LineupBuilderDivisionPickerBadge
            competitionName={competition.fullName}
            isInvalid={invalidLineup}
            lineupInvalidReasons={invalidReasons}
            isLineupNotPossible={hasLineupInUnavailableCompetition}
            notCreated={managerTeamNoCreated}
            disabled={managerTeamNoCreated}
            withTooltip={true}
            onTeam={true}
            dataTipId={"lub-division-picker-team"}
          />
          {isSelected && (
            <div className={"flex justify-center items-center"}>
              <CheckIcon className={clsx("h-5 w-5 fill-primary")} />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

const LineupBuilderDivisionPickerBadgeTip = (props) => {
  const { title, isInvalid, isLineupNotPossible, notCreated, onTeam } = props;
  const display = isInvalid || isLineupNotPossible || notCreated;
  if (!display) {
    return null;
  }
  return (
    <div className={"flex flex-col gap-2"}>
      <h4 className={"text-white text-md text-center"}>{title}</h4>
      <div className={"flex flex-col gap-3 items-start max-w-md"}>
        {isInvalid && (
          <div className={"flex flex-col gap-1"}>
            <div className={"flex flex-row gap-1 items-center"}>
              <LineupBuilderDivisionPickerBadge isInvalid={true} />
              <h4 className={"m-0 text-inverse-on-surface font-semibold text-md"}>
                {onTeam ? `Lineup is invalid` : `At least one lineup invalid`}
              </h4>
            </div>
            <div className={"flex flex-col gap-0.5 pl-2"}>
              <p>
                {onTeam
                  ? `Lineup has empty spots, you will not be able to submit it to Sorare`
                  : `One of the lineup in the competition has empty spots. You will not be able to submit it to Sorare`}
              </p>
            </div>
          </div>
        )}
        {isLineupNotPossible && (
          <div className={"flex flex-col gap-1"}>
            <div className={"flex flex-row gap-1 items-center"}>
              <LineupBuilderDivisionPickerBadge isLineupNotPossible={true} />
              <h4 className={"m-0 text-inverse-on-surface font-semibold text-md"}>Competition is inactive</h4>
            </div>
            <div className={"flex flex-col gap-0.5 pl-2"}>
              <p>Competition is inactive on Sorare and you have a saved lineup on this division.</p>
              <p>You will not be able to submit it to Sorare.</p>
            </div>
          </div>
        )}
        {notCreated && (
          <div className={"flex flex-col gap-1"}>
            <div className={"flex flex-row gap-1 items-center"}>
              <LineupBuilderDivisionPickerBadge notCreated={true} />
              <h4 className={"m-0 text-inverse-on-surface font-semibold text-md"}>Manager team does not exist</h4>
            </div>
            <div className={"flex flex-col gap-0.5 pl-2"}>
              <p>
                We don&apos;t know this manager team. Either it does not exist on Sorare and you have to create it there, or it does exist
                already, and we will get it the first time you submit a lineup on this team on Sorare.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const getDivisionDisplayNameV2 = (itemInfo) => {
  if (itemInfo === undefined || itemInfo === null) {
    return "";
  }
  const { name, team } = itemInfo;
  const teamName = team?.name || "";
  let displayNameParts = [name];
  if (teamName) {
    displayNameParts.push(teamName);
  }
  return displayNameParts.join(" · ");
};

export const getDivisionDisplayNameSubTitleV2 = (selectedItem, noTeam) => {
  if (selectedItem === undefined || selectedItem === null) {
    return "";
  }
  const { team, rarity } = selectedItem;
  let displayNameParts = [];
  if (team?.managerTeam?.activeDivision > 0) {
    displayNameParts.push(`Division ${team?.managerTeam?.activeDivision}`);
  }
  if (rarity) {
    displayNameParts.push(getRarityDisplayName(rarity));
  }
  if ((noTeam === undefined || !noTeam) && team?.name) {
    displayNameParts.push(team?.name);
  }
  return displayNameParts.join(" · ");
};

export const getItemFromCompAndOptionalTeam = (comp, team) => {
  if (comp === undefined || comp === null) {
    return null;
  }
  const item = {
    id: comp.id,
    divisionId: team?.competitionId || comp.id,
    name: comp.shortNameNoScarcity,
    rarity: comp.rarity,
    logo: team?.logoUrl || comp.logoUrl,
    team: team,
  };
  return item;
};
