export const getTotalScoreColors = (scoreNumber) => {
  const score = scoreNumber.toString();
  let scoreBackgroundColor = "#E5E5E5";
  let scoreColor = "white";
  if (score === "DNP") {
    scoreColor = "#6F6F6F";
  } else if (Number(score) < 15) {
    scoreColor = "#E73535";
    scoreBackgroundColor = "#FF6868";
  } else if (Number(score) < 30) {
    scoreColor = "#DD8416";
    scoreBackgroundColor = "#F89F31";
  } else if (Number(score) < 45) {
    scoreColor = "#D8B21B";
    scoreBackgroundColor = "#EAC739";
  } else if (Number(score) < 60) {
    scoreColor = "#65A434";
    scoreBackgroundColor = "#81BF50";
  } else if (Number(score) < 75) {
    scoreColor = "#29975D";
    scoreBackgroundColor = "#50BF84";
  } else if (Number(score) <= 100) {
    scoreColor = "#106A3A";
    scoreBackgroundColor = "#169A54";
  }
  return [scoreColor, scoreBackgroundColor];
};
