import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { withUser } from "../../userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactTooltip } from "../util/tooltip.js";
import SDLoading from "../util/SDLoading";
import ScoreEllipse from "../util/scoreEllipse";
import { errorCatcher } from "../util/errors";
import GLOBAL_MARGIN from "../util/margin";
import BoxWithToggle from "../util/boxWithToggle";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import { sorareBaseball, sorareFootball } from "../util/sports";
import { withRouter } from "react-router-dom";
import Spinner from "../loader/spinner";
import RankLabel from "../util/rankLabel";
import ItemSearchForm from "../search/itemsSearchForm";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { MonthLongLeagueBanner } from "../longTermLeagues/monthLong";
import { selectCustomClass, selectCustomStyle, SelectWithIcon } from "../util/select";

function SO5LiveRankings(props) {
  const underT1 = props.user.tier && !t1OrAbove(props.user.tier) && props.sport !== sorareBaseball;
  const [rankings, setRankings] = useState([]);
  const [noRanking, setNoRanking] = useState(true);
  const [displayRankings, setDisplayRankings] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [division, setDivision] = useState({});
  const [showLineups, setShowLineups] = useState(!underT1);
  const [stats, showStats] = useState({});
  const [showUncompletedLineups, setShowUncompletedLineups] = useState(false);
  const [canBeatMe, setCanBeatMe] = useState(false);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [manager, setManager] = useState("");
  const [managerSearchResults, setManagerSearchResults] = useState([]);
  const [longTermLeagues, setLongTermLeagues] = useState([]);

  useEffect(() => {
    if (props.gwNumber !== undefined && props.gwNumber > 0) {
      getDivisions();
    }
  }, [props.gwNumber]);

  useEffect(() => {
    const underT1 = props.user.tier && !t1OrAbove(props.user.tier) && props.sport !== sorareBaseball;
    setShowLineups(!underT1);
  }, [props.user.tier, props.sport]);

  useEffect(() => {
    props
      .fetch(`/search-api/search/autocomplete?limit=30&query=${manager}`)
      .then((response) => response.json())
      .then((res) => {
        setManagerSearchResults(res?.user || []);
      })
      .catch(errorCatcher());
  }, [manager]);

  useEffect(() => {
    setHasMore(true);
    if (selectedDivision !== "") {
      getRanking(true, 1, 20);
    }
  }, [selectedDivision, showUncompletedLineups, canBeatMe]);

  const getDivisions = () => {
    if (props.gwNumber !== undefined && props.gwNumber > 0) {
      props
        .fetch("/apiv2/gw/divisionsFull/" + props.gwNumber)
        .then((response) => response.json())
        .then((res) => {
          const options = [];
          if (res !== undefined) {
            res
              .filter((e) => e.Enabled)
              .forEach((d) => {
                options.push({ value: d.DivisionId, label: d.divisionFullDisplayName, icon: d.LogoUrl });
              });
          }
          setDivisionOptions(options);
          if (options.length > 0) {
            setSelectedDivision(options[0].value);
          }
        })
        .catch(errorCatcher());
    }
  };

  useEffect(() => {
    getDivisions();
  }, [props.gwNumber]);

  const getRanking = (reset, from, to) => {
    setIsLoading(true);
    let prevRankings = displayRankings;
    if (reset) {
      setRankings([]);
      setDisplayRankings([]);
      prevRankings = [];
    }
    props
      .fetch("/apiv2/SO5/rankings", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gw_number: props.gwNumber,
          from: from,
          to: to,
          unfinished: showUncompletedLineups,
          division_full_id: selectedDivision,
          can_beat_me: canBeatMe,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res === null) {
          setRankings([]);
          setDisplayRankings([]);
          setNoRanking(true);
          setHasMore(false);
        } else if (res.rankings === null) {
          setHasMore(false);
        } else {
          setRankings(res.rankings);
          setDisplayRankings(prevRankings.concat(res.rankings));
          setFrom(from);
          setTo(to);
          setNoRanking(false);
          setDivision(res.division);
          setLongTermLeagues(res.longTermLeagues);
        }
        setIsLoading(false);
        ReactTooltip.rebuild();
        //this.filterRankings(this.state.showUncompletedLineups)
      })
      .catch(
        errorCatcher(() => {
          setIsLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  const onMore = () => {
    getRanking(false, from + 20, to + 20);
  };

  const pct = (100 - (division?.CardsLeft * 100) / (division?.CardsLeft + division?.CardsPlayed + division?.CardsLive)).toFixed(0);

  const handleChangeDivision = (selectedOption) => {
    if (selectedOption) {
      setSelectedDivision(selectedOption.value);
    }
  };

  return (
    <div className={"space-y-8 mt-8 " + GLOBAL_MARGIN}>
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-4"}>
        <div className={"z-20"}>
          <SelectWithIcon
            options={divisionOptions}
            selectedValue={selectedDivision}
            onChange={handleChangeDivision}
            containerClasses={"rounded"}
          />
        </div>
        <div className={"z-20 h-full"}>
          <BoxWithToggle
            label={"Show lineups details"}
            className={"h-full shadow"}
            isActive={showLineups}
            disabled={underT1}
            disabledTip={underT1 ? "Star membership is needed" : ""}
            setActive={() => setShowLineups(!showLineups)}
          />
        </div>
        <div className={"z-20 h-full"}>
          <BoxWithToggle
            label={"Unfinished lineups only"}
            isActive={showUncompletedLineups}
            className={"h-full shadow"}
            setActive={() => setShowUncompletedLineups(!showUncompletedLineups)}
          />
        </div>
        <div className={"z-20 h-full"}>
          <BoxWithToggle
            label={"Can beat me"}
            isActive={canBeatMe}
            className={"h-full shadow"}
            setActive={() => setCanBeatMe(!canBeatMe)}
          />
        </div>
        <div>
          <ItemSearchForm
            type={"manager"}
            value={manager}
            onClean={() => setManager("")}
            onClickOutside={() => setManager("")}
            results={managerSearchResults}
            onClickItem={(managerSlug) => props.history.push(`/SO5Results/${managerSlug}`)}
            onChange={(v) => {
              setManager(v.target.value);
            }}
          />
        </div>
      </div>

      {isLoading && (
        <Box margin={{ vertical: "medium" }}>
          <SDLoading />
        </Box>
      )}

      {displayRankings.length !== 0 && (
        <Box margin={{ top: "small" }}>
          <div className={"w-full md:w-1O/12 xl:w-9/12 2xl:w-8/12 4xl:w-7/12 mx-auto flex flex-col gap-4"}>
            {isFree(props.user.tier) && (
              <UpgradeLimitBox
                className={"bg-white bg-upgrade-bg-rankings-lineups bg-cover h-upgrade-banner-m w-full"}
                title={"Want more insights?"}
                description={"Become a Star member to unlock lineups details and power left"}
              />
            )}
            <div className={"text-center w-full rounded-xl bg-surface-container text-on-surface p-4 flex flex-col justify-center gap-2"}>
              <h3 className={"font-semibold text-md m-0"}>{`Leaderboard ${pct}% complete`}</h3>
              <h4 className={"font-normal text-sm m-0"}>
                {division?.CardsLeft} cards left out of {division?.CardsLeft + division?.CardsPlayed + division?.CardsLive} total cards (
                {division?.CardsLive} playing live)
              </h4>
              <div className={"w-full mt-2"}>
                <div className="bg-transparent-inverse-surface-low bg-opacity-10 rounded-full h-2 mx-auto" style={{ width: "90%" }}>
                  <div className="bg-primary h-2 rounded-full" style={{ width: pct + "%" }}></div>
                </div>
              </div>
            </div>
            <MonthLongLeagueBanner leagues={longTermLeagues} sport={sorareFootball} />
            <div className={"rounded-xl bg-surface-container text-on-surface"}>
              <InfiniteScroll
                dataLength={displayRankings.length}
                scrollableTarget={"window"}
                next={onMore}
                hasMore={hasMore}
                useWindow={true}
                loader={
                  <div className={"m-3"}>
                    <Spinner />
                  </div>
                }
              >
                {displayRankings.length === 0 ? (
                  noRanking ? (
                    <Box margin={{ top: "medium" }}>
                      <Text>No ongoing gameweek.</Text>
                    </Box>
                  ) : (
                    <SDLoading />
                  )
                ) : (
                  displayRankings.map((rank, index) => {
                    if (rank !== null) {
                      let lineupRank = rank.lineup.Rank;
                      let tip = "No cards left.";
                      if (rank.lineup.PlayersLeft !== "") {
                        tip = rank.lineup.PlayersLeft;
                      }
                      let powerTip = "No cards left.";
                      if (rank.lineup.PlayersLive !== "") {
                        powerTip = rank.lineup.PlayersLive;
                      }
                      let manager = rank.manager;
                      let score = parseFloat(rank.lineup.Score);
                      let powerLive = parseFloat(rank.lineup.LivePower).toFixed(2) + "x live";
                      let powerLeft = parseFloat(rank.lineup.PowerLeft).toFixed(2) + "x left";
                      let color = "#292929";
                      if (lineupRank <= 3) {
                        color = "#44AF1D";
                      } else if (division.RewardType === "ranking") {
                        if (lineupRank <= division.PlaceThreshold1) {
                          color = "#4BC11F";
                        } else if (division.PlaceThreshold2 !== null) {
                          if (lineupRank <= division.PlaceThreshold2) {
                            color = "#7FC035";
                          }
                        }
                      } else if (division.RewardType === "top_and_score") {
                        if (lineupRank <= division.PlaceThreshold2) {
                          color = "#4BC11F";
                        } else if (lineupRank <= division.PlaceThreshold1) {
                          color = "#7FC035";
                        }
                      } else if (division.RewardType === "top") {
                        if (division.PlaceThreshold2 !== null) {
                          if (rank.lineup.Top >= division.PlaceThresold2) {
                            color = "#4BC11F";
                          } else if (rank.lineup.Top >= division.PlaceThresold1) {
                            color = "#7FC035";
                          }
                        } else {
                          if (rank.lineup.Top >= division.PlaceThresold1) {
                            color = "#4BC11F";
                          }
                        }
                      }
                      return (
                        <div
                          key={lineupRank}
                          className={"flex flex-row border-b-2 border-transparent-inverse-surface-low border-opacity-10 h-24 space-x-4"}
                        >
                          <div className={"flex flex-col items-center text-center justify-center font-semibold text-md w-2/12 md:w-1/12"}>
                            <RankLabel rank={lineupRank} />
                          </div>
                          <div className={"flex flex-col justify-center items-center hidden md:flex md:flex-col w-1/12 justify-center"}>
                            <div className={"w-14 h-14 rounded-full flex flex-row justify-center self-center"}>
                              {manager.PictureUrl !== "" ? (
                                <img src={manager.PictureUrl} className={"h-full w-full object-contain rounded-full"} />
                              ) : (
                                <FontAwesomeIcon icon={faUser} className={"self-center"} size={"2x"} color={"#292929"} />
                              )}
                            </div>
                          </div>
                          <div className={"md:w-6/12 w-5/12 lg:w-3/12 flex flex-col justify-center items-start"}>
                            <p className={"font-semibold text-md hover:font-bold"}>
                              <a href={"/SO5results/" + manager.Slug} target={"_blank"} rel="noreferrer">
                                {manager.Nickname}
                              </a>
                            </p>
                            <p className={"text-sm text-textGrey3"}>{manager.TeamName}</p>
                          </div>
                          {showLineups && (
                            <div className={"hidden lg:flex lg:flex-row justify-center items-center space-x-4 w-5/12"}>
                              {rank.details.map((details) => {
                                let bg = "bg-outline-variant";
                                if (details.scarcity.toLowerCase() === "rare") {
                                  bg = "bg-rare-halo";
                                } else if (details.scarcity.toLowerCase() === "limited") {
                                  bg = "bg-limited-halo";
                                } else if (details.scarcity.toLowerCase() === "super rare") {
                                  bg = "bg-sr-halo";
                                } else if (details.scarcity.toLowerCase() === "unique") {
                                  bg = "bg-unique-halo";
                                }
                                return (
                                  <div key={details.hover + index} className={"flex flex-col items-center space-y-2"}>
                                    <div className={"w-12 h-12 rounded-full"}>
                                      <img
                                        data-tip={details.hover}
                                        src={details.avatar}
                                        className={"rounded-full w-full h-full object-contain pt-1.5 " + bg}
                                      />
                                    </div>
                                    <ScoreEllipse
                                      size={"text-sm"}
                                      isCaptain={details.captain}
                                      power={details.power}
                                      score={details.score}
                                      has_played={details.has_played}
                                      out={details.out}
                                      sub={details.sub}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {!showLineups && <div className={"w-5/12 hidden lg:flex lg:flex-row"} />}
                          <div className={"flex flex-col w-4/12 lg:w-2/12 justify-center gap-0.5 pr-4 text-center"}>
                            {isFree(props.user.tier) ? (
                              <div style={{ maxWidth: "fit-content" }} className={"ml-auto mr-4"}>
                                <ScoreEllipse size={"text-base"} score={score.toFixed(2)} power={1} precision={2} />
                              </div>
                            ) : (
                              <>
                                <p style={{ color: color }} className={"text-lg font-semibold"}>
                                  {score.toFixed(2)} pts
                                </p>
                                <p className={"md:text-sm text-xxs text-textGrey3"} data-tip={powerTip}>
                                  {powerLive},{" "}
                                  <span className={"md:text-sm text-xxs"} data-tip={tip}>
                                    {powerLeft}
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                  })
                )}
              </InfiniteScroll>
            </div>
          </div>

          <ReactTooltip />
        </Box>
      )}
    </div>
  );
}

export default withRouter(withUser(SO5LiveRankings));
