import React, { useEffect, useState } from "react";
import { Anchor, Box, Grid, Image, Text } from "grommet";
import ResponsiveGrid from "../util/responsiveGrid";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function LatestManagerSignings(props) {
  const [cards, setCards] = useState(null);

  useEffect(() => getManagerInfo(), []);

  const getManagerInfo = () => {
    props
      .fetch("/apiv2/manager/signings/" + props.manager.Slug)
      .then((response) => response.json())
      .then((res) => {
        setCards(res);
      })
      .catch(errorCatcher());
  };

  //const cards = props.cards;
  const columns = {
    small: ["auto"],
    medium: ["flex", "flex", "flex"],
    large: ["flex", "flex", "flex", "flex", "flex"],
  };

  const rows = {
    small: ["auto", "auto", "auto"],
    medium: ["auto", "auto"],
    large: ["auto"],
  };
  return (
    <Box>
      {cards !== null ? (
        <div>
          <ResponsiveGrid rows={rows} columns={columns} gap="small">
            {cards.map((c, i) => {
              return (
                <Grid key={c.TokenId}>
                  <Box align={"center"} width={"90%"}>
                    <Anchor color={"brand"} target={"_blank"} href={"/card/" + c.TokenId}>
                      <Image width={"100%"} src={c.PictureUrl} />
                    </Anchor>
                  </Box>
                </Grid>
              );
            })}
          </ResponsiveGrid>
        </div>
      ) : (
        <Text>
          <em>No card available.</em>
        </Text>
      )}
    </Box>
  );
}

export default withUser(LatestManagerSignings);
