import { RewardsBox } from "../util/rewardsBox";
import React from "react";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { LongTermLeagueBanner } from "./longTermLeagueBanner";

export const MonthLongLeagueBanner = (props) => {
  return <LongTermLeagueBanner {...props} title={"Month-long leaderboards"} />;
};

export const MonthInfoBox = (props) => {
  const { info, loading } = props;
  let label = `GW${info?.gwStart}-${info?.gwEnd}`;
  if (info?.sorareSeason !== undefined) {
    label = `GW${info?.sorareGwStart}-${info?.sorareGwEnd} ${info?.sorareSeason}`;
  }
  return (
    <div
      style={{
        background: "linear-gradient(80deg, #008F97 14.96%, #005E78 87.01%)",
      }}
      className={`rounded-xl overflow-hidden w-full text-white`}
    >
      <div
        className={
          "flex flex-row bg-center justify-between items-center bg-no-repeat bg-contain bg-logo-sd-transparent w-full gap-1 p-4 h-full"
        }
        style={{
          backgroundSize: "150px",
          backgroundPositionY: "-40px",
        }}
      >
        {info === null || info === undefined || loading ? (
          <div className={"h-4 w-16 rounded-md bg-white animate-pulse-sm opacity-30"}></div>
        ) : (
          <span className={"text-xs font-semibold"}>{label}</span>
        )}
        <MonthLongStatusTag loading={info === null || info === undefined || loading} status={info?.status} />
      </div>
    </div>
  );
};

const MonthLongStatusTag = (props) => {
  const { status, loading } = props;
  return (
    <div
      className={`rounded-full py-1 px-2 text-xs text-black bg-white font-semibold ${
        loading ? "animate-pulse-sm w-20 opacity-30 h-5" : ""
      }`}
    >
      {status === "STARTED" ? "In progress" : status === "TO_START" ? "Not started" : status === "ENDED" ? "Completed" : ""}
    </div>
  );
};

const greatingMessageInfo = {
  no_rewards: {
    title: "🍀",
    label: "Better luck next time",
    bg: "bg-long-term-leagues-leaderboard-rewards-no-rewards",
  },
  low: {
    title: "Good luck! 🤞",
    bg: "bg-long-term-leagues-leaderboard-rewards-low",
  },
  medium: {
    label: "You’re good! 🔮",
    bg: "bg-long-term-leagues-leaderboard-rewards-medium",
  },
  high: {
    label: "What a month! 🔥",
    bg: "bg-long-term-leagues-leaderboard-rewards-high",
  },
};

export const MonthRewardsBox = (props) => {
  const { rewards, loading } = props;
  const cards = rewards?.cards;
  const money = rewards?.money;
  const coins = rewards?.coins;
  const rewardsLevel = rewards?.rewardsLevel;
  const { title, label, bg } = rewardsLevel ? greatingMessageInfo[rewardsLevel] : {};
  return (
    <RewardsBox
      loading={loading}
      title={title}
      label={label}
      cards={cards}
      money={money}
      coins={coins}
      bg={bg}
      width={"w-full"}
      height={"min-h-[10rem]"}
    />
  );
};

export const UserBox = (props) => {
  const { user, loading } = props;
  return (
    <div
      className={`bg-surface-container text-on-surface grid grid-cols-3 gap-1 rounded-xl border border-primary p-2 ${
        loading ? "animate-pulse-sm" : ""
      }`}
    >
      <div className={"flex flex-grow aspect-square justify-center items-center p-2"}>
        <a href={user?.Slug ? `/manager/${user.Slug}` : ""}>
          <img
            className={"inline aspect-square w-full object-cover rounded-full"}
            onError={(e) => (e.target.src = EmptyAvatar)}
            src={user?.PictureUrl ? user.PictureUrl : EmptyAvatar}
          />
        </a>
      </div>
      <div className={"flex flex-col justify-center gap-1 text-xs col-span-2"}>
        {user === null || user === undefined || loading ? (
          <>
            <div className={"h-6 w-36 rounded-md bg-surface-container-high animate-pulse-sm"}></div>
            <div className={"space-y-0.5"}>
              <div className={"h-4 w-20 rounded-md bg-surface-container-high animate-pulse-sm"}></div>
              <div className={"h-4 w-20 rounded-md bg-surface-container-high animate-pulse-sm"}></div>
            </div>
          </>
        ) : (
          <>
            <h3 className={"m-0 font-headers text-md flex-wrap truncate"} style={{ maxWidth: "90%" }} title={user?.Nickname}>
              {user?.Nickname}
            </h3>
            <div className={"font-semibold"}>
              <p>{user?.TeamName}</p>
              <p>Since {new Date(user?.CreationDate).toLocaleDateString()}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
