import React, { useState } from "react";

function BasketballTeamBoxScore(props) {
  const [team, setTeam] = useState("home");

  const home = props.home;
  const away = props.away;

  const boxScoreHeaderStyle = "text-xxs font-semibold text-textGrey3 uppercase text-center ";

  let statsToUse = home.players;
  if (team === "away") {
    statsToUse = away.players;
  }

  if (statsToUse === null) {
    return null;
  }
  let theStats = [];
  if (statsToUse.starters !== null) {
    theStats = theStats.concat(statsToUse.starters);
  }
  if (statsToUse.bench !== null) {
    theStats = theStats.concat(statsToUse.bench);
  }

  theStats = theStats.filter((s) => {
    return s.stats.minutes > 0 || s.stats.started === true;
  });

  const tabStatStyle = "font-medium text-xs text-center";

  return (
    <div>
      <div className={"flex flex-row bg-textGrey1 rounded-t-lg"}>
        <div className={"w-6/12 border-r border-grey-82 flex flex-row justify-center cursor-pointer"} onClick={() => setTeam("home")}>
          <div className={"w-12 h-12 object-contain py-2"}>
            <img className={"w-full h-full object-contain"} src={home.team.PictureUrl} />
          </div>
        </div>
        <div className={"w-6/12 flex flex-row justify-center cursor-pointer"} onClick={() => setTeam("away")}>
          <div className={"w-12 h-12 object-contain py-2"}>
            <img className={"w-full h-full object-contain"} src={away.team.PictureUrl} />
          </div>
        </div>
      </div>
      <div className={"bg-white rounded-b-lg"}>
        <table className={"w-full"}>
          <thead>
            <tr className={"border-b border-grey-e5"}>
              <td className={"text-xxs font-semibold text-textGrey3 uppercase pl-2 w-4/12 py-2"}>Player</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>Min</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>Pts</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>FG</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>3P</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>FT</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>RB</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>AST</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>STL</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>BLK</td>
              <td className={boxScoreHeaderStyle + " w-1/18"}>TO</td>
            </tr>
          </thead>

          <tbody>
            {theStats.map((p, i) => {
              const stats = p.stats;
              if (stats.minutes === 0) {
                return null;
              }
              let border = "border-b border-grey-e5";
              if (i === theStats.length - 1) {
                border = "";
              }
              return (
                <tr className={border}>
                  <td className={"pl-2 py-1 text-xs font-semibold"}>
                    <a href={"/player/" + p.player.PlayerId}>{p.player.MatchName}</a>
                  </td>
                  <td className={tabStatStyle}>{stats.minutes}</td>
                  <td className={tabStatStyle}>{stats.points}</td>
                  <td className={tabStatStyle}>
                    {stats.fgMade}/{stats.fgAtt}
                  </td>
                  <td className={tabStatStyle}>
                    {stats.threePtMade}/{stats.threePtAtt}
                  </td>
                  <td className={tabStatStyle}>
                    {stats.ftMade}/{stats.ftAtt}
                  </td>
                  <td className={tabStatStyle}>{stats.totalRebounds}</td>
                  <td className={tabStatStyle}>{stats.assists}</td>
                  <td className={tabStatStyle}>{stats.steals}</td>
                  <td className={tabStatStyle}>{stats.blocks}</td>
                  <td className={tabStatStyle}>{stats.turnovers}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BasketballTeamBoxScore;
