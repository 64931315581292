import React from "react";

export const LeaderboardProgress = (props) => {
  const { loading } = props;
  if (props.progress === null) {
    return (
      <div className={`flex flex-col gap-2 rounded-md p-3 bg-surface-container w-full`}>
        <div className={"flex flex-row justify-between text-sm font-semibold"}>
          <span className={"h-5 w-44 rounded-md bg-surface-container-high animate-pulse-sm"}></span>
          <span className={"h-5 w-36 rounded-md bg-surface-container-high animate-pulse-sm"}></span>
        </div>
        <div>
          <div className={"h-2 bg-outline-variant rounded-full overflow-hidden animate-pulse-sm"}></div>
        </div>
      </div>
    );
  }
  const { gwProgress, totalProgress, pastOrOngoingGw, totalGw, ongoingGw } = props.progress;
  const totalProgressRound = totalProgress >= 99 && totalProgress < 100 ? 99 : Math.round(totalProgress);
  const gwProgressRound = gwProgress >= 99 && gwProgress < 100 ? 99 : Math.round(gwProgress);
  const rightMessage = ongoingGw ? `Live gameweek ${gwProgressRound}% complete` : `No live gameweek`;
  return (
    <div className={`flex flex-col gap-2 rounded-md p-3 bg-surface-container w-full ${loading ? "animate-pulse-sm" : ""}`}>
      <div className={"flex flex-row justify-between text-sm font-semibold"}>
        <span className={"self-start"}>Leaderboard {totalProgressRound}% complete</span>
        <div className={"hidden sm:block text-on-surface-variant"}>{rightMessage}</div>
        <div className={"sm:hidden text-on-surface-variant"}>
          GW {pastOrOngoingGw}/{totalGw}
        </div>
      </div>
      <div>
        <div className={"h-2 bg-outline-variant rounded-full overflow-hidden"}>
          <div className={"h-full bg-primary rounded-full"} style={{ width: `${totalProgressRound}%` }} />
        </div>
      </div>
    </div>
  );
};
