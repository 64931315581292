import React, { useEffect, useState } from "react";
import { scarcities_objects_not_all } from "../util/scarcities";
import { leagues_no_div_objects, leagues_no_div_objects_not_all, leagues_with_divisions_objects_not_all } from "../util/leagues";
import { ReactTooltip } from "../util/tooltip.js";
import { PlayerStatusSelector, playing_status_objects } from "../util/playingStatus";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "./../../react-search.css";
import { Button } from "../util/button";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import Locker from "../../img/brand-close-locker-nude.svg";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { customOptionRender } from "../util/customSelectSearchRender";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { getPositionsBasedOnSport } from "../util/positions";
import BoxWithToggle from "../util/boxWithToggle";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import WatchlistMultiplePicker from "../watchlists/watchlistMultiplePicker";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import { ReactComponent as GridIcon } from "../../img/icons-grid.svg";
import { ReactComponent as ListSelectedIcon } from "../../img/icons-list.svg";
import FilterAlt from "../../img/filter_alt.svg";
import SportsPickerInter from "../util/sportsPickerInter";
import LineSeparator from "../../img/line-separator.svg";
import ManagerCardsAPILoadMore from "./managerCardsAPILoadMore";
import { cardTeamIds } from "../util/cardTeamIds";
import { seasons_objects_int_with_all } from "../util/seasons";
import { baseball_clubs_objects } from "../util/baseballClubs";
import { basketball_clubs_objects } from "../util/basketballClubs";
import { useDebounce } from "react-use";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { endOnboarding, getFirstEnabledOnboardingForPage, nextOnboarding, Onboarding } from "../onboarding/onboardingContext";
import {
  allStarDefaultCompetition,
  EligibilitySorareCompetitionsFilter,
  initialEligibilityCompetitionValue,
} from "../competitions/eligibilityCompetitionsPicker";

const limitAPI = 30;

const tag_filters = [
  { name: "Not for sale", value: "NOT_SALE" },
  { name: "Fixed price", value: "FIXED" },
  { name: "Dynamic", value: "DYN" },
];

function ManagerCardsWrapper(props) {
  const [nextGw, setNextGw] = useState(false);
  const [gwIds, setGwIds] = useState([]);
  const [gwValue, setGwValue] = useState(0);
  const [u23Only, setU23Only] = useState(false);
  const [scarcityValue, setScarcityValue] = useState([]);
  const [leagueValue, setLeagueValue] = useState([]);
  const [eligibilityCompetitions, setEligibilityCompetitions] = useState(initialEligibilityCompetitionValue);
  const [positionValue, setPositionValue] = useState([]);
  const [sortValue, setSortValue] = useState("Newest to oldest");
  const [playingStatusValue, setPlayingStatusValue] = useState(playing_status_objects[0].value);
  const [cards, setCards] = useState({});
  const [cardsRepartition, setCardsRepartition] = useState({});
  const [gwGames, setGwGames] = useState(null);
  const [activeTab, setActiveTab] = useState("all");
  const [sport, setSport] = useState(sorareFootball);
  const [watchlists, setWatchlists] = useState({});
  const [showSortMenu, setShowSortMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [display, setDisplay] = useState("grid");
  const [tagFilter, setTagFilter] = useState([]);
  const [reload, setReload] = useState(false);
  const [onSale, setOnSale] = useState(false);
  const [cardTeamId, setCardTeamId] = useState("");
  const [season, setSeason] = useState(0);
  const [smartSearchValue, setSmartSearchValue] = useState("");
  const [debounceSearchQuery, setDebounceSearchQuery] = useState("");
  const [onboarding, setOnboarding] = useState(getFirstEnabledOnboardingForPage("gallery"));

  useDebounce(
    () => {
      if (smartSearchValue !== "") {
        setActiveTab("all");
        setDebounceSearchQuery(smartSearchValue);
      } else if (smartSearchValue === "" && debounceSearchQuery !== "") {
        const resetCards = {};
        setCards(resetCards);
        fetchCardsRepartition({ q: " " });
        fetchManagerCards({ q: " ", newCards: resetCards });
        setDebounceSearchQuery("");
      }
    },
    250,
    [smartSearchValue],
  );

  useEffect(() => {
    if (debounceSearchQuery !== "" && debounceSearchQuery.length > 2) {
      const resetCards = {};
      setCards(resetCards);
      fetchCardsRepartition({ q: debounceSearchQuery });
      fetchManagerCards({ q: debounceSearchQuery, newCards: resetCards });
    }
  }, [debounceSearchQuery]);

  useEffect(() => {
    getNextGameweeks();
    fetchCardsRepartition({});
    fetchManagerCards({});
    ReactTooltip.rebuild();
  }, [props.manager]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [display]);

  const changePlayingStatus = async (playingStatusValue) => {
    await setPlayingStatusValue(playingStatusValue);
  };

  const changeScarcity = async (scarcityValue) => {
    await setScarcityValue(scarcityValue);
  };

  const getNextGameweeks = async () => {
    props
      .fetch("/apiv2/gw/currentAndNext")
      .then((response) => response.json())
      .then(async (res) => {
        let gwIds = [];
        let gwValue = res[1].GwNumber;
        res.map((gw, index) => {
          if (t1OrAbove(props.user.tier) || index === 0) {
            gwIds.push({ value: gw.GwNumber, name: gw.DisplayName });
          } else {
            gwIds.push({
              value: gw.GwNumber,
              name: gw.DisplayName,
              disabled: true,
              photo: Locker,
            });
          }
          return null;
        });
        setGwIds([{ value: 0, name: "No gameweek" }].concat(gwIds));
        setGwValue(0);
        await fetchGameweekGames(gwValue);
      })
      .catch(errorCatcher());
  };

  const changeGw = async (gwValue) => {
    await setGwValue(gwValue);
    fetchGameweekGames(gwValue);
  };

  const onFilter = () => {
    const resetCards = {};
    setCards(resetCards);
    fetchCardsRepartition({});
    fetchManagerCards({ newCards: resetCards });
  };

  const onChangeActiveTab = (tab) => {
    setActiveTab(tab);
    fetchManagerCards({ s: sport, a: tab });
  };

  const fetchGameweekGames = async (gw) => {
    props
      .fetch("/apiv2/games/teamMap/" + gw)
      .then((response) => response.json())
      .then((res) => {
        setGwGames(res);
      })
      .catch(errorCatcher());
  };

  const changeSport = (sport) => {
    let aTab;
    setSport(sport);
    let sort = sortValue;
    if (sport === sorareBaseball) {
      aTab = "SP";
    } else if (sport === sorareFootball) {
      aTab = "Goalkeeper";
    } else if (sport === sorareBasketball) {
      aTab = "G";
    } else {
      aTab = "all";
      setSortValue("Newest to oldest");
      sort = "Newest to oldest";
    }
    setActiveTab(aTab);
    setScarcityValue([]);
    setLeagueValue([]);
    setEligibilityCompetitions(initialEligibilityCompetitionValue);
    setPlayingStatusValue("all");
    setU23Only(false);
    setPositionValue([]);
    setGwValue(0);
    setCardsRepartition({});
    setWatchlists({});
    fetchCardsRepartition({
      s: sport,
      sc: [],
      le: {},
      ll: [],
      p: "all",
      u23: false,
      pos: [],
      gw: 0,
      w: {},
    });
    fetchManagerCards({
      s: sport,
      a: aTab,
      sc: [],
      le: {},
      ll: [],
      p: "all",
      u23: false,
      pos: [],
      gw: 0,
      sort: sort,
      w: {},
    });
  };

  const fetchManagerCards = ({ s, a, sc, le, ll, p, u23, pos, gw, sort, newCards, w, q }) => {
    let gwNumber = 0;
    if (a === undefined) a = activeTab;
    if (sort === undefined) sort = sortValue;
    if (gw === undefined) gw = gwValue;
    if (gw > 0) gwNumber = gw;
    if (sc === undefined) sc = scarcityValue;
    if (le === undefined) le = eligibilityCompetitions;
    if (ll == undefined) ll = leagueValue;
    if (p === undefined) p = playingStatusValue;
    if (u23 === undefined) u23 = u23Only;
    if (pos === undefined) pos = positionValue;
    if (s === undefined) s = sport;
    if (w === undefined) w = watchlists;
    let order = sort;
    if (order === "Newest to oldest") {
      order = "creationDateD";
    } else if (order === "Oldest to newest") {
      order = "creationDateA";
    } else if (order === "L5 - best to worst") {
      order = "SO5Avg5";
    } else if (order === "L15 - best to worst") {
      order = "SO5Avg20";
    } else if (order === "Floor - low to high") {
      order = "floorA";
    } else if (order === "Floor - high to low") {
      order = "floorD";
    } else if (order === "Latest val. - low to high") {
      order = "latA";
    } else if (order === "Latest val. - high to low") {
      order = "latD";
    }
    if (s === undefined) s = sport;
    if (newCards === undefined) newCards = cards;
    const cs = newCards[`${s}-${a}`] || {
      cards: [],
      offset: 0,
      hasNext: false,
    };
    setLoading(true);
    props
      .fetch("/apiv2/manager/filterCards", {
        method: "POST",
        headers: withJSONPagination(cs.offset, limitAPI),
        body: JSON.stringify({
          gwNumber: gwNumber,
          u23: u23,
          username: props.manager.Slug,
          scarcity: sc,
          leagues: ll,
          competitionsEligible: Object.keys(le).filter((k) => k !== allStarDefaultCompetition.id),
          order: order,
          playing_status: p,
          sport: s,
          position_group: a,
          positions: pos,
          watchlists: Object.keys(w),
          tag_filter: tagFilter,
          on_sale: onSale,
          card_team_id: cardTeamId,
          season: parseInt(season),
          search_query: q || debounceSearchQuery,
        }),
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        setCards({
          ...newCards,
          [`${s}-${a}`]: {
            cards: [...cs.cards, ...res],
            offset: cs.offset + limitAPI,
            hasNext: hasNext,
          },
        });
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher())
      .finally(() => setLoading(false));
  };

  const fetchCardsRepartition = ({ s, sc, le, ll, p, u23, pos, gw, w, q }) => {
    let gwNumber = 0;
    if (gw === undefined) gw = gwValue;
    if (gw > 0) gwNumber = gw;
    if (sc === undefined) sc = scarcityValue;
    if (le === undefined) le = eligibilityCompetitions;
    if (ll === undefined) ll = leagueValue;
    if (p === undefined) p = playingStatusValue;
    if (u23 === undefined) u23 = u23Only;
    if (pos === undefined) pos = positionValue;
    if (s === undefined) s = sport;
    if (w === undefined) w = watchlists;
    props
      .fetch("/apiv2/manager/cardsRepartition", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gwNumber: gwNumber,
          u23: u23,
          username: props.manager.Slug,
          scarcity: sc,
          leagues: ll,
          competitionsEligible: Object.keys(le).filter((k) => k !== allStarDefaultCompetition.id),
          playing_status: p,
          sport: s,
          positions: pos,
          watchlists: Object.keys(w),
          tag_filter: tagFilter,
          on_sale: onSale,
          card_team_id: cardTeamId,
          season: parseInt(season),
          search_query: q || debounceSearchQuery,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setCardsRepartition(res);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher(() => {}));
  };
  const onClickOnSortItem = (key) => {
    setShowSortMenu(false);
    setSortValue(key);
    const resetCards = {};
    setCards(resetCards);
    fetchManagerCards({ sort: key, newCards: resetCards });
  };

  const onSort = (key) => {
    setSortValue(key);
    const resetCards = {};
    setCards(resetCards);
    fetchManagerCards({ sort: key, newCards: resetCards });
  };

  const changeDisplay = (key) => {
    setDisplay(key);
    if (reload) {
      const resetCards = {};
      setCards(resetCards);
      fetchManagerCards({ newCards: resetCards });
      setReload(false);
    }
  };

  const activeClass = "text-brand text-sm rounded-md md:px-3 md:py-2 py-1 px-2 font-semibold";
  const inactiveClass =
    "text-[#666666] cursor-pointer text-sm focus:outline-none md:px-3 md:py-2 py-1 px-2 rounded-md hover:text-brand font-semibold";
  const sortOptions = [
    { name: "L5 - best to worst", value: "L5 - best to worst" },
    { name: "L15 - best to worst", value: "L15 - best to worst" },
    { name: "Newest to oldest", value: "Newest to oldest" },
    { name: "Oldest to newest", value: "Oldest to newest" },
    { name: "Floor - low to high", value: "Floor - low to high" },
    { name: "Floor - high to low", value: "Floor - high to low" },
    { name: "Latest val. - low to high", value: "Latest val. - low to high" },
    { name: "Latest val. - high to low", value: "Latest val. - high to low" },
  ];

  const orderMenuItems = (selectedOrder, onClick) => {
    return sortOptions.map((obj) => {
      const label = obj.name;
      const key = obj.value;
      return {
        key: key,
        label: label,
        className: "w-full sm:w-72 cursor-pointer " + (selectedOrder === key ? "font-semibold" : ""),
        onClick: () => onClick && onClick(key, label),
      };
    });
  };

  const isMe = props.manager.Slug === props.user.sorareSlug;

  return (
    cards !== null && (
      <div>
        <Onboarding
          page={"gallery"}
          index={onboarding.index}
          name={onboarding.name}
          steps={onboarding.steps}
          onNext={nextOnboarding}
          onEnd={endOnboarding}
          onSkip={endOnboarding}
        />
        <div className={"space-y-4 mb-4 md:mt-8 mt-4"}>
          <div className={"flex flex-row justify-between"}>
            <div
              onClick={() => setShowFilters(!showFilters)}
              className={
                "flex flex-row font-semibold text-sm space-x-2 py-2 px-4 rounded-lg cursor-pointer hover:bg-brand hover:text-white bg-white"
              }
            >
              <img src={FilterAlt} />
              <p className={"self-center"}>Filters</p>
            </div>
            <div className="flex flex-row gap-2 justify-end">
              <div className={"relative flex items-stretch flex-grow w-80"}>
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FormSearch className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  onChange={(event) => setSmartSearchValue(event.target.value)}
                  onFocus={(e) => {
                    e.stopPropagation();
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                  }}
                  value={smartSearchValue}
                  className={"smart-search-gallery rounded-lg block w-full pl-10 text-sm border-0 focus:ring-0"}
                  placeholder="Smart search the gallery (Euro 2024...)"
                />
                {smartSearchValue && (
                  <div
                    className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                    onClick={() => {
                      setSmartSearchValue("");
                      setDebounceSearchQuery("");
                      const resetCards = {};
                      setCards(resetCards);
                      fetchCardsRepartition({ q: " " });
                      fetchManagerCards({ q: " ", newCards: resetCards });
                    }}
                  >
                    <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
                  </div>
                )}
              </div>
              <button
                type="button"
                onClick={() => changeDisplay("grid")}
                className={
                  "inline-flex items-center p-3 border-0 border-transparent text-med font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-none bg-white " +
                  (display === "grid" ? "fill-brand cursor-default" : "fill-textGrey4 cursor-pointer hover:fill-brand")
                }
              >
                <GridIcon />
              </button>
              <button
                type="button"
                onClick={() => changeDisplay("list")}
                className={
                  "inline-flex items-center p-3 border-0 border-transparent text-med font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-none bg-white " +
                  (display === "list" ? "fill-brand cursor-default" : "fill-textGrey4 cursor-pointer hover:fill-brand")
                }
              >
                <ListSelectedIcon />
              </button>
            </div>
          </div>
          {showFilters && (
            <div className={"bg-white rounded-lg p-4 flex flex-col space-y-4"}>
              <div className={"flex flex-row justify-between space-x-2"}>
                {sport === sorareFootball && (
                  <div
                    className={"md:flex md:flex-row xl:self-center grid grid-cols-2 md:justify-start xl:justify-start lg:space-x-2 gap-2"}
                  >
                    <div className={"flex flex-row gap-x-1 px-2 border rounded-lg border-[#E6E6E6] whitespace-nowrap"}>
                      <button
                        className={activeTab === "Goalkeeper" ? activeClass : inactiveClass}
                        onClick={() => onChangeActiveTab("Goalkeeper")}
                      >
                        {`Goalkeepers ${cardsRepartition["Goalkeeper"] !== undefined ? `(${cardsRepartition["Goalkeeper"]})` : ""}`}
                      </button>
                      <img src={LineSeparator} />
                      <button
                        className={activeTab === "Defender" ? activeClass : inactiveClass}
                        onClick={() => onChangeActiveTab("Defender")}
                      >
                        {`Defenders ${cardsRepartition["Defender"] !== undefined ? `(${cardsRepartition["Defender"]})` : ""}`}
                      </button>
                      <img src={LineSeparator} />
                      <button
                        className={activeTab === "Midfielder" ? activeClass : inactiveClass}
                        onClick={() => onChangeActiveTab("Midfielder")}
                      >
                        {`Midfielders ${cardsRepartition["Midfielder"] !== undefined ? `(${cardsRepartition["Midfielder"]})` : ""}`}
                      </button>
                      <img src={LineSeparator} />
                      <button
                        className={activeTab === "Forward" ? activeClass : inactiveClass}
                        onClick={() => onChangeActiveTab("Forward")}
                      >
                        {`Forwards ${cardsRepartition["Forward"] !== undefined ? `(${cardsRepartition["Forward"]})` : ""}`}
                      </button>
                      <img src={LineSeparator} />
                      <button className={activeTab === "all" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("all")}>
                        {`All ${cardsRepartition["All"] !== undefined ? `(${cardsRepartition["All"]})` : ""}`}
                      </button>
                    </div>

                    {activeTab === "all" && (
                      <div className={"xl:w-36 2xl:w-48 self-center"}>
                        <SelectSearch
                          multiple={true}
                          options={getPositionsBasedOnSport(sport)}
                          value={positionValue}
                          placeholder={"Filter by positions"}
                          printOptions={"on-focus"}
                          filterOptions={fuzzySearch}
                          closeOnSelect={false}
                          onChange={(v) => setPositionValue(v)}
                        />
                      </div>
                    )}
                  </div>
                )}
                {sport === sorareBaseball && (
                  <div
                    className={"md:flex md:flex-row xl:self-center grid grid-cols-2 md:justify-start xl:justify-start lg:space-x-2 gap-2"}
                  >
                    <button className={activeTab === "SP" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("SP")}>
                      {`SP ${cardsRepartition["SP"] !== undefined ? `(${cardsRepartition["SP"]})` : ""}`}
                    </button>
                    <button className={activeTab === "RP" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("RP")}>
                      {`RP ${cardsRepartition["RP"] !== undefined ? `(${cardsRepartition["RP"]})` : ""}`}
                    </button>
                    <button className={activeTab === "OF" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("OF")}>
                      {`Outfielders ${cardsRepartition["OF"] !== undefined ? `(${cardsRepartition["OF"]})` : ""}`}
                    </button>
                    <button className={activeTab === "CI" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("CI")}>
                      {`Corner Infielders ${cardsRepartition["CI"] !== undefined ? `(${cardsRepartition["CI"]})` : ""}`}
                    </button>
                    <button className={activeTab === "MI" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("MI")}>
                      {`Middle Infielders ${cardsRepartition["MI"] !== undefined ? `(${cardsRepartition["MI"]})` : ""}`}
                    </button>
                    <button className={activeTab === "all" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("all")}>
                      {`All ${cardsRepartition["All"] !== undefined ? `(${cardsRepartition["All"]})` : ""}`}
                    </button>
                    {activeTab === "all" && (
                      <div className={"xl:w-36 2xl:w-48 self-center"}>
                        <SelectSearch
                          multiple={true}
                          options={getPositionsBasedOnSport(sport)}
                          value={positionValue}
                          placeholder={"Filter by positions"}
                          printOptions={"on-focus"}
                          filterOptions={fuzzySearch}
                          closeOnSelect={false}
                          onChange={(v) => setPositionValue(v)}
                        />
                      </div>
                    )}
                  </div>
                )}
                {sport === sorareBasketball && (
                  <div
                    className={"md:flex md:flex-row xl:self-center grid grid-cols-2 md:justify-start xl:justify-start lg:space-x-2 gap-2"}
                  >
                    <button className={activeTab === "G" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("G")}>
                      {`Guards ${cardsRepartition["G"] !== undefined ? `(${cardsRepartition["G"]})` : ""}`}
                    </button>
                    <button className={activeTab === "F" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("F")}>
                      {`Forwards ${cardsRepartition["F"] !== undefined ? `(${cardsRepartition["F"]})` : ""}`}
                    </button>
                    <button className={activeTab === "C" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("C")}>
                      {`Centers ${cardsRepartition["C"] !== undefined ? `(${cardsRepartition["C"]})` : ""}`}
                    </button>
                    <button className={activeTab === "all" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("all")}>
                      {`All ${cardsRepartition["All"] !== undefined ? `(${cardsRepartition["All"]})` : ""}`}
                    </button>
                    {activeTab === "all" && (
                      <div className={"xl:w-36 2xl:w-48 self-center"}>
                        <SelectSearch
                          multiple={true}
                          options={getPositionsBasedOnSport(sport)}
                          value={positionValue}
                          placeholder={"Filter by positions"}
                          printOptions={"on-focus"}
                          filterOptions={fuzzySearch}
                          closeOnSelect={false}
                          onChange={(v) => setPositionValue(v)}
                        />
                      </div>
                    )}
                  </div>
                )}
                {sport === "all" && (
                  <div
                    className={"md:flex md:flex-row xl:self-center grid grid-cols-2 md:justify-start xl:justify-start lg:space-x-2 gap-2"}
                  >
                    <button className={activeTab === "all" ? activeClass : inactiveClass} onClick={() => onChangeActiveTab("all")}>
                      {`All ${cardsRepartition["All"] !== undefined ? `(${cardsRepartition["All"]})` : ""}`}
                    </button>
                    <button
                      className={activeTab === sorareFootball ? activeClass : inactiveClass}
                      onClick={() => onChangeActiveTab(sorareFootball)}
                    >
                      {`Football ${cardsRepartition[sorareFootball] !== undefined ? `(${cardsRepartition[sorareFootball]})` : ""}`}
                    </button>
                    <button
                      className={activeTab === sorareBasketball ? activeClass : inactiveClass}
                      onClick={() => onChangeActiveTab(sorareBasketball)}
                    >
                      {`Basketball ${cardsRepartition[sorareBasketball] !== undefined ? `(${cardsRepartition[sorareBasketball]})` : ""}`}
                    </button>
                    <button
                      className={activeTab === sorareBaseball ? activeClass : inactiveClass}
                      onClick={() => onChangeActiveTab(sorareBaseball)}
                    >
                      {`Baseball ${cardsRepartition[sorareBaseball] !== undefined ? `(${cardsRepartition[sorareBaseball]})` : ""}`}
                    </button>
                  </div>
                )}

                <div>
                  <SportsPickerInter sport={sport} showAll={true} change={(s) => changeSport(s)} />
                </div>
              </div>
              <div className={"flex flex-row justify-between"}>
                <div className={"flex flex-row space-x-2"}>
                  {sport === sorareFootball && (
                    <>
                      <div className={"h-9"}>
                        <EligibilitySorareCompetitionsFilter
                          width={"w-full"}
                          minWidth={"min-w-[6rem]"}
                          maxWidth={"max-w-[15rem]"}
                          widthPanel={"w-80"}
                          displayOptions={{ shadow: "shadow" }}
                          selected={eligibilityCompetitions}
                          onSelect={(v) => {
                            let newLeagues = {};
                            if (v === allStarDefaultCompetition.id) {
                              newLeagues = initialEligibilityCompetitionValue;
                            } else {
                              newLeagues = { ...eligibilityCompetitions, [v]: true };
                              delete newLeagues[allStarDefaultCompetition.id]; // makes no sense to have the 'all' selected while others specific ones are selected
                            }
                            setEligibilityCompetitions(newLeagues);
                          }}
                          onUnselect={(v) => {
                            const newLeagues = { ...eligibilityCompetitions };
                            delete newLeagues[v];
                            if (Object.keys(newLeagues).length === 0) {
                              newLeagues[allStarDefaultCompetition.id] = true;
                            }
                            setEligibilityCompetitions(newLeagues);
                          }}
                        />
                      </div>
                      <SelectSearch
                        multiple={true}
                        options={leagues_no_div_objects_not_all}
                        value={leagueValue}
                        placeholder={"Filter by leagues"}
                        printOptions={"on-focus"}
                        filterOptions={fuzzySearch}
                        closeOnSelect={false}
                        onChange={(v) => setLeagueValue(v)}
                      />
                    </>
                  )}
                  <div className={"xl:w-40 2xl:w-48"}>
                    <SelectSearch
                      multiple={true}
                      options={[
                        {
                          value: "COMMON",
                          name: "Common",
                        },
                      ]
                        .concat(scarcities_objects_not_all)
                        .concat([
                          {
                            value: "CUSTOM SERIES",
                            name: "Custom Series",
                          },
                        ])}
                      value={scarcityValue}
                      placeholder={"Filter by scarcity"}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      closeOnSelect={false}
                      onChange={(v) => changeScarcity(v)}
                    />
                  </div>
                  <div className={"xl:w-40 2xl:w-48"}>
                    <SelectSearch
                      options={seasons_objects_int_with_all}
                      value={season}
                      placeholder={"Filter by season"}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      closeOnSelect={true}
                      search={true}
                      onChange={(v) => setSeason(v)}
                    />
                  </div>
                  <SelectSearch
                    options={
                      sport === "all" || sport === sorareFootball
                        ? cardTeamIds
                        : sport === sorareBasketball
                        ? basketball_clubs_objects
                        : baseball_clubs_objects
                    }
                    value={cardTeamId}
                    placeholder={"Filter by team jersey"}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    closeOnSelect={true}
                    search={true}
                    onChange={(v) => setCardTeamId(v)}
                  />
                </div>
                <div className={"flex flex-row justify-end space-x-2 self-center"}>
                  <div className={"xl:w-40 2xl:w-48"}>
                    <PlayerStatusSelector
                      playingStatus={playingStatusValue}
                      onChange={async (v) => changePlayingStatus(v)}
                      tier={props.user.tier}
                      sport={sport}
                      placeholder={"Playing status"}
                    />
                  </div>
                  <div className={"rounded-lg bg-white"}>
                    <BoxWithToggle
                      label={"U23 eligible"}
                      rounded={"rounded-lg"}
                      color={"text-black"}
                      border={"border border-[#E6E6E6] rounded-lg"}
                      padding={"py-1.5 px-4"}
                      isActive={u23Only}
                      setActive={() => setU23Only(!u23Only)}
                    />
                  </div>
                </div>
              </div>
              <div className={"flex flex-row justify-between"}>
                <div className={"flex flex-row space-x-2"}>
                  {sport === sorareFootball && (
                    <div className={""}>
                      <SelectSearch
                        options={gwIds}
                        value={gwValue}
                        onChange={async (v) => {
                          await changeGw(v);
                        }}
                        printOptions={"on-focus"}
                        filterOptions={fuzzySearch}
                        placeholder="Filter by gameweek"
                        onFocus={() => {
                          ReactTooltip.rebuild();
                        }}
                        renderOption={customOptionRender}
                      />
                    </div>
                  )}
                  <div>
                    <WatchlistMultiplePicker
                      types={["player"]}
                      options={{
                        pinListFirst: true,
                        defaultListFirst: true,
                        followedListFirst: true,
                        ownedListFirst: true,
                      }}
                      size={"xs"}
                      placeholder={"Filter by watchlist(s)"}
                      sports={sport === "all" ? [sorareFootball, sorareBasketball, sorareBaseball, "multi"] : [sport, "multi"]}
                      selected={watchlists}
                      onSelect={(w) => {
                        let newWatchlists = { ...watchlists };
                        if (watchlists[w.id]) {
                          delete newWatchlists[w.id];
                        } else {
                          newWatchlists[w.id] = w;
                        }
                        setWatchlists(newWatchlists);
                      }}
                    />
                  </div>

                  <div>
                    <SelectSearch
                      options={tag_filters}
                      value={tagFilter}
                      placeholder={"Filter by market status"}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      closeOnSelect={false}
                      multiple={true}
                      onChange={(v) => setTagFilter(v)}
                    />
                  </div>

                  <div className={"self-center bg-white"}>
                    <BoxWithToggle
                      label={"On sale on Sorare"}
                      rounded={"rounded-lg"}
                      color={"text-black"}
                      border={"border border-[#E6E6E6] rounded-lg"}
                      padding={"py-1.5 px-4"}
                      isActive={onSale}
                      setActive={() => setOnSale(!onSale)}
                    />
                  </div>
                </div>
                <div className={"flex flew-row space-x-4 md:justify-center"}>
                  <div className={"text-center self-center xl:justify-start"}>
                    <Button label={"Apply"} onClick={onFilter} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={"relative mb-2"}>
          <div className={" text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
            Sort by: {sortOptions.find((obj) => obj.value === sortValue).name}
            <span className={"ml-2 my-auto"}>
              <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
            </span>
          </div>
          <StandaloneMenu
            className={"absolute top-8 left-0 overflow-y-auto max-h-60 pt-2"}
            show={showSortMenu}
            onClickOutside={() => setShowSortMenu(false)}
            items={orderMenuItems(sortValue, onClickOnSortItem)}
          />
        </div>
        {isFree(props.user.tier) && sport !== sorareBaseball && sport !== sorareBasketball && (
          <div className="my-8">
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-gallery-players bg-cover h-upgrade-banner-s w-full"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock players status and future gameweeks filter"}
            />
          </div>
        )}
        <div>
          <ManagerCardsAPILoadMore
            loading={loading}
            players={cards[`${sport}-${activeTab}`]?.cards}
            hasNext={cards[`${sport}-${activeTab}`]?.hasNext}
            onNext={() => fetchManagerCards({ s: sport, a: activeTab })}
            gameStat={false}
            isMe={isMe}
            position={activeTab}
            gwGames={gwGames}
            display={display}
            sport={sport}
            sort={sortValue}
            setSort={onSort}
            setReload={setReload}
            refresh={onFilter}
            gwNumber={gwValue}
          />
        </div>
        <ReactTooltip />
      </div>
    )
  );
}

export default withUser(ManagerCardsWrapper);
