import React, { useEffect, useRef, useState } from "react";
import SDLogo from "../../img/sd logo field.svg";
import PlayerScoreModal from "../players/playerScoreModal";
import LiveCircle from "../util/liveCircle";
import { useInterval } from "../util/useInterval";
import { withUser } from "../../userContext";
import format from "date-fns/format";
import LineInLineup from "./lineInLineup";
import FieldAvatar, { NotPlayingFieldAvatar } from "./fieldAvatar";
import SDLoading from "../util/SDLoading";
import UnknownClub from "../../img/unknown_club.png";
import GameEvents from "./gameEvents";
import GameTeamStats from "./gameTeamStats";
import GameMyPlayers from "./gameMyPlayers";
import GameBench from "./gameBench";
import { errorCatcher } from "../util/errors";
import { sorareFootball } from "../util/sports";
import { ReactTooltip } from "../util/tooltip.js";
import { ReactComponent as BenchIcon } from "../../img/benchNotCameIn-outline.svg";
import { ReactComponent as OutIcon } from "../../img/notInSquad-outline.svg";
import { ReactComponent as PlusIcon } from "../../img/icons-plus-no-color.svg";
import { ReactComponent as MinusIcon } from "../../img/icons-minus-no-color.svg";
import PlayerAvatar from "../util/playerAvatar";
import { CommunityPredictionIndicator, getLineupProjectionBgColor, ProjectedLineupStats } from "../util/communityPrediction";
import { groupBy } from "lodash-es";
import PlayerAvailability from "../players/playerAvailability";
import { Last10LineAlternative } from "../decision/last10line";
import ToggleWithLabel from "../util/toggleWithLabel";
import { detailedPositionFullName } from "../util/detailedFootballPositions";
import GameCommentary from "./gameCommentary";
import GameLivePageHeader from "./gameLivePageHeader";
import { ProjectionPointsIndicatorForGame } from "../probableStarters/gamesList";
import { toFixedIfNecessary } from "../util/formatNumber";
import { StoreButtons } from "../util/stores";

function FormationEllipse(props) {
  return (
    <div className={"bg-textGrey3 text-white px-2 rounded-xl py-0.5 self-center"}>
      <p className={"font-semibold text-sm"}>{props.formation}</p>
    </div>
  );
}

function GameLivePage(props) {
  const [gameDetails, setGameDetails] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayStats, setOverlayStats] = useState({});
  const [overlayLineups, setOverlayLineups] = useState({});
  const [commentary, setCommentary] = useState([]);

  const [teamStarters, setTeamStarters] = useState({ home: [], away: [] });

  const [projectionStats, setProjectionStats] = useState({});

  useEffect(() => {
    getGameDetails();
    getProbableStarters();
    getProjectionStats();
  }, [props.user.sorareSlug]);

  const divRef = useRef(null);
  const theRef = useRef(null);

  useEffect(() => {
    if (theRef.current !== null && divRef.current !== null) {
      const firstDivHeight = theRef.current.getBoundingClientRect().height;
      let height = firstDivHeight - 70;
      if (firstDivHeight < 630) {
        height = 630;
      }
      divRef.current.style.height = `${height}px`;
    }
  }, [gameDetails]);

  useInterval(() => getGameDetails(), 60000);

  const getGameDetails = () => {
    if (props.user.sorareSlug && props.user.sorareSlug !== "") {
      props
        .fetch("/apiv2/games/fullGame", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gameId: props.match.params.gameId,
            username: props.user.sorareSlug,
            commentary: true,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          // away: reverse the lines because away is on right side
          if (res.away.positions.lineup !== null) res.away.positions.lineup = res.away.positions.lineup.reverse();
          // home: reverse positions inside lines because home is on left side
          if (res.home.positions.lineup !== null) {
            res.home.positions.lineup.forEach((line, i) => {
              res.home.positions.lineup[i] = line.reverse();
            });
          }
          setCommentary(res.commentary);
          setGameDetails(res);
          document.title =
            res.home.team.ClubName + " " + res.game.game.HomeGoals + " - " + res.game.game.AwayGoals + " " + res.away.team.ClubName;
        })
        .catch(errorCatcher());
    }
  };

  const getProbableStarters = () => {
    if (props.user.sorareSlug && props.user.sorareSlug !== "" && props.match.params.gameId !== "") {
      const params = new URLSearchParams();
      params.append("gameId", props.match.params.gameId);
      props
        .fetch("/projections-api/SO5/probableGameStarters?" + params.toString())
        .then((response) => response.json())
        .then((res) => {
          setTeamStarters({ home: res.homeStarters, away: res.awayStarters });
        })
        .catch(errorCatcher(() => {}));
    }
  };

  const getProjectionStats = (playerId, gameId, status) => {
    if (props.user.sorareSlug && props.user.sorareSlug !== "" && props.match.params.gameId !== "") {
      const params = new URLSearchParams();
      params.append("gameId", props.match.params.gameId);
      props
        .fetch("/projections-api/SO5/probableGameStartersStats?" + params.toString())
        .then((response) => response.json())
        .then((res) => {
          setProjectionStats(res);
        })
        .catch(errorCatcher(() => {}));
    }
    const newHomeStarters = teamStarters.home.map((player) => {
      if (player.player.PlayerId === playerId) {
        player.probable.status = status;
      }
      return player;
    });
    const newAwayStarters = teamStarters.away.map((player) => {
      if (player.player.PlayerId === playerId) {
        player.probable.status = status;
      }
      return player;
    });
    setTeamStarters({
      home: newHomeStarters,
      away: newAwayStarters,
    });
  };

  const homeLineup = gameDetails?.home?.positions?.lineup;
  const awayLineup = gameDetails?.away?.positions?.lineup;
  const homeSubs = gameDetails?.home?.positions?.substitutes;
  const awaySubs = gameDetails?.away?.positions?.substitutes;
  const game = gameDetails?.game?.game;
  const gameStatus = game?.Status;

  let fieldRound = true;
  if (
    gameDetails?.home?.positions.substitutes !== null ||
    gameDetails?.away?.positions.substitutes !== null ||
    gameStatus === "scheduled"
  ) {
    fieldRound = false;
  }

  let gameTime = new Date(game?.GameTime);
  let hasLineups = game?.HasLineups;
  let homeTeam = gameDetails?.home?.team;
  let awayTeam = gameDetails?.away?.team;
  let awayLength = 0;
  let homeLength = 0;
  if (homeLineup !== undefined && homeLineup !== null) {
    homeLength = homeLineup.length;
  }
  if (awayLineup !== undefined && awayLineup !== null) {
    awayLength = awayLineup.length;
  }
  let homeSpacing = "justify-around";
  let awaySpacing = "justify-around";
  if (homeLength >= 7) {
    homeSpacing = "space-x-4 4xl:justify-around";
  }
  if (awayLength >= 7) {
    awaySpacing = "space-x-4 4xl:justify-around";
  }

  let fieldHeight = "h-80 xl:h-75 2.5xl:h-70";
  const isGameScheduled = gameStatus === "scheduled" && !hasLineups;
  if (game !== undefined) {
    return (
      <div className={"flex flex-col gap-4 py-4 "}>
        <ReactTooltip />
        <div className={"w-full xl:w-11/12 2.5xl:w-10/12 mx-auto"}>
          <div className={"w-full lg:hidden px-4"}>
            <div className={"bg-brand-black flex flex-row rounded-t-xl px-8 h-16"}>
              <div className={"self-center w-5/12 flex flex-row gap-3 justify-start"}>
                <div className={"w-6 self-center"}>
                  <img className={"object-contain self-center"} src={homeTeam?.PictureUrl !== "" ? homeTeam?.PictureUrl : UnknownClub} />
                </div>
                <p className={"text-white text-md font-semibold hover:font-bold self-center"}>
                  <a href={"/team/" + homeTeam.TeamId}>{homeTeam?.ClubName}</a>
                </p>
              </div>
              <div className={"self-center w-2/12 text-center flex flex-col space-y-2"}>
                {gameStatus === "playing" && (
                  <p className={"text-white font-semibold text-sm text-live-red flex flex-row justify-center self-center space-x-1"}>
                    <span className={"self-center"}>
                      <LiveCircle color={"#FF3232"} />
                    </span>{" "}
                    <span>{game?.TimeMin}&apos;</span>
                  </p>
                )}
                {(gameStatus === "playing" || gameStatus === "played") && (
                  <p className={"text-white self-center"}>
                    <span className={"text-3xl font-bold text-white"}>{game?.HomeGoals}</span>
                    <span className={"self-center text-3xl"}> - </span>
                    <span className={"text-3xl font-bold text-white"}>{game?.AwayGoals}</span>
                  </p>
                )}
                {gameStatus === "scheduled" && (
                  <div className={"space-y-2"}>
                    <p className={"text-white self-center"}>
                      <span className={"text-base font-semibold text-white"}>{format(gameTime, "LLL do yyyy")}</span>
                    </p>
                    <p className={"text-white self-center"}>
                      <span className={"text-4xl font-semibold text-white"}>{format(gameTime, "HH:mm")}</span>
                    </p>
                  </div>
                )}
              </div>
              <div className={"self-center flex flex-row space-x-8 w-5/12 justify-end"}>
                <p className={"self-center text-white text-md hover:font-bold font-semibold"}>
                  <a href={"/team/" + awayTeam.TeamId}>{awayTeam?.ClubName}</a>
                </p>
                <div className={"w-6 self-center"}>
                  <img className={"object-contain self-center"} src={awayTeam?.PictureUrl !== "" ? awayTeam?.PictureUrl : UnknownClub} />
                </div>
              </div>
            </div>
            <div className={"bg-white h-36 flex flex-col gap-3 justify-center items-center bg-game-field-opacity p-2 rounded-b-xl"}>
              <p className={"font-semibold text-center mx-auto text-sm text-white"}>
                The gaming field is not optimized for mobile browsing.
                <br />
                Please use our mobile application, available on Android and iOS
              </p>
              <div className={"flex flex-row gap-2 justify-center items-center mx-4"}>
                <StoreButtons />
              </div>
            </div>
          </div>
          <div className={"w-full hidden lg:block"}>
            <PlayerScoreModal
              open={showOverlay}
              sport={sorareFootball}
              stats={overlayStats}
              lineups={overlayLineups}
              close={() => setShowOverlay(false)}
              game={gameDetails.game}
            />
            <div className={"relative"}>
              <div className={"bg-brand-black flex flex-row rounded-t-xl px-8 h-28"}>
                <div className={"self-center w-5/12 flex flex-row gap-3 justify-start"}>
                  <div className={"w-16 self-center"}>
                    <img
                      className={"w-14 h-14 object-contain self-center"}
                      src={homeTeam?.PictureUrl !== "" ? homeTeam?.PictureUrl : UnknownClub}
                    />
                  </div>
                  <p className={"text-white text-xl font-semibold hover:font-bold self-center"}>
                    <a href={"/team/" + homeTeam.TeamId}>{homeTeam?.ClubName}</a>
                  </p>
                  {game?.FormationHome !== "" && <FormationEllipse formation={game?.FormationHome} />}
                </div>
                <div className={"self-center w-2/12 text-center flex flex-col space-y-2"}>
                  {gameStatus === "playing" && (
                    <p className={"text-white font-semibold text-md text-live-red flex flex-row justify-center self-center space-x-1"}>
                      <span className={"self-center"}>
                        <LiveCircle color={"#FF3232"} />
                      </span>{" "}
                      <span>{game?.TimeMin}&apos;</span>
                    </p>
                  )}
                  {(gameStatus === "playing" || gameStatus === "played") && (
                    <p className={"text-white self-center"}>
                      <span className={"text-5xl font-bold text-white"}>{game?.HomeGoals}</span>
                      <span className={"self-center text-5xl"}> - </span>
                      <span className={"text-5xl font-bold text-white"}>{game?.AwayGoals}</span>
                    </p>
                  )}
                  {gameStatus === "scheduled" && (
                    <div className={"space-y-2"}>
                      <p className={"text-white self-center"}>
                        <span className={"text-base font-semibold text-white"}>{format(gameTime, "LLL do yyyy")}</span>
                      </p>
                      <p className={"text-white self-center"}>
                        <span className={"text-4xl font-semibold text-white"}>{format(gameTime, "HH:mm")}</span>
                      </p>
                    </div>
                  )}
                  {/*<p className={"text-sm font-semibold"}>{gameDetails.stats.game.CompDisplayName}</p>*/}
                </div>
                <div className={"self-center flex flex-row space-x-8 w-5/12 justify-end"}>
                  {game?.FormationAway !== "" && <FormationEllipse formation={game?.FormationAway} />}
                  <p className={"self-center text-white text-xl hover:font-bold font-semibold"}>
                    <a href={"/team/" + awayTeam.TeamId}>{awayTeam?.ClubName}</a>
                  </p>
                  <div className={"w-12 self-center"}>
                    <img className={"object-contain self-center"} src={awayTeam?.PictureUrl !== "" ? awayTeam?.PictureUrl : UnknownClub} />
                  </div>
                </div>
              </div>
              {isGameScheduled && (
                <div className={"absolute bottom-0 flex flex-row justify-center items-center w-full mx-auto -mb-12"}>
                  <div className={"bg-brand-black bg-opacity-30 text-white font-semibold px-3 py-1.5 rounded-md text-sm"}>
                    Lineups projected by the SorareData community
                  </div>
                </div>
              )}
            </div>
            {
              <div className={fieldHeight + " absolute w-full xl:w-11/12 2.5xl:w-10/12 flex flex-row z-10 mx-auto"}>
                <div className={"flex flex-row w-1/2 " + homeSpacing}>
                  {homeLineup !== null &&
                    homeLineup.map((l) => {
                      return (
                        <LineInLineup
                          line={l}
                          hideScores={!hasLineups}
                          linkToPlayerPage={isGameScheduled}
                          showProjectionInfo={isGameScheduled}
                          overlay={
                            !isGameScheduled
                              ? (s) => {
                                  setOverlayStats(s);
                                  setOverlayLineups(s.manager_details);
                                  setShowOverlay(true);
                                }
                              : undefined
                          }
                        />
                      );
                    })}
                </div>
                <div className={"flex flex-row w-1/2 space-x-6 " + awaySpacing}>
                  {awayLineup !== null &&
                    awayLineup.map((l) => {
                      return (
                        <LineInLineup
                          line={l}
                          hideScores={!hasLineups}
                          showProjectionInfo={isGameScheduled}
                          linkToPlayerPage={isGameScheduled}
                          overlay={
                            !isGameScheduled
                              ? (s) => {
                                  setOverlayStats(s);
                                  setOverlayLineups(s.manager_details);
                                  setShowOverlay(true);
                                }
                              : undefined
                          }
                        />
                      );
                    })}
                </div>
              </div>
            }
            <div className={"z-0 " + fieldHeight + " w-full flex flex-row"}>
              <div
                className={
                  fieldRound
                    ? "bg-game-field-borders " + fieldHeight + " w-1/12 rounded-bl-xl"
                    : "bg-game-field-borders " + fieldHeight + " w-1/12"
                }
              />
              <div className={"absolute border-4 border-l-0 border-white/20 z-5 h-1/4 self-center justify-center w-28 rounded-r-lg"} />
              <div className={"absolute border-4 border-l-0 border-white/20 z-5 h-1/2 self-center justify-center w-56 rounded-r-lg"} />
              <div className={"absolute w-full xl:w-11/12 2.5xl:w-10/12"}>
                <div className={"border-r-4 border-white/20 z-5 " + fieldHeight + " self-center justify-center w-1/2 "} />
              </div>
              <div className={"absolute w-full xl:w-11/12 2.5xl:w-10/12 h-1/12 self-center justify-center"}>
                <img className={"self-center justify-center mx-auto h-14 align-middle"} src={SDLogo} />
              </div>
              <div className={"absolute flex left-0 right-0 mx-auto w-2/12 h-2/12 self-center justify-center items-center align-middle"}>
                <div className={"border-4 border-white/20 align-middle mx-auto z-5 h-56 rounded-full self-center justify-center w-56 "} />
              </div>
              <div
                className={
                  "absolute flex w-full xl:w-11/12 2.5xl:w-10/12 mx-auto right-0 left-0 w-28 h-1/4 self-center justify-end items-center align-middle"
                }
              >
                <div className={"border-4 border-r-0 border-white/20 z-5 h-full self-center justify-center w-28 rounded-l-lg "} />
              </div>
              <div
                className={
                  "absolute flex w-full xl:w-11/12 2.5xl:w-10/12 mx-auto right-0 left-0 w-28 h-1/2 self-center justify-end items-center align-middle"
                }
              >
                <div className={"border-4 border-r-0 border-white/20 z-5 h-full self-center justify-center w-56 rounded-l-lg "} />
              </div>

              <div className={"bg-game-field-opacity " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-borders " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-opacity " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-borders " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-opacity " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-borders " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-opacity " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-borders " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-opacity " + fieldHeight + " w-1/12"} />
              <div className={"bg-game-field-borders " + fieldHeight + " w-1/12"} />
              <div
                className={
                  fieldRound
                    ? "bg-game-field-opacity " + fieldHeight + " w-1/12 rounded-br-xl"
                    : "bg-game-field-opacity " + fieldHeight + " w-1/12"
                }
              />
            </div>
            {((homeSubs !== undefined && homeSubs !== null) || (awaySubs !== undefined && awaySubs !== null)) && (
              <div className={"w-full mx-auto flex flex-row h-40 bg-game-field-footer rounded-b-xl"}>
                <div className={"flex flex-row w-1/2 justify-around self-center"}>
                  {homeSubs !== null &&
                    homeSubs.map((l) => {
                      return (
                        <FieldAvatar
                          stats={l}
                          overlay={(s) => {
                            setOverlayStats(s);
                            setOverlayLineups(s.manager_details);
                            setShowOverlay(true);
                          }}
                        />
                      );
                    })}
                </div>
                <div className={"flex flex-row w-1/2 justify-around self-center"}>
                  {awaySubs !== null &&
                    awaySubs.map((l) => {
                      return (
                        <FieldAvatar
                          stats={l}
                          overlay={(s) => {
                            setOverlayStats(s);
                            setOverlayLineups(s.manager_details);
                            setShowOverlay(true);
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            )}
            {isGameScheduled && <BenchAndOutOnScheduledGame game={gameDetails} gameStatus={gameStatus} />}
          </div>
          {(gameDetails.events?.game_id || gameDetails.game_team_stats) && (
            <div className={"flex flex-col lg:flex-row lg:space-x-8 mt-4"}>
              {gameDetails.events && (
                <div className={"w-full px-4 lg:px-0 lg:w-9/12 flex flex-col"}>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div style={{ alignSelf: "start" }} ref={theRef}>
                      <GameEvents events={gameDetails.events} homeTeam={homeTeam} awayTeam={awayTeam} game={gameDetails.game} />
                    </div>
                    {commentary !== null && commentary.length > 0 && (
                      <div>
                        <div className={"space-y-4 h-full"}>
                          <GameLivePageHeader label={"Live action"} />
                          <div
                            ref={divRef}
                            className={"flex flex-col bg-white rounded-lg smooth-scroll h-full overflow-y-auto p-4 space-y-2"}
                          >
                            {commentary.map((comment, index) => {
                              return <GameCommentary comment={comment} />;
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={"mt-8"}>
                    <GameMyPlayers homeTeam={gameDetails.home} awayTeam={gameDetails.away} game={gameDetails.game} />
                  </div>
                  <div className={"hidden lg:w-full lg:flex flex-col gap-4"}>
                    <GameLivePageHeader label={"Projections"} />
                    <GameProjection
                      isGameScheduled={isGameScheduled}
                      game={gameDetails}
                      onVoteChange={getProjectionStats}
                      starters={teamStarters}
                      projectionStats={projectionStats}
                    />
                  </div>
                </div>
              )}
              {gameDetails.game_team_stats && (
                <div className={"w-full px-4 lg:px-0 lg:w-3/12"}>
                  <div className={"flex flex-col space-y-4"}>
                    <GameTeamStats stats={gameDetails.game_team_stats} homeTeam={homeTeam} awayTeam={awayTeam} />
                    <GameBench homeTeam={gameDetails.home} awayTeam={gameDetails.away} game={gameDetails.game} />
                  </div>
                </div>
              )}
              <div className={"w-full flex flex-col gap-4 lg:hidden mt-4"}>
                <GameLivePageHeader label={"Projections"} />
                <GameProjection
                  isGameScheduled={isGameScheduled}
                  game={gameDetails}
                  onVoteChange={getProjectionStats}
                  starters={teamStarters}
                  projectionStats={projectionStats}
                />
              </div>
            </div>
          )}
        </div>
        {isGameScheduled && (
          <div className={"w-11/12 3xl:w-10/12 mx-auto"}>
            <GameProjection
              isGameScheduled={true}
              game={gameDetails}
              onVoteChange={getProjectionStats}
              starters={teamStarters}
              projectionStats={projectionStats}
            />
          </div>
        )}
      </div>
    );
  } else {
    return <SDLoading />;
  }
}

const BenchAndOutOnScheduledGame = (props) => {
  const { game } = props;
  const homeBench = game?.home?.positions?.bench;
  const awayBench = game?.away?.positions?.bench?.slice().reverse();
  const homeOut = game?.home?.positions?.out;
  const awayOut = game?.away?.positions?.out?.slice().reverse();
  return (
    <div className={"flex flex-col justify-center bg-game-field-footer rounded-b-xl p-4 gap-4"}>
      <div className={"w-full mx-auto grid grid-cols-2 grid-rows-1"}>
        <div className={"flex flex-row gap-4"}>
          <div
            className={"flex flex-row justify-center aspect-square rounded-[20%] h-10 w-10"}
            style={{
              backgroundColor: getLineupProjectionBgColor("NOT_STARTER"),
            }}
          >
            <div className={`flex flex-row items-center h-10 w-10`}>
              <BenchIcon className={"h-10 w-10"} />
            </div>
          </div>
          <div className={"flex flex-row gap-2 justify-start flex-wrap"}>
            {homeBench &&
              homeBench.map((l) => {
                return <NotPlayingFieldAvatar player={l} />;
              })}
          </div>
        </div>
        <div className={"flex flex-row gap-4 justify-end"}>
          <div className={"flex flex-row gap-2 justify-end flex-wrap"}>
            {awayBench &&
              awayBench.map((l) => {
                return <NotPlayingFieldAvatar player={l} />;
              })}
          </div>
          <div
            className={"flex flex-row justify-center aspect-square rounded-[20%] h-10 w-10"}
            style={{
              backgroundColor: getLineupProjectionBgColor("NOT_STARTER"),
            }}
          >
            <div className={`flex flex-row items-center h-10 w-10`}>
              <BenchIcon className={"h-10 w-10"} />
            </div>
          </div>
        </div>
      </div>
      <div className={"w-full mx-auto grid grid-cols-2 grid-rows-1"}>
        <div className={"flex flex-row gap-4"}>
          <div
            className={"flex flex-row justify-center aspect-square rounded-[20%] h-10 w-10"}
            style={{ backgroundColor: getLineupProjectionBgColor("OUT") }}
          >
            <div className={`flex flex-row items-center h-10 w-10`}>
              <OutIcon className={"h-10 w-10"} />
            </div>
          </div>
          <div className={"flex flex-row gap-2 justify-start flex-wrap"}>
            {homeOut &&
              homeOut.map((l) => {
                return <NotPlayingFieldAvatar player={l} />;
              })}
          </div>
        </div>
        <div className={"flex flex-row gap-4 justify-end"}>
          <div className={"flex flex-row gap-2 justify-end flex-wrap"}>
            {awayOut &&
              awayOut.map((l) => {
                return <NotPlayingFieldAvatar player={l} />;
              })}
          </div>
          <div
            className={"flex flex-row justify-center aspect-square rounded-[20%] h-10 w-10"}
            style={{ backgroundColor: getLineupProjectionBgColor("OUT") }}
          >
            <div className={`flex flex-row items-center h-10 w-10`}>
              <OutIcon className={"h-10 w-10"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GameProjection = (props) => {
  const { game, starters, onVoteChange, projectionStats, isGameScheduled } = props;
  const [showOnlyLicensed, setShowOnlyLicensed] = useState(game?.game?.international !== undefined ? !game.game.international : true);

  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"flex justify-center items-center"}>
        <div className={"p-2 bg-white rounded text-textGrey3"}>
          <ToggleWithLabel
            noMargin={true}
            label="Show only minted players"
            isActive={showOnlyLicensed}
            setActive={() => {
              const newShowOnlyLicensed = !showOnlyLicensed;
              setShowOnlyLicensed(newShowOnlyLicensed);
            }}
          />
        </div>
      </div>
      <div className={"flex flex-col gap-y-4  rounded-t-xl overflow-hidden 2xl:flex-row "}>
        <div
          className={
            "w-full rounded-t-xl overflow-hidden 1.5xl:overflow-auto 1.5xl:bg-white 2xl:rounded-none 1.5xl:w-10/12 mx-auto 2xl:w-1/2"
          }
        >
          <TeamProjection
            isGameScheduled={isGameScheduled}
            showOnlyLicensed={showOnlyLicensed}
            game={game?.game}
            team={game?.home}
            starters={starters.home}
            gwStatus={game?.gameweek_status}
            onVoteChange={onVoteChange}
            projectionStats={projectionStats?.[game?.game?.game?.HomeTeamId]}
          />
        </div>
        <div
          className={
            "w-full rounded-t-xl overflow-hidden 1.5xl:overflow-auto 2xl:rounded-none 1.5xl:w-10/12 mx-auto 2xl:w-1/2 border-l border-transparent-inverse-surface-low border-opacity-20"
          }
        >
          <TeamProjection
            isGameScheduled={isGameScheduled}
            showOnlyLicensed={showOnlyLicensed}
            game={game?.game}
            team={game?.away}
            starters={starters.away}
            gwStatus={game?.gameweek_status}
            onVoteChange={onVoteChange}
            projectionStats={projectionStats?.[game?.game?.game?.AwayTeamId]}
          />
        </div>
      </div>
    </div>
  );
};

const PlayerLine = (props) => {
  const { player, game, last, gwStatus, onVoteChange, isGameScheduled } = props;
  const scores = player.last5Scores || [];
  const availabilityStatus = player.player_status;
  const hasVote = player.probable.status && player.probable.status !== "NO_STATUS";
  const points = player?.probable?.result || 0;
  const pointColor =
    !hasVote || points === 0
      ? "text-grey-66 bg-grey-f2"
      : points < 0
      ? "text-white bg-horrible-red"
      : points < 1
      ? "text-white bg-light-green"
      : "text-white bg-green";
  const isMinted = player.player.Licensed;
  const borderColor = isMinted ? (hasVote ? "border-light-green" : "border-grey-f6") : "border-white";
  return (
    <tr className={`${last ? "border-y" : "border-t"} border-r border-grey-f2`}>
      <td className={`w-full inline-table`}>
        <div className={`flex flex-row w-full gap-1 pl-1 py-1 border-l-6 ${borderColor}`}>
          <PlayerAvatar imgSize="w-8 h-8 md:w-12 md:h-12" size="w-10 h-10 md:w-14 md:h-14" player={player.player} />
          <div className={"self-center flex flex-col justify-evenly items-start h-full gap-1 w-full"}>
            <div className={"flex flex-row gap-1 items-center w-full"}>
              <p className={"self-start font-bold text-xs max-w-[80%]"}>{player.player.MatchName}</p>
              <div className={"min-w-[20%] flex-auto"}>
                <PlayerAvailability size="w-4" availability={availabilityStatus} />
              </div>
            </div>
            <p className={"self-start font-semibold text-xxs"}>
              {detailedPositionFullName[player.player.MainPosition] || player.player.Position} - {player.player.Age}
            </p>
          </div>
          {!isGameScheduled && (
            <div className={"flex flex-row items-center px-2"}>
              <div className={`rounded-full h-fit text-xs py-0.5 font-semibold flex flex-row justify-center px-1 ${pointColor}`}>
                {hasVote ? (
                  <div className={"flex flex-row justify-between items-center gap-1 w-full"}>
                    {points != 0 ? (
                      points < 0 ? (
                        <MinusIcon className={"fill-white h-4 w-4"} />
                      ) : (
                        <PlusIcon className={"fill-white h-4 w-4"} />
                      )
                    ) : (
                      <span className={"mr-0.25"}></span>
                    )}
                    <span>{points < 0 ? toFixedIfNecessary(-points, 2) : toFixedIfNecessary(points, 2)}</span>
                    <span className={"mr-0.5"}>pt</span>
                  </div>
                ) : (
                  <span className={"whitespace-nowrap self-center px-1"}>No proj.</span>
                )}
              </div>
            </div>
          )}
        </div>
      </td>
      {isGameScheduled && (
        <td className={"hidden md:table-cell"}>
          <div className={"flex flex-row space-x-2 justify-center py-1"}>
            <div className={"w-full"}>
              <Last10LineAlternative
                reverse={true}
                textSize="text-xs"
                stats={scores}
                power={1}
                position={player.player.Position}
                player={player.player}
                powerAdj={false}
              />
            </div>
          </div>
        </td>
      )}
      <td className={"border-l border-textGrey5 self-center"}>
        <div className={"flex flex-row justify-center self-center pt-2.5"}>
          <CommunityPredictionIndicator
            gwLiveOrClosed={
              gwStatus !== undefined && (gwStatus === "ongoing" || gwStatus === "closed" || gwStatus === "ended") ? true : false
            }
            majorChoiceColored={false}
            votable={true}
            pred={player.lineup}
            selected={player.probable.status}
            gameId={game.game.GameId}
            playerId={player.player.PlayerId}
            textSize={"text-xxs"}
            iconSize={"w-6 h-6"}
            onVoteChange={onVoteChange}
            effectiveResult={player.effective_starting_status}
          />
        </div>
      </td>
    </tr>
  );
};

const TeamProjection = (props) => {
  const { team, game, starters, showOnlyLicensed, gwStatus, onVoteChange, projectionStats, isGameScheduled } = props;
  let finalStarters = [];
  if (starters !== undefined && starters !== null) {
    finalStarters = showOnlyLicensed ? starters.filter((s) => s.player.Licensed) : starters;
  }
  const startersByPosition = groupBy(finalStarters, "player.Position");
  return (
    <table className={"w-full border-collapse border-spacing-0"}>
      <thead className={""}>
        <tr className={"bg-surface-container-highest"}>
          <th colSpan="3" className={"text-left w-full"}>
            <div className={"flex flex-row justify-between py-3 px-4"}>
              <div className={"flex flex-row gap-2 justify-start"}>
                <div className={"w-8 h-8 self-center flex justify-center"}>
                  <img className={"object-contain self-center"} src={team?.team?.PictureUrl ? team.team.PictureUrl : UnknownClub} />
                </div>
                <p className={"text-on-surface text-md font-semibold hover:font-bold self-center"}>
                  <a href={"/team/" + team?.team?.TeamId}>{team?.team?.ClubName}</a>
                </p>
              </div>
              <div className={"flex flex-row gap-2"}>
                <ProjectionPointsIndicatorForGame stats={projectionStats} />
                <ProjectedLineupStats stats={projectionStats} />
              </div>
            </div>
          </th>
        </tr>
        <tr
          className={
            "bg-surface-container-highest border-t border-transparent-inverse-surface-low border-opacity-10 text-on-surface text-xs font-semibold"
          }
          colSpan="6"
        >
          <th className={"text-left"}>
            <div className={"py-2 pl-4"}>Players</div>
          </th>
          {isGameScheduled && (
            <th className={"hidden md:table-cell text-left text-center"}>
              <div className={"p-2"}>Last 5 games</div>
            </th>
          )}
          <th className={"text-left text-center"}>
            <div className={"py-2 pr-4"}>Projection</div>
          </th>
        </tr>
      </thead>
      <tbody className={"bg-white"}>
        {["Goalkeeper", "Defender", "Midfielder", "Forward"].map((p) => {
          if (startersByPosition[p] && startersByPosition[p].length > 0) {
            return (
              <>
                <tr>
                  <td
                    colSpan={isGameScheduled ? "3" : "2"}
                    className={"text-left bg-white py-2 px-4 text-brand-black text-xs font-bold border-r border-grey-f2"}
                  >
                    {p}s
                  </td>
                </tr>
                {startersByPosition[p].map((s, i) => {
                  return (
                    <PlayerLine
                      isGameScheduled={isGameScheduled}
                      last={i === startersByPosition[p].length - 1}
                      player={s}
                      game={game}
                      gwStatus={gwStatus}
                      onVoteChange={onVoteChange}
                    />
                  );
                })}
              </>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default withUser(GameLivePage);
