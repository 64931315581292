import React, { useState } from "react";
import { Box, Form, Heading, Text, TextInput } from "grommet";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { Button } from "../util/button";

function AskForPasswordReset(props) {
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const askReset = (e) => {
    e.preventDefault();
    console.log("sending", username);
    props
      .fetch("/apiv2/user/askPasswordReset", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setErrorMessage("An email was sent to your address with reset instructions.");
        } else {
          setErrorMessage("Could not ask for a reset: " + res.error);
        }
      })
      .catch(errorCatcher());
  };

  return (
    <Box>
      <Box align={"center"}>
        <Heading color={"brand"} level={"2"}>
          Reset your password
        </Heading>
        <Form onSubmit={askReset}>
          <TextInput type="text" name="username" placeholder="Your SorareData username" onChange={(e) => setUsername(e.target.value)} />
          <br />
          <Box align={"center"} margin={"auto"}>
            <Button onClick={askReset}>Ask reset</Button>
          </Box>
        </Form>
        <Box margin={"small"}>
          <Text>{errorMessage}</Text>
        </Box>
      </Box>
    </Box>
  );
}

export default withUser(AskForPasswordReset);
