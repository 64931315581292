import React, { useRef, useState } from "react";
import PopperPortal from "../players/helpers/popper";

const PopHoverWrapper = (props) => {
  const { children, hover, hoverTitle, fixed, offset } = props;
  const [popHover, setPopHover] = useState(false);
  const displayPopHover = () => setPopHover(true);
  const hidePopHover = () => setPopHover(false);
  const parentRef = useRef(null);
  return (
    <div
      className={"relative flex flex-col gap-1 justify-center self-center cursor-default h-full"}
      onMouseEnter={displayPopHover}
      onMouseLeave={hidePopHover}
      ref={parentRef}
    >
      {popHover && (
        <PopperPortal active={popHover} fixed={fixed} parentRef={parentRef} offset={offset}>
          <div
            className={"flex flex-col gap-3 isolate z-30 bg-surface-container rounded-md shadow-lg px-4 py-3 text-sm text-on-surface"}
            style={{ minWidth: "13em" }}
          >
            <h4 className={"text-on-surface m-0 text-xs"}>{hoverTitle}</h4>
            {hover}
          </div>
        </PopperPortal>
      )}
      {children}
    </div>
  );
};

export default PopHoverWrapper;
