import React from "react";
import CardIcon from "./cardIcon";
import LineSeparator from "../../img/line-separator.svg";

function MultiScarcityPicker(props) {
  let halfOpacity = "opacity-50";
  let style = "cursor-pointer flex flex-row space-x-4 self-center ";
  return (
    <div className={"flex flex-row space-x-4 justify-center mx-auto self-center bg-surface-container py-2 px-6 rounded-lg"}>
      <div
        className={props.scarcity.indexOf("LIMITED") >= 0 ? style : style + halfOpacity}
        onClick={() => props.onChange("LIMITED")}
        data-tip={props.tip ? props.tip : ""}
      >
        <CardIcon scarcity={"limited"} />
      </div>
      <img src={LineSeparator} />
      <div
        className={props.scarcity.indexOf("RARE") >= 0 ? style : style + halfOpacity}
        onClick={() => props.onChange("RARE")}
        data-tip={props.tip ? props.tip : ""}
      >
        <CardIcon scarcity={"rare"} />
      </div>
      <img src={LineSeparator} />
      <div
        className={props.scarcity.indexOf("SUPER RARE") >= 0 ? style : style + halfOpacity}
        onClick={() => props.onChange("SUPER RARE")}
        data-tip={props.tip ? props.tip : ""}
      >
        <CardIcon scarcity={"super rare"} />
      </div>
      {!props.noUnique && <img src={LineSeparator} />}
      {!props.noUnique && (
        <div
          className={props.scarcity.indexOf("UNIQUE") >= 0 ? style : style + halfOpacity}
          onClick={() => props.onChange("UNIQUE")}
          data-tip={props.tip ? props.tip : ""}
        >
          <CardIcon scarcity={"unique"} />
        </div>
      )}
      {props.customSeries && <img src={LineSeparator} />}
      {props.customSeries && (
        <div
          className={props.scarcity.indexOf("CUSTOM SERIES") >= 0 ? style : style + halfOpacity}
          onClick={() => props.onChange("CUSTOM SERIES")}
          data-tip={props.tip ? props.tip : ""}
        >
          <CardIcon scarcity={"custom series"} />
        </div>
      )}
    </div>
  );
}

export default MultiScarcityPicker;
