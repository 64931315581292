import React from "react";

function CardCountEntry(props) {
  return (
    <div>
      <div className={"flex flex-row space-x-1 self-center"} data-tip={props.tip}>
        <img src={props.img} className={"w-2 h-2 self-center"} />
        <p className={"text-med font-semibold self-center text-" + props.color}>
          <a href={props.anchor}>{" " + props.count}</a>
        </p>
      </div>
    </div>
  );
}

export default CardCountEntry;
