import GreyLockImg from "../../img/grey-lock.svg";
import React from "react";

function GreyLock(props) {
  return (
    <div className={"flex flex-col items-center"}>
      <img src={GreyLockImg} className={"w-full h-full object-contain"} />
    </div>
  );
}

export default GreyLock;
