import SubIn from "../../img/sub-in.svg";
import SubOutInjured from "../../img/injury-change.svg";
import SubOut from "../../img/sub-out.svg";
import GoalIcon from "../../img/ICN_Ballon.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import YellowCard from "../../img/yellow-card.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import PenaltySave from "../../img/PenaltySave.svg";
import CleanSheet from "../../img/cleansheet.svg";
import ScoreEllipse from "../util/scoreEllipse";
import React from "react";
import { CommunityPredictionSummary } from "../util/communityPrediction";
import { PickScore } from "../util/pickScore";
import OppScore from "../decision/oppScore";
import { t1OrAbove, t2OrAbove } from "../util/handleSubscriptionTier";
import { withUser } from "../../userContext";

export const NotPlayingFieldAvatar = (props) => {
  const { player } = props;
  return (
    <div data-tip={player.DisplayName}>
      <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
        {player.Avatar !== "" ? (
          <img src={player.Avatar} className={"rounded-full w-10 h-10 object-contain pt-2 bg-white"} />
        ) : (
          <svg className={"h-10 w-10 text-gray-300 rounded-full bg-white"} fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        )}
      </a>
    </div>
  );
};

function FieldAvatar(props) {
  const p = props.stats;
  let bottomRightIcons = [];
  let bottomLeftIcons = [];
  let topLeftIcons = [];
  let topRightIcon;
  if (p.SubbedOn) {
    topRightIcon = (
      <div className={"z-100 flex flex-row space-x-1"}>
        <img src={SubIn} /> <span className={"text-white text-xs opacity-50 font-semibold"}>{p.mins_played}&apos;</span>
      </div>
    );
  } else if (p.SubbedOff) {
    if (p.SubReason === "Injury") {
      topRightIcon = (
        <div className={"z-100 flex flex-row space-x-1"}>
          <img src={SubOutInjured} /> <span className={"text-white text-xs opacity-50 font-semibold"}>{p.mins_played}&apos;</span>
        </div>
      );
    } else {
      topRightIcon = (
        <div className={"z-100 flex flex-row space-x-1"}>
          <img src={SubOut} /> <span className={"text-white text-xs opacity-50 font-semibold"}>{p.mins_played}&apos;</span>
        </div>
      );
    }
  }
  for (let i = 0; i < p.Goals; i++) {
    let z = 100 + bottomRightIcons.length;
    bottomRightIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={GoalIcon} />
      </div>,
    );
  }
  for (let i = 0; i < p.AdjustedGoalAssist; i++) {
    let z = 100 + bottomLeftIcons.length;
    bottomLeftIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={AssistIcon} />
      </div>,
    );
  }
  for (let i = 0; i < p.AssistPenaltyWon; i++) {
    let z = 100 + bottomRightIcons.length;
    bottomRightIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={Whistle} />
      </div>,
    );
  }
  for (let i = 0; i < p.ClearanceOffLine + p.LastManTackle; i++) {
    let z = 100 + bottomRightIcons.length;
    bottomRightIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={AngelBall} />
      </div>,
    );
  }
  for (let i = 0; i < p.PenaltySave + p.PenaltySaves; i++) {
    let z = 100 + bottomRightIcons.length;
    bottomRightIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={PenaltySave} />
      </div>,
    );
  }
  for (let i = 0; i < p.YellowCard; i++) {
    let z = 100 + topLeftIcons.length;
    topLeftIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={YellowCard} />
      </div>,
    );
  }
  for (let i = 0; i < p.RedCard; i++) {
    let z = 100 + topLeftIcons.length;
    topLeftIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={RedCard} />
      </div>,
    );
  }
  for (let i = 0; i < p.OwnGoals; i++) {
    let z = 100 + topLeftIcons.length;
    topLeftIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={OwnGoal} />
      </div>,
    );
  }
  for (let i = 0; i < p.PenaltyConceded; i++) {
    let z = 100 + topLeftIcons.length;
    topLeftIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={RedWhistle} />
      </div>,
    );
  }
  for (let i = 0; i < p.ErrorLeadToGoal; i++) {
    let z = 100 + topLeftIcons.length;
    topLeftIcons.push(
      <div style={{ marginRight: "-8px", zIndex: z }}>
        <img src={RedShoe} />
      </div>,
    );
  }
  if (p.Position === "Goalkeeper") {
    for (let i = 0; i < p.CleanSheet; i++) {
      let z = 100 + bottomRightIcons.length;
      bottomRightIcons.push(
        <div style={{ marginRight: "-8px", zIndex: z }}>
          <img src={CleanSheet} />
        </div>,
      );
    }
  }
  let background = "bg-white";
  if (p.manager_details?.lineups !== null && p.manager_details?.lineups !== undefined) {
    background = "bg-player-halo";
  }
  let shirtNumber = p.ShirtNumber;
  if (shirtNumber === 0) {
    if (p.JerseyNumber !== 0) {
      shirtNumber = p.JerseyNumber;
    } else {
      shirtNumber = "";
    }
  }

  const content = (
    <div className={"space-y-2 cursor-pointer w-32"} onClick={props.overlay ? () => props.overlay(p) : undefined}>
      <div className={"h-20 flex flex-col self-center justify-center mx-auto items-center align-middle space-y-2"}>
        <div className="w-20 h-20 flex flex-row justify-center rounded-full self-center mx-auto">
          {p.Avatar !== "" && <img src={p.Avatar} className={"rounded-full w-14 h-14 object-contain pt-2 " + background} />}
          {p.Avatar === "" && (
            <svg className={"h-14 w-14 text-gray-300 rounded-full " + background} fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}
          <div className={"absolute mr-10 flex flex-row -mt-1.5 justify-start self-start z-100"}>{topLeftIcons}</div>
          <div className={"absolute ml-12 flex flex-row -mt-1.5 justify-end self-start z-100"}>{topRightIcon}</div>
          <div className={"absolute mr-10 flex flex-row justify-start self-end z-100"}>{bottomLeftIcons}</div>
          <div className={"absolute ml-10 flex flex-row justify-end self-end z-100"}>{bottomRightIcons}</div>
        </div>
        <div className={"text-center flex flex-row justify-center space-x-1 self-center"}>
          <p className={"text-xs font-semibold text-white opacity-50 self-center"}>{shirtNumber}</p>{" "}
          <p className={"text-white text-center text-sm font-semibold self-center truncate"}>{p.MatchName}</p>
        </div>
      </div>
      {!props.hideScores && (
        <div className={"w-12 self-center text-center items-center mx-auto"}>
          <ScoreEllipse power={1} score={p.SO5Score} size={"text-md"} shadow={"shadow-lg"} padding={"px-1 py-1"} round={true} />
        </div>
      )}
      {props.showProjectionInfo && (
        <div className={"flex w-full justify-center"}>
          <div className={"bg-white opacity-80 rounded flex flex-row gap-1 justify-between px-1 items-center"}>
            <div className={"w-16 my-1"}>
              <CommunityPredictionSummary adapting={true} hideReliability={true} pred={p.projected_lineup} />
            </div>
            {p.pick_score && t2OrAbove(props.user.tier) && (
              <div className={"w-10 h-6 -mt-3 mr-0.5"}>
                <PickScore label={p.pick_score?.pickScore} />
              </div>
            )}
            {p.pick_score && t1OrAbove(props.user.tier) && (
              <div className={"my-1"}>
                <OppScore size="w-6 h-6" fontSize="text-xs" score={p.pick_score?.oppScore} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  if (props.linkToPlayerPage) {
    return (
      <a href={"/player/" + p.PlayerId} target={"_blank"} rel="noreferrer">
        {content}
      </a>
    );
  }

  return content;
}

export default withUser(FieldAvatar);
