import React, { useEffect } from "react";
import AuctionCard from "./auctionCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactTooltip } from "../util/tooltip.js";
import { getTeamMapNextGw } from "../util/teamMap";
import { scarcities_objects } from "../util/scarcities";
import { leagues_no_div_objects_not_all } from "../util/leagues";
import { all_positions_objects } from "../util/positions";
import { withUser } from "../../userContext";
import { playing_status_objects } from "../util/playingStatus";
import { fetchGameweekGames } from "../util/nextGws";
import SDLoading from "../util/SDLoading";
import { errorCatcher } from "../util/errors";
import SearchFilters from "../search/searchFilters";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import AbortController from "abort-controller";
import { sorareFootball } from "../util/sports";
import { DEFAULT_AGE_RANGE } from "../util/ageRange";
import { allStarDefaultCompetition, initialEligibilityCompetitionValue } from "../competitions/eligibilityCompetitionsPicker";

function AuctionWrapper(props) {
  const [displayedAuctions, setDisplayedAuctions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [u23Only, setU23Only] = React.useState(false);
  const [watchlists, setWatchlists] = React.useState({});
  const [scarcityValue, setScarcityValue] = React.useState([]);
  const [leagueValue, setLeagueValue] = React.useState([]);
  const [eligibilityValue, setEligibilityValue] = React.useState(initialEligibilityCompetitionValue);

  const [positionValue, setPositionValue] = React.useState([]);
  const [priceInfo, setPriceInfo] = React.useState({});
  const [teamsValue, setTeamsValue] = React.useState([]);
  const [underMp, setUnderMp] = React.useState(false);
  const [underAvg, setUnderAvg] = React.useState(false);
  const [current, setCurrent] = React.useState(false);
  const [bmpFilter, setBmpFilter] = React.useState(false);
  const [avgFilter, setAvgFilter] = React.useState(false);
  const [currentRange, setCurrentRange] = React.useState([0, 1]);
  const [bmpRange, setBmpRange] = React.useState([0, 1]);
  const [avgRange, setAvgRange] = React.useState([0, 1]);
  const [ageRange, setAgeRange] = React.useState(DEFAULT_AGE_RANGE);
  const [scoreFilters, setScoreFilters] = React.useState({
    l5: { enabled: false, average_range: [0, 100], decisive_range: [0, 100], all_around_range: [-20, 100] },
    l15: { enabled: false, average_range: [0, 100], decisive_range: [0, 100], all_around_range: [-20, 100] },
    l40: { enabled: false, average_range: [0, 100], decisive_range: [0, 100], all_around_range: [-20, 100] },
  });
  const [gwValue, setGwValue] = React.useState(0);
  const [gwDisplayValue, setGwDisplayValue] = React.useState(0);

  const [gwFilter, setGwFilter] = React.useState(false);
  const [gwGames, setGwGames] = React.useState(null);
  const [playingStatusValue, setPlayingStatusValue] = React.useState(playing_status_objects[0].value);
  const [availabilityStatus, setAvailabilityStatus] = React.useState([]);
  const [sport, setSport] = React.useState(props.sport || "all");
  const [gws, setGws] = React.useState([]);
  const [refresh, setRefresh] = React.useState(0);
  const [theFilters, setTheFilters] = React.useState(undefined);
  const [abortController, setAbortController] = React.useState(new AbortController());
  const [searchQuery, setSearchQuery] = React.useState("");

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, []);

  const getMostExpensive = () => {
    let toFetch = 40;
    let nbAuctions = 0;
    if (displayedAuctions.length > 0) nbAuctions = displayedAuctions.length;
    props
      .fetch("/apiv2/auctions/" + props.tmp + "/" + toFetch + "/" + nbAuctions)
      .then((response) => response.json())
      .then((res) => {
        setDisplayedAuctions(displayedAuctions.concat(res.auctions));
        setPriceInfo(res.price_info);
      })
      .catch(errorCatcher());
  };

  const getNextGameweeks = () => {
    props
      .fetch("/apiv2/gw/next")
      .then((response) => response.json())
      .then(async (res) => {
        const gws = [];
        res.map((gw) => {
          gws.push({ value: gw.GwNumber, name: gw.DisplayName, valueAlternate: gw.SorareGwNumber });
          return null;
        });
        const firstGw = gws[0];
        const gwValue = firstGw.value;
        const sorareGwValue = firstGw.valueAlternate;
        const games = await fetchGameweekGames(props, gwValue);
        setGwGames(games);
        setGwValue(gwValue);
        setGwDisplayValue(sorareGwValue);
        setGws(gws);
      })
      .catch(errorCatcher());
  };

  const getGameweekGames = async (gw) => {
    let games = await fetchGameweekGames(props, gw);
    setGwGames(games);
  };

  const getAuctions = (reset, loading, filters) => {
    if (filters === undefined) filters = theFilters;
    let newAbortController;
    if (filters) {
      let toFetch = 40;
      if (props.loadMore === false) toFetch = 20;
      let nbAuctions = 0;
      if (displayedAuctions.length > 0) nbAuctions = displayedAuctions.length;
      if (reset) {
        nbAuctions = 0;
        if (abortController) abortController.abort();
        newAbortController = new AbortController();
        setAbortController(newAbortController);
      }
      setLoading(true);
      props
        .fetch("/apiv2/auctions/filterAuctions", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            limit: toFetch,
            from: nbAuctions,
            gw: filters.gw || 0,
            u23: filters.u23,
            scarcity: filters.scarcities,
            league: filters.leagues,
            competitionsEligible: Object.keys(filters.eligibilityCompetitions).filter((k) => k !== allStarDefaultCompetition.id),
            position: filters.positions,
            watchlists: Object.keys(filters.watchlists),
            teams: filters.teams,
            under_mp: filters.underBmp,
            under_avg: filters.underAvg,
            playing_status: filters.playingStatus?.length > 0 ? filters.playingStatus : "all",
            availability_status: filters.availabilityStatus,
            age: filters.age,
            current_price: filters.currentPrice?.enabled
              ? [
                  parseFloat(filters.currentPrice.min.toString().replace(",", ".")),
                  parseFloat(filters.currentPrice.max.toString().replace(",", ".")),
                ]
              : [],
            best_market_price: filters.floor?.enabled
              ? [parseFloat(filters.floor.min.toString().replace(",", ".")), parseFloat(filters.floor.max.toString().replace(",", "."))]
              : [],
            average_price: filters.average?.enabled
              ? [parseFloat(filters.average.min.toString().replace(",", ".")), parseFloat(filters.average.max.toString().replace(",", "."))]
              : [],
            last_5: {
              enabled: filters.l5Filter?.enabled,
              average_range: filters.l5Filter?.scoreRange,
              all_around_range: filters.l5Filter?.aaRange,
              decisive_range: filters.l5Filter?.daRange,
            },
            last_15: {
              enabled: filters.l15Filter?.enabled,
              average_range: filters.l15Filter?.scoreRange,
              all_around_range: filters.l15Filter?.aaRange,
              decisive_range: filters.l15Filter?.daRange,
            },
            last_40: {
              enabled: filters.l40Filter?.enabled,
              average_range: filters.l40Filter?.scoreRange,
              all_around_range: filters.l40Filter?.aaRange,
              decisive_range: filters.l40Filter?.daRange,
            },
            intl_trip: false,
            sport: filters.sport,
            jersey_mint: filters.jerseyMint,
            one_serial_number: filters.oneSerialNumber,
            search_query: filters.searchQuery,
          }),
          signal: newAbortController?.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          if (res === null) {
            setDisplayedAuctions([]);
          } else {
            if (reset) {
              setDisplayedAuctions(res.auctions);
              setPriceInfo(res.price_info);
              if (filters.gw) getGameweekGames(filters.gw);
            } else {
              setDisplayedAuctions(displayedAuctions.concat(res.auctions));
              setPriceInfo(res.price_info);
              if (filters.gw) getGameweekGames(filters.gw);
            }
          }
          setLoading(false);
        })
        .catch(errorCatcher(() => setLoading(false)));
    } else {
      let toFetch = 40;
      if (props.loadMore === false) toFetch = 20;
      let nbAuctions = 0;
      if (displayedAuctions.length > 0) nbAuctions = displayedAuctions.length;
      if (reset) {
        nbAuctions = 0;
        if (abortController) abortController.abort();
        newAbortController = new AbortController();
        setAbortController(newAbortController);
      }
      let scarcity = "all";
      if (scarcityValue !== scarcities_objects[0].value) scarcity = scarcityValue;
      let league = "all";
      if (leagueValue !== leagues_no_div_objects_not_all[0].value) league = leagueValue;
      let position = "all";
      if (positionValue !== all_positions_objects[0].value) position = positionValue;
      setLoading(true);
      props
        .fetch("/apiv2/auctions/filterAuctions", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            limit: toFetch,
            from: nbAuctions,
            gw: gwFilter ? gwValue : 0,
            u23: u23Only,
            scarcity: scarcity,
            league: league,
            competitionsEligible: Object.keys(eligibilityValue).filter((k) => k !== allStarDefaultCompetition.id),
            position: position,
            watchlists: Object.keys(watchlists),
            teams: teamsValue,
            under_mp: underMp,
            under_avg: underAvg,
            playing_status: playingStatusValue,
            availability_status: availabilityStatus,
            age: ageRange,
            current_price: current
              ? [parseFloat(currentRange[0].toString().replace(",", ".")), parseFloat(currentRange[1].toString().replace(",", "."))]
              : [],
            best_market_price: bmpFilter
              ? [parseFloat(bmpRange[0].toString().replace(",", ".")), parseFloat(bmpRange[1].toString().replace(",", "."))]
              : [],
            average_price: avgFilter
              ? [parseFloat(avgRange[0].toString().replace(",", ".")), parseFloat(avgRange[1].toString().replace(",", "."))]
              : [],
            last_5: scoreFilters.l5,
            last_15: scoreFilters.l15,
            last_40: scoreFilters.l40,
            sport: sport,
            search_query: searchQuery,
          }),
          signal: newAbortController?.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          if (res === null) {
            setDisplayedAuctions([]);
          } else {
            if (reset) {
              setDisplayedAuctions(res.auctions);
              setPriceInfo(res.price_info);
              if (gwFilter) getGameweekGames(gwValue);
            } else {
              setDisplayedAuctions(displayedAuctions.concat(res.auctions));
              setPriceInfo(res.price_info);
              if (gwFilter) getGameweekGames(gwValue);
            }
          }
          setLoading(false);
        })
        .catch(errorCatcher(() => setLoading(false)));
    }
  };

  let onMore = () => {
    if (props.loadMore !== false) {
      if (props.tmp === "expensive") getMostExpensive();
      else getAuctions(false, false);
    }
  };

  const filter = (filters, refresh) => {
    setScarcityValue(filters.scarcities);
    setPositionValue(filters.positions);
    setTeamsValue(filters.teams);
    setLeagueValue(filters.leagues);
    setEligibilityValue(filters.eligibilityCompetitions);
    setAgeRange(filters.age);
    setPlayingStatusValue(filters.playingStatus?.length > 0 ? filters.playingStatus : "all");
    setAvailabilityStatus(filters.availabilityStatus || []);
    setU23Only(filters.u23);
    setCurrent(filters.currentPrice.enabled);
    setCurrentRange([filters.currentPrice.min, filters.currentPrice.max]);
    setAvgFilter(filters.average.enabled);
    setAvgRange([filters.average.min, filters.average.max]);
    setBmpFilter(filters.floor.enabled);
    setBmpRange([filters.floor.min, filters.floor.max]);
    setScoreFilters({
      l5: filters.l15Filter,
      l15: filters.l15Filter,
      l40: filters.l40Filter,
    });
    setWatchlists(filters.watchlists);
    setUnderMp(filters.underBmp);
    setUnderAvg(filters.underAvg);
    setGwFilter(filters.filterByGw);
    setGwValue(filters.gw);
    setSport(filters.sport);
    setTheFilters(filters);
    setSearchQuery(filters.searchQuery);
    let interval = setInterval(() => getAuctions(true, false, filters), props.refresh);
    setRefresh(interval);
    if (refresh) {
      getAuctions(true, true, filters);
    }
  };

  useEffect(() => {
    getNextGameweeks();
    if (props.auctions === undefined) {
      getTeamMapNextGw(props);
      if (props.tmp === "expensive") getMostExpensive();
      else getAuctions(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(refresh);
    };
  }, [refresh]);

  let loadMore = true;
  if (props.loadMore === false) loadMore = props.loadMore;

  let notNextGw = false;
  if (gws.length > 0) {
    if (gwValue !== gws[0].value) notNextGw = true;
  }

  return (
    <div>
      <div className={"flex flex-col"}>
        <div className={""}>
          <SearchFilters
            sport={sport}
            loading={loading && displayedAuctions !== undefined && displayedAuctions.length >= 0}
            vertical={true}
            filter={filter}
            mode={"auctions"}
          />
        </div>
        <div className={"flex flex-col w-full"}>
          {isFree(props.user.tier) && sport === sorareFootball && (
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-advanced-search bg-cover h-upgrade-banner-l w-full mb-4"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock unlimited presets"}
            />
          )}
          {displayedAuctions !== undefined &&
            displayedAuctions?.length === 0 &&
            (loading ? <SDLoading /> : <p className={"text-base text-center mt-4"}>No auction available.</p>)}
          {displayedAuctions !== undefined && displayedAuctions?.length > 0 && (
            <div className={"flex flex-col w-full items-start"}>
              <InfiniteScroll
                dataLength={displayedAuctions.length}
                scrollableTarget={"window"}
                next={onMore}
                hasMore={loadMore && displayedAuctions.length % 40 === 0}
                onMore={onMore}
                loader={loadMore ? <SDLoading /> : undefined}
              >
                <div
                  className={
                    "justify-start grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 1.5xl:grid-cols-6 2xl:grid-cols-6 2.5xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-7 gap-4"
                  }
                >
                  {displayedAuctions.map((a, i) => (
                    <AuctionCard
                      key={a.AuctionId}
                      auction={a}
                      past={props.past}
                      nextGwPlayers={gwGames}
                      gwNumber={gwValue}
                      gwNumberDisplay={gwDisplayValue}
                      notNextGw={notNextGw}
                      forceUnit={props.forceUnit}
                      priceInfo={a.priceInfo ? a.priceInfo : priceInfo}
                    />
                  ))}
                </div>
              </InfiniteScroll>

              {!loadMore && (
                <div className={"w-full mt-4 flex flex-row justify-end"}>
                  <a className={"text-primary font-semibold"} href={"/liveMarket?mode=auctions"}>
                    All ongoing auctions {`->`}
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(AuctionWrapper);
