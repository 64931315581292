import React, { forwardRef, useEffect, useState } from "react";
import { format, formatDistance } from "date-fns";
import NewCardLink from "../cards/newCardLink";
import { findPriceForUserUnit } from "../util/formatMoney";
import TradeArrow from "../../img/trade-arrow.svg";
import { ReactTooltip } from "../util/tooltip.js";
import SDLoading from "../util/SDLoading";
import { positions_objects } from "../util/positions";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { scarcities_objects_not_all } from "../util/scarcities";
import { leagues_with_divisions_objects_not_all } from "../util/leagues";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import DatePicker from "react-datepicker";
import { Spinning } from "../loader/spinner";
import { dateDiffInDays } from "../util/date";
import AbortController from "abort-controller";

const limitAPI = 30;

function ManagerTransactions(props) {
  const now = new Date();
  const yesterday = new Date(now.getTime());
  yesterday.setDate(now.getDate() - 1);
  const [transactions, setTransactions] = useState({
    offers: [],
    auctions: [],
  });
  const [scarcity, setScarcity] = useState([]);
  const [position, setPosition] = useState([]);
  const [league, setLeague] = useState([]);
  const [type, setType] = useState("all");
  const [buyType, setBuyType] = useState("all");
  const [timeRangeMode, setTimeRangeMode] = useState("all");
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(now);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState("");
  const [abortController, setAbortController] = useState(new AbortController());

  const types = [
    { value: "all", name: "All transactions" },
    { value: "direct_offer", name: "Direct offers" },
    { value: "public_offer", name: "Public offers" },
    { value: "auction", name: "Auctions" },
    { value: "instant_buy", name: "Instant buys" },
    { value: "reward", name: "Rewards" },
  ];

  const buyTypes = [
    { value: "all", name: "All buys & sales" },
    { value: "buys", name: "All acquisitions" },
    { value: "buys_no_rewards", name: "All acquisitions (without rewards)" },
    { value: "sales", name: "All sales" },
  ];

  const timeRangeOptions = [
    { value: "all", name: "All time" },
    {
      value: "last_24_hours",
      name: "Last 24 hours",
    },
    { value: "custom_range", name: "Range" },
  ];

  const getManagerTransactions = ({ s, p, l, t, bt, o, trm }) => {
    if (!s) s = scarcity;
    if (!p) p = position;
    if (!l) l = league;
    if (!t) t = type;
    if (!bt) bt = buyType;
    if (!trm) trm = timeRangeMode;
    if (o === undefined) o = transactions.offset || 0;
    const params = new URLSearchParams({ type: t, buyType: bt });
    p?.forEach((pos) => params.append("position", pos));
    s?.forEach((sc) => params.append("scarcity", sc));
    l?.forEach((lg) => params.append("league", lg));
    if (trm && trm !== "all") {
      if (trm === "last_24_hours") {
        params.append("startDate", yesterday.toISOString());
        params.append("endDate", now.toISOString());
      } else {
        params.append("startDate", startDate.toISOString());
        params.append("endDate", endDate.toISOString());
      }
    }
    const abortController = new AbortController();
    setLoading(true);
    setAbortController(abortController);
    props
      .fetch(`/apiv2/manager/transactions/${props.manager.Slug}?${params.toString()}`, {
        method: "GET",
        headers: withJSONPagination(o, limitAPI),
        signal: abortController.signal,
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        const newTransactions = {
          offers: o === 0 ? res.offers : [...transactions.offers, ...res.offers],
          auctions: o === 0 ? res.auctions : [...transactions.auctions, ...res.auctions],
          rewards: o === 0 ? res.rewards : [...transactions.rewards, ...res.rewards],
          instantBuys: o === 0 ? res.instantBuys : [...transactions.instantBuys, ...res.instantBuys],
          valuation_map: o === 0 ? res.valuation_map : { ...transactions.valuation_map, ...res.valuation_map },
          hasNext: hasNext,
          offset: o + limitAPI,
        };
        setTransactions(newTransactions);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher())
      .finally(() => setLoading(false));
  };

  useEffect(() => getManagerTransactions({}), []);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      style={{ width: "90px", height: "25px" }}
      className={"cursor-pointer text-center text-textGrey2 font-sm sm:w-auto text-xs focus:outline-none bg-transparent"}
      onClick={onClick}
      ref={ref}
      value={format(new Date(value), "LLL do, yyyy")}
    />
  ));
  ExampleCustomInput.displayName = "ExampleCustomInput";

  let allTransactions = [];
  if (transactions.offers !== null) {
    allTransactions = allTransactions.concat(transactions.offers);
  }
  if (transactions.rewards !== null) {
    allTransactions = allTransactions.concat(transactions.rewards);
  }
  if (transactions.auctions !== null) {
    allTransactions = allTransactions.concat(transactions.auctions);
  }
  if (transactions.instantBuys !== null && transactions.instantBuys !== undefined) {
    allTransactions = allTransactions.concat(transactions.instantBuys);
  }
  allTransactions = allTransactions.sort(function (a, b) {
    let dateA;
    let dateB;
    if (a.auction) {
      dateA = a.auction.EndingDate;
    } else if (a.date) {
      dateA = a.date;
    } else if (a.settledAt) {
      dateA = a.settledAt;
    } else if (a.endDate) {
      dateA = a.endDate;
    } else if (a.offer) {
      dateA = a.offer.AcceptationDate;
    } else {
      dateA = new Date(0);
    }
    if (b.auction) {
      dateB = b.auction.EndingDate;
    } else if (b.date) {
      dateB = b.date;
    } else if (b.settledAt) {
      dateB = b.settledAt;
    } else if (b.endDate) {
      dateA = b.endDate;
    } else if (b.offer) {
      dateB = b.offer.AcceptationDate;
    } else {
      dateB = new Date(0);
    }
    dateA = new Date(dateA);
    dateB = new Date(dateB);
    if (dateA > dateB) {
      return -1;
    } else {
      return 1;
    }
  });
  const diffDate = dateDiffInDays(startDate, endDate);
  const canFilter = timeRangeMode !== "custom_range" || (diffDate > 0 && diffDate <= 7);
  const canFilterMessage = canFilter
    ? ""
    : diffDate <= 0
    ? "Start date should be strictly before end date"
    : "You can only filter on a range of 7 days maximum";
  return (
    <div className={"space-y-4"}>
      <div className={"flex flex-col xl:flex-row gap-y-2 gap-x-1"}>
        <div
          className={
            "flex flex-col gap-y-1 lg:grid lg:gap-1 lg:flex-none lg:grid-rows-2 lg:grid-cols-3 xl:grid-rows-none xl:flex xl:grid-cols-none xl:space-y-0 xl:flex-row xl:justify-center xl:mx-auto xl:w-11/12"
          }
        >
          <SelectSearch
            multiple
            closeOnSelect={false}
            options={scarcities_objects_not_all}
            value={scarcity}
            printOptions={"on-focus"}
            filterOptions={fuzzySearch}
            placeholder="All scarcities"
            className={`select-search ${focus === "scarcity" ? "z-50" : ""}`}
            onFocus={() => setFocus("scarcity")}
            onBlur={() => setFocus("")}
            onChange={(v) => {
              setScarcity(v);
            }}
          />
          <SelectSearch
            multiple={true}
            className={`select-search ${focus === "position" ? "z-50" : ""}`}
            options={positions_objects}
            printOptions={"on-focus"}
            value={position}
            filterOptions={fuzzySearch}
            onFocus={() => setFocus("position")}
            onBlur={() => setFocus("")}
            placeholder="All positions"
            closeOnSelect={false}
            onChange={(v) => {
              setPosition(v);
            }}
          />
          <SelectSearch
            multiple={true}
            className={`select-search ${focus === "league" ? "z-50" : ""}`}
            options={leagues_with_divisions_objects_not_all}
            printOptions={"on-focus"}
            value={league}
            filterOptions={fuzzySearch}
            onFocus={() => setFocus("league")}
            onBlur={() => setFocus("")}
            placeholder="All leagues"
            closeOnSelect={false}
            onChange={(v) => {
              setLeague(v);
            }}
          />
          <SelectSearch
            className={`select-search ${focus === "type" ? "z-50" : ""}`}
            onFocus={() => setFocus("type")}
            onBlur={() => setFocus("")}
            options={types}
            printOptions={"on-focus"}
            value={type}
            closeOnSelect={true}
            onChange={(v) => {
              setType(v);
            }}
          />
          <SelectSearch
            className={`select-search ${focus === "buyType" ? "z-50" : ""}`}
            onFocus={() => setFocus("buyType")}
            onBlur={() => setFocus("")}
            options={buyTypes}
            printOptions={"on-focus"}
            value={buyType}
            closeOnSelect={true}
            onChange={(v) => {
              setBuyType(v);
            }}
          />
          <div className={"flex items-center"}>
            <SelectSearch
              className={`select-search w-full ${focus === "timeRange" ? "z-50" : ""}`}
              onFocus={() => setFocus("timeRange")}
              onBlur={() => setFocus("")}
              options={timeRangeOptions}
              printOptions={"on-focus"}
              value={timeRangeMode}
              closeOnSelect={true}
              onChange={(v) => {
                setTimeRangeMode(v);
              }}
            />
            {timeRangeMode === "custom_range" && (
              <div className={"flex h-[80%] bg-white rounded-tr rounded-br items-center shadow pl-1"}>
                <div className={"cursor-pointer z-20"}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
                <span className={"self-center font-semibold text-xs text-textGrey3"}>to</span>
                <div className={"cursor-pointer z-20"}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"flex justify-center"}>
          <button
            onClick={
              canFilter
                ? () => {
                    abortController.abort();
                    getManagerTransactions({ o: 0 });
                  }
                : undefined
            }
            data-tip={canFilterMessage}
            type="button"
            className={`inline-flex justify-center w-[20%] xl:w-auto px-6 py-2 border border-brand text-med font-semibold rounded-lg
                shadow-sm text-white bg-brand focus:outline-none focus:ring-0 text-center ${
                  canFilter ? "hover:bg-brand-light hover:border-brand-light cursor-pointer" : "opacity-50 cursor-not-allowed"
                }`}
          >
            Filter
          </button>
        </div>
      </div>
      <InfiniteScroll
        next={() => getManagerTransactions({})}
        hasMore={transactions.hasNext}
        loader={<SDLoading />}
        dataLength={allTransactions.length}
      >
        <table className={"border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}>
          <thead>
            <tr className="text-center">
              <th className="rounded-tl-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs text-center">
                Transaction date
              </th>
              <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"></th>

              <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-4/12 font-bold uppercase text-xs">
                Sent
              </th>
              <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-1 text-left w-12 font-bold uppercase text-xs"></th>
              <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs text-center">
                To
              </th>
              <th className="rounded-tr-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-4/12 font-bold uppercase text-xs">
                <div className={"flex justify-between"}>
                  <span>Received</span>
                  <span className={"h-4 w-4"}>{loading && <Spinning className={"h-4 w-4"} />}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {allTransactions.length > 0 &&
              allTransactions.map((t, i) => {
                if (!t) {
                  return null;
                }
                let rounded = "";
                if (i === allTransactions.length - 1) {
                  rounded = "rounded-b-lg";
                }
                let date;
                let sender;
                let sending;
                let receiver;
                let receiving;
                let link;
                if (t.auction) {
                  date = t.auction.EndingDate;
                  receiver = "Sorare";
                  link = "/auction/" + t.auction.AuctionId;
                  const valuation = transactions.valuation_map["auction" + t.auction.TokenId];
                  const valuationFiatValues = {
                    eur: valuation?.Eur,
                    usd: valuation?.Usd,
                    gbp: valuation?.Gbp,
                  };
                  const value = findPriceForUserUnit(valuation?.Valuation, valuationFiatValues, props.user.preferredUnit);
                  const sent = findPriceForUserUnit(t.auction.EndingPrice, t, props.user.preferredUnit);
                  receiving = (
                    <p>
                      <a href={"/player/" + t.player.PlayerId}>{t.player.DisplayName}</a> -{" "}
                      <NewCardLink sport={t.player.Sport} card={t.card} />{" "}
                      <span data-tip={"Card valuation when traded"} className={"font-medium text-xs"}>
                        ({value})
                      </span>
                    </p>
                  );
                  sender = props.manager.Nickname;
                  sending = <p>{sent}</p>;
                } else if (t.date) {
                  date = t.date;
                  receiver = "Sorare";
                  const valuation = t.valuation_history;
                  const valuationFiatValues = {
                    eur: valuation?.Eur,
                    usd: valuation?.Usd,
                    gbp: valuation?.Gbp,
                  };
                  const value = findPriceForUserUnit(valuation?.Valuation, valuationFiatValues, props.user.preferredUnit);
                  receiving = (
                    <p>
                      <a href={"/player/" + t.player.PlayerId}>{t.player.DisplayName}</a> -{" "}
                      <NewCardLink sport={t.player.Sport} card={t.card} />{" "}
                      <span data-tip={"Card valuation when traded"} className={"font-medium text-xs"}>
                        ({value})
                      </span>
                    </p>
                  );
                  sender = props.manager.Nickname;
                  sending = <p>Nothing (reward)</p>;
                } else if (t.endDate || t.settledAt) {
                  // Instant buy
                  date = t.settledAt || t.endDate;
                  receiver = "Sorare";
                  const valuation = transactions.valuation_map["instant_buy" + t.tokenId];
                  const valuationFiatValues = {
                    eur: valuation?.Eur,
                    usd: valuation?.Usd,
                    gbp: valuation?.Gbp,
                  };
                  const value = findPriceForUserUnit(valuation?.Valuation, valuationFiatValues, props.user.preferredUnit);
                  const sent = findPriceForUserUnit(t.prices["eth"], t.prices, props.user.preferredUnit);
                  receiving = (
                    <p>
                      <a href={"/player/" + t.player.PlayerId}>{t.player.DisplayName}</a> -{" "}
                      <NewCardLink sport={t.player.Sport} card={t.card} />{" "}
                      <span data-tip={"Card valuation when traded"} className={"font-medium text-xs"}>
                        ({value})
                      </span>
                    </p>
                  );
                  sender = props.manager.Nickname;
                  sending = <p>{sent}</p>;
                } else if (t.offer) {
                  const sentAmount = findPriceForUserUnit(t.offer.SendAmountInWei, t.sent_amount_fiat, props.user.preferredUnit);
                  const receivedAmount = findPriceForUserUnit(t.offer.ReceiveAmountInWei, t.received_amount_fiat, props.user.preferredUnit);
                  date = t.offer.AcceptationDate;
                  link = "/offer/" + t.offer.OfferId;
                  sender = (
                    <a href={"/manager/" + t.manager_sending.Slug} className={"hover:font-semibold"}>
                      {t.manager_sending.Nickname}
                    </a>
                  );
                  receiver = (
                    <a href={"/manager/" + t.manager_receiving.Slug} className={"hover:font-semibold"}>
                      {t.manager_receiving.Nickname}
                    </a>
                  );
                  if (t.players_sent !== null) {
                    sending = (
                      <div>
                        {t.players_sent.map((c) => {
                          const valuation = transactions.valuation_map[t.offer.OfferId + c.card.TokenId];
                          const valuationFiatValues = {
                            eur: valuation?.Eur,
                            usd: valuation?.Usd,
                            gbp: valuation?.Gbp,
                          };
                          const value = findPriceForUserUnit(valuation?.Valuation, valuationFiatValues, props.user.preferredUnit);
                          /*return (<div className={"flex flex-col space-y-1 self-center"}>
                                              <div className={"w-10 h-10 self-center"}>
                                                  <img className={"w-full h-full object-contain"} src={c.card.AvatarUrl}/>
                                              </div>

                                              <span data-tip={"Card valuation when traded"}
                                                        className={"font-medium text-xs"}>({formatPrice(value, "eth")})</span>
                                          </div>)*/
                          return (
                            <p>
                              <a href={"/player/" + c.player.PlayerId}>{c.player.DisplayName}</a> -{" "}
                              <NewCardLink sport={c.player.Sport} card={c.card} />{" "}
                              <span data-tip={"Card valuation when traded"} className={"font-medium text-xs"}>
                                ({value})
                              </span>
                            </p>
                          );
                        })}
                        <p>{t.offer.SendAmountInWei > 0 && sentAmount}</p>
                      </div>
                    );
                  } else if (t.offer.SendAmountInWei === 0) {
                    sending = "Nothing";
                  } else if (t.offer.SendAmountInWei > 0) {
                    sending = <p>{sentAmount}</p>;
                  }
                  if (t.players_received !== null) {
                    receiving = (
                      <div>
                        {t.players_received.map((c) => {
                          const valuation = transactions.valuation_map[t.offer.OfferId + c.card.TokenId];
                          const valuationFiatValues = {
                            eur: valuation?.Eur,
                            usd: valuation?.Usd,
                            gbp: valuation?.Gbp,
                          };
                          const value = findPriceForUserUnit(valuation?.Valuation, valuationFiatValues, props.user.preferredUnit);
                          return (
                            <p>
                              <a href={"/player/" + c.player.PlayerId}>{c.player.DisplayName}</a> -{" "}
                              <NewCardLink sport={c.player.Sport} card={c.card} />{" "}
                              <span data-tip={"Card valuation when traded"} className={"font-medium text-xs"}>
                                ({value})
                              </span>
                            </p>
                          );
                        })}
                        <p>{t.offer.ReceiveAmountInWei > 0 && receivedAmount}</p>
                      </div>
                    );
                  } else if (t.offer.ReceiveAmountInWei === 0) {
                    receiving = "Nothing";
                  } else if (t.offer.ReceiveAmountInWei > 0) {
                    receiving = <p>{receivedAmount}</p>;
                  }
                  if (t.manager_sending.Nickname !== props.manager.Nickname) {
                    let change = sender;
                    sender = receiver;
                    receiver = change;
                    change = receiving;
                    receiving = sending;
                    sending = change;
                  }
                }
                return (
                  <tr className={rounded} key={i}>
                    <td className={"border-t border-gray-200 "}>
                      <div className={"flex flex-col space-y-1 py-2"}>
                        <span className="text-sm flex flex-row justify-center font-medium">
                          {date ? new Date(date).toLocaleString() : ""}
                        </span>
                        <span className="text-xs uppercase text-textGrey3 font-semibold flex flex-row justify-center">
                          {date
                            ? formatDistance(new Date(date), new Date(), {
                                addSuffix: true,
                              })
                            : ""}
                        </span>
                      </div>
                    </td>
                    <td className={"border-t border-gray-200 "}>
                      <div className={"flex flex-col py-2 px-2"}>
                        <span className="text-sm flex flex-row justify-center">{sender}</span>
                      </div>
                    </td>
                    <td className={"border-t border-gray-200 "}>
                      <div className={"flex flex-col py-2 text-sm font-semibold px-4"}>{sending}</div>
                    </td>
                    <td className={"border-t border-gray-200 "}>
                      <a href={link} target={"_blank"} rel="noreferrer">
                        <div className={"w-8 h-8 "}>
                          <img src={TradeArrow} className={`w-full h-full object-contain ${link ? "opacity-100" : "opacity-40"}`} />
                        </div>
                      </a>
                    </td>
                    <td className={"border-t border-gray-200 "}>
                      <div className={"flex flex-col py-2 px-2"}>
                        <span className="text-sm flex flex-row justify-center">{receiver}</span>
                      </div>
                    </td>
                    <td className={"border-t border-gray-200 "}>
                      <div className={"flex flex-col py-2 text-sm font-semibold px-4"}>{receiving}</div>
                    </td>
                  </tr>
                );
              })}
            {allTransactions.length === 0 && (
              <tr className={"text-sm text-center p-5 text-textGrey3"}>
                <td className={"py-5"} colSpan="6">
                  No transaction with these filters
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </InfiniteScroll>
      <ReactTooltip />
    </div>
  );
}

export default withUser(ManagerTransactions);
