const computePower = (cardTokenId, cardPower, withCaptain, withCardPower, captainPower, captain) => {
  let power = 1;
  if (withCaptain && captain && captain.card.TokenId === cardTokenId) {
    power += captainPower / 100;
  }
  if (withCardPower) {
    power += +cardPower - 1;
  }
  return power;
};

export const computeLineupStrength = (lineup, divRules, captain, divisionMaxScore) => {
  if (!divRules) {
    return {
      lineupScore: 0,
      divisionMaxScore: 0,
      divisionMaxScoreNoCaptain: 0,
    };
  }
  const lineupScore = lineup.reduce((acc, player) => {
    if (!player) return acc;
    const { withCaptain, withCardPower, captainPower } = divRules;
    const power = computePower(player.card.TokenId, player.card.Power, withCaptain, withCardPower, captainPower, captain);
    const mnp = player.mnp || 0;
    return acc + mnp * power;
  }, 0.0);
  return { lineupScore, divisionMaxScore: divisionMaxScore.normal, divisionMaxScoreNoCaptain: divisionMaxScore.noCaptain };
};

export const computeLineupRawStrength = (lineup, divRules, captain) => {
  if (!divRules) {
    return 0;
  }
  return lineup.reduce((acc, player) => {
    if (!player) return acc;
    const { withCaptain, withCardPower, captainPower } = divRules;
    const power = computePower(player.card.TokenId, player.card.PowerNoScarcityBonus, withCaptain, withCardPower, captainPower, captain);
    const mnp = player.mnp || 0;
    return acc + mnp * power;
  }, 0.0);
};
