import { isFree, t1OrAbove, t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import React, { useCallback, useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { CommunityPredictionSummaryIndicator, PlayerCommunityProjectionWithHover } from "../util/communityPrediction";
import { PickScoreDetails } from "../util/pickScore";
import { withSortableProps } from "../util/sorting";
import PlayerAvatar from "../util/playerAvatar";
import UnknownClub from "../../img/unknown_club.png";
import PlayerAvailability from "../players/playerAvailability";
import CardIcon from "../util/cardIcon";
import SorareScoreAverage from "./sorareScoreAverage";
import ScoreAverage from "./scoreAverage";
import { GameInLineVsWithDetails } from "./gameInLine";
import { PlayedAsStarterPct } from "../util/playedStats";
import { DecisiveActionsScore } from "../util/decisiveScore";
import { AllAroundScore } from "../util/allAroundScore";
import { MedianScore } from "../util/medianScore";
import { withUser } from "../../userContext";
import { ReactComponent as IconCarretFilled } from "../../img/icons-carret-filled.svg";
import { AddToLineupButton, getFilteredAndEnhancedPlayers, PreferentialPositions, SeasonTag } from "./lineupBuilderTable";
import { OppScoreWithDetails } from "./oppScore";
import { MatchupIndicator, MatchupOdds } from "../util/matchupScore";
import { ReactComponent as PersonAdd } from "@material-design-icons/svg/filled/person_add_alt_1.svg";
import { ReactComponent as CheckIcon } from "../../img/icons-check.svg";
import { ReactComponent as SquareWarningIcon } from "../../img/icons-warning-losange-no-color.svg";
import { NoCardResultsImage } from "../util/noCardResultsImage";
import { sorareFootball } from "../util/sports";

const Placeholder = (props) => {
  return (
    <div className={"rounded-lg bg-surface-container flex flex-col animate-pulse-sm"}>
      <div className={"flex flex-row gap-1 justify-start items-left px-3 py-1 text-on-surface"}>
        <div className={"bg-surface-container-high rounded h-4 w-32"}></div>
      </div>
      <div className={"border-b border-transparent-inverse-surface-low border-opacity-10 px-2 py-0 flex flex-row justify-between"}>
        <div className={"flex flex-col gap-1 items-left w-[45%]"}>
          <div className={"flex flex-row gap-1 items-center"}>
            <div>
              <PlayerAvatar player={{ Avatar: "" }} />
            </div>
            <div className={"flex flex-col gap-1 justify-center"}>
              <div className={"bg-surface-container-high rounded h-4 w-16"}></div>
              <div className={"bg-surface-container-high rounded h-4 w-12"}></div>
            </div>
          </div>
        </div>
        <div className={"grid grid-cols-4 grid-rows-1 items-center w-[55%] h-12 gap-3"}>
          <div className={"bg-surface-container-high rounded w-[35px] h-[35px]"}></div>
          <div className={"bg-surface-container-high rounded w-[35px] h-[35px]"}></div>
          <div className={"bg-surface-container-high rounded w-[35px] h-[35px]"}></div>
          <div className={"bg-surface-container-high rounded w-[35px] h-[35px]"}></div>
        </div>
      </div>
      <div className={"flex flex-row justify-between items-center h-7"}>
        <div className={"flex flex-row gap-1 items-center text-on-surface py-1 px-3 h-6"}>
          <div className={"bg-surface-container-high rounded h-4 w-40"}></div>
        </div>
        <div className={"flex flex-row gap-1 items-center text-on-surface py-1 px-3 h-6"}>
          <div className={"bg-surface-container-high rounded h-4 w-16"}></div>
        </div>
      </div>
    </div>
  );
};

export const LineupBuilderPlayerTiles = withUser((props) => {
  const {
    so5ScoresDetailed,
    oppScoresDetailed,
    pickScoreReveal,
    onPickScoreReveal,
    revealing,
    standings,
    players,
    odds,
    divRules,
    lineupPoints,
    selectedEntry,
    containerHeight,
    preferences,
    noHeader,
    noActionLabel,
    maxPlayers,
    onDiscardRevert,
    loading,
    currentLineupId,
  } = props;
  const { pickedAverage, playerSearchName, pickedView, columns } = props;

  const containerOffset = props.containerOffset || "0px";
  const placeholderAmount = props.placeholderAmount || 3;
  const userT1OrAbove = t1OrAbove(props.user.tier);
  const userT2OrAbove = t2OrAbove(props.user.tier);
  const powerAdjustedAverages = preferences.powerAdjustedScore && divRules?.withCardPower;
  const showPickedPlayers = preferences.showPickedPlayers;
  const showAffordablePlayersOnly = preferences.showAffordablePlayersOnly;
  const alwaysShowCost = userT1OrAbove ? preferences.showCost : false;
  const gameAdjusted = preferences.competitionAdjustedScore || preferences.teamAdjustedScore || preferences.homeAwayAdjustedScore;
  const anyAdjusted = gameAdjusted || powerAdjustedAverages;
  const isCapped = divRules?.isCapped !== undefined ? divRules.isCapped : false;
  const hasCostRestriction = isCapped || divRules?.atLeastCardsWithAverage !== undefined || divRules?.atMostCardsWithAverage !== undefined;
  const seasonLimitations = divRules?.seasonLimits;
  const seasonBonusLimitations = divRules?.seasonBonusLimits;
  const seasonHighlightEnabled =
    preferences.highlightNewSeason &&
    ((seasonLimitations !== null && seasonLimitations !== undefined) ||
      (seasonBonusLimitations !== null && seasonBonusLimitations !== undefined));
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [sort, setSort] = useState({
    field: "",
    order: "desc",
    origin: "automatic",
  });
  const onClickSort = useCallback(
    (field) => {
      const order = sort.field === field ? (sort.order === "asc" ? "desc" : "asc") : "desc";
      const newSort = { field, order, origin: "manual" };
      setSort(newSort);
    },
    [sort],
  );

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (elem) => {
      if (elem) {
        const { scrollHeight, scrollTop, clientHeight } = elem;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        const hasMore = displayedPlayers.length < filteredPlayers.length;
        if (scrollHeight - scrollTop - clientHeight < 300 && hasMore) {
          onDisplayedPlayerChange();
        }
      }
    },
    [displayedPlayers, filteredPlayers],
  );

  const onDisplayedPlayerChange = useCallback(() => {
    setDisplayedPlayers(filteredPlayers.slice(0, displayedPlayers.length + 20));
    ReactTooltip.rebuild();
  }, [filteredPlayers, displayedPlayers]);

  useEffect(() => {
    if (props.user?.tier && divRules) {
      // required props are ready
      if (sort.field === "" || sort.origin === "automatic") {
        // Not sorted manually by user yet
        // sort by l15 if rookie or uncapped div of Pro
        // sort by pick score otherwise
        if (props.defaultSort !== undefined) {
          setSort(props.defaultSort);
        } else {
          setSort({
            field:
              t2OrAbove(props.user?.tier) || (divRules?.isCapped && t1OrAbove(props.user?.tier))
                ? "computed.pickScoreReverse"
                : "computed.l15",
            order: "desc",
            origin: "automatic",
          });
        }
      }
    }
  }, [props.user, divRules]);

  useEffect(() => {
    const filteredEnhancedAndSorted = getFilteredAndEnhancedPlayers(
      players,
      currentLineupId,
      props.isCardInCurrentLineup,
      props.divRules,
      powerAdjustedAverages,
      preferences,
      so5ScoresDetailed,
      pickedAverage,
      odds,
      pickScoreReveal,
      lineupPoints,
      playerSearchName,
      showPickedPlayers,
      selectedEntry,
      showAffordablePlayersOnly,
      sort,
    );
    setFilteredPlayers(filteredEnhancedAndSorted);
    setDisplayedPlayers(maxPlayers ? filteredEnhancedAndSorted.slice(0, maxPlayers) : filteredEnhancedAndSorted.slice());
  }, [sort, players, playerSearchName, props.pickedCards, props.preferences, props.divRules, lineupPoints, selectedEntry, maxPlayers]);

  return (
    <div className={"flex flex-col gap-2"}>
      {(noHeader === undefined || noHeader === false) && (
        <div
          className={
            "flex flex-row justify-between text-on-surface-variant text-xs font-semibold rounded-lg bg-surface-container-high items-center px-2"
          }
        >
          <div className={"w-[45%]"}>
            <span>Player</span>
          </div>
          <div className={"grid grid-cols-4 grid-rows-1 w-[55%]"}>
            {pickedView === "classic" && (
              <>
                {isCapped || alwaysShowCost ? (
                  <Header
                    {...withSortableProps("cost", onClickSort, sort)}
                    label={"Cost"}
                    tip={"Fantasy point cost for Capped divisions, usually the Sorare L15"}
                  />
                ) : (
                  <div></div>
                )}
                <Header
                  {...withSortableProps("computed.l5", onClickSort, sort)}
                  adjusted={anyAdjusted}
                  label={"L5"}
                  tip={"Average of last 5 scores"}
                />
                <Header
                  {...withSortableProps("computed.l15", onClickSort, sort)}
                  adjusted={anyAdjusted}
                  label={"L15"}
                  tip={"Average of last 15 scores"}
                />
                <Header
                  {...withSortableProps("computed.pickScoreReverse", onClickSort, sort)}
                  label={"Pick score"}
                  tip={
                    "We give a relative grade to each card based on the player's performance, the card details and the upcoming game setup (competition/home or away)"
                  }
                  onboarding={"pick-score-same-all-divs"}
                />
              </>
            )}
            {pickedView === "advanced" && (
              <>
                <Header
                  {...withSortableProps("computed.communityProjections", onClickSort, sort)}
                  label={"Proj."}
                  tip={"Community projections, whether people think the player will play or not"}
                />
                <Header
                  {...withSortableProps("opp_score_adjusted", onClickSort, sort)}
                  adjusted={gameAdjusted}
                  label={"Opp. score"}
                  tip={
                    "Average of points allowed by a team's opponent over the last 15 games in the upcoming game setup (competition/home or away)"
                  }
                />
                <Header {...withSortableProps("matchup_score", onClickSort, sort)} adjusted={gameAdjusted} label={"Matchup ind."} />
                <Header
                  {...withSortableProps("computed.matchupOddsPct", onClickSort, sort)}
                  label={"Odds"}
                  tip={
                    "Probability of the most important key feature regarding the position, clean sheet, score 3+ goals, conceding 3+ goals"
                  }
                />
              </>
            )}
            {pickedView === "form" && (
              <>
                <Header
                  {...withSortableProps("computed.lPicked", onClickSort, sort)}
                  adjusted={anyAdjusted}
                  label={pickedAverage.toUpperCase()}
                  tip={"Average of last scores"}
                />
                <Header
                  {...withSortableProps("computed.decScore", onClickSort, sort)}
                  adjusted={anyAdjusted}
                  label={"DS avg."}
                  tip={"Decisive score average"}
                />
                <Header
                  {...withSortableProps("computed.aaScore", onClickSort, sort)}
                  adjusted={anyAdjusted}
                  label={"AA avg."}
                  tip={"All-Around average"}
                />
                <Header
                  {...withSortableProps("computed.medianScore", onClickSort, sort)}
                  adjusted={anyAdjusted}
                  label={"Median"}
                  tip={"Median of last scores, i.e. the score from which 50% of the scores are above and 50% are below"}
                />
              </>
            )}
          </div>
        </div>
      )}
      <div
        className={`grid ${columns} gap-2 overflow-y-auto`}
        style={
          containerHeight !== undefined && containerHeight !== 0
            ? {
                maxHeight: `calc(${containerHeight}px - ${containerOffset || 0}`,
              }
            : {}
        }
        onScroll={(e) => fetchMoreOnBottomReached(e.target)}
      >
        <>
          {!(pickedView === "form" && !t2OrAbove(props.user.tier)) &&
            displayedPlayers.map((entry, i) => {
              const player = entry.player;
              const card = entry.card;
              const computed = entry.computed;

              let avgColor;
              let pickPctAvg = entry.pick_stats.pick_pct;
              if (pickPctAvg < 10) {
                avgColor = "#BC190F";
              } else if (pickPctAvg < 25) {
                avgColor = "#E1921B";
              } else if (pickPctAvg < 40) {
                avgColor = "#F1CE17";
              } else if (pickPctAvg < 55) {
                avgColor = "#ABC21D";
              } else if (pickPctAvg < 70) {
                avgColor = "#1DC29B";
              } else if (pickPctAvg <= 100) {
                avgColor = "#019355";
              }

              let divAvg = entry.division_stats.rate;
              let divColor;
              if (divAvg < 5) {
                divColor = "#BC190F";
              } else if (divAvg < 15) {
                divColor = "#E1921B";
              } else if (divAvg < 25) {
                divColor = "#F1CE17";
              } else if (divAvg < 35) {
                divColor = "#ABC21D";
              } else if (divAvg < 45) {
                divColor = "#1DC29B";
              } else if (divAvg <= 100) {
                divColor = "#019355";
              }

              let power = ((parseFloat(card.Power) - 1) * 100).toFixed(1);
              let odds = props.odds[entry.next_game.GameId];
              const isHome = computed.isHome;

              let disableAddCard = false;
              let addToLineupContent = [];
              const isSelectedInCurrentPosition = computed.isInCurrentLineup.value && computed.isInCurrentLineup.isSelectedPosition;
              if (onDiscardRevert === undefined) {
                if (computed.isInCurrentLineup.value) {
                  disableAddCard = true;
                  addToLineupContent.push(
                    <CheckIcon className={`w-4 h-4 ${isSelectedInCurrentPosition ? "fill-primary" : "fill-on-surface-variant"}`} />,
                  );
                  if (noActionLabel === undefined || noActionLabel === false) {
                    addToLineupContent.push(
                      <p
                        className={`text-xs font-semibold text-center px-1 ${
                          isSelectedInCurrentPosition ? "text-primary" : "text-on-surface-variant"
                        }`}
                      >
                        In this lineup
                      </p>,
                    );
                  }
                } else if (entry.pickedStatus.picked) {
                  addToLineupContent.push(
                    <div data-tip={`Picked in ${entry.pickedStatus.divisionName} - Team ${entry.pickedStatus.teamNumber}`}>
                      <SquareWarningIcon className={`w-5 h-5 fill-on-surface-variant`} />
                    </div>,
                  );
                }
                if (!disableAddCard) {
                  addToLineupContent.push(
                    <div
                      className={"flex flex-row gap-2 items-center text-xs text-primary font-semibold cursor-pointer hover:opacity-80"}
                      onClick={() => props.pickCard(entry, card.Position)}
                    >
                      <AddToLineupButton size={"xs"} />
                      Add to lineup
                    </div>,
                  );
                }
              } else {
                addToLineupContent.push(
                  <div
                    className={"flex flex-row gap-1 items-center text-xs text-primary font-semibold cursor-pointer hover:opacity-80"}
                    onClick={() => onDiscardRevert(entry)}
                  >
                    <PersonAdd className={"fill-primary w-4 h-4"} />
                    Take back
                  </div>,
                );
              }

              const competitionAdjusted = preferences.competitionAdjustedScore ? entry.next_game.CompSlug : "all";
              const homeAwayAdjusted = preferences.homeAwayAdjustedScore ? (isHome ? "home" : "away") : "all";
              const teamAdjusted = preferences.teamAdjustedScore ? entry.player_team.TeamId : "all";
              const so5ScoreDetailed =
                so5ScoresDetailed?.[player.PlayerId]?.[card.Position]?.[teamAdjusted]?.[competitionAdjusted]?.[homeAwayAdjusted];
              const so5ScoreDetailedWithPickedAvg = so5ScoreDetailed?.[pickedAverage];
              const so5ScoreDetailedL15 = so5ScoreDetailed?.l15;
              const so5ScoreDetailedL5 = so5ScoreDetailed?.l5;

              // Opp score
              let opponent;
              if (entry.next_game.HomeTeamId) {
                opponent =
                  entry.next_game.HomeTeamId === entry.player.TeamId || entry.next_game.HomeTeamId === entry.player.NationalTeam
                    ? entry.next_game.AwayTeamId
                    : entry.next_game.HomeTeamId;
              }
              const oppScoreDetailed = oppScoresDetailed?.[opponent]?.[entry.next_game.CompSlug]?.[isHome ? "away" : "home"]; // opponent is away when player is home

              // Form
              let decScore = so5ScoreDetailedWithPickedAvg?.decisiveScoreAverage;
              let aaScore = so5ScoreDetailedWithPickedAvg?.allAroundAverage;
              let medianScore = so5ScoreDetailedWithPickedAvg?.median;
              let floorScore = so5ScoreDetailedWithPickedAvg?.scores ? Math.min(...so5ScoreDetailedWithPickedAvg.scores) : undefined;
              let ceilingScore = so5ScoreDetailedWithPickedAvg?.scores ? Math.max(...so5ScoreDetailedWithPickedAvg.scores) : undefined;
              if (powerAdjustedAverages) {
                if (decScore !== undefined) decScore = decScore * card.Power;
                if (aaScore !== undefined) aaScore = aaScore * card.Power;
                if (medianScore !== undefined) medianScore = medianScore * card.Power;
                if (floorScore !== undefined) floorScore = floorScore * card.Power;
                if (ceilingScore !== undefined) ceilingScore = ceilingScore * card.Power;
              }
              aaScore = aaScore?.toFixed(1);
              decScore = decScore?.toFixed(0);
              medianScore = medianScore?.toFixed(0);

              const playerReveal = pickScoreReveal?.players?.[player.PlayerId + "-" + card.Position];
              let mainPickScore =
                entry.pick_score_group !== undefined && entry.pick_score_group !== null ? entry.pick_score_group : playerReveal?.score;
              if (entry.next_game?.GameId === undefined || entry.next_game.GameId === "") {
                mainPickScore = "NG";
              }
              const pickScoreDetails =
                entry.pick_score_details !== undefined &&
                entry.pick_score_details !== null &&
                Object.keys(entry.pick_score_details).length > 0
                  ? entry.pick_score_details
                  : playerReveal?.details;

              const hasSeasonBonus =
                seasonBonusLimitations !== null &&
                seasonBonusLimitations !== undefined &&
                card.CardPower &&
                parseFloat(card.CardPower?.season) > 0;
              const isCardFromSpecificSeason =
                seasonLimitations !== null &&
                seasonLimitations !== undefined &&
                (seasonLimitations.season === 0 || card.Season === seasonLimitations.season) &&
                (seasonLimitations.cardTeamSlugs === undefined ||
                  seasonLimitations.cardTeamSlugs === null ||
                  seasonLimitations.cardTeamSlugs.length === 0 ||
                  seasonLimitations.cardTeamSlugs.includes(card?.CardTeamId));
              const highlightSeason = seasonHighlightEnabled && (hasSeasonBonus || isCardFromSpecificSeason);

              return (
                <div key={i} className={"rounded-lg bg-surface-container flex flex-col"}>
                  <div className={"flex flex-row gap-1 justify-start items-left px-3 pt-1 text-on-surface"}>
                    <a href={"/player/" + player.PlayerId} target={"_blank"} className={"hover:opacity-80"} rel="noreferrer">
                      <p className={"font-semibold text-sm"}>{player.MatchName}</p>
                    </a>
                    <div className={"self-center w-4 h-4"}>
                      <PlayerAvailability availability={entry.availability_status} />
                    </div>
                  </div>
                  <div
                    className={"border-b border-transparent-inverse-surface-low border-opacity-10 px-2 py-0 flex flex-row justify-between"}
                  >
                    <div className={"flex flex-col gap-1 items-left w-[45%]"}>
                      <div className={"flex flex-row gap-1 items-center"}>
                        <div>
                          <PlayerAvatar player={player} border={isSelectedInCurrentPosition ? "border-2 border-primary" : undefined} />
                        </div>
                        <div className={"flex flex-row gap-1 justify-center"}>
                          <div className={"flex flex-col gap-1 items-center"}>
                            {entry.player_team.TeamId && (
                              <div className={"h-5 w-5"}>
                                <a href={"/team/" + player.TeamId} target={"_blank"} rel="noreferrer">
                                  <img
                                    className={"object-contain h-5 w-5"}
                                    title={entry.player_team.DisplayName}
                                    src={entry.player_team.PictureUrl !== "" ? entry.player_team.PictureUrl : UnknownClub}
                                  />
                                </a>
                              </div>
                            )}
                            <span className={"cursor-pointer hover:opacity-80"}>
                              <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
                                <CardIcon scarcity={card.Scarcity.toLowerCase()} />
                              </a>
                            </span>
                          </div>
                          <div className={"flex flex-col gap-1 items-left"}>
                            <span className={"flex items-center"}>
                              <PreferentialPositions cardPosition={card.Position} detailedPositions={so5ScoreDetailedL15?.positions} />
                            </span>
                            <p
                              className={`font-semibold ${
                                powerAdjustedAverages ? "text-primary text-sm" : "text-xs text-on-surface-variant"
                              }`}
                            >
                              {power}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"grid grid-cols-4 grid-rows-1 items-center w-[55%]"}>
                      {pickedView === "classic" && (
                        <>
                          <div>
                            {(hasCostRestriction || alwaysShowCost) && (
                              <div className={"flex flex-row justify-center space-x-2 py-0.5  w-full h-full"}>
                                <div className={"flex justify-center items-center w-8 h-8"}>
                                  <SorareScoreAverage avg={entry.cost} />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={"py-0.5  self-center flex items-center justify-center"}>
                            <div className={"w-7"}>
                              {so5ScoreDetailedL5 ? (
                                <ScoreAverage
                                  avg={so5ScoreDetailedL5?.average}
                                  period={so5ScoreDetailedL5?.games}
                                  played={so5ScoreDetailedL5?.gamesPlayed}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={so5ScoreDetailedL5?.average}
                                  sApps={so5ScoreDetailedL5?.gamesPlayed}
                                  adapting={true}
                                />
                              ) : (
                                <ScoreAverage
                                  avg={0}
                                  period={5}
                                  played={0}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={0}
                                  sApps={0}
                                  adapting={true}
                                />
                              )}
                            </div>
                          </div>
                          <div className={"py-0.5  self-center flex items-center justify-center"}>
                            <div className={"w-7"}>
                              {so5ScoreDetailedL15 ? (
                                <ScoreAverage
                                  avg={so5ScoreDetailedL15?.average}
                                  period={so5ScoreDetailedL15?.games}
                                  played={so5ScoreDetailedL15?.gamesPlayed}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={so5ScoreDetailedL15?.average}
                                  sApps={so5ScoreDetailedL15?.gamesPlayed}
                                />
                              ) : (
                                <ScoreAverage
                                  avg={0}
                                  period={15}
                                  played={0}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={0}
                                  sApps={0}
                                />
                              )}
                            </div>
                          </div>
                          <div className={"py-0.5 self-center flex items-center justify-center"}>
                            <div className={"w-10"}>
                              <PickScoreDetails
                                main={mainPickScore}
                                onReveal={() => onPickScoreReveal(player, card.Position)}
                                revealing={revealing[player.PlayerId + "-" + card.Position]}
                                revealInfo={pickScoreReveal}
                                isCapped={isCapped}
                                detailed={pickScoreDetails}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {pickedView === "advanced" && (
                        <>
                          <div className={"flex items-center justify-center"}>
                            <div className={"w-[3rem] px-1"}>
                              <CommunityPredictionSummaryIndicator
                                adapting={true}
                                vertical={true}
                                pred={entry.projected_lineup}
                                gameId={entry.next_game.GameId}
                              />
                            </div>
                          </div>
                          <div>
                            <div className={"flex flex-row justify-center h-full"}>
                              <OppScoreWithDetails
                                noHelpIcon={true}
                                entry={entry}
                                locked={isFree(props.user.tier)}
                                detailed={oppScoreDetailed?.oppScore}
                                cardPosition={card.Position}
                              />
                            </div>
                          </div>
                          <div>
                            <div className={"flex flex-row justify-center items-center self-center"}>
                              <MatchupIndicator score={entry.matchup_score} locked={t1OrBelow(props.user.tier)} />
                            </div>
                          </div>
                          <div>
                            <div className={"flex flex-row justify-center"}>
                              <MatchupOdds
                                isHome={isHome}
                                odds={odds}
                                version={"text-icon"}
                                cardPosition={card.Position}
                                detailedPositions={so5ScoreDetailedL15?.positions}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {pickedView === "form" && (
                        <>
                          <div className={"flex flex-row justify-center h-full items-center"}>
                            {so5ScoreDetailedWithPickedAvg ? (
                              <ScoreAverage
                                avg={so5ScoreDetailedWithPickedAvg?.average}
                                period={so5ScoreDetailedWithPickedAvg?.games}
                                played={so5ScoreDetailedWithPickedAvg?.gamesPlayed}
                                power={entry.card.Power}
                                powerAdj={powerAdjustedAverages}
                                sAvg={so5ScoreDetailedWithPickedAvg?.average}
                                sApps={so5ScoreDetailedWithPickedAvg?.gamesPlayed}
                              />
                            ) : (
                              <ScoreAverage
                                avg={0}
                                period={0}
                                played={0}
                                power={entry.card.Power}
                                powerAdj={powerAdjustedAverages}
                                sAvg={0}
                                sApps={0}
                              />
                            )}
                          </div>
                          <div className={"flex flex-row justify-center h-full items-center"}>
                            <DecisiveActionsScore score={decScore} />
                          </div>
                          <div className={"flex flex-row justify-center h-full items-center"}>
                            <AllAroundScore score={aaScore} />
                          </div>
                          <div className={"flex flex-row justify-center h-full items-center"}>
                            <MedianScore sport={player.Sport} score={medianScore} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={"flex flex-row justify-between items-center"}>
                    <div className={"flex flex-row gap-1 items-center text-on-surface py-1 px-3"}>
                      {pickedView === "classic" && (
                        <>
                          <div className={"w-[5rem]"}>
                            <PlayerCommunityProjectionWithHover
                              projection={entry.projected_lineup}
                              gameId={player.TeamId}
                              sport={sorareFootball}
                            />
                          </div>
                          <div className={"border-r border-transparent-inverse-surface-low border-opacity-10 h-4"}></div>
                          <div className={"w-[5rem] pl-1"}>
                            <GameInLineVsWithDetails
                              adapting={true}
                              playerTeam={entry.player.TeamId}
                              team1={entry.player_team}
                              team2={entry.opposing_team}
                              game={entry.next_game}
                              playerNationalTeam={entry.player.NationalTeam}
                              odds={odds?.["full_time0"]}
                              standings={standings}
                            />
                          </div>
                          <div className={"border-r border-transparent-inverse-surface-low border-opacity-10 h-4"}></div>
                          <div className={"px-2 flex items-center"}>
                            <SeasonTag size="sm" season={card.Season} highlight={highlightSeason} />
                          </div>
                        </>
                      )}
                      {(pickedView === "advanced" || pickedView === "form") && (hasCostRestriction || alwaysShowCost) && (
                        <div className={"flex justify-center items-center w-6 h-6"}>
                          <SorareScoreAverage avg={entry.cost} />
                        </div>
                      )}
                      {pickedView === "advanced" && (
                        <>
                          <div className={"flex flex-row gap-1"}>
                            <div className={"flex flex-row justify-center self-center items-center gap-1"}>
                              <span className={"whitespace-nowrap text-xs font-semibold text-on-surface-variant"}>% Pick</span>
                              <p className={"text-xs font-semibold"} style={{ color: avgColor }}>
                                {pickPctAvg < 0 ? "NA" : pickPctAvg.toFixed(0) + "%"}
                              </p>
                            </div>
                          </div>
                          <div className={"flex flex-row gap-1"}>·</div>
                          <div className={"flex flex-row gap-1"}>
                            <div className={"flex flex-row justify-center self-center items-center gap-1"}>
                              <span className={"whitespace-nowrap text-xs font-semibold text-on-surface-variant"}>% Div</span>
                              <p className={"text-xs font-semibold"} style={{ color: divColor }}>
                                {entry.division_stats.rate < 0 ? "NA" : entry.division_stats.rate.toFixed(0) + "%"}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {pickedView === "form" && (
                        <>
                          <div className={"flex flex-row gap-1"}>·</div>
                          <div className={"flex flex-row justify-center h-full items-center"}>
                            <PlayedAsStarterPct lX={pickedAverage} stats={so5ScoreDetailedWithPickedAvg} />
                          </div>
                        </>
                      )}
                    </div>
                    <div className={"flex flex-row items-center gap-0.5 pr-2"}>{addToLineupContent}</div>
                  </div>
                </div>
              );
            })}
          {loading && (players === undefined || players.length === 0) && (
            <>
              {Array.from({ length: placeholderAmount }).map((_, i) => (
                <Placeholder key={i} />
              ))}
            </>
          )}
        </>
      </div>
      {displayedPlayers.length === 0 && !loading && !(pickedView === "form" && !t2OrAbove(props.user.tier)) && (
        <div className={"flex flex-col justify-center w-full bg-surface-container text-center py-8"}>
          <div className={"flex flex-col justify-center"}>
            <div className={"m-auto mb-6"}>
              <NoCardResultsImage />
            </div>
            <p className={"text-lg font-headers text-center text-on-surface font-bold mb-2"}>No card with such characteristics</p>
            <p className={"text-md font-normal text-center text-on-surface-variant font-semibold"}>
              Try to change the filters, the display preferences or choose another position.
            </p>
          </div>
        </div>
      )}
      {pickedView === "form" && !t2OrAbove(props.user.tier) && (
        <div className={"flex flex-col justify-center w-full bg-surface-container rounded text-center py-8 px-3"}>
          <div className={"m-auto"}>
            <p className={"text-md font-headers text-center text-on-surface-variant font-bold"}>Need Star membership</p>
          </div>
        </div>
      )}
    </div>
  );
});

const Header = (props) => {
  const { label, tip, sortable, sorted, adjusted, onboarding } = props;
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tip]);
  let adjustedElement = adjusted ? <span className={"text-primary"}>•</span> : null;
  return (
    <div
      title={tip}
      onClick={sortable ? props.onSort : undefined}
      className={`${
        sortable ? "cursor-pointer hover:text-primary hover:text-opacity-90" : ""
      } flex justify-center items-center px-1.5 py-3 text-center font-semibold ${sorted ? "text-primary" : ""}`}
    >
      <div className={`flex flex-col gap-1 ${onboarding ? onboarding : ""}`}>
        <span>
          {label}
          {adjustedElement}
        </span>
        {sortable && sorted && (
          <span className={"flex flex-row justify-center gap-2 items-center"}>
            <IconCarretFilled className={`h-3 w-3 fill-primary ${sorted == "asc" ? "transform rotate-180" : ""}`} />
          </span>
        )}
      </div>
    </div>
  );
};
