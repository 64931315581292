import React, { useEffect, useState } from "react";
import PlayerRankingsHeader from "../../img/rewards_header.png";
import SelectSearch from "react-select-search";
import { Link, useHistory, useLocation } from "react-router-dom";
import { withUser } from "../../userContext";
import SportsPicker from "../util/sportsPicker";
import { useQuery } from "../util/useQuery";
import { sorareFootball } from "../util/sports";
import CardsSection from "./newRewardsCardsSection";
import BoxesSection from "./newRewardsBoxesSection";
import SDLoading from "../util/SDLoading";

function NewRewardsPage(props) {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [gws, setGws] = useState([]);
  const [gw, setGw] = useState("");
  const [activeTab, setActiveTab] = useState(props.match?.params?.tab || "cards");

  const sport = query.get("sport") || sorareFootball;

  const embedded = props.embedded !== undefined ? props.embedded : false;

  useEffect(() => {
    if (sport !== "sr_football") {
      setActiveTab("cards");
    }

    const setupComponent = () => {
      props
        .fetch("/apiv2/gw/list?sport=" + sport)
        .then((response) => response.json())
        .then((res) => {
          let newGws = [];
          res.ids.map((id, idx) => {
            newGws.push({ name: res.names[idx], value: id });
          });
          setGws(newGws);
          setGw(res.ids[0]);
          setLoading(false);
        });
    };

    setLoading(true);
    setupComponent();
  }, [sport]);

  useEffect(() => {
    if (!embedded && props.match?.params?.tab !== undefined) {
      setActiveTab(props.match?.params?.tab);
    }
  }, [props.match?.params?.tab]);

  const tabs = [
    {
      link: `/rewards/sections/cards?sport=${sport}`,
      key: "cards",
      label: "Cards",
    },
    {
      link: `/rewards/sections/boxes?sport=${sport}`,
      key: "boxes",
      label: "Boxes",
    },
  ];

  const inactiveClass = "text-md py-2 inline-flex items-center mt-1 font-medium text-white hover:text-opacity-80 focus:outline-none";
  const activeClass = "inline-flex items-center text-md mt-1 border-b-4 border-primary py-2 font-medium focus:outline-none";

  return (
    <div>
      <div className="z-20">
        <div
          className="max-w-screen relative opacity-100 z-20"
          style={{
            backgroundImage: "url('" + PlayerRankingsHeader + "')",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="relative z-20 pt-6 pb-16 align-middle flex flex-col justify-between h-full w-full">
            <div className="flex flex-col items-center md:flex-row mx-16 gap-4">
              <p className={"text-5xl font-bold font-headers text-center md:text-left text-white z-10"}>Rewards</p>
              <div className={"flex flex-col lg:flex-row w-full items-center md:items-end justify-end z-20 gap-4"}>
                <div>
                  <SportsPicker
                    sport={sport}
                    change={(s) => {
                      query.set("sport", s);
                      history.replace({
                        pathname: location.pathname,
                        search: query.toString(),
                      });
                    }}
                  />
                </div>
                <div className={"justify-end w-64 z-20"}>
                  <SelectSearch className={"select-search z-20"} value={gw} onChange={(v) => setGw(v)} options={gws} closeOnSelect={true} />
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-transparent-inverse-surface-low bg-opacity-10 w-full">
              <div className="w-11/12 mx-auto 4xl:w-10/12 4.5xl:w-9/12 text-white text-md">
                <nav className="sm:flex sm:flex-row grid grid-cols-2 gap-x-8">
                  {tabs
                    ?.filter((e) => e.key)
                    .map((e) => {
                      const isBoxesTabOnUSSport = e.key === "boxes" && sport !== "sr_football";
                      const boxesTabDisabledOnUSSports = "pointer-events-none opacity-50 text-outline";
                      return (
                        <Link
                          key={e.link}
                          to={e.link}
                          aria-disabled={sport !== "sr_football"}
                          className={isBoxesTabOnUSSport ? boxesTabDisabledOnUSSports : ""}
                        >
                          <button className={activeTab === e.key ? activeClass : inactiveClass}>{e.label}</button>
                        </Link>
                      );
                    })}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="py-10">
          <SDLoading />
        </div>
      ) : (
        <>
          {activeTab === "cards" && <CardsSection sport={sport} gameWeek={gw} fetchApi={props.fetch} user={props.user} />}
          {activeTab === "boxes" && <BoxesSection sport={sport} gameWeek={gw} fetchApi={props.fetch} user={props.user} />}
        </>
      )}
    </div>
  );
}

export default withUser(NewRewardsPage);
