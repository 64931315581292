import React, { useState } from "react";
import { withUser } from "../../userContext";
import { scarcities_objects_not_all } from "../util/scarcities";
import clsx from "clsx";
import { capitalize } from "../util/capitalize";
import { split_price_classic, split_price_in_season } from "../util/splits";
import { Dropdown } from "../util/dropdown";
import { formatValuationOriginV2 } from "../util/formatValuationOrigin";
import { formatFloorPrice, formatValuationPrice } from "../util/formatMoney";

export const PlayerPriceSplit = withUser(({ split, ...props }) => {
  const preferredUnit = props.user.preferredUnit;

  const [scarcityFilter, setScarcityFilter] = useState(props.user.preferredScarcity || "LIMITED");

  const { scarcityColor, selectedSplit } = getSelectedSplit(scarcityFilter, split);

  return (
    <div>
      <div className="bg-surface-container-highest rounded-t-2xl grid grid-cols-2 p-5 text-on-surface">
        <div className="space-y-1">
          <p className="text-lg font-bold">Cards</p>
          <p className="text-sm font-semibold text-on-surface-variant">
            {capitalize(scarcityFilter)} · x{selectedSplit["ALL"].supply}
          </p>
        </div>
        <div>
          <div>
            <Dropdown
              displayOptions={{
                roundedTopFocus: "rounded-t-2xl",
                rounded: "rounded-3xl",
                roundedBottomFocus: "rounded-b-xl",
                width: "100%",
              }}
              selected={scarcityFilter}
              options={scarcities_objects_not_all}
              onChange={(v) => setScarcityFilter(v)}
            />
          </div>
        </div>
      </div>
      <div className="bg-transparent-surface-container-high bg-opacity-70 px-5 py-2 space-y-1 text-on-surface">
        <span className={clsx("text-base font-bold", scarcityColor)}>
          New season cards · x{selectedSplit[split_price_in_season].supply}
        </span>
        <div className="grid grid-cols-3">
          <div className="space-y-1">
            <p className="text-xs font-semibold text-on-surface-variant">Floor price</p>
            <p className={clsx("text-sm font-bold", scarcityColor)}>
              {formatFloorPrice(selectedSplit[split_price_in_season].bestMarketPrice, preferredUnit)}
            </p>
          </div>
          <div className="col-span-2 space-y-1">
            <p className="text-xs font-semibold text-on-surface-variant">
              {formatValuationOriginV2(selectedSplit[split_price_in_season].valuation.origin)}
            </p>
            <p className="text-sm font-bold">{formatValuationPrice(selectedSplit[split_price_in_season].valuation, preferredUnit)}</p>
          </div>
        </div>
      </div>
      <div className="bg-transparent-surface-container-high bg-opacity-70 rounded-b-2xl px-5 py-4 space-y-1 text-on-surface">
        <p className="text-base font-bold text-on-surface-variant">Classic cards · x{selectedSplit[split_price_classic].supply}</p>
        <div className="grid grid-cols-3">
          <div className="space-y-1">
            <p className="text-xs font-semibold text-on-surface-variant">Floor price</p>
            <p className="text-sm font-bold">{formatFloorPrice(selectedSplit[split_price_classic].bestMarketPrice, preferredUnit)}</p>
          </div>
          <div className="col-span-2 space-y-1">
            <p className="text-xs font-semibold text-on-surface-variant">
              {formatValuationOriginV2(selectedSplit[split_price_classic].valuation.origin)}
            </p>
            <p className="text-sm font-bold">{formatValuationPrice(selectedSplit[split_price_classic].valuation, preferredUnit)}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

const getSelectedSplit = (scarcityFilter, split) => {
  let scarcityColor = "";
  let selectedSplit = {};
  switch (scarcityFilter) {
    case "LIMITED":
      scarcityColor = "text-scarcity-limited-on-surface-container";
      selectedSplit = split["LIMITED"];
      break;
    case "RARE":
      scarcityColor = "text-scarcity-rare-on-surface-container";
      selectedSplit = split["RARE"];
      break;
    case "SUPER RARE":
      scarcityColor = "text-scarcity-super-rare-on-surface-container";
      selectedSplit = split["SUPER RARE"];
      break;
    case "UNIQUE":
      scarcityColor = "text-scarcity-unique-on-surface-container";
      selectedSplit = split["UNIQUE"];
      break;
  }

  return { scarcityColor, selectedSplit };
};
