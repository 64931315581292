import React from "react";
import PlayerAvatar from "../util/playerAvatar";

function GameCommentaryMessage(props) {
  const comment = props.comment;
  let showAvatar1 = false;
  let showAvatar2 = false;
  const basicParent = "pb-2 border-b border-grey-e5 space-y-1";
  const basicChild = "flex flex-row space-x-2 text-base";
  const timeStyle = "font-bold text-sm text-textGrey3 self-center";
  const typeStyle = "font-semibold";
  const commentStyle = "text-sm self-center";
  let type = comment.type;
  if (comment.type === "end 14") {
    type = "Final whistle!";
  }
  if (comment.type === "end 2") {
    type = "End of 2nd half";
  }
  if (comment.type === "goal") {
    type = "GOAAAAAAAALLLLLLL!";
  }
  if (comment.type === "attempt saved") {
    type = "Shot saved";
  }
  if (comment.type === "corner") {
    type = "Corner kick";
  }
  if (comment.type === "miss") {
    type = "Shot missed";
  }
  if (comment.type === "substitution") {
    type = "Substitution";
  }
  if (comment.type === "attempt blocked") {
    type = "Shot blocked";
  }
  if (comment.type === "yellow card") {
    type = "Yellow card";
  }
  if (comment.type === "red card") {
    type = "Red card";
  }
  if (comment.type === "end delay") {
    type = "Play resumes";
  }
  if (comment.type === "start delay") {
    type = "Stoppage";
  }
  if (comment.type === "free kick lost") {
    type = "Foul";
  }
  if (comment.type === "free kick won") {
    type = "Free kick won";
  }
  if (comment.type === "offside") {
    type = "Offside";
  }
  if (comment.type === "start") {
    type = "Half starts";
  }
  if (comment.type === "end 1") {
    type = "1st half ends";
  }
  if (comment.type === "lineup") {
    type = "Lineups available";
  }
  if (comment.type === "penalty lost") {
    type = "Penalty conceded";
  }
  if (comment.type === "penalty won") {
    type = "Penalty won";
  }
  if (comment.type === "penalty goal") {
    type = "PENALTY SCORED!";
  }
  showAvatar1 = comment.playerRef1.id !== "";
  showAvatar2 = comment.playerRef2.id !== "";
  return (
    <div className={basicParent}>
      <div className={basicChild}>
        {comment.time !== " " && comment.type !== "lineup" && <p className={timeStyle}>{comment.time}</p>}
        <p className={typeStyle}>{type}</p>
      </div>
      <div className={"flex flex-row space-x-2 relative"}>
        {showAvatar1 && (
          <div
            className={
              showAvatar1 && showAvatar2
                ? "relative flex flex-row justify-center mr-3"
                : "relative flex flex-row justify-center pl-1.5 pr-1.5"
            }
          >
            {showAvatar1 && (
              <div className={"self-center"}>
                <PlayerAvatar
                  player={{
                    Avatar: comment.playerRef1?.avatar,
                    PlayerId: comment.playerRef1?.id,
                  }}
                />
              </div>
            )}
            {showAvatar2 && (
              <div className={"absolute flex flex-row justify-center self-end bottom-1 right-0 -mr-3 rounded-full"}>
                <PlayerAvatar
                  size={"w-8 h-8"}
                  imgSize={"w-full h-full"}
                  player={{
                    Avatar: comment.playerRef2?.avatar,
                    PlayerId: comment.playerRef2?.id,
                  }}
                />
              </div>
            )}
          </div>
        )}

        <p className={commentStyle}>{comment.comment}</p>
      </div>
    </div>
  );
}

export default GameCommentaryMessage;
