import React from "react";
import { baseballGwScale, baseballScale, basketballScale } from "./scales";

function NBAProjectedScore(props) {
  let s = props.score;
  let label = props.label;
  let bg = "text-amazing-score border-amazing-score";
  let scale = basketballScale;
  if (props.scale === "baseball_gw") {
    scale = baseballGwScale;
  }
  if (props.scale === "baseball") {
    scale = baseballScale;
  }
  if (s < scale[4]) {
    bg = "text-great-score border-great-score";
  }
  if (s < scale[3]) {
    bg = "text-good-score border-good-score";
  }
  if (s < scale[2]) {
    bg = "text-ok-score border-ok-score";
  }
  if (s < scale[1]) {
    bg = "text-bad-score border-bad-score";
  }
  if (s < scale[0]) {
    bg = "text-horrible-score border-horrible-score";
  }
  let size = "w-8 h-8";
  if (props.size) {
    size = props.size;
  }
  let font = "text-sm";
  if (props.font) {
    font = props.font;
  }
  let fixed = 0;
  if (props.fixed) {
    fixed = props.fixed;
  }
  let bold = "font-bold";
  if (props.bold) {
    bold = props.bold;
  }
  return (
    <div className={"flex flex-col items-center space-y-1 " + font}>
      {label !== undefined && <p className={"text-xxs text-textGrey2"}>{label}</p>}
      <div
        data-tip={props.tip ? "Projected score (powered by RotoWire)" : undefined}
        className={"flex flex-col justify-center self-center " + "items-center " + size + " rounded-full border " + bg}
      >
        <p className={"flex justify-center self-center " + bold}>{s.toFixed(fixed)}</p>
      </div>
    </div>
  );
}

export default NBAProjectedScore;
