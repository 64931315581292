import React, { Component } from "react";
import { Text, Box, Heading } from "grommet";
import Countdown from "react-countdown-now";
import ManagerLink from "../manager/managerLink";

class OfferDetails extends Component {
  render() {
    const info = this.props.offer;
    const offer = info.offer;
    let status = offer.Status;
    let receiver = info.manager_receiving.Nickname;
    if (receiver === "") {
      receiver = "an unknown user";
    }
    let label = "Offer status: ";
    if (status === "ONGOING") {
      label = "Offer ends in: ";
      status = <Countdown date={offer.EndingAt} />;
    } else if (status === "ENDED") {
      label = "Offer ended without buyer.";
      status = "";
    } else if (status === "ACCEPTED") {
      label = (
        <Text>
          Offer was accepted by <ManagerLink manager={info.manager_receiving} />
          {" on"} {new Date(offer.AcceptationDate).toLocaleString()}
        </Text>
      );
      status = "";
    }
    return (
      <Box margin={{ top: "small" }}>
        <Text>
          Offer created on {new Date(offer.CreationDate).toLocaleString()} by <ManagerLink manager={info.manager_sending} />
        </Text>
        <Text weight={"bold"}>Offer price: {offer.ReceiveAmountInWei + " Ξ"}</Text>
        <Heading level={"4"}>
          {label} {status}
        </Heading>
      </Box>
    );
  }
}

export default OfferDetails;
