import SelectSearch, { fuzzySearch } from "react-select-search";
import { t1OrAbove } from "./handleSubscriptionTier";
import React, { useEffect } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { sorareBasketball, sorareFootball } from "./sports";
import BoxWithToggle from "./boxWithToggle";

export const status = ["All status", "Starter", "Substitute", "Reserve", "Newcomer", "Not covered", "No club"];
export const playing_status_objects_not_all = [
  { value: "starter", name: "Starter" },
  { value: "substitute", name: "Substitute" },
  { value: "not_playing", name: "Reserve" },
  {
    value: "Newcomer",
    name: "Newcomer",
  },
  { value: "Not covered", name: "Not covered" },
  { value: "No club", name: "No club" },
];
export const playing_status_objects = [{ value: "all", name: "All playing status" }, ...playing_status_objects_not_all];
export const playing_status_labels = {
  starter: "Starter",
  substitute: "Substitute",
  not_playing: "Reserve",
  Newcomer: "Newcomer",
  "Not covered": "Not covered",
  "No club": "No club",
  "Key Player": "Key Player",
  "Role Player": "Role Player",
  "Reserve Player": "Reserve Player",
};
export const basketball_playing_status_objects_not_all = [
  { value: "Key Player", name: "Key Player" },
  { value: "Role Player", name: "Role Player" },
  { value: "Reserve Player", name: "Reserve Player" },
];
export const basketball_playing_status_objects = [
  { value: "all", name: "All playing status" },
  ...basketball_playing_status_objects_not_all,
];

export const PlayerStatusSelector = (props) => {
  const { playingStatus, onChange, multiple, tier } = props;
  const placeholder = props.placeholder || "Filter by playing status";
  const className = props.className || "select-search border border-grey-f2 rounded";
  const premiumNeeded = !t1OrAbove(tier) && (props.sport === undefined || props.sport === sorareFootball);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [playingStatus]);

  let options = playing_status_objects;
  if (multiple) {
    options = playing_status_objects_not_all;
  }
  if (props.sport === sorareBasketball) {
    options = basketball_playing_status_objects_not_all;
  }

  if (!premiumNeeded) {
    return (
      <SelectSearch
        options={options}
        className={className}
        value={playingStatus}
        multiple={multiple}
        closeOnSelect={false}
        onChange={onChange}
        filterOptions={fuzzySearch}
        placeholder={placeholder}
        printOptions={"on-focus"}
        disabled={premiumNeeded}
      />
    );
  }
  return (
    <div className={`h-full bg-focus shadow py-2 rounded-md`}>
      <ReactTooltip id={"player-status-selector-component"} />
      <BoxWithToggle
        label={placeholder}
        isActive={false}
        disabled={premiumNeeded}
        disabledTip={"Star membership is needed"}
        className={"h-full"}
      />
    </div>
  );
};

export const PlayingStatus = (props) => {
  let status = "";
  if (props.status === "starter") {
    status = "Starter";
  } else if (props.status === "not_playing") {
    status = "Reserve";
  } else if (props.status === "substitute") {
    status = "Substitute";
  } else {
    status = props.status;
  }

  if (props.player?.TeamId === "retired") {
    status = "No club";
  }
  return <span>{status}</span>;
};
