import { ReactComponent as HeartIcon } from "../../img/icons-heart.svg";
import React, { useState } from "react";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { labelFromNumber } from "../util/formatNumber";

const WatchlistFollowingButton = (props) => {
  const id = props.id;
  const withLabel = props.withLabel || false;
  const labelSize = props.labelSize || "sm";
  const labelColor = props.labelColor || "textGrey4";
  const enabled = props.enabled || false;
  const size = props.size || 8;
  const connectedUserIsOwner = props.connectedUserIsOwner || false;
  const { onUnfollow, onFollow } = props;
  const [nbFollowers, setNbFollowers] = useState(props.nbFollowers || 0);
  const [isFollowed, setIsFollowed] = useState(props.isFollowed || 0);
  if (!id) {
    return null;
  }

  const followWatchlist = () => {
    props
      .fetch(`/apiv2/watchlists/id/${id}/follow`, { method: "POST" })
      .then((response) => response.json())
      .then(async (res) => {
        setIsFollowed(true);
        const nbNewFollowers = nbFollowers + 1;
        setNbFollowers(nbNewFollowers);
        if (onFollow) {
          onFollow(id, nbNewFollowers);
        }
      })
      .catch(errorCatcher());
  };

  const unfollowWatchlist = () => {
    props
      .fetch(`/apiv2/watchlists/id/${id}/unfollow`, { method: "POST" })
      .then((response) => response.json())
      .then(async (res) => {
        setIsFollowed(false);
        const nbNewFollowers = nbFollowers - 1;
        setNbFollowers(nbNewFollowers);
        if (onUnfollow) {
          onUnfollow(id, nbNewFollowers);
        }
      })
      .catch(errorCatcher());
  };

  const title = enabled
    ? connectedUserIsOwner
      ? "Watchlists of your own can't be followed"
      : isFollowed
      ? "Click to unfollow"
      : "Click to follow"
    : undefined;

  return (
    <div className={"flex flex-row flex-wrap"}>
      <HeartIcon
        className={`m-auto inline ${isFollowed ? "fill-heart" : `stroke-${labelColor} fill-[transparent]`} ${
          enabled && !connectedUserIsOwner ? "cursor-pointer " : "opacity-50"
        } w-${size} h-${size}`}
        title={title}
        onClick={enabled && !connectedUserIsOwner ? (isFollowed ? () => unfollowWatchlist() : () => followWatchlist()) : undefined}
      />

      {withLabel && (
        <span
          title={`${nbFollowers} followers`}
          className={`m-auto text-${labelColor} text-${labelSize} ${isFollowed ? "font-semibold" : "font-medium"}`}
        >
          {labelFromNumber(nbFollowers)}
        </span>
      )}
    </div>
  );
};

export default withUser(WatchlistFollowingButton);
