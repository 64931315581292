import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import { useQuery } from "../util/useQuery";
import { sorareBaseball } from "../util/sports";
import { ReactTooltip } from "../util/tooltip.js";
import { errorCatcher } from "../util/errors";
import SelectSearch from "react-select-search";
import BoxWithToggle from "../util/boxWithToggle";
import GreenButton from "../util/greenButton";
import SDLoading from "../util/SDLoading";
import RotowireCredit from "../util/rotowireCredit";
import { all_baseball_positions_objects } from "../util/positions";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import ordinal_suffix_of from "../util/ordinalNumber";
import NewCardIcon from "../util/newCardIcon";
import AllNbaLineups from "./allNbaLineups";
import BaseballLineupBuilderTable from "./baseballLineupBuilderTable";
import BaseballLineupBuilderLineup from "./baseballLineupBuilderLineup";

function BaseballLineupBuilder(props) {
  const query = useQuery();
  const [divisionValue, setDivisionValue] = useState("limited-all-star");
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [division, setDivision] = useState(undefined);
  const [scarcity, setScarcity] = useState("all");
  const [position, setPosition] = useState("Starting Pitcher");
  const [scarcities, setScarcities] = useState([
    { name: "All scarcities", value: "all" },
    {
      value: "LIMITED",
      name: "Limited",
    },
  ]);
  const [playingStatus, setPlayingStatus] = useState("all");
  const [lineup, setLineup] = useState([null, null, null, null, null, null, null]);
  const [canPick, setCanPick] = useState(true);
  const [pickedCards, setPickedCards] = useState([]);
  const [pickedPlayers, setPickedPlayers] = useState(false);
  const [eligiblePlayers, setEligiblePlayers] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [divisionStats, setDivisionStats] = useState(undefined);
  const [prizePool, setPrizePool] = useState([]);
  const [updatingCommons, setUpdatingCommons] = useState(false);
  const [l5Filter, setL5Filter] = useState({
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  });
  const [l15Filter, setL15Filter] = useState({
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  });
  const [l40Filter, setL40Filter] = useState({
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  });
  const [refresh, setRefresh] = useState(0);
  const [refresh2, setRefresh2] = useState(0);
  const [concept, setConcept] = useState(false);
  const [onlyMyPlayers, setOnlyMyPlayers] = useState(false);
  const [powerAdj, setPowerAdj] = useState(false);
  const [pickedPosition, setPickedPosition] = useState("Starting Pitcher");
  const [suggestion, setSuggestion] = useState(false);
  const [suggestionOptions, setSuggestionOptions] = useState({
    questionable: false,
    injured: false,
    picked: false,
    discarded: [],
    gameImportance: 2,
  });

  const getUsername = () => {
    let u = props.username;
    if (!u) {
      u = query.get("manager");
      if (!u) {
        u = props.user.sorareSlug;
      }
    }
    return u;
  };

  const username = getUsername();

  useEffect(() => {
    if (props.gw > 0 && props.sport === sorareBaseball && props.draftId !== "") {
      getDivisions(props.gw);
      getPlayers(props.gw, divisionValue);
      loadLineup(props.gw, divisionValue);
      getCardsPicked(props.gw);
    }
  }, [props.draftId, props.gw]);

  const getDivisions = (gw) => {
    props
      .fetch("/apiv2/gw/divisionsFull/" + gw + "?sport=" + sorareBaseball)
      .then((response) => response.json())
      .then((res) => {
        let divisions = [];
        res?.map((d) => {
          divisions.push({ name: d.displayName, value: d.divisionType });
        });
        setDivisionOptions(divisions);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          ReactTooltip.rebuild();
        }),
      );
  };

  const getPlayers = (gw, division, sc, c, p) => {
    let low10 = 0;
    let high10 = 100;
    let low40 = 0;
    let high40 = 100;
    let lowSeason = 0;
    let highSeason = 100;
    if (l5Filter.enabled) {
      low10 = l5Filter.scoreRange[0];
      high10 = l5Filter.scoreRange[1];
    }
    if (l15Filter.enabled) {
      low40 = l15Filter.scoreRange[0];
      high40 = l15Filter.scoreRange[1];
    }
    if (l40Filter.enabled) {
      lowSeason = l40Filter.scoreRange[0];
      highSeason = l40Filter.scoreRange[1];
    }
    if (c === undefined) {
      c = concept;
    }
    if (p === undefined) {
      p = position;
    }
    props
      .fetch(
        "/apiv2/decision/mlbLineupBuilder?division=" +
          division +
          "&gw=" +
          gw +
          "&manager=" +
          getUsername() +
          "&low10" +
          low10 +
          "&high10=" +
          high10 +
          "&low40=" +
          low40 +
          "&high40=" +
          high40 +
          "&lowSeason=" +
          lowSeason +
          "&highSeason=" +
          highSeason +
          "&scarcity=" +
          scarcity +
          "&position=" +
          p +
          "&playingStatus=" +
          playingStatus +
          "&concept=" +
          c,
      )
      .then((response) => response.json())
      .then((res) => {
        if (res.results === null) {
          setPlayers([]);
        } else {
          setPlayers(res.results);
        }
        setDivision(res.division);
        setDivisionStats(res.divisionStats);
        setPrizePool(res.prizePool);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          ReactTooltip.rebuild();
        }),
      );
  };

  const getCardsPicked = (gw) => {
    props
      .fetch("/apiv2/decision/mlbCardsPicked?gw=" + gw + "&manager=" + getUsername() + "&draftId=" + props.draftId)
      .then((response) => response.json())
      .then((res) => {
        if (res !== null) {
          setPickedCards(res);
        } else {
          setPickedCards([]);
        }
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          ReactTooltip.rebuild();
        }),
      );
  };

  const changeDivision = (div) => {
    setDivisionValue(div);
    let newScarcities = [];
    if (div.includes("limited")) {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        { value: "LIMITED", name: "Limited" },
      ];
    } else if (div.includes("super-rare")) {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        {
          value: "SUPER RARE",
          name: "Super Rare",
        },
      ];
    } else if (div.includes("rare-pro")) {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        {
          value: "RARE",
          name: "Rare",
        },
        { value: "LIMITED", name: "Limited" },
      ];
    } else if (div.includes("rare-")) {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        {
          value: "RARE",
          name: "Rare",
        },
        { value: "LIMITED", name: "Limited" },
      ];
    } else if (div === "unique-champion") {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        {
          value: "SUPER RARE",
          name: "Super Rare",
        },
        { value: "UNIQUE", name: "Unique" },
      ];
    } else if (div === "common-contender" || div === "common-champion" || div.includes("season-of-giving") || div.includes("common")) {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        { value: "COMMON", name: "Common" },
      ];
    } else if (div === "deck-the-halls") {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        {
          value: "LIMITED",
          name: "Limited",
        },
        { value: "RARE", name: "Rare" },
        {
          value: "SUPER RARE",
          name: "Super Rare",
        },
        { value: "UNIQUE", name: "Unique" },
      ];
    } else if (div === "sandlot") {
      newScarcities = [
        { name: "All scarcities", value: "all" },
        {
          value: "LIMITED",
          name: "Limited",
        },
        { value: "RARE", name: "Rare" },
        {
          value: "SUPER RARE",
          name: "Super Rare",
        },
        { value: "UNIQUE", name: "Unique" },
      ];
    }
    resetLineup();
    getPlayers(props.gw, div);
    getCardsPicked(props.gw);
    setScarcities(newScarcities);
    setScarcity("all");
    loadLineup(props.gw, div);
  };

  let divDisplayValue = "";
  divisionOptions.map((l) => {
    if (l.value === divisionValue) divDisplayValue = l.name;
  });

  const positionPath = ["Starting Pitcher", "Relief Pitcher", "Flex", "Corner Infielder", "Middle Infielder", "Outfielder", "Extra Hitter"];

  const pickCard = (c) => {
    let indexOf = positionPath.indexOf(pickedPosition);
    let index = indexOf;
    let newLineup = [...lineup];
    let newPickedCards = [...pickedCards];
    if (lineup[index] !== undefined && lineup[index] !== null) {
      let target = lineup[index].card.TokenId;
      console.log("target", target);
      newPickedCards = newPickedCards.filter((c, i) => {
        return c !== target;
      });
      console.log(newPickedCards);
    }
    newLineup[index] = c;
    setLineup(newLineup);
    checkLineup(newLineup);
    newPickedCards.push(c.card.TokenId);
    setPickedCards(newPickedCards);
    if (indexOf <= 5) {
      index = indexOf + 1;
      getPlayers(props.gw, divisionValue, undefined, undefined, positionPath[index]);
      setPickedPosition(positionPath[index]);
      setPosition(positionPath[index]);
    }
    setRefresh2(Math.random());
  };

  const removeCard = (position) => {
    let newLineup = [...lineup];
    let indexOf = positionPath.indexOf(position);
    let target = newLineup[indexOf].card.TokenId;
    newLineup[indexOf] = null;
    let newPickedCards = [...pickedCards];
    let index2 = -1;
    pickedCards.map((c, i) => {
      if (c === target) index2 = i;
    });
    if (index2 >= 0) {
      newPickedCards.splice(index2, 1);
      setPickedCards(newPickedCards);
    }
    setLineup(newLineup);
    checkLineup(newLineup);
  };

  const saveLineup = (l) => {
    if (l === undefined) {
      l = lineup;
    }
    props
      .fetch("/apiv2/decision/createMLBLineup", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          manager: getUsername(),
          division: divisionValue,
          gwNumber: props.gw,
          lineup: l,
          public: false,
          draftId: props.draftId,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setRefresh(Math.random());
        setErrorMsg("Lineup saved.");
        getCardsPicked(props.gw);
      })
      .catch(errorCatcher());
  };

  const changePosition = (p) => {
    getPlayers(props.gw, divisionValue, undefined, undefined, p);
    setPickedPosition(p);
    setPosition(p);
  };

  const loadLineup = (gw, div) => {
    gw = gw || props.gw;
    div = div || divisionValue;
    props
      .fetch("/apiv2/decision/loadMLBLineup?gw=" + gw + "&manager=" + getUsername() + "&division=" + div + "&draftId=" + props.draftId)
      .then((response) => response.json())
      .then((res) => {
        if (res.results !== null) {
          setSuggestion(false);
          if (res.results.length === 7) {
            let newLineup = [];
            res.results.map((r) => {
              let index = positionPath.indexOf(r.position);
              newLineup[index] = r;
            });
            setLineup(newLineup);
          } else {
            // fill with nulls
            let newLineup = [null, null, null, null, null, null, null];
            res.results.map((r) => {
              let index = positionPath.indexOf(r.position);
              newLineup[index] = r;
            });
            setLineup(newLineup);
          }
        } else {
          setLineup([null, null, null, null, null, null, null]);
        }
        getCardsPicked(props.gw);
      })
      .catch(errorCatcher());
  };

  const resetLineup = () => {
    setLineup([null, null, null, null, null, null, null]);
    setCanPick(true);
    setSuggestion(false);
    setErrorMsg("");
  };

  const deleteLineup = () => {
    saveLineup([null, null, null, null, null, null, null]);
    setLineup([null, null, null, null, null, null, null]);
    setSuggestion(false);
    setCanPick(true);
    setErrorMsg("");
  };

  const checkLineup = (l) => {
    let newCanPick = false;
    l.map((c) => {
      if (c === null) newCanPick = true;
    });
    setCanPick(newCanPick);
  };

  const checkPlayer = (p) => {
    let canPickPlayer = true;
    lineup.map((c) => {
      if (c !== null) {
        if (c.card.PlayerId === p) canPickPlayer = false;
      }
    });
    return canPickPlayer;
  };

  const checkCard = (target) => {
    let canPickCard = true;
    if (pickedCards !== null) {
      pickedCards.map((c) => {
        if (c !== null) {
          if (c === target) canPickCard = false;
        }
      });
    }
    return canPickCard;
  };

  const updateCommonCards = () => {
    setUpdatingCommons(true);
    props
      .fetch(`/apiv2/user/updateMLBCommonCards`, { method: "POST" })
      .then((response) => response.json())
      .then((res) => {
        setUpdatingCommons(false);
        getPlayers(props.gw, divisionValue);
      })
      .catch(errorCatcher());
  };

  const getSuggestion = (imp, disc, q, i, pick) => {
    imp = imp || suggestionOptions.gameImportance;
    disc = disc || suggestionOptions.discarded;
    if (q === undefined) q = suggestionOptions.questionable;
    if (i === undefined) i = suggestionOptions.injured;
    if (pick === undefined) pick = suggestionOptions.picked;
    let baseCardsQuery = "";
    let lineupIds = [];
    lineup.map((c, ind) => {
      if (c !== null) {
        baseCardsQuery += "&baseCards=" + c.card.TokenId + "," + positionPath[ind];
        lineupIds = [...lineupIds, c.card.TokenId];
      }
    });
    let excludedPlayersQuery = "";
    disc.map((p) => {
      excludedPlayersQuery += "&excludedPlayers=" + p;
    });
    let pickedCardsQuery = "";
    if (!pick) {
      pickedCards.map((c) => {
        if (lineupIds.indexOf(c) === -1) {
          pickedCardsQuery += "&pickedCards=" + c;
        }
      });
    }
    let queryConcept = "";
    if (concept) {
      queryConcept = "&concept=true";
    }
    props
      .fetch(
        "/apiv2/decision/optimalMLBLineup?gw=" +
          props.gw +
          "&division=" +
          divisionValue +
          "&manager=" +
          getUsername() +
          baseCardsQuery +
          queryConcept +
          excludedPlayersQuery +
          "&gamesImportance=" +
          imp +
          "&questionablePlayers=" +
          q +
          "&injuredPlayers=" +
          i +
          "&includePickedPlayers=" +
          pick +
          pickedCardsQuery,
      )
      .then((response) => response.json())
      .then((res) => {
        if (res !== null) {
          if (res.length === 7) {
            setLineup(res);
            let newPickedCards = [...pickedCards];
            res.map((r) => {
              if (r !== null) {
                newPickedCards.push(r.card.TokenId);
              }
            });
            setPickedCards(newPickedCards);
          } else {
            // fill with nulls
            let newLineup = [...res];
            let newPickedCards = [...pickedCards];
            res.map((r) => {
              if (r !== null) {
                newPickedCards.push(r.card.TokenId);
              }
            });
            setPickedCards(newPickedCards);
            let index = res.length;
            while (index < 7) {
              newLineup[index] = null;
              index++;
            }
            setLineup(newLineup);
          }
        }
      })
      .catch(errorCatcher());
  };

  let lineupScore = 0;
  let floorEth = 0;
  let floorUsd = 0;
  let floorEur = 0;
  let floorGbp = 0;
  let averageEth = 0;
  let averageUsd = 0;
  let averageEur = 0;
  let averageGbp = 0;
  let totalFloorEth = 0;
  let totalFloorUsd = 0;
  let totalFloorEur = 0;
  let totalFloorGbp = 0;
  if (lineup !== null && lineup !== undefined) {
    {
      lineup.map((entry) => {
        if (entry !== null) {
          if (entry.ownedCard.TokenId === "") {
            floorEth += entry.priceDetails.floor.Price;
            floorUsd += entry.priceDetails.floor.PriceUsd;
            floorEur += entry.priceDetails.floor.PriceEur;
            floorGbp += entry.priceDetails.floor.PriceGbp;
            averageEth += entry.priceDetails.latest.Average;
            averageUsd += entry.priceDetails.latest.UsdAverage;
            averageEur += entry.priceDetails.latest.EurAverage;
            averageGbp += entry.priceDetails.latest.GbpAverage;
          }
          totalFloorEth += entry.priceDetails.floor.Price;
          totalFloorUsd += entry.priceDetails.floor.PriceUsd;
          totalFloorEur += entry.priceDetails.floor.PriceEur;
          totalFloorGbp += entry.priceDetails.floor.PriceGbp;
          lineupScore += entry.average.AverageNoDnp;
        }
      });
    }
  }
  let floor = {
    Price: floorEth,
    PriceEur: floorEur,
    PriceGbp: floorGbp,
    PriceUsd: floorUsd,
  };
  let totalFloor = {
    Price: totalFloorEth,
    PriceEur: totalFloorEur,
    PriceGbp: totalFloorGbp,
    PriceUsd: totalFloorUsd,
  };
  let rt = {
    Average: averageEth,
    EurAverage: averageEur,
    GbpAverage: averageGbp,
    UsdAverage: averageUsd,
  };
  let mvpScore = 0;
  if (division?.hasMvp && lineup !== null && lineup !== undefined) {
    {
      lineup.map((entry) => {
        if (entry !== null && entry.average.AverageNoDnp > mvpScore) {
          mvpScore = entry.average.AverageNoDnp;
        }
      });
    }
  }
  if (mvpScore > 0) {
    lineupScore = lineupScore - mvpScore;
  }
  if (division?.cap === 0 && !division?.divisionType?.includes("no-cap")) {
    division.cap = 120;
  }
  let eligibleCap = division?.cap - lineupScore;

  return (
    <div className={"mx-4 space-y-4 pb-8"}>
      <div className={"flex flex-row justify-between"}>
        <div className={"flex flex-row justify-between z-10 space-x-4 w-8/12"}>
          <div className={"flex flex-row space-x-4 w-full"}>
            <div className={"w-5/12"}>
              <SelectSearch options={divisionOptions} value={divisionValue} onChange={(v) => changeDivision(v)} />
            </div>

            {/*<div className={"bg-white py-1.5 rounded-lg"}>
                            <BoxWithToggle label={"Concept players"} isActive={concept}
                                           setActive={() => {
                                               getPlayers(props.gw, divisionValue, undefined, !concept)
                                               setConcept(!concept)
                                           }}/>
                        </div>*/}
            <p className={"self-center font-semibold text-xs"}>{errorMsg}</p>
            {divDisplayValue.includes("Common") && (
              <div>
                <GreenButton label={"Update my common cards"} onClick={() => updateCommonCards()} />
              </div>
            )}
            {updatingCommons && (
              <div>
                <SDLoading size={"w-10"} />
              </div>
            )}
          </div>
        </div>

        <RotowireCredit credit={"Projections by"} />
      </div>
      <div className={"grid grid-cols-5 gap-4"}>
        <SelectSearch options={scarcities} value={scarcity} onChange={(v) => setScarcity(v)} />
        <SelectSearch options={all_baseball_positions_objects} value={position} onChange={(v) => setPosition(v)} />

        {/*<div className={"z-10"}>
                    <ScoreFilter l5={l5Filter}
                                 l15={l15Filter}
                                 l40={l40Filter}
                                 sport={sorareBaseball}
                                 setScoreFilters={(f) => {
                                     setL5Filter(f.l5)
                                     setL15Filter(f.l15)
                                     setL40Filter(f.l40)
                                 }}/>
                </div>*/}
        <div className={"bg-white py-1.5 rounded-lg"}>
          <BoxWithToggle label={"Show picked players"} isActive={pickedPlayers} setActive={() => setPickedPlayers(!pickedPlayers)} />
        </div>
        <div className={"bg-white py-1.5 rounded-lg"}>
          <BoxWithToggle label={"Power adjusted projections"} isActive={powerAdj} setActive={() => setPowerAdj(!powerAdj)} />
        </div>
        <div className={"flex flex-row justify-end"} onClick={() => getPlayers(props.gw, divisionValue)}>
          <GreenButton label={"Filter"} />
        </div>
      </div>

      <div className={"w-full space-x-0 space-y-8 lg:space-y-0 lg:space-x-8 flex lg:flex-row flex-col"}>
        <div
          className={
            "w-full lg:w-6/12 1.5xl:w-5/12 2xl:w-4/12 flex flex-row justify-start lg:justify-start lg:flex-col lg:space-y-4 space-y-0 space-x-8 lg:space-x-0"
          }
        >
          <div className={"space-y-2"}>
            <BaseballLineupBuilderLineup
              saveLineup={saveLineup}
              lineup={lineup}
              setLineup={setLineup}
              resetLineup={resetLineup}
              removeCard={removeCard}
              getSuggestion={getSuggestion}
              setSuggestion={setSuggestion}
              suggestion={suggestion}
              concept={concept}
              pickedPosition={pickedPosition}
              powerAdj={powerAdj}
              pickedCards={pickedCards}
              pickedPlayers={pickedPlayers}
              divId={divisionValue}
              divName={divDisplayValue}
              gw={props.gw}
              manager={username}
              changePosition={changePosition}
              deleteLineup={deleteLineup}
              division={division}
              divisionStats={divisionStats}
            />
            {concept && (
              <div className={"bg-brand-black grid grid-cols-3 py-4 rounded-lg px-6 gap-x-4"}>
                <div className={"flex flex-col items-center space-y-1"}>
                  <p className={"font-semibold text-white text-base"}>{formatBestMarketPrice(floor, props.user.preferredUnit)}</p>
                  <p className={"text-textGrey3 font-medium text-xs text-center px-6"}>Floor price of concept players</p>
                </div>
                <div className={"flex flex-col items-center space-y-1"}>
                  <p className={"font-semibold text-white text-base"}>{formatRealTimeAverage(rt, props.user.preferredUnit)}</p>
                  <p className={"text-textGrey3 font-medium text-xs text-center px-6"}>Recent price avg. of concept players</p>
                </div>
                <div className={"flex flex-col items-center space-y-1 bg-textGrey2 rounded-lg py-2"}>
                  <p className={"text-white font-medium text-xs text-center px-6"}>Lineup floor price</p>
                  <p className={"font-semibold text-brand text-base"}>{formatBestMarketPrice(totalFloor, props.user.preferredUnit)}</p>
                </div>
              </div>
            )}
          </div>

          {prizePool !== null && prizePool.length > 0 && (
            <div className={"w-6/12 1.5xl:w-full px-6 py-4 bg-white rounded-lg grid grid-rows-4 grid-flow-col gap-4"}>
              {prizePool?.map((s) => {
                let pp = s.prizePool;
                if (pp.scarcity.toLowerCase() === "common") {
                  return null;
                }
                let ranks = (
                  <p className={"text-sm font-semibold self-center"}>
                    {ordinal_suffix_of(pp.from)} <span className={"text-xs"}>TO</span> {ordinal_suffix_of(pp.to)}
                  </p>
                );
                if (pp.from === pp.to) {
                  ranks = <p className={"text-sm font-semibold self-center"}>{ordinal_suffix_of(pp.from)} place</p>;
                }
                return (
                  <div className={"flex flex-col space-y-1"}>
                    <div className={"flex flex-row justify-between space-x-2"}>
                      {ranks}
                      {pp.scarcity !== "" && (
                        <div className={"flex flex-row space-x-2"}>
                          <p className={"uppercase text-sm font-semibold text-textGrey2 self-center"}>TIER {pp.tier}</p>
                          <div className={"w-3"}>
                            <NewCardIcon scarcity={pp.scarcity.replace("_", " ").toLowerCase()} />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={"flex flex-row justify-between"}>
                      <span className={"text-xxs font-semibold uppercase text-textGrey2 self-center"}>Avg. range for tier</span>
                      <span className={"text-xs font-semibold self-center"}>
                        {s.startRange.toFixed(1)} - {s.endRange.toFixed(1)} pts
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={"w-7/12 1.5xl:w-8/12 h-85 overflow-auto "} id={"table_scroll"}>
          <BaseballLineupBuilderTable
            showPicked={pickedPlayers}
            eligibleCap={eligibleCap}
            refresh={refresh2}
            showEligible={eligiblePlayers}
            concept={concept}
            onlyMyPlayers={onlyMyPlayers}
            checkPlayer={checkPlayer}
            checkCard={checkCard}
            pickCard={pickCard}
            players={players}
            canPick={canPick}
            division={division}
            powerAdj={powerAdj}
          />
        </div>
      </div>
      <AllNbaLineups gw={props.gw} refresh={refresh} />
    </div>
  );
}

export default withUser(BaseballLineupBuilder);
