import React, { useEffect, useRef, useState } from "react";
import { MobileModale } from "./mobileModale";
import { useTailwindMediaQueries } from "./mediaQueries";
import { ReactComponent as ResetIcon } from "../../img/icons-reset.svg";
import { XIcon } from "@heroicons/react/outline";
import BoxWithToggle from "./boxWithToggle";
import { HelpModal } from "./helpModal";
import clsx from "clsx";

export const FloatingPanelFilters = (props) => {
  const mdQueries = useTailwindMediaQueries();
  const { children, title, buttonLabel, onReset, onOpenChange, onFilterChange, filters, Icon, withCloseIcon, withHelpIcon, helpText } =
    props;

  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const floatingPanelRef = useRef(null);

  useEffect(() => {
    if (!mdQueries["md"]) {
      return;
    }

    function handleClickOutside(event) {
      const buttonClicked = buttonRef.current.contains(event.target);
      const panelClicked = floatingPanelRef.current && floatingPanelRef.current.contains(event.target);

      // specific dom elements that must close or not the panel
      const isDiv = event.target.tagName === "DIV";
      const isReactDatepicker = event.target.classList.contains("react-datepicker__day");
      if (!buttonClicked && !panelClicked && isDiv && !isReactDatepicker) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const togglePanel = (event) => {
    setIsOpen(!isOpen);
  };

  const contentProps = {
    filters,
    onReset,
    onFilterChange,
    onOpenChange,
  };

  return (
    <div className={"relative h-full z-20 pointer-events-auto"}>
      <button
        ref={buttonRef}
        onClick={togglePanel}
        className={`h-full text-md my-auto bg-surface-container text-on-surface p-2 rounded-3xl border ${
          isOpen ? "shadow border-primary" : "border-surface-container"
        } flex flex-row items-center justify-between gap-2 hover:outline hover:outline-1 hover:outline-primary`}
      >
        <Icon className={`${isOpen ? "fill-primary" : "fill-on-surface"} h-5 w-5`} />
        {buttonLabel && mdQueries["md"] && (
          <div className={"my-auto"}>
            <span className={`text-left font-medium ${isOpen ? "text-primary" : ""}`}>{buttonLabel}</span>
          </div>
        )}
      </button>
      <div ref={floatingPanelRef}>
        {isOpen && (
          <div className={"hidden sm:block absolute bg-surface-container-high top-12 right-0 rounded-xl w-80 shadow-lg text-on-surface"}>
            <FloatingPanelHeader
              title={title}
              onReset={onReset}
              setIsOpen={setIsOpen}
              withCloseIcon={withCloseIcon}
              withHelpIcon={withHelpIcon}
              helpText={helpText}
            />
            {children(contentProps)}
          </div>
        )}
        <MobileModale open={!mdQueries["md"] && isOpen} close={togglePanel}>
          <div className={"bg-surface-container-high text-on-surface rounded-t-2xl text-left"}>
            <FloatingPanelHeader title={title} onReset={onReset} setIsOpen={setIsOpen} withCloseIcon />
            {children({ ...contentProps, maxSize: "max-h-[80vh]" })}
          </div>
        </MobileModale>
      </div>
    </div>
  );
};

const FloatingPanelHeader = ({ title, onReset, setIsOpen, withCloseIcon, withHelpIcon, helpText }) => {
  return (
    <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
      <div className={"flex flex-row justify-between px-4 py-4"}>
        <h3 className={"font-headers font-semibold m-0"}>{title}</h3>
        <div className={"flex flex-row gap-3"}>
          <ResetIcon className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface" onClick={onReset} />
          {withCloseIcon && (
            <XIcon
              className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
              aria-hidden="true"
              onClick={setIsOpen ? () => setIsOpen(false) : undefined}
            />
          )}
          {withHelpIcon && <HelpModal text={helpText} onConfirm={() => setIsOpen(false)} />}
        </div>
      </div>
    </div>
  );
};

const filterFloatingPanelContainer = "rounded-lg bg-surface-container py-2.5 px-1";

export const FloatingPanelBoxWithToggle = (props) => {
  const { field, filters, label, onFilterChange, disabled } = props;
  return (
    <div className={clsx(filterFloatingPanelContainer, disabled && "opacity-40 pointer-events-none select-none")}>
      <BoxWithToggle
        padding="px-2"
        color="text-on-surface"
        label={label}
        isActive={disabled ? false : filters[field]}
        setActive={() => onFilterChange(field, !filters[field])}
      />
    </div>
  );
};

export const FloatingPanelBoxWithToggleAndExpand = (props) => {
  const { label, children, isActive, onActiveChange } = props;
  return (
    <div className={filterFloatingPanelContainer}>
      <BoxWithToggle padding="px-2" color="text-on-surface" label={label} isActive={isActive} setActive={onActiveChange} />
      <div>{isActive && children}</div>
    </div>
  );
};
