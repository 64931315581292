import React, { useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import InfiniteScroll from "react-infinite-scroll-component";
import SDLoading from "../util/SDLoading";
import CirclePct from "../util/circlePct";
import { formatPrice } from "../util/formatMoney";
import { ReactTooltip } from "../util/tooltip.js";
import BoxWithToggle from "../util/boxWithToggle";
import NewCardLink from "../cards/newCardLink";
import SelectSearch from "react-select-search";
import MultiScarcityPicker from "../util/multiScarcityPicker";
import { t1OrAbove, t1OrBelow } from "../util/handleSubscriptionTier";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import { getPositionsBasedOnSport } from "../util/positions";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import Spinner from "../loader/spinner";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";

const CardPositionSelector = (props) => {
  const { positionFilter, onChange, tier } = props;
  const placeholder = "Filter by card position";
  const premiumNeeded = !t1OrAbove(tier);
  const options = getPositionsBasedOnSport("all");
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [positionFilter]);

  if (!premiumNeeded) {
    return (
      <SelectSearch
        placeholder={placeholder}
        options={options}
        printOptions={"on-focus"}
        value={positionFilter}
        onChange={onChange}
        multiple={true}
        closeOnSelect={false}
      />
    );
  }
  return (
    <div className={`h-full bg-focus py-2 rounded-md`}>
      <ReactTooltip id={"card-position-selector-component"} />
      <BoxWithToggle
        label={placeholder}
        isActive={false}
        disabled={premiumNeeded}
        disabledTip={"Star membership is needed"}
        className={"h-full"}
      />
    </div>
  );
};

const limitAPI = 30;

function ManagerSO5Utility(props) {
  const [stats, setStats] = useState({
    list: [],
    hasNext: false,
    offset: 0,
  });
  const [sort, setSort] = useState({ field: "nb_picked", order: "desc" });
  const [stillInGallery, setStillInGallery] = useState(true);
  const [filterString, setFilterString] = useState("");
  const [positionFilter, setPositionFilter] = useState([]);
  const [scarcityFilter, setScarcityFilter] = useState(["LIMITED", "RARE", "SUPER RARE", "UNIQUE"]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.manager && props.manager.Slug !== "") {
      getSO5Utility({});
    }
  }, [props.manager]);

  const manager = props.manager;

  const getSO5Utility = ({ o, s }) => {
    if (o === undefined) o = stats.offset;
    if (s === undefined) s = sort;
    setLoading(true);
    const params = new URLSearchParams();
    params.append("manager", manager.Slug);
    params.append("player_name_search", filterString);
    params.append("currently_owned", Boolean(stillInGallery).toString());
    params.append("sort_field", s.field);
    params.append("sort_order", s.order);
    positionFilter.forEach((pos) => {
      params.append("position", pos);
    });
    scarcityFilter.forEach((scarcity) => {
      params.append("scarcity", scarcity);
    });
    props
      .fetch("/apiv2/manager/so5utility?" + params.toString(), {
        method: "GET",
        headers: withJSONPagination(o, limitAPI),
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        res = res || [];
        const newStats = {
          list: o === 0 ? res : [...stats.list, ...res],
          hasNext: hasNext,
          offset: o + limitAPI,
        };
        setStats(newStats);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher())
      .finally(() => setLoading(false));
  };

  const sortData = (w) => {
    const newSort = {
      field: w,
      order: sort.field === w ? (sort.order === "asc" ? "desc" : "asc") : "desc",
    };
    setSort(newSort);
    getSO5Utility({ s: newSort, o: 0 });
  };

  const scarcityChange = (s) => {
    let newScarcities = [...scarcityFilter];
    if (newScarcities.indexOf(s) >= 0) {
      newScarcities = newScarcities.filter(function (value, index, arr) {
        return value !== s;
      });
    } else {
      newScarcities.push(s);
    }
    newScarcities.sort(function (a, b) {
      if (a === "LIMITED") {
        return -1;
      } else if (a === "UNIQUE") {
        return 1;
      } else if (b === "LIMITED") {
        return 1;
      } else if (b === "UNIQUE") {
        return -1;
      } else if (a === "RARE" && b === "SUPER RARE") {
        return -1;
      } else if (b === "RARE" && a === "SUPER RARE") {
        return 1;
      }
    });
    setScarcityFilter(newScarcities);
  };

  const currency = props.user.preferredUnit;

  return (
    <div>
      <div className={"w-11/12 xl:w-10/12 3xl:w-8/12 mx-auto flex flex-col space-y-4"}>
        <div className={"flex flex-col md:flex-row gap-2"}>
          <div className={"self-center w-full"}>
            <MultiScarcityPicker disabled={t1OrBelow(props.user?.tier)} scarcity={scarcityFilter} onChange={(s) => scarcityChange(s)} />
          </div>
          <div className={"self-center w-full"}>
            <CardPositionSelector
              positionFilter={positionFilter}
              tier={props.user?.tier}
              onChange={(v) => {
                setPositionFilter(v);
              }}
            />
          </div>
          <div className={"py-2 rounded-lg bg-white self-center w-full"}>
            <BoxWithToggle
              disabled={t1OrBelow(props.user?.tier)}
              disabledTip={t1OrBelow(props.user?.tier) ? `Star membership is needed` : ""}
              isActive={stillInGallery}
              setActive={() => {
                setStillInGallery(!stillInGallery);
              }}
              label={"Currently owned"}
            />
          </div>
          <div className={"relative self-center w-full"}>
            {filterString && (
              <div
                className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                onClick={() => {
                  setFilterString("");
                }}
              >
                <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
              </div>
            )}
            <input
              className="focus:outline-none focus:ring-0 focus:border-brand block w-full py-2 rounded-md pl-4 sm:text-sm border-grey-f2"
              type={"text"}
              placeholder={"Filter by player name"}
              value={filterString}
              disabled={t1OrBelow(props.user?.tier)}
              onChange={(v) => {
                setFilterString(v.target.value);
              }}
            />
          </div>
          <div className={"relative flex items-center justify-center"}>
            <button
              onClick={() => {
                getSO5Utility({ o: 0 });
              }}
              type="button"
              className={`inline-flex justify-center w-[20%] xl:w-auto px-6 py-2 border border-brand text-med font-semibold rounded-lg
                shadow-sm text-white bg-brand focus:outline-none focus:ring-0 text-center hover:bg-brand-light hover:border-brand-light cursor-pointer`}
            >
              Filter
            </button>
            <div className="absolute top-0 -right-10 transform translate-y-1/4">{loading && stats.list.length > 0 && <Spinner />}</div>
          </div>
        </div>
        <InfiniteScroll next={() => getSO5Utility({})} hasMore={stats.hasNext} loader={<SDLoading />} dataLength={stats.list.length}>
          <table
            className={"z-0 min-h-48 border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}
          >
            <thead>
              <th className="rounded-tl-lg text-white bg-brand-black border-b border-gray-200 mx-auto py-3 pl-2 text-left w-3/12 font-bold uppercase text-xs">
                Card
              </th>
              <th
                onClick={() => sortData("ratio")}
                data-tip={"Player was picked independently of the playing status of the player"}
                className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1.5/12 font-bold uppercase text-xs"
              >
                Pick %
              </th>
              <th
                onClick={() => sortData("picked_played_ratio")}
                data-tip={"Player played the game of the gameweek and was picked"}
                className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1.5/12 font-bold uppercase text-xs"
              >
                Pick when plays %
              </th>
              <th
                onClick={() => sortData("picked_started_ratio")}
                data-tip={"Player started the game of the gameweek and was picked"}
                className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1.5/12 font-bold uppercase text-xs"
              >
                Pick when starts %
              </th>
              <th
                onClick={() => sortData("won_cards")}
                className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1.5/12 font-bold uppercase text-xs"
              >
                Cards won
              </th>
              <th
                onClick={() => sortData("won_eth")}
                className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1.5/12 font-bold uppercase text-xs"
              >
                Gaming earnings
              </th>
              <th
                onClick={() => sortData(`total_${currency}_won`)}
                className="rounded-tr-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1.5/12 font-bold uppercase text-xs"
              >
                Total gaming yield
              </th>
            </thead>
            <tbody>
              {stats.list.map((s) => {
                const card = s.card;
                const player = s.player;
                const owned = props.manager.SorareAddress.toLowerCase() === s.card.CardOwner.toLowerCase();
                return (
                  <tr className={"border-b border-grey-e5"}>
                    <td>
                      <div className={"flex flex-row space-x-4"}>
                        <div className={"h-20"}>
                          <img className={"w-full h-full object-contain"} src={card.PictureUrl} />
                        </div>
                        <div className={"flex flex-col space-y-1 self-center text-sm"}>
                          <p className={owned ? "text-sm font-semibold" : "text-sm"}>
                            <a href={"/player/" + player.PlayerId}>{player.DisplayName}</a>
                          </p>
                          <NewCardLink card={card} sport={player.Sport} size={"text-sm"} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col items-center space-y-1"}>
                        <div>
                          <CirclePct showZero={true} odds={false} value={s.ratio} />
                        </div>
                        <div>
                          <p className={"text-xs font-semibold"}>
                            ({s.picked}/{s.eligible})
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col items-center space-y-1"}>
                        <div>
                          <CirclePct showZero={true} odds={false} value={s.picked_played_ratio} />
                        </div>
                        <div>
                          <p className={"text-xs font-semibold"}>
                            ({s.picked_played}/{s.played})
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col items-center space-y-1"}>
                        <div>
                          <CirclePct showZero={true} odds={false} value={s.picked_started_ratio} />
                        </div>
                        <div>
                          <p className={"text-xs font-semibold"}>
                            ({s.picked_started}/{s.started})
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className={"text-base text-center font-semibold self-center"}>{s.won_cards}</p>
                    </td>
                    <td>
                      <p className={"text-base text-center font-semibold self-center"}>{s.won_eth}</p>
                    </td>
                    <td>
                      <p className={"text-base text-center font-semibold self-center"}>{formatPrice(s.total_won?.[currency], currency)}</p>
                    </td>
                  </tr>
                );
              })}
              {stats.list.length === 0 && (
                <tr className={"text-sm text-center p-5 text-textGrey3"}>
                  <td className={"py-5"} colSpan="7">
                    {loading ? <Spinner /> : "No transaction with these filters"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
        {t1OrBelow(props.user?.tier) && (
          <div>
            <PlayerBlockedVideo
              title="Want more insights?"
              description="Become a Star member to discover which cards you're using the most in SO5."
              videoLink="https://www.youtube.com/embed/D_YtqBfqAC4"
            />
          </div>
        )}
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(ManagerSO5Utility);
