import React from "react";
import { withUser } from "../../userContext";
import { t1OrAbove } from "./handleSubscriptionTier";
import { sorareBaseball } from "./sports";
import clsx from "clsx";

export const periodLabels = {
  "1w": "1W",
  "1m": "1M",
  "3m": "3M",
  "6m": "6M",
  "1y": "1Y",
  ytd: "YTD",
  all: "All",
};

const freePeriods = ["1w", "1m"];

export const PeriodPicker = withUser((props) => {
  const availablePeriods = props.availablePeriods || ["1w", "1m", "3m", "6m", "1y", "all"];
  const allPeriodsFree = props.allPeriodsFree;
  return (
    <div className="bg-surface-container rounded-full text-md font-sans font-semibold flex flex-row justify-items-center overflow-hidden px-2 divide-x divide-outline-variant py-2">
      {availablePeriods.map((period) => {
        const label = periodLabels[period];
        const isSelected = period === props.period;

        if (allPeriodsFree || t1OrAbove(props.user?.tier) || props.sport === sorareBaseball || freePeriods.indexOf(period) >= 0) {
          return (
            <div
              key={period}
              className={clsx(
                "table w-full h-full self-center px-4 py-1 cursor-pointer hover:text-brand-text",
                isSelected ? "text-brand-text" : "text-on-surface-variant",
              )}
              onClick={() => props.onChange(period)}
            >
              <span className={"table-cell align-middle"}>{label}</span>
            </div>
          );
        } else {
          return (
            <div
              key={period}
              className={`table w-full h-full self-center px-2 pt-2 pb-1 cursor-default hover:text-brand-text border-b-4 opacity-20 `}
              data-tip={"Star membership is needed"}
            >
              <span className={"table-cell align-middle"}>{label}</span>
            </div>
          );
        }
      })}
    </div>
  );
});
