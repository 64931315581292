import React, { Component } from "react";
import { Box } from "grommet";
import { withUser } from "../../userContext";
import PrivateOfferPage from "./privateOfferPage";
import { errorCatcher } from "../util/errors";
import SDLoading from "../util/SDLoading";

class OfferPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offerInfo: {},
    };
  }

  setupComponent = () => {
    this.props
      .fetch("/apiv2/offers/newDetails/" + this.props.match.params.id)
      .then((response) => response.json())
      .then((res) => {
        if (res.offer.Status === "ONGOING" && this.interval === undefined) {
          this.interval = setInterval(() => this.setupComponent(), 30000);
        }
        this.setState({ offerInfo: res });
      })
      .catch(errorCatcher());
  };

  componentDidMount() {
    this.setupComponent();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const info = this.state.offerInfo;
    return (
      <Box>
        {info.offer !== undefined ? (
          <div>
            <PrivateOfferPage offer={info} />
          </div>
        ) : (
          <Box>
            <SDLoading />
          </Box>
        )}
      </Box>
    );
  }
}

export default withUser(OfferPage);
