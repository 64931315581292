import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../util/button";
import Modal from "../util/modal";
import { PriceAlertForm } from "./priceAlertForm";
import { ReactComponent as EditIcon } from "../../img/icons-edit-no-color.svg";
import { ReactComponent as BellIcon } from "../../img/icons-bell-circle.svg";
import { ReactComponent as BellIconActive } from "../../img/icons-bell-active-circle.svg";
import { ReactComponent as IconTrash } from "../../img/icons-trash-no-color.svg";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { PriceAlertSummary } from "./priceAlertSummary";
import clsx from "clsx";
import { PaywallIcon } from "../util/paywall";
import { PriceAlertPaywall } from "./priceAlertPaywall";

export const PriceAlertEditButton = ({ priceAlert, playerId, playerName, onSubmitCallback, roundedButton }) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const title = `Edit player price alert`;
  return (
    <div>
      {open && (
        <PriceAlertModal close={close} open={open} title={title} subTitle={playerName}>
          <PriceAlertForm
            onClose={close}
            priceAlert={priceAlert}
            playerId={playerId}
            onSubmitCallback={() => {
              onSubmitCallback();
              close();
            }}
          />
        </PriceAlertModal>
      )}
      <div
        className={clsx(roundedButton && "rounded-full p-2 bg-surface-container-highest hover:opacity-80")}
        onClick={() => setOpen(true)}
      >
        <EditIcon className="cursor-pointer w-4 h-4 my-auto fill-on-surface-variant" />
      </div>
    </div>
  );
};

export const PriceAlertPlayerPageButton = withUser((props) => {
  const { playerId, playerName } = props;
  const modeSummary = "summary";
  const modeCreation = "creation";

  const [open, setOpen] = useState(false);
  const [priceAlerts, setPriceAlerts] = useState([]);
  const [mode, setMode] = useState(modeCreation);
  const [alertsLoading, setAlertsLoading] = useState(false);
  const [alertSlots, setAlertSlots] = useState(null);
  const fetchPriceAlerts = useCallback(() => {
    setAlertsLoading(true);
    props
      .fetch(`/apiv2/user/priceAlerts?playerId=${playerId}`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then(({ alertConfigs, remainingAlertSlots }) => {
        setPriceAlerts(alertConfigs ?? []);
        if (alertConfigs?.length > 0) {
          setMode(modeSummary);
        }
        setAlertSlots(remainingAlertSlots);
      })
      .catch(errorCatcher())
      .finally(() => setAlertsLoading(false));
  }, []);

  useEffect(() => {
    fetchPriceAlerts();
  }, []);

  const close = () => {
    setOpen(false);
  };

  const openCallback = () => {
    !alertsLoading && setOpen(true);
    if (priceAlerts?.length > 0) {
      setMode(modeSummary);
    }
  };

  const isSummaryMode = () => mode === modeSummary;
  const isCreationMode = () => mode === modeCreation;
  const isPaywalled = typeof alertSlots === "number" && alertSlots <= 0;
  const isPaywallMode = isCreationMode() && isPaywalled;

  let title = "My price alerts";
  if (isCreationMode()) {
    title = "Create player price alert";
  }
  if (isPaywallMode) {
    title = "Price alerts";
  }
  return (
    <div>
      {open && (
        <PriceAlertModal close={close} open={open} title={title} subTitle={playerName} onSubmitText="Create a new price alert">
          {isSummaryMode() && (
            <div className="flex flex-col">
              <PriceAlertSummary
                priceAlerts={priceAlerts}
                playerId={playerId}
                playerName={playerName}
                refreshAlerts={fetchPriceAlerts}
                loading={alertsLoading}
                setLoading={setAlertsLoading}
              />
              <div className={"flex flex-row border-t border-outline-variant justify-end space-x-3 pt-3 px-4 mt-4"}>
                <Button context="secondary" label="Cancel" onClick={() => close()} />
                <div className="relative">
                  {isPaywalled && <PaywallIcon />}
                  <Button label="Create a new price alert" onClick={() => setMode(modeCreation)} />
                </div>
              </div>
            </div>
          )}
          {isCreationMode() && !isPaywallMode && (
            <div>
              <PriceAlertForm
                playerId={playerId}
                onClose={() => close()}
                onSubmitCallback={() => {
                  fetchPriceAlerts();
                  setMode(modeSummary);
                }}
              />
            </div>
          )}
          {isPaywallMode && <PriceAlertPaywall />}
        </PriceAlertModal>
      )}
      <div onClick={() => openCallback()} className="cursor-pointer hover:opacity-80  w-full h-full relative">
        {isPaywalled && <PaywallIcon rightPosition="-right-1.5" />}
        {priceAlerts?.length > 0 ? <BellIconActive className="w-full h-full" /> : <BellIcon className="w-full h-full" />}
      </div>
    </div>
  );
});

export const PriceAlertDeleteButton = withUser((props) => {
  const { alertConfigId, onDeleteCallback, setLoading, roundedButton } = props;
  const deletePriceAlert = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/user/priceAlerts/${alertConfigId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.ok) {
          onDeleteCallback();
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div className={clsx(roundedButton && "rounded-full p-2 bg-surface-container-highest hover:opacity-80")} onClick={deletePriceAlert}>
      <IconTrash className="cursor-pointer w-4 h-4 my-auto fill-on-surface-variant" />
    </div>
  );
});

export const PriceAlertDeletePlayerAlertsButton = withUser((props) => {
  const { playerId, onDeleteCallback, setLoading } = props;
  const deletePlayerAlerts = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/user/priceAlerts/players/id/${playerId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.ok) {
          onDeleteCallback();
        }
      })
      .catch(errorCatcher());
  };

  return (
    <span
      onClick={() => deletePlayerAlerts()}
      className="flex items-center gap-2 text-sm font-semibold cursor-pointer text-on-surface-variant"
    >
      <IconTrash className="h-5 w-5 fill-on-surface-variant" />
      Delete all
    </span>
  );
});

const PriceAlertModal = (props) => {
  const { close, open, children, title, subTitle } = props;
  return (
    <Modal
      padding="px-0 py-3"
      size="w-fit"
      maxSize="w-fit"
      close={close}
      open={open}
      content={
        <div className="font-sans">
          <div className="font-sans flex flex-col gap-2 text-on-surface">
            <div className="font-headers border-b border-transparent-inverse-surface-low border-opacity-10 pb-4 px-4 flex flex-col justify-center gap-2">
              <p className="text-xl font-headers font-semibold">{title}</p>
              <p className="text-xs font-headers font-medium">{subTitle}</p>
            </div>
            <div className="flex flex-row space-x-2 justify-center">
              <div className="flex justify-center w-full">{children}</div>
            </div>
          </div>
        </div>
      }
    />
  );
};
