import Toggle from "./toggle";
import React from "react";
import HelpTip from "./helpTip";
import clsx from "clsx";
import { PaywallIcon } from "./paywall";

function BoxWithToggle(props) {
  let label = props.label;
  const { className, hint, children, paywalled } = props;
  const canClick = !paywalled;
  const size = props.size || "text-sm";
  const textColor = props.color || "text-on-surface-variant";
  const bg = canClick ? props.bg || "bg-surface-container" : "bg-surface-disable opacity-40";
  const padding = props.padding || "p-2";
  const border = props.border || "";
  const rounded = props.rounded || "rounded-md";
  const wrap = props.wrap || "truncate";
  const maxWidthLabel = props.maxWidthLabel || "";
  const showChildren = props.isActive && children;
  const childrenPadding = props.childrenPadding || "px-4 py-3";

  return (
    <div className="relative">
      {paywalled && <PaywallIcon rightPosition="-right-1.5" />}
      <div
        className={clsx(
          className,
          rounded,
          bg,
          padding,
          border,
          "flex flex-row w-full gap-2 justify-between",
          canClick ? "cursor-pointer" : "cursor-not-allowed",
          showChildren && "rounded-b-none",
        )}
        data-tip={props.disabledTip}
        data-for={props.disabledTipId}
      >
        <div className={clsx(maxWidthLabel, "self-center justify-start relative")}>
          <span className={"flex flex-row gap-1"}>
            <p className={`font-medium ${wrap} ${size} ${textColor}`}>{label}</p>
            {hint && <HelpTip tip={hint} />}
          </span>
        </div>
        <div className={clsx("w-1/12 justify-end flex flex-row self-center", !canClick && "cursor-not-allowed")}>
          <Toggle disabled={!canClick} setActive={() => props.setActive && canClick && props.setActive()} isActive={props.isActive} />
        </div>
      </div>
      {showChildren && (
        <div className={clsx(childrenPadding, rounded, "bg-white rounded-t-none flex flex-col space-y-2 border-t")}>{children}</div>
      )}
    </div>
  );
}

export default BoxWithToggle;
