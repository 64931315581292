import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { withRouter } from "react-router-dom";
import SDLoading from "../util/SDLoading";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import EmptyAvatar from "../../img/empty-avatar.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../loader/spinner";
import AddSearchableItemsModal from "../search/addSearchableItemsModal";
import { getTeamMapNextGw } from "../util/teamMap";
import OfferCard from "../offers/offerCard";
import FavoriteBubble from "../util/favoriteBubble";
import DeletionBubble from "../util/deletionBubble";
import { searchText } from "../util/strings";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { Form } from "grommet";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { scarcities_objects_not_all } from "../util/scarcities";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import { ReactTooltip } from "../util/tooltip.js";
import BoxWithToggle from "../util/boxWithToggle";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import { getNbElementsToDisplay } from "./watchlistManagersLastArrivals";
import WatchlistMultiplePicker from "./watchlistMultiplePicker";
import AbortController from "abort-controller";

const WatchlistsOnGoingOffersListGroupByManager = (props) => {
  const { watchlist, managers, ethPriceInfo, nextGwPlayers, connectedUserIsOwner, removeManagerFromWatchlist, offersGroupBy, limit } =
    props;
  return (
    <div className={"flex flex-col gap-2 w-full mb-8"}>
      {managers.map((res) => {
        return (
          <div key={res.manager.Slug} className={"bg-focus mx-auto mt-2 rounded-xl w-full"}>
            <div className={"border-b-2 border-grey-e5"}>
              <div className={"flex flex-row place-content-between p-4"}>
                <div>
                  <span className={"flex flex-row"} title={res.manager.Nickname || res.manager.Slug}>
                    <span className={"my-auto hidden md:inline rounded-full mr-2 w-5 h-5 xl:w-7 xl:h-7"}>
                      <a href={"/manager/" + res.manager.Slug}>
                        <img
                          className={"rounded-full inline w-5 h-5 xl:w-7 xl:h-7 object-cover cursor-pointer"}
                          src={res.manager.PictureUrl || EmptyAvatar}
                        />
                      </a>
                    </span>
                    <span className={"text-xs xl:text-sm font-semibold mr-2 flex flex-col"}>
                      <a className={"truncate"} href={"/manager/" + res.manager.Slug}>
                        {res.manager.Nickname || res.manager.Slug}
                      </a>
                      <p className={"text-textGrey4 truncate text-xs"} style={{ maxWidth: "13em" }} title={res.manager.TeamName}>
                        <a href={"/manager/" + res.manager.Slug}>{res.manager.TeamName}</a>
                      </p>
                    </span>
                  </span>
                </div>
                <div className={"flex flex-row justify-center my-auto"}>
                  <div className={"h-9 w-9"}>
                    <FavoriteBubble type="manager" manager={res.manager.Slug} favorite={res.favorite} />
                  </div>
                  {connectedUserIsOwner && (
                    <div className={"h-9 w-9"}>
                      <DeletionBubble
                        onClick={() => removeManagerFromWatchlist(res.manager)}
                        title={`Click to remove "${res.manager.Nickname}" from "${watchlist.name}" watchlist`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <WatchlistsOnGoingOffersList
              limit={limit}
              offers={offersGroupBy ? offersGroupBy[res.manager.Slug] : undefined}
              ethPriceInfo={ethPriceInfo}
              nextGwPlayers={nextGwPlayers}
            />
          </div>
        );
      })}
    </div>
  );
};

const WatchlistsOnGoingOffersList = (props) => {
  const { offers, ethPriceInfo, nextGwPlayers, limit } = props;
  const [showAll, setShowAll] = useState(false);
  const offersToDisplay = offers && offers.length > 0 ? (showAll ? offers : offers.slice(0, limit)) : [];
  return (
    <>
      {offersToDisplay.length > 0 ? (
        <div className={"flex flex-col justify-center mx-auto"}>
          <div
            className={
              "mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 1.5xl:grid-cols-6 2xl:grid-cols-6 2.5xl:grid-cols-7 4.5xl:grid-cols-8 gap-2 pb-4"
            }
          >
            <ReactTooltip />
            {offersToDisplay.map((offer, i) => {
              return (
                <OfferCard
                  key={i}
                  offer={offer}
                  priceInfo={ethPriceInfo}
                  u23Only={false}
                  position={"All positions"}
                  filter={[]}
                  gwGames={nextGwPlayers}
                />
              );
            })}
          </div>
          {offers.length > limit && (
            <div className={"h-full mt-4 p-2 hover:bg-grey-f8 hover:opacity-90 cursor-pointer"} onClick={() => setShowAll(!showAll)}>
              <div className={"flex justify-center w-full"}>
                <span className={"ml-2 my-auto"}>
                  <SortArrowIcon className={"fill-brand h-6 w-6 " + (showAll ? "transform rotate-180" : "")} />
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={"text-textGrey3 text-md p-4 w-full text-center"}>No ongoing sale</div>
      )}
    </>
  );
};

const sortByLabels = {
  "average asc": "Cheapest price average",
  "average desc": "Most expensive price average",
  "floor asc": "Cheapest price floor",
  "floor desc": "Most expensive price floor",
  "price asc": "Cheapest selling price",
  "price desc": "Most expensive selling price",
  "l5 asc": "Worst L5 SO5 points",
  "l5 desc": "Best L5 SO5 points",
  "l15 asc": "Worst L15 SO5 points",
  "l15 desc": "Best L15 SO5 points",
};

const WatchlistManagersOnSale = (props) => {
  const watchlist = props.watchlist;

  const [loading, setLoading] = useState(false);
  const mdQueries = useTailwindMediaQueries();
  const [limit, setLimit] = useState(getNbElementsToDisplay(mdQueries));

  const [managers, setManagers] = useState(undefined);
  const [filteredManagers, setFilteredManagers] = useState(undefined);
  const [displayedManagers, setDisplayedManagers] = useState(undefined);

  const [offers, setOffers] = useState(undefined);
  const [filteredOffers, setFilteredOffers] = useState(undefined);
  const [displayedOffers, setDisplayedOffers] = useState(undefined);

  const [offersGroupBy, setOffersGroupBy] = useState(undefined);

  const [ethPriceInfo, setEthPriceInfo] = useState({});
  const [nextGwPlayers, setNextGwPlayers] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [scarcities, setScarcities] = useState([]);
  const [isGroupedByManager, setIsGroupedByManager] = useState(true);
  const [sortBy, setSortBy] = useState("price desc");
  const [showSortMenu, setShowSortMenu] = useState(false);

  const [savingAddManagers, setSavingAddManagers] = useState(false);
  const [openAddManagersModal, setOpenAddManagersModal] = useState(false);

  const [playerWatchlists, setPlayerWatchlists] = useState({});

  const [abortController, setAbortController] = useState(new AbortController());

  const connectedUserIsOwner = watchlist.owner === props.user.sorareSlug;

  useEffect(() => {
    const newLimit = getNbElementsToDisplay(mdQueries);
    setLimit(newLimit);
  }, [mdQueries]);

  useEffect(() => {
    getTeamMapNextGw(props).then((r) => setNextGwPlayers(r));
  }, []);

  useEffect(() => {
    getManagerOnSale();
  }, [watchlist, playerWatchlists, scarcities, isGroupedByManager, sortBy]);

  useEffect(() => {
    if (managers !== undefined) {
      if (isGroupedByManager) {
        const ms = managers
          ? Object.entries(managers)
              .map(([_, val]) => val)
              .sort((a, b) => a.manager.Nickname > b.manager.Nickname)
          : [];
        const filtered = ms.filter((m) => searchText(searchQuery, m.manager.Nickname));
        setFilteredManagers(filtered);
        setDisplayedManagers(filtered.slice(0, 50));
      } else {
        const filteredOffers =
          searchQuery !== ""
            ? offers.filter((offer) =>
                managers[offer.manager_slug] ? searchText(searchQuery, managers[offer.manager_slug].manager.Nickname) : false,
              )
            : offers;
        setFilteredOffers(filteredOffers);
        setDisplayedOffers(filteredOffers.slice(0, 50));
      }
    }
  }, [searchQuery]);

  const getManagerOnSale = () => {
    if (watchlist.id) {
      setLoading(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      const url = isGroupedByManager
        ? `/apiv2/watchlists/id/${watchlist.id}/managers/offers/onGoing/groupByManager/search`
        : `/apiv2/watchlists/id/${watchlist.id}/managers/offers/onGoing/search`;
      props
        .fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            playerWatchlists: Object.keys(playerWatchlists),
            scarcities: scarcities || [],
            sortBy: sortBy,
          }),
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setEthPriceInfo(res.ethPriceInfo);
            const managersBySlug = res.managers || {};
            setManagers(managersBySlug);
            const ms = managersBySlug
              ? Object.entries(managersBySlug)
                  .map(([_, val]) => val)
                  .sort((a, b) => a.manager.Nickname > b.manager.Nickname)
              : [];
            const filteredMs = searchQuery !== "" ? ms.filter((m) => searchText(searchQuery, m.manager.Nickname)) : ms;
            setFilteredManagers(filteredMs);
            if (isGroupedByManager) {
              setOffersGroupBy(res.offers || {});
              setDisplayedManagers(filteredMs.slice(0, 50));
            } else {
              const offers = res.offers || [];
              setOffers(offers);
              const filteredOffers =
                searchQuery !== ""
                  ? offers.filter((offer) =>
                      managersBySlug[offer.manager_slug]
                        ? searchText(searchQuery, managersBySlug[offer.manager_slug].manager.Nickname)
                        : false,
                    )
                  : offers;
              setFilteredOffers(filteredOffers);
              setDisplayedOffers(filteredOffers.slice(0, 50));
            }
          }
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const onSaveAddManagers = (playerIds) => {
    setSavingAddManagers(true);
    return props
      .fetch(`/apiv2/watchlists/id/${watchlist.id}/addItems`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(playerIds),
      })
      .then((res) => {
        setSavingAddManagers(false);
        if (res.error === undefined) {
          setOpenAddManagersModal(false);
          props.onAddManagers && props.onAddManagers();
        } else {
          console.log(res.error);
        }
      })
      .catch(
        errorCatcher(() => {
          setSavingAddManagers(false);
        }),
      );
  };

  const onRemoveManager = (managerToRemove) => {
    if (watchlist.id && managerToRemove) {
      setLoading(true);
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}/removeItems`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify([managerToRemove.Slug]),
        })
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.error === undefined) {
            getManagerOnSale();
          }
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const countManagers = managers ? Object.keys(managers).length : 0;

  const sortMenuItems = Object.entries(sortByLabels).map(([key, label]) => {
    return {
      key: key,
      label: label,
      className: "w-full sm:w-72 cursor-pointer " + (sortBy === key ? "font-semibold" : ""),
      onClick: () => {
        setShowSortMenu(false);
        setSortBy(key);
      },
    };
  });

  return (
    <div className={"h-full"}>
      <div className={"relative mb-2 flex flex-col md:flex-row justify-between gap-2"}>
        <div className={"text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
          Sort by: {sortByLabels[sortBy]}
          <span className={"ml-2 my-auto"}>
            <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
          </span>
        </div>
        <div className="flex flex-col sm:flex-row gap-2 justify-end">
          {loading && <Spinner />}
          <Form className="font-semibold text-sm">
            <div className={"m-auto relative flex h-9 "}>
              <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                <FormSearch className="h-5 w-5" aria-hidden="true" />
              </div>
              {searchQuery && (
                <div
                  className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                >
                  <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
                </div>
              )}
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                className={`h-9 rounded-lg block w-full pl-10 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0 ${
                  searchQuery !== "" ? "font-medium" : ""
                }`}
                placeholder="Search a manager"
              />
            </div>
          </Form>
          <div className={"flex w-full md:w-72"}>
            <BoxWithToggle
              className={"border border-grey-e9 py-2 md:py-0"}
              label={"Group by manager"}
              isActive={isGroupedByManager}
              setActive={() => setIsGroupedByManager(!isGroupedByManager)}
            />
          </div>
          <div className={"md:z-60 lg:z-50 xl:z-50"}>
            <SelectSearch
              multiple
              closeOnSelect={false}
              options={scarcities_objects_not_all}
              value={scarcities}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select one scarcity or more"
              onChange={(v) => setScarcities(v)}
            />
          </div>
          <div className={"w-full md:w-72"}>
            <WatchlistMultiplePicker
              types={["player"]}
              fetchOnMount={true}
              options={{
                pinListFirst: true,
                defaultListFirst: true,
                followedListFirst: true,
                ownedListFirst: true,
              }}
              size={"100%"}
              placeholder={"Filter by watchlist(s)"}
              selected={playerWatchlists}
              onSelect={(w) => {
                let newWatchlists = { ...playerWatchlists };
                if (playerWatchlists[w.id]) {
                  delete newWatchlists[w.id];
                } else {
                  newWatchlists[w.id] = w;
                }
                setPlayerWatchlists(newWatchlists);
              }}
            />
          </div>
          {connectedUserIsOwner && (
            <button
              className={
                "bg-brand text-white p-2 pl-10 pr-10 text-sm font-medium rounded-md focus:outline-none hover:bg-brand-lightWatchlist"
              }
              onClick={() => setOpenAddManagersModal(true)}
            >
              {`+ Add ${watchlist.type}s`}
            </button>
          )}
        </div>
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
          show={showSortMenu}
          onClickOutside={() => setShowSortMenu(false)}
          items={sortMenuItems}
        />
      </div>
      <div className={"h-full mb-8"}>
        {managers !== undefined && countManagers === 0 && (
          <div className={"flex flex-col justify-center w-full h-full bg-focus rounded-lg text-center border border-grey-e9"}>
            <div className={"m-auto py-36"}>
              <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
              <p className={"text-lg font-headers text-center font-bold mb-6"}>
                {connectedUserIsOwner ? "Your watchlist is empty" : "Watchlist is empty"}
              </p>
              {connectedUserIsOwner && (
                <button
                  onClick={() => setOpenAddManagersModal(true)}
                  type="button"
                  className="inline-flex items-center px-12 py-2.5 border border-transparent text-lg font-semibold rounded-lg
                shadow-sm text-white bg-brand hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-brand-light"
                >
                  {`+ Add ${watchlist.type}s`}
                </button>
              )}
            </div>
          </div>
        )}
        {managers !== undefined && countManagers > 0 && isGroupedByManager && displayedManagers !== undefined && (
          <div>
            <InfiniteScroll
              next={() => setDisplayedManagers(filteredManagers.slice(0, displayedManagers.length + 50))}
              hasMore={displayedManagers.length < filteredManagers.length}
              loader={<SDLoading />}
              dataLength={displayedManagers.length}
            >
              <WatchlistsOnGoingOffersListGroupByManager
                limit={limit}
                watchlist={watchlist}
                managers={displayedManagers}
                offersGroupBy={offersGroupBy}
                ethPriceInfo={ethPriceInfo}
                nextGwPlayers={nextGwPlayers}
                connectedUserIsOwner={connectedUserIsOwner}
                removeManagerFromWatchlist={onRemoveManager}
              />
            </InfiniteScroll>
          </div>
        )}
        {managers !== undefined && countManagers > 0 && !isGroupedByManager && displayedOffers !== undefined && (
          <div>
            <InfiniteScroll
              next={() => setDisplayedOffers(filteredOffers.slice(0, displayedOffers.length + 50))}
              hasMore={displayedOffers.length < filteredOffers.length}
              loader={<SDLoading />}
              dataLength={displayedOffers.length}
            >
              <WatchlistsOnGoingOffersList offers={displayedOffers} ethPriceInfo={ethPriceInfo} nextGwPlayers={nextGwPlayers} />
            </InfiniteScroll>
          </div>
        )}
        {managers !== undefined &&
          countManagers > 0 &&
          !isGroupedByManager &&
          displayedOffers !== undefined &&
          displayedOffers.length === 0 && (
            <div>
              <WatchlistsOnGoingOffersList />
            </div>
          )}
      </div>
      <AddSearchableItemsModal
        type="manager"
        title={"Add to watchlist"}
        open={openAddManagersModal}
        onSave={onSaveAddManagers}
        saving={savingAddManagers}
        onCancel={() => setOpenAddManagersModal(false)}
      />
    </div>
  );
};

export default withRouter(withUser(WatchlistManagersOnSale));
