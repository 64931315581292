import React, { useEffect, useState } from "react";

const LabelItem = (props) => {
  const { label, active, bgColor, textColor, activeBgColor, activeTextColor, onClick } = props;
  return (
    <div
      className={`flex flex-row rounded-full px-2 py-1 cursor-pointer justify-center items-center ${
        active ? `${activeBgColor} ${activeTextColor}` : `${bgColor} ${textColor}`
      }`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export const LabelSwitch = (props) => {
  // TODO: default colors to be changed with theme
  const { options, state } = props;
  const textSize = props.textSize || "text-xs sm:text-sm font-semibold";
  const globalBgColor = props.globalBgColor || "bg-full-black";
  const bgColor = props.bgColor || "bg-full-black";
  const activeBgColor = props.activeBgColor || "bg-white";
  const textColor = props.textColor || "text-grey-d9";
  const activeTextColor = props.activeTextColor || "text-full-black";
  const [internalState, setInternalState] = useState(state || options?.[0]?.value);
  const onToggle = (newState) => {
    setInternalState(newState);
    props.onChange && props.onChange(newState);
  };
  const labelItems = {
    bgColor,
    activeBgColor,
    textColor,
    activeTextColor,
  };
  useEffect(() => {
    setInternalState(state);
  }, [state]);
  return (
    <div className={`grid grid-cols-2 grid-rows-1 rounded-full p-1 ${globalBgColor} ${textSize} gap-1`}>
      {options?.map((option, index) => {
        return (
          <LabelItem
            {...labelItems}
            active={option.value === internalState}
            key={index}
            label={option.label}
            onClick={() => onToggle(option.value)}
          />
        );
      })}
    </div>
  );
};
