import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import ls from "local-storage";
import { useLocation } from "react-router-dom";
import Spinner from "../loader/spinner";
import { getManagerPlayers } from "../util/managerPlayers";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SorareLogin(props) {
  const [errorMsg, setErrorMsg] = useState("");

  let query = useQuery();

  useEffect(() => {
    const code = query.get("code");
    const link = ls.get("loginReturnLink");
    if (link) {
      // the user wants to log in for a native app
      ls.set("loginReturnLink", "");
      const hasParams = link.includes("release-channel");
      if (hasParams) {
        window.location.href = `${link}&code=${code}`;
      } else {
        window.location.href = `${link}?code=${code}`;
      }
    } else {
      // the user wants to log in for a web client
      handleCode(code);
    }
  }, []);

  const handleCode = (code) => {
    props
      .fetch("/apiv2/auth/loginWithSorare", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        if (!res.error) {
          const players = await getManagerPlayers(props, res.sorareSlug);
          props.setUser({
            username: res.user.Username,
            sorareSlug: res.sorareUser.Slug,
            sorareId: res.sorareUser.SorareId,
            sorarePictureUrl: res.sorareUser.PictureUrl,
            players: players,
            preferredUnit: res.user.Currency,
            preferredScarcity: res.user.MobileScarcity || "LIMITED",
            preferredLayout: res.user.CardBackground,
            secondUnit: res.user.MobileFiat,
            tier: res.plan,
            email: res.user.Email,
            termsAccepted: res.user.TermsAccepted,
            verified: res.user.Verified,
            sorareAverages: res.user.SorareAverages,
            hideCommonCards: res.user.HideCommonCards,
            hideIneligibleCards: res.user.HideIneligibleCards,
          });
          if (res.user.TermsAccepted && res.user.Email !== undefined && res.user.Email !== null && res.user.Email !== "") {
            props.history.push("/");
          } else {
            props.history.push("/validateRegistration");
          }
        } else {
          setErrorMsg("Login try failed. Please try again. " + res.error);
        }
      })
      .catch(errorCatcher());
  };

  return (
    <Box align={"center"} margin={{ top: "medium" }} gap={"small"}>
      {errorMsg === "" && (
        <Box>
          <Spinner />
          <Text textAlign={"center"}>Thanks for logging in with your Sorare account... We&apos;re setting everything up for you.</Text>
        </Box>
      )}
      {errorMsg !== "" && <Text textAlign={"center"}>{errorMsg}</Text>}
    </Box>
  );
}

export default withUser(SorareLogin);
