import React, { Component } from "react";
import { Box, Heading, Anchor, ResponsiveContext } from "grommet";
import { withUser } from "../../userContext";
import CardDisplay from "../cards/cardDisplay";
import BidsLines from "./bidsLines";
import Spinner from "../loader/spinner";
import { errorCatcher } from "../util/errors";
import { t1OrBelow } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import { findPriceForUserUnit } from "../util/formatMoney";
import BidNext from "./bidNext";

class AuctionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auctionInfo: {},
      link: "",
      auctionId: -1,
    };
  }

  setupComponent = () => {
    let id = this.props.auctionId;
    if (this.props.match !== undefined) id = this.props.match.params.id;
    this.setState({ auctionId: id });
    this.props
      .fetch("/apiv2/auctions/info/" + id)
      .then((response) => response.json())
      .then((res) => {
        this.setState({ auctionInfo: res });
        this.buildLink();
      })
      .catch(errorCatcher());
  };

  buildLink = () => {
    let slug = this.state.auctionInfo.card.player.Slug;
    let sport = this.state.auctionInfo.card.player.Sport;
    let sportLink = "";
    if (sport === sorareBaseball) {
      sportLink = "mlb/";
    } else if (sport === sorareBasketball) {
      sportLink = "nba/";
    }
    let link =
      "https://sorare.com/" +
      sportLink +
      "cards/" +
      slug +
      "-" +
      this.state.auctionInfo.card.card.Season +
      "-" +
      this.state.auctionInfo.card.card.Scarcity.toLowerCase() +
      "-" +
      this.state.auctionInfo.card.card.SerialNumber +
      "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1";
    link = link.replace(" ", "_");
    console.log(sport, sportLink, link);
    this.setState({ link });
  };

  componentDidMount() {
    this.setupComponent();
    this.interval = setInterval(() => {
      if (this.state.auctionInfo.auction.Status === "ONGOING") this.setupComponent();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.auctionInfo.auction) {
      if (this.state.auctionInfo.auction.AuctionId !== this.state.auctionId) {
        this.setupComponent();
      }
    }
  }

  render() {
    const info = this.state.auctionInfo;
    let showBids = true;
    if (this.props.showBids === false) showBids = false;
    const { EurAverage: eur, UsdAverage: usd, GbpAverage: gbp } = info.card?.rt || {};
    const estimatedPriceFiat = { eur, usd, gbp };
    const estimatedPrice = findPriceForUserUnit(info.card?.rt.Average, estimatedPriceFiat, this.props.user.preferredUnit);
    return (
      <Box>
        {info.auction !== undefined ? (
          <Box>
            <Box>
              <CardDisplay
                player={info.card.player}
                card={info.card.card}
                team={info.card.team}
                auction={info.auction}
                noTitle={this.props.noTitle}
                stat={true}
                info={info.card}
                unit={this.props.user.preferredUnit}
                estimatedPrice={estimatedPrice}
                valuationOrigin={info.card.valuation_origin}
                bmp={info.card.bmp}
              />
            </Box>
            {showBids && (
              <Box>
                <Box align={"center"}>
                  <Heading level={"3"}>Auction bids</Heading>
                </Box>
                <ResponsiveContext.Consumer>
                  {(size) => (
                    <Box margin={size !== "small" && "auto"}>
                      <BidNext unit={this.props.user.preferredUnit} fiat={info.next_bid_fiat} eth={info.next_bid} />
                      <BidsLines bids={info.bids} unit={this.props.user.preferredUnit} />
                    </Box>
                  )}
                </ResponsiveContext.Consumer>
              </Box>
            )}
            {t1OrBelow(this.props.user.tier) &&
              !(info.card.player.Sport === sorareBaseball) &&
              this.state.auctionInfo.auction?.Status === "ENDED" && (
                <div className="w-4/5 mx-auto my-4">
                  <UpgradeLimitBox
                    className={"bg-white bg-upgrade-bg-auction bg-cover h-upgrade-banner-s w-full"}
                    title={"Want more insights?"}
                    description={"Become a Star member to unlock all bids history."}
                  />
                </div>
              )}
            <Box align={"center"} margin={{ top: "small", bottom: "large" }}>
              <Anchor target={"_blank"} href={this.state.link}>
                View auction on Sorare
              </Anchor>
            </Box>
          </Box>
        ) : (
          <Box align={"center"} margin={{ top: "large" }}>
            <Spinner />
          </Box>
        )}
      </Box>
    );
  }
}

export default withUser(AuctionPage);
