import GoalIcon from "../../img/ICN_Ballon.svg";
import AssistIcon from "../../img/assist-icon.svg";
import Whistle from "../../img/whistle.svg";
import AngelBall from "../../img/angelball.svg";
import PenaltySave from "../../img/PenaltySave.svg";
import RedCard from "../../img/red-card.svg";
import OwnGoal from "../../img/own-goal.svg";
import RedWhistle from "../../img/redWhistle.svg";
import RedShoe from "../../img/redShoe.svg";
import CleanSheet from "../../img/cleansheet.svg";
import React from "react";

export const getDecisiveActionIcons = (stats, size) => {
  const icons = [];
  size = size || 6;
  let divStyle = `w-${size} h-${size} self-center rounded-full`;
  let imgStyle = "w-full h-full object-contain shadow rounded-full";
  if (stats !== undefined && stats !== null) {
    for (let i = 0; i < stats.Goals; i++) {
      icons.push(
        <div key={`${i}-goal`} className={divStyle}>
          <img className={imgStyle} src={GoalIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AdjustedGoalAssist; i++) {
      icons.push(
        <div key={`${i}-assist`} className={divStyle}>
          <img className={imgStyle} src={AssistIcon} />
        </div>,
      );
    }
    for (let i = 0; i < stats.AssistPenaltyWon; i++) {
      icons.push(
        <div key={`${i}-whistle`} className={divStyle}>
          <img className={imgStyle} src={Whistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ClearanceOffLine + stats.LastManTackle; i++) {
      icons.push(
        <div key={`${i}-angel`} className={divStyle}>
          <img className={imgStyle} src={AngelBall} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltySaves + stats.PenaltySave; i++) {
      icons.push(
        <div key={`${i}-penaltySave`} className={divStyle}>
          <img className={imgStyle} src={PenaltySave} />
        </div>,
      );
    }
    for (let i = 0; i < stats.RedCard; i++) {
      icons.push(
        <div key={`${i}-redCard`} className={divStyle}>
          <img className={imgStyle} src={RedCard} />
        </div>,
      );
    }
    for (let i = 0; i < stats.OwnGoal; i++) {
      icons.push(
        <div key={`${i}-ownGoal`} className={divStyle}>
          <img className={imgStyle} src={OwnGoal} />
        </div>,
      );
    }
    for (let i = 0; i < stats.PenaltyConceded; i++) {
      icons.push(
        <div key={`${i}-redWhistle`} className={divStyle}>
          <img className={imgStyle} src={RedWhistle} />
        </div>,
      );
    }
    for (let i = 0; i < stats.ErrorLeadToGoal; i++) {
      icons.push(
        <div key={`${i}-redShoe`} className={divStyle}>
          <img className={imgStyle} src={RedShoe} />
        </div>,
      );
    }
    if (stats.Position === "Goalkeeper") {
      for (let i = 0; i < stats.CleanSheet; i++) {
        icons.push(
          <div key={`${i}-cleanSheet`} className={divStyle}>
            <img className={imgStyle} src={CleanSheet} />
          </div>,
        );
      }
    }
  }
  return icons;
};

export const sortPlayersByPosition = (players) => {
  return players?.sort(function compare(a, b) {
    if (a.card.Position === "Goalkeeper" && b.card.Position === "Defender") {
      return -1;
    } else if (a.card.Position === "Goalkeeper" && b.card.Position === "Midfielder") {
      return -1;
    } else if (a.card.Position === "Goalkeeper" && b.card.Position === "Forward") {
      return -1;
    } else if (a.card.Position === "Defender" && b.card.Position === "Midfielder") {
      return -1;
    } else if (a.card.Position === "Defender" && b.card.Position === "Forward") {
      return -1;
    } else if (a.card.Position === "Defender" && b.card.Position === "Goalkeeper") {
      return 1;
    } else if (a.card.Position === "Midfielder" && b.card.Position === "Forward") {
      return -1;
    } else if (a.card.Position === "Midfielder" && b.card.Position === "Defender") {
      return 1;
    } else if (a.card.Position === "Midfielder" && b.card.Position === "Goalkeeper") {
      return 1;
    } else if (a.card.Position === "Forward" && b.card.Position === "Midfielder") {
      return 1;
    } else if (a.card.Position === "Forward" && b.card.Position === "Defender") {
      return 1;
    } else if (a.card.Position === "Forward" && b.card.Position === "Goalkeeper") {
      return 1;
    }
    return 0;
  });
};
