import React from "react";
import PlayerRollingAverage from "./playerRollingAverage";
import { withUser } from "../../userContext";
import { checkSecondUnitShouldBeDisplayed, findPriceForUserUnit, formatPrice } from "../util/formatMoney";
import { capitalize } from "../util/capitalize";
import LimitedPoint from "../../img/limited-point.png";
import RarePoint from "../../img/rare-point.png";
import SuperRarePoint from "../../img/sr-point.png";
import UniquePoint from "../../img/unique-point.png";
import NewCardIcon from "../util/newCardIcon";
import { getValuationOrigin } from "../util/valuationOrigin";

function PlayerPriceEntry(props) {
  let unit = "Ξ";
  if (props.unit !== undefined) {
    unit = props.unit;
  }
  let bestPrice = formatPrice(props.bestPrice.Price, "eth");
  if (props.bestPrice === -1) {
    bestPrice = "None";
  }
  let valuation = props.valuation;
  const precision = props.precision;

  const primaryUnit = props.primaryUnit || props.user.preferredUnit;
  const secondaryUnit = props.secondaryUnit !== undefined ? props.secondaryUnit : props.user.secondUnit;
  const fiatValues = {
    usd: valuation?.UsdAverage,
    eur: valuation?.EurAverage,
    gbp: valuation?.GbpAverage,
  };
  let value = findPriceForUserUnit(valuation?.Average, fiatValues, primaryUnit);
  let secondPrice = findPriceForUserUnit(valuation?.Average, fiatValues, secondaryUnit);
  const shouldDisplaySecondUnit = checkSecondUnitShouldBeDisplayed(primaryUnit, secondaryUnit);
  let periodValue = "3 days";
  if (valuation !== undefined) {
    if (valuation.ValuationOrigin === "7d") {
      periodValue = "1 week";
    } else if (valuation.ValuationOrigin === "14d") {
      periodValue = "2 weeks";
    } else if (valuation.ValuationOrigin === "30d") {
      periodValue = "1 month";
    } else if (valuation.ValuationOrigin === "lifetime") {
      periodValue = "Card lifetime";
    }
    periodValue += " average";
    if (valuation.ValuationOrigin === "last_sale") {
      periodValue = "Last public sale";
    }
    if (valuation.ValuationOrigin === "bmp") {
      periodValue = "Floor price";
    }
    if (valuation.ValuationOrigin === "starting_price") {
      value = "---";
      unit = "";
      secondPrice = "---";
      periodValue = "---";
    }
  } else {
    periodValue = "---";
    secondPrice = "---";
    bestPrice = "---";
  }

  if (parseFloat(props.valuation?.Average) === -1 || parseFloat(props.valuation?.Average) === 0 || props.valuation === undefined) {
    value = "---";
    unit = "";
  }

  if (primaryUnit !== "eth" && valuation !== undefined) {
    value = formatPrice(valuation?.[capitalize(primaryUnit) + "Average"], primaryUnit);
    secondPrice = parseFloat(valuation?.Average).toFixed(precision) + " Ξ";
    bestPrice = formatPrice(props.bestPrice["Price" + capitalize(primaryUnit)], primaryUnit);
    if (props.bestPrice["Price" + capitalize(primaryUnit)] <= 0) {
      bestPrice = "---";
    }
    if (
      props.bestPrice["Price" + capitalize(primaryUnit)] > 0 &&
      props.bestPrice["Price" + capitalize(primaryUnit)] < valuation?.[capitalize(primaryUnit) + "Average"]
    ) {
      periodValue = "Floor price";
      value = bestPrice;
    }
    if (valuation?.ValuationOrigin === "starting_price") {
      value = "---";
      unit = "";
      secondPrice = "---";
      periodValue = "---";
    }
  }
  if (props.bestPrice.Price < 0) {
    bestPrice = "---";
  }

  let img = LimitedPoint;
  if (props.scarcity === "RARE") {
    img = RarePoint;
  } else if (props.scarcity === "SUPER RARE") {
    img = SuperRarePoint;
  } else if (props.scarcity === "UNIQUE") {
    img = UniquePoint;
  }

  let secondTip = getValuationOrigin(valuation?.ValuationOriginFiat);
  if (props.user.preferredUnit !== "eth" && valuation !== undefined) {
    periodValue = getValuationOrigin(valuation?.ValuationOriginFiat);
    secondTip = getValuationOrigin(valuation?.ValuationOrigin);
  }

  return (
    <div className={"bg-white rounded-lg w-full"}>
      <div className={"flex flex-row"}>
        <div className={"flex flex-col w-6/12 border-r border-grey-e5"}>
          <div className={"flex flex-row space-x-1 justify-center h-14 self-center"}>
            <div className={"w-6 h-6 self-center"}>
              <NewCardIcon scarcity={props.scarcity.toLowerCase()} />
            </div>
            <p className={"text-sm font-semibold self-center"}>{props.supplyAndAverage?.card_supply.count}</p>
          </div>
          <div className={"border-t border-grey-e5 w-full flex flex-col h-16 self-center items-center justify-center"}>
            <div className={"flex flex-row space-x-2 justify-center self-center"}>
              <img src={img} className={"w-2.5 h-2.5 self-center"} />
              <p style={{ color: props.color }} className={"font-sans text-base font-semibold text-center"} data-tip={periodValue}>
                {value}
              </p>
            </div>
            {shouldDisplaySecondUnit && (
              <div className={"self-center"}>
                <p className={"text-sm text-textGrey2 font-semibold text-center"} data-tip={secondTip}>
                  {secondPrice}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={"flex flex-col space-y-1 py-1 mx-4 xl:mx-4 w-6/12"}>
          <PlayerRollingAverage
            color={props.color}
            unit={primaryUnit}
            average={props.supplyAndAverage.average !== null ? props.supplyAndAverage.average[0] : null}
            period={"3d"}
          />
          <PlayerRollingAverage
            color={props.color}
            unit={primaryUnit}
            average={props.supplyAndAverage.average !== null ? props.supplyAndAverage.average[1] : null}
            period={"7d"}
          />
          <PlayerRollingAverage
            color={props.color}
            unit={primaryUnit}
            average={props.supplyAndAverage.average !== null ? props.supplyAndAverage.average[2] : null}
            period={"2w"}
          />
          <PlayerRollingAverage
            color={props.color}
            unit={primaryUnit}
            average={props.supplyAndAverage.average !== null ? props.supplyAndAverage.average[3] : null}
            period={"1m"}
          />
          <PlayerRollingAverage color={props.color} unit={primaryUnit} average={bestPrice} period={"floor"} />
        </div>
      </div>
    </div>
  );
}

export default withUser(PlayerPriceEntry);
