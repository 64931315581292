import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import React, { useCallback } from "react";
import { useTheme } from "../../themeContext";
import { sdLight } from "../../themeColors";

export const getColorFromPct = (pct, isZeroValid, theme) => {
  let color, trail;
  if (theme === sdLight) {
    if (pct === undefined || pct === null || pct < 0) {
      color = "#292929";
      trail = "#E5E5E5";
    } else if ((isZeroValid && pct === 0) || (pct > 0 && pct < 20)) {
      color = "#FF3232";
      trail = "#FFEAEA";
    } else if (pct > 0 && pct < 40) {
      color = "#F89F31";
      trail = "#FEF1E0";
    } else if (pct > 0 && pct < 60) {
      color = "#E7B62C";
      trail = "#FAF0D5";
    } else if (pct > 0 && pct < 80) {
      color = "#81BF50";
      trail = "#F0F7EA";
    } else if (pct > 0 && pct <= 100) {
      color = "#169A54";
      trail = "#DCF0E5";
    } else {
      color = "#292929";
      trail = "#E5E5E5";
    }
  } else {
    // TODO adapt to dark theme
    if (pct === undefined || pct === null || pct < 0) {
      color = "rgb(204, 204, 204)";
      trail = "rgb(204, 204, 204, 0.1)";
    } else if ((isZeroValid && pct === 0) || (pct > 0 && pct < 20)) {
      color = "#FF3232";
      trail = "rgba(255,50,50,0.1)";
    } else if (pct > 0 && pct < 40) {
      color = "#F89F31";
      trail = "rgba(248,159,49,0.1)";
    } else if (pct > 0 && pct < 60) {
      color = "#E7B62C";
      trail = "rgba(231,182,44,0.1)";
    } else if (pct > 0 && pct < 80) {
      color = "#81BF50";
      trail = "rgba(129,191,80,0.1)";
    } else if (pct > 0 && pct <= 100) {
      color = "#169A54";
      trail = "rgba(22,154,84,0.1)";
    } else {
      color = "rgb(204, 204, 204)";
      trail = "rgb(204, 204, 204, 0.1)";
    }
  }
  return { color, trail };
};

function CirclePct(props) {
  const { theme } = useTheme();

  let pct = 0;
  let negative = props.negative;
  let text = "";
  const getColors = useCallback((pct, isZeroValid) => getColorFromPct(pct, isZeroValid, theme), [theme]);
  if (props.value !== undefined) {
    if (props.showZero || props.value > 0) {
      pct = 100 / props.value;
      if (negative) {
        pct = 100 - pct;
      }
      if (props.odds === false) {
        pct = props.value;
      }
      if (negative) {
        pct = 100 / props.value;
      }
    }
    text = pct <= 0 ? "NA" : pct.toFixed(0) + "%";
    if (props.showZero) {
      text = pct < 0 ? "NA" : pct.toFixed(0) + "%";
    }
  } else {
    text = "NA";
  }
  const colors = getColors(pct, props.showZero);
  const color = colors.color;
  const trail = colors.trail;

  if (text === "100%") {
    text = "100";
  }

  return (
    <div className={"w-10"}>
      <CircularProgressbarWithChildren
        value={Number(pct)}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: "round",
          pathColor: color,
          trailColor: trail,
        })}
      >
        <p className={"text-xs font-semibold"} style={{ color: color }}>
          {text}
        </p>
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default CirclePct;
