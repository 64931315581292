import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { withRouter } from "react-router-dom";
import { Accordion, AccordionPanel } from "grommet";
import SDLoading from "../util/SDLoading";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { scarcities_objects_not_all } from "../util/scarcities";
import { positions_objects } from "../util/positions";
import { leagues_with_divisions_objects_not_all } from "../util/leagues";
import { PlayerStatusSelector } from "../util/playingStatus";
import { teams } from "../util/teams";
import AgeRange, { DEFAULT_AGE_RANGE } from "../util/ageRange";
import PriceFilter from "../util/priceRange";
import ScoreFilter from "../util/scoreFilter";
import ToggleWithLabel from "../util/toggleWithLabel";
import { Button } from "../util/button";
import { ReactTooltip } from "../util/tooltip.js";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { StandaloneMenu } from "../util/standaloneMenu";
import { ReactComponent as IconTrash } from "../../img/icons-trash.svg";
import Spinner from "../loader/spinner";
import PlayerSearchListResult from "../players/playerSearchListResult";
import ConfirmModal from "../util/confirmModal";
import AddSearchableItemsModal from "../search/addSearchableItemsModal";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import Locker from "../../img/brand-close-locker-nude.svg";

const defaultScoreFilter = {
  l5: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
  l15: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
  l40: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
};

const sortByLabels = {
  "realTimeLimited asc": "Price Limited (lowest to highest)",
  "realTimeLimited desc": "Price Limited (highest to lowest)",
  "bmpLimited asc": "Floor price Limited (lowest to highest)",
  "bmpLimited desc": "Floor Price Limited (highest to lowest)",
  "realTimeRare asc": "Price Rare (lowest to highest)",
  "realTimeRare desc": "Price Rare (highest to lowest)",
  "bmpRare asc": "Floor price Rare (lowest to highest)",
  "bmpRare desc": "Floor Price Rare (highest to lowest)",
  "realTimeSuperRare asc": "Price Super Rare (lowest to highest)",
  "realTimeSuperRare desc": "Price Super Rare (highest to lowest)",
  "bmpSuperRare asc": "Floor price SuperRare (lowest to highest)",
  "bmpSuperRare desc": "Floor Price SuperRare (highest to lowest)",
  "realTimeUnique asc": "Price Unique (lowest to highest)",
  "realTimeUnique desc": "Price Unique (highest to lowest)",
  "bmpUnique asc": "Floor price Unique (lowest to highest)",
  "bmpUnique desc": "Floor Price Unique (highest to lowest)",
  "updated asc": "Least recently updated",
  "updated desc": "Most recently updated",
};

const getLastUpdated = (notifications) => {
  let lastUpdated;
  Object.keys(notifications).map((scarcity) => {
    if (lastUpdated === undefined || Date.parse(notifications[scarcity].updated_at) > lastUpdated) {
      lastUpdated = Date.parse(notifications[scarcity].updated_at);
    }
  });
  return lastUpdated;
};

const PlayerRemoval = (props) => {
  const { player, removePriceAlert, error, loading } = props;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  return (
    <div key={player.PlayerId} className={"px-1 flex flex-row justify-center m-auto hover:opacity-80"}>
      <IconTrash
        title={`Click to remove all price alerts for "${player.DisplayName}"`}
        className="h-4 w-4 cursor-pointer"
        onClick={() => setOpenConfirmation(true)}
      />
      <ConfirmModal
        title={"Deletion confirmation"}
        error={error}
        message={`Do you want to remove all price alerts of "${player.DisplayName}"? This action is irreversible.`}
        confirming={loading}
        open={openConfirmation}
        onConfirm={() => removePriceAlert(player.PlayerId, [])}
        onCancel={() => setOpenConfirmation(false)}
      />
    </div>
  );
};

function PriceAlerts(props) {
  // const onClickOnAddPlayer = props.onClickOnAddPlayer;

  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState(undefined);
  const [notifications, setNotifications] = useState({});
  const [displayedPlayers, setDisplayedPlayers] = useState(undefined);
  const [nextGameweeks, setGameweeks] = useState([]);

  const [scarcity, setScarcity] = useState([]);
  const [league, setLeague] = useState([]);
  const [position, setPosition] = useState([]);
  const [playingStatus, setPlayingStatus] = useState("all");
  const [clubs, setClubs] = useState([]);
  const [bmpFilter, setBmpFilter] = useState(false);
  const [avgFilter, setAvgFilter] = useState(false);
  const [scoreFilters, setScoreFilters] = useState(defaultScoreFilter);
  const [bmpRange, setBmpRange] = useState([0, 1]);
  const [avgRange, setAvgRange] = useState([0, 1]);
  const [ageRange, setAgeRange] = useState(DEFAULT_AGE_RANGE);
  const [u23Only, setU23Only] = useState(false);
  const [gwFilter, setGwFilter] = useState(false);
  const [gwValue, setGwValue] = useState("");
  const [sortBy, setSortBy] = useState("updated desc");
  const [limitations, setLimitations] = useState({});
  const [baseballOnly, setBaseballOnly] = useState(false);

  const [showSortMenu, setShowSortMenu] = useState(false);

  const [openAddSearchableItemsModal, setOpenAddSearchableItemsModal] = useState(false);

  const limit = 50;

  useEffect(() => {
    getNotifications();
  }, [sortBy]);

  const resetFilters = () => {
    setScarcity([]);
    setLeague([]);
    setPosition([]);
    setPlayingStatus("all");
    setClubs([]);
    setBmpFilter(false);
    setAvgFilter(false);
    setScoreFilters(defaultScoreFilter);
    setBmpRange([0, 1]);
    setAvgRange([0, 1]);
    setAgeRange(DEFAULT_AGE_RANGE);
    setU23Only(false);
    setGwFilter(false);
    setGwValue("");
    setSortBy("updated desc");
  };

  const getNotifications = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/notifications/cards/offers/search?withNextGameweeks=true`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scarcity: scarcity,
          position: position,
          league: league,
          bmpRange: bmpFilter ? [parseFloat(bmpRange[0]), parseFloat(bmpRange[1])] : [],
          avgRange: avgFilter ? [parseFloat(avgRange[0]), parseFloat(avgRange[1])] : [],
          u23: u23Only,
          ageRange: ageRange,
          l5: scoreFilters.l5,
          l15: scoreFilters.l15,
          l40: scoreFilters.l40,
          playingStatus: playingStatus,
          teams: clubs,
          gwNumber: gwValue === "" ? 0 : parseInt(gwValue),
          gwFilter: gwFilter,
          sortBy: sortBy,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setPlayers(res.players || []);
          setNotifications(res.notifications || {});
          setDisplayedPlayers(sorted(res.players, res.notifications, sortBy, limit));
          let gws = [];
          if (res.next_gameweeks) {
            res.next_gameweeks.map((gw, index) => {
              gws.push({
                name: gw.DisplayName,
                value: gw.GwNumber,
                disabled: isFree(props.user.tier) && index !== 0,
                photo: Locker,
              });
            });
          }
          setGameweeks(gws);
        }
        getLimitations();
        setLoading(false);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  const sorted = (players, notifications, sortBy, limit) => {
    if (!players) {
      return [];
    }
    if (!sortBy.includes("updated")) {
      return players.slice(0, limit); // Sort is done by server
    }
    // sort is done client side
    const res = [...players]
      .sort((a, b) => {
        const aN = notifications[a.player.PlayerId];
        const bN = notifications[b.player.PlayerId];
        const asc = sortBy.includes("asc");
        if (aN === undefined && bN === undefined) {
          return 0;
        }
        if (aN === undefined && bN !== undefined) {
          return asc ? -1 : 1;
        }
        if (aN !== undefined && bN === undefined) {
          return asc ? 1 : -1;
        }
        const aMostRecent = getLastUpdated(aN);
        const bMostRecent = getLastUpdated(bN);
        if (aMostRecent < bMostRecent) {
          return asc ? -1 : 1;
        } else if (aMostRecent === bMostRecent) {
          return 0;
        } else {
          return asc ? 1 : -1;
        }
      })
      .slice(0, limit);
    return res;
  };

  const removePriceAlert = (playerId, scarcities) => {
    if (playerId) {
      setLoading(true);
      scarcities = scarcities !== undefined && scarcities.length > 0 ? scarcities : scarcities_objects_not_all.map((o) => o.value);
      const params = new URLSearchParams(scarcities.map((s) => ["scarcity", s]));
      props
        .fetch(`/apiv2/notifications/cards/offers/players/${playerId}?${params.toString()}`, { method: "DELETE" })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            if (scarcities.length === scarcities_objects_not_all.length) {
              setPlayers(players.filter((p) => p.player.PlayerId !== playerId));
              setDisplayedPlayers(displayedPlayers.filter((p) => p.player.PlayerId !== playerId));
            }
            const newNotifications = { ...notifications };
            scarcities.forEach((scarcity) => delete newNotifications[playerId][scarcity]);
            setNotifications(newNotifications);
          }
          getLimitations();
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const savePriceAlert = (notification) => {
    const { player_id, scarcity } = notification;
    if (!player_id || !scarcity) {
      return;
    }
    const previousPrice = notifications[player_id]?.[scarcity]?.price || undefined;
    const previousUnit = notifications[player_id]?.[scarcity]?.unit || "eth";
    if (previousPrice === notification.price && previousUnit === notification.unit) {
      return; // no change
    }
    if (notification.price !== undefined && notification.price > 0) {
      setLoading(true);
      props
        .fetch(`/apiv2/notifications/cards/offers/players/${player_id}`, {
          method: "PATCH",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ [scarcity]: notification }),
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            const newNotifications = { ...notifications };
            if (newNotifications[player_id] === undefined) {
              newNotifications[player_id] = {};
            }
            newNotifications[player_id][scarcity] = res[scarcity];
            setNotifications(newNotifications);
          }
          getLimitations();
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    } else {
      removePriceAlert(player_id, [scarcity]);
    }
  };

  const onSaveAddPlayers = (playerIds) => {
    setLoading(true);
    props
      .fetch("/apiv2/players/searchAdvanced", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          playerIds: playerIds,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          if (players) {
            const existingPlayerIds = {};
            players.map((p) => {
              existingPlayerIds[p.player.PlayerId] = p;
            });
            if (res.players) {
              // Keeping only the new ones
              res.players = res.players.filter((p) => !existingPlayerIds[p.player.PlayerId]);
            }
          }
          setPlayers([...res.players, ...players]);
          setDisplayedPlayers([...res.players, ...displayedPlayers]);
          setOpenAddSearchableItemsModal(false);
        }
        getLimitations();
        setLoading(false);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  const activatePriceAlert = (playerId) => {
    setLoading(true);
    props
      .fetch(`/apiv2/notifications/cards/offers/players/${playerId}/enable`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          const newNotifications = { ...notifications };
          if (newNotifications[playerId] === undefined) {
            newNotifications[playerId] = {};
          }
          newNotifications[playerId] = res;
          setNotifications(newNotifications);
        }
        getLimitations();
        setLoading(false);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  const getLimitations = () => {
    setLoading(true);
    props
      .fetch("/apiv2/notifications/cards/offers/new/can")
      .then((response) => response.json())
      .then((res) => {
        if (!res.creationAuthorized) {
          setBaseballOnly(true);
        }
        setLimitations(res);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const sortMenuItems = Object.keys(sortByLabels).map((key) => {
    const label = sortByLabels[key];
    return {
      key: key,
      label: label,
      className: "w-full sm:w-72 cursor-pointer " + (sortBy === key ? "font-semibold" : ""),
      onClick: () => {
        setShowSortMenu(false);
        setSortBy(key);
      },
    };
  });

  const sortByStatus = (field) => {
    let sort = "";
    if (sortBy.includes(field)) {
      sort = field + (sortBy.includes("asc") ? " desc" : " asc");
    } else {
      sort = field + " asc";
    }
    setSortBy(sort);
  };

  const nbPlayersWithoutNotif = players?.filter((p) => !notifications[p.player.PlayerId]).length || 0;

  return (
    <div className={"h-full"}>
      <ReactTooltip />
      <div className={"mb-4"}>
        <Accordion>
          <AccordionPanel label={"Filters"}>
            <div className={"px-0 xl:px-12 2xl:px-24 3xl:px-36 space-y-2 pt-4"}>
              <div className={"md:grid md:grid-cols-2 md:gap-2 lg:grid-cols-3 xl:grid-cols-4 space-y-1 md:space-y-0"}>
                <div className={"z-40"}>
                  <SelectSearch
                    multiple
                    closeOnSelect={false}
                    options={scarcities_objects_not_all}
                    value={scarcity}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one scarcity or more"
                    onChange={(v) => setScarcity(v)}
                  />
                </div>
                <div className={"z-50"}>
                  <SelectSearch
                    multiple
                    closeOnSelect={false}
                    options={positions_objects}
                    value={position}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one position or more"
                    onChange={(v) => setPosition(v)}
                  />
                </div>
                <div className={"z-40"}>
                  <SelectSearch
                    multiple
                    closeOnSelect={false}
                    options={leagues_with_divisions_objects_not_all}
                    value={league}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one league or more"
                    onChange={(v) => setLeague(v)}
                  />
                </div>
                <div className={"z-40"}>
                  <PlayerStatusSelector playingStatus={playingStatus} onChange={(v) => setPlayingStatus(v)} tier={props.user.tier} />
                </div>
                <div className={"z-30"}>
                  <SelectSearch
                    options={teams}
                    onChange={(v) => setClubs(v)}
                    search
                    multiple
                    value={clubs}
                    closeOnSelect={false}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one club or more"
                  />
                </div>
                <div className={"z-40"}>
                  <AgeRange range={ageRange} setU23={() => setU23Only(!u23Only)} u23={u23Only} setRange={(ar) => setAgeRange(ar)} />
                </div>
                <PriceFilter
                  tip={scarcity.length === 0 && "(pick scarcities first)"}
                  watchlist={true}
                  bmp={bmpFilter}
                  bmpRange={bmpRange}
                  avg={avgFilter}
                  avgRange={avgRange}
                  tier={props.user.tier}
                  updateFilters={async (c, cr, bmp, bmpr, avg, avgr, ubmp, uavg) => {
                    await setBmpFilter(bmp);
                    await setBmpRange(bmpr);
                    await setAvgFilter(avg);
                    await setAvgRange(avgr);
                  }}
                />
                <ScoreFilter
                  l5={scoreFilters.l5}
                  l15={scoreFilters.l15}
                  l40={scoreFilters.l40}
                  setScoreFilters={async (f) => {
                    await setScoreFilters(f);
                  }}
                />
              </div>
              <div className={"flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between md:space-x-8 pt-2"}>
                <div className={"flex flex-row self-center space-x-4"}>
                  <div className={"md:self-center"}>
                    <ToggleWithLabel
                      label={"Plays during gameweek"}
                      isActive={gwFilter}
                      setActive={() => {
                        setGwFilter(!gwFilter);
                      }}
                    />
                  </div>
                  <div className={"self-center pb-1 z-20"}>
                    <SelectSearch
                      options={nextGameweeks}
                      value={gwValue}
                      onChange={async (v) => {
                        setGwValue(v);
                      }}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      placeholder="Filter by gameweek"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <Button context={"secondary"} label={"Reset"} onClick={resetFilters} />
                  <Button label={"Filter"} onClick={() => getNotifications()} />
                </div>
              </div>
            </div>
          </AccordionPanel>
        </Accordion>
      </div>
      {isFree(props.user.tier) && (
        <UpgradeLimitBox
          className={"bg-white bg-upgrade-bg-price-alerts bg-cover h-upgrade-banner-l w-full mb-4"}
          title={"Want more price alerts?"}
          description={"Become a Star member to unlock unlimited price alerts"}
        />
      )}
      <div className={"relative mb-4 flex flex-col sm:flex-row justify-between gap-2"}>
        <div className={"text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
          Sort by: {sortByLabels[sortBy]}
          <span className={"ml-2 my-auto"}>
            <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
          </span>
        </div>
        <div className="flex flex-row gap-2 justify-end">
          {loading && <Spinner />}
          <button
            // data-tip={!limitations.creationAuthorized ? `You reached the limit of ${limitations.max} players with price alerts for your membership. Either: upgrade your membership, remove some to reactivate disabled ones, remove all disabled and some active ones to add new ones` : ""}
            className={`bg-brand text-white p-2 pl-10 pr-10 text-sm font-medium rounded-md focus:outline-none  hover:bg-brand-lightWatchlist`}
            onClick={() => setOpenAddSearchableItemsModal(true)}
          >
            + Add players
          </button>
        </div>
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
          show={showSortMenu}
          onClickOutside={() => setShowSortMenu(false)}
          items={sortMenuItems}
        />
      </div>
      <div className={"h-full mb-8"}>
        {displayedPlayers !== undefined && displayedPlayers.length === 0 && (
          <div className={"flex flex-col justify-center w-full h-full bg-focus rounded-lg text-center border border-grey-e9"}>
            <div className={"m-auto py-36"}>
              <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
              <p className={"text-lg font-headers text-center font-bold mb-6"}>{"No price alert setup"}</p>
              <button
                onClick={() => setOpenAddSearchableItemsModal(true)}
                type="button"
                // data-tip={!limitations.creationAuthorized ? `You reached the limit of football ${limitations.max} players with price alerts for your membership.  Either: upgrade your membership, remove some to reactivate disabled ones, remove all disabled and some actives ones to add new ones` : ""}
                className={`inline-flex items-center px-12 py-2.5 border border-transparent text-lg font-semibold rounded-lg shadow-sm text-white bg-brand focus:outline-none focus:ring-2 focus:ring-brand-light hover:bg-brand-light`}
              >
                {`+ Add players`}
              </button>
            </div>
          </div>
        )}
        {nbPlayersWithoutNotif > 0 && (
          <p className={"text-center p-2 text-sm text-live-red"}>
            ⚠️ {nbPlayersWithoutNotif} players have no price alert set. Refreshing the page, sorting results or launching a new search will
            remove them.
          </p>
        )}
        {displayedPlayers !== undefined && displayedPlayers.length > 0 && (
          <div>
            <InfiniteScroll
              next={() => setDisplayedPlayers(sorted(players, notifications, sortBy, displayedPlayers.length + limit))}
              hasMore={displayedPlayers.length < players.length}
              loader={<SDLoading />}
              dataLength={displayedPlayers.length}
            >
              <PlayerSearchListResult
                columns={[
                  "notifications",
                  "name",
                  "realTimeLimited",
                  "bmpLimited",
                  "realTimeRare",
                  "bmpRare",
                  "realTimeSuperRare",
                  "bmpSuperRare",
                  "realTimeUnique",
                  "bmpUnique",
                ]}
                notifications={notifications}
                notificationLimitations={limitations}
                actionFirst={true}
                onChangeNotifications={savePriceAlert}
                onDeleteNotification={(playerId, scarcity) => removePriceAlert(playerId, [scarcity])}
                sortBy={sortBy}
                onChangeSort={sortByStatus}
                onActivatePriceAlert={activatePriceAlert}
                displayedPlayers={displayedPlayers}
                actionItems={
                  displayedPlayers?.map((p) => {
                    return (
                      <PlayerRemoval key={p.player.PlayerId} player={p.player} removePriceAlert={removePriceAlert} loading={loading} />
                    );
                  }) || []
                }
              />
            </InfiniteScroll>
          </div>
        )}
      </div>
      <AddSearchableItemsModal
        open={openAddSearchableItemsModal}
        onSave={onSaveAddPlayers}
        onCancel={() => setOpenAddSearchableItemsModal(false)}
        sport={baseballOnly ? "sr_baseball" : "multi"}
        msg={
          baseballOnly
            ? `You can only add Baseball players as you reached the limit of ${limitations.max} football players with price alerts for your membership. Either: upgrade your membership, remove some to reactivate disabled ones, remove all disabled and some active ones to add new ones`
            : ""
        }
      />
    </div>
  );
}

export default withRouter(withUser(PriceAlerts));
