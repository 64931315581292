import React, { useEffect } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { sortPlayersByPosition } from "../util/so5";
import SO5AvatarLineupCard from "./SO5AvatarLineupCard";

const SO5AvatarLineup = (props) => {
  const { stats, games, lineup } = props;
  useEffect(() => ReactTooltip.rebuild(), []);
  let players = [];
  if (props.players !== undefined) {
    players = props.players?.rare_players;
    if (players === null || players === undefined) {
      players = props.players?.common_players;
    } else if (props.players.common_players != null && props.players.common_players !== undefined) {
      players = players.concat(props.players.common_players);
    }
  }
  sortPlayersByPosition(players);

  return players?.length > 0 ? (
    <div className={"mx-auto text-center space-y-1"} id={lineup.DivisionId}>
      <div className={"grid grid-cols-5 gap-0.5 inline-grid"} style={{ minWidth: "20em" }}>
        {players.map((p, i) => {
          let SO5Score = "DNP";
          if (games[p.player.TeamId] === undefined && games[p.player.NationalTeam] === undefined) {
            SO5Score = parseFloat(0).toFixed(2);
          }
          let power = parseFloat(p.lineup_player.Power);
          let captain = false;
          if (p.lineup_player.IsCaptain) {
            captain = true;
          }

          if (stats[p.player.PlayerId] !== undefined) {
            SO5Score = stats[p.player.PlayerId].SO5Score;
            if (p.card.Position === "Defender") {
              SO5Score = stats[p.player.PlayerId].SO5ScoreDF;
            } else if (p.card.Position === "Midfielder") {
              SO5Score = stats[p.player.PlayerId].SO5ScoreMD;
            } else if (p.card.Position === "Forward") {
              SO5Score = stats[p.player.PlayerId].SO5ScoreFW;
            } else if (p.card.Position === "Goalkeeper") {
              SO5Score = stats[p.player.PlayerId].SO5ScoreGK;
            }

            if (
              p.player.NationalTeam !== "" &&
              !(lineup.DivisionId.includes("global") || lineup.DivisionId.includes("weekly")) &&
              stats[p.player.PlayerId]?.TeamId === p.player.NationalTeam
            ) {
              SO5Score = "0.0";
            } else if (SO5Score !== "DNP") {
              SO5Score = parseFloat(SO5Score).toFixed(1);
            }
          }
          let game = games[p.player.TeamId];

          if (p.player.NationalTeam !== "" && (lineup.DivisionId.includes("global") || lineup.DivisionId.includes("weekly"))) {
            game = games[p.player.NationalTeam];
            if (game === undefined) {
              game = games[p.player.TeamId];
            }
          }
          return (
            <SO5AvatarLineupCard
              key={i}
              game={game}
              player={p.player}
              power={power}
              SO5Score={SO5Score}
              stats={stats[p.player.PlayerId]}
              card={p.card}
              captain={captain}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <span className={"text-sm text-on-surface-variant"}>No lineup for this tournament</span>
  );
};

export default SO5AvatarLineup;
