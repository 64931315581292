import React from "react";

import { getFootballDecisiveScoreColors } from "./scales";

export const DecisiveActionsScore = (props) => {
  const { score } = props;
  const [scoreColor, scoreBackgroundColor] = getFootballDecisiveScoreColors(score);
  return (
    <div
      className={"flex text-sm font-semibold justify-center rounded-full text-center w-10 h-10 mx-auto"}
      style={{ backgroundColor: scoreBackgroundColor, color: scoreColor }}
    >
      <span className={"self-center"}>{score}</span>
    </div>
  );
};
