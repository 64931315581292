import React, { useEffect, useState } from "react";
import ToggleWithLabel from "./toggleWithLabel";
import { Range } from "rc-slider";
import { sorareBasketball } from "./sports";

function ScoreRange(props) {
  const [value, setValue] = useState(props.value);

  const onSliderChange = (value) => {
    setValue(value);
    props.setValue(value);
  };

  return (
    <div className={"space-y-1"}>
      <p className={"text-md font-semibold"}>{props.label}</p>
      <Range
        trackStyle={!props.disabled && [{ backgroundColor: "#f45206" }]}
        handleStyle={!props.disabled && [{ border: "solid 2px #f45206" }, { border: "solid 2px #f45206" }]}
        disabled={props.disabled}
        value={value}
        min={props.min}
        max={props.max}
        step={props.step}
        onChange={(v) => onSliderChange(v)}
      />
      <div className={"justify-between flex flex-row text-md font-semibold"}>
        <span>{value[0]}</span>
        <span>{value[1]}</span>
      </div>
    </div>
  );
}

function ScoreFilterSplit(props) {
  // scores may have different fields, depending on where it's used in the app
  // Making sure that it works for all cases
  const [enabled, setEnabled] = useState(props.obj?.enabled);
  const [avg, setAvg] = useState(props.obj?.average_range || props.obj?.scoreRange);
  const [decisive, setDecisive] = useState(props.obj?.decisive_range || props.obj?.daRange);
  const [aa, setAa] = useState(props.obj?.all_around_range || props.obj?.aaRange);

  const sport = props.sport;

  useEffect(() => {
    props.setObj({
      enabled: enabled,
      average_range: avg,
      scoreRange: avg,
      decisive_range: decisive,
      daRange: decisive,
      all_around_range: aa,
      aaRange: aa,
    });
  }, [enabled, avg, decisive, aa]);

  return (
    <div className={"space-y-4"}>
      <ToggleWithLabel isActive={enabled} label={"Over the past " + props.gms + " games"} setActive={() => setEnabled(!enabled)} />
      <div className={"space-y-4"}>
        <ScoreRange value={avg} setValue={(v) => setAvg(v)} step={1} min={0} max={100} label={"SO5 score average"} disabled={!enabled} />
        {sport !== sorareBasketball && (
          <ScoreRange
            value={decisive}
            setValue={(v) => setDecisive(v)}
            step={1}
            min={0}
            max={100}
            label={"Decisive score average"}
            disabled={!enabled}
          />
        )}
        {sport !== sorareBasketball && (
          <ScoreRange
            value={aa}
            setValue={(v) => setAa(v)}
            step={1}
            min={-20}
            max={100}
            label={"All-around score average"}
            disabled={!enabled}
          />
        )}
      </div>
    </div>
  );
}

export default ScoreFilterSplit;
