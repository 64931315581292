import React from "react";
import { Spotify } from "grommet-icons";
import { Youtube } from "grommet-icons/es6";

const FooterSD = () => {
  return (
    <footer className={"bg-brand-light-green text-white"}>
      <div className={"mx-auto py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between md:w-11/12 md:mx-auto"}>
        <div className="flex justify-center space-x-6 md:order-2 ">
          <a
            href="https://open.spotify.com/show/6XWLpPDTlYK2ycGt87b1dK"
            target={"_blank"}
            className="text-white self-center"
            rel="noreferrer"
          >
            <span className="sr-only">Spotify</span>
            <Spotify color={"white"} />
          </a>
          <a
            href="https://www.youtube.com/channel/UC5uxFJ8HpynQrl3dyp32Y8Q"
            target={"_blank"}
            className="text-white self-center"
            rel="noreferrer"
          >
            <span className="sr-only">YouTube</span>
            <Youtube color={"white"} />
          </a>
          <a href="https://twitter.com/soraredata" target={"_blank"} className="text-white self-center" rel="noreferrer">
            <span className="sr-only">Twitter</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
          <a href="mailto:support@soraredata.com" className="text-white self-center">
            <span className="sr-only">Email</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
          </a>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <div className="text-center text-base flex flex-row gap-1">
            <span>
              2020 - 2024 - SorareData, a{" "}
              <a className={"hover:underline font-semibold"} target={"_blank"} href={"https://podium.top"} rel="noreferrer">
                Podium
              </a>{" "}
              product
            </span>
            <span>-</span>
            <span className={"font-semibold hover:underline"}>
              <a href={"/terms-of-use"} target={"_blank"} rel="noreferrer">
                Terms of Use
              </a>
            </span>
            <span>-</span>
            <span className={"font-semibold hover:underline"}>
              <a href={"/privacy-policy"} target={"_blank"} rel="noreferrer">
                Privacy Policy
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSD;
