import React, { useState } from "react";
import { withUser } from "../../userContext";
import { ReactTooltip } from "../util/tooltip.js";
import { format } from "date-fns";
import UnknownClub from "../../img/unknown_club.png";
import LiveCircle from "../util/liveCircle";
import Started from "../../img/starter.svg";
import ScoreEllipse from "../util/scoreEllipse";
import PlayerAvatar from "../util/playerAvatar";
import PlayerScoreModal from "../players/playerScoreModal";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";

function PlayerInGame(props) {
  const [isOpen, setOverlay] = useState(false);
  let p = props.player;
  let game = props.game;
  return (
    <div>
      <PlayerScoreModal
        lineups={{ lineups: p.lineups }}
        open={isOpen}
        stats={p.stats}
        player={p.player}
        position={p.player.Position}
        close={() => setOverlay(false)}
        game={game}
        sport={props.sport}
      />
      <div className={"flex flex-col items-center self-center cursor-pointer space-y-1"} onClick={() => setOverlay(true)}>
        <div className={"flex flex-row self-center justify-center"}>
          <PlayerAvatar sport={props.sport} noLink={true} player={p.player} linedUpPlayer={!p.off_lineups} />
        </div>
        {game.game.HasLineups && (
          <ScoreEllipse
            sport={props.sport}
            formationPlace={p.stats && p.stats.FormationPlace}
            minsPlayed={p.stats && p.stats.mins_played}
            onGameSheet={p.stats && p.stats.OnGameSheet}
            game={game.game}
            power={1}
            size={"text-sm"}
            score={p.so5_score}
          />
        )}
      </div>
    </div>
  );
}

function SO5Game(props) {
  ReactTooltip.rebuild();

  const game = props.game;
  const sport = props.sport;
  let date = new Date(game.game.GameTime);
  let homeScore = game.game.HomeScore;
  let awayScore = game.game.AwayScore;
  if (sport === sorareFootball) {
    homeScore = game.game.HomeGoals;
    awayScore = game.game.AwayGoals;
  }
  let homeWins = homeScore > awayScore;
  let awayWins = homeScore < awayScore;
  let homeWeight = "";
  let awayWeight = "";
  let mapEntry = [];
  const map = props.map;
  if (map !== null && map[game.game.GameId] !== undefined) {
    mapEntry = map[game.game.GameId];
  }
  if (homeWins) homeWeight = "font-bold";
  if (awayWins) awayWeight = "font-bold";
  let players = [];
  if (props.map !== null) {
    players = props.map[game.game.GameId];
  }
  let playersShown = [];
  let link = "/game/" + game.game.GameId;
  let period = game.game.TimeMin + "'";
  if (sport === sorareBasketball) {
    period = "Q" + game.game.LiveDetails?.lastPlay?.quarter + " - " + game.game.LiveDetails?.lastPlay?.clock;
    link = "/basketballGame?gameId=" + game.game.GameId;
  } else if (sport === sorareBaseball) {
    link = "/baseballGame?gameId=" + game.game.GameId;
  }
  return (
    <div
      className={
        props.last
          ? "flex flex-col relative bg-white py-4 space-y-1"
          : "flex flex-col border-b border-grey-e5 relative bg-white py-4 space-y-1"
      }
    >
      <div className={"flex flex-row justify-center space-x-4 px-6"}>
        {sport === sorareFootball && (
          <div className={"flex w-12 flex-row self-center"}>
            {game.game.Status === "playing" && (
              <a target={"_blank"} href={link} rel="noreferrer">
                <div className={"flex flex-row space-x-1 self-center"}>
                  <div className={"self-center"}>
                    <LiveCircle color={"#E73107"} />
                  </div>
                  <p className={"text-rare font-semibold font-headers text-sm"}>{period}</p>
                </div>
              </a>
            )}
            {game.game.HasLineups && game.game.Status === "scheduled" && (
              <a target={"_blank"} href={link} rel="noreferrer">
                <div className={"w-6 h-6"}>
                  <img src={Started} className={"w-full h-full object-contain"} />
                </div>
              </a>
            )}
            {game.game.Status === "played" && (
              <a target={"_blank"} href={link} rel="noreferrer">
                <p className={"text-brand font-semibold font-headers text-textGrey3 text-sm"}>FT</p>
              </a>
            )}
          </div>
        )}
        {sport === sorareBasketball && (
          <div className={"flex w-24 flex-row self-center"}>
            {game.game.Status === "inprogress" && (
              <a target={"_blank"} href={link} rel="noreferrer">
                <div className={"flex flex-row space-x-1 self-center"}>
                  <div className={"self-center"}>
                    <LiveCircle color={"#E73107"} />
                  </div>
                  <p className={"text-rare font-semibold font-headers text-sm"}>{period}</p>
                </div>
              </a>
            )}
            {game.game.Status === "halftime" && (
              <a target={"_blank"} href={link} rel="noreferrer">
                <p className={"text-brand font-semibold font-headers text-textGrey3 text-sm"}>HT</p>
              </a>
            )}
            {(game.game.Status === "closed" || game.game.Status === "complete") && (
              <a target={"_blank"} href={link} rel="noreferrer">
                <p className={"text-brand font-semibold font-headers text-textGrey3 text-sm"}>FT</p>
              </a>
            )}
          </div>
        )}
        <div className={"flex flex-row w-6/12 justify-end space-x-4"}>
          <p className={"text-base font-semibold self-center"}>{game.home_team}</p>
          <div className={"w-8 h-8 self-center"}>
            <img className={"w-full h-full object-contain"} src={game.home_team_logo !== "" ? game.home_team_logo : UnknownClub} />
          </div>
        </div>
        <div className={"w-16 flex flex-row justify-center self-center text-center"}>
          <a className={"self-center"} target={"_blank"} href={link} color={"#292929"} rel="noreferrer">
            {(game.game.Status === "playing" ||
              game.game.Status === "played" ||
              game.game.Status === "closed" ||
              game.game.Status === "complete" ||
              game.game.Status === "inprogress" ||
              game.game.Status === "halftime") && (
              <div className={"flex flex-row text-base self-center space-x-1 font-medium"}>
                <p className={"self-center " + homeWeight}>{homeScore}</p>
                <p className={"self-center"}> - </p>
                <p className={"self-center " + awayWeight}>{awayScore}</p>
              </div>
            )}
            {(game.game.Status === "scheduled" || game.game.Status === "created") && (
              <div className={"flex text-base font-semibold text-textGrey3 flex-row"}>
                <p>{format(new Date(game.game.GameTime), "HH:mm")}</p>
              </div>
            )}
          </a>
        </div>

        <div className={"flex flex-row w-6/12 justify-start space-x-4"}>
          <div className={"w-8 h-8 self-center"}>
            <img className={"w-full h-full object-contain"} src={game.away_team_logo !== "" ? game.away_team_logo : UnknownClub} />
          </div>
          <p className={"text-base font-semibold self-center"}>{game.away_team}</p>
        </div>
        {sport === sorareBasketball && <div className={"flex w-24 flex-row self-center"}></div>}
        {sport === sorareFootball && <div className={"flex w-12 flex-row self-center"}></div>}
      </div>
      {
        <div className={"flex flex-row space-x-3 justify-center"}>
          {props.displayScores &&
            players !== undefined &&
            players.map((p) => {
              if (playersShown.indexOf(p.player.PlayerId) < 0) {
                playersShown.push(p.player.PlayerId);
              } else {
                return null;
              }
              return <PlayerInGame key={p.player.PlayerId} sport={p.player.Sport} player={p} game={game} />;
            })}
        </div>
      }
    </div>
  );
}

export default withUser(SO5Game);
