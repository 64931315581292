import React, { useState } from "react";
import ScoreEllipse from "../util/scoreEllipse";
import PlayerScoreModal from "../players/playerScoreModal";
import { getDecisiveActionIcons } from "../util/so5";

function SO5AvatarLineupCard(props) {
  let { card, game, power, SO5Score, stats, player } = props;
  let isCaptain = props.captain;
  const [isOpen, setOverlay] = useState(false);
  const icons = getDecisiveActionIcons(stats, 3);
  return (
    <div>
      {stats !== undefined && (
        <PlayerScoreModal
          open={isOpen}
          stats={stats}
          player={player}
          position={card.Position}
          close={() => setOverlay(false)}
          game={game}
        />
      )}
      <div className={"flex flex-col gap-1"}>
        <div className={"flex justify-center relative"}>
          {card.PlayerId !== undefined ? (
            <div className={"flex flex-row self-end justify-end"}>
              <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
                <img className={"object-contain h-10 w-10 rounded-full"} src={card.AvatarUrl || player.Avatar} />
              </a>
            </div>
          ) : (
            <img className={"object-contain"} src={card.AvatarUrl || player.Avatar} />
          )}
          <div className={"absolute -bottom-1 items-center flex flex-col z-10"}>
            <div className={"flex flex-row justify-center mx-auto z-10 -space-x-1"}>{icons}</div>
          </div>
        </div>
        <div className={"flex flex-col self-center"}>
          <div onClick={() => setOverlay(true)} className={"flex flex-row self-center justify-center mx-auto cursor-pointer"}>
            <div className={"flex flex-row self-center justify-center items-baseline"}>
              <ScoreEllipse
                padding="px-1 py-0.5"
                size="text-xs"
                score={SO5Score}
                power={power}
                isCaptain={isCaptain}
                formationPlace={stats?.FormationPlace}
                onGameSheet={stats?.OnGameSheet}
                minsPlayed={stats?.mins_played}
                game={game?.game}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SO5AvatarLineupCard;
