export const fiatUnits = { eur: "€", usd: "$", gbp: "£" };
export const ethUnit = { eth: "Ξ" };
export const noneUnit = { "": "None" };
export const units = { ...ethUnit, ...fiatUnits };
export const units_objects = [
  { name: "Ξ", value: "eth" },
  { name: "€", value: "eur" },
  { name: "$", value: "usd" },
  { name: "£", value: "gbp" },
];
