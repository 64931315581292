import React, { Component } from "react";
import { Box, CheckBox, Heading, Select } from "grommet";
import { ReactTooltip } from "../util/tooltip.js";
import SO5PlayerLine from "./SO5PlayerLine";
import ResponsiveGrid from "../util/responsiveGrid";
import { leagues_with_divisions } from "../util/leagues";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

class SO5TopPerformers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topPerformers: [],
      worstPerformers: [],
      positionValue: "Overall",
      leagueValue: leagues_with_divisions[0],
      u23Only: false,
    };
  }

  componentDidMount() {
    this.getTopPerformers();
    this.getWorstPerformers();
  }

  getTopPerformers = () => {
    let positionValue = this.state.positionValue;
    let leagueValue = this.state.leagueValue;
    if (positionValue === "Overall") positionValue = "all";
    if (leagueValue === leagues_with_divisions[0]) leagueValue = "all";
    if (this.props.gwNumber !== undefined && this.props.gwNumber > 0) {
      this.props
        .fetch("/apiv2/SO5/topPerformers/" + positionValue + "/" + this.state.u23Only + "/" + this.props.gwNumber + "/" + leagueValue)
        .then((response) => response.json())
        .then(async (res) => {
          this.setState({ topPerformers: res });
        })
        .catch(errorCatcher());
    }
  };

  getWorstPerformers = () => {
    let positionValue = this.state.positionValue;
    let leagueValue = this.state.leagueValue;
    if (positionValue === "Overall") positionValue = "all";
    if (leagueValue === leagues_with_divisions[0]) leagueValue = "all";
    if (this.props.gwNumber !== undefined && this.props.gwNumber > 0) {
      this.props
        .fetch("/apiv2/SO5/worstPerformers/" + positionValue + "/" + this.state.u23Only + "/" + this.props.gwNumber + "/" + leagueValue)
        .then((response) => response.json())
        .then(async (res) => {
          this.setState({ worstPerformers: res });
        })
        .catch(errorCatcher());
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.gwNumber !== this.props.gwNumber) {
      this.getTopPerformers();
      this.getWorstPerformers();
    }
  }

  u23Only = async (e) => {
    await this.setState({ u23Only: e.target.checked });
    this.getTopPerformers();
    this.getWorstPerformers();
  };

  setPositionValue = async (positionValue) => {
    await this.setState({ positionValue });
    this.getTopPerformers();
    this.getWorstPerformers();
  };

  setLeagueValue = async (leagueValue) => {
    await this.setState({ leagueValue });
    this.getTopPerformers();
    this.getWorstPerformers();
  };

  render() {
    const topPerformers = this.state.topPerformers;
    const worstPerformers = this.state.worstPerformers;
    const positionValue = this.state.positionValue;
    const leagueValue = this.state.leagueValue;
    let columns = {
      small: ["auto"],
      medium: ["auto", "auto"],
      large: ["auto", "auto"],
    };

    const rows = {
      small: ["auto"],
      medium: ["auto"],
      large: ["auto"],
    };
    return (
      <Box align={"center"} width={"auto"} margin={"auto"}>
        <Heading level={"2"} color={"brand"}>
          This week top/worst 10 performances
        </Heading>
        <Box>
          <Box direction={"row"}>
            <Box align={"center"} margin={{ horizontal: "auto", bottom: "small" }}>
              <Select
                options={["Overall", "Goalkeeper", "Defender", "Midfielder", "Forward"]}
                value={positionValue}
                onChange={({ option }) => this.setPositionValue(option)}
              />
            </Box>
            <Box align={"center"} margin={{ horizontal: "auto", bottom: "small" }}>
              <Select options={leagues_with_divisions} value={leagueValue} onChange={({ option }) => this.setLeagueValue(option)} />
            </Box>
          </Box>
          <Box align={"center"} justify={"center"} margin={{ bottom: "small" }} direction={"row"}>
            <CheckBox checked={this.state.u23Only} label="U23 eligible" onChange={(event) => this.u23Only(event)} />
          </Box>

          {topPerformers?.length > 0 && worstPerformers?.length > 0 && (
            <ResponsiveGrid columns={columns} rows={rows}>
              <Box>
                {topPerformers.map((p, i) => {
                  return (
                    <Box
                      width={"100%"}
                      pad={{ vertical: "xsmall", horizontal: "medium" }}
                      border={{ color: "#E8E8E8", size: "xsmall" }}
                      background={{ color: "white" }}
                      key={i + p.player.PlayerId + i + p.player.Age}
                    >
                      <SO5PlayerLine key={p.player.PlayerId + i + p.player.Age} player={p} gwNumber={this.props.gwNumber} />
                    </Box>
                  );
                })}
              </Box>
              <Box>
                {worstPerformers.map((p, i) => {
                  return (
                    <Box
                      width={"100%"}
                      pad={{ vertical: "xsmall", horizontal: "medium" }}
                      border={{ color: "#E8E8E8", size: "xsmall" }}
                      background={{ color: "white" }}
                      key={p.player.PlayerId + i}
                    >
                      <SO5PlayerLine player={p} gwNumber={this.props.gwNumber} />
                    </Box>
                  );
                })}
              </Box>
              <ReactTooltip />
            </ResponsiveGrid>
          )}
        </Box>
      </Box>
    );
  }
}

export default withUser(SO5TopPerformers);
