import React from "react";
import { PlayingStatus } from "../util/playingStatus";
import AddLineupLine from "../../img/add-lineup-line.svg";
import AddLineupLineOrange from "../../img/add-lineup-line-orange.svg";

function ComboSelectionListItem(props) {
  const player = props.stats.player;
  const combo = props.stats;
  const classStats = "md:text-lg font-semibold text-sm";
  return (
    <div className={"h-16 grid grid-cols-8 hover:shadow cursor-pointer hover:bg-orange-50"}>
      <div className={"col-span-2 flex flex-row pl-1 md:pl-0"}>
        <div className={"hidden md:block w-16"}>
          <img className={"hidden md:block h-16 w-auto"} src={player.Avatar} />
        </div>
        <div className={"self-center"}>
          <p className={"md:text-med font-semibold text-xs"}>
            <a href={"/player/" + player.PlayerId}>{player.DisplayName}</a>
          </p>
          <p className={"md:text-xs text-xxs"}>
            <span>
              {player.Position} - <PlayingStatus player={player} status={player.PlayingStatus} /> - {player.Age}
            </span>
          </p>
        </div>
      </div>
      <div className={"self-center text-center"}>
        <p className={classStats}>{combo.common_games}</p>
      </div>
      <div className={"self-center text-center"}>
        <p className={classStats}>{combo.avg_minutes.toFixed(0)}</p>
      </div>
      <div className={"self-center text-center"}>
        <p className={classStats}>{combo.average_combined_score.toFixed(1)}</p>
      </div>
      <div className={"self-center text-center"}>
        <p className={classStats}>{combo.average_combined_score_divided.toFixed(1)}</p>
      </div>
      <div className={"self-center text-center"}>
        <p className={classStats}>{(combo.avg_above_60 * 100).toFixed(0)}%</p>
      </div>
      <div className={"h-1/4 w-full items-center justify-center self-center align-middle flex"}>
        <img
          className={"cursor-pointer"}
          src={AddLineupLine}
          onMouseOver={(e) => {
            e.currentTarget.src = AddLineupLineOrange;
          }}
          onMouseOut={(e) => {
            e.currentTarget.src = AddLineupLine;
          }}
          onClick={() => props.addToCombo(player)}
        />
      </div>
    </div>
  );
}

export default ComboSelectionListItem;
