import React, { useEffect, useState } from "react";
import { Accordion, AccordionPanel } from "grommet";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import CurrencyPicker from "../util/newCurrencyPicker";
import SportsPicker from "../util/sportsPicker";
import { isFree, t1OrAbove, t2OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import Spinner from "../loader/spinner";
import { scarcities_objects_not_all } from "../util/scarcities";
import { formatPrice } from "../util/formatMoney";
import { formatFactor, formatPercentage } from "../util/formatGrowth";
import { ReactTooltip } from "../util/tooltip.js";
import HelpTip from "../util/helpTip";
import { Area, AreaChart, CartesianGrid, ComposedChart, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { format } from "date-fns";
import Locker from "../../img/brand-close-locker-nude.svg";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import CardIcon from "../util/cardIcon";
import { units } from "../util/units";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import BoxWithToggle from "../util/boxWithToggle";
import SDLoading from "../util/SDLoading";
import WarningTip from "../util/warningTip";
import { WatchDemoBadge } from "../util/watchDemo";

const colors = {
  estimated_gallery_value: "#59B1FF",
  estimated_gallery_valueArea: "#8FCAFF",
  cards_owned: "#FFA665",
  cards_ownedArea: "#FFBE8F",
  // By sport
  estimated_gallery_value_football: "#1486EE",
  estimated_gallery_value_baseball: "#59B1FF",
  estimated_gallery_value_basketball: "#B8DEFF",
  estimated_gallery_value_footballArea: "#459CED",
  estimated_gallery_value_baseballArea: "#8FCAFF",
  estimated_gallery_value_basketballArea: "#D6ECFF",
  cards_owned_football: "#FF8E3D",
  cards_owned_baseball: "#FFA665",
  cards_owned_basketball: "#FFD0AF",
  cards_owned_footballArea: "#FFA05C",
  cards_owned_baseballArea: "#FFBE8F",
  cards_owned_basketballArea: "#FFE7D6",
  // By origin
  estimated_gallery_value_rewards: "#1486EE",
  estimated_gallery_value_purchased: "#59B1FF",
  estimated_gallery_value_rewardsArea: "#459CED",
  estimated_gallery_value_purchasedArea: "#8FCAFF",
  cards_owned_rewards: "#FF8E3D",
  cards_owned_purchased: "#FFA665",
  cards_owned_rewardsArea: "#FFA05C",
  cards_owned_purchasedArea: "#FFBE8F",
  // By scarcity
  estimated_gallery_value_limited: "#045297",
  estimated_gallery_value_rare: "#1486EE",
  estimated_gallery_value_super_rare: "#59B1FF",
  estimated_gallery_value_unique: "#B8DEFF",
  estimated_gallery_value_limitedArea: "#0062B8",
  estimated_gallery_value_rareArea: "#459CED",
  estimated_gallery_value_super_rareArea: "#8FCAFF",
  estimated_gallery_value_uniqueArea: "#D6ECFF",
  cards_owned_limited: "#FF5C00",
  cards_owned_rare: "#FF8E3D",
  cards_owned_super_rare: "#FFA665",
  cards_owned_unique: "#FFD0AF",
  cards_owned_limitedArea: "#FF700A",
  cards_owned_rareArea: "#FFA05C",
  cards_owned_super_rareArea: "#FFBE8F",
  cards_owned_uniqueArea: "#FFE7D6",
  // Others
  multiple_on_investmentPos: "#63ECAA",
  multiple_on_investmentNeg: "#E45147",
  trading_exposure: "#E7B62C",
  total_exposure: "#BB6BD9",
  total_realized_profit_and_loss: "#747cf8",
};

const legends = {
  estimated_gallery_value_football: "football",
  estimated_gallery_value_baseball: "baseball",
  estimated_gallery_value_basketball: "basketball",
  cards_owned_football: "football",
  cards_owned_baseball: "baseball",
  cards_owned_basketball: "basketball",
  estimated_gallery_value_rewards: "rewards",
  estimated_gallery_value_purchased: "purchased",
  cards_owned_rewards: "rewards",
  cards_owned_purchased: "purchased",
  estimated_gallery_value_limited: "limited",
  estimated_gallery_value_rare: "rare",
  estimated_gallery_value_super_rare: "super rare",
  estimated_gallery_value_unique: "unique",
  cards_owned_limited: "limited",
  cards_owned_rare: "rare",
  cards_owned_super_rare: "super rare",
  cards_owned_unique: "unique",
};

const StatBox = (props) => {
  const { loading, title, value, subValue, subText, highlight, tip, type, locked, disabled, disabledMessage, warning } = props;
  let valueColor = "text-brand-black";
  if (highlight) {
    if (type === "price") {
      valueColor =
        value !== undefined && value.price !== 0 ? (value.price > 0 ? "text-success-green" : "text-horrible-red") : "text-brand-black";
    } else {
      valueColor = value !== undefined && value !== 0 ? (value > 0 ? "text-success-green" : "text-horrible-red") : "text-brand-black";
    }
  }
  return (
    <div className={"bg-white rounded-lg flex flex-col gap-2 p-3"}>
      <p className={`flex flex-row gap-1 text-md font-semibold font-headers ${highlight ? "text-brand" : "text-textGrey3"}`}>
        <span>{title}</span>
        {tip && <HelpTip tip={tip} />}
        {warning && <WarningTip tip={warning} />}
      </p>
      <div className={"flex flex-col gap-1 h-full justify-evenly"}>
        {locked ? (
          <div className="flex h-full" data-tip={"Star membership is needed"}>
            <img alt="" className="my-auto w-7 h-7 ml-1" src={Locker} />
          </div>
        ) : loading ? (
          <div role="status" className="max-w-sm animate-pulse flex flex-col gap-2 my-1">
            <div className="h-4 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-1/3"></div>
            <div className="h-4 bg-gray-e5 rounded-lg dark:bg-grey-f2 w-2/3"></div>
          </div>
        ) : disabled ? (
          <div className={"h-full w-full bg-transparent flex justify-center"}>
            <p className={"text-xs text-textGrey4 font-semibold text-center m-auto"}>{disabledMessage}</p>
          </div>
        ) : (
          <>
            <p className={`text-xl font-semibold ${valueColor}`}>
              <span>{type === "price" ? formatPrice(value?.price, value?.currency, undefined, 3) : value}</span>
              {subValue && (
                <span className={"ml-2 text-sm text-textGrey4"}>
                  {type === "price" ? formatPrice(subValue?.price, subValue?.currency, undefined, 3) : subValue}
                </span>
              )}
              {subText && <span className={"ml-2 text-sm text-textGrey4"}>{subText}</span>}
            </p>
            <div className={"flex flex-row gap-3"}>{props.children}</div>
          </>
        )}
      </div>
    </div>
  );
};

const MoneyStatBox = (props) => {
  const { loading, title, value, subText, highlight, tip, locked, hasSecondary } = props;
  return (
    <StatBox
      loading={loading}
      title={title}
      highlight={highlight}
      tip={tip}
      value={value?.primary}
      subText={subText}
      locked={locked}
      type={"price"}
    >
      {hasSecondary && (
        <span className={"text-xs text-textGrey4 font-semibold"}>
          {formatPrice(value?.secondary.price, value?.secondary.currency, undefined, 2)}
        </span>
      )}
    </StatBox>
  );
};

const MoneyStatDetailBox = (props) => {
  const { loading, title, value, subValue, subText, highlight, tip, locked } = props;
  return (
    <StatBox
      loading={loading}
      title={title}
      highlight={highlight}
      tip={tip}
      value={value}
      subValue={subValue}
      subText={subText}
      locked={locked}
      type={"price"}
    >
      {props.children}
    </StatBox>
  );
};

const RosterTooltip = (props) => {
  const { active, payload, label, currency, breakdown, selected } = props;
  if (active && payload && payload.length) {
    return (
      <div className="z-20 bg-focus p-2 rounded-md border border-grey-f2 shadow space-y-1">
        <h3 className="label text-sm text-brand-black text-center m-0 mb-2">{format(new Date(label), "dd-MMM yyyy")}</h3>
        <div className={"flex flex-row justify-between gap-3"}>
          <span className="label text-sm text-textGrey3 font-semibold">ESTIMATED GALLERY VALUE</span>
          <div className={"flex flex-row gap-1 text-sm text-brand-black font-semibold"}>
            {!breakdown && (
              <span className={`text-sm my-auto`} style={{ color: colors["estimated_gallery_value"] }}>
                {"●"}
              </span>
            )}
            <span>{formatPrice(payload[0].payload.estimated_gallery_value, currency, undefined, 3)}</span>
          </div>
        </div>
        {breakdown === "sport" && (
          <div className={"ml-3 border-l-2 border-grey-e9 pl-2"}>
            {["basketball", "baseball", "football"]
              .filter((item) => {
                return selected[item];
              })
              .map((item) => {
                return (
                  <div key={item} className={"flex flex-row justify-between gap-3"}>
                    <span className="label text-sm text-textGrey4 font-semibold flex flex-row gap-1">
                      <span>{item === "football" ? "⚽️" : item === "baseball" ? "⚾️" : "🏀"}</span>
                      {item.toUpperCase()}
                    </span>
                    <span className={"flex flex-row gap-1 text-sm text-textGrey4 font-semibold"}>
                      <span
                        className={`text-sm my-auto`}
                        style={{
                          color: colors["estimated_gallery_value_" + item],
                        }}
                      >
                        {"●"}
                      </span>
                      <span>{formatPrice(payload[0].payload["estimated_gallery_value_" + item], currency, undefined, 3)}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        {breakdown === "origin" && (
          <div className={"ml-3 border-l-2 border-grey-e9 pl-2"}>
            {["purchased", "rewards"]
              .filter((item) => {
                return selected[item];
              })
              .map((item) => {
                return (
                  <div key={item} className={"flex flex-row justify-between gap-3"}>
                    <span className="label text-sm text-textGrey4 font-semibold flex flex-row gap-1">
                      <span>{item === "rewards" ? "🏆" : "💵"}</span>
                      {item.toUpperCase()}
                    </span>
                    <span className={"flex flex-row gap-1 text-sm text-textGrey4 font-semibold"}>
                      <span
                        className={`text-sm my-auto`}
                        style={{
                          color: colors["estimated_gallery_value_" + item],
                        }}
                      >
                        {"●"}
                      </span>
                      <span>{formatPrice(payload[0].payload["estimated_gallery_value_" + item], currency, undefined, 3)}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        {breakdown === "scarcity" && (
          <div className={"ml-3 border-l-2 border-grey-e9 pl-2"}>
            {["unique", "super rare", "rare", "limited"]
              .filter((item) => {
                return selected[item];
              })
              .map((item) => {
                const sanitizedItem = item.replaceAll(" ", "_");
                return (
                  <div key={item} className={"flex flex-row justify-between gap-3"}>
                    <span className="label text-sm text-textGrey4 font-semibold flex flex-row gap-1">
                      <CardIcon inline scarcity={item} />
                      {item.toUpperCase()}
                    </span>
                    <span className={"flex flex-row gap-1 text-sm text-textGrey4 font-semibold"}>
                      <span
                        className={`text-sm my-auto`}
                        style={{
                          color: colors["estimated_gallery_value_" + sanitizedItem],
                        }}
                      >
                        {"●"}
                      </span>
                      <span>{formatPrice(payload[0].payload["estimated_gallery_value_" + sanitizedItem], currency, undefined, 3)}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        <div className={"flex flex-row justify-between gap-3"}>
          <span className="label text-sm text-textGrey3 font-semibold">CARDS OWNED</span>
          <div className={"flex flex-row gap-1 text-sm text-brand-black font-semibold"}>
            {!breakdown && (
              <span className={`text-sm my-auto`} style={{ color: colors["cards_owned"] }}>
                {"●"}
              </span>
            )}
            <span>{payload[0].payload.cards_owned}</span>
          </div>
        </div>
        {breakdown === "sport" && (
          <div className={"ml-3 border-l-2 border-grey-e9 pl-2"}>
            {["football", "baseball", "basketball"]
              .filter((item) => {
                return selected[item];
              })
              .map((item) => {
                return (
                  <div key={item} className={"flex flex-row justify-between gap-3"}>
                    <span className="label text-sm text-textGrey4 font-semibold flex flex-row gap-1">
                      <span>{item === "football" ? "⚽️" : item === "baseball" ? "⚾️" : "🏀"}</span>
                      {item.toUpperCase()}
                    </span>
                    <span className={"flex flex-row gap-1 text-sm text-textGrey4 font-semibold"}>
                      <span className={`text-sm my-auto`} style={{ color: colors["cards_owned_" + item] }}>
                        {"●"}
                      </span>
                      <span>{payload[0].payload["cards_owned_" + item]}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        {breakdown === "origin" && (
          <div className={"ml-3 border-l-2 border-grey-e9 pl-2"}>
            {["rewards", "purchased"]
              .filter((item) => {
                return selected[item];
              })
              .map((item) => {
                return (
                  <div key={item} className={"flex flex-row justify-between gap-3"}>
                    <span className="label text-sm text-textGrey4 font-semibold flex flex-row gap-1">
                      <span>{item === "rewards" ? "🏆" : "💵"}</span>
                      {item.toUpperCase()}
                    </span>
                    <span className={"flex flex-row gap-1 text-sm text-textGrey4 font-semibold"}>
                      <span className={`text-sm my-auto`} style={{ color: colors["cards_owned_" + item] }}>
                        {"●"}
                      </span>
                      <span>{payload[0].payload["cards_owned_" + item]}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        {breakdown === "scarcity" && (
          <div className={"ml-3 border-l-2 border-grey-e9 pl-2"}>
            {["limited", "rare", "super rare", "unique"]
              .filter((item) => {
                return selected[item];
              })
              .map((item) => {
                const sanitizedItem = item.replaceAll(" ", "_");
                return (
                  <div key={item} className={"flex flex-row justify-between gap-3"}>
                    <span className="label text-sm text-textGrey4 font-semibold flex flex-row gap-1">
                      <CardIcon inline scarcity={item} />
                      {item.toUpperCase()}
                    </span>
                    <span className={"flex flex-row gap-1 text-sm text-textGrey4 font-semibold"}>
                      <span
                        className={`text-sm my-auto`}
                        style={{
                          color: colors["cards_owned_" + sanitizedItem],
                        }}
                      >
                        {"●"}
                      </span>
                      <span>{payload[0].payload["cards_owned_" + sanitizedItem]}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
  return null;
};

const RosterSummaryGraphLegend = (props) => {
  const { hover, onHoverChange, selected, onSelectChange, dataKeys } = props;
  return (
    <div className={"flex flex-row justify-center gap-3"}>
      {Object.entries(dataKeys).map(([key, value]) => {
        return (
          <SummaryGraphLegendItem
            key={key}
            label={value.label}
            color={value.color}
            icon={value.icon}
            colors={value.colors}
            selected={selected[key]}
            onSelectChange={(isSelected) => onSelectChange(key, isSelected)}
            isHover={hover === key}
            onHoverChange={(isHover) => onHoverChange(isHover ? key : "")}
          />
        );
      })}
    </div>
  );
};

const legendsFromBreakdown = (breakdown) => {
  if (breakdown === "origin") {
    return {
      rewards: true,
      purchased: true,
    };
  } else if (breakdown === "scarcity") {
    return {
      limited: true,
      rare: true,
      "super rare": true,
      unique: true,
    };
  } else if (breakdown === "sport") {
    return {
      football: true,
      baseball: true,
      basketball: true,
    };
  }
  return {};
};

const RosterGraph = (props) => {
  const { currency, tier, sport } = props;
  const [breakdown, setBreakdown] = useState("");
  const [hover, setHover] = useState("");
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setHover("");
    setSelected(legendsFromBreakdown(breakdown));
  }, [breakdown]);

  useEffect(() => {
    if (isFree(tier)) {
      setBreakdown("");
    }
  }, [tier, sport]);

  let data = props.data;
  if (breakdown === "sport") {
    data = props.data.map((d) => {
      return {
        ...d,
        ["estimated_gallery_value_footballCumul"]: selected["football"] ? d.estimated_gallery_value_football : 0,
        ["estimated_gallery_value_baseballCumul"]:
          (selected["football"] ? d.estimated_gallery_value_football : 0) + (selected["baseball"] ? d.estimated_gallery_value_baseball : 0),
        ["estimated_gallery_value_basketballCumul"]:
          (selected["football"] ? d.estimated_gallery_value_football : 0) +
          (selected["baseball"] ? d.estimated_gallery_value_baseball : 0) +
          (selected["basketball"] ? d.estimated_gallery_value_basketball : 0),
        ["cards_owned_footballCumul"]: selected["football"] ? d.cards_owned_football : 0,
        ["cards_owned_baseballCumul"]:
          (selected["football"] ? d.cards_owned_football : 0) + (selected["baseball"] ? d.cards_owned_baseball : 0),
        ["cards_owned_basketballCumul"]:
          (selected["football"] ? d.cards_owned_football : 0) +
          (selected["baseball"] ? d.cards_owned_baseball : 0) +
          (selected["basketball"] ? d.cards_owned_basketball : 0),
      };
    });
  }
  if (breakdown === "origin") {
    data = props.data.map((d) => {
      return {
        ...d,
        ["estimated_gallery_value_rewardsCumul"]: selected["rewards"] ? d.estimated_gallery_value_rewards : 0,
        ["estimated_gallery_value_purchasedCumul"]:
          (selected["purchased"] ? d.estimated_gallery_value_purchased : 0) + (selected["rewards"] ? d.estimated_gallery_value_rewards : 0),
        ["cards_owned_rewardsCumul"]: selected["rewards"] ? d.cards_owned_rewards : 0,
        ["cards_owned_purchasedCumul"]:
          (selected["purchased"] ? d.cards_owned_purchased : 0) + (selected["rewards"] ? d.cards_owned_rewards : 0),
      };
    });
  }
  if (breakdown === "scarcity") {
    data = props.data.map((d) => {
      return {
        ...d,
        ["estimated_gallery_value_limitedCumul"]: selected["limited"] ? d.estimated_gallery_value_limited : 0,
        ["estimated_gallery_value_rareCumul"]:
          (selected["limited"] ? d.estimated_gallery_value_limited : 0) + (selected["rare"] ? d.estimated_gallery_value_rare : 0),
        ["estimated_gallery_value_super_rareCumul"]:
          (selected["limited"] ? d.estimated_gallery_value_limited : 0) +
          (selected["rare"] ? d.estimated_gallery_value_rare : 0) +
          (selected["super rare"] ? d.estimated_gallery_value_super_rare : 0),
        ["estimated_gallery_value_uniqueCumul"]:
          (selected["limited"] ? d.estimated_gallery_value_limited : 0) +
          (selected["rare"] ? d.estimated_gallery_value_rare : 0) +
          (selected["super rare"] ? d.estimated_gallery_value_super_rare : 0) +
          (selected["unique"] ? d.estimated_gallery_value_unique : 0),
        ["cards_owned_limitedCumul"]: selected["limited"] ? d.cards_owned_limited : 0,
        ["cards_owned_rareCumul"]: (selected["limited"] ? d.cards_owned_limited : 0) + (selected["rare"] ? d.cards_owned_rare : 0),
        ["cards_owned_super_rareCumul"]:
          (selected["limited"] ? d.cards_owned_limited : 0) +
          (selected["rare"] ? d.cards_owned_rare : 0) +
          (selected["super rare"] ? d.cards_owned_super_rare : 0),
        ["cards_owned_uniqueCumul"]:
          (selected["limited"] ? d.cards_owned_limited : 0) +
          (selected["rare"] ? d.cards_owned_rare : 0) +
          (selected["super rare"] ? d.cards_owned_super_rare : 0) +
          (selected["unique"] ? d.cards_owned_unique : 0),
      };
    });
  }

  const t1OrAboveOrBaseball = t1OrAbove(tier) || sport === sorareBaseball;

  return (
    <div className={"bg-white rounded-lg p-5"}>
      <div className={"mt-0 ml-5 mb-3 flex flex-row justify-between border-b border-grey-e5"}>
        <div className={"flex flex-row gap-5"}>
          <h4
            onClick={() => setBreakdown("")}
            className={`font-headers text-md h-full mb-3 mt-0 cursor-pointer hover:opacity-90 ${
              breakdown === "" ? "text-brand border-b-4 border-brand" : "text-textGrey4 hover:text-brand"
            }`}
          >
            No breakdown
          </h4>
          <h4
            onClick={t1OrAboveOrBaseball ? () => setBreakdown("sport") : undefined}
            data-tip={t1OrAboveOrBaseball ? "" : "Star membership is needed"}
            className={`font-headers text-md h-full mb-3 mt-0 ${
              t1OrAboveOrBaseball ? "cursor-pointer hover:opacity-90" : "cursor-not-allowed"
            } ${
              breakdown === "sport"
                ? "text-brand border-b-4 border-brand"
                : `text-textGrey4 ${t1OrAboveOrBaseball ? "hover:text-brand" : ""}`
            }`}
          >
            Breakdown by sport
          </h4>
          <h4
            onClick={t1OrAboveOrBaseball ? () => setBreakdown("scarcity") : undefined}
            data-tip={t1OrAboveOrBaseball ? "" : "Star membership is needed"}
            className={`font-headers text-md h-full mb-3 mt-0 ${
              t1OrAboveOrBaseball ? "cursor-pointer hover:opacity-90" : "cursor-not-allowed"
            } ${
              breakdown === "scarcity"
                ? "text-brand border-b-4 border-brand"
                : `text-textGrey4 ${t1OrAboveOrBaseball ? "hover:text-brand" : ""}`
            }`}
          >
            Breakdown by scarcity
          </h4>
          <h4
            onClick={t1OrAboveOrBaseball ? () => setBreakdown("origin") : undefined}
            data-tip={t1OrAboveOrBaseball ? "" : "Star membership is needed"}
            className={`font-headers text-md h-full mb-3 mt-0 ${
              t1OrAboveOrBaseball ? "cursor-pointer hover:opacity-90" : "cursor-not-allowed"
            } ${
              breakdown === "origin"
                ? "text-brand border-b-4 border-brand"
                : `text-textGrey4 ${t1OrAboveOrBaseball ? "hover:text-brand" : ""}`
            }`}
          >
            Breakdown by origin
          </h4>
        </div>
      </div>
      {breakdown === "sport" && (
        <RosterSummaryGraphLegend
          hover={hover}
          onHoverChange={(key) => setHover(key)}
          selected={selected}
          dataKeys={{
            football: {
              colors: [colors["estimated_gallery_value_football"], colors["cards_owned_football"]],
              label: "Football",
              icon: <span>⚽️</span>,
            },
            baseball: {
              colors: [colors["estimated_gallery_value_baseball"], colors["cards_owned_baseball"]],
              label: "Baseball",
              icon: <span>⚾️</span>,
            },
            basketball: {
              colors: [colors["estimated_gallery_value_basketball"], colors["cards_owned_basketball"]],
              label: "Basketball",
              icon: <span>🏀</span>,
            },
          }}
          onSelectChange={(key, val) =>
            setSelected((prev) => {
              return { ...prev, [key]: val };
            })
          }
        />
      )}
      {breakdown === "origin" && (
        <RosterSummaryGraphLegend
          hover={hover}
          onHoverChange={(key) => setHover(key)}
          selected={selected}
          dataKeys={{
            rewards: {
              colors: [colors["estimated_gallery_value_rewards"], colors["cards_owned_rewards"]],
              label: "Reward cards (gw rewards, referral...)",
              icon: <span>🏆</span>,
            },
            purchased: {
              colors: [colors["estimated_gallery_value_purchased"], colors["cards_owned_purchased"]],
              label: "Purchased cards (auctions, instant buys, offers...)",
              icon: <span>💵</span>,
            },
          }}
          onSelectChange={(key, val) =>
            setSelected((prev) => {
              return { ...prev, [key]: val };
            })
          }
        />
      )}
      {breakdown === "scarcity" && (
        <RosterSummaryGraphLegend
          hover={hover}
          onHoverChange={(key) => setHover(key)}
          selected={selected}
          dataKeys={{
            limited: {
              colors: [colors["estimated_gallery_value_limited"], colors["cards_owned_limited"]],
              label: "Limited cards",
              icon: <CardIcon inline scarcity={"limited"} />,
            },
            rare: {
              colors: [colors["estimated_gallery_value_rare"], colors["cards_owned_rare"]],
              label: "Rare cards",
              icon: <CardIcon inline scarcity={"rare"} />,
            },
            "super rare": {
              colors: [colors["estimated_gallery_value_super_rare"], colors["cards_owned_super_rare"]],
              label: "Super Rare cards",
              icon: <CardIcon inline scarcity={"super rare"} />,
            },
            unique: {
              colors: [colors["estimated_gallery_value_unique"], colors["cards_owned_unique"]],
              label: "Unique cards",
              icon: <CardIcon inline scarcity={"unique"} />,
            },
          }}
          onSelectChange={(key, val) =>
            setSelected((prev) => {
              return { ...prev, [key]: val };
            })
          }
        />
      )}
      {breakdown === "" && <div className={"h-4"}></div>}
      <h3 className={"text-md text-textGrey3 font-semibold p-0 m-0 mb-4"}>ESTIMATED GALLERY VALUE</h3>
      <div>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 60,
              left: 20,
              bottom: 0,
            }}
          >
            <YAxis
              axisLine={{ stroke: "#333" }}
              type="number"
              domain={[0, "auto"]}
              tickCount={5}
              tickFormatter={(x) => {
                return formatPrice(x, currency, undefined, 2);
              }}
              style={{
                fontSize: "0.85rem",
                fontFamily: "Inter, sans-serif",
                fontWeight: 500,
                marginTop: "3px",
                fill: "#333",
              }}
            />
            <CartesianGrid stroke={"#eeeeee"} />

            {breakdown === "" && (
              <Area
                strokeLinecap="round"
                dataKey="estimated_gallery_value"
                stroke={colors["estimated_gallery_value"]}
                strokeWidth={2}
                fillOpacity={1}
                fill={colors["estimated_gallery_valueArea"]}
                type="monotone"
                activeDot={{ fill: colors["estimated_gallery_value"] }}
              />
            )}
            {breakdown === "sport" && (
              <>
                {["estimated_gallery_value_basketball", "estimated_gallery_value_baseball", "estimated_gallery_value_football"].map(
                  (key) => {
                    return (
                      <Area
                        strokeLinecap="round"
                        dataKey={key + "Cumul"}
                        stroke={colors[key]}
                        strokeWidth={2}
                        fillOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.02 : 1) : 0}
                        strokeOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.02 : 1) : 0}
                        fill={colors[key + "Area"]}
                        type="monotone"
                        activeDot={{ fill: colors[key] }}
                      />
                    );
                  },
                )}
              </>
            )}
            {breakdown === "origin" && (
              <>
                {["estimated_gallery_value_purchased", "estimated_gallery_value_rewards"].map((key) => {
                  return (
                    <Area
                      strokeLinecap="round"
                      dataKey={key + "Cumul"}
                      stroke={colors[key]}
                      strokeWidth={2}
                      fillOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.1 : 1) : 0}
                      strokeOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.1 : 1) : 0}
                      fill={colors[key + "Area"]}
                      type="monotone"
                      activeDot={{ fill: colors[key] }}
                    />
                  );
                })}
              </>
            )}
            {breakdown === "scarcity" && (
              <>
                {[
                  "estimated_gallery_value_unique",
                  "estimated_gallery_value_super_rare",
                  "estimated_gallery_value_rare",
                  "estimated_gallery_value_limited",
                ].map((key) => {
                  return (
                    <Area
                      strokeLinecap="round"
                      dataKey={key + "Cumul"}
                      stroke={colors[key]}
                      strokeWidth={2}
                      fillOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.05 : 1) : 0}
                      strokeOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.05 : 1) : 0}
                      fill={colors[key + "Area"]}
                      type="monotone"
                      activeDot={{ fill: colors[key] }}
                    />
                  );
                })}
              </>
            )}

            <XAxis
              dataKey="timestamp"
              tickFormatter={(x) => {
                return format(new Date(x), "LLL dd");
              }}
              position={"insideBottom"}
              type="number"
              scale="time"
              domain={["auto", "auto"]}
              axisLine={{ stroke: "#4f4f4f" }}
              style={{
                fontSize: "0.65rem",
                fontFamily: "Inter, sans-serif",
                marginTop: "3px",
                fill: "transparent",
                fontWeight: 700,
              }}
            />
            <ReferenceLine x={0} stroke="#e5e7eb" />
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              content={<RosterTooltip currency={currency} breakdown={breakdown} selected={selected} name={"estimated_gallery_value"} />}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className={"relative z-10"} style={{ marginTop: "-30px" }}>
        <ResponsiveContainer width="100%" height={330}>
          <AreaChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 60,
              left: 20,
              bottom: 0,
            }}
          >
            <YAxis
              axisLine={{ stroke: "#4f4f4f" }}
              type="number"
              domain={[0, "auto"]}
              tickCount={5}
              style={{
                fontSize: "0.85rem",
                fontFamily: "Inter, sans-serif",
                fontWeight: 500,
                marginTop: "3px",
                fill: "#4f4f4f",
              }}
              reversed
            />
            <CartesianGrid stroke={"#eeeeee"} />

            {breakdown === "" && (
              <Area
                strokeLinecap="round"
                dataKey="cards_owned"
                stroke={colors["cards_owned"]}
                strokeWidth={2}
                fillOpacity={1}
                fill={colors["cards_ownedArea"]}
                type="monotone"
                activeDot={{ fill: colors["cards_owned"] }}
              />
            )}
            {breakdown === "sport" && (
              <>
                {["cards_owned_basketball", "cards_owned_baseball", "cards_owned_football"].map((key) => {
                  return (
                    <Area
                      strokeLinecap="round"
                      dataKey={key + "Cumul"}
                      stroke={colors[key]}
                      strokeWidth={2}
                      fillOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.1 : 1) : 0}
                      strokeOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.1 : 1) : 0}
                      fill={colors[key + "Area"]}
                      type="monotone"
                      activeDot={{ fill: colors[key] }}
                    />
                  );
                })}
              </>
            )}
            {breakdown === "origin" && (
              <>
                {["cards_owned_purchased", "cards_owned_rewards"].map((key) => {
                  return (
                    <Area
                      strokeLinecap="round"
                      dataKey={key + "Cumul"}
                      stroke={colors[key]}
                      strokeWidth={2}
                      fillOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.1 : 1) : 0}
                      strokeOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.1 : 1) : 0}
                      fill={colors[key + "Area"]}
                      type="monotone"
                      activeDot={{ fill: colors[key] }}
                    />
                  );
                })}
              </>
            )}
            {breakdown === "scarcity" && (
              <>
                {["cards_owned_unique", "cards_owned_super_rare", "cards_owned_rare", "cards_owned_limited"].map((key) => {
                  return (
                    <Area
                      strokeLinecap="round"
                      dataKey={key + "Cumul"}
                      stroke={colors[key]}
                      strokeWidth={2}
                      fillOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.05 : 1) : 0}
                      strokeOpacity={selected[legends[key]] ? (hover !== "" && hover !== legends[key] ? 0.05 : 1) : 0}
                      fill={colors[key + "Area"]}
                      activeDot={{ fill: colors[key] }}
                    />
                  );
                })}
              </>
            )}
            <XAxis
              dataKey="timestamp"
              tickFormatter={(x) => {
                return format(new Date(x), "LLL dd");
              }}
              position={"insideTop"}
              type="number"
              scale="time"
              domain={["auto", "auto"]}
              style={{
                fontSize: "0.8rem",
                fontFamily: "Inter, sans-serif",
                marginTop: "3px",
                fill: "#4f4f4f",
                fontWeight: 600,
                transform: "translate(0, -300px)",
              }}
              tickLine={true}
            />
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              content={<RosterTooltip currency={currency} breakdown={breakdown} name={"cards_owned"} selected={selected} />}
            />
          </AreaChart>
        </ResponsiveContainer>
        <h3 className={"text-md text-textGrey3 font-semibold p-0 m-0 mt-4"}>CARDS OWNED</h3>
      </div>
    </div>
  );
};

const FinancialSummaryTooltip = ({ active, payload, label, currency }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-focus pt-2 rounded-md border border-grey-f2 shadow">
        <h3 className="label text-sm text-brand-black text-center m-0">{format(new Date(label), "dd-MMM yyyy")}</h3>
        <div className={"p-3 border-b-2 border-grey-e9"}>
          <div className={"flex flex-row justify-between gap-3"}>
            <span className="label text-sm text-textGrey3 font-semibold">ESTIMATED GALLERY VALUE</span>
            <div className={"flex flex-row gap-1 text-sm text-brand-black font-semibold"}>
              <span className={`text-sm my-auto`} style={{ color: colors["estimated_gallery_value"] }}>
                {"●"}
              </span>
              <span>{formatPrice(payload[0].payload.estimated_gallery_value, currency)}</span>
            </div>
          </div>
          <div className={"flex flex-row justify-between gap-3"}>
            <span className="label text-sm text-textGrey3 font-semibold">CARDS OWNED</span>
            <div className={"flex flex-row gap-1 text-sm text-brand-black font-semibold"}>
              <span>{payload[0].payload.cards_owned}</span>
            </div>
          </div>
        </div>
        <div className={"p-3 border-b-2 border-grey-e9 space-y-2"}>
          <div className={"flex flex-row justify-between gap-3"}>
            <span className="label text-sm text-textGrey3 font-semibold">TRADING EXPOSURE</span>
            <div className={"flex flex-row gap-1 text-sm text-brand-black font-semibold"}>
              <span className={`text-sm my-auto`} style={{ color: colors["trading_exposure"] }}>
                {"●"}
              </span>
              <span>{formatPrice(payload[0].payload.total_trading_exposure, currency)}</span>
            </div>
          </div>
          <div className={"ml-3 border-l-2 border-grey-e9 pl-3"}>
            <div className={"flex flex-row justify-between gap-3"}>
              <span className="label text-sm text-textGrey3 font-semibold">TOTAL PURCHASES</span>
              <span className={"flex flex-row gap-1 text-sm text-textGrey3 font-semibold"}>
                {formatPrice(-payload[0].payload.total_purchases, currency)}
              </span>
            </div>
            <div className={"flex flex-row justify-between gap-3"}>
              <span className="label text-sm text-textGrey3 font-semibold">TOTAL SALES</span>
              <span className={"flex flex-row gap-1 text-sm text-textGrey3 font-semibold"}>
                {formatPrice(payload[0].payload.total_sales, currency)}
              </span>
            </div>
          </div>
          <div className={"flex flex-row justify-between gap-3"}>
            <span className="label text-sm text-textGrey3 font-semibold">REALIZED CARD PROFIT & LOSS</span>
            <div className={"flex flex-row gap-1 text-sm text-brand-black font-semibold"}>
              <span className={`text-sm my-auto`} style={{ color: colors["total_exposure"] }}>
                {"●"}
              </span>
              <span>{formatPrice(payload[0].payload.total_exposure, currency)}</span>
            </div>
          </div>
          <div className={"ml-3 border-l-2 border-grey-e9 pl-3"}>
            <div className={"flex flex-row justify-between gap-3"}>
              <span className="label text-sm text-textGrey3 font-semibold">TOTAL GAMING YIELD</span>
              <span className={"flex flex-row gap-1 text-sm text-textGrey3 font-semibold"}>
                {formatPrice(payload[0].payload.total_eth_gains, currency)}
              </span>
            </div>
          </div>
        </div>
        <div className={"p-3"}>
          <div className={"flex flex-row justify-between gap-3"}>
            <span className="label text-lg text-textGrey3 font-semibold">TOTAL CARD P&L</span>
            <div className={`flex flex-row gap-1.5 text-lg font-semibold`}>
              <span
                className={`text-lg my-auto rounded-full w-4 h-4`}
                style={{
                  background: colors["total_realized_profit_and_loss"],
                }}
              ></span>
              <span className={"text-brand-black"}>{formatPrice(payload[0].payload.total_realized_profit_and_loss, currency)}</span>
            </div>
          </div>
          <div className={"flex flex-row justify-between gap-3"}>
            <span className="label text-lg text-textGrey3 font-semibold">MULTIPLE ON CARD INVESTMENT</span>
            <div className={`flex flex-row gap-1.5 text-lg font-semibold`}>
              <span
                className={`text-lg my-auto rounded-full w-4 h-4`}
                style={{
                  background: `linear-gradient( -90deg, ${colors["multiple_on_investmentNeg"]} 50%, ${colors["multiple_on_investmentPos"]} 51% )`,
                }}
              ></span>
              <span
                style={{
                  color: payload[0].payload.multiple_on_investment >= 1 ? "#1aac66" : colors["multiple_on_investmentNeg"],
                }}
              >
                {formatFactor(payload[0].payload.multiple_on_investment)}X
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const roundToNearestInterestingNumber = (x) => {
  if (x >= 10) {
    // Tweak to find nearest human readable number: x >= 1000 -> 1000, x >= 100 -> 100, x >= 10 -> 10
    const divider = (Math.trunc(x).toString().length - 1) * 10;
    return Math.ceil(x / divider) * divider;
  } else if (x >= 1) {
    return ceilPrecised(x, 0);
  } else if (x >= 0.1) {
    return ceilPrecised(x, 1);
  } else {
    return ceilPrecised(x, 2);
  }
};

const ceilPrecised = (number, precision) => {
  const power = Math.pow(10, precision);
  return Math.ceil(number * power) / power;
};

const getTicksByInterval = (min, max, step, withMax) => {
  const start = min;
  const res = [];
  for (let i = start; i < max; i += step) {
    res.push(i);
  }
  if (withMax) {
    res.push(max);
  }
  return res;
};

const getTicksByAmount = (min, max, amount) => {
  const diff = max - min;
  const ratio = diff / amount;
  const step = roundToNearestInterestingNumber(ratio);
  return getTicksByInterval(min, max, step, true);
};

const FinancialSummaryGraphLegend = (props) => {
  const { hover, onHoverChange, selected, onSelectChange } = props;
  const datakeys = {
    estimated_gallery_value: {
      color: colors["estimated_gallery_value"],
      label: "Estimated Gallery Value",
    },
    total_trading_exposure: {
      color: colors["trading_exposure"],
      label: "Total Trading Exposure",
    },
    total_exposure: {
      color: colors["total_exposure"],
      label: "Realized Card Profit & Loss",
    },
    total_realized_profit_and_loss: {
      color: colors["total_realized_profit_and_loss"],
      label: "Total Card P&L",
    },
    multiple_on_investment: {
      colors: [colors["multiple_on_investmentNeg"], colors["multiple_on_investmentPos"]],
      label: "Multiple on Card Investment",
    },
  };
  return (
    <div className={"flex flex-row justify-center gap-2"}>
      {Object.entries(datakeys).map(([key, value]) => {
        return (
          <SummaryGraphLegendItem
            key={key}
            label={value.label}
            color={value.color}
            colors={value.colors}
            selected={selected[key]}
            onSelectChange={(isSelected) => onSelectChange(key, isSelected)}
            isHover={hover === key}
            onHoverChange={(isHover) => onHoverChange(isHover ? key : "")}
          />
        );
      })}
    </div>
  );
};

const SummaryGraphLegendItem = (props) => {
  const { label, color, icon, colors, isHover, onHoverChange, selected, onSelectChange } = props;
  return (
    <div
      className={`flex flex-row gap-1 text-xs font-semibold cursor-pointer ${isHover ? "text-textGrey3" : "text-brand-black"} ${
        selected ? "" : "text-textGrey4 line-through"
      }`}
      onMouseEnter={() => onHoverChange(true)}
      onMouseLeave={() => onHoverChange(false)}
      onClick={() => onSelectChange(!selected)}
    >
      {color && !colors && (
        <span className={`text-xs my-auto`} style={{ color: color }}>
          {"●"}
        </span>
      )}
      {!color && colors && (
        <span
          className={`text-xs my-auto rounded-full`}
          style={{
            background: `linear-gradient( 90deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%, ${colors[1]} 100%)`,
            width: "10px",
            height: "10px",
          }}
        ></span>
      )}
      <span>{label}</span>
      <span className={isHover ? "opacity-50" : selected ? "opacity-100" : "opacity-50"}>{icon !== undefined ? icon : null}</span>
    </div>
  );
};

const FinancialSummaryGraph = (props) => {
  const { data, currency } = props;
  const [hover, setHover] = useState("");
  const [selected, setSelected] = useState({
    estimated_gallery_value: true,
    total_trading_exposure: true,
    total_exposure: true,
    multiple_on_investment: true,
    total_realized_profit_and_loss: true,
  });
  if (!data) {
    return null;
  }
  let minMulOnInvest, maxMulOnInvest, minExposure, maxExposure;
  data.forEach((d) => {
    if (maxMulOnInvest === undefined || d.multiple_on_investment > maxMulOnInvest) {
      maxMulOnInvest = d.multiple_on_investment;
    }
    if (minMulOnInvest === undefined || d.multiple_on_investment < minMulOnInvest) {
      minMulOnInvest = d.multiple_on_investment;
    }
    if (maxExposure === undefined || d.total_trading_exposure > maxExposure) {
      maxExposure = d.total_trading_exposure;
    }
    if (minExposure === undefined || d.total_trading_exposure < minExposure) {
      minExposure = d.total_trading_exposure;
    }
    if (d.total_exposure > maxExposure) {
      maxExposure = d.total_exposure;
    }
    if (d.total_exposure < minExposure) {
      minExposure = d.total_exposure;
    }
    if (maxExposure === undefined || d.estimated_gallery_value > maxExposure) {
      maxExposure = d.estimated_gallery_value;
    }
    if (minExposure === undefined || d.estimated_gallery_value < minExposure) {
      minExposure = d.estimated_gallery_value;
    }
    if (maxExposure === undefined || d.total_realized_profit_and_loss > maxExposure) {
      maxExposure = d.total_realized_profit_and_loss;
    }
    if (minExposure === undefined || d.total_realized_profit_and_loss < minExposure) {
      minExposure = d.total_realized_profit_and_loss;
    }
  });
  let yAxisMulOnInvest = Math.max(minMulOnInvest, -minMulOnInvest, maxMulOnInvest, -maxMulOnInvest);
  if (yAxisMulOnInvest < 2) {
    yAxisMulOnInvest = 2;
  }
  let mulOnInvestTicks = getTicksByInterval(0, yAxisMulOnInvest, 0.5);
  let yAxisExposure = roundToNearestInterestingNumber(Math.max(minExposure, -minExposure, maxExposure, -maxExposure));
  if (yAxisExposure === 0) {
    yAxisExposure = 1;
  }
  let exposureTicks = getTicksByAmount(-yAxisExposure, yAxisExposure, 11);

  // how much part of multiple on invest is negative vs positive
  // Will be used for a gradient red vs green to have dual colored chart line
  let percentage = maxMulOnInvest - minMulOnInvest === 0 ? 0 : ((maxMulOnInvest - 1) / (maxMulOnInvest - minMulOnInvest)) * 100;
  percentage = percentage < 0 ? 0 : percentage;

  return (
    <div className={"bg-white rounded-lg p-5 space-y-2"}>
      <FinancialSummaryGraphLegend
        hover={hover}
        onHoverChange={(key) => setHover(key)}
        selected={selected}
        onSelectChange={(key, val) =>
          setSelected((prev) => {
            return { ...prev, [key]: val };
          })
        }
      />
      <div className={"flex flex-row justify-between text-md text-textGrey3 font-semibold"}>
        <span className={"text-left"}>
          EXPOSURE, CARD P&L &<br /> GALLERY VALUE in {units[currency]}
        </span>
        <span className={"text-right"}>
          MULTIPLE ON <br />
          CARD INVESTMENT
        </span>
      </div>
      <ResponsiveContainer width="100%" height={700}>
        <ComposedChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: 20,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="galleryValue" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={colors["estimated_gallery_value"]}
                stopOpacity={hover === "estimated_gallery_value" ? 0.8 : 0.4}
              />
              <stop
                offset="95%"
                stopColor={colors["estimated_gallery_value"]}
                stopOpacity={hover === "estimated_gallery_value" ? 0.4 : 0.1}
              />
            </linearGradient>
            <linearGradient id="multiple-on-investment-gradient" x1="0" y1="0" x2="0" y2="100%">
              <stop offset="0%" stopColor={colors["multiple_on_investmentPos"]} />
              <stop offset={`${percentage}%`} stopColor={colors["multiple_on_investmentPos"]} />
              <stop offset={`${percentage}%`} stopColor={colors["multiple_on_investmentNeg"]} />
              <stop offset="100%" stopColor={colors["multiple_on_investmentNeg"]} />
            </linearGradient>
            <linearGradient id="multiple-on-investment-gradient-dot" x1="0" y1="0" x2="100%" y2="0">
              <stop offset="0%" stopColor={colors["multiple_on_investmentPos"]} />
              <stop offset={`50%`} stopColor={colors["multiple_on_investmentPos"]} />
              <stop offset={`51%`} stopColor={colors["multiple_on_investmentNeg"]} />
              <stop offset="100%" stopColor={colors["multiple_on_investmentNeg"]} />
            </linearGradient>
          </defs>
          <Tooltip wrapperStyle={{ outline: "none" }} content={<FinancialSummaryTooltip currency={currency} />} />
          <CartesianGrid strokeDasharray="3 3" stroke={"#eeeeee"} />
          <XAxis
            dataKey="timestamp"
            tickFormatter={(x) => {
              return format(new Date(x), "LLL dd");
            }}
            position
            type="number"
            scale="time"
            domain={["auto", "auto"]}
            axisLine={{ stroke: "white" }}
            style={{
              fontSize: "0.8rem",
              fontFamily: "Inter, sans-serif",
              marginTop: "3px",
              fill: "#828282",
              fontWeight: 500,
            }}
          />
          <YAxis
            axisLine={{ stroke: "white" }}
            type="number"
            ticks={exposureTicks}
            domain={[-yAxisExposure, yAxisExposure]}
            yAxisId={"exposure"}
            style={{
              fontSize: "0.85rem",
              fontFamily: "Inter, sans-serif",
              fontWeight: 500,
              marginTop: "3px",
              fill: "#828282",
            }}
            tickFormatter={(x) => {
              return formatPrice(x, currency, 0, 2);
            }}
          />
          <YAxis
            axisLine={{ stroke: "white" }}
            type="number"
            ticks={mulOnInvestTicks}
            domain={[-yAxisMulOnInvest + 1, yAxisMulOnInvest + 1]}
            allowDecimals={false}
            yAxisId={"multiple-on-investment"}
            orientation={"right"}
            style={{
              fontSize: "0.85rem",
              fontFamily: "Inter, sans-serif",
              fontWeight: 500,
              marginTop: "3px",
              fill: "#828282",
            }}
            tickFormatter={(x) => {
              if (x < 0) {
                return "";
              }
              return formatFactor(x) + "X";
            }}
          />
          <ReferenceLine yAxisId={"exposure"} y={0} stroke="#e5e7eb" />
          {selected["estimated_gallery_value"] && (
            <Area
              dataKey="estimated_gallery_value"
              yAxisId={"exposure"}
              stroke="transparent"
              fillOpacity={hover !== "" && hover !== "estimated_gallery_value" ? 0.1 : 1}
              fill="url(#galleryValue)"
              type="monotone"
              activeDot={{ fill: colors["estimated_gallery_value"] }}
            />
          )}
          {selected["total_trading_exposure"] && (
            <Line
              dataKey="total_trading_exposure"
              yAxisId={"exposure"}
              stroke={colors["trading_exposure"]}
              opacity={hover !== "" && hover !== "total_trading_exposure" ? 0.1 : 1}
              unit="M"
              strokeLinecap="round"
              strokeWidth={2}
              type="bump"
              dot={false}
              activeDot={{ fill: colors["trading_exposure"] }}
            />
          )}
          {selected["total_exposure"] && (
            <Line
              dataKey="total_exposure"
              yAxisId={"exposure"}
              stroke={colors["total_exposure"]}
              opacity={hover !== "" && hover !== "total_exposure" ? 0.1 : 1}
              unit="M"
              strokeLinecap="round"
              strokeWidth={2}
              type="bump"
              dot={false}
              activeDot={{ fill: colors["total_exposure"] }}
            />
          )}
          {selected["total_realized_profit_and_loss"] && (
            <Line
              dataKey="total_realized_profit_and_loss"
              yAxisId={"exposure"}
              stroke={colors["total_realized_profit_and_loss"]}
              opacity={hover !== "" && hover !== "total_realized_profit_and_loss" ? 0.1 : 1}
              unit="M"
              strokeLinecap="round"
              strokeWidth={2}
              type="bump"
              dot={false}
              activeDot={{ fill: colors["total_realized_profit_and_loss"] }}
            />
          )}
          {selected["multiple_on_investment"] && (
            <Line
              dataKey="multiple_on_investment"
              yAxisId={"multiple-on-investment"}
              stroke="url(#multiple-on-investment-gradient"
              unit="M"
              opacity={hover !== "" && hover !== "multiple_on_investment" ? 0.1 : 1}
              strokeWidth={2}
              type="bump"
              dot={false}
              activeDot={{ fill: "url(#multiple-on-investment-gradient-dot" }}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const getSecondaryCurrency = (currency, preferredPrimaryCurrency, preferredSecondaryCurrency) => {
  let secondaryCurrency = "";
  if (!currency) return "";
  if (preferredPrimaryCurrency && currency !== preferredPrimaryCurrency) {
    secondaryCurrency = preferredPrimaryCurrency;
  } else if (preferredSecondaryCurrency && currency !== preferredSecondaryCurrency) {
    secondaryCurrency = preferredSecondaryCurrency;
  }
  return secondaryCurrency;
};

function ManagerStats(props) {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeIndexes, setActiveIndexes] = useState([0, 1, 2]);
  const [currency, setCurrency] = useState(props.user.preferredUnit);
  const [secondaryCurrency, setSecondaryCurrency] = useState(
    getSecondaryCurrency(currency, props.user.preferredUnit, props.user.secondUnit),
  );
  const [sport, setSport] = useState("all");
  const [isPublic, setIsPublic] = useState(false);
  const [isForbidden, setIsForbidden] = useState(undefined);
  const isDisabled = isFree(props.user.tier) && sport !== sorareBaseball;

  const onChangeCurrency = (newCurrency) => {
    setCurrency(newCurrency);
    setSecondaryCurrency(getSecondaryCurrency(newCurrency, props.user.preferredUnit, props.user.secondUnit));
  };

  useEffect(() => {
    onChangeCurrency(props.user.preferredUnit);
  }, [props.user.preferredUnit, props.user.secondUnit]);

  useEffect(() => {
    getManagerStats();
    ReactTooltip.rebuild();
  }, [currency, sport]);

  const getManagerStats = () => {
    if (!currency || !sport) {
      return;
    }
    setLoading(true);
    props
      .fetch(
        "/apiv2/manager/stats/v2/" +
          props.manager.Slug +
          "?primary_currency=" +
          currency +
          "&sport=" +
          sport +
          "&secondary_currency=" +
          secondaryCurrency,
      )
      .then((response) => {
        if (response.status === 403) {
          setIsForbidden(true);
          throw new Error("Forbidden");
        }
        return response.json();
      })
      .then((res) => {
        setIsForbidden(false);
        setLoading(false);
        setStats(res);
        setIsPublic(res?.is_public || false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const saveManagerStatsAccess = (targetIsPublic) => {
    props
      .fetch("/apiv2/manager/stats/access", {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_public: targetIsPublic,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === "ok") {
          setIsPublic(targetIsPublic);
        }
      })
      .catch(errorCatcher(() => {}));
  };

  return (
    <div>
      <div className={"grid grid-rows-3 grid-cols-1 lg:grid-cols-3 lg:grid-rows-1 gap-2"}>
        <div className={"flex flex-row gap-2 justify-center lg:justify-start"}>
          <h2 className={"text-2xl p-0 m-0 my-auto"}>Manager stats</h2>
          <div className={"my-auto"}>
            <WatchDemoBadge
              title={"Manager stats demo"}
              description="Understand key & advanced stats, roster and financial graphs to make better decisions."
              description2={
                <a
                  className={"text-brand hover:underline"}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://www.youtube.com/watch?v=krIUyydc6cA"}
                >
                  Également disponible avec des sous-titres français
                </a>
              }
              videoLink="https://www.youtube.com/embed/GilRHFIXc80"
            />
          </div>
        </div>
        <div className={"flex justify-center"}>
          <SportsPicker showAll sport={sport} change={(s) => setSport(s)} />
        </div>
        <div className={"flex flex-row gap-2 justify-center lg:justify-end lg:ml-auto"}>
          {loading && <Spinner />}
          <CurrencyPicker currency={currency} onChange={(c) => onChangeCurrency(c)} />
        </div>
      </div>

      {isForbidden === undefined && loading && <SDLoading />}
      {isForbidden !== undefined ? (
        isForbidden === true ? (
          <div className={"text-center py-10 text-textGrey3 font-medium "}>
            <p>This user has decided to keep their manager stats private</p>
          </div>
        ) : (
          <>
            {props.user.sorareSlug === props.manager.Slug && (
              <div className={"flex flex-row self-start mb-1"}>
                <div className={"pl-0.5"}>
                  <BoxWithToggle
                    padding={"px-0"}
                    bg="bg-transparent"
                    isActive={isPublic}
                    disabled={isFree(props.user.tier)}
                    disabledTip={isFree(props.user.tier) ? "This feature is only available to Premium users" : undefined}
                    disabledTipId={"manager-stats-disabled-tip"}
                    className={"h-full"}
                    setActive={() => saveManagerStatsAccess(!isPublic)}
                    label={"Make my stats public"}
                    hint={
                      "If you make your stats public, other managers will be able to see them. If you keep them private, only you will be able to see them."
                    }
                  />
                  <ReactTooltip id={"manager-stats-disabled-tip"} />
                </div>
              </div>
            )}
            <div>
              <Accordion multiple={true} activeIndex={activeIndexes} onActive={(indexes) => setActiveIndexes(indexes)}>
                <AccordionPanel label={`Key stats`}>
                  <div className={"grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-2 px-2 py-4"}>
                    <StatBox
                      loading={loading}
                      title={"Cards Owned"}
                      tip={"Breakdown of owned cards at midnight today"}
                      value={
                        stats?.key_stats
                          ? Object.values(stats.key_stats.owned_cards.current).reduce((accumulator, val) => {
                              return accumulator + val;
                            }, 0)
                          : undefined
                      }
                      subText={stats?.key_stats ? `of which ${stats?.key_stats.owned_cards.rewards} rewards` : undefined}
                    >
                      {stats?.key_stats?.owned_cards.current &&
                        scarcities_objects_not_all.map(({ name, value }) => {
                          return (
                            <span key={value} className={"flex flex-row justify-center gap-1 h-full"}>
                              <span className={"text-xs text-textGrey4 font-semibold"}>{stats?.key_stats?.owned_cards.current[value]}</span>
                              <span className={`text-xxs my-auto text-${value.toLowerCase().replace(" ", "-")}`}>{"●"}</span>
                            </span>
                          );
                        })}
                    </StatBox>
                    <MoneyStatDetailBox
                      loading={loading}
                      title={"Total gaming yield"}
                      tip={"Sum of ETH won on tournaments, from podiums or thresholds"}
                      value={stats?.key_stats?.gaming_yield?.total.primary}
                      subValue={secondaryCurrency ? stats?.key_stats?.gaming_yield?.total.secondary : undefined}
                    >
                      <span className={"text-xs text-textGrey4 font-semibold"}>
                        {`${stats?.key_stats?.gaming_yield?.nb_thresholds || 0} Thresholds, ${
                          stats?.key_stats?.gaming_yield?.nb_podiums || 0
                        } Podiums`}
                      </span>
                    </MoneyStatDetailBox>
                    <MoneyStatBox
                      loading={loading}
                      title={"Total Purchases"}
                      tip={"Total of purchases through auctions, instant buys and offers at midnight today"}
                      value={stats?.key_stats?.total_purchases}
                      hasSecondary={secondaryCurrency}
                    />
                    <MoneyStatBox
                      loading={loading}
                      title={"Total Sales"}
                      tip={"Total of sales at midnight today"}
                      value={stats?.key_stats?.total_sales}
                      hasSecondary={secondaryCurrency}
                    />
                    <MoneyStatBox
                      loading={loading}
                      title={"Gallery Valuation"}
                      value={stats?.key_stats?.gallery_value}
                      tip={"Sum of all cards' valuation at midnight today, excluding floor prices"}
                      hasSecondary={secondaryCurrency}
                    />
                  </div>
                </AccordionPanel>
                <AccordionPanel label={`Advanced stats`}>
                  {t1OrAbove(props.user.tier) ? (
                    <div className={"grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-2 px-2 py-4"}>
                      <StatBox
                        loading={loading}
                        title={"Total rewards"}
                        warning={sport === "all" ? "Only football, not publicly available for other sports for now" : undefined}
                        disabled={sport === sorareBaseball || sport === sorareBasketball}
                        disabledMessage={"Not publicly available on Sorare yet"}
                        value={
                          stats?.advanced_stats
                            ? Object.values(stats.advanced_stats.total_rewards.kept).reduce((accumulator, val) => {
                                return accumulator + val;
                              }, 0) +
                              Object.values(stats.advanced_stats.total_rewards.sold).reduce((accumulator, val) => {
                                return accumulator + val;
                              }, 0)
                            : undefined
                        }
                        subValue={stats?.key_stats ? `of which ${stats?.key_stats.owned_cards.rewards} kept` : undefined}
                      >
                        {stats?.advanced_stats?.total_rewards &&
                          scarcities_objects_not_all.map(({ name, value }) => {
                            return (
                              <span key={value} className={"flex flex-row justify-center gap-1 h-full"}>
                                <span className={"text-xs text-textGrey4 font-semibold"}>
                                  {stats?.advanced_stats?.total_rewards.sold[value] + stats?.advanced_stats?.total_rewards.kept[value]}
                                </span>
                                <span className={`text-xxs my-auto text-${value.toLowerCase().replace(" ", "-")}`}>{"●"}</span>
                              </span>
                            );
                          })}
                      </StatBox>
                      <MoneyStatDetailBox
                        loading={loading}
                        title={"Gaming yield : thresholds"}
                        tip={
                          "Manager can win ETH rewards on some competitions when they reach a given score, usually '>= 205 points' and '>= 250 points'"
                        }
                        value={stats?.advanced_stats?.gaming_yield?.value_thresholds.primary}
                        subText={
                          stats?.key_stats?.gaming_yield?.total.primary.price > 0 &&
                          stats?.advanced_stats?.gaming_yield?.value_thresholds.primary.price > 0
                            ? `${formatPercentage(
                                stats?.advanced_stats?.gaming_yield?.value_thresholds.primary.price /
                                  stats?.key_stats?.gaming_yield?.total.primary.price,
                              )} of total yield`
                            : undefined
                        }
                      >
                        <span className={"text-xs text-textGrey4 font-semibold"}>
                          {stats?.advanced_stats?.gaming_yield?.threshold_details &&
                            Object.entries(stats?.advanced_stats?.gaming_yield?.threshold_details)
                              .map(([threshold, value]) => `${value} Threshold ${threshold}`)
                              .join(", ")}
                        </span>
                      </MoneyStatDetailBox>
                      <MoneyStatBox
                        loading={loading}
                        title={"Spent on Secondary market"}
                        tip={"Total spent on offers at midnight today"}
                        value={stats?.advanced_stats?.spent_on_offers}
                        hasSecondary={secondaryCurrency}
                        subText={
                          stats?.key_stats?.total_purchases.primary.price > 0 && stats?.advanced_stats?.spent_on_offers.primary.price > 0
                            ? `${formatPercentage(
                                stats?.advanced_stats?.spent_on_offers.primary.price / stats?.key_stats?.total_purchases.primary.price,
                              )} of purchases`
                            : undefined
                        }
                      />
                      <MoneyStatBox
                        highlight
                        locked={isDisabled}
                        loading={loading}
                        title={"Realized Card Profit & Loss"}
                        tip={"Total Realized Card Profit & Loss = Gaming Yield + (Total Sales - Total Purchases)"}
                        hasSecondary={secondaryCurrency}
                        value={stats?.advanced_stats?.realized_profit_and_loss}
                      />
                      <StatBox
                        loading={loading}
                        title={"Gallery valuation per scarcity"}
                        tip={"Gallery valuation per scarcity, as the sum of all cards' valuation at midnight today, excluding floor prices"}
                      >
                        <div className={"grid grid-rows-2 grid-cols-2 gap-1 w-full"}>
                          {scarcities_objects_not_all.map(({ name, value }) => {
                            const stat = stats?.advanced_stats?.gallery_valuation_per_scarcity[value];
                            const price = formatPrice(
                              stat?.primary?.price !== undefined ? +stat.primary.price : 0,
                              stat?.primary?.currency || currency,
                              undefined,
                              3,
                            );
                            return (
                              <span key={value} className={"flex flex-row gap-1 h-full"}>
                                <span className={`text-sm my-auto text-${value.toLowerCase().replace(" ", "-")}`}>{"●"}</span>
                                <span className={"text-xs my-auto text-brand-black font-semibold"}>{price}</span>
                              </span>
                            );
                          })}
                        </div>
                      </StatBox>
                      <StatBox
                        loading={loading}
                        title={"Total rewards per tier"}
                        warning={sport === "all" ? "Only football, not publicly available for other sports for now" : undefined}
                        disabled={sport === sorareBaseball || sport === sorareBasketball}
                        disabledMessage={"Not publicly available on Sorare yet"}
                      >
                        <div className={"flex flex-row gap-1 justify-between w-full text-center px-1"}>
                          {[3, 2, 1, 0].map((tier) => {
                            return (
                              <span className={"flex flex-col gap-1"}>
                                <span className={"text-textGrey3 font-semibold text-xs uppercase"}>{`Tier ${tier}`}</span>
                                <span className={"text-brand-black font-semibold text-xs"}>
                                  {stats?.advanced_stats?.total_rewards.tiers["tier" + tier] || 0}
                                </span>
                              </span>
                            );
                          })}
                          {stats?.advanced_stats?.total_rewards.tiers["unknown"] !== undefined &&
                            stats?.advanced_stats?.total_rewards.tiers["unknown"] > 0 && (
                              <span className={"flex flex-col gap-1"}>
                                <span className={"text-textGrey3 font-semibold text-xs uppercase"}>{`N/A`}</span>
                                <span className={"text-brand-black font-semibold text-xs"}>
                                  {stats?.advanced_stats?.total_rewards.tiers["unknown"]}
                                </span>
                              </span>
                            )}
                        </div>
                      </StatBox>
                      <MoneyStatDetailBox
                        loading={loading}
                        title={"Gaming yield : other"}
                        tip={"Managers can win ETH rewards on some competitions when they reach top 3"}
                        value={stats?.advanced_stats?.gaming_yield?.value_podiums.primary}
                        subText={
                          stats?.key_stats?.gaming_yield?.total.primary.price > 0 &&
                          stats?.advanced_stats?.gaming_yield?.value_podiums.primary.price > 0
                            ? `${formatPercentage(
                                stats?.advanced_stats?.gaming_yield?.value_podiums.primary.price /
                                  stats?.key_stats?.gaming_yield?.total.primary.price,
                              )} of total yield`
                            : undefined
                        }
                      >
                        <span className={"text-xs text-textGrey4 font-semibold invisible"}>X</span>
                      </MoneyStatDetailBox>

                      <MoneyStatBox
                        loading={loading}
                        title={"Spent on Primary market"}
                        tip={"Total spent on auctions and instant buys at midnight today"}
                        value={stats?.advanced_stats?.spent_on_primary_market}
                        hasSecondary={secondaryCurrency}
                        subText={
                          stats?.key_stats?.total_purchases.primary.price > 0 &&
                          stats?.advanced_stats?.spent_on_primary_market.primary.price > 0
                            ? `${formatPercentage(
                                stats?.advanced_stats?.spent_on_primary_market.primary.price /
                                  stats?.key_stats?.total_purchases.primary.price,
                              )} of purchases`
                            : undefined
                        }
                      />
                      <MoneyStatBox
                        highlight
                        locked={isDisabled}
                        loading={loading}
                        title={"Total Card Profit & Loss"}
                        tip={"Total Card Profit & Loss = Gaming Yield + (Total Sales - Total Purchases) + Gallery valuation"}
                        value={stats?.advanced_stats?.total_profit_and_loss}
                        hasSecondary={secondaryCurrency}
                      />
                      <div className={"bg-white rounded-lg flex flex-col gap-2 p-3"}>
                        <p className={`flex flex-row gap-1 text-md font-semibold font-headers text-brand`}>
                          <span>Multiple on Card Investment</span>
                          <HelpTip tip={"Multiple on Card Investment = (Sales + Gaming Yield + Gallery Value) / Total Purchases"} />
                        </p>
                        <div className={"flex flex-col gap-1 h-full"}>
                          {isDisabled ? (
                            <div className="flex h-full" data-tip={"Star membership is needed"}>
                              <img alt="" className="my-auto w-7 h-7 ml-1" src={Locker} />
                            </div>
                          ) : loading ? (
                            <div role="status" className="max-w-sm animate-pulse flex flex-col gap-2 my-1">
                              <div className="h-4 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-1/3"></div>
                            </div>
                          ) : (
                            <p
                              className={`text-xl font-semibold ${
                                stats?.advanced_stats?.multiple_on_investment !== undefined &&
                                stats?.advanced_stats?.multiple_on_investment !== 1
                                  ? stats?.advanced_stats?.multiple_on_investment > 1
                                    ? "text-success-green"
                                    : "text-horrible-red"
                                  : "text-brand-black"
                              }`}
                            >
                              <span>{formatFactor(stats?.advanced_stats?.multiple_on_investment)}x</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <UpgradeLimitBox
                      className={"bg-white bg-upgrade-bg-gallery-manager-stats bg-cover h-upgrade-banner-s w-full my-3"}
                      title={"Want more insights?"}
                      description={"Become a Star member to unlock advanced manager stats."}
                    />
                  )}
                </AccordionPanel>
                <AccordionPanel label={`Charts`}>
                  {t1OrAbove(props.user.tier) ? (
                    <div>
                      <div>
                        <h2 className={"text-lg"}>Financial summary</h2>
                        {t2OrAbove(props.user.tier) || sport === sorareBaseball ? (
                          <FinancialSummaryGraph data={stats?.financial_history.items} currency={currency} />
                        ) : (
                          <PlayerBlockedVideo
                            title="Want more insights?"
                            description="Become a Star member to unlock card financial summary graph"
                            videoLink="https://www.youtube.com/embed/w4_Yplk6z_k"
                          />
                        )}
                      </div>
                      <div>
                        <h2 className={"text-lg"}>Roster size vs Roster value</h2>
                        <RosterGraph data={stats?.roster_history.items} currency={currency} tier={props.user.tier} sport={sport} />
                      </div>
                    </div>
                  ) : (
                    <div className={"py-3"}>
                      <PlayerBlockedVideo
                        title="Want more insights?"
                        description="Become a Star member to unlock roster graph."
                        description2="Become a Star member to unlock card financial summary graph."
                        videoLink="https://www.youtube.com/embed/GilRHFIXc80"
                      />
                    </div>
                  )}
                </AccordionPanel>
              </Accordion>
              <ReactTooltip />
            </div>
          </>
        )
      ) : null}
    </div>
  );
}

export default withUser(ManagerStats);
