import React, { useEffect, useState } from "react";
import { scarcities_objects } from "../util/scarcities";
import { leagues_with_divisions_objects } from "../util/leagues";
import { all_positions_objects, getAllPositionsBasedOnSport } from "../util/positions";
import { Anchor, Box, DataTable, Heading, Text } from "grommet";
import PlayersInOfferLine from "./playersInOfferLine";
import SDLoading from "../util/SDLoading";
import GreenButton from "../util/greenButton";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import SelectSearch from "react-select-search";
import { allSportsOptions } from "../util/sports";
import { useQuery } from "../util/useQuery";
import { findPriceForUserUnit } from "../util/formatMoney";

function AcceptedOffers(props) {
  const query = useQuery();
  const sportQuery = query.get("sport");

  // ["All offers", "Public offers", "Private offers"]
  const offerTypes = [
    { value: "All offers", name: "All offers" },
    {
      value: "Public offers",
      name: "Public offers",
    },
    { value: "Private offers", name: "Private offers" },
  ];
  const multiPlayers = [
    { value: 0, name: "0+ players" },
    {
      value: 1,
      name: "1 player",
    },
    { value: 2, name: "2+ players" },
  ];
  const [offers, setOffers] = useState(null);
  const [dateBool, setDateBool] = useState(false);
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [scarcity, setScarcity] = useState(scarcities_objects[0].value);
  const [league, setLeague] = useState(leagues_with_divisions_objects[0].value);
  const [position, setPosition] = useState(all_positions_objects[0].value);
  const [manager, setManager] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [multi, setMulti] = useState(multiPlayers[0].value);
  const [offerType, setOfferType] = useState(offerTypes[0].value);
  const [page, setPage] = useState(1);
  const [player, setPlayer] = useState("");
  const [sport, setSport] = useState(sportQuery || allSportsOptions[0].value);

  const preferredUnit = props.user.preferredUnit;

  useEffect(() => {
    fetchOffers(0);
  }, []);

  const fetchOffers = (offset) => {
    setLoading(true);
    let scarcityValue = scarcity;
    let leagueValue = league;
    let positionValue = position;
    let offerTypeValue = "all";
    if (offset === 0) setPage(1);
    if (offerType !== offerTypes[0]) offerTypeValue = offerType.split(" ")[0].toLowerCase();
    props
      .fetch("/apiv2/offers/latestOffers", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: dateBool,
          start_date: dateStart,
          end_date: dateEnd,
          scarcity: scarcityValue,
          league: leagueValue,
          position: positionValue,
          manager: manager,
          limit: 20,
          offset: offset,
          multi_players: multi,
          offer_type: offerTypeValue,
          player: player,
          sport: sport,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setOffers(res);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const goLeft = () => {
    if (page > 1) {
      fetchOffers((page - 2) * 20);
      setPage(page - 1);
    }
  };

  const goRight = () => {
    if (offers.length >= 20) {
      fetchOffers(page * 20);
      setPage(page + 1);
    }
  };

  const columns = [
    {
      property: "acceptationDate",
      header: <Text>Offer date</Text>,
      sortable: true,
      render: (d) => {
        return <Text size={"small"}>{new Date(d.acceptationDate).toLocaleString()}</Text>;
      },
    },
    {
      property: "manager1",
      header: <Text textAlign={"center"}>Offer sender</Text>,
      render: (d) => {
        const amountLabel = findPriceForUserUnit(d.send_amount_in_wei, d.sent_amount_fiat, preferredUnit, false, 4);
        return (
          <Box direction={"row"} gridArea="player1">
            <PlayersInOfferLine
              players={d.players_sent}
              manager={d.manager_sending}
              etherAmount={d.send_amount_in_wei}
              amountLabel={amountLabel}
            />
          </Box>
        );
      },
    },
    {
      sortable: false,
      render: () => {
        return <Text textAlign={"center"}>{"<==>"}</Text>;
      },
    },
    {
      property: "manager2",
      header: <Text>Offer receiver</Text>,
      render: (d) => {
        const amountLabel = findPriceForUserUnit(d.receive_amount_in_wei, d.received_amount_fiat, preferredUnit, false, 4);
        return (
          <Box direction={"row"} gridArea="player1">
            <PlayersInOfferLine
              players={d.players_received}
              manager={d.manager_receiving}
              etherAmount={d.receive_amount_in_wei}
              amountLabel={amountLabel}
            />
          </Box>
        );
      },
    },
    {
      property: "offerId",
      primary: true,
      render: (d) => (
        <a target={"_blank"} href={"/offer/" + d.offerId} rel="noreferrer">
          <p className={"text-base font-semibold text-brand hover:font-bold"}>Details</p>
        </a>
      ),
    },
  ];

  return (
    <div className={"pb-8"}>
      <Box margin={"auto"} align={"center"}>
        <Heading level={"2"} color={"brand"}>
          Settled offers history
        </Heading>
        <div className={"grid grid-cols-4 gap-4"}>
          <SelectSearch options={leagues_with_divisions_objects} value={league} onChange={(v) => setLeague(v)} />
          <SelectSearch options={getAllPositionsBasedOnSport(sport)} value={position} onChange={(v) => setPosition(v)} />
          <SelectSearch
            options={scarcities_objects.concat({
              value: "CUSTOM SERIES",
              name: "Custom Series",
            })}
            value={scarcity}
            onChange={(v) => setScarcity(v)}
          />
          <SelectSearch options={multiPlayers} value={multi} onChange={(v) => setMulti(v)} />
          <SelectSearch options={offerTypes} value={offerType} onChange={(v) => setOfferType(v)} />
          <SelectSearch options={allSportsOptions} value={sport} onChange={(v) => setSport(v)} />
          <div className={"self-center"}>
            <input
              className={"rounded shadow p-2 text-sm focus:outline-none w-full font-medium"}
              name="managerName"
              placeholder={"Filter with a manager"}
              onChange={(e) => setManager(e.target.value)}
              value={manager}
            />
          </div>
          <div className={"self-center"}>
            <input
              className={"rounded shadow p-2 text-sm focus:outline-none w-full font-medium"}
              name="playerName"
              placeholder={"Filter with a player"}
              onChange={(e) => setPlayer(e.target.value)}
              value={player}
            />
          </div>
        </div>
        <div className={"mt-4 flex-row flex justify-center"}>
          <GreenButton
            margin={"auto"}
            type="submit"
            primary
            label="Filter"
            onClick={() => fetchOffers(0)}
            onSubmit={() => fetchOffers(0)}
          />
        </div>
        {offers !== null && offers.length > 0 && !isLoading ? (
          <Box>
            <DataTable
              step={10}
              sortable
              columns={columns}
              data={offers.map((o, i) => ({
                key: o.offer.OfferId,
                offerId: o.offer.OfferId,
                acceptationDate: o.offer.AcceptationDate,
                sent_cards_value: o.sent_cards_value,
                manager_sending: o.manager_sending,
                players_sent: o.players_sent,
                players_received: o.players_received,
                manager_receiving: o.manager_receiving,
                receive_amount_in_wei: o.offer.ReceiveAmountInWei,
                send_amount_in_wei: o.offer.SendAmountInWei,
                received_amount_fiat: o.received_amount_fiat,
                sent_amount_fiat: o.sent_amount_fiat,
              }))}
            />
            <Box margin={{ top: "5%" }} direction={"row"} width={"100%"} justify={"evenly"}>
              <Box>
                <Anchor alignSelf={"start"} onClick={() => goLeft()}>
                  {"<-- Previous offers"}
                </Anchor>
              </Box>
              <Box>
                <Text alignSelf={"center"}>
                  <em>Page {page}</em>
                </Text>
              </Box>
              <Box>
                <Anchor alignSelf={"end"} onClick={() => goRight()}>
                  {"Next offers -->"}
                </Anchor>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box margin={{ top: "medium" }}>
            {isLoading === true && <SDLoading />}
            {offers === null && !isLoading && (
              <Box align={"center"}>
                <Text>No offer available.</Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
}

export default withUser(AcceptedOffers);
