import React from "react";
import clsx from "clsx";

function Toggle(props) {
  let bg = "bg-surface-container-high border-surface-container-high";
  let border = "translate-x-0 ";
  if (props.isActive) {
    bg = "bg-primary border-primary";
    border = "translate-x-4";
  }
  const cursor = props.disabled ? "cursor-not-allowed" : "cursor-pointer";

  return (
    <button
      type="button"
      onClick={() => {
        !props.disabled && props.setActive();
      }}
      className={clsx(
        bg,
        cursor,
        "relative inline-flex shrink-0 h-6 w-11 border-2 rounded-full transition-colors ease-in-out duration-200 focus:outline-none",
      )}
      role="switch"
      aria-checked="false"
    >
      <span className={"sr-only "}>Use setting</span>
      <span
        aria-hidden="true"
        className={
          border +
          " pointer-events-none inline-block h-4 w-4 self-center ml-1 rounded-full bg-white transform ring-0 transition ease-in-out duration-200"
        }
      />
    </button>
  );
}

export default Toggle;
