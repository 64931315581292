import { Button } from "../util/button";
import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as IconChevronRight } from "@material-design-icons/svg/filled/chevron_right.svg";

export const LongTermLeagueBanner = withRouter((props) => {
  const { leagues, sport, title } = props;
  if (leagues === undefined || leagues === null || leagues.length == 0) {
    return null;
  }
  return (
    <div
      className={
        "w-full rounded-xl bg-black-4D py-4 px-6 flex flex-row justify-between bg-leaderboard-banner bg-center bg-cover h-20 items-center cursor-pointer hover:opacity-90"
      }
      onClick={() => props.history.push(`/longTermLeagues/${sport}/leaderboard?leagueId=${leagues[0].id}`)}
    >
      <h3 className={"text-white text-xl font-headers"}>{title}</h3>
      <Button
        iconPosition="right"
        context="neutral"
        label={"View"}
        padding={"px-4 py-2"}
        icon={<IconChevronRight className={"fill-on-surface"} />}
        onClick={() => props.history.push(`/longTermLeagues/${sport}/leaderboard?leagueId=${leagues[0].id}`)}
      />
    </div>
  );
});
