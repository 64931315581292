import React, { Component } from "react";
import { BRAND_BLACK } from "../util/colors";
import { ReactComponent as ArrowUp } from "@material-design-icons/svg/filled/arrow_upward.svg";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/filled/arrow_downward.svg";

class HomeDashboardEntry extends Component {
  render() {
    let variation = parseFloat(this.props.variation).toFixed(2);
    let ImgVariation = "";
    let color = "success-green";
    if (this.props.variation === undefined) {
      variation = "";
    } else if (variation === "-0.00" || variation === "0.00") {
      variation = "Unchanged";
      color = BRAND_BLACK;
    } else if (this.props.variation > 0) {
      variation = variation + "%";
      ImgVariation = <ArrowUp className={"w-auto fill-success-green h-5 w-5"} />;
    } else if (this.props.variation < 0) {
      variation = variation * -1 + "%";
      ImgVariation = <ArrowDown className={"w-auto fill-horrible-red h-5 w-5"} />;
      color = "horrible-red";
    }

    const secondText = this.props.secondText;

    return (
      <div className={"rounded-lg bg-surface-container py-4 space-y-2 px-4 md:px-6 lg:px-4"}>
        <p className={"text-on-surface-variant font-semibold text-tiny md:text-base"}>{this.props.description}</p>
        {this.props.loading === undefined || !this.props.loading ? (
          <p className={"text-2xl md:text-3xl font-semibold"}>{this.props.value}</p>
        ) : (
          <div role="status" className="max-w-sm animate-pulse flex flex-col gap-2 my-1">
            <div className="h-9 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-3/4"></div>
          </div>
        )}
        {variation !== "NaN%" && variation !== "" && (
          <div className={"flex flex-row space-x-1 pt-1"}>
            {ImgVariation}
            <p className={"md:text-med text-sm font-semibold text-" + color}>{variation}</p>
          </div>
        )}
        {this.props.secondText &&
          (this.props.loading === undefined || !this.props.loading ? (
            <p className={"text-on-surface-variant font-semibold text-sm md:text-base"}>{secondText}</p>
          ) : (
            <div role="status" className="max-w-sm animate-pulse flex flex-col gap-2 my-1">
              <div className="h-6 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-1/2"></div>
            </div>
          ))}
      </div>
    );
  }
}

export default HomeDashboardEntry;
