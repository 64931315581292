export const nbaDivisionsObjects = [
  { name: "Limited Contender", value: "limited-contender" },
  { name: "Limited Champion", value: "limited-champion" },
  { name: "Limited No Cap", value: "limited-no-cap" },
  { name: "Limited Underdog", value: "limited-underdog" },
  { name: "Limited Eastern Conference", value: "limited-eastern-conference" },
  { name: "Limited All-Offense", value: "limited-all-offense" },
  { name: "Limited All-Defense", value: "limited-all-defense" },
  { name: "Deck The Halls", value: "deck-the-halls" },
  { name: "Streetball", value: "streetball" },
  { name: "Season of Giving", value: "season-of-giving" },
  { name: "Rare Contender", value: "rare-contender" },
  { name: "Rare Champion", value: "rare-champion" },
  { name: "Rare Underdog", value: "rare-underdog" },
  { name: "Super Rare Contender", value: "super-rare-contender" },
  { name: "Super Rare Champion", value: "super-rare-champion" },
  { name: "Unique Champion", value: "unique-champion" },
  { name: "Common Contender", value: "common-contender" },
  { name: "Common Champion", value: "common-champion" },
  { name: "Common Eastern Conference", value: "common-eastern-conference" },
  { name: "Common Underdog", value: "common-underdog" },
  { name: "Common No Cap", value: "common-no-cap" },
  { name: "Common All-Offense", value: "common-all-offense" },
  { name: "Common All-Defense", value: "common-all-defense" },
];

export const nbaDivisionsObjectsWithAll = [{ name: "All tournaments", value: "" }, ...nbaDivisionsObjects];
