export const cardTeamIds = [
  { value: "all", name: "All clubs" },
  { value: "aab-aalborg", name: "Aalborg BK" },
  { value: "aberdeen-aberdeen", name: "Aberdeen FC" },
  { value: "adana-demirspor-adana", name: "Adana Demir Spor Kulübü" },
  { value: "admira-maria-enzersdorf", name: "FC Flyeralarm Admira" },
  { value: "ado-den-haag-den-haag", name: "HFC ADO Den Haag" },
  { value: "afc-bournemouth-bournemouth-dorset", name: "AFC Bournemouth" },
  { value: "ajaccio-ajaccio", name: "AC Ajaccio" },
  { value: "ajax-amsterdam", name: "AFC Ajax" },
  { value: "akhmat-grozny-groznyi", name: "FK Akhmat Grozny" },
  { value: "alanyaspor-alanya", name: "Alanyaspor Kulübü" },
  { value: "albacete-albacete", name: "Albacete Balompié" },
  { value: "almeria-almeria", name: "UD Almería" },
  { value: "altay-izmir", name: "Altay Spor Kulübü" },
  {
    value: "america-mineiro-belo-horizonte-minas-gerais",
    name: "América FC (Minas Gerais)",
  },
  { value: "amiens-sc-amiens", name: "Amiens SC" },
  { value: "anderlecht-bruxelles-brussel", name: "RSC Anderlecht" },
  { value: "angers-sco-angers", name: "Angers SCO" },
  { value: "ankaragucu-ankara", name: "MKE Ankaragücü Spor Kulübü" },
  { value: "antalyaspor-antalya", name: "Antalyaspor Kulübü" },
  { value: "antwerp-deurne", name: "Royal Antwerp FC" },
  { value: "argentina", name: "Argentina" },
  {
    value: "argentinos-juniors-capital-federal-ciudad-de-buenos-aires",
    name: "Argentinos Juniors",
  },
  { value: "arizona-diamondbacks", name: "Arizona Diamondbacks" },
  { value: "arminia-bielefeld-bielefeld", name: "DSC Arminia Bielefeld" },
  { value: "arsenal-london", name: "Arsenal FC" },
  { value: "arsenal-tula-tula", name: "FK Arsenal Tula" },
  { value: "as-eupen-eupen", name: "KAS Eupen" },
  { value: "aston-villa-birmingham", name: "Aston Villa FC" },
  { value: "atalanta-ciserano", name: "Atalanta Bergamasca Calcio" },
  { value: "athletic-club-bilbao", name: "Athletic Club Bilbao" },
  { value: "atlanta-braves", name: "Atlanta Braves" },
  { value: "atlanta-hawks", name: "Atlanta Hawks" },
  { value: "atlanta-united-atlanta-georgia", name: "Atlanta United" },
  { value: "atlas-guadalajara", name: "Atlas FC de Guadalajara" },
  { value: "atletico-madrid-madrid", name: "Club Atlético de Madrid" },
  {
    value: "atletico-mineiro-belo-horizonte-minas-gerais",
    name: "Atlético Mineiro",
  },
  { value: "augsburg-augsburg", name: "FC Augsburg" },
  { value: "austin-austin-texas", name: "Austin FC" },
  { value: "australia", name: "Australia" },
  {
    value: "austria-klagenfurt-klagenfurt-am-worthersee",
    name: "SK Austria Klagenfurt",
  },
  { value: "austria-lustenau-lustenau", name: "SC Austria Lustenau" },
  { value: "austria-wien-wien", name: "FK Austria Wien" },
  { value: "auxerre-auxerre", name: "AJ Auxerre" },
  { value: "avispa-fukuoka-fukuoka", name: "Avispa Fukuoka" },
  { value: "az-alkmaar", name: "Alkmaar Zaanstreek" },
  { value: "baltimore-orioles", name: "Baltimore Orioles" },
  { value: "barcelona-barcelona", name: "FC Barcelona" },
  { value: "bastia-furiani", name: "SC Bastia" },
  { value: "bayer-leverkusen-leverkusen", name: "Bayer 04 Leverkusen" },
  { value: "bayern-munchen-munchen", name: "FC Bayern München" },
  { value: "beerschot-wilrijk-wilrijk", name: "KFCO Beerschot " },
  { value: "belgium", name: "Belgium" },
  { value: "benfica-lisboa", name: "SL Benfica" },
  { value: "besiktas-istanbul", name: "Beşiktaş Jimnastik Kulübü" },
  {
    value: "boca-juniors-capital-federal-ciudad-de-buenos-aires",
    name: "CA Boca Juniors",
  },
  { value: "bochum-bochum", name: "VfL Bochum 1848" },
  { value: "bologna-bologna", name: "Bologna FC 1909" },
  { value: "bordeaux-le-haillan", name: "FC Girondins de Bordeaux" },
  { value: "borussia-dortmund-dortmund", name: "Borussia Dortmund" },
  {
    value: "borussia-m-gladbach-monchengladbach",
    name: "Borussia Mönchengladbach",
  },
  { value: "boston-celtics", name: "Boston Celtics" },
  { value: "boston-red-sox", name: "Boston Red Sox" },
  { value: "brazil", name: "Brazil" },
  { value: "brentford-brentford-middlesex", name: "Brentford FC" },
  { value: "brest-brest", name: "Stade Brestois 29" },
  {
    value: "brighton-hove-albion-brighton-east-sussex",
    name: "Brighton & Hove Albion FC",
  },
  { value: "brooklyn-nets", name: "Brooklyn Nets" },
  { value: "burgos-burgos", name: "Burgos CF" },
  { value: "burnley-burnley-lancashire", name: "Burnley FC" },
  { value: "busan-i-park-busan", name: "Busan I'Park" },
  { value: "cadiz-cadiz", name: "Cádiz CF" },
  { value: "caen-caen", name: "Stade Malherbe Caen" },
  { value: "cagliari-cagliari", name: "Cagliari Calcio" },
  { value: "cambuur-leeuwarden", name: "SC Cambuur Leeuwarden" },
  { value: "cameroon", name: "Cameroon" },
  { value: "canada", name: "Canada" },
  { value: "ceara-fortaleza-ceara", name: "Ceará SC" },
  { value: "celta-de-vigo-vigo", name: "Real Club Celta de Vigo" },
  { value: "celtic-glasgow", name: "Celtic FC" },
  { value: "cercle-brugge-brugge", name: "Cercle Brugge KSV" },
  { value: "cerezo-osaka-osaka-osaka", name: "Cerezo Osaka" },
  { value: "charlotte-fc-charlotte-north-carolina", name: "Charlotte FC" },
  { value: "charlotte-hornets", name: "Charlotte Hornets" },
  { value: "chelsea-london", name: "Chelsea FC" },
  { value: "chicago-bulls", name: "Chicago Bulls" },
  { value: "chicago-cubs", name: "Chicago Cubs" },
  { value: "chicago-fire-bridgeview-illinois", name: "Chicago Fire FC" },
  { value: "chicago-white-sox", name: "Chicago White Sox" },
  { value: "cienciano-cusco", name: "Club Cienciano" },
  { value: "cincinnati-cincinnati-ohio", name: "FC Cincinnati" },
  { value: "cincinnati-reds", name: "Cincinnati Reds" },
  { value: "clermont-clermont-ferrand", name: "Clermont Foot 63" },
  { value: "cleveland-cavaliers", name: "Cleveland Cavaliers" },
  { value: "cleveland-guardians", name: "Cleveland Guardians" },
  { value: "club-brugge-brugge", name: "Club Brugge KV" },
  { value: "colorado-rapids-denver-colorado", name: "Colorado Rapids" },
  { value: "colorado-rockies", name: "Colorado Rockies" },
  { value: "columbus-crew-columbus-ohio", name: "Columbus Crew" },
  { value: "consadole-sapporo-sapporo", name: "Consadole Sapporo" },
  { value: "corinthians-sao-paulo", name: "SC Corinthians Paulista" },
  { value: "costa-rica", name: "Costa Rica" },
  { value: "coventry-city-coventry", name: "Coventry City FC" },
  { value: "cremonese-cremona", name: "US Cremonese" },
  { value: "croatia", name: "Croatia" },
  { value: "crystal-palace-london", name: "Crystal Palace FC" },
  { value: "cska-moskva-moskva", name: "PFC CSKA" },
  { value: "cuiaba-cuiaba-mato-grosso", name: "Cuiabá EC" },
  { value: "daegu-daegu", name: "Daegu FC" },
  { value: "daejeon-citizen-daejeon", name: "Daejeon Citizen FC" },
  { value: "dallas-frisco-texas", name: "FC Dallas" },
  { value: "dallas-mavericks", name: "Dallas Mavericks" },
  { value: "darmstadt-98-darmstadt", name: "SV Darmstadt 1898" },
  { value: "dc-united-washington-district-of-columbia", name: "DC United" },
  { value: "denmark", name: "Denmark" },
  { value: "denver-nuggets", name: "Denver Nuggets" },
  { value: "deportivo-alaves-vitoria-gasteiz", name: "Deportivo Alavés" },
  { value: "detroit-pistons", name: "Detroit Pistons" },
  { value: "detroit-tigers", name: "Detroit Tigers" },
  { value: "dijon-dijon", name: "Dijon FCO" },
  { value: "dinamo-moskva-moskva", name: "FC Dynamo Moscow" },
  { value: "dinamo-zagreb-zagreb", name: "GNK Dinamo Zagreb" },
  { value: "dundee-dundee", name: "Dundee FC" },
  { value: "dundee-united-dundee", name: "Dundee United FC" },
  { value: "ecuador", name: "Ecuador" },
  { value: "eibar-eibar", name: "SD Eibar" },
  {
    value: "eintracht-braunschweig-braunschweig",
    name: "Braunschweiger TSV Eintracht 1895",
  },
  {
    value: "eintracht-frankfurt-frankfurt-am-main",
    name: "Eintracht Frankfurt",
  },
  { value: "elche-elche", name: "Elche CF" },
  { value: "emmen-emmen", name: "FC Emmen" },
  { value: "empoli-empoli", name: "Empoli FC" },
  { value: "england", name: "England" },
  { value: "espanyol-barcelona", name: "Reial Club Deportiu Espanyol" },
  { value: "everton-liverpool", name: "Everton FC" },
  { value: "excelsior-rotterdam", name: "SBV Excelsior" },
  { value: "fatih-karagumruk-istanbul", name: "Fatih Karagümrük Spor Kulübü" },
  { value: "fc-andorra", name: "FC Andorra" },
  { value: "fc-cartagena-cartagena", name: "FC Cartagena" },
  { value: "fenerbahce-istanbul", name: "Fenerbahçe Spor Kulübü" },
  { value: "feyenoord-rotterdam", name: "Feyenoord Rotterdam" },
  { value: "fiorentina-firenze", name: "ACF Fiorentina" },
  { value: "fortaleza-fortaleza-ceara", name: "Fortaleza EC" },
  {
    value: "fortuna-dusseldorf-dusseldorf",
    name: "Düsseldorfer TuS Fortuna 1895",
  },
  { value: "fortuna-sittard-sittard", name: "Fortuna Sittard" },
  { value: "france", name: "France" },
  { value: "freiburg-freiburg-im-breisgau", name: "Sport-Club Freiburg" },
  { value: "fulham-london", name: "Fulham FC" },
  { value: "galatasaray-istanbul", name: "Galatasaray Spor Kulübü" },
  { value: "gamba-osaka-suita-shi-suita", name: "Gamba Osaka" },
  { value: "gangwon-gangneung", name: "Gangwon FC" },
  {
    value: "gazisehir-gaziantep-gaziantep",
    name: "Gazişehir Gaziantep Futbol Kulübü",
  },
  { value: "genk-genk", name: "KRC Genk" },
  { value: "genoa-genova", name: "Genoa CFC" },
  { value: "gent-gent", name: "KAA Gent" },
  { value: "germany", name: "Germany" },
  { value: "getafe-getafe-madrid", name: "Getafe Club de Fútbol" },
  { value: "ghana", name: "Ghana" },
  {
    value: "gimnasia-la-plata-la-plata-provincia-de-buenos-aires",
    name: "Gimnasia y Esgrima La Plata",
  },
  { value: "giresunspor-giresun", name: "Giresun Spor Kulübü" },
  { value: "girona-girona", name: "Girona FC" },
  { value: "go-ahead-eagles-deventer", name: "Go Ahead Eagles" },
  { value: "golden-state-warriors", name: "Golden State Warriors" },
  { value: "gorica-velika-gorica", name: "HNK Gorica" },
  { value: "goztepe-izmir", name: "Göztepe Spor Kulübü" },
  { value: "granada-granada", name: "Granada CF" },
  { value: "grenoble-foot-38-grenoble", name: "Grenoble Foot 38" },
  { value: "greuther-furth-furth", name: "SpVgg Greuther Fürth" },
  { value: "groningen-groningen", name: "FC Groningen" },
  { value: "guingamp-guingamp", name: "En Avant Guingamp" },
  { value: "gwangju-gwangju", name: "Gwangju FC" },
  { value: "hajduk-split-split", name: "HNK Hajduk Split" },
  { value: "hamburger-sv-hamburg", name: "Hamburger SV" },
  { value: "hannover-96-hannover", name: "Hannoverscher Sportverein 1896" },
  { value: "hansa-rostock-rostock", name: "FC Hansa Rostock" },
  { value: "hartberg-hartberg", name: "TSV Egger Glas Hartberg" },
  { value: "hatayspor-hatay", name: "Hatay Spor Kulübü" },
  { value: "hearts-edinburgh", name: "Heart of Midlothian FC" },
  { value: "heerenveen-heerenveen-1920", name: "SC Heerenveen" },
  {
    value: "heidenheim-heidenheim-an-der-brenz",
    name: "1. FC Heidenheim 1846",
  },
  { value: "hellas-verona-verona", name: "Hellas Verona FC" },
  { value: "heracles-almelo", name: "Heracles Almelo" },
  { value: "hertha-bsc-berlin", name: "Hertha Berlin" },
  { value: "hibernian-edinburgh", name: "Hibernian FC" },
  { value: "hoffenheim-sinsheim", name: "TSG Hoffenheim" },
  { value: "holstein-kiel-kiel", name: "Kieler SV Holstein 1900" },
  { value: "houston-astros", name: "Houston Astros" },
  { value: "houston-dynamo-houston-texas", name: "Houston Dynamo FC" },
  { value: "houston-rockets", name: "Houston Rockets" },
  { value: "huesca-huesca", name: "SD Huesca" },
  { value: "incheon-united-incheon", name: "Incheon United FC" },
  { value: "indiana-pacers", name: "Indiana Pacers" },
  { value: "inter-miami", name: "Inter Miami CF" },
  { value: "internazionale-milano", name: "FC Internazionale Milano" },
  { value: "iran", name: "Iran" },
  {
    value: "istanbul-basaksehir-istanbul",
    name: "İstanbul Başakşehir Futbol Kulübü",
  },
  { value: "istanbulspor-istanbul", name: "İstanbulspor AŞ" },
  { value: "istra-1961-pula", name: "NK Istra 1961" },
  { value: "jahn-regensburg-regensburg", name: "SSV Jahn 2000 Regensburg" },
  { value: "japan", name: "Japan" },
  { value: "jeju-united-seogwipo-jeju-do", name: "Jeju United FC" },
  { value: "jeonbuk-motors-jeonju", name: "Jeonbuk Hyundai Motors FC" },
  { value: "jubilo-iwata-iwata", name: "Júbilo Iwata" },
  { value: "juventus-torino", name: "Juventus FC" },
  { value: "kaiserslautern-kaiserslautern", name: "1. FC Kaiserslautern" },
  { value: "kansas-city-royals", name: "Kansas City Royals" },
  { value: "karlsruher-sc-karlsruhe", name: "Karlsruher SC" },
  { value: "kashima-antlers-kashima-ibaraki", name: "Kashima Antlers" },
  { value: "kashiwa-reysol-kashiwa-chiba", name: "Kashiwa Reysol" },
  { value: "kasimpasa-istanbul", name: "Kasımpaşa Spor Kulübü" },
  { value: "kawasaki-frontale-kawasaki", name: "Kawasaki Frontale" },
  { value: "kayserispor-kayseri", name: "Kayseri Spor Kulübü" },
  { value: "khimki-khimki", name: "FK Khimki" },
  { value: "kilmarnock-kilmarnock", name: "Kilmarnock FC" },
  { value: "koln-koln", name: "1. FC Köln" },
  { value: "konyaspor-konya", name: "Atiker Konyaspor Kulübü" },
  { value: "korea-republic", name: "Korea Republic" },
  { value: "kortrijk-kortrijk", name: "KV Kortrijk" },
  { value: "krasnodar-krasnodar", name: "FK Krasnodar" },
  { value: "krylya-sovetov-samara", name: "PFK Krylya Sovetov Samara" },
  { value: "kv-oostende-oostende", name: "KV Oostende" },
  { value: "kyoto-sanga-kyoto-kyoto", name: "Kyoto Sanga FC" },
  { value: "la-galaxy-los-angeles-california", name: "LA Galaxy" },
  { value: "las-palmas-las-palmas-de-gran-canaria", name: "UD Las Palmas" },
  { value: "lask-linz", name: "LASK" },
  { value: "lazio-formello", name: "SS Lazio" },
  { value: "ldu-quito-quito", name: "Liga Deportiva Universitaria de Quito" },
  { value: "lecce-lecce", name: "US Lecce" },
  { value: "leeds-united-leeds-west-yorkshire", name: "Leeds United FC" },
  { value: "leganes-leganes", name: "CD Leganés" },
  { value: "leicester-city-leicester", name: "Leicester City FC" },
  { value: "levante-valencia", name: "Levante UD" },
  { value: "lille-villeneuve-d-ascq", name: "LOSC Lille" },
  { value: "liverpool-liverpool", name: "Liverpool FC" },
  { value: "livingston-livingston", name: "Livingston FC" },
  { value: "lokeren-lokeren", name: "KSC Lokeren Oost-Vlaanderen" },
  { value: "lokomotiv-moskva-moskva", name: "FC Lokomotiv Moscow" },
  { value: "lokomotiva-zagreb-zagreb", name: "NK Lokomotiva Zagreb" },
  { value: "lorient-ploemeur", name: "FC Lorient" },
  { value: "los-angeles-angels", name: "Los Angeles Angels" },
  { value: "los-angeles-dodgers", name: "Los Angeles Dodgers" },
  { value: "los-angeles-fc-los-angeles-california", name: "Los Angeles FC" },
  { value: "los-angeles-lakers", name: "Los Angeles Lakers" },
  { value: "lugo-lugo", name: "CD Lugo" },
  { value: "magdeburg-magdeburg", name: "1. FC Magdeburg" },
  { value: "mainz-05-mainz", name: "1. FSV Mainz 05" },
  { value: "malaga-malaga", name: "Málaga CF" },
  { value: "mallorca-palma-de-mallorca", name: "Real Club Deportivo Mallorca" },
  { value: "manchester-city-manchester", name: "Manchester City FC" },
  { value: "manchester-united-manchester", name: "Manchester United FC" },
  { value: "mechelen-mechelen-malines", name: "YR KV Mechelen" },
  { value: "memphis-grizzlies", name: "Memphis Grizzlies" },
  { value: "mexico", name: "Mexico" },
  { value: "miami-heat", name: "Miami Heat" },
  { value: "miami-marlins", name: "Miami Marlins" },
  { value: "midtjylland-herning", name: "FC Midtjylland" },
  { value: "milan-milano", name: "AC Milan" },
  { value: "millwall-london", name: "Millwall FC" },
  { value: "milwaukee-brewers", name: "Milwaukee Brewers" },
  { value: "milwaukee-bucks", name: "Milwaukee Bucks" },
  { value: "minnesota-timberwolves", name: "Minnesota Timberwolves" },
  { value: "minnesota-twins", name: "Minnesota Twins" },
  {
    value: "minnesota-united-minneapolis-saint-paul-minnesota",
    name: "Minnesota United",
  },
  { value: "mirandes-miranda-de-ebro", name: "CD Mirandés" },
  { value: "monaco-monaco", name: "AS Monaco" },
  { value: "montreal-impact-montreal-quebec", name: "CF Montreal" },
  { value: "monza-monza", name: "AC Monza" },
  { value: "morocco", name: "Morocco" },
  { value: "motherwell-motherwell", name: "Motherwell FC" },
  { value: "nagoya-grampus-nagoya", name: "Nagoya Grampus" },
  { value: "nantes-la-chapelle-sur-erdre", name: "FC Nantes" },
  { value: "napoli-castel-volturno", name: "SSC Napoli" },
  { value: "nashville-sc", name: "Nashville SC" },
  { value: "nec-nijmegen", name: "Nijmegen Eendracht Combinatie" },
  { value: "necaxa-aguascalientes", name: "Club Necaxa" },
  { value: "netherlands", name: "Netherlands" },
  {
    value: "new-england-foxborough-massachusetts",
    name: "New England Revolution",
  },
  { value: "new-orleans-pelicans", name: "New Orleans Pelicans" },
  { value: "new-york-city-new-york-new-york", name: "New York City FC" },
  { value: "new-york-knicks", name: "New York Knicks" },
  { value: "new-york-mets", name: "New York Mets" },
  { value: "new-york-rb-secaucus-new-jersey", name: "New York Red Bulls" },
  { value: "new-york-yankees", name: "New York Yankees" },
  {
    value: "newcastle-united-newcastle-upon-tyne",
    name: "Newcastle United FC",
  },
  { value: "newell-s-old-boys-rosario-santa-fe", name: "Newell's Old Boys" },
  { value: "nizhny-novgorod-nizhny-novgorod", name: "FK Nizhny Novgorod" },
  { value: "norwich-city-norwich-norfolk", name: "Norwich City FC" },
  { value: "nottingham-forest-nottingham", name: "Nottingham Forest FC" },
  { value: "nurnberg-nurnberg", name: "1. FC Nürnberg" },
  { value: "oakland-athletics", name: "Oakland Athletics" },
  { value: "oh-leuven-heverlee", name: "Oud-Heverlee Leuven" },
  { value: "oita-trinita-oita-oita", name: "Oita Trinita" },
  { value: "oklahoma-city-thunder", name: "Oklahoma City Thunder" },
  { value: "olympique-lyonnais-lyon", name: "Olympique Lyonnais" },
  { value: "olympique-marseille-marseille", name: "Olympique de Marseille" },
  { value: "orlando-city-lake-mary-florida", name: "Orlando City SC" },
  { value: "orlando-magic", name: "Orlando Magic" },
  { value: "osasuna-pamplona-irunea", name: "CA Osasuna" },
  { value: "osijek-osijek", name: "NK Osijek" },
  { value: "paderborn-paderborn", name: "SC Paderborn 07" },
  { value: "paris-paris", name: "Paris FC" },
  { value: "pec-zwolle-zwolle", name: "PEC Zwolle" },
  { value: "philadelphia-76ers", name: "Philadelphia 76ers" },
  { value: "philadelphia-phillies", name: "Philadelphia Phillies" },
  {
    value: "philadelphia-union-chester-pennsylvania",
    name: "Philadelphia Union",
  },
  { value: "phoenix-suns", name: "Phoenix Suns" },
  { value: "pittsburgh-pirates", name: "Pittsburgh Pirates" },
  { value: "pohang-steelers-pohang", name: "Pohang Steelers FC" },
  { value: "poland", name: "Poland" },
  { value: "ponferradina-ponferrada", name: "SD Ponferradina" },
  { value: "portland-timbers-portland-oregon", name: "Portland Timbers" },
  { value: "portland-trail-blazers", name: "Portland Trail Blazers" },
  { value: "porto-porto", name: "FC Porto" },
  { value: "portugal", name: "Portugal" },
  { value: "psg-paris", name: "Paris Saint-Germain" },
  { value: "psv-eindhoven", name: "PSV Eindhoven" },
  {
    value: "pumas-unam-delegacion-coyoacan-ciudad-de-mexico-d-f",
    name: "Pumas de la Universidad Nacional Autonoma de Mexico",
  },
  { value: "qatar", name: "Qatar" },
  { value: "queretaro-queretaro", name: "Querétaro FC" },
  {
    value: "racing-club-avellaneda-provincia-de-buenos-aires",
    name: "Racing Club de Avellaneda",
  },
  {
    value: "racing-santander-santander",
    name: "Real Racing Club de Santander",
  },
  { value: "rapid-wien-wien", name: "SK Rapid Wien" },
  { value: "rayo-vallecano-madrid", name: "Rayo Vallecano" },
  { value: "rb-leipzig-leipzig", name: "RB Leipzig" },
  { value: "real-betis-sevilla", name: "Real Betis Balompié" },
  { value: "real-madrid-madrid", name: "Real Madrid CF" },
  { value: "real-oviedo-oviedo", name: "Real Oviedo" },
  { value: "real-salt-lake-salt-lake-city-utah", name: "Real Salt Lake" },
  {
    value: "real-sociedad-donostia-san-sebastian",
    name: "Real Sociedad de Fútbol",
  },
  {
    value: "real-valladolid-valladolid",
    name: "Real Valladolid Club de Fútbol",
  },
  { value: "real-zaragoza-zaragoza", name: "Real Zaragoza" },
  { value: "reims-reims", name: "Stade de Reims" },
  { value: "rennes-rennes", name: "Stade Rennais F.C." },
  { value: "rfc-seraing-seraing", name: "RFC Seraing" },
  { value: "rheindorf-altach-altach", name: "CASHPOINT SCR Altach" },
  { value: "ried-ried-im-innkreis", name: "SV Guntamatic Ried" },
  { value: "rijeka-rijeka", name: "HNK Rijeka" },
  {
    value: "river-plate-capital-federal-ciudad-de-buenos-aires",
    name: "CA River Plate",
  },
  { value: "rizespor-rize", name: "Çaykur Rize Spor Kulübü" },
  { value: "rkc-waalwijk-waalwijk", name: "RKC Waalwijk" },
  { value: "rodez-onet-le-chateau", name: "Rodez Aveyron Football" },
  { value: "roma-roma", name: "AS Roma" },
  {
    value: "rosario-central-rosario-provincia-de-santa-fe",
    name: "CA Rosario Central",
  },
  { value: "rosenborg-trondheim", name: "Rosenborg BK" },
  { value: "ross-county-dingwall", name: "Ross County FC" },
  { value: "rostov-rostov-na-donu", name: "FK Rostov" },
  {
    value: "royal-excel-mouscron-mouscron-moeskroen",
    name: "Royal Excel Mouscron",
  },
  { value: "rubin-kazan-kazan", name: "FK Rubin Kazan" },
  { value: "sacramento-kings", name: "Sacramento Kings" },
  { value: "sagan-tosu-tosu", name: "Sagan Tosu" },
  { value: "saint-etienne-saint-etienne", name: "AS Saint-Étienne" },
  { value: "salernitana-salerno", name: "US Salernitana 1919" },
  { value: "salzburg-wals-siezenheim", name: "FC Red Bull Salzburg" },
  { value: "sampdoria-genova", name: "UC Sampdoria" },
  { value: "san-antonio-spurs", name: "San Antonio Spurs" },
  { value: "san-diego-padres", name: "San Diego Padres" },
  { value: "san-francisco-giants", name: "San Francisco Giants" },
  {
    value: "san-lorenzo-capital-federal-ciudad-de-buenos-aires",
    name: "CA San Lorenzo de Almagro",
  },
  { value: "sandhausen-sandhausen", name: "SV Sandhausen" },
  { value: "sanfrecce-hiroshima-hiroshima", name: "Sanfrecce Hiroshima" },
  { value: "sangju-sangmu-sangju", name: "Gimcheon Sangmu" },
  { value: "santos-laguna-torreon", name: "Club Santos Laguna" },
  { value: "sassuolo-sassuolo", name: "US Sassuolo Calcio" },
  { value: "saudi-arabia", name: "Saudi Arabia" },
  { value: "schalke-04-gelsenkirchen", name: "FC Schalke 04" },
  { value: "seattle-mariners", name: "Seattle Mariners" },
  { value: "seattle-sounders-renton-washington", name: "Seattle Sounders FC" },
  { value: "senegal", name: "Senegal" },
  { value: "seongnam-seongnam", name: "Seongnam FC" },
  { value: "seoul-seoul", name: "FC Seoul" },
  { value: "serbia", name: "Serbia" },
  { value: "sevilla-sevilla-1890", name: "Sevilla FC" },
  { value: "shakhtar-donetsk-donets-k", name: "FC Shakhtar Donetsk" },
  { value: "shimizu-s-pulse-shizuoka", name: "Shimizu S-Pulse" },
  { value: "shonan-bellmare-hiratsuka", name: "Shonan Bellmare" },
  { value: "sibenik-sibenik", name: "HNK Šibenik" },
  { value: "sint-truiden-sint-truiden-st-trond", name: "Sint-Truidense VV" },
  { value: "sion-martigny-croix", name: "FC Sion" },
  { value: "sivasspor-sivas", name: "Sivasspor Kulübü" },
  {
    value: "sj-earthquakes-santa-clara-california",
    name: "San Jose Earthquakes",
  },
  {
    value: "slaven-koprivnica-koprivnica",
    name: "NK Slaven Belupo Koprivnica",
  },
  { value: "sochaux-montbeliard", name: "FC Sochaux-Montbéliard" },
  { value: "sochi-sochi", name: "FK Sochi" },
  { value: "southampton-southampton-hampshire", name: "Southampton FC" },
  { value: "spain", name: "Spain" },
  { value: "sparta-rotterdam-rotterdam", name: "Sparta Rotterdam" },
  { value: "spartak-moskva-moskva", name: "FK Spartak Moskva" },
  { value: "spezia-la-spezia", name: "Spezia Calcio" },
  {
    value: "sporting-charleroi-charleroi",
    name: "Sporting du Pays de Charleroi",
  },
  { value: "sporting-cp-lisboa", name: "Sporting Clube de Portugal" },
  { value: "sporting-gijon-gijon", name: "Real Sporting de Gijón" },
  { value: "sporting-kc-kansas-city-kansas", name: "Sporting Kansas City " },
  { value: "st-johnstone-perth", name: "Saint Johnstone FC" },
  { value: "st-louis-cardinals", name: "St. Louis Cardinals" },
  { value: "st-louis-city-st-louis-missouri", name: "St. Louis City SC" },
  { value: "st-mirren-paisley", name: "Saint Mirren FC" },
  { value: "st-pauli-hamburg", name: "FC St. Pauli" },
  { value: "standard-liege-liege-luik", name: "Royal Standard de Liège" },
  { value: "strasbourg-strasbourg", name: "RC Strasbourg Alsace" },
  { value: "sturm-graz-graz", name: "SK Puntigamer Sturm Graz" },
  { value: "stuttgart-stuttgart", name: "VfB Stuttgart" },
  { value: "suwon-bluewings-suwon", name: "Suwon Samsung Bluewings FC" },
  { value: "suwon-suwon", name: "Suwon FC" },
  { value: "switzerland", name: "Switzerland" },
  { value: "tampa-bay-rays", name: "Tampa Bay Rays" },
  { value: "tenerife-santa-cruz-de-tenerife", name: "CD Tenerife" },
  { value: "texas-rangers", name: "Texas Rangers" },
  {
    value: "tigres-uanl-san-nicolas-de-los-garza",
    name: "CF Tigres de la Universidad Autónoma de Nuevo León",
  },
  { value: "tokushima-vortis-tokushima", name: "Tokushima Vortis" },
  { value: "tokyo-tokyo", name: "FC Tokyo" },
  { value: "torino-torino", name: "Torino FC" },
  { value: "toronto-blue-jays", name: "Toronto Blue Jays" },
  { value: "toronto-raptors", name: "Toronto Raptors" },
  { value: "toronto-toronto", name: "Toronto FC" },
  { value: "tottenham-hotspur-london", name: "Tottenham Hotspur FC" },
  { value: "toulouse-toulouse", name: "Toulouse FC" },
  { value: "trabzonspor-trabzon", name: "Trabzonspor Kulübü" },
  { value: "tunisia", name: "Tunisia" },
  { value: "twente-enschede", name: "FC Twente" },
  { value: "ud-ibiza-ibiza-town", name: "UD Ibiza" },
  { value: "udinese-udine", name: "Udinese Calcio" },
  { value: "ufa-ufa", name: "FK Ufa" },
  { value: "ulsan-ulsan", name: "Ulsan Hyundai Horang-i" },
  { value: "umraniyespor-istanbul", name: "Ümraniye Spor Kulübü" },
  { value: "union-berlin-berlin", name: "1. FC Union Berlin" },
  {
    value: "union-saint-gilloise-bruxelles-brussels",
    name: "Royal Union Saint-Gilloise",
  },
  { value: "united-states", name: "United States" },
  {
    value: "universidad-catolica-santiago-de-chile",
    name: "CD Universidad Católica",
  },
  { value: "universitario-lima", name: "Club Universitario de Deportes" },
  { value: "ural-ekaterinburg", name: "FK Ural Sverdlovskaya Oblast" },
  { value: "urawa-reds-saitama", name: "Urawa Red Diamonds" },
  { value: "uruguay", name: "Uruguay" },
  { value: "utah-jazz", name: "Utah Jazz" },
  { value: "utrecht-utrecht", name: "FC Utrecht" },
  { value: "valencia-valencia", name: "Valencia CF" },
  { value: "valenciennes-valenciennes", name: "Valenciennes FC" },
  {
    value: "vancouver-whitecaps-vancouver-british-columbia",
    name: "Vancouver Whitecaps FC",
  },
  { value: "varazdin-varazdin", name: "NK Varaždin" },
  { value: "vegalta-sendai-sendai", name: "Vegalta Sendai" },
  {
    value: "velez-sarsfield-capital-federal-ciudad-de-buenos-aires",
    name: "Club Atlético Vélez Sarsfield",
  },
  { value: "villarreal-ii-villarreal", name: "Villarreal CF II" },
  { value: "villarreal-villarreal", name: "Villarreal CF" },
  { value: "vissel-kobe-kobe", name: "Vissel Kobe" },
  { value: "vitesse-arnhem", name: "SBV Vitesse" },
  { value: "volendam-volendam", name: "FC Volendam" },
  { value: "vvv-venlo", name: "VVV Venlo" },
  { value: "waasland-beveren-beveren-waas", name: "Waasland-Beveren" },
  { value: "wales", name: "Wales" },
  { value: "washington-nationals", name: "Washington Nationals" },
  { value: "washington-wizards", name: "Washington Wizards" },
  { value: "watford-watford", name: "Watford FC" },
  { value: "wattens-wattens", name: "WSG Tirol" },
  { value: "werder-bremen-bremen", name: "SV Werder Bremen" },
  { value: "west-ham-united-london", name: "West Ham United FC" },
  { value: "westerlo-westerlo", name: "KVC Westerlo" },
  { value: "willem-ii-tilburg", name: "Willem II" },
  { value: "wolfsberger-ac-wolfsberg", name: "RZ Pellets WAC" },
  { value: "wolfsburg-wolfsburg", name: "VfL Wolfsburg" },
  {
    value: "wolverhampton-wanderers-wolverhampton",
    name: "Wolverhampton Wanderers FC",
  },
  { value: "yeni-malatyaspor-malatya", name: "Yeni Malatya Spor Kulübü" },
  { value: "yokohama-f-marinos-yokohama", name: "Yokohama F. Marinos" },
  { value: "yokohama-yokohama", name: "Yokohama FC" },
  {
    value: "zenit-sankt-peterburg-st-petersburg",
    name: "FK Zenit St. Petersburg",
  },
  { value: "zulte-waregem-waregem", name: "SV Zulte-Waregem" },
];
