import React from "react";
import CaptainBadge from "./captainBadge";
import { baseballScale, basketballScale, footballScale } from "./scales";
import { sorareBaseball, sorareBasketball } from "./sports";

function ScoreEllipse(props) {
  let isCaptain = false;
  let shadow = "";
  if (props.isCaptain) {
    isCaptain = true;
  }
  if (props.shadow) {
    shadow = props.shadow;
  }
  let padding = "px-2 py-0.5";
  if (props.padding) {
    padding = props.padding;
  }
  let size = "text-sm xl:text-base 2xl:text-lg";
  if (props.size) {
    size = props.size;
  }
  let scale = footballScale;
  if (props.sport === sorareBaseball) scale = baseballScale;
  if (props.sport === sorareBasketball) scale = basketballScale;
  if (props.score === "DNP") {
    let textColor = props.dnpTextColor || "text-textGrey3";
    return (
      <div className={"rounded-2xl space-x-1 flex flex-row px-2 py-0.5 bg-grey-e5"}>
        <p className={size + " font-semibold " + textColor}>DNP</p>
        {isCaptain && (
          <div className={"self-center"}>
            <CaptainBadge color={props.badgeColor || "#808080"} />
          </div>
        )}
      </div>
    );
  } else {
    let floatSO5Score = parseFloat(props.score);
    let power = props.power;
    let color = "";
    let totalScore = floatSO5Score * power;
    let nbPlayers = 1;
    if (props.nbPlayers) {
      nbPlayers = props.nbPlayers;
    }

    if (floatSO5Score < scale[0] * nbPlayers) {
      color = "bg-horrible-score";
    } else if (floatSO5Score < scale[1] * nbPlayers) {
      color = "bg-bad-score";
    } else if (floatSO5Score < scale[2] * nbPlayers) {
      color = "bg-ok-score";
    } else if (floatSO5Score < scale[3] * nbPlayers) {
      color = "bg-good-score";
    } else if (floatSO5Score < scale[4] * nbPlayers) {
      color = "bg-great-score";
    } else if (floatSO5Score <= 1000 * nbPlayers) {
      color = "bg-amazing-score";
    }
    let precision = 1;
    if (props.precision !== undefined) {
      precision = props.precision;
    }

    if (props.round) {
      if (props.score >= 99.95) {
        totalScore = 100;
      } else {
        totalScore = totalScore.toFixed(precision);
      }
    } else {
      totalScore = totalScore.toFixed(precision);
    }
    let textColor = "text-white";
    const onGameSheet = props.onGameSheet;
    const game = props.game;
    if (props.sub) {
      totalScore = "SUB";
      color = "bg-grey-e5";
      textColor = "text-textGrey3";
    } else if (props.out) {
      totalScore = "OUT";
      color = "bg-grey-e5";
      textColor = "text-textGrey3";
    } else if (game) {
      if (game.Status === "playing" || (game.Status === "scheduled" && game.HasLineups === true)) {
        if (props.formationPlace === undefined && props.minsPlayed === undefined) {
          totalScore = "OUT";
          color = "bg-grey-e5";
          textColor = "text-textGrey3";
        } else if (props.formationPlace === 0 && props.minsPlayed === 0) {
          if (onGameSheet === true) {
            totalScore = "SUB";
            color = "bg-grey-e5";
            textColor = "text-textGrey3";
          } else {
            totalScore = "OUT";
            color = "bg-grey-e5";
            textColor = "text-textGrey3";
          }
        }
      } else if (game.Status === "scheduled" || game.Status === "") {
        totalScore = "DNP";
        color = "bg-grey-e5";
        textColor = "text-textGrey3";
      }
    } else if (props.has_played === false || props.dnp) {
      totalScore = "DNP";
      color = "bg-grey-e5";
      textColor = "text-textGrey3";
    }

    if (props.color) {
      color = props.color;
    }

    if (props.textColor) {
      textColor = props.textColor;
    }

    return (
      <div className={shadow + " rounded-2xl space-x-1 justify-center flex flex-row " + padding + " " + color}>
        <p className={size + " font-semibold text-focus " + textColor}>{totalScore}</p>
        {isCaptain && (
          <div className={"self-center"}>
            <CaptainBadge color={props.badgeColor || "white"} />
          </div>
        )}
      </div>
    );
  }
}

export default ScoreEllipse;
