import React from "react";
import GameLivePageHeader from "./gameLivePageHeader";
import SubIn from "../../img/sub-in-details.svg";
import SubInInversed from "../../img/sub-in-inversed.svg";
import UnknownClub from "../../img/unknown_club.png";

function GameBench(props) {
  const homeTeam = props.homeTeam;
  const awayTeam = props.awayTeam;
  const timeMin = props.game.game.TimeMin;

  const renderBenchPlayer = (p, j, s, i, sh) => {
    return (
      <div className={"space-x-1.5 flex flex-row " + j}>
        {i && s && (
          <div className={"w-5 h-3 self-center"}>
            <img className={"w-full h-full object-contain self-center"} src={SubInInversed} />
          </div>
        )}
        {!i && sh && <p className={"text-xs text-textGrey3 font-semibold self-center pt-0.5 w-4"}>{p.ShirtNumber}</p>}
        <p className={"text-sm font-semibold self-center"}>
          <a href={"/player/" + p.PlayerId} target={"_blank"} rel="noreferrer">
            {p.MatchName}
          </a>
        </p>
        {!i && s && (
          <div className={"w-5 h-3 self-center"}>
            <img className={"w-full h-full object-contain self-center"} src={SubIn} />
          </div>
        )}
        {i && sh && <p className={"text-xs text-textGrey3 font-semibold text-right self-center pt-0.5 w-4"}>{p.ShirtNumber}</p>}
      </div>
    );
  };
  return (
    <div className={"space-y-4"}>
      <div className={"space-y-4"}>
        <GameLivePageHeader label={"Benches"} />

        <div className={"flex flex-row bg-white rounded-xl p-4"}>
          <div className={"flex flex-col space-y-2 border-r border-grey-e5 w-6/12"}>
            <div className={"flex flex-row justify-start"}>
              <div className={"w-10 h-10"}>
                <img
                  className={"w-full h-full object-contain"}
                  src={homeTeam.team.PictureUrl !== "" ? homeTeam.team.PictureUrl : UnknownClub}
                />
              </div>
            </div>
            {homeTeam.positions.substitutes !== null &&
              homeTeam.positions.substitutes.map((p) => {
                return renderBenchPlayer(p, "justify-start", true, false, true);
              })}
            {homeTeam.positions.bench !== null &&
              homeTeam.positions.bench.map((p) => {
                return renderBenchPlayer(p, "justify-start", false, false, true);
              })}
          </div>
          <div className={"flex flex-col space-y-2 w-6/12 border-l border-grey-e5"}>
            <div className={"flex flex-row justify-end"}>
              <div className={"w-10 h-10"}>
                <img
                  className={"w-full h-full object-contain"}
                  src={awayTeam.team.PictureUrl !== "" ? awayTeam.team.PictureUrl : UnknownClub}
                />
              </div>
            </div>
            {awayTeam.positions.substitutes !== null &&
              awayTeam.positions.substitutes.map((p) => {
                return renderBenchPlayer(p, "justify-end", true, true, true);
              })}
            {awayTeam.positions.bench !== null &&
              awayTeam.positions.bench.map((p) => {
                return renderBenchPlayer(p, "justify-end", false, true, true);
              })}
          </div>
        </div>
      </div>
      <div className={"space-y-4"}>
        <GameLivePageHeader label={"Out of squads"} />

        <div className={"flex flex-row bg-white rounded-xl p-4"}>
          <div className={"flex flex-col space-y-2 border-r border-grey-e5 w-6/12"}>
            <div className={"flex flex-row justify-start"}>
              <div className={"w-10 h-10"}>
                <img
                  className={"w-full h-full object-contain"}
                  src={homeTeam.team.PictureUrl !== "" ? homeTeam.team.PictureUrl : UnknownClub}
                />
              </div>
            </div>
            {homeTeam.positions.out !== null &&
              homeTeam.positions.out.map((p) => {
                return renderBenchPlayer(p, "justify-start", false, false, false);
              })}
          </div>
          <div className={"flex flex-col space-y-2 w-6/12 border-l border-grey-e5"}>
            <div className={"flex flex-row justify-end"}>
              <div className={"w-10 h-10"}>
                <img
                  className={"w-full h-full object-contain"}
                  src={awayTeam.team.PictureUrl !== "" ? awayTeam.team.PictureUrl : UnknownClub}
                />
              </div>
            </div>
            {awayTeam.positions.out !== null &&
              awayTeam.positions.out.map((p) => {
                return renderBenchPlayer(p, "justify-end", false, true, false);
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameBench;
