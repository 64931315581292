import React from "react";

function DecisiveActionBox(props) {
  return (
    <div className={"flex flex-row space-x-2 items-center"}>
      <div className={"w-6 h-6"} data-tip={props.tip}>
        <img className={"w-full h-full object-contain"} src={props.img} />
      </div>
      <p className={"text-base font-semibold text-center"}>{props.value}</p>
    </div>
  );
}

export default DecisiveActionBox;
