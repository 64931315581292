import React, { useEffect, useState } from "react";
import Scorers from "../teams/scorers";
import TeamStatsTable from "../teams/teamStatsTable";
import SelectSearch from "react-select-search";
import { all_positions_objects } from "../util/positions";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import { sorareFootball } from "../util/sports";

function ManagerRosterStats(props) {
  const [rosterInfo, setRosterInfo] = useState({});
  const [nbGames, setNbGames] = useState(15);
  const [position, setPosition] = useState("all");

  useEffect(() => {
    getRosterStats();
  }, []);

  const getRosterStats = (nb, p) => {
    if (nb === undefined) nb = nbGames;
    if (p === undefined) p = position;
    props
      .fetch("/apiv2/manager/aggregatedStats/" + props.manager.Slug + "?games=" + nb + "&position=" + p)
      .then((response) => response.json())
      .then((res) => {
        setRosterInfo(res);
      })
      .catch(errorCatcher());
  };

  if (rosterInfo === undefined) {
    return null;
  }

  const topAssists = rosterInfo.top_assists;
  const topAverages = rosterInfo.top_averages;
  const topAllAround = rosterInfo.top_all_around;
  const topDecisiveActions = rosterInfo.top_decisive;
  const topMinutes = rosterInfo.top_minutes;
  const topScorers = rosterInfo.top_scorers;
  const topFK = rosterInfo.direct_freekick_takers;
  const topPK = rosterInfo.top_penalty_takers;
  const topFKA = rosterInfo.top_deadball_assists;
  const topCorners = rosterInfo.top_corner_takers;
  let colorStart = "";
  let colorEnd = "";
  if (colorStart === "") {
    colorStart = "#132D8F";
    colorEnd = "#1D3EBD";
  }

  const active = "relative inline-flex items-center px-4 py-2 border bg-brand-pastel text-sm font-medium text-gray-700 focus:outline-none";
  const inactive =
    "relative inline-flex items-center px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-brand-pastel focus:outline-none";

  if (rosterInfo.aggregated_stats || t1OrBelow(props.user.tier)) {
    return (
      <div className={"space-y-6"}>
        <div className={"space-y-4"}>
          <div className={"flex flex-row justify-between"}>
            <p className={"text-brand font-semibold font-headers text-xl"}>Top players by category</p>
            <div className={"flex flex-row space-x-4"}>
              <div>
                <SelectSearch
                  className={"select-search z-20"}
                  options={all_positions_objects}
                  printOptions={"on-focus"}
                  value={position}
                  closeOnSelect={true}
                  onChange={(v) => {
                    setPosition(v);
                    getRosterStats(undefined, v);
                  }}
                />
              </div>
              <div>
                <span className="relative z-0 inline-flex rounded-md">
                  <button
                    onClick={() => {
                      setNbGames(5);
                      getRosterStats(5);
                    }}
                    type="button"
                    className={nbGames === 5 ? "rounded-l-lg " + active : "rounded-l-lg " + inactive}
                  >
                    Last 5
                  </button>
                  <button
                    onClick={() => {
                      setNbGames(15);
                      getRosterStats(15);
                    }}
                    type="button"
                    className={nbGames === 15 ? active : inactive}
                  >
                    Last 15
                  </button>
                  <button
                    onClick={() => {
                      setNbGames(40);
                      getRosterStats(40);
                    }}
                    type="button"
                    className={nbGames === 40 ? "rounded-r-lg " + active : "rounded-r-lg " + inactive}
                  >
                    Last 40
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"}>
            <Scorers showAll={true} scorers={topAverages} title={"Top SO5 averages"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topAllAround} title={"All-around averages"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topDecisiveActions} title={"Top net decisive actions"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topMinutes} title={"Top minutes played"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topScorers} title={"Top goalscorers"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topAssists} title={"Top assists"} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topPK} title={"Penalty kicks"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topFK} title={"Freekick scorers"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topFKA} title={"Freekick assists"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
            <Scorers showAll={true} scorers={topCorners} title={"Corner takers"} bgStart={colorStart} bgEnd={colorEnd} />
          </div>
        </div>

        {t2OrAbove(props.user.tier) && (
          <div className={"space-y-4"}>
            <p className={"text-brand font-semibold font-headers text-xl"}>Roster detailed stats</p>
            <TeamStatsTable
              comps={[{ name: "All competitions", value: "all" }]}
              manager={props.manager}
              sport={sorareFootball}
              stats={rosterInfo.aggregated_stats}
            />
          </div>
        )}
        {t1OrBelow(props.user.tier) && (
          <PlayerBlockedVideo
            title="Want more insights?"
            description="Become a Star member to unlock roster detailed stats"
            videoLink="https://www.youtube.com/embed/0Mn04-435qk"
          />
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default withUser(ManagerRosterStats);
