import UnknownPlayer from "../../img/unknown_player2.png";
import React from "react";
import { withUser } from "../../userContext";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import { convertToBaseballAverage } from "../util/baseballAverage";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";

function PlayerAndValue(props) {
  const player = props.player;
  let value = props.value;
  let total = props.total;
  if (total > 0 && value > total) {
    value = total;
  }
  let pct = (value * 100) / total;
  if (props.baseball && props.showPct) {
    value = convertToBaseballAverage(props.pct);
  } else if (props.baseball) {
    value = convertToBaseballAverage(props.value);
  }
  if (props.precision) {
    value = value.toFixed(props.precision);
  }
  let zoom = "pt-2 object-contain";
  if (props.sport === sorareBasketball || props.sport === sorareBaseball) {
    zoom = "object-cover";
  }
  return (
    <a href={"/player/" + player.PlayerId}>
      <div className={"flex flex-row justify-between border-b px-4 border-grey-e5 w-full py-2 self-center h-18"}>
        <div key={player.PlayerId} className={"flex flex-row space-x-4"}>
          <div className={"w-14 h-14 rounded-full mx-auto flex flex-row justify-center"}>
            {player.Avatar !== "" && <img className={"rounded-full w-12 h-12 bg-grey-f2 self-center " + zoom} src={player.Avatar} />}
            {player.Avatar === "" && (
              <svg className={"w-12 h-12 text-gray-300 rounded-full bg-grey-f2 self-center"} fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            )}
          </div>
          <p className={"text-med self-center font-semibold hover:font-bold"}>{player.MatchName}</p>
        </div>
        <div className={"flex flex-col text-center space-y-1 self-center"}>
          {!props.more && <p className={"text-xl self-center font-semibold"}>{value}</p>}
          {props.more && (
            <div>
              <p className={"text-xl font-semibold"}>
                {pct.toFixed(0)}%{" "}
                <span className={"font-medium text-sm text-textGrey3"}>
                  {value}/{total}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </a>
  );
}

function Scorers(props) {
  const scorers = props.scorers;
  if (scorers) {
    const first_scorer = scorers[0];
    const title = props.title;
    let rounded = "rounded-t-lg";
    if (
      (isFree(props.user.tier) && props.sport === sorareFootball) ||
      (scorers.length === 1 && (t1OrAbove(props.user.tier) || props.showAll))
    ) {
      rounded = "rounded-lg";
    }
    let value = first_scorer.value;
    let total = first_scorer.total;
    if (total > 0 && value > total) {
      value = total;
    }
    let pct = (value * 100) / total;
    if (props.baseball && props.showPct) {
      value = convertToBaseballAverage(first_scorer.pct);
    } else if (props.baseball) {
      value = convertToBaseballAverage(first_scorer.value);
    }
    if (props.precision) {
      value = value.toFixed(props.precision);
    }

    let avatar = first_scorer.player.SquaredPictureUrl;
    if (props.sport === sorareBasketball) {
      avatar = first_scorer.player.Avatar;
    }
    const sport = props.sport;

    return (
      <div className={""}>
        <div
          className={"relative " + rounded + " flex flex-row w-full h-36"}
          style={{
            background: "linear-gradient(to left, " + props.bgStart + ", " + props.bgEnd + ")",
          }}
        >
          <div className={"absolute w-full h-36 z-0 text-focus overflow-hidden " + rounded} style={{ opacity: "10%" }}>
            <div className={"h-player rounded-t-lg flex w-full h-36 bg-gradient-to-r from-white to-full-black z-10"} />
          </div>
          <div className={"flex flex-col space-y-4 w-8/12 text-white p-4 z-10"}>
            <p className={"text-base uppercase font-semibold text-white whitespace-nowrap"}>{title}</p>
            <a href={"/player/" + first_scorer.player.PlayerId} target={"_blank"} rel="noreferrer">
              <p className={"text-white text-base font-semibold hover:font-bold z-20"}>{first_scorer.player.MatchName}</p>
            </a>
            {!props.more && <p className={"text-1.5xl text-white font-semibold"}>{value}</p>}
            {props.more && (
              <div>
                <p className={"text-1.5xl font-semibold "}>
                  {pct.toFixed(0)}%{" "}
                  <span className={"font-medium text-sm text-white"}>
                    {value}/{total}
                  </span>
                </p>
              </div>
            )}
          </div>
          {(avatar === "" || sport !== sorareBasketball) && (
            <div className={"absolute flex text-white opacity-100 z-0 overflow-hidden w-full h-36  self-end bottom-0 top-0 inset-x-0"}>
              <div className={"h-full absolute z-0 right-4 self-end align-bottom pt-2"}>
                {<img className={"object-cover h-full"} src={avatar !== "" ? avatar : UnknownPlayer} />}
              </div>
            </div>
          )}
          {sorareBasketball === sport && avatar !== "" && (
            <div className={"absolute flex text-white opacity-100 z-0 overflow-hidden w-full h-36  self-end bottom-0 top-0 inset-x-0"}>
              <div className={"h-full absolute z-0 right-4 self-end align-bottom py-4 rounded-lg"}>
                {<img className={"object-cover h-full rounded-lg"} src={avatar !== "" ? avatar : UnknownPlayer} />}
              </div>
            </div>
          )}
        </div>
        <div className={`bg-white rounded-b-lg max-h-96 overflow-x-hidden no-scroll-bar`}>
          <div className={"flex flex-col "}>
            {scorers?.map((ts, i) => {
              const player = ts.player;
              const value = ts.value;
              if ((t1OrAbove(props.user?.tier) || props.showAll || props.sport === sorareBaseball) && i > 0) {
                return (
                  <PlayerAndValue
                    baseball={props.baseball}
                    sport={sport}
                    showPct={props.showPct}
                    player={player}
                    value={value}
                    pct={ts.pct}
                    total={ts.total}
                    more={props.more}
                    precision={props.precision}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default withUser(Scorers);
