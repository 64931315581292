import { PriceAlertSummaryItem } from "./priceAlertSummary";
import { Button } from "../util/button";
import { ReactComponent as IconRocket } from "../../img/icon-rocket.svg";
import React from "react";
import { useHistory } from "react-router-dom";

export const PriceAlertPaywall = () => {
  const history = useHistory();
  return (
    <div className="lg:max-w-lg p-2 px-4 space-y-4">
      <p className="text-center text-sm text-on-surface-variant font-medium">
        Rookie member are capped to 20 alerts which you have reached already.
      </p>
      <p className="text-center text-sm text-on-surface font-medium">
        Unlock unlimited alerts, dynamic prices, edition and season filters with the Star membership
      </p>
      <PriceAlertSummaryItem
        alert={{
          scarcity: "LIMITED",
          listingType: "offer",
          computedPrice: {
            eth: 0.094,
            usd: 241.6373,
            eur: 218.863,
            gbp: 186.4443,
          },
          seasons: ["2024"],
        }}
        preferredUnit="eth"
        hideActions
      />
      <div className="text-center">
        <Button onClick={() => history.push("/product")}>
          <IconRocket className={"w-4 h-4"} />
          <span>Upgrade membership</span>
        </Button>
      </div>
    </div>
  );
};
