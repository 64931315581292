import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import ScoreEllipse from "../util/scoreEllipse";
import { errorCatcher } from "../util/errors";
import { sorareBasketball, sorareFootball } from "../util/sports";
import BaseballScoreCircle from "../util/baseballScoreCircle";

function SO5PlayersOverview(props) {
  const [players, setPlayers] = useState([]);

  useEffect(() => getPlayers(), [props.sport, props.username]);

  const getPlayers = () => {
    let sport = sorareFootball;
    if (props.sport) {
      sport = props.sport;
    }
    if (props.username !== null && props.username !== undefined && props.gw !== null) {
      props
        .fetch("/apiv2/SO5/userPlayers/" + props.gw + "/" + props.username + "?sport=" + sport)
        .then((response) => response.json())
        .then((res) => {
          setPlayers(res);
        })
        .catch(errorCatcher());
    }
  };

  return (
    <div className={"h-reward-card overflow-y-auto z-0 rounded-xl bg-black-1f"}>
      <div className={"flex flex-row space-x-4 border-b border-[#4D4D4D] bg-black-1f h-12"}>
        <p className={"pl-4 font-headers text-base self-center font-semibold text-grey-b3"}>Your players</p>
      </div>
      {players !== null &&
        players.length > 0 &&
        players.map((p) => {
          return (
            <div
              key={p.player.PlayerId}
              className={"flex flex-row space-x-4 border-b border-[#4D4D4D] justify-center self-center bg-black-1f h-16"}
            >
              <div className={"flex flex-row items-center justify-center w-20 pl-3"}>
                <a href={"/player/" + p.player.PlayerId}>
                  <div className={"w-14 h-14 rounded-full self-center flex"}>
                    <img
                      className={
                        props.sport === sorareBasketball
                          ? "h-12 w-12 rounded-full object-cover self-center"
                          : "h-full w-full rounded-full object-contain pt-1"
                      }
                      src={p.player.Avatar}
                    />
                  </div>
                </a>
              </div>
              <div className={"flex flex-col self-center space-y-1 w-10/12"}>
                <p className={"text-base font-semibold hover:font-bold text-white"}>
                  <a href={"/player/" + p.player.PlayerId}>{p.player.DisplayName}</a>
                </p>
                <p className={"text-sm text-grey-b3"}>
                  {p.player.Position} - {p.player.Age}
                </p>
              </div>
              <div className={"flex flex-row text-center w-3/12 pr-8 justify-end"}>
                <div className={"w-14 self-center"}>
                  {props.sport !== sorareBasketball && (
                    <ScoreEllipse score={p.stats.SO5Score} power={1} textColor={"text-[#1A1A1A]"} size={"text-base"} round={true} />
                  )}
                  {props.sport === sorareBasketball && (
                    <BaseballScoreCircle cr={"180"} dark={true} sport={sorareBasketball} score={p.stats.score} power={1} round={true} />
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default withUser(SO5PlayersOverview);
