import React, { Component } from "react";
import { Anchor } from "grommet";

class ManagerLink extends Component {
  render() {
    let manager = this.props.manager;
    let nickname = manager.Nickname;
    if (manager.LoomAddress === "0x552Ed16b186D8D2Abeaa1eB6352d1DE5D449Ad90") {
      nickname = "Credit Card bid";
    } else if (manager.Slug === "") {
      nickname = "Unknown user to SorareData";
    }
    return (
      <Anchor color={"black"} href={"/manager/" + manager.Slug}>
        {manager.TeamName} ({nickname})
      </Anchor>
    );
  }
}

export default ManagerLink;
