import React, { useCallback, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Range as RcRange } from "rc-slider";
import { getThemeColor, useTheme } from "../../themeContext";

const GlobalStyles = createGlobalStyle`
  body {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
  }
`;

export const Track = styled("div")`
  display: inline-block;
  height: 8px;
  width: 90%;
  margin: 0 5%;
`;

export const Tick = styled("div")`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    height: 5px;
    width: 2px;
    transform: translate(-50%, 0.7rem);
  }
`;

export const TickLabel = styled("div")`
  position: absolute;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
`;

export const Segment = styled("div")`
  background: ${(props) => (props.index === 0 ? "#DADADA" : props.index === 1 ? props.color : props.index === 2 ? "#DADADA" : props.color)};
  height: 100%;
`;

export const Handle = styled("div")`
  background: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
  color: white;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transform: ${(props) => (props.active ? "translateY(-100%) scale(1.3)" : "translateY(0) scale(0.9)")};
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

export const RangeWithLabel = (props) => {
  const { label, range, onChange, disabled } = props;
  const min = props.min !== undefined ? props.min : 0;
  const max = props.max !== undefined ? props.max : 100;
  const unit = props.unit != undefined ? props.unit : "";
  const [localRange, setLocalRange] = useState([...range]);
  const enabled = disabled !== undefined ? !disabled : true;
  useEffect(() => {
    setLocalRange([...range]);
  }, [range]);
  const onLocalChange = useCallback(
    (val) => {
      if (enabled) {
        setLocalRange([...val]);
      }
    },
    [enabled],
  );
  return (
    <div className={"space-y-1"}>
      <p className={"font-medium text-on-surface-variant text-sm flex flex-row gap-2"}>
        <span>{label}</span>
        {enabled && (
          <span className={"font-semibold text-on-surface"}>
            {localRange[0]}
            {unit} - {localRange[1]}
            {unit}
          </span>
        )}
      </p>
      <div className={"px-2"}>
        <Range
          step={1}
          value={localRange}
          min={min}
          max={max}
          allowCross={false}
          disabled={!enabled}
          onChange={onLocalChange}
          onAfterChange={enabled ? (v) => onChange([...v]) : undefined}
        />
      </div>
    </div>
  );
};

export const Range = (props) => {
  const { theme } = useTheme();
  return (
    <RcRange
      railStyle={{
        backgroundColor: getThemeColor(theme, "surface-container-high"),
      }}
      trackStyle={[{ backgroundColor: getThemeColor(theme, "primary") }]}
      handleStyle={[{ border: "solid 1px " + getThemeColor(theme, "primary") }, { border: "solid 1px " + getThemeColor(theme, "primary") }]}
      {...props}
    />
  );
};
