export const getNavigatorLang = () => {
  return navigator.language || navigator.languages[0] || "en-US";
};

export const getDefaultCurrency = () => {
  const lang = getNavigatorLang();
  if (lang === "en-US") {
    return "usd";
  } else if (lang === "en-GB") {
    return "gbp";
  } else {
    return "eur";
  }
};
