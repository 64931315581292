import React from "react";

function NBALineupPointsRemaining(props) {
  const cap = props.cap;
  const points = props.points;
  const concept = props.concept;
  let size = (((cap - points) * 100) / cap).toFixed(0) + "%";
  let bg = "bg-super-rare";
  if (cap < points) {
    bg = "bg-rare";
    size = "100%";
  }
  let greyText = "text-textGrey3";
  if (props.concept) {
    greyText = "text-white";
  }
  if (cap > 0) {
    return (
      <div className={"space-y-0.5"}>
        <div className={"self-center flex flex-row justify-between"}>
          <div>
            <p className={"text-xs font-semibold " + greyText}>REMAINING POINTS</p>
          </div>
          <div>
            <p className={"font-semibold text-xs"}>
              <span>{props.cap - props.points}</span>/<span className={greyText}>{props.cap}</span>
            </p>
          </div>
        </div>
        <div className="cursor-pointer w-full bg-grey-e5 rounded-full h-1.5 self-center">
          <div className={bg + " h-1.5 rounded-full"} style={{ width: size }} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={"space-y-0.5"}>
        <div className={"self-center flex flex-row justify-between"}>
          <div>
            <p className={"text-xs font-semibold " + greyText}>NO CAP</p>
          </div>
          <div>
            <p className={"font-semibold text-xs"}>
              <span>{props.points} pts</span>
            </p>
          </div>
        </div>
        <div className="cursor-pointer w-full bg-grey-e5 rounded-full h-1.5 self-center">
          <div className={"bg-super-rare h-1.5 rounded-full"} style={{ width: "100%" }} />
        </div>
      </div>
    );
  }
}

export default NBALineupPointsRemaining;
