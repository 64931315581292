import React, { useEffect, useState } from "react";
import { Anchor, Box } from "grommet";
import { withUser } from "../../userContext";
import CardLink from "../cards/cardLink";
import PlayerLink from "./playerLink";
import { errorCatcher } from "../util/errors";
import clsx from "clsx";

export const CardsOwnedForPlayer = withUser((props) => {
  const { player, isLite, hidePlayerName } = props;
  const [cards, setCards] = useState([]);

  useEffect(() => {
    getCards();
  }, [player]);

  const getCards = () => {
    if (props.user.sorareSlug !== null && props.user.sorareSlug !== "" && props.player.PlayerId) {
      props
        .fetch("/apiv2/cards/cardsOwned/" + player.PlayerId + "/" + props.user.sorareSlug)
        .then((response) => response.json())
        .then((res) => {
          setCards(res);
        })
        .catch(errorCatcher());
    }
  };

  let endCopy = "";
  if (cards.length > 1) {
    endCopy = "s";
  }

  /* to use when the modal is ready
  if (isLite) {
    return (
      <div
        className="w-full bg-surface-container self-center px-3 py-2 rounded-full text-on-surface text-base font-semibold flex justify-center items-center">
        <PlayerCardsIcon className="w-6 h-6"/>
        &nbsp;&nbsp;{cards.length} card{endCopy} owned
      </div>
    );
  }
  */

  return (
    <Box>
      {props.user.sorareSlug !== null && props.user.sorareSlug !== "" ? (
        <CardsOwned cards={cards} player={player} endCopy={endCopy} isLite={isLite} hidePlayerName={hidePlayerName} />
      ) : (
        <Box>
          <p className={"text-xs"}>
            Wanna know if you own this player? Link your Sorare account in the <Anchor href={"/profile"}>profile page</Anchor>!
          </p>
        </Box>
      )}
    </Box>
  );
});

const CardsOwned = ({ cards, player, endCopy, isLite, hidePlayerName }) => {
  let className = "bg-white bg-opacity-10 rounded";
  if (isLite) {
    className = "text-on-surface-variant";
  }
  return (
    <>
      {cards.length > 0 ? (
        <div className={clsx(className, "text-xs px-1 py-0.5")}>
          <p className={"text-xs text-center self-center"}>
            You own {cards.length} card{endCopy}
            {!hidePlayerName && (
              <>
                {" of "}
                <PlayerLink size={"text-xs"} player={player} />
              </>
            )}
            {": "}
            {cards.map((c, i) => {
              let virgule = ", ";
              if (i > 2) {
                return null;
              }
              if (i === cards.length - 1 || i === 2) virgule = "";
              return (
                <span key={c.TokenId}>
                  <CardLink sport={player.Sport} size={"text-xs"} key={c.TokenId} card={c} />
                  {virgule}
                </span>
              );
            })}
            {cards.length > 3 ? <span> and {cards.length - 3} more</span> : null}
          </p>
        </div>
      ) : (
        <Box>
          <p className={"text-xs text-center"}>
            You do not own any card of <PlayerLink size={"text-xs"} player={player} />.
          </p>
        </Box>
      )}
    </>
  );
};
