import { sorareBaseball, sorareBasketball, sorareFootball } from "./sports";
import { teams } from "./teams";
import { baseball_clubs_objects, not_all_baseball_clubs_objects } from "./baseballClubs";
import { not_all_basketball_clubs_objects } from "./basketballClubs";

export const getClubsFromSport = (sport, withAll) => {
  let clubs = [];
  if (withAll) {
    clubs = [{ value: "all", name: "All clubs" }];
  }
  if (sport === sorareFootball) {
    clubs.push(...teams);
  } else if (sport === sorareBaseball) {
    clubs.push(...not_all_baseball_clubs_objects);
  } else if (sport === sorareBasketball) {
    clubs.push(...not_all_basketball_clubs_objects);
  } else {
    clubs.push(...teams, ...baseball_clubs_objects, ...not_all_basketball_clubs_objects);
  }
  return clubs;
};
