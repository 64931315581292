import React, { Fragment, useEffect, useState } from "react";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { ReactComponent as HourGlass } from "../../img/icons-hourglass.svg";
import { useElementSize } from "usehooks-ts";
import Eye from "./eye";
import { ReactTooltip } from "../util/tooltip.js";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "./button";
import { PickScoreLegendBox, PickScoreLegendExample } from "../decision/footballLineupTableLegend";
import PopHoverWrapper from "./popHoverWrapper";
import PlayCircle from "../../img/play_circle.svg";
import { ReactComponent as LockerIcon } from "../../img/locker-icon-no-color.svg";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import { getThemeColor, useTheme } from "../../themeContext";

const pickScoreInfo = {
  AAA: { color: "#135D3B", val: 100 },
  AA: { color: "#1D7B50", val: 87.5 },
  A: { color: "#72AA5D", val: 75 },
  B: { color: "#C7C63D", val: 62.5 },
  C: { color: "#EDD01C", val: 50 },
  D: { color: "#F4B432", val: 37.5 },
  E: { color: "#F99864", val: 25 },
  F: { color: "#FE8684", val: 12.5 },
  "-": { color: "#BDBDBD", val: 100 },
  NG: { color: "#BDBDBD", val: 0 },
  "": { color: "#BDBDBD", val: 100 },
};

// undefined is "hidden/not authorized", empty is "no pick score"
const pickScoreOrder = ["AAA", "AA", "A", "B", "C", "D", "E", "F", "undefined", "", "NG"];

export const rankFromPickScoreGroup = (pickScoreGroup) => {
  const pickScoreLabels = pickScoreOrder;
  if (pickScoreGroup === undefined || pickScoreGroup === null) {
    return pickScoreLabels.findIndex((e) => e === "undefined") + 1;
  }
  const index = pickScoreLabels.findIndex((e) => e === pickScoreGroup);
  if (index >= 0) {
    return index + 1;
  }
  return pickScoreLabels.findIndex((e) => e === "") + 1;
};

export const PickScore = (props) => {
  const label = props.label || "-";
  const pickScoreInf = pickScoreInfo[label] || pickScoreInfo["-"];
  const { theme } = useTheme();
  const color = pickScoreInf.color;
  const val = pickScoreInf.val;
  const background = getThemeColor(theme, "transparent-inverse-surface-low");
  const [refContainer, { width }] = useElementSize();
  const [textSize, setTextSize] = useState(0);
  useEffect(() => {
    setTextSize(width / 4);
  }, [width]);
  return (
    <div ref={refContainer} className={"relative w-full translate-y-[18%]"} title={label === "-" ? "No pick score yet" : ""}>
      <CircularProgressbarWithChildren
        value={val}
        circleRatio={0.5}
        strokeWidth={10}
        styles={buildStyles({
          rotation: 3 / 4,
          strokeLinecap: "round",
          pathColor: color,
          trailColor: background,
        })}
      >
        <CircularProgressbar
          value={val}
          circleRatio={0.5}
          strokeWidth={10}
          styles={buildStyles({
            rotation: 3 / 4,
            strokeLinecap: "round",
            pathColor: color,
          })}
        />
        <p className={"font-bold absolute"} style={{ color: color, fontSize: textSize, marginTop: -textSize }}>
          {label !== "-" ? label : <HourGlass style={{ fill: color, width: textSize, height: textSize }} />}
        </p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

const PickScoreReveal = (props) => {
  const { onReveal, info, revealing } = props;
  const [open, setOpen] = useState(false);
  const revealsCurrent = info?.current || 0;
  const revealsMax = info?.max;
  const authorized = info?.authorized !== undefined ? Boolean(info.authorized) : false;
  const revealsLeft = authorized ? (revealsMax || 0) - revealsCurrent : 0;
  const localStorageOptionKey = "pickscore_hide_reveal_modale";
  const optionFromLS = localStorage.getItem(localStorageOptionKey);
  const hideModale = optionFromLS !== "" ? Boolean(optionFromLS) : false;
  return (
    <div className={`flex flex-col gap-1`}>
      <div className={`flex justify-center items-center`}>
        {authorized ? (
          <div
            className={`flex justify-center ${
              revealing ? "animate-pulse" : ""
            } items-center bg-brand-pastel p-1 rounded cursor-pointer hover:opacity-80`}
            onClick={hideModale ? onReveal : () => setOpen(true)}
          >
            <Eye color={"#f45206"} />
          </div>
        ) : (
          <div className={"flex justify-center items-center bg-surface-container-high p-1 rounded"}>
            <LockerIcon className={"w-4 h-4 fill-outline"} />
          </div>
        )}
      </div>
      {revealsLeft !== undefined && <div className={"text-xs text-on-surface-variant text-center font-semibold"}>{revealsLeft} left</div>}
      {open && (
        <RevealModale
          left={revealsLeft}
          max={revealsMax}
          open={open}
          close={() => setOpen(false)}
          onReveal={(dontShowMessageAgain) => {
            if (dontShowMessageAgain) {
              localStorage.setItem(localStorageOptionKey, "true");
            }
            setOpen(false);
            onReveal();
          }}
        />
      )}
    </div>
  );
};

const RevealModale = (props) => {
  const { left, max } = props;
  const [open, setOpen] = useState(false);
  const [dontShowMessageAgain, setDontShowMessageAgain] = useState(false);
  const [pickScoreExample, setPickScoreExample] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    ReactTooltip.rebuild();
  }, [props.open]);

  const onReveal = () => {
    props.onReveal && props.onReveal(dontShowMessageAgain);
  };

  const gaugeWidth = max !== undefined && max > 0 ? ((max - left) / max) * 100 : 0;

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-500 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={"inline-block transform transition-all sm:my-8 sm:align-middle"}>
                <div className={"flex flex-row gap-4 justify-center"}>
                  <div className={"max-w-3xl relative align-bottom bg-surface-container-high rounded-lg pt-5 pb-4 text-left shadow-xl"}>
                    <div className="absolute top-0 right-0 pt-4 pr-4">
                      <div
                        className={"flex flex-row cursor-pointer"}
                        onClick={() => {
                          props.close();
                          setOpen(false);
                        }}
                      >
                        <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
                      </div>
                    </div>
                    <div className={"font-sans flex flex-col gap-2 text-on-surface"}>
                      <div className="font-headers px-5 border-b border-transparent-inverse-surface-low border-opacity-10 pb-4 flex flex-col justify-center items-center gap-2">
                        <p className="text-xl font-headers font-semibold m-0 text-center">Reveal pick score</p>
                        {max !== undefined && max > 0 && (
                          <div className={"flex flex-col justify-center items-center gap-1 w-full"}>
                            <div className="w-full bg-surface-container rounded-full h-2 self-center w-32">
                              <div
                                className={"h-2 rounded-full"}
                                style={{
                                  backgroundColor: "#5D72F6",
                                  width: gaugeWidth + "%",
                                }}
                              />
                            </div>
                            <p className={"font-sans text-xxs font-semibold"}>
                              {left}/{max} left for this GW and division
                            </p>
                          </div>
                        )}
                      </div>
                      <div className={"flex flex-col gap-3 px-4 py-1 transition-height transition-slowest ease"}>
                        <div className={"flex flex-col justify-center items-center w-full pt-1 font-sans gap-3"}>
                          <PickScoreLegendBox onExample={() => setPickScoreExample(!pickScoreExample)} />
                          <div className={"flex flex-row"}>
                            <div className={"w-4 -rotate-12 pt-1"}>
                              <PickScore label={"F"} />
                            </div>
                            <div className={"w-10"}>
                              <PickScore label={"AAA"} />
                            </div>
                            <div className={"w-6 rotate-12"}>
                              <PickScore label={"B"} />
                            </div>
                          </div>
                          <div className={"font-semibold text-xs text-center"} style={{ maxWidth: "30em" }}>
                            <p>
                              Become a Star member to unlock pick score for Cap and league-specific divisions or Star member to unlock it
                              for all divisions.
                            </p>
                            <a href={"https://youtu.be/kYLPKrHArtE"} target={"blank_"}>
                              <div className={"flex flew-row gap-1 justify-center items-center hover:opacity-80"}>
                                <p className={"text-primary "}>Learn more about the subscription tiers</p>
                                <div className={"w-4 h-4 self-center inline-flex"}>
                                  <img className={"w-full h-full object-contain"} src={PlayCircle} />
                                </div>
                              </div>
                            </a>
                            <p className={"text-primary underline mt-1"}>
                              <a href={""}>Upgrade</a>
                            </p>
                          </div>
                          <div>
                            <div
                              className="flex items-center mb-4 cursor-pointer"
                              onClick={() => setDontShowMessageAgain(!dontShowMessageAgain)}
                            >
                              <input
                                type="checkbox"
                                checked={dontShowMessageAgain}
                                className="w-4 h-4 primary bg-surface-container border-transparent-inverse-surface-low border-opacity-10 rounded ring-0 focus:ring-0"
                              />
                              <label htmlFor="default-checkbox" className="ml-2 text-xs text-on-surface-variant">
                                Don’t show me this message again
                              </label>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row justify-center gap-3 w-full sm:w-[40em]">
                            <Button
                              adapting
                              context="neutral"
                              label={"Cancel"}
                              onClick={() => {
                                props.close();
                                setOpen(false);
                              }}
                            />
                            <Button adapting label={"Reveal"} onClick={onReveal} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"max-w-sm flex flex-col gap-4 justify-center"}>
                    {pickScoreExample && <PickScoreLegendExample onExample={() => setPickScoreExample(!pickScoreExample)} />}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

const pickScoreDetailedFields = ["odds", "lineupProjection", "form", "scoringHistory", "matchup"];
const pickScoreDetailedFieldsCapped = ["odds", "cost", "lineupProjection", "form", "scoringHistory", "matchup"];
const pickScoreDetailedLabels = {
  matchup: "Matchup",
  odds: "Odds",
  scoringHistory: "Scoring history",
  lineupProjection: "Proj. lineup",
  form: "Form",
  cost: "Cost efficiency",
};

export const PickScoreDetails = (props) => {
  const { main, detailed, onReveal, revealing, revealInfo, isCapped } = props;
  if (main === undefined || main === null) {
    return (
      <div className={"flex justify-center items-center w-full"}>
        <div className={"flex justify-center items-center w-12"}>
          <PickScoreReveal onReveal={onReveal} info={revealInfo} revealing={revealing} />
        </div>
      </div>
    );
  }

  if (main === "NG" || detailed === undefined || detailed === null || Object.entries(detailed).every(([_, val]) => val === undefined)) {
    // No details at all
    return (
      <div className={"flex justify-center items-center w-full"}>
        <div className={"flex justify-center items-center w-12"}>
          <PickScore label={main} />
        </div>
      </div>
    );
  }

  return (
    <PopHoverWrapper
      hoverPosition={"left"}
      hoverTitle="Pick score details"
      hover={
        <>
          <div className={"flex flex-col gap-2"} style={{ height: "12em", width: "100%" }}>
            <PickScoreDetailsRadar height={"12em"} width={"100%"} main={main} isCapped={isCapped} detailed={detailed} />
          </div>
        </>
      }
    >
      <div className={"flex justify-center items-center w-full"}>
        <div className={"flex justify-center items-center w-12"}>
          <PickScore label={main} />
        </div>
      </div>
    </PopHoverWrapper>
  );
};

export const PickScoreDetailsRadar = (props) => {
  const { main, isCapped, detailed } = props;
  const { theme } = useTheme();
  const labelColorNormal = getThemeColor(theme, "on-surface-variant") || "4F4F4F";
  const labelColorDisabled = getThemeColor(theme, "outline");
  const surfaceContainerHigh = getThemeColor(theme, "transparent-inverse-surface-low");
  const fields = isCapped ? pickScoreDetailedFieldsCapped : pickScoreDetailedFields;
  const labels = fields.map((field) => pickScoreDetailedLabels[field]);
  const values = fields.map((field) => detailed?.[field]);
  const pickScoreGroupColor = (pickScoreInfo[main] || pickScoreInfo["-"])?.color || "black";
  const viewBoxSize = 120;
  const circleSize = 90;
  const labelCircleRadiusX = (viewBoxSize + 25) / 2; // label circle is an ellipse slightly larger on X axis, so label have more space to be displayed
  const labelCircleRadiusY = viewBoxSize / 2;
  const firstCircleRadius = circleSize / 2;
  const secondCircleRadius = circleSize / 3;
  const thirdCircleRadius = circleSize / 6;
  const center = viewBoxSize / 2;
  const deg = 360 / fields.length;
  const dotsProps = values.map((val, i) => {
    const valIsEmpty = val === undefined || val === null;
    const color = valIsEmpty ? "#D9D9D9" : val < 0 ? "#F07E64" : val > 0 ? "#50BF84" : "#DAE7EB";
    const angleX = Math.cos((i * deg * Math.PI) / 180);
    const angleY = Math.sin((i * deg * Math.PI) / 180);
    const r = valIsEmpty ? 0 : val < 0 ? thirdCircleRadius : val === 0 ? secondCircleRadius : firstCircleRadius;
    const cx = center + r * angleX;
    const cy = center + r * angleY;
    let cxLabel = center + labelCircleRadiusX * angleX;
    let cyLabel = center + labelCircleRadiusY * angleY;
    const cxLargeCircle = center + firstCircleRadius * angleX;
    const cyLargeCircle = center + firstCircleRadius * angleY;
    const labelWords = labels[i]?.split(" ") || []; // Have to split the words to use text spans because SVG 1.1 does not support text wrapping
    let labelColor = labelColorNormal;
    if (valIsEmpty) {
      labelWords.push("(missing)");
      labelColor = labelColorDisabled;
    }
    return {
      val,
      cx,
      cy,
      cxLargeCircle,
      cyLargeCircle,
      cxLabel,
      cyLabel,
      color,
      labelColor,
      labelWords,
    };
  });
  const polygonPoints = dotsProps.map(({ cx, cy }) => `${cx},${cy}`).join(" ");
  const polygon = <polygon points={polygonPoints} fill={pickScoreGroupColor} fillOpacity="0.6" stroke="#2D2D2D" strokeWidth="1" />;
  const labelsElements = dotsProps.map(({ cxLabel, cyLabel, labelWords, labelColor }) => {
    const hasTwoWords = labelWords.length > 1;
    return (
      <text
        key={`${cxLabel}-${cyLabel}`}
        textAnchor={"middle"}
        dominantBaseline={hasTwoWords ? "" : "middle"}
        fontFamily={"Inter"}
        fontWeight={600}
        fontSize={"8"}
        x={cxLabel}
        y={cyLabel}
        fill={labelColor}
      >
        {hasTwoWords
          ? labelWords.map((word, i) => (
              <tspan key={i} alignmentBaseline="middle" x={cxLabel} dy={i === 0 ? 0 : "1.2em"}>
                {word}
              </tspan>
            ))
          : labelWords[0]}
      </text>
    );
  });
  const lines = dotsProps.map(({ cxLargeCircle, cyLargeCircle }) => (
    <line
      key={`${cxLargeCircle}-${cyLargeCircle}`}
      width={"20px"}
      x1={center}
      y1={center}
      x2={cxLargeCircle}
      y2={cyLargeCircle}
      stroke="#6F6F6F"
      strokeWidth="1"
      strokeOpacity="0.2"
    />
  ));
  return (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      width={viewBoxSize + 30}
      height={viewBoxSize}
      viewBox={`0 -15 ${viewBoxSize} ${viewBoxSize + 30}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={center} cy={center} r={firstCircleRadius} fill="#50BF84" fillOpacity="0.2" />
      <circle cx={center} cy={center} r={secondCircleRadius} fill={surfaceContainerHigh} />
      <circle cx={center} cy={center} r={secondCircleRadius} fill="#DAE7EB" fillOpacity="0.3" />
      <circle cx={center} cy={center} r={thirdCircleRadius} fill={surfaceContainerHigh} />
      <circle cx={center} cy={center} r={thirdCircleRadius} fill="#F07E64" fillOpacity="0.4" />
      {lines}
      {polygon}
      {dotsProps
        .filter((dotProps) => dotProps.val !== undefined && dotProps.val !== null)
        .map((dotProps) => {
          const { cx, cy, color } = dotProps;
          return (
            <circle
              key={`${cx}-${cy}`}
              cx={cx}
              cy={cy}
              r={3}
              fill={color}
              fillOpacity="1"
              strokeOpacity="1"
              stroke="#2D2D2D"
              strokeWidth="1"
            />
          );
        })}
      {labelsElements}
    </svg>
  );
};
