import { withUser } from "../../userContext";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";
import { ReactTooltip } from "../util/tooltip.js";
import { Dialog, Transition } from "@headlessui/react";
import PlayerTile from "../../img/lub-legend-player-tile.png";
import PlayerTileDark from "../../img/lub-legend-player-tile-dark.png";
import { ReactComponent as IconTrustLow } from "../../img/icon-trust-low-no-color.svg";
import { ReactComponent as IconTrustMid } from "../../img/icon-trust-medium-no-color.svg";
import { ReactComponent as IconTrustHigh } from "../../img/icon-trust-high-no-color.svg";
import IconHome from "../../img/homeIcon.svg";
import IconHomeBrand from "../../img/homeIconBrand.svg";
import { CommunityPredictionIndicator, CommunityPredictionSummary } from "../util/communityPrediction";
import SorareScoreAverage from "./sorareScoreAverage";
import { PickScore, PickScoreDetailsRadar } from "../util/pickScore";
import ScoreAverage from "./scoreAverage";
import GameInLine from "./gameInLine";
import OppScore from "./oppScore";
import CirclePct from "../util/circlePct";
import MatchupScore from "../util/matchupScore";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import Started from "../../img/starter.svg";
import CameIn from "../../img/cameIn.svg";
import Bench from "../../img/benchNotCameIn.svg";
import NotInSquad from "../../img/notInSquad.svg";
import { DecisiveActionsScore } from "../util/decisiveScore";
import { AllAroundScore } from "../util/allAroundScore";
import { MedianScore } from "../util/medianScore";
import { CeilingScore, FloorScore } from "../util/boundScore";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import ExamplePickScoreAA from "../../img/example-pick-score-aa.png";
import ExamplePickScoreAAA from "../../img/example-pick-score-aaa.png";
import ExampleCost from "../../img/example-cost.png";
import { Button } from "../util/button";
import { PlayedStats } from "../util/playedStats";
import PlayCircle from "../../img/play_circle.svg";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import { sdDark } from "../../themeColors";
import { getThemeColor, useTheme } from "../../themeContext";

function ViewLUBTableLegend(props) {
  const { tab, onClose } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (tab) {
      setOpen(true);
    }
  }, [tab]);

  const close = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, []);

  return (
    <div>
      {open && <Modale tab={tab} close={close} open={open} />}
      <div onClick={() => setOpen(true)} className={"h-10 flex items-center py-1.5 px-1 cursor-pointer hover:opacity-80"}>
        <div className={"font-semibold text-sm self-center text-primary flex flex-row gap-1 px-2"}>
          <span>View table legend</span>
          <IconInfoHelp className={"w-5 h-5 my-auto fill-primary"} />
        </div>
      </div>
    </div>
  );
}

const Modale = (props) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(props.tab || "player_tile");
  const [examples, setExamples] = useState({
    classic: {
      cost: false,
      pickScore: false,
      opponentScore: false,
    },
    advanced: {
      divPct: false,
      opponentScore: false,
      matchupIndicator: false,
    },
    form: {
      medianFloorCeiling: false,
    },
  });

  useEffect(() => {
    setOpen(props.open);
    ReactTooltip.rebuild();
  }, [props.open]);

  const onExample = (type, example) => {
    setExamples({
      ...examples,
      [type]: {
        ...examples[type],
        [example]: !examples[type][example],
      },
    });
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-100 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={"inline-block transform transition-all sm:my-8 sm:align-middle"}>
                <div className={"flex flex-row gap-4 justify-center"}>
                  <div className={"max-w-3xl relative align-bottom bg-surface-container-high rounded-lg pt-5 pb-4 text-left shadow-xl"}>
                    <div className="absolute top-0 right-0 pt-4 pr-4">
                      <div
                        className={"flex flex-row cursor-pointer"}
                        onClick={() => {
                          props.close();
                          setOpen(false);
                        }}
                      >
                        <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
                      </div>
                    </div>
                    <div className={"font-sans flex flex-col gap-2 text-on-surface"}>
                      <div className="font-headers px-5">
                        <p className="text-xl font-semibold m-0">Table legend</p>
                      </div>
                      <div className={"border-b border-transparent-inverse-surface-low border-opacity-10 w-full"}>
                        <div className={"flex flex-row gap-8 px-5 text-xs text-textGrey3 font-medium"}>
                          <span
                            className={`py-1 hover:text-primary cursor-pointer border-b-2 hover:border-primary ${
                              view === "player_tile" ? "font-semibold border-primary text-on-surface" : "border-surface-container-high"
                            }`}
                            onClick={() => setView("player_tile")}
                          >
                            Player tile
                          </span>
                          <span
                            className={`py-1 hover:text-on-surface cursor-pointer border-b-2 hover:border-primary ${
                              view === "classic" ? "font-semibold border-primary text-on-surface" : "border-surface-container-high"
                            }`}
                            onClick={() => setView("classic")}
                          >
                            Classic view
                          </span>
                          <span
                            className={`py-1 hover:text-on-surface cursor-pointer border-b-2 hover:border-primary ${
                              view === "advanced" ? "font-semibold border-primary text-on-surface" : "border-surface-container-high"
                            }`}
                            onClick={() => setView("advanced")}
                          >
                            Advanced view
                          </span>
                          <span
                            className={`py-1 hover:text-on-surface cursor-pointer border-b-2 hover:border-primary ${
                              view === "form" ? "font-semibold border-primary text-on-surface" : "border-surface-container-high"
                            }`}
                            onClick={() => setView("form")}
                          >
                            Form view
                          </span>
                        </div>
                      </div>
                      <div className={"px-4 py-1 transition-height transition-slowest ease"}>
                        {view === "player_tile" && <PlayerTileLegend />}
                        {view === "classic" && <ClassicLegend onExample={(ex) => onExample("classic", ex)} />}
                        {view === "advanced" && <AdvancedLegend onExample={(ex) => onExample("advanced", ex)} />}
                        {view === "form" && <FormLegend onExample={(ex) => onExample("form", ex)} />}
                      </div>
                    </div>
                  </div>
                  <div className={"max-w-sm flex flex-col gap-4 justify-center"}>
                    {examples[view] &&
                      Object.entries(examples[view]).map(([key, enabled]) => {
                        if (enabled) {
                          if (key === "cost") {
                            return <CostLegendExample onExample={() => onExample(view, key)} />;
                          }
                          if (key === "pickScore") {
                            return <PickScoreLegendExample onExample={() => onExample(view, key)} />;
                          }
                          if (key === "opponentScore") {
                            return <OpponentScoreLegendExample onExample={() => onExample(view, key)} />;
                          }
                          if (key === "matchupIndicator") {
                            return <MatchupIndicatorLegendExample onExample={() => onExample(view, key)} />;
                          }
                          if (key === "medianFloorCeiling") {
                            return <MedianFloorCeilingLegendExample onExample={() => onExample(view, key)} />;
                          }
                        }
                        return null;
                      })}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

const PlayerTileLegend = () => {
  const { theme } = useTheme();
  return (
    <div className={"flex flex-col gap-3"}>
      <Box>
        <div className={"flex justify-center items-center py-1"}>
          <img className={"w-[80%]"} src={theme === sdDark ? PlayerTileDark : PlayerTile} alt={"player tile legend"} />
        </div>
      </Box>
      <Box header={"Projected Lineup"} title={"Community projection for the player to start the game"}>
        <CommunityProjectionLegend summary />
      </Box>
    </div>
  );
};

const classicLegendColumns = ["Cost", "Pick score", "H/A", "L5", "L15", "Win %", "Odds", "Opp. Scores"];

const ClassicLegend = (props) => {
  const { onExample } = props;
  return (
    <div className={"flex flex-col gap-3"}>
      <div className={"px-0 pb-3 rounded-b-lg overflow-hidden"}>
        <TableExample columns={classicLegendColumns}>
          {classicLegendColumns.map((label) => (
            <td key={label}>
              <div className={"flex flex-row justify-center space-x-2 py-2"}>
                <div className={"flex justify-center items-center"}>
                  {label === "Cost" && (
                    <div className={"w-10"}>
                      <SorareScoreAverage size="sm" avg={70} />
                    </div>
                  )}
                  {label === "Pick score" && (
                    <div className={"w-10"}>
                      <div className={"w-10"}>
                        <PickScore label="AA" />
                      </div>
                    </div>
                  )}
                  {label === "H/A" && (
                    <div className={"flex justify-center items-center"}>
                      <img src={IconHome} />
                    </div>
                  )}
                  {label === "L5" && (
                    <div className={"flex justify-center items-center"}>
                      <ScoreAverage avg={78} period={5} played={4} sAvg={78} sApps={4} />
                    </div>
                  )}
                  {label === "L15" && (
                    <div className={"flex justify-center items-center"}>
                      <ScoreAverage avg={78} period={5} played={4} sAvg={78} sApps={4} />
                    </div>
                  )}
                  {label === "Win %" && (
                    <div className={"flex justify-center items-center"}>
                      <GameExample />
                    </div>
                  )}
                  {label === "Odds" && (
                    <div className={"flex justify-center items-center"}>
                      <div className={"flex flex-col gap-1 justify-center self-start"}>
                        <CirclePct value={3.6} />
                        <div className={"flex flex-row gap-1 font-medium text-sm text-on-surface-variant"}>
                          <span>3GS</span>
                          <IconInfoHelp className={"fill-outline-variant my-auto"} />
                        </div>
                      </div>
                    </div>
                  )}
                  {label === "Opp. Scores" && (
                    <div className={"flex justify-center items-center"}>
                      <div className={"flex justify-center items-center gap-2 h-3/4"} title={"Opponent score"}>
                        <OppScore score={53} />
                        <IconInfoHelp className={"fill-outline-variant my-auto"} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </td>
          ))}
        </TableExample>
      </div>
      <CostLegendBox onExample={onExample} />
      <PickScoreLegendBox onExample={onExample} />
      <Box header={"H/A"} title={"Home/away split"}>
        <HomeAwayLegend />
      </Box>
      <LXLegendBox />
      <Box header={"Win %"} title={"Probability of win for the player team"}>
        <GameLegend />
      </Box>
      <Box header={"Odds"} title={"Odds for the game"}>
        <MatchupOddsLegend />
      </Box>
      <Box header={"Opp. Scores"} title={"Opponent Scores"} example={() => onExample("opponentScore")}>
        <OppScoreLegend />
      </Box>
    </div>
  );
};

export const CostLegendBox = ({ onExample }) => {
  return (
    <Box header={"Cost"} title={"Fantasy points cost"} example={onExample ? () => onExample("cost") : undefined}>
      <CostLegend />
    </Box>
  );
};

export const LXLegendBox = (props) => {
  const lx = props.lx || [5, 15];
  return (
    <Box header={lx.map((x) => `L${x}`).join(", ")} title={lx.map((x) => `Last ${x}`).join(", ")}>
      <LXLegend lx={lx} />
    </Box>
  );
};

const advancedLegendColumns = ["Cost", "H/A", "Comm proj.", "% Pick", "% Div", "Opp. Scores", "Matchup ind."];

const AdvancedLegend = (props) => {
  const { onExample } = props;
  return (
    <div className={"flex flex-col gap-3"}>
      <div className={"px-0 pb-3 rounded-b-lg overflow-hidden"}>
        <TableExample columns={advancedLegendColumns}>
          {advancedLegendColumns.map((label) => (
            <td key={label}>
              <div className={"flex flex-row justify-center space-x-2 py-2"}>
                <div className={"flex justify-center items-center"}>
                  {label === "Cost" && (
                    <div className={"w-10"}>
                      <SorareScoreAverage size="sm" avg={70} />
                    </div>
                  )}
                  {label === "H/A" && (
                    <div className={"flex justify-center items-center"}>
                      <img src={IconHome} />
                    </div>
                  )}
                  {label === "Comm proj." && <CommunityPredictionIndicator pred={prediction} />}
                  {label === "% Pick" && (
                    <div className={"flex justify-center items-center"}>
                      <PctPickExample />
                    </div>
                  )}
                  {label === "% Div" && (
                    <div className={"flex justify-center items-center"}>
                      <PctDivExample />
                    </div>
                  )}
                  {label === "Opp. Scores" && (
                    <div className={"flex justify-center items-center"}>
                      <div className={"flex justify-center items-center gap-2 h-3/4"} title={"Opponent score"}>
                        <OppScore score={53} />
                        <IconInfoHelp className={"fill-outline-variant my-auto"} />
                      </div>
                    </div>
                  )}
                  {label === "Matchup ind." && (
                    <div className={"flex justify-center items-center"}>
                      <MatchupScore score={19} />
                    </div>
                  )}
                </div>
              </div>
            </td>
          ))}
        </TableExample>
      </div>
      <Box header={"Cost"} title={"Fantasy points cost"}>
        <CostLegend />
      </Box>
      <Box header={"H/A"} title={"Home/away split"}>
        <HomeAwayLegend />
      </Box>
      <Box header={"Projected Lineup"} title={"Community projection for the player to start the game"}>
        <CommunityProjectionLegend />
      </Box>
      <Box header={"% Pick"} title={"Pick percentage"}>
        <PctPickLegend />
      </Box>
      <Box header={"% Div"} title={"Division percentage"}>
        <PctDivPickLegend />
      </Box>
      <Box header={"Opp. Scores"} title={"Opponent Scores"} example={() => onExample("opponentScore")}>
        <OppScoreLegend />
      </Box>
      <Box header={"Matchup Ind."} title={"Matchup indicator"} example={() => onExample("matchupIndicator")}>
        <MatchupIndicatorLegend />
      </Box>
    </div>
  );
};

const formLegendColumnHoverKeys = {
  // Key is column, value is legend
  Median: "Median, Floor, Ceiling",
  Floor: "Median, Floor, Ceiling",
  Ceiling: "Median, Floor, Ceiling",
};

const formLegendHoverKeys = {
  // Key is column, value is legend
  "Median, Floor, Ceiling": ["Median", "Floor", "Ceiling"],
  "L5, L15, L40": ["L5"],
};

const formLegendColumns = ["Cost", "H/A", "Played", "L5", "DS avg", "AA avg", "Median", "Floor", "Ceiling"];

const FormLegend = (props) => {
  const { onExample } = props;
  const [hoverColumn, setHoverColumn] = useState("");
  const onHoverColumn = useCallback((label) => {
    setTimeout(() => {
      setHoverColumn(formLegendColumnHoverKeys[label] || label);
    }, 500);
  }, []);
  const [hoverLegend, setHoverLegend] = useState([]);
  const onHoverLegend = useCallback((label) => {
    setTimeout(() => {
      setHoverLegend(formLegendHoverKeys[label] || [label]);
    }, 500);
  }, []);
  const handleMouseEventsLegend = (label) => {
    return {};
    /*return {
               onMouseEnter: () => onHoverLegend(label),
               onMouseLeave: () => setHoverLegend([]),
           }*/
  };
  return (
    <div className={"flex flex-col gap-3"}>
      <div className={"px-0 pb-3 rounded-b-lg overflow-hidden"}>
        <TableExample columns={formLegendColumns}>
          {formLegendColumns.map((label) => (
            <td key={label} onMouseEnter={undefined /*() => onHoverColumn(label)*/} onMouseLeave={undefined /*() => setHoverColumn("")*/}>
              <div className={"flex flex-row justify-center space-x-2 py-2"}>
                <div className={"flex justify-center items-center"}>
                  {label === "Cost" && (
                    <div className={"w-10"}>
                      <SorareScoreAverage size="sm" avg={70} />
                    </div>
                  )}
                  {label === "H/A" && (
                    <div className={"flex justify-center items-center"}>
                      <img src={IconHome} />
                    </div>
                  )}
                  {label === "Played" && (
                    <div className={"flex justify-center items-center"}>
                      <PlayedStats
                        stats={{
                          gamesStarted: 4,
                          gamesPlayed: 5,
                          actualGames: 5,
                          gamesOnBench: 0,
                          gamesOut: 0,
                        }}
                      />
                    </div>
                  )}
                  {label === "L5" && (
                    <div className={"flex justify-center items-center"}>
                      <ScoreAverage avg={78} period={5} played={4} sAvg={78} sApps={4} />
                    </div>
                  )}
                  {label === "DS avg" && (
                    <div className={"flex justify-center items-center"}>
                      <DecisiveActionsScore score={45} />
                    </div>
                  )}
                  {label === "AA avg" && (
                    <div className={"flex justify-center items-center"}>
                      <AllAroundScore score={14.5} />
                    </div>
                  )}
                  {label === "Median" && (
                    <div className={"flex justify-center items-center"}>
                      <MedianScore score={76} />
                    </div>
                  )}
                  {label === "Floor" && (
                    <div className={"flex justify-center items-center"}>
                      <FloorScore score={14} />
                    </div>
                  )}
                  {label === "Ceiling" && (
                    <div className={"flex justify-center items-center"}>
                      <CeilingScore score={100} />
                    </div>
                  )}
                </div>
              </div>
            </td>
          ))}
        </TableExample>
      </div>
      <Box header={"Cost"} title={"Fantasy points cost"} {...handleMouseEventsLegend("Cost")}>
        <CostLegend />
      </Box>
      <Box header={"H/A"} title={"Home/away split"} {...handleMouseEventsLegend("H/A")}>
        <HomeAwayLegend />
      </Box>
      <Box header={"Played"} title={"Played game history"} {...handleMouseEventsLegend("Played")}>
        <PlayedLegend />
      </Box>
      <Box header={"L5, L15, L40"} title={"Last 5, Last 15, Last 40"} {...handleMouseEventsLegend("L5, L15, L40")}>
        <LXLegend lx={[5, 15, 40]} />
      </Box>
      <Box header={"DS avg"} title={"Decisive score average"} {...handleMouseEventsLegend("DS avg")}>
        <DecisiveScoreLegend />
      </Box>
      <Box header={"AA avg"} title={"All-around score average"} {...handleMouseEventsLegend("AA avg")}>
        <AllAroundScoreLegend />
      </Box>
      <Box
        header={"Median, Floor, Ceiling"}
        title={"Median, Floor and Ceiling scores of a games subset (L5, L15, L40)"}
        {...handleMouseEventsLegend("Median, Floor, Ceiling")}
        example={() => onExample("medianFloorCeiling")}
      >
        <MedianFloorCeilingLegend />
      </Box>
    </div>
  );
};

const TableExample = (props) => {
  const { columns, children } = props;
  return (
    <table className={"w-full border-collapse"} style={{ height: "1px" }} cellPadding="0" cellSpacing="0">
      <thead className={"text-on-surface-variant text-xs font-semibold sticky top-0 z-[19] overflow-hidden"}>
        <tr className={"overflow-hidden rounded-t-lg"}>
          {columns.map((label, i) => {
            const first = i === 0;
            const last = i === columns.length - 1;
            return (
              <th key={label} className={`text-center font-semibold border-none`}>
                <div
                  className={`border-none px-1.5 py-3 bg-surface-container-highest ${first ? "rounded-tl-lg" : ""} ${
                    last ? "rounded-tr-lg" : ""
                  }`}
                >
                  {label}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={"bg-surface-container"}>
        <tr>{children}</tr>
      </tbody>
    </table>
  );
};

const Box = (props) => {
  const { header, title, example, highlight } = props;
  return (
    <div
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={`px-0 py-3 rounded-lg grid grid-flow-row-dense grid-cols-10 divide-x divide-transparent-inverse-surface-low divide-opacity-10 border ${
        highlight
          ? "bg-containers-surface-focus-active-primarytint border-primary"
          : "bg-surface-container border-transparent-inverse-surface-low border-opacity-10"
      }`}
    >
      {header && <div className={"col-span-1 px-3 font-bold text-sm flex items-center justify-center text-center"}>{header}</div>}
      <div className={`${header ? "col-span-9" : "col-span-10"} w-full flex flex-col px-3 text-xs`}>
        {title && (
          <div className={"flex flex-row justify-between"}>
            <h4 className={"mt-0 text-xs font-semibold"}>{title}</h4>
            {example && (
              <span onClick={example} className={"flex flew-row gap-2 text-primary text-xs font-semibold cursor-pointer hover:opacity-80"}>
                <span>View example</span>
                <SortArrowIcon className={"fill-primary self-start transform -rotate-90 mt-1 mb-auto"} />
              </span>
            )}
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};

const BoxLegend = ({ title, children, onExample }) => {
  return (
    <div
      className={
        "inline-block align-bottom bg-surface-container rounded-lg p-4 text-left shadow-xl flex flex-col gap-2 border-transparent-inverse-surface-low border-opacity-10 text-on-surface"
      }
    >
      <h3 className={"font-headers text-xl font-semibold text-center m-0"}>Example</h3>
      {title && <h4 className={"font-semibold text-md m-0"}>{title}</h4>}
      {children}
      {onExample && (
        <div className={"flex justify-end py-2"}>
          <span className={"font-semibold text-primary text-xs hover:opacity-80 cursor-pointer"} onClick={onExample}>
            Ok, got it
          </span>
        </div>
      )}
    </div>
  );
};

const prediction = {
  guesses: 10,
  pct: 80,
  doubt: 1,
  miss: 1,
  out: 0,
  reliabilityLevel: "high",
};

const CommunityProjectionLegend = ({ summary }) => {
  return (
    <div className={"flex flex-col gap-1"}>
      {summary ? (
        <div className={"grid grid-flow-row-dense grid-cols-7"}>
          <div className={"col-span-1 flex justify-center items-center"}>
            <div className={"w-[80%]"}>
              <CommunityPredictionSummary pred={prediction} position={"horizontal"} />
            </div>
          </div>
          <div className={"col-span-6 flex flex-col "}>
            <p>a. Most voted projected status at the start of the game.</p>
            <p>b. Community projection probability for the player to have the projected status.</p>
            <p>c. Projection reliability (A).</p>
          </div>
        </div>
      ) : (
        <div className={"grid grid-flow-row-dense grid-cols-7"}>
          <div className={"col-span-2 flex justify-center items-center"}>
            <CommunityPredictionIndicator pred={prediction} />
          </div>
          <div className={"col-span-5 flex flex-col"}>
            <p>
              a. Projected statuses at the start of the game with their probabilities, from left to right: Starter, Doubtful, Bench, Out of
              squad
            </p>
            <p>b. Projection reliability (A).</p>
          </div>
        </div>
      )}
      <div className={`grid grid-flow-row-dense grid-cols-7`}>
        <div className={`${summary ? "col-span-1" : "col-span-2"} flex justify-center items-center`}>
          <div className={"flex flew-row bg-surface-container-highest rounded-md gap-1 px-1 py-0.5 w-fit"}>
            <IconTrustLow className="fill-on-surface-variant" />
            <IconTrustMid className="fill-on-surface-variant" />
            <IconTrustHigh className="fill-on-surface-variant" />
          </div>
        </div>
        <div className={`${summary ? "col-span-6" : "col-span-5"} flex items-center`}>
          <p>A. Reliability scale from left to right: Not reliable, Reliable, Highly reliable.</p>
        </div>
      </div>
    </div>
  );
};

const HomeAwayLegend = () => {
  return (
    <div className={"flex flex-col gap-2"}>
      <div className={"grid grid-flow-row-dense grid-cols-7"}>
        <div className={"col-span-1 flex justify-center items-center"}>
          <img src={IconHome} />
        </div>
        <div className={"col-span-6 flex flex-col"}>
          <p>Indicator that this player is playing either a home (H) or away (A) game</p>
        </div>
      </div>
      <div className={"grid grid-flow-row-dense grid-cols-7"}>
        <div className={"col-span-1 flex justify-center items-center"}>
          <img src={IconHomeBrand} />
        </div>
        <div className={"col-span-6 flex items-center"}>
          <p>When preference is set to Home/Away adjusted, this indicator is orange</p>
        </div>
      </div>
    </div>
  );
};

const CostLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <div className={"w-10"}>
          <SorareScoreAverage size="sm" avg={70} />
        </div>
      </div>
      <div className={"col-span-6 flex flex-col"}>
        <p>
          Fantasy-points cost attributed by Sorare to this player for this competition; typically the L15 score (the average fantasy points
          per match scored during the last 15 gameweeks).
        </p>
      </div>
    </div>
  );
};

const OppScoreLegend = () => {
  return (
    <div className={"flex flex-col gap-2"}>
      <div className={"grid grid-flow-row-dense grid-cols-5"}>
        <div className={"col-span-1 flex justify-center items-center"}>
          <div className={"flex justify-center items-center gap-2 h-3/4"} title={"Opponent score"}>
            <OppScore score={53} />
            <IconInfoHelp className={"fill-outline-variant my-auto"} />
          </div>
        </div>
        <div className={"col-span-4 flex flex-col"}>
          <p>
            a. The opponent score is the average of points allowed by a team&apos;s opponent over the last 15 games in the upcoming game
            setup (competition/home or away), weighted on position occupied by this player
          </p>
        </div>
      </div>
      <div className={"grid grid-flow-row-dense grid-cols-5"}>
        <div className={"col-span-1 flex justify-evenly items-center"}>
          <div className={"flex flex-col gap-1 justify-center self-center"}>
            <OppScore score={55} />
            <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>RAM</p>
          </div>
          <div className={"flex flex-col gap-1 justify-center self-center"}>
            <OppScore score={51} />
            <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>CAM</p>
          </div>
        </div>
        <div className={"col-span-4 flex items-center"}>
          <p>
            b. The opponent score is the average of points allowed by a team&apos;s opponent over the last 15 games in the upcoming game
            setup (competition/home or away) split by the position on the field usually occupied by this player
          </p>
        </div>
      </div>
    </div>
  );
};

const MatchupOddsLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <div className={"flex flex-col gap-1 justify-center self-start"}>
          <CirclePct value={3.6} />
          <div className={"flex flex-row gap-1 font-medium text-sm text-on-surface-variant"}>
            <span>3GS</span>
            <IconInfoHelp className={"fill-outline-variant my-auto"} />
          </div>
        </div>
      </div>
      <div className={"col-span-6 flex flex-col gap-6 py-2"}>
        <p>a. Probability of realisation of the event </p>
        <p>
          b. Events evaluated :
          <ul className={"list-disc pl-3"}>
            <li>CS : keeping the clean sheet (no goal conceded)</li>
            <li>3GS : scoring 3 goals</li>
            <li>3GA : conceding 3 goals</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

const GameExample = () => {
  const data = {
    playerTeam: "ajax-amsterdam",
    playerNationalTeam: "france",
    team1: {
      TeamId: "ajax-amsterdam",
      LeagueSlug: "eredivisie",
      ClubName: "Ajax Amsterdam",
      PictureUrl: "https://assets.sorare.com/club/9dbcd0b2-b569-4679-acbd-5c5ad4147f0a/picture/dc7566e5971658be538df76c75db2e63.png",
    },
    team2: {
      TeamId: "olympique-lyonnais-lyon",
      LeagueSlug: "ligue-1",
      ClubName: "Olympique Lyonnais",
      PictureUrl: "https://assets.sorare.com/club/42b054fb-1ba3-4547-857b-97b3f229abc7/picture/dbd68ac96fe0834a3086dee3a2cbda9c.png",
    },
    game: {
      GameId: "no-game",
      HomeTeamId: "ajax-amsterdam",
      AwayTeamId: "olympique-lyonnais-lyon",
      GameTime: "2023-04-16T20:45:00Z",
    },
    odds: {
      home: 4.3,
      draw: 3.7,
      away: 1.8,
    },
    standings: {
      "ajax-amsterdameredivisie": {
        rank: 1,
      },
      "olympique-lyonnais-lyonligue-1": {
        rank: 10,
      },
    },
  };

  return <GameInLine {...data} />;
};

const GameLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-5"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <GameExample />
      </div>
      <div className={"col-span-4 flex flex-col justify-between py-1"}>
        <p className={"mt-1"}>a. Opposing teams and their rank in the competition of the match.</p>
        <p>b. Probability of win and draw for each team. Bold value is the probability of player team</p>
      </div>
    </div>
  );
};

const LXLegend = ({ lx }) => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <ScoreAverage avg={78} period={5} played={4} sAvg={78} sApps={4} />
      </div>
      <div className={"col-span-6 flex flex-col gap-2 py-2"}>
        <p>a. Fantasy points average over the last {lx.join(", ")} games.</p>
        <p>b. Percentage of games played over the last {lx.join(", ")} games. </p>
      </div>
    </div>
  );
};

export const PickScoreLegendBox = ({ onExample }) => {
  return (
    <Box header={"Pick Score"} title={"Pick score"} example={() => onExample("pickScore")}>
      <PickScoreLegend />
    </Box>
  );
};
const PickScoreLegend = () => {
  return (
    <div className={"space-y-2"}>
      <div className={"grid grid-flow-row-dense grid-cols-7"}>
        <div className={"col-span-2 flex flex-col justify-center items-center"}>
          <div className={"w-10"}>
            <PickScore label="AA" />
          </div>
        </div>
        <div className={"col-span-5 flex flex-col"}>
          <p>
            Computed value that highlights recommended picks for the competition, based on their position, scoring history, matchup, odds
            and projections, from AAA (best) to F (worst).
          </p>
          <p>This evaluation is re-computed periodically</p>
        </div>
      </div>
      <div className={"grid grid-flow-row-dense grid-cols-7"}>
        <div className={"col-span-2 flex flex-col justify-center items-center"}>
          <div className={"w-full"}>
            <PickScoreDetailsRadar
              main={"A"}
              detailed={{
                matchup: 1,
                odds: null,
                scoringHistory: 1,
                lineupProjection: 0,
                form: 1,
                cost: -1,
              }}
              isCapped={true}
            />
          </div>
        </div>
        <div className={"col-span-5 flex flex-col"}>
          <p>
            You can hover the pick score to understand better the rating and which components are positive (green dot), neutral (blue-grey
            dot) or detrimental (red dot).
          </p>
          <p>
            <span className={"underline"}>Matchup:</span> matchup against this team, for this expected position
          </p>
          <p>
            <span className={"underline"}>Odds:</span> odds adjusted to expected position (i.e : CS% for GK)
          </p>
          <p>
            <span className={"underline"}>Cost efficiency:</span> potential to over-achieve its cost
          </p>
          <p>
            <span className={"underline"}>Proj. lineup:</span> player is expect to start, bench or not play
          </p>
          <p>
            <span className={"underline"}>Form:</span> form in the last 5 games compared to the player’s standards
          </p>
          <p>
            <span className={"underline"}>Scoring history:</span> median scoring in this setup
          </p>
        </div>
      </div>
    </div>
  );
};

const OpponentScoreLegendExample = ({ onExample }) => {
  return (
    <BoxLegend title={"Opponent score"} onExample={onExample}>
      <OpponentScoreExample />
    </BoxLegend>
  );
};

const OpponentScoreExample = () => {
  return (
    <div className={"text-xs flex flex-col gap-2 font-sans"}>
      <div className={"flex justify-center items-center gap-2"}>
        <OppScore score={47} />
        <IconInfoHelp className={"fill-outline-variant my-auto"} />
      </div>
      <p>
        If you&apos;re looking at Neymar’s opp. scores against Reims in GW341, a 47 opponent score means that Reims has allowed on average
        47 fantasy points to starters who played 60 minutes or more on similar positions as Neymar’s in the past 8 games in the same
        competition/home or away setup.
      </p>
      <p className={"font-bold"}>Therefore, the higher the opponent score is, the better the SO5 matchup is.</p>
      <div className={"flex justify-center gap-1"}>
        <div className={"flex flex-col gap-1 justify-center self-center"}>
          <OppScore score={44} />
          <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>LF</p>
        </div>
        <div className={"flex flex-col gap-1 justify-center self-center"}>
          <OppScore score={47} />
          <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>LAM</p>
        </div>
        <div className={"flex flex-col gap-1 justify-center self-center"}>
          <OppScore score={47} />
          <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>CAM</p>
        </div>
        <div className={"flex flex-col gap-1 justify-center self-center"}>
          <OppScore score={55} />
          <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>LM</p>
        </div>
        <div className={"flex flex-col gap-1 justify-center self-center"}>
          <OppScore score={47} />
          <p className={"text-xxs text-on-surface-variant font-semibold text-center"}>RF</p>
        </div>
      </div>
      <p>
        And if we look in the detailed opponent scores for Neymar, we will see that the LM usually score best against Reims, while the LF
        scores less, meaning that the formation impacts a lot against this team
      </p>
    </div>
  );
};

const CostLegendExample = ({ onExample }) => {
  return (
    <BoxLegend title={"Cost"} onExample={onExample}>
      <CostLegendExampleInner />
    </BoxLegend>
  );
};

export const CostLegendExampleInner = () => {
  return (
    <div className={"text-xs space-y-3 font-sans"}>
      <div className={"flex justify-center items-center"}>
        <img src={ExampleCost} />
      </div>
      <p>
        L15 average is calculated using the last 15 games this player was listed in covered teams. The average ignores games where the
        player did not play (DNP).
      </p>
      <p>
        Additionally, there can be differences between the Sorare L15 (cost) and the SorareData L15 based on your settings, as Sorare&apos;s
        averages are calculated after each gameweek and SorareData&apos;s after each player&apos;s match.
      </p>
    </div>
  );
};

export const PickScoreLegendExample = ({ onExample }) => {
  return (
    <BoxLegend title={"Pick score"} onExample={onExample}>
      <div className={"text-xs space-y-3 font-sans"}>
        <p>Let’s take Timothy Weah in GW341 as an example.</p>
        <div className={"flex justify-center items-center"}>
          <img src={ExamplePickScoreAA} />
        </div>

        <p>
          In All Star Rare competition, his Pick Score is AA, meaning he is considered a very solid pick, but not among the very top ones
        </p>
        <div className={"flex justify-center items-center"}>
          <img src={ExamplePickScoreAAA} />
        </div>
        <p>
          Now if we look at his score in U23 Rare competition, his Pick score improves to AAA, as there are less available players that fit
          the criteria of this competition
        </p>
      </div>
    </BoxLegend>
  );
};

const MatchupIndicatorLegendExample = ({ onExample }) => {
  return (
    <BoxLegend title={"Matchup indicator"} onExample={onExample}>
      <MatchupIndicatorExample />
    </BoxLegend>
  );
};

const MatchupIndicatorExample = () => {
  return (
    <div className={"text-xs space-y-2 font-sans"}>
      <p>Let&apos;s look at Karim Benzema’s matchup in a Real Madrid-Barcelona game.</p>
      <p>
        <ul className={"list-disc pl-3"}>
          <li>Karim Benzema’s average score at home during LaLiga games is 65</li>
          <li>LaLiga fantasy points average for forwards is 51</li>
          <li>Forwards score 56 on average against Barcelona when Barca is playing LaLiga games away.</li>
        </ul>
      </p>
      <p>
        Matchup indicator is 65-51+56-51 = <b>19</b>
      </p>
    </div>
  );
};

const MedianFloorCeilingLegendExample = ({ onExample }) => {
  return (
    <BoxLegend title={"Median, Floor, Ceiling"} onExample={onExample}>
      <div className={"text-xs space-y-2 font-sans"}>
        <p>
          Let’s take <b>Lionel Messi Last 15</b> home games for <b>PSG</b> in <b>Ligue 1.</b>
        </p>
        <p>47.9 / 83.6 / 54.6 / 77.6 / 82.8 / 52.7 / 66.7 / 79.8 / 52.8 / 82.7 / 45 / 74.2 / 55.4 / 99 / 75.7 </p>
        <p>Sorting this subset by ascending order, we easily identify the floor, median and ceiling values:</p>
        <p>
          <b>45</b> / 47.9 / 52.7 / 52.8 / 54.6 / 55.4 / 66.7 / <b>74.2</b> / 75.7 / 77.6 / 79.8 / 82.7 / 82.8 / 83.6 / <b>99</b>
        </p>
        <p>
          The <b>floor</b> of that subset is <b>45</b>
        </p>
        <p>
          The <b>ceiling</b> of that subset is <b>99</b>
        </p>
        <p>
          The <b>median</b> of that subset is <b>74.2</b>
        </p>
        <p>
          For reference, the average of that subset is <b>68.7 (L15)</b>
        </p>
      </div>
    </BoxLegend>
  );
};

const PctPickExample = () => {
  const { theme } = useTheme();
  const circleNeutralBackground = getThemeColor(theme, "transparent-inverse-surface-low");
  return (
    <div className={"w-10"}>
      <CircularProgressbarWithChildren
        value={25}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: "round",
          pathColor: "#50BF84",
          trailColor: circleNeutralBackground,
        })}
      >
        <p className={"text-xs font-semibold"} style={{ color: "#50BF84" }}>
          {"25%"}
        </p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

const PctPickLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <PctPickExample />
      </div>
      <div className={"col-span-6 flex flex-row items-center"}>
        <p>Average % of cards of this player picked when the player plays</p>
      </div>
    </div>
  );
};

const PctDivExample = () => {
  const { theme } = useTheme();
  const circleNeutralBackground = getThemeColor(theme, "transparent-inverse-surface-low");
  return (
    <div className={"w-10"}>
      <CircularProgressbarWithChildren
        value={25}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: "round",
          pathColor: "#50BF84",
          trailColor: circleNeutralBackground,
        })}
      >
        <p className={"text-xs font-semibold"} style={{ color: "#50BF84" }}>
          {"25%"}
        </p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

const PctDivPickLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <PctDivExample />
      </div>
      <div className={"col-span-6 flex flex-row items-center"}>
        <p>Average % of picks cards of this player lined up in this division</p>
      </div>
    </div>
  );
};

const MatchupIndicatorLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <MatchupScore score={19} />
      </div>
      <div className={"col-span-6 flex flex-col"}>
        <p>
          The matchup score is used to evaluate the differences between two teams in terms of fantasy scoring, for a given player. It is the
          addition of two things:
        </p>
        <ul className={"list-disc pl-3"}>
          <li>The player’s over/under-performance compared to competition average - competitions’ average for this position</li>
          <li>
            The opponent score over/under-performance compared to competition average (i.e opp. score) - competition’s average for this
            position
          </li>
        </ul>
      </div>
    </div>
  );
};

const PlayedLegend = () => {
  return (
    <div className={"flex flex-col gap-1"}>
      <div className={"grid grid-flow-row-dense grid-cols-5"}>
        <div className={"col-span-1 flex justify-center items-center"}>
          <div className={"flex flex-col gap-1 justify-center items-center"}>
            <img className={"h-4 w-4"} src={Started} />
            <p className={"text-on-surface-variant text-xs"}>90%</p>
          </div>
        </div>
        <div className={"col-span-4 flex flex-col justify-evenly gap-1"}>
          <p>a. Player’s game statuses</p>
          <p>b. Percentage of games of this status over the last 5, 15 or 40 games.</p>
        </div>
      </div>
      <div className={`grid grid-flow-row-dense grid-cols-5`}>
        <div className={`col-span-1 flex justify-center items-center`}>
          <div className={"flex flew-row bg-grey-e9 rounded-md gap-1 px-1 py-1 w-fit"}>
            <img className={"h-4 w-4"} src={Started} />
            <img className={"h-4 w-4"} src={CameIn} />
            <img className={"h-4 w-4"} src={Bench} />
            <img className={"h-4 w-4"} src={NotInSquad} />
          </div>
        </div>
        <div className={"col-span-4 flex items-center"}>
          <p>A. Statuses, from left to right : starter, played as substitute, stayed on bench, out of squad</p>
        </div>
      </div>
    </div>
  );
};

const DecisiveScoreLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <DecisiveActionsScore score={45} />
      </div>
      <div className={"col-span-6 flex flex-col gap-2 py-2"}>
        <p>Decisive score average over the last 5, 15 or 40 games</p>
      </div>
    </div>
  );
};

const AllAroundScoreLegend = () => {
  return (
    <div className={"grid grid-flow-row-dense grid-cols-7"}>
      <div className={"col-span-1 flex justify-center items-center"}>
        <AllAroundScore score={14.5} />
      </div>
      <div className={"col-span-6 flex flex-col gap-2 py-2"}>
        <p>All Around score average over the last 5, 15 or 40 games</p>
      </div>
    </div>
  );
};

const MedianFloorCeilingLegend = () => {
  return (
    <div className={"flex flex-col gap-1"}>
      <div className={"grid grid-flow-row-dense grid-cols-7"}>
        <div className={"col-span-1 flex justify-center items-center"}>
          <MedianScore score={76} />
        </div>
        <div className={"col-span-6 flex flex-col justify-evenly gap-1"}>
          <p>a. Median (“middle value”) of the subset</p>
        </div>
      </div>
      <div className={`grid grid-flow-row-dense grid-cols-7`}>
        <div className={`col-span-1 flex justify-center items-center`}>
          <FloorScore score={14} />
        </div>
        <div className={"col-span-6 flex items-center"}>
          <p>b. Floor (“lowest value” excluding DNP) of the subset</p>
        </div>
      </div>
      <div className={`grid grid-flow-row-dense grid-cols-7`}>
        <div className={`col-span-1 flex justify-center items-center`}>
          <CeilingScore score={100} />
        </div>
        <div className={"col-span-6 flex items-center"}>
          <p>c. Ceiling (“highest value”) of the subset</p>
        </div>
      </div>
    </div>
  );
};

const LockedIndicatorModale = (props) => {
  const { title, children } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    ReactTooltip.rebuild();
  }, [props.open]);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-500 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={"inline-block transform transition-all sm:my-8 sm:align-middle"}>
                <div className={"flex flex-row gap-4 justify-center"}>
                  <div className={"max-w-3xl relative align-bottom bg-surface-container-high rounded-lg pt-5 pb-4 text-left shadow-xl"}>
                    <div className="absolute top-0 right-0 pt-4 pr-4">
                      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <div
                          className={"flex flex-row cursor-pointer"}
                          onClick={() => {
                            props.close();
                            setOpen(false);
                          }}
                        >
                          <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
                        </div>
                      </div>
                    </div>
                    <div className={"font-sans flex flex-col gap-2 text-on-surface"}>
                      <div className="font-headers px-5 border-b border-transparent-inverse-surface-low border-opacity-10 pb-4">
                        <p className="text-xl font-semibold m-0 text-center">{title}</p>
                      </div>
                      <div className={"flex flex-col gap-3 px-4 py-1 transition-height transition-slowest ease"}>
                        {children}
                        <div className={"flex items-center justify-between w-full pt-1"}>
                          <div>
                            <a href={"https://youtu.be/kYLPKrHArtE"} target={"blank_"}>
                              <div className={"flex flew-row gap-1 justify-center items-center hover:opacity-80 text-xs font-semibold"}>
                                <p className={"text-primary "}>Learn more about the subscription tiers</p>
                                <div className={"w-4 h-4 self-center inline-flex"}>
                                  <img className={"w-full h-full object-contain"} src={PlayCircle} />
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="w-1/3">
                            <a href={"/product"}>
                              <Button label={"Upgrade"} adapting />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export const MatchuIndicatorModaleLegend = (props) => {
  const { open, close } = props;
  return (
    <LockedIndicatorModale title={"Matchup Indicator"} open={open} close={close}>
      <Box header={"Matchup ind."}>
        <MatchupIndicatorLegend />
      </Box>
      <div className={"flex flex-col gap-2 bg-surface-container text-on-surface rounded-lg p-3"}>
        <h4 className={"text-center text-xl font-headers"}>Example</h4>
        <MatchupIndicatorExample />
      </div>
      <div className={"flex flex-row w-full justify-center items-center text-on-surface "}>
        <p className={"text-sm font-semibold text-center w-2/3"}>
          Become a Star member to unlock advanced stats on your players matchup (Matchup Indicator)
        </p>
      </div>
    </LockedIndicatorModale>
  );
};

export const OppScoreModaleLegend = (props) => {
  const { open, close } = props;
  return (
    <LockedIndicatorModale title={"Opponent Score"} open={open} close={close}>
      <Box header={"Opp. Scores"}>
        <OppScoreLegend />
      </Box>
      <div className={"flex flex-col gap-2 bg-surface-container text-on-surface rounded-lg p-3"}>
        <h4 className={"text-center text-xl font-headers"}>Example</h4>
        <OpponentScoreExample />
      </div>
      <div className={"flex flex-row w-full justify-center items-center text-on-surface "}>
        <p className={"text-sm font-semibold text-center w-2/3"}>
          Become a Star member to unlock advanced stats on your players opponents (Opponent score)
        </p>
      </div>
    </LockedIndicatorModale>
  );
};

export default withUser(ViewLUBTableLegend);
