export const not_all_baseball_clubs_objects = [
  { value: "arizona-diamondbacks", name: "Arizona Diamondbacks" },
  { value: "atlanta-braves", name: "Atlanta Braves" },
  { value: "baltimore-orioles", name: "Baltimore Orioles" },
  { value: "boston-red-sox", name: "Boston Red Sox" },
  { value: "chicago-cubs", name: "Chicago Cubs" },
  { value: "chicago-white-sox", name: "Chicago White Sox" },
  { value: "cincinnati-reds", name: "Cincinnati Reds" },
  { value: "cleveland-guardians", name: "Cleveland Guardians" },
  { value: "colorado-rockies", name: "Colorado Rockies" },
  { value: "detroit-tigers", name: "Detroit Tigers" },
  { value: "houston-astros", name: "Houston Astros" },
  { value: "kansas-city-royals", name: "Kansas City Royals" },
  { value: "los-angeles-dodgers", name: "Los Angeles Dodgers" },
  { value: "los-angeles-angels", name: "Los Angeles Angels" },
  { value: "miami-marlins", name: "Miami Marlins" },
  { value: "milwaukee-brewers", name: "Milwaukee Brewers" },
  { value: "minnesota-twins", name: "Minnesota Twins" },
  { value: "new-york-mets", name: "New York Mets" },
  { value: "new-york-yankees", name: "New York Yankees" },
  { value: "oakland-athletics", name: "Oakland Athletics" },
  { value: "philadelphia-phillies", name: "Philadelphia Phillies" },
  { value: "pittsburgh-pirates", name: "Pittsburgh Pirates" },
  { value: "san-diego-padres", name: "San Diego Padres" },
  { value: "san-francisco-giants", name: "San Francisco Giants" },
  { value: "seattle-mariners", name: "Seattle Mariners" },
  { value: "st-louis-cardinals", name: "St. Louis Cardinals" },
  { value: "tampa-bay-rays", name: "Tampa Bay Rays" },
  { value: "texas-rangers", name: "Texas Rangers" },
  { value: "toronto-blue-jays", name: "Toronto Blue Jays" },
  { value: "washington-nationals", name: "Washington Nationals" },
];

export const baseball_clubs_objects = [{ value: "all", name: "All clubs" }, ...not_all_baseball_clubs_objects];
