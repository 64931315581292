import React from "react";
import GoalIcon from "../../img/ICN_Ballon.svg";
import OwnGoalIcon from "../../img/own-goal.svg";
import AssistIcon from "../../img/assist-icon.svg";
import YellowCard from "../../img/yellow-card.svg";
import SubIn from "../../img/sub-in-details.svg";
import RedCard from "../../img/red-card.svg";
import SecondYellow from "../../img/second-yellow-card.svg";
import SubOut from "../../img/sub-out-details.svg";
import GameLivePageHeader from "./gameLivePageHeader";

function GameEvents(props) {
  const events = props.events;

  let allEvents = [];
  if (events.goals !== null) {
    allEvents = allEvents.concat(events.goals);
  }
  if (events.cards !== null) {
    allEvents = allEvents.concat(events.cards);
  }
  if (events.substitutions !== null) {
    allEvents = allEvents.concat(events.substitutions);
  }

  allEvents = allEvents.sort(function (a, b) {
    if (a.time <= b.time) return -1;
    else return 1;
  });

  const periodSeparatorStyle = "text-xs uppercase font-semibold text-textGrey3 text-center";
  const homeTeam = props.homeTeam;
  const awayTeam = props.awayTeam;
  const timeMinStyle = "w-6 text-sm font-semibold text-textGrey3 self-center";
  const playerNameStyle = "text-sm font-semibold self-center";

  const iconReturn = (icon) => {
    return (
      <div className={"w-5 h-5"}>
        <img src={icon} className={"w-full h-full object-contain"} />
      </div>
    );
  };

  const subIconReturn = (icon) => {
    return (
      <div className={"w-5 h-3 self-center"}>
        <img src={icon} className={"w-full h-full object-contain"} />
      </div>
    );
  };

  const eventReturn = (e) => {
    let name;
    let justify = " justify-start ";
    let isHome = true;
    if (e.team_id === awayTeam.TeamId) {
      justify = " justify-end ";
      isHome = false;
    }
    if (e.player_id) {
      // card
      let icon = YellowCard;
      if (e.type === "RC") {
        icon = RedCard;
      } else if (e.type === "Y2C") {
        icon = SecondYellow;
      }
      return (
        <div className={"flex flex-row space-x-4" + justify}>
          {isHome && <p className={timeMinStyle}>{e.time}&apos;</p>}
          <div className={"flex flex-col space-y-2"}>
            <div className={"flex flex-row space-x-2" + justify}>
              {isHome && iconReturn(icon)}
              <p className={playerNameStyle}>
                <a href={"/player/" + e.player_id} target={"_blank"} rel="noreferrer">
                  {e.player_name}
                </a>
              </p>
              {!isHome && iconReturn(icon)}
            </div>
          </div>
          {!isHome && <p className={timeMinStyle}>{e.time}&apos;</p>}
        </div>
      );
    } else if (e.player_on_name) {
      // sub
      return (
        <div className={"flex flex-row space-x-4" + justify}>
          {isHome && <p className={timeMinStyle}>{e.time}&apos;</p>}
          <div className={"space-y-0.5 flex flex-col"}>
            <div className={"flex flex-row space-x-2" + justify}>
              {isHome && subIconReturn(SubIn)}
              <p className={"text-brand-green font-semibold text-sm"}>
                <a href={"/player/" + e.player_on_id} target={"_blank"} rel="noreferrer">
                  {e.player_on_name}
                </a>
              </p>
              {!isHome && subIconReturn(SubIn)}
            </div>
            <div className={"flex flex-row space-x-2" + justify}>
              {isHome && subIconReturn(SubOut)}
              <p className={"text-brand font-semibold text-sm"}>
                <a href={"/player/" + e.player_off_id} target={"_blank"} rel="noreferrer">
                  {e.player_off_name}
                </a>
              </p>
              {!isHome && subIconReturn(SubOut)}
            </div>
          </div>
          {!isHome && <p className={timeMinStyle}>{e.time}&apos;</p>}
        </div>
      );
    } else if (e.scorer_id) {
      // goal
      const goalIcon = e.type === "OG" ? OwnGoalIcon : GoalIcon;
      return (
        <div className={"flex flex-row space-x-4" + justify}>
          {isHome && <p className={timeMinStyle}>{e.time}&apos;</p>}
          <div className={"flex flex-col space-y-0.5"}>
            <div className={"flex flex-row space-x-2" + justify}>
              {isHome && iconReturn(goalIcon)}
              <p className={playerNameStyle}>
                <a href={"/player/" + e.scorer_id} target={"_blank"} rel="noreferrer">
                  {e.scorer_name} {e.type === "PG" && "(pen)"}
                </a>
              </p>
              {!isHome && iconReturn(goalIcon)}
            </div>
            {e.assistant_id !== "" && (
              <div className={"flex flex-row space-x-2" + justify}>
                {isHome && iconReturn(AssistIcon)}
                <p className={playerNameStyle}>
                  <a href={"/player/" + e.assistant_id} target={"_blank"} rel="noreferrer">
                    {e.assistant_name}
                  </a>
                </p>
                {!isHome && iconReturn(AssistIcon)}
              </div>
            )}
          </div>
          {!isHome && <p className={timeMinStyle}>{e.time}&apos;</p>}
        </div>
      );
    } else return null;
  };

  if (allEvents.length === 0) {
    return null;
  }

  return (
    <div className={"space-y-4 w-full"}>
      <GameLivePageHeader label={"Game summary"} />
      <div className={"bg-white rounded-lg p-4 space-y-1.5"}>
        {allEvents.map((e) => {
          if (e.period === 1) {
            return eventReturn(e);
          } else return null;
        })}
        {(props.game.game.Status === "played" || (props.game.game.Status === "playing" && props.game.game.Period > 1)) && (
          <p className={periodSeparatorStyle}>HALF TIME {events.halftime}</p>
        )}
        {allEvents.map((e) => {
          if (e.period === 2) {
            return eventReturn(e);
          } else return null;
        })}
        {props.game.game.Status === "played" && <p className={periodSeparatorStyle}>FULL TIME {events.fulltime}</p>}
      </div>
    </div>
  );
}

export default GameEvents;
