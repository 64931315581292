import React, { Fragment } from "react";
import { withUser } from "../../userContext";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const LogoutModale = (props) => {
  const { open } = props;

  const onCancel = () => {
    props.onCancel && props.onCancel();
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={onCancel}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={onCancel}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"w-auto mt-8"}>
                  <div className={"w-full border-b-2 border-grey-e5 pb-4"}>
                    <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>Log out of SorareData</h2>
                  </div>
                </div>
                <div className={"flex flex-row justify-center p-6 mx-auto"}>
                  <div className={"flex flex-row gap-2 justify-center my-auto"}>
                    <a href={"/logout"}>
                      <button
                        className={`mx-2 p-3 px-16 text-sm font-medium rounded-lg bg-[#E73107] text-white  focus:outline-none hover:opacity-80 cursor-pointer`}
                      >
                        Log out
                      </button>
                    </a>
                    <a href={"/logout?allDevices=true"}>
                      <button
                        className={`mx-2 p-3 px-16 text-sm font-medium rounded-lg bg-[#F8E0E0] text-[#E73107] focus:outline-none hover:opacity-80 cursor-pointer`}
                      >
                        Log out of all devices
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default withUser(LogoutModale);
