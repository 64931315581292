import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactTooltip } from "../util/tooltip.js";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";
import { Tag } from "./tag";

export const ModalDS = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    ReactTooltip.rebuild();
  }, [props.open]);

  let maxSize = "sm:max-w-3xl";
  let size = "sm:w-full";
  let padding = "px-4 pt-5 pb-4 sm:p-6";
  if (props.maxSize) {
    maxSize = props.maxSize;
  }
  if (props.size) {
    size = props.size;
  }
  let bg = "bg-surface-container-high";
  if (props.bg) {
    bg = props.bg;
  }

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-100 inset-0 overflow-y-auto"
          onClose={() => {
            if (!props.noClose) props.close();
          }}
        >
          <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`${size} ${maxSize} inline-block align-bottom ${bg} rounded-2xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle`}
              >
                {!props.noShowClose && (
                  <div className="absolute top-0 right-0 pt-4 pr-4 cursor-pointer">
                    <CloseIcon
                      className="fill-on-surface h-6 w-6 hover:opacity-80"
                      onClick={() => {
                        setOpen(false);
                        props.close();
                      }}
                    />
                  </div>
                )}
                {props.header && <div className={"border-b border-outline-variant"}>{props.header}</div>}
                {props.content}
                {props.footer && <div className={"border-t border-outline-variant"}>{props.footer}</div>}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export const ModalDSTitleHeader = (props) => {
  const { title, subtitle, beta } = props;
  return (
    <div className="flex flex-col justify-start items-baseline gap-1 p-4">
      <h3 className={"m-0 font-headers text-xl flex flex-row gap-2 items-center"}>
        {title}
        {beta && (
          <div className={"flex my-auto"}>
            <Tag context="tertiary" size="small" label={"Beta"} />
          </div>
        )}
      </h3>
      {subtitle && <h5 className={"m-0 font-medium text-sm"}>{subtitle}</h5>}
    </div>
  );
};
