import React, { Component } from "react";
import { Text, Box, Grid, Anchor, ResponsiveContext } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import ResponsiveGrid from "../util/responsiveGrid";
import { findPriceForUserUnit, formatPrice } from "../util/formatMoney";
import EnabledWallets from "../util/enabledWallets";

class BidLine extends Component {
  render() {
    const bid = this.props.bid;
    const bidDate = new Date(bid.bid.CreationDate);

    const columns = {
      small: ["50%", "35%", "15%"],
      medium: ["xsmall", "medium", "xsmall", "auto", "xsmall"],
      large: ["xsmall", "medium", "xsmall", "auto", "xsmall"],
    };

    const ellipsisStyle = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "inline-block",
    };

    const shouldDisplaySecondUnit = this.props.unit !== "eth";
    const price = findPriceForUserUnit(bid.bid.Price, bid.bid_price_fiat, this.props.unit);

    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <ResponsiveGrid columns={columns} rows={["auto"]}>
            {size !== "small" && (
              <div className={"mx-auto overflow-hidden flex items-center justify-center"}>
                <div className={"w-12 h-12 flex items-center justify-center"}>
                  <a href={"/manager/" + bid.manager.Slug}>
                    {bid.manager.PictureUrl !== "" ? (
                      <img className={"object-cover w-11 h-11 mx-auto"} style={{ borderRadius: "50%" }} src={bid.manager.PictureUrl} />
                    ) : (
                      <FontAwesomeIcon icon={faUser} size={"2x"} color={"#292929"} />
                    )}
                  </a>
                </div>
              </div>
            )}
            <Box direction={"row"} justify={"start"} align={"center"} width={"300px"} margin={{ left: "medium" }}>
              <Grid columns={["auto"]} rows={["auto", "auto"]}>
                <Box style={ellipsisStyle}>
                  <Anchor href={"/manager/" + bid.manager.Slug} color={"#292929"}>
                    <Text size={"medium"} weight={600}>
                      {bid.manager.Nickname}
                    </Text>
                  </Anchor>
                </Box>
                <Box style={ellipsisStyle}>
                  <Anchor href={"/manager/" + bid.manager.Slug} color={"#292929"}>
                    <Text size={"medium"} weight={600} color={"#6b6b6b"}>
                      {bid.manager.TeamName}
                    </Text>
                  </Anchor>
                </Box>
              </Grid>
            </Box>
            {size !== "small" && (
              <Box direction={"row"} justify={"start"} align={"center"}>
                <Grid columns={["auto"]} rows={["auto"]}>
                  <Box>{!!bid.manager.EnabledWallets && <EnabledWallets enabledWallets={bid.manager.EnabledWallets} />}</Box>
                </Grid>
              </Box>
            )}
            <Box direction={"row"} justify={"start"} align={"center"} margin={{ right: "medium", left: "medium" }}>
              <Grid columns={["auto"]} rows={["auto"]}>
                <Box>
                  <Text size={"small"} weight={600}>
                    {bidDate.toLocaleDateString() + " " + bidDate.toLocaleTimeString()}
                  </Text>
                </Box>
              </Grid>
            </Box>
            <Box direction={"column"} justify={"center"} align={size === "small" ? "end" : "center"} margin={{ right: "small" }}>
              <Box direction={"row"} justify={"end"}>
                <Text size={"medium"} weight={600}>
                  {price}
                </Text>
              </Box>
              {shouldDisplaySecondUnit && (
                <Box direction={"row"} justify={"center"}>
                  <Text size={"small"} weight={600}>
                    {formatPrice(bid.bid.Price, "eth")}
                  </Text>
                </Box>
              )}
            </Box>
          </ResponsiveGrid>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export default BidLine;
