import React, { Component } from "react";
import { Box, Heading } from "grommet";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import CustomizedAxisTick from "../util/tick";
import { BRAND_COLOR } from "../util/colors";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

class AuctionVolumeHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: {},
    };
  }

  componentDidMount() {
    this.getStats();
  }

  getStats = () => {
    this.props
      .fetch("/apiv2/stats/volume/auctions/history")
      .then((response) => response.json())
      .then((res) => {
        this.setState({ stats: res });
      })
      .catch(errorCatcher());
  };

  render() {
    const data = this.state.stats;
    return (
      <Box>
        <Box align={"center"}>
          <Heading level={"3"}>Primary market volume history</Heading>
          <ResponsiveContainer width={"100%"} height={400}>
            <LineChart
              data={data}
              width={600}
              height={400}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 40,
              }}
            >
              <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
              <YAxis domain={[0, 200]} />
              <Tooltip />
              <Line stroke={BRAND_COLOR} type="monotone" dataKey="volume" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  }
}

export default withUser(AuctionVolumeHistory);
