import { formatPrice } from "../util/formatMoney";
import CardLink from "../cards/cardLink";
import PlayerAvailability from "../players/playerAvailability";
import ScoreAverage from "../decision/scoreAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import GameOnCard from "../games/gameOnCard";
import Remove from "../../img/remove-card-lineup.svg";
import React from "react";

function TradeListView(props) {
  const cp = props.info;
  const card = cp.card;
  const player = cp.player;
  let lastPrice = cp.card.LastPrice;
  let lastStatus = cp.card.LastStatus;
  let displayStatus = lastStatus;
  const unit = props.unit;
  const teamMap = props.teamMap;
  const showRemove = props.showRemove;
  if (lastStatus === "Sold") {
    displayStatus = formatPrice(lastPrice, "eth");
  }
  return (
    <tr className={"border-b border-grey-e5"}>
      <td>
        <div className={"py-2 flex flex-row space-x-3"}>
          <a href={"/card/" + card.TokenId}>
            <div className={"h-14"}>
              <img src={card.PictureUrl} className={"h-full object-contain"} />
            </div>
          </a>

          <div className={"flex flex-col space-y-0.5 self-center"}>
            <a href={"/player/" + player.PlayerId}>
              <p className={"text-sm font-semibold"}>{player.DisplayName}</p>
            </a>
            <CardLink size={"text-xs"} card={card} sport={player.Sport} />
          </div>
        </div>
      </td>
      <td>
        <div className={"flex flex-row justify-center"}>
          <div className={"w-4 h-4 self-center"}>
            <PlayerAvailability availability={cp.status} />
          </div>
        </div>
      </td>
      <td>
        <div className={"flex flex-row self-center justify-center"}>
          <ScoreAverage
            font={"text-sm font-semibold"}
            size={"w-8 h-7"}
            playedSize={"w-8"}
            period={5}
            played={cp.gms5}
            sAvg={cp.s5Avg}
            sApps={cp.s5Apps}
            avg={cp.avg5}
            playerId={player.PlayerId}
          />
        </div>
      </td>
      <td>
        <div className={"flex flex-row self-center justify-start"}>
          <ScoreAverage
            font={"text-sm font-semibold"}
            size={"w-8 h-7"}
            playedSize={"w-8"}
            period={15}
            played={cp.gms20}
            sAvg={cp.s15Avg}
            sApps={cp.s15Apps}
            avg={cp.avg20}
            playerId={player.PlayerId}
          />
        </div>
      </td>
      <td>
        <div className={"flex flex-row self-center justify-start"}>
          <p className={"text-sm font-semibold"}>{formatBestMarketPrice(cp.bmp, unit)}</p>
        </div>
      </td>
      <td>
        <div className={"flex flex-row self-center justify-start"}>
          <p className={"text-sm font-semibold"}>{formatRealTimeAverage(cp.rt, unit)}</p>
        </div>
      </td>
      <td>
        <div className={"flex flex-row self-center justify-start"}>
          <p className={"text-sm font-semibold"}>{(100 * card.Power - 100).toFixed(1)}%</p>
        </div>
      </td>
      <td>
        <div className={"flex flex-row justify-center"}>
          <GameOnCard player={player} gwGames={teamMap} fontSize={"10px"} gwNumber={undefined} />
        </div>
      </td>
      {showRemove && (
        <td>
          <div className={"flex flex-row justify-center"}>
            <div
              className="w-4 h-4 self-center cursor-pointer"
              onClick={() => {
                props.remove(cp.card.TokenId);
              }}
            >
              <img className={"w-full h-full object-contain"} src={Remove} />
            </div>
          </div>
        </td>
      )}
    </tr>
  );
}

export default TradeListView;
