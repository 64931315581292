import React, { useState } from "react";
import Bolt from "../../img/bolt.svg";
import Modal from "../util/modal";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import Check from "../../img/check_small.svg";
import { withUser } from "../../userContext";
import ManagerCollectionCardModal from "./managerCollectionCardModal";

function CollectionScoreModalBox(props) {
  return (
    <div className={props.checked ? "space-y-1" : "space-y-1 opacity-60"}>
      <p className={"text-[#CCCCCC] font-semibold"}>{props.label}</p>
      <div className={"flex flex-rows space-x-1"}>
        <p className={"text-white"}>{props.score}</p>
        {props.checked && <img src={Check} />}
        {props.heldDiff > 0 && <p className={"self-center pl-1 text-green-dark font-semibold text-xxs"}>in {props.heldDiff} days</p>}
      </div>
    </div>
  );
}

function CollectionScoreModal(props) {
  let details = props.details;
  return (
    <div className={"rounded-lg text-[#333333] bg-[#333333] p-4 z-10"}>
      <div className={"grid grid-cols-3 gap-3 border-b border-[#B3B3B3] pb-4"}>
        <CollectionScoreModalBox score={10} label={"Owned"} checked={details.owner > 0} />
        <CollectionScoreModalBox score={10} label={"Held 90 days"} checked={details.holding > 0} heldDiff={props.heldDiff} />
        <CollectionScoreModalBox score={20} label={"Only owner"} checked={details.firstOwner > 0} />
        <CollectionScoreModalBox score={20} label={"Special edition"} checked={details.specialEdition > 0} />
        <CollectionScoreModalBox score={30} label={"Serial #1"} checked={details.firstSerialNumber > 0} />
        <CollectionScoreModalBox score={30} label={"Jersey mint"} checked={details.shirtMatchingSerialNumber > 0} />
      </div>
      <div className={"flex flex-row justify-between pt-4"}>
        <p className={"text-xs text-[#CCCCCC]"}>Total</p>
        <div className={"flex flex-row space-x-0.5"}>
          <span className={"text-white"}>{details.total}</span>
          <img src={Bolt} />
          <span className={"text-[#CCCCCC] opacity-50"}>/150</span>
        </div>
      </div>
    </div>
  );
}

function ManagerCollectionCard(props) {
  const [showModal, setShowModal] = useState(false);

  const c = props.card;

  if (c.isOwned && c.cards !== null) {
    let heldDiff;
    if (c.cards[0].cardCollectionMetadata.heldSince !== null) {
      heldDiff = 90 - Math.round((new Date() - new Date(c.cards[0].cardCollectionMetadata.heldSince)) / (1000 * 60 * 60 * 24));
    }
    return (
      <>
        <div className={"flex flex-col space-y-2"}>
          <div
            className={"cursor-pointer"}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <img src={c.cards[0].card.PictureUrl} />
            <div>
              <Modal
                close={() => setShowModal(false)}
                bgModal={"bg-black-4D"}
                bg={"bg-black-4D"}
                open={showModal}
                noShowClose={true}
                size={"w-10/12"}
                content={
                  <ManagerCollectionCardModal
                    collection={props.collection}
                    manager={props.manager}
                    placeholder={c.placeholderCard}
                    owned={true}
                  />
                }
                maxSize={"w-10/12"}
              />
            </div>
          </div>

          <div className={"flex flex-row justify-end space-x-2"}>
            {c.cards[0].cardCollectionMetadata.holding === 0 && c.cards[0].cardCollectionMetadata.heldSince !== null && heldDiff > 0 && (
              <div className={"self-center font-semibold text-green-dark text-xxs"}>+10 in {heldDiff} days</div>
            )}
            <div
              className={
                "relative cursor-pointer flex group flex-row space-x-0.5 justify-center px-1.5 py-1 rounded-full bg-black-4D text-white font-semibold text-xs"
              }
            >
              <div className={"hidden group-hover:absolute group-hover:block bottom-0 z-10 mb-8 w-80"}>
                <CollectionScoreModal details={c.cards[0].cardCollectionMetadata} heldDiff={heldDiff} />
              </div>
              <p>{c.cards[0].cardCollectionMetadata.total}</p>
              <img src={Bolt} />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={"relative group space-y-2"}>
        <div
          className={"cursor-pointer"}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {/*<div
                        className={"hidden group-hover:absolute group-hover:block left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 z-10"}>
                        <div className={"bg-white cursor-pointer px-4 py-1 rounded-full"}>
                            <p className={"text-xs cursor-pointer font-semibold"}>Find me</p>
                        </div>
                    </div>*/}
          <img className={"opacity-50 z-0"} src={c.placeholderCard.PictureUrl} />
          <div>
            {showModal && (
              <Modal
                noShowClose={true}
                bgModal={"bg-black-4D"}
                bg={"bg-black-4D"}
                open={showModal}
                size={"w-10/12"}
                content={
                  <ManagerCollectionCardModal
                    owned={false}
                    collection={props.collection}
                    manager={props.manager}
                    placeholder={c.placeholderCard}
                  />
                }
                maxSize={"w-10/12"}
                close={() => setShowModal(false)}
              />
            )}
          </div>
        </div>
        <div className={"flex flex-row justify-end opacity-50"}>
          <div
            className={
              "relative cursor-pointer flex group flex-row space-x-0.5 justify-center px-1.5 py-1 rounded-full bg-black-4D text-white font-semibold text-xs"
            }
          >
            <p>{formatBestMarketPrice(c.placeholderCard.bestMarketPrice, props.user.preferredUnit)}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withUser(ManagerCollectionCard);
