import React, { useState } from "react";
import { withUser } from "../../userContext";
import ls from "local-storage";
import HomeAnnouncementBanner from "../../img/home-announcement-banner.png";
import SDLogoWhite from "../../img/logo-landing.svg";
import { Button } from "../util/button";

function HomeAnnouncement(props) {
  const [show, setShow] = useState(ls.get("showAnnouncement"));

  // TODO: remove this line to enable back the announcement banner
  // Needs a rework for responsiveness and not popping too much
  return null;
  // eslint-disable-next-line no-unreachable
  if (show !== false) {
    return (
      <div className="fixed bottom-0 inset-x-0 z-100 pb-2 sm:pb-5">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div
            className="p-2 rounded-lg shadow-lg sm:p-3"
            style={{
              backgroundImage: "url('" + HomeAnnouncementBanner + "')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg">
                  <img className="h-6 w-6 text-white" src={SDLogoWhite} />
                </span>
                <p className="ml-3 font-medium text-white truncate font-headers">
                  <span className="md:hidden text-base">
                    Get all the latest news directly in your inbox with the <b>SorareData newsletter</b>.
                  </span>
                  <span className="hidden md:inline">
                    Get all the latest news directly in your inbox with the <b>SorareData newsletter</b>.
                  </span>
                </p>
              </div>
              <div className={"flex flex-row space-x-4"}>
                <div className="order-3 mt-2 shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <a href="https://soraredata.substack.com/">
                    <Button label={"Subscribe 🇺🇸"} />
                  </a>
                </div>
                <div className="order-3 mt-2 shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <a href="https://soraredatanewsletter.substack.com/">
                    <Button label={"S'abonner 🇫🇷"} />
                  </a>
                </div>
              </div>

              <div
                className="order-2 shrink-0 sm:order-3 sm:ml-2"
                onClick={() => {
                  setShow(false);
                  ls.set("showAnnouncement", false);
                }}
              >
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <span className="sr-only">Dismiss</span>
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line no-unreachable
  } else {
    return null;
  }
}

export default withUser(HomeAnnouncement);
