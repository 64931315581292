import { sorareBaseball, sorareBasketball } from "./sports";

export const getCardSupply = (sport, card) => {
  if (!sport || !card) {
    return Infinity;
  }
  const scarcity = card.Scarcity.toUpperCase();
  let supply = Infinity;
  if (scarcity === "LIMITED") {
    supply = 1000;
    if (sport === sorareBaseball) {
      supply = 5000;
    }
    if (sport === sorareBasketball) {
      supply = card.Season >= 2023 ? 1000 : 5000;
    }
  } else if (scarcity === "RARE") {
    supply = 100;
    if (sport === sorareBaseball) {
      supply = 1000;
    }
    if (sport === sorareBasketball) {
      supply = card.Season >= 2023 ? 100 : 1000;
    }
  } else if (scarcity === "SUPER RARE") {
    supply = 10;
    if (sport === sorareBaseball) {
      supply = 100;
    }
    if (sport === sorareBasketball) {
      supply = card.Season >= 2023 ? 10 : 100;
    }
  } else if (scarcity === "UNIQUE") {
    supply = 1;
  } else if (scarcity === "CUSTOM SERIES") {
    supply = 2022;
  }
  return supply;
};
