import React, { useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import PlayerAvatar from "../util/playerAvatar";
import { withUser } from "../../userContext";
import StartIcon from "../../img/starter.svg";
import NotInSquad from "../../img/notInSquad.svg";
import CameIn from "../../img/cameIn.svg";
import Bench from "../../img/benchNotCameIn.svg";
import format from "date-fns/format";
import SquaredScore from "../util/squaredScore";
import { sorareFootball } from "../util/sports";
import CommunityPrediction from "../util/communityPrediction";
import UserPrediction from "../util/userPrediction";
import UnknownClub from "../../img/unknown_club.png";
import { ReactTooltip } from "../util/tooltip.js";
import SelectSearch from "react-select-search";
import { all_positions_objects } from "../util/positions";
import { leagues_with_divisions_objects } from "../util/leagues";
import GreenButton from "../util/greenButton";
import { scarcities_objects } from "../util/scarcities";

const TableHeader = (props) => {
  return (
    <thead className={""}>
      <tr className={"bg-brand-black text-white text-sm font-semibold p-2 rounded-lg"}>
        <th className={"text-left w-3/12 rounded-tl-lg pl-2"}>Player</th>
        <th className={"text-left w-1/12 pl-2 text-center"}>Opponent</th>
        <th className={"text-left w-2/12 text-center"}>Last 5 games</th>
        <th className={"text-left w-2/12 text-center"}>Last 5 scores</th>
        <th className={"text-left w-2/12 text-center"}>Community</th>
        <th className={"text-left w-2/12 rounded-tr-lg text-center py-2"}>Your prediction</th>
      </tr>
    </thead>
  );
};

function ProbablePlayersForUser(props) {
  const gwNumber = props.gwNumber;

  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [position, setPosition] = useState("all");
  const [league, setLeague] = useState("all");
  const [scarcity, setScarcity] = useState("all");

  useEffect(() => {
    if (gwNumber && gwNumber > 0) {
      fetchData();
    }
  }, [props.gwNumber]);

  const fetchData = (p, l, s) => {
    p = p || position;
    l = l || league;
    s = s || scarcity;
    props
      .fetch("/projections-api/SO5/probablePlayersOverview?gwNumber=" + gwNumber + "&position=" + p + "&league=" + l + "&scarcity=" + s)
      .then((response) => response.json())
      .then((res) => {
        setPlayers(res);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher(() => {}));
  };

  const changeStatus = (player, status, i) => {
    let newPlayers = [...players];
    newPlayers[i].probable.status = status;
    setPlayers(newPlayers);
    let starters = [{ playerId: player.player.PlayerId, status: status }];
    props
      .fetch("/projections-api/SO5/changeStartingStatus", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: player.game.game.GameId,
          players: starters,
        }),
      })
      .then((response) => response.json())
      .then((res) => {})
      .catch(errorCatcher(() => {}));
  };

  return (
    <div className={"w-full"}>
      <div className={"w-full space-y-4"}>
        <div className={"grid grid-cols-5 gap-4"}>
          <div>
            <SelectSearch
              options={all_positions_objects}
              value={position}
              onChange={(v) => {
                setPosition(v);
              }}
            />
          </div>
          <div>
            <SelectSearch
              options={leagues_with_divisions_objects}
              value={league}
              onChange={(v) => {
                setLeague(v);
              }}
            />
          </div>
          <div>
            <SelectSearch
              options={scarcities_objects}
              value={scarcity}
              onChange={(v) => {
                setScarcity(v);
              }}
            />
          </div>
          <div>
            <GreenButton label={"Filter"} onClick={() => fetchData()} />
          </div>
        </div>
        <table className={"w-full bg-white"}>
          <TableHeader />
          <tbody>
            {players !== null &&
              players.map((p, i) => {
                const player = p;
                const scores = player.last5Scores;
                let reversedScores = [];
                if (scores && scores.length > 0) {
                  reversedScores = scores.slice().reverse();
                }
                let isHome = true;
                const game = p.game;
                if (game.game.AwayTeamId === player.player.TeamId || game.game.AwayTeamId === player.player.NationalTeam) {
                  isHome = false;
                }
                let opp_logo = game.home_team_logo;
                let opp_name = game.home_team;
                if (isHome) {
                  opp_logo = game.away_team_logo;
                  opp_name = game.away_team;
                }
                return (
                  <tr>
                    <td>
                      <div className={"flex flex-row space-x-4"}>
                        <PlayerAvatar player={player.player} />
                        <div className={"self-center"}>
                          <p className={"self-center font-medium text-sm"}>{player.player.DisplayName}</p>
                          <p className={"self-center text-xs"}>{player.player.Position}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row space-x-4 justify-center"} data-tip={opp_name}>
                        <div className={"w-8 h-8"}>
                          <img src={opp_logo === "" ? UnknownClub : opp_logo} className={"w-full h-full object-contain"} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row space-x-2 justify-center px-1"}>
                        {reversedScores.map((s) => {
                          let icon = StartIcon;
                          if (s.OnGameSheet === false) {
                            icon = NotInSquad;
                          }
                          if (s.SubbedOn === true) {
                            icon = CameIn;
                          }
                          if (s.OnGameSheet === true && s.mins_played === 0) {
                            icon = Bench;
                          }
                          return (
                            <div className={"flex flex-col items-center space-y-0.5"}>
                              <div className={"w-7 h-7"}>
                                <img src={icon} className={"w-full h-full"} />
                              </div>
                              <p className={"text-xxs text-textGrey3 font-semibold"}>{format(new Date(s.game.GameTime), "dd/MM")}</p>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row space-x-2 justify-center"}>
                        {reversedScores.map((s) => {
                          return (
                            <div className={"flex flex-col items-center space-y-0.5"}>
                              <SquaredScore font={"text-sm font-semibold"} size={"w-7 h-7"} avg={s.NewScore} sport={sorareFootball} />
                              <p className={"text-xxs text-textGrey3 font-semibold"}>{format(new Date(s.game.GameTime), "dd/MM")}</p>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div className={"pr-2 flex flex-row justify-center"}>
                        <CommunityPrediction pred={player.lineup} gameId={game.game.GameId} />
                      </div>
                    </td>
                    <td>
                      <div className={"px-2 flex flex-row justify-center"}>
                        <UserPrediction status={player.probable.status} changeStatus={(s) => changeStatus(player, s, i)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(ProbablePlayersForUser);
