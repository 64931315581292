import React, { Component, useEffect } from "react";
import { Box, Text } from "grommet";
import { withUser } from "../../userContext";
import Countdown from "react-countdown-now";

function UpdateTitle({ title, countdownString }) {
  useEffect(() => {
    console.log(title, countdownString);
    document.title = title + " - " + countdownString;
  });
  return null;
}

class AuctionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progPrice: 0,
      progPriceFund: 0,
      hasProgrammedAuction: false,
      countdownString: "",
      hasTheAuction: "",
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateCountdown(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateProgPrice = (event) => {
    this.setState({ progPrice: parseFloat(event.target.value) });
  };

  updateProgPriceFund = (event) => {
    this.setState({ progPriceFund: parseFloat(event.target.value) });
  };

  updateCountdown = () => {
    const dateEnding = new Date(this.props.auction.EndingAt);
    const countdown = (dateEnding - Date.now()) / 1000;
    let days = Math.floor(countdown / (60 * 60 * 24));
    let h = Math.floor((countdown - days * 60 * 60 * 24) / (60 * 60));
    let minutes = Math.floor((countdown - (days * 60 * 60 * 24 + h * 60 * 60)) / 60);
    let secondes = Math.floor(countdown - (days * 60 * 60 * 24 + h * 60 * 60 + minutes * 60));
    if (days.toString().length === 1) days = "0" + days;
    if (h.toString().length === 1) h = "0" + h;
    if (minutes.toString().length === 1) minutes = "0" + minutes;
    if (secondes.toString().length === 1) secondes = "0" + secondes;

    let countdownString = minutes + ":" + secondes;
    if (h > "00") {
      countdownString = h + ":" + countdownString;
    }
    if (days > "00") {
      countdownString = days + ":" + countdownString;
    }
    this.setState({ countdownString: countdownString });
  };

  render() {
    const auction = this.props.auction;
    let dateEnding = 0;
    let dateEnded = 0;
    let noTitle = false;
    if (this.props.noTitle === true) noTitle = true;
    if (this.props.auction) {
      dateEnding = new Date(this.props.auction.EndingAt);
      dateEnded = new Date(this.props.auction.EndingDate);
    }
    let title = "Bundle #" + auction.AuctionId.slice(0, 5);
    if (this.props.bundle !== true) {
      title = this.props.player.DisplayName;
    }
    return (
      <Box>
        {auction.Status === "ONGOING" ? (
          <Box>
            {!noTitle && <UpdateTitle title={title} countdownString={this.state.countdownString} />}
            {this.props.bundle !== true && (
              <Box margin={{ vertical: "medium" }}>
                <Text weight={600} size={"large"}>
                  {auction.CurrentPrice + " Ξ"} - <Countdown date={dateEnding} daysInHours={true} />
                </Text>
              </Box>
            )}
          </Box>
        ) : (
          <Text margin={{ top: "small" }}>This auction has ended on {dateEnded.toLocaleString()}</Text>
        )}
      </Box>
    );
  }
}

export default withUser(AuctionDetails);
