import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import PrivateOfferSide from "./privateOfferSide";
import SDLoading from "../util/SDLoading";
import Countdown from "react-countdown-now";
import { ReactTooltip } from "../util/tooltip.js";
import LineupBuilderBanner from "../../img/LineupBuilderBanner.png";
import format from "date-fns/format";
import { Button } from "../util/button";
import ListViewPicker from "../util/listViewPicker";

function PrivateOfferPage(props) {
  const [listView, setListView] = useState(false);

  const info = props.offer;
  let manager_sending = info.manager_sending;
  let manager_receiving = info.manager_receiving;

  useEffect(() => {
    if (
      (info.players_sent !== null && info.players_sent.length > 3) ||
      (info.players_received !== null && info.players_received.length) > 3
    ) {
      setListView(true);
    }
  }, [info]);

  return (
    <div className={"pb-8"}>
      <div className={"space-y-2"}>
        <div>
          <div
            className={"w-full lg:h-48 h-32 relative opacity-100"}
            style={{
              backgroundImage: "url('" + LineupBuilderBanner + "')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className={"z-40 self-center flex mx-16 lg:h-48 h-32 align-middle flex-row justify-between"}>
              <p className={"self-center text-5xl font-headers font-semibold text-left text-white z-10"}>Offer overview</p>
              <div className={"self-center"}>
                <a href={"/tradeBuilder?offerId=" + info.offer.OfferId}>
                  <Button label={"Open in builder"} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"mx-8 xl:w-11/12 xl:mx-auto 2xl:mx-16 3xl:mx-16 4xl:w-10/12 4xl:mx-auto mt-4 space-y-4"}>
        <div className={"flex flex-row justify-end"}>
          <ListViewPicker mode={listView} change={(c) => setListView(c)} />
        </div>
        <div>
          <div className={"flex flex-row space-x-4"}>
            <div className={"flex flex-row w-4/12 self-center space-x-4"}>
              <div className={"w-16 h-16 bg-white rounded-lg"}>
                <img className={"w-full h-full object-contain"} src={manager_sending.PictureUrl} />
              </div>
              <div className={"self-center"}>
                <p className={"text-2xl font-headers text-brand font-semibold hover:font-bold"}>
                  <a href={"/manager/" + manager_sending.Slug}>{manager_sending.Nickname}</a>
                </p>
              </div>
            </div>

            <div className={"flex flex-row w-4/12 justify-center self-center"}>
              <div className={"rounded-lg bg-white p-4 flex flex-col items-center space-y-2 self-center"}>
                {new Date(info.offer.CreationDate).getTime() > 1000 && (
                  <p className={"text-sm font-semibold"}>Offer sent on {format(new Date(info.offer.CreationDate), "LLL do, yyyy")}</p>
                )}
                {info.offer.Status === "ONGOING" && (
                  <p className={"text-sm font-semibold text-brand"}>
                    Expires in <Countdown date={new Date(info.offer.EndingAt)} daysInHours={true} />
                  </p>
                )}
                {info.offer.Status === "REJECTED" && <p className={"text-sm font-semibold text-brand"}>This offer has been rejected.</p>}
                {info.offer.Status === "ACCEPTED" && (
                  <p className={"text-sm font-semibold text-brand"}>
                    This offer has been accepted on {format(new Date(info.offer.AcceptationDate), "LLL do, yyyy")}.
                  </p>
                )}
              </div>
            </div>
            <div className={"flex flex-row w-4/12 justify-end self-center space-x-4"}>
              <div className={"self-center"}>
                <p className={"text-2xl font-headers text-brand font-semibold hover:font-bold"}>
                  <a href={"/manager/" + manager_receiving.Slug}>{manager_receiving.Nickname}</a>
                </p>
              </div>
              <div className={"w-16 h-16 bg-white rounded-lg"}>
                <img className={"w-full h-full object-contain "} src={manager_receiving.PictureUrl} />
              </div>
            </div>
          </div>
        </div>

        <div>
          {info.players_sent !== undefined ? (
            <div className={"flex flex-row space-x-8"}>
              <div className={"w-6/12 flex flex-row justify-start"}>
                <PrivateOfferSide
                  left={true}
                  offer={info.offer}
                  manager={info.manager_sending}
                  ether={info.offer.SendAmountInWei}
                  cards={info.players_sent}
                  teamMap={info.team_map}
                  listView={listView}
                  totalValue={info.sent_value}
                />
              </div>
              <div className={"w-6/12 flex flex-row justify-end"}>
                <PrivateOfferSide
                  manager={info.manager_receiving}
                  offer={info.offer}
                  ether={info.offer.ReceiveAmountInWei}
                  teamMap={info.team_map}
                  cards={info.players_received}
                  listView={listView}
                  totalValue={info.received_value}
                />
              </div>
            </div>
          ) : (
            <div>
              <SDLoading />
            </div>
          )}
        </div>
      </div>

      <ReactTooltip />
    </div>
  );
}

export default withUser(PrivateOfferPage);
