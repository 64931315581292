import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import RotowireCredit from "../util/rotowireCredit";
import TeamNewsBox from "../teams/teamNewsBox";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import BoxWithToggle from "../util/boxWithToggle";

function ManagerNews(props) {
  const [news, setNews] = useState([]);
  const [displayedNews, setDisplayedNews] = useState([]);
  const [noCommon, setNoCommon] = useState(props.user.hideCommonCards);

  const getNews = (nc) => {
    if (nc === undefined) nc = noCommon;
    props
      .fetch("/apiv2/manager/news?manager=" + props.manager.Slug + "&noCommon=" + nc)
      .then((response) => response.json())
      .then((res) => {
        if (res !== null) {
          setNews(res);
          setDisplayedNews(res.slice(0, 6));
        }
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (props.manager && props.manager.Slug !== "") {
      getNews();
    }
  }, [props.manager]);

  return (
    <div className={"space-y-4 pb-8"}>
      <div className={"flex flex-row justify-center"}>
        <div className={"py-1.5 bg-white flex flex-row justify-center rounded-lg"}>
          <BoxWithToggle
            setActive={() => {
              getNews(!noCommon);
              setNoCommon(!noCommon);
            }}
            isActive={noCommon}
            label={"Hide common cards"}
          />
        </div>
      </div>

      <div className={"flex flex-row justify-between"}>
        <p className={"text-xl text-brand font-semibold"}>Latest news</p>
        <RotowireCredit credit={"Provided by"} />
      </div>
      <InfiniteScroll
        next={() => setDisplayedNews(news.slice(0, displayedNews.length + 6))}
        hasMore={displayedNews.length < news.length}
        loader={<SDLoading />}
        dataLength={displayedNews.length}
      >
        <div className={"grid grid-cols-2 gap-8 flex flex-col"}>
          {displayedNews.map((n) => {
            return <TeamNewsBox news={n} />;
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default withUser(ManagerNews);
