import React from "react";
import { withUser } from "../../userContext";
import WatchlistManagersGWPerformance from "../watchlists/watchlistManagersGWPerformance";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";

const SO5ManagersPerformance = (props) => {
  const { gameweek, gwNumber, sport } = props;

  return (
    <div className={GLOBAL_MARGIN_NO_BG_NO_PAD + " space-y-4"}>
      <WatchlistManagersGWPerformance
        gameweek={gameweek}
        gwNumber={gwNumber}
        sport={sport}
        withManagerSearch
        withDivisionPicker
        withWatchlistsPicker
        withManagerAdder
      />
    </div>
  );
};

export default withUser(SO5ManagersPerformance);
