import React from "react";
import NewCardLink from "./newCardLink";
import ordinal_suffix_of from "../util/ordinalNumber";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { formatPrice } from "../util/formatMoney";

function ProfitabilityLine(props) {
  const reward = props.reward;
  const reward_share = props.reward.reward_share;
  const unit = props.unit;
  const sport = props.sport;

  let rewardEarned = 0;
  let rewardEarnedWeighted = 0;
  let rewardValueThen = 0;
  let rewardValueThenWeighted = 0;
  let rewardValueNow = 0;
  let rewardValueNowWeighted = 0;

  if (unit === "eth") {
    rewardEarned = reward.eth_won;
    rewardEarnedWeighted = reward.eth_won * reward_share;
    rewardValueThen = reward.total_value_at_time;
    rewardValueThenWeighted = reward.total_value_at_time * reward_share;
    rewardValueNow = reward.total_value_now;
    rewardValueNowWeighted = reward.total_value_now * reward_share;
  } else {
    rewardEarned += reward.fiat_won[unit];
    rewardEarnedWeighted += reward.fiat_won[unit] * reward_share;
    rewardValueThen += reward.total_value_at_time_fiat[unit];
    rewardValueThenWeighted += reward.total_value_at_time_fiat[unit] * reward_share;
    rewardValueNow += reward.total_value_now_fiat[unit];
    rewardValueNowWeighted += reward.total_value_now_fiat[unit] * reward_share;
  }

  return (
    <li className={"p-4 hover:bg-gray-50 bg-focus grid grid-cols-8 justify-center space-x-2"}>
      <div className={"flex flex-row space-x-3 col-span-2"}>
        <p className={"text-base font-semibold self-center flex"}>GW #{reward.gw_number}</p>
        <div className={"self-center space-y-1"}>
          <p className={"flex text-sm"}>{reward.display_name}</p>
          <p className={"flex text-sm"}>
            {reward.nickname} ({ordinal_suffix_of(reward.lineup_rank)})
          </p>
        </div>
      </div>
      <p className={"text-sm flex justify-center self-center"}>
        <span className={"text-base font-semibold"}>{(reward.reward_share * 100).toFixed(0)} %</span>
      </p>
      <div className={"col-span-2 self-center"}>
        {reward.card_won !== null &&
          reward.card_won.map((c, i) => {
            return (
              <p className={"text-sm self-center"}>
                <a href={"/card/" + c.TokenId} target={"_blank"} rel="noreferrer">
                  <span className={"hover:font-semibold"}>{reward.player_won[i].DisplayName}</span> <NewCardLink card={c} sport={sport} />
                </a>
              </p>
            );
          })}

        {reward.card_won === null && <p className={"flex text-sm col-span-2 self-center"}>No cards won</p>}
      </div>

      <div className={"space-y-0.5 self-center"}>
        <p className={"text-base flex justify-center self-center font-semibold"}>{formatPrice(rewardEarned, unit)}</p>
        <p className={"text-sm flex justify-center self-center"}>{formatPrice(rewardEarnedWeighted, unit)}</p>
      </div>
      {t1OrAbove(props.tier) && (
        <div className={"space-y-0.5 self-center"}>
          <p className={"text-base flex justify-center self-center font-semibold"}>{formatPrice(rewardValueThen, unit)}</p>
          <p className={"text-sm flex justify-center self-center"}>{formatPrice(rewardValueThenWeighted, unit)}</p>
        </div>
      )}
      {t1OrAbove(props.tier) && (
        <div className={"space-y-0.5 self-center"}>
          <p className={"text-base flex justify-center self-center font-semibold"}>{formatPrice(rewardValueNow, unit)}</p>
          <p className={"text-sm flex justify-center self-center"}>{formatPrice(rewardValueNowWeighted, unit)}</p>
        </div>
      )}
    </li>
  );
}

export default ProfitabilityLine;
