import React from "react";
import RotowireLogo from "../../img/rotowire-logo.png";

function RotowireCredit(props) {
  const credit = props.credit;
  return (
    <div className={"self-center flex flex-row space-x-1"}>
      <p className={"text-textGrey3 font-semibold text-xs self-center"}>{credit}</p>
      <img className={"h-6"} src={RotowireLogo} />
    </div>
  );
}

export default RotowireCredit;
