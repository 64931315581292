import { animated, useSpring } from "react-spring";
import React, { useRef, useState } from "react";
import "./cardShowcase.scss";

function CardShowcase(props) {
  const card = props.card;
  const width = props.width;
  const height = props.height;

  const button = document.querySelector(".card");

  if (button !== null) {
    button.addEventListener("mousemove", (e) => {
      const { x, y } = button.getBoundingClientRect();
      console.log(x, y);
      button.style.setProperty("--x", e.clientX - x);
      button.style.setProperty("--y", e.clientY - y);
    });
  }

  return (
    <Card ratio={0.619} src={card.PictureUrl}>
      <img src={card.PictureUrl} className={width + " " + height} />
    </Card>
  );
}

function Card({ children }) {
  // We add this ref to card element and use in onMouseMove event ...
  // ... to get element's offset and dimensions.
  const ref = useRef();

  // Keep track of whether card is hovered so we can increment ...
  // ... zIndex to ensure it shows up above other cards when animation causes overlap.
  const [isHovered, setHovered] = useState(false);

  const [animatedProps, setAnimatedProps] = useSpring(() => {
    return {
      // Array containing [rotateX, rotateY, and scale] values.
      // We store under a single key (xys) instead of separate keys ...
      // ... so that we can use animatedProps.xys.interpolate() to ...
      // ... easily generate the css transform value below.
      xys: [0, 0, 1],
      // Setup physics
      config: { mass: 1, tension: 400, friction: 40, precision: 0.00001 },
    };
  });

  let className = "card";
  if (isHovered) className = "card shiny card__shine";

  return (
    <animated.div
      ref={ref}
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseMove={({ clientX, clientY }) => {
        // Get mouse x position within card
        const x = clientX - (ref.current.offsetLeft - (window.scrollX || window.pageXOffset || document.body.scrollLeft));

        // Get mouse y position within card
        const y = clientY - (ref.current.offsetTop - (window.scrollY || window.pageYOffset || document.body.scrollTop));

        // Set animated values based on mouse position and card dimensions
        const dampen = 18; // Lower the number the less rotation
        const xys = [
          +(y - ref.current.clientHeight / 2) / dampen, // rotateX
          -(x - ref.current.clientWidth / 2) / dampen, // rotateY
          1.2, // Scale
        ];

        // Update values to animate to
        setAnimatedProps({ xys: xys });
      }}
      onMouseLeave={() => {
        setHovered(false);
        // Set xys back to original
        setAnimatedProps({ xys: [0, 0, 1] });
      }}
      style={{
        // If hovered we want it to overlap other cards when it scales up
        zIndex: isHovered ? 2 : 1,
        // Interpolate function to handle css changes
        transform: animatedProps.xys.interpolate((x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`),
      }}
    >
      {children}
    </animated.div>
  );
}

export default CardShowcase;
