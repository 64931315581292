import React, { useEffect, useRef, useState } from "react";
import { Form } from "grommet";
import { FormSearch } from "grommet-icons";
import { withRouter } from "react-router-dom";
import { withUser } from "../../userContext";
import UnknownPlayer from "../../img/unknown_player2.png";
import { errorCatcher } from "../util/errors";

function ManagerSearchBar(props) {
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);

  const [displayResults, setDisplayResults] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isHoverResults, setIsHoverResults] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const [selectableIndex, setSelectableIndex] = useState(-1);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (!displayResults) {
      setSelectableIndex(-1);
    }
  }, [displayResults]);

  useEffect(() => {
    setSelectableIndex(-1);
  }, [value]);

  useEffect(() => {
    if (isFocus) {
      setDisplayResults(true);
    } else if (isHoverResults) {
      setDisplayResults(true);
    }
  }, [isFocus, isHoverResults]);

  const searchAutoComplete = (v) => {
    props
      .fetch("/search-api/search/autocomplete?query=" + v + "&type=user&type=player")
      .then((response) => response.json())
      .then((res) => {
        setResults(res);
      })
      .catch(errorCatcher());
  };

  const onKeyDownInputSearch = (e) => {
    const playerLength = results?.player?.length || 0;
    const userLength = results?.user?.length || 0;
    const teamLength = results?.team?.length || 0;
    const totalLength = playerLength + userLength + teamLength;
    if (displayResults) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          if (prev < 0) {
            return totalLength - 1;
          } else {
            return prev - 1;
          }
        });
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          if (prev >= totalLength - 1) {
            return -1;
          } else {
            return prev + 1;
          }
        });
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (results && selectableIndex >= 0 && selectableIndex < totalLength) {
          if (selectableIndex >= 0 && selectableIndex < playerLength) {
            onClickItem("player", results?.player[selectableIndex].item_id);
          } else if (selectableIndex < playerLength + userLength) {
            const index = selectableIndex - playerLength;
            onClickItem("manager", results?.user[index].item_id);
          } else {
            const index = selectableIndex - (playerLength + userLength);
            onClickItem("team", results?.team[index].item_id);
          }
        }
      } else if (e.key === "Escape") {
        e.preventDefault();
        setSelectableIndex(-1);
        inputRef.current.blur();
        setDisplayResults(false);
      }
    }
  };

  const onClickItem = (type, id) => {
    if (id) {
      setValue("");
      if (type === "player") {
        props.choosePlayer(id);
      } else if (type === "manager") {
        props.chooseManager(id);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  let roundSearch = "rounded";
  if ((results?.player?.length > 0 || results?.team?.length > 0) && value !== "") {
    roundSearch = "rounded-t";
  }
  let placeholderStyle = "block w-full pl-10 text-sm border-0 focus:ring-0 " + roundSearch;
  if (value !== "") {
    placeholderStyle = "block w-full pl-10 font-medium text-sm border-0 focus:ring-0 " + roundSearch;
  }

  return (
    <div className={"relative"}>
      <Form className={"z-0"}>
        <div className={"mt-1 flex " + roundSearch}>
          <div className="relative flex items-stretch grow h-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FormSearch className="h-5 w-5" aria-hidden="true" />
            </div>
            <input
              ref={inputRef}
              autoFocus
              type="text"
              onChange={(v) => {
                searchAutoComplete(v.target.value);
                setValue(v.target.value);
              }}
              onFocus={(e) => {
                e.stopPropagation();
                setIsFocus(true);
              }}
              onBlur={(e) => {
                e.stopPropagation();
                setIsFocus(false);
              }}
              onKeyDown={onKeyDownInputSearch}
              value={value}
              className={placeholderStyle}
              placeholder={`Search for users & players: YNWA, Zuraw_, JohnNellis, Joshua Kimmich, Kylian Mbappé...`}
            />
          </div>
        </div>
      </Form>
      <div className={"z-50 w-full absolute"}>
        {displayResults && value !== "" && (
          <div className={"rounded-b border-t-2 border-grey-e5 bg-white z-50 w-full"}>
            <div className={"space-y-2 px-2 py-2 z-50"}>
              {results?.player !== null && results?.player?.length > 0 && (
                <div className={"space-y-2"}>
                  <p className={"text-xs text-textGrey3 px-2"}>Players</p>
                  <div className={"space-y-1"}>
                    {results?.player?.map((p, i) => {
                      return (
                        <a key={i} href={undefined} onClick={() => props.choosePlayer(p.item_id)}>
                          <div
                            className={
                              "z-50 flex flex-row justify-between h-12 hover:bg-grey-f9 rounded cursor-pointer " +
                              (selectableIndex === i ? "bg-grey-f2" : "")
                            }
                          >
                            <div className={"flex flex-row space-x-4 self-center pl-2"}>
                              {p.Picture !== "" ? (
                                <div className={"w-10 h-10"}>
                                  <img className={"object-contain pt-1 w-full h-full rounded-full bg-grey-f2"} src={p.Picture} />
                                </div>
                              ) : (
                                <div className={"w-10 h-10"}>
                                  <img className={"object-contain pt-1 w-full h-full rounded-full bg-grey-f2"} src={UnknownPlayer} />
                                </div>
                              )}
                              <div className={"flex flex-col space-y-1 self-center justify-start text-ellipsis items-start"}>
                                <p className={"text-sm font-medium text-start text-ellipsis"}>{p.item_name}</p>
                                <p className={"md:hidden lg:block xl:hidden text-brand text-start text-xs font-semibold text-ellipsis"}>
                                  {p.TeamName !== "" ? p.TeamName : "No club"}
                                </p>
                              </div>
                            </div>
                            <div className={"flex pr-2 hidden md:flex lg:hidden xl:flex"}>
                              <p className={"text-brand text-xs font-semibold self-center"}>{p.TeamName !== "" ? p.TeamName : "No club"}</p>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
              {results?.user !== null && results?.user?.length > 0 && (
                <div className={"space-y-2"}>
                  <p className={"text-xs text-textGrey3 px-2"}>Managers</p>
                  <div className={"space-y-1"}>
                    {results?.user?.map((p, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => props.chooseManager(p.item_id)}
                          className={
                            "z-50 flex flex-row justify-between h-12 hover:bg-grey-f9 rounded cursor-pointer " +
                            (selectableIndex === i + (results?.player?.length || 0) ? "bg-grey-f2" : "")
                          }
                        >
                          <div className={"flex flex-row space-x-4 self-center pl-2"}>
                            {p.Picture !== "" ? (
                              <div className={"w-10 h-10"}>
                                <img className={"object-contain pt-1 w-full h-full rounded-full bg-grey-f2"} src={p.Picture} />
                              </div>
                            ) : (
                              <div className={"w-10 h-10"}>
                                <img className={"object-contain pt-1 w-full h-full rounded-full bg-grey-f2"} src={UnknownPlayer} />
                              </div>
                            )}
                            <div className={"flex flex-col space-y-1 self-center text-start"}>
                              <p className={"text-sm text-start text-start font-medium text-ellipsis"}>{p.item_name}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(withRouter(ManagerSearchBar));
