import React, { useState } from "react";
import { Box, Form, Heading, Text, TextInput } from "grommet";
import { withRouter } from "react-router-dom";
import GreenButton from "../util/greenButton";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function ResetPassword(props) {
  const [newPwd1, setNewPwd1] = useState("");
  const [newPwd2, setNewPwd2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(props.match.params.otp);
    if (newPwd1 === newPwd2) {
      props
        .fetch("/apiv2/user/resetPassword", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: props.match.params.otp,
            new_password: newPwd1,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            props.history.push("/login");
          } else {
            setErrorMessage("Password reset failed: " + res.error);
          }
        })
        .catch(errorCatcher());
    } else {
      setErrorMessage("The two new passwords don't match.");
    }
  };
  return (
    <Box>
      <Box align={"center"}>
        <Heading level={"2"} color={"brand"}>
          Reset your password
        </Heading>
        <Box margin={{ bottom: "medium" }}>
          <Text>Please use a strong (12+ characters with uppercase, lowercase and special characters) and unique password.</Text>
        </Box>
        <Form onSubmit={handleSubmit}>
          <TextInput type="password" name="newPwd1" placeholder="Your new password" onChange={(e) => setNewPwd1(e.target.value)} />
          <br />
          <TextInput type="password" name="newPwd2" placeholder="Your new password (again)" onChange={(e) => setNewPwd2(e.target.value)} />
          <br />
          <Box align={"center"} margin={"auto"}>
            <GreenButton margin={"auto"} type="submit" primary label="Reset password" onSubmit={handleSubmit} />
          </Box>
        </Form>
        <Box margin={"small"}>
          <Text>{errorMessage}</Text>
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(withUser(ResetPassword));
