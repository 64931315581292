import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import LoginBanner from "../../img/login-banner.png";
import SDLoading from "../util/SDLoading";
import { withRouter } from "react-router-dom";
import { errorCatcher } from "../util/errors";
import { Button } from "../util/button";
import { affilatedSorareLink } from "../util/url";

function DeprecatedAccount(props) {
  const [loading, setLoading] = useState(false);
  const [sorareLink, setSorareLink] = useState("");

  useEffect(() => {
    if (props.isConnectedWithSorare()) {
      props.history.push("/");
    } else {
      getLoginURL();
    }
  }, []);

  const getLoginURL = () => {
    setLoading(true);
    props
      .fetch("/apiv2/user/sorare-login-url")
      .then((response) => response.json())
      .then((res) => {
        setSorareLink(res);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  if (loading || !sorareLink) {
    return <SDLoading />;
  }

  return (
    <div className={"flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0 min-h-screen"}>
      <div className={"md:w-6/12"}>
        <div className={"space-y-3 text-left w-10/12 mx-auto mt-8 md:mt-16 self-center"}>
          <div className={"text-left mx-auto text-headers font-bold"}>
            <h3 className={"text-3xl font-bold font-headers mt-2 mb-1"}>Welcome {props.user?.username || ""}</h3>
          </div>
          <div>
            <p className={"text-md my-1"}>
              Your account is not supported anymore because it is not linked with your Sorare account. Please login with Sorare to enjoy all
              SorareData features
            </p>
          </div>
          <div className={"w-10/12"} style={{ maxWidth: "30em" }}>
            <div className={"flex flex-row pt-4"}>
              <a href={sorareLink}>
                <Button label="Connect with Sorare" />
              </a>
            </div>
          </div>
          <div className={"flex flex-col space-y-4 text-brand-black text-md pt-16"}>
            <p>
              No Sorare account?{" "}
              <a className={"cursor-pointer text-brand-text font-semibold"} href={affilatedSorareLink} target={"_blank"} rel="noreferrer">
                Create a Sorare account.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div
        className={"h-screen md:h-auto md:w-6/12"}
        style={{
          backgroundImage: "url('" + LoginBanner + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
}

export default withUser(withRouter(DeprecatedAccount));
