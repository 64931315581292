import React from "react";
import { StoreButtons } from "./stores";

function NotOptimized() {
  return (
    <div className={"lg:hidden h-screen items-center mx-auto space-y-4"}>
      <p className={"text-base text-center w-11/12 mx-auto font-medium"}>
        The gameweek center & SO5 results is not optimized for mobile browsing. Please use our mobile application, available on Android and
        iOS.
      </p>
      <div className={"space-x-2 flex flex-row align-middle items-center mx-4"}>
        <StoreButtons />
      </div>
    </div>
  );
}

export default NotOptimized;
