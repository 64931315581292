import React, { useEffect } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import BasketballAvatarLineupCard from "./basketballAvatarLineupCard";

const BasketballAvatarLineup = (props) => {
  const { stats, games, lineup } = props;
  useEffect(() => ReactTooltip.rebuild(), []);
  let players = props.players;

  return players?.length > 0 ? (
    <div className={"mx-auto text-center space-y-1"} id={lineup.DivisionId}>
      <div className={"grid grid-cols-5 gap-0.5 inline-grid"} style={{ minWidth: "20em" }}>
        {players.map((p, i) => {
          let SO5Score = "DNP";
          if (stats[p.player.PlayerId] !== undefined && stats[p.player.PlayerId].playerId !== "") {
            SO5Score = parseFloat(stats[p.player.PlayerId].score).toFixed(0);
          }
          let power = parseFloat(p.lineupPlayer.power);
          let game = games[p.player.TeamId];
          return (
            <BasketballAvatarLineupCard
              key={i}
              game={game}
              player={p.player}
              power={power}
              SO5Score={SO5Score}
              stats={stats[p.player.PlayerId]}
              card={p.card}
              captain={false}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <span className={"text-sm text-on-surface-variant"}>No lineup for this tournament</span>
  );
};

export default BasketballAvatarLineup;
