import React, { useCallback, useEffect, useRef, useState } from "react";
import { withUser } from "../../userContext";
import PlayerAvatar from "../util/playerAvatar";
import ScoreAverage from "./scoreAverage";
import PlayerAvailability from "../players/playerAvailability";
import GameInLine from "./gameInLine";
import { OppScoreWithDetails } from "./oppScore";
import LUBTableFormExample from "../../img/lub-legend-form-table.png";
import LockerRound from "../util/orangeOpenLockerBrandBg";
import { ReactComponent as CheckIcon } from "../../img/icons-check.svg";
import { ReactComponent as SquareWarningIcon } from "../../img/icons-warning-losange-no-color.svg";
import { getMatchupOddsInfo, MatchupIndicator, MatchupOdds } from "../util/matchupScore";
import Last10Line from "./last10line";
import { CommunityPredictionIndicator, PlayerCommunityProjectionWithHover, predicitionsInfo } from "../util/communityPrediction";
import { ReactTooltip } from "../util/tooltip.js";
import { isFree, t1OrAbove, t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import SorareScoreAverage from "./sorareScoreAverage";
import UnknownClub from "../../img/unknown_club.png";
import HomeIcon from "../../img/homeIcon.svg";
import AwayIcon from "../../img/awayIcon.svg";
import HomeIconBrand from "../../img/homeIconBrand.svg";
import AwayIconBrand from "../../img/awayIconBrand.svg";
import { ReactComponent as IconCarretFilled } from "../../img/icons-carret-filled.svg";
import { ReactComponent as PersonAdd } from "@material-design-icons/svg/filled/person_add_alt_1.svg";
import { ReactComponent as AddIcon } from "@material-design-icons/svg/filled/add_circle.svg";
import { DecisiveActionsScore } from "../util/decisiveScore";
import { AllAroundScore } from "../util/allAroundScore";
import { MedianScore } from "../util/medianScore";
import { CeilingScore, FloorScore } from "../util/boundScore";
import CardIcon from "../util/cardIcon";
import { abbrv_football_positions, so5_positions } from "../util/positions";
import Spinner from "../loader/spinner";
import { PickScoreDetails, rankFromPickScoreGroup } from "../util/pickScore";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { PlayedStats } from "../util/playedStats";
import { detailedPositionAbreviations, detailedPositionFullName } from "../util/detailedFootballPositions";
import { Button } from "../util/button";
import { endOnboarding, getFirstEnabledOnboardingForPage, nextOnboarding, Onboarding } from "../onboarding/onboardingContext";
import PlayCircle from "../../img/play_circle.svg";
import { sortData, withSortableProps } from "../util/sorting";
import { NoCardResultsImage } from "../util/noCardResultsImage";
import { getThemeColor, useTheme } from "../../themeContext";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import { LineupBuilderPlayerTiles } from "./footballLineupBuilderTiles";
import { SearchInput } from "../util/input";
import { searchText } from "../util/strings";
import { sorareFootball } from "../util/sports";

export const LineupBuilderList = withUser((props) => {
  const { loading } = props;
  const [playerSearchName, setPlayerSearchName] = useState("");
  const [pickedView, setPickedView] = useState("classic");
  const [pickedAverage, setPickedAverage] = useState("l15");
  const mdQueries = useTailwindMediaQueries();

  const onChangeSearchName = useCallback((e) => {
    setPlayerSearchName(e.target.value);
  }, []);

  const onChangeView = useCallback((view) => {
    setPickedView(view);
  }, []);

  const onChangeAverage = useCallback((avg) => {
    setPickedAverage(avg);
  }, []);

  return (
    <div>
      <div className={"flex flex-col sm:flex-row gap-2 mb-2 justify-between"}>
        <TableViewAndFormPicker
          {...{
            pickedView,
            onChangeView,
            loading,
            pickedAverage,
            onChangeAverage,
          }}
        />
        <div className={"flex h-10 justify-end"}>
          <SearchInput
            onChange={onChangeSearchName}
            onReset={() => setPlayerSearchName("")}
            search={playerSearchName}
            renderOptions={{ adapting: true, blurWidth: "w-full sm:w-52" }}
            placeholder={"Search player or team"}
          />
        </div>
      </div>
      {mdQueries["xl"] ? (
        <div className={"overflow-hidden rounded-lg"}>
          <LineupBuilderTable
            {...props}
            pickedView={pickedView}
            pickedAverage={pickedAverage}
            playerSearchName={playerSearchName}
            maxPlayers={20}
          />
        </div>
      ) : (
        <div className={"block overflow-hidden "}>
          <LineupBuilderPlayerTiles
            {...props}
            pickedView={pickedView}
            pickedAverage={pickedAverage}
            playerSearchName={playerSearchName}
            containerOffset={"100px"}
            columns={"grid-cols-1"}
            maxPlayers={20}
          />
        </div>
      )}
    </div>
  );
});

export const TableViewAndFormPicker = (props) => {
  const { loading, pickedView, pickedAverage, onChangeView, onChangeAverage, adapting } = props;
  const direction = props.direction || "flex-col sm:flex-row";
  return (
    <div className={`relative flex ${direction} ${adapting ? "w-full" : ""} gap-2`}>
      <div className={"h-10"}>
        <TableViewPicker adapting={adapting} value={pickedView} onChange={onChangeView} />
      </div>
      {pickedView === "form" && (
        <div className={"h-10"}>
          <TableViewAveragePicker adapting={adapting} value={pickedAverage} onChange={onChangeAverage} />
        </div>
      )}
      {loading &&
        (adapting ? (
          <div className={"absolute transform translate-x-full -right-2 top-1/2 -translate-y-1/2"}>
            <Spinner />
          </div>
        ) : (
          <Spinner />
        ))}
    </div>
  );
};

export const getFilteredAndEnhancedPlayers = (
  players,
  currentLineupId,
  isCardInCurrentLineup,
  divRules,
  powerAdjustedAverages,
  preferences,
  so5ScoresDetailed,
  pickedAverage,
  odds,
  pickScoreReveal,
  lineupPoints,
  playerSearchName,
  showPickedPlayers,
  selectedEntry,
  showAffordablePlayersOnly,
  sort,
) => {
  const enhanced =
    enhancePlayersWithComputedData(
      players,
      isCardInCurrentLineup,
      powerAdjustedAverages,
      preferences,
      so5ScoresDetailed,
      pickedAverage,
      odds,
      pickScoreReveal,
    ) || [];
  const capLimit = divRules?.cappedLimit !== undefined ? divRules.cappedLimit : 0;
  const remainingPoints = capLimit - lineupPoints;
  const filteredAndEnhanced = enhanced.filter((e) => {
    let matchName = true;
    if (playerSearchName !== undefined && playerSearchName !== "") {
      matchName =
        searchText(playerSearchName, e.player.DisplayName) || (e.player_team && searchText(playerSearchName, e.player_team.DisplayName));
    }
    let matchPicked = true;
    const isPickedInSavedLineupButNotInCurrentlyEditedLineup =
      e.pickedStatus.picked && e.pickedStatus.lineupId === currentLineupId && !e.computed.isInCurrentLineup.value;
    if (!showPickedPlayers && e.pickedStatus.picked && !isPickedInSavedLineupButNotInCurrentlyEditedLineup) {
      // show picked = disabled -> we should remove elem
      matchPicked = false;
    }
    let matchAffordable = true;
    const additionalPointsWhenRemovingExistingPlayer =
      selectedEntry?.cost !== undefined && selectedEntry?.cost !== null ? selectedEntry.cost : 0;
    if (showAffordablePlayersOnly && divRules?.isCapped && e.cost > remainingPoints + additionalPointsWhenRemovingExistingPlayer) {
      matchAffordable = false;
    }
    return matchName && matchPicked && matchAffordable;
  });
  const filteredEnhancedAndSorted = sortData(sort, filteredAndEnhanced);
  return filteredEnhancedAndSorted;
};

export const LineupBuilderTable = withUser((props) => {
  const {
    so5ScoresDetailed,
    oppScoresDetailed,
    pickScoreReveal,
    onPickScoreReveal,
    revealing,
    players,
    odds,
    divRules,
    openLegend,
    lineupPoints,
    selectedEntry,
    containerHeight,
    containerOffset,
    preferences,
    noHeader,
    noActionLabel,
    maxPlayers,
    onDiscardRevert,
    loading,
    currentLineupId,
  } = props;
  const { pickedAverage, playerSearchName, pickedView } = props;
  const { theme } = useTheme();
  const userT1OrAbove = t1OrAbove(props.user.tier);
  const placeholderAmount = props.placeholderAmount || 3;
  const powerAdjustedAverages = preferences.powerAdjustedScore && divRules?.withCardPower;
  const showPickedPlayers = preferences.showPickedPlayers;
  const showAffordablePlayersOnly = preferences.showAffordablePlayersOnly;
  const alwaysShowCost = userT1OrAbove ? preferences.showCost : false;
  const gameAdjusted = preferences.competitionAdjustedScore || preferences.teamAdjustedScore || preferences.homeAwayAdjustedScore;
  const anyAdjusted = gameAdjusted || powerAdjustedAverages;
  const isCapped = divRules?.isCapped !== undefined ? divRules.isCapped : false;
  const hasCostRestriction = isCapped || divRules?.atLeastCardsWithAverage !== undefined || divRules?.atMostCardsWithAverage !== undefined;
  const seasonLimitations = divRules?.seasonLimits;
  const seasonBonusLimitations = divRules?.seasonBonusLimits;
  const seasonHighlightEnabled =
    preferences.highlightNewSeason &&
    ((seasonLimitations !== null && seasonLimitations !== undefined) ||
      (seasonBonusLimitations !== null && seasonBonusLimitations !== undefined));
  const [onboarding, setOnboarding] = useState(getFirstEnabledOnboardingForPage("footballLUB"));
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [sort, setSort] = useState({
    field: "",
    order: "desc",
    origin: "automatic",
  });
  const onClickSort = useCallback(
    (field) => {
      const order = sort.field === field ? (sort.order === "asc" ? "desc" : "asc") : "desc";
      const newSort = { field, order, origin: "manual" };
      setSort(newSort);
    },
    [sort],
  );

  const onDisplayedPlayerChange = useCallback(() => {
    setDisplayedPlayers(filteredPlayers.slice(0, displayedPlayers.length + 20));
    ReactTooltip.rebuild();
  }, [filteredPlayers, displayedPlayers]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (elem) => {
      if (elem) {
        const { scrollHeight, scrollTop, clientHeight } = elem;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        const hasMore = displayedPlayers.length < filteredPlayers.length;
        if (scrollHeight - scrollTop - clientHeight < 300 && hasMore) {
          onDisplayedPlayerChange();
        }
      }
    },
    [displayedPlayers, filteredPlayers],
  );
  const tableContainerRef = useRef(null);

  useEffect(() => {
    setOnboarding(getFirstEnabledOnboardingForPage("footballLUB"));
  }, [props]);

  useEffect(() => {
    if (props.user?.tier && divRules) {
      // required props are ready
      if (sort.field === "" || sort.origin === "automatic") {
        // Not sorted manually by user yet
        // sort by l15 if rookie or uncapped div of Pro
        // sort by pick score otherwise
        if (props.defaultSort !== undefined) {
          setSort(props.defaultSort);
        } else {
          setSort({
            field:
              t2OrAbove(props.user?.tier) || (divRules?.isCapped && t1OrAbove(props.user?.tier))
                ? "computed.pickScoreReverse"
                : "computed.l15",
            order: "desc",
            origin: "automatic",
          });
        }
      }
    }
  }, [props.user, divRules]);

  useEffect(() => {
    const filteredEnhancedAndSorted = getFilteredAndEnhancedPlayers(
      players,
      currentLineupId,
      props.isCardInCurrentLineup,
      props.divRules,
      powerAdjustedAverages,
      preferences,
      so5ScoresDetailed,
      pickedAverage,
      odds,
      pickScoreReveal,
      lineupPoints,
      playerSearchName,
      showPickedPlayers,
      selectedEntry,
      showAffordablePlayersOnly,
      sort,
    );
    setFilteredPlayers(filteredEnhancedAndSorted);
    setDisplayedPlayers(maxPlayers ? filteredEnhancedAndSorted.slice(0, maxPlayers) : filteredEnhancedAndSorted.slice());
  }, [sort, players, playerSearchName, props.pickedCards, props.preferences, props.divRules, lineupPoints, selectedEntry, maxPlayers]);

  const [onboarding1] = onboarding.steps?.map((e) => e.target.replace(".", "")) || [""];
  return (
    <>
      <div
        className={"overflow-y-auto overflow-x-auto"}
        style={
          containerHeight !== undefined && containerHeight !== 0 ? { maxHeight: `calc(${containerHeight}px - ${containerOffset}` } : {}
        }
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target)}
      >
        <table className={"w-full"} style={{ height: "1px" }}>
          {(noHeader === undefined || noHeader === false) && (
            <thead className={"text-on-surface-variant text-xs font-semibold sticky top-0 z-[19]"}>
              <tr className={"py-2"}>
                <TableHeaderLine first label={"Add to lineup"} width={"w-1/24"} />
                <TableHeaderLine
                  {...withSortableProps("player.DisplayName", onClickSort, sort)}
                  adjusted={anyAdjusted}
                  adjustedDetails={{
                    competitionAdjusted: preferences.competitionAdjustedScore,
                    teamAdjusted: preferences.teamAdjustedScore,
                    homeAwayAdjusted: preferences.homeAwayAdjustedScore,
                    powerAdjusted: powerAdjustedAverages,
                  }}
                  label={"Player"}
                  width={"w-1/24"}
                />
                {(hasCostRestriction || alwaysShowCost) && (
                  <TableHeaderLine
                    {...withSortableProps("cost", onClickSort, sort)}
                    label={"Cost"}
                    width={"w-1/24"}
                    tip={"Fantasy point cost for Capped divisions, usually the Sorare L15"}
                  />
                )}
                {pickedView === "classic" && (
                  <TableHeaderLine
                    {...withSortableProps("computed.pickScoreReverse", onClickSort, sort)}
                    label={"Pick score"}
                    tip={
                      "We give a relative grade to each card based on the player's performance, the card details and the upcoming game setup (competition/home or away)"
                    }
                    width={"w-1/18"}
                    onboarding={hasCostRestriction ? "pick-score-same-all-divs" : ""}
                  />
                )}
                <TableHeaderLine {...withSortableProps("computed.isAway", onClickSort, sort)} label={"Home Away"} width={"w-1/24"} />
                {pickedView === "classic" && (
                  <>
                    <TableHeaderLine
                      {...withSortableProps("computed.l5", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={"L5"}
                      tip={"Average of last 5 scores"}
                      width={"w-1/24"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.l15", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={"L15"}
                      tip={"Average of last 15 scores"}
                      width={"w-1/24"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.pctWin", onClickSort, sort)}
                      label={"Win %"}
                      tip={"Odds of winning"}
                      width={"w-1/12"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.matchupOddsPct", onClickSort, sort)}
                      label={"Odds"}
                      tip={
                        "Probability of the most important key feature regarding the position, clean sheet, score 3+ goals, conceding 3+ goals"
                      }
                      width={"w-1/18"}
                    />
                    <TableHeaderLine
                      last
                      {...withSortableProps("opp_score_adjusted", onClickSort, sort)}
                      adjusted={gameAdjusted}
                      label={"Opp. score"}
                      tip={
                        "Average of points allowed by a team's opponent over the last 15 games in the upcoming game setup (competition/home or away)"
                      }
                      width={"w-1/18"}
                    />
                  </>
                )}
                {pickedView === "advanced" && (
                  <>
                    <TableHeaderLine
                      {...withSortableProps("computed.communityProjections", onClickSort, sort)}
                      label={"Comm. proj."}
                      tip={"Community projections, whether people think the player will play or not"}
                      width={"w-1/24"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("pick_stats.pick_pct", onClickSort, sort)}
                      label={"% Pick"}
                      tip={"Average % of cards of this player picked when the player plays"}
                      width={"w-1/24"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("division_stats.rate", onClickSort, sort)}
                      label={"% Div"}
                      tip={"Average % of picked cards of this player lined up in this division"}
                      width={"w-1/24"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("opp_score_adjusted", onClickSort, sort)}
                      adjusted={gameAdjusted}
                      label={"Opp. score"}
                      tip={
                        "Average of points allowed by a team's opponent over the last 15 games in the upcoming game setup (competition/home or away)"
                      }
                      width={"w-1/18"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("matchup_score", onClickSort, sort)}
                      adjusted={gameAdjusted}
                      label={"Matchup indicator"}
                      width={"w-1/18"}
                    />
                  </>
                )}
                {pickedView === "form" && (
                  <>
                    <TableHeaderLine
                      {...withSortableProps("computed.played", onClickSort, sort)}
                      adjusted={gameAdjusted}
                      label={"Played"}
                      tip={"Repartition of player status in last games"}
                      width={"w-1/12"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.lPicked", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={pickedAverage.toUpperCase()}
                      tip={"Average of last scores"}
                      width={"w-1/24"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.decScore", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={"DS avg."}
                      tip={"Decisive score average"}
                      width={"w-1/24"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.aaScore", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={"AA avg."}
                      tip={"All-Around average"}
                      width={"w-1/18"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.medianScore", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={"Median"}
                      tip={"Median of last scores, i.e. the score from which 50% of the scores are above and 50% are below"}
                      width={"w-1/18"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.floorScoreOrder", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={"Floor"}
                      tip={"Floor of last scores, i.e. the worst score the player had in the last games"}
                      width={"w-1/18"}
                    />
                    <TableHeaderLine
                      {...withSortableProps("computed.ceilingScoreOrder", onClickSort, sort)}
                      adjusted={anyAdjusted}
                      label={"Ceiling"}
                      tip={"Ceiling of last scores, i.e. the best score the player had in the last games"}
                      width={"w-1/18"}
                    />
                  </>
                )}
              </tr>
            </thead>
          )}
          <tbody>
            {!(pickedView === "form" && !t2OrAbove(props.user.tier)) &&
              displayedPlayers.map((entry, i) => {
                const player = entry.player;
                const card = entry.card;
                const computed = entry.computed;

                let avgColor;
                let pickPctAvg = entry.pick_stats.pick_pct;
                if (pickPctAvg < 10) {
                  avgColor = "#BC190F";
                } else if (pickPctAvg < 25) {
                  avgColor = "#E1921B";
                } else if (pickPctAvg < 40) {
                  avgColor = "#F1CE17";
                } else if (pickPctAvg < 55) {
                  avgColor = "#ABC21D";
                } else if (pickPctAvg < 70) {
                  avgColor = "#1DC29B";
                } else if (pickPctAvg <= 100) {
                  avgColor = "#019355";
                }

                let divAvg = entry.division_stats.rate;
                let divColor;
                if (divAvg < 5) {
                  divColor = "#BC190F";
                } else if (divAvg < 15) {
                  divColor = "#E1921B";
                } else if (divAvg < 25) {
                  divColor = "#F1CE17";
                } else if (divAvg < 35) {
                  divColor = "#ABC21D";
                } else if (divAvg < 45) {
                  divColor = "#1DC29B";
                } else if (divAvg <= 100) {
                  divColor = "#019355";
                }

                let power = ((parseFloat(card.Power) - 1) * 100).toFixed(1);
                let odds = props.odds[entry.next_game.GameId];
                const isHome = computed.isHome;

                let disableAddCard = false;
                let addToLineupContent = [];
                const isSelectedInCurrentPosition = computed.isInCurrentLineup.value && computed.isInCurrentLineup.isSelectedPosition;
                if (onDiscardRevert === undefined) {
                  if (computed.isInCurrentLineup.value) {
                    disableAddCard = true;
                    addToLineupContent.push(
                      <CheckIcon className={`w-5 h-5 ${isSelectedInCurrentPosition ? "fill-primary" : "fill-on-surface"}`} />,
                    );
                    if (noActionLabel === undefined || noActionLabel === false) {
                      addToLineupContent.push(
                        <p
                          className={`text-xxs font-semibold text-center px-1 ${
                            isSelectedInCurrentPosition ? "text-primary" : "text-on-surface"
                          }`}
                        >
                          In this lineup
                        </p>,
                      );
                    }
                  } else if (entry.pickedStatus.picked) {
                    addToLineupContent.push(
                      <div data-tip={`Picked in ${entry.pickedStatus.divisionName} - Team ${entry.pickedStatus.teamNumber}`}>
                        <SquareWarningIcon className={`w-5 h-5 fill-on-surface`} />
                      </div>,
                    );
                  }
                }

                const competitionAdjusted = preferences.competitionAdjustedScore ? entry.next_game.CompSlug : "all";
                const homeAwayAdjusted = preferences.homeAwayAdjustedScore ? (isHome ? "home" : "away") : "all";
                const teamAdjusted = preferences.teamAdjustedScore ? entry.player_team.TeamId : "all";
                const so5ScoreDetailed =
                  so5ScoresDetailed?.[player.PlayerId]?.[card.Position]?.[teamAdjusted]?.[competitionAdjusted]?.[homeAwayAdjusted];
                const so5ScoreDetailedWithPickedAvg = so5ScoreDetailed?.[pickedAverage];
                const so5ScoreDetailedL15 = so5ScoreDetailed?.l15;
                const so5ScoreDetailedL5 = so5ScoreDetailed?.l5;

                // Opp score
                let opponent;
                if (entry.next_game.HomeTeamId) {
                  opponent =
                    entry.next_game.HomeTeamId === entry.player.TeamId || entry.next_game.HomeTeamId === entry.player.NationalTeam
                      ? entry.next_game.AwayTeamId
                      : entry.next_game.HomeTeamId;
                }
                const oppScoreDetailed = oppScoresDetailed?.[opponent]?.[entry.next_game.CompSlug]?.[isHome ? "away" : "home"]; // opponent is away when player is home

                // Form
                let decScore = so5ScoreDetailedWithPickedAvg?.decisiveScoreAverage;
                let aaScore = so5ScoreDetailedWithPickedAvg?.allAroundAverage;
                let medianScore = so5ScoreDetailedWithPickedAvg?.median;
                let floorScore = so5ScoreDetailedWithPickedAvg?.scores ? Math.min(...so5ScoreDetailedWithPickedAvg.scores) : undefined;
                let ceilingScore = so5ScoreDetailedWithPickedAvg?.scores ? Math.max(...so5ScoreDetailedWithPickedAvg.scores) : undefined;
                if (powerAdjustedAverages) {
                  if (decScore !== undefined) decScore = decScore * card.Power;
                  if (aaScore !== undefined) aaScore = aaScore * card.Power;
                  if (medianScore !== undefined) medianScore = medianScore * card.Power;
                  if (floorScore !== undefined) floorScore = floorScore * card.Power;
                  if (ceilingScore !== undefined) ceilingScore = ceilingScore * card.Power;
                }
                aaScore = aaScore?.toFixed(1);
                decScore = decScore?.toFixed(0);
                medianScore = medianScore?.toFixed(0);
                floorScore = floorScore?.toFixed(2);
                ceilingScore = ceilingScore?.toFixed(2);

                const playerStats = entry.player_stats;
                const playerReveal = pickScoreReveal?.players?.[player.PlayerId + "-" + card.Position];
                let mainPickScore =
                  entry.pick_score_group !== undefined && entry.pick_score_group !== null ? entry.pick_score_group : playerReveal?.score;
                if (entry.next_game?.GameId === undefined || entry.next_game.GameId === "") {
                  mainPickScore = "NG";
                }
                const pickScoreDetails =
                  entry.pick_score_details !== undefined &&
                  entry.pick_score_details !== null &&
                  Object.keys(entry.pick_score_details).length > 0
                    ? entry.pick_score_details
                    : playerReveal?.details;
                const lastRow = i === displayedPlayers.length - 1;
                const circleNeutralBackground = getThemeColor(theme, "transparent-inverse-surface-low");
                const hasSeasonBonus =
                  seasonBonusLimitations !== null &&
                  seasonBonusLimitations !== undefined &&
                  card.CardPower &&
                  parseFloat(card.CardPower?.season) > 0;
                const isCardFromSpecificSeason =
                  seasonLimitations !== null &&
                  seasonLimitations !== undefined &&
                  (seasonLimitations.season === 0 || card.Season === seasonLimitations.season) &&
                  (seasonLimitations.cardTeamSlugs === undefined ||
                    seasonLimitations.cardTeamSlugs === null ||
                    seasonLimitations.cardTeamSlugs.length === 0 ||
                    seasonLimitations.cardTeamSlugs.includes(card?.CardTeamId));
                const highlightSeason = seasonHighlightEnabled && (hasSeasonBonus || isCardFromSpecificSeason);
                return (
                  <React.Fragment key={card.TokenId}>
                    <tr
                      className={`bg-surface-container ${
                        !props.showL15 && !lastRow && "border-b border-transparent-inverse-surface-low border-opacity-10"
                      }`}
                    >
                      <td className={"w-1/24"}>
                        <div
                          className={
                            "border-r border-transparent-inverse-surface-low border-opacity-10 h-14 flex flex-col justify-center items-center gap-1"
                          }
                        >
                          {!disableAddCard && onDiscardRevert === undefined && (
                            <div className={"w-full items-center justify-center self-center align-middle flex flex-col"}>
                              {!disableAddCard && <AddToLineupButton onClick={() => props.pickCard(entry, card.Position)} />}
                            </div>
                          )}
                          {onDiscardRevert && (
                            <div className={"w-full items-center justify-center self-center align-middle flex flex-col"}>
                              <PersonAdd
                                className={"w-7 h-7 fill-primary cursor-pointer"}
                                title={"Revert player back to not discarded players"}
                                onClick={() => onDiscardRevert(entry)}
                              />
                            </div>
                          )}
                          {addToLineupContent}
                        </div>
                      </td>
                      <td className={"w-1/24 min-w-[300px]"}>
                        <div className={"flex flex-row justify-start gap-2 py-2 pl-2 text-on-surface"}>
                          <div>
                            <PlayerAvatar player={player} border={isSelectedInCurrentPosition ? "border-2 border-primary" : undefined} />
                          </div>
                          <div className={"self-center flex flex-col space-y-1"}>
                            <div className={"flex flex-row gap-1 justify-start items-center"}>
                              <a href={"/player/" + player.PlayerId} target={"_blank"} className={"hover:opacity-80"} rel="noreferrer">
                                <p className={"font-semibold text-sm"}>{player.DisplayName}</p>
                              </a>
                              {entry.player_team.TeamId && (
                                <div className={"h-5 w-5"}>
                                  <a href={"/team/" + player.TeamId} target={"_blank"} rel="noreferrer">
                                    <img
                                      className={"object-contain h-5 w-5"}
                                      title={entry.player_team.DisplayName}
                                      src={entry.player_team.PictureUrl !== "" ? entry.player_team.PictureUrl : UnknownClub}
                                    />
                                  </a>
                                </div>
                              )}
                              <div className={"self-center w-4 h-4"}>
                                <PlayerAvailability availability={entry.availability_status} />
                              </div>
                              <p className={`font-semibold self-center ${powerAdjustedAverages ? "text-primary text-sm" : "text-xs"}`}>
                                {power}%
                              </p>
                            </div>
                            <div className={"flex flex-row divide-x divide-transparent-inverse-surface-low divide-opacity-10 items-center"}>
                              <span className={"pr-2 cursor-pointer hover:opacity-80"}>
                                <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
                                  <CardIcon scarcity={card.Scarcity.toLowerCase()} />
                                </a>
                              </span>
                              <span className={"px-2 flex items-center whitespace-nowrap"}>
                                <PreferentialPositions cardPosition={card.Position} detailedPositions={so5ScoreDetailedL15?.positions} />
                              </span>
                              <span className={"px-2"}>
                                <PlayerCommunityProjectionWithHover
                                  projection={entry.projected_lineup}
                                  sport={sorareFootball}
                                  playerTeamId={player.TeamId}
                                />
                              </span>
                              <span className={"px-2 flex items-center"}>
                                <SeasonTag season={card.Season} highlight={highlightSeason} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      {(hasCostRestriction || alwaysShowCost) && (
                        <td className={"w-1/24"}>
                          <div className={"flex flex-row justify-center space-x-2 py-2 w-full h-full"}>
                            <div className={"flex justify-center items-center w-12 h-12"}>
                              <SorareScoreAverage avg={entry.cost} />
                            </div>
                          </div>
                        </td>
                      )}
                      {pickedView === "classic" && (
                        <td className={"w-1/18"}>
                          <PickScoreDetails
                            main={mainPickScore}
                            onReveal={() => onPickScoreReveal(player, card.Position)}
                            revealing={revealing[player.PlayerId + "-" + card.Position]}
                            revealInfo={pickScoreReveal}
                            isCapped={isCapped}
                            detailed={pickScoreDetails}
                          />
                        </td>
                      )}
                      <td className={"w-1/24"}>
                        <div className={"flex flex-row justify-center space-x-2 py-2"}>
                          <img
                            className={"h-5 w-5"}
                            src={
                              preferences.homeAwayAdjustedScore ? (isHome ? HomeIconBrand : AwayIconBrand) : isHome ? HomeIcon : AwayIcon
                            }
                          />
                        </div>
                      </td>
                      {pickedView === "classic" && (
                        <>
                          <td className={"w-1/24"}>
                            <div className={"py-2 self-center"}>
                              {so5ScoreDetailedL5 ? (
                                <ScoreAverage
                                  avg={so5ScoreDetailedL5?.average}
                                  period={so5ScoreDetailedL5?.games}
                                  played={so5ScoreDetailedL5?.gamesPlayed}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={so5ScoreDetailedL5?.average}
                                  sApps={so5ScoreDetailedL5?.gamesPlayed}
                                />
                              ) : (
                                <ScoreAverage
                                  avg={0}
                                  period={5}
                                  played={0}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={0}
                                  sApps={0}
                                />
                              )}
                            </div>
                          </td>
                          <td className={"w-1/24"}>
                            <div className={"py-2 self-center"}>
                              {so5ScoreDetailedL15 ? (
                                <ScoreAverage
                                  avg={so5ScoreDetailedL15?.average}
                                  period={so5ScoreDetailedL15?.games}
                                  played={so5ScoreDetailedL15?.gamesPlayed}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={so5ScoreDetailedL15?.average}
                                  sApps={so5ScoreDetailedL15?.gamesPlayed}
                                />
                              ) : (
                                <ScoreAverage
                                  avg={0}
                                  period={15}
                                  played={0}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={0}
                                  sApps={0}
                                />
                              )}
                            </div>
                          </td>
                          <td className={"w-1/12"}>
                            <GameInLine
                              playerTeam={player.TeamId}
                              playerNationalTeam={player.NationalTeam}
                              team1={entry.player_team}
                              team2={entry.opposing_team}
                              game={entry.next_game}
                              odds={odds !== undefined && odds["full_time0"]}
                              standings={props.standings ? props.standings : {}}
                            />
                          </td>
                          <td className={"w-1/18"}>
                            <div className={"flex flex-row justify-center"}>
                              <MatchupOdds
                                isHome={isHome}
                                odds={odds}
                                cardPosition={card.Position}
                                detailedPositions={so5ScoreDetailedL15?.positions}
                              />
                            </div>
                          </td>
                          <td className={"w-1/18"}>
                            <div className={"flex flex-row justify-center h-full"}>
                              <OppScoreWithDetails
                                entry={entry}
                                locked={isFree(props.user.tier)}
                                detailed={oppScoreDetailed?.oppScore}
                                cardPosition={card.Position}
                              />
                            </div>
                          </td>
                        </>
                      )}

                      {pickedView === "advanced" && (
                        <>
                          <td className={"w-1/24"}>
                            <div className={`${i === 0 ? onboarding1 : ""} flex flex-row justify-center self-center`}>
                              <CommunityPredictionIndicator
                                majorChoiceColored={true}
                                selected={entry.projected_lineup_vote}
                                votable={true}
                                playerId={player.PlayerId}
                                pred={entry.projected_lineup}
                                gameId={entry.next_game.GameId}
                              />
                            </div>
                          </td>
                          <td className={"w-1/24"}>
                            <div className={"flex flex-row justify-center self-center"}>
                              <div className={"w-10"}>
                                <CircularProgressbarWithChildren
                                  value={Number(pickPctAvg)}
                                  strokeWidth={8}
                                  styles={buildStyles({
                                    strokeLinecap: "round",
                                    pathColor: avgColor,
                                    trailColor: circleNeutralBackground,
                                  })}
                                >
                                  <p className={"text-xs font-semibold"} style={{ color: avgColor }}>
                                    {pickPctAvg < 0 ? "NA" : pickPctAvg.toFixed(0) + "%"}
                                  </p>
                                </CircularProgressbarWithChildren>
                              </div>
                            </div>
                          </td>
                          <td className={"w-1/24"}>
                            <div className={"flex flex-row justify-center self-center"}>
                              <div className={"w-10"}>
                                <CircularProgressbarWithChildren
                                  value={parseFloat(entry.division_stats.rate)}
                                  strokeWidth={8}
                                  styles={buildStyles({
                                    strokeLinecap: "round",
                                    pathColor: divColor,
                                    trailColor: circleNeutralBackground,
                                  })}
                                >
                                  <p className={"text-xs font-semibold"} style={{ color: divColor }}>
                                    {entry.division_stats.rate < 0 ? "NA" : entry.division_stats.rate.toFixed(0) + "%"}
                                  </p>
                                </CircularProgressbarWithChildren>
                              </div>
                            </div>
                          </td>
                          <td className={"w-1/18"}>
                            <div className={"flex flex-row justify-center items-center h-full"}>
                              <OppScoreWithDetails
                                entry={entry}
                                locked={isFree(props.user.tier)}
                                detailed={oppScoreDetailed?.oppScore}
                                cardPosition={card.Position}
                              />
                            </div>
                          </td>
                          <td className={"w-1/18"}>
                            <div className={"flex flex-row justify-center items-center self-center"}>
                              <MatchupIndicator score={entry.matchup_score} locked={t1OrBelow(props.user.tier)} />
                            </div>
                          </td>
                        </>
                      )}
                      {pickedView === "form" && (
                        <>
                          <td>
                            <div className={"flex flex-row justify-center h-full items-center"}>
                              <PlayedStats stats={so5ScoreDetailedWithPickedAvg} />
                            </div>
                          </td>
                          <td>
                            <div className={"flex flex-row justify-center h-full items-center"}>
                              {so5ScoreDetailedWithPickedAvg ? (
                                <ScoreAverage
                                  avg={so5ScoreDetailedWithPickedAvg?.average}
                                  period={so5ScoreDetailedWithPickedAvg?.games}
                                  played={so5ScoreDetailedWithPickedAvg?.gamesPlayed}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={so5ScoreDetailedWithPickedAvg?.average}
                                  sApps={so5ScoreDetailedWithPickedAvg?.gamesPlayed}
                                />
                              ) : (
                                <ScoreAverage
                                  avg={0}
                                  period={0}
                                  played={0}
                                  power={entry.card.Power}
                                  powerAdj={powerAdjustedAverages}
                                  sAvg={0}
                                  sApps={0}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            <div className={"flex flex-row justify-center h-full items-center"}>
                              <DecisiveActionsScore score={decScore} />
                            </div>
                          </td>
                          <td>
                            <div className={"flex flex-row justify-center h-full items-center"}>
                              <AllAroundScore score={aaScore} />
                            </div>
                          </td>
                          <td>
                            <div className={"flex flex-row justify-center h-full items-center"}>
                              <MedianScore sport={player.Sport} score={medianScore} />
                            </div>
                          </td>
                          <td>
                            <div className={"flex flex-row justify-center h-full items-center"}>
                              <FloorScore sport={player.Sport} score={floorScore} />
                            </div>
                          </td>
                          <td>
                            <div className={"flex flex-row justify-center h-full items-center"}>
                              <CeilingScore sport={player.Sport} score={ceilingScore} />
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                    {preferences.showL15Scores && playerStats && playerStats.length > 0 && (
                      <tr className={"border-y border-transparent-inverse-surface-low border-opacity-10 bg-surface-container py-0.5"}>
                        <td colSpan={pickedView === "classic" ? 10 : pickedView === "advanced" ? 9 : 11}>
                          <div className={"pb-1 pt-0.5 bg-surface-container"}>
                            <Last10Line
                              stats={playerStats}
                              power={entry.card.Power}
                              position={card.Position}
                              player={player}
                              powerAdj={powerAdjustedAverages}
                              enhanceConfig={{
                                currentGame: entry.next_game,
                                isHome: isHome,
                                currentTeamId: entry.player_team.TeamId,
                                teamAdjusted: preferences.teamAdjustedScore,
                                competitionAdjusted: preferences.competitionAdjustedScore,
                                homeAwayAdjusted: preferences.homeAwayAdjustedScore,
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            {loading && displayedPlayers.length === 0 && (
              <>
                {Array.from({ length: placeholderAmount }).map((_, i) => (
                  <Placeholder key={i} pickedView={pickedView} hasCostRestriction={hasCostRestriction} alwaysShowCost={alwaysShowCost} />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      {players !== undefined && displayedPlayers.length === 0 && !(pickedView === "form" && !t2OrAbove(props.user.tier)) && (
        <div className={"flex flex-col justify-center w-full bg-surface-container text-center py-8"}>
          <div className={"flex flex-col justify-center"}>
            <div className={"m-auto mb-6"}>
              <NoCardResultsImage />
            </div>
            <p className={"text-lg font-headers text-center text-on-surface font-bold mb-2"}>No card with such characteristics</p>
            <p className={"text-md font-normal text-center text-on-surface-variant font-semibold"}>
              Try to change the filters, the display preferences or choose another position.
            </p>
          </div>
        </div>
      )}
      {pickedView === "form" && !t2OrAbove(props.user.tier) && <MoreInsightBlockForm onViewExample={() => openLegend("form")} />}
    </>
  );
});

export const AddToLineupButton = (props) => {
  let sizeUnder = "w-6 h-6";
  let sizePlus = "w-10 h-10";
  if (props.size === "sm") {
    sizeUnder = "w-4 h-4";
    sizePlus = "w-8 h-8";
  } else if (props.size === "xs") {
    sizeUnder = "w-3 h-3";
    sizePlus = "w-6 h-6";
  }
  return (
    <div
      className={`relative rounded-full ${sizeUnder} bg-primary flex justify-center items-center cursor-pointer hover:opacity-80`}
      onClick={props.onClick}
    >
      <div className={"absolute -top-1/2 w-[200%] h-[200%] flex flex-row justify-center items-center"}>
        <AddIcon className={`${sizePlus} fill-surface-container-highest`} />
      </div>
    </div>
  );
};

const MoreInsightBlockForm = (props) => {
  const { onViewExample } = props;
  return (
    <div
      className={
        "flex flex-col w-full rounded-md bg-center bg-clip-border bg-cover bg-no-repeat bg-upgrade-bg-lub-form rounded py-3 px-5 gap-3 mt-2"
      }
    >
      <div className={"flex flex-row gap-4"}>
        <div className="flex flex-row justify-center self-center">
          <LockerRound containerStyle="w-7 h-7" />
        </div>
        <div className="flex flex-col py-2 justify-center">
          <div className="mb-1">
            <p className={`text-lg text-black font-black text-left font-headers`}>Want more insights?</p>
          </div>
          <div>
            <p className={`text-xs font-semibold text-left`}>
              Become a Star member to unlock the form view that will give you access to many key stats regarding your players
            </p>
          </div>
        </div>
      </div>
      <div className={"flex flex-row justify-center items-center"}>
        <img className={"object-contain"} style={{ filter: "drop-shadow(0px 2px 50px rgba(0, 0, 0, 0.1))" }} src={LUBTableFormExample} />
      </div>
      <div className={"flex items-center justify-between w-full"}>
        <div>
          <a href={"https://youtu.be/kYLPKrHArtE"} target={"blank_"}>
            <div className={"flex flew-row gap-1 justify-center items-center hover:opacity-80 text-xs font-semibold"}>
              <p className={"text-primary "}>Learn more about the subscription tiers</p>
              <div className={"w-4 h-4 self-center inline-flex"}>
                <img className={"w-full h-full object-contain"} src={PlayCircle} />
              </div>
            </div>
          </a>
        </div>
        <div className="flex flex-row gap-3 items-center">
          <a className={"text-primary text-sm font-semibold cursor-pointer hover:underline"} onClick={onViewExample}>
            View legend
          </a>
          <a href={"/product"}>
            <Button label={"Upgrade"} />
          </a>
        </div>
      </div>
    </div>
  );
};

export const enhancePlayersWithComputedData = (
  players,
  isCardInCurrentLineup,
  powerAdjustedAverages,
  preferences,
  so5ScoresDetailed,
  pickedAverage,
  odds,
  pickScoreReveal,
) => {
  return players?.map((e) => {
    const isHome = isEntryHome(e);
    const player = e.player;
    const card = e.card;

    const isInCurrentLineup = isCardInCurrentLineup(e.card.TokenId);

    // Compute new order, only when a new request is done

    const powerAdjusted = powerAdjustedAverages ? card.Power : 1;
    const competitionAdjusted = preferences.competitionAdjustedScore ? e.next_game.CompSlug : "all";
    const homeAwayAdjusted = preferences.homeAwayAdjustedScore ? (isHome ? "home" : "away") : "all";
    const teamAdjusted = preferences.teamAdjustedScore ? e.player_team.TeamId : "all";
    const so5ScoreDetailed =
      so5ScoresDetailed?.[player.PlayerId]?.[card.Position]?.[teamAdjusted]?.[competitionAdjusted]?.[homeAwayAdjusted];
    const so5ScoreDetailedL15 = so5ScoreDetailed?.l15;
    const so5ScoreDetailedL5 = so5ScoreDetailed?.l5;
    const so5ScoreDetailedWithPickedAvg = so5ScoreDetailed?.[pickedAverage];

    let eOdds;
    if (odds[e.next_game.GameId] !== undefined) {
      eOdds = odds[e.next_game.GameId]["full_time0"]?.home || 100;
      if (!isHome) eOdds = odds[e.next_game.GameId]["full_time0"]?.away || 100;
    }
    const matchupOddsPct = getMatchupOddsInfo(odds?.[e.next_game.GameId], isHome, so5ScoreDetailedL15?.positions, card.Position);

    let decScore = so5ScoreDetailedWithPickedAvg?.decisiveScoreAverage;
    let aaScore = so5ScoreDetailedWithPickedAvg?.allAroundAverage;
    let medianScore = so5ScoreDetailedWithPickedAvg?.median;
    let floorScore = so5ScoreDetailedWithPickedAvg?.scores ? Math.min(...so5ScoreDetailedWithPickedAvg.scores) : undefined;
    let ceilingScore = so5ScoreDetailedWithPickedAvg?.scores ? Math.max(...so5ScoreDetailedWithPickedAvg.scores) : undefined;
    if (powerAdjustedAverages) {
      if (decScore !== undefined) decScore = decScore * card.Power;
      if (aaScore !== undefined) aaScore = aaScore * card.Power;
      if (medianScore !== undefined) medianScore = medianScore * card.Power;
      if (floorScore !== undefined) floorScore = floorScore * card.Power;
      if (ceilingScore !== undefined) ceilingScore = ceilingScore * card.Power;
    }
    let communityProjectionScore = -1;
    if (e.projected_lineup && e.projected_lineup.guesses > 0) {
      const pred = predicitionsInfo(e.projected_lineup);
      // Start pct is the most important, then out (which is the complement of doubt+bench). All is pondered by trust score
      communityProjectionScore = pred.startPct === undefined ? -1 : (pred.startPct + (pred.doubtPct + pred.missPct) / 10) * pred.trustScore;
    }

    let playedScore = -1;
    if (so5ScoreDetailedWithPickedAvg !== undefined && so5ScoreDetailedWithPickedAvg.actualGames > 0) {
      const gamesStarted = so5ScoreDetailedWithPickedAvg.gamesStarted / so5ScoreDetailedWithPickedAvg.actualGames;
      const gamesEnteredFromBench =
        (so5ScoreDetailedWithPickedAvg.gamesPlayed - so5ScoreDetailedWithPickedAvg.gamesStarted) /
        so5ScoreDetailedWithPickedAvg.actualGames;
      const gamesOnBench = so5ScoreDetailedWithPickedAvg.gamesOnBench / so5ScoreDetailedWithPickedAvg.actualGames;
      const gamesOut = so5ScoreDetailedWithPickedAvg.gamesOut / so5ScoreDetailedWithPickedAvg.actualGames;
      playedScore = 1000 * gamesStarted + 100 * gamesEnteredFromBench + 10 * gamesOnBench + 1 * gamesOut;
    }
    // convert pick score to a float, so it can be ordered
    // Float first part is the rank deduced from pick score label in this order: "AAA", "AA", ..., "F", "authorized but not available" (empty), not authorized (null/undefined).
    // Float second part is the ranking sent by the server when available. So when we get the whole breakdown we know if one player is a better AAA than another AAA.
    // When revealing a player, the ranking is not known, so it will be first by default in its own pick score group
    // Refreshing breakdown from the server will get the ranking though
    let pickScoreGroup = pickScoreReveal?.players?.[player.PlayerId + "-" + card.Position]?.score || e.pick_score_group;
    if (e.next_game?.GameId === undefined || e.next_game.GameId === "") {
      pickScoreGroup = "NG";
    }
    const pickScoreRank = Number(rankFromPickScoreGroup(pickScoreGroup) + "." + (e.pick_score_rank || 0).toString().padStart(10, "0"));
    let lineupPosition = so5_positions.findIndex((e) => e === isInCurrentLineup.position);
    lineupPosition = lineupPosition > -1 ? lineupPosition : 5;
    return {
      ...e,
      computed: {
        isHome: isEntryHome(e),
        isAway: !isEntryHome(e),
        l5: so5ScoreDetailedL5?.average != undefined ? so5ScoreDetailedL5.average * powerAdjusted : 0,
        l15: so5ScoreDetailedL15?.average != undefined ? so5ScoreDetailedL15.average * powerAdjusted : 0,
        lPicked: so5ScoreDetailedWithPickedAvg?.average ? so5ScoreDetailedWithPickedAvg.average * powerAdjusted : undefined,
        pctWin: 1 / eOdds,
        matchupOddsPct: matchupOddsPct.value ? 1 / matchupOddsPct.value : -1,
        aaScore: aaScore * powerAdjusted,
        decScore: decScore * powerAdjusted,
        medianScore: medianScore * powerAdjusted,
        floorScore: floorScore !== undefined ? floorScore * powerAdjusted : undefined,
        floorScoreOrder: floorScore !== undefined ? floorScore * powerAdjusted : -1,
        ceilingScore: ceilingScore !== undefined ? ceilingScore * powerAdjusted : undefined,
        ceilingScoreOrder: ceilingScore !== undefined ? ceilingScore * powerAdjusted : -1,
        played: playedScore,
        communityProjections: communityProjectionScore,
        isInCurrentLineup: isInCurrentLineup,
        pickScoreReverse: -1 * pickScoreRank,
        lineupPosition: lineupPosition,
      },
    };
  });
};

const isEntryHome = (entry) => {
  let isHome = false;
  if (entry.next_game.HomeTeamId) {
    isHome = entry.next_game.HomeTeamId === entry.player.TeamId || entry.next_game.HomeTeamId === entry.player.NationalTeam;
  }
  return isHome;
};

const TableHeaderLine = (props) => {
  const { label, width, first, last, tip, sortable, sorted, adjusted, adjustedDetails, beta, onboarding } = props;
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tip]);
  let adjustedElement = null;
  if (adjusted) {
    if (adjustedDetails) {
      let adjMessages = [];
      if (adjustedDetails.powerAdjusted) {
        adjMessages.push("power");
      }
      if (adjustedDetails.teamAdjusted) {
        adjMessages.push("team");
      }
      if (adjustedDetails.competitionAdjusted) {
        adjMessages.push("comp.");
      }
      if (adjustedDetails.homeAwayAdjusted) {
        adjMessages.push("H/A");
      }
      adjustedElement = <span className={"text-primary font-medium"}> (• {adjMessages.join(" & ")} adjusted)</span>;
    } else {
      adjustedElement = <span className={"text-primary"}>•</span>;
    }
  }
  return (
    <th
      title={tip}
      onClick={sortable ? props.onSort : undefined}
      className={`${
        sortable ? "cursor-pointer hover:text-primary hover:text-opacity-90" : ""
      } ${width} bg-surface-container-high px-1.5 py-3 ${first ? "rounded-tl-md" : ""} ${
        last ? "rounded-tr-md" : ""
      } text-center font-semibold ${sorted ? "text-primary" : "text-on-surface-variant"}`}
    >
      <div className={`flex flex-col gap-1 ${onboarding ? onboarding : ""}`}>
        <span>
          {label}
          {adjustedElement}
        </span>

        {sortable && sorted ? (
          <span className={"flex flex-row justify-center gap-2 items-center"}>
            {beta && <span className={"text-xxs bg-super-rare py-0.5 px-1.5 text-white rounded-full"}>BETA</span>}
            <IconCarretFilled className={`h-3 w-3 fill-primary ${sorted == "asc" ? "transform rotate-180" : ""}`} />
          </span>
        ) : beta ? (
          <span className={"text-xxs bg-super-rare py-0.5 px-1.5 text-white rounded-full mx-auto"}>BETA</span>
        ) : null}
      </div>
    </th>
  );
};

export const SeasonTag = (props) => {
  const { season, highlight } = props;
  const size = props.size || "md";
  if (season === undefined || season === null || season === 0) {
    return null;
  }
  return (
    <span
      style={{
        lineHeight: "15px",
      }}
      className={`${size === "md" ? "text-xs" : "text-xxs"} inline-block rounded-full font-semibold ${
        highlight ? "text-on-tertiary-fixed bg-tertiary-fixed" : "text-on-surface-variant bg-transparent-inverse-surface-low bg-opacity-10"
      } px-1 py-0.5`}
    >
      {season}
    </span>
  );
};

const TableViewPicker = (props) => {
  const { adapting } = props;
  const onChange = useCallback(
    (view) => {
      props.onChange && props.onChange(view);
    },
    [props.onChange],
  );
  const pickedStyle = "text-primary";
  const notPickedStyle = "text-on-surface-variant";
  const pickedView = props.value || "classic";
  return (
    <div
      className={`text-center font-semibold bg-surface-container flex flex-row ${
        adapting ? "w-full justify-around" : "justify-evenly space-x-6"
      } text-md px-8 py-2 rounded-full items-center`}
    >
      <div className={"cursor-pointer"} onClick={() => onChange("classic")}>
        <p className={pickedView === "classic" ? pickedStyle : notPickedStyle}>Classic</p>
      </div>
      <div className={"border-l border-outline-variant h-6"} />
      <div className={"cursor-pointer"} onClick={() => onChange("advanced")}>
        <p className={pickedView === "advanced" ? pickedStyle : notPickedStyle}>Advanced</p>
      </div>
      <div className={"border-l border-outline-variant h-6"} />
      <div className={"cursor-pointer"} onClick={() => onChange("form")}>
        <p className={pickedView === "form" ? pickedStyle : notPickedStyle}>Form</p>
      </div>
    </div>
  );
};

const TableViewAveragePicker = (props) => {
  const { adapting } = props;
  const onChange = useCallback(
    (view) => {
      props.onChange && props.onChange(view);
    },
    [props.onChange],
  );
  const pickedStyle = "text-primary";
  const notPickedStyle = "text-on-surface-variant";
  const pickedAvg = props.value || "l15";
  return (
    <div
      className={`text-center font-semibold bg-surface-container flex flex-row ${
        adapting ? "w-full justify-around" : "justify-evenly space-x-6"
      } text-md px-8 py-2 rounded-full items-center`}
    >
      <div className={"cursor-pointer"} onClick={() => onChange("l5")}>
        <p className={pickedAvg === "l5" ? pickedStyle : notPickedStyle}>L5</p>
      </div>
      <div className={"border-l border-outline-variant h-6"} />
      <div className={"cursor-pointer"} onClick={() => onChange("l15")}>
        <p className={pickedAvg === "l15" ? pickedStyle : notPickedStyle}>L15</p>
      </div>
      <div className={"border-l border-outline-variant h-6"} />
      <div className={"cursor-pointer"} onClick={() => onChange("l40")}>
        <p className={pickedAvg === "l40" ? pickedStyle : notPickedStyle}>L40</p>
      </div>
    </div>
  );
};

export const PreferentialPositions = (props) => {
  const { cardPosition, detailedPositions } = props;
  const cardPositionAbreviation = abbrv_football_positions[cardPosition];
  const elems = [cardPositionAbreviation || "UKN"];
  let positionsAndCountMsg = "Card is " + cardPosition + "; ";
  if (detailedPositions !== undefined && detailedPositions !== null && detailedPositions.length > 0) {
    const mainDetailedPosition = detailedPositions?.[0].Position;
    const mainDetailedPositionAbbrv = mainDetailedPosition ? detailedPositionAbreviations[mainDetailedPosition] : undefined;
    if (mainDetailedPositionAbbrv !== undefined && mainDetailedPositionAbbrv !== cardPositionAbreviation) {
      elems.push(mainDetailedPositionAbbrv);
    }
    positionsAndCountMsg +=
      detailedPositions.map((pos) => pos.Count + " games as " + detailedPositionFullName[pos.Position]).join(", ") + " in last 15 games";
  }
  return (
    <span className={"text-sm font-medium text-on-surface-variant"} title={positionsAndCountMsg}>
      {elems.filter((p) => p !== undefined && p !== "").join(" · ")}
    </span>
  );
};

export const Placeholder = (props) => {
  const { pickedView, hasCostRestriction, alwaysShowCost } = props;
  return (
    <React.Fragment>
      <tr className={`bg-surface-container border-b border-transparent-inverse-surface-low border-opacity-10`}>
        <td className={"w-1/24"}>
          <div
            className={
              "border-r border-transparent-inverse-surface-low border-opacity-10 h-14 flex flex-col justify-center items-center gap-1"
            }
          ></div>
        </td>
        <td className={"w-1/24 min-w-[300px]"}>
          <div className={"flex flex-row justify-start gap-2 py-2 pl-2 text-on-surface"}>
            <div>
              <PlayerAvatar player={{ Avatar: "" }} />
            </div>
            <div className={"self-center flex flex-col space-y-1"}>
              <div className={"flex flex-col gap-1 justify-center"}>
                <div className={"bg-surface-container-high rounded h-4 w-16"}></div>
                <div className={"bg-surface-container-high rounded h-4 w-12"}></div>
              </div>
            </div>
          </div>
        </td>
        {(hasCostRestriction || alwaysShowCost) && (
          <td className={"w-1/24"}>
            <div className={"flex flex-row justify-center h-full items-center"}>
              <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
            </div>
          </td>
        )}
        {pickedView === "classic" && (
          <td className={"w-1/18"}>
            <div className={"flex flex-row justify-center h-full items-center"}>
              <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
            </div>
          </td>
        )}
        <td className={"w-1/24"}>
          <div className={"flex flex-row justify-center h-full items-center"}>
            <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
          </div>
        </td>
        {pickedView === "classic" && (
          <>
            <td className={"w-1/24"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/24"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/12"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/18"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/18"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
          </>
        )}

        {pickedView === "advanced" && (
          <>
            <td className={"w-1/24"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/24"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/24"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/18"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td className={"w-1/18"}>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
          </>
        )}
        {pickedView === "form" && (
          <>
            <td>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
            <td>
              <div className={"flex flex-row justify-center h-full items-center"}>
                <div className={"bg-surface-container-high rounded h-8 w-8"}></div>
              </div>
            </td>
          </>
        )}
      </tr>
    </React.Fragment>
  );
};
