import React from "react";
import LineSeparator from "../../img/line-separator.svg";
import Jersey from "../../img/jersey.svg";
import JerseyPastel from "../../img/jersey-pastel.svg";
import { sorareBaseball, sorareBasketball } from "./sports";

function PositionPickerBox(props) {
  const activeLabel = "text-sm text-center text-brand font-semibold";
  const inactiveLabel = "text-sm text-center text-textGrey4 font-semibold";
  const positions = props.positions;
  const position = props.position;
  const single = props.single;
  let active = position === positions;
  if (!single) {
    active = positions.indexOf(position) >= 0;
  }

  const add = () => {
    if (single) {
      props.change(props.position);
    } else {
      let newPositions = [...positions];
      newPositions.push(props.position);
      props.change(newPositions);
    }
  };

  const remove = () => {
    if (single) {
      props.change("");
    } else {
      let newPositions = [...positions];
      newPositions = newPositions.filter(function (v) {
        return v !== props.position;
      });
      props.change(newPositions);
    }
  };

  return (
    <div className={"space-x-2 flex flex-row"}>
      <div
        className={"flex flex-col mx-auto self-center items-center pr-2 align-middle relative cursor-pointer w-10"}
        onClick={active ? () => remove() : () => add()}
      >
        <div className={"z-10 pt-2 self-center"}>
          <p className={active ? activeLabel : inactiveLabel}>{props.label}</p>
        </div>
        <div className={"absolute z-0 self-center"}>
          <div className={"w-8 h-8"}>
            <img className={"object-contain w-full h-full z-0"} src={active ? JerseyPastel : Jersey} />
          </div>
        </div>
      </div>
      {!props.last && <img src={LineSeparator} />}
    </div>
  );
}

function SinglePositionPicker(props) {
  const positions = props.positions;
  const sport = props.sport;

  if (sport === sorareBaseball) {
    return (
      <div className={"grid grid-cols-5 gap-x-4 self-center bg-white py-2 px-6 rounded-lg shadow-sm border border-grey-f2"}>
        <PositionPickerBox position={"SP"} label={"SP"} change={(p) => props.changePositions(p)} positions={positions} single={true} />
        <PositionPickerBox position={"RP"} label={"RP"} positions={positions} change={(p) => props.changePositions(p)} single={true} />
        <PositionPickerBox position={"OF"} label={"OF"} positions={positions} change={(p) => props.changePositions(p)} single={true} />
        <PositionPickerBox position={"MI"} label={"MI"} positions={positions} change={(p) => props.changePositions(p)} single={true} />
        <PositionPickerBox position={"CI"} label={"CI"} positions={positions} last change={(p) => props.changePositions(p)} single={true} />
      </div>
    );
  } else {
    return (
      <div className={"grid grid-cols-4 gap-x-4 self-center bg-white py-1 px-6 rounded-lg shadow-sm border border-grey-f2"}>
        <PositionPickerBox
          position={"Goalkeeper"}
          label={"Gk"}
          change={(p) => props.changePositions(p)}
          positions={positions}
          single={true}
        />
        <PositionPickerBox
          position={"Defender"}
          label={"Def"}
          positions={positions}
          change={(p) => props.changePositions(p)}
          single={true}
        />
        <PositionPickerBox
          position={"Midfielder"}
          label={"Mid"}
          positions={positions}
          change={(p) => props.changePositions(p)}
          single={true}
        />
        <PositionPickerBox
          position={"Forward"}
          label={"For"}
          positions={positions}
          change={(p) => props.changePositions(p)}
          single={true}
          last={true}
        />
      </div>
    );
  }
}

function PositionPicker(props) {
  const positions = props.positions;
  const sport = props.sport;

  if (sport === sorareBaseball) {
    return (
      <div className={"grid grid-cols-5 gap-x-4 self-center bg-white py-2 px-6 rounded-lg shadow-sm border border-grey-f2"}>
        <PositionPickerBox position={"SP"} label={"SP"} change={(p) => props.changePositions(p)} positions={positions} />
        <PositionPickerBox position={"RP"} label={"RP"} positions={positions} change={(p) => props.changePositions(p)} />
        <PositionPickerBox position={"OF"} label={"OF"} positions={positions} change={(p) => props.changePositions(p)} />
        <PositionPickerBox position={"MI"} label={"MI"} positions={positions} change={(p) => props.changePositions(p)} />
        <PositionPickerBox position={"CI"} label={"CI"} positions={positions} last change={(p) => props.changePositions(p)} />
      </div>
    );
  } else if (sport === sorareBasketball) {
    return (
      <div className={"grid grid-cols-3 gap-x-4 self-center bg-white py-2 px-6 rounded-lg shadow-sm border border-grey-f2"}>
        <PositionPickerBox position={"G"} label={"G"} change={(p) => props.changePositions(p)} positions={positions} />
        <PositionPickerBox position={"F"} label={"F"} positions={positions} change={(p) => props.changePositions(p)} />
        <PositionPickerBox position={"C"} label={"C"} positions={positions} last change={(p) => props.changePositions(p)} />
      </div>
    );
  } else {
    return (
      <div className={"grid grid-cols-4 gap-x-4 self-center bg-white py-2 px-6 rounded-lg shadow-sm border border-grey-f2"}>
        <PositionPickerBox position={"Goalkeeper"} label={"Gk"} change={(p) => props.changePositions(p)} positions={positions} />
        <PositionPickerBox position={"Defender"} label={"Def"} positions={positions} change={(p) => props.changePositions(p)} />
        <PositionPickerBox position={"Midfielder"} label={"Mid"} positions={positions} change={(p) => props.changePositions(p)} />
        <PositionPickerBox position={"Forward"} label={"For"} positions={positions} last change={(p) => props.changePositions(p)} />
      </div>
    );
  }
}

export { SinglePositionPicker, PositionPicker };
