import React from "react";
import { PlayingStatus } from "../util/playingStatus";

function ComboSelectedListItem(props) {
  const player = props.player;
  return (
    <div
      className={"h-16 hover:shadow cursor-pointer hover:bg-orange-50"}
      onClick={() => {
        if (!props.lock) props.removeFromCombo(player);
      }}
    >
      <div className={"col-span-2 flex flex-row"}>
        <div className={"w-16"}>
          <img className={"h-16 w-auto"} src={player.Avatar} />
        </div>
        <div className={"self-center"}>
          <p className={"text-med font-semibold"}>{player.DisplayName}</p>
          <p className={"text-xs"}>
            <span>
              {player.Position} - <PlayingStatus player={player} status={player.PlayingStatus} /> - {player.Age}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ComboSelectedListItem;
