import { getNavigatorLang } from "./locale";

export function formatGrowth(value, unit, lang) {
  if (value === undefined) {
    return "---";
  }
  const localeLang = lang || getNavigatorLang();
  return new Intl.NumberFormat(localeLang, {
    style: "percent",
    signDisplay: "exceptZero",
    maximumSignificantDigits: 4,
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  }).format(value);
}

export function formatPercentage(value, unit, lang) {
  if (value === undefined) {
    return "---";
  }
  const localeLang = lang || getNavigatorLang();
  return new Intl.NumberFormat(localeLang, {
    style: "percent",
    signDisplay: "auto",
    maximumSignificantDigits: 4,
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  }).format(value);
}

export function formatFactor(value, unit, lang) {
  if (value === undefined) {
    return "---";
  }
  const localeLang = lang || getNavigatorLang();
  return new Intl.NumberFormat(localeLang, {
    signDisplay: "auto",
    maximumSignificantDigits: 3,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(value);
}
