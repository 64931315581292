import React from "react";
import { getCardSupply } from "../util/cardSupply";
import clsx from "clsx";

function CardLink(props) {
  let scarcity = props.card.Scarcity;
  const sport = props.sport;
  const supply = getCardSupply(sport, props.card);
  let color = "text-scarcity-common-on-surface-container";
  if (scarcity === "LIMITED") {
    color = "text-scarcity-limited-on-surface-container";
  } else if (scarcity === "RARE") {
    color = "text-scarcity-rare-on-surface-container";
  } else if (scarcity === "SUPER RARE") {
    color = "text-scarcity-super-rare-on-surface-container";
  } else if (scarcity === "UNIQUE") {
    color = "text-scarcity-unique-on-surface-container";
  } else if (scarcity === "CUSTOM SERIES") {
    color = "text-scarcity-custom-series-on-surface-container";
  }
  let size = props.size || "text-base";
  return (
    <span className={clsx(size, "font-medium")}>
      <a className={color} href={"/card/" + props.card.TokenId}>
        {props.card.SerialNumber}/{supply} ({props.card.Season})
      </a>
    </span>
  );
}

export default CardLink;
