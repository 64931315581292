import { useQuery } from "../util/useQuery";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { errorCatcher } from "../util/errors";
import { ReactComponent as ArrowLeftIcon } from "@material-design-icons/svg/filled/chevron_left.svg";
import { ReactComponent as TrophyIcon } from "../../img/icons-trophy-no-color.svg";
import { ReactComponent as FilterIcon } from "@material-design-icons/svg/filled/filter_alt.svg";
import { ReactComponent as FootballIcon } from "../../img/icon-sports-soccer-no-color.svg";
import { ReactComponent as BasketballIcon } from "../../img/icon-sports-basketball-no-color.svg";
import { ReactComponent as BaseballIcon } from "../../img/icon-sports-baseball-no-color.svg";
import { withUser } from "../../userContext";
import BoxWithToggle from "../util/boxWithToggle";
import { SearchInput } from "../util/input";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import { LeagueResultTiles, LongTermLeagueResultTiles } from "./longTermLeagueResultTiles";
import { MonthInfoBox, MonthRewardsBox, UserBox } from "./monthLong";
import RankLabel from "../util/rankLabel";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { getThemeColor, useTheme } from "../../themeContext";
import { formatNumber } from "../util/formatNumber";
import LiveCircle from "../util/liveCircle";
import { ReactComponent as PlayersLeftIcon } from "../../img/players-left-icon.svg";
import { ReactTooltip } from "../util/tooltip";
import Spinner from "../loader/spinner";
import PopHoverWrapper from "../util/popHoverWrapper";
import { CardReward, CoinsReward, MoneyReward } from "../SO5/SO5LiveDivision";
import { findPriceForUserUnit } from "../util/formatMoney";
import PopperPortal from "../players/helpers/popper";
import { ControlledShortLineup } from "../lineup/shortLineup";
import useDebounce from "../../hooks/useDebounce";
import { NoCardResultsImage } from "../util/noCardResultsImage";
import InfiniteScroll from "react-infinite-scroll-component";
import { MobileModale } from "../util/mobileModale";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import { XIcon } from "@heroicons/react/outline";
import { LeagueSelector, PeriodSelector } from "./longTermLeaguesSelectors";
import { sorareBaseball, sorareFootball, sportLabelsSingleSimple } from "../util/sports";
import { LeaderboardProgress } from "./longTermLeaguesLeaderboardProgress";
import { Filters } from "./longTermLeaguesLeaderboardFilters";
import { LeaderboardTiles } from "./longTermLeaguesLeaderboardTiles";
import { LeaderboardTables } from "./longTermLeaguesLeaderboardTable";

export const LongTermLeaguesLeaderboardPage = (props) => {
  const query = useQuery();
  const sport = props.match.params.sport || sorareFootball;
  const leagueId = query.get("leagueId") || "";
  const sorareSlug = query.get("sorareSlug") || props.sorareSlug || "";
  const sorareId = query.get("sorareId") || props.sorareId || "";
  const footballFilters = {
    unfinishedOnly: query.get("unfinishedOnly") === "true",
    managerName: query.get("managerName") || "",
    canBeatMe: query.get("canBeatMe") === "true",
  };
  const basketballFilters = {
    managerName: query.get("managerName") || "",
    unfinishedOnly: query.get("unfinishedOnly") === "true",
    // canBeatMe not implemented yet for basketball
  };

  const filters = sport === sorareFootball ? footballFilters : basketballFilters;
  return (
    <div className={"w-full h-full bg-surface"}>
      <div
        className={`lg:mx-20 mx-4 md:mx-12 xl:w-10/12 2xl:w-10/12 2.5xl:w-9/12 3xl:w-8/12 3.5xl:w-7/12 4xl:w-6/12 2xl:mx-auto pb-8 py-8`}
      >
        <LongTermLeaguesLeaderboardComponent
          leagueId={leagueId}
          filters={filters}
          sorareSlug={sorareSlug}
          sorareId={sorareId}
          sport={sport}
        />
      </div>
    </div>
  );
};

const limitAPI = 30;

const defaultFilters = {
  unfinishedOnly: false,
  managerName: "",
  canBeatMe: false,
};

const LongTermLeaguesLeaderboardComponent = withUser((props) => {
  const { sorareSlug, sorareId, sport } = props;
  const [loading, setLoading] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);

  const [leaderboard, setLeaderboard] = useState(null);
  const [userLeaderboard, setUserLeaderboard] = useState(null);
  const [authenticatedUserLeaderboard, setAuthenticatedUserLeaderboard] = useState(null);
  const [leagues, setLeagues] = useState(null);
  const [periodSummary, setPeriodSummary] = useState(null);
  const [monthLongPeriods, setMonthLongPeriods] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [leagueProgress, setLeagueProgress] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [filters, setFilters] = useState(props.filters || defaultFilters);

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    const sportLabel = sportLabelsSingleSimple.find((s) => s.value === props.sport).name;
    document.title = sportLabel + " Month-long leaderboard";
  }, []);

  const getLeaguesAndPeriods = (userSlug, userSorareId) => {
    setLoading(true);
    const newParams = new URLSearchParams();
    if (userSlug) {
      // old way to identify someone
      newParams.append("userSorareSlug", userSlug);
    }
    if (userSorareId) {
      // new way to identify someone (more reliable)
      newParams.append("userSorareId", userSorareId);
    }
    props
      .fetch(`/apiv2/longTermLeagues/${sport}/all?${newParams.toString()}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          const leagues = res.leagues || {};
          setLeagues(leagues);
          const monthLongPeriods = res.periods.monthLong || [];
          setMonthLongPeriods(monthLongPeriods);
          const selectedLeague = query.get("leagueId");
          if (selectedLeague === "" || selectedLeague === null || selectedLeague === undefined) {
            // user did not select any league
            if (monthLongPeriods.length > 0) {
              const lastPeriod = monthLongPeriods[monthLongPeriods.length - 1];
              setSelectedPeriod(lastPeriod);
              if (lastPeriod.leagueIds.length > 0) {
                setSelectedLeague(lastPeriod.leagueIds[0]);
              }
            }
          } else {
            const league = leagues[selectedLeague];
            const period = monthLongPeriods.find((p) => p.slug === league.periodSlug);
            setSelectedPeriod(period);
            setSelectedLeague(selectedLeague);
          }
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const getPeriodSummary = (userSlug, userSorareId, year, month) => {
    if (!year || !month) {
      return;
    }
    setLoadingSummary(true);
    const newParams = new URLSearchParams();
    if (userSlug) {
      newParams.append("userSorareSlug", userSlug);
    }
    if (userSorareId) {
      // new way to identify someone (more reliable)
      newParams.append("userSorareId", userSorareId);
    }
    props
      .fetch(`/apiv2/longTermLeagues/${sport}/summary/monthLong/${year}/${month}?${newParams.toString()}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setPeriodSummary(res || {});
        }
        setLoadingSummary(false);
      })
      .catch(
        errorCatcher(() => {
          setLoadingSummary(false);
        }),
      );
  };

  const getLeagueProgress = (leagueId) => {
    if (!leagueId) {
      return;
    }
    setLoadingProgress(true);
    props
      .fetch(`/apiv2/longTermLeagues/${sport}/id/${leagueId}/progress`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setLeagueProgress(res || {});
        }
        setLoadingProgress(false);
      })
      .catch(
        errorCatcher(() => {
          setLoadingProgress(false);
        }),
      );
  };

  const getLeagueLeaderboard = (userSlug, userSorareId, leagueId, offset, f) => {
    if (!leagueId) {
      return;
    }
    setLoadingLeaderboard(true);
    const newParams = new URLSearchParams();
    if (userSlug) {
      newParams.append("userSorareSlug", userSlug);
    }
    if (userSorareId) {
      // new way to identify someone (more reliable)
      newParams.append("userSorareId", userSorareId);
    }
    if (f === undefined) {
      f = {};
      if (filters.unfinishedOnly !== undefined && filters.unfinishedOnly !== null) {
        f.unfinishedOnly = filters.unfinishedOnly;
      }
      if (filters.managerName !== undefined && filters.managerName !== null) {
        f.managerName = filters.managerName;
      }
      if (filters.canBeatMe !== undefined && filters.canBeatMe !== null) {
        f.canBeatMe = filters.canBeatMe;
      }
    }
    if (f.unfinishedOnly !== undefined && f.unfinishedOnly !== null) {
      newParams.append("unfinishedOnly", `${f.unfinishedOnly}`);
    }
    if (f.managerName !== undefined && f.managerName !== null) {
      newParams.append("managerName", f.managerName);
    }
    if (f.canBeatMe !== undefined && f.canBeatMe !== null) {
      newParams.append("canBeatMe", `${f.canBeatMe}`);
    }
    props
      .fetch(`/apiv2/longTermLeagues/${sport}/id/${leagueId}/leaderboard?${newParams.toString()}`, {
        method: "GET",
        headers: withJSONPagination(offset, limitAPI),
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        if (res.error === undefined) {
          const resLeaderboard = res?.leaderboard || [];
          const newLeaderboard = {
            list: offset === 0 ? resLeaderboard : [...leaderboard.list, ...resLeaderboard],
            hasNext: hasNext,
            offset: offset + limitAPI,
          };
          setLeaderboard(newLeaderboard);
          setUserLeaderboard(res.userLeaderboard);
          setAuthenticatedUserLeaderboard(res.authenticatedUserLeaderboard);
        }
        setLoadingLeaderboard(false);
      })
      .catch(
        errorCatcher(() => {
          setLoadingLeaderboard(false);
        }),
      );
  };

  useEffect(() => {
    getLeagueLeaderboard(sorareSlug, sorareId, selectedLeague, 0);
    getLeagueProgress(selectedLeague);
  }, [selectedLeague]);

  useEffect(() => {
    getLeagueLeaderboard(sorareSlug, sorareId, selectedLeague, 0, filters);
  }, [filters]);

  useEffect(() => {
    getLeaguesAndPeriods(props.sorareSlug, props.sorareId);
  }, [props.sorareSlug, props.sorareId]);

  useEffect(() => {
    if (selectedPeriod) {
      getPeriodSummary(props.sorareSlug, props.sorareId, selectedPeriod.year, selectedPeriod.month);
    }
  }, [selectedPeriod, props.sorareId]);

  const onChangePeriod = (periodSlug) => {
    const period = monthLongPeriods.find((p) => p.slug === periodSlug);
    setSelectedPeriod(period);
    onChangeLeague(period.leagueIds[0]);
  };

  const onChangeLeague = (leagueId) => {
    setSelectedLeague(leagueId);
    const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
    newParams.set("leagueId", leagueId);
    history.replace({
      pathname: location.pathname,
      search: newParams.toString(),
    });
  };

  const onChangeFilters = (filters) => {
    setFilters(filters);
    const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
    if (filters.managerName !== undefined && filters.managerName !== null) {
      newParams.set("managerName", filters.managerName);
    }
    if (filters.unfinishedOnly !== undefined && filters.unfinishedOnly !== null) {
      newParams.set("unfinishedOnly", `${filters.unfinishedOnly}`);
    }
    if (filters.canBeatMe !== undefined && filters.canBeatMe !== null) {
      newParams.set("canBeatMe", `${filters.canBeatMe}`);
    }

    history.replace({
      pathname: location.pathname,
      search: newParams.toString(),
    });
  };

  const onLoadMore = () => {
    getLeagueLeaderboard(sorareSlug, sorareId, selectedLeague, leaderboard.offset);
  };

  const selectedLeagueObj = leagues?.[selectedLeague] || {};
  const goBackDisabled = history === undefined || history.length <= 2;
  const SportIcon = sport === sorareFootball ? FootballIcon : sport === sorareBaseball ? BaseballIcon : BasketballIcon;
  return (
    <div className={"w-full flex flex-col gap-3 bg-surface text-on-surface"}>
      <div className={"flex flex-row justify-start"}>
        <div
          className={`flex flex-row gap-1 items-center ${goBackDisabled ? "opacity-40" : "cursor-pointer hover:opacity-80"}`}
          onClick={goBackDisabled ? undefined : () => history.goBack()}
        >
          <span>
            <ArrowLeftIcon className={"h-8 w-8 fill-on-surface"} />
          </span>
          <p className={"text-lg text-on-surface font-headers font-semibold"}>Back</p>
        </div>
      </div>
      <div className={"flex flex-row gap-2 items-center pl-2"}>
        <SportIcon className={"fill-on-surface h-5 w-5"} />
        <h2 className={"text-xl font-headers font-semibold text-on-surface m-0"}>Month-long leaderboards</h2>
      </div>
      <div className={"flex flex-col xl:hidden gap-3"}>
        <div className={"h-10 max-w-[20em]"}>
          <PeriodSelector loading={loading} periods={monthLongPeriods} selected={selectedPeriod?.slug || ""} onSelect={onChangePeriod} />
        </div>
        <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3"}>
          <div className={"flex flex-col gap-3"}>
            <MonthInfoBox loading={periodSummary === null || loadingSummary} info={periodSummary?.info} />
            <UserBox loading={periodSummary === null || loadingSummary} user={periodSummary?.user} />
          </div>
          <div className={"hidden sm:flex"}>
            <MonthRewardsBox loading={periodSummary === null || loadingSummary} rewards={periodSummary?.rewards} />
          </div>
        </div>
        <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3"}>
          <LeagueResultTiles sport={sport} leagues={periodSummary?.leagues} userSlug={sorareSlug} onSelectLeague={onChangeLeague} />
        </div>
        <div className={"flex h-10"}>
          <Filters
            sport={sport}
            loading={loadingLeaderboard}
            loadingLeagues={loading}
            filters={filters}
            leagues={leagues}
            selectedLeague={selectedLeague}
            onChangeLeague={onChangeLeague}
            selectedPeriod={selectedPeriod}
            onChangeFilters={onChangeFilters}
          />
        </div>
        <div className={"flex"}>
          <LeaderboardProgress sport={sport} progress={leagueProgress} loading={loadingProgress} />
        </div>
        <div className={"block md:hidden"}>
          <LeaderboardTiles
            sport={sport}
            league={selectedLeagueObj}
            userSlug={sorareSlug}
            leaderboard={leaderboard}
            authenticatedUserLeaderboard={authenticatedUserLeaderboard}
            userLeaderboard={userLeaderboard}
            loading={loadingLeaderboard}
            onLoadMore={onLoadMore}
          />
        </div>
        <div className={"hidden md:block"}>
          <LeaderboardTables
            sport={sport}
            league={selectedLeagueObj}
            userSlug={sorareSlug}
            leaderboard={leaderboard}
            authenticatedUserLeaderboard={authenticatedUserLeaderboard}
            userLeaderboard={userLeaderboard}
            loading={loadingLeaderboard}
            onLoadMore={onLoadMore}
          />
        </div>
      </div>
      <div className={"hidden xl:flex flex-col gap-3"}>
        <div className={"flex flex-row gap-5"}>
          <div className={"w-[25%]"}>
            <div className={"flex h-full"}>
              <PeriodSelector
                sport={sport}
                loading={loading}
                periods={monthLongPeriods}
                selected={selectedPeriod?.slug || ""}
                onSelect={onChangePeriod}
              />
            </div>
          </div>
          <div className={"w-[75%]"}>
            <Filters
              sport={sport}
              loading={loadingLeaderboard}
              loadingLeagues={loading}
              filters={filters}
              leagues={leagues}
              selectedLeague={selectedLeague}
              onChangeLeague={onChangeLeague}
              selectedPeriod={selectedPeriod}
              onChangeFilters={onChangeFilters}
            />
          </div>
        </div>
        <div className={"flex flex-col lg:flex-row gap-5"}>
          <div className={"w-[25%] flex"}>
            <MonthInfoBox loading={loading} info={periodSummary?.info} />
          </div>
          <div className={"w-[75%] h-full"}>
            <LeaderboardProgress progress={leagueProgress} loading={loadingProgress} />
          </div>
        </div>
        <div className={"flex flex-col md:flex-row gap-5"}>
          <div className={"w-[25%] flex flex-col gap-3 h-10"}>
            <PeriodSummaryLayout
              sport={sport}
              summary={periodSummary}
              loading={periodSummary === null || loadingSummary}
              userSlug={sorareSlug}
              onSelectLeague={onChangeLeague}
            />
          </div>
          <div className={"w-[75%] flex flex-col gap-3"}>
            <LeaderboardTables
              sport={sport}
              league={selectedLeagueObj}
              userSlug={sorareSlug}
              leaderboard={leaderboard}
              authenticatedUserLeaderboard={authenticatedUserLeaderboard}
              userLeaderboard={userLeaderboard}
              loading={loadingLeaderboard}
              onLoadMore={onLoadMore}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
const PeriodSummaryLayout = (props) => {
  const { summary, loading, user, userSlug, onSelectLeague } = props;
  return (
    <div className={"flex flex-col gap-3"}>
      <UserBox loading={loading} user={summary?.user} />
      <MonthRewardsBox loading={loading} rewards={summary?.rewards} />
      <LeagueResultTiles leagues={summary?.leagues} userSlug={userSlug} onSelectLeague={onSelectLeague} />
    </div>
  );
};
