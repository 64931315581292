import { t1OrAbove } from "./handleSubscriptionTier";
import { isSportFree } from "./sports";
import React, { useEffect } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import BoxWithToggle from "./boxWithToggle";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { Dropdown } from "./dropdown";

const availability_status_objects_mulitple = [
  { value: "Available", name: "No injury or suspension" },
  { value: "Suspension", name: "Suspended" },
  { value: "Injury", name: "Injured" },
  { value: "Unknown", name: "Unknown" },
];

const availability_status_objects = [
  {
    value: "all",
    name: "All injury/suspension status",
  },
  ...availability_status_objects_mulitple,
];

export const AvailabilityStatusSelector = (props) => {
  const { availabilityStatus, onChange, multiple, tier } = props;
  const placeholder = props.placeholder || "Filter by availability status";
  const className = props.className || "select-search border border-grey-f2 rounded";
  const premiumNeeded = !t1OrAbove(tier) && (props.sport === undefined || !isSportFree(props.sport));
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [availabilityStatus]);
  if (!premiumNeeded) {
    return (
      <SelectSearch
        options={multiple ? availability_status_objects_mulitple : availability_status_objects}
        className={className}
        value={availabilityStatus}
        multiple={multiple}
        closeOnSelect={false}
        onChange={onChange}
        filterOptions={fuzzySearch}
        placeholder={placeholder}
        printOptions={"on-focus"}
        disabled={premiumNeeded}
      />
    );
  }
  return (
    <div className={`h-full bg-focus shadow py-2 rounded-md`}>
      <ReactTooltip id={"availability-status-selector-component"} />
      <BoxWithToggle
        label={placeholder}
        isActive={false}
        disabled={premiumNeeded}
        disabledTip={"Star membership is needed"}
        className={"h-full"}
      />
    </div>
  );
};

export const AvailabilityStatusSelectorNew = (props) => {
  const { availabilityStatus, onChange, multiple, tier } = props;
  const placeholder = props.placeholder || "Filter by availability status";
  const premiumNeeded = !t1OrAbove(tier) && (props.sport === undefined || !isSportFree(props.sport));
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [availabilityStatus]);
  if (!premiumNeeded) {
    return (
      <Dropdown
        options={multiple ? availability_status_objects_mulitple : availability_status_objects}
        selected={availabilityStatus}
        multiple={multiple}
        onChange={onChange}
        placeholder={placeholder}
        disabled={premiumNeeded}
        displayOptions={{ borders: true }}
      />
    );
  }
  return (
    <div className={`h-full bg-surface-container border border-transparent-inverse-surface-low border-opacity-10 shadow py-2 rounded-md`}>
      <ReactTooltip id={"availability-status-selector-component"} />
      <BoxWithToggle
        label={placeholder}
        isActive={false}
        disabled={premiumNeeded}
        disabledTip={"Star membership is needed"}
        className={"h-full"}
      />
    </div>
  );
};
