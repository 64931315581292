import React from "react";
import RisingPlayersBox from "./risingPlayersBox";
import MostSoldPlayersBox from "./mostSoldPlayersBox";

function RisingPlayers(props) {
  const weeklyPlayers = props.mostSoldPlayers?.most_sold_week;
  const monthlyPlayers = props.mostSoldPlayers?.most_sold_month;
  const risingPlayers = props.risingPlayers?.rising_players;
  const fallingPlayers = props.risingPlayers?.falling_players;

  if (weeklyPlayers && monthlyPlayers && risingPlayers && fallingPlayers) {
    return (
      <div className={"grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 grid-rows-auto flex flex-row gap-4 w-full xl:mx-0"}>
        <MostSoldPlayersBox players={weeklyPlayers} title={"Most sold rare players "} period={"- 2 weeks"} />
        <MostSoldPlayersBox players={monthlyPlayers} title={"Most sold rare players "} period={"- this month"} />
        <RisingPlayersBox players={risingPlayers} title={"Rising players "} period={"- 2 weeks"} />
        <RisingPlayersBox players={fallingPlayers?.slice().reverse()} title={"Falling players "} period={"- 2 weeks"} />
      </div>
    );
  } else return null;
}

export default RisingPlayers;
