import React, { useEffect } from "react";
import { Box, Text } from "grommet";
import { Cell, LabelList, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import { ReactTooltip } from "../util/tooltip.js";
import { format } from "date-fns";
import { baseballScale, basketballScale, footballScale } from "../util/scales";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";

function PlayerStatsGraph(props) {
  // remove duplicate x values (else the graph displays nothing)
  let dataWithUniqueX = props.data
    ? props.data.reduce((acc, current) => {
        if (!acc[current.x] || (current.played && !acc[current.x].played)) {
          acc[current.x] = current;
        }
        return acc;
      }, {})
    : {};

  let data = Object.values(dataWithUniqueX);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const sport = props.sport;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload[0]) {
      let receiving = "Hosting";
      let opponent = "";
      let playerTeamId = payload[0].payload.player_team_id;
      if (payload[0].payload.away_id === playerTeamId) {
        receiving = "At";
        opponent = payload[0].payload.home_display_name;
      } else if (payload[0].payload.home_id === playerTeamId) {
        opponent = payload[0].payload.away_display_name;
      }
      if (sport === sorareBaseball || sport === sorareBasketball) {
        let score = payload[0].payload.y;
        return (
          <Box elevation={"small"} pad={"small"} background={{ color: "white" }} gap={"small"}>
            <Box>
              <Text size={"small"}>{format(new Date(payload[0].payload.x * 1000), "MMM do")}</Text>
              <Text size={"small"} weight={500}>
                Scored {score} pts
              </Text>
              <Text size={"small"}>
                {receiving} {opponent} ({payload[0].payload.game.HomeScore}-{payload[0].payload.game.AwayScore})
              </Text>
            </Box>
          </Box>
        );
      } else {
        let level = payload[0].payload.level;
        let score = payload[0].payload.Score;
        let aa = payload[0].payload.all_around_score;
        if (props.position) {
          if (props.position === "Goalkeeper") {
            score = payload[0].payload.SO5ScoreGK;
            aa = payload[0].payload.AaGK || payload[0].payload.all_around_score;
            level = payload[0].payload.LevelGK || payload[0].payload.level;
          } else if (props.position === "Defender") {
            score = payload[0].payload.SO5ScoreDF;
            aa = payload[0].payload.AaDF || payload[0].payload.all_around_score;
            level = payload[0].payload.LevelFD || payload[0].payload.level;
          } else if (props.position === "Midfielder") {
            score = payload[0].payload.SO5ScoreMD;
            aa = payload[0].payload.AaMD || payload[0].payload.all_around_score;
            level = payload[0].payload.LevelFD || payload[0].payload.level;
          } else if (props.position === "Forward") {
            score = payload[0].payload.SO5ScoreFW;
            aa = payload[0].payload.AaFW || payload[0].payload.all_around_score;
            level = payload[0].payload.LevelFD || payload[0].payload.level;
          }
        }
        return (
          <Box elevation={"small"} pad={"small"} background={{ color: "white" }} gap={"small"}>
            <Box>
              <Text size={"small"}>GW #{payload[0].payload.sorareGwNumber}</Text>
              <Text size={"small"} weight={500}>
                Scored {score} pts in {payload[0].payload.MinsPlayed} mins
              </Text>
              <Text size={"small"}>
                {receiving} {opponent} ({payload[0].payload.game.HomeGoals}-{payload[0].payload.game.AwayGoals})
              </Text>
            </Box>
            {level > 0 && (
              <Box>
                <Text size={"small"}>Decisive score: {level}</Text>
                <Text size={"small"}>All-around score: {aa.toFixed(1)}</Text>
              </Box>
            )}
          </Box>
        );
      }
    }

    return null;
  };

  let dataKey = "y";
  let labelKey = "Label";
  let domain = [0, 100];

  const period = props.period;
  let scale = footballScale;
  if (sport === sorareBaseball) {
    scale = baseballScale;
    dataKey = "y";
    domain = "auto";
  } else if (sport === sorareBasketball) {
    scale = basketballScale;
    dataKey = "y";
    domain = [-6, 100];
  } else if (sport === sorareFootball) {
    dataKey = "Score";
    if (props.position) {
      if (props.position === "Goalkeeper") {
        dataKey = "SO5ScoreGK";
        labelKey = "LabelGK";
      } else if (props.position === "Defender") {
        dataKey = "SO5ScoreDF";
        labelKey = "LabelDF";
      } else if (props.position === "Midfielder") {
        dataKey = "SO5ScoreMD";
        labelKey = "LabelMD";
      } else if (props.position === "Forward") {
        dataKey = "SO5ScoreFW";
        labelKey = "LabelFW";
      }
    }
  }

  if (data !== null && data !== undefined) {
    return (
      <div>
        <div className={"w-full mx-auto h-96 xl:h-40v my-4 text-sm bg-focus rounded-lg"}>
          <ResponsiveContainer>
            <ScatterChart
              data={data}
              margin={{
                top: 40,
                right: 20,
                bottom: 20,
                left: 10,
              }}
            >
              <defs>
                <linearGradient id="horrible-score" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#F11B0E" stopOpacity={1} />
                  <stop offset="100%" stopColor="#BC190F" stopOpacity={1} />
                </linearGradient>
                <linearGradient id="bad-score" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FFA31A" stopOpacity={1} />
                  <stop offset="100%" stopColor="#E1921B" stopOpacity={1} />
                </linearGradient>
                <linearGradient id="ok-score" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#EECE24" stopOpacity={1} />
                  <stop offset="100%" stopColor="#F1CE17" stopOpacity={1} />
                </linearGradient>
                <linearGradient id="good-score" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#B6CC2C" stopOpacity={1} />
                  <stop offset="100%" stopColor="#ABC21D" stopOpacity={1} />
                </linearGradient>
                <linearGradient id="great-score" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#2CCC89" stopOpacity={1} />
                  <stop offset="100%" stopColor="#1DC29B" stopOpacity={1} />
                </linearGradient>
                <linearGradient id="amazing-score" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#00B93F" stopOpacity={1} />
                  <stop offset="100%" stopColor="#019355" stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="x"
                scale={sport === sorareBaseball ? "time" : "auto"}
                tickFormatter={(d, i) => {
                  if (sport === sorareBaseball) {
                    return format(new Date(d * 1000), "MMM dd");
                  } else if (sport === sorareBasketball) {
                    if (d > 0) {
                      return format(new Date(d * 1000), "MMM dd");
                    }
                  }
                  return (data ? data[i]?.sorareGwNumber : d) || d;
                }}
                name={"Gameweek"}
                style={{
                  fontSize: "0.65rem",
                  fontFamily: "Inter, sans-serif",
                  marginTop: "10px",
                  fill: "#000000",
                  fontWeight: 500,
                }}
              />
              <YAxis
                dataKey={dataKey}
                name="Score"
                domain={domain}
                style={{
                  fontSize: "0.65rem",
                  fontFamily: "Inter, sans-serif",
                  marginTop: "10px",
                  fill: "#000000",
                  fontWeight: 500,
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Scatter data={data}>
                <LabelList
                  dataKey={labelKey}
                  style={{
                    pointerEvents: "none",
                    fontSize: "11px",
                    fontWeight: "500",
                  }}
                  position={"top"}
                />
                {data.map((entry, index) => {
                  let color = "url(#horrible-score)";
                  if (entry[dataKey] >= scale[4]) {
                    color = "url(#amazing-score)";
                  } else if (entry[dataKey] >= scale[3]) {
                    color = "url(#great-score)";
                  } else if (entry[dataKey] >= scale[2]) {
                    color = "url(#good-score)";
                  } else if (entry[dataKey] >= scale[1]) {
                    color = "url(#ok-score)";
                  } else if (entry[dataKey] >= scale[0]) {
                    color = "url(#bad-score)";
                  }
                  if (entry.Played !== true) color = "#292929";
                  return <Cell key={`cell-${index}`} fill={color} />;
                })}
              </Scatter>
            </ScatterChart>
          </ResponsiveContainer>
        </div>
        <ReactTooltip />
      </div>
    );
  } else {
    return null;
  }
}

export default PlayerStatsGraph;
