import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { withUser } from "../../userContext";
import { formatPrice } from "./formatMoney";
import { Button } from "./button";
import { errorCatcher } from "./errors";
import { scarcities_objects_not_all } from "./scarcities";
import { ReactTooltip } from "../util/tooltip.js";
import { renderToString } from "react-dom/server";
import NewCardIcon from "./newCardIcon";
import NotificationsList from "./notificationsList";
import Spinner from "../loader/spinner";
import { sorareBaseball } from "../util/sports";

function NotificationSettingsModal(props) {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [pendingNotifications, setPendingNotifications] = useState({});
  const [msg, setMsg] = useState("");
  const [limitations, setLimitations] = useState({});
  const player = props.player;

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      setPendingNotifications({});
      getNotifications();
      getLimitations();
    }
  }, [props.open, props.player]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [loading]);

  const getNotifications = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/notifications/cards/offers/players/${player.PlayerId}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setNotifications(res);
          setMsg("");
        } else {
          setMsg(res.error);
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const saveNotifications = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/notifications/cards/offers/players/${player.PlayerId}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pendingNotifications),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setNotifications(res);
          setPendingNotifications({});
          setMsg("");
          props.onSaveSuccess && props.onSaveSuccess(res);
        } else {
          setMsg(res.error);
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const getLimitations = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/notifications/cards/offers/new/can`)
      .then((response) => response.json())
      .then((res) => {
        setLimitations(res);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const activatePriceAlert = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/notifications/cards/offers/players/${player.PlayerId}/enable`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setNotifications(res);
          setPendingNotifications({});
          setMsg("");
          props.onSaveSuccess && props.onSaveSuccess(res);
        } else {
          setMsg(res.error);
        }
        getLimitations();
        setLoading(false);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  const onPriceAlertChange = (notification) => {
    const { scarcity } = notification;
    updatePendingNotifications({ [scarcity]: notification });
  };

  const removePriceAlert = (scarcities) => {
    scarcities = scarcities !== undefined && scarcities.length > 0 ? scarcities : scarcities_objects_not_all.map((o) => o.value);
    let newNotifs = {};
    scarcities.forEach((s) => (newNotifs[s] = { scarcity: s })); // No price set, so it's seen as removal if previous existed
    updatePendingNotifications(newNotifs);
  };

  const updatePendingNotifications = (newNotifs) => {
    if (newNotifs === undefined || Object.keys(newNotifs).length === 0) {
      return;
    }
    const newNotificationChanges = { ...pendingNotifications };
    Object.keys(newNotifs).forEach((scarcity) => {
      const notification = newNotifs[scarcity];
      const previousNotification = notifications[scarcity];
      if (notification.price === undefined || notification.price <= 0) {
        if (previousNotification === undefined || previousNotification.price === undefined || previousNotification.price <= 0) {
          delete newNotificationChanges[scarcity]; // nothing changed
        } else {
          newNotificationChanges[scarcity] = notification; // will be a deletion when saving
        }
      } else {
        if (previousNotification === undefined || previousNotification.price === undefined || previousNotification.price <= 0) {
          newNotificationChanges[scarcity] = notification;
        } else {
          if (previousNotification.price !== notification.price || previousNotification.unit !== notification.unit) {
            newNotificationChanges[scarcity] = notification;
          } else {
            delete newNotificationChanges[scarcity]; // nothing changed
          }
        }
      }
    });
    setPendingNotifications(newNotificationChanges);
    ReactTooltip.rebuild();
  };

  const resetAllNotifications = () => {
    setPendingNotifications({});
  };

  const pendingNotificationsScarcities = Object.keys(pendingNotifications) || [];
  const hasChanges = pendingNotificationsScarcities.length > 0 ? true : false;

  let hasOnlyDeletions = hasChanges;
  const tooltip = hasChanges
    ? renderToString(
        <ul className={"space-y-2"}>
          {pendingNotificationsScarcities.map((scarcity) => {
            if (!scarcity) {
              hasOnlyDeletions = false;
              return null;
            }
            const toSave = pendingNotifications[scarcity];
            const previous = notifications[scarcity];
            let message = "";
            if (previous === undefined || previous.price === undefined || previous.price === 0) {
              message = `Price alert will be created: ${formatPrice(toSave.price, toSave.unit)}`;
              hasOnlyDeletions = false;
            } else if (toSave === undefined || toSave.price === undefined || toSave.price === 0) {
              message = `Price alert will be deleted`;
            } else {
              message = `Price alert will change: ${formatPrice(previous.price, previous.unit)} ➡️ ${formatPrice(
                toSave.price,
                toSave.unit,
              )}`;
              hasOnlyDeletions = false;
            }
            return (
              <li key={scarcity} className={"flex flex-row gap-2"}>
                <span className={"h-5 w-5"}>
                  <NewCardIcon scarcity={scarcity.toLowerCase()} />
                </span>{" "}
                {message}
              </li>
            );
          })}
        </ul>,
      )
    : undefined;

  const notificationActive =
    notifications !== undefined ? (Object.values(notifications).find((notif) => !notif.active) ? false : true) : undefined;

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      props.close();
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"space-y-4 py-2"}>
                  <h4 className={"text-center text-brand"}>Notification settings for {player.DisplayName}</h4>
                  <p className={"text-xs text-justify w-10/12 mx-auto"}>
                    <span className={"font-semibold text-center"}>
                      You will be alerted for every card of {player.DisplayName} that hits the secondary market listed for under the chosen
                      price.{" "}
                    </span>
                    You can receive notifications through the SorareData app for{" "}
                    <a
                      className={"font-medium hover:font-semibold"}
                      target={"_blank"}
                      href={"https://apps.apple.com/us/app/soraredata/id1590662758"}
                      rel="noreferrer"
                    >
                      iOS
                    </a>{" "}
                    and{" "}
                    <a
                      className={"font-medium hover:font-semibold"}
                      target={"_blank"}
                      href={"https://play.google.com/store/apps/details?id=com.soraredata"}
                      rel="noreferrer"
                    >
                      Android
                    </a>
                    . Enable market notifications through the settings tab by enabling the &quot;When a watchlist player is on the
                    market&quot; toggle.
                  </p>
                  <div className={"flex flex-col items-center mx-auto"}>
                    {msg !== "" && <p className={"text-center font-semibold text-sm"}>{msg}</p>}
                    <NotificationsList
                      notifications={{
                        ...notifications,
                        ...pendingNotifications,
                      }}
                      onChangeNotifications={onPriceAlertChange}
                      onDeleteNotification={removePriceAlert}
                      player={player}
                      limitations={limitations}
                      onActivate={activatePriceAlert}
                      active={notificationActive}
                    />
                  </div>
                  <div className={"grid grid-cols-2 justify-center gap-2"}>
                    <div className={"flex flex-row justify-end"}>
                      <Button
                        context={"secondary"}
                        disabled={!hasChanges}
                        onClick={
                          hasChanges
                            ? () => {
                                resetAllNotifications();
                              }
                            : undefined
                        }
                        label={"Reset"}
                      />
                    </div>
                    <div className={"flex flex-row gap-2"}>
                      {(limitations.creationAuthorized ||
                        limitations.authorizedPlayers?.includes(player.PlayerId) ||
                        player.Sport === sorareBaseball ||
                        hasOnlyDeletions) && (
                        <Button
                          disabled={!hasChanges}
                          onClick={
                            hasChanges
                              ? () => {
                                  saveNotifications();
                                }
                              : undefined
                          }
                          label={"Apply"}
                          data-tip={tooltip}
                          data-html={tooltip ? true : false}
                          data-id={"notifications-settings-modal-apply"}
                        />
                      )}
                      {loading && <Spinner />}
                      {!(
                        limitations.creationAuthorized ||
                        limitations.authorizedPlayers?.includes(player.PlayerId) ||
                        player.Sport === sorareBaseball ||
                        hasOnlyDeletions
                      ) && (
                        <Button
                          disabled={true}
                          label={"Apply"}
                          data-tip={`You reached the limit of ${limitations.max} players with price alerts for your membership. Either: upgrade your membership, remove some to reactivate disabled ones, remove all disabled and some active ones to add new ones`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withUser(NotificationSettingsModal);
