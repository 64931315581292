import React, { useEffect, useState } from "react";

import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import SimpleWatchlistCard from "./watchlistCardSimple";
import SDLoading from "../util/SDLoading";

function TrendingWatchlists(props) {
  const periodLabels = {
    all: "All-time",
    "1-month": "Month",
    "1-week": "Week",
  };

  const [period, setPeriod] = useState("1-month");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getTrendingWatchlists(period);
  }, []);

  const getTrendingWatchlists = (period) => {
    setLoading(true);
    setPeriod(period);
    props
      .fetch(`/apiv2/watchlists/trending?period=${period}`)
      .then((response) => response.json())
      .then(async (res) => {
        setItems(res || []);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };
  return (
    <div>
      <div className="grid grid-cols-1 p-0 gap-2">
        <div className="font-semibold text-med bg-brand-pastel text-center text-brand-text mx-auto rounded-lg w-full p-4">
          Trending watchlists
        </div>
        <div className=" bg-focus mx-auto rounded-lg w-full border border-grey-e9 p-4 text-sm font-sans font-semibold text-textGrey4 grid grid-cols-3 divide-x text-center">
          {Object.keys(periodLabels).map((p, index) => {
            let labelClass = "hover:opacity-90";
            if (period === p) {
              labelClass += " text-brand-text";
            } else {
              labelClass += " hover:text-brand-text";
            }
            return (
              <div key={p} className={`cursor-pointer ${labelClass}`} onClick={() => getTrendingWatchlists(p)}>
                {periodLabels[p]}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col p-0 pb-3 gap-2">
          {loading && <SDLoading />}
          {!loading &&
            items.map((item) => {
              return <SimpleWatchlistCard key={item.id} watchlist={item} />;
            })}
        </div>
      </div>
    </div>
  );
}

export default withUser(TrendingWatchlists);
