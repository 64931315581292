import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { ReactComponent as MenuDotIcon } from "../../img/icon-menu-dot.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import GreyLocker from "../../img/grey-locker.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import Locker from "../../img/brand-close-locker-nude.svg";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../util/button";
import ConfirmModal from "../util/confirmModal";
import Spinner, { Spinning } from "../loader/spinner";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";

const RemoveAllLineupsModale = (props) => {
  return (
    <ConfirmModal
      title={"Lineups removal confirmation"}
      error={props.error}
      message={
        <>
          Do you want to remove all lineups in &quot;{props.draft.draft_name}&quot;?
          <br />
          The draft will be kept but you will lose all your {props.draft.total_lineups} lineup(s).
        </>
      }
      confirming={props.loading}
      open={props.open}
      onConfirm={
        props.confirm
          ? () => {
              props.confirm(props.draft);
            }
          : undefined
      }
      onCancel={props.close}
    />
  );
};

const DraftDeleteModale = (props) => {
  return (
    <ConfirmModal
      title={"Deletion confirmation"}
      error={props.error}
      message={
        <>
          Do you want to remove &quot;{props.draft.draft_name}&quot;?
          <br />
          The draft will be deleted and you will lose all your {props.draft.total_lineups} lineup(s).
        </>
      }
      confirming={props.loading}
      open={props.open}
      onConfirm={
        props.confirm
          ? () => {
              props.confirm(props.draft);
            }
          : undefined
      }
      onCancel={props.close}
    />
  );
};

const DraftEditModale = (props) => {
  const limitations = props.limitations || { creationAuthorized: true };
  const [open, setOpen] = useState(false);
  const [draft, setDraft] = useState(props.draft || { draft_id: "", draft_name: "" });

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  useEffect(() => {
    setDraft(props.draft || { draft_id: "", draft_name: "" });
  }, [props.draft]);

  const isCreation = draft.draft_id === "";
  const canSave = isCreation ? draft.draft_name !== "" && limitations.creationAuthorized : draft.draft_name !== "";
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-surface-container-high text-on-surface rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <div
                    className={"flex flex-row cursor-pointer"}
                    onClick={() => {
                      props.close();
                      setOpen(false);
                    }}
                  >
                    <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
                  </div>
                </div>
                <div className={"flex flex-col items-center mx-auto space-y-4"}>
                  <h1 className={"m-0 p-0 text-xl text-primary"}>{props.title}</h1>
                  <p className={"text-sm text-center"}>
                    <b>Lineup Builder drafts</b> can be used to create different versions of your lineups on the same competition.
                  </p>

                  <div
                    className="flex justify-center w-full"
                    title={
                      limitations.creationAuthorized
                        ? ""
                        : "You can only keep the same name as you reached the limit of presets for your membership"
                    }
                  >
                    <input
                      disabled={!limitations.creationAuthorized}
                      className={`${
                        limitations.creationAuthorized ? "text-on-surface" : "text-on-disable cursor-not-allowed"
                      } bg-surface-container shadow-sm block w-full md:w-8/12 lg:w-6/12 sm:text-sm border-transparent-inverse-surface-low border-opacity-10 rounded-md focus:ring-0 focus:border-primary`}
                      type={"text"}
                      placeholder={"Draft name"}
                      value={draft.draft_name}
                      onChange={(e) => setDraft({ ...draft, draft_name: e.target.value })}
                    />
                  </div>
                  <div className={"relative"}>
                    {props.saving && (
                      <div className={"absolute flex flex-row transform -right-10 mt-1 translate-y-1/2 justify-center self-center"}>
                        <Spinner className={"transform -translate-y-1/2"} />
                      </div>
                    )}
                    <Button
                      disabled={!canSave}
                      label={isCreation ? "Create draft" : "Save draft"}
                      onClick={props.save ? () => props.save(draft) : undefined}
                    />
                  </div>
                  {props.error && (
                    <div>
                      <p className={"font-semibold text-live-on-surface text-sm"}>{props.error}</p>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

const CircularLineupCount = (props) => {
  const { total_lineups } = props;
  return (
    <span
      className="text-xxs font-bold rounded-full flex justify-center items-center text-on-surface bg-surface-container-high w-5 h-5"
      title={`${total_lineups} complete or partial lineup(s) in draft`}
    >
      <span className={"my-auto"}>{total_lineups}</span>
    </span>
  );
};

export const defaultDraft = {
  draft_id: "default",
  draft_name: "Default Draft",
  total_lineups: 0,
  enabled: true,
};

const CustomSelect = ({ loading, options, selected, onChange, onNewDraft, gw, sport, limitations, tier }) => {
  const [focus, setFocus] = useState(false);
  const [tipMessage, setTipMessage] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    ReactTooltip.rebuild();
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setTipMessage(limitations?.creationAuthorized ? "" : "Star membership needed");
    ReactTooltip.rebuild();
  }, [focus, limitations]);

  const creationAuthorized = limitations?.creationAuthorized;
  return (
    <div className={"relative w-full sm:w-auto z-30"} ref={ref}>
      <button
        onClick={gw && sport && !loading ? () => setFocus(!focus) : undefined}
        className={`h-full text-md my-auto bg-surface-container w-full sm:w-80 px-4 py-2 ${
          focus ? " rounded-t-2xl" : "rounded-3xl"
        } flex flex-row justify-between`}
      >
        <span className={"my-auto w-5/6 truncate text-left text-on-surface"}>
          {gw && sport && selected ? selected.draft_name || defaultDraft.draft_name : ""}
        </span>
        <div className={"flex flex-row my-auto gap-2"}>
          {loading ? (
            <Spinning className={"h-5 w-5"} />
          ) : gw && sport && selected ? (
            <CircularLineupCount total_lineups={selected.total_lineups || 0} />
          ) : null}
          <div className={"flex flex-col justify-center"}>
            <SortArrowIcon className={`fill-on-surface h-3 w-3 ${focus ? "transform rotate-180" : ""}`} />
          </div>
        </div>
      </button>
      {focus && (
        <div className={"absolute bg-surface-container rounded-b-2xl w-full sm:w-80 shadow overflow-hidden text-on-surface"}>
          <ul className={"h-full overflow-y-scroll"} style={{ maxHeight: "25em" }}>
            {options.map((option, i) => {
              let color = "text-on-surface";
              if (!option.enabled) {
                color = "text-on-surface-variant opacity-50";
              }
              return (
                <li
                  key={option.draft_id}
                  onClick={() => {
                    onChange(option.draft_id, option.draft_name);
                    setFocus(false);
                  }}
                  title={
                    option.enabled
                      ? option.draft_name
                      : option.draft_name +
                        ": this draft is disabled because you have too many drafts for your current membership. Upgrade your membership to create more drafts."
                  }
                  className={`${i === 0 ? "border-y" : "border-b"} border-transparent-inverse-surface-low border-opacity-10 ${
                    option.enabled ? "hover:bg-containers-surface-focus-active-primarytint cursor-pointer" : "cursor-not-allowed"
                  }`}
                >
                  <div className="w-full h-full px-4 py-2">
                    <button
                      value={option.value}
                      className={`w-full ${color} ${selected && option.draft_id === selected.draft_id ? "font-semibold" : ""} ${
                        option.enabled ? "cursor-pointer" : "cursor-not-allowed"
                      } text-left text-sm flex flex-row justify-between`}
                    >
                      <span className={"w-5/6 truncate"}>{option.draft_name}</span>
                      <div className={"flex flex-row gap-2 my-auto"}>
                        {option.enabled ? (
                          <CircularLineupCount total_lineups={option.total_lineups} />
                        ) : (
                          <div className="my-auto h-5 w-5 flex justify-center p-1">
                            <img className={"h-3 w-3"} alt="" src={Locker} />
                          </div>
                        )}
                        {/* for circular count to be aligned with the one in the dropdown value*/}
                        <div className={"invisible flex flex-col justify-center"}>
                          <SortArrowIcon className={`fill-on-surface h-3 w-3`} />
                        </div>
                      </div>
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div
            data-tip={tipMessage}
            data-id={"lub-draft"}
            className={`${
              creationAuthorized
                ? "cursor-pointer bg-containers-surface-focus-active-primarytint text-primary hover:bg-primary hover:text-on-primary"
                : "bg-surface-disable text-on-disable cursor-not-allowed"
            }`}
          >
            <button
              disabled={!creationAuthorized}
              onClick={creationAuthorized ? onNewDraft : undefined}
              className={"w-full h-full font-semibold text-left text-sm my-auto px-3 py-2 flex flex-row justify-between items-center"}
            >
              <span>+ Add a new draft</span>
              {!creationAuthorized && <img className={"h-3 w-3"} src={GreyLocker} />}
            </button>
          </div>
        </div>
      )}
      <ReactTooltip id={"lub-draft"} />
    </div>
  );
};

const LineupBuilderDraftPicker = (props) => {
  const { gw, targetUserId, sport, lineupHash } = props;
  const [drafts, setDrafts] = useState([]);
  const [draftsLimitations, setDraftsLimitations] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState(props.draft_id);
  const [showMenu, setShowMenu] = useState(false);
  const [draftEditModaleOpen, setDraftEditModaleOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [newDraftModaleOpen, setNewDraftModaleOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [createError, setCreateError] = useState("");
  const [duplicateDraftModaleOpen, setDuplicateDraftModaleOpen] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [duplicateError, setDuplicateError] = useState("");
  const [removeAllLineupsModaleOpen, setRemoveAllLineupsModaleOpen] = useState(false);
  const [removingAllLineups, setRemovingAllLineups] = useState(false);
  const [removeAllLineupsError, setRemoveAllLineupsError] = useState("");
  const [deleteDraftModaleOpen, setDeleteDraftModaleOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState("");

  const targetUser = targetUserId !== undefined && targetUserId !== null ? targetUserId : "";

  useEffect(() => {
    loadDrafts();
  }, [gw, targetUserId, sport, lineupHash]);
  useEffect(() => {
    if (drafts.length > 0 && (selectedDraft == "" || drafts.find((d) => d.draft_id === selectedDraft) === undefined)) {
      onChange(
        drafts.length > 0 ? drafts[0].draft_id : defaultDraft.draft_id,
        drafts.length > 0 ? drafts[0].draft_name : defaultDraft.draft_name,
      );
    }
  }, [drafts]);

  const loadDrafts = useCallback(() => {
    if (gw && sport) {
      setLoading(true);
      props
        .fetch(`/decision-api/decision/lub/drafts/all/${sport}/${gw}?target=${targetUser}`)
        .then((response) => response.json())
        .then((res) => {
          const dRes = res.drafts || [];
          if (dRes.length === 0) {
            dRes.push(defaultDraft);
          }
          const draftsNormlized = dRes.map((d) => {
            return {
              draft_name: d.draft_name || defaultDraft.draft_name,
              draft_id: d.draft_id || defaultDraft.draft_id,
              total_lineups: d.total_lineups,
              enabled: d.enabled === undefined ? true : d.enabled,
            };
          });
          setDrafts(draftsNormlized);
          setDraftsLimitations(res.draft_limitations);
          ReactTooltip.rebuild();
          setLoading(false);
        })
        .catch(errorCatcher(() => setLoading(false)));
    }
  }, [gw, sport, targetUser]);

  const onChange = useCallback(
    (v, vName) => {
      console.log(v, vName);
      setSelectedDraft(v);
      props.onChange && props.onChange(v, vName);
    },
    [props.onChange],
  );

  const onDelete = useCallback(
    (draft) => {
      const draftId = draft?.draft_id || defaultDraft.draft_id;
      if (gw && sport) {
        setDeleteError("");
        setDeleting(true);
        props
          .fetch(`/decision-api/decision/lub/drafts/id/${draftId}`, {
            method: "DELETE",
          })
          .then((response) => response.json())
          .then((res) => {
            if (res.error === undefined) {
              setSelectedDraft("");
              loadDrafts();
              setDeleteDraftModaleOpen(false);
            } else {
              setDeleteError(res.error);
            }
            setDeleting(false);
          })
          .catch(
            errorCatcher(() => {
              setDeleteError("Unknown error, retry later");
              setDeleting(false);
            }),
          );
      }
    },
    [gw, sport, defaultDraft],
  );

  const onRemoveAllLineups = useCallback(
    (draft) => {
      const draftId = draft?.draft_id || defaultDraft.draft_id;
      if (gw && sport) {
        setRemoveAllLineupsError("");
        setRemovingAllLineups(true);
        props
          .fetch(`/decision-api/decision/lub/drafts/id/${draftId}/deleteLineups`, {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sport: sport,
              gw_number: gw,
              target: targetUser,
            }),
          })
          .then((response) => response.json())
          .then((res) => {
            if (res.error === undefined) {
              loadDrafts();
              setRemoveAllLineupsModaleOpen(false);
            } else {
              setRemoveAllLineupsError(res.error);
            }
            setRemovingAllLineups(false);
          })
          .catch(
            errorCatcher(() => {
              setRemoveAllLineupsError("Unknown error, retry later");
              setRemovingAllLineups(false);
            }),
          );
      }
    },
    [gw, sport, defaultDraft],
  );

  const onDuplicate = useCallback(
    (draft) => {
      if (gw && sport) {
        setDuplicateError("");
        setDuplicating(true);
        props
          .fetch(`/decision-api/decision/lub/drafts/id/${draft.draft_id}/duplicate`, {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: draft.draft_id,
              name: draft.draft_name,
              sport: sport,
              gw_number: gw,
              target: targetUser,
            }),
          })
          .then((response) => response.json())
          .then((res) => {
            if (res.error === undefined) {
              loadDrafts();
              onChange(res.id, res.name);
              setDuplicateDraftModaleOpen(false);
            } else {
              setDuplicateError(res.error);
            }
            setDuplicating(false);
          })
          .catch(
            errorCatcher(() => {
              setDuplicateError("Unknown error, retry later");
              setDuplicating(false);
            }),
          );
      }
    },
    [gw, sport, targetUser],
  );

  const onCreateDraft = useCallback(
    (draft) => {
      if (gw && sport) {
        setCreateError("");
        setCreating(true);
        props
          .fetch(`/decision-api/decision/lub/drafts/new`, {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: draft.draft_name,
              sport: sport,
              gw_number: gw,
              target: targetUser,
            }),
          })
          .then((response) => response.json())
          .then((res) => {
            if (res.error === undefined) {
              loadDrafts();
              onChange(res.id, res.name);
              setNewDraftModaleOpen(false);
            } else {
              setCreateError(res.error);
            }
            setCreating(false);
          })
          .catch(
            errorCatcher(() => {
              setCreateError("Unknown error, retry later");
              setCreating(false);
            }),
          );
      }
    },
    [gw, sport, targetUser],
  );

  const onSaveDraft = useCallback(
    (draft) => {
      if (gw && sport) {
        setSaveError("");
        setSaving(true);
        props
          .fetch(`/decision-api/decision/lub/drafts/id/${draft.draft_id}`, {
            method: "PUT",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: draft.draft_name,
              sport: sport,
              gw_number: gw,
              target: targetUser,
            }),
          })
          .then((response) => response.json())
          .then((res) => {
            if (res.error === undefined) {
              loadDrafts();
              onChange(res.id, res.name);
              setDraftEditModaleOpen(false);
            } else {
              setSaveError(res.error);
            }
            setSaving(false);
          })
          .catch(
            errorCatcher(() => {
              setSaveError("Unknown error, retry later");
              setSaving(false);
            }),
          );
      }
    },
    [gw, sport, targetUser],
  );

  const selectedDraftObj = drafts.find((d) => d.draft_id === selectedDraft);

  const showModale = (callback) => {
    setShowMenu(false);
    setTimeout(() => {
      // https://github.com/tailwindlabs/headlessui/issues/1744#issuecomment-1208079384
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          callback && callback();
        });
      });
    }, 300 /* Duration of Modal transition */);
  };

  let menuItems = [];
  if (selectedDraftObj?.enabled) {
    menuItems.push(
      {
        key: "edit",
        label: "Edit name",
        className: "cursor-pointer w-full sm:w-52",
        onClick: () => {
          showModale(() => setDraftEditModaleOpen(true));
        },
      },
      {
        key: "duplicate",
        label: "Duplicate",
        className: "cursor-pointer w-full sm:w-52",
        onClick: () => {
          showModale(() => setDuplicateDraftModaleOpen(true));
        },
      },
      {
        key: "remove-all-lineups",
        label: "Remove all lineups",
        className: "cursor-pointer w-full sm:w-52",
        onClick: () => {
          showModale(() => setRemoveAllLineupsModaleOpen(true));
        },
      },
    );
  }
  if (selectedDraftObj?.draft_id !== defaultDraft.draft_id) {
    menuItems.push({
      key: "delete",
      label: "Delete",
      className: "cursor-pointer text-live-live-container-fixed w-full sm:w-52",
      onClick: () => {
        showModale(() => setDeleteDraftModaleOpen(true));
      },
    });
  }

  return (
    <div className={`w-full sm:w-auto z-40`}>
      {selectedDraftObj && (
        <DraftEditModale
          open={draftEditModaleOpen}
          draft={selectedDraftObj}
          save={onSaveDraft}
          close={() => setDraftEditModaleOpen(false)}
          saving={saving}
          error={saveError}
          title={"Rename draft"}
        />
      )}
      {selectedDraftObj && (
        <DraftEditModale
          open={newDraftModaleOpen}
          save={onCreateDraft}
          close={() => setNewDraftModaleOpen(false)}
          saving={creating}
          error={createError}
          title={"Create draft"}
        />
      )}
      {selectedDraftObj && (
        <DraftEditModale
          open={duplicateDraftModaleOpen}
          draft={{
            ...selectedDraftObj,
            draft_name: selectedDraftObj.draft_name + " (copy)",
          }}
          save={onDuplicate}
          saving={duplicating}
          error={duplicateError}
          close={() => setDuplicateDraftModaleOpen(false)}
          title={`Duplicate draft: ${selectedDraftObj.draft_name}`}
        />
      )}
      {selectedDraftObj && (
        <DraftDeleteModale
          loading={deleting}
          error={deleteError}
          open={deleteDraftModaleOpen}
          close={() => setDeleteDraftModaleOpen(false)}
          draft={selectedDraftObj}
          confirm={onDelete}
        />
      )}
      {removeAllLineupsModaleOpen && selectedDraftObj && (
        <RemoveAllLineupsModale
          loading={removingAllLineups}
          error={removeAllLineupsError}
          open={removeAllLineupsModaleOpen}
          close={() => setRemoveAllLineupsModaleOpen(false)}
          draft={selectedDraftObj}
          confirm={onRemoveAllLineups}
        />
      )}
      <div className={"flex flex-row gap-2 w-full sm:w-auto z-30"}>
        <CustomSelect
          gw={gw}
          sport={sport}
          loading={loading}
          options={drafts}
          limitations={draftsLimitations}
          selected={selectedDraftObj}
          onChange={onChange}
          onNewDraft={() => setNewDraftModaleOpen(true)}
          tier={props.user.tier}
        />
        {menuItems.length > 0 && (
          <div className={"relative flex flex-row min-w-fit z-30"}>
            <div
              className={`bg-surface-container p-2 rounded-full cursor-pointer hover:opacity-80 my-auto aspect-square`}
              onClick={() => setShowMenu(true)}
            >
              <MenuDotIcon className="fill-on-surface" />
            </div>
            <StandaloneMenu
              style={{ top: "120%" }}
              show={showMenu}
              title={selectedDraftObj?.draft_name ? selectedDraftObj.draft_name : undefined}
              onClickOutside={() => setShowMenu(false)}
              items={menuItems}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withUser(LineupBuilderDraftPicker);
