import React, { useEffect, useRef, useState } from "react";
import { Form } from "grommet";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import UnknownPlayer from "../../img/unknown_player2.png";
import EmptyAvatar from "../../img/empty-avatar.svg";
import SelectedIcon from "../../img/card-selected.svg";
import UnknownClub from "../../img/unknown_club.png";

const ItemSearchFormManagerResult = (props) => {
  const p = props.manager;
  const { onClickItem, selectableIndex, index, selectedItems } = props;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClickItem(p.item_id, p.item_name);
      }}
      className={
        "z-50 flex flex-row justify-between h-8 hover:bg-grey-f9 rounded cursor-pointer " + (selectableIndex === index ? "bg-grey-f2" : "")
      }
    >
      <div className={"flex flex-row space-x-2 self-center pl-2"}>
        <div className={"w-6 h-6"}>
          <img className={"object-contain pt-1 w-full h-full rounded-full bg-grey-f2"} src={p.Picture ? p.Picture : EmptyAvatar} />
        </div>
        <div className={"flex flex-col self-center justify-start text-ellipsis items-start"}>
          <p className={"text-sm font-medium text-start text-ellipsis"}>
            {p.item_name}
            {selectedItems !== undefined && selectedItems[p.item_id] && (
              <img className={"inline ml-1 h-4 w-4"} data-tip={"Already selected"} src={SelectedIcon} />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const ItemSearchFormPlayerResult = (props) => {
  const p = props.player;
  const { onClickItem, selectableIndex, index, selectedItems } = props;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClickItem(p.item_id, p.item_short_name);
      }}
      className={
        "z-50 flex flex-row justify-between h-8 hover:bg-grey-f9 rounded cursor-pointer " + (selectableIndex === index ? "bg-grey-f2" : "")
      }
    >
      <div className={"flex flex-row space-x-2 self-center pl-2"}>
        {p.Picture !== "" ? (
          <div className={"w-6 h-6"}>
            <img className={"object-contain pt-1 w-full h-full rounded-full bg-grey-f2"} src={p.Picture} />
          </div>
        ) : (
          <div className={"w-6 h-6"}>
            <img className={"object-contain pt-1 w-full h-full rounded-full bg-grey-f2"} src={UnknownPlayer} />
          </div>
        )}
        <div className={"flex flex-col self-center justify-start text-ellipsis items-start"}>
          <p className={"text-sm font-medium text-start text-ellipsis"}>
            {p.item_name}
            {selectedItems !== undefined && selectedItems[p.item_id] && (
              <img className={"inline ml-1 h-4 w-4"} data-tip={"Already selected"} src={SelectedIcon} />
            )}
          </p>
          <p className={"md:hidden lg:block xl:hidden text-brand text-start text-xs font-semibold text-ellipsis"}>
            {p.TeamName !== "" ? p.TeamName : "No club"}
          </p>
        </div>
      </div>
      <div className={"flex pr-2 hidden md:flex lg:hidden xl:flex"}>
        <p className={"text-brand text-xs font-semibold self-center"}>{p.TeamName !== "" ? p.TeamName : "No club"}</p>
        <img className={"object-contain m-auto pl-1 w-5 h-5"} src={p.TeamPictureUrl ? p.TeamPictureUrl : UnknownClub} />
      </div>
    </div>
  );
};

const ItemSearchForm = (props) => {
  const { onChange, value, results, onClean, selectedItems } = props;
  const type = props.type || "player";
  const [displayResults, setDisplayResults] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isHoverResults, setIsHoverResults] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const [selectableIndex, setSelectableIndex] = useState(0);

  useEffect(() => {
    inputRef.current.focus();
    setIsFocus(true);
  }, []);

  useEffect(() => {
    if (!displayResults) {
      setSelectableIndex(0);
    }
  }, [displayResults]);

  useEffect(() => {
    setSelectableIndex(0);
  }, [value]);

  useEffect(() => {
    if (isFocus) {
      setDisplayResults(true);
    } else if (isHoverResults) {
      setDisplayResults(true);
    }
  }, [isFocus, isHoverResults]);

  const onKeyDownInputSearch = (e) => {
    if (displayResults) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          if (prev <= 0) {
            const resultLength = results?.length || 0;
            return resultLength - 1;
          } else {
            return prev - 1;
          }
        });
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectableIndex((prev) => {
          const resultLength = results?.length || 0;
          if (prev >= resultLength - 1) {
            return 0;
          } else {
            return prev + 1;
          }
        });
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (results && selectableIndex >= 0 && selectableIndex < results.length) {
          onClickItem(
            results[selectableIndex].item_id,
            type === "player" ? results[selectableIndex].item_short_name : results[selectableIndex].item_name,
          );
        }
      }
    }
  };

  const onClickItem = (id, name) => {
    inputRef.current.select();
    props.onClickItem && props.onClickItem(id, name);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  let roundSearch = "rounded";
  if (displayResults && value !== "") {
    roundSearch = "rounded-t";
  }
  let placeholderStyle = "block w-full pl-10 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0 " + roundSearch;
  if (value !== "") {
    placeholderStyle = "block w-full pl-10 font-medium text-sm border border-grey-e9 focus:border-grey-e9  focus:ring-0 " + roundSearch;
  }

  return (
    <div className={"relative"}>
      <Form className={"z-0"}>
        <div className={"flex"}>
          <div style={{ width: "28em" }} className={"m-auto relative flex h-9 " + roundSearch} ref={ref}>
            <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
              <FormSearch className="h-5 w-5" aria-hidden="true" />
            </div>
            {value && (
              <div className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer" onClick={onClean}>
                <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
              </div>
            )}
            <input
              ref={inputRef}
              autoFocus
              type="text"
              onChange={onChange}
              onFocus={(e) => {
                e.stopPropagation();
                setIsFocus(true);
              }}
              onBlur={(e) => {
                e.stopPropagation();
                setIsFocus(false);
              }}
              onKeyDown={onKeyDownInputSearch}
              value={value}
              className={placeholderStyle}
              placeholder={`Search for a ${type}...`}
            />
            <div className={"z-500 absolute w-full top-9"} onPointerDown={(e) => e.preventDefault()}>
              {displayResults && value !== "" && (
                <div className={"rounded-b border-b border-l border-r border-grey-e9 bg-white z-50"}>
                  <div className={"space-y-2 px-2 pt-2 z-50"}>
                    {results?.length > 0 && (
                      <div
                        className={"space-y-2"}
                        onMouseEnter={() => setIsHoverResults(true)}
                        onMouseLeave={() => setIsHoverResults(false)}
                      >
                        <p className={"text-xs text-textGrey3 px-2 capitalize"}>{type}s</p>
                        <div className={"space-y-1 overflow-y-scroll"} style={{ maxHeight: "12em" }}>
                          {type === "player" &&
                            results?.map((item, i) => {
                              return (
                                <ItemSearchFormPlayerResult
                                  key={i}
                                  player={item}
                                  onClickItem={onClickItem}
                                  selectableIndex={selectableIndex}
                                  index={i}
                                  selectedItems={selectedItems}
                                />
                              );
                            })}
                          {type === "manager" &&
                            results?.map((item, i) => {
                              return (
                                <ItemSearchFormManagerResult
                                  key={i}
                                  manager={item}
                                  onClickItem={onClickItem}
                                  selectableIndex={selectableIndex}
                                  index={i}
                                  selectedItems={selectedItems}
                                />
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {results?.length === 0 && (
                      <div className={"space-y-0"}>
                        <div className={"space-y-1"}>
                          <div className={"z-50 flex flex-row justify-between rounded text-textGrey4 text-sm m-auto"}>
                            <p className={"my-auto mb-2"}>0 results</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ItemSearchForm;
