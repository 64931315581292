import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { errorCatcher } from "../util/errors";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import Spinner from "../loader/spinner";
import { ReactComponent as GridIcon } from "../../img/icons-grid.svg";
import { ReactComponent as ListSelectedIcon } from "../../img/icons-list.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import { withUser } from "../../userContext";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import SDLoading from "../util/SDLoading";
import PlayerSearchResult from "../players/playerSearchResult";
import { Accordion, AccordionPanel } from "grommet";
import { getNextGameweeksObjects } from "../util/nextGws";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { scarcities_objects_not_all } from "../util/scarcities";
import { getSimplePositionsBasedOnSport } from "../util/positions";
import { leagues_with_divisions_objects_not_all } from "../util/leagues";
import { PlayerStatusSelector } from "../util/playingStatus";
import AgeRange, { DEFAULT_AGE_RANGE } from "../util/ageRange";
import PriceFilter from "../util/priceRange";
import ScoreFilter from "../util/scoreFilter";
import { Button } from "../util/button";
import PlayerSearchListResult from "../players/playerSearchListResult";
import { sorareBasketball, sorareFootball } from "../util/sports";
import { GameweekFilter } from "../players/gameweekFilter";
import { isFree } from "../util/handleSubscriptionTier";
import Locker from "../../img/brand-close-locker-nude.svg";
import { getClubsFromSport } from "../util/clubs";

const sortByLabels = (sport) => {
  return {
    "realTimeLimited asc": "Price Limited (lowest to highest)",
    "realTimeLimited desc": "Price Limited (highest to lowest)",
    "realTimeRare asc": "Price Rare (lowest to highest)",
    "realTimeRare desc": "Price Rare (highest to lowest)",
    "realTimeSuperRare asc": "Price Super Rare (lowest to highest)",
    "realTimeSuperRare desc": "Price Super Rare (highest to lowest)",
    "realTimeUnique asc": "Price Unique (lowest to highest)",
    "realTimeUnique desc": "Price Unique (highest to lowest)",
    "rankingPosition asc": "Ranking position (best to worst)",
    "rankingPosition desc": "Ranking position (worst to best)",
    "rankingOverall asc": "Ranking overall (best to worst)",
    "rankingOverall desc": "Ranking overall (worst to best)",
    "l5 asc": sport === sorareBasketball ? "L10 (worst to best)" : "L5 (worst to best)",
    "l5 desc": sport === sorareBasketball ? "L10 (best to worst)" : "L5 (best to worst)",
    "l15 asc": sport === sorareBasketball ? "L40 (worst to best)" : "L15 (worst to best)",
    "l15 desc": sport === sorareBasketball ? "L40 (best to worst)" : "L15 (best to worst)",
    "l40 asc": sport === sorareBasketball ? "Season (worst to best)" : "L40 (worst to best)",
    "l40 desc": sport === sorareBasketball ? "Season (best to worst)" : "L40 (best to worst)",
  };
};

const defaultScoreFilter = {
  l5: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
  l15: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
  l40: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
};

const PlayerSearchWithFilters = (props) => {
  const selectedItems = props.selectedItems;
  const sport = props.sport || sorareFootball;
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState(undefined);
  const [displayedPlayers, setDisplayedPlayers] = useState(undefined);
  const [teamMap, setTeamMap] = useState({});
  const [sortBy, setSortBy] = useState("l40 desc");
  const [activeIndexes, setActiveIndexes] = useState([0]);

  const [nextGameweeks, setGameweeks] = useState([]);
  const [scarcity, setScarcity] = useState([]);
  const [league, setLeague] = useState([]);
  const [positions, setPositions] = useState([]);
  const [playingStatus, setPlayingStatus] = useState("all");
  const [clubs, setClubs] = useState([]);
  const [bmpFilter, setBmpFilter] = useState(false);
  const [avgFilter, setAvgFilter] = useState(false);
  const [scoreFilters, setScoreFilters] = useState(defaultScoreFilter);
  const [bmpRange, setBmpRange] = useState([0, 1]);
  const [avgRange, setAvgRange] = useState([0, 1]);
  const [ageRange, setAgeRange] = useState(DEFAULT_AGE_RANGE);
  const [u23Only, setU23Only] = useState(false);
  const [gwFilter, setGwFilter] = useState(false);
  const [gwValue, setGwValue] = useState("");

  const [display, setDisplay] = useState("grid");

  const [showSortMenu, setShowSortMenu] = useState(false);

  useEffect(() => {
    if (players !== undefined) {
      searchPlayers();
    }
  }, [sortBy]);

  useEffect(() => {
    fetchGw();
  }, []);

  const changeDisplay = (display) => {
    setDisplay(display);
    ReactTooltip.rebuild();
  };

  const fetchGw = async () => {
    const nextGws = await getNextGameweeksObjects(props);
    const newGws = nextGws.map((gw, index) => {
      return {
        ...gw,
        disabled: isFree(props.user.tier) && index !== 0,
        photo: Locker,
      };
    });
    setGameweeks(newGws);
  };

  const resetFilters = () => {
    setScarcity([]);
    setLeague([]);
    setPositions([]);
    setPlayingStatus("all");
    setClubs([]);
    setBmpFilter(false);
    setAvgFilter(false);
    setScoreFilters(defaultScoreFilter);
    setBmpRange([0, 1]);
    setAvgRange([0, 1]);
    setAgeRange(DEFAULT_AGE_RANGE);
    setU23Only(false);
    setGwFilter(false);
    setGwValue("");
  };

  const limit = 501;

  const searchPlayers = () => {
    setLoading(true);
    props
      .fetch("/apiv2/players/searchAdvanced", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scarcity: scarcity,
          position: positions,
          league: league,
          bmpRange: bmpFilter ? [parseFloat(bmpRange[0]), parseFloat(bmpRange[1])] : [],
          avgRange: avgFilter ? [parseFloat(avgRange[0]), parseFloat(avgRange[1])] : [],
          u23: u23Only,
          ageRange: ageRange,
          l5: scoreFilters.l5,
          l15: scoreFilters.l15,
          l40: scoreFilters.l40,
          playingStatus: playingStatus,
          teams: clubs,
          gwNumber: gwValue === "" ? 0 : parseInt(gwValue),
          gwFilter: gwFilter,
          sortBy: sortBy,
          limit: limit,
          sport: sport,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setPlayers(res.players.slice(0, limit - 1) || []);
          setDisplayedPlayers(res.players ? res.players.slice(0, 12) : []);
          setTeamMap(res.team_map || {});
        }
        setLoading(false);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  const sortMenuItems = Object.keys(sortByLabels(sport)).map((key) => {
    const label = sortByLabels(sport)[key];
    return {
      key: key,
      label: label,
      className: "w-full sm:w-72 cursor-pointer " + (sortBy === key ? "font-semibold" : ""),
      onClick: () => {
        setShowSortMenu(false);
        setSortBy(key);
      },
    };
  });

  const sortByStatus = (field) => {
    let sort = "";
    if (sortBy.includes(field)) {
      sort = field + (sortBy.includes("asc") ? " desc" : " asc");
    } else {
      sort = field + " asc";
    }
    setSortBy(sort);
  };

  const selectAll = () => {
    if (props.onSelectItems && players) {
      let toSelect = {};
      if (players) {
        players.forEach((p) => {
          toSelect[p.player.PlayerId] = p.player.MatchName;
        });
        props.onSelectItems(toSelect);
      }
    }
  };

  const unselectAll = () => {
    if (props.onUnselectItems && players) {
      let toUnselect = {};
      players
        .filter((p) => selectedItems[p.player.PlayerId])
        .forEach((p) => {
          toUnselect[p.player.PlayerId] = p.player.MatchName;
        });
      props.onUnselectItems(toUnselect);
    }
  };

  const displayContent = () => {
    if (players === undefined || displayedPlayers === undefined) {
      return null;
    }
    if (players.length === 0 || players.length >= limit - 1) {
      return (
        <div
          className={"flex flex-col justify-center w-full bg-focus rounded-lg text-center border border-grey-e9 py-4 "}
          style={{ height: "20em" }}
        >
          <div className={"m-auto"}>
            <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
            <span className={"text-lg font-headers text-center text-textGrey2 font-bold mb-6"}>
              {players.length === 0 ? (
                "No player with such characteristics"
              ) : (
                <>
                  <p>Too many players with such characteristics</p>
                  <p className={"text-sm font-sans text-textGrey3 font-semibold"}>{`Please use filters to get less than ${
                    limit - 1
                  } results`}</p>
                </>
              )}
            </span>
          </div>
        </div>
      );
    }
    if (display === "grid") {
      return (
        <div>
          <InfiniteScroll
            height={"25em"}
            next={() => setDisplayedPlayers(players.slice(0, displayedPlayers.length + 12))}
            hasMore={displayedPlayers.length < players.length}
            loader={SDLoading}
            dataLength={displayedPlayers.length}
          >
            <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"}>
              {displayedPlayers.map((p) => {
                return (
                  <PlayerSearchResult
                    key={p.player.PlayerId}
                    teamMap={teamMap}
                    result={p}
                    actions={["selection"]}
                    isSelected={selectedItems[p.player.PlayerId]}
                    onSelect={(p) => props.onClickItem(p.PlayerId, p.MatchName)}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      );
    }

    return (
      <div>
        <InfiniteScroll
          height={"25em"}
          next={() => setDisplayedPlayers(players.slice(0, displayedPlayers.length + 12))}
          hasMore={displayedPlayers.length < players.length}
          loader={<SDLoading />}
          dataLength={displayedPlayers.length}
        >
          <PlayerSearchListResult
            selectable
            sortBy={sortBy}
            onChangeSort={sortByStatus}
            displayedPlayers={displayedPlayers}
            selectedPlayers={selectedItems}
            onSelect={(p) => props.onClickItem(p.PlayerId, p.MatchName)}
          />
        </InfiniteScroll>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div>
        <div className={"md:grid md:grid-cols-2 md:gap-2 lg:grid-cols-3 xl:grid-cols-4 space-y-1 md:space-y-0"}>
          <div className={"md:z-60 lg:z-50 xl:z-50"}>
            <SelectSearch
              multiple
              closeOnSelect={false}
              options={scarcities_objects_not_all}
              value={scarcity}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select one scarcity or more"
              onChange={(v) => setScarcity(v)}
            />
          </div>
          <div className={"md:z-60 lg:z-50 xl:z-50"}>
            <SelectSearch
              multiple
              closeOnSelect={false}
              options={getSimplePositionsBasedOnSport(sport)}
              value={positions}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select one position or more"
              onChange={(v) => setPositions(v)}
            />
          </div>
          {(sport === sorareFootball || sport === "multi") && (
            <div className={"md:z-50 lg:z-50 xl:z-50"}>
              <SelectSearch
                multiple
                closeOnSelect={false}
                options={leagues_with_divisions_objects_not_all}
                value={league}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                placeholder="Select one league or more"
                onChange={(v) => setLeague(v)}
              />
            </div>
          )}
          <div className={"md:z-50 lg:z-40 xl:z-50"}>
            <PlayerStatusSelector
              playingStatus={playingStatus}
              onChange={(v) => setPlayingStatus(v)}
              tier={props.user.tier}
              sport={sport}
            />
          </div>
          <div className={"md:z-40 lg:z-40 xl:z-40"}>
            <SelectSearch
              options={getClubsFromSport(sport, true)}
              onChange={(v) => setClubs(v)}
              search
              multiple
              value={clubs}
              closeOnSelect={false}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select one club or more"
            />
          </div>
          <div className={"md:z-40 lg:z-40 xl:z-40"}>
            <AgeRange range={ageRange} setU23={() => setU23Only(!u23Only)} u23={u23Only} setRange={(ar) => setAgeRange(ar)} />
          </div>
          <div className={"md:z-30 lg:z-30 xl:z-40"}>
            <PriceFilter
              tip={scarcity.length === 0 && "(pick scarcities first)"}
              watchlist={true}
              bmp={bmpFilter}
              bmpRange={bmpRange}
              avg={avgFilter}
              avgRange={avgRange}
              tier={props.user.tier}
              sport={sport}
              updateFilters={async (c, cr, bmp, bmpr, avg, avgr, ubmp, uavg) => {
                await setBmpFilter(bmp);
                await setBmpRange(bmpr);
                await setAvgFilter(avg);
                await setAvgRange(avgr);
              }}
            />
          </div>
          <div className={"md:z-30 lg:z-30 xl:z-40"}>
            <ScoreFilter
              l5={scoreFilters.l5}
              l15={scoreFilters.l15}
              l40={scoreFilters.l40}
              sport={sport}
              setScoreFilters={async (f) => {
                await setScoreFilters(f);
              }}
            />
          </div>
          <div className={"col-span-1 lg:col-span-2 xl:col-span-2 flex flex-row self-center space-x-4"}>
            <div className={"w-full lg:w-1/2 pr-1"}>
              {sport === sorareFootball ||
                (sport === "multi" && (
                  <GameweekFilter
                    label={"Plays during gameweek"}
                    className={"w-full"}
                    filterByGw={gwFilter}
                    onChangeFilterByGw={() => setGwFilter(!gwFilter)}
                    gameweeks={nextGameweeks}
                    gw={gwValue}
                    closeOnSelect={false}
                    onChangeGw={(v) => setGwValue(v)}
                  />
                ))}
            </div>
          </div>
          <div className="col-span-1 lg:col-span-1 xl:col-span-2 flex flex-row gap-2 justify-end">
            <Button context={"secondary"} label={"Reset"} onClick={resetFilters} />
            <Button label={"Search"} onClick={searchPlayers} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={"p-4"}>
      <div className={"block md:hidden"}>
        <Accordion activeIndex={activeIndexes} onActive={(indexes) => setActiveIndexes(indexes)}>
          <AccordionPanel label={"Filters"}>
            <div className={"pt-2"}>{renderFilters()}</div>
          </AccordionPanel>
        </Accordion>
      </div>
      <div className={"hidden md:block"}>{renderFilters()}</div>
      <div>
        {players !== undefined && (
          <h3 className={"font-headers font-bold m-0 mt-2"}>{`Results (${players.length}${players.length === 500 ? "+" : ""})`}</h3>
        )}
      </div>
      <div className={"relative my-2 flex flex-row justify-between"}>
        <div
          className={"text-xs md:text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"}
          onClick={() => setShowSortMenu(true)}
        >
          Sort by: {sortByLabels(sport)[sortBy]}
          <span className={"ml-2 my-auto"}>
            <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
          </span>
        </div>
        <div className="flex flex-row gap-2">
          {loading && <Spinner />}
          <span className={"my-auto text-xs md:text-sm font-semibold text-brand px-2 flex flex-row gap-2"}>
            {players && players.length !== limit && (
              <span className={"my-auto cursor-pointer hover:font-bold"} onClick={selectAll}>
                Select all
              </span>
            )}
            {players && players.length !== limit && (
              <span className={"my-auto cursor-pointer hover:font-bold"} onClick={unselectAll}>
                Unselect all
              </span>
            )}
          </span>
          <button
            type="button"
            onClick={() => changeDisplay("grid")}
            className={
              "inline-flex items-center p-3 border-0 border-transparent text-med font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-none bg-white " +
              (display === "grid" ? "fill-brand cursor-default" : "fill-textGrey4 cursor-none hover:fill-brand")
            }
          >
            <GridIcon />
          </button>
          <button
            type="button"
            onClick={() => changeDisplay("list")}
            className={
              "inline-flex items-center p-3 border-0 border-transparent text-med font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-none bg-white " +
              (display === "list" ? "fill-brand cursor-default" : "fill-textGrey4 cursor-pointer hover:fill-brand")
            }
          >
            <ListSelectedIcon />
          </button>
        </div>
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 py-2"}
          show={showSortMenu}
          onClickOutside={() => setShowSortMenu(false)}
          items={sortMenuItems}
        />
      </div>
      <div className={"h-auto"}>{displayContent()}</div>
    </div>
  );
};

export default withUser(PlayerSearchWithFilters);
