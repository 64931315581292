import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import PlayerSearchResult from "./playerSearchResult";
import { leagues_with_divisions_objects } from "../util/leagues";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Toggle from "../util/toggle";
import GreenButton from "../util/greenButton";
import { withUser } from "../../userContext";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { errorCatcher } from "../util/errors";
import { t2OrAbove } from "../util/handleSubscriptionTier";
import { Button } from "../util/button";
import { isSportFree } from "../util/sports";

function SimilarPlayers(props) {
  const player = props.player;
  const averages = props.averages;
  const team = props.team;
  const [results, setResults] = useState([]);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [scoreOption, setScoreOption] = useState(15);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(player.Position);
  const [league, setLeague] = useState("all");
  const [useAge, setUseAge] = useState(true);
  const [teamMap, setTeamMap] = useState([]);

  useEffect(() => getSimilarPlayers(), []);

  const getSimilarPlayers = () => {
    setLoading(true);
    let gamesPlayed5 = [];
    let gamesPlayed15 = [];
    let gamesPlayed40 = [];
    let sd5Range = [];
    let sd15Range = [];
    let sd40Range = [];
    if (scoreOption === 5) {
      gamesPlayed5 = [averages.gms_5 - 1, averages.gms_5 + 1];
      sd5Range = [Math.floor(averages.avg_5 - 4), Math.floor(averages.avg_5 + 4)];
    } else if (scoreOption === 15) {
      gamesPlayed15 = [averages.gms_15 - 2, averages.gms_15 + 2];
      sd15Range = [Math.floor(averages.avg_15 - 4), Math.floor(averages.avg_15 + 4)];
    } else if (scoreOption === 40) {
      gamesPlayed40 = [averages.gms_40 - 4, averages.gms_40 + 4];
      sd40Range = [Math.floor(averages.avg_40 - 5), Math.floor(averages.avg_40 + 5)];
    }
    props
      .fetch("/apiv2/players/similar", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          player_id: player.PlayerId,
          position: position,
          games_played_5: gamesPlayed5,
          games_played_15: gamesPlayed15,
          games_played_40: gamesPlayed40,
          league: league,
          age: useAge ? [player.Age - 2, player.Age + 2] : [],
          sd_5_range: sd5Range,
          sd_15_range: sd15Range,
          sd_40_range: sd40Range,
          sorare_slug: props.user.sorareSlug,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.players === null) {
          setLoading(false);
          setDisplayedResults([]);
          setResults([]);
        } else {
          setResults(res.players);
          setLoading(false);
          setDisplayedResults(res.players.slice(0, 20));
        }
        setTeamMap(res.team_map);
        ReactTooltip.rebuild();
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
          ReactTooltip.rebuild();
        }),
      );
  };

  let scoreOptions = [
    { value: 5, name: "Based on the last 5 games" },
    { value: 15, name: "Based on the last 15 games" },
  ];
  if (averages.avg_40 >= 0) {
    scoreOptions.push({ value: 40, name: "Based on the last 40 games" });
  }

  return (
    <div className={"space-y-4"}>
      {(t2OrAbove(props.user?.tier) || isSportFree(player.sport)) && (
        <div>
          <div className={"flex flex-col xl:flex-row xl:space-x-4 space-y-2 xl:space-y-0 z-50 xl:justify-center items-center"}>
            <div className={"z-50"}>
              <SelectSearch
                options={[
                  { value: "all", name: "All positions" },
                  { value: "Goalkeeper", name: "Goalkeeper" },
                  { value: "Defender", name: "Defender" },
                  { value: "Midfielder", name: "Midfielder" },
                  { value: "Forward", name: "Forward" },
                ]}
                value={position}
                onChange={(v) => setPosition(v)}
                closeOnSelect={true}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                placeholder="Select a position"
              />
            </div>
            <div className={"z-50"}>
              <SelectSearch
                options={leagues_with_divisions_objects}
                onChange={(v) => setLeague(v)}
                search
                value={league}
                closeOnSelect={true}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                placeholder="Select one league/division or more"
              />
            </div>
            <div className={"z-50"}>
              <SelectSearch
                options={scoreOptions}
                onChange={(v) => setScoreOption(v)}
                value={scoreOption}
                closeOnSelect={true}
                printOptions={"on-focus"}
                filterOptions={fuzzySearch}
                placeholder="Select one league/division or more"
              />
            </div>
            <div className={"flex flex-row space-x-2 mb-2 justify-center"}>
              <Toggle isActive={useAge} setActive={() => setUseAge(!useAge)} />
              <p className={"text-sm self-center"}>Same age range</p>
            </div>
          </div>
          <div></div>
          <div className={"text-center"} onClick={() => getSimilarPlayers()}>
            <div className={"my-4"}>
              <GreenButton label={"Search"} />
            </div>
            <p className={"text-sm mt-2"}>
              Want to search even further? Check our{" "}
              <a href={"/advancedSearch?mode=players"} className={"font-semibold hover:font-bold"}>
                player search tool
              </a>
              !
            </p>
          </div>
        </div>
      )}
      <InfiniteScroll
        next={() => setDisplayedResults(results.slice(0, displayedResults.length + 20))}
        hasMore={displayedResults.length < results.length}
        loader={SDLoading}
        dataLength={displayedResults.length}
      >
        <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4"}>
          {displayedResults.map((p, i) => {
            if (t2OrAbove(props.user?.tier) || isSportFree(player.sport) || i === 0) {
              return (
                <div>
                  <PlayerSearchResult result={p} teamMap={teamMap} />
                </div>
              );
            } else if (i === 1) {
              return (
                <div className="w-full h-full bg-similar-upgrade bg-cover rounded-lg border-solid border-2 border-grey-e0 flex flex-col justify-arround justify-center items-center">
                  <div className="w-4/5">
                    <p className="text-black font-headers text-1.5xl font-extrabold text-center mt-10 mb-4">Want more insights?</p>
                  </div>
                  <div className="w-4/5">
                    <p className="text-black text-md font-semibold text-center mb-10">
                      Become a Star member to unlock all similar players.
                    </p>
                  </div>
                  <div className="flex justify-center items-center w-1/3 mb-10">
                    <a href={"/product"}>
                      <Button label={"Upgrade"} />
                    </a>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </InfiniteScroll>

      {loading && <SDLoading />}
      {results.length === 0 && !loading && <p className={"text-sm text-center mx-auto"}>No similar player was found.</p>}
    </div>
  );
}

export default withUser(SimilarPlayers);
