import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import Overlay from "react-overlay-component";
import { formatPrice } from "../util/formatMoney";
import { getValuationOriginShort } from "../util/valuationOrigin";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { withUser } from "../../userContext";
import { formatValuationHistory } from "../util/formatValuationHistory";
import CurrencyPicker from "../util/currencyPicker";
import { errorCatcher } from "../util/errors";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import CardLocker from "../cards/cardLocker";
import MultiScarcityPicker from "../util/multiScarcityPicker";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { leagues_with_divisions_objects_not_all } from "../util/leagues";
import { positions_objects } from "../util/positions";
import { allSportsOptions } from "../util/sports";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../loader/spinner";
import AbortController from "abort-controller";
import VisibilityOff from "../../img/visibility_off.svg";
import Visibility from "../../img/visibility.svg";
import {
  allStarDefaultCompetition,
  EligibilitySorareCompetitionsFilter,
  initialEligibilityCompetitionValue,
} from "../competitions/eligibilityCompetitionsPicker";

const limitAPI = 30;

const sortOptions = [
  { name: "Highest to lowest price", value: "price desc" },
  { name: "Lowest to highest price", value: "price asc" },
  {
    name: "Highest to lowest yesterday variation",
    value: "yesterday_variation desc",
  },
  {
    name: "Lowest to highest yesterday variation",
    value: "yesterday_variation asc",
  },
  {
    name: "Highest to lowest last week variation",
    value: "last_week_variation desc",
  },
  {
    name: "Lowest to highest last week variation",
    value: "last_week_variation asc",
  },
];

function PriceList(props) {
  const [priceList, setPriceList] = useState({
    list: [],
    hasNext: false,
    offset: 0,
  });
  const [option, setOption] = useState("price desc");
  const [isOpen, setOverlay] = useState(false);
  const [currency, setCurrency] = useState("");
  const [position, setPosition] = useState([]);
  const [scarcity, setScarcity] = useState(["LIMITED", "RARE", "SUPER RARE", "UNIQUE", "CUSTOM SERIES"]);
  const [league, setLeague] = useState([]);
  const [eligibilityCompetitions, setEligibilityCompetitions] = React.useState(initialEligibilityCompetitionValue);
  const [sport, setSport] = useState("all");
  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [priceListStats, setPriceListStats] = useState({
    total_price: 0,
    total_yesterday_price: 0,
    total_last_week_price: 0,
  });
  const [abortController, setAbortController] = useState(new AbortController());

  const closeOverlay = () => setOverlay(false);
  const configs = {
    animate: true,
    clickDismiss: true,
    // escapeDismiss: false,
    focusOutline: false,
  };

  useEffect(() => {
    getPriceListStats({});
    getPriceList({ rankOption: "price desc" });
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (props.user.preferredUnit) {
      setCurrency(props.user.preferredUnit);
    } else {
      setCurrency("eth");
    }
    ReactTooltip.rebuild();
  }, [props.user]);

  const getPriceList = ({ rankOption, sc, c, l, p, sp, o, ep }) => {
    if (sc === undefined) sc = scarcity;
    if (l === undefined) l = league;
    if (p === undefined) p = position;
    if (sp === undefined) sp = sport;
    if (o === undefined) o = priceList.offset;
    if (c === undefined) c = currency;
    if (ep === undefined) ep = eligibilityCompetitions;
    if (rankOption === undefined) rankOption = option;
    let [criteria, order] = rankOption.split(" ");
    const abortController = new AbortController();
    setLoading(true);
    setAbortController(abortController);
    const epParams = Object.keys(ep).filter((k) => k !== allStarDefaultCompetition.id);
    props
      .fetch("/apiv2/manager/price-list", {
        method: "POST",
        headers: withJSONPagination(o, limitAPI),
        body: JSON.stringify({
          manager: props.manager.Slug,
          criteria: criteria,
          scarcity: sc,
          league: l,
          order: order,
          position: p,
          sport: sp,
          currency: c,
          competitionsEligible: epParams,
        }),
        signal: abortController.signal,
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        res = res || [];
        const newPriceList = {
          list: o === 0 ? res : [...priceList.list, ...res],
          hasNext: hasNext,
          offset: o + limitAPI,
        };
        setPriceList(newPriceList);
        ReactTooltip.rebuild();
        setLoading(false);
      })
      .catch(errorCatcher())
      .finally(() => setLoading(false));
  };

  const getPriceListStats = ({ sc, c, l, p, sp }) => {
    if (sc === undefined) sc = scarcity;
    if (l === undefined) l = league;
    if (p === undefined) p = position;
    if (sp === undefined) sp = sport;
    if (c === undefined) c = currency;
    setLoadingStats(true);
    props
      .fetch("/apiv2/manager/price-list/stats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
        body: JSON.stringify({
          manager: props.manager.Slug,
          scarcity: sc,
          league: l,
          position: p,
          sport: sp,
          currency: c,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setPriceListStats(res);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher())
      .finally(() => setLoadingStats(false));
  };

  let totalPrice = formatPrice(priceListStats.total_price[currency] || 0, currency);
  let yesterdayPrice = formatPrice(priceListStats.total_yesterday_price[currency] || 0, currency);

  const scarcityChange = (s) => {
    let newScarcities = [...scarcity];
    if (newScarcities.indexOf(s) >= 0) {
      newScarcities = newScarcities.filter(function (value, index, arr) {
        return value !== s;
      });
    } else {
      newScarcities.push(s);
    }
    newScarcities.sort(function (a, b) {
      if (a === "LIMITED") {
        return -1;
      } else if (a === "UNIQUE") {
        return 1;
      } else if (b === "LIMITED") {
        return 1;
      } else if (b === "UNIQUE") {
        return -1;
      } else if (a === "RARE" && b === "SUPER RARE") {
        return -1;
      } else if (b === "RARE" && a === "SUPER RARE") {
        return 1;
      }
    });
    setScarcity(newScarcities);
  };

  const setHideValuations = () => {
    props
      .fetch("/apiv2/user/changeHideValuations", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: !props.user.hideValuations,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        props.changeHideValuations(!props.user.hideValuations);
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"mt-8"}>
      {priceList.list !== undefined && (
        <div className={"space-y-4"}>
          <div className={"w-8/12 md:w-4/12 lg:w-4/12 xl:w-3/12 2xl:w-2/12 mx-auto"}>
            <div className={"rounded bg-focus py-4 space-y-2 px-4 md:px-6 lg:px-4 shadow-sm"}>
              <div className={"flex flex-row space-x-2"}>
                <p className={"text-title-grey font-semibold text-tiny md:text-base"}>Roster total price</p>
                <img
                  className={"cursor-pointer w-4 self-center"}
                  onClick={() => setHideValuations()}
                  src={props.user.hideValuations ? VisibilityOff : Visibility}
                />
              </div>
              {loadingStats || props.user.hideValuations ? (
                <div
                  role="status"
                  className={
                    props.user.hideValuations ? "max-w-sm flex flex-col gap-2 my-1" : "max-w-sm animate-pulse flex flex-col gap-2 my-1"
                  }
                >
                  <div className="h-9 bg-grey-e5 rounded-lg dark:bg-grey-f2 w-1/2"></div>
                </div>
              ) : (
                <p className={"text-2xl md:text-3xl font-semibold"}>{totalPrice}</p>
              )}
              <div className={""}>
                <a
                  className={"text-center text-sm font-semibold text-brand hover:font-semibold cursor-pointer"}
                  onClick={() => setOverlay(true)}
                >
                  How does it work?
                </a>
              </div>
            </div>
          </div>
          <div className={"flex flex-col md:flex-row justify-center text-center text-base gap-2"}>
            <div className={"w-full md:w-80"}>
              <SelectSearch
                options={sortOptions}
                className={"select-search border z-30 border-grey-f2 rounded"}
                value={option}
                onChange={(v) => {
                  setOption(v);
                  getPriceList({ rankOption: v, o: 0 });
                }}
                filterOptions={fuzzySearch}
                printOptions={"on-focus"}
              />
            </div>
            <div>
              <CurrencyPicker
                currency={currency}
                onChange={(c) => {
                  setCurrency(c);
                  getPriceList({ c: c, o: 0 });
                }}
              />
            </div>
          </div>
          <div className={"flex flex-col md:flex-row justify-center gap-2"}>
            <div>
              <MultiScarcityPicker customSeries scarcity={scarcity} onChange={(s) => scarcityChange(s)} />
            </div>
            <div>
              <SelectSearch
                options={positions_objects}
                className={"select-search border z-20 border-grey-f2 rounded"}
                value={position}
                multiple={true}
                closeOnSelect={false}
                onChange={(v) => {
                  setPosition(v);
                }}
                filterOptions={fuzzySearch}
                placeholder="Filter by position"
                printOptions={"on-focus"}
              />
            </div>
            <div>
              <SelectSearch
                options={leagues_with_divisions_objects_not_all}
                className={"select-search border z-20 border-grey-f2 rounded"}
                value={league}
                multiple={true}
                closeOnSelect={false}
                onChange={(v) => {
                  setLeague(v);
                }}
                filterOptions={fuzzySearch}
                placeholder="Filter by league"
                printOptions={"on-focus"}
              />
            </div>
            <div>
              <EligibilitySorareCompetitionsFilter
                width={"w-full"}
                minWidth={"min-w-[15rem]"}
                maxWidth={"max-w-[80rem]"}
                widthPanel={"w-80"}
                displayOptions={{ shadow: "shadow" }}
                selected={eligibilityCompetitions}
                onSelect={(v) => {
                  let newLeagues = {};
                  if (v === allStarDefaultCompetition.id) {
                    newLeagues = initialEligibilityCompetitionValue;
                  } else {
                    newLeagues = { ...eligibilityCompetitions, [v]: true };
                    delete newLeagues[allStarDefaultCompetition.id]; // makes no sense to have the 'all' selected while others specific ones are selected
                  }
                  setEligibilityCompetitions(newLeagues);
                }}
                onUnselect={(v) => {
                  const newLeagues = { ...eligibilityCompetitions };
                  delete newLeagues[v];
                  if (Object.keys(newLeagues).length === 0) {
                    newLeagues[allStarDefaultCompetition.id] = true;
                  }
                  setEligibilityCompetitions(newLeagues);
                }}
              />
            </div>
            <div>
              <SelectSearch
                options={allSportsOptions}
                className={"select-search border z-20 border-grey-f2 rounded"}
                value={sport}
                multiple={false}
                closeOnSelect={true}
                onChange={(v) => {
                  setSport(v);
                }}
                filterOptions={fuzzySearch}
                placeholder="Filter by sport"
                printOptions={"on-focus"}
              />
            </div>
            <div className={"relative flex justify-center"}>
              <button
                onClick={() => {
                  abortController.abort();
                  getPriceListStats({});
                  getPriceList({ o: 0 });
                }}
                type="button"
                className={`inline-flex justify-center w-[20%] xl:w-auto px-6 py-2 border border-brand text-med font-semibold rounded-lg
                shadow-sm text-white bg-brand focus:outline-none focus:ring-0 text-center hover:bg-brand-light hover:border-brand-light cursor-pointer`}
              >
                Filter
              </button>
              <div className="absolute -right-10 transform translate-y-1/4">{loading && <Spinner />}</div>
            </div>
          </div>
          {isFree(props.user.tier) && (
            <div className="my-8">
              <UpgradeLimitBox
                className={"bg-white bg-upgrade-bg-gallery-prices bg-cover h-upgrade-banner-s w-full"}
                title={"Want more insights?"}
                description={"Become a Star member to unlock price details, price evolution and filters"}
              />
            </div>
          )}
          <InfiniteScroll
            next={() => getPriceList({})}
            hasMore={priceList.hasNext}
            loader={<SDLoading />}
            dataLength={priceList.list.length}
          >
            {priceList.list?.length > 0 ? (
              <div
                className={
                  "px-4 mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 2xl:gap-x-6 gap-4 mx-auto rounded-lg bg-focus py-4"
                }
              >
                {priceList.list?.map((p, i) => {
                  let yesterdayColor = "text-success-green";
                  let weekColor = "text-success-green";
                  let lwVariation = p.last_week_variation.toFixed(1) + "%";
                  let ywVariation = p.yesterday_variation.toFixed(1) + "%";
                  let period = getValuationOriginShort(p.real_time_valuation.ValuationOrigin);
                  if (currency !== "eth") {
                    lwVariation = p.last_week_variation_fiat.toFixed(1) + "%";
                    ywVariation = p.yesterday_variation_fiat.toFixed(1) + "%";
                    period = getValuationOriginShort(p.real_time_valuation.ValuationOriginFiat);
                    if (p.last_week_variation_fiat === 0) {
                      lwVariation = "=";
                      weekColor = "text-brand-black";
                    }
                    if (p.yesterday_variation_fiat === 0) {
                      ywVariation = "=";
                      yesterdayColor = "text-brand-black";
                    }
                    if (p.yesterday_variation_fiat < 0) {
                      yesterdayColor = "text-horrible-red";
                    }
                    if (p.last_week_variation_fiat < 0) {
                      weekColor = "text-horrible-red";
                    }
                  } else {
                    if (p.yesterday_variation < 0) {
                      yesterdayColor = "text-horrible-red";
                    }
                    if (p.last_week_variation < 0) {
                      weekColor = "text-horrible-red";
                    }
                    if (p.last_week_variation === 0) {
                      lwVariation = "=";
                      weekColor = "text-brand-black";
                    }
                    if (p.yesterday_variation === 0) {
                      ywVariation = "=";
                      yesterdayColor = "text-brand-black";
                    }
                  }

                  let lwPrice = "(" + formatValuationHistory(p.last_week_valuation, currency) + ")";
                  if (p.last_week_valuation.Valuation === 0) {
                    lwVariation = "No price";
                    lwPrice = "";
                    weekColor = "text-brand-black";
                  }

                  let ywPrice = "(" + formatValuationHistory(p.yesterday_valuation, currency) + ")";
                  if (p.yesterday_valuation.Valuation === 0) {
                    ywVariation = "No price";
                    ywPrice = "";
                    yesterdayColor = "text-brand-black";
                  }
                  return (
                    <div className={"flex flex-col space-y-1 text-center items-center justify-start"} key={i}>
                      <div className={"object-contain overflow-hidden flex items-center justify-center"}>
                        <a href={"/card/" + p.card.TokenId} target={"_blank"} rel="noreferrer">
                          <img className={"object-contain"} src={p.card.PictureUrl} />
                        </a>
                      </div>
                      <div className={"space-y-0.5"}>
                        <p>
                          <span className={"text-lg md:text-xl font-bold"}>{formatRealTimeAverage(p.real_time_valuation, currency)}</span>{" "}
                          <span className={"text-xs align-middle"}>({period})</span>
                        </p>
                        {(t1OrAbove(props.user.tier) || i <= 2) && (
                          <div>
                            <p data-tip={"From yesterday"}>
                              <span className={"text-base font-medium " + yesterdayColor}>{ywVariation}</span>{" "}
                              <span className={"text-xs align-middle"}>{ywPrice}</span>
                            </p>
                            <p data-tip={"From last week"}>
                              <span className={"text-base font-medium " + weekColor}>{lwVariation}</span>{" "}
                              <span className={"text-xs align-middle"}>{lwPrice}</span>
                            </p>
                          </div>
                        )}
                        {isFree(props.user.tier) && i > 2 && (
                          <div>
                            <p data-tip={"Variation from yesterday available for Star members only"}>
                              <CardLocker bg={"bg-white"} />
                            </p>
                            <p data-tip={"Variation from last week available for Star members only"}>
                              <CardLocker bg={"bg-white"} />
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={"flex justify-center text-textGrey3 text-md w-full"}>
                {loading ? <SDLoading /> : <span className={"py-5 text-center"}>No card with these filters</span>}
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
      <ReactTooltip />
      <Overlay configs={configs} isOpen={isOpen} closeOverlay={closeOverlay}>
        <div className={"space-y-4"}>
          <h2 className={"text-center"}>How does it work?</h2>
          <div className={"text-center flex flex-col justify-center items-center"}>
            <div className={"object-contain overflow-hidden flex items-center justify-center w-6/12"}>
              <img
                className={"object-contain"}
                src={"https://assets.sorare.com/card/612325bb-b2fa-45ea-899d-431f01685f5d/picture/154345e8e40cba9ea1ee5352849aa1b9"}
              />
            </div>
            <div className={"space-y-0.5"}>
              <p>
                <span className={"text-lg md:text-xl font-bold"}>Real-time card average price</span>{" "}
                <span className={"text-xs align-middle"}>(computed over how many days)</span>
              </p>
              <p data-tip={"From yesterday"}>
                <span className={"text-base font-medium"}>Variation from yesterday</span>{" "}
                <span className={"text-xs align-middle"}>Yesterday price</span>
              </p>
              <p data-tip={"From last week"}>
                <span className={"text-base font-medium"}>Variation from last week</span>{" "}
                <span className={"text-xs align-middle"}>Last week price</span>
              </p>
            </div>
          </div>
          <div className={"text-base space-y-4"}>
            <div>
              <p>Real-time card average price is computed as it follows:</p>
              <ul className={"list-inside list-decimal"}>
                <li>If available, 3 days average price is used.</li>
                <li>If 3 days average price isn&apos;t available, 7 days average price is used (if available).</li>
                <li>If 7 days average price isn&apos;t available, 14 days average price is used (if available).</li>
                <li>If 14 days average price isn&apos;t available, 30 days average price is used (if available).</li>
                <li>If 30 days average price isn&apos;t available, last public sale is used (if available).</li>
                <li>If the card was never sold publicly, auction starting price is used.</li>
                <li className={"font-semibold"}>
                  If the current floor price is lower than the real-time card average price computed as above, the floor price becomes the
                  real-time card average price.
                </li>
              </ul>
            </div>
            <div>
              <p>
                As for every average price computed on SorareData, all auctions and public secondary market offers are taken into account.
                Real-time average card prices are computed after every sale of a card.
              </p>
            </div>
            <div>
              <p className={"font-semibold"}>Total roster price is the simple addition of all real-time card prices.</p>
            </div>
          </div>
        </div>
      </Overlay>
    </div>
  );
}

export default withUser(PriceList);
