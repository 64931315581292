import React from "react";

function MainDetailedStatLine(props) {
  let textColor = "text-on-surface-variant";
  let textValue = "text-on-surface";
  if (props.dark) {
    textColor = "text-[#CCCCCC]";
    textValue = "text-[#E6E6E6]";
  }
  return (
    <div className={"flex flex-row space-x-2"}>
      <img src={props.icon} />
      <p className={"flex self-center space-x-1.5"}>
        <span className={"text-xl font-semibold self-center " + textValue}>{props.value}</span>{" "}
        <span className={textColor + " uppercase font-semibold text-xs self-center"}>{props.stat}</span>
      </p>
    </div>
  );
}

export default MainDetailedStatLine;
