import React, { useState } from "react";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import useDebounce from "../../hooks/useDebounce";
import { LeagueSelector } from "./longTermLeaguesSelectors";
import { SearchInput } from "../util/input";
import { MobileModale } from "../util/mobileModale";
import { XIcon } from "@heroicons/react/outline";
import BoxWithToggle from "../util/boxWithToggle";
import Spinner from "../loader/spinner";
import { ReactComponent as FilterIcon } from "@material-design-icons/svg/filled/filter_alt.svg";

export const Filters = (props) => {
  const { loading, loadingLeagues, selectedLeague, selectedPeriod, filters, leagues, onChangeFilters, onChangeLeague } = props;
  const [managerFilter, setManagerFilter] = useState(filters.managerName);
  const mdQueries = useTailwindMediaQueries();

  const [isLeagueSelectFocused, setIsLeagueSelectFocused] = useState(false);
  const [openMobileModale, setOpenMobileModale] = useState(false);

  const onChangeManagerFilter = (val) => {
    const newFilters = { ...filters, managerName: val };
    onChangeFilters(newFilters);
  };

  const onChangecanBeatMe = (val) => {
    const newFilters = { ...filters, canBeatMe: val };
    onChangeFilters(newFilters);
  };

  const onChangeUnfinishedOnly = (val) => {
    const newFilters = { ...filters, unfinishedOnly: val };
    onChangeFilters(newFilters);
  };

  const debouncedOnChangeManagerFilter = useDebounce(onChangeManagerFilter, 400);

  return (
    <div className={"relative flex flex-row justify-between items-center gap-3 h-full w-full"}>
      <div className={"relative flex flex-row justify-start lg:justify-center items-center gap-3 h-full w-full"}>
        <div className={`h-10 z-10 ${isLeagueSelectFocused ? "w-[8em] md:w-[12em] lg:w-full" : "w-[8em] md:w-[12em] lg:w-[15em]"}`}>
          <LeagueSelector
            loading={loadingLeagues}
            onFocus={() => setIsLeagueSelectFocused(true)}
            onBlur={() => setIsLeagueSelectFocused(false)}
            leagues={selectedPeriod?.leagueIds?.map((id) => leagues[id]) || []}
            selected={selectedLeague}
            onSelect={onChangeLeague}
          />
        </div>
        {filters.canBeatMe !== undefined && filters.canBeatMe !== null && (
          <div
            className={"hidden lg:block h-full"}
            title={
              "Show only the managers ranked below me but who could surpass me in the standings considering the maximum possible score of our live lineups"
            }
          >
            <Toggle isActive={filters.canBeatMe} setActive={() => onChangecanBeatMe(!filters.canBeatMe)} label="Can beat me" />
          </div>
        )}
        <div className={"hidden lg:block h-full"} title={"Show only managers with cards left to play or currently playing"}>
          <Toggle
            isActive={filters.unfinishedOnly}
            setActive={() => onChangeUnfinishedOnly(!filters.unfinishedOnly)}
            label="Unfinished only"
          />
        </div>
        <div className={"h-full"}>
          <SearchInput
            search={managerFilter}
            onChange={(e) => {
              setManagerFilter(e.target.value);
              debouncedOnChangeManagerFilter(e.target.value);
            }}
            placeholder={"Search manager"}
            onReset={() => {
              setManagerFilter("");
              onChangeManagerFilter("");
            }}
          />
        </div>
      </div>
      <div
        className={"block lg:hidden rounded-full p-2 bg-surface-container items-center justify-center cursor-pointer"}
        onClick={() => setOpenMobileModale(true)}
      >
        <MobileModale open={!mdQueries["md"] && openMobileModale} close={() => setOpenMobileModale(false)}>
          <div className={"bg-surface-container text-on-surface rounded-t-2xl text-left"}>
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <div className={"flex flex-row justify-between px-4 py-4"}>
                <h3 className={"font-headers text-on-surface font-semibold m-0"}>Filters</h3>
                <div className={"flex flex-row gap-3"}>
                  <XIcon
                    className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
                    aria-hidden="true"
                    onClick={() => setOpenMobileModale(false)}
                  />
                </div>
              </div>
            </div>
            <div className={`flex flex-col gap-3 w-full px-4 py-4 overflow-y-auto`}>
              {filters.canBeatMe !== undefined && filters.canBeatMe !== null && (
                <div className={"border border-transparent-inverse-surface-low border-opacity-10 rounded-lg py-2"}>
                  <BoxWithToggle
                    padding="px-2"
                    color="text-on-surface"
                    label={"Can beat me"}
                    isActive={filters.canBeatMe}
                    setActive={() => onChangecanBeatMe(!filters.canBeatMe)}
                  />
                </div>
              )}
              <div className={"border border-transparent-inverse-surface-low border-opacity-10 rounded-lg py-2"}>
                <BoxWithToggle
                  padding="px-2"
                  color="text-on-surface"
                  label={"Unfinished only"}
                  isActive={filters.unfinishedOnly}
                  setActive={() => onChangeUnfinishedOnly(!filters.unfinishedOnly)}
                />
              </div>
            </div>
          </div>
        </MobileModale>
        <FilterIcon className={"fill-on-surface h-5 w-5"} />
      </div>
      {loading && (
        <div className={"hidden lg:flex absolute flex-row transform -right-10 justify-center self-center"}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

const Toggle = (props) => {
  const { isActive, setActive, label, disable } = props;
  return (
    <div className={`${disable ? "opacity-40" : ""} h-full`}>
      <BoxWithToggle
        isActive={isActive}
        setActive={disable ? undefined : setActive}
        label={label}
        className={"h-full"}
        rounded={"rounded-full"}
        color={"text-on-surface"}
      />
    </div>
  );
};
