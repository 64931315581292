import React from "react";
import { ReactComponent as IconWallet } from "@material-design-icons/svg/filled/wallet.svg";
import clsx from "clsx";

function EnabledWallets({ className, enabledWallets, containerClassName }) {
  let containerClass = "text-sm py-1 px-3 flex items-center gap-2 border border-outline-variant bg-surface-container rounded-full";
  if (containerClassName) {
    containerClass = containerClassName;
  }
  return (
    <div className={clsx("w-auto flex", className)}>
      <div className={containerClass}>
        <IconWallet className={"fill-on-surface"} />
        <span className={"text-on-surface font-semibold"}>{enabledWallets}</span>
      </div>
    </div>
  );
}

export default EnabledWallets;
