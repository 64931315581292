import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import PasswordChange from "./changePassword";
import EmailSetup from "./emailSetup";
import { ChooseCurrencyAndScarcity } from "./chooseCurrency";
import Dashboard from "../../img/dashboard.svg";
import Display from "../../img/logo-display-settings.svg";
import Mail from "../../img/mail.svg";
import Password from "../../img/Group.png";
import Subscription from "../../img/subscription-logo.svg";
import LayoutPreference from "./layoutPreference";
import GetSubscription from "./getSubscription";
import { errorCatcher } from "../util/errors";
import AccountDeletion from "./accountDeletion";
import ls from "local-storage";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { isFree } from "../util/handleSubscriptionTier";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import AveragePreference from "./averagePreference";
import PrivacySettings from "./privacySettings";
import CardsPreference from "./cardsPreference";

function MenuButton(props) {
  let textStyle = "text-base font-medium";
  let divStyle =
    "flex flex-row space-x-2 p-1.5 rounded-md cursor-pointer hover:bg-containers-surface-hover-primarytint hover:text-primary hover:font-semibold";
  if (props.picked) {
    textStyle = "text-base font-semibold text-primary";
    divStyle += " bg-containers-surface-hover-primarytint";
  }
  return (
    <div className={divStyle} onClick={props.onClick}>
      <div className={"w-4 h-4 self-center"}>
        <img className={"w-full h-full object-contain"} src={props.img} />
      </div>
      <p className={textStyle}>{props.title}</p>
    </div>
  );
}

function UserProfile(props) {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let target = query.get("target");
  if (!target) {
    target = "scarcity";
  }
  const [user, setUser] = useState({});
  const [picked, setPicked] = useState(target);

  const [portalUrl, setPortalUrl] = useState("");

  const customerPortal = () => {
    props
      .fetch("/apiv2/stripe/customerPortal", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.redirectUrl !== "" && res.redirectUrl !== undefined) {
          setPortalUrl(res.redirectUrl);
        }
      })
      .catch();
  };

  const getUserInfo = () => {
    props
      .fetch("/apiv2/user/profileInfo", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then((res) => {
        let tier = ls.get("tier") || "free";
        res.plan = tier;
        setUser(res);
        props.forceProfileReload();
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    const params = new URLSearchParams({ ["target"]: picked });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [picked]);

  useEffect(() => {
    if (props.user.sorareSlug && props.user.sorareSlug !== "") {
      customerPortal();
    }
  }, [props.user]);
  useEffect(() => {
    getUserInfo();
  }, []);

  let subMenuStyle = "font-medium text-on-surface-variant text-sm";

  return (
    <div className={"flex flex-col md:flex-row space-y-8 md:space-y-0 min-h-screen h-full text-on-surface"}>
      <div className={"w-full md:w-[18em] flex flex-col bg-surface-container pb-4"}>
        <div
          className={"flex flex-col w-full items-center self-center py-8 border-b border-transparent-inverse-surface-low border-opacity-10"}
        >
          <div className={"w-32 h-32 flex flex-col items-center"}>
            <img className={"w-full h-full object-contain rounded-full"} src={user.PictureUrl} />
          </div>
          <div>
            <p className={"font-headers text-lg font-semibold"}>{user.Username}</p>
          </div>
        </div>
        <div className={"mx-4 mt-4 flex flex-col space-y-4"}>
          <div className={"space-y-2"}>
            <p className={subMenuStyle}>Preferences</p>
            <div>
              <MenuButton
                title={"Scarcity & Currency"}
                onClick={() => setPicked("scarcity")}
                picked={picked === "scarcity"}
                img={Dashboard}
              />
            </div>
            <div>
              <MenuButton title={"Display preference"} onClick={() => setPicked("display")} picked={picked === "display"} img={Display} />
            </div>
            <div>
              <MenuButton title={"Privacy settings"} onClick={() => setPicked("privacy")} picked={picked === "privacy"} img={Password} />
            </div>
          </div>
          <div className={"space-y-2"}>
            <p className={subMenuStyle}>Account</p>
            <div className={"space-y-2"}>
              <MenuButton title={"Email"} picked={picked === "email"} onClick={() => setPicked("email")} img={Mail} />
              <MenuButton title={"Password"} picked={picked === "password"} onClick={() => setPicked("password")} img={Password} />
              <MenuButton
                title={"Membership"}
                picked={picked === "subscription"}
                onClick={() => setPicked("subscription")}
                img={Subscription}
              />
              {portalUrl && (
                <MenuButton
                  title={"Payment details & Invoices"}
                  onClick={() => window.open(portalUrl, "_blank", "noopener,noreferrer")}
                  img={Subscription}
                />
              )}
              <MenuButton
                title={"Account deletion"}
                picked={picked === "account_deletion"}
                onClick={() => setPicked("account_deletion")}
                img={Dashboard}
              />
            </div>
          </div>
        </div>
      </div>
      {picked === "scarcity" && <ChooseCurrencyAndScarcity />}
      {picked === "display" && (
        <div className={"mx-6 w-4/6"}>
          <div className={"my-8 space-y-4"}>
            <p className={"text-xl text-primary font-headers font-semibold"}>Display preference</p>
            <div className={"space-y-8"}>
              {isFree(props.user.tier) && (
                <UpgradeLimitBox
                  className={"bg-white bg-upgrade-bg-layout-preferences bg-cover h-upgrade-banner-m w-full mr-8"}
                  title={"Want personalization?"}
                  description={"Become a Star member to unlock display personalization."}
                  button={"Upgrade"}
                />
              )}
              <LayoutPreference />
              <AveragePreference />
              <CardsPreference />
            </div>
          </div>
        </div>
      )}
      {picked === "privacy" && (
        <div className={"mx-6 w-4/6"}>
          <div className={"mt-8 space-y-4"}>
            <p className={"text-xl text-primary font-headers font-semibold"}>Privacy settings</p>
            <div className={"space-y-8"}>
              <PrivacySettings />
            </div>
          </div>
        </div>
      )}
      {picked === "email" && (
        <div className={"mx-6 "}>
          <EmailSetup connectedUser={user} />
        </div>
      )}
      {picked === "password" && (
        <div className={"mx-6 "}>
          <PasswordChange />
        </div>
      )}
      {picked === "account_deletion" && (
        <div className={"mx-6"}>
          <AccountDeletion />
        </div>
      )}
      {picked === "subscription" && (
        <div className={"mt-8 space-y-4 w-full"}>
          <GetSubscription />
        </div>
      )}
    </div>
  );
}

export default withRouter(withUser(UserProfile));
