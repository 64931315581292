import React, { useEffect, useState } from "react";
import { Box } from "grommet";
import { withUser } from "../../userContext";
import SO5Games from "./SO5Games";
import SO5LiveRankings from "./SO5LiveRankings";
import SO5TopPerformers from "./SO5TopPerformers";
import SO5SelectionStats from "./SO5SelectionStats";
import SO5PlayersPerformance from "./SO5PlayersPerformance";
import SO5ImpactPlayers from "./SO5ImpactPlayers";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import PreviousGw from "../../img/previous-gw.svg";
import NextGw from "../../img/next-gw.svg";
import GwBanner from "../../img/gwbanner.png";
import { errorCatcher } from "../util/errors";
import SO5Matchups from "./SO5Matchups";
import SO5ManagersPerformance from "./SO5ManagersPerformance";
import { Link } from "react-router-dom";
import { t2OrAbove } from "../util/handleSubscriptionTier";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import ProjGwCenterTab from "../probableStarters/projGwCenterTab";
import { sorareBasketball, sorareFootball } from "../util/sports";
import SportsPicker from "../util/sportsPicker";
import BasketballLiveRankings from "./basketballLiveRankings";
import BasketballGwProjections from "./basketballGwProjections";
import GwPickScores from "./gwPickScores";
import { useQuery } from "../util/useQuery";
import { SportNotSupported } from "../util/sportNotSupported";

function SO5ResultsPage(props) {
  const query = useQuery();

  let activeSport = query.get("sport") || sorareFootball;
  if (props.match.params.tab === "projections") {
    activeSport = sorareBasketball;
  }

  const activeTab = props.match.params.tab || "games";
  const [gwNumber, setGwNumber] = useState(0);
  const [displayGw, setDisplayGw] = useState("");
  const [theGw, setTheGw] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [sport, setSport] = useState(activeSport);

  useEffect(() => {
    document.title = "Gameweek center";
    getLatestGw();
  }, [sport]);

  const getLatestGw = () => {
    props
      .fetch("/apiv2/gw/latest?sport=" + sport)
      .then((response) => response.json())
      .then(async (res) => {
        if (activeTab === "projections" || activeTab === "pick-scores" || activeTab === "community-lineups") {
          getGwByNumber(res.GwNumber + 1);
        } else {
          setGwNumber(res.GwNumber);
          setDisplayGw(res.DisplayName);
          setTheGw(res);
          setStartDate(new Date(res.StartDate));
        }
      })
      .catch(errorCatcher());
  };

  const getGwByNumber = (gw) => {
    props
      .fetch("/apiv2/gw/byNumber/" + gw + "?sport=" + sport)
      .then((response) => response.json())
      .then(async (res) => {
        setGwNumber(res.GwNumber);
        setDisplayGw(res.DisplayName);
        setTheGw(res);
        setStartDate(new Date(res.StartDate));
      })
      .catch(errorCatcher());
  };

  const next = async () => {
    getGwByNumber(gwNumber + 1);
    setGwNumber(gwNumber + 1);
  };

  const previous = async () => {
    getGwByNumber(gwNumber - 1);
    setGwNumber(gwNumber - 1);
  };

  const gwDisplayName = displayGw;
  const inactiveClass =
    "text-base border-b-4 border-brand-black py-2 px-6 block font-medium text-textGrey4 hover:text-white focus:outline-none";
  const activeClass = "text-base border-b-4 border-brand py-2 px-6 font-medium focus:outline-none";

  return (
    <div>
      <div
        className={"bg-white flex flex-col "}
        style={{
          backgroundImage: "url('" + GwBanner + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "180px",
        }}
      >
        <div
          className={
            "flex flex-col h-full justify-evenly sm:h-[inherit] sm:flex-row sm:py-6 sm:h-[180px] sm:pb-8 " + GLOBAL_MARGIN_NO_BG_NO_PAD
          }
        >
          <div className={"flex flex-row w-full sm:w-6/12 self-center justify-start"}>
            <p className={"text-4xl font-headers font-semibold text-white text-center sm:text-left w-full"}>Gameweek center</p>
          </div>
          <div className={"w-full sm:w-6/12 self-center flex flex-col space-y-2 items-center sm:items-end justify-center sm:justify-end"}>
            <div>
              <SportsPicker sport={sport} change={(s) => setSport(s)} />
            </div>
            <div className={"flex flex-row space-x-4 self-center justify-center sm:self-end sm:justify-end"}>
              <div className={"self-center py-2 px-4 rounded-lg bg-white"} style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}>
                <p className={"font-semibold text-sm sm:text-base self-center"}>{gwDisplayName}</p>
              </div>
              <div
                className={"bg-white cursor-pointer px-4 py-3 flex flex-row justify-center rounded-lg"}
                onClick={() => previous()}
                style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
              >
                <img src={PreviousGw} />
              </div>
              <div
                className={"bg-white cursor-pointer px-4 py-3 flex flex-row justify-center rounded-lg"}
                onClick={() => next()}
                style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
              >
                <img src={NextGw} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"bg-brand-black"}>
        <div className={"xl:ml-16 mx-auto mb-6 text-focus " + GLOBAL_MARGIN_NO_BG_NO_PAD}>
          <nav className="lg:flex lg:flex-row lg:block grid grid-cols-2 grid-rows-4">
            <Link to={"/SO5Results/sections/games"}>
              <button className={activeTab === "games" ? activeClass : inactiveClass}>Games</button>
            </Link>
            {sport === sorareBasketball && (
              <Link to={"/SO5Results/sections/rankings"}>
                <button className={activeTab === "rankings" ? activeClass : inactiveClass}>Leaderboards</button>
              </Link>
            )}
            {sport === sorareBasketball && (
              <Link to={"/SO5Results/sections/projections"}>
                <button className={activeTab === "projections" ? activeClass : inactiveClass}>Projections</button>
              </Link>
            )}
            {(sport === sorareFootball || sport === sorareBasketball) && (
              <Link to={"/SO5Results/sections/impact"}>
                <button className={activeTab === "impact" ? activeClass : inactiveClass}>Impact players</button>
              </Link>
            )}
            {/* {sport === sorareFootball && <Link to={"/SO5Results/sections/stacks"}>
                            <button className={activeTab === "stacks" ? (activeClass) : (inactiveClass)}>
                                Stacks
                            </button>
                        </Link>} */}
            {sport === sorareFootball && (
              <Link to={"/SO5Results/sections/pick-scores"}>
                <button className={activeTab === "pick-scores" ? activeClass : inactiveClass}>Pick scores</button>
              </Link>
            )}
            {sport === sorareFootball && (
              <Link to={"/SO5Results/sections/matchups"}>
                <button className={activeTab === "matchups" ? activeClass : inactiveClass}>Matchups</button>
              </Link>
            )}
            {(sport === sorareFootball || sport === sorareBasketball) && (
              <Link to={"/SO5Results/sections/managers"}>
                <button className={activeTab === "managers" ? activeClass : inactiveClass}>Managers</button>
              </Link>
            )}
            {sport === sorareFootball && (
              <Link to={"/SO5Results/sections/rankings"}>
                <button className={activeTab === "rankings" ? activeClass : inactiveClass}>Leaderboards</button>
              </Link>
            )}
            {sport === sorareFootball && (
              <Link to={"/SO5Results/sections/stats"}>
                <button className={activeTab === "stats" ? activeClass : inactiveClass}>Stats</button>
              </Link>
            )}
            {sport === sorareFootball && (
              <Link to={"/SO5Results/sections/my-players"}>
                <button className={activeTab === "my-players" ? activeClass : inactiveClass}>Players</button>
              </Link>
            )}
            {sport === sorareFootball && (
              <Link to={"/SO5Results/sections/community-lineups"}>
                <button className={activeTab === "community-lineups" ? activeClass : inactiveClass}>Lineup projections</button>
              </Link>
            )}
          </nav>
        </div>
      </div>
      <div>
        {activeTab === "games" && (
          <div>
            <SO5Games gwNumber={gwNumber} gw={theGw} sport={sport} />
          </div>
        )}
        {activeTab === "impact" && (sport === sorareFootball || sport === sorareBasketball) && (
          <div>
            <SO5ImpactPlayers gwNumber={gwNumber} sport={sport} />
          </div>
        )}
        {activeTab === "matchups" && sport === sorareFootball && t2OrAbove(props.user.tier) && (
          <div>
            <SO5Matchups gwNumber={gwNumber} />
          </div>
        )}
        {activeTab === "matchups" && sport === sorareFootball && !t2OrAbove(props.user.tier) && (
          <div>
            <PlayerBlockedVideo
              title="Want more insights?"
              description="Become a Star member to unlock Matchups and more."
              videoLink="https://www.youtube.com/embed/E8vLClqW-lk"
            />
          </div>
        )}
        {activeTab === "rankings" && sport === sorareFootball && (
          <div>
            <SO5LiveRankings gwNumber={gwNumber} />
          </div>
        )}
        {activeTab === "rankings" && sport === sorareBasketball && (
          <div>
            <SportNotSupported sport={sport} featureName="Leaderboards" />;
          </div>
        )}
        {activeTab === "pick-scores" && sport === sorareFootball && (
          <div>
            <GwPickScores gwNumber={gwNumber} />
          </div>
        )}
        {activeTab === "projections" && sport === sorareBasketball && (
          <div>
            <SportNotSupported sport={sport} featureName="Projections" />;
          </div>
        )}
        {activeTab === "managers" && sport === sorareFootball && (
          <div>
            <SO5ManagersPerformance gameweek={theGw?.Id} gwNumber={gwNumber} sport={sorareFootball} />
          </div>
        )}
        {activeTab === "managers" && sport === sorareBasketball && (
          <div>
            <SportNotSupported sport={sport} featureName="Manager performance" />;
          </div>
        )}
        {activeTab === "stats" && sport === sorareFootball && (
          <div>
            <Box>
              {startDate !== null && startDate !== undefined && Date.now() >= startDate && (
                <SO5SelectionStats gwNumber={gwNumber} limit={20} />
              )}
            </Box>
            <Box>
              <SO5TopPerformers gwNumber={gwNumber} />
            </Box>
          </div>
        )}
        {activeTab === "my-players" && sport === sorareFootball && (
          <div>
            <SO5PlayersPerformance gwNumber={gwNumber} />
          </div>
        )}
        {activeTab === "community-lineups" && sport === sorareFootball && (
          <div className={GLOBAL_MARGIN_NO_BG_NO_PAD}>
            <ProjGwCenterTab gwNumber={gwNumber} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(SO5ResultsPage);
