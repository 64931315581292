import React, { useState } from "react";
import AddLineup from "../../img/add-lineup-circle.svg";
import AddLineupBrand from "../../img/add-lineup-circle-brand.svg";
import SaveIcon from "../../img/save-icon-lineup.svg";
import DeleteIcon from "../../img/delete-icon-lineup.svg";
import RemoveCardIcon from "../../img/remove-card-builder.svg";
import UniqueHeader from "../../img/UNIQUE.svg";
import MixteHeader from "../../img/MIXTE.svg";
import SuperRareHeader from "../../img/SUPERRARE.svg";
import RareHeader from "../../img/RARE.svg";
import LimitedHeader from "../../img/limited-header.svg";
import NBAProjectedScore from "../util/nbaProjectionScore";
import NewCardIcon from "../util/newCardIcon";
import LineupBuilderBg from "../../img/mlb-lineup-builder-bg-color.png";
import LineupBuilderBgBlack from "../../img/mlb-lineup-builder-bg-black.png";
import { withUser } from "../../userContext";
import { ReactComponent as Bulb } from "../../img/bulb.svg";
import PlayerAvailability from "../players/playerAvailability";

function BaseballLineupBuilderLineupCard(props) {
  const [isHover, setIsHover] = useState(false);

  const entry = props.card;
  const showStats = props.showStats;
  const isMvp = props.mvp;
  const concept = props.concept;
  const picked = props.pickedPosition === props.position;
  if (entry === null) {
    if (picked) {
      return (
        <div
          className={"cursor-pointer border-2 border-white rounded-lg flex flex-col items-center self-center justify-center space-y-1"}
          style={{ width: "109px", height: "263px" }}
        >
          <div
            className={
              "w-full h-full bg-[#F8E7E0] bg-opacity-80 border-2 border-brand rounded-lg flex flex-col items-center self-center justify-center space-y-1"
            }
          >
            <div className={""}>
              <img src={AddLineupBrand} className={""} />
            </div>
            <p className={"self-center text-center text-brand font-semibold text-xs"}>{props.position}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => props.changePosition(props.position)}
          className={
            "cursor-pointer border-2 border-white bg-[#000000] bg-opacity-30 rounded-lg flex flex-col items-center self-center justify-center space-y-1"
          }
          style={{ width: "109px", height: "263px" }}
        >
          <div className={""}>
            <img src={AddLineup} />
          </div>
          <p className={"self-center text-center text-white font-semibold text-xs"}>{props.position}</p>
        </div>
      );
    }
  } else if (entry) {
    let card = props.card.card;
    let opps = [];
    if (entry.projections !== null) {
      entry.projections.map((proj) => {
        if (proj.homeTeam.TeamId === entry.player.TeamId) {
          opps.push(proj.awayTeam.PictureUrl);
        } else {
          opps.push(proj.homeTeam.PictureUrl);
        }
      });
    }
    let border = "";
    if (picked) {
      border = "border-brand border-2";
    }
    const readOnly = props.readOnly;
    return (
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={"relative bg-white rounded-lg border-white border-2 cursor-pointer"}
        style={{ width: "109px", height: "263px" }}
      >
        {isHover && !readOnly && (
          <div
            className={`absolute z-10 top-0 w-16 h-16 right-0`}
            onClick={() => {
              props.removeCard && props.removeCard(props.position);
              props.changePosition(props.position);
              setIsHover(false);
            }}
          >
            <div className={"pt-2 pr-2 flex flew-row justify-end self-start "}>
              <div className="w-[50%] h-auto cursor-pointer" title={"Remove card from lineup"}>
                <img className={"w-full h-full"} src={RemoveCardIcon} />
              </div>
            </div>
          </div>
        )}
        <div onClick={() => props.changePosition(props.position)} className={"w-full h-full space-y-1.5 p-1.5 rounded-lg " + border}>
          <div className={""}>
            <img src={card.PictureUrl} />
          </div>
          <div className={"flex flex-row justify-center"}>
            <div className={"flex flex-row justify-center py-0.5 rounded-full px-1.5 bg-grey-f2 space-x-1"}>
              <div className={"w-4 self-center"}>
                <PlayerAvailability availability={entry.playerStatus} />
              </div>
              <p className={"text-xs font-semibold text-black-4D"}>{(card.Power * 100 - 100).toFixed(1)}%</p>
            </div>
          </div>
          <div className={"flex flex-row justify-center"}>
            <div className={"self-center"}>
              <NBAProjectedScore font={"text-xs"} bold={"font-bold"} size={"w-6 h-6"} fixed={0} score={entry.totalProjected} />
            </div>
          </div>
          <div className={"flex flex-row justify-center"}>
            <p className={"text-xs font-semibold text-black-4D"}>{entry.nbGames} games</p>
          </div>
          <div className={"flex flex-row justify-center space-x-1"}>
            {opps.map((opp, index) => {
              return (
                <div className={"w-4 h-4"} key={index}>
                  <img className={"w-full h-full object-contain"} src={opp} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else return null;
}

function BaseballLineupBuilderLineup(props) {
  const divId = props.divId;
  const division = props.division;
  const divisionStats = props.divisionStats;
  const lineup = props.lineup;
  const concept = props.concept;
  const suggestion = props.suggestion;
  const [openModal, setOpenModal] = useState(false);
  let header;
  if (divId?.includes("unique")) {
    header = UniqueHeader;
  } else if (divId?.includes("weekly")) {
    header = MixteHeader;
  } else if (divId?.includes("super-rare")) {
    header = SuperRareHeader;
  } else if (divId?.includes("rare")) {
    header = RareHeader;
  } else if (divId?.includes("limited")) {
    header = LimitedHeader;
  } else {
    header = MixteHeader;
  }

  let projectedScore = 0;
  if (lineup) {
    lineup.map((entry) => {
      if (entry !== null) {
        projectedScore += entry.totalProjected * entry.card.Power;
      }
    });
  }

  let projectedScarcity = "";
  let projectedTier = 0;

  divisionStats?.stats?.map((entry) => {
    if (projectedScore >= entry.startRange) {
      if (projectedScarcity === "") {
        projectedScarcity = entry.scarcity;
        projectedTier = entry.tier;
      } else if (projectedScarcity === entry.scarcity && projectedTier > entry.tier) {
        projectedTier = entry.tier;
      } else if (projectedScarcity !== entry.scarcity) {
        projectedScarcity = entry.scarcity;
        projectedTier = entry.tier;
      }
    }
  });

  const cap = division?.cap;
  let bg = "bg-white text-brand-black";
  let greyText = "text-textGrey3";
  if (concept) {
    bg = "bg-brand-black text-white";
    greyText = "text-white";
  }

  const atLeastOnePlayerPicked = lineup.filter((entry) => entry !== null).length > 0;
  const lineupFinished = lineup.filter((entry) => entry === null).length === 0;

  const pickedPosition = props.pickedPosition;
  let bgUrl = LineupBuilderBg;
  if (suggestion) {
    bgUrl = LineupBuilderBgBlack;
  }

  return (
    <>
      <div className={bg + " rounded-lg 1.5xl:w-full w-full"}>
        <div
          className={"py-2 rounded-t-lg flex flex-row justify-between"}
          style={{
            backgroundImage: "url('" + header + "')",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className={"self-center flex flex-row space-x-2"}>
            <p className={"text-base font-semibold text-white pl-4"}>{props.divName}</p>
            {concept && (
              <div className={"px-2 py-0.5 bg-white rounded-full text-brand-black text-xxs self-center font-semibold"}>
                <p>Concept</p>
              </div>
            )}
          </div>
          {!props.editOnly && !concept && (
            <div className={"flex flex-row space-x-3 self-center pr-4"}>
              <div className={"w-5 flex self-center cursor-pointer"} onClick={() => props.saveLineup()}>
                <img className={"w-full h-full object-contain"} src={SaveIcon} />
              </div>
              <div className={"w-4 flex self-center cursor-pointer"} onClick={() => props.deleteLineup()}>
                <img className={"w-full h-full self-center"} src={DeleteIcon} />
              </div>
            </div>
          )}
        </div>
        <div
          className={"flex flex-col space-y-4 rounded-b-lg"}
          style={{
            backgroundImage: "url('" + bgUrl + "')",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {!props.readOnly && (
            <div className={"flex flex-row justify-center pt-4"}>
              {" "}
              {!lineupFinished && !concept && (
                <button
                  type="button"
                  onClick={() => {
                    props.getSuggestion();
                    props.setSuggestion(true);
                  }}
                  className={`inline-flex items-center px-6 py-2 border border-brand text-med font-semibold 
                    rounded-lg shadow-sm text-white focus:outline-none focus:ring-0 bg-brand hover:border-brand-light hover:opacity-50 cursor-pointer`}
                >
                  <span className={"inline flex flex-row gap-2"}>
                    <Bulb className={"w-4 h-4 my-auto fill-white"} />
                    <span className={"my-auto "}>{atLeastOnePlayerPicked ? "Complete the lineup" : "Suggest a lineup"}</span>
                    <span
                      className={`my-auto text-xxs font-bold rounded-full flex justify-center uppercase px-2 py-0.5 text-white bg-brand-black`}
                    >
                      Beta
                    </span>
                  </span>
                </button>
              )}
            </div>
          )}
          <div className={"flex flex-row space-x-2 justify-center pt-4"}>
            <BaseballLineupBuilderLineupCard
              card={lineup[0]}
              position={"Starting Pitcher"}
              nextPosition={"Relief Pitcher"}
              removeCard={props.removeCard}
              readOnly={props.readOnly}
              changePosition={props.changePosition}
              pickedPosition={pickedPosition}
            />
            <BaseballLineupBuilderLineupCard
              card={lineup[1]}
              position={"Relief Pitcher"}
              nextPosition={"Flex"}
              readOnly={props.readOnly}
              removeCard={props.removeCard}
              changePosition={props.changePosition}
              pickedPosition={pickedPosition}
            />
            <div className={"h-32 w-0.5 rounded border border-white self-center"}></div>
            <BaseballLineupBuilderLineupCard
              card={lineup[2]}
              position={"Flex"}
              nextPosition={"Corner Infielder"}
              removeCard={props.removeCard}
              readOnly={props.readOnly}
              changePosition={props.changePosition}
              pickedPosition={pickedPosition}
            />
          </div>
          <div className={"flex flex-row space-x-2 justify-center"}>
            <BaseballLineupBuilderLineupCard
              card={lineup[3]}
              position={"Corner Infielder"}
              nextPosition={"Middle Infielder"}
              removeCard={props.removeCard}
              readOnly={props.readOnly}
              changePosition={props.changePosition}
              pickedPosition={pickedPosition}
            />
            <BaseballLineupBuilderLineupCard
              card={lineup[4]}
              position={"Middle Infielder"}
              nextPosition={"Outfielder"}
              readOnly={props.readOnly}
              removeCard={props.removeCard}
              changePosition={props.changePosition}
              pickedPosition={pickedPosition}
            />
            <BaseballLineupBuilderLineupCard
              card={lineup[5]}
              position={"Outfielder"}
              nextPosition={"Extra Hitter"}
              readOnly={props.readOnly}
              removeCard={props.removeCard}
              changePosition={props.changePosition}
              pickedPosition={pickedPosition}
            />
            <div className={"h-32 w-0.5 rounded border border-white self-center"}></div>
            <BaseballLineupBuilderLineupCard
              card={lineup[6]}
              position={"Extra Hitter"}
              removeCard={props.removeCard}
              readOnly={props.readOnly}
              changePosition={props.changePosition}
              pickedPosition={pickedPosition}
            />
          </div>
          <div className={"flex flex-row justify-between bg-[#000000] bg-opacity-50 px-6 py-4 rounded-b-lg"}>
            <div className={"flex flex-row space-x-2"}>
              <p className={"text-sm text-white font-medium"}>Projected score:</p>
              <p className={"text-sm font-semibold text-white"}>{projectedScore.toFixed(2)} pts</p>
            </div>
            <div className={"flex flex-row space-x-2 text-right"}>
              <p className={"text-sm font-medium text-white"}>Projected reward:</p>
              {projectedTier > 0 && (
                <div className={"flex flex-row space-x-2 justify-end"}>
                  <p className={"text-sm text-white font-semibold"}>TIER {projectedTier} </p>
                  <div className={"w-3"}>
                    <NewCardIcon scarcity={projectedScarcity.toLowerCase()} />
                  </div>
                </div>
              )}
              {projectedTier === 0 && <p className={"text-sm text-white font-semibold"}>NA</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withUser(BaseballLineupBuilderLineup);
