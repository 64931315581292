import React, { useEffect, useState } from "react";
import LineupBuilderBanner from "../../img/LineupBuilderBanner.png";
import { Button } from "../util/button";
import ManagerSearchBar from "../manager/managerSearchBar";
import Modal from "../util/modal";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import PrivateOfferBox from "./privateOfferBox";
import { findPriceForUserUnit, formatPrice } from "../util/formatMoney";
import { useLocation } from "react-router-dom";
import ConceptPicker from "../../img/concept-picker.svg";
import CardLayout from "../cards/cardLayout";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { getValuationOrigin } from "../util/valuationOrigin";
import ManagerCardsView from "../manager/managerCardsView";
import Remove from "../../img/remove-card-lineup.svg";
import { ReactTooltip } from "../util/tooltip.js";
import TradeListView from "./tradeListView";
import ListViewPicker from "../util/listViewPicker";
import PlayerCards from "../players/playerCards";
import EnabledWallets from "../util/enabledWallets";

function TradeBuilderSide(props) {
  const [sortedCards, setSortedCards] = useState(props.cards);
  const [sortHow, setSortHow] = useState(-1);
  const [sortWhat, setSortWhat] = useState("l15");

  useEffect(() => {
    setSortedCards(props.cards);
    if (props.cards !== null && props.cards.length > 0) {
      sortData(sortWhat, props.cards, true);
    }
  }, [props.cards]);

  const cards = props.cards;
  const totalValue = props.value;
  const unit = props.unit;
  const list = props.listView;
  const teamMap = props.teamMap;

  let width = "220px";
  let height = "335px";

  const [showModal, setShowModal] = useState(false);

  const sortData = (w, c, nc) => {
    let newCards = [...cards];
    if (c) newCards = [...c];
    let how = sortHow;
    if (!nc) {
      how = how * -1;
      setSortHow(how);
    }
    setSortWhat(w);
    let key = "";
    if (w === "l5") {
      key = "avg5";
    } else if (w === "l15") {
      key = "avg20";
    } else if (w === "bonus") {
      key = "Power";
      newCards.sort(function (a, b) {
        if (a.card[key] < b.card[key]) {
          return -1 * how;
        } else if (a.card[key] > b.card[key]) {
          return how;
        }
        return 0;
      });
      setSortedCards(newCards);
      return;
    } else if (w === "floor") {
      key = "Price";
      newCards.sort(function (a, b) {
        if (a.bmp[key] < b.bmp[key]) {
          return -1 * how;
        } else if (a.bmp[key] > b.bmp[key]) {
          return how;
        }
        return 0;
      });
      setSortedCards(newCards);
      return;
    } else if (w === "rt") {
      key = "Average";
      newCards.sort(function (a, b) {
        if (a.rt[key] < b.rt[key]) {
          return -1 * how;
        } else if (a.rt[key] > b.rt[key]) {
          return how;
        }
        return 0;
      });
      setSortedCards(newCards);
      return;
    }
    newCards.sort(function (a, b) {
      if (a[key] < b[key]) {
        return -1 * how;
      } else if (a[key] > b[key]) {
        return how;
      }
      return 0;
    });
    setSortedCards(newCards);
  };

  return (
    <div className={"w-full"}>
      {showModal && (
        <Modal
          noClose={true}
          maxSize={"max-w-full"}
          size={"w-full xl:w-11/12 4xl:w-10/12"}
          open={showModal}
          content={
            <div className={"w-full max-w-full "}>
              <ManagerCardsView
                cards={props.cards !== null ? props.cards.map((c) => c.card.TokenId) : []}
                close={() => setShowModal(false)}
                confirm={(sc) => props.buildOffer(sc)}
                manager={props.manager}
                unit={unit}
              />
            </div>
          }
          close={() => setShowModal(false)}
          setOpen={() => setShowModal(true)}
        />
      )}
      <div className={"bg-white rounded-lg p-4 space-y-4 w-full"}>
        {totalValue && totalValue["value_eth"] && (
          <div className={"grid grid-cols-3 gap-4"}>
            <PrivateOfferBox
              label={"Cash sent"}
              value={formatPrice(totalValue["value_" + unit].eth, unit)}
              secondaryValue={
                unit === "eth" ? formatPrice(totalValue["value_eur"].eth, "eur") : formatPrice(totalValue["value_eth"].eth, "eth")
              }
            />
            <PrivateOfferBox
              label={"Cash + floor prices"}
              value={formatPrice(totalValue["value_" + unit].floor, unit)}
              secondaryValue={
                unit === "eth" ? formatPrice(totalValue["value_eur"].floor, "eur") : formatPrice(totalValue["value_eth"].floor, "eth")
              }
              warning={totalValue.incomplete}
              tip={"Be careful, some cards do not currently have floor prices."}
            />
            <PrivateOfferBox
              brand={true}
              label={"Cash + last averages"}
              value={formatPrice(totalValue["value_" + unit].cards, unit)}
              secondaryValue={
                unit === "eth" ? formatPrice(totalValue["value_eur"].cards, "eur") : formatPrice(totalValue["value_eth"].cards, "eth")
              }
              warning={totalValue.average_incomplete}
              tip={"Be careful, some cards do not have valuations as they have not been sold publicly."}
            />
          </div>
        )}
        <div className={"flex flex-row justify-start"}>
          {!list && (
            <div className={"grid grid-cols-2 2xl:grid-cols-3 3.2xl:grid-cols-4 gap-4"}>
              {sortedCards &&
                sortedCards.map((cp) => {
                  const lastPrice = cp.card.LastPrice || cp.card_last_move?.details.price;
                  let lastStatus = cp.card.LastStatus;
                  let displayStatus = lastStatus;
                  let ethCurrentPrice = "";
                  if (lastStatus === "Sold" || lastStatus === "") {
                    displayStatus = findPriceForUserUnit(lastPrice, cp.card_last_move?.details, unit);
                    ethCurrentPrice = formatPrice(lastPrice, "eth");
                  }
                  return (
                    <div className={"relative flex flex-col"}>
                      <div
                        className="absolute flex z-10 left-0 -mt-4 -ml-1 top-1 cursor-pointer transform w-6 origin-top-left"
                        onClick={() => {
                          props.remove(cp.card.TokenId);
                        }}
                      >
                        <img className={"self-end"} src={Remove} />
                      </div>

                      <div key={cp.card.Slug}>
                        <CardLayout
                          card={cp.card}
                          lastPrice={displayStatus}
                          border={" border border-grey-e5"}
                          imageLink={"/card/" + cp.card.TokenId}
                          picture={cp.card.PictureUrl}
                          monthPrice={formatRealTimeAverage(cp.rt, unit)}
                          marketPrice={formatBestMarketPrice(cp.bmp, unit)}
                          valuationOrigin={
                            unit === "eth" ? getValuationOrigin(cp.rt.ValuationOrigin) : getValuationOrigin(cp.rt.ValuationOrigin)
                          }
                          ethCurrentPrice={ethCurrentPrice}
                          currentPrice={displayStatus}
                          date={false}
                          countdown={false}
                          avgPrice={""}
                          player={cp.player}
                          gwNumber={props.gwNumber}
                          l5={cp.avg5}
                          l20={cp.avg20}
                          gms5={cp.gms5}
                          gms20={cp.gms20}
                          s5={cp.s5Avg}
                          s15={cp.s15Avg}
                          s5Apps={cp.s5Apps}
                          s15Apps={cp.s15Apps}
                          availability={cp.status}
                          gwGames={props.games}
                        />
                      </div>
                    </div>
                  );
                })}
              <div
                style={{ width, height }}
                className={
                  "space-y-4 cursor-pointer bg-grey-e0 text-textGrey3 hover:text-brand hover:bg-brand-pastel hover:border hover:border-brand " +
                  "flex flex-col items-center justify-center align-middle mx-auto rounded-lg"
                }
                onClick={() => setShowModal(true)}
              >
                <img src={ConceptPicker} />
                <p className={"self-center text-center text-sm font-semibold"}>Add a card</p>
              </div>
            </div>
          )}
          {list && (
            <div>
              <table
                className={"z-0 min-h-48 border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"}
              >
                <thead>
                  <th className="rounded-tl-lg text-white bg-brand-black border-b border-gray-200 mx-auto py-3 pl-2 text-left w-3/12 font-bold uppercase text-xs">
                    Card
                  </th>
                  <th className="text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/24 font-bold uppercase text-xs"></th>
                  <th
                    onClick={() => sortData("l5")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-1/12 font-bold uppercase text-xs"
                  >
                    L5
                  </th>
                  <th
                    onClick={() => sortData("l15")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                  >
                    L15
                  </th>
                  <th
                    onClick={() => sortData("floor")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1.5/12 font-bold uppercase text-xs"
                  >
                    Floor
                  </th>
                  <th
                    onClick={() => sortData("rt")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1.5/12 font-bold uppercase text-xs"
                  >
                    Average
                  </th>
                  <th
                    onClick={() => sortData("bonus")}
                    className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-1/12 font-bold uppercase text-xs"
                  >
                    Bonus
                  </th>
                  <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-center w-2/12 font-bold uppercase text-xs">
                    Next game
                  </th>
                  <th className="text-center rounded-tr-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"></th>
                </thead>
                <tbody>
                  {sortedCards &&
                    sortedCards.map((cp) => {
                      return <TradeListView showRemove={true} remove={(c) => props.remove(c)} info={cp} teamMap={teamMap} unit={unit} />;
                    })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className={"flex flex-row justify-center self-center pt-4 pb-2"}>
                        <div className="w-4 h-4 self-center cursor-pointer" onClick={() => setShowModal(true)}>
                          <img className={"w-full h-full object-contain"} src={ConceptPicker} />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function TradeBuilder(props) {
  const [showSearch, setShowSearch] = useState(false);
  const [showCardSearch, setShowCardSearch] = useState(false);
  const [player, setPlayer] = useState(undefined);
  const [sender, setSender] = useState(undefined);
  const [receiver, setReceiver] = useState(undefined);
  const [senderValue, setSenderValue] = useState({ eth: 0, floor: 0, avg: 0 });
  const [receiverValue, setReceiverValue] = useState({
    eth: 0,
    floor: 0,
    avg: 0,
  });
  const [senderCards, setSenderCards] = useState([]);
  const [receiverCards, setReceiverCards] = useState([]);
  const [listView, setListView] = useState(false);
  const [teamMap, setTeamMap] = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const receiverInUrl = query.get("receiver");
  const offerInUrl = query.get("offerId");

  useEffect(() => {
    if (receiverInUrl && receiverInUrl !== "") {
      getManager(receiverInUrl);
    }
  }, [receiverInUrl]);

  useEffect(() => {
    if (offerInUrl && offerInUrl !== "") {
      getOffer(offerInUrl);
    }
  }, [offerInUrl]);

  const getOffer = (o, t) => {
    props
      .fetch("/apiv2/offers/buildOffer", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offerId: o,
          tokenId: t,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setSender(res.manager_sending);
        setReceiver(res.manager_receiving);
        setSenderCards(res.players_sent);
        setReceiverCards(res.players_received);
        setSenderValue(res.sent_value);
        setReceiverValue(res.received_value);
        setTeamMap(res.team_map);
        if (res.players_sent.length > 3 || res.players_received.length > 3) {
          setListView(true);
        }
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  const getManager = (m) => {
    props
      .fetch("/apiv2/user/usersForTrade?user=" + m)
      .then((response) => response.json())
      .then((res) => {
        setSender(res.sender);
        setReceiver(res.receiver);
        setSenderCards([]);
        setReceiverCards([]);
        setSenderValue({});
        setReceiverValue({});
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  const getPlayer = (p) => {
    props
      .fetch("/apiv2/players/basicInfo?playerId=" + p)
      .then((response) => response.json())
      .then((res) => {
        setPlayer(res);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (player && player.PlayerId !== "") setShowCardSearch(true);
  }, [player]);

  const remove = (c) => {
    let sent = senderCards;
    let rec = receiverCards;
    if (sent !== null) {
      sent = sent.filter((cd) => cd.card.TokenId !== c);
    }
    if (rec !== null) {
      rec = rec.filter((cd) => cd.card.TokenId !== c);
    }

    buildOffer(sent, rec);
  };

  const buildOffer = (sc, rc) => {
    let sent = sc || senderCards;
    let rec = rc || receiverCards;
    let sentCards = [];
    let receivedCards = [];
    if (sent !== null) {
      sent.map((s) => {
        if (s.card) {
          sentCards.push(s.card.TokenId);
        } else {
          sentCards.push(s);
        }
      });
    }
    if (rec !== null) {
      rec.map((r) => {
        if (r.card) {
          receivedCards.push(r.card.TokenId);
        } else {
          receivedCards.push(r);
        }
      });
    }

    props
      .fetch("/apiv2/offers/buildOffer", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender: sender,
          receiver: receiver,
          sent_cards: sentCards,
          received_cards: receivedCards,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setSender(res.manager_sending);
        setReceiver(res.manager_receiving);
        setSenderCards(res.players_sent);
        setReceiverCards(res.players_received);
        setSenderValue(res.sent_value);
        setReceiverValue(res.received_value);
        setTeamMap(res.team_map);
        if (
          (res.players_sent !== null && res.players_sent.length > 3) ||
          (res.players_received !== null && res.players_received.length > 3)
        ) {
          setListView(true);
        }
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  return (
    <div>
      {showSearch && (
        <Modal
          size={"sm:w-full h-1/2"}
          content={
            <div style={{ zIndex: 1000 }}>
              <ManagerSearchBar
                choosePlayer={(m) => {
                  setShowSearch(false);
                  getPlayer(m);
                }}
                chooseManager={(m) => {
                  setShowSearch(false);
                  getManager(m);
                }}
              />
            </div>
          }
          close={() => setShowSearch(false)}
          open={showSearch}
          setOpen={() => setShowSearch(true)}
        />
      )}
      {showCardSearch && (
        <Modal
          noClose={true}
          maxSize={"max-w-full"}
          size={"w-full xl:w-11/12 4xl:w-10/12"}
          content={
            <div style={{ zIndex: 1000 }}>
              <PlayerCards
                infiniteScrollHeight={"70vh"}
                player={player}
                onClick={(id) => {
                  setShowCardSearch(false);
                  getOffer(undefined, id);
                }}
                hover={"hover:border-2 hover:border-brand border-2 border-background-grey cursor-pointer rounded-lg"}
              />
            </div>
          }
          close={() => setShowCardSearch(false)}
          open={showCardSearch}
          setOpen={() => setShowCardSearch(true)}
        />
      )}
      <div className={"space-y-8"}>
        <div className={"space-y-2"}>
          <div>
            <div
              className={"w-full lg:h-48 h-32 relative opacity-100"}
              style={{
                backgroundImage: "url('" + LineupBuilderBanner + "')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className={"z-40 self-center flex mx-16 lg:h-48 h-32 align-middle flex-row justify-between"}>
                <p className={"self-center text-5xl font-headers font-semibold text-left text-white z-10"}>Trade builder</p>
                <div className={"self-center"}>
                  <Button label={"Create a trade"} onClick={() => setShowSearch(true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {receiver && (
          <div className={"mx-8 xl:w-11/12 xl:mx-auto 2xl:mx-16 3xl:mx-16 4xl:w-10/12 4xl:mx-auto mt-4 space-y-4"}>
            <div>
              <div className={"flex flex-row space-x-4"}>
                <div className={"flex flex-row w-5/12 self-center space-x-4"}>
                  <div className={"w-16 h-16 bg-white rounded-lg"}>
                    <img className={"w-full h-full object-contain"} src={sender.PictureUrl} />
                  </div>
                  <div className={"self-center"}>
                    <p className={"text-2xl font-headers text-brand font-semibold hover:font-bold"}>
                      <a href={"/manager/" + sender.Slug}>{sender.Nickname}</a>
                    </p>
                    <EnabledWallets enabledWallets={sender.EnabledWallets} />
                  </div>
                </div>
                <div className={"w-2/12 flex flex-row justify-center"}>
                  <ListViewPicker mode={listView} change={(c) => setListView(c)} />
                </div>
                <div className={"flex flex-row w-5/12 justify-end self-center space-x-4"}>
                  <div className={"self-center"}>
                    <p className={"text-2xl font-headers text-brand font-semibold hover:font-bold"}>
                      <a href={"/manager/" + receiver.Slug}>{receiver.Nickname}</a>
                    </p>
                    <div className={"flex justify-end"}>
                      <EnabledWallets enabledWallets={receiver.EnabledWallets} />
                    </div>
                  </div>
                  <div className={"w-16 h-16 bg-white rounded-lg"}>
                    <img className={"w-full h-full object-contain "} src={receiver.PictureUrl} />
                  </div>
                </div>
              </div>
            </div>
            <div className={"flex flex-row space-x-8"}>
              <div className={"w-6/12"}>
                <TradeBuilderSide
                  listView={listView}
                  manager={sender}
                  unit={props.user.preferredUnit}
                  value={senderValue}
                  cards={senderCards}
                  buildOffer={(sc) => buildOffer(sc)}
                  teamMap={teamMap}
                  remove={(c) => remove(c)}
                />
              </div>
              <div className={"w-6/12"}>
                <TradeBuilderSide
                  listView={listView}
                  manager={receiver}
                  unit={props.user.preferredUnit}
                  value={receiverValue}
                  remove={(c) => remove(c)}
                  teamMap={teamMap}
                  cards={receiverCards}
                  buildOffer={(rc) => buildOffer(undefined, rc)}
                />
              </div>
            </div>
          </div>
        )}
        {!receiver && (
          <div>
            <p className={"text-base font-semibold text-center"}>Click on create a trade to get started!</p>
          </div>
        )}
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(TradeBuilder);
