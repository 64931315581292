import React, { useState } from "react";
import GameOnCard from "../games/gameOnCard";
import PlayerAvailability from "../players/playerAvailability";
import CardPlayingStatus from "./cardPlayingStatus";
import CardLocker from "./cardLocker";
import Countdown from "react-countdown-now";
import { withUser } from "../../userContext";
import ScoreAverage from "../decision/scoreAverage";
import PlayerPriceGraphModal from "../players/playerPriceGraphModal";
import OngoingPlayerCardsSales from "../players/ongoingPlayerCardsSold";
import Limited2022 from "../../img/cards-back/Yellow BG2022.svg";
import Rare2022 from "../../img/cards-back/Red bg2022.svg";
import SuperRare2022 from "../../img/cards-back/Blue Bg2022.svg";
import Unique2022 from "../../img/cards-back/Black bg2022.svg";
import CustomSeriesBg from "../../img/custom series bg.svg";
import { formatPriceSize } from "../util/formatPriceSide";
import CirclePct from "../util/circlePct";
import { isFree, t1OrAbove, t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import Locker from "../../img/locker-squared-bg.svg";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { PickScore } from "../util/pickScore";
import SorareScoreAverage from "../decision/sorareScoreAverage";
import GamesPlayed from "../decision/gamesPlayed";
import NBAProjectedScore from "../util/nbaProjectionScore";
import NBAProjDiff from "../util/NBAProjDiff";
import GreyLock from "../util/greyLock";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import MarketStatusIcon from "../../img/market-status-icon.svg";
import MarketStatusPopover from "./marketStatusPopover";
import { errorCatcher } from "../util/errors";
import CardTag from "./cardTag";
import CardInUse from "./cardInUse";
import NewCardIcon from "../util/newCardIcon";
import LineSep from "../../img/line-sep.svg";
import { PlayingStatus } from "../util/playingStatus";
import { abbrv_football_positions } from "../util/positions";
import { detailedPositionAbreviations, detailedPositionFullName } from "../util/detailedFootballPositions";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import CheckBox from "../../img/check-box-grey.svg";
import CheckBoxBrand from "../../img/check-box-brand.svg";
import { checkSecondUnitShouldBeDisplayed, formatPrice } from "../util/formatMoney";
import NewCardLink from "./newCardLink";
import clsx from "clsx";
import { getScarcityInfo } from "../util/scarcities";
import { getValuationOriginShortOnCard } from "../util/valuationOrigin";
import { split_price_classic, split_price_in_season } from "../util/splits";

const PreferentialPositions = (props) => {
  const { cardPosition, detailedPositions } = props;
  const cardPositionAbreviation = abbrv_football_positions[cardPosition];
  const elems = [cardPositionAbreviation || "UKN"];
  let positionsAndCountMsg = "Player is considered as " + cardPosition;
  if (detailedPositions !== undefined && detailedPositions !== null && detailedPositions.length > 0) {
    const mainDetailedPosition = detailedPositions?.[0];
    const mainDetailedPositionAbbrv = mainDetailedPosition ? detailedPositionAbreviations[mainDetailedPosition] : undefined;
    const mainDetailedPositionFullName = mainDetailedPosition ? detailedPositionFullName[mainDetailedPosition] : undefined;
    if (mainDetailedPositionAbbrv !== undefined && mainDetailedPositionAbbrv !== cardPositionAbreviation) {
      elems.push(mainDetailedPositionAbbrv);
    }
    if (mainDetailedPositionFullName !== undefined) {
      positionsAndCountMsg += "; plays mainly as " + mainDetailedPositionFullName;
    }
  }
  return (
    <span className={"text-xs font-medium text-textGrey3"} title={positionsAndCountMsg}>
      {elems.filter((p) => p !== undefined && p !== "").join(" · ")}
    </span>
  );
};

function CardLayout(props) {
  const [openPriceGraph, setOpenPriceGraph] = useState(false);
  const [openOngoingSales, setOpenOngoingSales] = useState(false);
  const [showMarketStatus, setShowMarketStatus] = useState(false);
  const [tag, setTag] = useState(props.tag);

  const Completionist = () => {
    if (props.date.getUTCSeconds() === 0) {
      return <span>{"Ended"}</span>;
    } else return <span>{"Ended"}</span>;
  };

  let c = true;
  if (props.noClick) {
    c = false;
  }

  let layout = props.user.preferredLayout || "white";
  if (props.layout) {
    layout = props.layout;
  }

  const card = props.card;
  const player = props.player;
  const split = props.split || props.bmp?.Split;
  const isInSeason = split === split_price_in_season;
  const isClassic = split === split_price_classic;
  let bg;
  if (card.Scarcity === "LIMITED") {
    bg = Limited2022;
  } else if (card.Scarcity === "RARE") {
    bg = Rare2022;
  } else if (card.Scarcity === "SUPER RARE") {
    bg = SuperRare2022;
  } else if (card.Scarcity === "UNIQUE") {
    bg = Unique2022;
  } else if (card.Scarcity === "CUSTOM SERIES") {
    bg = CustomSeriesBg;
  }

  let so5Utility = props.so5Utility;
  const so5Display = props.so5Display;
  let so5Value = so5Utility?.so_5_utility;
  let so5Picked = so5Utility?.so_5_picked;
  let so5Eligible = so5Utility?.so_5_eligible;
  if (props.so5Utility) {
    if (so5Display === "played") {
      so5Value = so5Utility.so_5_picked_played_ratio;
      so5Picked = so5Utility.so_5_picked_played;
      so5Eligible = so5Utility.so_5_played;
    } else if (so5Display === "started") {
      so5Value = so5Utility.so_5_picked_started_ratio;
      so5Picked = so5Utility.so_5_picked_started;
      so5Eligible = so5Utility.so_5_started;
    } else if (so5Display === "next_game") {
      so5Utility = undefined;
    }
  }

  const sport = props.player.Sport;
  let pickScore = "NG";
  if (props.gwGames !== undefined && props.gwGames !== null) {
    if (props.gwGames[props.player.TeamId]?.pick_scores !== undefined && props.gwGames[props.player.TeamId]?.pick_scores !== null) {
      pickScore = props.gwGames[props.player.TeamId]?.pick_scores[props.player.PlayerId]?.pickScore;
    } else if (
      props.gwGames[props.player.NationalTeam]?.pick_scores !== undefined &&
      props.gwGames[props.player.NationalTeam]?.pick_scores !== null
    ) {
      pickScore = props.gwGames[props.player.NationalTeam]?.pick_scores[props.player.PlayerId]?.pickScore;
    } else if (props.gwGames[props.player.TeamId] !== undefined) {
      pickScore = "";
    }
  }

  let nbOfGames = 0;
  let logos = [];
  let projectedScore = 0;
  let bestOpponent = "";
  let mins = 0;
  if ((sport === sorareBasketball || sport === sorareBaseball) && props.gwGames !== null && props.gwGames !== undefined) {
    nbOfGames = props.gwGames[props.player.TeamId]?.nb_of_games;
    logos = props.gwGames[props.player.TeamId]?.opponent_logos;
    if (props.gwGames[props.player.TeamId]?.projected_scores !== null) {
      props.gwGames[props.player.TeamId]?.projected_scores.map((pit) => {
        if (pit.playerId === props.player.PlayerId) {
          projectedScore = pit.score;
          bestOpponent = pit.opponent;
          mins = pit.minutes;
        }
      });
    }
  }

  const createTag = (tag, price, currency, open) => {
    price = price.replace("€", "").replace("$", "").replace("£", "").replace("Ξ", "");
    price = price.replace(",", ".");
    price = parseFloat(price);
    if (open && tag === "NOT_FOR_SALE") {
      tag = "";
    }
    if (price >= 0 && tag === "") {
      tag = "FIXED";
    }
    if ((price === 0 || isNaN(price)) && tag === "FIXED") {
      tag = "";
      price = 0;
    }
    if (tag !== "FIXED") {
      price = 0;
    }
    if (!open) {
      tag = "NOT_FOR_SALE";
      price = 0;
      currency = "";
    }
    props
      .fetch("/apiv2/cards/createTag", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tokenId: card.TokenId,
          tag: tag,
          price: price,
          currency: currency,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (!res.error) {
          setTag(res.tag);
          if (props.setReload) {
            console.log("setting reload");
            props.setReload(true);
          }
        }
        if (props.changeTag) {
          props.changeTag(res.tag);
        }
      })
      .catch(errorCatcher());
  };

  if (props.display === "list") {
    let color = card.Scarcity.toLowerCase().replace(" ", "-");
    let lastPrice = card.LastPrice;
    let lastStatus = card.LastStatus;
    let displayStatus = lastStatus;
    const selected = props.selected.includes(card.TokenId);
    if (lastStatus === "Sold") {
      displayStatus = formatPrice(lastPrice, "eth");
    }
    if (props.lastMove) {
      if (props.lastMove.details.price > 0) {
        displayStatus = formatPrice(props.lastMove.details.price, "eth");
        if (props.user.preferredUnit !== "eth") {
          displayStatus = formatPrice(props.lastMove.details[props.user.preferredUnit], props.user.preferredUnit);
        }
      } else {
        displayStatus = props.lastMove.details.transferType;
      }
    }
    return (
      <tr className={"bg-white border-b border-grey-e5"}>
        <td>
          {props.setStatus && (
            <div className={"self-center flex flex-col justify-center px-4"}>
              {!selected && (
                <div className={"self-center cursor-pointer"} onClick={() => props.select(card.TokenId)}>
                  <img src={CheckBox} />
                </div>
              )}
              {selected && (
                <div className={"self-center cursor-pointer"} onClick={() => props.select(card.TokenId)}>
                  <img src={CheckBoxBrand} />
                </div>
              )}
            </div>
          )}
          {!props.setStatus && <div className={"w-4"}></div>}
        </td>
        <td>
          <div className={"flex flex-row space-x-4 py-2"}>
            <div className={"w-14 h-14 rounded-full"}>
              <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
                <img src={card.AvatarUrl} className={"w-full h-full object-cover rounded-full"} />
              </a>
            </div>
            <div className={"flex flex-col space-y-1 self-center"}>
              <div className={"flex flex-row space-x-1"}>
                <p className={"text-sm font-semibold"}>
                  <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                    {player.DisplayName}
                  </a>
                </p>
                <div className={"w-4 h-4 self-center"}>
                  <img className={"object-contain w-full h-full"} src={props.teamPicture} />
                </div>
                <div className={"self-center"}>
                  <PlayerAvailability
                    availability={props.availability}
                    size={"w-4 h-4"}
                    player={props.player}
                    intl={props.player.InternationalTrip}
                    position="center"
                    nextGwPlayers={props.gwGames}
                  />
                </div>
                <div className={"self-center"}>
                  <p className={"text-xs font-bold"}>{((props.card.Power - 1) * 100).toFixed(1)}%</p>
                </div>
              </div>
              <div className={"flex flex-row space-x-1.5"}>
                <div className={"w-3 self-center"}>
                  <NewCardIcon scarcity={card.Scarcity.toLowerCase()} />
                </div>
                {sport === sorareFootball && (
                  <div className={"self-center"}>
                    <PreferentialPositions cardPosition={card.Position} detailedPositions={[player.MainPosition]} />
                  </div>
                )}
                {sport !== sorareFootball && (
                  <div className={"self-center"}>
                    <span className={"text-xs font-medium text-textGrey3"}>{player.Position}</span>
                  </div>
                )}
                <img src={LineSep} />
                <div className={"self-end"}>
                  <p className={"text-xs font-medium text-textGrey3 self-end"}>
                    <PlayingStatus player={player} status={player.PlayingStatus} />
                  </p>
                </div>
                <img src={LineSep} />
                <div className={"self-end text-xs"}>
                  <NewCardLink card={card} />
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className={"flex flex-row self-center justify-center"}>
            {player.Sport === sorareFootball && (
              <div className={"w-12 items-center flex flex-row self-center justify-center"}>
                {t2OrAbove(props.user.tier) && <PickScore label={pickScore} />}
                {t1OrBelow(props.user.tier) && <GreyLock />}
              </div>
            )}
            {sport === sorareBaseball && (
              <div className={"flex flex-col items-center space-y-0.5"}>
                <a href={"/player/" + props.player.PlayerId}>
                  <div className={"w-9 h-9"}>
                    <BaseballScoreCircle size={"130"} precision={0} score={props.l20} />
                  </div>
                </a>
              </div>
            )}
            {sport === sorareBasketball && (
              <div className={"flex flex-col items-center space-y-1"}>
                <div className={"flex justify-center items-center w-8 h-8"}>
                  <SorareScoreAverage avg={props.s5} />
                </div>
                <div className={"w-8"}>
                  <GamesPlayed
                    sport={sorareBasketball}
                    period={48}
                    gms={5}
                    played={props.gms5}
                    showPct={true}
                    label={props.gms5 + "'"}
                    labelStyle={"text-center font-semibold text-textGrey3 text-xxs"}
                  />
                </div>
              </div>
            )}
          </div>
        </td>
        <td>
          {sport === sorareFootball && (
            <ScoreAverage
              font={"text-sm font-semibold"}
              size={"w-8 h-7"}
              playedSize={"w-8"}
              period={5}
              played={props.gms5}
              sport={player.Sport}
              avg={props.l5}
              sAvg={props.s5}
              sApps={props.s5Apps}
              playerId={player.PlayerId}
            />
          )}
          {sport === sorareBaseball && (
            <div className={"flex flex-row space-x-2 w-full self-start justify-center"}>
              <div className={"flex flex-col space-y-1 items-center"}>
                <NBAProjectedScore font={"text-xs"} size={"h-7 w-7"} score={projectedScore} scale={"baseball_gw"} />
              </div>
            </div>
          )}
          {sport === sorareBasketball && (
            <div className={"flex flex-col space-y-1 w-full self-center items-center"}>
              <div className={"flex flex-col space-y-1 justify-center"}>
                <div data-tip={"Best projected score next gameweek"}>
                  <NBAProjectedScore size={"w-6 h-6"} font={"text-xs"} score={projectedScore} />
                </div>
                {bestOpponent !== "" && (
                  <div className={"w-6 h-6"}>
                    <img src={bestOpponent} className={"w-full h-full object-contain"} />
                  </div>
                )}
              </div>
            </div>
          )}
        </td>
        <td>
          {sport === sorareFootball && (
            <ScoreAverage
              font={"text-sm font-semibold"}
              size={"w-8 h-7"}
              playedSize={"w-8"}
              period={15}
              played={props.gms20}
              sport={props.player.Sport}
              sAvg={props.s15}
              sApps={props.s15Apps}
              avg={props.l20}
              playerId={props.player.PlayerId}
            />
          )}
          {sport === sorareBaseball && (
            <div className={"flex flex-row space-x-2 w-full self-start justify-center"}>
              <div className={"flex flex-row justify-center "}>
                <div className={"flex flex-row justify-center self-center space-x-2 rounded bg-grey-f2 px-2 cursor-pointer py-0.5"}>
                  {props.gwGames !== undefined && props.gwGames !== null && props.gwGames[props.player.TeamId]?.opponent_and_games ? (
                    <p className={"text-xs text-center font-semibold"}>{props.gwGames[props.player.TeamId].nb_of_games}</p>
                  ) : (
                    <p className={"text-xs text-center font-semibold"}>{0}</p>
                  )}
                </div>
              </div>
            </div>
          )}
          {sport === sorareBasketball && (
            <div className={"flex flex-col space-y-1.5 w-full self-center items-center pt-1"}>
              <div data-tip={"Projected score difference from L10"}>
                <NBAProjDiff projDiff={projectedScore - props.s5} />
              </div>
              <div className={"w-8"}>
                <GamesPlayed
                  sport={sorareBasketball}
                  period={0}
                  gms={5}
                  tip={"Projected minutes difference"}
                  played={mins - props.gms5}
                  showPct={true}
                  label={mins - props.gms5 + "'"}
                  labelStyle={"text-center font-semibold text-textGrey3 text-xxs"}
                />
              </div>
            </div>
          )}
        </td>
        <td>
          {sport === sorareFootball && (
            <GameOnCard
              player={props.player}
              position={card.Position}
              sport={props.player.Sport}
              tier={props.user.tier}
              gwGames={props.gwGames}
              fontSize={"10px"}
              gwNumber={props.gwNumber}
              gwNumberDisplay={props.gwNumberDisplay}
            />
          )}
          {sport === sorareBaseball && (
            <div className={"flex flex-row space-x-2 w-full self-start justify-center rounded p-1"}>
              {props.gwGames !== undefined &&
                props.gwGames !== null &&
                props.gwGames[props.player.TeamId]?.opponent_and_games &&
                props.gwGames[props.player.TeamId].opponent_and_games.map((game, index) => {
                  return (
                    <div className={"flex flex-col space-y-1 items-center"}>
                      <div className={"w-5 h-5"}>
                        <img className={"w-full h-full object-contain"} src={game.logo} />
                      </div>
                      <p className={"text-xs text-center font-semibold"}>{game.games}x</p>
                    </div>
                  );
                })}
            </div>
          )}
          {sport === sorareBasketball && (
            <div className={"flex flex-row space-x-2 w-full self-start justify-center"}>
              <div className={"flex flex-row justify-center "}>
                <div className={"flex flex-row justify-center self-center space-x-2 rounded bg-grey-f2 px-2 cursor-pointer py-0.5"}>
                  {props.gwGames !== undefined && props.gwGames !== null && props.gwGames[props.player.TeamId]?.opponent_and_games ? (
                    <p className={"text-xs text-center font-semibold"}>{props.gwGames[props.player.TeamId].nb_of_games}</p>
                  ) : (
                    <p className={"text-xs text-center font-semibold"}>{0}</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </td>
        <td>
          <p className={"text-center font-semibold text-sm text-" + color}>
            {props.centerLink ? (
              <a href={props.centerLink} target={"_blank"} rel="noreferrer">
                {displayStatus}
              </a>
            ) : (
              displayStatus
            )}
          </p>
        </td>
        <td>
          <p className={"text-center font-semibold text-sm text-" + color} data-tip={props.valuationOrigin}>
            {formatRealTimeAverage(props.rt, props.user.preferredUnit)}
          </p>
        </td>
        <td>
          <p className={"text-center font-semibold text-sm text-" + color}>{formatBestMarketPrice(props.bmp, props.user.preferredUnit)}</p>
        </td>
        <td>
          <CardTag long={true} tag={tag} bmp={props.bmp} rt={props.rt} onSale={props.onSale} />
        </td>
        <td>
          <div className={"flex flex-row justify-center"}>
            <MarketStatusPopover
              tag={props.tag}
              inline={true}
              card={props.card}
              bmp={props.bmp}
              rt={props.rt}
              offerPrice={props.currentPrice}
              isMe={props.setStatus}
              changeTag={props.changeTag}
              createTag={createTag}
              onSale={props.onSale}
              close={() => setShowMarketStatus(false)}
            />
          </div>
        </td>
        {(sport === sorareFootball || props.all) && (
          <td>
            {props.so5Use && !props.onSale && <CardInUse />}
            {(!props.so5Use || sport !== sorareFootball) && (
              <p className={"text-center self-center font-medium text-sm text-black-4D"}>-</p>
            )}
          </td>
        )}
      </tr>
    );
  } else {
    const primaryUnit = props.user.preferredUnit;
    const secondaryUnit = props.user.secondUnit;
    const shouldSecondaryBeDisplayed = checkSecondUnitShouldBeDisplayed(primaryUnit, secondaryUnit);
    const scarcityInfo = getScarcityInfo(card.Scarcity);
    const scarcityTextColor = scarcityInfo.color;
    return (
      <div className={"flex flex-row"}>
        {openPriceGraph && (
          <PlayerPriceGraphModal
            cardProps={props}
            open={openPriceGraph}
            close={() => setOpenPriceGraph(false)}
            card={props.card}
            player={props.player}
          />
        )}
        {openOngoingSales && (
          <OngoingPlayerCardsSales
            open={openOngoingSales}
            close={() => setOpenOngoingSales(false)}
            card={props.card}
            player={props.player}
          />
        )}
        <div className={props.hover}>
          <div
            className={"relative rounded-t-lg group bg-surface-container-highest flex justify-center p-4 pb-6"}
            style={
              layout === "white"
                ? {}
                : {
                    backgroundImage: "url('" + bg + "')",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }
            }
          >
            {props.setStatus && (
              <div className={"hidden z-30 group-hover:block absolute top-2 right-2"}>
                <img src={MarketStatusIcon} onClick={() => setShowMarketStatus(true)} className={"cursor-pointer"} />
              </div>
            )}
            <a href={props.imageLink} target={"_blank"} rel="noreferrer">
              <img src={props.picture} className={"object-contain hover:scale-105 duration-200 transform"} width={184} height={298} />
            </a>
          </div>

          <div className={clsx("space-y-2 bg-white justify-center flex flex-col rounded-b-lg", props.border)}>
            <div className="relative space-y-2 px-3 pb-2 pt-5">
              {props.tag && (
                <div className="justify-center flex flex-col">
                  <div className={"pt-2 -pb-1"}>
                    <div className={"flex flex-row justify-center space-x-2"}>
                      <CardTag tag={tag} bmp={props.bmp} rt={props.rt} onSale={props.onSale} />
                      {props.so5Use && !props.onSale && <CardInUse />}
                    </div>
                  </div>
                </div>
              )}
              <div className={clsx("flex flex-row justify-between mb-1 px-1 pt-2", props.tag && "pt-2")}>
                <span
                  className={clsx(
                    "absolute -top-4 left-1/2 -translate-x-1/2 rounded-full bg-surface-container px-3 py-2",
                    "font-bold leading-none text-md",
                    isInSeason && scarcityTextColor,
                  )}
                >
                  {props.centerLink ? (
                    <a href={props.centerLink} target={"_blank"} rel="noreferrer">
                      {formatPriceSize(props.currentPrice)}
                    </a>
                  ) : (
                    formatPriceSize(props.currentPrice)
                  )}
                </span>
                <span
                  className={clsx("flex w-4/12 text-sm font-semibold leading-none text-on-surface-variant", c && "cursor-pointer")}
                  data-tip={"Floor price"}
                  onClick={() => {
                    if (c) setOpenOngoingSales(true);
                  }}
                >
                  <div className="relative flex w-fit items-center">
                    <div className="flex flex-col items-center gap-y-1.5">
                      <span className="text-xs w-fit leading-none font-semibold">Floor</span>
                      {formatPriceSize(props.marketPrice, true)}
                    </div>
                  </div>
                </span>
                <span className="flex text-sm font-semibold leading-none text-on-surface-variant">
                  <div className="flex flex-col items-center gap-y-1.5">
                    <span className={clsx("text-xs font-semibold", isInSeason && scarcityTextColor)}>
                      {isClassic ? "Classic" : card.Season}
                    </span>
                    <div className="!h-3 bg-outline-variant w-px flex-shrink-0 rounded-full" />
                  </div>
                </span>
                <span
                  className={clsx(
                    "flex justify-end w-4/12 text-sm font-semibold leading-none text-on-surface-variant",
                    c && "cursor-pointer",
                  )}
                  data-tip={props.valuationOrigin}
                  onClick={() => {
                    if (c) setOpenPriceGraph(true);
                  }}
                >
                  <div className="relative flex w-fit items-center">
                    <div className="flex flex-col items-center gap-y-1.5">
                      <span className="text-xs w-fit leading-none font-semibold">
                        {getValuationOriginShortOnCard(props.valuationOrigin)}
                      </span>
                      {formatPriceSize(props.monthPrice, true)}
                    </div>
                  </div>
                </span>
              </div>
              {props.avgPrice && props.avgPrice !== "" && (
                <div className={"flex flex-row justify-center"}>
                  <p className={"text-xxs font-semibold"}>{props.avgPrice}</p>
                </div>
              )}
            </div>
            <div className={"flex flex-row space-x-2"}>
              <div className={"w-4/12 items-center flex flex-col self-center space-y-0"}>
                {sport === sorareFootball && (
                  <div className={"w-8/12 items-center flex flex-col"}>
                    {t2OrAbove(props.user.tier) && <PickScore label={pickScore} />}
                    {t1OrBelow(props.user.tier) && <GreyLock />}
                  </div>
                )}
                {sport === sorareBaseball && (
                  <div className={"flex flex-col items-center space-y-0.5"}>
                    <a href={"/player/" + props.player.PlayerId}>
                      <div className={"w-9 h-9"}>
                        <BaseballScoreCircle size={"130"} precision={0} score={props.l20} />
                      </div>
                    </a>
                    <p className={"text-xxs font-medium text-textGrey2"}>L15</p>
                  </div>
                )}
                {sport === sorareBasketball && (
                  <div className={"flex flex-col items-center space-y-1"}>
                    <div className={"flex justify-center items-center w-8 h-8"}>
                      <SorareScoreAverage avg={props.s5} />
                    </div>
                    <div className={"w-8"}>
                      <GamesPlayed
                        sport={sorareBasketball}
                        period={48}
                        gms={5}
                        played={props.gms5}
                        showPct={true}
                        label={props.gms5 + "'"}
                        labelStyle={"text-center font-semibold text-textGrey3 text-xxs"}
                      />
                    </div>
                  </div>
                )}
              </div>
              {sport === sorareFootball && (
                <div className={"flex flex-row space-x-2 w-4/12 self-center"}>
                  <ScoreAverage
                    font={"text-sm font-semibold"}
                    size={"w-8 h-7"}
                    playedSize={"w-8"}
                    period={5}
                    played={props.gms5}
                    sport={props.player.Sport}
                    avg={props.l5}
                    sAvg={props.s5}
                    sApps={props.s5Apps}
                    playerId={props.player.PlayerId}
                  />
                  <ScoreAverage
                    font={"text-sm font-semibold"}
                    size={"w-8 h-7"}
                    playedSize={"w-8"}
                    period={15}
                    played={props.gms20}
                    sport={props.player.Sport}
                    sAvg={props.s15}
                    sApps={props.s15Apps}
                    avg={props.l20}
                    playerId={props.player.PlayerId}
                  />
                </div>
              )}
              {sport === sorareBaseball && (
                <div className={"flex flex-row space-x-2 w-4/12 self-start justify-center border border-background-grey rounded p-1"}>
                  {props.gwGames !== undefined &&
                    props.gwGames !== null &&
                    props.gwGames[props.player.TeamId]?.opponent_and_games &&
                    props.gwGames[props.player.TeamId].opponent_and_games.map((game, index) => {
                      return (
                        <div className={"flex flex-col space-y-1 items-center"}>
                          <div className={"w-5 h-5"}>
                            <img className={"w-full h-full object-contain"} src={game.logo} />
                          </div>
                          <p className={"text-xs text-center font-semibold"}>{game.games}x</p>
                        </div>
                      );
                    })}
                </div>
              )}
              {sport === sorareBaseball && (
                <div className={"flex flex-row space-x-2 w-4/12 self-start justify-center"}>
                  <div className={"flex flex-col space-y-1 items-center"}>
                    <NBAProjectedScore font={"text-xs"} size={"h-7 w-7"} score={projectedScore} scale={"baseball_gw"} />
                    <div className={"border border-background-grey rounded-full py-0.5 px-1"}>
                      <p className={"text-xxs text-center font-semibold"}>Proj.</p>
                    </div>
                  </div>
                </div>
              )}
              {sport === sorareBasketball && (
                <div className={"flex flex-col space-y-1 w-4/12 self-center items-center"}>
                  <div className={"flex flex-row space-x-2 justify-center"}>
                    {bestOpponent !== "" && (
                      <div className={"w-6 h-6"}>
                        <img src={bestOpponent} className={"w-full h-full object-contain"} />
                      </div>
                    )}
                    <div data-tip={"Best projected score next gameweek"}>
                      <NBAProjectedScore size={"w-6 h-6"} font={"text-xs"} score={projectedScore} />
                    </div>
                  </div>
                  <div className={"px-2 py-1 rounded-full"} style={{ backgroundColor: "#E5E5E5" }}>
                    <p className={"font-semibold text-xxs"} style={{ color: "#7F7F7F" }}>
                      {nbOfGames} game{nbOfGames === 1 ? "" : "s"}
                    </p>
                  </div>
                </div>
              )}
              {sport === sorareBasketball && (
                <div className={"flex flex-col space-y-1.5 w-4/12 self-center items-center pt-1"}>
                  <div data-tip={"Projected score difference from L10"}>
                    <NBAProjDiff projDiff={projectedScore - props.s5} />
                  </div>
                  <div className={"w-8"}>
                    <GamesPlayed
                      sport={sorareBasketball}
                      period={0}
                      gms={5}
                      tip={"Projected minutes difference"}
                      played={mins - props.gms5}
                      showPct={true}
                      label={mins - props.gms5 + "'"}
                      labelStyle={"text-center font-semibold text-textGrey3 text-xxs"}
                    />
                  </div>
                </div>
              )}
              {!so5Utility && sport === sorareFootball && (
                <div className={"flex flex-row justify-center w-4/12 self-center"}>
                  <GameOnCard
                    player={props.player}
                    position={card.Position}
                    sport={props.player.Sport}
                    tier={props.user.tier}
                    gwGames={props.gwGames}
                    fontSize={"10px"}
                    gwNumber={props.gwNumber}
                    gwNumberDisplay={props.gwNumberDisplay}
                  />
                </div>
              )}
              {so5Utility && (t2OrAbove(props.user.tier) || props.player.Sport === sorareBaseball) && (
                <div
                  className={"flex flex-col space-y-0.5 items-center w-4/12 self-center"}
                  data-tip={"Card SO5 utility for current owner"}
                >
                  <CirclePct odds={false} showZero={true} value={so5Eligible > 0 ? so5Value : undefined} />
                  <p className={"text-xxs font-semibold"}>
                    {so5Picked}/{so5Eligible}
                  </p>
                </div>
              )}
              {so5Utility && !(t2OrAbove(props.user.tier) || props.player.Sport === sorareBaseball) && (
                <div
                  className={"flex flex-col space-y-0.5 items-center w-4/12 self-center"}
                  data-tip={"Card SO5 utility only available for Star members"}
                >
                  <img src={Locker} />
                </div>
              )}
            </div>
            <div className={"flex items-center justify-between"}>
              {props.countdown && (
                <>
                  <div className={"flex w-4/12 items-center justify-center flex-col space-y-0.5"}>
                    {(t1OrAbove(props.user.tier) || sport === sorareBaseball || sport === sorareBasketball) && (
                      <div>
                        <PlayerAvailability
                          availability={props.availability}
                          size={"w-4 h-4"}
                          player={props.player}
                          intl={props.player.InternationalTrip}
                          position="center"
                          nextGwPlayers={props.gwGames}
                        />
                      </div>
                    )}
                    <p className={"text-xs font-semibold"}>{((props.card.Power - 1) * 100).toFixed(1)}%</p>
                  </div>
                  <div className={"flex w-5/12 justify-center rounded-t-lg border-t border-outline-variant bg-surface-container-low p-3"}>
                    {props.countdown && (
                      <p className={"font-semibold text-xs"}>
                        <Countdown daysInHours={true} date={props.date}>
                          <Completionist />
                        </Countdown>
                      </p>
                    )}
                    {!props.countdown && !t1OrAbove(props.user.tier) && sport === sorareFootball && <CardLocker />}
                  </div>
                  <div className="flex w-4/12 justify-center">
                    <div className={"text-xs font-semibold text-on-surface-variant"}>
                      <CardPlayingStatus weight={600} player={props.player} size={"11px"} status={props.player.PlayingStatus} />
                    </div>
                  </div>
                </>
              )}
              {!props.countdown && (
                <div
                  style={{ backgroundColor: "#F9FBFC" }}
                  className={"flex flex-row space-x-1 w-full rounded-b-lg justify-center text-center py-2 text-textGrey3"}
                >
                  <div>
                    <PlayerAvailability
                      availability={props.availability}
                      size={"w-4 h-4"}
                      player={props.player}
                      intl={props.player.InternationalTrip}
                      position="center"
                      nextGwPlayers={props.gwGames}
                    />
                  </div>
                  <p className={"text-xs font-semibold"}>{((props.card.Power - 1) * 100).toFixed(1)}%</p>
                  {
                    <div className={"text-xs font-semibold text-textGrey3 text-center"}>
                      · <CardPlayingStatus weight={600} player={props.player} size={"12px"} status={props.player.PlayingStatus} />
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
        {props.setStatus && showMarketStatus && (
          <div>
            <MarketStatusPopover
              priceInfo={props.priceInfo}
              changeTag={props.changeTag}
              card={props.card}
              grid={true}
              bmp={props.bmp}
              rt={props.rt}
              createTag={createTag}
              close={() => setShowMarketStatus(false)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withUser(CardLayout);
