import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { ReactComponent as CloseIcon } from "../../img/icons-close.svg";
import { ReactTooltip } from "../util/tooltip.js";
import ItemSearchForm from "./itemsSearchForm";
import PlayerSearchWithFilters from "../watchlists/playerSearchWithFilters";

const availableDisplays = {
  player: ["search", "advanced"],
  manager: ["search"],
};

function AddSearchableItemsModal(props) {
  const title = props.title || "Add players";
  const type = props.type || "player";
  const sport = props.sport || "multi";
  const { onSave, saving } = props;
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(availableDisplays[type][0]);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    setOpen(props.open);
    if (!props.open) {
      setSearch("");
      setResults([]);
      setSelectedItems({});
    }
  }, [props.open]);

  useEffect(() => {
    props
      .fetch(`/search-api/search/autocomplete?limit=30&query=${search}&sport=${sport}`)
      .then((response) => response.json())
      .then((res) => {
        if (type === "player") {
          setResults(res?.player || []);
        } else if (type === "manager") {
          setResults(res?.user || []);
        }
      })
      .catch(errorCatcher());
  }, [search]);

  const close = () => {
    props.onCancel();
    setOpen(false);
  };

  const onSaveSelected = () => {
    const ids = Object.keys(selectedItems);
    if (ids.length > 0 && onSave) {
      onSave(ids);
    }
  };

  const handleSelect = (id, itemName) => {
    ReactTooltip.rebuild();
    const newSelectedItems = { ...selectedItems };
    if (selectedItems[id]) {
      delete newSelectedItems[id];
    } else {
      newSelectedItems[id] = itemName;
    }
    setSelectedItems(newSelectedItems);
  };

  const handleMultipleSelect = (items) => {
    ReactTooltip.rebuild();
    const newSelectedItems = { ...selectedItems, ...items };
    setSelectedItems(newSelectedItems);
  };

  const handleMultipleUnselect = (items) => {
    ReactTooltip.rebuild();
    const newSelectedItems = {};
    Object.keys(selectedItems)
      .filter((id) => !items[id])
      .forEach((id) => {
        newSelectedItems[id] = selectedItems[id];
      });
    setSelectedItems(newSelectedItems);
  };

  const canSave = Object.keys(selectedItems).length > 0;

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0" onClose={close}>
          <div className="flex items-end justify-center h-full p-0 text-center overflow-y-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom text-left shadow-xl m-auto transform transition-all sm:align-middle sm:max-w-7xl sm:w-full w-full p-0 ">
                <div className="relative bg-grey-f8 rounded-lg">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={close}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className={"w-auto h-auto pt-8 "}>
                    <div className={"flex flex-col w-full border-b-2 border-grey-e5 pb-6"}>
                      <h2 className={"text-center font-headers text-brand-black text-3xl font-bold m-0 mb-2"}>{title}</h2>
                      {props.msg && (
                        <div className="mx-8 my-4 text-center text-black">
                          <p>{props.msg}</p>
                        </div>
                      )}
                      {availableDisplays[type].length > 1 && (
                        <div className=" bg-focus mx-auto rounded-lg border border-grey-e9 px-0 py-2 gap-0 text-sm font-sans font-semibold text-textGrey4 grid grid-cols-2 divide-x text-center">
                          {availableDisplays[type].map((disp) => {
                            return (
                              <div
                                key={disp}
                                className={
                                  "px-6 cursor-pointer capitalize " + (display === disp ? "text-brand-text" : "hover:text-brand-text")
                                }
                                onClick={() => setDisplay(disp)}
                              >
                                {disp}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className={"w-full h-full"}>
                      <div className={(display === "search" ? "block" : "hidden") + " p-4 m-auto mt-1"}>
                        <ItemSearchForm
                          type={type}
                          value={search}
                          sport={sport}
                          selectedItems={selectedItems}
                          onClean={() => setSearch("")}
                          onClickOutside={() => setSearch("")}
                          results={results}
                          onClickItem={handleSelect}
                          onChange={(v) => {
                            setSearch(v.target.value);
                          }}
                        />
                      </div>
                      <div className={display === "advanced" ? "block" : "hidden"}>
                        {type === "player" && (
                          <PlayerSearchWithFilters
                            sport={sport}
                            selectedItems={selectedItems}
                            onClickItem={handleSelect}
                            onUnselectItems={handleMultipleUnselect}
                            onSelectItems={handleMultipleSelect}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-grey-f8 h-auto rounded-lg mt-4">
                  <div className={"w-auto "}>
                    <div className={"flex flex-row w-full border-b-2 border-grey-e5 p-3"}>
                      <h4
                        className={"text-center font-headers text-brand-black text-md md:text-2xl font-bold my-auto p-1"}
                      >{`Current selection (${Object.keys(selectedItems).length})`}</h4>
                      <div className={"ml-auto mr-0 inline"}>
                        <button
                          className={
                            "text-brand mx-2 p-2 px-6 md:px-16 text-xs sm:text-med font-medium bg-brand-pastel rounded-md " +
                            (canSave ? "focus:outline-none hover:opacity-80 cursor-pointer" : "opacity-50 cursor-default")
                          }
                          onClick={() => setSelectedItems({})}
                        >
                          <span>Reset all</span>
                        </button>
                        <button
                          className={
                            "text-white mx-2 p-2 px-6 md:px-16 text-xs sm:text-med font-medium rounded-md bg-brand focus:outline-none " +
                            (canSave ? "hover:bg-brand-light cursor-pointer" : "opacity-50 cursor-default")
                          }
                          onClick={onSaveSelected}
                        >
                          {saving ? <span>Adding...</span> : <span>Add</span>}
                        </button>
                      </div>
                    </div>
                    <div
                      className={"w-full flex flex-row flex-wrap gap-4 p-4 text-sm font-semibold text-brand overflow-scroll"}
                      style={{ minHeight: "4em", maxHeight: "12em" }}
                    >
                      {Object.keys(selectedItems).map((id) => {
                        const itemName = selectedItems[id];
                        return (
                          <span key={id} className={"bg-brand-pastel rounded-full pl-4 py-2"}>
                            {itemName}
                            <CloseIcon
                              onClick={() => handleSelect(id, itemName)}
                              className={"cursor-pointer inline stroke-brand hover:opacity-80 ml-2 mr-4 h-3 w-3"}
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withUser(AddSearchableItemsModal);
