import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "@material-design-icons/svg/filled/close.svg";

const showBetaFeature = (featureKey) => {
  const show = localStorage.getItem(featureKey);
  return show === "true" || show === null || show === undefined || show === "";
};

const hideBetaFeature = (featureKey) => {
  localStorage.setItem(featureKey, "false");
};

export const BetaBanner = (props) => {
  const { featureKey } = props;
  const finalfeatureKey = `beta-${featureKey}`;
  const [show, setShow] = useState(showBetaFeature(finalfeatureKey));
  const hide = () => {
    hideBetaFeature(finalfeatureKey);
    setShow(false);
  };
  if (featureKey === null || featureKey === undefined || featureKey == "") {
    return null;
  }
  if (!show) {
    return null;
  }
  return (
    <div
      className={"rounded-xl overflow-hidden flex flex-col justify-center relative"}
      style={{ background: `linear-gradient(90deg, #25CBAF 0%, rgba(51, 59, 251, 0.40) 100%)` }}
    >
      <div className={"flex flex-row justify-between items-center bg-surface-container-high bg-opacity-70"}>
        <div className={"flex flex-col gap-1 p-4 justify-center"}>
          <h4 className={"m-0 font-headers text-medium text-on-surface"}>Feature in Beta</h4>
          <p className={"text-md text-on-surface-variant"}>This feature is new and we&apos;re still working on fine tuning</p>
        </div>
        <div className={"p-4"}>
          <CloseIcon className={"w-8 h-8 fill-on-surface cursor-pointer"} onClick={() => hide()} />
        </div>
      </div>
    </div>
  );
};
