import React from "react";

/**
 * @deprecated use Design System Button instead
 */
function GreenButton(props) {
  return (
    <button
      type="button"
      {...props}
      className="inline-flex items-center px-6 py-2 text-med font-semibold
                rounded-md shadow-sm text-white bg-brand-light-green hover:bg-brand-light-light-green focus:outline-none focus:ring-2
                focus:ring-brand-light-light-green"
    >
      {props.label}
    </button>
  );
}

export default GreenButton;
