import React from "react";

function ComboStats(props) {
  const stats = props.stats;
  const dtClass = "order-2 mt-2 lg:text-med text-sm leading-6 font-medium text-gray-500 justify-center";

  return (
    <div className="mt-10">
      <div className="relative">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-6">
          <div className="mx-auto">
            <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5">
              <div className="flex flex-col border-b border-gray-100 lg:px-6 px-2 py-4 text-center sm:border-0 sm:border-r">
                <dt className={dtClass}>Common games played</dt>
                <dd className="order-1 text-3xl font-extrabold">{stats?.common_games}</dd>
              </div>
              <div className="flex flex-col border-t border-b border-gray-100 lg:px-6 px-2 py-4 text-center sm:border-0 sm:border-l sm:border-r">
                <dt className={dtClass}>Minutes played average</dt>
                <dd className="order-1 text-3xl font-extrabold">{stats?.avg_minutes?.toFixed(0)}</dd>
              </div>
              <div className="flex flex-col border-t border-gray-100 lg:px-6 px-2 py-4 text-center sm:border-0 sm:border-l">
                <dt className={dtClass}>Combined score average</dt>
                <dd className="order-1 text-3xl font-extrabold">{stats?.average_combined_score?.toFixed(1)}</dd>
              </div>
              <div className="flex flex-col border-t border-gray-100 lg:px-6 px-2 py-4 text-center sm:border-0 sm:border-l">
                <dt className={dtClass}>Combined score per player</dt>
                <dd className="order-1 text-3xl font-extrabold">{stats?.average_combined_score_divided?.toFixed(1)}</dd>
              </div>
              <div className="flex flex-col border-t border-gray-100 lg:px-6 py-4 px-2 text-center sm:border-0 sm:border-l">
                <dt className={dtClass}>% of games with 60+ points each</dt>
                <dd className="order-1 text-3xl font-extrabold">{(stats?.avg_above_60 * 100).toFixed(0)}%</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComboStats;
