import React from "react";

const ConceptPicker = (props) => {
  let color = "";
  if (props.scarcity === "limited") {
    color = "#E7B62C";
  } else if (props.scarcity === "rare") {
    color = "#E73107";
  } else if (props.scarcity === "super rare") {
    color = "#0380F3";
  } else if (props.scarcity === "unique") {
    color = "#292929";
  }
  return (
    <svg className={"cursor-pointer"} width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C43.9879 35.0407 35.0407 43.9879 24 44ZM8 24.344C8.09463 33.1465 15.2822 40.2191 24.0851 40.172C32.8879 40.1245 39.9991 32.975 39.9991 24.172C39.9991 15.369 32.8879 8.21955 24.0851 8.172C15.2822 8.12491 8.09463 15.1975 8 24V24.344ZM26 34H22V26H14V22H22V14H26V22H34V26H26V34Z"
        fill={"white"}
      />
      <path
        d="M24 44C12.9593 43.9879 4.01212 35.0407 4 24V23.6C4.21986 12.6091 13.2692 3.85595 24.2613 4.0018C35.2535 4.14764 44.0674 13.1378 43.9956 24.1307C43.9237 35.1236 34.9931 43.9978 24 44ZM14 22V26H22V34H26V26H34V22H26V14H22V22H14Z"
        fill={color}
      />
    </svg>
  );
};

export default ConceptPicker;
