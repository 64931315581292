import React, { useState } from "react";
import { units } from "./units";

function CurrencyPicker(props) {
  const disabledCurrencies = props.disabledCurrencies || {};
  const [isHover, setIsHover] = useState(null);
  const unitsObj = props.units || units;

  return (
    <div
      className={`bg-surface-container rounded-3xl w-full text-xl font-sans font-semibold text-on-surface-variant flex flex-row justify-items-center overflow-hidden px-1 py-2 ${props.border}`}
    >
      <div
        className={`flex flex-row w-full justify-items-center overflow-hidden px-1 divide-x divide-transparent-inverse-surface-low divide-opacity-10`}
      >
        {Object.keys(unitsObj).map((currency) => {
          const disabledMessage = disabledCurrencies[currency];
          const isDisabled = disabledMessage !== undefined;
          const isSelected = props.currency === currency;
          const hover = isHover === currency;
          let currencyClass;
          if (isDisabled) {
            currencyClass = "text-on-surface-variant opacity-50 ";
          } else if (isSelected || hover) {
            currencyClass = "text-primary";
            if (hover) {
              currencyClass += " opacity-90";
            }
          } else {
            currencyClass = "text-on-surface opacity-50";
            if (props.fontUnselected) {
              currencyClass = props.fontUnselected;
            }
          }
          const currencyText = unitsObj[currency];
          return (
            <div
              key={currency}
              className={`table w-full text-center self-center px-3 ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"} ${
                currencyText.length > 3 ? "text-sm" : ""
              }`}
              onClick={() => !isDisabled && props.onChange(currency)}
              onMouseEnter={() => setIsHover(currency)}
              onMouseLeave={() => setIsHover(null)}
              data-tip={disabledMessage}
            >
              <span className={`table-cell align-middle ${currencyClass}`}>{currencyText}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CurrencyPicker;
