import { withUser } from "../../userContext";
import React, { useState } from "react";
import Modal from "./modal";
import StartIcon from "../../img/starter.svg";
import Doubtful from "../../img/doubtful.svg";
import Bench from "../../img/benchNotCameIn.svg";
import NotInSquad from "../../img/notInSquad.svg";
import { Button } from "./button";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";
import { ReactComponent as PlayerCircle } from "@material-design-icons/svg/filled/play_circle.svg";

const ProjectionModale = (props) => {
  const { close, open } = props;
  const imgClass = "w-6 h-6";
  return (
    <Modal
      close={close}
      open={open}
      content={
        <div className={"font-sans"}>
          <div className={"py-2 border-b border-grey-e5 font-headers"}>
            <p className={"text-3xl font-semibold text-center"}>Lineup projections</p>
          </div>
          <div className={"flex flex-row space-x-2 pt-4 justify-center"}>
            <div className={"flex flex-col items-center"}>
              <div>
                <img className={imgClass} src={StartIcon} />
              </div>
            </div>
            <div>
              <div>
                <img className={imgClass} src={Doubtful} />
              </div>
            </div>
            <div>
              <div>
                <img className={imgClass} src={Bench} />
              </div>
            </div>
            <div>
              <div>
                <img className={imgClass} src={NotInSquad} />
              </div>
            </div>
          </div>
          <div className={"py-4 px-4"}>
            <p className={"text-sm font-medium text-center"}>
              Participate in our lineup projections contest to win prizes and help the community reduce DNPs. Managers with the highest
              success rate will receive various rewards.
              <br />
            </p>
          </div>
          <div className={"px-4"}>
            <p className={"text-brand text-sm font-semibold text-center"}>This feature will remain free forever.</p>
          </div>
          <div className={"flex flex-row justify-between py-4 px-4"}>
            <p className={"text-sm font-medium text-center font-semibold hover:opacity-90 underline text-brand"}>
              <a
                target={"_blank"}
                href={"https://soraredata.medium.com/new-rewards-rules-for-our-community-projected-lineups-competition-329d5e68b46e"}
                rel="noreferrer"
              >
                Learn more about prizepool
              </a>
            </p>
            <p className={"text-sm font-medium text-center font-semibold hover:opacity-90 underline  text-brand"}>
              <a
                target={"_blank"}
                href={"https://soraredata.medium.com/rule-for-the-ranking-of-the-community-lineup-projection-6033cbafa537"}
                rel="noreferrer"
              >
                Learn more about the rules & scoring
              </a>
            </p>
          </div>
          <div className={"flex flex-row justify-center py-2"}>
            <Button label={"Ok, got it!"} onClick={() => close()} />
          </div>
        </div>
      }
    />
  );
};

function LineupPredictionModal(props) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {open && <ProjectionModale close={() => setOpen(false)} open={open} />}
      <div
        onClick={() => setOpen(true)}
        className={
          "bg-white h-10 flex justify-center items-center rounded-lg py-1.5 px-4 cursor-pointer border border-white hover:border-brand"
        }
      >
        <p className={"font-semibold text-sm self-center text-brand"}>Lineup projections</p>
      </div>
    </div>
  );
}

export const LineupCompetitionRules = (props) => {
  const { dark } = props;
  const [open, setOpen] = useState(false);
  return (
    <div>
      {open && <ProjectionModale close={() => setOpen(false)} open={open} />}
      <div onClick={() => setOpen(true)} className={"h-10 flex items-center py-1.5 cursor-pointer hover:opacity-80"}>
        <div className={`font-semibold text-sm self-center ${dark ? "text-surface-container" : "text-on-surface"} flex flex-row gap-1`}>
          <IconInfoHelp className={`w-5 h-5 my-auto ${dark ? "fill-surface-container" : "fill-on-surface"}`} />
          <span>Competition rules</span>
        </div>
      </div>
    </div>
  );
};

export const LineupProjectedLineupsVideo = (props) => {
  const { dark } = props;
  const [open, setOpen] = useState(false);
  return (
    <div>
      {open && <ModaleProjectedLineupsVideo close={() => setOpen(false)} open={open} />}
      <div onClick={() => setOpen(true)} className={"h-10 flex items-center py-1.5 cursor-pointer hover:opacity-80"}>
        <div className={`font-semibold text-sm self-center ${dark ? "text-surface-container" : "text-on-surface"} flex flex-row gap-1`}>
          <PlayerCircle className={`w-5 h-5 my-auto ${dark ? "fill-surface-container" : "fill-on-surface"}`} />
          <span>Understanding Projected Lineups</span>
        </div>
      </div>
    </div>
  );
};

const ModaleProjectedLineupsVideo = (props) => {
  const { close, open } = props;
  return (
    <Modal
      close={close}
      open={open}
      content={
        <div className={"font-sans"}>
          <div className={"font-sans flex flex-col gap-2 text-on-surface"}>
            <div className="font-headers px-5 border-b border-transparent-inverse-surface-low border-opacity-10 pb-4 flex flex-col justify-center items-center gap-2">
              <p className="text-xl font-headers font-semibold m-0 text-center">Understanding Projected Lineups</p>
            </div>
            <div className={"flex flex-row space-x-2 pt-4 justify-center"}>
              <div className={"flex justify-center"}>
                <iframe
                  src={"https://www.youtube.com/embed/469L1uu6sc0"}
                  style={{ aspectRatio: "16/9" }}
                  className={"rounded-xl sm:[70vw] md:[50vw] lg:w-[35vw]"}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className={"flex flex-row justify-center py-2"}>
            <Button label={"Ok, got it!"} onClick={() => close()} />
          </div>
        </div>
      }
    />
  );
};

export default withUser(LineupPredictionModal);
