import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { withRouter } from "react-router-dom";
import { ReactTooltip } from "../util/tooltip.js";
import ScarcityPicker from "../util/scarcityPicker";
import CurrencyPicker from "../util/newCurrencyPicker";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import NewCardIcon from "../util/newCardIcon";
import { scarcities_colors } from "../util/scarcities";
import SDLoading from "../util/SDLoading";
import { Area, AreaChart, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { format } from "date-fns";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatAllCurrencies } from "../util/formatAllCurrencies";
import Spinner from "../loader/spinner";
import { PeriodPicker } from "../util/periodPicker";
import { ReactComponent as IconCircle } from "../../img/icons-circle.svg";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { searchText } from "../util/strings";
import randomColor from "randomcolor";
import { equidistantFixedColors } from "../util/colors";
import PlayerPriceCard from "../players/playerPriceCard";
import HelpTip from "../util/helpTip";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { isFree, isTier1 } from "../util/handleSubscriptionTier";

const sortByLabels = {
  "bmpPrice asc": "Floor price (lowest to highest)",
  "bmpPrice desc": "Floor price (highest to lowest)",
  "averagePriceGrowth asc": "Average price growth (lowest to highest)",
  "averagePriceGrowth desc": "Average price growth (highest to lowest)",
  "added asc": "Least recently added",
  "added desc": "Most recently added",
};

const limitTooManyPlayers = 25;

const sortByValuation = (key, order) => {
  const orderBy = order || "asc";
  const keySearch = key || "value";
  return (a, b) => {
    if (a[keySearch] === b[keySearch]) {
      return 0;
    }
    if (orderBy === "asc") {
      return a[keySearch] > b[keySearch] ? 1 : -1;
    }
    return a[keySearch] > b[keySearch] ? -1 : 1;
  };
};

const PlaceholderWrapper = (props) => {
  return (
    <div className={"flex justify-center m-auto "} style={{ height: "300px" }}>
      <div className={"p-2 text-center items-center m-auto text-textGrey3 text-lg"}>{props.children}</div>
    </div>
  );
};

const AverageHistoryDiagramTooltip = ({ active, payload, label, currency }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-brand-black opacity-80 p-1 rounded-sm">
        <p className="label text-sm text-white">{format(new Date(label), "dd/MM/yyyy")}</p>
        <p className="label text-sm text-white">{formatAllCurrencies(payload[0].value, currency)}</p>
      </div>
    );
  }

  return null;
};

const AverageHistoryDiagram = (props) => {
  const { history, scarcity, currency, dataKey, players } = props;
  const scarcityColor = scarcities_colors[scarcity.toLowerCase()] || "grey";

  if (players === undefined) {
    return null;
  }

  if (players.length === 0) {
    return (
      <PlaceholderWrapper>
        <p>No player in watchlist</p>
      </PlaceholderWrapper>
    );
  }
  if (history.length === 0) {
    return (
      <PlaceholderWrapper>
        <p>Price history diagram starts when all players have cards for given scarcity.</p>
        <p>
          Check that all your players have a valuation for scarcity{" "}
          <span className={"inline-block h-6 w-6"} style={{ verticalAlign: "top" }}>
            <NewCardIcon scarcity={scarcity.toLowerCase()} />
          </span>
        </p>
      </PlaceholderWrapper>
    );
  }

  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <ComposedChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} padding={{ top: 0, right: 0, bottom: 0, left: 0 }} data={history}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={scarcityColor} stopOpacity={0.1} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Tooltip content={<AverageHistoryDiagramTooltip currency={currency} />} />
        <CartesianGrid stroke={"#eeeeee"} />
        <Line
          type="monotone"
          unit="M"
          strokeLinecap="round"
          strokeWidth={2}
          dataKey={dataKey}
          stroke={scarcityColor}
          dot={false}
          legendType="none"
        />
        {dataKey === "cumulative" && (
          <Area type="monotone" dataKey={dataKey} stroke={"none"} strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
        )}
        <XAxis
          dataKey="timestamp"
          tickFormatter={(x) => {
            return format(new Date(x), "LLL dd");
          }}
          position
          type="number"
          scale="time"
          domain={["auto", "auto"]}
          axisLine={{ stroke: "white" }}
          style={{
            fontSize: "0.65rem",
            fontFamily: "Inter, sans-serif",
            marginTop: "3px",
            fill: "#828282",
            fontWeight: 500,
          }}
        />
        <YAxis
          axisLine={{ stroke: "white" }}
          dataKey={dataKey}
          type="number"
          domain={[0, "auto"]}
          tickCount={8}
          style={{
            fontSize: "0.65rem",
            fontFamily: "Inter, sans-serif",
            fontWeight: 500,
            marginTop: "3px",
            fill: "#828282",
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const CumulativeDiagramTooltip = ({ active, payload, label, currency, playerNames, hoverDotPlayer }) => {
  if (active && payload && payload.length) {
    let playerDisplay = null;
    if (hoverDotPlayer) {
      const hoverPlayer = payload.find((p) => p.dataKey === hoverDotPlayer);
      if (hoverPlayer) {
        playerDisplay = (
          <p className="label text-sm text-white">{`${playerNames[hoverDotPlayer] || "Rest"}: ${formatAllCurrencies(
            hoverPlayer.value,
            currency,
          )}`}</p>
        );
      }
    } else {
      playerDisplay = (
        <ul>
          {[...payload]
            .sort(sortByValuation("value", "desc"))
            .slice(0, 20)
            .map((p) => {
              return (
                <li key={p.dataKey} className="label text-sm text-white">{`${playerNames[p.dataKey] || "Rest"}: ${formatAllCurrencies(
                  p.value,
                  currency,
                )}`}</li>
              );
            })}
        </ul>
      );
    }
    return (
      <div className="bg-brand-black opacity-80 p-1 rounded-sm overflow-y-hidden" style={{ maxHeight: "300px" }}>
        <p className="label text-sm text-white">{format(new Date(label), "dd/MM/yyyy")}</p>
        {playerDisplay}
      </div>
    );
  }

  return null;
};

const PlayerValueDiagram = (props) => {
  const { currency, players, colors, playerHover, dataKey, tooManyPlayers } = props;
  const [hoverDotPlayer, setHoverDotPlayer] = useState("");

  if (players === undefined) {
    return null;
  }
  if (players.length === 0) {
    return (
      <PlaceholderWrapper>
        <p>No selected player</p>
      </PlaceholderWrapper>
    );
  }

  const averagesByDate = {};
  const playerNames = {};
  let hoverPlayerIsInDisplayed = false;
  players.forEach((p, i) => {
    p.priceHistory?.forEach((ph) => {
      averagesByDate[ph.timestamp] = {
        ...averagesByDate[ph.timestamp],
        [p.player.PlayerId]: ph.average,
      };
    });
    playerNames[p.player.PlayerId] = p.player.DisplayName;
    if (playerHover === p.player.PlayerId) {
      hoverPlayerIsInDisplayed = playerHover === p.player.PlayerId;
    }
  });

  const data = Object.keys(averagesByDate).map((timestamp) => {
    const playerData = { timestamp: Number(timestamp) };
    let cumulative = 0;
    if (dataKey === "average") {
      players.forEach((p, i) => {
        if (!tooManyPlayers || i < limitTooManyPlayers) {
          playerData[p.player.PlayerId] = averagesByDate[timestamp][p.player.PlayerId] || 0;
        }
      });
    } else {
      let restCumul = 0;
      for (let i = players.length - 1; i >= 0; i--) {
        const p = players[i];
        const val = averagesByDate[timestamp][p.player.PlayerId];
        if (!tooManyPlayers || i < limitTooManyPlayers) {
          cumulative = cumulative || restCumul;
          if (val > 0) {
            cumulative += val;
          }
          playerData[p.player.PlayerId] = cumulative;
        } else {
          if (val > 0) {
            restCumul += val;
          }
        }
      }
      if (restCumul > 0) {
        playerData["rest"] = restCumul;
      }
    }
    return playerData;
  });

  const displayedPlayers = tooManyPlayers ? [...players.slice(0, limitTooManyPlayers)] : [...players];

  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <AreaChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} padding={{ top: 0, right: 0, bottom: 0, left: 0 }} data={data}>
        {!tooManyPlayers && (
          <Tooltip
            cursor={false}
            content={<CumulativeDiagramTooltip currency={currency} playerNames={playerNames} hoverDotPlayer={hoverDotPlayer} />}
          />
        )}
        <CartesianGrid stroke={"#eeeeee"} />
        {displayedPlayers.reverse().map((p) => {
          let isPlain = true;
          if (playerHover !== "" && hoverPlayerIsInDisplayed) {
            isPlain = playerHover === p.player.PlayerId;
          }
          const fillOpacity = dataKey === "cumulative" ? (isPlain ? "0.10" : "0.05") : "0";
          return (
            <Area
              key={p.player.PlayerId}
              type="monotone"
              unit="M"
              strokeLinecap="round"
              strokeWidth={2}
              dataKey={p.player.PlayerId}
              fill={colors[p.player.PlayerId]}
              stroke={colors[p.player.PlayerId]}
              style={{
                strokeOpacity: isPlain ? "1" : "0.1",
                fillOpacity: fillOpacity,
              }}
              isAnimationActive={!tooManyPlayers}
              dot={false}
              legendType="none"
              activeDot={
                !tooManyPlayers && {
                  onMouseOver: (e) => setHoverDotPlayer(p.player.PlayerId),
                  onMouseLeave: (e) => setHoverDotPlayer(""),
                }
              }
            />
          );
        })}
        {tooManyPlayers && dataKey === "average" && (
          <Area
            type="monotone"
            unit="M"
            strokeLinecap="round"
            strokeWidth={2}
            dataKey={"rest"}
            fill={"grey"}
            stroke={"grey"}
            style={{
              strokeOpacity: "1",
              fillOpacity: dataKey === "cumulative" ? "0.10" : "0",
            }}
            dot={false}
            legendType="none"
          />
        )}
        <XAxis
          dataKey="timestamp"
          tickFormatter={(x) => {
            return format(new Date(x), "LLL dd");
          }}
          position
          type="number"
          scale="time"
          domain={["auto", "auto"]}
          axisLine={{ stroke: "white" }}
          style={{
            fontSize: "0.65rem",
            fontFamily: "Inter, sans-serif",
            marginTop: "3px",
            fill: "#828282",
            fontWeight: 500,
          }}
        />
        <YAxis
          axisLine={{ stroke: "white" }}
          type="number"
          domain={[0, "auto"]}
          tickCount={8}
          style={{
            fontSize: "0.65rem",
            fontFamily: "Inter, sans-serif",
            fontWeight: 500,
            marginTop: "3px",
            fill: "#828282",
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const PlayerLegend = (props) => {
  const { players, tooManyPlayers, dataKey, playerHover, colors } = props;
  const selectedPlayers = props.selectedPlayers || {};
  const { onSelect, onSelectAll, onUnselectAll, onPlayerHover } = props;
  const [filteredPlayers, setFilteredPlayers] = useState(undefined);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search === undefined || search === "") {
      setFilteredPlayers(undefined);
    } else {
      setFilteredPlayers(
        props.players
          .filter((p) => {
            return searchText(search, p.player.DisplayName);
          })
          .sort(sortByValuation("lastValuation", "desc")),
      );
    }
  }, [players, search]);

  const legendPlayers = filteredPlayers !== undefined ? filteredPlayers : players;
  return (
    <div
      className={"md:col-span-2 xl:col-span-1 flex flex-col w-full md:w-auto md:flex-col justify-between gap-2 px-2"}
      style={{ minWidth: "fit-content", height: "300px" }}
    >
      <div className={"m-auto relative flex h-9 "}>
        <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
          <FormSearch className="h-5 w-5" aria-hidden="true" />
        </div>
        {search && (
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer" onClick={() => setSearch("")}>
            <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
          </div>
        )}
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          className={`h-9 rounded-lg block w-full pl-10 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0  ${
            search ? "font-medium" : ""
          }`}
          placeholder="Search..."
        />
      </div>
      <div className={"h-full overflow-y-scroll"}>
        <ul className={"text-xs text-brand-black font-semibold overflow-y-scroll"} style={{ minWidth: "fit-content" }}>
          {legendPlayers.map((p) => {
            return (
              <li
                key={p.player.PlayerId}
                onMouseEnter={tooManyPlayers || !selectedPlayers[p.player.PlayerId] ? undefined : () => onPlayerHover(p.player.PlayerId)}
                onMouseLeave={
                  tooManyPlayers || !selectedPlayers[p.player.PlayerId]
                    ? undefined
                    : () => {
                        p.player.PlayerId === playerHover && onPlayerHover("");
                      }
                }
                className={`pb-1 cursor-pointer hover:opacity-80 ${selectedPlayers[p.player.PlayerId] ? "opacity-100" : "opacity-20"}`}
                onClick={() => {
                  !tooManyPlayers && onPlayerHover("");
                  onSelect(p.player.PlayerId);
                }}
              >
                <IconCircle className={"h-2 w-2 inline align-baseline mr-1"} style={{ fill: colors[p.player.PlayerId] }} />
                {p.player.DisplayName}
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        {tooManyPlayers && (
          <p className={"px-2 py-1 text-xs text-center text-textGrey3 italic"}>
            {`Selected more than ${limitTooManyPlayers} players. ${
              dataKey === "average" ? "Cheapest players are hidden" : "Cheapest players are summed up in one line"
            }`}
          </p>
        )}
      </div>
      <div className={"my-auto text-xs md:text-sm font-semibold text-brand justify-between flex flex-row gap-2"}>
        {props.players && (
          <span className={"my-auto w-full cursor-pointer hover:font-bold"} onClick={onSelectAll}>
            Select all
          </span>
        )}
        {props.players && (
          <span className={"my-auto w-full cursor-pointer hover:font-bold"} onClick={onUnselectAll}>
            Unselect all
          </span>
        )}
      </div>
    </div>
  );
};

const PlayerValueDiagramAndLegend = (props) => {
  const { currency, scarcity, dataKey } = props;
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState(undefined);
  const [selectedPlayersArray, setSelectedPlayersArray] = useState(undefined); // To avoid rerendering
  const [playerHover, setPlayerHover] = useState("");
  const [colors, setColors] = useState({});
  let baseColors = [...equidistantFixedColors];
  const tooManyPlayers = selectedPlayers !== undefined ? Object.keys(selectedPlayers).length > limitTooManyPlayers : false;

  useEffect(() => {
    if (props.players === undefined) {
      return;
    }
    const newColors = { ...colors };
    const newPlayers = [...props.players].sort(sortByValuation("lastValuation", "desc"));
    newPlayers.forEach((p) => {
      if (!newColors[p.player.PlayerId]) {
        newColors[p.player.PlayerId] = baseColors.shift() || randomColor();
      }
    });
    setColors(newColors);
    setDisplayedPlayers(newPlayers);
    if (selectedPlayers === undefined) {
      const firstPlayers = {};
      newPlayers.slice(0, 5).forEach((p) => (firstPlayers[p.player.PlayerId] = true));
      setSelectedPlayers(firstPlayers);
    }
  }, [props.players]);

  useEffect(() => {
    if (selectedPlayers !== undefined) {
      setSelectedPlayersArray(displayedPlayers.filter((p) => selectedPlayers[p.player.PlayerId]));
    } else {
      setSelectedPlayersArray(undefined);
    }
  }, [selectedPlayers, displayedPlayers]);

  const handleSelect = (playerId) => {
    const newSelectedPlayers = selectedPlayers !== undefined ? { ...selectedPlayers } : {};
    if (selectedPlayers[playerId]) {
      delete newSelectedPlayers[playerId];
    } else {
      newSelectedPlayers[playerId] = true;
    }
    setSelectedPlayers(newSelectedPlayers);
  };

  const selectAll = () => {
    if (props.players === undefined) {
      return;
    }
    let selected = {};
    props.players.forEach((p) => {
      selected[p.player.PlayerId] = true;
    });
    setSelectedPlayers(selected);
  };

  const unSelectAll = () => {
    setSelectedPlayers({});
  };

  return (
    <div className={" gap-y-1 flex flex-col md:grid md:grid-rows-none md:grid-cols-8 justify-between"}>
      <div className={"md:col-span-6 xl:col-span-7 pr-3"}>
        <PlayerValueDiagram
          scarcity={scarcity}
          colors={colors}
          players={selectedPlayersArray}
          currency={currency}
          playerHover={playerHover}
          dataKey={dataKey}
          tooManyPlayers={tooManyPlayers}
        />
      </div>
      <PlayerLegend
        players={displayedPlayers}
        selectedPlayers={selectedPlayers}
        tooManyPlayers={tooManyPlayers}
        dataKey={dataKey}
        playerHover={playerHover}
        colors={colors}
        onSelectAll={selectAll}
        onUnselectAll={unSelectAll}
        onSelect={handleSelect}
        onPlayerHover={(id) => setPlayerHover(id)}
      />
    </div>
  );
};

const totalTipMessage =
  "<ul> <li><b>Total average price</b>: average of players average prices for all players in the watchlist</li><li><b>Total cumulative value</b>: sum of players average prices for all players in the watchlist</li></ul>";
const byPlayersTipMessage =
  "<ul> <li><b>Player average price</b>: average price of public transactions regarding each player in a given time frame</li><li><b>Cumulative value by player</b>: breakdown of total cumulative value by player, showing the relative weight of each player</li></ul>";

function WatchlistPlayersPriceEvolution(props) {
  const watchlist = props.watchlist;
  const [loading, setLoading] = useState(false);
  const connectedUserIsOwner = watchlist.owner === props.user.sorareSlug;

  const [showSortMenu, setShowSortMenu] = useState(false);

  const [period, setPeriod] = useState("1m");
  const [scarcity, setScarcity] = useState(props.user.preferredScarcity || "LIMITED");
  const [currency, setCurrency] = useState(props.user.preferredUnit || "eth");
  const [sortBy, setSortBy] = useState("averagePriceGrowth desc");

  const [priceHistory, setPriceHistory] = useState([]);
  const [players, setPlayers] = useState(undefined);
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [averagePriceByCard, setAveragePriceByCard] = useState(undefined);
  const [cumulativePrice, setCumulativePrice] = useState(undefined);
  const [globalGraphDisplay, setGlobalGraphDisplay] = useState("average"); // vs cumulative
  const [playerGraphDisplay, setPlayerGraphDisplay] = useState("average"); // vs cumulative

  const displayPlayerCharts = players?.find((p) => p.priceHistory != undefined) ? true : false;

  useEffect(() => {
    getPriceEvolution();
    ReactTooltip.rebuild();
  }, [period, scarcity, currency, sortBy]);

  useEffect(() => {
    getPriceEvolution();
  }, [watchlist]);

  const getPriceEvolution = () => {
    if (watchlist.id) {
      setLoading(true);
      setAveragePriceByCard(undefined);
      setCumulativePrice(undefined);
      const params = new URLSearchParams({
        period: period,
        currency: currency,
        scarcity: scarcity,
        sortBy: sortBy,
      });
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}/players/priceEvolution?${params.toString()}`)
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setPlayers(res.players || []);
            setDisplayedPlayers(res.players ? res.players.slice(0, 50) : []);
            setPriceHistory(res.history || []);
            setAveragePriceByCard(res.averagePriceByCard || 0);
            setCumulativePrice(res.cumulativePrice || 0);
          }
          setLoading(false);
          ReactTooltip.rebuild();
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
            ReactTooltip.rebuild();
          }),
        );
    }
  };

  const sortMenuItems = Object.keys(sortByLabels).map((key) => {
    const label = sortByLabels[key];
    return {
      key: key,
      label: label,
      className: "w-full sm:w-72 cursor-pointer " + (sortBy === key ? "font-semibold" : ""),
      onClick: () => {
        setShowSortMenu(false);
        setSortBy(key);
      },
    };
  });

  return (
    <div className={"h-full"}>
      <ReactTooltip />
      <div className={"flex flex-col lg:flex-row justify-between"}>
        <h2 className={"p-0 m-0"}>Price evolution</h2>
        <div className={"flex flex-col sm:flex-row gap-2"}>
          <div className={"flex"}>
            <PeriodPicker period={period} onChange={(p) => setPeriod(p)} />
          </div>
          <div className={"flex"}>
            <ScarcityPicker scarcity={scarcity} onChange={(s) => setScarcity(s)} />
          </div>
          <div className={"flex"}>
            <CurrencyPicker currency={currency} onChange={(c) => setCurrency(c)} />
          </div>
        </div>
      </div>
      <div className={"bg-focus mx-auto mt-2 rounded-xl pr-4 py-4 mb-4"}>
        <div className={"mt-0 ml-5 mb-3 flex flex-row justify-between"}>
          <div className={"flex flex-row gap-1"}>
            <h3 className={"m-0 p-0 text-xl font-semibold font-headers"}>Total</h3>
            <span className={"h-4 w-4 my-auto"}>
              <HelpTip tip={totalTipMessage} id={"watchlist-total-hint"} html={true} />
            </span>
          </div>
          <div>{loading && <Spinner />}</div>
        </div>
        <div className={"mt-0 ml-5 mb-3 flex flex-row justify-between border-b border-grey-e5"}>
          <div className={"flex flex-row gap-5"}>
            <h4
              onClick={() => setGlobalGraphDisplay("average")}
              className={`font-headers text-md h-full mb-3 mt-0 cursor-pointer hover:opacity-90 ${
                globalGraphDisplay === "average" ? "text-brand border-b-4 border-brand" : "text-textGrey4 hover:text-brand"
              }`}
            >
              Average price
            </h4>
            <h4
              onClick={() => setGlobalGraphDisplay("cumulative")}
              className={`font-headers text-md h-full mb-3 mt-0 cursor-pointer hover:opacity-90 ${
                globalGraphDisplay === "cumulative" ? "text-brand border-b-4 border-brand" : "text-textGrey4 hover:text-brand"
              }`}
            >
              Cumulative value
            </h4>
          </div>
          {(scarcity === "SUPER RARE" || scarcity === "UNIQUE") && (
            <p className={"px-2 text-sm text-center text-textGrey3 italic"}>
              Super Rare and Unique cards are illiquid. Trading history may not be representative of fair market value at any point in time
            </p>
          )}
        </div>
        <div className={" gap-y-1 flex flex-col md:grid md:grid-rows-none md:grid-cols-8 justify-between"}>
          <div className={"relative md:col-span-6 xl:col-span-7 pr-3"}>
            <AverageHistoryDiagram
              history={priceHistory}
              players={players}
              dataKey={globalGraphDisplay}
              scarcity={scarcity}
              currency={currency}
              cumulativePrice={cumulativePrice}
              averagePriceByCard={averagePriceByCard}
            />
          </div>
          <div
            className={"md:col-span-2 xl:col-span-1 flex flex-row w-full md:w-auto md:flex-col justify-evenly gap-4 p-2"}
            style={{ minWidth: "fit-content" }}
          >
            <div className={"flex flex-col w-full gap-2 px-6 py-6 self-center rounded-md bg-grey-f2 text-md"}>
              <div className={"text-xl font-semibold self-center"} style={{ color: scarcities_colors[scarcity.toLowerCase()] }}>
                {averagePriceByCard === undefined ? <>{"---"}</> : <>{formatAllCurrencies(averagePriceByCard, currency)}</>}{" "}
              </div>
              <div className={"text-md text-textGrey3 self-center font-semibold"}>Average price by card</div>
            </div>
            <div className={"flex flex-col w-full gap-2 px-6 py-6 self-center rounded-md bg-grey-f2 text-md"}>
              <div className={"text-xl font-semibold self-center"} style={{ color: scarcities_colors[scarcity.toLowerCase()] }}>
                {cumulativePrice === undefined ? <>{"---"}</> : <>{formatAllCurrencies(cumulativePrice, currency)}</>}{" "}
              </div>
              <div className={"text-md text-textGrey3 self-center font-semibold"}>Cumulative price</div>
            </div>
          </div>
        </div>
      </div>
      {displayPlayerCharts ? (
        <div className={"bg-focus mx-auto mt-2 rounded-xl pr-4 py-4 mb-4"}>
          <div className={"mt-0 ml-5 mb-3 flex flex-row justify-between"}>
            <div className={"flex flex-row gap-1"}>
              <h3 className={"m-0 p-0 text-xl font-semibold font-headers"}>By players</h3>
              <span className={"h-4 w-4 my-auto"}>
                <HelpTip tip={byPlayersTipMessage} id={"watchlist-by-players-hint"} html={true} />
              </span>
            </div>
            <div>{loading && <Spinner />}</div>
          </div>
          <div className={"mt-0 ml-5 mb-3 flex flex-row justify-between border-b border-grey-e5"}>
            <div className={"flex flex-row gap-5"}>
              <h4
                onClick={() => setPlayerGraphDisplay("average")}
                className={`font-headers text-md h-full mb-3 mt-0 cursor-pointer hover:opacity-90 ${
                  playerGraphDisplay === "average" ? "text-brand border-b-4 border-brand" : "text-textGrey4 hover:text-brand"
                }`}
              >
                Average price
              </h4>
              <h4
                onClick={() => setPlayerGraphDisplay("cumulative")}
                className={`font-headers text-md h-full mb-3 mt-0 cursor-pointer hover:opacity-90 ${
                  playerGraphDisplay === "cumulative" ? "text-brand border-b-4 border-brand" : "text-textGrey4 hover:text-brand"
                }`}
              >
                Cumulative value
              </h4>
            </div>
            {(scarcity === "SUPER RARE" || scarcity === "UNIQUE") && (
              <p className={"px-2 text-sm text-center text-textGrey3 italic"}>
                Super Rare and Unique cards are illiquid. Trading history may not be representative of fair market value at any point in
                time
              </p>
            )}
          </div>
          <PlayerValueDiagramAndLegend scarcity={scarcity} players={players} currency={currency} dataKey={playerGraphDisplay} />
        </div>
      ) : (
        <>
          {isFree(props.user.tier) || isTier1(props.user.tier) ? (
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-watchlists-price bg-cover h-upgrade-banner-l w-full my-4"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock detailed price evolution by player"}
            />
          ) : null}
        </>
      )}
      <div>
        <div className={"relative mb-4 flex flex-row justify-between"}>
          <div className={"text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
            Sort by: {sortByLabels[sortBy]}
            <span className={"ml-2 my-auto"}>
              <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
            </span>
          </div>
          {loading && <Spinner style={{ height: "5px" }} />}
          <StandaloneMenu
            className={"top-8 left-0 overflow-y-auto max-h-60 py-2"}
            show={showSortMenu}
            onClickOutside={() => setShowSortMenu(false)}
            items={sortMenuItems}
          />
        </div>
      </div>
      <div>
        {players !== undefined && (
          <InfiniteScroll
            next={() => setDisplayedPlayers(players.slice(0, displayedPlayers.length + 50))}
            hasMore={displayedPlayers.length < players.length}
            loader={<SDLoading />}
            dataLength={displayedPlayers.length}
          >
            <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 2xl:gap-y-8"}>
              {displayedPlayers.map((r) => {
                return <PlayerPriceCard key={r.player.PlayerId} period={period} player={r} scarcity={scarcity} currency={currency} />;
              })}
            </div>
          </InfiniteScroll>
        )}
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withRouter(withUser(WatchlistPlayersPriceEvolution));
