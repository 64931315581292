import React from "react";
import { withUser } from "../../userContext";
import { formatPrice } from "../util/formatMoney";

function RisingPlayersBox(props) {
  const leader = props.players[0];
  const titleComponent = (
    <p className={"text-on-surface font-semibold text-sm"}>
      <span>{props.title}</span>
      <span className={"text-on-surface-variant"}>{props.period}</span>
    </p>
  );
  return (
    <div className={"rounded-lg bg-surface-container flex flex-col pl-6 space-y-3 py-4 col-span-1"}>
      {props.link ? (
        <a className={"hover:font-bold hover:text-primary"} href={props.link}>
          {titleComponent}
        </a>
      ) : (
        titleComponent
      )}
      {props.players.length > 0 && (
        <div className={"space-y-4"}>
          <div className={"flex flex-row space-x-3"}>
            <div>
              <div className={"object-contain rounded-lg h-10 w-8 overflow-hidden flex items-center justify-center"}>
                <a href={"player/" + leader.PlayerId} target={"_blank"} rel="noreferrer">
                  <img className={"object-contain"} src={leader.Avatar} />
                </a>
              </div>
            </div>
            <div className={"self-center text-sm"}>
              <a className={"hover:font-semibold font-medium"} href={"player/" + leader.PlayerId} target={"_blank"} rel="noreferrer">
                <span>#1 - {leader.DisplayName}</span>
              </a>
              <p className={leader.percentage > 0 ? "text-sm text-success-green" : "text-sm text-horrible-red"}>
                {leader.percentage.toFixed(0)} % ({formatPrice(leader["average_" + props.user.preferredUnit], props.user.preferredUnit)})
              </p>
            </div>
          </div>
          <div className={"space-y-0.5"}>
            {props.players.slice(1, 10).map((p, rank) => {
              return (
                <div className={"text-tiny"}>
                  <a className={"hover:font-semibold"} href={"player/" + p.PlayerId} target={"_blank"} rel="noreferrer">
                    <p>
                      <span className={"font-semibold"}>#{rank + 2} </span> - {p.DisplayName} -{" "}
                      <span className={leader.percentage > 0 ? "text-success-green" : "text-horrible-red"}>
                        {p.percentage.toFixed(0)} % ({formatPrice(p["average_" + props.user.preferredUnit], props.user.preferredUnit)})
                      </span>
                    </p>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default withUser(RisingPlayersBox);
