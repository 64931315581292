import React, { useEffect, useState } from "react";
import useComponentVisible from "./outsideAlerter";
import { Range } from "rc-slider";
import SelectSearch from "react-select-search";
import Locker from "../../img/brand-close-locker-nude.svg";
import { t1OrBelow, t2OrAbove } from "./handleSubscriptionTier";
import { withUser } from "../../userContext";

function SO5UtilityFilter(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [value, setValue] = useState([0, 100]);
  const [valuePlayed, setValuePlayed] = useState([0, 100]);
  const [valueStarted, setValueStarted] = useState([0, 100]);
  const [whatToShow, setWhatToShow] = useState("utility");

  const onSliderChange = (value) => {
    setValue(value);
  };

  const onSliderPlayedChange = (value) => {
    setValuePlayed(value);
  };

  const onSliderStartedChange = (value) => {
    setValueStarted(value);
  };

  useEffect(() => {
    setValue([value[0], props.limit]);
  }, [props.limit]);

  const filterClass = "flex flex-row rounded-md bg-white py-2 shadow cursor-pointer ";
  return (
    <div>
      <div
        className={t2OrAbove(props.user.tier) ? filterClass : filterClass + " opacity-50"}
        onClick={() => {
          if (t2OrAbove(props.user.tier)) {
            setIsComponentVisible(!isComponentVisible);
          }
        }}
        data-tip={t1OrBelow(props.user.tier) ? "Star membership is needed" : ""}
      >
        <p className={"text-sm px-4"}>SO5 utility filter</p>
        {t1OrBelow(props.user.tier) && <img className="h-4 w-4 -ml-1 mr-1" alt="" src={Locker} />}
      </div>
      {isComponentVisible && (
        <div ref={ref} className={"bg-white mt-2 absolute w-80 z-10 py-4 rounded-xl shadow"}>
          <div className={"w-10/12 mx-auto space-y-4 text-base"}>
            <div>
              <p className={"text-sm font-semibold"}>SO5 pick % when player is SO5 eligible</p>
              <div className={""}>
                <Range
                  trackStyle={[{ backgroundColor: "#f45206" }]}
                  handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                  step={1}
                  value={value}
                  min={0}
                  max={props.limit}
                  allowCross={false}
                  onChange={onSliderChange}
                />
                <div className={"justify-between flex flex-row text-base font-semibold"}>
                  <span>{value[0]}</span>
                  <span>{value[1]}</span>
                </div>
              </div>
            </div>
            <div>
              <p className={"text-sm font-semibold"}>SO5 pick % when player plays a game</p>
              <div className={""}>
                <Range
                  trackStyle={[{ backgroundColor: "#f45206" }]}
                  handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                  step={1}
                  value={valuePlayed}
                  min={0}
                  max={props.limit}
                  allowCross={false}
                  onChange={onSliderPlayedChange}
                />
                <div className={"justify-between flex flex-row text-base font-semibold"}>
                  <span>{valuePlayed[0]}</span>
                  <span>{valuePlayed[1]}</span>
                </div>
              </div>
            </div>
            <div>
              <p className={"text-sm font-semibold"}>SO5 pick % when player starts a game</p>
              <div className={""}>
                <Range
                  trackStyle={[{ backgroundColor: "#f45206" }]}
                  handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                  step={1}
                  value={valueStarted}
                  min={0}
                  max={props.limit}
                  allowCross={false}
                  onChange={onSliderStartedChange}
                />
                <div className={"justify-between flex flex-row text-base font-semibold"}>
                  <span>{valueStarted[0]}</span>
                  <span>{valueStarted[1]}</span>
                </div>
              </div>
            </div>
            <div>
              <p className={"text-sm font-semibold"}>Display setting</p>
              <SelectSearch
                onChange={(v) => setWhatToShow(v)}
                value={whatToShow}
                printOptions={"on-focus"}
                options={[
                  { value: "utility", name: "SO5 pick % when eligible" },
                  { value: "played", name: "SO5 pick % when plays" },
                  { value: "started", name: "SO5 pick % when starts" },
                  { value: "next_game", name: "Next gameweek game" },
                ]}
              />
            </div>

            <div className={"mx-auto text-center"}>
              <div
                className={"bg-brand px-2 py-2 w-24 mx-auto rounded-3xl cursor-pointer font-semibold hover:font-bold"}
                onClick={() => {
                  setIsComponentVisible(false);
                  props.powerOptions(value, valuePlayed, valueStarted, whatToShow);
                }}
              >
                <span className={"text-white"}>Apply</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withUser(SO5UtilityFilter);
