import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { sorareFootball } from "../util/sports";
import { errorCatcher } from "../util/errors";
import SelectSearch from "react-select-search";
import { covered_leagues_objects } from "../util/leagues";
import LineSeparator from "../../img/line-separator.svg";
import HomeIcon from "../../img/homeIcon.svg";
import AwayIcon from "../../img/awayIcon.svg";
import { detailedPositionAbreviations, detailedPositionFullName } from "../util/detailedFootballPositions";
import { ReactTooltip } from "../util/tooltip.js";

const specificColorScale = [30, 40, 45, 50, 55];

const TeamOppScores = (props) => {
  const { team } = props;
  const [oppScores, setOppScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [home, setHome] = useState(true);
  const [away, setAway] = useState(true);
  useEffect(() => {
    setLoading(true);
    props
      .fetch("/apiv2/teams/detailedOppScore/" + team)
      .then((response) => response.json())
      .then((res) => {
        if (res?.error === undefined) {
          setOppScores(res);
          const leagues = Object.keys(res);
          if (leagues.length > 0 && selectedLeague === "") {
            setSelectedLeague(leagues[0]);
          }
        }
      })
      .catch(errorCatcher())
      .finally(() => setLoading(false));
  }, []);

  const teamLeagues = oppScores ? Object.keys(oppScores) : undefined;
  const leagueOptions = covered_leagues_objects.filter((league) => teamLeagues && teamLeagues.includes(league.value)) || [];

  const fieldHeight = "h-5-line-field";
  const fieldRound = true;
  const sport = sorareFootball;
  const homeAway = (home && away) || (!home && !away) ? "all" : home ? "home" : "away";
  const colorScale = specificColorScale;
  const oppScoreInfo = oppScores[selectedLeague]?.[homeAway].oppScore;
  return (
    <div className="z-20">
      <h2>Opponent Score</h2>
      <div className={"flex flex-row gap-2 z-20"}>
        <SelectSearch
          className={"select-search z-20"}
          options={leagueOptions}
          value={selectedLeague}
          onChange={(value) => setSelectedLeague(value)}
        />
        <div className={"bg-white rounded py-1.5 space-x-2 px-3 flex flex-row self-end"}>
          <div className={"w-6 h-6 self-center"} onClick={() => setHome(!home)}>
            <img
              alt=""
              data-tip={"Select games at home"}
              src={HomeIcon}
              className={home ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"}
            />
          </div>
          <img alt="" src={LineSeparator} />
          <div className={"w-6 h-6 self-center"} onClick={() => setAway(!away)}>
            <img
              alt=""
              data-tip={"Select games away"}
              src={AwayIcon}
              className={away ? "w-full h-full object-contain cursor-pointer" : "w-full h-full object-contain cursor-pointer opacity-30"}
            />
          </div>
        </div>
      </div>
      <div className={"flex lg:flex-row flex-col space-y-8 lg:space-x-8 lg:max-w-full"}>
        <ReactTooltip />
        <div className={"w-full lg:w-6/12 space-y-4 py-5"}>
          <div className={"relative rounded-xl overflow-hidden"}>
            <div className={fieldHeight + " w-full absolute flex flex-col z-10"}>
              <div className={"h-full flex flex-col items-center justify-evenly w-full h-full gap-5"}>
                <div className={"flex flex-row justify-center w-full h-1/8"}>
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"gk"} />
                </div>
                <div className={"grid grid-cols-4 justify-center w-full self-center w-full h-1/8"}>
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"rightDef"} />
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"centreDef"} />
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"centreDef"} />
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"leftDef"} />
                </div>
                <div className={"flex flex-col justify-evenly w-full h-full gap-0.5 h-1/2"}>
                  <div className={"grid grid-cols-3 justify-center w-full self-center w-3/4"}>
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"rightDefMid"} />
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"centreDefMid"} />
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"leftDefMid"} />
                  </div>
                  <div className={"grid grid-cols-2 justify-center w-full self-center w-1/2"}>
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"centreMid"} />
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"centreMid"} />
                  </div>
                  <div className={"flex justify-between self-center w-4/5"}>
                    <SquaredScore colorScale={colorScale} info={oppScoreInfo} position={"rightMid"} />
                    <SquaredScore colorScale={colorScale} info={oppScoreInfo} position={"leftMid"} />
                  </div>
                  <div className={"grid grid-cols-3 justify-center w-full self-center w-3/4"}>
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"rightAttMid"} />
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"centreAttMid"} />
                    <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"leftAttMid"} />
                  </div>
                </div>
                <div className={"grid grid-cols-3 justify-center w-full self-center w-full h-1/8"}>
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"rightFwd"} />
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"centreFwd"} />
                  <SquaredScore colorScale={colorScale} center info={oppScoreInfo} position={"leftFwd"} />
                </div>
              </div>
            </div>
            <div className={"z-0 flex flex-col " + fieldHeight}>
              <div
                className={
                  fieldRound ? "bg-game-field-opacity " + fieldHeight + " w-full" : "bg-game-field-borders " + fieldHeight + " w-1/12"
                }
              />
              <div className={"absolute border-4 border-t-0 border-white/10 z-5 h-20 self-center justify-center w-3/12 rounded-b-lg"} />
              <div className={"absolute border-4 border-t-0 border-white/10 z-5 h-48 self-center justify-center w-6/12 rounded-b-lg"} />
              <div className={"bg-game-field-borders " + fieldHeight + " w-full"} />
              <div className={"bg-game-field-opacity " + fieldHeight + " w-full"} />
              <div className={"bg-game-field-borders " + fieldHeight + " w-full flex flex-row"}>
                <div className={"absolute flex flex-row justify-center pr-4 w-full h-2/12 self-center"}>
                  <div
                    className={"border-4 flex border-white/10 align-middle mx-auto z-0 h-56 rounded-full self-center justify-center w-56 "}
                  />
                </div>
                <div className={"absolute border-2 flex border-white/10 flex-row justify-center pr-4 w-full h-1 self-center"}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={"w-full lg:w-6/12 space-y-4"}></div>
      </div>
    </div>
  );
};

const SquaredScore = (props) => {
  const { colorScale, sport = sorareFootball, info, center = false, size = "w-10 h-10", precision = 1, position } = props;
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);
  if (info === undefined || info === null || info === "") {
    return null;
  }
  const infoForPosition = info[position];
  let score = infoForPosition?.Average;
  if (score === undefined || score === null || score === "") {
    return null;
  }
  const floatSO5Score = parseFloat(score);
  if (score >= 99.95) {
    score = 100;
  } else {
    score = score.toFixed(precision);
  }
  let color = "";
  let textColor = "text-white";
  if (floatSO5Score <= 0 || isNaN(floatSO5Score)) {
    color = "bg-textGrey4";
    score = "-";
    textColor = "text-white";
  } else if (floatSO5Score < colorScale[0]) {
    color = "bg-horrible-score";
  } else if (floatSO5Score < colorScale[1]) {
    color = "bg-bad-score";
  } else if (floatSO5Score < colorScale[2]) {
    color = "bg-ok-score";
  } else if (floatSO5Score < colorScale[3]) {
    color = "bg-good-score";
  } else if (floatSO5Score < colorScale[4]) {
    color = "bg-great-score";
  } else if (floatSO5Score <= 1000) {
    color = "bg-amazing-score";
  }
  const detailedPositionAbbr = detailedPositionAbreviations[position];
  const detailedFullPosition = detailedPositionFullName[position];
  const title = `${detailedPositionAbbr} - ${detailedFullPosition}: the team has played against ${infoForPosition.Nb} ${detailedPositionAbbr} player(s) producing an average of ${score}`;
  return (
    <div
      className={`${center ? "mx-auto" : ""} shadow-lg rounded-md flex flex-row justify-center items-center ${size} ${color}`}
      data-tip={title}
    >
      <p className={`font-semibold text-focus ${textColor} text-md`}>{score}</p>
    </div>
  );
};

export default withUser(TeamOppScores);
