import { formatPrice } from "./formatMoney";

export function formatValuationHistory(rt, unit) {
  if (rt.Valuation === -1) {
    return "No sale";
  }
  if (unit === "eth") {
    return formatPrice(rt.Valuation, "eth");
  } else {
    let price;
    if (unit === "€" || unit === "eur") {
      unit = "eur";
      price = rt["Eur"];
    } else if (unit === "$" || unit === "usd") {
      unit = "usd";
      price = rt["Usd"];
    } else if (unit === "£" || unit === "gbp") {
      unit = "gbp";
      price = rt["Gbp"];
    }
    let max = 2;
    if (price >= 100) max = 0;
    if (price >= 1000) max = 0;
    if (unit === "usd") {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } else if (unit === "eur") {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } else if (unit === "gbp") {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    }
  }
}
