import * as React from "react";

const Eye = (props) => {
  let color = props.color || "#E0E0E0";
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 19.0008C10.3599 19.0213 8.7367 18.6673 7.254 17.9658C6.10469 17.4051 5.07265 16.6306 4.213 15.6838C3.30243 14.705 2.58547 13.5625 2.1 12.3168L2 12.0008L2.105 11.6848C2.59082 10.4403 3.30624 9.2981 4.214 8.31783C5.07334 7.37115 6.10504 6.5967 7.254 6.03583C8.73671 5.33442 10.3599 4.98044 12 5.00083C13.6401 4.98048 15.2633 5.33446 16.746 6.03583C17.8953 6.59657 18.9274 7.37103 19.787 8.31783C20.6993 9.29539 21.4165 10.4382 21.9 11.6848L22 12.0008L21.895 12.3168C20.3262 16.4006 16.3742 19.0702 12 19.0008ZM12 7.00083C8.59587 6.89416 5.47142 8.87593 4.117 12.0008C5.4712 15.1259 8.59579 17.1078 12 17.0008C15.4041 17.1072 18.5284 15.1255 19.883 12.0008C18.5304 8.87441 15.4047 6.89191 12 7.00083ZM12 15.0008C10.5573 15.0104 9.30937 13.9982 9.02097 12.5846C8.73256 11.171 9.48427 9.75086 10.8154 9.1945C12.1465 8.63813 13.6852 9.10097 14.4885 10.2993C15.2919 11.4977 15.1354 13.0969 14.115 14.1168C13.5563 14.6821 12.7948 15.0004 12 15.0008Z"
        fill={color}
      />
    </svg>
  );
};
export default Eye;
