import React, { useEffect, useState } from "react";
import { Text, Box, DataTable } from "grommet";
import PlayerLink from "../players/playerLink";
import CardLink from "../cards/cardLink";
import Countdown from "react-countdown-now";
import Spinner from "../loader/spinner";
import { getSdScoreColor } from "../util/sdScoreColor";
import { withUser } from "../../userContext";
import { formatPrice, findPriceForUserUnit, checkSecondUnitShouldBeDisplayed } from "../util/formatMoney";
import { errorCatcher } from "../util/errors";

function AuctionWrapperDataTable(props) {
  const [auctions, setAuctions] = useState([]);
  const [noAuction, setNoAuction] = useState(false);
  const [priceInfo, setPriceInfo] = useState({});

  const shouldDisplaySecondUnit = checkSecondUnitShouldBeDisplayed(props.user.preferredUnit, props.user.secondUnit);

  const getAuctions = () => {
    let toFetch = 200;
    let nbAuctions = 0;
    props
      .fetch("/apiv2/auctions/searchAuctions", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          limit: toFetch,
          from: nbAuctions,
          gwNumber: 0,
          u23: false,
          scarcity: "all",
          league: "all",
          position: "all",
          status: "ENDED",
          order: "desc",
          start_date: props.startDate,
          end_date: props.endDate,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setAuctions(res);
      })
      .catch(errorCatcher());
  };

  const getPlayerAuctions = () => {
    let tmp = "ongoingAuctions";
    if (props.tmp === "last") tmp = "endedAuctions";
    props
      .fetch("/apiv2/players/" + tmp + "/" + props.player.PlayerId)
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        setPriceInfo(res.price_info);
        setAuctions(res.auctions);
        setNoAuction(res.auctions === null || res.auctions.length === 0);
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (props.player !== undefined) {
      getPlayerAuctions();
    } else {
      if (props.auctions === undefined) {
        if (props.tmp === "next") {
          this.interval = setInterval(() => getAuctions(), 30000);
        }
        getAuctions();
      } else {
        setAuctions(props.auctions);
        setNoAuction(props.auctions.length === 0);
      }
    }
  }, [props.auctions]);

  let dateLabel = "Countdown   ";
  if (props.tmp === "last") {
    dateLabel = "Auction ended on";
  }
  let averageSortable = true;
  if (props.tmp !== undefined) averageSortable = false;
  let columns = [];
  columns.push(
    {
      property: "auctionId",
      header: <p className={"text-base"}>Player</p>,
      sortable: false,
      primary: true,
      render: (d) => (
        <p>
          <PlayerLink player={d.player} /> - <CardLink sport={d.player.Sport} card={d.card} />
        </p>
      ),
    },
    {
      property: "playerAge",
      header: <p className={"text-base"}>Age</p>,
      render: (d) => <span className={"text-base"}>{d.playerAge}</span>,
    },
    {
      property: "position",
      header: <p className={"text-base"}>P</p>,
      render: (d) => <p className={"text-base"}>{d.position.charAt(0)}</p>,
    },
  );
  columns.push({
    property: "current",
    header: <p className={"text-base"}>Price</p>,
    render: (d) => {
      return (
        <p className={"text-lg font-bold"}>
          {findPriceForUserUnit(d.current, d.currentPriceFiat, props.user.preferredUnit)}{" "}
          {shouldDisplaySecondUnit && (
            <span className={"font-medium text-sm"}>({findPriceForUserUnit(d.current, d.currentPriceFiat, props.user.secondUnit)})</span>
          )}
        </p>
      );
    },
  });
  if (!props.noStats) {
    columns.push(
      {
        property: "so5averageL5",
        header: (
          <p className={"text-base text-center"} title={"SO5 average last 5 games"}>
            L5
          </p>
        ),
        render: (d) => {
          let color = getSdScoreColor(d.so5averageL5);
          return <p className={"text-center text-base font-semibold text-" + color + "-start"}>{d.so5averageL5.toFixed(1)}</p>;
        },
      },
      {
        property: "so5averageL20",
        header: (
          <p className={"text-base"} title={"SO5 average last 20 games"}>
            L15
          </p>
        ),
        render: (d) => {
          let value = d.so5averageL20.toFixed(1);
          let color = getSdScoreColor(d.so5averageL20);
          if (d.so5averageL20 === -1) {
            value = "NA";
            color = "brand-black";
          }
          return <p className={"text-center text-base font-semibold text-" + color + "-start"}>{value}</p>;
        },
      },
    );
  }

  if (!props.noStats) {
    columns.push({
      property: "estimatedValue",
      header: (
        <p className={"text-base"} title={"Real-time card average price"}>
          RT price
        </p>
      ),
      sortable: averageSortable,
      render: (d) => (
        <p className={"text-base text-center"}>
          <span className={"font-medium text-sm"}>
            {formatPrice(d.value * d.priceInfo[props.user.preferredUnit], props.user.preferredUnit)}
          </span>
        </p>
      ),
    });
  }
  columns.push({
    property: "endDate",
    header: <p className={"text-base"}>{dateLabel}</p>,
    render: (d) => {
      let dateToDisplay = new Date(d.endDate);
      const Completionist = () => {
        return <span className={"text-base"}>{dateToDisplay.toLocaleString()}</span>;
      };
      return (
        <div className={"text-base"}>
          <Text textAlign={"center"}>
            {" "}
            <Countdown daysInHours={true} date={dateToDisplay}>
              <Completionist />
            </Countdown>
          </Text>
        </div>
      );
    },
  });

  columns.push({
    property: "auction",
    render: (d) => (
      <div>
        <a
          className={"text-base text-brand font-semibold hover:font-bold"}
          href={"/auction/" + d.auctionId}
          target={"_blank"}
          rel="noreferrer"
        >
          Details
        </a>
      </div>
    ),
  });

  return (
    <Box>
      {auctions !== null && auctions.length > 0 ? (
        <DataTable
          step={50}
          sortable
          columns={columns}
          data={auctions.map((auction, index) => ({
            key: auction.auction.auctionId,
            player: auction.player,
            position: auction.card.Position,
            playerAge: auction.player.Age,
            auctionId: auction.auction.AuctionId,
            card: auction.card,
            serialNumber: auction.card.SerialNumber,
            current: parseFloat(auction.auction.CurrentPrice),
            currentPriceFiat: auction.current_price_fiat,
            price: parseFloat(auction.auction.CurrentPrice).toFixed(3) + " Ξ",
            endDate: new Date(auction.auction.EndingDate).getUTCSeconds() > 0 ? auction.auction.EndingDate : auction.auction.EndingAt,
            auction: auction.auction,
            type: auction.auction.PublicOffer,
            so5averageL5: auction.avg5,
            so5averageL20: auction.avg20,
            appsCurrentSeason: auction.apps_current_season,
            apps2018: auction.apps_2018,
            value: auction.r30,
            eur: auction.eur,
            gbp: auction.gbp,
            usd: auction.usd,
            unit: props.user.preferredUnit ? props.user.preferredUnit : "eth",
            priceInfo: auction.priceInfo,
          }))}
        />
      ) : noAuction === true ? (
        <p className={"text-center"}>
          <em>No auction available.</em>
        </p>
      ) : (
        <Spinner />
      )}
    </Box>
  );
}

export default withUser(AuctionWrapperDataTable);
