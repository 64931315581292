import React, { useEffect, useState } from "react";
import Close from "../../img/close.svg";
import NewCurrencyPicker from "../util/newCurrencyPicker";
import { withUser } from "../../userContext";
import InfoIcon from "../../img/info-modal-black.svg";
import Toggle from "../util/toggle";
import Lock from "../../img/lock.svg";
import Edit from "../../img/edit.svg";
import Sell from "../../img/sell.svg";
import SellRed from "../../img/sell-red.svg";
import Dynamic from "../../img/dynamic.svg";
import { formatPrice } from "../util/formatMoney";
import { errorCatcher } from "../util/errors";
import { getLowestValuation } from "../util/lowestValuation";
import Modal from "../util/modal";
import { Button } from "../util/button";

function GridButton(props) {
  const selected = props.value === props.selected;
  return (
    <div
      onClick={() => props.change(props.value)}
      className={
        selected
          ? "rounded-full bg-brand text-white text-sm font-semibold px-2 py-1.5"
          : "rounded-full border border-black-4D text-black-4D text-sm font-semibold px-2 py-1.5 cursor-pointer"
      }
    >
      <p className={"text-center"}>{props.text}</p>
    </div>
  );
}

function MarketStatusPopover(props) {
  const [inputValue, setInputValue] = useState("");
  const [theCurrency, setCurrency] = useState(props.user.preferredUnit);
  const [selected, setSelected] = useState("");
  const [openToOffers, setOpenToOffers] = useState(false);

  const [showPopover, setShowPopover] = useState(false);

  const getTag = (tokenId) => {
    props
      .fetch("/apiv2/cards/getCardTag?tokenId=" + tokenId)
      .then((response) => response.json())
      .then((res) => {
        if (res.tokenId !== "") {
          setSelected(res.status);
          if (res.status !== "NOT_ON_SALE") {
            setOpenToOffers(true);
            setCurrency(res.currency);
          }
          if (res.status === "FIXED") {
            setInputValue(formatPrice(res.price, res.currency));
            setCurrency(res.currency);
          } else if (res.status.includes("DYN")) {
            setSelected(res.status);
            changeStatus(res.status, true, res.currency);
          }
          if (res.currency === "" || res.status === "") {
            setCurrency(props.user.preferredUnit);
          }
        }
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (props.modal) {
      return;
    }
    if (!props.inline) {
      getTag(props.card.TokenId);
    } else {
      const tag = props.tag;
      if (tag.tokenId !== "") {
        setSelected(tag.status);
        if (tag.status !== "NOT_ON_SALE") {
          setOpenToOffers(true);
          setCurrency(tag.currency);
        }
        if (tag.status === "FIXED") {
          setInputValue(formatPrice(tag.price, tag.currency));
          setCurrency(tag.currency);
        } else if (tag.status.includes("DYN")) {
          changeStatus(tag.status, true, tag.currency);
        } else if (tag.status === "NOT_ON_SALE") {
          setOpenToOffers(false);
        }
      }
      if (props.onSale) {
        setInputValue(props.offerPrice);
      }
    }
    if (props.grid) {
      setOpenToOffers(true);
    }
  }, [props.card]);

  const changeStatus = (s, r, c) => {
    let currency = c || theCurrency;
    if (props.multi) {
      if (s === selected && !r) {
        setSelected("");
        setInputValue("");
      } else {
        setSelected(s);
        if (s === "DYN_M20") {
          setInputValue("latest val. -20%");
        } else if (s === "DYN_M15") {
          setInputValue("latest val. -15%");
        } else if (s === "DYN_M10") {
          setInputValue("latest val. -10%");
        } else if (s === "DYN_M5") {
          setInputValue("latest val. -5%");
        } else if (s === "DYN_LAT") {
          setInputValue("latest valuation");
        } else if (s === "DYN_5") {
          setInputValue("latest val. +5%");
        } else if (s === "DYN_10") {
          setInputValue("latest val. +10%");
        } else if (s === "DYN_15") {
          setInputValue("latest val. +15%");
        } else if (s === "DYN_20") {
          setInputValue("latest val. +20%");
        }
        setOpenToOffers(true);
      }
    } else {
      let lowestValuation = getLowestValuation(props.rt, props.bmp, currency);
      if (s === selected && !r) {
        setSelected("");
      } else {
        setSelected(s);
        if (!s.includes("DYN") && c !== theCurrency) {
          setInputValue("");
        }
        if (s === "DYN_M20") {
          setInputValue(formatPrice(lowestValuation - lowestValuation * 0.2, currency));
        } else if (s === "DYN_M15") {
          setInputValue(formatPrice(lowestValuation - lowestValuation * 0.15, currency));
        } else if (s === "DYN_M10") {
          setInputValue(formatPrice(lowestValuation - lowestValuation * 0.1, currency));
        } else if (s === "DYN_M5") {
          setInputValue(formatPrice(lowestValuation - lowestValuation * 0.05, currency));
        } else if (s === "DYN_LAT") {
          setInputValue(formatPrice(lowestValuation, currency));
        } else if (s === "DYN_5") {
          setInputValue(formatPrice(lowestValuation + lowestValuation * 0.05, currency));
        } else if (s === "DYN_10") {
          setInputValue(formatPrice(lowestValuation + lowestValuation * 0.1, currency));
        } else if (s === "DYN_15") {
          setInputValue(formatPrice(lowestValuation + lowestValuation * 0.15, currency));
        } else if (s === "DYN_20") {
          setInputValue(formatPrice(lowestValuation + lowestValuation * 0.2, currency));
        }
        setOpenToOffers(true);
      }
    }
  };

  if (props.modal) {
    return (
      <div>
        <div
          onClick={() => setShowPopover(true)}
          className={"rounded bg-white space-x-2 px-2 py-1 shadow-sm cursor-pointer flex flex-row justify-center"}
        >
          <img src={Edit} />
          <p className={"text-sm font-semibold text-brand"}>Set price tag</p>
        </div>
        {showPopover && (
          <div>
            <Modal
              bg={"bg-white"}
              size={"w-5/12"}
              maxSize={"w-5/12"}
              open={showPopover}
              close={() => {
                setShowPopover(false);
              }}
              content={
                <div className={"font-sans bg-white"}>
                  <div className={"space-y-1"}>
                    <p className={"text-2xl font-headers font-semibold"}>Set price tag for selected cards</p>
                    <p className={"text-xs font-semibold"}>{props.selectedPlayers.length} cards selected</p>
                  </div>
                  <div className={"flex flex-row justify-start space-x-8 my-4"}>
                    <p className={"text-base font-medium font-[#1A1A1A] self-center"}>Open to offers</p>
                    <div>
                      <Toggle
                        setActive={() => {
                          if (openToOffers) {
                            setInputValue("");
                            setSelected("");
                          }
                          setOpenToOffers(!openToOffers);
                        }}
                        isActive={openToOffers}
                      />
                    </div>
                  </div>
                  <div className={"space-y-4 p-4 rounded-lg bg-[#FAFAFA]"}>
                    <div className={"space-y-1"}>
                      <p className={"text-black-4D text-sm font-medium"}>Minimum price</p>
                      <div className={"flex flex-row space-x-2"}>
                        <div className="relative rounded-lg border-0">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            {!openToOffers && <img className={"w-5 h-5"} src={Lock} />}
                            {openToOffers && inputValue === "" && <img className={"w-5 h-5"} src={Edit} />}
                            {openToOffers && inputValue !== "" && selected === "" && <img className={"w-5 h-5"} src={Sell} />}
                            {openToOffers && inputValue !== "" && selected !== "" && <img className={"w-5 h-5"} src={Dynamic} />}
                          </div>
                          <input
                            disabled={true}
                            type="text"
                            name="value"
                            id="value"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            className="block w-48 rounded-md border-0 py-2 pl-10 text-gray-900 ring-1 ring-inset ring-[#E6E6E6]
                               placeholder:text-gray-400 text-base font-semibold focus:ring-2
                               focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder={"-----"}
                          />
                        </div>
                        <div className={"self-center"}>
                          <NewCurrencyPicker
                            disabledCurrencies={
                              !openToOffers
                                ? {
                                    eur: "You're not open to offers",
                                    eth: "You're not open to offers",
                                    usd: "You're not open to offers",
                                    gbp: "You're not open to offers",
                                  }
                                : undefined
                            }
                            fontUnselected={"text-black-4D"}
                            currency={theCurrency}
                            onChange={(currency) => {
                              setCurrency(currency);
                              changeStatus(selected, true, currency);
                            }}
                            border={"border-[#E6E6E6] border rounded"}
                          />
                        </div>
                      </div>
                    </div>
                    <p className={"text-black-4D text-sm font-medium"}>Dynamic prices based on latest valuation</p>
                    <div className={"space-y-2"}>
                      <div className={"flex flex-row space-x-2"}>
                        <GridButton text={"-20%"} value={"DYN_M20"} change={changeStatus} selected={selected} />
                        <GridButton text={"-15%"} value={"DYN_M15"} change={changeStatus} selected={selected} />
                        <GridButton text={"-10%"} value={"DYN_M10"} change={changeStatus} selected={selected} />
                        <GridButton text={"-5%"} value={"DYN_M5"} change={changeStatus} selected={selected} />
                        <GridButton text={"Latest val."} value={"DYN_LAT"} change={changeStatus} selected={selected} />
                      </div>
                      <div className={"flex flex-row space-x-2"}>
                        <GridButton text={"+5%"} value={"DYN_5"} change={changeStatus} selected={selected} />
                        <GridButton text={"+10%"} value={"DYN_10"} change={changeStatus} selected={selected} />
                        <GridButton text={"+15%"} value={"DYN_15"} change={changeStatus} selected={selected} />
                        <GridButton text={"+20%"} value={"DYN_20"} change={changeStatus} selected={selected} />
                      </div>
                    </div>
                    <div className={"flex flex-row space-x-3 p-2 rounded-lg bg-white"}>
                      <div className={"self-center"}>
                        <img className={"w-8 h-8"} src={InfoIcon} />
                      </div>
                      <p className={"text-xs font-medium text-[#666666]"}>
                        Dynamic price will adjust automatically on each card based on latest valuation variations (either average or floor
                        price)
                      </p>
                    </div>
                  </div>
                  <div className={"flex flex-row justify-between mt-4"}>
                    <div className={"flex flex-col"}>
                      <p className={"text-sm text-brand font-medium"}>{props.selectedPlayers.length}</p>
                      <p className={"text-sm text-black-4D font-medium"}>cards impacted</p>
                    </div>
                    <div className={"flex flex-row space-x-2"}>
                      <div
                        onClick={() => {
                          setShowPopover(false);
                        }}
                      >
                        <Button context={"secondary"} label={"Cancel"} />
                      </div>
                      <div
                        onClick={() => {
                          props.createTags(selected, inputValue, theCurrency, openToOffers);
                          setShowPopover(false);
                        }}
                      >
                        <Button label={"Set price for all"} />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
    );
  } else if (props.inline) {
    let inputColor = "text-gray-900";
    let divOptions = "cursor-pointer";
    let cursorOptions = "cursor-pointer";
    if (props.onSale) {
      inputColor = "text-[#FF6666]";
      divOptions = "cursor-not-allowed opacity-50";
      cursorOptions = "cursor-not-allowed";
    }
    if (!props.isMe) {
      cursorOptions = "cursor-not-allowed";
    }
    return (
      <>
        <div
          className={"relative rounded-lg border-0 " + divOptions}
          onClick={() => {
            if (props.isMe && !props.onSale) {
              setShowPopover(true);
              setOpenToOffers(true);
            }
          }}
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {(selected === "" || inputValue === "") && !props.onSale && <img className={"w-5 h-5"} src={Edit} />}
            {props.onSale && <img className={"w-5 h-5"} src={SellRed} />}
            {openToOffers && inputValue !== "" && selected.includes("FIXED") && !props.onSale && <img className={"w-5 h-5"} src={Sell} />}
            {openToOffers && inputValue !== "" && selected.includes("DYN") && !props.onSale && <img className={"w-5 h-5"} src={Dynamic} />}
          </div>
          <input
            disabled={!props.isMe || props.onSale || selected.includes("DYN")}
            type="text"
            name="value"
            id="value"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={
              "block w-32 " +
              cursorOptions +
              " rounded-md border-0 py-2 pl-10 ring-1 ring-inset " +
              "ring-[#E6E6E6] placeholder:text-gray-400 text-base font-semibold focus:ring-2 " +
              "focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
              inputColor
            }
            placeholder={props.isMe ? "Set price" : "-----"}
          />
        </div>
        {showPopover && (
          <div className={"bg-white rounded p-4 absolute z-50 mt-12 origin-bottom space-y-4 w-96 shadow-lg"}>
            <div className={"flex flex-row justify-between w-full"}>
              <p className={"text-xl font-headers font-semibold"}>Market Status</p>
              <div className={"self-center"}>
                <img
                  className={"w-4 h-4 cursor-pointer"}
                  onClick={() => {
                    props.createTag(selected, inputValue, theCurrency, openToOffers);
                    setShowPopover(false);
                    props.close();
                  }}
                  src={Close}
                />
              </div>
            </div>
            <div className={"space-y-2"}>
              <p className={"text-black-4D text-sm font-medium"}>Dynamic prices based on latest valuation</p>
              <div className={"flex flex-row space-x-2"}>
                <GridButton text={"-20%"} value={"DYN_M20"} change={changeStatus} selected={selected} />
                <GridButton text={"-15%"} value={"DYN_M15"} change={changeStatus} selected={selected} />
                <GridButton text={"-10%"} value={"DYN_M10"} change={changeStatus} selected={selected} />
                <GridButton text={"-5%"} value={"DYN_M5"} change={changeStatus} selected={selected} />
                <GridButton text={"Latest val."} value={"DYN_LAT"} change={changeStatus} selected={selected} />
              </div>
              <div className={"flex flex-row space-x-2"}>
                <GridButton text={"+5%"} value={"DYN_5"} change={changeStatus} selected={selected} />
                <GridButton text={"+10%"} value={"DYN_10"} change={changeStatus} selected={selected} />
                <GridButton text={"+15%"} value={"DYN_15"} change={changeStatus} selected={selected} />
                <GridButton text={"+20%"} value={"DYN_20"} change={changeStatus} selected={selected} />
              </div>
            </div>
            <div className={"flex flex-row space-x-3 p-2 rounded-lg border-[#E6E6E6] border"}>
              <div className={"self-center"}>
                <img className={"w-8 h-8"} src={InfoIcon} />
              </div>
              <p className={"text-xs font-medium text-[#666666]"}>
                Dynamic price will adjust automatically on each card based on latest valuation variations (either average or floor price)
              </p>
            </div>
            <div className={"space-y-1 w-full"}>
              <p className={"text-black-4D text-sm font-medium"}>Currency</p>
              <div className={"self-center flex flex-row justify-start w-32"}>
                <NewCurrencyPicker
                  disabledCurrencies={
                    !openToOffers
                      ? {
                          eur: "You're not open to offers",
                          eth: "You're not open to offers",
                          usd: "You're not open to offers",
                          gbp: "You're not open to offers",
                        }
                      : undefined
                  }
                  fontUnselected={"text-black-4D"}
                  currency={theCurrency}
                  onChange={(currency) => {
                    setCurrency(currency);
                    changeStatus(selected, true, currency);
                  }}
                  border={"border-[#E6E6E6] border rounded"}
                />
              </div>
            </div>
            <div className={"border-t border-[#E6E6E6]"}>
              <div className={"flex flex-row justify-between mt-4"}>
                <p className={"text-base font-medium font-[#1A1A1A] self-center"}>Open to offers</p>
                <div>
                  <Toggle
                    setActive={() => {
                      if (openToOffers) {
                        setInputValue("");
                        setSelected("");
                      }
                      setOpenToOffers(!openToOffers);
                    }}
                    isActive={openToOffers}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <div className={"bg-white rounded p-4 absolute z-50 origin-right space-y-4 w-96 shadow-lg"}>
        <div className={"flex flex-row justify-between w-full"}>
          <p className={"text-xl font-headers font-semibold"}>Market Status</p>
          <div className={"self-center"}>
            <img
              className={"w-4 h-4 cursor-pointer"}
              onClick={() => {
                props.createTag(selected, inputValue, theCurrency, openToOffers);
                props.close();
              }}
              src={Close}
            />
          </div>
        </div>
        <div className={"space-y-1"}>
          <p className={"text-black-4D text-sm font-medium"}>Minimum price</p>
          <div className={"flex flex-row space-x-2"}>
            <div className="relative rounded-lg border-0">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                {!openToOffers && <img className={"w-5 h-5"} src={Lock} />}
                {((openToOffers && inputValue === "") || selected.includes("FIXED")) && <img className={"w-5 h-5"} src={Edit} />}
                {openToOffers && inputValue !== "" && selected === "" && <img className={"w-5 h-5"} src={Sell} />}
                {openToOffers && inputValue !== "" && selected.includes("DYN") && <img className={"w-5 h-5"} src={Dynamic} />}
              </div>
              <input
                disabled={!openToOffers || selected.includes("DYN")}
                type="text"
                name="value"
                id="value"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="block w-32 rounded-md border-0 py-2 pl-10 text-gray-900 ring-1 ring-inset ring-[#E6E6E6]
                               placeholder:text-gray-400 text-base font-semibold focus:ring-2
                               sm:text-sm sm:leading-6"
                placeholder={openToOffers ? "Set price" : "-----"}
              />
            </div>
            <div className={"self-center"}>
              <NewCurrencyPicker
                disabledCurrencies={
                  !openToOffers
                    ? {
                        eur: "You're not open to offers",
                        eth: "You're not open to offers",
                        usd: "You're not open to offers",
                        gbp: "You're not open to offers",
                      }
                    : undefined
                }
                fontUnselected={"text-black-4D"}
                currency={theCurrency}
                onChange={(currency) => {
                  setCurrency(currency);
                  changeStatus(selected, true, currency);
                }}
                border={"border-[#E6E6E6] border rounded"}
              />
            </div>
          </div>
        </div>
        <div className={"space-y-2"}>
          <p className={"text-black-4D text-sm font-medium"}>Dynamic prices based on latest valuation</p>
          <div className={"flex flex-row space-x-2"}>
            <GridButton text={"-20%"} value={"DYN_M20"} change={changeStatus} selected={selected} />
            <GridButton text={"-15%"} value={"DYN_M15"} change={changeStatus} selected={selected} />
            <GridButton text={"-10%"} value={"DYN_M10"} change={changeStatus} selected={selected} />
            <GridButton text={"-5%"} value={"DYN_M5"} change={changeStatus} selected={selected} />
            <GridButton text={"Latest val."} value={"DYN_LAT"} change={changeStatus} selected={selected} />
          </div>
          <div className={"flex flex-row space-x-2"}>
            <GridButton text={"+5%"} value={"DYN_5"} change={changeStatus} selected={selected} />
            <GridButton text={"+10%"} value={"DYN_10"} change={changeStatus} selected={selected} />
            <GridButton text={"+15%"} value={"DYN_15"} change={changeStatus} selected={selected} />
            <GridButton text={"+20%"} value={"DYN_20"} change={changeStatus} selected={selected} />
          </div>
        </div>
        <div className={"flex flex-row space-x-3 p-2 rounded-lg border-[#E6E6E6] border"}>
          <div className={"self-center"}>
            <img className={"w-8 h-8"} src={InfoIcon} />
          </div>
          <p className={"text-xs font-medium text-[#666666]"}>
            Dynamic price will adjust automatically on each card based on latest valuation variations (either average or floor price)
          </p>
        </div>
        <div className={"border-t border-[#E6E6E6]"}>
          <div className={"flex flex-row justify-between mt-4"}>
            <p className={"text-base font-medium font-[#1A1A1A] self-center"}>Open to offers</p>
            <div>
              <Toggle
                setActive={() => {
                  if (openToOffers) {
                    setInputValue("");
                    setSelected("");
                  }
                  setOpenToOffers(!openToOffers);
                }}
                isActive={openToOffers}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUser(MarketStatusPopover);
