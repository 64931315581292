import React, { createContext, useContext, useState } from "react";
import { withUser } from "./userContext";
import { designSystemFunctionalColors, sdDark, sdLight } from "./themeColors";

export const ThemeContext = createContext();

export const ThemeProvider = withUser(({ children, user }) => {
  const [theme, setTheme] = useState(sdLight);

  const toggleTheme = () => {
    setTheme((currentTheme) => (currentTheme === sdLight ? sdDark : sdLight));
  };
  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
        enabled: user?.featureFlipping?.themeSwitcher || false,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
});

export const getThemeColor = (theme, key, format) => {
  if (theme === undefined || theme === null || theme === "") {
    return "";
  }
  return designSystemFunctionalColors[theme]?.[key] || "";
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
};
