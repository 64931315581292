import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { withRouter } from "react-router-dom";
import { Accordion, AccordionPanel } from "grommet";
import SDLoading from "../util/SDLoading";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { scarcities_objects_not_all } from "../util/scarcities";
import { getSimplePositionsBasedOnSport } from "../util/positions";
import { leagues_with_divisions_objects_not_all } from "../util/leagues";
import { PlayerStatusSelector } from "../util/playingStatus";
import AgeRange, { DEFAULT_AGE_RANGE } from "../util/ageRange";
import PriceFilter from "../util/priceRange";
import ScoreFilter from "../util/scoreFilter";
import { Button } from "../util/button";
import { ReactTooltip } from "../util/tooltip.js";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as ListSelectedIcon } from "../../img/icons-list.svg";
import { ReactComponent as GridIcon } from "../../img/icons-grid.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import PlayerSearchResult from "../players/playerSearchResult";
import { StandaloneMenu } from "../util/standaloneMenu";
import FavoriteBubble from "../util/favoriteBubble";
import Spinner from "../loader/spinner";
import PlayerSearchListResult from "../players/playerSearchListResult";
import NotificationBubble from "../util/notificationBubble";
import DeletionBubble from "../util/deletionBubble";
import AddSearchableItemsModal from "../search/addSearchableItemsModal";
import { isSportFree, sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { GameweekFilter } from "../players/gameweekFilter";
import { isFree } from "../util/handleSubscriptionTier";
import Locker from "../../img/brand-close-locker-nude.svg";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { getClubsFromSport } from "../util/clubs";

const defaultScoreFilter = {
  l5: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
  l15: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
  l40: {
    enabled: false,
    average_range: [0, 100],
    decisive_range: [0, 100],
    all_around_range: [-20, 100],
  },
};

const sortByLabels = (sport) => {
  return {
    "realTimeLimited asc": "Price Limited (lowest to highest)",
    "realTimeLimited desc": "Price Limited (highest to lowest)",
    "realTimeRare asc": "Price Rare (lowest to highest)",
    "realTimeRare desc": "Price Rare (highest to lowest)",
    "realTimeSuperRare asc": "Price Super Rare (lowest to highest)",
    "realTimeSuperRare desc": "Price Super Rare (highest to lowest)",
    "realTimeUnique asc": "Price Unique (lowest to highest)",
    "realTimeUnique desc": "Price Unique (highest to lowest)",
    "rankingPosition asc": "Ranking position (best to worst)",
    "rankingPosition desc": "Ranking position (worst to best)",
    "rankingOverall asc": "Ranking overall (best to worst)",
    "rankingOverall desc": "Ranking overall (worst to best)",
    "l5 asc": sport === sorareBasketball ? "L10 (worst to best)" : "L5 (worst to best)",
    "l5 desc": sport === sorareBasketball ? "L10 (best to worst)" : "L5 (best to worst)",
    "l15 asc": sport === sorareBasketball ? "L40 (worst to best)" : "L15 (worst to best)",
    "l15 desc": sport === sorareBasketball ? "L40 (best to worst)" : "L15 (best to worst)",
    "l40 asc": sport === sorareBasketball ? "Season (worst to best)" : "L40 (worst to best)",
    "l40 desc": sport === sorareBasketball ? "Season (best to worst)" : "L40 (best to worst)",
    "added asc": "Least recently added",
    "added desc": "Most recently added",
  };
};

function WatchlistPlayersOverview(props) {
  const watchlist = props.watchlist;

  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState(undefined);
  const [displayedPlayers, setDisplayedPlayers] = useState(undefined);
  const [teamMap, setTeamMap] = useState({});
  const [nextGameweeks, setGameweeks] = useState([]);

  const [scarcity, setScarcity] = useState([]);
  const [league, setLeague] = useState([]);
  const [position, setPosition] = useState([]);
  const [playingStatus, setPlayingStatus] = useState("all");
  const [clubs, setClubs] = useState([]);
  const [bmpFilter, setBmpFilter] = useState(false);
  const [avgFilter, setAvgFilter] = useState(false);
  const [scoreFilters, setScoreFilters] = useState(defaultScoreFilter);
  const [bmpRange, setBmpRange] = useState([0, 1]);
  const [avgRange, setAvgRange] = useState([0, 1]);
  const [ageRange, setAgeRange] = useState(DEFAULT_AGE_RANGE);
  const [u23Only, setU23Only] = useState(false);
  const [gwFilter, setGwFilter] = useState(false);
  const [gwValue, setGwValue] = useState("");
  const [sortBy, setSortBy] = useState("added desc");
  const [display, setDisplay] = useState("grid"); // or list

  const [savingAddPlayers, setSavingAddPlayers] = useState(false);
  const [openAddSearchableItemsModal, setOpenAddSearchableItemsModal] = useState(false);

  const [showSortMenu, setShowSortMenu] = useState(false);
  const connectedUserIsOwner = watchlist.owner === props.user.sorareSlug;

  useEffect(() => {
    getPlayers();
  }, [watchlist, sortBy]);

  const resetFilters = () => {
    setScarcity([]);
    setLeague([]);
    setPosition([]);
    setPlayingStatus("all");
    setClubs([]);
    setBmpFilter(false);
    setAvgFilter(false);
    setScoreFilters(defaultScoreFilter);
    setBmpRange([0, 1]);
    setAvgRange([0, 1]);
    setAgeRange(DEFAULT_AGE_RANGE);
    setU23Only(false);
    setGwFilter(false);
    setGwValue("");
    setSortBy("added desc");
  };

  const getPlayers = () => {
    if (watchlist.id) {
      setLoading(true);
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}/players/search`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            scarcity: scarcity,
            position: position,
            league: league,
            bmpRange: bmpFilter ? [parseFloat(bmpRange[0]), parseFloat(bmpRange[1])] : [],
            avgRange: avgFilter ? [parseFloat(avgRange[0]), parseFloat(avgRange[1])] : [],
            u23: u23Only,
            ageRange: ageRange,
            l5: scoreFilters.l5,
            l15: scoreFilters.l15,
            l40: scoreFilters.l40,
            playingStatus: playingStatus,
            teams: clubs,
            gwNumber: gwValue === "" ? 0 : parseInt(gwValue),
            gwFilter: gwFilter,
            sortBy: sortBy,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setPlayers(res.players || []);
            setDisplayedPlayers(res.players ? res.players.slice(0, 50) : []);
            setTeamMap(res.team_map || {});
            let gws = [];
            if (res.next_gameweeks) {
              res.next_gameweeks.map((gw, index) => {
                gws.push({
                  name: gw.DisplayName,
                  value: gw.GwNumber,
                  disabled: isFree(props.user.tier) && index !== 0,
                  photo: Locker,
                });
              });
            }
            setGameweeks(gws);
          }
          setLoading(false);
          ReactTooltip.rebuild();
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
            ReactTooltip.rebuild();
          }),
        );
    }
  };

  const removePlayerFromWatchlist = (playerId) => {
    if (watchlist.id && playerId) {
      setLoading(true);
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}/removeItems`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify([playerId]),
        })
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.error === undefined) {
            setPlayers(players.filter((p) => p.player.PlayerId !== playerId));
            setDisplayedPlayers(displayedPlayers.filter((p) => p.player.PlayerId !== playerId));
          }
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const onSaveAddPlayers = (playerIds) => {
    setSavingAddPlayers(true);
    return props
      .fetch(`/apiv2/watchlists/id/${watchlist.id}/addItems`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(playerIds),
      })
      .then((res) => {
        setSavingAddPlayers(false);
        if (res.error === undefined) {
          setOpenAddSearchableItemsModal(false);
          props.onAddPlayers && props.onAddPlayers();
        } else {
          console.log(res.error);
        }
      })
      .catch(
        errorCatcher(() => {
          setSavingAddPlayers(false);
        }),
      );
  };

  const sortMenuItems = Object.keys(sortByLabels(watchlist.sport)).map((key) => {
    const label = sortByLabels(watchlist.sport)[key];
    return {
      key: key,
      label: label,
      className: "w-full sm:w-72 cursor-pointer " + (sortBy === key ? "font-semibold" : ""),
      onClick: () => {
        setShowSortMenu(false);
        setSortBy(key);
      },
    };
  });

  const sortByStatus = (field) => {
    let sort = "";
    if (sortBy.includes(field)) {
      sort = field + (sortBy.includes("asc") ? " desc" : " asc");
    } else {
      sort = field + " asc";
    }
    setSortBy(sort);
  };

  const changeDisplay = (display) => {
    setDisplay(display);
    ReactTooltip.rebuild();
  };

  const listActionItems =
    displayedPlayers?.map((p) => {
      return (
        <div key={p.player.PlayerId} className={"flex flex-row justify-center m-auto"}>
          <div className={"h-9 w-9"}>
            <FavoriteBubble player={p.player} favorite={p.favorite} />
          </div>
          <div className={"h-9 w-9"}>
            <NotificationBubble player={p.player} hasNotifications={p.has_price_alerts} />
          </div>
          {connectedUserIsOwner && (
            <div className={"h-9 w-9"}>
              <DeletionBubble
                onClick={() => removePlayerFromWatchlist(p.player.PlayerId)}
                title={`Click to remove "${p.player.DisplayName}" from "${watchlist.name}" watchlist`}
              />
            </div>
          )}
        </div>
      );
    }) || [];

  return (
    <div className={"h-full"}>
      <ReactTooltip />
      {isFree(props.user.tier) && !isSportFree(watchlist?.sport) && (
        <div className="mb-2">
          <UpgradeLimitBox
            className={"bg-white bg-upgrade-bg-player-status bg-cover h-upgrade-banner-s w-full"}
            title={"Want more insights?"}
            description={"Become a Star member to unlock players status and premium filters."}
          />
        </div>
      )}
      <div className={"mb-4"}>
        <Accordion>
          <AccordionPanel label={"Filters"}>
            <div className={"px-0 xl:px-12 2xl:px-24 3xl:px-36 space-y-2 pt-4"}>
              <div className={"md:grid md:grid-cols-2 md:gap-2 lg:grid-cols-3 xl:grid-cols-4 space-y-1 md:space-y-0"}>
                <div className={"z-40"}>
                  <SelectSearch
                    multiple
                    closeOnSelect={false}
                    options={scarcities_objects_not_all}
                    value={scarcity}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one scarcity or more"
                    onChange={(v) => setScarcity(v)}
                  />
                </div>
                <div className={"z-50"}>
                  <SelectSearch
                    multiple
                    closeOnSelect={false}
                    options={getSimplePositionsBasedOnSport(watchlist?.sport)}
                    value={position}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one position or more"
                    onChange={(v) => setPosition(v)}
                  />
                </div>
                {watchlist?.sport !== sorareBaseball && (
                  <div className={"z-40"}>
                    <SelectSearch
                      multiple
                      closeOnSelect={false}
                      options={leagues_with_divisions_objects_not_all}
                      value={league}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      placeholder="Select one league or more"
                      onChange={(v) => setLeague(v)}
                    />
                  </div>
                )}
                <div className={"z-40"}>
                  <PlayerStatusSelector
                    playingStatus={playingStatus}
                    onChange={(v) => setPlayingStatus(v)}
                    tier={props.user.tier}
                    sport={watchlist?.sport}
                  />
                </div>
                <div className={"z-30"}>
                  <SelectSearch
                    options={getClubsFromSport(watchlist?.sport, true)}
                    onChange={(v) => setClubs(v)}
                    search
                    multiple
                    value={clubs}
                    closeOnSelect={false}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one club or more"
                  />
                </div>
                <div className={"z-40"}>
                  <AgeRange range={ageRange} setU23={() => setU23Only(!u23Only)} u23={u23Only} setRange={(ar) => setAgeRange(ar)} />
                </div>
                <PriceFilter
                  tip={scarcity.length === 0 && "(pick scarcities first)"}
                  watchlist={true}
                  bmp={bmpFilter}
                  bmpRange={bmpRange}
                  avg={avgFilter}
                  avgRange={avgRange}
                  sport={watchlist?.sport}
                  tier={props.user.tier}
                  updateFilters={async (c, cr, bmp, bmpr, avg, avgr, ubmp, uavg) => {
                    await setBmpFilter(bmp);
                    await setBmpRange(bmpr);
                    await setAvgFilter(avg);
                    await setAvgRange(avgr);
                  }}
                />
                <ScoreFilter
                  l5={scoreFilters.l5}
                  l15={scoreFilters.l15}
                  l40={scoreFilters.l40}
                  sport={watchlist?.sport}
                  setScoreFilters={async (f) => {
                    await setScoreFilters(f);
                  }}
                />
              </div>
              <div className={"flex flex-col md:flex-row justify-between pt-0.5 gap-1"}>
                <div className={"md:w-1/2 lg:w-1/3 xl:w-1/4 pr-1.5"}>
                  {(watchlist?.sport === sorareFootball || watchlist?.sport === "multi") && (
                    <GameweekFilter
                      label={"Plays during gameweek"}
                      className={"w-full"}
                      filterByGw={gwFilter}
                      onChangeFilterByGw={() => setGwFilter(!gwFilter)}
                      gameweeks={nextGameweeks}
                      gw={gwValue}
                      onChangeGw={(v) => setGwValue(v)}
                    />
                  )}
                </div>
                <div className="flex flex-row gap-2">
                  <Button context={"secondary"} label={"Reset"} onClick={resetFilters} />
                  <Button label={"Filter"} onClick={() => getPlayers()} />
                </div>
              </div>
            </div>
          </AccordionPanel>
        </Accordion>
      </div>
      <div className={"relative mb-4 flex flex-col sm:flex-row justify-between gap-2"}>
        <div className={"text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
          Sort by: {sortByLabels(watchlist.sport)[sortBy]}
          <span className={"ml-2 my-auto"}>
            <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
          </span>
        </div>
        <div className="flex flex-row gap-2 justify-end">
          {loading && <Spinner />}
          {connectedUserIsOwner && (
            <button
              className={
                "bg-brand text-white p-2 pl-10 pr-10 text-sm font-medium rounded-md focus:outline-none hover:bg-brand-lightWatchlist"
              }
              onClick={() => setOpenAddSearchableItemsModal(true)}
            >
              {`+ Add ${watchlist.type}s`}
            </button>
          )}
          <button
            type="button"
            onClick={() => changeDisplay("grid")}
            className={
              "inline-flex items-center p-3 border-0 border-transparent text-med font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-none bg-white " +
              (display === "grid" ? "fill-brand cursor-default" : "fill-textGrey4 cursor-none hover:fill-brand")
            }
          >
            <GridIcon />
          </button>
          <button
            type="button"
            onClick={() => changeDisplay("list")}
            className={
              "inline-flex items-center p-3 border-0 border-transparent text-med font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-none bg-white " +
              (display === "list" ? "fill-brand cursor-default" : "fill-textGrey4 cursor-pointer hover:fill-brand")
            }
          >
            <ListSelectedIcon />
          </button>
        </div>
        <StandaloneMenu
          className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
          show={showSortMenu}
          onClickOutside={() => setShowSortMenu(false)}
          items={sortMenuItems}
        />
      </div>
      <div className={"h-full"}>
        {displayedPlayers !== undefined && displayedPlayers.length === 0 && (
          <div className={"flex flex-col justify-center w-full h-full bg-focus rounded-lg text-center border border-grey-e9"}>
            <div className={"m-auto py-36"}>
              <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
              <p className={"text-lg font-headers text-center font-bold mb-6"}>
                {connectedUserIsOwner ? "Your watchlist is empty" : "Watchlist is empty"}
              </p>
              {connectedUserIsOwner && (
                <button
                  onClick={() => setOpenAddSearchableItemsModal(true)}
                  type="button"
                  className="inline-flex items-center px-12 py-2.5 border border-transparent text-lg font-semibold rounded-lg
                shadow-sm text-white bg-brand hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-brand-light"
                >
                  {`+ Add ${watchlist.type}s`}
                </button>
              )}
            </div>
          </div>
        )}
        {displayedPlayers !== undefined && displayedPlayers.length > 0 && (
          <div>
            {display === "grid" && (
              <InfiniteScroll
                next={() => setDisplayedPlayers(players.slice(0, displayedPlayers.length + 50))}
                hasMore={displayedPlayers.length < players.length}
                loader={<SDLoading />}
                dataLength={displayedPlayers.length}
              >
                <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 2xl:gap-y-8"}>
                  {displayedPlayers.map((p) => {
                    return (
                      <PlayerSearchResult
                        key={p.player.PlayerId}
                        teamMap={teamMap}
                        result={p}
                        deleteTitle={`Click to remove "${p.player.DisplayName}" from "${watchlist.name}" watchlist`}
                        gw={gwValue}
                        actions={connectedUserIsOwner ? ["deletion", "favorite", "notification"] : ["favorite", "notification"]}
                        onDelete={(p) => removePlayerFromWatchlist(p.PlayerId)}
                      />
                    );
                  })}
                </div>
              </InfiniteScroll>
            )}
            {display === "list" && (
              <>
                <div>
                  <InfiniteScroll
                    next={() => setDisplayedPlayers(players.slice(0, displayedPlayers.length + 50))}
                    hasMore={displayedPlayers.length < players.length}
                    loader={<SDLoading />}
                    dataLength={displayedPlayers.length}
                  >
                    <PlayerSearchListResult
                      sortBy={sortBy}
                      onChangeSort={sortByStatus}
                      displayedPlayers={displayedPlayers}
                      actionItems={listActionItems}
                    />
                  </InfiniteScroll>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <AddSearchableItemsModal
        sport={watchlist?.sport}
        title={"Add to watchlist"}
        open={openAddSearchableItemsModal}
        onSave={onSaveAddPlayers}
        saving={savingAddPlayers}
        onCancel={() => setOpenAddSearchableItemsModal(false)}
      />
    </div>
  );
}

export default withRouter(withUser(WatchlistPlayersOverview));
