const HeaderPaginationOffset = "X-Sd-Paginate-Offset";
const HeaderPaginationLimit = "X-Sd-Paginate-Limit";
const HeaderPaginationHasNext = "X-Sd-Paginate-Has-Next";
const HeaderPaginationTotal = "X-Sd-Paginate-Total";

export const withJSONPagination = (offset, limit, headers) => {
  const resHeaders = headers || {};
  resHeaders["Accept"] = "application/json, text/plain, */*";
  resHeaders["Content-Type"] = "application/json";
  return withPagination(offset, limit, resHeaders);
};

export const withPagination = (offset, limit, headers) => {
  const resHeaders = headers || {};
  if (offset !== undefined && offset !== null) {
    resHeaders[HeaderPaginationOffset] = offset;
  }
  if (limit !== undefined && limit !== null) {
    resHeaders[HeaderPaginationLimit] = limit;
  }
  return resHeaders;
};

export const getPaginatedJSONResponse = async (response) => {
  const hasNext = response.headers.get(HeaderPaginationHasNext) === "true";
  const total = response.headers.get(HeaderPaginationTotal);

  return response?.json().then((res) => ({
    res: res,
    hasNext: hasNext,
    total: total !== null && total !== undefined && total !== "" ? parseInt(total) : undefined,
  }));
};
