import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Box, Heading, Select } from "grommet";
import { RARE_COLOR, SUPER_RARE_COLOR, UNIQUE_COLOR } from "../util/colors";
import CustomizedAxisTickHistogram from "../util/tickHistogram";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function CurrentMarketState(props) {
  const [data, setData] = useState([]);
  const [scarcity, setScarcity] = useState("Rare");

  const getData = () => {
    if (!props.user.sorareSlug) return;

    props
      .fetch("/apiv2/stats/histogram/" + scarcity + "?currency=" + props.user.preferredUnit)
      .then((response) => response.json())
      .then((res) => {
        setData(res);
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    getData();
  }, [scarcity, props.user.sorareSlug]);

  let color = RARE_COLOR;
  if (scarcity === "Super rare") {
    color = SUPER_RARE_COLOR;
  } else if (scarcity === "Unique") {
    color = UNIQUE_COLOR;
  }

  return (
    <Box>
      <Box align={"center"}>
        <Heading level={"3"} textAlign={"center"}>
          Current secondary market listing prices by scarcity
        </Heading>
      </Box>
      <Box align={"center"} margin={{ bottom: "small" }}>
        <Select options={["Rare", "Super rare", "Unique"]} value={scarcity} onChange={({ option }) => setScarcity(option)} />
      </Box>
      <ResponsiveContainer width={"100%"} height={450}>
        <BarChart
          width={600}
          height={450}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 30,
          }}
        >
          <XAxis dataKey={"Label"} tick={<CustomizedAxisTickHistogram />} />
          <YAxis />
          <Tooltip />
          <Bar fill={color} dataKey="Count" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default withUser(CurrentMarketState);
