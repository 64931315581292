import React, { useEffect, useState } from "react";
import { Box, Heading, Text } from "grommet";
import Bundle from "./bundle";
import Spinner from "../loader/spinner";
import { getTeamMapNextGw } from "../util/teamMap";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function LatestSoldBundles(props) {
  const [bundles, setBundles] = useState([]);
  const [nextGwPlayers, setNextGwPlayers] = useState({});

  useEffect(() => {
    getBundles();
    getTeamMapNextGw(props).then((r) => setNextGwPlayers(r));
  }, []);

  const getBundles = () => {
    props
      .fetch("/apiv2/auctions/lastBundlesSold")
      .then((response) => response.json())
      .then((res) => {
        setBundles(res);
      })
      .catch(errorCatcher());
  };

  return (
    <Box>
      <Box align={"center"}>
        <Heading textAlign={"center"} level={"2"} color={"brand"}>
          Latest bundle auctions
        </Heading>
      </Box>

      {bundles !== null && bundles.length > 0 ? (
        <Box gap={"medium"}>
          {bundles.map((bundle, index) => {
            return <Bundle bundle={bundle} where={"list"} nextGwPlayers={nextGwPlayers} />;
          })}
        </Box>
      ) : bundles === null ? (
        <Box align={"center"} margin={{ top: "small" }}>
          <Text>No bundle.</Text>
        </Box>
      ) : (
        <Box align={"center"} margin={{ top: "large" }}>
          <Spinner />
        </Box>
      )}
    </Box>
  );
}

export default withUser(LatestSoldBundles);
