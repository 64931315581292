import React from "react";
import AuctionWrapper from "./auctionWrapper";

function OngoingAuctions(props) {
  document.title = "Ongoing Sorare auctions";
  return (
    <div className={"mx-16"}>
      <AuctionWrapper tmp={"next"} sport={props.sport} refresh={45000} past={false} filter={true} />
    </div>
  );
}

export default OngoingAuctions;
