import React from "react";
import NewCardIcon from "../util/newCardIcon";
import { ReactComponent as EthereumIcon } from "../../img/ether-no-color.svg";
import Coin from "../../img/coin.png";
import { withUser } from "../../userContext";
import { ReactComponent as VisibilityOff } from "../../img/visibility_off_no_color.svg";
import { ReactComponent as Visibility } from "../../img/visibility_no_color.svg";
import { ReactComponent as ChevronRightIcon } from "@material-design-icons/svg/filled/chevron_right.svg";
import ordinal_suffix_of_no_number from "../util/ordinal_suffix_no_number";
import Bg1stPlace from "../../img/bg-1st-place.png";
import Bg2ndPlace from "../../img/bg-2nd-place.png";
import Bg3rdPlace from "../../img/bg-3rd-place.png";
import { ReactComponent as PlayersLeftIcon } from "../../img/players-left-icon.svg";
import LiveCircle from "../util/liveCircle";
import { findPriceForUserUnit } from "../util/formatMoney";
import { nextRewardsToText } from "./SO5LineupCards";
import { getDivisionAndRarityDisplayName, getRarityDisplayName } from "../util/divisions";
import clsx from "clsx";
import { sorareFootball } from "../util/sports";

export const SO5LiveDivision = withUser((props) => {
  const dark = props.dark;
  const anchor = props.anchor;
  let lu = props.lu;
  const sport = props.sport;
  const link = props.link;
  let hidden = lu.hidden;
  let rank = lu.lineup.Rank || lu.lineup.rank;
  let compTitle = lu.competition.DisplayNameShort || lu.competition.DisplayName || lu.competition.displayName;
  let compSubtitle = sport === sorareFootball ? getDivisionAndRarityDisplayName(lu.competition) : "";
  const rewards = props.rewards;
  const leagueIsLive = lu.competition.IsLive;
  let score = parseFloat(lu.lineup.Score || lu.lineup.score).toFixed(2) || 0;
  let top = lu.lineup.Top;
  let rankingSystem = lu.competition.RewardType;
  const defaultText = dark ? "text-white" : "text-on-surface";
  let colorPoints = defaultText;
  let colorRank = dark ? "text-white" : "text-on-surface-variant";
  let rewardCards = rewards?.current?.cards || [];
  let rewardCoins = rewards?.current?.coins || "";
  let rewardMoney =
    rewards?.current?.eth > 0 ? findPriceForUserUnit(rewards.current.eth, rewards.current.fiat, props.user.preferredUnit, true, 3) : "";

  const nextRewards = rewards?.next;
  const nextRewardsText = nextRewards ? nextRewardsToText(nextRewards, props.user.preferredUnit) : "";
  let nextReward = "";
  if (nextRewardsText) {
    const gap = nextRewards?.gap || 0;
    nextReward = gap.toFixed(1) + " pts to " + nextRewardsText;
  }
  const greenText = dark ? "text-green-dark" : "text-quality-scale-good-on-surface-container";
  if (rankingSystem === "top_and_score") {
    if (lu.competition.RewardThreshold1 > 0) {
      if (score - lu.competition.RewardThreshold1 >= 0) {
        colorPoints = greenText;
      } else {
        colorPoints = defaultText;
      }
    }
    if (rank <= lu.competition.PlaceThreshold2) {
      colorRank = greenText;
    } else if (rank <= lu.competition.PlaceThreshold1) {
      colorRank = greenText;
    } else {
      colorRank = defaultText;
    }
  } else if (rankingSystem === "top") {
    if (top <= lu.competition.PlaceThreshold2) {
      colorRank = greenText;
    } else if (top <= lu.competition.PlaceThreshold1) {
      colorRank = greenText;
    } else {
      colorRank = defaultText;
    }
  } else if (rankingSystem === "ranking") {
    if (rank <= lu.competition.PlaceThreshold2) {
      colorRank = greenText;
    } else if (rank <= lu.competition.PlaceThreshold1) {
      colorRank = greenText;
    } else {
      colorRank = defaultText;
    }
  } else if (rewardCards.length > 0 || rewardMoney !== "") {
    colorRank = greenText;
  }
  const hideBg = dark ? "bg-black-1f" : "bg-surface-container";
  let bg = dark ? "bg-black-1f" : "bg-surface-container";
  let bgCard = dark ? "bg-black-1f border-b border-[#4D4D4D]" : "bg-surface-container border-b border-outline-variant";
  if (rewardMoney !== "" || (rewardCards.length > 0 && rewardCards[0].scarcity !== "")) {
    bg = "bg-gradient bg-gradient-to-r from-[#008F97] to-[#005E78]";
    bgCard = dark ? "bg-black-1f" : "bg-surface-container";
  }

  let divColor = dark ? "text-[#CCCCCC]" : "text-on-surface-variant";
  let playersLeftColor = dark ? "text-[#CCCCCC]" : "text-on-surface-variant";
  let playerLeftIconColor = dark ? "fill-[#CCCCCC]" : "fill-on-surface-variant";
  let nbCardsLeftColor = "text-[#1F1F1F]";
  let opacityPlayersLeft = "opacity-100";
  let dividerColor = dark ? "border-black-4D" : "border-outline-variant";

  let bgStyle = {};
  if (rank === 1 && score > 0 && (rewardMoney !== "" || (rewardCards.length > 0 && rewardCards[0].scarcity !== ""))) {
    bgStyle = {
      backgroundImage: "url('" + Bg1stPlace + "')",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    bg = "bg-[#1F1F1F] bg-opacity-20";
    bgCard = "";
    colorPoints = "text-[#3C2E11]";
    colorRank = "text-[#22580E]";
    divColor = "text-[#3C2E11]";
    playerLeftIconColor = dark ? "fill-[#262626]" : "fill-black";
    playersLeftColor = dark ? "text-[#262626]" : "text-black";
    nbCardsLeftColor = "text-white";
    dividerColor = "border-black-4D";
  }
  if (rank === 2 && score > 0 && (rewardMoney !== "" || (rewardCards.length > 0 && rewardCards[0].scarcity !== ""))) {
    bgStyle = {
      backgroundImage: "url('" + Bg2ndPlace + "')",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    bg = "bg-[#1F1F1F] bg-opacity-20";
    bgCard = "";
    colorPoints = "text-[#262626]";
    colorRank = "text-[#22580E]";
    divColor = "text-[#262626]";
    playerLeftIconColor = dark ? "fill-[#262626]" : "fill-black";
    playersLeftColor = dark ? "text-[#262626]" : "text-black";
    nbCardsLeftColor = "text-white";
    dividerColor = "border-black-4D";
  }
  if (rank === 3 && score > 0 && (rewardMoney !== "" || (rewardCards.length > 0 && rewardCards[0].scarcity !== ""))) {
    bgStyle = {
      backgroundImage: "url('" + Bg3rdPlace + "')",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    bg = "bg-[#1F1F1F] bg-opacity-20";
    bgCard = "";
    colorPoints = "text-[#352518]";
    colorRank = "text-[#22580E]";
    divColor = "text-[#352518]";
    playerLeftIconColor = dark ? "fill-[#262626]" : "fill-black";
    playersLeftColor = dark ? "text-[#262626]" : "text-black";
    nbCardsLeftColor = "text-white";
    dividerColor = "border-black-4D";
  }

  let group = "";
  if (props.user.sorareSlug === lu.lineup.SorareUserSlug) {
    group = "group";
  }

  if (lu.lineup?.CardsLeft !== undefined && lu.lineup.CardsLive !== undefined && lu.lineup.CardsLeft + lu.lineup.CardsLive === 0) {
    opacityPlayersLeft = "opacity-40";
  }

  const competitionHasStarted = rank > 1 || (rank === 1 && score > 0);
  return (
    <div className={group + " col-span-1 rounded-xl"} style={bgStyle}>
      <div className={"z-0"}>
        <a href={link ? link : anchor ? "#" + lu.lineup.LineupId : undefined}>
          <div
            className={
              bgCard +
              " relative rounded-t-xl" +
              " flex flex-row justify-center items-center content-center px-2 py-2 space-x-2 group-hover:bg-white group-hover:bg-opacity-10"
            }
          >
            <div className={"text-center h-10 self-center flex w-10"}>
              <img
                className={"w-full h-full self-center align-middle object-contain"}
                src={lu.competition.LogoUrl}
                onError={(e) => {
                  e.target.src = "";
                }}
              />
            </div>
            <div className={"w-full flex flex-row space-x-2 px-1"}>
              <div className={"w-full"}>
                <p className={clsx("text-xs font-semibold", divColor)}>{compTitle}</p>
                {compSubtitle && <p className={clsx("text-xs", divColor)}>{compSubtitle}</p>}
                <div className={"flex flex-row space-x-2"}>
                  <p
                    className={`font-semibold text-sm ${
                      rank > 1 || (rank === 1 && score > 0) ? colorRank : dark ? "text-grey-b3" : "text-on-surface-variant"
                    }`}
                  >
                    {rank > 1 || (rank === 1 && score > 0) ? rank : ""}
                    <span className={"text-xs"}>{rank > 1 || (rank === 1 && score > 0) ? ordinal_suffix_of_no_number(rank) : "-"}</span>
                  </p>
                  <div className={`border-l w-0 ${dividerColor} h-4 rounded-lg self-center`}></div>
                  <p className={"text-sm font-semibold " + colorPoints}>{score} pts</p>
                </div>
              </div>
            </div>
            <div
              className={
                "hidden h-full group-hover:flex flex-row gap-2 justify-center items-center group-hover:absolute right-4 top-0 z-10 self-center"
              }
            >
              {link && (
                <div className={"flex items-center justify-center flex-row gap-0.5"}>
                  <ChevronRightIcon className={`${playerLeftIconColor} w-7 h-7`} />
                  <div className={`border-r ${dividerColor} h-5`}></div>
                </div>
              )}
              {!hidden && props.hideLineup && (
                <div className={`flex flex-row justify-center ${hideBg} cursor-pointer rounded-full aspect-square`}>
                  <button
                    type="button"
                    {...props}
                    onClick={(e) => {
                      e.preventDefault();
                      props.hideLineup && props.hideLineup(lu.lineup.LineupId);
                    }}
                    className={`inline-flex items-center rounded-full p-2 ${hideBg} cursor-pointer`}
                  >
                    <VisibilityOff className={dark ? "fill-white" : "fill-on-surface-variant"} />
                  </button>
                </div>
              )}
              {hidden && props.unhideLineup && (
                <div className={`flex flex-row justify-center ${hideBg} cursor-pointer rounded-full aspect-square`}>
                  <button
                    type="button"
                    {...props}
                    onClick={(e) => {
                      e.preventDefault();
                      props.unhideLineup && props.unhideLineup(lu.lineup.LineupId);
                    }}
                    className={`inline-flex items-center rounded-full p-2 ${hideBg} cursor-pointer`}
                  >
                    <Visibility className={dark ? "fill-white" : "fill-on-surface-variant"} />
                  </button>
                </div>
              )}
            </div>
            {link && (
              <div className={"block group-hover:invisible flex h-full items-center"}>
                <ChevronRightIcon className={`${playerLeftIconColor} w-7 h-7`} />
              </div>
            )}
            {lu.lineup.CardsLeft !== undefined && (
              <div className={"w-24 h-10 flex relative flex-col justify-center self-center items-center space-y-0.5 " + opacityPlayersLeft}>
                <div className={" group-hover:hidden flex flex-col space-y-0.5 items-center self-center justify-center"}>
                  <PlayersLeftIcon className={`w-6 object-contain ${playerLeftIconColor}`} />
                  {lu.lineup.CardsLeft + lu.lineup.CardsLive > 0 && lu.lineup.CardsLive > 0 && (
                    <p className={"absolute bottom-6 text-xs font-semibold " + nbCardsLeftColor}>
                      {lu.lineup.CardsLeft + lu.lineup.CardsLive}
                    </p>
                  )}
                  {lu.lineup.CardsLeft + lu.lineup.CardsLive > 0 && lu.lineup.CardsLive === 0 && (
                    <p className={"absolute top-1 text-xs font-semibold " + nbCardsLeftColor}>
                      {lu.lineup.CardsLeft + lu.lineup.CardsLive}
                    </p>
                  )}
                  {lu.lineup.CardsLeft + lu.lineup.CardsLive === 0 && (
                    <p className={"absolute text-xs top-1 font-semibold " + nbCardsLeftColor}>
                      {lu.lineup.CardsLeft + lu.lineup.CardsLive}
                    </p>
                  )}
                  <p className={"text-xxs font-semibold text-center " + playersLeftColor}>players left</p>
                </div>
                {lu.lineup.CardsLive !== undefined && lu.lineup.CardsLive > 0 && (
                  <div className={"flex flex-row space-x-1 h-3"}>
                    <div className={"self-center"}>
                      <LiveCircle color={"#FF6666"} />
                    </div>
                    <p className={"text-xxs font-semibold text-center " + playersLeftColor}>{lu.lineup.CardsLive} live</p>
                  </div>
                )}
              </div>
            )}
          </div>
          {!(rewardCards.length === 0 && rewardMoney === "" && rewardCoins === "") && (
            <div
              className={
                "flex flex-row h-full justify-between text-center space-x-3 py-1.5 rounded-b-xl px-4 " +
                bg +
                "  group-hover:bg-white group-hover:bg-opacity-10"
              }
            >
              {rewardCards[0] && rewardCards[0]?.scarcity !== "" && (
                <div className={"flex flex-row space-x-1.5"}>
                  {rewardCards.map((reward, i) => {
                    if (reward.scarcity === "") {
                      return null;
                    }
                    return <CardReward key={i} dark={dark} scarcity={reward.scarcity} tier={reward.tier} />;
                  })}
                </div>
              )}
              {!(rewardCards[0] && rewardCards[0]?.scarcity !== "") &&
                rewardMoney === "" &&
                lu.lineup.CardsLeft + lu.lineup.CardsLive === 0 && (
                  <div className={"flex flex-row"}>
                    <p className={`font-semibold text-xs text-left self-center ${dark ? "text-[#CCCCCC]" : "text-on-surface-variant"}`}>
                      No reward
                    </p>
                  </div>
                )}
              {!(rewardCards[0] && rewardCards[0]?.scarcity !== "") &&
                rewardMoney === "" &&
                (lu.lineup.CardsLeft + lu.lineup.CardsLive > 0 || leagueIsLive) && (
                  <div className={"flex flex-row"}>
                    <p className={`font-semibold text-xs text-left self-center ${dark ? "text-[#CCCCCC]" : "text-on-surface-variant"}`}>
                      {nextReward}
                    </p>
                  </div>
                )}
              {!(rewardCards[0] && rewardCards[0]?.scarcity !== "") && rewardMoney !== "" && (
                <div className={"flex flex-row"}>
                  <p className={`font-semibold text-xs text-left self-center ${dark ? "text-[#CCCCCC]" : "text-on-surface-variant"}`}></p>
                </div>
              )}
              <div className={"flex flex-row space-x-1.5"}>
                {rewardMoney !== "" && <MoneyReward dark={dark} money={rewardMoney} />}
                {rewardCoins !== "" && <CoinsReward dark={dark} coins={rewardCoins} />}
              </div>
            </div>
          )}
          {rewardCards.length === 0 && rewardMoney === "" && rewardCoins === "" && (
            <div className={"py-2 rounded-b-xl " + bg + "  group-hover:bg-white group-hover:bg-opacity-10"}>
              <p className={`font-semibold text-xs text-left ${dark ? "text-[#CCCCCC]" : "text-on-surface-variant"} pl-4`}>
                {competitionHasStarted && nextReward && (lu.lineup.CardsLeft + lu.lineup.CardsLive > 0 || leagueIsLive)
                  ? nextReward
                  : "No reward"}
              </p>
            </div>
          )}
        </a>
      </div>
    </div>
  );
});

export const CardReward = (props) => {
  const { dark, scarcity, tier } = props;
  return (
    <div className={`${dark ? "bg-[#1F1F1F]" : "bg-surface-container-high"} bg-opacity-70 px-2 py-0.5 rounded flex flex-row space-x-1.5`}>
      <div style={{ height: "15px" }} className={"self-center"}>
        <NewCardIcon scarcity={scarcity} />
      </div>
      <p className={`font-semibold text-xs ${dark ? "text-white" : "text-on-surface"} text-center self-center`}>Tier {tier}</p>
    </div>
  );
};

export const MoneyReward = withUser((props) => {
  const { dark, money } = props;
  return (
    <div className={`${dark ? "bg-[#1F1F1F]" : "bg-surface-container-high"} bg-opacity-70 px-2 py-0.5 rounded flex flex-row space-x-1`}>
      <div style={{ height: "15px" }}>
        {props.user.preferredUnit === "eth" && (
          <EthereumIcon style={{ height: "15px" }} className={dark ? "fill-white" : "fill-on-surface"} />
        )}
      </div>
      <p className={`font-semibold  ${dark ? "text-white" : "text-on-surface"} text-xs text-center self-center`}>{money}</p>
    </div>
  );
});

export const CoinsReward = (props) => {
  const { dark, coins } = props;
  return (
    <div className={`${dark ? "bg-[#1F1F1F]" : "bg-surface-container-high"} bg-opacity-70 px-2 py-0.5 rounded flex flex-row space-x-1`}>
      <div style={{ height: "15px" }}>
        <img style={{ height: "15px" }} src={Coin} />
      </div>
      <p className={`font-semibold  ${dark ? "text-white" : "text-on-surface"} text-xs text-center self-center`}>{coins}</p>
    </div>
  );
};
