import React from "react";

function LiveCircle(props) {
  return (
    <svg width={6} height={6} viewBox="0 0 4 4" fill="none" {...props}>
      <circle cx="2" cy="2" r="2" fill={props.color} />
    </svg>
  );
}

export default LiveCircle;
