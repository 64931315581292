import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { useQuery } from "../util/useQuery";
import BaseballLineupBuilderLineup from "./baseballLineupBuilderLineup";

function AllMLBLineups(props) {
  const query = useQuery();
  const [lineups, setLineups] = useState([]);

  const getUsername = () => {
    let u = props.username;
    if (!u) {
      u = query.get("manager");
      if (!u) {
        u = props.user.sorareSlug;
      }
    }
    return u;
  };

  useEffect(() => {
    if (props.gw > 0 && props.draftId) {
      loadLineups(props.gw);
    }
  }, [props.gw, props.refresh, props.draftId]);

  const loadLineups = (gw) => {
    gw = gw || props.gw;
    props
      .fetch("/apiv2/decision/allMLBLineups?gw=" + gw + "&manager=" + getUsername() + "&draftId=" + props.draftId)
      .then((response) => response.json())
      .then((res) => {
        if (res !== null) {
          setLineups(res);
        }
      })
      .catch(errorCatcher());
  };

  return (
    <div className={"grid 2xl:grid-cols-3 grid-cols-1 lg:grid-cols-2 gap-4 pb-8"}>
      {lineups.map((lineup, index) => {
        return (
          <BaseballLineupBuilderLineup
            readOnly={true}
            divName={lineup.division?.displayName}
            divId={lineup.division?.divisionType}
            division={lineup.division}
            lineup={lineup.results}
            divisionStats={lineup.divisionStats}
          />
        );
      })}
    </div>
  );
}

export default withUser(AllMLBLineups);
