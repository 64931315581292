import React from "react";
import UnknownClub from "../../img/unknown_club.png";
import NeutralPbp from "../../img/neutral-pbp.svg";

function BasketballPlayByPlay(props) {
  const pbp = props.pbp;

  let thePlays = [];
  if (pbp.ot !== null) {
    let ot = [...pbp.ot];
    ot = ot.reverse();
    thePlays = thePlays.concat(ot);
  }
  if (pbp.quarter4 !== null) {
    let q4 = [...pbp.quarter4];
    q4 = q4.reverse();
    thePlays = thePlays.concat(q4);
  }
  if (pbp.quarter3 !== null) {
    let q3 = [...pbp.quarter3];
    q3 = q3.reverse();
    thePlays = thePlays.concat(q3);
  }
  if (pbp.quarter2 !== null) {
    let q2 = [...pbp.quarter2];
    q2 = q2.reverse();
    thePlays = thePlays.concat(q2);
  }
  if (pbp.quarter1 !== null) {
    let q1 = [...pbp.quarter1];
    q1 = q1.reverse();
    thePlays = thePlays.concat(q1);
  }

  const home = props.home;
  const away = props.away;

  const homeLogo = home?.PictureUrl;
  const awayLogo = away?.PictureUrl;

  return (
    <div>
      {thePlays.map((p, i) => {
        if (i >= 10) return null;
        if (p === null) return null;
        let rounded = "";
        let border = " border-b border-grey-e5";
        if (i === 0) {
          rounded = "rounded-t-lg ";
        } else if (i === 9) {
          rounded = "rounded-b-lg ";
          border = "";
        }
        let isHome = home.TeamId === p.teamId;
        let isAway = away.TeamId === p.teamId;
        let neutral = !isHome && !isAway;
        let homeStyle = "font-semibold";
        let awayStyle = "font-medium text-textGrey3";
        if (p.homeScore < p.awayScore) {
          homeStyle = "font-medium text-textGrey3";
          awayStyle = "font-semibold";
        }
        if (p.homeScore === p.awayScore) {
          awayStyle = "font-semibold";
        }
        let pbpStyle = "text-sm font-medium";
        if (p.type === "twopointmade" || p.type === "threepointmade" || p.type === "freethrowmade") {
          pbpStyle = "text-sm font-bold";
        }
        let pointsScored = "";
        if (p.type === "twopointmade") {
          pointsScored = "+2";
        } else if (p.type === "threepointmade") {
          pointsScored = "+3";
        } else if (p.type === "freethrowmade") {
          pointsScored = "+1";
        }
        if (pointsScored !== "") {
          if (isHome) {
            homeStyle = "text-white font-bold";
            awayStyle = "font-medium text-textGrey3";
          } else {
            awayStyle = "text-white font-bold";
            homeStyle = "font-medium text-textGrey3";
          }
        }

        return (
          <div className={rounded + "flex flex-row bg-white py-3 px-2" + border}>
            <div className={"w-1/12 self-center flex flex-row justify-center"}>
              {isHome && (
                <img
                  src={homeLogo === "" || homeLogo === null ? UnknownClub : homeLogo}
                  className={"w-6 h-6 rounded-full object-contain"}
                />
              )}
              {isAway && <img src={awayLogo === "" || awayLogo === null ? UnknownClub : awayLogo} className={"w-6 h-6 rounded-full"} />}
              {neutral && <img src={NeutralPbp} className={"w-6 h-6 rounded-full"} />}
            </div>
            <div className={"w-10/12 flex flex-col space-y-1"}>
              <p className={pbpStyle}>{p.description}</p>
              <p className={"text-xs font-semibold text-textGrey3"}>{p.clock}</p>
            </div>
            {pointsScored === "" && (
              <div className={"w-1/12 self-center flex flex-row justify-center"}>
                <p className={"font-semibold text-xs"}>
                  <span className={homeStyle}>{p.homeScore}</span>
                  <span className={"font-medium text-textGrey3"}>-</span>
                  <span className={awayStyle}>{p.awayScore}</span>
                </p>
              </div>
            )}
            {pointsScored !== "" && (
              <div className={"w-1/12 self-center flex flex-col items-center rounded-lg"}>
                <div className={"px-2 py-1 bg-brand-black w-full text-center rounded-t-lg"}>
                  <p className={"font-semibold text-xs"}>
                    <span className={homeStyle}>{p.homeScore}</span>
                    <span className={"font-medium text-textGrey3"}>-</span>
                    <span className={awayStyle}>{p.awayScore}</span>
                  </p>
                </div>
                <div style={{ backgroundColor: "#D4F1E0" }} className={"rounded-b-lg w-full px-2 py-1"}>
                  <p className={"font-semibold text-xs text-center text-green-3"}>{pointsScored}</p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default BasketballPlayByPlay;
