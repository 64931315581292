import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import React, { useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "@material-design-icons/svg/filled/search.svg";

export const SearchInput = (props) => {
  const { onChange, search, onReset, renderOptions, placeholder, onFocus, onBlur } = props;
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);
  const adapting = renderOptions?.adapting || false;
  const focusWidth = adapting ? renderOptions?.focusWidth || "w-full sm:w-80" : "";
  const blurWidth = adapting ? renderOptions?.blurWidth || "w-full sm:w-40" : "";
  const placeholderText = placeholder || "Search";
  return (
    <div
      className={`relative bg-surface-container rounded-3xl flex flex-row gap-2 overflow-hidden px-2 border h-full ${
        focus ? `border-primary ${focusWidth}` : `border-surface-container ${blurWidth}`
      } ${adapting ? "transition-width transition-slowest ease" : ""}`}
      onClick={() => {
        setFocus(true);
        ref?.current?.focus();
        onFocus && onFocus();
      }}
    >
      <div className="flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 fill-on-surface-variant" />
      </div>
      {search && onReset && (
        <div
          className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
          onClick={() => {
            setFocus(true);
            ref?.current?.focus();
            onReset();
            onFocus && onFocus();
          }}
        >
          <img src={CloseOutlineIcon} className="opacity-80 h-4 w-4" aria-hidden="true" />
        </div>
      )}
      <input
        ref={ref}
        type="text"
        onChange={onChange}
        value={search}
        className={`w-full block p-0 bg-surface-container font-medium text-sm border-0 focus:ring-0 placeholder-on-surface-variant ${
          search !== "" ? "text-on-surface" : "text-on-surface-variant"
        }`}
        placeholder={placeholderText}
        onFocus={() => {
          setFocus(true);
          onFocus && onFocus();
        }}
        onBlur={() => {
          setFocus(false);
          onBlur && onBlur();
        }}
      />
    </div>
  );
};
