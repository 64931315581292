import React, { useState } from "react";
import { withUser } from "../../userContext";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import WatchlistsBanner from "../../img/watchlists-banner.png";
import WatchlistsListing from "./myWatchlists";
import CommunityWatchlists from "./communityWatchlists";
import TrendingWatchlists from "./trendingWatchlists";
import NewWatchlistModal from "./newWatchlistModal";
import { Link, withRouter } from "react-router-dom";
import PriceAlerts from "./priceAlerts";
import { PriceAlertsSection } from "./priceAlertsSection";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";

function Watchlists(props) {
  const activeTab = props.match.params.tab || "overview";
  const inactiveClass =
    "text-base border-b-4 border-brand-black py-2 px-0 block font-medium text-textGrey4 hover:text-white focus:outline-none";
  const activeClass = "text-base border-b-4 border-brand py-2 px-0 font-medium focus:outline-none";
  const [newWatchlistOpen, setNewWatchlistOpen] = useState(false);

  const onCreateWatchlist = (newWatchlist) => {
    props.history.push(`/watchlists/id/${newWatchlist.id}`);
  };

  return (
    <div>
      <div
        className={"bg-white flex flex-col "}
        style={{
          backgroundImage: "url('" + WatchlistsBanner + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "180px",
        }}
      >
        <div className={"flex flex-row py-6 " + GLOBAL_MARGIN_NO_BG_NO_PAD} style={{ height: "180px" }}>
          <div className={"flex flex-row w-6/12 self-center justify-start"}>
            <p className={"text-4xl font-headers font-semibold text-white text-left"}>Watchlists</p>
          </div>
          <div className={"w-6/12 self-center flex flex-row justify-end"}>
            <button
              className={
                "bg-brand text-white p-2 pl-8 pr-8 text-sm font-medium rounded-md focus:outline-none hover:bg-brand-lightWatchlist"
              }
              onClick={() => setNewWatchlistOpen(true)}
            >
              + New watchlist
            </button>
          </div>
        </div>
      </div>
      <div className="bg-brand-black">
        <div className={"xl:ml-16 mx-auto mb-6 text-focus " + GLOBAL_MARGIN_NO_BG_NO_PAD}>
          <nav className="lg:flex lg:flex-row lg:block grid grid-cols-2 grid-rows-2 gap-10 pl-2">
            <Link to={"/watchlists/overview"}>
              <button className={activeTab === "overview" ? activeClass : inactiveClass}>Overview</button>
            </Link>
            <Link to={"/watchlists/my-watchlists"}>
              <button className={activeTab === "my-watchlists" ? activeClass : inactiveClass}>My watchlists</button>
            </Link>
            <Link to={"/watchlists/community"}>
              <button className={activeTab === "community" ? activeClass : inactiveClass}>Community watchlists</button>
            </Link>
            <Link to={"/watchlists/price-alerts"}>
              <button className={activeTab === "price-alerts" ? activeClass : inactiveClass}>Price alerts</button>
            </Link>
          </nav>
        </div>
      </div>
      <div>
        <div className="lg:mx-20 mx-4 md:mx-12 xl:w-11/12 2xl:w-11/12 3xl:w-10/12 xl:mx-auto 4xl:w-8/12">
          {activeTab === "overview" && (
            <div>
              <WatchlistsListing limitOwnedLines={1} limitFollowedLines={1} />
              {isFree(props.user.tier) && (
                <UpgradeLimitBox
                  className={"bg-white bg-upgrade-bg-watchlists bg-cover h-upgrade-banner-l w-full my-4"}
                  title={"Want more watchlists?"}
                  description={"Become a Star member to unlock unlimited watchlists"}
                />
              )}
              <div className="grid grid-cols-1 lg:grid-cols-8 gap-4">
                <div className="lg:col-span-4 xl:col-span-5 2xl:col-span-6 w-full">
                  <CommunityWatchlists displayTitle limit={50} />
                </div>
                <div className="lg:col-span-4 xl:col-span-3 2xl:col-span-2 w-full">
                  <TrendingWatchlists limit={5} />
                </div>
              </div>
            </div>
          )}
          {activeTab === "my-watchlists" && (
            <>
              {isFree(props.user.tier) && (
                <UpgradeLimitBox
                  className={"bg-white bg-upgrade-bg-watchlists bg-cover h-upgrade-banner-l w-full my-4"}
                  title={"Want more watchlists?"}
                  description={"Become a Star member to unlock unlimited watchlists"}
                />
              )}
              <WatchlistsListing displayDefaults displayHeader limitOwnedLines={5} limitFollowedLines={5} />
            </>
          )}
          {activeTab === "community" && <CommunityWatchlists displayOrder displaySportFilter displayTypeFilter limit={500} />}
          {activeTab === "price-alerts" && (
            <div>
              <PriceAlertsSection />
            </div>
          )}
        </div>
      </div>
      <NewWatchlistModal open={newWatchlistOpen} onCreate={onCreateWatchlist} onClose={() => setNewWatchlistOpen(false)} />
    </div>
  );
}

export default withRouter(withUser(Watchlists));
