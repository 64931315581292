import React, { useState, useEffect } from "react";
import clsx from "clsx";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { getScarcityInfo } from "../util/scarcities";
import { abbrv_football_positions } from "../util/positions";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import SelectSearch from "react-select-search";
import WatchlistMultiplePicker from "../watchlists/watchlistMultiplePicker";
import NewCardIcon from "../util/newCardIcon";
import { formatPercentage } from "../util/formatGrowth";
import BronzeMedal from "../../img/bronze-medal.svg";
import SilverMedal from "../../img/silver-medal.svg";
import GoldMedal from "../../img/gold-medal.svg";
import useDebounce from "../../hooks/useDebounce";
import EmptyAvatar from "../../img/empty-avatar.svg";
import { withJSONPagination, getPaginatedJSONResponse } from "../util/pagination";
import { checkSecondUnitShouldBeDisplayed, findPriceForUserUnit } from "../util/formatMoney";
import { HiddableItem, ToggleYourRewards, SortableColumn, SHOW_REWARDS_ID, ITEMS_PER_PAGE } from "./newRewardsCardsSection";
import { SelectWithIcon } from "../util/select";

const boxTypeKey = (type) => `ProbabilisticBundle:limited-${type}:0`;

function StatsBoxesBox(props) {
  let value = props.value;
  let type = props.type;
  let iconUrl = props.iconUrl;
  const label = {
    premium: "Premium boxes",
    standard: "Standard boxes",
    lite: "Lite boxes",
  };
  return (
    <div
      className={clsx(
        type === "all" ? "text-on-surface" : "text-on-surface-variant",
        "bg-surface-container p-4 flex flex-col justify-center items-center space-y-2 rounded-xl h-full",
      )}
    >
      <p className={"text-3xl text-center font-headers font-bold"}>{value}</p>
      <div className="flex items-center gap-2 text-base text-center font-semibold">
        {iconUrl && <img src={iconUrl} className="w-6 h-6" />}
        <span>{type === "all" ? "Boxes" : label[type]}</span>
      </div>
    </div>
  );
}

function ScarcityTag(props) {
  const { color, bgColor } = getScarcityInfo(props.scarcity, props.sport);
  return (
    <div
      className={`
        w-min flex gap-2 justify-center items-center
        text-xs font-semibold whitespace-nowrap px-2 py-0.5 rounded-full
        ${color} ${bgColor} ${props?.className}
      `}
    >
      {props?.icon && <img src={props.icon} />}
      {props.children}
    </div>
  );
}

const tierOptions = [...Array(5).keys()].map((i) => {
  if (i === 0) {
    return {
      name: "Star",
      value: 0,
    };
  }
  return {
    name: `Tier ${i}`,
    value: i,
  };
});
const defaultFilters = {
  tiers: "",
  competition: "all",
  playerNameSearch: "",
  managerNameSearch: "",
};
const defaultStats = {
  stats: {
    LITE: 0,
    STANDARD: 0,
    PREMIUM: 0,
  },
};

const BoxesSection = (props) => {
  const defaultShowRewards = !!localStorage.getItem(SHOW_REWARDS_ID);

  const [stats, setStats] = useState(defaultStats);
  const [compAndStatsLoading, setCompAndStatsLoading] = useState(false);
  const [boxes, setBoxes] = useState([]);
  const [boxesLoading, setBoxesLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [watchlists, setWatchlists] = useState({});
  const [competitions, setCompetitions] = useState(null);
  const [showRewards, setShowRewards] = useState(defaultShowRewards);
  const [managerNoPicture, setManagerNoPicture] = useState({});
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState(["rank", "asc"]);

  const getParams = () => {
    const params = new URLSearchParams();

    params.append("sport", props.sport);
    params.append("sort", order.join(","));

    if (filters?.playerNameSearch) params.append("playerQuery", filters.playerNameSearch);
    if (filters?.managerNameSearch) params.append("managerQuery", filters.managerNameSearch);

    if (filters.tiers) filters.tiers.forEach((tier) => params.append("tiers", tier));

    const watchlistKeys = Object.keys(watchlists);
    if (watchlistKeys.length) watchlistKeys.forEach((wId) => params.append("watchlists", wId));

    return params.toString();
  };

  const isFootball = props.sport === "sr_football";
  const hasFetchedComp = !compAndStatsLoading && !!competitions;

  const url = `/apiv2/rewards/gw/${props.gameWeek}/${filters.competition}/boxes?${getParams()}`;
  const fetchBoxes = async () =>
    await props
      .fetchApi(url, {
        method: "GET",
        headers: withJSONPagination(offset, ITEMS_PER_PAGE),
      })
      .then((res) => getPaginatedJSONResponse(res))
      .catch((e) => console.error("Fetching error: ", e));

  const debouncedFetch = useDebounce(() => {
    fetchBoxes().then(({ res, hasNext }) => {
      setOffset(0);
      setHasMore(hasNext);
      setBoxes([...(res.rewardsBoxes ? res.rewardsBoxes : [])]);
      setBoxesLoading(false);
    });
  }, 300);

  useEffect(() => {
    const fetchCompAndStats = async () => {
      const fetchCompetitions = props.fetchApi("/apiv2/rewards/gw/" + props.gameWeek + "/all/boxes/competitions?sport=" + props.sport, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      });
      const fetchStats = props.fetchApi("/apiv2/rewards/gw/" + props.gameWeek + "/all/boxes/stats?sport=" + props.sport, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      });

      const [competitionResponse, statsResponse] = await Promise.all([fetchCompetitions, fetchStats]);
      const competitionData = await competitionResponse.json();
      const statsData = await statsResponse.json();
      setCompAndStatsLoading(false);
      setCompetitions(competitionData.competitions ?? []);
      setStats(statsData);
    };

    setCompAndStatsLoading(true);
    fetchCompAndStats();
  }, [props.gameWeek, props.sport]);

  useEffect(() => {
    if (hasFetchedComp) {
      debouncedFetch();
    }
  }, [filters?.playerNameSearch, filters?.managerNameSearch]);

  useEffect(() => {
    if (hasFetchedComp) {
      setBoxesLoading(true);
      fetchBoxes().then(({ res, hasNext }) => {
        setOffset(0);
        setHasMore(hasNext);
        setBoxes([...(res?.rewardsBoxes ? res.rewardsBoxes : [])]);
        setBoxesLoading(false);
      });
    }
  }, [order, watchlists, filters.competition, filters.tiers]);

  useEffect(() => {
    if (!compAndStatsLoading) {
      if (offset === 0) {
        setBoxesLoading(true);
      }
      fetchBoxes().then(({ res, hasNext }) => {
        setBoxes([...(offset === 0 ? [] : boxes), ...(res?.rewardsBoxes ? res.rewardsBoxes : [])]);
        setHasMore(hasNext);
        setBoxesLoading(false);
      });
    }
  }, [compAndStatsLoading, props.sport, offset]);

  const compOptions = [
    {
      label: "All competitions",
      value: "all",
    },
    ...(competitions ?? []).map((competition) => ({
      label: competition.displayName,
      value: competition.divisionId,
      icon: competition.logo,
    })),
  ];

  const onFilter = (value, key) => {
    setBoxesLoading(true);
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const onSelectWatchlist = (watchlist) => {
    let newWatchlists = { ...watchlists };
    if (watchlists[watchlist.id]) {
      delete newWatchlists[watchlist.id];
    } else {
      newWatchlists[watchlist.id] = watchlist;
    }
    setWatchlists(newWatchlists);
  };

  const onSort = (name) => {
    setBoxesLoading(true);
    setOrder((prev) => {
      if (prev[0] !== name) return [name, "asc"];
      return [name, prev[1] === "desc" ? "asc" : "desc"];
    });
  };

  const onLoadMore = () => {
    setOffset((prev) => prev + ITEMS_PER_PAGE);
  };

  const onToggleShowRewards = () => {
    if (showRewards) {
      localStorage.removeItem(SHOW_REWARDS_ID);
      setShowRewards(false);
    } else {
      localStorage.setItem(SHOW_REWARDS_ID, true);
      setShowRewards(true);
    }
  };

  const rankLabel = (rank) => {
    const imgClassName = "w-6 h-6 mx-auto";
    if (rank === 1) return <img src={GoldMedal} className={imgClassName} />;
    if (rank === 2) return <img src={SilverMedal} className={imgClassName} />;
    if (rank === 3) return <img src={BronzeMedal} className={imgClassName} />;
    return rank;
  };

  return compAndStatsLoading ? (
    <div className="py-10">
      <SDLoading />
    </div>
  ) : (
    <div className={GLOBAL_MARGIN_NO_BG_NO_PAD + " mt-4 space-y-4 z-0 pb-10"}>
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"}>
        <StatsBoxesBox value={stats.stats.PREMIUM + stats.stats.STANDARD + stats.stats.LITE} type="all" />
        <StatsBoxesBox value={stats.stats.PREMIUM} type="premium" iconUrl={stats?.mapbox?.[boxTypeKey("premium")].iconUrl} />
        <StatsBoxesBox value={stats.stats.STANDARD} type="standard" iconUrl={stats?.mapbox?.[boxTypeKey("standard")].iconUrl} />
        <StatsBoxesBox value={stats.stats.LITE} type="lite" iconUrl={stats?.mapbox?.[boxTypeKey("lite")].iconUrl} />
      </div>
      <div>
        <div className="flex flex-col xl:flex-row justify-between gap-4">
          <div className="flex flex-col md:flex-row gap-4">
            <SelectWithIcon
              options={compOptions}
              selectedValue={filters.competition}
              onChange={({ value }) => onFilter(value, "competition")}
              containerClasses="rounded-md md:w-60"
            />
            <div className="flex-1">
              <SelectSearch
                multiple
                value={filters.tiers}
                printOptions="on-focus"
                placeholder="Filter by tier"
                onChange={(v) => onFilter(v, "tiers")}
                options={tierOptions}
                closeOnSelect
              />
            </div>
            <input
              className="text-sm text-textGrey3 font-medium truncate flex-1 focus:ring-brand block w-full py-2 rounded-md px-4 shadow sm:text-sm border-grey-e5"
              value={filters?.playerNameSearch}
              placeholder={"Filter by player name"}
              onChange={(e) => onFilter(e.target.value, "playerNameSearch")}
            />
            <input
              className="text-sm text-textGrey3 font-medium truncate flex-1 focus:ring-brand block w-full py-2 rounded-md px-4 shadow sm:text-sm border-grey-e5"
              value={filters?.managerNameSearch}
              placeholder={"Filter by manager name"}
              onChange={(e) => onFilter(e.target.value, "managerNameSearch")}
            />
          </div>
          <WatchlistMultiplePicker
            className="md:w-80"
            types={["player", "manager"]}
            fetchOnMount={true}
            options={{
              pinListFirst: true,
              defaultListFirst: true,
              followedListFirst: true,
              ownedListFirst: true,
            }}
            size={"100%"}
            placeholder={"Filter by watchlist(s)"}
            selected={watchlists}
            onSelect={onSelectWatchlist}
          />
        </div>
      </div>
      {boxesLoading ? (
        <div className="py-10">
          <SDLoading />
        </div>
      ) : (
        <div>
          <InfiniteScroll
            className="w-full overflow-x-scroll"
            dataLength={boxes.length}
            scrollableTarget={"window"}
            next={onLoadMore}
            hasMore={hasMore}
            useWindow={true}
            loader={<SDLoading />}
          >
            <table className={"border-collapse overflow-hidden rounded-lg table-fixed whitespace-no-wrap mx-auto min-w-full"}>
              <thead>
                <tr className="text-center bg-surface-container-high text-on-surface-variant h-12 font-semibold">
                  <th onClick={() => onSort("rank")} className="cursor-pointer text-left text-center text-xs w-max">
                    <SortableColumn className="px-2" title="Rank" isSorting={order[0] === "rank"} isOrderAsc={order[1] === "asc"} />
                  </th>
                  <th className="w-px">
                    <div className="h-8 w-px bg-outline-variant" />
                  </th>
                  <th className="py-3 px-4 text-left text-xs lg:w-1/4">Manager</th>
                  <th className="py-3 px-1 text-left text-xs text-center">Box type</th>
                  <th className="py-3 px-1 text-left text-xs text-center lg:w-1/5">Box reward</th>
                  <th onClick={() => onSort("tier")} className="cursor-pointer py-3 px-1 text-left text-xs text-center">
                    <SortableColumn title="Tier" isSorting={order[0] === "tier"} isOrderAsc={order[1] === "asc"} />
                  </th>
                  <th onClick={() => onSort("valuation")} className="cursor-pointer py-3 px-4 text-left text-xs text-center">
                    <SortableColumn title="Valuation" isSorting={order[0] === "valuation"} isOrderAsc={order[1] === "asc"} />
                  </th>
                  <th onClick={() => onSort("probability")} className="cursor-pointer py-3 px-1 text-left text-xs text-center">
                    <SortableColumn title="Odd" isSorting={order[0] === "probability"} isOrderAsc={order[1] === "asc"} />
                  </th>
                  <th className="py-3 px-4 text-left text-xs text-center">Competition</th>
                  <th className="w-12" />
                </tr>
              </thead>
              <tbody className="space-y-2 bg-surface-container text-on-surface">
                {!boxes.length ? (
                  <tr>
                    <td colSpan="9" className="text-center text-sm p-4 w-full">
                      No result was found on the selected gameweek or with the selected filters.
                    </td>
                  </tr>
                ) : (
                  boxes.map((box) => {
                    let lineup = box.lineup;
                    let bundleItem = box.probabilisticBundleItems?.[0];
                    let bundleCard = box.probabilisticBundleCards?.[0];
                    let player = bundleCard?.player;
                    let card = bundleCard?.card;
                    let valuation = bundleCard?.cardValuation;
                    let bundle = stats?.mapbox?.[box.probabilisticBundle?.probabilisticBundle];
                    let tier = bundleCard?.probabilisticBundleCard?.tier;
                    const isMe = box.winnerSlug === props.user.sorareSlug;
                    const price = valuation
                      ? findPriceForUserUnit(
                          valuation.Valuation,
                          {
                            eth: valuation.Eth,
                            eur: valuation.Eur,
                            gbp: valuation.Gbp,
                            usd: valuation.Usd,
                          },
                          props.user.preferredUnit,
                        )
                      : "";
                    const secondPrice = valuation
                      ? findPriceForUserUnit(
                          valuation.Valuation,
                          {
                            eth: valuation.Eth,
                            eur: valuation.Eur,
                            gbp: valuation.Gbp,
                            usd: valuation.Usd,
                          },
                          props.user.secondUnit,
                        )
                      : "";
                    const shouldDisplaySecondUnit = checkSecondUnitShouldBeDisplayed(props.user.preferredUnit, props.user.secondUnit);
                    const { supply } = card?.Scarcity ? getScarcityInfo(card.Scarcity, props.sport) : { supply: 0 };

                    const position = card ? (isFootball ? abbrv_football_positions[card.Position] : card.Position) : null;

                    return (
                      <tr
                        key={box.probabilisticBundle.id + box.probabilisticBundle.lineupId + box.winnerSlug}
                        className="h-16 py-2 border-t border-outline-variant"
                      >
                        <td className="text-center font-semibold text-base align-middle px-4 md:px-0">{rankLabel(lineup.Rank)}</td>
                        <td className="w-px">
                          <div className="h-8 w-px bg-outline-variant" />
                        </td>
                        <td className="self-center font-semibold text-sm px-2 lg:px-4">
                          <div className="flex gap-2 items-center w-max">
                            <a href={`/manager/${box.winnerSlug}`}>
                              <img
                                src={managerNoPicture[box.winnerSlug] || !box?.winnerPicUrl ? EmptyAvatar : box.winnerPicUrl}
                                onError={() => setManagerNoPicture((prev) => ({ ...prev, [box.winnerSlug]: true }))}
                                className="rounded-full w-10 h-10 flex-shrink-0"
                              />
                            </a>
                            <div className="flex flex-col gap-1 flex-shrink-0">
                              <a href={`/manager/${box.winnerSlug}`}>
                                <span>{box.winner}</span>
                              </a>
                              {isMe && <ToggleYourRewards showRewards={showRewards} onToggleShowRewards={onToggleShowRewards} />}
                            </div>
                          </div>
                        </td>
                        <td className={"self-center font-semibold text-sm text-center"}>
                          {bundle ? (
                            <div className="flex items-center gap-1">
                              <img src={bundle?.iconUrl} className="w-4 h-4" />
                              <span className="whitespace-nowrap">{`${bundle?.quality.charAt(0)}${bundle?.quality
                                .slice(1)
                                .toLowerCase()} Box`}</span>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-center">
                          <HiddableItem isMe={isMe} showReward={showRewards} hiddenItemClassName="w-32">
                            {card ? (
                              <div className="flex items-center gap-2 px-4 lg:pl-8">
                                <a href={"/player/" + player?.PlayerId}>
                                  <div className="bg-transparent-inverse-surface-low bg-opacity-10 w-10 h-10 rounded-full flex items-center justify-center flex-shrink-0">
                                    <img src={player.Avatar} className="rounded-full h-10" />
                                  </div>
                                </a>
                                <div className={"flex flex-col gap-1"}>
                                  <div className="flex items-center gap-2">
                                    <a href={"/player/" + player?.PlayerId}>
                                      <p className={"font-semibold text-sm truncate"}>{player?.DisplayName}</p>
                                    </a>
                                    <img
                                      className="object-contain object-center h-4 w-4 flex-shrink-0"
                                      src={bundleCard?.cardTeamLogoURL}
                                      alt={bundleCard?.cardTeamDisplayName}
                                    />
                                  </div>
                                  <ScarcityTag sport={props.sport} scarcity={card.Scarcity}>
                                    <div className="inline">
                                      <a
                                        href={"/card/" + card.TokenId}
                                        target="_blank"
                                        rel="noreferrer"
                                      >{`${card.SerialNumber}/${supply}`}</a>
                                      {` · ${card.Season} · ${position}`}
                                    </div>
                                  </ScarcityTag>
                                </div>
                              </div>
                            ) : bundleItem ? (
                              <ScarcityTag
                                className="mx-auto"
                                sport={props.sport}
                                scarcity={bundleItem.itemName.replace(/^.*\s(\w+)$/, "$1")}
                              >
                                {`${bundleItem.quantity}x ${bundleItem.itemName.replace(/\s-\s\w+$/, "")}`}
                              </ScarcityTag>
                            ) : (
                              "-"
                            )}
                          </HiddableItem>
                        </td>
                        <td className={"self-center font-semibold text-sm text-center"}>
                          <HiddableItem isMe={isMe} showReward={showRewards} hiddenItemClassName="w-20">
                            {card?.Scarcity ? (
                              <ScarcityTag className="mx-auto !w-16" sport={props.sport} scarcity={card.Scarcity}>
                                <div className="w-2">
                                  <NewCardIcon scarcity={box.Scarcity} />
                                </div>
                                <span>{tier === 0 ? "Star" : `Tier ${tier}`}</span>
                              </ScarcityTag>
                            ) : (
                              "-"
                            )}
                          </HiddableItem>
                        </td>
                        <td className={"self-center font-semibold text-center text-sm"}>
                          <HiddableItem isMe={isMe} showReward={showRewards} hiddenItemClassName="w-10">
                            {card ? (
                              <div>
                                <p className={"font-semibold text-base text-center"}>{price}</p>
                                {secondPrice !== "" && shouldDisplaySecondUnit && (
                                  <p className={"font-semibold text-xs text-textGrey2 text-center"}>{secondPrice}</p>
                                )}
                              </div>
                            ) : (
                              "-"
                            )}
                          </HiddableItem>
                        </td>
                        <td className={"self-center font-semibold text-sm text-center lg:px-4"}>
                          {formatPercentage(box.probabilisticBundle.probability)}
                        </td>
                        <td className={"self-center font-semibold text-sm text-center whitespace-nowrap pl-2 lg:pl-0"}>
                          {box.compDisplayName}
                        </td>
                        <td />
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default BoxesSection;
