import React, { ButtonHTMLAttributes, useState } from "react";
import { ReactComponent as LockerIcon } from "../../img/locker-icon-no-color.svg";
import { ReactComponent as RocketIcon } from "../../img/rocket-icon.svg";
import clsx from "clsx";

export const LockerButton = (props) => {
  const { onClick } = props;
  const type = props.type || "square"; // or round
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={`flex justify-center items-center bg-surface-container-high p-3 ${
        onClick ? "hover:bg-containers-surface-focus-active-primarytint cursor-pointer" : ""
      } ${type === "square" ? "rounded" : "rounded-full"}`}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <LockerIcon className={`w-4 h-4 ${isHover && onClick ? "fill-primary" : "fill-outline"}`} />
    </div>
  );
};

export const RocketButton = (props) => {
  const { onClick } = props;
  return (
    <Button
      iconPosition="left"
      label={props.label}
      padding={"px-4 py-2"}
      icon={<RocketIcon className={"fill-on-surface"} />}
      onClick={() => onClick}
    />
  );
};

export const Button = (props) => {
  // Button with design system
  const disabled = props.disabled || false;
  const adapting = props.adapting || false;
  const iconPosition = props.iconPosition || "left";
  const tag = props.tag || null;
  const context = props.context || "primary";
  const textSize = props.textSize || "text-sm font-semibold";
  let textColor = "";
  const rounded = props.rounded || "rounded-full";
  const padding = props.padding || (props.label || props.children ? (props.icon ? "px-3 py-2.5" : "px-6 py-2") : "p-2");
  let buttonColors = "";
  if (disabled) {
    buttonColors = "bg-surface-disable text-on-disable opacity-40";
  } else {
    if (context === "primary") {
      textColor = props.textColor || "text-on-primary";
      buttonColors =
        "bg-primary hover:bg-containers-primary-hover focus:bg-containers-primary-focus-active active:bg-containers-primary-press";
    } else if (context === "secondary") {
      textColor = props.textColor || "text-primary";
      buttonColors = "bg-surface-container hover:bg-containers-surface-hover-primarytint active:bg-containers-surface-press-primarytint";
    } else if (context === "neutral") {
      textColor = props.textColor || "text-on-surface";
      buttonColors =
        "bg-surface-container hover:bg-containers-surface-hover-neutraltint focus:bg-containers-surface-focus-active-neutraltint active:bg-containers-surface-press-neutraltint";
    } else if (context === "dim") {
      textColor = props.textColor || "text-on-surface-variant";
      buttonColors =
        "bg-transparent-inverse-surface-low bg-opacity-10 hover:bg-containers-surface-transparent-inverse-surface-hover hover:bg-opacity-10 focus:bg-containers-surface-transparent-inverse-surface-hover focus:bg-opacity-10 active:bg-containers-surface-transparent-inverse-surface-hover active:bg-opacity-10";
    } else {
      // transparent
      textColor = props.textColor || "text-on-surface";
      buttonColors = "bg-transparent  hover:opacity-80";
    }
  }
  // Get from props all attributes that are handled by html buttons
  const buttonProps = Object.keys(props)
    .filter((key) => ["onClick", "disabled", "className", "title", "style", "data-tip", "data-delay-show"].includes(key))
    .reduce((obj, key) => {
      obj[key] = props[key];
      return obj;
    }, {});

  return (
    <button
      type={"button"}
      {...buttonProps}
      className={clsx(
        "inline-flex gap-2 items-center text-center justify-center focus:outline-none",
        adapting ? "w-full h-full" : "",
        textColor,
        textSize,
        padding,
        buttonColors,
        rounded,
        tag ? "relative" : "",
      )}
    >
      {tag}
      {iconPosition === "left" && props.icon}
      {props.label}
      {props.children}
      {iconPosition === "right" && props.icon}
    </button>
  );
};
