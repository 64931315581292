import React from "react";
import WarningIcon from "../../img/multiple_players_icon.svg";

function WarningTip(props) {
  return (
    <div className={"self-center cursor-pointer"} data-id={props.id} data-html={props.html} data-tip={props.tip}>
      <img src={WarningIcon} style={{ maxWidth: "inherit" }} />
    </div>
  );
}

export default WarningTip;
