import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { withUser } from "../../userContext";
import { withRouter } from "react-router-dom";
import WatchlistPicker from "./watchlistPicker";

function SelectWatchlistModal(props) {
  const { open, limit, types, sports, options, searchPlaceholder } = props;
  const title = props.title || "Select watchlist";

  const [selected, setSelected] = useState(undefined);

  const onCancel = () => {
    setSelected(undefined);
    props.onCancel && props.onCancel();
  };
  const onValidate = () => {
    selected && props.onValidate && props.onValidate(selected);
  };

  const canValidate = selected !== undefined;
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={onCancel}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={onCancel}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"w-auto mt-8"}>
                  <div className={"w-full border-b-2 border-grey-e5 pb-4"}>
                    <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>{title}</h2>
                  </div>
                  <div className={"mx-full mb-8 px-6 pt-6"}>
                    <WatchlistPicker
                      limit={limit}
                      types={types}
                      sports={sports}
                      options={options}
                      selected={selected}
                      onSelect={(w) => setSelected(w)}
                      placeholder={searchPlaceholder}
                    />
                    <div className={"flex flex-row mt-4"}>
                      <div className={"ml-auto mr-0 inline"}>
                        <button
                          disabled={!canValidate}
                          className={
                            "text-white p-2 px-16 text-sm font-medium rounded-lg " +
                            (canValidate
                              ? "bg-brand focus:outline-none hover:bg-brand-light cursor-pointer"
                              : "bg-brand-light cursor-default")
                          }
                          onClick={onValidate}
                        >
                          <span>Validate</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withRouter(withUser(SelectWatchlistModal));
