import React, { useEffect, useState } from "react";
import PopperPortal from "../players/helpers/popper";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";
import { ReactComponent as IconEventBusy } from "@material-design-icons/svg/filled/event_busy.svg";
import { ReactComponent as ValidIcon } from "../../img/icons-circle-check-v2.svg";
import { ReactComponent as InvalidIcon } from "../../img/icons-circle-invalid.svg";
import SorareScoreAverage from "./sorareScoreAverage";
import { card_editions_objects } from "../util/cardEditions";

export const LineupInvalidReasonIncomplete = "INCOMPLETE";
export const LineupInvalidReasonClubLimits = "CLUB_LIMITS";
export const LineupInvalidReasonSeasonLimits = "SEASON_LIMITS";
export const LineupInvalidReasonSeasonBonusLimits = "SEASON_BONUS_LIMITS";
export const LineupInvalidReasonScarcityLimits = "SCARCITY_LIMITS";
export const LineupInvalidReasonLegendsLimits = "LEGENDS_LIMITS";
export const LineupInvalidReasonCapLimits = "CAP_LIMITS";
export const LineupInvalidReasonCaptainLimits = "CAPTAIN_LIMITS";
export const LineupInvalidReasonPlayersFromSameTeamLimits = "PLAYERS_FROM_SAME_TEAM_LIMITS";
export const LineupInvalidReasonAtLeastCardsWithAverage = "AT_LEAST_CARDS_WITH_AVERAGE";
export const LineupInvalidReasonAtMostCardsWithAverage = "AT_MOST_CARDS_WITH_AVERAGE";
export const LineupInvalidReasonCardEditionsLimits = "CARD_EDITIONS_LIMITS";
export const LineupInvalidReasonNoSamePlayerTwice = "NO_SAME_PLAYER_TWICE";
export const LineupInvalidReasonCardNotOwned = "CARD_NOT_OWNED";
export const LineupInvalidReasonCardPositions = "CARD_POSITIONS";

export const labelInvalidReasons = {
  [LineupInvalidReasonIncomplete]: "Incomplete lineup",
  [LineupInvalidReasonClubLimits]: "Club limits not compliant",
  [LineupInvalidReasonSeasonLimits]: "Season rules not compliant",
  [LineupInvalidReasonSeasonBonusLimits]: "In-season rules not compliant",
  [LineupInvalidReasonScarcityLimits]: "Scarcity rules not compliant",
  [LineupInvalidReasonLegendsLimits]: "Legends rules not compliant",
  [LineupInvalidReasonCapLimits]: "Cap limit reached",
  [LineupInvalidReasonPlayersFromSameTeamLimits]: "2+ players from the same team",
  [LineupInvalidReasonAtLeastCardsWithAverage]: "Minimum player scores limits not compliant",
  [LineupInvalidReasonAtMostCardsWithAverage]: "Maximum player scores limits not compliant",
  [LineupInvalidReasonCardEditionsLimits]: "Card editions limits not compliant",
  [LineupInvalidReasonNoSamePlayerTwice]: "Same player twice in lineup",
  [LineupInvalidReasonCardNotOwned]: "At least one card not owned",
  [LineupInvalidReasonCaptainLimits]: "Captain not compliant",
  [LineupInvalidReasonCardPositions]: "At least one card is in a unauthorized lineup position",
};

const defaultLimitations = {
  scarcityLimitations: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  seasonLimitations: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  seasonBonusLimitations: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  captainLimitations: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  legends: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  sameTeam: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  noSamePlayerTwice: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  nbCards: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  clubCompetitions: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  nationCompetitions: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  domesticLeagues: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  clubLimits: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  atLeastCardsWithAverage: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  atMostCardsWithAverage: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  cap: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
  cardEditions: {
    show: false,
    isValid: false,
    isEmpty: true,
  },
};

export const RulesIndicator = (props) => {
  const { rules, lineup, loading, captain, mode, unavailableComp, onChange } = props;

  const [limitations, setLimitations] = useState(defaultLimitations);

  useEffect(() => {
    const scarcityLimitations = rules?.scarcityLimits;
    const seasonLimitations = rules?.seasonLimits;
    const seasonBonusLimitations = rules?.seasonBonusLimits;
    const maxSameTeam = rules?.maxSameTeam || 0;
    const withCaptain = rules?.withCaptain;
    const cap = rules?.cappedLimit;
    const captainAllowedScarcities = rules?.captainAllowedScarcities || [];
    const isLegend = rules?.isLegend;
    const legendsAllowedScarcities = rules?.legendsAllowedScarcities || [];
    const atLeastCardsWithAverage = rules?.atLeastCardsWithAverage;
    const atMostCardsWithAverage = rules?.atMostCardsWithAverage;
    const clubCompetitions = rules?.clubCompetitions || [];
    const nationCompetitions = rules?.internationalCompetitions || [];
    const domesticLeagues = rules?.domesticLeagues || [];
    const clubLimits = rules?.clubLimits;
    const cardEditionLimits = rules?.cardEditionLimits;
    let legendsScarcities = {};
    const legendsCountLimits = rules?.legendsCountLimits || {};
    let legendCount = 0;
    const nbCardsByScarcity = {};
    const nbCardsByCardEdition = {};
    const playersByTeam = {};
    const playersCount = {};
    const players = {};
    let amountCurrentSeason = 0;
    let amountSeasonBonus = 0;
    let hasEmptyPlace = false;
    let hasCaptain = captain !== undefined && captain !== null && captain.card.TokenId !== "";

    lineup.forEach((e) => {
      if (e?.card?.Scarcity) {
        nbCardsByScarcity[e?.card?.Scarcity] = (nbCardsByScarcity[e?.card?.Scarcity] || 0) + 1;
      }
      if (seasonLimitations && e?.card?.Season) {
        if (
          (seasonLimitations.season === 0 || e?.card.Season === seasonLimitations.season) &&
          (seasonLimitations.cardTeamSlugs === undefined ||
            seasonLimitations.cardTeamSlugs === null ||
            seasonLimitations.cardTeamSlugs.length === 0 ||
            seasonLimitations.cardTeamSlugs.includes(e?.card?.CardTeamId))
        ) {
          amountCurrentSeason++;
        }
      }
      if (seasonBonusLimitations && e?.card?.CardPower && e?.card?.CardPower.season && parseFloat(e?.card?.CardPower.season) > 0) {
        amountSeasonBonus++;
      }
      if (e?.player_team?.TeamId) {
        playersByTeam[e?.player_team?.TeamId] = [...(playersByTeam[e?.player_team?.TeamId] || []), e?.player];
      }
      if (e?.player.PlayerId) {
        players[e?.player.PlayerId] = e?.player;
        playersCount[e?.player.PlayerId] = (playersCount[e?.player.PlayerId] || 0) + 1;
      }
      if (e?.player.Legend) {
        legendCount++;
        legendsScarcities[e?.card?.Scarcity] = (legendsScarcities[e?.card?.Scarcity] || 0) + 1;
      }
      if (e?.card?.CardEdition) {
        nbCardsByCardEdition[e?.card?.CardEdition] = (nbCardsByCardEdition[e?.card?.CardEdition] || 0) + 1;
      }
      if (e === null) {
        hasEmptyPlace = true;
      }
    });
    const reachingPlayers = Object.keys(playersByTeam).filter((teamId) => playersByTeam[teamId].length > maxSameTeam) || [];
    const lineupPoints = lineup.reduce((acc, p) => acc + (p?.cost !== undefined ? p.cost : 0), 0);

    const isEmpty =
      lineup === undefined || lineup.length === 0 || lineup.filter((e) => e === null || e === undefined).length === lineup.length;

    const scarcityLimitsInfo = scarcityLimitations
      ? Object.entries(scarcityLimitations).map(([scarcity, { min, max }]) => {
          const nb = nbCardsByScarcity[scarcity] || 0;
          const scarcityLower = scarcity.toLowerCase();
          const scarcityNormalized = "scarcity-" + scarcityLower.replace(" ", "-") + "-on-surface-container";
          let isValid;
          let msg = [];
          const scarcityElem = <span className={`font-bold text-` + scarcityNormalized}>{scarcityLower}</span>;
          if (min === max) {
            isValid = nb === min;
            msg = (
              <span>
                exactly {min} {scarcityElem}
              </span>
            );
          } else if (min === 0 && max !== 0) {
            isValid = nb <= max;
            msg = (
              <span key={scarcity}>
                max {max} {scarcityElem}
              </span>
            );
          } else if (min !== 0 && max === 0) {
            isValid = nb >= min;
            msg = (
              <span>
                min {min} {scarcityElem}
              </span>
            );
          } else {
            isValid = nb >= min && nb <= max;
            msg = (
              <span>
                between {min}-{max} {scarcityElem}
              </span>
            );
          }
          return {
            isValid: isValid,
            msg: msg,
          };
        })
      : [
          {
            isValid: true,
            msg: "valid",
          },
        ];

    let seasonLimitationsInfo = {
      isValid: true,
      msg: "",
    };
    if (seasonLimitations) {
      const { min, max, season, cardTeamLeaguesDisplayNames, cardTeamDisplayNames } = seasonLimitations;
      const leaguesOrTeams =
        cardTeamLeaguesDisplayNames?.length > 0
          ? cardTeamLeaguesDisplayNames.join(", ")
          : cardTeamDisplayNames?.length > 0
          ? cardTeamDisplayNames.join(", ")
          : "";

      seasonLimitationsInfo.msg =
        "between " + min + "-" + max + " cards from " + season + " season" + (leaguesOrTeams ? " (" + leaguesOrTeams + ")" : "");
      seasonLimitationsInfo.isValid = amountCurrentSeason >= min && amountCurrentSeason <= max;
    }

    let seasonBonusLimitationsInfo = {
      isValid: true,
      msg: "",
    };
    if (seasonBonusLimitations) {
      const { min, max } = seasonBonusLimitations;
      seasonBonusLimitationsInfo.msg = "between " + min + " and " + max + " cards with a season bonus";
      seasonBonusLimitationsInfo.isValid = amountSeasonBonus >= min && amountSeasonBonus <= max;
    }

    let clubLimitsInfo = {
      isValid: true,
      msg: "",
    };
    if (clubLimits) {
      const { clubs, min, max } = clubLimits;
      const clubCount = clubs?.reduce((acc, club) => acc + (playersByTeam[club.slug]?.length || 0), 0) || 0;
      const clubNames = clubs?.map((c) => c.displayName).join(", ") || "";
      let isValid;
      let msg;
      if (min === max) {
        isValid = clubCount === min;
        if (min === 5) {
          msg = "only";
        } else {
          msg = `exactly ${min}`;
        }
      } else if (min === 0 && max !== 0) {
        isValid = clubCount <= max;
        msg = `max ${max}`;
      } else if (min !== 0 && max === 0) {
        isValid = clubCount >= min;
        msg = `min ${min}`;
      } else {
        isValid = clubCount >= min && clubCount <= max;
        msg = `between ${min}-${max}`;
      }
      clubLimitsInfo.isValid = isValid;
      clubLimitsInfo.msg = `${msg} ${clubNames} players`;
    }

    let cardEditionLimitsInfo = {
      isValid: true,
      msg: "",
    };
    if (cardEditionLimits) {
      const msgs = [];
      const valids = [];
      cardEditionLimits.forEach((limits) => {
        const { names, min, max } = limits;
        const editionCount = names.reduce((acc, name) => acc + (nbCardsByCardEdition[name] || 0), 0);
        const namesLabel = names.map((name) => {
          const edition = card_editions_objects.find((e) => e.value === name);
          return edition?.name || name;
        });
        let isValid;
        let msg;
        if (min === max) {
          isValid = editionCount === min;
          if (min === 5) {
            msg = "only";
          } else {
            msg = `exactly ${min}`;
          }
        } else if (min === 0 && max !== 0) {
          isValid = editionCount <= max;
          msg = `max ${max}`;
        } else if (min !== 0 && (max === 0 || max === 5)) {
          isValid = editionCount >= min;
          msg = `min ${min}`;
        } else {
          isValid = editionCount >= min && editionCount <= max;
          msg = `between ${min}-${max}`;
        }
        valids.push(isValid);
        msgs.push(`${msg} ${namesLabel.join(" or ")}`);
      });
      cardEditionLimitsInfo.isValid = valids.every((v) => v === true);
      cardEditionLimitsInfo.msg = msgs.join(", ");
    }

    let atLeastCardsWithAverageInfo = {
      isValid: true,
      msg: "",
    };
    if (atLeastCardsWithAverage) {
      const { count, type } = atLeastCardsWithAverage;
      const averagesInLineup = checkAveragesInLineup(lineup, atLeastCardsWithAverage);
      const scoreMsg = averagesInLineup.msg;
      const countInLineup = averagesInLineup.count;
      const msg = `min ${count} players with ${type.toUpperCase()} ${scoreMsg}`;
      atLeastCardsWithAverageInfo = {
        isValid: countInLineup >= count,
        msg: msg,
      };
    }

    let atMostCardsWithAverageInfo = {
      isValid: true,
      msg: "",
    };
    if (atMostCardsWithAverage) {
      const { count, type } = atMostCardsWithAverage;
      const averagesInLineup = checkAveragesInLineup(lineup, atMostCardsWithAverage);
      const scoreMsg = averagesInLineup.msg;
      const countInLineup = averagesInLineup.count;
      const msg = `max ${count} players with ${type.toUpperCase()} ${scoreMsg}`;
      atMostCardsWithAverageInfo = {
        isValid: countInLineup <= count,
        msg: msg,
      };
    }

    let legendCountLimitInfo = {
      isValid: true,
      msg: "",
    };
    if (legendsCountLimits !== undefined) {
      const { min, max } = legendsCountLimits;
      let isValid;
      let msg;
      if (min === max) {
        isValid = legendCount === min;
        msg = <span key={"legend-count"}>exactly {min}</span>;
      } else if (min === 0 && max !== 0) {
        isValid = legendCount <= max;
        msg = <span key={"legend-count"}>max {max}</span>;
      } else if (min !== 0 && max === 0) {
        isValid = legendCount >= min;
        msg = <span key={"legend-count"}>min {min}</span>;
      } else {
        isValid = legendCount >= min && legendCount <= max;
        msg = (
          <span key={"legend-count"}>
            between {min}-{max} cards
          </span>
        );
      }
      legendCountLimitInfo = {
        isValid: isValid,
        msg: msg,
      };
    }

    const legendsIsValid = isLegend
      ? (legendsAllowedScarcities === undefined ||
          legendsAllowedScarcities.length === 0 ||
          Object.keys(legendsScarcities).filter(
            (legendScarcity) => legendsAllowedScarcities.find((s) => s === legendScarcity) === undefined,
          ).length === 0) && // legends scarcity allowed
        legendCountLimitInfo.isValid
      : true;

    const capIsValid = cap ? lineupPoints <= cap : true;
    const scarcityLimitsValid =
      isEmpty ||
      scarcityLimitations === undefined ||
      scarcityLimitations === null ||
      scarcityLimitations.length === 0 ||
      scarcityLimitsInfo?.map((val) => val.isValid).every((val) => val === true);
    const captainLimitationsValid =
      !withCaptain ||
      captainAllowedScarcities.length === 0 ||
      (hasCaptain && captainAllowedScarcities.find((s) => s === captain.card.Scarcity) !== undefined);
    const sameTeamValid = maxSameTeam === 0 || reachingPlayers.length === 0;
    const noSamePlayerTwiceValid = (Object.values(playersCount).filter((count) => count >= 2) || []).length === 0;
    const nbCardsValid = isEmpty || (lineup.length === 5 && lineup.filter((e) => e === null || e === undefined).length === 0);
    const invalidReasons = {};
    if (!scarcityLimitsValid) {
      invalidReasons[LineupInvalidReasonScarcityLimits] = true;
    }
    if (!seasonLimitationsInfo.isValid) {
      invalidReasons[LineupInvalidReasonSeasonLimits] = true;
    }
    if (!seasonBonusLimitationsInfo.isValid) {
      invalidReasons[LineupInvalidReasonSeasonBonusLimits] = true;
    }
    if (!captainLimitationsValid) {
      invalidReasons[LineupInvalidReasonCaptainLimits] = true;
    }
    if (!legendsIsValid) {
      invalidReasons[LineupInvalidReasonLegendsLimits] = true;
    }
    if (!sameTeamValid) {
      invalidReasons[LineupInvalidReasonPlayersFromSameTeamLimits] = true;
    }
    if (!noSamePlayerTwiceValid) {
      invalidReasons[LineupInvalidReasonNoSamePlayerTwice] = true;
    }
    if (!nbCardsValid) {
      invalidReasons[LineupInvalidReasonIncomplete] = true;
    }
    if (!clubLimitsInfo.isValid) {
      invalidReasons[LineupInvalidReasonClubLimits] = true;
    }
    if (!atLeastCardsWithAverageInfo.isValid) {
      invalidReasons[LineupInvalidReasonAtLeastCardsWithAverage] = true;
    }
    if (!atMostCardsWithAverageInfo.isValid) {
      invalidReasons[LineupInvalidReasonAtMostCardsWithAverage] = true;
    }
    if (!capIsValid) {
      invalidReasons[LineupInvalidReasonCapLimits] = true;
    }
    if (!cardEditionLimitsInfo.isValid) {
      invalidReasons[LineupInvalidReasonCardEditionsLimits] = true;
    }
    const newLimitations = {
      scarcityLimitations: {
        show: true,
        isValid: scarcityLimitsValid,
        isEmpty: isEmpty,
        scarcityLimitsInfo,
      },
      seasonLimitations: {
        show: seasonLimitations !== undefined,
        isValid: seasonLimitationsInfo.isValid,
        isEmpty: isEmpty,
        seasonLimitsInfo: seasonLimitationsInfo,
      },
      seasonBonusLimitations: {
        show: seasonBonusLimitations !== undefined,
        isValid: seasonBonusLimitationsInfo.isValid,
        isEmpty: isEmpty,
        seasonLimitsInfo: seasonBonusLimitationsInfo,
      },
      captainLimitations: {
        show: withCaptain,
        isValid: captainLimitationsValid,
        isEmpty: isEmpty,
        captainScarcity: captain?.card?.Scarcity,
        captainAllowedScarcities: captainAllowedScarcities,
      },
      legends: {
        show: isLegend && (legendsAllowedScarcities !== undefined || legendsCountLimits !== undefined),
        isValid: legendsIsValid,
        isEmpty: isEmpty,
        legendsCountLimits,
        legendsAllowedScarcities,
        legendCountLimitInfo,
      },
      sameTeam: {
        show: maxSameTeam !== 0,
        isValid: sameTeamValid,
        isEmpty: isEmpty,
        max: maxSameTeam,
        reachingPlayers,
        playersByTeam,
      },
      noSamePlayerTwice: {
        show: true,
        isValid: noSamePlayerTwiceValid,
        isEmpty: isEmpty,
        players,
        playersCount,
      },
      nbCards: {
        show: true,
        isValid: nbCardsValid,
        isEmpty: isEmpty,
        requiredAmount: 5,
      },
      clubCompetitions: {
        show: clubCompetitions.length > 0,
        isValid: true,
        isEmpty: isEmpty,
        competitions: clubCompetitions?.map((c) => c.displayName),
      },
      nationCompetitions: {
        show: nationCompetitions.length > 0,
        isValid: true,
        isEmpty: isEmpty,
        competitions: nationCompetitions?.map((c) => c.displayName),
      },
      domesticLeagues: {
        show: domesticLeagues.length > 0,
        isValid: true,
        isEmpty: isEmpty,
        leagues: domesticLeagues?.map((c) => c.displayName),
      },
      clubLimits: {
        show: clubLimits !== undefined,
        isValid: clubLimitsInfo.isValid,
        isEmpty: isEmpty,
        clubLimitsInfo: clubLimitsInfo,
      },
      atLeastCardsWithAverage: {
        show: atLeastCardsWithAverage !== undefined,
        isValid: atLeastCardsWithAverageInfo.isValid,
        isEmpty: isEmpty,
        info: atLeastCardsWithAverageInfo,
      },
      atMostCardsWithAverage: {
        show: atMostCardsWithAverage !== undefined,
        isValid: atMostCardsWithAverageInfo.isValid,
        isEmpty: isEmpty,
        info: atMostCardsWithAverageInfo,
      },
      cap: {
        show: cap !== undefined && cap > 0,
        isValid: capIsValid,
        isEmpty: isEmpty,
        msg: cap ? `sum of the 5 players' cost must be ${cap} or below` : "",
      },
      cardEditions: {
        show: cardEditionLimits !== undefined,
        isValid: cardEditionLimitsInfo.isValid,
        isEmpty: isEmpty,
        cardEditionInfo: cardEditionLimitsInfo,
      },
    };
    const invalidReasonsArray = Object.keys(invalidReasons);
    const isValid = invalidReasonsArray.length === 0;

    const cappedInfo = {
      cap: rules?.cappedLimit || 0,
      points: lineupPoints,
    };

    setLimitations({
      ...newLimitations,
      isValid,
      isEmpty,
      cappedInfo,
      invalidReasons: invalidReasonsArray,
    });

    onChange && onChange(isValid, invalidReasonsArray);
  }, [rules, lineup, captain, mode, unavailableComp]);

  const content = (
    <RuleComponent
      mode={mode}
      unavailableComp={unavailableComp}
      oading={loading}
      isEmpty={limitations?.isEmpty}
      isValid={limitations?.isValid}
      capped={limitations?.cappedInfo}
      title={unavailableComp ? "Sorare did not open this tournament" : undefined}
    />
  );

  if (loading || unavailableComp) {
    return content;
  }

  return <RulesPopHoverIndicator limitations={limitations}>{content}</RulesPopHoverIndicator>;
};

const RuleComponent = (props) => {
  const { loading, isEmpty, isValid, capped, unavailableComp, title } = props;
  const mode = props.mode || "minimal";
  const isCapped = capped?.cap > 0;

  return (
    <div
      className={"rounded-lg bg-transparent-surface-container-high bg-opacity-70 py-1 px-3 flex flew-row justify-center items-center"}
      title={title}
    >
      <div className={"h-6 flex flew-row justify-center gap-2 items-center text-on-surface"}>
        {loading ? (
          <div role="status" className="w-5 h-5 animate-pulse">
            <div className="w-5 h-5 bg-grey-e0 rounded-full w-full"></div>
          </div>
        ) : unavailableComp ? (
          <IconEventBusy className={"h-5 w-5 fill-error"} />
        ) : isEmpty ? (
          <IconInfoHelp className={"h-5 w-5 fill-on-surface"} />
        ) : isValid ? (
          <ValidIcon className={"fill-quality-scale-very-good-on-surface-container h-5 w-5"} />
        ) : (
          <InvalidIcon className={"h-5 w-5 fill-error"} />
        )}
        {isCapped ? (
          <>
            <div className={"border-l border-transparent-inverse-surface-low border-opacity-10 h-4"}></div>
            <LineupPointsRemaining cap={capped.cap} points={capped.points} mode={mode} />
          </>
        ) : (
          <span className={"text-xs font-semibold text-on-surface"}>{unavailableComp ? "Disabled" : "Rules"}</span>
        )}
      </div>
    </div>
  );
};

const LineupPointsRemaining = (props) => {
  const { cap, points, mode } = props;
  let size = (((cap - points) * 100) / cap).toFixed(0) + "%";
  let bg = "bg-scarcity-super-rare-on-surface-container";
  if (cap < points) {
    bg = "bg-scarcity-rare-on-surface-container";
    size = "100%";
  }
  const remaining = cap - points;
  return (
    <div className={"flex flex-row gap-2"}>
      <div className={"w-6 h-6"}>
        <SorareScoreAverage fontSize={"lg"} avg={remaining} negative={remaining < 0} dontNormalize={true} />
      </div>
      {mode === "detailed" && (
        <div className={"w-32 flex flex-col justify-center items-center gap-1"}>
          <div className={"flex flex-row w-full justify-between text-xxxs xs:text-xxs font-semibold text-on-surface-variant"}>
            <p>Remaining</p>
            <p>/{cap}</p>
          </div>
          <div className={`cursor-pointer w-full ${bg} bg-opacity-10 rounded-full h-1 self-center`}>
            <div className={bg + " h-1 rounded-full"} style={{ width: size }} />
          </div>
        </div>
      )}
    </div>
  );
};

const RulesPopHoverIndicator = (props) => {
  const { children, limitations } = props;
  const [popHover, setPopHover] = useState(false);
  const displayPopHover = () => setPopHover(true);
  const hidePopHover = () => setPopHover(false);

  const playersReaching =
    limitations.sameTeam.reachingPlayers
      ?.map((teamId) => {
        const players = limitations.sameTeam.playersByTeam?.[teamId];
        if (!players) {
          return "";
        }
        return players.map((p) => p.DisplayName).join(" & ");
      })
      .join(" / ") || "";

  const playersAppearingMoreThanOnce = Object.entries(limitations.noSamePlayerTwice.players || {})
    .map(([playerId, player]) => {
      return {
        ...player,
        count: limitations.noSamePlayerTwice.playersCount[playerId],
      };
    })
    .filter((p) => p.count >= 2)
    .map((p) => p.count + " cards of " + p.DisplayName)
    .join(", ");

  const captainMainText = (
    <span>
      Captain: must be{" "}
      {limitations.captainLimitations.captainAllowedScarcities
        ?.map((e, idx) => {
          const lower = e.toLowerCase();
          const normalized = lower.replace(" ", "-");
          return (
            <span key={idx} className={"font-bold text-scarcity-" + normalized + "-on-surface-container"}>
              {lower}
            </span>
          );
        })
        .reduce((prev, curr) => (prev === null ? [curr] : [...prev, " or ", curr]), null)}
    </span>
  );
  const captainInvalidText = (
    <span>
      Currently{" "}
      {limitations.captainLimitations.captainScarcity ? (
        <span
          className={
            "font-bold text-scarcity-" +
            limitations.captainLimitations.captainScarcity?.toLowerCase()?.replace(" ", "-") +
            "-on-surface-container"
          }
        >
          {limitations.captainLimitations.captainScarcity?.toLowerCase()}
        </span>
      ) : (
        <span className={"font-bold"}>no captain selected</span>
      )}
    </span>
  );

  const scarcityLimitationsMainText = (
    <span>
      Scarcity:{" "}
      {limitations.scarcityLimitations.scarcityLimitsInfo
        ?.map((e) => e.msg)
        .reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)}
    </span>
  );

  const competitionClubsMainText = (
    <span>
      Competitions:{" "}
      {limitations.clubCompetitions.competitions?.reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)}
    </span>
  );

  const nationCompetitionsMainText = (
    <span>
      International competitions:{" "}
      {limitations.nationCompetitions.competitions?.reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)}
    </span>
  );

  const leaguesMainText = (
    <span>
      Leagues: {limitations.domesticLeagues.leagues?.reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)}
    </span>
  );

  const seasonLimitationsMainText = "Season: " + limitations.seasonLimitations?.seasonLimitsInfo?.msg;
  const seasonBonusLimitationsMainText = "Season bonus: " + limitations.seasonBonusLimitations?.seasonLimitsInfo?.msg;
  const clubLimitsMainText = "Clubs: " + limitations.clubLimits?.clubLimitsInfo?.msg;
  const cardEditionsMainText = "Editions: " + limitations.cardEditions?.cardEditionInfo?.msg;
  const atLeastCardsWithAverageMainText = "Average: " + limitations.atLeastCardsWithAverage?.info?.msg;
  const atMostCardsWithAverageMainText = "Average: " + limitations.atMostCardsWithAverage?.info?.msg;
  const capMainText = "Cap: " + limitations.cap?.msg;

  const legendsCountLimits = limitations.legends?.legendsCountLimits;
  const legendsAllowedScarcities = limitations.legends?.legendsAllowedScarcities;
  const legendMessages = [];
  if (legendsCountLimits) {
    const legendsCountLimitMsg = limitations.legends?.legendCountLimitInfo?.msg;
    legendMessages.push(legendsCountLimitMsg);
  }
  if (legendsAllowedScarcities) {
    const legendsAllowedScarcitiesMsg = (
      <span key={"legend-allowed-scarcities"}>
        {legendsAllowedScarcities
          .map((s) => {
            const lower = s.toLowerCase();
            const normalized = lower.replace(" ", "-");
            return (
              <span key={lower} className={"font-bold text-" + normalized}>
                {lower}
              </span>
            );
          })
          .reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)}{" "}
        scarcities allowed
      </span>
    );
    legendMessages.push(legendsAllowedScarcitiesMsg);
  }
  const legendMainText = (
    <span>Legends: {legendMessages.reduce((prev, curr) => (prev === null ? [curr] : [...prev, " - ", curr]), null)}</span>
  );

  return (
    <div
      className={"relative flex flex-col gap-1 justify-center self-center cursor-default h-full"}
      onMouseEnter={displayPopHover}
      onMouseLeave={hidePopHover}
    >
      {popHover && (
        <PopperPortal active={popHover}>
          <div
            className={
              "flex flex-col border border-transparent-inverse-surface-low border-opacity-10 isolate z-30 bg-surface-container rounded-md shadow-lg text-sm text-on-surface"
            }
            style={{ minWidth: "13em", maxWidth: "50em" }}
          >
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10 px-4 py-4"}>
              <h3 className={"m-0 font-headers"}>Rules</h3>
            </div>
            <div className={"p-4 flex flex-col gap-2 font-semibold font-normal"}>
              <RuleLine limitations={limitations.clubCompetitions} mainText={competitionClubsMainText} />
              <RuleLine limitations={limitations.clubLimits} mainText={clubLimitsMainText} />
              <RuleLine limitations={limitations.nationCompetitions} mainText={nationCompetitionsMainText} />
              <RuleLine limitations={limitations.domesticLeagues} mainText={leaguesMainText} />
              <RuleLine limitations={limitations.seasonLimitations} mainText={seasonLimitationsMainText} />
              <RuleLine limitations={limitations.seasonBonusLimitations} mainText={seasonBonusLimitationsMainText} />
              <RuleLine limitations={limitations.scarcityLimitations} mainText={scarcityLimitationsMainText} />
              <RuleLine limitations={limitations.captainLimitations} mainText={captainMainText} invalidText={captainInvalidText} />
              <RuleLine limitations={limitations.legends} mainText={legendMainText} />
              <RuleLine limitations={limitations.atLeastCardsWithAverage} mainText={atLeastCardsWithAverageMainText} />
              <RuleLine limitations={limitations.atMostCardsWithAverage} mainText={atMostCardsWithAverageMainText} />
              <RuleLine limitations={limitations.cardEditions} mainText={cardEditionsMainText} />
              <RuleLine limitations={limitations.cap} mainText={capMainText} />
              <RuleLine
                limitations={limitations.noSamePlayerTwice}
                mainText={"Players: no same player twice"}
                invalidText={playersAppearingMoreThanOnce}
              />
              <RuleLine
                limitations={limitations.sameTeam}
                mainText={`Teams: max ${limitations.sameTeam.max} players on the same team`}
                invalidText={playersReaching}
              />
              <RuleLine limitations={limitations.nbCards} mainText={`Lineup: ${limitations.nbCards.requiredAmount} cards required`} />
            </div>
          </div>
        </PopperPortal>
      )}
      {children}
    </div>
  );
};

const RuleLine = ({ limitations, mainText, invalidText }) => {
  const isEmpty = limitations.isEmpty;
  const isValid = limitations.isValid;
  const show = limitations.show;
  if (!show) {
    return null;
  }
  return (
    <div className={"flex flex-row gap-2"}>
      {isEmpty ? (
        <span className={"font-md text-on-surface"}>•</span>
      ) : isValid ? (
        <ValidIcon className={"h-5 w-5 min-w-fit"} />
      ) : (
        <InvalidIcon className={"h-5 w-5 min-w-fit"} />
      )}
      <span className={"flex flex-row gap-1"}>
        <span>{mainText}</span>
        {!isValid && invalidText && (
          <>
            <span>-</span>
            <span>{invalidText}</span>
          </>
        )}
      </span>
    </div>
  );
};

const checkAveragesInLineup = (lineup, limitsOfAverage) => {
  const { type } = limitsOfAverage;
  const min = limitsOfAverage.min || 0;
  const max = limitsOfAverage.max || 0;
  let scoreMsg = "";
  let countInLineup = 0;
  if (min === max) {
    countInLineup =
      lineup?.filter((e) => {
        if (!e) {
          return false;
        }
        const score = type === "l15" ? e.cost : e.s5Avg;
        return score === min;
      })?.length || 0;
    scoreMsg = `of exactly ${min}`;
  } else if (min === 0 && max !== 0) {
    countInLineup =
      lineup?.filter((e) => {
        if (!e) {
          return false;
        }
        const score = type === "l15" ? e.cost : e.s5Avg;
        return score <= max;
      })?.length || 0;
    scoreMsg = `of ${max} or below`;
  } else if (min !== 0 && max === 0) {
    countInLineup =
      lineup?.filter((e) => {
        if (!e) {
          return false;
        }
        const score = type === "l15" ? e.cost : e.s5Avg;
        return score >= min;
      }).length || 0;
    scoreMsg = `of ${min} or above`;
  } else {
    countInLineup =
      lineup?.filter((e) => {
        if (!e) {
          return false;
        }
        const score = type === "l15" ? e.cost : e.s5Avg;
        return score >= min && score <= max;
      })?.length || 0;
    scoreMsg = `between ${min}-${max}`;
  }
  return { msg: scoreMsg, count: countInLineup };
};
