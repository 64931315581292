import React, { useState } from "react";
import InjuryChange from "../../img/injury-change.svg";
import RedCard from "../../img/red-card.svg";
import YellowCard from "../../img/yellow-card.svg";
import SubbedOn from "../../img/sub-in.svg";
import PlayerScoreModal from "../players/playerScoreModal";
import { withUser } from "../../userContext";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { sorareFootball } from "../util/sports";
import LineSeparator from "../../img/line-separator.svg";
import { footballScale } from "../util/scales";
import { playedStatsColors } from "../util/playedStats";

import Started from "../../img/starter.svg";
import CameIn from "../../img/cameIn.svg";
import Bench from "../../img/benchNotCameIn.svg";
import NotInSquad from "../../img/notInSquad.svg";

const defaultEnhanceConfig = {
  teamAdjusted: false,
  competitionAdjusted: false,
  homeAwayAdjusted: false,
  currentGame: {},
};

function Last10Line(props) {
  const [modalStats, setModalStats] = useState(undefined);
  const [modalPlayer, setModalPlayer] = useState(undefined);
  const [showOverlay, setShowOverlay] = useState(false);

  const stats = props.stats;
  const player = props.player;
  const reverse = props.reverse !== undefined ? props.reverse : false;
  const sport = modalPlayer?.Sport || props.sport || sorareFootball;
  const enhanceConfig = props.enhanceConfig || defaultEnhanceConfig;
  const textSize = props.textSize || "md:text-sm text-xs";
  return (
    <div>
      {modalStats !== undefined && (
        <PlayerScoreModal
          sport={sport}
          newMatrix={true}
          position={props.position}
          stats={modalStats}
          player={modalPlayer}
          open={showOverlay}
          close={() => setShowOverlay(false)}
        />
      )}
      <div className={"flex flex-row justify-around bg-surface-container"}>
        {(reverse ? stats.slice().reverse() : stats).map((s) => {
          let SO5Score = s.NewScore;
          if (props.position === "Goalkeeper") {
            SO5Score = s.NewScoreGK;
          } else if (props.position === "Defender") {
            SO5Score = s.NewScoreDF;
          } else if (props.position === "Midfielder") {
            SO5Score = s.NewScoreMD;
          } else if (props.position === "Forward") {
            SO5Score = s.NewScoreFW;
          }
          let scoreColorStart = "horrible-score-start";

          let floatSO5Score = parseFloat(SO5Score);

          if (floatSO5Score < 15) {
            scoreColorStart = "horrible-score-start";
          } else if (floatSO5Score < 30) {
            scoreColorStart = "bad-score-start";
          } else if (floatSO5Score < 45) {
            scoreColorStart = "ok-score-start";
          } else if (floatSO5Score < 60) {
            scoreColorStart = "good-score-start";
          } else if (floatSO5Score < 75) {
            scoreColorStart = "great-score-start";
          } else if (floatSO5Score <= 100) {
            scoreColorStart = "amazing-score-start";
          }

          let textSizeNormalized = textSize;
          if (floatSO5Score === 0) {
            if (s.OnGameSheet === true && s.mins_played === 0) {
              SO5Score = "SUB";
            } else if (!s.OnGameSheet) {
              SO5Score = "OUT";
            }
            scoreColorStart = "on-surface-variant";
            if (textSize === "md:text-sm text-xs") {
              textSizeNormalized = "md:text-xs text-xxs";
            }
            if (textSize === "text-xs") {
              textSizeNormalized = "text-xxs";
            }
          } else {
            if (props.powerAdj) {
              SO5Score = parseFloat(SO5Score) * props.power;
            }
            SO5Score = parseFloat(SO5Score).toFixed(0);
          }
          let isEnhanced = false;
          const matchIsHome = s.game.HomeTeamId === player.TeamId || s.game.HomeTeamId === player.NationalTeam;
          const matchIsAway = s.game.AwayTeamId === player.TeamId || s.game.AwayTeamId === player.NationalTeam;

          if (enhanceConfig && (enhanceConfig.teamAdjusted || enhanceConfig.competitionAdjusted || enhanceConfig.homeAwayAdjusted)) {
            const isPlayerWithCurrentTeam = s.TeamId && enhanceConfig.currentTeamId && s.TeamId === enhanceConfig.currentTeamId;
            const isPlayerWithCurrentCompetition =
              enhanceConfig.currentGame.CompSlug && s.game.CompSlug && enhanceConfig.currentGame.CompSlug === s.game.CompSlug;
            const isPlayerWithCurrentHomeAway = (enhanceConfig.isHome && matchIsHome) || (!enhanceConfig.isHome && matchIsAway);
            // Intersection. We enhance the game when all *enabled* options are true for this game
            isEnhanced =
              (enhanceConfig.teamAdjusted ? isPlayerWithCurrentTeam : true) &&
              (enhanceConfig.competitionAdjusted ? isPlayerWithCurrentCompetition : true) &&
              (enhanceConfig.homeAwayAdjusted ? isPlayerWithCurrentHomeAway : true);
          }
          return (
            <div
              key={`${s.GameId}-${s.PlayerId}`}
              className={"flex flex-col justify-center self-center cursor-pointer"}
              onClick={() => {
                setModalStats(s);
                setModalPlayer(props.player);
                setShowOverlay(true);
              }}
            >
              <div className={"flex flex-row justify-center self-center h-2 cursor-pointer"}>
                {t1OrAbove(props.user.tier) && s.SubbedOn === true && <img data-tip={"Came from the bench."} src={SubbedOn} />}
              </div>
              <div className={`flex flex-row justify-center space-x-1 self-end ${isEnhanced ? "border-b-2 border-primary" : ""}`}>
                <span className={`font-semibold ${textSizeNormalized} text-${scoreColorStart}`}>{SO5Score}</span>
                {t1OrAbove(props.user.tier) && s.SubReason === "Injury" && (
                  <img className={"w-4"} data-tip={"Subbed off injured."} src={InjuryChange} />
                )}
                {t1OrAbove(props.user.tier) && s.YellowCard >= 1 && (
                  <img className={"w-4"} data-tip={"Received a yellow card."} src={YellowCard} />
                )}
                {t1OrAbove(props.user.tier) && s.RedCard === 1 && <img className={"w-4"} data-tip={"Received a red card."} src={RedCard} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const Last10LineAlternativeItem = (props) => {
  const { stats, player, onClick } = props;
  const score = parseFloat(stats.NewScore);
  let bgColor;
  let scale = footballScale;
  if (score < scale[0]) {
    bgColor = "bg-horrible-score";
  } else if (score < scale[1]) {
    bgColor = "bg-bad-score";
  } else if (score < scale[2]) {
    bgColor = "bg-ok-score";
  } else if (score < scale[3]) {
    bgColor = "bg-good-score";
  } else if (score < scale[4]) {
    bgColor = "bg-great-score";
  } else {
    bgColor = "bg-amazing-score";
  }
  let SO5Score = score.toFixed(0);
  let fontColor = "text-focus";
  let textSize = "text-xs";
  if (stats.mins_played === 0) {
    SO5Score = "DNP";
    bgColor = "bg-bar-grey";
    fontColor = "text-brand-black";
    textSize = "text-xxs";
  }
  const playedStatusIcon = stats.Started ? Started : stats.SubbedOn ? CameIn : stats.OnGameSheet ? Bench : NotInSquad;
  return (
    <div className={"flex flex-col justify-center items-center gap-1 cursor-pointer"} onClick={onClick}>
      <p className={"text-xxxs text-textGrey3 font-semibold"}>#{stats.game.GwNumber}</p>
      <div className={"flex flex-row gap-1"}>
        <div className={"flex flex-col gap-0.5"}>
          <div className={`flex justify-center items-center rounded self-center ${bgColor} ${fontColor} ${textSize} w-6 h-6 font-semibold`}>
            {SO5Score}
          </div>
          <div className={"h-3 flex flex-row justify-center -space-x-1"}>
            {stats.YellowCard >= 1 && <img className={"w-4"} data-tip={"Received a yellow card."} src={YellowCard} />}
            {stats.RedCard === 1 && <img className={"w-4"} data-tip={"Received a red card."} src={RedCard} />}
            {stats.SubReason === "Injury" && <img className={"w-4"} data-tip={"Subbed off injured."} src={InjuryChange} />}
          </div>
        </div>
        <div className={"flex flex-col"}>
          <div className={"flex flex-row justify-center items-center w-6 h-6"}>
            <img className={"w-full h-full"} src={playedStatusIcon} />
          </div>
          <div
            className={`h-3 flex justify-center items-center font-semibold text-xxs`}
            style={{
              color: stats.Started ? playedStatsColors.gamesStarted : playedStatsColors.gamesEnteredFromBench,
            }}
          >
            {stats.mins_played > 0 && `${stats.mins_played}'`}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Last10LineAlternative = (props) => {
  const [modalStats, setModalStats] = useState(undefined);
  const [modalPlayer, setModalPlayer] = useState(undefined);
  const [showOverlay, setShowOverlay] = useState(false);

  const player = props.player;
  const reverse = props.reverse !== undefined ? props.reverse : false;
  const sport = modalPlayer?.Sport || props.sport || sorareFootball;
  const stats = reverse ? props.stats.slice().reverse() : props.stats;
  const elements = stats.map((s, i) => {
    return (
      <Last10LineAlternativeItem
        player={player}
        key={i}
        stats={s}
        onClick={() => {
          setModalStats(s);
          setModalPlayer(props.player);
          setShowOverlay(true);
        }}
      />
    );
  });
  return (
    <div>
      {modalStats !== undefined && (
        <PlayerScoreModal
          sport={sport}
          newMatrix={true}
          position={props.position}
          stats={modalStats}
          player={modalPlayer}
          open={showOverlay}
          close={() => setShowOverlay(false)}
        />
      )}
      <div className={"flex flex-row gap-1.5 justify-center items-center"}>
        {elements.length > 0 && elements.reduce((prev, curr) => [prev, <img src={LineSeparator} />, curr])}
      </div>
    </div>
  );
};

export default withUser(Last10Line);
