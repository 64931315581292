import React from "react";

function NBAL10Circle(props) {
  let s = props.score;
  let size = "w-6 h-6";
  if (props.size) {
    size = props.size;
  }
  let font = "text-xs";
  if (props.font) {
    font = props.font;
  }
  return (
    <div className={"flex flex-col items-center space-y-1 " + font}>
      <div
        style={{ backgroundColor: "#0380F3" }}
        className={"flex flex-col justify-center self-center " + "items-center " + size + " rounded-full "}
      >
        <p className={"flex justify-center self-center font-semibold text-white"}>{s.toFixed(0)}</p>
      </div>
    </div>
  );
}

export default NBAL10Circle;
