import React from "react";
import CardIcon from "../util/cardIcon";

function RewardCardEntry(props) {
  const info = props.info;
  const scarcity = props.scarcity;
  const text = info.text;
  let splits = text?.split(",");
  let bg = props.bg || "bg-opacity-20 bg-white";
  let textColor = props.textColor || "text-white";

  return (
    <div className={"text-white flex flex-row space-x-2"}>
      <div className={bg + " flex flex-row space-x-2 px-2 py-1 rounded-lg"}>
        <span className={"font-semibold self-start text-xs"}>{info.total_cards}x</span>
        <div className={"self-center"}>
          <CardIcon scarcity={scarcity} size={"xs"} />
        </div>
      </div>
      <div className={"flex flex-row space-x-2"}>
        {splits?.map((split, index) => {
          let t = split.split("Tier");
          return (
            <div className={"self-center flex flex-row space-x-2 " + textColor}>
              <p key={index} className={"font-semibold flex flex-row space-x-2 text-sm justify-center self-center"}>
                {t[0].replaceAll(" ", "")}x Tier {t[1]}
              </p>
              {index < splits.length - 1 && <div className={"self-center border-l border-opacity-50 border-white h-3"}></div>}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RewardCardEntry;
