import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { withUser } from "../../userContext";
import { withRouter } from "react-router-dom";
import { errorCatcher } from "../util/errors";
import format from "date-fns/format";

function WatchlistNoteSideBar(props) {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState({ text: "" });
  const [noteText, setNoteText] = useState("");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const readOnly = props.readOnly || false;

  useEffect(() => {
    if (props.open) {
      getNote();
    }
    setOpen(props.open);
  }, [props.open]);

  const close = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
    setError("");
  };

  const getNote = () => {
    if (props.id) {
      props
        .fetch(`/apiv2/watchlists/id/${props.id}/note`)
        .then(async (response) => {
          if (response.status >= 400) {
            if (response.status >= 500) {
              setError("Internal error, please retry later");
            } else {
              const body = await response.json();
              setError(body.error);
            }
          }
          const body = await response.json();
          const note = body || { text: "" };
          setNote(note);
          setNoteText(note.text);
        })
        .catch(errorCatcher());
    }
  };

  const saveNote = () => {
    setSaving(true);
    if (props.id) {
      props
        .fetch(`/apiv2/watchlists/id/${props.id}/note`, {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: noteText,
          }),
        })
        .then(async (response) => {
          if (response.status >= 400) {
            if (response.status >= 500) {
              setError("Internal error, please retry later");
            } else {
              const body = await response.json();
              setError(body.error);
            }
          }
          const body = await response.json();
          const note = body || { text: "" };
          setNote(note);
          setNoteText(note.text);
          setSaving(false);
        })
        .catch(
          errorCatcher(() => {
            setSaving(false);
          }),
        );
    }
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0" onClose={close}>
          <div className="flex items-end justify-center h-full p-0 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div className="h-full inline-block align-bottom bg-focus rounded-l-lg text-left shadow-xl mr-0 ml-auto transform transition-all sm:align-middle max-w-lg w-full p-0 ">
                <div className="block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-focus rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={close}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"flex flex-col w-auto h-full pt-8"}>
                  <div className={"w-full border-b-2 border-grey-e5 pb-4"}>
                    <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>Notes</h2>
                  </div>
                  <div className={"flex flex-col h-full mx-full p-6"}>
                    <div className={"flex flex-col h-full w-full text-sm"}>
                      {note.updated && (
                        <div className={"bg-grey-f2 w-full text-xs text-textGrey3 font-semibold p-2"}>
                          LAST EDIT<span> • </span>
                          <span>{format(new Date(note.updated), "dd/MM/yyyy")}</span>
                        </div>
                      )}
                      <textarea
                        disabled={readOnly}
                        style={{ resize: "none" }}
                        className={
                          "text-sm bg-grey-f2 border-0 w-full h-full focus:outline-none focus:ring-grey-f2 focus:border-grey-f2 overflow-auto"
                        }
                        placeholder={
                          readOnly ? "No note" : "Type here notes related to the watchlist, like a description, top/flop and so on..."
                        }
                        value={noteText}
                        onChange={!readOnly ? (event) => setNoteText(event.target.value) : undefined}
                      />
                    </div>
                    <div className={"flex flex-row mt-4 mx-auto"}>
                      <div className={"ml-auto mr-0 inline"}>
                        {!readOnly && (
                          <button
                            className={
                              "text-white py-3 px-6 text-sm font-medium rounded-lg bg-brand focus:outline-none hover:bg-brand-light cursor-pointer"
                            }
                            onClick={saveNote}
                          >
                            {saving ? <span>Saving...</span> : <span>Save</span>}
                          </button>
                        )}
                      </div>
                    </div>
                    {error && <p className={"text-live-red"}>{error}</p>}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withRouter(withUser(WatchlistNoteSideBar));
