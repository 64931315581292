import React from "react";
import { ReactComponent as TwitterBrand } from "../../img/twitter-brand.svg";
import { ReactComponent as IconArticle } from "@material-design-icons/svg/filled/article.svg";
import { ReactComponent as RocketLaunch } from "@material-design-icons/svg/filled/rocket_launch.svg";
import { ReactComponent as PlayCircle } from "@material-design-icons/svg/filled/play_circle.svg";
import { ReactComponent as IconPlayerCards } from "../../img/icon-player-cards.svg";
import { ReactComponent as IconCup } from "@material-design-icons/svg/filled/emoji_events.svg";
import DotBg from "../../img/Dot pattern full.png";
import { StoreButtons } from "../util/stores";
import { Button } from "../util/button";
import { withUser } from "../../userContext";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { Tag } from "../util/tag";

function TweetAction(props) {
  return (
    <div
      className={
        "w-full flex flex-row gap-1 self-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80 "
      }
    >
      <div className={"w-4 h-4 self-center"}>
        <TwitterBrand className={"w-full h-full fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end whitespace-nowrap"}>{props.action}</p>
    </div>
  );
}

function CupAction(props) {
  const action = props.action || "Read article";
  return (
    <div
      className={
        "w-full flex flex-row gap-1 items-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80"
      }
    >
      <div className={"w-5 h-5 self-center"}>
        <IconCup className={"w-5 h-5 fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end whitespace-nowrap"}>{action}</p>
    </div>
  );
}

function ArticleAction(props) {
  const action = props.action || "Read article";
  return (
    <div
      className={
        "w-full flex flex-row gap-1 self-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80"
      }
    >
      <div className={"w-5 h-5 self-center"}>
        <IconArticle className={"w-full h-full fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end whitespace-nowrap"}>{action}</p>
    </div>
  );
}

function VideoAction(props) {
  return (
    <div
      className={
        "w-full flex flex-row gap-1 self-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80"
      }
    >
      <div className={"w-5 h-5 self-center"}>
        <PlayCircle className={"w-full h-full fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end lg:justify-start 1.5xl:justify-end whitespace-nowrap"}>
        {props.action ? props.action : "Watch video"}
      </p>
    </div>
  );
}

function HomeSplitter() {
  return <div className={"h-1 w-full border-b self-center border-transparent-inverse-surface-low border-opacity-10"}></div>;
}

function HomeNews(props) {
  return (
    <div
      className={`w-full h-full flex flex-col items-center sm:flex-row gap-2 p-2 lg:flex-col 1.5xl:flex-row self-center justify-center sm:justify-between lg:justify-center 1.5xl:justify-between ${
        props.border ? "border-t border-transparent-inverse-surface-low border-opacity-10" : ""
      }`}
    >
      <div className={"flex"}>
        <p className={"w-full font-medium font-headers text-sm self-end"}>{props.text}</p>
      </div>
      <div className={"flex"}>
        <a
          href={props.target}
          target={"_blank"}
          className={"self-center text-end flex w-full justify-end lg:justify-start 1.5xl:justify-end"}
          rel="noreferrer"
        >
          {props.action}
        </a>
      </div>
    </div>
  );
}

const bannersBySize = [
  "bg-[url('../public/images/banner428x376.png')]",
  "sm:bg-[url('../public/images/banner576x376.png')]",
  "md:bg-[url('../public/images/banner611x376.png')]",
  "lg:bg-[url('../public/images/banner704x376.png')]",
  "xl:bg-[url('../public/images/banner781x376.png')]",
  "1.5xl:bg-[url('../public/images/banner861x320.png')]",
  "2xl:bg-[url('../public/images/banner1181x320.png')]",
  "3xl:bg-[url('../public/images/banner1300x320.png')]",
  "5xl:bg-[url('../public/images/banner1575x320.png')]",
];

const HomeBanner = withUser((props) => {
  return (
    <div className={"flex flex-col sm:flex-row justify-between lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-6"}>
      {!props.isPromo && (
        <div
          className={`flex w-full lg:w-8/12 bg-no-repeat bg-center bg-cover rounded-lg h-home-banner-xs 1.5xl:h-home-banner-1.5xl ${bannersBySize.join(
            " ",
          )}`}
        >
          <div className={"w-full px-6 py-4 flex flex-col justify-start items-start gap-4"}>
            <div className={"w-full xl:w-6/12"}>
              <p className={"text-white font-semibold text-3xl"}>Take your collections anywhere.</p>
            </div>
            <div className={"w-full xl:w-6/12 space-y-5"}>
              <p className={"font-headers font-medium text-sm lg:text-base 3xl:text-lg text-white"}>
                Upgrade to the latest version of the SorareData mobile app for collection tracking, new teams pages, and more!
              </p>
            </div>
            <div className={"h-[17%] flex self-star pb-2 flex-row xl:justify-start"}>
              <div className={"flex flex-row gap-4 font-headers font-medium text-white h-full"}>
                {/*t1OrAbove(props.user.tier) ? (
                  <a href={"/lineupBuilder?gw=475&divisionId=10-14-may-2024-preview-legends-sw-pro"} target={"_blank"} rel="noreferrer">
                    <Button context="secondary" icon={<IconPlayerCards className={"w-4 h-4 fill-primary"} />}>
                      Build your lineups
                    </Button>
                  </a>
                ) : (
                  <a href={"/product"} target={"_blank"} rel="noreferrer">
                    <Button context="primary" icon={<RocketLaunch className={"w-4 h-4 fill-on-primary"} />}>
                      Upgrade membership
                    </Button>
                  </a>
                )*/}
                <StoreButtons />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          "flex flex-col w-full lg:w-4/12 relative rounded-lg bg-surface-container px-6 py-6 lg:h-home-banner-xs 1.5xl:h-home-banner-1.5xl text-on-surface"
        }
      >
        <div
          className={"absolute h-[65px] w-3/12 top-0 right-0 flex flex-row justify-end self-end"}
          style={{
            backgroundImage: "url('" + DotBg + "')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <p className={"font-semibold text-3xl font-headers pb-3"}>Latest news</p>
        <div className={"grid grid-cols-1 grid-rows-3 h-full"}>
          <HomeNews
            border={true}
            text={"Welcome Harry!"}
            target={"https://soraredata.medium.com/introducing-our-newest-soraredata-ambassador-e3dd321fe484"}
            action={<ArticleAction />}
          />
          <HomeNews
            border={true}
            text={"Update on MLB and NBA Coverage"}
            target={"https://medium.com/@soraredata/update-on-soraredata-mlb-and-nba-coverage-e27e08679591"}
            action={<ArticleAction />}
          />
          <HomeNews
            border={true}
            text={"How to use our Lineup Export Button"}
            target={"https://x.com/SorareData/status/1824098638429102092"}
            action={<VideoAction />}
          />
        </div>
      </div>
    </div>
  );
});

export default HomeBanner;
