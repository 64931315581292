import React, { useEffect, useState } from "react";
import PlayerSearchResult from "../players/playerSearchResult";
import CarretUp from "../../img/carret-vector-up.svg";
import CarretDown from "../../img/carret-vector.svg";
import { ReactTooltip } from "../util/tooltip.js";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import BoxWithToggle from "../util/boxWithToggle";
import SelectSearch from "react-select-search";
import getQueryString from "../util/getParams";
import { positions_objects } from "../util/positions";
import { PlayerStatusSelector } from "../util/playingStatus";
import AgeRange, { DEFAULT_AGE_RANGE } from "../util/ageRange";
import { Button } from "../util/button";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { FilterMenu } from "../search/searchFilters";
import ListViewPicker from "../util/listViewPicker";
import PlayerSearchListResult from "../players/playerSearchListResult";
import FavoriteBubble from "../util/favoriteBubble";
import NotificationBubble from "../util/notificationBubble";
import PlayerNoCards from "../players/playerNoCards";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";

function RosterPart(props) {
  const [deployed, setDeployed] = useState(true);
  const roster = props.roster;
  const onlySorare = props.onlySorare;
  let lengthOnlySorare = 0;
  if (roster !== null && roster !== undefined) {
    roster.map((r) => {
      if (r.player.Licensed === true) lengthOnlySorare++;
    });
    return (
      <div className={"space-y-2"}>
        <div className={"border-b border-grey-e5 flex flex-row justify-between pb-1 cursor-pointer"} onClick={() => setDeployed(!deployed)}>
          <p className={"text-base font-semibold"}>
            {props.position} ({onlySorare ? lengthOnlySorare : roster.length})
          </p>
          <img src={deployed ? CarretUp : CarretDown} />
        </div>
        {deployed && (
          <div className={"grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4"}>
            {roster.map((g) => {
              if (g.player.Licensed === false && onlySorare) {
                return null;
              } else {
                return <PlayerSearchResult result={g} teamMap={props.teamMap} sport={props.sport} />;
              }
            })}
          </div>
        )}
      </div>
    );
  } else return null;
}

function TeamRoster(props) {
  const defaultPosition = "all";
  const defaultAge = DEFAULT_AGE_RANGE;
  const defaultStatus = "";
  const defaultSearch = "";

  const [sorareRoster, setSorareRoster] = useState([]);
  const [notSorareRoster, setNotSorareRoster] = useState([]);
  let rosterStruct = {
    goalkeepers: [],
    defenders: [],
    midfielders: [],
    forwards: [],
  };
  if (props.sport === sorareBaseball) {
    rosterStruct = {
      startingPitchers: [],
      reliefPitchers: [],
      outfielders: [],
      middleInfielders: [],
      cornerInfielders: [],
    };
  }

  const [roster, setRoster] = useState(rosterStruct);
  const [teamMap, setTeamMap] = useState({});
  const [onlySorare, setOnlySorare] = useState(false);
  const [statusFilter, setStatusFilter] = useState(defaultStatus);
  const [ageFilter, setAgeFilter] = useState(defaultAge);
  const [positionFilter, setPositionFilter] = useState(defaultPosition);
  const [searchFilter, setSearchFilter] = useState(defaultSearch);
  const [reload, setReload] = useState(false);
  const [u23Only, setU23Only] = useState(false);
  const [listView, setListView] = useState(false);
  const [sortBy, setSortBy] = useState("l15 desc");

  useEffect(() => {
    getTeamRoster();
    ReactTooltip.rebuild();
  }, [reload]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [listView]);

  useEffect(() => {
    sortFullRoster();
    ReactTooltip.rebuild();
  }, [sortBy]);

  useEffect(() => {
    if (u23Only) {
      setAgeFilter([0, 23]);
    } else {
      setAgeFilter(defaultAge);
    }
    ReactTooltip.rebuild();
  }, [u23Only]);

  const listActionItems =
    sorareRoster?.map((p) => {
      return (
        <div key={p.player.PlayerId} className={"flex flex-row justify-center m-auto"}>
          <div className={"h-9 w-9"}>
            <FavoriteBubble player={p.player} favorite={p.favorite} />
          </div>
          <div className={"h-9 w-9"}>
            <NotificationBubble player={p.player} hasNotifications={p.has_price_alerts} />
          </div>
        </div>
      );
    }) || [];

  const sortFullRoster = () => {
    let sorted = [];
    sorted = sorareRoster.sort((a, b) => {
      let s = sortBy.split(" ");
      if (s.length === 2) {
        let field = s[0];
        let order = s[1];
        let aVal = null;
        let bVal = null;

        switch (field) {
          case "rankingPosition":
            aVal = a.player_ranking.ranking_position <= 0 ? null : a.player_ranking.ranking_position;
            bVal = b.player_ranking.ranking_position <= 0 ? null : b.player_ranking.ranking_position;
            break;
          case "rankingOverall":
            aVal = a.player_ranking.ranking_overall <= 0 ? null : a.player_ranking.ranking_overall;
            bVal = b.player_ranking.ranking_overall <= 0 ? null : b.player_ranking.ranking_overall;
            break;
          case "l5":
            aVal = a.scores_5.AverageNoDnp;
            bVal = b.scores_5.AverageNoDnp;
            break;
          case "l15":
            aVal = a.scores_15.AverageNoDnp;
            bVal = b.scores_15.AverageNoDnp;
            break;
          case "l40":
            aVal = a.scores_40.AverageNoDnp;
            bVal = b.scores_40.AverageNoDnp;
            break;
          case "realTimeLimited":
            aVal = a.real_time_limited.Average;
            bVal = b.real_time_limited.Average;
            break;
          case "realTimeRare":
            aVal = a.real_time_rare.Average;
            bVal = b.real_time_rare.Average;
            break;
          case "realTimeSuperRare":
            aVal = a.real_time_super_rare.Average;
            bVal = b.real_time_super_rare.Average;
            break;
          case "realTimeUnique":
            aVal = a.real_time_unique.Average;
            bVal = b.real_time_unique.Average;
            break;
          case "bmpLimited":
            aVal = a.bmp_limited.Price;
            bVal = b.bmp_limited.Price;
            break;
          case "bmpRare":
            aVal = a.bmp_rare.Price;
            bVal = b.bmp_rare.Price;
            break;
          case "bmpSuperRare":
            aVal = a.bmp_sr.Price;
            bVal = b.bmp_sr.Price;
            break;
          case "bmpUnique":
            aVal = a.bmp_u.Price;
            bVal = b.bmp_u.Price;
            break;
          default:
            return 0;
        }

        if (aVal === null) {
          return 1;
        } else if (bVal === null) {
          return -1;
        }

        if (order === "desc") {
          return bVal - aVal;
        } else {
          return aVal - bVal;
        }
      }
      return 0;
    });
    setSorareRoster(sorted);
  };

  const sortByStatus = (field) => {
    let sort = "";
    if (sortBy.includes(field)) {
      sort = field + (sortBy.includes("asc") ? " desc" : " asc");
    } else {
      sort = field + " asc";
    }
    setSortBy(sort);
  };

  const getTeamRoster = () => {
    let filters = {
      status: statusFilter,
      age: ageFilter,
      position: positionFilter,
      search: searchFilter,
    };

    props
      .fetch("/apiv2/teams/roster/" + props.teamId + "?" + getQueryString(filters))
      .then((response) => response.json())
      .then((res) => {
        setTeamMap(res.team_map);
        setRoster(res.roster);
        let full = [];
        let sorare = [];
        let notSorare = [];

        if (res.roster?.goalkeepers) {
          full.push(...res.roster.goalkeepers);
        }
        if (res.roster?.defenders) {
          full.push(...res.roster.defenders);
        }
        if (res.roster?.midfielders) {
          full.push(...res.roster.midfielders);
        }
        if (res.roster?.forwards) {
          full.push(...res.roster.forwards);
        }
        if (res.roster?.startingPitchers) {
          full.push(...res.roster.startingPitchers);
        }
        if (res.roster?.reliefPitchers) {
          full.push(...res.roster.reliefPitchers);
        }
        if (res.roster?.outfielders) {
          full.push(...res.roster.outfielders);
        }
        if (res.roster?.middleInfielders) {
          full.push(...res.roster.middleInfielders);
        }
        if (res.roster?.cornerInfielders) {
          full.push(...res.roster.cornerInfielders);
        }

        full.map((v) => {
          if (v.player.Licensed) {
            sorare.push(v);
          } else {
            notSorare.push(v);
          }
        });

        setSorareRoster(sorare);
        setNotSorareRoster(notSorare);
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  const resetFilters = () => {
    setStatusFilter(defaultStatus);
    setAgeFilter(defaultAge);
    setPositionFilter(defaultPosition);
    setSearchFilter(defaultSearch);
    setU23Only(false);
    setOnlySorare(false);
    setSortBy("l15 desc");
    setReload(!reload);
  };

  const sport = props.sport;

  return (
    <div>
      {sport !== sorareBasketball && (
        <div className="flex justify-end items-center my-4">
          <ListViewPicker mode={listView} change={(c) => setListView(c)} />
        </div>
      )}
      {sport !== sorareBasketball && (
        <div className={"mb-4"}>
          <FilterMenu
            tooltip={true}
            label={"Filters"}
            labelStyle={"text-lg font-semibold self-center"}
            content={
              <div>
                <div className="grid grid-cols-5 gap-x-4">
                  <div className="rounded-lg bg-white shadow self-center py-1.5">
                    <BoxWithToggle label={"Sorare players only"} isActive={onlySorare} setActive={() => setOnlySorare(!onlySorare)} />
                  </div>

                  <div className="z-50 self-center">
                    <PlayerStatusSelector
                      playingStatus={statusFilter}
                      onChange={(v) => setStatusFilter(v)}
                      placeholder={"Playing status"}
                      tier={props.user.tier}
                      sport={props.sport}
                    />
                  </div>
                  <div className="z-50 self-center">
                    <SelectSearch
                      options={positions_objects}
                      value={positionFilter}
                      placeholder={"Position"}
                      onChange={(v) => setPositionFilter(v)}
                    />
                  </div>
                  <div className="z-50 w-full self-center">
                    <AgeRange range={ageFilter} setU23={() => setU23Only(!u23Only)} u23={u23Only} setRange={(ar) => setAgeFilter(ar)} />
                  </div>
                  <div className="mx-2 my-2 z-50">
                    <input
                      className="focus:outline-none block w-full py-2 rounded-md pl-4 shadow sm:text-sm border-grey-e5"
                      type="text"
                      placeholder="Filter by name"
                      value={searchFilter}
                      onChange={(e) => setSearchFilter(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center my-4">
                  <div className="mx-2">
                    <Button context="secondary" label={"Reset"} onClick={() => resetFilters()} />
                  </div>
                  <div className="mx-2">
                    <Button label={"Filter"} onClick={() => setReload(!reload)} />
                  </div>
                </div>
              </div>
            }
          />
          {isFree(props.user.tier) && !(props.sport === sorareBaseball) && (
            <div className="my-8">
              <UpgradeLimitBox
                className={"bg-white bg-upgrade-bg-player-status bg-cover h-upgrade-banner-s w-full"}
                title={"Want more insights?"}
                description={"Become a Star member to unlock players status."}
              />
            </div>
          )}
        </div>
      )}
      {!listView && props.sport === sorareFootball && (
        <div className={"space-y-8 pb-8"}>
          <RosterPart roster={roster.goalkeepers} onlySorare={onlySorare} position={"Goalkeepers"} teamMap={teamMap} />
          <RosterPart roster={roster.defenders} onlySorare={onlySorare} position={"Defenders"} teamMap={teamMap} />
          <RosterPart roster={roster.midfielders} onlySorare={onlySorare} position={"Midfielders"} teamMap={teamMap} />
          <RosterPart roster={roster.forwards} onlySorare={onlySorare} position={"Forwards"} teamMap={teamMap} />
        </div>
      )}
      {listView && (
        <div className={"space-y-8"}>
          <PlayerSearchListResult
            sortBy={sortBy}
            onChangeSort={sortByStatus}
            displayedPlayers={sorareRoster}
            actionItems={listActionItems}
          />
          <div className="pt-4 pb-8">
            <FilterMenu
              tooltip={true}
              label={`Players without Sorare cards printed (` + notSorareRoster.length + `)`}
              labelStyle={"text-sm font-semibold self-center"}
              content={
                <div className="w-full">
                  <PlayerNoCards displayedPlayers={notSorareRoster} />
                </div>
              }
            />
          </div>
        </div>
      )}
      {!listView && props.sport === sorareBaseball && (
        <div className={"space-y-8 pb-8"}>
          <RosterPart roster={roster.startingPitchers} onlySorare={onlySorare} position={"Starting Pitchers"} teamMap={teamMap} />
          <RosterPart roster={roster.reliefPitchers} onlySorare={onlySorare} position={"Relief Pitchers"} teamMap={teamMap} />
          <RosterPart roster={roster.outfielders} onlySorare={onlySorare} position={"Outfielders"} teamMap={teamMap} />
          <RosterPart roster={roster.middleInfielders} onlySorare={onlySorare} position={"Middle Infielders"} teamMap={teamMap} />
          <RosterPart roster={roster.cornerInfielders} onlySorare={onlySorare} position={"Corner Infielders"} teamMap={teamMap} />
        </div>
      )}
      {!listView && props.sport === sorareBasketball && (
        <div className={"space-y-8 pb-8"}>
          <RosterPart roster={roster.guards} sport={props.sport} onlySorare={onlySorare} position={"Guards"} teamMap={teamMap} />
          <RosterPart roster={roster.forwards} sport={props.sport} onlySorare={onlySorare} position={"Forwards"} teamMap={teamMap} />
          <RosterPart roster={roster.centers} sport={props.sport} onlySorare={onlySorare} position={"Centers"} teamMap={teamMap} />
        </div>
      )}
      <ReactTooltip />
    </div>
  );
}

export default withUser(TeamRoster);
