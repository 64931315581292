import React, { useEffect, useState } from "react";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import { baseballScale, footballScale } from "../util/scales";
import { withUser } from "../../userContext";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import { useElementSize } from "usehooks-ts";
import GamesPlayed from "./gamesPlayed";

function ScoreAverage(props) {
  let scoreColorStart;
  let floatSO5Score = props.avg;
  let scale = footballScale;
  let played = props.played;

  const [refContainer, { width: containerWidth, height: containerHeight }] = useElementSize();
  const [containerSize, setContainerSize] = useState(0);

  useEffect(() => {
    const newContainerSize = [containerWidth, containerHeight].reduce(
      (min, cur) => (cur != 0 ? (cur < min ? cur : min) : min),
      containerWidth,
    ); // keep smallest dimension so it stays in the container (but avoid 0 value)
    setContainerSize(newContainerSize);
  }, [containerWidth, containerHeight]);

  if (props.user.sorareAverages === true && props.sport !== sorareBaseball) {
    if (props.sAvg !== undefined && props.sApps !== undefined) {
      floatSO5Score = props.sAvg;
      played = props.sApps;
    }
  }
  let precision = 0;
  if (props.sport === sorareBaseball) {
    scale = baseballScale;
    precision = 1;
    if (floatSO5Score >= 10) {
      precision = 0;
    }
  }
  if (floatSO5Score < scale[0]) {
    scoreColorStart = "horrible-score";
  } else if (floatSO5Score < scale[1]) {
    scoreColorStart = "bad-score";
  } else if (floatSO5Score < scale[2]) {
    scoreColorStart = "ok-score";
  } else if (floatSO5Score < scale[3]) {
    scoreColorStart = "good-score";
  } else if (floatSO5Score < scale[4]) {
    scoreColorStart = "great-score";
  } else {
    scoreColorStart = "amazing-score";
  }
  scoreColorStart = scoreColorStart + "-start";

  if (props.sport === sorareBasketball) {
    let size = props.size || "w-12";
    let font = props.fontSize || "120px";
    let text = floatSO5Score.toFixed(precision);
    let labelStyle = props.labelStyle || "text-center font-semibold text-xs";
    let spacing = props.spacing || "space-y-1";
    return (
      <div className={"flex flex-col items-center " + spacing}>
        <div className={size}>
          <a href={props.playerId && "/player/" + props.playerId} target={"_blank"} className={size} rel="noreferrer">
            <BaseballScoreCircle size={font} scale={"basketball"} score={floatSO5Score} precision={floatSO5Score >= 10 ? 0 : 1} />
          </a>
        </div>
        <div className={"w-8"}>
          <GamesPlayed
            sport={sorareBasketball}
            period={48}
            gms={props.period}
            played={props.played}
            showPct={true}
            label={props.played + "'"}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }

  if (props.powerAdj) {
    floatSO5Score = floatSO5Score * props.power;
  }
  let text = floatSO5Score.toFixed(precision);
  let size = "w-8 h-7 md:w-9 md:h-8 xl:h-7 xl:w-8 2xl:w-8 2xl:h-7 2.5xl:w-9 2.5xl:h-8";
  if (props.size) {
    size = props.size;
  }
  let font = "text-md md:text-base xl:text-sm 2xl:text-md 2.5xl:text-md font-semibold";
  if (props.font) {
    font = props.font;
  }
  let playedSize = "w-8 md:w-9 xl:w-8 2xl:w-8 2.5xl:w-9";
  if (props.playedSize) {
    playedSize = props.playedSize;
  }
  if (floatSO5Score === -1) {
    scoreColorStart = "bar-grey";
    text = "NA";
    font += " text-brand-black";
  }
  return (
    <div ref={refContainer} className={props.size === "adapting" ? "w-full h-full" : ""}>
      <a
        href={props.playerId && "/player/" + props.playerId}
        target={"_blank"}
        className={props.size === "adapting" ? "w-full h-full block" : ""}
        rel="noreferrer"
      >
        <div className={`flex flex-col space-y-1 mx-auto items-center w-full h-full`}>
          <div
            className={`flex flex-row ${size !== "adapting" ? size + " rounded-md " : "rounded-[20%]"} ${"bg-" + scoreColorStart}`}
            style={
              size === "adapting"
                ? {
                    aspectRatio: "1/1",
                    height: containerSize,
                    width: containerSize,
                  }
                : undefined
            }
            data-tip={"Average SO5 points (DNPs excluded) over the past " + props.period + " games"}
          >
            <p
              className={`flex mx-auto self-center justify-center ${size !== "adapting" ? font : "font-semibold"} text-focus text-center`}
              style={size === "adapting" ? { fontSize: containerWidth / 3 } : undefined}
            >
              {text}
            </p>
          </div>
          {!props.hidePct && props.sport !== sorareBaseball && (
            <div className={props.size !== "adapting" ? playedSize : "w-full max-w-[2em]"}>
              <GamesPlayed sport={props.sport} tier={props.user?.tier} showPct={props.showPct} period={props.period} played={played} />
            </div>
          )}
        </div>
      </a>
    </div>
  );
}

export default withUser(ScoreAverage);
