import React, { useState } from "react";
import SaveIcon from "../../img/save-icon-lineup.svg";
import DeleteIcon from "../../img/delete-icon-lineup.svg";
import RemoveCardIcon from "../../img/remove-card-builder.svg";
import UniqueHeader from "../../img/UNIQUE.svg";
import MixteHeader from "../../img/MIXTE.svg";
import SuperRareHeader from "../../img/SUPERRARE.svg";
import RareHeader from "../../img/RARE.svg";
import LimitedHeader from "../../img/limited-header.svg";
import NBAL10Circle from "../util/NBAL10Circle";
import NBAProjectedScore from "../util/nbaProjectionScore";
import NBAProjDiff from "../util/NBAProjDiff";
import HomeIcon from "../../img/homeIcon.svg";
import AwayIcon from "../../img/awayIcon.svg";
import NewCardIcon from "../util/newCardIcon";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { withUser } from "../../userContext";
import NBALineupPointsRemaining from "./NBARemainingPoints";
import { ReactComponent as Bulb } from "../../img/bulb.svg";
import NbaSuggestionModal from "./nbaSuggestionModal";

function NBALineupBuilderCard(props) {
  const [showRemove, setShowRemove] = useState(false);

  const entry = props.card;
  const showStats = props.showStats;
  const isMvp = props.mvp;
  const concept = props.concept;
  if (entry === null) {
    return (
      <div>
        {concept && <div className={"h-6"} />}
        <div
          className={
            "flex flex-col bg-textGrey2  4xl:w-builder-card-xl 4xl:h-builder-card-xl w-builder-card h-builder-card rounded-lg justify-content-center "
          }
        >
          <div className={"h-4/5 w-card self-center align-middle flex flex-row justify-center"}></div>
        </div>
      </div>
    );
  } else {
    let card = props.card.card;
    let owned = false;
    if (concept && entry.ownedCard.TokenId !== "") {
      card = props.card.ownedCard;
      owned = true;
    }
    let bg_url;
    if (card.PictureUrl) {
      bg_url = card.PictureUrl;
    }
    let playerTeam = entry.player?.TeamId;
    let isHome = false;
    let opponent = entry.bestGame?.homeTeam?.PictureUrl;
    if (entry.bestGame?.homeTeam.TeamId === playerTeam) {
      opponent = entry.bestGame?.awayTeam.PictureUrl;
      isHome = true;
    }
    let textGrey = "text-textGrey3 ";
    if (concept) {
      textGrey = "text-white ";
    }
    let saturate = "";
    if (concept && !owned) {
      saturate = "grayscale(70%)";
    }
    let powerColor = "text-limited";
    if (card.Scarcity === "RARE") {
      powerColor = "text-rare";
    } else if (card.Scarcity === "SUPER RARE") {
      powerColor = "text-super-rare";
    } else if (card.Scarcity === "UNIQUE") {
      powerColor = "text-unique";
    } else if (card.Scarcity === "COMMON") {
      powerColor = "text-brand-black";
    }
    return (
      <div className={"space-y-2"}>
        {concept && (
          <div className={"h-4"}>
            <div className={"flex flex-row text-xxs font-semibold justify-center"}>
              {owned && (
                <div className={"px-2 py-0.5 bg-white rounded-full text-brand-black"}>
                  <p>Owned</p>
                </div>
              )}
              {!owned && (
                <div className={"px-2 py-0.5 bg-white rounded-full text-textGrey2 bg-textGrey4"}>
                  <p>Concept</p>
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={"w-builder-card h-builder-card 4xl:w-builder-card-xl 4xl:h-builder-card-xl relative"}
          onMouseOver={() => setShowRemove(true)}
          onMouseLeave={() => setShowRemove(false)}
        >
          <div
            className={"absolute w-full h-full cursor-pointer z-0"}
            style={{
              backgroundImage: "url('" + bg_url + "')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              filter: saturate,
            }}
          />
          <div className={"z-10"}>
            {showRemove && !props.editOnly && (
              <div
                className="absolute z-10 cursor-pointer transform w-6 pt-1 pl-1 justify-end self-end"
                onClick={() => {
                  props.removeCard(entry);
                  setShowRemove(false);
                }}
              >
                <img className={"w-full h-full"} src={RemoveCardIcon} />
              </div>
            )}
            {isMvp && (
              <div className={"absolute absolute left-0 top-0"}>
                <div className={"flex flex-row justify-start mt-1.5 ml-1 bg-super-rare rounded-sm p-0.5"}>
                  <p className={"text-white text-xxs font-semibold"}>MVP</p>
                </div>
              </div>
            )}
            <div className={"absolute inset-x-0 bottom-2"}>
              <div className={"flex flex-row justify-center"}>
                <NBAL10Circle score={entry.average.AverageNoDnp} />
              </div>
            </div>
          </div>
        </div>
        <div className={"rounded-lg p-1 space-y-1.5"}>
          <p className={textGrey + " font-semibold text-xs text-center"}>BEST GAME</p>
          <div className={"flex flex-row space-x-2 justify-center"}>
            <div className={"flex flex-col items-center self-center space-y-1"}>
              <div className={"w-6 h-6 self-center"}>
                <img className={"w-full h-full object-contain"} src={opponent} />
              </div>
              <div>{isHome ? <img src={HomeIcon} /> : <img src={AwayIcon} />}</div>
            </div>

            <div className={"flex flex-col self-center items-center space-y-1.5"}>
              <div className={"flex flex-row justify-center self-center"}>
                <NBAProjectedScore size={"w-6 h-6"} font={"text-xs"} score={entry.bestProjection} />
              </div>
              <NBAProjDiff padding={" py-0.5 px-1.5 "} font={"text-xxs"} width={" w-7 "} projDiff={entry.projectionDiff} />
            </div>
          </div>
        </div>
        {concept && (
          <div className={"border-t border-grey-e5 py-2 mx-2"}>
            <div className={"flex flex-col items-center space-y-1"}>
              <p className={"text-xs font-semibold " + powerColor}>
                {formatBestMarketPrice(entry.priceDetails.floor, props.user.preferredUnit)}
              </p>
              <p className={"text-xxs font-semibold"}>Floor price</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function NBALineupBuilderLineup(props) {
  const divId = props.divId;
  const division = props.division;
  const divisionStats = props.divisionStats;
  const lineup = props.lineup;
  const concept = props.concept;
  const [openModal, setOpenModal] = useState(false);
  let header;
  if (divId.includes("unique")) {
    header = UniqueHeader;
  } else if (divId.includes("weekly")) {
    header = MixteHeader;
  } else if (divId.includes("super-rare")) {
    header = SuperRareHeader;
  } else if (divId.includes("rare")) {
    header = RareHeader;
  } else if (divId.includes("limited")) {
    header = LimitedHeader;
  } else {
    header = MixteHeader;
  }

  let mvp = "";
  let mvpScore = 0;
  let lineupScore = 0;
  let projectedScore = 0;
  if (division?.hasMvp || division?.divId?.includes("champion")) {
    {
      lineup.map((entry) => {
        if (entry !== null && entry.average.AverageNoDnp > mvpScore) {
          mvp = entry.card.TokenId;
          mvpScore = entry.average.AverageNoDnp;
        }
      });
    }
  }

  {
    lineup.map((entry) => {
      if (entry !== null) {
        lineupScore += entry.average.AverageNoDnp;
        projectedScore += entry.bestProjection * entry.card.Power;
      }
    });
  }
  if (mvpScore > 0) {
    lineupScore = lineupScore - mvpScore;
  }

  let projectedScarcity = "";
  let projectedTier = 0;

  divisionStats?.stats?.map((entry) => {
    if (entry.endRange <= projectedScore) {
      projectedScarcity = entry.scarcity;
      projectedTier = entry.tier;
    }
  });

  const cap = division?.cap;
  let bg = "bg-white text-brand-black";
  let greyText = "text-textGrey3";
  if (concept) {
    bg = "bg-brand-black text-white";
    greyText = "text-white";
  }

  const atLeastOnePlayerPicked = lineup.filter((entry) => entry !== null).length > 0;
  const lineupFinished = lineup.filter((entry) => entry === null).length === 0;
  return (
    <>
      {openModal && <NbaSuggestionModal close={() => setOpenModal(false)} {...props} lineup={lineup} pickedCards={props.pickedCards} />}
      <div className={bg + " rounded-lg space-y-2 1.5xl:w-full w-full"}>
        <div
          className={"py-2 rounded-t-lg flex flex-row justify-between"}
          style={{
            backgroundImage: "url('" + header + "')",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className={"self-center flex flex-row space-x-2"}>
            <p className={"text-base font-semibold text-white pl-4"}>{props.divName}</p>
            {concept && (
              <div className={"px-2 py-0.5 bg-white rounded-full text-brand-black text-xxs self-center font-semibold"}>
                <p>Concept</p>
              </div>
            )}
          </div>
          {!props.editOnly && !concept && (
            <div className={"flex flex-row space-x-3 self-center pr-4"}>
              <div className={"w-5 flex self-center cursor-pointer"} onClick={() => props.saveLineup()}>
                <img className={"w-full h-full object-contain"} src={SaveIcon} />
              </div>
              <div className={"w-4 flex self-center cursor-pointer"} onClick={() => props.deleteLineup()}>
                <img className={"w-full h-full self-center"} src={DeleteIcon} />
              </div>
            </div>
          )}
        </div>
        <div className={"mx-6"}>
          <NBALineupPointsRemaining concept={concept} cap={cap} points={lineupScore} />
        </div>
        <div className={"flex flex-row justify-center space-x-2 pt-2 px-4"}>
          {lineup.map((entry) => {
            return (
              <NBALineupBuilderCard
                removeCard={props.removeCard}
                card={entry}
                concept={concept}
                user={props.user}
                editOnly={props.editOnly}
                mvp={entry !== null ? entry.card?.TokenId === mvp && mvp !== "" : false}
              />
            );
          })}
        </div>
        {!lineupFinished && !concept && (
          <div className={"flex flex-row justify-center py-1"}>
            <button
              type="button"
              onClick={() => setOpenModal(true)}
              className={`inline-flex items-center px-6 py-2 border border-brand text-med font-semibold 
                    rounded-lg shadow-sm text-white focus:outline-none focus:ring-0 bg-brand hover:border-brand-light hover:opacity-50 cursor-pointer`}
            >
              <span className={"inline flex flex-row gap-2"}>
                <Bulb className={"w-4 h-4 my-auto fill-white"} />
                <span className={"my-auto "}>{atLeastOnePlayerPicked ? "Complete the lineup" : "Suggest a lineup"}</span>
                <span
                  className={`my-auto text-xxs font-bold rounded-full flex justify-center uppercase px-2 py-0.5 text-white bg-brand-black`}
                >
                  Beta
                </span>
              </span>
            </button>
          </div>
        )}
        <div className={"flex flex-row justify-between border-t border-grey-e5 px-6 py-2 rounded-b-lg"}>
          <div className={"flex flex-col space-y-0.5"}>
            <p className={"text-sm font-semibold " + greyText}>PROJECTED SCORE</p>
            <p className={"text-sm font-semibold"}>{projectedScore.toFixed(2)} pts</p>
          </div>
          <div className={"flex flex-col space-y-0.5 text-right"}>
            <p className={"text-sm font-semibold " + greyText}>PROJECTED REWARD</p>
            {projectedTier > 0 && (
              <div className={"flex flex-row space-x-2 justify-end"}>
                <p className={"text-sm font-semibold"}>TIER {projectedTier} </p>
                <div className={"w-3"}>
                  <NewCardIcon scarcity={projectedScarcity.toLowerCase()} />
                </div>
              </div>
            )}
            {projectedTier === 0 && <p className={"text-sm font-semibold"}>NONE</p>}
          </div>
        </div>
      </div>
    </>
  );
}

export default withUser(NBALineupBuilderLineup);
