import ThreeDays from "../../img/3d-avg.svg";
import ThirtyDays from "../../img/30d-avg.svg";
import FourteenDays from "../../img/14d-avg.svg";
import SevenDays from "../../img/7d-avg.svg";
import FloorPrice from "../../img/best-price.svg";
import Lifetime from "../../img/lifetime-avg.svg";

export function getValuationOrigin(origin) {
  if (origin === "3d") {
    return "3 days average";
  } else if (origin === "7d") {
    return "1 week average";
  } else if (origin === "14d") {
    return "2 weeks average";
  } else if (origin === "30d") {
    return "1 month average";
  } else if (origin === "lifetime") {
    return "Card lifetime price average";
  } else if (origin === "best_market_price") {
    return "Floor price";
  } else if (origin === "bmp") {
    return "Floor price";
  } else if (origin === "starting_price") {
    return "No sale";
  } else if (origin === "last_sale") {
    return "Last public sale";
  }
  return "No sale";
}

export function getValuationOriginShort(origin) {
  if (origin === "3d") {
    return "3 days";
  } else if (origin === "7d") {
    return "1 week";
  } else if (origin === "14d") {
    return "2 weeks";
  } else if (origin === "30d") {
    return "1 month";
  } else if (origin === "lifetime") {
    return "lifetime";
  } else if (origin === "best_market_price") {
    return "Floor price";
  } else if (origin === "bmp") {
    return "Floor price";
  } else if (origin === "starting_price") {
    return "No sale";
  } else if (origin === "last_sale") {
    return "Last sale";
  }
  return "No sale";
}
export function getValuationIcon(origin) {
  if (origin === "3d") {
    return ThreeDays;
  } else if (origin === "7d") {
    return SevenDays;
  } else if (origin === "14d") {
    return FourteenDays;
  } else if (origin === "30d") {
    return ThirtyDays;
  } else if (origin === "lifetime") {
    return Lifetime;
  } else if (origin === "best_market_price") {
    return FloorPrice;
  } else if (origin === "bmp") {
    return FloorPrice;
  }
  return ThreeDays;
}

export function getValuationOriginShortOnCard(origin) {
  if (origin === "3 days average" || origin === "3d") {
    return "3D AVG";
  } else if (origin === "1 week average" || origin === "7d") {
    return "1W AVG";
  } else if (origin === "2 weeks average" || origin === "14d") {
    return "2W AVG";
  } else if (origin === "1 month average" || origin === "30d") {
    return "1M AVG";
  } else if (origin === "Last public sale" || origin === "last_sale") {
    return "Last sale";
  } else if (origin === "Floor price" || origin === "best_market_price" || origin === "bmp") {
    return "Floor price";
  } else if (origin === "No sale" || origin === "starting_price") {
    return "No sale";
  }
  return origin;
}
