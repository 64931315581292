import React, { useCallback, useEffect, useState } from "react";
import { withUser } from "../../userContext";
import LineupBuilderHeader from "../../img/lineup-builder-header.png";
import LineupBuilderHeaderDark from "../../img/lineup-builder-header-dark.png";
import { getAllGameweeksObjects } from "../util/nextGws";
import { isFree } from "../util/handleSubscriptionTier";
import { ReactTooltip } from "../util/tooltip.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import SportsPicker from "../util/sportsPicker";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import NBALineupBuilder from "./nbaLineupBuilder";
import AllNbaLineups from "./allNbaLineups";
import { CommunityBuilding } from "./communityBuilding";
import LineupBuilderDraftPicker, { defaultDraft } from "./lineupBuilderDraftPicker";
import { FootballLineupBuilder } from "./footballLineupBuilder";
import { useQuery } from "../util/useQuery";
import { MySavedLineups } from "./mySavedLineups";
import { errorCatcher } from "../util/errors";
import EmptyAvatar from "../../img/empty-avatar.svg";
import IsNaN from "lodash-es/isNaN";
import BaseballLineupBuilder from "./baseballLineupBuilder";
import AllMLBLineups from "./allMLBLineups";
import { useTheme } from "../../themeContext";
import { sdDark, sdLight } from "../../themeColors";
import { Dropdown } from "../util/dropdown";
import { defaultFootballLUBPreferences, getPreferencesFromLocalStorage, setPreferenceToLocalStorage } from "./footballPreferences";
import { UnsaveChangesModal } from "./lineupBuilderUnsaveChangesModal";
import { isMobile } from "react-device-detect";
import { SportNotSupported } from "../util/sportNotSupported";

function LineupBuilder(props) {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const embedded = props.embedded !== undefined ? props.embedded : false;
  const [gws, setGws] = React.useState([]);
  const [gwValue, setGwValue] = React.useState(!IsNaN(Number(query.get("gw"))) ? Number(query.get("gw")) : -1);
  const [activeTab, setActiveTab] = useState(props.match?.params?.tab || "builder");
  const [sport, setSport] = useState(props.match?.params?.sport || sorareFootball);
  const [draftId, setDraftId] = useState(query.get("draftId") || defaultDraft.draft_id);
  const [draftName, setDraftName] = useState(defaultDraft.draft_name);
  const [focus, setFocus] = useState("");
  const [lineupHash, setLineupHash] = useState("");
  const [themeFootballOverride, setThemeFootballOverride] = useState(getPreferencesFromLocalStorage().darkMode ? sdDark : sdLight);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [promptOnUnsavedChanges, setPromptOnUnsavedChanges] = useState(
    getPreferencesFromLocalStorage().ignoreUnsavedLineupChanges !== undefined
      ? !getPreferencesFromLocalStorage().ignoreUnsavedLineupChanges
      : !defaultFootballLUBPreferences.ignoreUnsavedLineupChanges,
  );
  const [openModalUnsavedChanges, setOpenModalUnsavedChanges] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);

  useEffect(() => {
    document.title = "Lineup builder - " + getUsername() || "";
  }, [props.user]);

  useEffect(() => {
    if (!embedded && props.match?.params?.tab !== undefined) {
      setActiveTab(props.match?.params?.tab);
    }
  }, [props.match?.params?.tab]);

  const fetchGw = useCallback(
    async (resetGw) => {
      let res = await getAllGameweeksObjects(props, sorareFootball);
      let tab = [];
      res.gameweeks.map((gw) => {
        tab.push({ value: gw.GwNumber, name: gw.DisplayName });
      });
      let nextGwsWithLimit = tab.map((gw, index) => {
        const isLocked = gw.value != res.next && isFree(props.user.tier);
        return {
          ...gw,
          locked: isLocked,
          tooltip: isLocked ? "Star membership needed" : "",
        };
      });
      if (resetGw || !gwValue || gwValue <= 0) {
        const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
        newParams.delete("draftId");
        newParams.delete("divisionId");
        newParams.delete("gw");
        history.replace({
          pathname: location.pathname,
          search: newParams.toString(),
        });
        setGwValue(res.next);
      }
      setSport(sorareFootball);
      setGws(nextGwsWithLimit);
    },
    [props, gwValue, history, query, location],
  );

  const fetchBasketballGws = useCallback(
    async (resetGw) => {
      let res = await getAllGameweeksObjects(props, sorareBasketball);
      let tab = [];
      res.gameweeks.map((gw) => {
        tab.push({ value: gw.GwNumber, name: gw.DisplayName });
      });
      let nextGwsWithLimit = tab.map((gw, index) => {
        return {
          ...gw,
        };
      });
      if (resetGw || gwValue <= 0) {
        // gw 0 exists for basketball
        const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
        newParams.delete("draftId");
        newParams.delete("divisionId");
        newParams.delete("gw");
        history.replace({
          pathname: location.pathname,
          search: newParams.toString(),
        });
        setGwValue(res.next);
      }
      setSport(sorareBasketball);
      setGws(nextGwsWithLimit);
    },
    [props, gwValue, history, query, location],
  );

  const fetchBaseballGws = useCallback(
    async (resetGw) => {
      let res = await getAllGameweeksObjects(props, sorareBaseball);
      let tab = [];
      res.gameweeks.map((gw) => {
        tab.push({ value: gw.GwNumber, name: gw.DisplayName });
      });
      let nextGwsWithLimit = tab.map((gw, index) => {
        return {
          ...gw,
        };
      });
      if (resetGw || gwValue <= 0) {
        // gw 0 exists for basketball
        const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
        newParams.delete("draftId");
        newParams.delete("divisionId");
        newParams.delete("gw");
        history.replace({
          pathname: location.pathname,
          search: newParams.toString(),
        });
        setGwValue(res.next);
      }
      setSport(sorareBaseball);
      setGws(nextGwsWithLimit);
    },
    [props, gwValue, history, query, location],
  );

  useEffect(() => {
    if (props.match?.params?.sport === sorareFootball) {
      fetchGw();
    } else if (props.match?.params?.sport === sorareBasketball) {
      fetchBasketballGws();
    } else if (props.match?.params?.sport === sorareBaseball) {
      fetchBaseballGws();
    } else {
      fetchGw();
    }
    ReactTooltip.rebuild();
  }, [props.user]);

  useEffect(() => {
    if (gwValue !== undefined && gwValue !== null && gwValue !== "") {
      const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
      newParams.set("gw", gwValue);
      history.replace({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  }, [gwValue]);

  useEffect(() => {
    if (draftId !== undefined && draftId !== null && draftId !== "") {
      const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
      newParams.set("draftId", draftId);
      newParams.delete("lineupId");
      history.replace({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    }
  }, [draftId]);

  const getUsername = useCallback(() => {
    let u = props.username;
    if (!u) {
      u = query.get("manager");
      if (!u) {
        u = props.user.sorareSlug;
      }
    }
    return u;
  }, [props.username, query, props.user.sorareSlug]);

  const getUserSorareId = useCallback(() => {
    let u = props.userSorareId;
    if (!u) {
      u = query.get("managerSorareId");
    }
    return u !== undefined && u != null ? u : "";
  }, [props.userSorareId, query]);

  const onSaveFootballLineup = useCallback((lineupId) => {
    setHasUnsavedChanges(false);
    setLineupHash(lineupId);
  }, []);

  const onChangePromptOnUnsavedChanges = (prompOnUnsavedChanges) => {
    setPromptOnUnsavedChanges(prompOnUnsavedChanges);
    setPreferenceToLocalStorage({ ...getPreferencesFromLocalStorage(), ignoreUnsavedLineupChanges: !prompOnUnsavedChanges });
  };

  const executePendingAction = () => {
    if (pendingAction) {
      pendingAction();
      setPendingAction(null); // Clear after execution
    }
  };

  const interceptAction = (action) => {
    // Disable interception on mobile due to https://linear.app/soraredata/issue/SDFE-442/cant-scroll-after-two-consecutive-modals
    if (!isMobile && hasUnsavedChanges && promptOnUnsavedChanges && activeTab === "builder") {
      setOpenModalUnsavedChanges(true);
      setPendingAction(() => action); // Store action as a function
    } else {
      action(); // Execute immediately if no unsaved changes
    }
  };

  const handleLinkNavigation = (url, event) => {
    event.preventDefault();
    interceptAction(() => history.push(url));
  };

  const handleDiscardChanges = () => {
    setHasUnsavedChanges(false);
    setOpenModalUnsavedChanges(false);
    executePendingAction();
  };

  const handleCancel = () => {
    setOpenModalUnsavedChanges(false);
    setPendingAction(null);
  };

  const onThemeChange = (sport, isDarkTheme) => {
    setThemeFootballOverride(isDarkTheme ? sdDark : sdLight);
  };

  const inactiveClass = "text-md py-2 inline-flex items-center mt-1 font-medium text-white hover:text-opacity-80 focus:outline-none";
  const activeClass = "inline-flex items-center text-md mt-1 border-b-4 border-primary py-2 font-medium focus:outline-none";

  // deprecated: use userId instead
  const username = getUsername();
  const userId = getUserSorareId();
  const { theme } = useTheme();
  const isThemeDark = theme === sdDark || (themeFootballOverride === sdDark && sport === sorareFootball);

  const tabs = [
    {
      link: `/lineupBuilder/sections/builder/sport/${sport}?${query.toString()}`,
      key: "builder",
      label: "My lineup builder",
    },
    sport === sorareFootball
      ? {
          link: `/lineupBuilder/sections/community/sport/${sport}?${query.toString()}`,
          key: "community",
          label: "Community lineups",
        }
      : {},
    {
      link: `/lineupBuilder/sections/saved/sport/${sport}?${query.toString()}`,
      key: "saved",
      label: "My teams",
    },
  ];

  return (
    <div className={themeFootballOverride === sdDark && sport === sorareFootball ? sdDark : ""}>
      <UnsaveChangesModal
        show={openModalUnsavedChanges}
        close={handleCancel}
        onDiscard={handleDiscardChanges}
        onDontAskMeAgain={(dontAskMeAgain) => onChangePromptOnUnsavedChanges(!dontAskMeAgain)}
      />
      <div
        className={"w-full self-center flex flex-col relative"}
        style={{
          backgroundImage: "url('" + (isThemeDark ? LineupBuilderHeaderDark : LineupBuilderHeader) + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: isThemeDark ? "lightgray(0.5)" : "",
        }}
      >
        <div
          className={
            "grid grid-rows-3 grid-cols-1 lg:grid-rows-1 lg:grid-cols-3 justify-between self-center w-11/12 py-4 gap-2 4xl:mx-auto 4xl:w-10/12 4.5xl:w-9/12"
          }
        >
          <div className={"flex w-full justify-center lg:justify-start"}>
            {username === undefined || username === "" || username === props.user.sorareSlug ? (
              <LineupBuilderDraftPicker
                lineupHash={lineupHash}
                sport={sport}
                draft_id={draftId}
                gw={gwValue}
                targetUserId={userId}
                onChange={(d, dName) =>
                  interceptAction(() => {
                    setDraftId(d);
                    setDraftName(dName);
                  })
                }
              />
            ) : (
              <ManagerInfo manager={userId} />
            )}
          </div>
          <div className={"flex flex-row justify-center"}>
            <SportsPicker
              sport={sport}
              change={(s) =>
                interceptAction(() => {
                  if (s === sorareFootball) {
                    fetchGw(true);
                  } else if (s === sorareBaseball) {
                    fetchBaseballGws(true);
                  } else {
                    fetchBasketballGws(true);
                  }
                })
              }
            />
          </div>
          <div
            className={`${focus === "gw" ? "z-500" : ""} self-center flex flex-col justify-center mx-auto lg:ml-auto lg:mr-0`}
            style={{ minWidth: "calc(min(20em, 100%))" }}
          >
            <Dropdown
              options={gws}
              displayOptions={{
                height: "h-full",
                roundedTopFocus: "rounded-t-2xl",
                rounded: "rounded-3xl",
                roundedBottomFocus: "rounded-b-xl",
              }}
              selected={gwValue}
              onChange={(v) =>
                interceptAction(() => {
                  setGwValue(v);
                })
              }
              onBlur={() => setFocus("")}
              onFocus={() => setFocus("gw")}
            />
          </div>
        </div>
        <div className={"bg-transparent-inverse-surface-low bg-opacity-10 w-full"}>
          <div className="w-11/12 mx-auto 4xl:w-10/12 4.5xl:w-9/12 text-white text-md">
            <nav className="sm:flex sm:flex-row grid grid-cols-2 gap-x-8">
              {tabs
                ?.filter((e) => e.key)
                .map((e) => {
                  return (
                    <span key={e.label}>
                      <Link to={e.link} onClick={(ev) => handleLinkNavigation(e.link, ev)}>
                        <button className={activeTab === e.key ? activeClass : inactiveClass}>{e.label}</button>
                      </Link>
                    </span>
                  );
                })}
            </nav>
          </div>
        </div>
      </div>
      {sport === sorareFootball && (
        <div>
          <div className={"bg-surface py-4 min-h-screen"}>
            {activeTab === "builder" && (
              <FootballLineupBuilder
                gw={gwValue}
                targetUserId={userId}
                draftId={draftId}
                onSaveLineup={onSaveFootballLineup}
                onLineupChange={(lineup) => setHasUnsavedChanges(lineup.hasChangesToSave)}
                interceptAction={interceptAction}
                onPreferencesChange={(pref) => setPromptOnUnsavedChanges(!pref.ignoreUnsavedLineupChanges)}
                onThemeChange={(isThemeDark) => onThemeChange(sorareFootball, isThemeDark)}
              />
            )}
            {activeTab === "community" && (
              <div>
                <CommunityBuilding gw={gwValue} targetUserId={userId} />
              </div>
            )}
            {activeTab === "saved" && (
              <div>
                <MySavedLineups gw={gwValue} targetUserId={userId} draftId={draftId} draftName={draftName} dark={isThemeDark} />
              </div>
            )}
          </div>
          <ReactTooltip />
        </div>
      )}
      {sport === sorareBasketball && <SportNotSupported sport={sport} featureName="Lineup Builder" />}
      {sport === sorareBaseball && (
        <div className={"py-4"}>
          {activeTab === "builder" && (
            <div>
              <BaseballLineupBuilder gw={gwValue} sport={sport} targetUser={username} targetUserId={userId} draftId={draftId} />
            </div>
          )}
          {activeTab === "saved" && (
            <div className={"mx-4"}>
              <AllMLBLineups gw={gwValue} draftId={draftId} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const ManagerInfo = withUser((props) => {
  const { manager } = props;
  const [info, setInfo] = useState({});

  useEffect(() => {
    props
      .fetch(`/apiv2/manager/id/${manager}/info`)
      .then((response) => response.json())
      .then((res) => {
        setInfo(res?.manager || {});
      })
      .catch(errorCatcher());
  }, [manager]);

  return (
    <div className={"flex justify-center space-x-4 xl:w-5/12 2xl:w-6/12  md:w-6/12"}>
      <span className={"my-auto hidden md:inline rounded-full"}>
        <a href={"/manager/" + info?.Slug}>
          <img className={"rounded-full inline object-cover cursor-pointer w-10 h-10"} src={info?.PictureUrl || EmptyAvatar} />
        </a>
      </span>
      <div className={"flex flex-col self-center lg:w-40"}>
        <div className={"text-2xl text-grey-f8 font-semibold flex flex-row justify-between"}>
          <span>{info?.Nickname}</span>
        </div>
      </div>
    </div>
  );
});

export default withUser(LineupBuilder);
