import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactTooltip } from "../util/tooltip.js";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import NBAProjectedScore from "../util/nbaProjectionScore";
import LineSeparator from "../../img/line-separator.svg";
import NBAProjectedMinutes from "../util/nbaProjectedMinutes";
import { errorCatcher } from "../util/errors";
import NBALineupBuilderGame from "../decision/NBALineupBuilderGame";
import LimitedPoint from "../../img/limited-point.png";
import PriceIndicator from "../players/priceIndicator";
import { sorareBasketball } from "../util/sports";
import RarePoint from "../../img/rare-point.png";
import SuperRarePoint from "../../img/sr-point.png";
import PlayerAvailability from "../players/playerAvailability";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { not_all_basketball_clubs_objects } from "../util/basketballClubs";
import BoxWithToggle from "../util/boxWithToggle";
import { Range } from "rc-slider";
import RotowireCredit from "../util/rotowireCredit";

function BasketballGwProjections(props) {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortWhat, setSortWhat] = useState("L15");
  const [showValuation, setShowValuation] = useState(true);
  const [showBmp, setShowBmp] = useState(false);
  const [teamFilter, setTeamFilter] = useState([]);
  const [howManyGames, setHowManyGames] = useState([1, 2, 3]);
  const [maxMinutes, setMaxMinutes] = useState([0, 48]);
  const [maxScore, setMaxScore] = useState([0, 100]);
  const [nameFilter, setNameFilter] = useState("");
  const [inGallery, setInGallery] = useState(false);
  const [showU23, setShowU23] = useState(false);
  const [showVeterans, setShowVeterans] = useState(false);

  const username = props.user.sorareSlug;

  const sortData = (w, players, order) => {
    let howToSort = 0;
    if (order) {
      howToSort = order;
    } else {
      if (w === sortWhat) {
        howToSort = sortOrder * -1;
        setSortOrder(howToSort);
      } else {
        howToSort = -1;
        setSortOrder(-1);
      }
    }
    setSortWhat(w);
    players = players || filteredPlayers;
    let breakdown = [...players];
    let stat1 = "";
    let stat2 = "";
    let nbOfStat = 1;
    if (w === "L10") {
      stat1 = "average";
      stat2 = "AverageNoDnp";
      nbOfStat = 2;
    } else if (w === "L10Min") {
      stat1 = "average";
      stat2 = "GamesPlayed";
      nbOfStat = 2;
    } else if (w === "limited") {
      stat1 = "l_avg";
      stat2 = "Average";
      nbOfStat = 2;
    } else if (w === "rare") {
      stat1 = "r_avg";
      stat2 = "Average";
      nbOfStat = 2;
    } else if (w === "super rare") {
      stat1 = "sr_avg";
      stat2 = "Average";
      nbOfStat = 2;
    } else if (w === "ratioPerf") {
      stat1 = "projectionRatio";
    } else if (w === "diffPerf") {
      stat1 = "projectionDiff";
    } else if (w === "bestMinutes") {
      stat1 = "bestMinutes";
    } else if (w === "minutesDiff") {
      stat1 = "minutesDiff";
    } else if (w === "bestProjection") {
      stat1 = "bestProjection";
    } else {
      nbOfStat = 0;
    }
    if (nbOfStat === 1) {
      breakdown.sort(function (a, b) {
        if (a[stat1] < b[stat1]) {
          return howToSort * -1;
        } else if (a[stat1] > b[stat1]) {
          return howToSort;
        }
        return 0;
      });
    } else if (nbOfStat === 2) {
      breakdown.sort(function (a, b) {
        if (a[stat1][stat2] < b[stat1][stat2]) {
          return howToSort * -1;
        } else if (a[stat1][stat2] > b[stat1][stat2]) {
          return howToSort;
        }
        return 0;
      });
    } else if (w === "gamesNb") {
      breakdown.sort(function (a, b) {
        if (a.projections === null) {
          return howToSort * -1;
        }
        if (b.projections === null) {
          return howToSort;
        }
        if (a.projections.length < b.projections.length) {
          return howToSort * -1;
        } else if (a.projections.length > b.projections.length) {
          return howToSort;
        }
        return 0;
      });
    }

    setFilteredPlayers(breakdown);
    setDisplayedPlayers(breakdown.slice(0, 50));
    ReactTooltip.rebuild();
  };

  const getPlayers = () => {
    props
      .fetch("/apiv2/SO5/basketballProjections?gw=" + props.gwNumber + "&username=" + username)
      .then((response) => response.json())
      .then((res) => {
        if (res === null) {
          setDisplayedPlayers([]);
          setFilteredPlayers([]);
          setPlayers([]);
        } else {
          setDisplayedPlayers(res.slice(0, 50));
          setFilteredPlayers(res);
          setPlayers(res);
          filter(res, teamFilter, maxMinutes, maxScore, nameFilter);
        }
      })
      .catch(errorCatcher());
  };

  const filterByTeam = (tf, players) => {
    let newPlayers = [...players];
    setTeamFilter(tf);
    if (tf.length > 0) {
      newPlayers = newPlayers.filter((p) => tf.indexOf(p.player.TeamId) > -1);
    }
    return newPlayers;
  };

  const filterMinutes = (min, players) => {
    let newPlayers = [...players];
    setMaxMinutes(min);
    newPlayers = newPlayers.filter((p) => p.average.GamesPlayed <= min[1] && p.average.GamesPlayed >= min[0]);
    return newPlayers;
  };

  const filterScore = (sco, players) => {
    let newPlayers = [...players];
    setMaxScore(sco);
    newPlayers = newPlayers.filter((p) => p.average.AverageNoDnp <= sco[1] && p.average.AverageNoDnp >= sco[0]);
    return newPlayers;
  };

  const filterName = (name, players) => {
    let newPlayers = [...players];
    setNameFilter(name);
    newPlayers = newPlayers.filter((p) => p.player.DisplayName.toLowerCase().includes(name.toLowerCase()));
    return newPlayers;
  };

  const filterGallery = (gal, players) => {
    let newPlayers = [...players];
    setInGallery(gal);
    if (gal) {
      newPlayers = newPlayers.filter((p) => p.inGallery === gal);
    }
    return newPlayers;
  };

  const filterU23 = (u23, players) => {
    let newPlayers = [...players];
    setShowU23(u23);
    // create date object on Feb 1st 1998
    const date = new Date(1999, 1, 1);
    if (u23) {
      newPlayers = newPlayers.filter((p) => {
        return new Date(p.player.BirthDate) >= date;
      });
    }
    return newPlayers;
  };

  const filterVet = (vet, players) => {
    let newPlayers = [...players];
    setShowVeterans(vet);
    // create date object on Feb 1st 1993
    const date = new Date(1993, 1, 1);
    if (vet) {
      newPlayers = newPlayers.filter((p) => new Date(p.player.BirthDate) <= date);
    }
    return newPlayers;
  };

  const filter = (thePlayers, tf, min, sco, name, gal, u23, vet) => {
    thePlayers = thePlayers || players;
    let newPlayers = [...thePlayers];
    newPlayers = filterByTeam(tf, newPlayers);
    newPlayers = filterMinutes(min, newPlayers);
    newPlayers = filterScore(sco, newPlayers);
    newPlayers = filterName(name, newPlayers);
    newPlayers = filterGallery(gal, newPlayers);
    newPlayers = filterU23(u23, newPlayers);
    newPlayers = filterVet(vet, newPlayers);
    setFilteredPlayers(newPlayers);
    setDisplayedPlayers(newPlayers.slice(0, 50));
    //sortData("bestProjection", newPlayers, -1)
  };

  useEffect(() => {
    getPlayers();
  }, [props.gwNumber]);

  return (
    <div className={"w-11/12 mx-auto space-y-4 pb-8"}>
      <div className={"grid grid-cols-4 gap-4"}>
        <div>
          <div className={"text-left space-y-2"}>
            <p className={"space-x-2 text-sm text-textGrey3 font-medium"}>
              <span>
                Max points average over L10:{" "}
                <span className={"font-semibold text-brand-black"}>
                  {maxScore[0]}-{maxScore[1]}
                </span>
              </span>
            </p>
            <div className={"pr-4"}>
              <Range
                trackStyle={[
                  {
                    backgroundColor: "#f45206",
                    border: "",
                  },
                ]}
                handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                step={1}
                min={0}
                max={100}
                value={maxScore}
                onChange={(value) => filter(players, teamFilter, maxMinutes, value, nameFilter)}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={"text-left space-y-2"}>
            <p className={"space-x-2 text-sm text-textGrey3 font-medium"}>
              <span>
                Max minutes average over L10:{" "}
                <span className={"font-semibold text-brand-black"}>
                  {maxMinutes[0]}-{maxMinutes[1]}
                </span>
              </span>
            </p>
            <div className={"pr-4"}>
              <Range
                trackStyle={[
                  {
                    backgroundColor: "#f45206",
                    border: "",
                  },
                ]}
                handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                step={1}
                min={0}
                max={48}
                value={maxMinutes}
                onChange={(value) => filter(players, teamFilter, value, maxScore, nameFilter)}
              />
            </div>
          </div>
        </div>
        <div>
          <input
            className="focus:outline-none focus:ring-0 focus:border-0 font-medium block w-full py-2 shadow-sm rounded-md pl-4 sm:text-sm border-0"
            type="text"
            placeholder="Filter by name"
            value={nameFilter}
            onChange={(e) => filter(players, teamFilter, maxMinutes, maxScore, e.target.value)}
          />
        </div>
        <div>
          <SelectSearch
            options={not_all_basketball_clubs_objects}
            placeholder={"Filter by teams"}
            onChange={(v) => filter(players, v, maxMinutes, maxScore, nameFilter)}
            search={true}
            value={teamFilter}
            printOptions={"on-focus"}
            filterOptions={fuzzySearch}
            closeOnSelect={false}
            multiple={true}
          />
        </div>
        {/*<div className={"flex flex-row space-x-4"}>
                    <div onClick={() => {
                        // remove 1 from howManyGames array
                        let newHowManyGames = [...howManyGames];
                        let index = newHowManyGames.indexOf(1);
                        if (index > -1) {
                            newHowManyGames.splice(index, 1);
                        } else {
                            newHowManyGames.push(1)
                        }
                        setHowManyGames(newHowManyGames)
                    }}>
                        <p>1 gm</p>
                    </div>
                    <div>
                        <p>2 gm</p>
                    </div>
                    <div>
                        <p>3 gm</p>
                    </div>
                </div>*/}
        <div className={"col-span-4 flex flex-row space-x-2"}>
          <div className={"flex-row flex space-x-2 w-4/12"}>
            <div className={"py-1.5 rounded-lg bg-white self-center w-6/12"}>
              <BoxWithToggle isActive={showValuation} label="Latest valuation" setActive={() => setShowValuation(!showValuation)} />
            </div>
            <div className={"py-1.5 rounded-lg bg-white self-center w-6/12"}>
              <BoxWithToggle isActive={showBmp} label="Floor price" setActive={() => setShowBmp(!showBmp)} />
            </div>
          </div>
          <div className={"flex-row flex space-x-2 w-4/12"}>
            <div className={"py-1.5 rounded-lg bg-white self-center w-6/12"}>
              <BoxWithToggle
                isActive={showU23}
                label="U23 only"
                setActive={() => filter(players, teamFilter, maxMinutes, maxScore, nameFilter, inGallery, !showU23, showVeterans)}
              />
            </div>
            <div className={"py-1.5 rounded-lg bg-white self-center w-6/12"}>
              <BoxWithToggle
                isActive={showVeterans}
                label="Veterans only"
                setActive={() => filter(players, teamFilter, maxMinutes, maxScore, nameFilter, inGallery, showU23, !showVeterans)}
              />
            </div>
          </div>

          <div className={"py-1.5 rounded-lg bg-white self-center w-2/12"}>
            <BoxWithToggle
              isActive={inGallery}
              label="My players"
              setActive={() => filter(players, teamFilter, maxMinutes, maxScore, nameFilter, !inGallery)}
            />
          </div>
          <div className={"flex flex-row justify-end w-2/12"}>
            <RotowireCredit credit={"Projections by"} />
          </div>
        </div>
      </div>
      <InfiniteScroll
        next={() => {
          setDisplayedPlayers(filteredPlayers.slice(0, displayedPlayers.length + 20));
          ReactTooltip.rebuild();
        }}
        hasMore={displayedPlayers.length < filteredPlayers.length}
        dataLength={displayedPlayers.length}
        scrollThreshold={0.7}
        scrollableTarget={"table_scroll"}
      >
        <table className={"w-full"}>
          <thead className={"uppercase bg-brand-black text-white text-xxs font-semibold"}>
            <tr>
              <th className={"w-2/12 rounded-tl-lg"}></th>
              <th className={"w-1/32 text-center py-3 cursor-pointer"} onClick={() => sortData("L10")}>
                L10
              </th>
              <th
                className={"w-1/32 text-center cursor-pointer"}
                data-tip={"Best projected score this gameweek"}
                onClick={() => sortData("bestProjection")}
              >
                BEST GM PROJ.
              </th>
              <th
                className={"w-1/32 text-center cursor-pointer"}
                data-tip={"Best projected score - last 10 average (higher is better)"}
                onClick={() => sortData("diffPerf")}
              >
                DIFF.
              </th>
              <th
                className={"w-1/32 text-center cursor-pointer"}
                data-tip={"Best projected score / last 10 average (higher is better)"}
                onClick={() => sortData("ratioPerf")}
              >
                RATIO
              </th>
              <th className={"w-1/64"}></th>
              <th
                className={"w-1/32 text-center cursor-pointer"}
                data-tip={"Last 10 games minutes average"}
                onClick={() => sortData("L10Min")}
              >
                L10 MIN AVG.
              </th>
              <th
                className={"w-1/32 text-center cursor-pointer"}
                data-tip={"Best game projected minutes"}
                onClick={() => sortData("bestMinutes")}
              >
                BEST GM PROJ MINS
              </th>
              <th
                className={"w-1/32 text-center cursor-pointer"}
                data-tip={"Best game projected minutes - last 10 games minutes average (higher is better)"}
                onClick={() => sortData("minutesDiff")}
              >
                DIFF.
              </th>
              <th className={"w-1/64"}></th>
              <th
                className={"w-1/32 text-center cursor-pointer"}
                data-tip={"Number of games to be played in the gameweek"}
                onClick={() => sortData("gamesNb")}
              >
                NUMBER OF GMS
              </th>
              <th className={"w-1/18 text-center"} data-tip={"Game 1 projection"} onClick={() => sortData("matchup")}>
                GAME 1 PROJ.
              </th>
              <th className={"w-1/18 text-center"} data-tip={"Game 2 projection"} onClick={() => sortData("opp")}>
                GAME 2 PROJ.
              </th>
              <th className={"w-1/18 text-center"} data-tip={"Game 3 projection"} onClick={() => sortData("opp")}>
                GAME 3 PROJ.
              </th>
              <th className={"w-1/12 text-center cursor-pointer"} onClick={() => sortData("limited")}>
                LIMITED VALUE
              </th>
              <th className={"w-1/12 text-center cursor-pointer"} onClick={() => sortData("rare")}>
                RARE VALUE
              </th>
              <th className={"w-1/12 text-center rounded-tr-lg cursor-pointer"} onClick={() => sortData("super rare")}>
                SUPER RARE VALUE
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedPlayers.map((entry) => {
              const player = entry.player;

              let projDiff = entry.projectionDiff;
              let minutesDiff = entry.minutesDiff;

              let bgProjDiff = "";

              if (projDiff < -10) {
                bgProjDiff = "bg-horrible-score";
              } else if (projDiff < -5) {
                bgProjDiff = "bg-bad-score";
              } else if (projDiff < 1) {
                bgProjDiff = "bg-ok-score";
              } else if (projDiff < 6) {
                bgProjDiff = "bg-good-score";
              } else if (projDiff < 11) {
                bgProjDiff = "bg-great-score";
              } else {
                bgProjDiff = "bg-amazing-score";
              }

              if (projDiff.toFixed(0) === "0" || projDiff.toFixed(0) === "-0") {
                projDiff = "0";
              } else if (projDiff > 0) {
                projDiff = "+" + projDiff.toFixed(0);
              } else {
                projDiff = projDiff.toFixed(0);
              }

              let bgMinutesDiff = "";

              if (minutesDiff < -10) {
                bgMinutesDiff = "bg-horrible-score";
              } else if (minutesDiff < -5) {
                bgMinutesDiff = "bg-bad-score";
              } else if (minutesDiff < 1) {
                bgMinutesDiff = "bg-ok-score";
              } else if (minutesDiff < 5) {
                bgMinutesDiff = "bg-good-score";
              } else if (minutesDiff < 10) {
                bgMinutesDiff = "bg-great-score";
              } else {
                bgMinutesDiff = "bg-amazing-score";
              }

              if (minutesDiff.toFixed(0) === "0" || minutesDiff.toFixed(0) === "-0") {
                minutesDiff = "0";
              } else if (minutesDiff > 0) {
                minutesDiff = "+" + minutesDiff.toFixed(0);
              } else {
                minutesDiff = minutesDiff.toFixed(0);
              }

              return (
                <>
                  <tr key={player.PlayerId} className={"bg-white border-b border-grey-e5"}>
                    <td>
                      <div className={"flex flex-row justify-start space-x-2 py-2 pl-2 self-center"}>
                        <div className={"w-10 h-10 rounded-full"}>
                          <img src={player.Avatar} className={"object-cover w-full h-full rounded-full"} />
                        </div>
                        <div className={"flex flex-col self-center space-y-0.5"}>
                          <div className={"flex-row space-x-1 flex"}>
                            <div className={"text-xs font-semibold"}>
                              <a className={"hover:font-semibold"} href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                                <p>{player.DisplayName}</p>
                              </a>
                            </div>
                            <div className={"self-center"}>
                              <PlayerAvailability size={"w-3"} availability={entry.playerStatus} />
                            </div>
                          </div>
                          <div>
                            <p className={"text-xs text-textGrey3 font-medium"}>
                              {player.Position} - {player.Age} - {player.PlayingStatus}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center self-center"}>
                        <div className={"w-10 h-10"}>
                          <BaseballScoreCircle scale={"basketball"} precision={0} size={"120"} score={entry.average.AverageNoDnp} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center self-center"}>
                        <NBAProjectedScore size={"w-7 h-7"} font={"text-xs"} score={entry.bestProjection} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <div className={"rounded-2xl space-x-1 justify-center flex flex-row w-8 px-2 py-0.5 " + bgProjDiff}>
                          <p className={"font-semibold text-xs text-white"}>{projDiff}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <div className={"bg-grey-e5 rounded-2xl space-x-1 justify-center flex flex-row px-2 py-0.5"}>
                          <p className={"font-semibold text-xs"}>{entry.projectionRatio.toFixed(2)}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <img className={"w-3 h-8"} src={LineSeparator} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <NBAProjectedMinutes real={true} minutes={entry.average.GamesPlayed} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <NBAProjectedMinutes minutes={entry.bestMinutes} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <div className={"rounded-2xl space-x-1 justify-center flex flex-row w-8 px-2 py-0.5 " + bgMinutesDiff}>
                          <p className={"font-semibold text-xs text-white"}>{minutesDiff}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <img className={"w-3 h-8"} src={LineSeparator} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col items-center space-y-0.5"}>
                        <div className={"flex flex-row justify-center bg-grey-f2 px-2 py-0.5 rounded"}>
                          <p className={"text-xs font-semibold"}>{entry.projections !== null ? entry.projections.length : 0}</p>
                        </div>
                        <p className={"text-xxxs text-textGrey2 font-semibold"}>GAMES</p>
                      </div>
                    </td>
                    <td>
                      {entry.projections !== null && entry.projections.length > 0 && (
                        <div className={"py-2"}>
                          <NBALineupBuilderGame game={entry.projections[0]} />
                        </div>
                      )}
                    </td>
                    <td>
                      {entry.projections !== null && entry.projections.length > 1 && (
                        <div>
                          <NBALineupBuilderGame game={entry.projections[1]} />
                        </div>
                      )}
                    </td>
                    <td>
                      {entry.projections !== null && entry.projections.length > 2 && (
                        <div>
                          <NBALineupBuilderGame game={entry.projections[2]} />
                        </div>
                      )}
                    </td>
                    <td>
                      <div className={"py-1"}>
                        <PriceIndicator
                          scarcity={"limited"}
                          avg={entry.l_avg}
                          bmp={entry.bmpL}
                          user={props.user}
                          showValo={showValuation}
                          sport={sorareBasketball}
                          icon={LimitedPoint}
                          showBmp={showBmp}
                        />
                      </div>
                    </td>
                    <td>
                      <PriceIndicator
                        scarcity={"rare"}
                        avg={entry.r_avg}
                        bmp={entry.bmpR}
                        user={props.user}
                        showValo={showValuation}
                        sport={sorareBasketball}
                        icon={RarePoint}
                        showBmp={showBmp}
                      />
                    </td>
                    <td>
                      <PriceIndicator
                        scarcity={"super-rare"}
                        avg={entry.sr_avg}
                        bmp={entry.bmpSR}
                        user={props.user}
                        showValo={showValuation}
                        sport={sorareBasketball}
                        icon={SuperRarePoint}
                        showBmp={showBmp}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
      <ReactTooltip />
    </div>
  );
}

export default withUser(BasketballGwProjections);
