import React from "react";
import { Button } from "./button";
import Locker from "../util/orangeOpenLockerBrandBg";
import PlayCircle from "../../img/play_circle.svg";
import clsx from "clsx";

function UpgradeLimitBox(props) {
  let size = props.size; // md or sm
  if (size === undefined || (size !== "sm" && size !== "md")) {
    size = "md";
  }

  let textColor = "text-black";
  if (props.dark) {
    textColor = "text-white";
  }
  return (
    <div className={"flex flex-row w-full rounded-md bg-center " + props.className}>
      <div className="flex flex-row w-1/12 mr-4 justify-center self-center">
        <Locker containerStyle="w-7 h-7 ml-6" />
      </div>
      <div className={clsx("flex flex-col w-8/12 py-2 justify-center", textColor)}>
        <div className="mb-1">
          <p className={`${size === "md" ? "text-xl" : "text-md"} font-black text-left font-headers`}>{props.title}</p>
        </div>
        <div>
          <p className={`${size === "md" ? "text-sm" : "text-xs"} font-semibold text-left`}>{props.description}</p>
        </div>
        {props.description2 && (
          <div>
            <p className={`${size === "md" ? "text-sm" : "text-xs"} font-semibold text-left`}>{props.description2}</p>
          </div>
        )}
        {props.video && (
          <div className={"mt-1"}>
            <a href={props.video.url} target={"blank_"}>
              <div
                className={`flex flew-row gap-1 justify-start items-center hover:opacity-80 font-semibold ${
                  size === "md" ? "text-sm" : "text-xs"
                } `}
              >
                <p className={"text-brand"}>{props.video.msg}</p>
                <div className={"w-4 h-4 self-center inline-flex"}>
                  <img className={"w-full h-full object-contain"} src={PlayCircle} />
                </div>
              </div>
            </a>
          </div>
        )}
      </div>
      <div className="flex w-3/12 justify-end items-center pr-8">
        <a href={"/product"}>
          <Button label={props.button || "Upgrade"} co />
        </a>
      </div>
    </div>
  );
}

export default UpgradeLimitBox;
