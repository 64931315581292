import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "grommet";
import { FormSearch } from "grommet-icons";
import { withRouter } from "react-router-dom";
import { withUser } from "../../userContext";
import UnknownPlayer from "../../img/unknown_player2.png";
import UnknownClub from "../../img/unknown_club.png";
import SportsBaseballIcon from "../../img/icon-sports-baseball.svg";
import SportsBasketballIcon from "../../img/icon-sports-basketball.svg";
import SportsSoccerIcon from "../../img/icon-sports-soccer.svg";
import UserShieldIcon from "../../img/icon-shield-user.svg";
import TeamShieldIcon from "../../img/icon-shield-team.svg";
import CloseIcon from "../../img/icon-close.svg";
import CloseIconBlack from "../../img/icon-close-black.svg";
import { errorCatcher } from "../util/errors";
import useDebounce from "../../hooks/useDebounce";
import Spinner from "../loader/spinner";
import { ManagerVerifiedCheckMark } from "../util/manager";

function HomeSearch(props) {
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [displayResults, setDisplayResults] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isHoverResults, setIsHoverResults] = useState(false);
  const [selectableIndexes, setSelectableIndexes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAutoFocused, setHasAutoFocused] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);

  const hasResults =
    results.player?.length > 0 || results.players_by_sport?.length > 0 || results.team?.length > 0 || results.user?.length > 0;

  useEffect(() => {
    if (!displayResults) {
      setSelectableIndexes([]);
    }
  }, [displayResults]);

  useEffect(() => {
    setSelectableIndexes([]);
  }, [value]);

  useEffect(() => {
    if (isFocus) {
      setDisplayResults(true);
    } else if (isHoverResults) {
      setDisplayResults(true);
    }
  }, [isFocus, isHoverResults]);

  const searchUser = () => {
    if (selectableIndexes.length === 0) {
      let v = value;
      setValue("");
      props.history.push("/playerSearch/" + v);
    }
  };

  const searchAutoComplete = (v) => {
    setIsLoading(true);

    props
      .fetch("/search-api/search/autocomplete?query=" + v)
      .then((response) => response.json())
      .then((res) => {
        setResults(res);
      })
      .catch(errorCatcher())
      .finally(() => {
        setIsLoading(false);
      });
  };

  const debouncedSearchAutoComplete = useDebounce(searchAutoComplete, 250);

  const onClickItem = (type, id) => {
    if (type && id) {
      setValue("");
      props.history.push(`/${type}/${id}`);
    }
  };

  const handleInputValue = () => {
    inputRef.current.value = "";
    inputRef.current.focus();
    setValue("");
  };

  const onChange = (v) => {
    setValue(v.target.value);
    debouncedSearchAutoComplete(v.target.value);
  };

  useEffect(() => {
    if (props.autoFocus && !hasAutoFocused && inputRef.current) {
      inputRef.current.focus();
      setHasAutoFocused(true);
    }
  }, [props.autoFocus, hasAutoFocused]);

  const onKeyDownInputSearch = (e) => {
    const playerList = results.players_by_sport;
    const userList = results.user;
    const teamList = results.team;

    const playerLength = playerList?.reduce((total, sportData) => total + sportData.items.length, 0) || 0;
    const userLength = userList?.length || 0;
    const teamLength = teamList?.length || 0;
    const totalLength = playerLength + userLength + teamLength;

    if (displayResults) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectableIndexes((prevIndexes) => {
          const [listIndex, linkIndex] = prevIndexes;
          let newLinkIndex = linkIndex;
          let newListIndex = listIndex;

          if (newLinkIndex > 0) {
            newLinkIndex--;
          } else if (newListIndex > 0) {
            newListIndex--;
            newLinkIndex = getListLength(newListIndex) - 1;
          } else {
            newListIndex = totalLength - 1;
            newLinkIndex = getListLength(newListIndex) - 1;
          }

          return [newListIndex, newLinkIndex];
        });
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectableIndexes((prevIndexes) => {
          const [listIndex, linkIndex] = prevIndexes;
          let newLinkIndex = linkIndex;
          let newListIndex = listIndex;

          if (newLinkIndex < getListLength(newListIndex) - 1) {
            newLinkIndex++;
          } else if (newListIndex < totalLength - 1) {
            newListIndex++;
            newLinkIndex = 0;
          } else {
            newListIndex = 0;
            newLinkIndex = 0;
          }

          return [newListIndex, newLinkIndex];
        });
      } else if (e.key === "Enter") {
        e.preventDefault();

        const [listIndex, linkIndex] = selectableIndexes;

        if (selectableIndexes.length === 0) {
          searchUser();
        } else if (listIndex >= 0 && listIndex < playerList.length) {
          const sportData = playerList[listIndex];
          const player = sportData.items[linkIndex];
          onClickItem("player", player.item_id);
        } else if (listIndex >= playerList.length && listIndex < playerList.length + userLength) {
          const userIndex = listIndex - playerList.length;
          const user = userList[userIndex];
          onClickItem("manager", user.item_id);
          window.location.reload();
        } else if (listIndex >= playerList.length + userLength && listIndex < totalLength) {
          const teamIndex = listIndex - playerList.length - userLength;
          const team = teamList[teamIndex];
          onClickItem("team", team.item_id);
        }
      } else if (e.key === "Escape") {
        e.preventDefault();
        setSelectableIndexes([]);
        inputRef.current.blur();
        setDisplayResults(false);
      }
    }
  };

  const getListLength = (listIndex) => {
    if (listIndex >= 0 && listIndex < (results.players_by_sport?.length || 0)) {
      return results.players_by_sport[listIndex].items.length;
    } else if (listIndex < (results.players_by_sport?.length || 0) + (results.user?.length || 0)) {
      return 1;
    }
  };

  const getSportIcon = (icon) => {
    const icons = {
      sr_football: SportsSoccerIcon,
      sr_basketball: SportsBasketballIcon,
      sr_baseball: SportsBaseballIcon,
    };

    return icons[icon];
  };

  const getSportTitle = (key) => {
    const titles = {
      sr_football: "Football players",
      sr_basketball: "Basketball players",
      sr_baseball: "Baseball players",
    };

    return titles[key];
  };

  const getTeamTag = (key) => {
    const titles = {
      sr_football: "Football",
      sr_basketball: "Basketball",
      sr_baseball: "Baseball",
    };

    return titles[key];
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && document.activeElement !== inputRef.current) {
        setDisplayResults(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleLinkHighlight = (listIndex, linkIndex) => {
    setSelectableIndexes([listIndex, linkIndex]);
  };

  const isAuth = props.user.username !== undefined && props.user.username !== null && props.user.username !== "";
  const isHomePage = document.location.pathname === "/";

  if (!isAuth && isHomePage) {
    return null;
  }

  return (
    <div className="flex items-center bg-white lg:bg-opacity-0 shadow-sm lg:shadow-none absolute top-16 left-0 lg:relative lg:inset-auto p-3 lg:p-0 w-full z-50">
      <Form onSubmit={() => searchUser()} className="w-full lg:max-w-[402px]">
        <div className="flex rounded-full">
          <div className="relative bg-[#F0F0F0] lg:bg-brand-light-green flex items-stretch flex-grow h-10 rounded-full" ref={ref}>
            <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
              <span className="flex lg:hidden">
                <FormSearch className="h-5 w-5" color={"black"} aria-hidden="true" />
              </span>
              <span className="hidden lg:flex">
                <FormSearch className="h-5 w-5" color={"white"} aria-hidden="true" />
              </span>
            </div>

            <input
              ref={inputRef}
              className="bg-white bg-opacity-10 border-1 border-solid border-[#F0F0F0] focus:border-[#CCCCCC] lg:border-brand-light-green lg:focus:border-white rounded-full font-medium text-sm text-ellipsis lg:text-white placeholder:text-black-4d lg:placeholder:text-white placeholder:font-medium pl-10 w-full focus:ring-0"
              type="text"
              onChange={onChange}
              onFocus={(e) => {
                e.stopPropagation();
                setIsFocus(true);
              }}
              onBlur={(e) => {
                e.stopPropagation();
                setIsFocus(false);
              }}
              onKeyDown={onKeyDownInputSearch}
              value={value}
              placeholder="Search for a player/team: Vanaken, Trout, Real Madrid…"
            />

            {value.length > 0 && (
              <>
                <button className="hidden lg:flex lg:absolute lg:inset-y-0 lg:right-3 lg:flex lg:items-center" onClick={handleInputValue}>
                  <img src={CloseIcon} alt="Clear search" />
                </button>

                <button className="lg:hidden flex absolute inset-y-0 right-3 flex items-center" onClick={handleInputValue}>
                  <img className="w-[18px] h-[18px]" src={CloseIconBlack} alt="Clear search" />
                </button>
              </>
            )}
          </div>
        </div>
      </Form>

      {isLoading && (
        <div className="bg-white lg:rounded-2xl p-4 absolute top-14 lg:top-12 right-0 left-0 shadow-sm lg:shadow-md lg:max-w-[402px] z-50">
          <Spinner />
        </div>
      )}

      {displayResults && value !== "" && hasResults && (
        <div className="bg-white lg:rounded-2xl absolute top-14 lg:top-12 right-0 left-0 shadow-sm lg:shadow-md lg:max-w-[402px] z-50">
          <div className="flex flex-col space-y-1">
            {results.players_by_sport?.map((sportData, listIndex) => (
              <div key={sportData.sport} className="flex flex-col space-y-1 first-of-type:pt-3 last-of-type:pb-3">
                <div className="flex items-center space-x-1 px-4 py-1">
                  <img className="w-4 h-4" src={getSportIcon(sportData.sport)} alt="" />
                  <span className="text-xs text-black-4d text-left font-semibold">{getSportTitle(sportData.sport)}</span>
                </div>

                {sportData.items.map((player, linkIndex) => (
                  <Link
                    key={player.item_id}
                    className={`flex space-between space-x-1 px-4 py-1 hover:bg-[#FFDECC] ${
                      selectableIndexes[0] === listIndex && selectableIndexes[1] === linkIndex ? "bg-[#FFDECC]" : ""
                    }`}
                    to={`/player/${player.item_id}`}
                    onMouseEnter={() => handleLinkHighlight(listIndex, linkIndex)}
                    onMouseLeave={() => handleLinkHighlight(-1, -1)}
                  >
                    <span className="flex flex-1 space-x-2 items-center">
                      <img
                        className={`w-8 h-8 rounded-full bg-[#CCCCCC] ${player.sport === "sr_football" ? "pt-1 object-contain" : ""}`}
                        src={player.Picture || UnknownPlayer}
                        alt={player.item_name}
                        width="40"
                        height="40"
                      />
                      <span className="text-sm font-medium text-start text-ellipsis">{player.item_name}</span>
                    </span>

                    {player.TeamName && (
                      <span className="flex lg:hidden xl:flex items-center space-x-1">
                        <span className="text-xs text-black-4d font-semibold">
                          {player.TeamName.length > 35 ? player.TeamShortName : player.TeamName}
                        </span>
                        <img
                          className="w-4 h-4 object-contain"
                          src={player.TeamPictureUrl || UnknownClub}
                          alt={player.TeamName}
                          onError={(e) => {
                            e.target.src = UnknownClub;
                          }}
                        />
                      </span>
                    )}
                  </Link>
                ))}
              </div>
            ))}

            {results.user?.length > 0 && (
              <div className="flex flex-col space-y-1 first-of-type:pt-3 last-of-type:pb-3">
                <div className="flex items-center space-x-1 px-4 py-1">
                  <img className="w-4 h-4" src={UserShieldIcon} alt="" />
                  <span className="text-xs text-black-4d text-left font-semibold">Managers</span>
                </div>

                {results.user.map((userData, linkIndex) => (
                  <Link
                    key={userData.item_id}
                    className={`flex space-between space-x-1 px-4 py-1 hover:bg-[#FFDECC] ${
                      selectableIndexes[0] === results.players_by_sport?.length + linkIndex ? "bg-[#FFDECC]" : ""
                    }`}
                    to={`/manager/${userData.item_id}`}
                    onMouseEnter={() => handleLinkHighlight(results.players_by_sport?.length + linkIndex, 0)}
                    onMouseLeave={() => handleLinkHighlight(-1, -1)}
                  >
                    <span className="flex flex-1 space-x-2 items-center">
                      <img
                        className={`w-8 h-8 rounded-full bg-[#CCCCCC] ${userData.Picture === "" ? "pt-1 object-contain" : "object-cover"}`}
                        src={userData.Picture || UnknownPlayer}
                        alt={userData.item_name}
                        width="40"
                        height="40"
                      />
                      <span className="flex gap-1">
                        <span className="text-sm font-medium text-start text-ellipsis">{userData.item_name}</span>
                        <ManagerVerifiedCheckMark managerSlug={userData.item_id} />
                      </span>
                    </span>
                  </Link>
                ))}
              </div>
            )}

            {results.team?.length > 0 && (
              <div className="flex flex-col space-y-1 first-of-type:pt-3 last-of-type:pb-3">
                <div className="flex items-center space-x-1 px-4 py-1">
                  <img className="w-4 h-4" src={TeamShieldIcon} alt="" />
                  <span className="text-xs text-black-4d text-left font-semibold">Teams</span>
                </div>

                {results.team.map((teamData, linkIndex) => (
                  <Link
                    key={teamData.item_id}
                    className={`flex items-center space-between space-x-1 px-4 py-1 hover:bg-[#FFDECC] ${
                      selectableIndexes[0] === results.players_by_sport?.length + results.user?.length + linkIndex ? "bg-[#FFDECC]" : ""
                    }`}
                    to={`/team/${teamData.item_id}`}
                    onMouseEnter={() => handleLinkHighlight(results.players_by_sport?.length + results.user?.length + linkIndex, 0)}
                    onMouseLeave={() => handleLinkHighlight(-1, -1)}
                  >
                    <span className="flex flex-1 space-x-2 items-center">
                      <img
                        className="w-8 h-8 object-contain"
                        src={teamData.Picture || UnknownClub}
                        alt={teamData.item_name}
                        width="40"
                        height="40"
                        onError={(e) => {
                          e.target.src = UnknownClub;
                        }}
                      />
                      <span className="text-sm font-medium text-start text-ellipsis">{teamData.item_name}</span>
                    </span>

                    <span className="flex lg:hidden xl:flex text-xs text-black-4d font-semibold">{getTeamTag(teamData.sport)}</span>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withUser(withRouter(HomeSearch));
