import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import InfiniteScroll from "react-infinite-scroll-component";
import AddLineupLine from "../../img/builder-pick.svg";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import NBAProjectedScore from "../util/nbaProjectionScore";
import NBAProjectedMinutes from "../util/nbaProjectedMinutes";
import LineSeparator from "../../img/line-separator.svg";
import PlayerAvailability from "../players/playerAvailability";
import NBAProjDiff from "../util/NBAProjDiff";
import { withUser } from "../../userContext";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import PlayerRollingAverage from "../players/playerRollingAverage";
import PlusMinus from "../../img/PlusMinus.svg";
import NbaGamesDetails from "./nbaGamesDetails";

function NBALineupBuilderTable(props) {
  const players = props.players;

  const [allPlayers, setAllPlayers] = useState(props.players);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortWhat, setSortWhat] = useState("L10");

  const powerAdjustedAverages = props.powerAdj;
  const odds = props.odds;
  const position = props.position;
  const concept = props.concept;

  const sortData = (w, keepOrder, pl) => {
    let howToSort = 0;
    if (w === sortWhat) {
      if (!keepOrder) {
        howToSort = sortOrder * -1;
        setSortOrder(howToSort);
      } else {
        howToSort = sortOrder;
      }
    } else {
      howToSort = -1;
      setSortOrder(-1);
    }
    setSortWhat(w);
    pl = pl || allPlayers;
    let breakdown = [...pl];
    let stat1 = "";
    let stat2 = "";
    let nbOfStat = 1;
    if (w === "L10") {
      stat1 = "average";
      stat2 = "AverageNoDnp";
      nbOfStat = 2;
    } else if (w === "L10Min") {
      stat1 = "average";
      stat2 = "GamesPlayed";
      nbOfStat = 2;
    } else if (w === "ratioPerf") {
      stat1 = "projectionRatio";
    } else if (w === "diffPerf") {
      stat1 = "projectionDiff";
    } else if (w === "bestMinutes") {
      stat1 = "bestMinutes";
    } else if (w === "minutesDiff") {
      stat1 = "minutesDiff";
    } else if (w === "bestProjection") {
      stat1 = "bestProjection";
    } else if (w === "upside") {
      stat1 = "upside";
    } else if (w === "ceiling") {
      stat1 = "ceiling";
    } else if (w === "vol") {
      stat1 = "detailedStats";
      stat2 = "stdDev";
      nbOfStat = 2;
    } else if (w === "games") {
      stat1 = "nbGames";
    } else {
      nbOfStat = 0;
    }
    if (nbOfStat === 1) {
      breakdown.sort(function (a, b) {
        if (a[stat1] < b[stat1]) {
          return howToSort * -1;
        } else if (a[stat1] > b[stat1]) {
          return howToSort;
        }
        return 0;
      });
    } else if (nbOfStat === 2) {
      breakdown.sort(function (a, b) {
        if (a[stat1][stat2] < b[stat1][stat2]) {
          return howToSort * -1;
        } else if (a[stat1][stat2] > b[stat1][stat2]) {
          return howToSort;
        }
        return 0;
      });
    } else if (w === "gamesNb") {
      breakdown.sort(function (a, b) {
        if (a.projections === null) {
          return howToSort * -1;
        }
        if (b.projections === null) {
          return howToSort;
        }
        if (a.projections.length < b.projections.length) {
          return howToSort * -1;
        } else if (a.projections.length > b.projections.length) {
          return howToSort;
        }
        return 0;
      });
    }

    setFilteredPlayers(breakdown);

    filterPlayers(breakdown);
    ReactTooltip.rebuild();
  };

  const filterPlayers = (pl) => {
    if (pl !== null && pl.length > 0) {
      let newPlayers = [...pl];
      if (props.onlyMyPlayers === true) {
        newPlayers = newPlayers.filter((player) => {
          return player.ownedCard.TokenId !== "";
        });
      }
      if (props.showEligible) {
        newPlayers = newPlayers.filter((player) => {
          return player.average.AverageNoDnp <= props.eligibleCap;
        });
      }
      setFilteredPlayers(newPlayers);
      if (newPlayers !== null) {
        setDisplayedPlayers(newPlayers.slice(0, 40));
      }
    } else {
      setFilteredPlayers([]);
      setDisplayedPlayers([]);
    }
  };

  useEffect(() => {
    setAllPlayers(props.players);
    sortData(sortWhat, true, props.players);
  }, [props.players]);

  useEffect(() => {
    sortData(sortWhat, true, props.players);
  }, [props.onlyMyPlayers, props.showEligible, props.refresh]);

  if (filteredPlayers === null || displayedPlayers === null) {
    return (
      <div>
        <p className={"text-center font-semibold text-sm"}>No players available.</p>
      </div>
    );
  }

  const divisionType = props.division?.divisionType;
  const allOffense = divisionType?.includes("all-offense");
  const allDefense = divisionType?.includes("all-defense");

  return (
    <div>
      <InfiniteScroll
        next={() => {
          setDisplayedPlayers(filteredPlayers.slice(0, displayedPlayers.length + 40));
          ReactTooltip.rebuild();
        }}
        hasMore={displayedPlayers.length < filteredPlayers.length}
        dataLength={displayedPlayers.length}
        scrollThreshold={0.7}
        scrollableTarget={"table_scroll"}
      >
        <table className={"w-full z-0"}>
          <thead className={"uppercase bg-builder-header text-textGrey3 text-xxs font-semibold"}>
            <tr>
              <th className={"w-16 text-center py-3 cursor-pointer"}>Add to lineup</th>
              <th className={"w-3.5/12 rounded-tl-lg"}>Player</th>
              <th className={"w-1/12 text-center cursor-pointer"} data-tip={"Game 1 projection"} onClick={() => sortData("games")}>
                NB of games
              </th>
              <th className={"w-1/24 text-center py-3 cursor-pointer"} onClick={() => sortData("L10")}>
                L10
              </th>
              <th
                className={"w-1/24 text-center cursor-pointer"}
                data-tip={"Best projected score this gameweek"}
                onClick={() => sortData("bestProjection")}
              >
                BEST GM PROJ.
              </th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Best projected score - last 10 average (higher is better)"}
                onClick={() => sortData("diffPerf")}
              >
                DIFF.
              </th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Best projected score / last 10 average (higher is better)"}
                onClick={() => sortData("ratioPerf")}
              >
                RATIO
              </th>
              <th className={"w-1/64"}></th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Downside - upside projection"}
                onClick={() => sortData("upside")}
              >
                DWN - UP proj.
              </th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Floor - ceiling projection"}
                onClick={() => sortData("ceiling")}
              >
                FLR - CEIL proj.
              </th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Volatility score (standard deviation over the past year)"}
                onClick={() => sortData("vol")}
              >
                VOL
              </th>
              <th className={"w-1/64"}></th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Last 10 games minutes average"}
                onClick={() => sortData("L10Min")}
              >
                L10 MIN AVG.
              </th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Best game projected minutes"}
                onClick={() => sortData("bestMinutes")}
              >
                BEST GM PROJ MINS
              </th>
              <th
                className={"w-1/18 text-center cursor-pointer"}
                data-tip={"Best game projected minutes - last 10 games minutes average (higher is better)"}
                onClick={() => sortData("minutesDiff")}
              >
                DIFF.
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedPlayers.map((entry, index) => {
              const player = entry.player;
              const card = entry.card;

              let power = (card.Power * 100 - 100).toFixed(1) + "%";
              let owned = false;
              if (concept) {
                if (entry.ownedCard.Power > 0) {
                  power = (entry.ownedCard.Power * 100 - 100).toFixed(1) + "%";
                  owned = true;
                }
              } else {
                owned = true;
              }
              let powerColor = "text-limited";
              if (card.Scarcity === "RARE") {
                powerColor = "text-rare";
              } else if (card.Scarcity === "SUPER RARE") {
                powerColor = "text-super-rare";
              } else if (card.Scarcity === "UNIQUE") {
                powerColor = "text-unique";
              } else if (card.Scarcity === "COMMON") {
                powerColor = "text-brand-black";
              }

              let projDiff = entry.projectionDiff;
              let minutesDiff = entry.minutesDiff;

              let bgMinutesDiff = "";

              if (minutesDiff < -10) {
                bgMinutesDiff = "bg-horrible-score";
              } else if (minutesDiff < -5) {
                bgMinutesDiff = "bg-bad-score";
              } else if (minutesDiff < 1) {
                bgMinutesDiff = "bg-ok-score";
              } else if (minutesDiff < 5) {
                bgMinutesDiff = "bg-good-score";
              } else if (minutesDiff < 10) {
                bgMinutesDiff = "bg-great-score";
              } else {
                bgMinutesDiff = "bg-amazing-score";
              }

              if (minutesDiff.toFixed(0) === "0" || minutesDiff.toFixed(0) === "-0") {
                minutesDiff = "0";
              } else if (minutesDiff > 0) {
                minutesDiff = "+" + minutesDiff.toFixed(0);
              } else {
                minutesDiff = minutesDiff.toFixed(0);
              }

              let canPick = props.canPick && props.checkPlayer(player.PlayerId);
              if (!props.checkPlayer(player.PlayerId) && !props.showPicked) {
                return null;
              }
              if (!props.checkCard(card.TokenId) && !props.showPicked) {
                return null;
              }
              let grayscale = "";
              if (!owned) {
                grayscale = "grayscale-70";
              }

              let subTitle = player.Position + " | " + player.Age + " | ";
              let spacing = "space-y-0.5";
              if (concept) {
                spacing = "space-y-1";
              }
              let rt = entry.priceDetails.latest;
              let bmp = entry.priceDetails.floor;

              return (
                <>
                  <tr key={card.TokenId} className={"bg-white border-b border-grey-e5"}>
                    <td className={"border-r border-grey-e5"}>
                      {props.canPick && props.checkPlayer(entry.player.PlayerId) && (
                        <div className={"space-y-2 flex flex-col items-center"}>
                          <div className={"w-8 h-8 items-center justify-center self-center align-middle flex"}>
                            <img
                              className={"cursor-pointer w-full h-full object-contain"}
                              src={AddLineupLine}
                              onClick={() => props.pickCard(entry)}
                            />
                          </div>
                          {!props.checkCard(entry.card.TokenId) && (
                            <p className={"text-center font-semibold text-xxs text-textGrey3"}>Picked</p>
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      <div className={"flex flex-row justify-start space-x-2 py-2 pl-2 self-center"}>
                        <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                          <div className={"w-10 h-10 rounded-full"}>
                            <img src={card.AvatarUrl} className={"object-cover w-full h-full rounded-full " + grayscale} />
                          </div>
                        </a>

                        <div className={"flex flex-col self-center " + spacing}>
                          <div className={"flex-row space-x-1 flex"}>
                            <div className={"text-sm font-semibold"}>
                              <a href={"/player/" + player.PlayerId} target={"_blank"} rel="noreferrer">
                                <p>{player.MatchName}</p>
                              </a>
                            </div>
                            <div className={"w-3 self-center"}>
                              <PlayerAvailability availability={entry.playerStatus} />
                            </div>
                            <div className={"self-center"}>
                              <p className={"text-xs font-bold self-center "}>{power}</p>
                            </div>
                          </div>
                          {!concept && (
                            <div className={"flex flex-row space-x-1"}>
                              <p className={"text-xs text-textGrey3 font-medium self-center"}>{subTitle}</p>
                              <div className={"flex flex-row space-x-1.5"}>
                                {entry.projections !== null &&
                                  entry.projections.map((proj, index) => {
                                    let opponent = proj.homeTeam.PictureUrl;
                                    if (proj.homeTeam.TeamId === entry.player.TeamId) {
                                      opponent = proj.awayTeam.PictureUrl;
                                    }
                                    return (
                                      <div className={"flex flex-row space-x-1"}>
                                        <div className={"w-4 h-4 self-center"}>
                                          <img className={"w-full h-full object-contain"} src={opponent} />
                                        </div>
                                        <div className={"self-center"}>
                                          <NBAProjectedScore font={"text-xxs"} size={"w-5 h-5"} score={proj.projection.score} />
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                          {concept && (
                            <div className={"flex flex-row space-x-2 " + powerColor}>
                              <div className={"w-20"}>
                                <PlayerRollingAverage
                                  color={powerColor}
                                  imgSize={"w-4 h-4"}
                                  justify={"justify-start space-x-1"}
                                  average={rt}
                                  fontSize={"text-xs"}
                                  period={rt.ValuationOrigin}
                                />
                              </div>
                              <div className={"w-20"}>
                                <PlayerRollingAverage
                                  color={powerColor}
                                  fontSize={"text-xs"}
                                  justify={"justify-start space-x-1"}
                                  average={formatBestMarketPrice(bmp, props.user.preferredUnit)}
                                  period={"floor"}
                                  imgSize={"w-4 h-4"}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <NbaGamesDetails games={entry.projections} player={entry} />
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center self-center"}>
                        <div className={"w-10 h-10"}>
                          <BaseballScoreCircle scale={"basketball"} precision={0} size={"120"} score={entry.average.AverageNoDnp} />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center self-center"}>
                        <NBAProjectedScore
                          size={"w-7 h-7"}
                          font={"text-xs"}
                          score={powerAdjustedAverages ? entry.bestProjection * card.Power : entry.bestProjection}
                        />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <NBAProjDiff projDiff={projDiff} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <div className={"bg-grey-e5 rounded-2xl space-x-1 justify-center flex flex-row px-2 py-0.5"}>
                          <p className={"font-semibold text-xs"}>{entry.projectionRatio.toFixed(2)}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <img className={"w-3 h-8"} src={LineSeparator} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center text-sm font-semibold text-[#808080]"}>
                        <p>
                          <span className={"text-[#E4C856]"}>{entry.downside.toFixed(0)}</span> -{" "}
                          <span className={"text-[#60C595]"}>{entry.upside.toFixed(0)}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center text-sm font-semibold text-[#808080]"}>
                        <p>
                          <span className={"text-[#E4C856]"}>{entry.floor.toFixed(0)}</span> -{" "}
                          <span className={"text-[#169A54]"}>{entry.ceiling.toFixed(0)}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <div className={"flex flex-row justify-center bg-[#767676] w-auto px-2 py-0.5 space-x-1 rounded-full"}>
                          <div className={""}>
                            <img src={PlusMinus} className={"w-full h-full object-contain self-center"} />
                          </div>
                          <p className={"text-white font-semibold text-xs"}>{entry.detailedStats.stdDev.toFixed(0)}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <img className={"w-3 h-8"} src={LineSeparator} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <NBAProjectedMinutes real={true} minutes={entry.average.GamesPlayed} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <NBAProjectedMinutes minutes={entry.bestMinutes} />
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-row justify-center"}>
                        <div className={"rounded-2xl space-x-1 justify-center flex flex-row w-8 px-2 py-0.5 " + bgMinutesDiff}>
                          <p className={"font-semibold text-xs text-white"}>{minutesDiff}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {props.showL15 && (
                    <tr className={"border-b border-t border-grey-e5 bg-white"}>
                      <td colSpan={12}>
                        <div className={"pb-1 pt-0.5 bg-white"}></div>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
      <ReactTooltip />
    </div>
  );
}

export default withUser(NBALineupBuilderTable);
