import React, { useState } from "react";
import MenuIcon from "../../img/icon-menu.svg";
import CloseIcon from "../../img/icon-close-black.svg";
import CalendarIcon from "../../img/icon-calendar-green.svg";
import GraphIcon from "../../img/icon-graph-green.svg";
import CardStackIcon from "../../img/icon-card-stack-green.svg";
import TagIcon from "../../img/icon-tag-green.svg";
import BellIcon from "../../img/icon-bell-green.svg";
import ChevronLeftIcon from "../../img/icon-chevron-left.svg";
import AvatarIcon from "../../img/icon-avatar.svg";
import SportsSoccerIcon from "../../img/sports_soccer.svg";
import SportsBasketballIcon from "../../img/sports_basketball.svg";
import SportsBaseballIcon from "../../img/sports_baseball.svg";
import LogoutModale from "../home/logoutModale";
import { NewTag } from "../home/sidebarItem";
import { menuData } from "../home/navbarComponents";

const getMenuIcon = (icon, user) => {
  const icons = {
    so5: CalendarIcon,
    scout: GraphIcon,
    builder: CardStackIcon,
    market: TagIcon,
    notifications: BellIcon,
    user: user?.sorarePictureUrl || AvatarIcon,
  };

  return icons[icon];
};

const getSportIcon = (key) => {
  const icons = {
    football: SportsSoccerIcon,
    basketball: SportsBasketballIcon,
    baseball: SportsBaseballIcon,
  };

  return icons[key];
};

const MobileMenu = ({ user, showMenu, onMenuToggle }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSubDropdownTab, setShowSubDropdownTab] = useState("");
  const [showModale, setShowModale] = useState(false);

  const handleLogout = () => {
    setShowModale(true);
  };

  const handleCancelLogout = () => {
    setShowModale(false);
    setShowDropdown(false);
    setShowSubDropdownTab("");
  };

  const handleMenuToggle = () => {
    onMenuToggle();
    setShowDropdown(!showDropdown);
    setShowSubDropdownTab("");
  };

  const handleMenuBack = () => {
    setShowSubDropdownTab("");
  };

  const generateDynamicSlug = (item) => {
    return typeof item.href === "function" ? item.href(user) : null;
  };

  return (
    <>
      <button
        className={`flex flex-col h-10 w-10 justify-center items-center ${showMenu ? "md:bg-white md:bg-opacity-14 md:rounded-full" : ""}`}
        onClick={handleMenuToggle}
      >
        <img src={MenuIcon} alt="Toggle menu" />
      </button>

      {showMenu && (
        <nav className="bg-menu-pattern bg-center bg-cover bg-no-repeat md:rounded-lg shadow-md absolute top-16 md:top-[60px] left-0 md:left-auto md:right-4 z-50 p-6 w-full md:w-64">
          <div className="flex flex-col">
            <header className="flex">
              {showSubDropdownTab && (
                <button className="flex items-center space-x-2 text-sm font-semibold text-brand-black" onClick={handleMenuBack}>
                  <img src={ChevronLeftIcon} alt="" />
                  <span className="text-sm text-sm font-semibold text-brand-black leading-6">{menuData[showSubDropdownTab].title}</span>
                </button>
              )}

              <button className="absolute top-6 right-6 md:hidden" onClick={handleMenuToggle}>
                <img src={CloseIcon} alt="Close menu" />
              </button>
            </header>

            {!showSubDropdownTab && (
              <ul className="flex flex-col items-start space-y-6">
                {Object.keys(menuData).map((key) => {
                  const item = menuData[key];

                  return (
                    <li key={key} className={`flex items-center space-x-2 text-sm text-brand-light-green font-semibold`}>
                      <img
                        src={getMenuIcon(key, user)}
                        className={`${key === "user" ? "rounded-full" : ""}`}
                        alt=""
                        width="24"
                        height="24"
                      />
                      <button onClick={() => setShowSubDropdownTab(item.name)}>{item.title}</button>
                    </li>
                  );
                })}
              </ul>
            )}

            {showSubDropdownTab && (
              <div className="flex flex-col mt-6 text-left">
                <ul className="flex flex-col">
                  {menuData[showSubDropdownTab].children.map((item) => {
                    const isTitle = item?.title;
                    const dynamicSlug = generateDynamicSlug(item);

                    return isTitle ? (
                      <li key={item.title} className="flex flex-col space-y-4 [&:not(:first-of-type)]:mt-8 w-1/2 md:w-full">
                        <span className="flex items-center border-b border-grey-b3 pb-3 space-x-2">
                          <img src={getSportIcon(item.name)} alt="" />
                          <span className="font-bold text-brand-light-green text-xl">{item.title}</span>
                        </span>

                        <ul className="flex flex-col space-y-4">
                          {item.children.map((item) => {
                            const dynamicSlug = generateDynamicSlug(item);

                            return (
                              <li>
                                <div className="flex items-center gap-1">
                                  <a className="font-semibold text-sm text-black-4D" href={dynamicSlug || item.href}>
                                    {item.label}
                                  </a>
                                  {item?.isNew && <NewTag />}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    ) : (
                      <li className="[&:not(:first-of-type)]:mt-4">
                        {!item.href ? (
                          <>
                            <button className="font-semibold text-sm text-black" onClick={handleLogout}>
                              {item.label}
                            </button>

                            {showModale && <LogoutModale open={showModale} onCancel={handleCancelLogout} />}
                          </>
                        ) : (
                          <div className="flex items-center gap-1">
                            <a
                              className={`flex font-semibold text-sm text-black-4D ${item.tag ? "space-x-2" : ""}`}
                              href={dynamicSlug || item.href}
                            >
                              <span>{item.label}</span>
                              {item.tag && (
                                <span className="flex items-center bg-[#0AC2AA] text-white text-xs rounded-full px-2 py-0.5">
                                  {item.tag}
                                </span>
                              )}
                            </a>
                            {item?.isNew && <NewTag />}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </nav>
      )}
    </>
  );
};

export default MobileMenu;
