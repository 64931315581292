import Tooltip from "react-tooltip";
import React from "react";
import { getThemeColor, useTheme } from "../../themeContext";

export const ReactTooltip = (props) => {
  const { theme } = useTheme();
  const newProps = {
    textColor: getThemeColor(theme, "inverse-on-surface"),
    backgroundColor: getThemeColor(theme, "inverse-surface"),
    ...props,
  };
  return <Tooltip {...newProps} />;
};

ReactTooltip.hide = Tooltip.hide;
ReactTooltip.rebuild = Tooltip.rebuild;
ReactTooltip.show = Tooltip.show;
