import Injured25 from "../../img/injury-25.svg";
import Injured100 from "../../img/injury-100.svg";
import PlayerAvatar from "../util/playerAvatar";
import { sorareBasketball } from "../util/sports";
import format from "date-fns/format";
import React from "react";

function TeamNewsBox(props) {
  const n = props.news;
  let theNews = n.news;
  let status = theNews.Injury.Status;
  let statusText = "";
  let icon = "";
  if (status === "GTD") {
    statusText = "Game-time decision";
    icon = Injured25;
  } else if (status === "OUT") {
    statusText = "Out";
    icon = Injured100;
  } else if (status === "OFS") {
    statusText = "Out for season";
    icon = Injured100;
  }
  let returnDate = theNews.Injury.ReturnDate;
  let returnDateText = "";
  if (returnDate !== "") {
    returnDateText = new Date(returnDate).toLocaleDateString();
  }
  return (
    <div className={"rounded-lg bg-white flex flex-col"}>
      <div className={"border-b border-grey-e5 h-20"}>
        <div className={"flex flex-row justify-between px-4"}>
          <div className={"flex flex-row space-x-2 self-center"}>
            <div className={"py-2 self-center"}>
              <PlayerAvatar player={n.player} sport={sorareBasketball} fullOpacity={true} />
            </div>
            <div className={"self-center flex flex-col"}>
              <p className={"text-base font-semibold"}>{theNews.Headline}</p>
              <p className={"text-sm text-textGrey3 font-semibold"}>
                <a href={"/player/" + n.player.PlayerId}>{n.player.DisplayName}</a>
              </p>
            </div>
          </div>
          <p className={"self-center font-semibold text-base text-textGrey3"}>{format(new Date(n.date), "d MMM yyyy")}</p>
        </div>
      </div>

      <div className={"px-4 py-4 space-y-4 h-full"}>
        <p className={"text-sm font-medium text-textGrey2"}>{theNews.Notes}</p>
        <div className={"space-y-2"}>
          <p className={"text-sm font-semibold"}>ANALYSIS</p>
          <p className={"text-sm font-medium text-textGrey2"}>{theNews.Analysis}</p>
        </div>
      </div>
      {theNews.Injury.Status !== "" && (
        <div className={"border-t border-grey-e5 justify-end items-end h-18"}>
          <div className={"flex flex-row px-4 justify-between py-4"}>
            <div className={"self-center"}>
              <p className={"text-xs font-semibold"}>STATUS</p>
              <div className={"flex flex-row space-x-1"}>
                <p className={"font-semibold text-base"}>{statusText}</p>
                {icon !== "" && <img className={"w-5 h-5 self-center"} src={icon} />}
              </div>
            </div>
            {returnDate !== "" && (
              <div className={"self-center"}>
                <p className={"text-xs font-semibold text-right"}>UNTIL</p>
                <p className={"font-semibold text-sm"}>{returnDateText}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamNewsBox;
