import { findPriceForUserUnit, formatPrice } from "./formatMoney";
import { getNavigatorLang } from "./locale";
import { units } from "./units";

export function formatBestMarketPrice(bmp, unit, lang) {
  let localeLang = lang || getNavigatorLang();
  const supportedLocales = Intl.NumberFormat.supportedLocalesOf(localeLang);
  if (supportedLocales === undefined || supportedLocales.length === 0) {
    localeLang = "en-US";
  }
  if (bmp === undefined) {
    return "No sale";
  }
  if (bmp.Price <= 0) {
    return "No sale";
  }
  if (unit === "eth") {
    if (bmp.Price <= 0) return "No sale";
    return formatPrice(bmp.Price, "eth");
  } else {
    let price;
    if (unit === "€" || unit === "eur") {
      unit = "eur";
      price = bmp["PriceEur"];
    } else if (unit === "$" || unit === "usd") {
      unit = "usd";
      price = bmp["PriceUsd"];
    } else if (unit === "£" || unit === "gbp") {
      unit = "gbp";
      price = bmp["PriceGbp"];
    }
    let max = 2;
    if (price >= 100) max = 0;
    if (price >= 1000) max = 0;
    if (price <= 0) return "No sale";
    try {
      return new Intl.NumberFormat(localeLang, {
        style: "currency",
        currency: unit ? unit.toUpperCase() : "USD",
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } catch (_) {
      // If currency style and options are not supported by the browser, use plain simple number format
      return (
        new Intl.NumberFormat(localeLang, {
          maximumFractionDigits: max,
          minimumFractionDigits: max,
        }).format(price) +
        " " +
        units[unit?.toLowerCase() || "$"]
      );
    }
  }
}
