import React from "react";
import CardMove from "./cardMove";

function CardMoves(props) {
  const moves = props.moves;
  const player = props.player;
  return (
    <div>
      {moves === null && (
        <div>
          <p className={"text-sm text-center"}>No history found for this token</p>
        </div>
      )}
      {moves !== null &&
        moves?.map((m, index) => {
          return (
            <div>
              <CardMove first={index === 0} last={index === moves.length - 1} player={player} move={m} />
            </div>
          );
        })}
    </div>
  );
}

export default CardMoves;
