import React from "react";
import { withUser } from "../../userContext";
import CustomLineupCard from "./CustomLineupCard";
import useComponentVisible from "../util/outsideAlerter";
import GameInLine from "./gameInLine";
import ScoreAverage from "./scoreAverage";
import OppScore from "./oppScore";
import LineSeparator from "../../img/line-separator.svg";
import GiftIcon from "../../img/giftIcon.svg";
import Carret from "../../img/carret-vector.svg";
import CarretUp from "../../img/carret-vector-up.svg";
import Locker from "../../img/brand-close-locker-nude.svg";
import { t2OrAbove } from "../util/handleSubscriptionTier";
import { getBackgroundGradientFromDivisionIdLegacy } from "../util/divisions";

function CustomLineup(props) {
  const lineup = props.lineup;
  const divisionStats = props.divisionStats;
  const divId = props.comp.DivisionId;
  const divisionValue = props.comp.DivisionId.split(props.comp.GameWeek.slice(0, props.comp.GameWeek.length - 2) + "-")[1];
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [bgHeader1, bgHeader2] = getBackgroundGradientFromDivisionIdLegacy(divId);
  return (
    <div className={""}>
      <div
        className={`rounded-t-lg bg-sd-watermark bg-cover bg-center flex flex-row justify-between text-base text-white h-10 space-x-4 mt-4 overflow-hidden`}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <div
          className={"w-full px-2 flex items-center justify-between"}
          style={{
            backgroundImage: `linear-gradient(180deg, ${bgHeader1} 0%, ${bgHeader2} 100%)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className={"w-10/12 self-center"}>
            <p>
              <span className={"font-semibold"}>{props.title}</span>
            </p>
          </div>
          <div className={"w-1/12 self-center justify-end flex flex-row pr-4"}>
            <img className={"w-4"} src={isComponentVisible ? Carret : CarretUp} />
          </div>
        </div>
      </div>
      {lineup !== undefined && !isComponentVisible && (
        <div ref={ref}>
          {divisionStats[divisionValue] && (
            <div className={"w-full p-4 bg-surface-container flex flex-row text-on-surface"}>
              <div className={"w-6/12 flex flex-row justify-between"}>
                <div className={"flex flex-row space-x-2"}>
                  <img className={"w-10"} src={props.comp.LogoUrl} />
                  <div className={"flex flex-col"}>
                    <p className={"text-sm font-semibold"}>{props.comp.DisplayName}</p>
                    <p className={"text-sm"}>
                      <span className={"text-primary font-semibold"}>{divisionStats[divisionValue]?.total_possible_lineups}</span>
                      <span className={"font-medium text-on-surface-variant"}>possible full lineups</span>
                    </p>
                  </div>
                </div>

                <img src={LineSeparator} />
              </div>
              <div className={"w-6/12 flex flex-row self-center justify-end space-x-2"}>
                <div className={"self-center flex flex-col items-end"}>
                  <p className={"text-xs 1.5xl:text-sm font-semibold"}>Average points needed for a reward</p>
                  <p className={"font-semibold text-primary text-sm justify-end"}>
                    {divisionStats[divisionValue]?.average_points.toFixed(2)}
                  </p>
                </div>
                <div className={"bg-brand pt-2 px-1.5 self-end rounded"}>
                  <img className={"w-8"} src={GiftIcon} />
                </div>
              </div>
            </div>
          )}
          <div
            className={"flex flex-row bg-surface-container h-auto p-4 justify-center space-x-2 3xl:space-x-4 overflow-x-auto no-scroll-bar"}
          >
            {lineup.map((l) => {
              return (
                <div className={"space-y-2"}>
                  <CustomLineupCard card={l.card} captain={l.captain} position={"Goalkeeper"} />
                  <div className={"bg-surface-container-high rounded-lg px-1 py-2 space-y-2"}>
                    <div className={"self-center"}>
                      <GameInLine
                        under={true}
                        bg={"bg-surface-container"}
                        playerTeam={l.player.TeamId}
                        playerNationalTeam={l.player.NationalTeam}
                        team1={l.player_team}
                        team2={l.opposing_team}
                        game={l.next_game}
                        odds={props.odds[l.next_game.GameId]}
                        standings={props.standings}
                      />
                    </div>
                    <div className={"flex flex-row space-x-2 justify-center pb-2"}>
                      <ScoreAverage avg={l.last_5} period={5} played={l.l5} sAvg={l.s5Avg} sApps={l.s5Apps} />
                      <ScoreAverage avg={l.last_15} period={15} played={l.last_15_played} sAvg={l.s15Avg} sApps={l.s15Apps} />
                      {t2OrAbove(props.user.tier) && (
                        <div className={"self-start"}>
                          <OppScore entry={l} />
                        </div>
                      )}
                      {!t2OrAbove(props.user.tier) && (
                        <div
                          data-tip={"Become a Star member to unlock opponent score"}
                          className={"self-start bg-surface-container rounded-md flex justify-center items-center"}
                        >
                          <img alt="" className="w-4 h-4 m-2" src={Locker} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default withUser(CustomLineup);
