import * as React from "react";

const BarChartIcon = (props) => {
  let color = props.color || "#E0E0E0";
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21 21h-3V11h3v10Zm-5 0h-3V8h3v13Zm-5 0H8V5h3v16Zm-5 0H3v-8h3v8Z" fill={color} />
    </svg>
  );
};
export default BarChartIcon;
