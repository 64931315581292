import React from "react";
import { baseballScale, basketballScale } from "./scales";
import { sorareBasketball } from "./sports";
import Schedule from "../../img/schedule.svg";

function BaseballScoreCircle(props) {
  let scale = baseballScale;
  if (props.scale === "basketball" || props.sport === sorareBasketball) {
    scale = basketballScale;
  }
  let score = props.score;
  let secondCircle = true;
  let mainColor = "";
  let secondColor = "";
  if (score < scale[0]) {
    mainColor = "#FF3232";
    secondColor = "#FFEAEA";
  } else if (score < scale[1]) {
    mainColor = "#F89F31";
    secondColor = "#FEF1E0";
  } else if (score < scale[2]) {
    mainColor = "#E7B62C";
    secondColor = "#FAF0D5";
  } else if (score < scale[3]) {
    mainColor = "#81BF50";
    secondColor = "#F0F7EA";
  } else if (score < scale[4]) {
    mainColor = "#50BF84";
    secondColor = "#E5F5ED";
  } else {
    mainColor = "#169A54";
    secondColor = "#DCF0E5";
  }
  let precision = 1;
  if (props.precision !== undefined) {
    precision = props.precision;
  }
  let value = "";
  let textColor = "white";
  if (props.dark) {
    textColor = "black";
  }
  if (precision >= 0) {
    value = score.toFixed(precision);
  } else {
    value = score;
    if (score === "DNP") {
      mainColor = "#E0E0E0";
      textColor = "#828282";
    } else if (score === "OUT") {
      if (!props.ongoing) {
        mainColor = "#E0E0E0";
        textColor = "#292929";
      } else {
        mainColor = "#FF3232";
      }
    } else if (score === "SUB") {
      if (!props.ongoing) {
        mainColor = "#E0E0E0";
        textColor = "#292929";
      } else {
        mainColor = "#F89F31";
      }
    } else if (score === "PP") {
      mainColor = "#E0E0E0";
      textColor = "#292929";
    } else if (score.includes("#")) {
      mainColor = "#E0E0E0";
      textColor = "#292929";
    }
  }
  if (props.noCircle) {
    secondCircle = false;
  }
  let size = "100px";
  if (props.size !== undefined) {
    size = props.size;
  }
  if (score >= 100) {
    value = score.toFixed(0);
  }
  if (score === "DNP") {
    return (
      <div className={"self-center justify-center mx-auto flex py-1"}>
        <div className={"self-center w-5 h-5 z-50 flex flex-row justify-center"}>
          <img className={"w-full h-full object-contain self-center"} src={Schedule} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={"self-center z-50"}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 400 400"}>
          <g>
            {secondCircle && <circle fill={secondColor} cx="200" cy="200" r={props.cr || "195"} />}
            <circle fill={mainColor} cx="200" cy="200" r={secondCircle ? "145" : "195"} />
            <text
              color={textColor}
              fill={textColor}
              fontSize={size}
              fontWeight={700}
              x="50%"
              y="50%"
              textAnchor={"middle"}
              stroke="white"
              strokeWidth={0}
              dy={".3em"}
            >
              {value}
            </text>
          </g>
        </svg>
      </div>
    );
  }
}

export default BaseballScoreCircle;
