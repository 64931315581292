import React, { useEffect, useState } from "react";
import SDLoading from "../util/SDLoading";
import AuctionCard from "../auctions/auctionCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import ScarcityPicker from "../util/scarcityPicker";
import { ReactTooltip } from "../util/tooltip.js";

function WatchlistAuctions(props) {
  const [auctions, setAuctions] = useState([]);
  const [displayedAuctions, setDisplayedAuctions] = useState([]);
  const [priceInfo, setPriceInfo] = useState([]);
  const [teamMap, setTeamMap] = useState([]);
  const [scarcity, setScarcity] = useState(props.user.preferredScarcity || "limited");

  const watchlist = props.watchlist;

  useEffect(() => {
    getAuctions();
  }, [watchlist]);

  const getAuctions = (s) => {
    if (!s) s = scarcity;
    if (watchlist.id) {
      props
        .fetch("/apiv2/watchlists/id/" + watchlist.id + "/auctions?scarcity=" + s)
        .then((response) => response.json())
        .then((res) => {
          setAuctions(res.auctions);
          setPriceInfo(res.price_info);
          setTeamMap(res.team_map);
          setDisplayedAuctions(res.auctions.slice(0, 40));
          ReactTooltip.rebuild();
        })
        .catch(errorCatcher());
    }
  };

  return (
    <div className={"space-y-8"}>
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2.5xl:grid-cols-5 mx-auto"}>
        <div>
          <ScarcityPicker
            scarcity={scarcity}
            onChange={(s) => {
              getAuctions(s);
              setScarcity(s);
            }}
          />
        </div>
      </div>
      <div className={"flex flex-row justify-start"}>
        <InfiniteScroll
          dataLength={displayedAuctions.length}
          scrollableTarget={"window"}
          next={() => setDisplayedAuctions(auctions.slice(0, displayedAuctions.length + 40))}
          hasMore={auctions.length > displayedAuctions.length}
          onMore={() => setDisplayedAuctions(auctions.slice(0, displayedAuctions.length + 40))}
          loader={<SDLoading />}
        >
          <div
            className={
              "grid grid-cols-1 grid-rows-auto md:grid-cols-3 lg:grid-cols-4 " +
              "xl:grid-cols-4 2xl:grid-cols-5 2.5xl:grid-cols-6 4xl:grid-cols-7 gap-x-4 gap-y-6 3xl:gap-6"
            }
          >
            {displayedAuctions.map((a, i) => (
              <AuctionCard key={a.AuctionId} auction={a} past={false} nextGwPlayers={teamMap} priceInfo={priceInfo} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default withUser(WatchlistAuctions);
