import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { ReactTooltip } from "../util/tooltip";
import { Spinning } from "../loader/spinner";
import { MobileModale } from "../util/mobileModale";
import { XIcon } from "@heroicons/react/outline";
import { renderToString } from "react-dom/server";
import clsx from "clsx";
import { Tag } from "../util/tag";
import { getRarityDisplayName } from "../util/divisions";
import { LineupBuilderDivisionPickerBadge } from "./lineupBuilderDivisionOrLineupPicker";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as IconLineupBuilder } from "../../img/icon-lineup-builder.svg";
import { ReactComponent as CheckBox } from "../../img/check-box-grey.svg";
import { ReactComponent as CheckBoxBrand } from "../../img/check-box-brand.svg";
import { ReactComponent as CheckBoxBrandMinus } from "../../img/check-box-brand-minus.svg";
import { ReactComponent as Warn } from "@material-design-icons/svg/round/error.svg";

export const CompetitionMultiSelectMenu = (props) => {
  const { competitions, selectedIds, onChange, loading, allOption, withTip } = props;
  const [focus, setFocus] = useState(false);
  const isAllOptionsSelected = selectedIds.length === competitions.length;

  // close on click outside
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        !isMobile && setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [focus]);

  // main label
  const selectedCompetitionLabels = competitions.filter((c) => selectedIds.includes(c.id) && c.id !== allOption.id).map((s) => s.label);
  const selectedItemsNames = isAllOptionsSelected ? [allOption.label] : selectedCompetitionLabels;
  const selectedItemsLabel = selectedItemsNames.join(", ");

  return (
    <div className={"relative h-full z-20"} ref={ref}>
      <button
        onClick={() => setFocus(!focus)}
        className={`h-full text-md my-auto px-3 py-2 w-full sm:w-80 rounded-full ${
          focus ? "bg-surface-container-high shadow" : "bg-surface-container"
        } flex flex-row justify-between gap-2`}
      >
        <div className={"flex flex-row items-center gap-2 w-full my-auto"}>
          {isAllOptionsSelected && <IconLineupBuilder className="w-5 h-5 fill-on-surface" />}
          <span style={{ maxWidth: "16.5em" }} className={"truncate items-center text-on-surface font-medium"}>
            {selectedItemsLabel ? selectedItemsLabel : "No competition selected"}
          </span>
        </div>
        <div className={"flex flex-row gap-2 my-auto w-1/6 justify-end"}>
          <div className={"flex flex-row my-auto gap-2"}>
            {loading ? <Spinning className={"h-5 w-5"} /> : null}
            <div className={"flex flex-col justify-center"}>
              <SortArrowIcon className={`fill-on-surface h-3 w-3 ${focus ? "transform rotate-180" : ""}`} />
            </div>
          </div>
        </div>
      </button>
      {isMobile && (
        <MobileModale open={focus} close={() => setFocus(false)}>
          <div className={"h-full bg-surface-container-high  rounded-t-lg"}>
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <div className={"flex flex-row justify-between px-4 py-4"}>
                <h3 className={"font-headers text-on-surface font-semibold m-0"}>Teams</h3>
                <div className={"flex flex-row gap-3"}>
                  <XIcon
                    className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
                    aria-hidden="true"
                    onClick={() => setFocus(false)}
                  />
                </div>
              </div>
            </div>
            <ul className={"max-h-[80vh] overflow-y-auto"}>
              <CompetitionMultiSelectItems
                competitions={competitions}
                selectedIds={selectedIds}
                onChange={onChange}
                allOption={allOption}
                withTip={withTip}
              />
            </ul>
          </div>
        </MobileModale>
      )}
      {focus && !isMobile && (
        <div className={"absolute bg-surface-container-high rounded-xl mt-2 w-80 shadow overflow-hidden"}>
          <ul className={" h-full overflow-y-auto"} style={{ maxHeight: "25em" }}>
            <CompetitionMultiSelectItems
              competitions={competitions}
              selectedIds={selectedIds}
              onChange={onChange}
              allOption={allOption}
              withTip={withTip}
            />
          </ul>
          <ReactTooltip id="multi-select-item" place={"right"} className={"overflow-visible"} />
        </div>
      )}
    </div>
  );
};

const CompetitionMultiSelectItems = (props) => {
  const { selectedIds, onChange, allOption, withTip } = props;
  const competitions = props.competitions?.slice() || [];
  const isIndeterminateState = selectedIds.includes(allOption.id) && selectedIds.length !== competitions.length;
  return (
    <ul>
      {competitions.map((c, i) => {
        const tooltip = renderToString(<CompetitionMultiSelectBadgeTip competition={c} title={c.fullName} />);
        return (
          <MultiSelectItem
            key={i}
            option={c}
            onChange={onChange}
            first={i === 0}
            isSelected={selectedIds.includes(c.id)}
            isAllOption={c.id === allOption.id}
            isIndeterminateState={isIndeterminateState}
            tooltip={withTip ? tooltip : undefined}
          />
        );
      })}
    </ul>
  );
};

const MultiSelectItem = (props) => {
  const { first, option, onChange, isSelected, isAllOption, tooltip, isIndeterminateState } = props;
  if (option === null) {
    return null;
  }
  return (
    <li
      onClick={() => onChange(option.id)}
      className={clsx(
        "flex flex-row justify-between items-center",
        "py-2 px-3 cursor-pointer select-none z-30",
        "hover:bg-containers-surface-transparent-inverse-surface-hover hover:bg-opacity-10",
        !first && "border-t",
        "border-outline-variant",
        "text-sm",
        isSelected ? "font-semibold text-primary" : "text-on-surface",
      )}
      data-tip={tooltip}
      data-html={true}
      data-for={"multi-select-item"}
      data-delay-show={500}
      data-position={"bottom"}
    >
      <div className={"self-center flex flex-row items-center gap-3"}>
        {option.logo && (
          <div className="h-full rounded-md self-center">
            <div className="w-5 h-5 flex justify-center">
              <img src={option.logo} className="w-full h-full object-contain fill-on-surface" />
            </div>
          </div>
        )}
        <div className={option.subtitle ? "" : "py-1.5"}>
          <p className="font-medium text-on-surface">{option.label}</p>
          {option.subtitle && <p className="text-xs font-medium text-on-surface-variant">{option.subtitle}</p>}
        </div>
      </div>
      <div className="flex flex-row space-x-3">
        <Tag
          textSize={"font-semibold text-[0.7em] leading-[1.2em]"}
          context={option.isTagDisabled ? "neutral" : "emphasized-transparent"}
          padding={"px-2 py-0.5"}
          icon={
            option.withWarning ? (
              <Warn className={`w-4 h-4 ${option.isTagDisabled ? "fill-on-surface-variant" : "fill-on-surface"}`} />
            ) : undefined
          }
        >
          {option.tagLabel}
        </Tag>
        <div className="self-center">
          {isSelected ? (
            <CheckedIcon className="w-4 h-4" isAllOption={isAllOption} isIndeterminateState={isIndeterminateState} />
          ) : (
            <CheckBox className="w-4 h-4" />
          )}
        </div>
      </div>
    </li>
  );
};

export const getCompetitionOption = (comp) => {
  if (comp === undefined || comp === null) {
    return null;
  }
  let lineupsCompletion = comp.currentNbEntries;
  if (comp.maxNbEntries !== undefined && comp.maxNbEntries > 1) {
    lineupsCompletion = `${comp.currentNbEntries}/${comp.maxNbEntries}`;
  }
  return {
    id: comp.id,
    label: comp.fullNameNoScarcity,
    logo: comp.logoUrl,
    subtitle: getRarityDisplayName(comp.rarity),
    tagLabel: lineupsCompletion,
    isTagDisabled: comp.currentNbEntries === 0,
    withWarning: comp.hasInvalidLineup,
    withUnavailable: comp.hasLineupInUnavailableCompetition,
    ...comp,
  };
};

const CompetitionMultiSelectBadgeTip = (props) => {
  const { title, competition } = props;
  if (!competition) {
    return null;
  }
  const isMultipleEntryCompetition = competition?.maxNbEntries > 1;
  const hasLineupsInvalid = competition?.hasInvalidLineup;
  const display = hasLineupsInvalid || isMultipleEntryCompetition;
  if (!display) {
    return null;
  }
  return (
    <div className={"flex flex-col gap-2"}>
      <h4 className={"text-white text-md text-center"}>{title}</h4>
      <div className={"flex flex-col gap-3 items-start max-w-md"}>
        {hasLineupsInvalid && (
          <div className={"flex flex-col gap-1"}>
            <div className={"flex flex-row gap-1 items-center"}>
              <LineupBuilderDivisionPickerBadge isInvalid={true} />
              <h4 className={"m-0 text-inverse-on-surface font-semibold text-md"}>At least one of your lineups is invalid</h4>
            </div>
          </div>
        )}
        {isMultipleEntryCompetition && (
          <div className={"flex flex-col gap-1"}>
            <div className={"flex flex-row gap-1 items-center"}>
              <LineupBuilderDivisionPickerBadge
                remainingTeams={{
                  current: competition.currentNbEntries,
                  max: competition.maxNbEntries,
                }}
              />
              <h4 className={"m-0 text-inverse-on-surface font-semibold text-md"}>Competition allow multiple lineups</h4>
            </div>
            <div className={"flex flex-col gap-0.5 pl-2"}>
              <p>
                You can submit up to {competition.maxNbEntries} lineups in this competition and you have currently{" "}
                {competition.currentNbEntries} saved lineup(s).
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CheckedIcon = ({ isAllOption, isIndeterminateState }) => {
  return isAllOption && isIndeterminateState ? <CheckBoxBrandMinus className="w-4 h-4" /> : <CheckBoxBrand className="w-4 h-4" />;
};
