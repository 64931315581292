import React, { useEffect, useState } from "react";
import SDLoading from "../util/SDLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import ScarcityPicker from "../util/scarcityPicker";
import OfferCard from "../offers/offerCard";
import BoxWithToggle from "../util/boxWithToggle";
import { ReactTooltip } from "../util/tooltip.js";
import Spinner from "../loader/spinner";

function WatchlistOffers(props) {
  const [offers, setOffers] = useState([]);
  const [displayedOffers, setDisplayedOffers] = useState([]);
  const [scarcity, setScarcity] = useState(props.user.preferredScarcity || "limited");
  const [priceInfo, setPriceInfo] = useState([]);
  const [teamMap, setTeamMap] = useState([]);
  const [floorOnly, setFloorOnly] = useState(true);
  const [underAvg, setUnderAvg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const watchlist = props.watchlist;

  useEffect(() => {
    getOffers();
  }, [watchlist]);

  const getOffers = (s, f, ua) => {
    if (!s) s = scarcity;
    if (f === undefined) f = floorOnly;
    if (ua === undefined) ua = underAvg;
    if (watchlist.id) {
      setLoading(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      props
        .fetch("/apiv2/watchlists/id/" + watchlist.id + "/offers?scarcity=" + s + "&underAvg=" + ua + "&floor=" + f, {
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          setOffers(res.offers);
          setPriceInfo(res.price_info);
          setTeamMap(res.team_map);
          setDisplayedOffers(res.offers.slice(0, 40));
          ReactTooltip.rebuild();
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  return (
    <div className={"space-y-8"}>
      <div className={"relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2.5xl:grid-cols-5 mx-auto space-x-4"}>
        <div>
          <ScarcityPicker
            scarcity={scarcity}
            onChange={(s) => {
              getOffers(s);
              setScarcity(s);
            }}
          />
        </div>
        <div className={"py-2.5 self-center bg-white rounded-lg"}>
          <BoxWithToggle
            label={"Floor price only"}
            setActive={() => {
              getOffers(undefined, !floorOnly);
              setFloorOnly(!floorOnly);
            }}
            isActive={floorOnly}
          />
        </div>
        <div className={"py-2.5 self-center bg-white rounded-lg"}>
          <BoxWithToggle
            label={"Under latest average"}
            setActive={() => {
              getOffers(undefined, undefined, !underAvg);
              setUnderAvg(!underAvg);
            }}
            isActive={underAvg}
          />
        </div>
        <div className={"absolute flex flex-row transform right-0 translate-y-1/2 justify-center self-center"}>
          {loading && <Spinner className={"transform -translate-y-1/2"} />}
        </div>
      </div>
      <div className={"flex flex-row justify-start"}>
        <InfiniteScroll
          dataLength={displayedOffers.length}
          scrollableTarget={"window"}
          next={() => setDisplayedOffers(offers.slice(0, displayedOffers.length + 40))}
          hasMore={offers.length > displayedOffers.length}
          onMore={() => setDisplayedOffers(offers.slice(0, displayedOffers.length + 40))}
          loader={<SDLoading />}
        >
          <div
            className={
              "grid grid-cols-1 grid-rows-auto md:grid-cols-3 lg:grid-cols-4 " +
              "xl:grid-cols-4 2xl:grid-cols-5 2.5xl:grid-cols-6 4xl:grid-cols-7 gap-x-4 gap-y-6 3xl:gap-6"
            }
          >
            {displayedOffers.map((o, i) => (
              <OfferCard key={o.offer.OfferId} offer={o} filter={[]} priceInfo={priceInfo} u23Only={false} gwGames={teamMap} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default withUser(WatchlistOffers);
