import clsx from "clsx";
import { getScarcityInfo } from "../util/scarcities";
import React from "react";
import { withUser } from "../../userContext";
import { getSplitLabel, split_price_all, split_price_classic, split_price_in_season } from "../util/splits";
import { formatFloorPrice, formatValuationPrice } from "../util/formatMoney";
import { isFree } from "../util/handleSubscriptionTier";
import { isSportFree } from "../util/sports";
import { ReactComponent as LockerIcon } from "../../img/locker-icon-no-color.svg";

export const PlayerPriceDetails = (props) => {
  const { prices } = props;
  return (
    <div className="space-y-4">
      <span className="text-on-surface text-lg font-semibold">All scarcities details</span>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
        {Object.entries(prices.split)?.map(([scarcity, split]) => (
          <CardPriceDetailsByScarcity key={scarcity} scarcity={scarcity} split={split} withBorder />
        ))}
      </div>
    </div>
  );
};

export const CardPriceDetailsByScarcity = withUser((props) => {
  const { split, scarcity, withBorder, sport } = props;
  if (!split || Object.keys(split).length === 0) {
    return null;
  }
  const isPaywall = isFree(props.user?.tier) && !isSportFree(sport);
  return (
    <div className={clsx("bg-surface-container p-5 flex flex-col space-y-4", withBorder ? "rounded-lg shadow" : "")}>
      <div className="space-y-2">
        <span className={clsx(getScarcityInfo(scarcity).color, "text-md font-semibold capitalize")}>{scarcity.toLowerCase()}</span>
      </div>
      <div className="space-y-2">
        <span className="text-on-surface-variant font-semibold text-base">Supply</span>
        <div className="space-y-1.5">
          <CardSupplySplit splitKey={split_price_all} split={split} />
          <CardSupplySplit splitKey={split_price_in_season} split={split} />
          <CardSupplySplit splitKey={split_price_classic} split={split} />
        </div>
      </div>
      <div className="space-y-2">
        <span className="text-on-surface-variant font-semibold text-base">Price details</span>
        <CardPriceDetail
          splitLabel={getSplitLabel(split_price_in_season)}
          prices={split[split_price_in_season]}
          preferredUnit={props.user.preferredUnit}
          secondUnit={props.user.secondUnit}
          scarcityColor={getScarcityInfo(scarcity).color}
          isPaywall={isPaywall}
        />
        <div className="border-b border-outline-variant w-full" />
        <CardPriceDetail
          splitLabel={getSplitLabel(split_price_classic)}
          prices={split[split_price_classic]}
          preferredUnit={props.user.preferredUnit}
          secondUnit={props.user.secondUnit}
          isPaywall={isPaywall}
        />
      </div>
    </div>
  );
});

const CardSupplySplit = ({ splitKey, split }) => {
  return (
    <div className="flex flex-row justify-between text-sm">
      <span className="text-on-surface-variant">{getSplitLabel(splitKey)} cards</span>
      <span className="text-on-surface font-semibold">{split[splitKey].supply}</span>
    </div>
  );
};

const CardPriceDetail = ({ splitLabel, prices, preferredUnit, secondUnit, scarcityColor, isPaywall }) => {
  const fiatUnit = preferredUnit === "eth" ? secondUnit : preferredUnit;
  return (
    <div className="text-sm space-y-1.5 text-on-surface">
      <span className="font-semibold">{splitLabel}</span>
      {prices.rollingAverages?.map((price, idx) => (
        <div className="grid grid-cols-2" key={idx}>
          <span className="text-on-surface-variant">{price.period} Days average</span>
          {isPaywall ? (
            <div className="justify-self-end bg-surface-container-low rounded-full flex items-center justify-center h-4 w-4">
              <LockerIcon className="fill-on-surface-variant h-2.5 w-2.5" />
            </div>
          ) : (
            <div className={"grid grid-cols-2 font-semibold"}>
              <span className="justify-self-end">{formatValuationPrice(price, "eth")}</span>
              <span className="justify-self-end">{formatValuationPrice(price, fiatUnit || "eur")}</span>
            </div>
          )}
        </div>
      ))}
      <div className="grid grid-cols-2">
        <span className="text-on-surface-variant space-y-1.5">Floor</span>
        <div className={clsx("grid grid-cols-2 font-semibold", scarcityColor)}>
          <span className="justify-self-end">{formatFloorPrice(prices.bestMarketPrice, "eth")}</span>
          <span className="justify-self-end">{formatFloorPrice(prices.bestMarketPrice, fiatUnit || "eur")}</span>
        </div>
      </div>
    </div>
  );
};
