import React, { useEffect, useRef, useState } from "react";
import { errorCatcher } from "../util/errors";
import { sorareBasketball } from "../util/sports";
import { withUser } from "../../userContext";
import BaseballScoreCircle from "../util/baseballScoreCircle";
import ordinal_suffix_of from "../util/ordinalNumber";
import SDIcon from "../../img/sd-logo-dark-mode.svg";
import GamePreview from "../games/gamePreview";
import PlayerScoreModal from "../players/playerScoreModal";
import { ReactTooltip } from "../util/tooltip.js";
import CardIcon from "../util/cardIcon";
import InfoIconGrey from "../../img/info-icon-grey.svg";
import { LiveLineups } from "./commonLiveLineups";
import ls from "local-storage";
import AbortController from "abort-controller";

function BasketballLineupCard(props) {
  const [isOpen, setOverlay] = useState(false);

  const g = props.g;
  const position = props.position;
  const player = props.player;
  let score = 0;
  if (g.score) {
    score = g.score;
  }
  let playingFor = g.playerTeamId;
  if (g.playerTeamId === "") {
    playingFor = player.TeamId;
  }
  let opponent = g.away_team_id;
  let opponentLogo = g.away_team_logo;
  if (playingFor !== g.home_team_id) {
    opponent = g.home_team_id;
    opponentLogo = g.home_team_logo;
  }
  let last = props.last;
  let size = "190px";
  let gameOngoing = g.game.Status === "inprogress" || g.game.Status === "halftime";
  let gameNotStarted = g.game.Status === "scheduled" || g.game.Status === "created";
  let status = "DNP";
  let precision = 0;
  if (gameOngoing) {
    size = "180px";
    if (!g.played && !g.started && g.onRoster) {
      score = "SUB";
      precision = -1;
      size = "140px";
    }
    if (!g.played && !g.started && !g.onRoster) {
      score = "OUT";
      precision = -1;
      size = "140px";
    }
    if (g.played) {
      score = g.score;
    }
  } else if (gameNotStarted) {
    size = "140px";
    score = "DNP";
    precision = -1;
  }
  return (
    <div>
      <PlayerScoreModal
        open={isOpen}
        stats={g}
        sport={sorareBasketball}
        player={player}
        position={player.Position}
        dark={true}
        close={() => setOverlay(false)}
        game={g}
      />
      <div className={"flex flex-row space-x-2 self-center"}>
        <div className={"self-center"}>
          <div className={"w-4"}>
            <img src={opponentLogo} className={"w-full h-full object-contain"} />
          </div>
        </div>
        <div className={"flex flex-row justify-center cursor-pointer self-center"} onClick={() => setOverlay(true)}>
          <div className={"w-6 self-center"}>
            <BaseballScoreCircle
              dark={true}
              ongoing={gameOngoing}
              scale={"basketball"}
              noCircle={true}
              size={size}
              score={score}
              precision={precision}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function BasketballLineup(props) {
  const [overview, setOverview] = useState(false);

  const lineup = props.lineup;
  const division = lineup?.division;
  const cards = lineup?.cards;
  let colorRank = "text-green-dark";
  let rank = lineup.lineup.rank;
  let lineupScore = lineup.lineup.score;
  let paidRank = lineup.division.paidRankLimit;
  if (rank > paidRank) {
    colorRank = "text-brand-dark";
  }
  let projColor = "text-brand-dark";
  if (lineup.lineup.projRank <= paidRank) {
    projColor = "text-green-dark";
  }
  let icon = lineup.division.logo;
  let textRewards = "";
  if (lineup.currentReward.scarcity !== "") {
    textRewards = "Tier " + lineup.currentReward.tier + " " + lineup.currentReward.scarcity.toLowerCase();
  } else {
    textRewards = "No reward";
  }
  let nextRewardText = "";
  if (lineup.currentReward.nextScarcity !== "") {
    nextRewardText = "Tier " + lineup.currentReward.nextTier + " " + lineup.currentReward.nextScarcity.toLowerCase();
  }
  let rewardsOverview = lineup.rewardOverview;
  return (
    <div id={lineup.lineup.lineupId} className={"bg-[#1F1F1F] rounded-lg pt-2"}>
      <div className={"flex flex-row py-2 space-x-2 w-full relative px-8"}>
        <img className={"w-12 h-12"} src={icon} />
        <div className={"flex flex-col w-6/12 self-end"}>
          <div>
            <p className={"text-[#CCCCCC] text-left font-medium text-sm uppercase"}>{division.displayName}</p>
          </div>
          <div className={"text-left text-[#E6E6E6] flex flex-row space-x-2"}>
            <p>
              <span className={"text-xl font-semibold " + colorRank}>{ordinal_suffix_of(rank)}</span>
              <span data-tip={"Lineup score"} className={"text-xl font-semibold"}>
                {" "}
                - {parseFloat(lineupScore).toFixed(2)} pts
              </span>
            </p>
            {/*<div className={"flex self-center pt-1"}><span data-tip={"Best to worst possible ranks"}
                                                                        className={"self-center text-textGrey3 font-semibold text-sm"}> ({maxRanks})</span>
                        </div>*/}
          </div>
        </div>
        <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 justify-center self-center"}>
          <div className={"w-8 h-8"}>
            <img className={"w-full h-full object-contain"} src={SDIcon} />
          </div>
        </div>
        {division.divisionType !== "common-contender" && (
          <div className={"relative flex justify-end self-end w-6/12 pb-0.5"}>
            <div className={"flex flex-col space-y-2"}>
              <div className={"flex flex-row justify-end"}>
                {lineup.currentReward && lineup.currentReward.scarcity !== "" && (
                  <div className={"flex flex-row space-x-2"}>
                    <div className={"px-2 py-0.5 rounded flex flex-row space-x-2"}>
                      <div className={"self-center"}>
                        <CardIcon size={"lg"} scarcity={lineup.currentReward.scarcity.toLowerCase()} />
                      </div>
                      <p className={"uppercase font-medium text-sm text-white text-center self-center"}>Tier {lineup.currentReward.tier}</p>
                    </div>
                  </div>
                )}
                {!lineup.currentReward ||
                  (lineup.currentReward.scarcity === "" && (
                    <div className={"flex flex-row space-x-2"}>
                      <p className={"font-medium text-sm text-white text-center self-center text-[#CCCCCC]"}>No reward</p>
                    </div>
                  ))}
              </div>
              <div className={" flex flex-row justify-center lg:justify-start self-end lg:pl-6 space-x-2"}>
                <p className={"text-[#CCCCCC] text-sm font-medium self-center"}>
                  Proj: {ordinal_suffix_of(lineup.lineup.projRank)}, {lineup.lineup.projScore.toFixed(2)} pts
                </p>
                <div className={"border border-black-4D self-center h-4"} />
                {rank > 1 && (
                  <span className={"text-[#CCCCCC] text-sm font-medium self-center"}>
                    {lineup.currentReward.scoreToNext.toFixed(2)} pts to {nextRewardText}
                  </span>
                )}
                <div className={"w-4 self-center cursor-pointer"} onMouseEnter={() => setOverview(true)}>
                  <img className={"w-full object-contain"} src={InfoIconGrey} />
                </div>
              </div>
            </div>
            {overview === true && division.divisionType !== "common-contender" && (
              <div
                className="absolute flex w-auto mt-8 p-4 -top-1 rounded-md shadow-lg bg-[#292929] focus:outline-none z-20"
                onMouseLeave={() => setOverview(false)}
              >
                <div className="py-1 text-left" role="none">
                  {rewardsOverview.map((r, i) => {
                    if (r.scarcity.toLowerCase() === "common") {
                      return null;
                    }
                    let to = "to";
                    let color = "text-brand-dark";
                    let gap = r.toScore - lineup.lineup.score;
                    if (gap <= 0) {
                      to = "from";
                      color = "text-green-dark";
                      gap = gap * -1;
                    }
                    let custom = "";
                    let border = "border-b border-black-4D";
                    if (i === 0) {
                      custom = "rounded-t-lg";
                    }
                    if (i === rewardsOverview.length - 1) {
                      border = "";
                      custom = "rounded-b-lg";
                    }
                    let bg = "bg-black-1f";
                    let startRange = r.to;
                    let endRange = r.from;
                    if (endRange === startRange) endRange = 0;
                    if (rank <= startRange && rank >= endRange) {
                      bg = "bg-black-33";
                    }
                    textRewards = "Tier " + r.tier + " " + r.scarcity.toLowerCase();
                    return (
                      <div className={"flex py-1.5 px-2 flex-row justify-between " + border + " " + custom + " " + bg}>
                        <div className={"self-center"}>
                          <p className={"text-xs font-semibold text-[#E6E6E6]"}>
                            {endRange > 0 && <span>{endRange}-</span>}
                            {startRange}
                          </p>
                        </div>
                        <div className={"w-16"}></div>
                        <div className={"flex flex-row space-x-2"}>
                          <p className={color + " font-medium text-xs self-center"}>
                            {gap.toFixed(2)} pts {to}
                          </p>
                          {/*r.eth > 0 && <div
                                                    className={"rounded text-sm bg-[#F5F5F5] bg-opacity-10 text-[#E6E6E6] text-white px-2 font-semibold flex flex-row space-x-1.5"}>
                                                    <img className={"self-center"} src={EthereumIconWhite}
                                                         style={{height: "16px"}}/>
                                                    <p className={"self-center"}>{r.eth.toFixed(3)}</p></div>*/}
                          <div className={"px-2 py-0.5 rounded bg-[#F5F5F5] bg-opacity-10 text-[#E6E6E6] flex flex-row space-x-2"}>
                            <div className={"self-center"}>
                              <CardIcon size={"lg"} scarcity={r.scarcity.toLowerCase()} />
                            </div>
                            <p className={"font-semibold text-sm text-center self-center"}>Tier {r.tier}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={"grid grid-cols-5 gap-x-4 mt-1 px-8 pb-4 "}>
        {cards !== null &&
          cards.map((c, ind) => {
            const card = c.card;
            let score = 0;
            let isHome = true;
            const position = c.lineupPlayer.position;
            let showcasedGame;
            if (c.games !== null) {
              if (c.games.length === 1) {
                showcasedGame = c.games[0];
                score = showcasedGame.score;
              } else if (c.games.length > 1) {
                showcasedGame = c.games[0];
                score = c.games[0].score;
                c.games.map((g) => {
                  let status = g.game.Status;
                  if (g.score > score) {
                    score = g.score;
                    showcasedGame = g;
                    isHome = g.isHome;
                  }
                  if (g.score > score) {
                    score = g.score;
                  }
                  if (status === "inprogress" || status === "halftime") {
                    showcasedGame = g;
                  }
                });
              }
            }
            let power = c.lineupPlayer.power;
            let totalScore = 0;
            if (power !== null) {
              totalScore = score * power;
            }
            return (
              <div key={ind} className={"flex flex-col items-center space-y-2"}>
                <div className={"flex flex-col items-center"}>
                  <div className={"absolute flex flex-row -mt-2.5 justify-center self-center"}>
                    <div className={"px-2 bg-grey-d9 rounded-full"}>
                      <p className={"font-semibold text-sm"}>{position}</p>
                    </div>
                  </div>
                  {card.Scarcity !== "COMMON" ? (
                    <a target={"_blank"} href={`/card/${card.TokenId}`} rel="noreferrer">
                      <img src={card.PictureUrl} />
                    </a>
                  ) : (
                    <img src={card.PictureUrl} />
                  )}
                </div>
                <div className={"flex flex-row justify-center"}>
                  <div className={"self-center"}>
                    <p>
                      <span className={"text-sm text-[#E6E6E6] self-center font-semibold cursor-pointer"}>{score.toFixed(1)}</span>
                      <span className={"text-sm font-semibold text-[#CCCCCC]"}> x {power}</span>
                    </p>
                  </div>
                </div>
                <div className={"flex flex-row justify-center space-x-2"}>
                  <div className={"w-12 self-center"}>
                    {
                      <BaseballScoreCircle
                        scale={"basketball"}
                        sport={sorareBasketball}
                        dark={true}
                        cr={"180"}
                        score={totalScore}
                        size={"120px"}
                        precision={0}
                      />
                    }
                    {/*<ScoreEllipse size={"text-base"} score={totalScore} sport={sorareBaseball} power={power} isCaptain={false} formationPlace={1}
                                                  onGameSheet={true} minsPlayed={1} />*/}
                  </div>
                </div>
                {showcasedGame && (
                  <div className={"flex flex-row justify-center"}>
                    <GamePreview sport={sorareBasketball} logoSize={"w-4 h-4 self-center"} game={showcasedGame} />
                  </div>
                )}
                {!showcasedGame && <div className={"flex flex-row justify-center h-5"}></div>}
                {c.games !== null && (
                  <div className={"flex flex-row justify-around space-x-2 bg-[#292929] rounded-lg px-4 py-2 w-full"}>
                    {c.games.map((g, i) => {
                      return <BasketballLineupCard g={g} position={position} player={c.player} last={i === c.games.length - 1} />;
                    })}
                  </div>
                )}
                {c.games === null && (
                  <div className={"flex flex-row justify-center space-x-2 py-3 bg-[#292929] rounded-lg w-full"}>
                    <p className={"text-xs font-semibold text-center text-[#CCCCCC]"}>No game</p>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <ReactTooltip />
    </div>
  );
}

function BasketballLiveLineups(props) {
  const [lineups, setLineups] = useState([]);
  const [rewards, setRewards] = useState({});
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gw, setGw] = useState(null);
  const [username, setUsername] = useState(props.username);
  const [sortOption, setSortOption] = useState(ls.get("so5_basketball_sort") || "best_rank");
  const [abortController, setAbortController] = useState(new AbortController());

  const state = useRef();
  state.current = gw;

  const getLineups = (gw) => {
    if (gw === undefined) gw = state.current;
    setLoading(true);
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    props
      .fetch("/apiv2/SO5/basketballLineups?gw=" + gw + "&username=" + props.username, {
        signal: newAbortController.signal,
      })
      .then((response) => response.json())
      .then(async (res) => {
        const rewards = {};
        const lineups =
          res.lineups?.map((l) => {
            const obj = {
              ...l,
              lineup: {
                ...l.lineup,
                LineupId: l.lineup.lineupId,
                Rank: l.lineup.rank,
                Score: l.lineup.score,
                CardsLeft: l.lineup.cardsLeft,
                CardsLive: 0,
                SorareUserSlug: res.sorare_user_slug,
                UserId: l.lineup.userId,
              },
              competition: {
                LogoUrl: l.division.logo,
                DisplayName: l.division.displayName,
              },
            };
            const rewardsCards = [];
            if (l.currentReward?.scarcity) {
              rewardsCards.push({ scarcity: l.currentReward.scarcity, tier: l.currentReward.tier });
            }
            let nextRewardsCards = [];
            let gap = 0;
            l.rewardOverview?.forEach((r) => {
              if (r.scarcity && l.lineup.rank >= r.to) {
                // get first reward that is lower than rank
                nextRewardsCards = [{ scarcity: r.scarcity, tier: r.tier }];
                gap = r.toScore - l.lineup.score;
              }
            });
            if (rewards[l.lineup.lineupId] === undefined) {
              rewards[l.lineup.lineupId] = {
                current: {
                  cards: [],
                  eth: 0,
                  coins: 0,
                  fiat: {},
                },
                next: {
                  gap: 0,
                  cards: [],
                  eth: 0,
                  coins: 0,
                  fiat: {},
                },
              };
            }
            rewards[l.lineup.lineupId].current.cards = rewardsCards;
            rewards[l.lineup.lineupId].next.cards = nextRewardsCards;
            rewards[l.lineup.lineupId].next.gap = gap;
            return obj;
          }) || [];
        setRewards(rewards);
        res.lineups = lineups;
        setLineups(lineups);
        setInfo(res);
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const onHideLineup = (id) => {
    let newInfo = { ...info };
    newInfo.lineups.map((lineup) => {
      if (lineup.lineup.LineupId === id) {
        lineup.hidden = true;
      }
    });
    setInfo(newInfo);
  };

  const onUnhideLineup = (id) => {
    let newInfo = { ...info };
    newInfo.lineups.map((lineup) => {
      if (lineup.lineup.LineupId === id) {
        lineup.hidden = false;
      }
    });
    setInfo(newInfo);
  };

  const onHideLongTermLeague = (id) => {
    let newInfo = { ...info };
    newInfo.long_term_leagues.map((league) => {
      if (league.league.id === id) {
        league.hidden = true;
      }
    });
    setInfo(newInfo);
  };

  const onUnhideLongTermLeague = (id) => {
    let newInfo = { ...info };
    newInfo.long_term_leagues.map((league) => {
      if (league.league.id === id) {
        league.hidden = false;
      }
    });
    setInfo(newInfo);
  };

  useEffect(() => {
    if (props.gw > 0 && props.gw < 360) {
      setGw(props.gw);
      getLineups(props.gw);
    }
  }, [props.gw]);

  useEffect(() => {
    const interval = setInterval(() => {
      getLineups();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <LiveLineups
      sport={sorareBasketball}
      info={info}
      lineupHidable={true}
      onHideLineup={onHideLineup}
      onUnhideLineup={onUnhideLineup}
      longTermLeagueHidable={true}
      onHideLongTermLeague={onHideLongTermLeague}
      onUnhideLongTermLeague={onUnhideLongTermLeague}
      loading={loading}
      gw={gw}
      username={username}
      sortSelected={sortOption}
      sortOptions={["az", "best_rank", "curr_score"]}
      onSortChange={(v) => {
        ls.set("so5_basketball_sort", v);
        setSortOption(v);
      }}
      rewards={rewards}
    >
      {lineups !== null &&
        lineups
          .filter((l) => l.hidden === undefined || !l.hidden)
          .map((l) => {
            return <BasketballLineup lineup={l} />;
          })}
    </LiveLineups>
  );
}

export default withUser(BasketballLiveLineups);
