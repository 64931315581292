import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { ResponsiveBar } from "@nivo/bar";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function PlayerMultiStatsGraph(props) {
  const [data, setData] = useState(props.data || []);
  const [period, setPeriod] = useState(props.period || "3m");

  // returns a list of total value labels for stacked bars
  const TotalLabels = ({ bars, yScale }) => {
    // space between top of stacked bars and total label
    const labelMargin = 20;

    return bars.map(({ data: { data, indexValue }, x, width }, i) => {
      // sum of all the bar values in a stacked bar
      const total = Object.keys(data)
        //filter out whatever your indexBy value is
        .filter((key) => key === "score")
        .reduce((a, key) => a + data[key], 0);
      return (
        <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}-${i}`}>
          <text
            // add any class to the label here
            className="text-xs font-medium"
            x={width / 2}
            y={labelMargin / 2}
            textAnchor="middle"
            alignmentBaseline="central"
            // add any style to the label here
          >
            {total.toFixed(0)}
          </text>
        </g>
      );
    });
  };

  useEffect(() => fetchGraph(), [period, props.players]);
  useEffect(() => props.setPeriod(period), [period]);
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const fetchGraph = () => {
    if (props.data === undefined) {
      let ids = [];
      props.players?.map((p) => {
        if (p !== undefined) ids.push(p.PlayerId);
      });
      if (ids.length > 0) {
        props
          .fetch("/apiv2/players/multiStatsGraph", {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              players: ids,
              period: period,
            }),
          })
          .then((response) => response.json())
          .then((res) => {
            setData(res);
          })
          .catch(errorCatcher());
      }
    }
  };

  const CustomTooltip = (n) => {
    if (!props.combined) {
      const node = n.node;
      const data = node.data;
      let receiving = "Hosting";
      let opponent = "";
      let playerTeamId = data.player_team_id;
      if (data.away_id === playerTeamId) {
        receiving = "At";
        opponent = data.home_display_name;
      } else if (data.home_id === playerTeamId) {
        opponent = data.away_display_name;
      }
      let level = data.level;

      return (
        <Box elevation={"small"} pad={"small"} background={{ color: "white" }} gap={"small"}>
          <Box>
            <Text size={"small"}>
              GW #{data.x} - {data.name}
            </Text>
            <Text size={"small"} weight={500}>
              Scored {data.y} pts in {data.MinsPlayed} mins
            </Text>
            <Text size={"small"}>
              {receiving} {opponent} ({data.game.HomeGoals}-{data.game.AwayGoals})
            </Text>
          </Box>
          {level > 0 && (
            <Box>
              <Text size={"small"}>Decisive score: {level}</Text>
              <Text size={"small"}>All-around score: {data.all_around_score.toFixed(1)}</Text>
            </Box>
          )}
        </Box>
      );
    } else {
      const node = n.node;
      const data = node.data;
      return (
        <Box elevation={"small"} pad={"small"} background={{ color: "white" }} gap={"small"}>
          <div>
            <p className={"font-semibold text-sm"}>GW #{data.x}</p>
            <p className={"text-sm"}>
              Combined score: <span className={"font-semibold"}>{data.y.toFixed(2)}</span>
            </p>
          </div>
        </Box>
      );
    }
  };

  let activeClass = "bg-bar-grey rounded px-4 py-2 font-semibold text-base focus:outline-none";
  let inactiveClass = "bg-focus rounded px-4 py-2 font-semibold text-base hover:bg-bar-grey focus:outline-none";

  const filterWithKeys = (d) => {
    if (props.keys) {
      if (d.serieId === props.keys[0]?.r?.player?.DisplayName) {
        return "#1f77b4";
      } else if (d.serieId === props.keys[1]?.r?.player?.DisplayName) {
        return "#ff7f0e";
      } else if (d.serieId === props.keys[2]?.r?.player?.DisplayName) {
        return "#2ca02c";
      } else if (d.serieId === props.keys[3]?.r?.player?.DisplayName) {
        return "#d62728";
      } else if (d.serieId === props.keys[4]?.r?.player?.DisplayName) {
        return "#9467bd";
      }
    } else return { scheme: "category10" };
  };

  return (
    <div>
      {!props.combined && (
        <div className={"w-full mx-auto text-base space-x-4"}>
          <button className={period === "1m" ? activeClass : inactiveClass} onClick={() => setPeriod("1m")}>
            1m
          </button>
          <button className={period === "3m" ? activeClass : inactiveClass} onClick={() => setPeriod("3m")}>
            3m
          </button>
          <button className={period === "6m" ? activeClass : inactiveClass} onClick={() => setPeriod("6m")}>
            6m
          </button>
          <button className={period === "ytd" ? activeClass : inactiveClass} onClick={() => setPeriod("ytd")}>
            YTD
          </button>
          <button className={period === "1y" ? activeClass : inactiveClass} onClick={() => setPeriod("1y")}>
            1y
          </button>
          <button className={period === "all" ? activeClass : inactiveClass} onClick={() => setPeriod("all")}>
            All
          </button>
        </div>
      )}
      <div className={"w-full mx-auto my-4 text-sm bg-focus rounded self-end flex"} style={{ height: "25rem" }}>
        {!props.combined && data !== null && (
          <ResponsiveScatterPlot
            data={data}
            colors={props.keys ? (id) => filterWithKeys(id) : { scheme: "category10" }}
            //colors={["#0582F5", "#E224CF", "#FF385C", "#F2C94C", "#27A260", "#ffa600"]}
            margin={{ top: 40, right: 40, bottom: 60, left: 60 }}
            yScale={{ type: "linear", min: "auto", max: "auto" }}
            xScale={{ type: "linear", min: "auto", max: "auto" }}
            nodeSize={10}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 46,
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -60,
            }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                translateY: 50,
                itemWidth: 130,
                itemHeight: 10,
                symbolSize: 10,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            tooltip={(node) => CustomTooltip(node)}
          />
        )}
        {props.combined && (
          <ResponsiveBar
            data={data}
            animate={false}
            colors={{ scheme: "category10" }}
            keys={["player_1", "player_2", "player_3", "player_4", "player_5"]}
            legendLabel={(d) => {
              if (d.id === "player_1") {
                return props.keys[0]?.r?.player?.DisplayName;
              } else if (d.id === "player_2") {
                return props.keys[1]?.r?.player?.DisplayName;
              } else if (d.id === "player_3") {
                return props.keys[2]?.r?.player?.DisplayName;
              } else if (d.id === "player_4") {
                return props.keys[3]?.r?.player?.DisplayName;
              } else if (d.id === "player_5") {
                return props.keys[4]?.r?.player?.DisplayName;
              }
            }}
            //colors={["#0582F5", "#E224CF", "#FF385C", "#F2C94C", "#27A260", "#ffa600"]}
            margin={{ top: 40, right: 40, bottom: 60, left: 60 }}
            valueScale={{ type: "linear" }}
            valueFormat={(value) => {
              if (value === 0) {
                return "";
              } else return parseFloat(value).toFixed(0);
            }}
            indexScale={{ type: "band", round: true }}
            nodeSize={10}
            axisTop={null}
            axisRight={null}
            indexBy="gw"
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 46,
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -60,
            }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                translateY: 50,
                itemWidth: 130,
                itemHeight: 10,
                symbolSize: 10,
                symbolShape: "circle",
              },
            ]}
            layers={["grid", "axes", "bars", TotalLabels, "markers", "legends"]}
          />
        )}
      </div>
    </div>
  );
}

export default withUser(PlayerMultiStatsGraph);
