import React, { Component } from "react";
import { Text, Box } from "grommet";
import BidLine from "./bidLine";

class BidsLines extends Component {
  render() {
    const bids = this.props.bids;
    return bids !== null ? (
      bids.length > 0 ? (
        bids.map((bid, index) => {
          return (
            <Box
              height="64px"
              border={{ color: "#E8E8E8", size: "xsmall" }}
              background={{ color: "white" }}
              style={{ borderRadius: "12px" }}
              justify={"center"}
              margin={{ top: ".4rem" }}
            >
              <BidLine bid={bid} unit={this.props.unit} />
            </Box>
          );
        })
      ) : (
        <Text>No bid in this auction.</Text>
      )
    ) : (
      <Text>No bid in this auction.</Text>
    );
  }
}

export default BidsLines;
