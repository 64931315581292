import React from "react";
import { sorareFootball } from "./sports";
import { getScoreColors } from "./scales";
import { ReactComponent as DoubleCarret } from "../../img/icons-double-carret.svg";

export const FloorScore = (props) => {
  const { score } = props;
  const sport = props.sport || sorareFootball;
  if (score === undefined || isNaN(Number(score))) {
    return <div className={"flex justify-center text-md text-textGrey3 font-semibold"}>-</div>;
  }

  const [mainColor, _] = getScoreColors(score, sport);
  return (
    <div style={{ color: mainColor }} className={"flex justify-center text-md gap-1 font-semibold items-center"}>
      <DoubleCarret style={{ fill: mainColor }} className={"h-6 w-6"} />
      <span>{score}</span>
    </div>
  );
};

export const CeilingScore = (props) => {
  const { score } = props;
  const sport = props.sport || sorareFootball;

  if (score === undefined || isNaN(Number(score))) {
    return <div className={"flex justify-center text-md text-textGrey3 font-semibold"}>-</div>;
  }

  const [mainColor, _] = getScoreColors(score, sport);
  return (
    <div style={{ color: mainColor }} className={"flex justify-center text-md gap-1 font-semibold items-center"}>
      <DoubleCarret style={{ fill: mainColor }} className={"h-6 w-6 transform rotate-180"} />
      <span>{score}</span>
    </div>
  );
};
