import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import { formatValuationOrigin } from "../util/formatValuationOrigin";
import React from "react";

function PriceIndicator(props) {
  let bmp = formatBestMarketPrice(props.bmp, props.user.preferredUnit);
  let rta = formatRealTimeAverage(props.avg, props.user.preferredUnit);
  if (props.sport === sorareBaseball || props.sport === sorareBasketball) {
    return (
      <div className={"flex flex-row w-full"}>
        <div className={"3xl:space-y-0.5 space-y-1.5 lg:space-y-1 w-full"}>
          {props.showValo && (
            <div className={"flex flex-row lg:space-x-2 space-x-1 text-center justify-center"}>
              <img src={props.icon} className={"w-2 h-2 self-center"} />
              <p className={"text-" + props.scarcity + " 3xl:text-lg 2xl:text-base xl:text-med lg:text-sm text-sm font-semibold"}>{rta}</p>
            </div>
          )}
          {props.showValo && (
            <div className={"lg:text-xxs text-xxxs text-textGrey3 font-medium justify-center self-center text-center w-20 md:w-auto"}>
              <p>
                <span className={"font-semibold"}>
                  {formatValuationOrigin(props.avg.ValuationOrigin) !== "No sale" && formatValuationOrigin(props.avg.ValuationOrigin)}
                </span>
              </p>
            </div>
          )}
          {props.showBmp && ((props.avg.ValuationOrigin !== "bmp" && bmp !== "No sale") || !props.showValo) && (
            <div className={"flex flex-row lg:space-x-2 space-x-1 text-center justify-center"}>
              {!props.showValo && <img src={props.icon} className={"w-2 h-2 self-center"} />}
              <p
                className={
                  props.showValo
                    ? "text-" + props.scarcity + " 3xl:text-base 2xl:text-sm lg:text-sm text-xs font-semibold"
                    : "text-" + props.scarcity + " 3xl:text-base 2xl:text-sm xl:text-med lg:text-sm text-sm font-semibold"
                }
              >
                {bmp}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={"flex flex-row lg:space-x-3 space-x-2 self-center md:w-4/12 w-auto"}>
        <div className={"3xl:space-y-0.5 space-y-1.5 lg:space-y-1 w-full"}>
          {props.showValo && (
            <div className={"flex flex-row lg:space-x-2 space-x-1 text-center justify-center"}>
              <img src={props.icon} className={"w-2 h-2 self-center"} />
              <p className={"text-" + props.scarcity + " 3xl:text-lg 2xl:text-base xl:text-med lg:text-sm text-sm font-semibold"}>{rta}</p>
            </div>
          )}
          {props.showValo && (
            <div className={"lg:text-xxs text-xxxs text-textGrey3 font-medium justify-center self-center text-center w-20 md:w-auto"}>
              <p>
                <span className={"font-semibold"}>
                  {formatValuationOrigin(props.avg.ValuationOrigin) !== "No sale" && formatValuationOrigin(props.avg.ValuationOrigin)}
                </span>
              </p>
            </div>
          )}
          {props.showBmp && ((props.avg.ValuationOrigin !== "bmp" && bmp !== "No sale") || !props.showValo) && (
            <div className={"flex flex-row lg:space-x-2 space-x-1 text-center justify-center"}>
              {!props.showValo && <img src={props.icon} className={"w-2 h-2 self-center"} />}
              <p
                className={
                  props.showValo
                    ? "text-" + props.scarcity + " 3xl:text-base 2xl:text-sm lg:text-sm text-xs font-semibold"
                    : "text-" + props.scarcity + " 3xl:text-lg 2xl:text-base xl:text-med lg:text-sm text-sm font-semibold"
                }
              >
                {bmp}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PriceIndicator;
