import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { ReactTooltip } from "./tooltip";
import { MobileModale } from "./mobileModale";
import { XIcon } from "@heroicons/react/outline";
import { ReactComponent as CheckIcon } from "../../img/icons-check.svg";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as LockReset } from "@material-design-icons/svg/filled/lock.svg";
import { ReactComponent as CheckBox } from "../../img/check-box-empty-no-color.svg";
import { ReactComponent as CheckBoxBrand } from "../../img/check-box-checked-no-color.svg";
import { ReactComponent as CheckBoxBrandMinus } from "../../img/check-box-minus-no-color.svg";
import clsx from "clsx";
import { PaywallIcon } from "./paywall";

export const DropdownDS = (props) => {
  const { options, selected, onChange, paywalled, disabled, title, multiple } = props;
  const optionAllValue = props.optionAllValue || "all";
  const width = props.width || "w-auto";
  const [focus, setFocus] = useState(false);
  const disabledMessage = props.disabledMessage || "Feature unavailable";
  const paywalledMessage = props.paywalledMessage || "Star membership is needed";
  const titleCanClick = disabled ? disabledMessage : paywalled ? paywalledMessage : "";
  const canClick = !disabled && !paywalled;

  const onClose = () => {
    setFocus(false);
  };

  const ref = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocus(false);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [focus]);

  const handleChange = (value) => {
    !multiple && setFocus(false);
    onChange && onChange(value);
  };
  const selectedItems = multiple
    ? selected.map((id) => options.find(({ value }) => id === value))
    : [options?.find(({ value }) => selected === value) || {}];
  const isAllOptionsSelected = multiple && selectedItems.length === options.length;
  const selectedItemsLabelsNotAll = multiple
    ? options.filter((c) => selected.includes(c.value) && c.value !== optionAllValue).map((s) => s.label || s.name)
    : selectedItems.map((s) => s.label || s.name);
  const optionAllItem = multiple ? options.find((c) => c.value === optionAllValue) : {};
  const selectedItemsNames = isAllOptionsSelected
    ? [optionAllItem?.label || optionAllItem?.name || props.placeholder]
    : selectedItemsLabelsNotAll;
  const selectedItemsLabel = selectedItemsNames.join(", ");
  const Icon = props?.icon;
  const itemsProps = {
    optionAllValue,
    options,
    selectedItems,
    handleChange,
    multiple,
  };
  return (
    <div className={clsx("relative h-full", width)} ref={ref}>
      {paywalled && <PaywallIcon />}
      <button
        onClick={canClick ? () => setFocus(!focus) : undefined}
        className={clsx(
          "h-full text-md my-auto px-4 py-3 w-full rounded-full",
          width,
          !canClick ? "bg-surface-disable opacity-40" : focus ? "bg-surface-container-high shadow" : "bg-surface-container",
          "flex flex-row justify-between gap-2",
          canClick ? "cursor-pointer" : paywalled ? "cursor-not-allowed" : "cursor-default",
        )}
        title={!canClick ? titleCanClick : title ? `${title}: ${selectedItemsLabel}` : selectedItemsLabel}
      >
        <div className={"flex flex-row items-center gap-2 w-full"}>
          {Icon && <Icon className={"w-5 h-5 fill-on-surface"} />}
          <span className={"flex items-center text-on-surface font-medium truncate h-full"}>{selectedItemsLabel || props.placeholder}</span>
        </div>
        <div className={"flex flex-row gap-2 my-auto w-5 justify-end"}>
          <div className={"flex flex-col justify-center"}>
            <SortArrowIcon className={`fill-on-surface h-2.5 w-2.5 ${focus ? "transform rotate-180" : ""}`} />
          </div>
        </div>
      </button>
      {isMobile && (
        <MobileModale open={focus}>
          <div className={"h-full  bg-surface-container-high rounded-t-lg"}>
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <div className={"flex flex-row justify-between items-center px-4 py-4"}>
                <h3 className={"font-headers text-on-surface font-semibold m-0"}>{title || `Choose an option`}</h3>
                <div className={"flex flex-row gap-3"}>
                  <XIcon className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface" aria-hidden="true" onClick={onClose} />
                </div>
              </div>
            </div>
            <ul className={"max-h-[80vh] overflow-y-auto"}>
              <DropdownDSItems {...itemsProps} />
            </ul>
          </div>
        </MobileModale>
      )}
      {focus && !isMobile && (
        <div className={`absolute bg-surface-container-high rounded-xl mt-2 ${width} shadow overflow-hidden z-30`}>
          <ul className={"h-full overflow-y-scroll"} style={{ maxHeight: "23em" }}>
            <DropdownDSItems {...itemsProps} />
          </ul>
        </div>
      )}
    </div>
  );
};

const DropdownDSItems = ({ options, selectedItems, handleChange, multiple, optionAllValue }) => {
  const allSelectedValues = selectedItems.map((c) => c.value);
  const isIndeterminateState = multiple && allSelectedValues.includes(optionAllValue) && selectedItems.length !== options.length;
  return (
    <>
      {options?.map((o, i) => {
        return (
          <DropdownDSItem
            key={o.value}
            index={i}
            option={o}
            isIndeterminateState={isIndeterminateState}
            onChange={handleChange}
            isSelected={allSelectedValues.includes(o.value)}
            withBorder={i < options.length - 1}
            multiple={multiple}
            optionAllValue={optionAllValue}
          />
        );
      })}
    </>
  );
};

const DropdownDSItem = ({ option, isSelected, multiple, onChange, withBorder, isIndeterminateState, optionAllValue }) => {
  const disabled = option.disabled !== undefined ? option.disabled : false;
  const canClick = !disabled;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (canClick) {
      onChange(option.value);
    }
  };

  const isAllOption = multiple && option.value === optionAllValue;

  return (
    <li
      onClick={onClick}
      className={`${
        withBorder ? "border-b border-outline-variant" : ""
      } hover:bg-containers-surface-transparent-inverse-surface-hover hover:bg-opacity-10 ${
        canClick ? "cursor-pointer" : "cursor-default"
      } pr-1`}
    >
      <div className={` w-full h-full px-3 py-2 ${isSelected ? "font-semibold text-primary" : "text-on-surface"}`}>
        <button
          value={option.value}
          className={`w-full ${
            canClick ? "cursor-pointer" : "cursor-default"
          } text-left text-sm flex flex-row justify-between gap-2 text-on-surface`}
        >
          <div className={"flex flex-row grow-0 items-center gap-3 w-full"}>
            {option.logo && (
              <div className="h-full rounded-md self-center">
                <div className="w-5 h-5 flex justify-center">
                  <img src={option.logo} className="w-full h-full object-contain fill-on-surface" />
                </div>
              </div>
            )}
            <div className={clsx(option.subtitle ? "" : "py-1.5", "truncate items-center my-auto flex flex-col")}>
              <p className={clsx("w-full truncate font-medium", isSelected ? "text-primary" : "text-on-surface")}>
                {option.label || option.name}
              </p>
              {option.subtitle && (
                <p className={clsx("w-full text-xs truncate font-medium", isSelected ? "text-primary" : "text-on-surface-variant")}>
                  {option.subtitle}
                </p>
              )}
            </div>
          </div>
          <div className={"flex flex-row gap-3 my-auto justify-end items-center"}>
            <div className={"w-5 flex justify-center items-center"}>
              {multiple ? (
                isSelected ? (
                  <CheckedIcon isAllOption={isAllOption} isIndeterminateState={isIndeterminateState} />
                ) : (
                  <CheckBox className="w-4 h-4 fill-on-surface" />
                )
              ) : isSelected ? (
                <CheckIcon className={"h-5 w-5 fill-primary"} />
              ) : null}
            </div>
          </div>
        </button>
      </div>
    </li>
  );
};

const CheckedIcon = ({ isAllOption, isIndeterminateState }) => {
  return isAllOption && isIndeterminateState ? (
    <CheckBoxBrandMinus className="w-4 h-4 fill-primary" />
  ) : (
    <CheckBoxBrand className="w-4 h-4 fill-primary" />
  );
};
