/**
 * @deprecated should use design system colors
 */
export const RARE_COLOR = "#cd3434";
/**
 * @deprecated should use design system colors
 */
export const SUPER_RARE_COLOR = "#1fa1ff";
/**
 * @deprecated should use design system colors
 */
export const UNIQUE_COLOR = "#452801";
/**
 * @deprecated should use design system colors
 */
export const LIMITED_COLOR = "#E7B62C";
/**
 * @deprecated should use design system colors
 */
export const CUSTOM_SERIES_COLOR = "#8D32E4";
/**
 * @deprecated should use design system colors
 */
export const BRAND_COLOR = "#f45206";
/**
 * @deprecated should use design system colors
 */
export const BRAND_BLACK = "#292929";
/**
 * @deprecated should use design system colors
 */
export const MAIN_GREEN = "#056155";
/**
 * @deprecated should use design system colors
 */
export const HOVER_GREEN = "#339379";
/**
 * @deprecated should use design system colors
 */
export const BACKGROUND_GREY = "#f2f2f2";

// Colors to be used when we don't know how much colors we need
// TODO: may need adaptation to dark theme
export const equidistantFixedColors = [
  // Manual first colors, so they're beautiful
  "#54D2D2",
  "#6666D2",
  "#FF6150",
  "#F8AA4B",
  "#FFCB00",
  "#3B9393",
  "#B398AC",
  "#D28654",
  "#072448",
  "#935E3B",
  "#98B398",
  // Generated with https://medialab.github.io/iwanthue/
  "#e39155",
  "#562fc1",
  "#55e84e",
  "#983ddf",
  "#8ee93e",
  "#d93fdf",
  "#4fbc37",
  "#a22caf",
  "#daec3f",
  "#434ccb",
  "#f0d031",
  "#311980",
  "#a2d241",
  "#d761e4",
  "#6ee778",
  "#df36ad",
  "#53e696",
  "#9967e4",
  "#4ea33c",
  "#6d3097",
  "#cab934",
  "#5b72e4",
  "#eaa632",
  "#49489c",
  "#dedf6f",
  "#211451",
  "#ade588",
  "#511a69",
  "#90ab35",
  "#98409b",
  "#62e2b4",
  "#f24720",
  "#76e2d9",
  "#da302d",
  "#49ad6b",
  "#e43680",
  "#3d7527",
  "#dd76d7",
  "#93892c",
  "#798be6",
  "#e57e20",
  "#3c6db5",
  "#e36333",
  "#5f9ee1",
  "#ab391a",
  "#60bcda",
  "#dc3350",
  "#5aa996",
  "#d0589f",
  "#83aa69",
  "#842364",
  "#ced589",
  "#240f32",
  "#cfa745",
  "#7b62a9",
  "#af761e",
  "#384e83",
  "#dfbd7e",
  "#212e50",
  "#c7e3af",
  "#532f62",
  "#c6ddc9",
  "#42172f",
  "#b9cae1",
  "#6e1f15",
  "#beb7eb",
  "#2d481c",
  "#d89ce4",
  "#636f2a",
  "#ad71b7",
  "#417b55",
  "#e5617f",
  "#12252e",
  "#e0755d",
  "#5183a7",
  "#a9393a",
  "#3b6b68",
  "#a53256",
  "#76969d",
  "#a45c2c",
  "#8f85b6",
  "#896a32",
  "#2d2134",
  "#d6a080",
  "#361d18",
  "#e1b9bf",
  "#273828",
  "#e290b3",
  "#553f1e",
  "#9f577c",
  "#a5a27e",
  "#692134",
  "#696b51",
  "#da8382",
  "#395467",
  "#773e26",
  "#665271",
  "#9a675e",
  "#a58696",
  "#654547",
  // Rest can be generated on the fly with randomColor lib
];
