import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import RotowireCredit from "../util/rotowireCredit";
import TeamNewsBox from "./teamNewsBox";

function TeamNews(props) {
  const [news, setNews] = useState([]);

  const getNews = () => {
    props
      .fetch("/apiv2/teams/news?teamId=" + props.teamId)
      .then((response) => response.json())
      .then((res) => {
        if (res !== null) {
          setNews(res);
        }
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (props.teamId !== "") {
      getNews();
    }
  }, [props.teamId]);

  return (
    <div className={"space-y-4 pb-8"}>
      <div className={"flex flex-row justify-between"}>
        <p className={"text-xl text-brand font-semibold"}>Latest news</p>
        <RotowireCredit credit={"Provided by"} />
      </div>
      <div className={"grid grid-cols-2 gap-8 flex flex-col"}>
        {news.map((n) => {
          return <TeamNewsBox news={n} />;
        })}
      </div>
    </div>
  );
}

export default withUser(TeamNews);
