import React from "react";
import { PriceGraphAdvancedFiltersPanel, PriceGraphPreferencesPanel } from "./priceGraphFilterPanels";
import { PeriodPicker } from "../util/periodPicker";
import { Dropdown } from "../util/dropdown";
import { scarcities_objects_not_all } from "../util/scarcities";
import { FloatingPanelFilters } from "../util/floatingPanelFilter";
import { ReactComponent as TuneIcon } from "../../img/icons-tune.svg";
import { ReactComponent as FilterIcon } from "../../img/icons-filter.svg";
import clsx from "clsx";
import { split_price_all, splitOptions } from "../util/splits";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { DropdownDS } from "../util/dropdownDS";

export const PriceGraphFilters = (props) => {
  const {
    filters,
    onFilterChange,
    onResetFilters,
    preferences,
    onPreferenceChange,
    onResetPreferences,
    sport,
    positionOptions,
    withLateralDisplay,
    tier,
  } = props;

  return (
    <div className="flex gap-2 flex-wrap justify-center md:justify-between">
      <PeriodPicker
        sport={sport}
        period={filters.period}
        onChange={(p) => {
          onFilterChange("period", p);
        }}
        allPeriodsFree
      />
      <div className={clsx("self-center z-10 w-[7.5em] h-10 flex-none")}>
        <DropdownDS
          selected={filters.scarcity}
          options={scarcities_objects_not_all}
          onChange={(v) => {
            onFilterChange("scarcity", v);
          }}
          width={"w-full"}
        />
      </div>
      <div className={clsx("self-center z-10 w-44 h-10 flex-none")}>
        <DropdownDS
          selected={filters.seasonality}
          options={splitOptions}
          onChange={(v) => {
            onFilterChange("seasonality", v);
          }}
          paywalled={!t1OrAbove(tier)}
          width={"w-full"}
        />
      </div>
      <div className={"self-center h-10 flex-none"}>
        <FloatingPanelFilters
          title="Advanced filters"
          buttonLabel="Advanced filters"
          Icon={FilterIcon}
          filters={filters}
          onFilterChange={onFilterChange}
          onReset={onResetFilters}
        >
          {(contentProps) => <PriceGraphAdvancedFiltersPanel {...contentProps} positionOptions={positionOptions} sport={sport} />}
        </FloatingPanelFilters>
      </div>
      <div className={"h-10 justify-self-end md:flex-1 flex justify-end pointer-events-none"}>
        <FloatingPanelFilters
          title="Preferences"
          buttonLabel=""
          Icon={TuneIcon}
          filters={preferences}
          onFilterChange={onPreferenceChange}
          onReset={onResetPreferences}
          withHelpIcon
          helpText="You can change the default scarcity and currency of this page by editing your profile page settings."
        >
          {(contentProps) => (
            <PriceGraphPreferencesPanel
              {...contentProps}
              withLateralDisplay={withLateralDisplay}
              disabledHighlight={filters.seasonality !== split_price_all}
            />
          )}
        </FloatingPanelFilters>
      </div>
    </div>
  );
};
