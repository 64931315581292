import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import LineSeparator from "../../img/line-separator.svg";
import GamesList, { ProjectionPointsIndicator, ProjectionRatioIndicator } from "./gamesList";
import { errorCatcher } from "../util/errors";
import ProbablePlayersForUser from "./probablePlayersOverview";
import { LineupCompetitionRules, LineupProjectedLineupsVideo } from "../util/lineupPredictionModal";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { ProjectionSeasonRewardBox } from "./lineupProjectionLeaderboard";
import { toFixedIfNecessary } from "../util/formatNumber";
import { formatNumber } from "../util/formatNumber";
import { ReactComponent as Carret } from "../../img/sort-arrow-icon-asc.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useDebounce } from "react-use";
import AbortController from "abort-controller";

function ProjGwCenterTab(props) {
  const [picked, setPicked] = useState("games");
  const [games, setGames] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [debounceSearchFilter, setDebounceSearchFilter] = useState("");
  const [userResultsForSeason, setUserResultsForSeason] = useState(undefined);
  const [managerInfo, setManagerInfo] = useState(undefined);
  const [probableLineupsAbortController, setProbableLineupsAbortController] = useState(new AbortController());
  const [seasonResultsAbortController, setSeasonResultsAbortController] = useState(new AbortController());

  useEffect(() => {
    if (props.gwNumber > 0) {
      setLoading(true);
      probableLineupsAbortController.abort();
      const newAbortController = new AbortController();
      setProbableLineupsAbortController(newAbortController);
      props
        .fetch("/projections-api/SO5/probableLineups?gw=" + props.gwNumber, {
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          setGames(res);
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
      getSeasonUserResults(props.gwNumber);
    }
  }, [props.gwNumber]);

  useEffect(() => {
    if (props.user?.sorareSlug) {
      props
        .fetch("/apiv2/manager/info/" + props.user.sorareSlug)
        .then((response) => response.json())
        .then((res) => {
          setManagerInfo({
            name: res.manager.Nickname,
            picture: res.manager.PictureUrl,
          });
        })
        .catch(errorCatcher());
    }
  }, [props.user]);

  useDebounce(
    () => {
      setDebounceSearchFilter(searchFilter);
    },
    250,
    [searchFilter],
  );

  const getSeasonUserResults = (gwNumber) => {
    if (gwNumber) {
      const newParams = new URLSearchParams();
      newParams.append("gw", gwNumber);
      seasonResultsAbortController.abort();
      const newAbortController = new AbortController();
      setSeasonResultsAbortController(newAbortController);
      props
        .fetch("/projections-api/SO5/projectionUserLeaderboardForSeason?" + newParams.toString(), {
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setUserResultsForSeason(res);
          }
        })
        .catch(errorCatcher());
    }
  };

  const pickedStyle = "text-xs sm:text-sm text-brand self-center";
  const notPickedStyle = "text-xs sm:text-sm text-textGrey4 self-center";
  const eligibleLeagues = games?.regions?.map((region) => region.leagues)?.flat(1) || [];
  const flatGames = Object.entries(games?.games || {})
    .map(([league, games]) => (eligibleLeagues.includes(league) ? games : []))
    .flat(1);
  const allProbableStartersInLeague = flatGames.map((game) => Object.values(game.probable_starters_stats)).flat();
  const totalEligiblePlayers = allProbableStartersInLeague.map((starter) => starter.nb_eligible_players).reduce((a, b) => a + b, 0);
  const totalVotedPlayers = allProbableStartersInLeague
    .map((starter) => Object.values(starter.votes))
    .flat(1)
    .reduce((a, b) => a + b, 0);
  let totalPoints, totalPossiblePoints;
  allProbableStartersInLeague.forEach((starter) => {
    if (starter.manager_scoring !== null && starter.manager_scoring !== undefined) {
      if (totalPoints === undefined) {
        totalPoints = parseFloat(starter.manager_scoring.points);
      } else {
        totalPoints += parseFloat(starter.manager_scoring.points);
      }
      if (totalPossiblePoints === undefined) {
        totalPossiblePoints = parseFloat(starter.manager_scoring.max_possible_points);
      } else {
        totalPossiblePoints += parseFloat(starter.manager_scoring.max_possible_points);
      }
    }
  });

  return (
    <div className={"flex flex-col-reverse md:flex-row gap-5"}>
      <div className={"flex flex-col gap-3 w-full"}>
        <div className={"flex flex-col gap-2 sm:flex-row sm:justify-between items-center"}>
          <div className={"flex flex-row justify-center"}>
            <div
              className={
                "font-headers font-semibold bg-white flex flex-row gap-3 justify-evenly rounded-full text-sm px-4 py-2 items-center w-full lg:w-auto"
              }
            >
              <div className={"cursor-pointer"} onClick={() => setPicked("games")}>
                <p className={picked === "games" ? pickedStyle : notPickedStyle}>All games</p>
              </div>
              <img src={LineSeparator} />
              <div className={"cursor-pointer"} onClick={() => setPicked("players")}>
                <p className={picked === "players" ? pickedStyle : notPickedStyle}>My players</p>
              </div>
            </div>
          </div>
          {picked === "games" && (
            <div
              className={
                "relative rounded-md bg-white p-0 px-2 flex flex-row gap-1 w-full md:w-80 h-full shadow-sm h-full min-h-[2em] items-center"
              }
            >
              <div className="flex items-center pointer-events-none">
                <FormSearch className="h-5 w-5" aria-hidden="true" />
              </div>
              {searchFilter && (
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => {
                    setSearchFilter("");
                  }}
                >
                  <img src={CloseOutlineIcon} className="opacity-80 h-4 w-4" aria-hidden="true" />
                </div>
              )}
              <input
                className={`w-full h-full block bg-transparent font-medium p-1 border-0 text-sm border-0 focus:ring-0 ${
                  searchFilter !== "" ? "text-brand-black" : "text-grey-82"
                }`}
                type={"text"}
                placeholder="Search game or competition"
                value={searchFilter}
                onChange={(e) => {
                  setSearchFilter(e.target.value);
                }}
              />
            </div>
          )}
        </div>
        {picked === "players" && <ProbablePlayersForUser gwNumber={props.gwNumber} user={props.user} />}
        {picked === "games" && (
          <div className={"mb-8"}>
            <GamesList games={games} searchFilter={debounceSearchFilter} />
          </div>
        )}
      </div>
      <div className={"flex flex-col sm:grid sm:grid-rows-2 sm:grid-cols-2 md:flex md:flex-col gap-2 self-center md:self-auto"}>
        <UserCurrentResultBox
          manager={managerInfo}
          seasonName={userResultsForSeason?.summary.seasonDisplayName}
          total={userResultsForSeason?.summary?.total}
          loading={userResultsForSeason === undefined}
        />
        <div className={"flex flex-col gap-2 px-4 py-3 bg-surface-container rounded-lg justify-center"}>
          <h4 className={"text-sm font-semibold text-brand-black m-0"}>Total projections this GW:</h4>
          <div className={"flex flex-row gap-2 justify-center"}>
            <ProjectionRatioIndicator
              loading={Object.keys(games?.games || {}).length === 0 || loading}
              eligible={totalEligiblePlayers}
              voted={totalVotedPlayers}
            />
            {totalPoints !== undefined && (
              <ProjectionPointsIndicator points={totalPoints} totalPossiblePoints={totalPossiblePoints} eligible={totalEligiblePlayers} />
            )}
          </div>
        </div>
        <div className={"flex flex-col gap-1 px-4 py-3 bg-surface-container rounded-lg justify-center"}>
          <h4 className={"text-sm font-semibold text-brand-black m-0"}>How does it work?</h4>
          <div className={"-my-1"}>
            <LineupCompetitionRules />
          </div>
          <div className={"-my-1"}>
            <LineupProjectedLineupsVideo />
          </div>
        </div>
        <ProjectionSeasonRewardBox
          loading={userResultsForSeason === undefined}
          rewards={userResultsForSeason?.summary?.rewards}
          eligibleToRaffle={userResultsForSeason?.summary?.eligibleToRaffle}
          greating={userResultsForSeason?.summary?.greatingType}
        />
      </div>
    </div>
  );
}

const UserCurrentResultBox = (props) => {
  const { seasonName, total, manager, loading } = props;
  if (loading) {
    return (
      <div
        className={`rounded-xl animate-pulse-sm flex flex-col bg-center bg-no-repeat bg-cover bg-projection-leaderboard-result overflow-hidden w-80 p-5 text-white gap-1 border-3 border-transparent h-36`}
      ></div>
    );
  }
  let totalGood = total?.totalGoodStarters || 0;
  let totalProjections = total?.totalProjections || 0;
  let totalProjectionsStarters = total?.totalGoodStarters + total?.totalBadStarters || 0;
  const nbProjections = formatNumber(totalProjections);
  const accuracy = totalProjectionsStarters > 0 ? toFixedIfNecessary((totalGood / totalProjectionsStarters) * 100, 2) + "%" : "0%";
  return (
    <div
      className={`rounded-xl flex flex-col justify-between bg-center bg-no-repeat bg-cover bg-projection-leaderboard-result overflow-hidden w-80 text-white gap-1 p-5 h-36`}
    >
      <div className={"flex flex-row gap-2 items-center"}>
        <div className={"w-8 h-8 flex flex-col items-center justify-center min-w-fit"}>
          {manager.picture ? (
            <img src={manager.picture} className={"h-full w-full object-contain"} />
          ) : (
            <FontAwesomeIcon icon={faUser} className={"self-center animate-pulse-sm"} size={"2x"} color={"#e6e6e6"} />
          )}
        </div>
        <div className={"text-2xl text-grey-e6 font-semibold w-full"}>
          <div className={"w-[80%] truncate"}>{manager?.name}</div>
          <div className={"text-sm"}>{seasonName}</div>
        </div>
      </div>
      <div className={"flex flex-row justify-between items-end"}>
        <button className={"bg-white text-brand-black font-semibold text-xs px-3 py-1.5 rounded-full cursor-pointer hover:opacity-80"}>
          <a className={"flex flex-row gap-3 items-center"} href={"/lineupProjections/leaderboard"}>
            View leaderboard
            <Carret className={"fill-brand-black h-2.5 w-2.5 transform -rotate-90"} />
          </a>
        </button>
        <div className={"flex flex-col justify-evenly items-end gap-1 text-xs font-semibold"}>
          <p>{nbProjections} projections</p>
          <p>{accuracy} accuracy</p>
        </div>
      </div>
    </div>
  );
};

export default withUser(ProjGwCenterTab);
