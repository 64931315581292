import React, { useEffect, useState } from "react";
import { getNextGameweeksObjects } from "../util/nextGws";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { withUser } from "../../userContext";
import GridIcon from "../../img/grid_icon.svg";
import CarretDown from "../../img/carret-vector.svg";
import CarretUp from "../../img/carret-vector-up.svg";
import { PositionPicker } from "../util/positionPicker";
import { PlayerStatusSelector } from "../util/playingStatus";
import { AvailabilityStatusSelector } from "../util/availabilityStatus";
import { leagues_no_div_objects_not_all } from "../util/leagues";
import { teams } from "../util/teams";
import { Range } from "rc-slider";
import ToggleWithLabel from "../util/toggleWithLabel";
import ScoresIcon from "../../img/line_chart_up.svg";
import BoxWithToggle from "../util/boxWithToggle";
import ScarcityPicker from "../util/scarcityPicker";
import { card_editions_objects } from "../util/cardEditions";
import { sell_status_objects } from "../util/sellStatus";
import { FormSearch } from "grommet-icons";
import CurrencyPicker from "../util/currencyPicker";
import "../../react-search.css";
import { Button } from "../util/button";
import CardsIcon from "../../img/cards-icon.svg";
import { seasons_objects_int } from "../util/seasons";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import Locker from "../../img/brand-close-locker-nude.svg";
import { ReactTooltip } from "../util/tooltip.js";
import SaveFilters from "../util/saveFilters";
import { scarcities_objects_not_all_and_cs } from "../util/scarcities";
import SportsPicker from "../util/sportsPicker";
import { allSportsOptions, sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { baseball_positions_not_all, basketball_positions_not_all, getPositionsBasedOnSport, positions_not_all } from "../util/positions";
import { baseball_clubs_objects, not_all_baseball_clubs_objects } from "../util/baseballClubs";
import { GameweekFilter } from "../players/gameweekFilter";
import ScoreFilter from "../util/scoreFilter";
import PriceFilter from "../util/priceRange";
import AgeRange, { DEFAULT_AGE_RANGE } from "../util/ageRange";
import { basketball_clubs_objects, not_all_basketball_clubs_objects } from "../util/basketballClubs";
import WatchlistMultiplePicker from "../watchlists/watchlistMultiplePicker";
import Spinner from "../loader/spinner";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { useDebounce } from "react-use";
import { endOnboarding, getFirstEnabledOnboardingForPage, nextOnboarding, Onboarding } from "../onboarding/onboardingContext";
import {
  allStarDefaultCompetition,
  EligibilitySorareCompetitionsFilter,
  initialEligibilityCompetitionValue,
} from "../competitions/eligibilityCompetitionsPicker";
import { CardSplitDropdown, split_price_all } from "../util/splits";

function FilterMenu(props) {
  const [full, setFull] = useState(false);

  useEffect(() => {
    if (props.tooltip) {
      ReactTooltip.rebuild();
    }
  }, [full]);

  return (
    <div className={"space-y-3"}>
      <div className={"flex flex-row justify-between pr-2 cursor-pointer border-b border-grey-e5 pb-1"} onClick={() => setFull(!full)}>
        <div className={"flex flex-row space-x-2"}>
          {props.icon && (
            <div className={"w-4 h-4 self-center"}>
              <img alt="" className={"w-full h-full object-contain"} src={props.icon} />
            </div>
          )}
          <p className={props.labelStyle ? props.labelStyle : "text-sm font-semibold self-center"}>{props.label}</p>
        </div>
        <div className={"w-3 h-3 self-center"}>
          <img alt="" className={"w-full h-full object-contain"} src={full ? CarretUp : CarretDown} />
        </div>
      </div>
      {full && <div>{props.content}</div>}
    </div>
  );
}

function ScoreFilterBox(props) {
  const filter = props.stats;
  const sport = props.sport;

  let label = "L" + props.gms;
  if (props.gms === "Season") {
    label = "Season";
  }

  return (
    <div className={"space-y-2"}>
      <div className={"border border-grey-f2 rounded-lg py-2"}>
        <BoxWithToggle label={label} isActive={filter.enabled} setActive={() => props.change({ ...filter, enabled: !filter.enabled })} />
      </div>
      {filter.enabled && (
        <div className={"space-y-2 px-2"}>
          <div className={"space-y-1"}>
            <p className={"font-semibold text-textGrey3 text-sm"}>Score average</p>
            <Range
              trackStyle={[{ backgroundColor: "#f45206" }]}
              handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
              step={1}
              value={filter.scoreRange}
              min={0}
              max={100}
              allowCross={false}
              onChange={(v) => props.change({ ...filter, scoreRange: v })}
            />
            <div className={"justify-between flex flex-row text-sm font-semibold"}>
              <span>{filter.scoreRange[0]}</span>
              <span>{filter.scoreRange[1]}</span>
            </div>
          </div>
          {sport === sorareFootball && (
            <div className={"space-y-1"}>
              <div className="flex flex-row">
                <p className={"font-semibold text-textGrey3 text-sm"}>Decisive score average</p>
                {props.disabled && <img className="ml-2" alt="" src={Locker}></img>}
              </div>
              <div data-tip={props.disabledTip}>
                <Range
                  trackStyle={[
                    {
                      backgroundColor: props.disabled ? "#ffffff" : "#f45206",
                      border: props.disabled ? "solid 1px  #f45206" : "",
                    },
                  ]}
                  handleStyle={[
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                  ]}
                  step={1}
                  value={filter.daRange}
                  min={0}
                  max={100}
                  allowCross={false}
                  onChange={(v) => props.change({ ...filter, daRange: v })}
                  disabled={props.disabled}
                />
              </div>
              <div className={"justify-between flex flex-row text-sm font-semibold"}>
                <span>{filter.daRange[0]}</span>
                <span>{filter.daRange[1]}</span>
              </div>
            </div>
          )}
          {sport === sorareFootball && (
            <div className={"space-y-1"}>
              <div className="flex flex-row">
                <p className={"font-semibold text-textGrey3 text-sm"}>All-around score average</p>
                {props.disabled && <img className="ml-2" alt="" src={Locker}></img>}
              </div>
              <div data-tip={props.disabledTip}>
                <Range
                  trackStyle={[
                    {
                      backgroundColor: props.disabled ? "#ffffff" : "#f45206",
                      border: props.disabled ? "solid 1px  #f45206" : "",
                    },
                  ]}
                  handleStyle={[
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                  ]}
                  step={1}
                  value={filter.aaRange}
                  min={-20}
                  max={100}
                  allowCross={false}
                  onChange={(v) => props.change({ ...filter, aaRange: v })}
                  disabled={props.disabled}
                />
              </div>
              <div className={"justify-between flex flex-row text-sm font-semibold"}>
                <span>{filter.aaRange[0]}</span>
                <span>{filter.aaRange[1]}</span>
              </div>
            </div>
          )}
          {!props.light && sport !== sorareBasketball && (
            <div className={"space-y-1"}>
              <div className="flex flex-row">
                <p className={"font-semibold text-textGrey3 text-sm"}>% of games started</p>
                {props.disabled && <img className="ml-2" alt="" src={Locker}></img>}
              </div>
              <div data-tip={props.disabledTip}>
                <Range
                  trackStyle={[
                    {
                      backgroundColor: props.disabled ? "#ffffff" : "#f45206",
                      border: props.disabled ? "solid 1px  #f45206" : "",
                    },
                  ]}
                  handleStyle={[
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                  ]}
                  step={1}
                  value={filter.gmsStarted}
                  min={0}
                  max={100}
                  allowCross={false}
                  onChange={(v) => props.change({ ...filter, gmsStarted: v })}
                  disabled={props.disabled}
                />
              </div>
              <div className={"justify-between flex flex-row text-sm font-semibold"}>
                <span>{filter.gmsStarted[0]}</span>
                <span>{filter.gmsStarted[1]}</span>
              </div>
            </div>
          )}
          {!props.light && sport !== sorareBasketball && (
            <div className={"space-y-1"}>
              <div className="flex flex-row">
                <p className={"font-semibold text-textGrey3 text-sm"}>% of games played</p>
                {props.disabled && <img className="ml-2" alt="" src={Locker}></img>}
              </div>
              <div data-tip={props.disabledTip}>
                <Range
                  trackStyle={[
                    {
                      backgroundColor: props.disabled ? "#ffffff" : "#f45206",
                      border: props.disabled ? "solid 1px  #f45206" : "",
                    },
                  ]}
                  handleStyle={[
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                    {
                      border: props.disabled ? "solid 1px  #f45206" : "solid 1px #f45206",
                    },
                  ]}
                  step={1}
                  value={filter.gmsPlayed}
                  min={0}
                  max={100}
                  allowCross={false}
                  onChange={(v) => props.change({ ...filter, gmsPlayed: v })}
                  disabled={props.disabled}
                />
              </div>
              <div className={"justify-between flex flex-row text-sm font-semibold"}>
                <span>{filter.gmsPlayed[0]}</span>
                <span>{filter.gmsPlayed[1]}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function FilterPriceBox(props) {
  const obj = props.obj;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const isDisabled = props.tier && !t1OrAbove(props.tier) && props.sport !== sorareBaseball;

  return (
    <div className={"border border-grey-f2 space-y-2 py-2 rounded-lg"}>
      <div className={"z-500"}>
        <BoxWithToggle
          label={props.label}
          isActive={obj.enabled}
          disabled={isDisabled}
          disabledTip={isDisabled ? "Star membership is needed" : ""}
          setActive={() => props.change({ ...obj, enabled: !obj.enabled })}
        />
      </div>

      {obj.enabled && (
        <div className={"px-2 flex flex-row space-x-4"}>
          <div className={"w-6/12 self-center"}>
            <input
              className="focus:outline-none focus:ring-0 focus:border-brand block w-full py-2 rounded-md pl-4 shadow sm:text-sm border-grey-f2"
              type={"number"}
              value={obj.min}
              onChange={(v) => props.change({ ...obj, min: v.target.value })}
            />
          </div>
          <div className={"w-6/12 self-center"}>
            <input
              className="focus:outline-none focus:ring-0 focus:border-brand block w-full py-2 rounded-md pl-4 shadow sm:text-sm border-grey-f2"
              type={"number"}
              value={obj.max}
              onChange={(v) => props.change({ ...obj, max: v.target.value })}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function SearchFilters(props) {
  const mode = props.mode;
  const cardMode = mode === "cards";
  const playerMode = mode === "players";
  const SearchMode = playerMode || cardMode;
  const auctionMode = mode === "auctions";
  const offerMode = mode === "offers";
  const marketMode = auctionMode || offerMode;
  const loading = props.loading;
  const [gameweeks, setGameweeks] = useState([]);
  const [gw, setGw] = useState(0);
  const [positions, setPositions] = useState(positions_not_all);
  const [playingStatus, setPlayingStatus] = useState([]);
  const [availabilityStatus, setAvailabilityStatus] = useState([]);
  const [eligibilityCompetitions, setEligibilityCompetitions] = useState(initialEligibilityCompetitionValue);
  const [so5League, setSO5League] = useState([]);
  const [teamFilter, setTeamFilter] = useState([]);
  const [ageRange, setAgeRange] = useState(DEFAULT_AGE_RANGE);
  const [u23Eligible, setU23Eligible] = useState(false);
  const [l5Filter, setL5Filter] = useState({
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  });
  const [l15Filter, setL15Filter] = useState({
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  });
  const [l40Filter, setL40Filter] = useState({
    enabled: false,
    scoreRange: [0, 100],
    daRange: [0, 100],
    aaRange: [-20, 100],
    gmsPlayed: [0, 100],
    gmsStarted: [0, 100],
  });
  const [scarcity, setScarcity] = useState(props.user.preferredScarcity);
  const [editions, setEditions] = useState([]);
  const [status, setStatus] = useState([]);
  const [currency, setCurrency] = useState(props.user.preferredUnit);
  const [currentPriceOptions, setCurrentPriceOptions] = useState({
    enabled: false,
    min: 0,
    max: 10,
  });
  const [currentFloorOptions, setCurrentFloorOptions] = useState({
    enabled: false,
    min: 0,
    max: 10,
  });
  const [mostRecentOptions, setMostRecentOptions] = useState({
    enabled: false,
    min: 0,
    max: 10,
  });
  const [serialNumberOptions, setSerialNumberOptions] = useState({
    enabled: false,
    min: 0,
    max: 1000,
  });
  const [filterName, setFilterName] = useState("");
  const [filterOwner, setFilterOwner] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [seasonality, setSeasonality] = useState(split_price_all);
  const [isFloor, setIsFloor] = useState(false);
  const [onSale, setOnSale] = useState(false);
  const [cardPositions, setCardPositions] = useState(["Goalkeeper", "Defender", "Midfielder", "Forward"]);
  const [filterByGw, setFilterByGw] = useState(false);
  const [sortOption, setSortOption] = useState(SearchMode ? "l5" : "endingAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [powerRange, setPowerRange] = useState({
    enabled: false,
    min: 0,
    max: 55,
  });
  const [underBmp, setUnderBmp] = useState(false);
  const [underAvg, setUnderAvg] = useState(false);
  const [watchlists, setWatchlists] = useState({});
  const [scarcities, setScarcities] = useState([]);
  const [sport, setSport] = useState(props.sport || "all");
  const [hidden, setHidden] = useState(false);
  const [filterFocused, setFilterFocused] = useState("");
  const [jerseyMint, setJerseyMint] = useState(false);
  const [oneSerialNumber, setOneSerialNumber] = useState(false);
  const [smartSearchValue, setSmartSearchValue] = useState("");
  const [debounceSearchQuery, setDebounceSearchQuery] = useState("");
  const [onboarding, setOnboarding] = useState(getFirstEnabledOnboardingForPage("liveMarket"));

  useDebounce(
    () => {
      if (smartSearchValue !== "") {
        setDebounceSearchQuery(smartSearchValue);
      } else if (smartSearchValue === "" && debounceSearchQuery !== "") {
        filter(true, "");
      }
    },
    250,
    [smartSearchValue],
  );

  useEffect(() => {
    if (debounceSearchQuery !== "" && debounceSearchQuery.length > 2) {
      filter(true, debounceSearchQuery);
    }
  }, [debounceSearchQuery]);

  if (sport === "all" && !props.vertical) {
    setSport(sorareFootball);
  }

  useEffect(() => {
    if (sport === sorareBaseball) {
      if (!props.vertical) {
        setPositions(baseball_positions_not_all);
      }
      setCardPositions(baseball_positions_not_all);
    } else if (sport === sorareBasketball) {
      if (!props.vertical) {
        setPositions(basketball_positions_not_all);
      }
      setCardPositions(basketball_positions_not_all);
    } else {
      if (!props.vertical) {
        setPositions(positions_not_all);
      }
      setCardPositions(positions_not_all);
    }
    setPlayingStatus([]);
    setAvailabilityStatus([]);
    setCurrentFloorOptions({ ...currentFloorOptions, enabled: false });
    setMostRecentOptions({ ...mostRecentOptions, enabled: false });
  }, [sport]);

  useEffect(() => {
    setPositions([]);
  }, [props.vertical]);

  const onSliderChange = (value) => {
    setAgeRange(value);
  };

  useEffect(() => {
    // register eventListener on each state update
    const launchFilter = (e) => {
      if (e.key === "Enter") {
        filter();
      }
    };

    document.addEventListener("keydown", launchFilter);
    return () => {
      document.removeEventListener("keydown", launchFilter);
    };
  }, [
    gameweeks,
    gw,
    positions,
    playingStatus,
    availabilityStatus,
    so5League,
    eligibilityCompetitions,
    teamFilter,
    ageRange,
    u23Eligible,
    l5Filter,
    l15Filter,
    l40Filter,
    scarcity,
    editions,
    status,
    currency,
    currentPriceOptions,
    currentFloorOptions,
    mostRecentOptions,
    serialNumberOptions,
    seasons,
    seasonality,
    isFloor,
    onSale,
    cardPositions,
    filterByGw,
    sortOption,
    sortOrder,
    filterOwner,
    filterName,
    powerRange,
    underBmp,
    underAvg,
    watchlists,
    scarcities,
  ]);

  const resetFilters = () => {
    setGw("");
    setPositions(["Goalkeeper", "Defender", "Midfielder", "Forward"]);
    setPlayingStatus([]);
    setAvailabilityStatus([]);
    setSO5League([]);
    setEligibilityCompetitions(initialEligibilityCompetitionValue);
    setTeamFilter([]);
    setAgeRange(DEFAULT_AGE_RANGE);
    setU23Eligible(false);
    setL5Filter({
      enabled: false,
      scoreRange: [0, 100],
      daRange: [0, 100],
      aaRange: [-20, 100],
      gmsPlayed: [0, 100],
      gmsStarted: [0, 100],
    });
    setL15Filter({
      enabled: false,
      scoreRange: [0, 100],
      daRange: [0, 100],
      aaRange: [-20, 100],
      gmsPlayed: [0, 100],
      gmsStarted: [0, 100],
    });
    setL40Filter({
      enabled: false,
      scoreRange: [0, 100],
      daRange: [0, 100],
      aaRange: [-20, 100],
      gmsPlayed: [0, 100],
      gmsStarted: [0, 100],
    });
    setScarcity(props.user.preferredScarcity);
    setScarcities([]);
    setEditions([]);
    setStatus([]);
    setCurrency(props.user.preferredUnit);
    setCurrentPriceOptions({ enabled: false, min: 0, max: 10 });
    setCurrentFloorOptions({ enabled: false, min: 0, max: 10 });
    setMostRecentOptions({ enabled: false, min: 0, max: 10 });
    setSerialNumberOptions({ enabled: false, min: 0, max: 1000 });
    setSeasons([]);
    setSeasonality(split_price_all);
    setIsFloor(false);
    setOnSale(false);
    setCardPositions(["Goalkeeper", "Defender", "Midfielder", "Forward"]);
    setFilterByGw(false);
    setSortOption(SearchMode ? "l5" : "endingAt");
    setSortOrder("desc");
    setFilterOwner("");
    setPowerRange({ enabled: false, min: 0, max: 55 });
    setUnderAvg(false);
    setUnderBmp(false);
    setWatchlists({});
    ReactTooltip.rebuild();
    setSport(props.user.preferredSport || sorareFootball);
  };

  useEffect(() => {
    async function fetchGw() {
      let nextGws = await getNextGameweeksObjects(props);
      let nextGwsWithLimit = nextGws.map((gw, index) => {
        return {
          ...gw,
          disabled: isFree(props.user.tier) && index !== 0,
          photo: Locker,
        };
      });
      setGameweeks(nextGwsWithLimit);
    }

    fetchGw();
    ReactTooltip.rebuild();
  }, [props.user.tier]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [l5Filter.enabled, l15Filter.enabled, l40Filter.enabled]);

  useEffect(() => {
    setHidden(props.hidden);
    ReactTooltip.rebuild();
  }, [props.hidden]);

  useEffect(() => {
    setScarcity(props.user.preferredScarcity);
    setCurrency(props.user.preferredUnit);
    ReactTooltip.rebuild();
  }, [props.user]);

  const filter = (refresh, q) => {
    props.filter(
      {
        positions: positions,
        playingStatus: SearchMode ? playingStatus : playingStatus[0],
        availabilityStatus: availabilityStatus,
        teams: teamFilter,
        leagues: so5League,
        eligibilityCompetitions: eligibilityCompetitions,
        u23: u23Eligible,
        l5Filter: l5Filter,
        l15Filter: l15Filter,
        l40Filter: l40Filter,
        age: ageRange,
        floor: currentFloorOptions,
        average: mostRecentOptions,
        scarcity: scarcity,
        gw: gw,
        currency: currency,
        serialNumber: serialNumberOptions,
        filterName: filterName,
        seasons: seasons,
        seasonality: seasonality,
        isFloor: isFloor,
        onSale: onSale,
        currentPrice: currentPriceOptions,
        cardPositions: cardPositions,
        cardStatus: status,
        filterByGw: filterByGw,
        filterOwner: filterOwner,
        sortOption: sortOption,
        sortOrder: sortOrder,
        editions: editions,
        powerRange: powerRange,
        underBmp: underBmp,
        underAvg: underAvg,
        watchlists: watchlists,
        scarcities: scarcities,
        sport: sport,
        jerseyMint: jerseyMint,
        oneSerialNumber: oneSerialNumber,
        searchQuery: q || smartSearchValue,
      },
      refresh,
    );
  };

  const updateFilters = (s, p, c, l, ar, ps, u23, cu, cur, avg, avgr, bmp, bmpr, ump, uavg, so5, so15, so40, w, as) => {
    setScarcities(s);
    setPositions(p);
    setTeamFilter(c);
    setSO5League(l);
    setAgeRange(ar);
    setPlayingStatus([ps]);
    setAvailabilityStatus(as);
    setU23Eligible(u23);
    setCurrentPriceOptions({
      enabled: cu,
      min: cur[0],
      max: cur[1],
    });
    setCurrentFloorOptions({
      enabled: bmp,
      min: bmpr[0],
      max: bmpr[1],
    });
    setMostRecentOptions({
      enabled: avg,
      min: avgr[0],
      max: avgr[1],
    });
    setUnderBmp(ump);
    setUnderAvg(uavg);
    if (so5.enabled) {
      setL5Filter(so5);
    }
    if (so15.enabled) {
      setL15Filter(so15);
    }
    if (so40.enabled) {
      setL40Filter(so40);
    }

    setWatchlists(
      w?.reduce((obj, element) => {
        obj[element] = { id: element };
        return obj;
      }, {}) || {},
    );

    // filter(false)
  };

  const focusBlurHandler = (fieldName) => {
    return {
      onFocus: () => setFilterFocused(fieldName),
      onBlur: () => setFilterFocused(""),
    };
  };

  const PLAYER_SORTING_OPTIONS = [
    { value: "l5", name: "Sort by L5 average" },
    { value: "l15", name: "Sort by L15 average" },
    { value: "l40", name: "Sort by L40 average" },
    { value: "valuation", name: "Sort by valuation" },
    { value: "floor", name: "Sort by floor" },
  ];

  const BASKETBALL_PLAYER_SORTING_OPTIONS = [
    { value: "l5", name: "Sort by L10 average" },
    { value: "l15", name: "Sort by L40 average" },
    { value: "l40", name: "Sort by season average" },
    { value: "valuation", name: "Sort by valuation" },
    { value: "floor", name: "Sort by floor" },
  ];

  const CARDS_SORTING_OPTIONS = [
    { value: "serial", name: "Sort by serial number" },
    { value: "l5", name: "Sort by L5 average" },
    { value: "l15", name: "Sort by L15 average" },
    { value: "l40", name: "Sort by L40 average" },
    { value: "price", name: "Sort by price" },
    { value: "floor", name: "Sort by floor" },
    { value: "power", name: "Sort by power" },
  ];

  const BASKETBALL_CARDS_SORTING_OPTIONS = [
    { value: "serial", name: "Sort by serial number" },
    { value: "l5", name: "Sort by L10 average" },
    { value: "l15", name: "Sort by L40 average" },
    { value: "l40", name: "Sort by season average" },
    { value: "price", name: "Sort by price" },
    { value: "floor", name: "Sort by floor" },
    { value: "power", name: "Sort by power" },
  ];

  const DESC_ASC = [
    { value: "asc", name: "Ascending" },
    { value: "desc", name: "Descending" },
  ];

  const OFFERS_SORTING_OPTIONS = [
    { value: "Newly listed", name: "Newly listed" },
    { value: "endingAt", name: "Offer ending date" },
    { value: "ReceiveAmountInWei", name: "Price" },
    { value: "avg5", name: "L5 average" },
    { value: "SDRatio5", name: "price/Average L5" },
    { value: "avg20", name: "L15 average" },
    { value: "SDRation20", name: "price/Average L15" },
    { value: "diff", name: "average card price difference" },
  ];

  let sortingOptions = [];
  if (cardMode) {
    if (onSale) {
      sortingOptions = CARDS_SORTING_OPTIONS.concat({
        value: "current_price",
        name: "Sort by selling price",
      });
      if (sport === sorareBasketball)
        sortingOptions = BASKETBALL_CARDS_SORTING_OPTIONS.concat({
          value: "current_price",
          name: "Sort by selling price",
        });
    } else {
      sortingOptions = CARDS_SORTING_OPTIONS;
      if (sport === sorareBasketball) sortingOptions = BASKETBALL_CARDS_SORTING_OPTIONS;
    }
  }
  if (playerMode) {
    sortingOptions = PLAYER_SORTING_OPTIONS;
    if (sport === sorareBasketball) sortingOptions = BASKETBALL_PLAYER_SORTING_OPTIONS;
  }
  if (offerMode) {
    sortingOptions = OFFERS_SORTING_OPTIONS;
  }

  const vertical = props.vertical;

  if (hidden) {
    return null;
  } else if (vertical) {
    return (
      <div>
        <Onboarding
          page={"liveMarket"}
          index={onboarding.index}
          name={onboarding.name}
          steps={onboarding.steps}
          onNext={nextOnboarding}
          onEnd={endOnboarding}
          onSkip={endOnboarding}
        />
        <div className={"relative flex items-stretch flex-grow w-96 mb-4"}>
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FormSearch className="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            type="text"
            onChange={(event) => setSmartSearchValue(event.target.value)}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onBlur={(e) => {
              e.stopPropagation();
            }}
            value={smartSearchValue}
            className={"smart-search-live-market rounded-lg block w-full pl-10 text-sm border-0 focus:ring-0"}
            placeholder="Smart search the market"
          />
          {smartSearchValue && (
            <div
              className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
              onClick={() => {
                setSmartSearchValue("");
                setDebounceSearchQuery("");
                filter(true, " ");
              }}
            >
              <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
            </div>
          )}
        </div>
        <div className={"mb-4"}>
          <div>
            {props.user.sorareSlug && (
              <div className={"grid grid-cols-5 gap-2 mb-2"}>
                <div className={`w-full ${filterFocused === "sport" ? "z-500" : ""}`}>
                  <SelectSearch
                    closeOnSelect={true}
                    options={allSportsOptions}
                    value={sport}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    onChange={(v) => setSport(v)}
                    {...focusBlurHandler("sport")}
                  />
                </div>
                <div>
                  <WatchlistMultiplePicker
                    types={["player"]}
                    fetchOnMount={true}
                    options={{
                      pinListFirst: true,
                      defaultListFirst: true,
                      followedListFirst: true,
                      ownedListFirst: true,
                    }}
                    sports={sport === "all" ? [] : [sport]}
                    size={"100%"}
                    placeholder={"Filter by watchlist(s)"}
                    selected={watchlists}
                    onSelect={(w) => {
                      let newWatchlists = { ...watchlists };
                      if (watchlists[w.id]) {
                        delete newWatchlists[w.id];
                      } else {
                        newWatchlists[w.id] = w;
                      }
                      setWatchlists(newWatchlists);
                    }}
                  />
                </div>
                {(sport === sorareFootball || sport === "all") && (
                  <div className={`col-span-3 ${filterFocused === "filters" ? "z-500" : ""}`}>
                    <SaveFilters
                      reset={resetFilters}
                      scarcity={scarcities}
                      positions={positions}
                      leagues={so5League}
                      eligibilityCompetitions={eligibilityCompetitions}
                      clubs={teamFilter}
                      ageRange={ageRange}
                      u23={u23Eligible}
                      currentPrice={currentPriceOptions.enabled}
                      currentPriceRange={[
                        parseFloat(currentPriceOptions.min.toString().replace(",", ".")),
                        parseFloat(currentPriceOptions.max.toString().replace(",", ".")),
                      ]}
                      avg={mostRecentOptions.enabled}
                      avgRange={[
                        parseFloat(mostRecentOptions.min.toString().replace(",", ".")),
                        parseFloat(mostRecentOptions.max.toString().replace(",", ".")),
                      ]}
                      bmp={currentFloorOptions.enabled}
                      bmpRange={[
                        parseFloat(currentFloorOptions.min.toString().replace(",", ".")),
                        parseFloat(currentFloorOptions.max.toString().replace(",", ".")),
                      ]}
                      underBmp={underBmp}
                      underAvg={underAvg}
                      so5={l5Filter.enabled}
                      so5Filters={l5Filter}
                      so15={l15Filter.enabled}
                      so15Filters={l15Filter}
                      so40={l40Filter.enabled}
                      so40Filters={l40Filter}
                      playerStatus={playingStatus[0]}
                      watchlists={Object.keys(watchlists)}
                      currency={"eth"}
                      updateFilters={updateFilters}
                      twoLines={true}
                      filterMenu={false}
                      {...focusBlurHandler("filters")}
                    />
                  </div>
                )}
              </div>
            )}

            <div className={"space-y-2"}>
              <div className={"md:grid md:grid-cols-2 md:gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 space-y-1 md:space-y-0"}>
                <div className={filterFocused === "scarcity" ? "z-500" : ""}>
                  <SelectSearch
                    multiple
                    closeOnSelect={false}
                    options={scarcities_objects_not_all_and_cs}
                    value={scarcities}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one scarcity or more"
                    onChange={(v) => setScarcities(v)}
                    {...focusBlurHandler("scarcity")}
                  />
                </div>
                <div className={filterFocused === "position" ? "z-500" : ""}>
                  <SelectSearch
                    multiple
                    closeOnSelect={false}
                    options={getPositionsBasedOnSport(sport)}
                    value={positions}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one position or more"
                    onChange={(v) => setPositions(v)}
                    {...focusBlurHandler("position")}
                  />
                </div>
                {sport !== sorareBaseball && sport !== sorareBasketball && (
                  <div className={filterFocused === "eligibility-filter" ? "z-500" : ""}>
                    <EligibilitySorareCompetitionsFilter
                      minWidth={"min-w-[6rem]"}
                      displayOptions={{ shadow: "shadow" }}
                      width={"w-full"}
                      selected={eligibilityCompetitions}
                      onSelect={(v) => {
                        let newLeagues = {};
                        if (v === allStarDefaultCompetition.id) {
                          newLeagues = initialEligibilityCompetitionValue;
                        } else {
                          newLeagues = { ...eligibilityCompetitions, [v]: true };
                          delete newLeagues[allStarDefaultCompetition.id]; // makes no sense to have the 'all' selected while others specific ones are selected
                        }
                        setEligibilityCompetitions(newLeagues);
                      }}
                      onUnselect={(v) => {
                        const newLeagues = { ...eligibilityCompetitions };
                        delete newLeagues[v];
                        if (Object.keys(newLeagues).length === 0) {
                          newLeagues[allStarDefaultCompetition.id] = true;
                        }
                        setEligibilityCompetitions(newLeagues);
                      }}
                      {...focusBlurHandler("eligibility-filter")}
                    />
                  </div>
                )}
                {sport !== sorareBaseball && sport !== sorareBasketball && (
                  <div className={filterFocused === "league" ? "z-500" : ""}>
                    <SelectSearch
                      options={leagues_no_div_objects_not_all}
                      className={"select-search border z-20 border-grey-f2 rounded"}
                      value={so5League}
                      multiple={true}
                      closeOnSelect={false}
                      onChange={(v) => {
                        setSO5League(v);
                      }}
                      filterOptions={fuzzySearch}
                      placeholder="Filter by league"
                      printOptions={"on-focus"}
                      {...focusBlurHandler("league")}
                    />
                  </div>
                )}
                <div className={filterFocused === "club" ? "z-500" : ""}>
                  <SelectSearch
                    options={
                      sport === sorareBasketball
                        ? not_all_basketball_clubs_objects
                        : sport === sorareBaseball
                        ? not_all_baseball_clubs_objects
                        : teams
                    }
                    onChange={(v) => setTeamFilter(v)}
                    search
                    multiple
                    value={teamFilter}
                    closeOnSelect={false}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one club or more"
                    {...focusBlurHandler("club")}
                  />
                </div>
                {sport !== sorareBaseball && sport !== sorareBasketball && offerMode && (
                  <CardSplitDropdown seasonality={seasonality} onChange={(v) => setSeasonality(v)} tier={props.user.tier} />
                )}
                {offerMode && (
                  <SelectSearch
                    className={"select-search border border-grey-f2 rounded"}
                    options={seasons_objects_int}
                    printOptions={"on-focus"}
                    filterOptions={fuzzySearch}
                    placeholder="Select one season or more"
                    multiple={true}
                    value={seasons}
                    closeOnSelect={false}
                    onChange={(v) => setSeasons(v)}
                  />
                )}
                <AgeRange
                  range={ageRange}
                  setU23={() => setU23Eligible(!u23Eligible)}
                  u23={u23Eligible}
                  setRange={(ar) => setAgeRange(ar)}
                />
                <PlayerStatusSelector
                  className={"select-search border border-grey-f2 z-30 rounded"}
                  tier={props.user.tier}
                  playingStatus={playingStatus}
                  multiple={SearchMode}
                  onChange={(v) => {
                    if (marketMode) {
                      setPlayingStatus([v]);
                    } else {
                      setPlayingStatus(v);
                    }
                  }}
                  sport={sport}
                />
                <AvailabilityStatusSelector
                  className={"select-search border border-grey-f2 rounded"}
                  tier={props.user.tier}
                  availabilityStatus={availabilityStatus}
                  multiple={true}
                  onChange={(v) => {
                    setAvailabilityStatus(v);
                  }}
                  sport={sport}
                />
                <PriceFilter
                  current={currentPriceOptions.enabled}
                  currentRange={[currentPriceOptions.min, currentPriceOptions.max]}
                  bmp={currentFloorOptions.enabled}
                  bmpRange={[currentFloorOptions.min, currentFloorOptions.max]}
                  avg={mostRecentOptions.enabled}
                  avgRange={[mostRecentOptions.min, mostRecentOptions.max]}
                  underBmp={underBmp}
                  underAvg={underAvg}
                  showCur={true}
                  sport={sport}
                  tier={props.user.tier}
                  type={props.mode}
                  jerseyMint={jerseyMint}
                  oneSerialNumber={oneSerialNumber}
                  updateFilters={(c, cr, bmp, bmpr, avg, avgr, ubmp, uavg, jm, osn) => {
                    setCurrentFloorOptions({
                      enabled: bmp,
                      min: bmpr[0],
                      max: bmpr[1],
                    });
                    setCurrentPriceOptions({
                      enabled: c,
                      min: cr[0],
                      max: cr[1],
                    });
                    setUnderBmp(ubmp);
                    setUnderAvg(uavg);
                    setMostRecentOptions({
                      enabled: avg,
                      min: avgr[0],
                      max: avgr[1],
                    });
                    setJerseyMint(jm);
                    setOneSerialNumber(osn);
                  }}
                />
                <ScoreFilter
                  l5={l5Filter}
                  l15={l15Filter}
                  l40={l40Filter}
                  sport={sport}
                  setScoreFilters={(f) => {
                    setL5Filter(f.l5);
                    setL15Filter(f.l15);
                    setL40Filter(f.l40);
                  }}
                />
                {sport !== sorareBaseball && (
                  <GameweekFilter
                    filterByGw={filterByGw}
                    onChangeFilterByGw={() => setFilterByGw(!filterByGw)}
                    gameweeks={gameweeks}
                    gw={gw}
                    onChangeGw={(v) => setGw(v)}
                  />
                )}
              </div>
              <div className={"relative flex flex-row justify-end"}>
                <Button label={"Filter"} onClick={() => filter(true)} />
                <div className="absolute top-0 -right-10 transform translate-y-1/4">{loading && <Spinner />}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"bg-white rounded p-4 space-y-3"}>
        <div className={"space-y-3"}>
          <div className={"flex flex-row justify-between"}>
            <p className={"text-base font-semibold"}>Filters</p>
            {loading && <Spinner className={"h-5 w-5"} />}
          </div>
          <div className={"flex flex-row justify-center"}>
            <SportsPicker sport={sport} change={(s) => setSport(s)} />
          </div>
        </div>
        <div>
          {props.user.sorareSlug && sport !== sorareBaseball && (
            <div className={"my-2"}>
              <SaveFilters
                reset={resetFilters}
                scarcity={scarcities}
                positions={positions}
                leagues={so5League}
                eligibilityCompetitions={eligibilityCompetitions}
                clubs={teamFilter}
                ageRange={ageRange}
                u23={u23Eligible}
                currentPrice={currentPriceOptions.enabled}
                currentPriceRange={[
                  parseFloat(currentPriceOptions.min.toString().replace(",", ".")),
                  parseFloat(currentPriceOptions.max.toString().replace(",", ".")),
                ]}
                avg={mostRecentOptions.enabled}
                avgRange={[
                  parseFloat(mostRecentOptions.min.toString().replace(",", ".")),
                  parseFloat(mostRecentOptions.max.toString().replace(",", ".")),
                ]}
                bmp={currentFloorOptions.enabled}
                bmpRange={[
                  parseFloat(currentFloorOptions.min.toString().replace(",", ".")),
                  parseFloat(currentFloorOptions.max.toString().replace(",", ".")),
                ]}
                underBmp={underBmp}
                underAvg={underAvg}
                so5={l5Filter.enabled}
                so5Filters={l5Filter}
                so15={l15Filter.enabled}
                so15Filters={l15Filter}
                so40={l40Filter.enabled}
                so40Filters={l40Filter}
                playerStatus={playingStatus[0]}
                watchlists={Object.keys(watchlists)}
                currency={"eth"}
                updateFilters={updateFilters}
                twoLines={true}
                filterMenu={true}
              />
            </div>
          )}
          <FilterMenu
            icon={GridIcon}
            label={"Players"}
            content={
              <div className={"space-y-3"}>
                <WatchlistMultiplePicker
                  types={["player"]}
                  fetchOnMount={true}
                  options={{
                    pinListFirst: true,
                    defaultListFirst: true,
                    followedListFirst: true,
                    ownedListFirst: true,
                  }}
                  sports={sport === "all" ? [] : [sport]}
                  size={"100%"}
                  placeholder={"Filter by watchlist(s)"}
                  selected={watchlists}
                  onSelect={(w) => {
                    let newWatchlists = { ...watchlists };
                    if (watchlists[w.id]) {
                      delete newWatchlists[w.id];
                    } else {
                      newWatchlists[w.id] = w;
                    }
                    setWatchlists(newWatchlists);
                  }}
                />
                {sport === sorareFootball && (
                  <GameweekFilter
                    filterByGw={filterByGw}
                    onChangeFilterByGw={() => setFilterByGw(!filterByGw)}
                    gameweeks={gameweeks}
                    gw={gw}
                    onChangeGw={(v) => setGw(v)}
                  />
                )}
                {SearchMode && (
                  <div>
                    <input
                      className="focus:outline-none focus:ring-0 focus:border-brand block w-full py-2 rounded-md pl-4 sm:text-sm border-grey-f2"
                      type={"text"}
                      placeholder={"Filter by player name"}
                      value={filterName}
                      onChange={(v) => setFilterName(v.target.value)}
                    />
                  </div>
                )}
                {playerMode && (
                  <div>
                    <input
                      className="focus:outline-none focus:ring-0 focus:border-brand block w-full py-2 rounded-md pl-4 sm:text-sm border-grey-f2"
                      type={"text"}
                      placeholder={"Filter by card owner"}
                      value={filterOwner}
                      onChange={(v) => {
                        setFilterOwner(v.target.value);
                      }}
                    />
                  </div>
                )}

                <div className={"z-50"}>
                  <PositionPicker
                    sport={sport}
                    positions={positions}
                    changePositions={(p) => {
                      setPositions(p);
                    }}
                  />
                </div>
                <PlayerStatusSelector
                  className={"select-search border border-grey-f2 z-50 rounded"}
                  tier={props.user.tier}
                  playingStatus={playingStatus}
                  multiple={SearchMode}
                  onChange={(v) => {
                    if (marketMode) {
                      setPlayingStatus([v]);
                    } else {
                      setPlayingStatus(v);
                    }
                  }}
                  sport={sport}
                />
                {SearchMode && (
                  <AvailabilityStatusSelector
                    className={"select-search border border-grey-f2 z-40 rounded"}
                    tier={props.user.tier}
                    availabilityStatus={availabilityStatus}
                    multiple={true}
                    onChange={(v) => {
                      setAvailabilityStatus(v);
                    }}
                    sport={sport}
                  />
                )}
                {sport !== sorareBaseball && sport !== sorareBasketball && (
                  <div className={"z-35"}>
                    <EligibilitySorareCompetitionsFilter
                      minWidth={"min-w-[6rem]"}
                      displayOptions={{ shadow: "shadow" }}
                      width={"w-full"}
                      zIndex={"z-35"}
                      selected={eligibilityCompetitions}
                      onSelect={(v) => {
                        let newLeagues = {};
                        if (v === allStarDefaultCompetition.id) {
                          newLeagues = initialEligibilityCompetitionValue;
                        } else {
                          newLeagues = { ...eligibilityCompetitions, [v]: true };
                          delete newLeagues[allStarDefaultCompetition.id]; // makes no sense to have the 'all' selected while others specific ones are selected
                        }
                        setEligibilityCompetitions(newLeagues);
                      }}
                      onUnselect={(v) => {
                        const newLeagues = { ...eligibilityCompetitions };
                        delete newLeagues[v];
                        if (Object.keys(newLeagues).length === 0) {
                          newLeagues[allStarDefaultCompetition.id] = true;
                        }
                        setEligibilityCompetitions(newLeagues);
                      }}
                    />
                  </div>
                )}
                {sport !== sorareBaseball && sport !== sorareBasketball && (
                  <div>
                    <SelectSearch
                      options={leagues_no_div_objects_not_all}
                      className={"select-search border z-30 border-grey-f2 rounded"}
                      value={so5League}
                      multiple={true}
                      closeOnSelect={false}
                      onChange={(v) => {
                        setSO5League(v);
                      }}
                      filterOptions={fuzzySearch}
                      placeholder="Filter by league"
                      printOptions={"on-focus"}
                    />
                  </div>
                )}
                <div>
                  <SelectSearch
                    options={
                      sport === sorareBasketball ? basketball_clubs_objects : sport === sorareBaseball ? baseball_clubs_objects : teams
                    }
                    className={"select-search z-20 border border-grey-f2 rounded"}
                    value={teamFilter}
                    multiple={true}
                    closeOnSelect={false}
                    onChange={(v) => {
                      setTeamFilter(v);
                    }}
                    search={true}
                    filterOptions={fuzzySearch}
                    placeholder="Filter by club"
                    printOptions={"on-focus"}
                  />
                </div>
                <div className={"space-y-3"}>
                  <p className={"text-sm font-semibold"}>Age</p>
                  <div className={"space-y-1 px-2"}>
                    <Range
                      trackStyle={[{ backgroundColor: "#f45206" }]}
                      handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                      step={1}
                      value={ageRange}
                      min={13}
                      max={u23Eligible ? 24 : 59}
                      allowCross={false}
                      onChange={onSliderChange}
                    />
                    <div className={"justify-between flex flex-row text-sm font-semibold"}>
                      <span>{ageRange[0]}</span>
                      <span>{ageRange[1]}</span>
                    </div>
                  </div>
                  <div className={"px-2"}>
                    <ToggleWithLabel isActive={u23Eligible} setActive={() => setU23Eligible(!u23Eligible)} label={"U23 eligible"} />
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div>
          <FilterMenu
            icon={ScoresIcon}
            label={"Scores"}
            content={
              <div className={"space-y-2"}>
                <ScoreFilterBox
                  sport={sport}
                  gms={sport === sorareBasketball ? 10 : 5}
                  stats={l5Filter}
                  change={(s) => setL5Filter(s)}
                  disabled={!t1OrAbove(props.user.tier) && sport === sorareFootball}
                  light={marketMode}
                  disabledTip={!t1OrAbove(props.user.tier) ? "Star membership is needed" : ""}
                />
                <ScoreFilterBox
                  sport={sport}
                  gms={sport === sorareBasketball ? 40 : 15}
                  stats={l15Filter}
                  change={(s) => setL15Filter(s)}
                  disabled={!t1OrAbove(props.user.tier) && sport === sorareFootball}
                  light={marketMode}
                  disabledTip={!t1OrAbove(props.user.tier) ? "Star membership is needed" : ""}
                />
                <ScoreFilterBox
                  sport={sport}
                  gms={sport === sorareBasketball ? "Season" : 40}
                  stats={l40Filter}
                  change={(s) => setL40Filter(s)}
                  disabled={!t1OrAbove(props.user.tier) && sport === sorareFootball}
                  light={marketMode}
                  disabledTip={!t1OrAbove(props.user.tier) ? "Star membership is needed" : ""}
                />
              </div>
            }
          />
        </div>
        <div>
          <FilterMenu
            icon={CardsIcon}
            label={"Cards"}
            content={
              <div className={"space-y-3"}>
                {SearchMode && (
                  <div className={"border border-grey-f2 rounded shadow-sm"}>
                    <ScarcityPicker customSeries={true} scarcity={scarcity} onChange={(s) => setScarcity(s)} />
                  </div>
                )}
                {marketMode && (
                  <div className={"border border-grey-f2 rounded shadow-sm"}>
                    <SelectSearch
                      multiple
                      closeOnSelect={false}
                      options={scarcities_objects_not_all_and_cs}
                      value={scarcities}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      placeholder="Select one scarcity or more"
                      onChange={(v) => setScarcities(v)}
                    />
                  </div>
                )}
                {/* {marketMode &&
                        <MultiScarcityPicker scarcity={scarcities} onChange={(s) => setScarcities(s)}/>
                    } */}

                {cardMode && sport !== sorareBasketball && (
                  <div>
                    <PositionPicker
                      sport={sport}
                      positions={cardPositions}
                      changePositions={(p) => {
                        setCardPositions(p);
                      }}
                    />
                  </div>
                )}
                {cardMode && (
                  <div className={"z-50"}>
                    <SelectSearch
                      className={"select-search border border-grey-f2 rounded"}
                      options={seasons_objects_int}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      placeholder="Select one season or more"
                      multiple={true}
                      value={seasons}
                      closeOnSelect={false}
                      onChange={(v) => setSeasons(v)}
                    />
                  </div>
                )}
                {cardMode && (
                  <div className={"z-50"}>
                    <SelectSearch
                      className={"select-search border border-grey-f2 rounded"}
                      options={sell_status_objects}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                      placeholder="Select one card status or more"
                      multiple={true}
                      value={status}
                      closeOnSelect={false}
                      onChange={(v) => setStatus(v)}
                    />
                  </div>
                )}
                {cardMode && sport === sorareFootball && (
                  <div className={"z-40"}>
                    <SelectSearch
                      className={"select-search border border-grey-f2 rounded"}
                      value={editions}
                      multiple={true}
                      closeOnSelect={false}
                      printOptions={"on-focus"}
                      placeholder={"Filter with one or more editions"}
                      onChange={(v) => {
                        setEditions(v);
                      }}
                      options={card_editions_objects}
                    />
                  </div>
                )}
                {cardMode && (
                  <FilterPriceBox
                    label={"Filter by card serial number"}
                    obj={serialNumberOptions}
                    sport={sport}
                    change={(c) => setSerialNumberOptions(c)}
                  />
                )}
                {cardMode && (
                  <FilterPriceBox label={"Filter by card power"} obj={powerRange} sport={sport} change={(c) => setPowerRange(c)} />
                )}
                {cardMode && (
                  <div>
                    <input
                      className="focus:outline-none focus:ring-0 focus:border-brand block w-full py-2 rounded-md pl-4 sm:text-sm border-grey-f2"
                      type={"text"}
                      placeholder={"Filter by card owner"}
                      value={filterOwner}
                      onChange={(v) => setFilterOwner(v.target.value)}
                    />
                  </div>
                )}
                {SearchMode && (
                  <div className={"border border-grey-f2 rounded shadow-sm"}>
                    <CurrencyPicker currency={currency} onChange={(c) => setCurrency(c)} />
                  </div>
                )}
                <FilterPriceBox
                  label={marketMode ? "Filter by floor price Ξ" : "Filter by floor price"}
                  obj={currentFloorOptions}
                  sport={sport}
                  change={(c) => setCurrentFloorOptions(c)}
                  tier={props.user.tier}
                />
                <FilterPriceBox
                  label={marketMode ? "Filter by most recent average Ξ" : "Filter by most recent average"}
                  obj={mostRecentOptions}
                  sport={sport}
                  change={(c) => setMostRecentOptions(c)}
                  tier={props.user.tier}
                />
                {(cardMode || marketMode) && (
                  <FilterPriceBox
                    label={marketMode ? "Filter by selling price Ξ" : "Filter by selling price"}
                    obj={currentPriceOptions}
                    sport={sport}
                    change={(c) => {
                      if (c.enabled === true && cardMode) {
                        if (onSale === false) {
                          setOnSale(true);
                        }
                      }
                      setCurrentPriceOptions(c);
                    }}
                  />
                )}
                {cardMode && (
                  <div className={"border border-grey-f2 space-y-2 py-2"}>
                    <div className={""}>
                      <BoxWithToggle label={"Card is on sale"} isActive={onSale} setActive={() => setOnSale(!onSale)} />
                    </div>
                  </div>
                )}
                {cardMode && (
                  <div className={"border border-grey-f2 space-y-2 py-2"}>
                    <div className={""}>
                      <BoxWithToggle
                        label={"Current price is floor"}
                        isActive={isFloor}
                        setActive={() => {
                          if (!isFloor === true) {
                            if (onSale === false) {
                              setOnSale(true);
                            }
                          }
                          setIsFloor(!isFloor);
                        }}
                        disabled={props.user.tier && !t1OrAbove(props.user.tier) && sport !== sorareBaseball}
                        disabledTip={props.user.tier && !t1OrAbove(props.user.tier) ? "Star membership is needed" : ""}
                      />
                    </div>
                  </div>
                )}
                {marketMode && (
                  <div className={"border border-grey-f2 space-y-2 py-2"}>
                    <div className={""}>
                      <BoxWithToggle
                        label={"Under floor price"}
                        isActive={underBmp}
                        setActive={() => {
                          setUnderBmp(!underBmp);
                        }}
                        disabled={props.user.tier && !t1OrAbove(props.user.tier) && sport !== sorareBaseball}
                        disabledTip={props.user.tier && !t1OrAbove(props.user.tier) ? "Star membership is needed" : ""}
                      />
                    </div>
                  </div>
                )}
                {marketMode && (
                  <div className={"border border-grey-f2 space-y-2 py-2"}>
                    <div className={""}>
                      <BoxWithToggle
                        label={"Under most recent average"}
                        isActive={underAvg}
                        setActive={() => {
                          setUnderAvg(!underAvg);
                        }}
                        disabled={props.user.tier && !t1OrAbove(props.user.tier) && sport !== sorareBaseball}
                        disabledTip={props.user.tier && !t1OrAbove(props.user.tier) ? "Star membership is needed" : ""}
                      />
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </div>
        {(SearchMode || offerMode) && (
          <div className={"flex flex-row space-x-2"}>
            <div className={"w-7/12 z-10"}>
              <SelectSearch
                options={sortingOptions}
                printOptions={"auto"}
                className={"select-search container z-10 border border-grey-f2 rounded"}
                value={sortOption}
                onChange={(v) => {
                  setSortOption(v);
                }}
              />
            </div>
            <div className={"w-5/12 z-10"}>
              <SelectSearch
                options={DESC_ASC}
                className={"select-search border border-grey-f2 rounded"}
                value={sortOrder}
                onChange={(v) => {
                  setSortOrder(v);
                }}
              />
            </div>
          </div>
        )}
        <div className={"relative flex flex-row justify-center"}>
          <Button label={"Filter"} onClick={() => filter(true)} />
        </div>
      </div>
    );
  }
}

export default withUser(SearchFilters);

export { FilterMenu };
