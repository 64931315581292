import React, { useCallback, useEffect, useRef, useState } from "react";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { formatNumber } from "../util/formatNumber";
import { toFixedIfNecessary } from "../util/formatNumber";
import { ProjectionRatioIndicator } from "./gamesList";
import { CardReward } from "../decision/prizePool";
import ordinal_suffix_of from "../util/ordinalNumber";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as IconCircle } from "../../img/icons-circle-info-no-color.svg";
import { ReactComponent as IconCertified } from "../../img/icons-certified.svg";
import RankLabel from "../util/rankLabel";
import { ReactTooltip } from "../util/tooltip.js";
import { useQuery } from "../util/useQuery";
import ArrowLeftIcon from "../../img/arrow-left-icon.svg";
import { useHistory } from "react-router-dom";
import Spinner from "../loader/spinner";
import { LineupCompetitionRules, LineupProjectedLineupsVideo } from "../util/lineupPredictionModal";
import PopperPortal from "../players/helpers/popper";
import { RewardsBox } from "../util/rewardsBox";
import { Tag } from "../util/tag";
import { useElementSize } from "usehooks-ts";

function ProjectionLeaderboardPage(props) {
  const query = useQuery();
  const sorareUserId = query.get("sorareId") || props.sorareId || "";
  return (
    <div className={"w-full h-full bg-black-14"}>
      <div className={`lg:mx-20 mx-4 md:mx-12 xl:w-10/12 2xl:w-10/12 3xl:w-8/12 4xl:w-6/12 2xl:mx-auto pb-8 py-8`}>
        <ProjectionLeaderboardComponent sorareId={sorareUserId} />
      </div>
    </div>
  );
}

const ProjectionLeaderboardComponent = withUser((props) => {
  const [leaderboard, setLeaderboard] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedRegionOrLeague, setSelectedRegionOrLeague] = useState("");

  const [userResultsForSeason, setUserResultsForSeason] = useState(undefined);
  const [loadingUserResults, setLoadingUserResults] = useState(false);

  const [loadingUserResultsForRegionOrLeague, setLoadingUserResultsForRegionOrLeague] = useState(false);
  const [userResultsForRegionOrLeague, setUserResultsForRegionOrLeague] = useState(undefined);
  const history = useHistory();

  const getLeaderboard = (userId) => {
    setLoading(true);
    const newParams = new URLSearchParams();
    if (userId) {
      newParams.append("sorareId", userId);
    }
    props
      .fetch("/projections-api/SO5/projectionUserLeaderboardSummary?" + newParams.toString())
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setLeaderboard(res);
          res && res?.results?.length > 0 && setSelectedSeason(res.results[0].id);
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const getSeasonUserResults = (userId, season) => {
    if (season) {
      setLoadingUserResults(true);
      const newParams = new URLSearchParams();
      if (userId) {
        newParams.append("sorareId", userId);
      }
      newParams.append("season", season);
      props
        .fetch("/projections-api/SO5/projectionUserLeaderboardForSeason?" + newParams.toString())
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setUserResultsForSeason(res);
            const newSelectedRegionOrLeague =
              selectedRegionOrLeague === "" && res && res?.summary?.rankings?.length > 0
                ? res.summary.rankings[0].id
                : selectedRegionOrLeague;
            setSelectedRegionOrLeague(newSelectedRegionOrLeague);
            getSeasonUserResultsForRegionOrLeague(props.sorareId, selectedSeason, newSelectedRegionOrLeague);
          }
          setLoadingUserResults(false);
        })
        .catch(
          errorCatcher(() => {
            setLoadingUserResults(false);
          }),
        );
    }
  };

  const getSeasonUserResultsForRegionOrLeague = (userId, season, regionOrLeague) => {
    if (season && regionOrLeague) {
      setLoadingUserResultsForRegionOrLeague(true);
      const newParams = new URLSearchParams();
      if (userId) {
        newParams.append("sorareId", userId);
      }
      newParams.append("season", season);
      newParams.append("competition", regionOrLeague);
      props
        .fetch("/projections-api/SO5/projectionLeaderboard?" + newParams.toString())
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setUserResultsForRegionOrLeague(res);
          }
          setLoadingUserResultsForRegionOrLeague(false);
        })
        .catch(
          errorCatcher(() => {
            setLoadingUserResultsForRegionOrLeague(false);
          }),
        );
    }
  };

  const selectRegionOrLeague = (regionOrLeague) => {
    setSelectedRegionOrLeague(regionOrLeague);
    getSeasonUserResultsForRegionOrLeague(props.sorareId, selectedSeason, regionOrLeague);
  };

  useEffect(() => {
    getLeaderboard(props.sorareId);
  }, [props.sorareId]);

  useEffect(() => {
    getSeasonUserResults(props.sorareId, selectedSeason);
  }, [selectedSeason]);

  const user = leaderboard?.user || {};
  const summary = leaderboard?.summary;
  const results = leaderboard?.results;
  const initialLoading = loading && summary === undefined;

  const participationPct = toFixedIfNecessary((summary ? summary.totalProjections / summary.totalPossibleProjections : 0) * 100, 2) + "%";
  const accuracyPct =
    toFixedIfNecessary((summary ? summary.totalGoodStarters / (summary.totalGoodStarters + summary.totalBadStarters || 1) : 0) * 100, 2) +
    "%";
  let resultsGroupedByRegion = userResultsForSeason === undefined ? undefined : {};
  userResultsForSeason?.summary?.rankings?.forEach((ranking) => {
    const region = ranking.group;
    if (region) {
      // it's a subgroup to attach to a region
      if (resultsGroupedByRegion[region] === undefined) {
        resultsGroupedByRegion[region] = {
          region: region,
          rankings: [ranking],
        };
      } else {
        resultsGroupedByRegion[region].rankings.push(ranking);
      }
    } else {
      // it's a region
      if (resultsGroupedByRegion[ranking.id] === undefined) {
        resultsGroupedByRegion[ranking.id] = {
          region: ranking.id,
          results: ranking,
          rankings: [],
        };
      } else {
        resultsGroupedByRegion[ranking.id].results = ranking;
      }
    }
  });
  const selectedRegionOrLeagueObj = selectedRegionOrLeague
    ? userResultsForSeason?.summary?.rankings?.find((r) => r.id === selectedRegionOrLeague)
    : undefined;
  return (
    <div className={"w-full flex flex-col gap-2 bg-black-14"}>
      <div className={"flex flex-row justify-start"}>
        <div
          className={"flex flex-row gap-3 items-center cursor-pointer hover:opacity-80"}
          onClick={() => history.push("/SO5-community-lineups")}
        >
          <span>
            <img className={"h-4 w-4"} src={ArrowLeftIcon} />
          </span>
          <p className={"text-sm text-white font-semibold"}>Back to projections</p>
        </div>
      </div>
      <div className={"flex flex-row justify-between gap-3"}>
        <div className={"flex flex-col gap-3 py-2"}>
          <div className={"flex flex-row gap-3"}>
            <div className={"w-20 h-20 flex flex-col items-center justify-center"}>
              {user.SorareUser?.PictureUrl ? (
                <img src={user.SorareUser?.PictureUrl} className={"h-full w-full object-contain"} />
              ) : (
                <FontAwesomeIcon icon={faUser} className={"self-center animate-pulse-sm"} size={"2x"} color={"#cccccc"} />
              )}
            </div>
            <div className={"flex flex-col justify-between py-3"}>
              <div className={"text-2xl text-grey-e6 font-semibold"}>{user.SorareUser?.Nickname}</div>
              <div className={"flex flex-col gap-1 text-grey-cc"}>
                <p className={"text-md font-semibold"}>{user.SorareUser?.TeamName}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={"flex flex-col gap-1 px-4 bg-black-1f rounded-lg justify-center"}>
          <h4 className={"text-sm font-semibold text-grey-e6 m-0"}>How does it work?</h4>
          <div className={"-my-1"}>
            <LineupCompetitionRules dark />
          </div>
          <div className={"-my-1"}>
            <LineupProjectedLineupsVideo dark />
          </div>
        </div>
      </div>
      <div>
        <h4 className={"font-bold text-sm text-brand-dark"}>Summary</h4>
        <div className={"grid grid-cols-1 grid-rows-6 xs:grid-rows-2 xs:grid-cols-3 lg:grid-rows-1 lg:grid-cols-6 gap-3"}>
          <SummaryBox loading={initialLoading} label={"Total projections"} text={formatNumber(summary?.totalProjections)} />
          <SummaryBox loading={initialLoading} label={"Total participation"} text={participationPct} />
          <SummaryBox loading={initialLoading} label={"Total accuracy"} text={accuracyPct} />
          <SummaryBox loading={initialLoading} label={"Total points"} text={formatNumber(parseFloat(summary?.totalPoints))} />
          <SummaryBox loading={initialLoading} label={"Total wins"}>
            <div className={"flex flex-row gap-0.5 justify-between font-semibold"}>
              <div className={"flex flex-col justify-center"}>
                <p className={"text-xl text-grey-e6 leading-5"}>{summary?.totalWinsRegion}</p>
                <p className={"text-sm text-grey-cc"}>Regions</p>
              </div>
              <div className={"flex flex-col justify-center"}>
                <p className={"text-xl text-grey-e6 leading-5"}>{summary?.totalWinsLeague}</p>
                <p className={"text-sm text-grey-cc"}>Leagues</p>
              </div>
            </div>
          </SummaryBox>
          <SummaryBox loading={initialLoading} label={"Total rewards"} text={"🎁 " + formatNumber(summary?.totalRewards)} />
        </div>
      </div>
      <div className={"flex flex-col"}>
        <h4 className={"font-bold text-sm text-brand-dark"}>Results</h4>
        <div className={"relative"}>
          <div
            className={"absolute w-[20%] right-0 h-full"}
            style={{
              background: "linear-gradient(90deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.9) 100%)",
            }}
          ></div>
          <div className={"flex flex-1 w-full overflow-x-auto"}>
            <div className={"flex flex-row flex-nowrap gap-2 pb-3"}>
              {loading && results === undefined && (
                <>
                  <ResultBox loading={true} />
                  <ResultBox loading={true} />
                  <ResultBox loading={true} />
                </>
              )}
              {results?.map((result, index) => {
                return <ResultBox selected={result.id === selectedSeason} result={result} onSelect={() => setSelectedSeason(result.id)} />;
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={"flex flex-col md:flex-row gap-2 -mt-3"}>
        <div className={"w-80 flex flex-col gap-3"}>
          <ProjectionSeasonRewardBox
            loading={loadingUserResults && userResultsForSeason === undefined}
            rewards={userResultsForSeason?.summary?.rewards}
            eligibleToRaffle={userResultsForSeason?.summary?.eligibleToRaffle}
            greating={userResultsForSeason?.summary?.greatingType}
          />
          <ProjectionSeasonResults
            loading={loadingUserResults && userResultsForSeason === undefined}
            rankings={resultsGroupedByRegion}
            selected={selectedRegionOrLeague}
            otherLeagues={userResultsForSeason?.summary?.otherLeagues}
            onSelect={(regionOrLeague) => selectRegionOrLeague(regionOrLeague)}
          />
        </div>
        <div className={"w-full flex flex-col gap-2"}>
          <div className={"flex flex-row justify-between items-center"}>
            <div className={"w-80 h-10 z-50"}>
              <LeagueAndRegionPicker
                regionsAndLeagues={resultsGroupedByRegion}
                selected={selectedRegionOrLeague}
                onSelect={(regionOrLeague) => selectRegionOrLeague(regionOrLeague)}
              />
            </div>
            <p className={"text-sm font-semibold text-grey-cc"}>
              {selectedRegionOrLeagueObj !== undefined ? `${selectedRegionOrLeagueObj.totalPossibleProjections} projections available` : ""}
            </p>
          </div>
          <div>
            <LeaderboardTable
              loading={loadingUserResultsForRegionOrLeague}
              user={user.SorareUser}
              standingForUser={userResultsForRegionOrLeague?.userStanding}
              standing={userResultsForRegionOrLeague?.standing}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const LeaderboardTableHeaderCell = (props) => {
  const { label, first, last, bold, width } = props;
  return (
    <th
      className={`text-xxs px-1.5 py-3 bg-black-1f overflow-hidden ${width} ${first ? "rounded-tl-xl" : ""} ${
        last ? "rounded-tr-xl" : ""
      } text-center ${bold ? "font-bold" : "font-semibold"} uppercase`}
    >
      {label}
    </th>
  );
};

const LeaderboardTableRow = (props) => {
  const { user, lead, reward, hasRewardablePlace, enhance, isLeagueSpecialist, tableWidth } = props;
  const accuracyPct =
    lead?.goodStarters && lead.goodStarters > 0
      ? `${toFixedIfNecessary((lead.goodStarters / (lead.goodStarters + (lead.badStarters || 0))) * 100, 2)}%`
      : "0%";
  const expectedRewards = reward?.expectedRewards || [];
  const leagueSpecialistText =
    "League Specialist: this manager is one of the best projectors of this league this season; when available, their projections contribute to our high reliability tag";
  return (
    <React.Fragment>
      <ReactTooltip id={"proj-leaderboard-not-eligible-table"} textColor="#1A1A1A" backgroundColor="#F5F5F5" effect="solid" />

      <tr className={"bg-black-1f"}>
        <td className={`${enhance ? "bg-black-33 rounded-l-lg" : "bg-black-1f border-b border-black-4D"}`}>
          <div className={"flex justify-center items-center text-xs px-2 py-3 font-semibold text-grey-cc"}>
            <RankLabel rank={lead.rank} hashMode={true} />
          </div>
        </td>
        <td className={`${enhance ? "bg-black-33" : "bg-black-1f border-b border-black-4D"}`}>
          <div className={"flex flex-row items-center gap-2 px-2 py-3 text-center"}>
            <p className={"text-sm text-grey-e6 font-semibold hover:text-brand-dark max-w-[15em] truncate"} title={user.Nickname}>
              <a href={"/lineupProjections/leaderboard?sorareId=" + (user.SorareId || lead.sorareId)}>{user.Nickname}</a>
            </p>
            {isLeagueSpecialist &&
              (tableWidth >= 845 ? (
                <Tag
                  title={leagueSpecialistText}
                  icon={<IconCertified className={"fill-quality-scale-excellent-on-dim-quality-container-fixed"} />}
                  bg={"bg-quality-scale-excellent-quality-container-dim-fixed"}
                  textColor={"text-quality-scale-excellent-on-dim-quality-container-fixed"}
                  label={"League Specialist"}
                />
              ) : (
                <IconCertified title={leagueSpecialistText} className={"fill-quality-scale-excellent-quality-container-dim-fixed"} />
              ))}
          </div>
        </td>
        <td className={`${enhance ? "bg-black-33" : "bg-black-1f border-b border-black-4D"}`}>
          <div className={"flex flex-row justify-center items-center px-2 py-3"}>
            {expectedRewards.map((reward, index) => {
              return <CardReward dark={true} scarcity={reward.scarcity} tier={reward.tier} key={index} />;
            })}
            {(expectedRewards === null || expectedRewards === undefined || expectedRewards.length === 0) && hasRewardablePlace && (
              <div
                data-html={true}
                data-for={"proj-leaderboard-not-eligible-table"}
                data-tip={
                  "<p class='text-sans font-semibold text-center'>Not eligible:<br/>Manager is expecting to win a better reward in another competition</p>"
                }
              >
                <IconCircle className={"fill-grey-e6"} />
              </div>
            )}
          </div>
        </td>
        <td className={`${enhance ? "bg-black-33" : "bg-black-1f border-b border-black-4D"}`}>
          <div className={"flex flex-row justify-center items-center px-2 py-3 text-center"}>
            <p className={"text-sm text-grey-e6 font-medium "}>{(lead?.bad || 0) + (lead?.good || 0)}</p>
          </div>
        </td>
        <td className={`${enhance ? "bg-black-33" : "bg-black-1f border-b border-black-4D"}`}>
          <div className={"flex flex-row justify-center items-center px-2 py-3 text-center"}>
            <p className={"text-sm text-grey-e6 font-medium"}>{accuracyPct}</p>
          </div>
        </td>
        <td className={`${enhance ? "bg-black-33" : "bg-black-1f border-b border-black-4D"}`}>
          <div className={"flex flex-row justify-center items-center px-2 py-3 text-center"}>
            <BonusDetailsHover bonusDetails={lead.bonusDetails}>
              <p className={"text-sm text-grey-e6 font-medium"}>{lead.bonus || 0}</p>
            </BonusDetailsHover>
          </div>
        </td>
        <td className={`${enhance ? "bg-black-33 rounded-r-lg" : "bg-black-1f border-b border-black-4D"}`}>
          <div className={"flex flex-row justify-center items-center px-2 py-3 text-center"}>
            <p className={`text-sm text-grey-e6 ${enhance ? "font-semibold" : "font-medium"}`}>{toFixedIfNecessary(lead.score || 0, 2)}</p>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

const BonusDetailsHover = (props) => {
  const { children, bonusDetails } = props;
  const [popHover, setPopHover] = useState(false);
  const displayPopHover = () => setPopHover(true);
  const hidePopHover = () => setPopHover(false);
  if (bonusDetails === undefined || bonusDetails === null) {
    return children;
  }
  return (
    <div
      className={"relative flex flex-col gap-1 justify-center self-center cursor-default h-full"}
      onMouseEnter={displayPopHover}
      onMouseLeave={hidePopHover}
    >
      {popHover && (
        <PopperPortal active={popHover}>
          <div
            className={"flex flex-col gap-0 isolate z-30 bg-brand-black rounded-lg shadow-lg text-sm text-grey-cc"}
            style={{ minWidth: "20em" }}
          >
            <h4 className={"text-brand-dark m-0 text-sm px-3 py-3"}>Bonus details</h4>
            <table
              className={"w-full box-border"}
              style={{ height: "1px", borderCollapse: "separate" }}
              border="0"
              cellPadding="0"
              cellSpacing="0"
            >
              <thead className={"text-xs font-semibold"}>
                <tr className={"text-left"}>
                  <th className={`text-xs px-3 py-2`}>Bonus</th>
                  <th className={`text-xs px-3 py-2`}>Occurrence</th>
                  <th className={`text-xs px-3 py-2`}>Points</th>
                </tr>
              </thead>
              <tbody>
                {bonusDetails?.length > 0 ? (
                  bonusDetails.map((bonus, index) => {
                    return (
                      <tr key={index} className={"text-grey-e6"}>
                        <td className={`px-3 py-2 font-semibold ${index > 0 ? "border-t border-black-4D" : ""}`}>{bonus.type}</td>
                        <td className={`px-3 py-2 font-semibold ${index > 0 ? "border-t border-black-4D" : ""}`}>x{bonus.count}</td>
                        <td className={`px-3 py-2 font-semibold ${index > 0 ? "border-t border-black-4D" : ""}`}>{bonus.bonusScore}pts</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3} className={"text-center text-grey-e6 italic text-xs py-3 px-3"}>
                      No bonus
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </PopperPortal>
      )}
      {children}
    </div>
  );
};

const LeaderboardTable = (props) => {
  const { loading, user, standingForUser, standing } = props;
  const [tableContainerRef, { width: tableWidth }] = useElementSize();
  const [displayedStanding, setDisplayedStanding] = useState(standing?.slice(0, 50) || []);

  useEffect(() => {
    if (standing !== undefined) {
      setDisplayedStanding(standing?.slice(0, displayedStanding.length + 50) || []);
    }
  }, [standing]);

  const onDisplayedStandingChange = useCallback(() => {
    if (standing !== undefined) {
      setDisplayedStanding(standing?.slice(0, displayedStanding.length + 50) || []);
    }
  }, [standing, displayedStanding]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (elem) => {
      if (elem) {
        const { scrollHeight, scrollTop, clientHeight } = elem;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        const hasMore = displayedStanding.length < standing.length;
        if (scrollHeight - scrollTop - clientHeight < 100 && hasMore) {
          onDisplayedStandingChange();
        }
      }
    },
    [displayedStanding, standing],
  );

  if (loading && standing === undefined) {
    return (
      <div className={"flex flex-row justify-center p-8"}>
        <Spinner className={"h-8 w-8"} />
      </div>
    );
  }
  if (standingForUser && standingForUser.leaderboardEntry.rank === 0) {
    standingForUser.leaderboardEntry.rank = standing && standing.length > 0 ? standing[standing.length - 1].leaderboardEntry.rank + 1 : 1;
  }
  const userInTop10 = displayedStanding.slice(0, 10).findIndex((lead) => lead.user.SorareId === user.SorareId) !== -1;
  return (
    <div>
      <div
        className={"overflow-y-scroll"}
        style={{ maxHeight: `100vh` }}
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target)}
      >
        <table
          className={"w-full box-border"}
          style={{ height: "1px", borderCollapse: "separate" }}
          border="0"
          cellPadding="0"
          cellSpacing="0"
        >
          <thead className={"text-grey-cc text-xs font-semibold sticky top-0 z-[19]"}>
            <tr className={"py-2"}>
              <LeaderboardTableHeaderCell first width={"max-w-[10%]"} />
              <LeaderboardTableHeaderCell width={"w-fit"} />
              <LeaderboardTableHeaderCell width={"w-[10%]"} label={"Exp. reward"} />
              <LeaderboardTableHeaderCell width={"w-[10%]"} label={"Projections"} />
              <LeaderboardTableHeaderCell width={"w-[10%]"} label={"%Accuracy"} />
              <LeaderboardTableHeaderCell width={"w-[10%]"} label={"Bonuses"} />
              <LeaderboardTableHeaderCell width={"w-[10%]"} label={"Score"} bold last />
            </tr>
          </thead>
          <tbody>
            {standingForUser && displayedStanding.length > 0 && !userInTop10 && (
              <LeaderboardTableRow
                enhance
                user={user}
                lead={standingForUser.leaderboardEntry}
                reward={standingForUser.reward}
                hasRewardablePlace={standingForUser.hasRewardablePlace}
                isLeagueSpecialist={standingForUser.isLeagueSpecialist}
                tableWidth={tableWidth}
              />
            )}
            {displayedStanding.map((entry, i) => {
              const entryUser = entry.user;
              const lead = entry.leaderboardEntry;
              const reward = entry.reward;
              const hasRewardablePlace = entry.hasRewardablePlace;
              const isLeagueSpecialist = entry.isLeagueSpecialist;
              return (
                <LeaderboardTableRow
                  enhance={entryUser.SorareId === user.SorareId}
                  key={i}
                  user={entryUser}
                  lead={lead}
                  reward={reward}
                  hasRewardablePlace={hasRewardablePlace}
                  isLeagueSpecialist={isLeagueSpecialist}
                  tableWidth={tableWidth}
                />
              );
            })}
            {displayedStanding.length === 0 && (
              <tr>
                <td colSpan={7}>
                  <div
                    className={
                      "p-4 bg-black-1f border-t border-black-4D text-sm text-center flex flex-col items-center justify-center text-grey-f5 gap-1"
                    }
                  >
                    <p className={"text-md font-semibold"}>No result</p>
                    <p className={"italic"}>Select another division, region or season</p>
                  </div>{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ProjectionSeasonResults = (props) => {
  const { rankings, loading, selected, onSelect, otherLeagues } = props;
  if (loading && rankings === undefined) {
    return (
      <div className={"flex flex-row justify-center"}>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={"flex flex-col gap-2"}>
      {rankings &&
        Object.entries(rankings).map(([region, value]) => {
          return (
            <ProjectionSeasonResultRegion
              region={value.region}
              results={value.results}
              otherLeagues={otherLeagues}
              rankings={value.rankings}
              selected={selected}
              onSelect={onSelect}
            />
          );
        })}
    </div>
  );
};

const ProjectionSeasonResultRegion = (props) => {
  const { selected, onSelect, results, otherLeagues, region } = props;
  const [hoverRegion, setHoverRegion] = useState(false);
  const [openDetails, setOpenDetails] = useState(results ? results.points > 0 : false);
  const isRegionSelected = results.id === selected;
  const clickableRegion = !isRegionSelected && hoverRegion;
  const hasLeaguesInRegion = props.rankings.length > 0;
  return (
    <div
      className={`bg-black-1f rounded-xl overflow-hidden border ${
        isRegionSelected ? "border-brand-dark" : clickableRegion ? "border-brand-dark cursor-pointer" : "border-black-1f"
      }`}
    >
      <div
        className={`py-2 px-3 ${hasLeaguesInRegion ? "border-b border-black-4D" : ""}`}
        onClick={hoverRegion ? () => onSelect(results.id) : undefined}
        onMouseEnter={() => setHoverRegion(true)}
        onMouseLeave={() => setHoverRegion(false)}
      >
        <ProjectionSeasonResultLeagueContent ranking={results} />
      </div>
      {hasLeaguesInRegion && (
        <div className={"p-2 bg-black-1f flex flex-col gap-2"}>
          <div className={"flex flex-row justify-end"}>
            <div
              className={"flex flex-row gap-1 cursor-pointer hover:opacity-80 items-center"}
              onClick={() => setOpenDetails(!openDetails)}
            >
              <p className={"text-xs text-brand-dark font-semibold"}>League details</p>
              <SortArrowIcon className={`fill-brand-dark h-2.5 w-2.5 ${openDetails ? "transform rotate-180" : ""}`} />
            </div>
          </div>
          {openDetails &&
            props.rankings.map((ranking) => {
              const isSelected = ranking.id === selected;
              const isOther = ranking.id.includes("other");
              const clickable = !isSelected && !isOther;
              const closedLeagues = isOther ? otherLeagues[region]?.closedLeagues?.map((l) => l.displayName) || [] : [];
              const tip = isOther
                ? "All covered games that do not fall under an open League and count in the region" +
                  (closedLeagues?.length > 0 ? ":<br/>" + closedLeagues.join(", ") : "")
                : undefined;
              return (
                <div
                  className={`py-2 px-3 rounded-xl bg-brand-black border ${
                    isSelected
                      ? "border-brand-dark"
                      : clickable
                      ? "hover:border-brand-dark border-brand-black cursor-pointer"
                      : "border-brand-black"
                  }`}
                  onClick={clickable ? () => onSelect(ranking.id) : undefined}
                  data-tip={tip}
                  data-html={true}
                  data-for={"projection-season-result-region"}
                >
                  <ProjectionSeasonResultLeagueContent ranking={ranking} />
                </div>
              );
            })}
        </div>
      )}
      <ReactTooltip id={"projection-season-result-region"} delayShow={200} textColor="#1A1A1A" backgroundColor="#F5F5F5" />
    </div>
  );
};

const ProjectionSeasonResultLeagueContent = (props) => {
  const { ranking } = props;
  const hasRewards = ranking && ranking.rewards?.length > 0;
  const hasRanking = ranking && ranking.totalGood + ranking.totalBad > 0;
  const accuracyPct =
    toFixedIfNecessary((ranking ? ranking.totalGoodStarters / (ranking.totalGoodStarters + ranking.totalBadStarters) : 0) * 100, 0) + "%";
  const hasRewardablePlace = ranking && ranking.hasRewardablePlace;
  return (
    <div className={"flex flex-row gap-1 justify-between items-center"}>
      <ReactTooltip id="not-eligible" textColor="#1A1A1A" backgroundColor="#F5F5F5" effect="solid" />
      <div
        className={`flex flex-col gap-2 ${
          hasRanking && (hasRewards || hasRewardablePlace)
            ? "w-[50%]"
            : hasRanking && !(hasRewards || hasRewardablePlace)
            ? "w-[70%]"
            : "w-full"
        } flex-grow`}
      >
        <h3 className={"m-0 uppercase text-[0.65rem] leading-3 font-medium text-grey-cc"}>{ranking.displayName}</h3>
        <div className={"flex flex-row text-sm gap-1.5"}>
          {ranking.rank > 0 && (
            <div
              className={`pr-1.5 border-r border-grey-e6 font-semibold ${ranking.rewards?.length > 0 ? "text-green-dark" : "text-grey-e6"}`}
            >
              {ordinal_suffix_of(ranking.rank)}
            </div>
          )}
          <div className={"font-semibold text-grey-e6"}>{formatNumber(ranking.points)}pts</div>
        </div>
      </div>
      {(hasRewards || hasRewardablePlace) && (
        <div className={"w-[20%] flex flex-row gap-1"}>
          {ranking.rewards?.map((r, i) => {
            return <CardReward dark={true} key={i} scarcity={r.scarcity} tier={r.tier} />;
          })}
          {(ranking.rewards === null || ranking.rewards === undefined || ranking.rewards.length === 0) && hasRewardablePlace && (
            <div
              className={"flex flex-row justify-center w-full"}
              data-for={"not-eligible"}
              data-html={true}
              data-tip={
                "<p class='text-sans font-semibold text-center'>Not eligible:<br/>You are winning a better reward in another competition</p>"
              }
            >
              <IconCircle className={"fill-grey-e6"} />
            </div>
          )}
        </div>
      )}
      <div className={"w-[30%] flex flex-row justify-end"}>
        <p className={"text-grey-cc text-xxs font-semibold"}>{hasRanking ? `${accuracyPct} accuracy` : ""}</p>
      </div>
    </div>
  );
};

const greatingMessageInfo = {
  no_reward: {
    title: "🍀",
    label: "Better luck next time",
    position: "center",
    bg: "bg-projection-leaderboard-greatings-no-reward",
  },
  good: {
    label: "Good luck! 🤞",
    bg: "bg-projection-leaderboard-greatings-good",
  },
  very_good: {
    label: "You’re good! 🔮",
    bg: "bg-projection-leaderboard-greatings-very-good",
  },
  exceptional: {
    label: "What a month! 🔥",
    bg: "bg-projection-leaderboard-greatings-exceptional",
  },
};

export const ProjectionSeasonRewardBox = (props) => {
  const { loading, rewards, greating, eligibleToRaffle } = props;
  const { title, label, bg } = greating ? greatingMessageInfo[greating] : {};
  return <RewardsBox loading={loading} title={title} label={label} cards={rewards} bg={bg} raffleTicket={eligibleToRaffle} />;
};

const SummaryBox = (props) => {
  const { label, text, loading, children } = props;

  return (
    <div className={"rounded-xl grid grid-cols-1 grid-rows-2 bg-black-1f overflow-hidden h-28 py-3 px-5 w-full"}>
      <p className={"text-grey-cc uppercase font-semibold text-sm lg:text-sm 2xl:text-base flex flex-row items-center"}>{label}</p>
      <div className={"h-10"}>
        {loading ? (
          <div role="status" className="w-full animate-pulse-sm w-36">
            <div className={`h-6 bg-textGrey5 opacity-50 rounded w-full`}></div>
          </div>
        ) : text !== undefined ? (
          <p className={"text-grey-e6 text-2xl lg:text-xl font-semibold font-headers"}>{text}</p>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

const ResultBox = (props) => {
  const { result, selected, onSelect, loading } = props;
  const accuracy =
    result && result?.totalGoodStarters > 0
      ? toFixedIfNecessary((result?.totalGoodStarters / (result?.totalGoodStarters + result?.totalBadStarters)) * 100, 2) + "%"
      : "0%";
  if (loading) {
    return (
      <div
        className={`rounded-xl animate-pulse-sm flex flex-col bg-center bg-no-repeat bg-cover bg-projection-leaderboard-result overflow-hidden w-80 p-5 text-white gap-1 border-3 border-transparent h-40`}
      ></div>
    );
  }
  return (
    <div
      className={`rounded-xl flex flex-col bg-center bg-no-repeat bg-cover bg-projection-leaderboard-result overflow-hidden w-80 text-white gap-1 ${
        selected
          ? "p-[1.125rem] border-2 border-brand-dark"
          : "opacity-40 p-5 cursor-pointer hover:p-[1.125rem] hover:border-2 hover:opacity-90 hover:border-brand-dark"
      }`}
      onClick={!selected ? onSelect : undefined}
    >
      <div className={"flex flex-col justify-evenly"}>
        <div className={"flex flex-row justify-between"}>
          <span className={"text-xs"}>
            GW{result?.gwMin}-{result?.gwMax}
          </span>
          <ProjectionRatioIndicator fontSize="text-xs" voted={result?.projections} eligible={result?.maxPossibleProjections} />
        </div>
        <div>
          <h3 className={"m-0"}>{result?.displayName}</h3>
        </div>
      </div>
      <div className={"flex flex-row gap-1 justify-between"}>
        <div className={"flex items-end"}>
          <SeasonStatus season={result} />
        </div>
        <div className={"flex flex-col justify-evenly items-end gap-1"}>
          <p className={"text-sm font-semibold"}>
            <span className={"text-2xl"}>{toFixedIfNecessary(result?.points, 2)}</span>
            pts
          </p>
          <p className={"text-xs"}>{accuracy} accuracy</p>
        </div>
      </div>
    </div>
  );
};

const SeasonStatus = (props) => {
  const { season } = props;
  const { isCompleted, sentRewards } = season;
  return (
    <div className={"rounded-full py-0.5 px-2 text-xs text-black-33 bg-background-grey-lineup font-semibold"}>
      {!isCompleted ? "Season in progress" : sentRewards ? "Rewards sent" : "Season completed"}
    </div>
  );
};

const LeagueAndRegionPickerSelect = ({ options, selected, onSelect }) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const selectedObj = options.find(({ value }) => selected === value);
  return (
    <div className={"relative w-full"} ref={ref}>
      <button
        onClick={() => setFocus(!focus)}
        className={`h-full text-md my-auto bg-black-1f px-3 py-2 w-full ${
          focus ? "rounded-t-3xl" : "hover:opacity-90 rounded-3xl"
        } flex flex-row justify-between`}
      >
        <span className={"my-auto w-5/6 truncate text-left font-semibold text-grey-e6 leading-5"}>
          {selected ? selectedObj?.name || "Select division or region" : ""}
        </span>
        <div className={"flex flex-row my-auto gap-2"}>
          <div className={"flex flex-col justify-center"}>
            <SortArrowIcon className={`fill-grey-e6 h-3 w-3 ${focus ? "transform rotate-180" : ""}`} />
          </div>
        </div>
      </button>
      {focus && (
        <div className={"absolute bg-black-1f rounded-b-3xl w-full overflow-hidden"}>
          <ul className={"h-full overflow-y-scroll"} style={{ maxHeight: "15em" }}>
            {options.map((option, i) => {
              let color = "text-grey-e6";
              return (
                <li
                  key={i}
                  onClick={() => {
                    setFocus(false);
                    onSelect(option.value);
                  }}
                  className={`${i !== 0 ? "border-t" : ""} border-black-4D ${
                    selected && option.value === selected ? "bg-black-33" : ""
                  } hover:bg-black-33 cursor-pointer`}
                >
                  <div className="w-full h-full px-3 py-2">
                    <button
                      value={option.value}
                      className={`w-full ${option.level === 1 ? "pl-4" : ""} ${color} ${
                        option.count > 0 ? "font-semibold" : ""
                      } cursor-pointer text-left text-sm flex flex-row justify-between`}
                    >
                      <span className={"w-5/6 truncate"}>{option.name}</span>
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

const LeagueAndRegionPicker = (props) => {
  const { regionsAndLeagues, selected, onSelect } = props;

  let leaguesOptions = [];
  if (regionsAndLeagues) {
    Object.entries(regionsAndLeagues).forEach(([regionId, val]) => {
      leaguesOptions.push({
        value: regionId,
        name: val.results.displayName,
        level: 0,
      });
      Object.entries(val.rankings).forEach(([leagueId, league]) => {
        if (!league.id.includes("other")) {
          leaguesOptions.push({
            value: league.id,
            name: league.displayName,
            level: 1,
          });
        }
      });
    });
  }

  return (
    <div className={"flex flex-row gap-2 h-full"}>
      <LeagueAndRegionPickerSelect options={leaguesOptions} selected={selected} onSelect={onSelect} />
    </div>
  );
};

export default withUser(ProjectionLeaderboardPage);
