import React, { useEffect, useState, Fragment } from "react";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";
import ScoreEllipse from "../util/scoreEllipse";
import { so5_positions } from "../util/positions";

function CombinedStatsGraph(props) {
  const [data, setData] = useState(props.data || []);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const MyCustomLayer = (props) => {
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    return (
      <>
        {props.slices.map((slice, i) => (
          <rect
            key={i}
            x={slice.x0}
            y={slice.y0}
            width={slice.width}
            height={slice.height}
            stroke="red"
            strokeWidth={0}
            strokeOpacity={0.75}
            fill="red"
            fillOpacity={0}
            onMouseEnter={() => props.setCurrentSlice(slice)}
            onClick={() => console.log("clicked!")}
            onMouseMove={(event) => {
              showTooltipFromEvent(
                React.createElement(props.sliceTooltip, {
                  slice,
                  axis: props.enableSlices,
                }),
                event,
                "right",
              );
            }}
            onMouseLeave={() => {
              hideTooltip();
              props.setCurrentSlice(null);
            }}
          />
        ))}
      </>
    );
  };

  const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          })),
        )}
        fill={"none"}
        stroke={colorById[id] || colorById.default}
        style={styleById[id] || styleById.default}
      />
    ));
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  //const colors = ["#003f5c", "#bc5090", "#ff6361", "#ffa600", "#f95d6a", "#58508d", "#374c80", "#7a5195", "#d45087", "#ff7c43"]
  const styleById = {
    tier_0: {
      strokeDasharray: "3, 3",
    },
    tier_1: {
      strokeDasharray: "3, 3",
    },
    tier_2: {
      strokeDasharray: "3, 3",
    },
    tier_3: {
      strokeDasharray: "3, 3",
    },
    average: {
      strokeDasharray: "3, 3",
    },
    threshold: {
      strokeDasharray: "3, 3",
    },
    score: {},
    default: {
      strokeWidth: 2,
    },
  };
  let colorById = {
    tier_0: "#e6bd64",
    tier_1: "#e6bd64",
    tier_2: "#e6bd64",
    tier_3: "#e6bd64",
    score: "#056155",
    average: "#a9a9a9",
    default: "black",
  };
  if (props.scarcity() === "rare" || props.scarcity() === "rare_pro") {
    colorById = {
      tier_0: "#d46666",
      tier_1: "#d46666",
      tier_2: "#d46666",
      tier_3: "#d46666",
      score: "#056155",
      average: "#a9a9a9",
      default: "black",
    };
  } else if (props.scarcity() === "super_rare" || props.scarcity() == "super rare") {
    colorById = {
      tier_0: "#54b7ff",
      tier_1: "#54b7ff",
      tier_2: "#54b7ff",
      tier_3: "#54b7ff",
      score: "#056155",
      average: "#a9a9a9",
      default: "black",
    };
  } else if (props.scarcity() === "unique") {
    colorById = {
      tier_0: "#000000",
      tier_1: "#000000",
      tier_2: "#000000",
      tier_3: "#000000",
      score: "#056155",
      average: "#a9a9a9",
      default: "black",
    };
  } else if (props.scarcity() === "mix") {
    colorById = {
      tier_0: "#8D32E4",
      tier_1: "#8D32E4",
      tier_2: "#8D32E4",
      tier_3: "#8D32E4",
      score: "#056155",
      average: "#a9a9a9",
      default: "black",
    };
  }

  let highestTier = "tier_0";
  let lowestTier = "tier_3";

  const filterWithKeys = (d) => {
    return colorById[d.id];
  };

  let height = "h-25rem";
  if (props.height) {
    height = props.height;
  }
  let margin = "my-4";
  if (props.noMargin) {
    margin = "";
  }

  const allGws = data?.find((d) => d.id === "score")?.data?.map((d) => d.x) || []; // list all the gws representing x Axis
  const data2Normalized =
    allGws.length > 0
      ? data?.map((d) => {
          // fill the gap for curves that don't have all the gws
          return {
            id: d.id,
            data: allGws.map((gw, i) => {
              const d2 = d.data?.find((d) => d.x === gw);
              if (!d2) {
                // fill empty points so the graph lib is not lost with points order
                return {
                  x: gw,
                  y: null,
                  player_1: 0,
                  player_2: 0,
                  player_3: 0,
                  player_4: 0,
                  player_5: 0,
                  average: 0,
                  all_played: false,
                  label: false,
                };
              }
              return d2;
            }),
          };
        }) || []
      : data;
  return (
    <div className={"rounded"}>
      <div className={"w-full mx-auto " + margin + " text-sm bg-focus rounded self-end flex " + height}>
        <ResponsiveLine
          data={data2Normalized}
          isInteractive={true}
          animate={false}
          style={{ borderRadius: "12px" }}
          colors={(id) => filterWithKeys(id)}
          margin={{ top: 15, right: 20, bottom: 20, left: 35 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "0",
            max: "auto",
            reverse: false,
          }}
          yFormat=" >-.2f"
          enableSlices={"x"}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (v) => (props.selectedGw === v ? "_" + v + "_" : v),
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          pointSize={6}
          pointLabelYOffset={-5}
          enablePointLabel={true}
          pointLabel={(d) => {
            if (d.label === true) {
              return parseFloat(d.y).toFixed(0);
            }
          }}
          theme={{
            background: "#ffffff",
            textColor: "#333333",
            fontSize: 10,
            fontWeight: "semibold",
            borderRadius: "12px",
          }}
          sliceTooltip={(input) => {
            return <Tooltip input={input} capped={props.capped} keys={props.keys} powers={props.powers} />;
          }}
          enableArea={true}
          curve={"catmullRom"}
          areaOpacity={0.25}
          defs={[
            linearGradientDef("gradientA", [
              { offset: 0, color: "inherit" },
              { offset: 100, color: "inherit", opacity: 0 },
            ]),
            linearGradientDef("gradientTier", [
              { offset: 0, color: "inherit" },
              { offset: 30, color: "inherit", opacity: 0 },
            ]),
            linearGradientDef("gradientB", [
              { offset: 0, color: "white" },
              { offset: 100, color: "white", opacity: 0 },
            ]),
          ]}
          fill={[
            {
              match: (d) => {
                return d.id === "score";
              },
              id: "gradientA",
            },
            {
              match: (d) => d.id !== highestTier && d.id !== "score",
              id: "gradientB",
            },
            {
              match: (d) => {
                return d.id === highestTier;
              },
              id: "gradientTier",
            },
          ]}
          layers={["grid", "markers", "axes", "areas", "points", DashedLine, MyCustomLayer, "mesh", "legends"]}
        />
      </div>
      <div className={"flex flex-row justify-end"}>
        <div className={"flex flex-row gap-4 p-2 mt-1"}>
          <div className={"flex flex-col justify-between items-center gap-1"}>
            <div className={"my-1 w-5 border-b-2 border-dotted"} style={{ borderColor: colorById.default }} />
            <p className={"text-sm font-semibold"} style={{ color: colorById.default }}>
              Threshold
            </p>
          </div>
          <div className={"flex flex-col justify-between items-center gap-1"}>
            <div className={"my-1 w-5 border-b-2 border-dotted"} style={{ borderColor: colorById.average }} />
            <p className={"text-sm font-semibold"} style={{ color: colorById.average }}>
              Average
            </p>
          </div>
          <div className={"flex flex-col justify-between items-center gap-2"}>
            <div className={"w-2 h-2 rounded-full"} style={{ backgroundColor: colorById.tier_0 }} />
            <p className={"text-sm font-semibold"} style={{ color: colorById.tier_0 }}>
              Tier
            </p>
          </div>
          <div className={"flex flex-col justify-between items-center gap-2"}>
            <div className={"w-2 h-2 rounded-full"} style={{ backgroundColor: colorById.score }} />
            <p className={"text-sm font-semibold"} style={{ color: colorById.score }}>
              Score
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const TooltipLine = (props) => {
  if (props.keys[props.index]) {
    let entry = props.keys[props.index];
    let player = entry.player || entry.r.player;
    return (
      <div className={"flex flex-row space-x-8 justify-between w-full"}>
        <p className={"self-center text-xs"}>{props.keys[props.index] ? player.DisplayName : props.position}</p>
        <ScoreEllipse score={props.score} power={1} size={"text-xs"} />
      </div>
    );
  } else return null;
};

export const Tooltip = (props) => {
  const { input, capped, powers, keys } = props;
  let tiers = [0, 0, 0, 0];
  let players = [];
  let score = 0;
  let average = 0;
  let gw = "";
  input.slice.points.map((s) => {
    if (s.serieId === "tier_0") {
      tiers[0] = s.data.y;
    } else if (s.serieId === "tier_1") {
      tiers[1] = s.data.y;
    } else if (s.serieId === "tier_2") {
      tiers[2] = s.data.y;
    } else if (s.serieId === "tier_3") {
      tiers[3] = s.data.y;
    }
    if (s.serieId === "score") {
      gw = s.data.x;
      score = s.data.y;
      players = [s.data.player_1, s.data.player_2, s.data.player_3, s.data.player_4, s.data.player_5];
      average = s.data.average;
    }
  });

  return (
    <TooltipSimple powers={powers} players={players} gw={gw} keys={keys} score={score} tiers={tiers} capped={capped} average={average} />
  );
};

export const TooltipSimple = (props) => {
  const { powers, players, gw, keys, score, tiers, capped, average, headerColor } = props;
  const rewardIndex = tiers.findIndex((tier) => {
    if (tier > 0 && score >= tier) {
      return true;
    }
    return false;
  });
  let reward = rewardIndex >= 0 ? "Tier " + rewardIndex : "None";
  if (capped) {
    if (score >= 250 && average <= 240) {
      reward = "Threshold";
    }
    if (average > 240) {
      reward = "Uneligible";
    }
  }
  return (
    <div className={"bg-white p-4 shadow rounded-lg"}>
      <div className={"space-y-2"}>
        <div>
          <p className={`text-sm text-center font-semibold ${headerColor ? headerColor : ""}`}>GW #{gw}</p>
        </div>
        <div className={"space-y-2 w-full border-b border-grey-e5 pb-2"}>
          {players.map((player, index) => {
            return <TooltipLine key={index} index={index} powers={powers} position={so5_positions[index]} score={player} keys={keys} />;
          })}
        </div>
        <div className={"flex flex-row space-x-8 justify-between w-full"}>
          <p className={"self-center text-xs font-medium"}>Total</p>
          <p className={"text-sm font-semibold"}>{parseFloat(score).toFixed(0)}</p>
        </div>
        <div className={"flex flex-row space-x-8 justify-between w-full"}>
          <p className={"self-center text-xs font-medium"}>Reward</p>
          <p className={"text-sm font-semibold"}>{reward}</p>
        </div>
        {capped && (
          <div className={"flex flex-row space-x-8 justify-between w-full"}>
            <p className={"self-center text-xs font-medium"}>L15 before</p>
            <p className={"text-sm font-semibold"}>{average.toFixed(0)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CombinedStatsGraph;
