import React, { Component } from "react";
import { Box, Heading, ResponsiveContext } from "grommet";
import { withUser } from "../../userContext";
import Spinner from "../loader/spinner";
import Bundle from "./bundle";
import BidsLines from "./bidsLines";
import AuctionDetails from "./auctionDetails";
import { errorCatcher } from "../util/errors";
import BidNext from "./bidNext";

class BundleDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bundle: {},
    };
  }

  getBundle = () => {
    this.props
      .fetch("/apiv2/auctions/bundleInfo/" + this.props.match.params.bundleId)
      .then((response) => response.json())
      .then((res) => {
        this.setState({ bundle: res });
      })
      .catch(errorCatcher());
  };

  componentDidMount() {
    this.getBundle();
    this.interval = setInterval(() => {
      this.getBundle();
    }, 45000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const bundle = this.state.bundle;
    return (
      <Box margin={{ bottom: "large" }}>
        {bundle.auction !== undefined && (
          <Box>
            <Box align={"center"}>
              <Heading color={"brand"} textAlign={"center"} level={"2"}>
                Bundle ID #{bundle.auction.AuctionId.slice(0, 5)}...
              </Heading>
            </Box>
            <Box>
              <Bundle bundle={bundle} where={"details"} />
            </Box>
            <Box align={"center"}>
              <Heading level={"3"}>Bundle auction bids</Heading>
              <ResponsiveContext.Consumer>
                {(size) => (
                  <Box margin={size !== "small" && "auto"}>
                    <BidNext unit={this.props.user.preferredUnit} fiat={bundle.next_bid_fiat} eth={bundle.next_bid} />
                    <BidsLines bids={bundle.bids} unit={this.props.user.preferredUnit} />
                  </Box>
                )}
              </ResponsiveContext.Consumer>
              <AuctionDetails auction={bundle.auction} bundle={true} />
            </Box>
          </Box>
        )}
        {bundle.auction === undefined && (
          <Box margin={{ top: "small" }}>
            <Spinner />
          </Box>
        )}
      </Box>
    );
  }
}

export default withUser(BundleDetails);
