import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { Accordion, AccordionPanel, Box, Form } from "grommet";
import WatchlistCard from "./watchlistCard";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import EditWatchlistModal from "./editWatchlistModal";
import DeleteWatchlistModal from "./deleteWatchlistModal";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import Spinner from "../loader/spinner";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { orderLabels, orderMenuItems, watchlistTypeOptions } from "./constants";
import { sportsOptions } from "../util/sports";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { ReactComponent as IconPlus } from "../../img/icons-plus.svg";
import { StandaloneMenu } from "../util/standaloneMenu";
import BoxWithToggle from "../util/boxWithToggle";
import SelectWatchlistModal from "./selectWatchlistModal";
import { useDebounce } from "react-use";

const getNbElementsToDisplay = (mdQueries, nbLines) => {
  if (mdQueries["3xl"]) {
    return nbLines * 4;
  } else if (mdQueries["xl"]) {
    return nbLines * 3;
  } else if (mdQueries["md"]) {
    return nbLines * 2;
  }
  return nbLines * 3;
};

const emptyRes = {
  owned: { items: [], count: 0, filteredCount: 0 },
  followed: { items: [], count: 0, filteredCount: 0 },
};
const emptyDisplayAll = { owned: false, followed: false };

const WatchlistSelectorPlaceholder = (props) => {
  const { type, onUnselect, onSelect, watchlist } = props;
  const [openModal, setOpenModal] = useState(false);

  const onValidate = (w) => {
    onSelect && onSelect(w);
    setOpenModal(false);
  };

  if (watchlist !== undefined) {
    return (
      <WatchlistCard
        closeable
        watchlist={watchlist}
        onClose={onUnselect}
        onCloseTitle={`Remove ${watchlist.type} watchlist "${watchlist.name}" from default`}
      />
    );
  }
  return (
    <div
      style={{ minHeight: "180px" }}
      className={`flex justify-center h-full bg-focus mx-auto rounded-md w-full p-4 border-2 border-dashed border-grey-e9 hover:border-solid hover:opacity-90 cursor-pointer`}
      onClick={() => setOpenModal(true)}
    >
      <div className={"flex flex-col content-between p-0 my-auto"}>
        <IconPlus className="fill-brand mx-auto h-10 w-10" />
        <p className={"text-center text-brand font-semibold text-sm m-1"}>{`Select a ${type} watchlist`}</p>
      </div>
      <div className={"absolute"}>
        <SelectWatchlistModal
          title={`Select ${type} watchlist`}
          searchPlaceholder={`Set your default ${type} watchlist`}
          onCancel={() => setOpenModal(false)}
          onValidate={onValidate}
          open={openModal}
          limit={10}
          types={[type]}
          options={{
            pinListFirst: true,
            defaultListFirst: true,
            ownedListFirst: true,
            followedListFirst: true,
          }}
        />
      </div>
    </div>
  );
};

function WatchlistsListing(props) {
  const { limitOwnedLines, limitFollowedLines } = props;

  const mdQueries = useTailwindMediaQueries();
  const [limits, setLimits] = useState({
    owned: getNbElementsToDisplay(mdQueries, limitOwnedLines),
    followed: getNbElementsToDisplay(mdQueries, limitFollowedLines),
  });
  const [displayAll, setDisplayAll] = useState(emptyDisplayAll);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(emptyRes);

  const [activeIndexes, setActiveIndexes] = useState([0, 1]);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [watchlistToModify, setWatchlistToModify] = useState({});

  const [searchQuery, setSearchQuery] = useState("");
  const [debounceSearchQuery, setDebounceSearchQuery] = useState("");
  const [order, setOrder] = useState("updated desc");
  const [sport, setSport] = useState("");
  const [type, setType] = useState("");
  const [pinListFirst, setPinListFirst] = useState(true);

  const [showSortMenu, setShowSortMenu] = useState(false);

  const [loadingDefaults, setLoadingDefaults] = useState(false);
  const [defaultWatchlists, setDefaultWatchlists] = useState({});

  const [limitations, setLimitations] = useState({});

  useDebounce(
    () => {
      setDebounceSearchQuery(searchQuery);
    },
    250,
    [searchQuery],
  );
  useEffect(() => {
    reload();
  }, [debounceSearchQuery]);

  useEffect(() => {
    reload();
  }, [order, sport, type, pinListFirst, displayAll]);

  useEffect(() => {
    document.title = "Watchlists";
  }, []);

  useEffect(() => {
    const newOwnedLimit = getNbElementsToDisplay(mdQueries, limitOwnedLines);
    const newFollowedLimit = getNbElementsToDisplay(mdQueries, limitOwnedLines);
    if (newOwnedLimit !== limits.owned && newFollowedLimit !== limits.followed) {
      setLimits({
        owned: getNbElementsToDisplay(mdQueries, limitOwnedLines),
        followed: getNbElementsToDisplay(mdQueries, limitFollowedLines),
      });
    }
  }, [mdQueries, limitOwnedLines]);

  const reload = () => {
    getLimitations();
    getDefaultWatchlists();
    getWatchlistInfo(displayAll.owned ? 0 : limits.owned, displayAll.followed ? 0 : limits.followed);
  };

  const getWatchlistInfo = (limitOwned, limitFollowed) => {
    setLoading(true);
    props
      .fetch("/apiv2/watchlists/user/search", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchQuery: searchQuery,
          limitOwned: limitOwned,
          limitFollowed: limitFollowed,
          order: order,
          pinListFirst: pinListFirst,
          sports: sport ? [sport] : [],
          types: type ? [type] : [],
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error === undefined) {
          setRes(res);
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const getDefaultWatchlists = () => {
    setLoadingDefaults(true);
    props
      .fetch("/apiv2/watchlists/default")
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error === undefined) {
          setDefaultWatchlists(res);
        }
        setLoadingDefaults(false);
      })
      .catch(
        errorCatcher(() => {
          setLoadingDefaults(false);
        }),
      );
  };

  const onDeleteWatchlist = (w) => {
    setWatchlistToModify(w);
    setOpenDeleteModal(true);
  };

  const onEditWatchlist = (w) => {
    setWatchlistToModify(w);
    setOpenEditModal(true);
  };

  const onPinWatchlist = (w) => {
    const action = w.isPinned ? "unpin" : "pin";
    props
      .fetch(`/apiv2/watchlists/id/${w.id}/${action}`, { method: "POST" })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          reload();
        }
      })
      .catch(errorCatcher());
  };

  const getLimitations = () => {
    props
      .fetch(`/apiv2/watchlists/new/can`)
      .then((response) => response.json())
      .then((res) => {
        setLimitations(res);
      })
      .catch(errorCatcher(() => {}));
  };

  const onSelectAsDefaultWatchlist = (w) => {
    if (w.isDefault) {
      onUnselectAsDefaultWatchlist(w);
    } else {
      props
        .fetch(`/apiv2/watchlists/id/${w.id}/selectAsDefault`, {
          method: "POST",
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            reload();
          }
        })
        .catch(errorCatcher());
    }
  };

  const onUnselectAsDefaultWatchlist = (w) => {
    props
      .fetch(`/apiv2/watchlists/id/${w.id}/unselectAsDefault`, {
        method: "POST",
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          reload();
        }
      })
      .catch(errorCatcher());
  };

  const onReactivateWatchlist = (w) => {
    props
      .fetch(`/apiv2/watchlists/id/${w.id}/enable`, { method: "POST" })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          reload();
        }
      })
      .catch(errorCatcher());
  };

  const onSaveEditWatchlist = () => {
    reload();
    setOpenEditModal(false);
    setWatchlistToModify({});
  };

  const onConfirmDeleteWatchlist = () => {
    reload();
    setOpenDeleteModal(false);
    setWatchlistToModify({});
  };

  const onClickOnSortItem = (key) => {
    setShowSortMenu(false);
    setOrder(key);
  };

  if (props.user.username === undefined) {
    return null;
  }

  const seeMoreOwnedWatchlists = res?.owned?.items ? res.owned.items.length < res.owned.filteredCount : false;
  const seeMoreFollowedWatchlists = res.followed?.items ? res.followed.items.length < res.followed.filteredCount : false;
  return (
    <div className={"w-full"}>
      {props.displayDefaults && (
        <div className={"bg-focus rounded-lg mx-auto flex justify-center mb-1"}>
          <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 p-6 w-full 3xl:w-3/4 "}>
            <div className={"flex flex-col content-between my-auto"}>
              <h3 className={"text-xl font-semibold mx-0 my-1"}>Default watchlists</h3>
              <p className={"text-sm font-sans text-textGrey4 font-medium leading-5 my-1"}>
                Choose your watchlists that will be shown first in features using watchlists on our platforms.
              </p>
            </div>
            <WatchlistSelectorPlaceholder
              watchlist={defaultWatchlists.player}
              type={"player"}
              onUnselect={onUnselectAsDefaultWatchlist}
              onSelect={onSelectAsDefaultWatchlist}
            />
            <WatchlistSelectorPlaceholder
              watchlist={defaultWatchlists.manager}
              type={"manager"}
              onUnselect={onUnselectAsDefaultWatchlist}
              onSelect={onSelectAsDefaultWatchlist}
            />
          </div>
        </div>
      )}
      {props.displayHeader && (
        <div className="relative flex flex-col md:flex-row place-content-between p-0 py-3 pl-1.5 gap-2">
          <div className="font-semibold text-xl my-auto">
            <div className={"text-sm font-semibold text-brand cursor-pointer flex flex-row my-auto"} onClick={() => setShowSortMenu(true)}>
              Sort by: {orderLabels.find((obj) => obj.value === order).name}
              <span className={"ml-2 my-auto"}>
                <SortArrowIcon className={"fill-brand " + (showSortMenu ? "transform rotate-180" : "")} />
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row place-content-between p-0 gap-2">
            {(loading || loadingDefaults) && <Spinner />}
            <span className={"flex w-full md:w-72"}>
              <BoxWithToggle
                className={"border border-grey-e9 py-2 md:py-0"}
                label={"Pinned list first"}
                isActive={pinListFirst}
                setActive={() => setPinListFirst(!pinListFirst)}
              />
            </span>
            <SelectSearch
              closeOnSelect={true}
              options={watchlistTypeOptions}
              value={type}
              filterOptions={fuzzySearch}
              onChange={(v) => setType(v)}
            />
            <SelectSearch
              closeOnSelect={true}
              options={sportsOptions}
              value={sport}
              filterOptions={fuzzySearch}
              onChange={(v) => setSport(v)}
            />
            <Form className="font-semibold text-sm">
              <div className={"m-auto relative flex h-9 "}>
                <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                  <FormSearch className="h-5 w-5" aria-hidden="true" />
                </div>
                {searchQuery && (
                  <div className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer" onClick={() => setSearchQuery("")}>
                    <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
                  </div>
                )}
                <input
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  className={`h-9 rounded-md block w-full pl-10 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0 ${
                    searchQuery !== "" ? "font-medium" : ""
                  }`}
                  placeholder="Search..."
                />
              </div>
            </Form>
          </div>
          <StandaloneMenu
            className={"top-8 left-0 overflow-y-auto max-h-60 pt-2"}
            show={showSortMenu}
            onClickOutside={() => setShowSortMenu(false)}
            items={orderMenuItems(order, onClickOnSortItem)}
          />
        </div>
      )}
      <Accordion multiple={true} activeIndex={activeIndexes} onActive={(indexes) => setActiveIndexes(indexes)}>
        <AccordionPanel
          label={`My watchlists (${
            res.owned.filteredCount !== res.owned.count ? res.owned.filteredCount + "/" + res.owned.count : res.owned.count
          })`}
        >
          <Box pad={{ horizontal: "xsmall", vertical: "small" }}>
            <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-6"}>
              {seeMoreOwnedWatchlists
                ? res.owned.items.slice(0, limits.owned).map((watchlist) => {
                    return (
                      <WatchlistCard
                        key={watchlist.id}
                        watchlist={watchlist}
                        onDelete={onDeleteWatchlist}
                        onEdit={onEditWatchlist}
                        onPin={onPinWatchlist}
                        onSelectAsDefault={onSelectAsDefaultWatchlist}
                        limitations={limitations}
                        onReactivate={onReactivateWatchlist}
                      />
                    );
                  })
                : res.owned.items.map((watchlist) => {
                    return (
                      <WatchlistCard
                        key={watchlist.id}
                        watchlist={watchlist}
                        onDelete={onDeleteWatchlist}
                        onEdit={onEditWatchlist}
                        onPin={onPinWatchlist}
                        onSelectAsDefault={onSelectAsDefaultWatchlist}
                        limitations={limitations}
                        onReactivate={onReactivateWatchlist}
                      />
                    );
                  })}
            </div>
            {seeMoreOwnedWatchlists && (
              <span
                className={"m-auto mt-2 cursor-pointer font-semibold text-sm py-2 px-4 rounded-full bg-brand-pastel text-brand-text"}
                onClick={() => setDisplayAll({ ...displayAll, owned: true })}
              >
                See more
              </span>
            )}
          </Box>
        </AccordionPanel>
        <AccordionPanel
          label={`Followed watchlists (${
            res.followed.filteredCount !== res.followed.count ? res.followed.filteredCount + "/" + res.followed.count : res.followed.count
          })`}
        >
          <Box pad={{ horizontal: "xsmall", vertical: "small" }}>
            <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-6"}>
              {seeMoreFollowedWatchlists
                ? res.followed.items.slice(0, limits.followed).map((watchlist) => {
                    return (
                      <WatchlistCard
                        key={watchlist.id}
                        watchlist={watchlist}
                        onDelete={onDeleteWatchlist}
                        onEdit={onEditWatchlist}
                        onPin={onPinWatchlist}
                        onSelectAsDefault={onSelectAsDefaultWatchlist}
                        limitations={limitations}
                        onReactivate={onReactivateWatchlist}
                      />
                    );
                  })
                : res.followed.items.map((watchlist) => {
                    return (
                      <WatchlistCard
                        key={watchlist.id}
                        watchlist={watchlist}
                        onDelete={onDeleteWatchlist}
                        onEdit={onEditWatchlist}
                        onPin={onPinWatchlist}
                        onSelectAsDefault={onSelectAsDefaultWatchlist}
                        limitations={limitations}
                        onReactivate={onReactivateWatchlist}
                      />
                    );
                  })}
            </div>
            {seeMoreFollowedWatchlists && (
              <span
                className={"m-auto mt-2 cursor-pointer font-semibold text-sm py-2 px-4 rounded-full bg-brand-pastel text-brand-text"}
                onClick={() => setDisplayAll({ ...displayAll, followed: true })}
              >
                See more
              </span>
            )}
          </Box>
        </AccordionPanel>
      </Accordion>
      {watchlistToModify && (
        <EditWatchlistModal
          watchlist={watchlistToModify}
          open={openEditModal}
          onSave={onSaveEditWatchlist}
          onCancel={() => setOpenEditModal(false)}
        />
      )}
      {watchlistToModify && (
        <DeleteWatchlistModal
          watchlist={watchlistToModify}
          open={openDeleteModal}
          onDelete={onConfirmDeleteWatchlist}
          onCancel={() => setOpenDeleteModal(false)}
        />
      )}
    </div>
  );
}

export default withUser(WatchlistsListing);
