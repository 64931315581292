import React, { useEffect, useRef, useState } from "react";
import { Form } from "grommet";
import Spinner from "../loader/spinner";
import { withUser } from "../../userContext";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { Button } from "../util/button";
import WatchlistPlayerTypeIcon from "../../img/icon-watchlists-type-player.svg";
import WatchlistManagerTypeIcon from "../../img/icon-watchlists-type-manager.svg";
import clsx from "clsx";

const selectionAllLabels = {
  "all-owned-watchlists": "All owned watchlists",
  "all-followed-watchlists": "All followed watchlists",
};

const ListItem = (props) => {
  const { onSelect, selected, id } = props;
  const label = selectionAllLabels[id];
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onSelect({ id: id, name: label });
      }}
      className={`z-50 flex flex-col px-2 py-1 hover:bg-grey-f9 cursor-pointer`}
    >
      <div className={"flex flex-row"}>
        <div className={"my-auto mx-2"}>
          <input
            style={{ width: "12px", height: "12px" }}
            type={"checkbox"}
            className={`focus:ring-0 rounded border-2 ${selected[id] ? "border-brand-text bg-brand-pastel" : "border-textGrey4 bg-focus"}`}
          />
        </div>
        <div className={"flex flex-col"}>
          <div className={"flex flex-row space-x-2"}>
            <div className={"flex flex-col self-center justify-start items-start"}>
              <p title={label} className={"text-sm font-medium text-start truncate"} style={{ maxWidth: "20em" }}>
                {label}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WatchlistMultiplePicker = (props) => {
  const withApply = props.withApply || false;
  const onApply = props.onApply;
  const size = props.size || "100%";
  const limit = props.limit || 100;
  const types = props.types || [];
  const sports = props.sports || [];
  const fetchOnMount = props.fetchOnMount || false;
  const options = props.options || {};
  const pinListFirst = options.pinListFirst || false;
  const defaultListFirst = options.defaultListFirst || false;
  const followedListFirst = options.followedListFirst || false;
  const ownedListFirst = options.ownedListFirst || false;
  const selectFirst = options.selectFirst || false;
  const selected = props.selected || {};
  const placeholder = props.placeholder || "Filter by one or more watchlist";

  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState(undefined);
  const [displayResults, setDisplayResults] = useState(false);
  const [isHoverResults, setIsHoverResults] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectFirst || fetchOnMount) {
      searchWatchlists();
    }
  }, []);

  useEffect(() => {
    if (displayResults) {
      searchWatchlists();
    }
  }, [displayResults]);

  useEffect(() => {
    if (isHoverResults) {
      setDisplayResults(true);
    }
  }, [isHoverResults]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onSelect = (w) => {
    props.onSelect && props.onSelect(w);
  };

  const searchWatchlists = () => {
    setSearching(true);
    props
      .fetch(`/apiv2/watchlists/interesting/search`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          limit: limit,
          order: "name asc",
          pinListFirst: pinListFirst,
          defaultListFirst: defaultListFirst,
          ownedListFirst: ownedListFirst,
          followedListFirst: followedListFirst,
          types: types,
          sports: sports,
          hideDisabled: true,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          const results = res || [];
          setResults(results);
          if (selectFirst && results.length > 0 && !selected) {
            props.onSelect && props.onSelect(results[0]);
          }
        }
        setSearching(false);
      })
      .catch((res) => {
        setSearching(false);
      });
  };

  const selectedKeys = Object.keys(selected);
  const roundSearch = displayResults ? "rounded-t" : "rounded";
  let placeholderStyle = `bg-focus truncate pl-4 pr-6 block w-full text-sm py-2 shadow cursor-pointer font-medium
    ${roundSearch} 
    `;
  let state;
  if (selectedKeys.length === 0) {
    state = placeholder;
  } else if (selectedKeys.length === 1) {
    const itemKey = selectedKeys[0];
    state = selected[itemKey]?.name || results?.find((r) => r.id === itemKey)?.name || selectionAllLabels[itemKey] || placeholder;
  } else {
    const existing = selectedKeys.filter((k) => results?.find((r) => r.id === k) || selectionAllLabels[k]);
    if (existing.length === 0) {
      state = placeholder;
    } else if (existing.length === 1) {
      const existingKey = existing[0];
      state =
        selected[existingKey]?.name || results?.find((r) => r.id === existingKey)?.name || selectionAllLabels[existingKey] || placeholder;
    } else {
      state = "Multiple watchlists";
    }
  }
  if (state === "" || state === placeholder) {
    placeholderStyle += " text-textGrey3 ";
  }
  const width = size === "lg" ? "28em" : size === "sm" ? "18em" : size === "xs" ? "13em" : size;
  const multipleWatchlistTypes = types.length >= 2;
  return (
    <div className={clsx("relative", props.className)}>
      <Form className={"z-0"}>
        <div className={"flex"}>
          <div style={{ width: width }} className={"m-auto relative flex " + roundSearch} ref={ref}>
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
              {searching && (
                <div className="pr-2 flex items-center">
                  <span className={"h-3 w-3"}>
                    <Spinner className={"w-4 h-4"} />
                  </span>
                </div>
              )}
              <SortArrowIcon className={`mr-1 my-auto ${displayResults ? "transform rotate-180" : ""}`} />
            </div>
            <div className={placeholderStyle} onClick={() => setDisplayResults(true)} ref={inputRef}>
              {state}
            </div>
            <div className={"z-500 absolute w-full top-9 shadow overflow-hidden rounded-b"}>
              {displayResults && (
                <div className={"rounded-b border-x border-grey-e9 bg-white z-50 overflow-hidden"}>
                  <div className={"space-y-2 z-50"}>
                    {results?.length > 0 && (
                      <div
                        className={"space-y-0"}
                        onMouseEnter={() => setIsHoverResults(true)}
                        onMouseLeave={() => setIsHoverResults(false)}
                      >
                        <div className={""}>
                          <div className={"border-b border-t border-grey-e9 py-2"}>
                            <ListItem onSelect={onSelect} id={"all-owned-watchlists"} selected={selected} />
                            <ListItem onSelect={onSelect} id={"all-followed-watchlists"} selected={selected} />
                          </div>
                          <div className={"py-2 overflow-scroll"} style={{ maxHeight: "16em" }}>
                            {results?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSelect(item);
                                  }}
                                  className={`z-50 flex flex-col px-2 py-1 hover:bg-grey-f9 cursor-pointer`}
                                >
                                  <div className={"flex flex-row"}>
                                    <div className={"my-auto mx-2"}>
                                      <input
                                        style={{
                                          width: "12px",
                                          height: "12px",
                                        }}
                                        type={"checkbox"}
                                        className={`focus:ring-0 rounded border-2 ${
                                          selected[item.id] ? "border-brand-text bg-brand-pastel" : "border-textGrey4 bg-focus"
                                        }`}
                                      />
                                    </div>
                                    <div className={`w-full grid grid-rows-1 grid-cols-12 ${multipleWatchlistTypes ? "gap-1" : ""}`}>
                                      <div className={`flex flex-col ${multipleWatchlistTypes ? "col-span-10" : "col-span-12"} w-full`}>
                                        <div className={"flex flex-row space-x-2 w-full"}>
                                          <div className={"flex flex-col self-center justify-start items-start truncate w-full"}>
                                            <p
                                              title={item.name}
                                              className={"text-sm font-medium text-start truncate"}
                                              style={{ maxWidth: "99%" }}
                                            >
                                              {item.name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className={"flex flex-row justify-between"}>
                                          <p className={"text-textGrey3 text-xs font-semibold truncate"}>
                                            Created by: {item.ownerDisplayName || item.owner}
                                          </p>
                                        </div>
                                      </div>
                                      {multipleWatchlistTypes && (
                                        <div className={"flex justify-center items-center pr-1.5 col-span-2"}>
                                          <img
                                            className={"h-6 w-6"}
                                            src={item.type === "player" ? WatchlistPlayerTypeIcon : WatchlistManagerTypeIcon}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {withApply && (
                            <div className={"flex justify-center p-2 border-t border-grey-e9"}>
                              <Button
                                label={"Apply"}
                                onClick={() => {
                                  onApply && onApply();
                                  setDisplayResults(false);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {results?.length === 0 && (
                      <div className={"space-y-0"}>
                        <div className={"space-y-1"}>
                          <div className={"z-50 flex flex-row justify-between rounded text-textGrey4 text-sm m-auto"}>
                            <p className={"my-auto p-2"}>0 results</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default withUser(WatchlistMultiplePicker);
