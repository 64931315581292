export const footballScale = [15, 30, 45, 60, 75]; // Averages
export const footballScoreScales = [20, 30, 40, 60, 80, 100]; // Scores
export const footballScoreColorsScales = ["#E73107", "#F2994A", "#EBCF4A", "#B1C345", "#5DC09B", "#429559"];

export const defaultScoreColor = "#BDBDBD";
export const defaultScoreColorAlternative = "#808080";
export const baseballScale = [-1, 3, 7, 12, 20];
export const baseballGwScale = [1, 10, 20, 35, 50];
export const basketballScale = [0.1, 10, 20, 40, 60];
export const baseballScaleCircle = [0, 5, 10, 20, 40];

const getScoreColor = (score, scale, colorScales) => {
  if (score === null || score === undefined) {
    return [defaultScoreColor, defaultScoreColorAlternative];
  }
  let scoreFloat;
  if (typeof score == "string") {
    scoreFloat = parseFloat(score);
  } else {
    scoreFloat = score;
  }
  if (isNaN(scoreFloat) || score < 0) {
    return [defaultScoreColor, defaultScoreColorAlternative];
  }
  for (let i = 0; i < scale.length; i++) {
    if (score < scale[i]) {
      return [colorScales[i], "#FFFFFF"];
    }
  }
  return [colorScales[colorScales.length - 1], "#FFFFFF"];
};

export const getFootballScoreColors = (score) => {
  return getScoreColor(score, footballScoreScales, footballScoreColorsScales);
};

export const getScoreColors = (score, sport) => {
  // TODO for other sports
  return getFootballScoreColors(score);
};

export const getFootballAllAroundScoreColors = (score) => {
  let scoreBackgroundColor = "#E5E5E5";
  let scoreColor = "#292929";
  if (score !== undefined && score !== null) {
    if (Number(score) < -10) {
      scoreColor = "#FF3232";
      scoreBackgroundColor = "#FFEAEA";
    } else if (Number(score) < 0) {
      scoreColor = "#F89F31";
      scoreBackgroundColor = "#FEF1E0";
    } else if (Number(score) < 10) {
      scoreColor = "#E7B62C";
      scoreBackgroundColor = "#FAF0D5";
    } else if (Number(score) < 20) {
      scoreColor = "#81BF50";
      scoreBackgroundColor = "#F0F7EA";
    } else if (Number(score) < 30) {
      scoreColor = "#50BF84";
      scoreBackgroundColor = "#E5F5ED";
    } else if (Number(score) <= 1000) {
      scoreColor = "#169A54";
      scoreBackgroundColor = "#DCF0E5";
    }
  }
  return [scoreColor, scoreBackgroundColor];
};

export const getFootballDecisiveScoreColors = (score) => {
  let scoreBackgroundColor = "#E5E5E5";
  let scoreColor = "#292929";
  if (score !== undefined && score !== null) {
    if (Number(score) <= 15) {
      scoreColor = "#FF3232";
      scoreBackgroundColor = "#FFEAEA";
    } else if (Number(score) <= 25) {
      scoreColor = "#F89F31";
      scoreBackgroundColor = "#FEF1E0";
    } else if (Number(score) <= 35) {
      scoreColor = "#E7B62C";
      scoreBackgroundColor = "#FAF0D5";
    } else if (Number(score) <= 60) {
      scoreColor = "#81BF50";
      scoreBackgroundColor = "#F0F7EA";
    } else if (Number(score) <= 70) {
      scoreColor = "#50BF84";
      scoreBackgroundColor = "#E5F5ED";
    } else if (Number(score) > 70) {
      scoreColor = "#169A54";
      scoreBackgroundColor = "#DCF0E5";
    }
  }
  return [scoreColor, scoreBackgroundColor];
};
