import React from "react";
import { getFootballAllAroundScoreColors } from "./scales";

export const AllAroundScore = (props) => {
  const { score } = props;
  const [scoreColor, scoreBackgroundColor] = getFootballAllAroundScoreColors(score);
  return (
    <div
      className={"flex text-sm font-semibold justify-center rounded-full text-center w-10 h-10 mx-auto"}
      style={{ backgroundColor: scoreBackgroundColor, color: scoreColor }}
    >
      <span className={"self-center"}>{score !== undefined ? score : "-"}</span>
    </div>
  );
};
