import React, { Component } from "react";
import { Box, Heading, Select } from "grommet";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomizedAxisTick from "../util/tick";
import { BRAND_COLOR } from "../util/colors";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

class LineupHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: {},
      divisionValue: "All-Star Division 4",
    };
  }

  componentDidMount() {
    this.getStats();
  }

  getStats = () => {
    let divisionValue = this.state.divisionValue.toLowerCase().replace("-", "_").replace(/ /g, "-").replace("u23", "under_twenty_one");
    this.props
      .fetch("/apiv2/stats/lineups/" + divisionValue)
      .then((response) => response.json())
      .then((res) => {
        this.setState({ stats: res });
      })
      .catch(errorCatcher());
  };

  setDivisionValue = async (divisionValue) => {
    await this.setState({ divisionValue });
    this.getStats();
  };

  render() {
    const data = this.state.stats;
    const divisionValue = this.state.divisionValue;
    return (
      <Box>
        <Box align={"center"}>
          <Heading level={"3"}>Number of lineups for each gameweek</Heading>
          <Box align={"center"}>
            <Select
              options={[
                "All-Star Division 4",
                "All-Star Division 3",
                "All-Star Division 2",
                "All-Star Division 1",
                "U23 Division 4",
                "U23 Division 3",
                "U23 Division 2",
                "U23 Division 1",
                "Challenger Europe Division 4",
                "Challenger Europe Division 3",
                "Challenger Europe Division 2",
                "Challenger Europe Division 1",
                "Champion Europe Division 4",
                "Champion Europe Division 3",
                "Champion Europe Division 2",
                "Champion Europe Division 1",
                "Champion Asia Division 4",
                "Champion Asia Division 3",
                "Champion Asia Division 2",
                "Champion Asia Division 1",
                "Champion America Division 4",
                "Champion America Division 3",
                "Champion America Division 2",
                "Champion America Division 1",
                "Rookie Division 1",
              ]}
              value={divisionValue}
              onChange={({ option }) => this.setDivisionValue(option)}
            />
          </Box>
          <ResponsiveContainer width={"100%"} height={450}>
            <AreaChart
              data={data}
              width={600}
              height={400}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 40,
              }}
            >
              <XAxis dataKey="gw_number" tick={<CustomizedAxisTick />} />
              <YAxis domain={[0, 40]} />
              <Tooltip />
              <Area type="monotone" name={"Number of lineups"} dataKey="number_of_lineups" fill={BRAND_COLOR} stroke={BRAND_COLOR} />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  }
}

export default withUser(LineupHistory);
