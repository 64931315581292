import React from "react";
import { sorareBaseball } from "../util/sports";
import { baseballScale, footballScale } from "../util/scales";
import { withUser } from "../../userContext";

function SquaredScore(props) {
  let floatSO5Score = props.avg;
  if (props.user.sorareAverages === true && props.sport !== sorareBaseball) {
    if (props.sAvg !== undefined && props.sApps !== undefined) {
      floatSO5Score = props.sAvg;
    }
  }
  if (props.powerAdj) {
    floatSO5Score = floatSO5Score * props.power;
  }
  let scoreColorStart;
  let scale = footballScale;
  let precision = 0;
  if (props.sport === sorareBaseball) {
    scale = baseballScale;
    precision = 1;
    if (floatSO5Score >= 10) {
      precision = 0;
    }
  }
  if (floatSO5Score < scale[0]) {
    scoreColorStart = "horrible-score";
  } else if (floatSO5Score < scale[1]) {
    scoreColorStart = "bad-score";
  } else if (floatSO5Score < scale[2]) {
    scoreColorStart = "ok-score";
  } else if (floatSO5Score < scale[3]) {
    scoreColorStart = "good-score";
  } else if (floatSO5Score < scale[4]) {
    scoreColorStart = "great-score";
  } else {
    scoreColorStart = "amazing-score";
  }
  scoreColorStart = scoreColorStart + "-start";
  let text = floatSO5Score.toFixed(precision);
  let size = "w-8 h-7 md:w-9 md:h-8 xl:h-7 xl:w-8 2xl:w-8 2xl:h-7 2.5xl:w-9 2.5xl:h-8";
  if (props.size) {
    size = props.size;
  }
  let font = "text-md md:text-base xl:text-sm 2xl:text-md 2.5xl:text-md font-semibold";
  if (props.font) {
    font = props.font;
  }
  if (floatSO5Score === -1) {
    scoreColorStart = "bar-grey";
    text = "NA";
    font += " text-brand-black";
  }
  return (
    <a href={props.playerId && "/player/" + props.playerId} target={"_blank"} rel="noreferrer">
      <div className={"flex flex-col space-y-1 mx-auto items-center"}>
        <div className={"flex flex-row " + size + " rounded-md bg-" + scoreColorStart + ""}>
          <p className={"flex mx-auto self-center justify-center " + font + " text-focus text-center"}>{text}</p>
        </div>
      </div>
    </a>
  );
}

export default withUser(SquaredScore);
