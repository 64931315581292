import React, { useEffect, useState } from "react";
import ScoreFilterSplit from "./scoreFilterSplit";
import useComponentVisible from "./outsideAlerter";
import { sorareBasketball } from "./sports";

function ScoreFilter(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [l5, setL5] = useState(props.l5);
  const [l15, setL15] = useState(props.l15);
  const [l40, setL40] = useState(props.l40);

  useEffect(() => {
    setL5(props.l5);
    setL15(props.l15);
    setL40(props.l40);
  }, [props]);

  const sport = props.sport;
  let label = "L5/L15/L40";
  if (sport === sorareBasketball) {
    label = "L10/L40/Season";
  }

  return (
    <div>
      <div
        className={"rounded-md bg-white py-2 shadow cursor-pointer"}
        onClick={(e) => {
          setIsComponentVisible(!isComponentVisible);
        }}
      >
        <p className={"text-sm px-4"}>{label}</p>
      </div>
      {isComponentVisible && (
        <div ref={ref} className={"bg-white mt-2 z-20 rounded-xl shadow absolute right-8 lg:right-64 p-8"}>
          <div className={"flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 mx-auto text-base"}>
            <ScoreFilterSplit gms={sport === sorareBasketball ? 10 : 5} setObj={(l5) => setL5(l5)} obj={l5} sport={props.sport} />
            <ScoreFilterSplit gms={sport === sorareBasketball ? 40 : 15} setObj={(l15) => setL15(l15)} obj={l15} sport={props.sport} />
            <ScoreFilterSplit
              gms={sport === sorareBasketball ? "season" : 40}
              setObj={(l40) => setL40(l40)}
              obj={l40}
              sport={props.sport}
            />
          </div>
          <div className={"mx-auto text-center"}>
            <div
              onClick={() => {
                setL5(l5);
                setL15(l15);
                setL40(l40);
                props.setScoreFilters({ l5: l5, l15: l15, l40: l40 });
              }}
              className={"bg-brand px-2 py-2 w-24 mx-auto rounded-3xl cursor-pointer font-semibold hover:font-bold"}
            >
              <span className={"text-white"}>Apply</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ScoreFilter;
