import React from "react";
import WarningIcon from "../../img/multiple_players_icon.svg";
import { ReactTooltip } from "../util/tooltip.js";

function PrivateOfferBox(props) {
  let fontColor = "text-brand-black";
  if (props.brand) {
    fontColor = "text-brand";
  }
  let warning = false;
  if (props.warning) {
    warning = true;
  }

  ReactTooltip.rebuild();

  return (
    <div className={"flex flex-col bg-grey-e5 space-y-2 p-3 items-center rounded " + fontColor}>
      <p className={"text-sm text-center font-semibold"}>{props.label}</p>
      <div className={"flex flex-col space-y-1 items-center"}>
        <div className={"flex flex-row space-x-2 justify-center"}>
          <p className={"text-xl font-semibold"}>{props.value}</p>
          {warning && (
            <div className={"w-4 h-4 self-center"} data-tip={props.tip}>
              <img src={WarningIcon} className={"w-full h-full"} />
            </div>
          )}
        </div>
        <p className={"text-sm font-semibold"}>{props.secondaryValue}</p>
      </div>
    </div>
  );
}

export default PrivateOfferBox;
