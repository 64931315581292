import React, { Component } from "react";
import { Box, Heading } from "grommet";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomizedAxisTick from "../util/tick";
import { BRAND_COLOR } from "../util/colors";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

class HomeStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: {},
    };
  }

  componentDidMount() {
    this.getStats();
  }

  getStats = () => {
    this.props
      .fetch("/apiv2/stats/cardsByManager/history")
      .then((response) => response.json())
      .then((res) => {
        this.setState({ stats: res });
      })
      .catch(errorCatcher());
  };

  render() {
    const data = this.state.stats;
    return (
      <Box>
        <Box align={"center"}>
          <Heading level={"3"}>Owners of at least N blockchain cards</Heading>
          <ResponsiveContainer width={"100%"} height={500}>
            <LineChart width={600} height={400} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 40 }}>
              <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
              <YAxis />
              <Tooltip />
              <Line stroke={BRAND_COLOR} dataKey="one_card_amount" dot={false} name={"1 card or more"} />
              <Line stroke={BRAND_COLOR} dataKey="two_cards_amount" dot={false} name={"2 cards or more"} />
              <Line stroke={BRAND_COLOR} type="monotone" dataKey="five_cards_amount" dot={false} name={"5 cards or more"} />
              <Line stroke={BRAND_COLOR} type="monotone" dataKey="ten_cards_amount" dot={false} name={"10 cards or more"} />
              <Line stroke={BRAND_COLOR} type="monotone" dataKey="twenty_five_cards_amount" dot={false} name={"25 cards or more"} />
              <Line stroke={BRAND_COLOR} type="monotone" dataKey="fifty_cards_amount" dot={false} name={"50 cards or more"} />
              <Line stroke={BRAND_COLOR} type="monotone" dataKey="hundred_cards_amount" dot={false} name={"100 cards or more"} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  }
}

export default withUser(HomeStats);
