import React, { useState } from "react";
import PlayerAvatar from "../util/playerAvatar";
import ScoreEllipse from "../util/scoreEllipse";
import GetDaIcons from "../util/daIcons";
import PlayerScoreModal from "../players/playerScoreModal";
import { sorareFootball } from "../util/sports";

function SO5PlayerLine(props) {
  const [isOpen, setOverlay] = useState(false);
  const player = props.player;
  let icons = GetDaIcons(player.stats, "w-5 h-5 self-center rounded-full");
  return (
    <div>
      <PlayerScoreModal
        open={isOpen}
        stats={player.stats}
        player={props.player.player}
        position={player.player.Position}
        sport={sorareFootball}
        close={() => setOverlay(false)}
      />
      <div className={"flex flex-row space-x-4 rounded-lg bg-white p-4 w-full self-center"}>
        <div className={""}>
          <PlayerAvatar player={player.player} linedUpPlayer={player.lineups !== null} />
        </div>
        <div className={"flex w-10/12 flex-col items-start self-center"}>
          <div className={"flex flex-row space-x-2"}>
            <p className={"text-base font-semibold"}>{player.player.DisplayName}</p>
            {icons && <div className={"flex flex-row justify-center mx-auto z-10 -space-x-0.5"}>{icons}</div>}
          </div>
          <p className={"text-sm"}>
            {player.player.Position} - {player.player.Age}
          </p>
        </div>
        <div className={"self-center cursor-pointer"} onClick={() => setOverlay(true)}>
          <ScoreEllipse size={"text-base"} score={player.stats.SO5Score} power={1} round={true} />
        </div>
      </div>
    </div>
  );
}

export default SO5PlayerLine;
