import React, { Component, useEffect, useRef, useState } from "react";
import { getNextGameweeks } from "../util/nextGws";
import { ReactTooltip } from "../util/tooltip.js";
import { getLeaguesBasedOnSport, leagues_no_div_objects, leagues_no_div_objects_not_all } from "../util/leagues";
import PlayerRankingNewLook from "./playerRankingNewLook";
import SelectSearch from "react-select-search";
import { getAllPositionsBasedOnSport } from "../util/positions";
import PlayerRankingsHeader from "../../img/player_rankings_header.svg";
import { withUser } from "../../userContext";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import Locker from "../../img/brand-close-locker-nude.svg";
import { customOptionRender } from "../util/customSelectSearchRender";
import SportsPicker from "../util/sportsPicker";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { SinglePositionPicker } from "../util/positionPicker";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactComponent as TuneIcon } from "../../img/icons-tune.svg";

import BoxWithToggle from "../util/boxWithToggle";
import Slider from "rc-slider";
import {
  allStarDefaultCompetition,
  EligibilitySorareCompetitionsFilter,
  initialEligibilityCompetitionValue,
} from "../competitions/eligibilityCompetitionsPicker";
import { isMobile } from "react-device-detect";
import { MobileModale } from "../util/mobileModale";
import { XIcon } from "@heroicons/react/outline";
import { useElementSize } from "usehooks-ts";

export const PlayerRankingWrapper = withUser((props) => {
  const [eligibilityCompetitions, setEligibilityCompetitions] = useState(initialEligibilityCompetitionValue);
  const [positionValue, setPositionValue] = useState("all");
  const [leagueValue, setLeagueValue] = useState("all");
  const [u23, setU23] = useState(false);
  const [gwIds, setGwIds] = useState([]);
  const [gwValue, setGwValue] = useState("");
  const [showBmp, setShowBmp] = useState(false);
  const [showValo, setShowValo] = useState(true);
  const [minApps, setMinApps] = useState(7);
  const [sport, setSport] = useState(undefined);
  const [positions, setPositions] = useState(getAllPositionsBasedOnSport(sorareFootball));
  const [galleryFilter, setGalleryFilter] = useState(false);

  useEffect(() => {
    if (!props.user) {
      return;
    }
    getNextGameweeks(props).then((res) => {
      let gwIds = [];
      gwIds.push({ value: 0, name: "No gameweek" });
      res.map((gw, index) => {
        if (t1OrAbove(props.user.tier) || index === 0) {
          gwIds.push({ value: gw.GwNumber, name: gw.DisplayName });
        } else {
          gwIds.push({ value: gw.GwNumber, name: gw.DisplayName, disabled: true, photo: Locker });
        }
        return null;
      });
      setGwIds(gwIds);
      setGwValue(undefined);
      const params = new URLSearchParams(props.location.search);
      let sport = params.get("sport") || props.user.preferredSport || sorareFootball;
      if (sport === "all") {
        sport = sorareFootball;
      }
      let positions = getAllPositionsBasedOnSport(sport);
      let minApps = 7;
      if (sport === sorareBasketball) {
        minApps = 100;
      } else if (sport === sorareBaseball) {
        setPositionValue(positions[0].value);
        minApps = 7;
      }
      setSport(sport);
      setPositions(positions);
      setMinApps(minApps);
    });
  }, [props.user]);

  const onChangePositionValue = (v) => {
    if (v.includes("itcher")) {
      setMinApps(1);
    } else {
      setMinApps(7);
    }
    setPositionValue(v);
  };

  const onChangePrefs = (prefs) => {
    setShowValo(prefs.showValo);
    setShowBmp(prefs.showBmp);
  };

  const onChangeGw = async (gwValue) => {
    await setGwValue(gwValue);
  };

  return (
    <div className={"space-y-4"}>
      <div
        className={"w-full lg:h-40 h-32 relative opacity-100"}
        style={{
          backgroundImage: "url('" + PlayerRankingsHeader + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={"absolute w-full lg:h-40 h-32 z-0 text-focus"} style={{ opacity: "10%" }}>
          <div className={"h-player rounded-t-xl flex lg:h-40 h-32 bg-gradient-to-r from-full-black to-white z-10"} />
        </div>
        <div className={"z-10 self-center flex mx-16 lg:h-40 h-32 align-middle flex-row justify-between"}>
          <div className={"flex flex-row"}>
            <h2 color={"brand"} className={"self-center text-left text-white z-10"}>
              Player rankings
            </h2>
          </div>
          <div className={"flex flex-row self-center z-10"}>
            <SportsPicker
              sport={sport === "all" ? sorareFootball : sport}
              change={async (s) => {
                let minApps = 7;
                if (s === sorareBaseball) {
                  minApps = 7;
                }
                if (s === sorareBasketball) {
                  minApps = 100;
                }
                let allPos = getAllPositionsBasedOnSport(s);
                await setMinApps(minApps);
                await setPositions(allPos);
                await setPositionValue(allPos[0].value);
                await setSport(s);
              }}
            />
          </div>
        </div>
      </div>
      <div className={"w-11/12 mx-auto space-y-2 "}>
        {sport === sorareFootball && (
          <div className={"grid grid-cols-4 gap-x-4 gap-y-2"}>
            <div>
              <SinglePositionPicker positions={positionValue} changePositions={(p) => setPositionValue(p)} />
            </div>
            <div>
              <EligibilitySorareCompetitionsFilter
                width={"w-full"}
                minWidth={"min-w-[6rem]"}
                displayOptions={{ shadow: "shadow" }}
                selected={eligibilityCompetitions}
                onSelect={(v) => {
                  let newLeagues = {};
                  if (v === allStarDefaultCompetition.id) {
                    newLeagues = initialEligibilityCompetitionValue;
                  } else {
                    newLeagues = { ...eligibilityCompetitions, [v]: true };
                    delete newLeagues[allStarDefaultCompetition.id]; // makes no sense to have the 'all' selected while others specific ones are selected
                  }
                  setEligibilityCompetitions(newLeagues);
                }}
                onUnselect={(v) => {
                  const newLeagues = { ...eligibilityCompetitions };
                  delete newLeagues[v];
                  if (Object.keys(newLeagues).length === 0) {
                    newLeagues[allStarDefaultCompetition.id] = true;
                  }
                  setEligibilityCompetitions(newLeagues);
                }}
              />
            </div>
            <div>
              <SelectSearch options={leagues_no_div_objects} value={leagueValue} onChange={(option) => setLeagueValue(option)} />
            </div>
            <div className={"w-full self-center"}>
              <SelectSearch
                options={gwIds}
                value={gwValue}
                placeholder={"Filter by gameweek"}
                onChange={(v) => onChangeGw(v)}
                renderOption={customOptionRender}
              />
            </div>
            <div className={"py-1.5 bg-white shadow rounded-lg self-center"}>
              <BoxWithToggle isActive={u23} label="U23 eligible" setActive={() => setU23(!u23)} />
            </div>
            <div className={"text-left space-y-2"}>
              <p className={"space-x-2 text-sm text-textGrey3 font-medium"}>
                <span>Min. games played over the last 15: {minApps}</span>
              </p>
              <Slider
                trackStyle={[
                  {
                    backgroundColor: "#f45206",
                    border: "",
                  },
                ]}
                handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                step={1}
                min={0}
                max={15}
                value={minApps}
                onChange={(value) => setMinApps(value)}
              />
            </div>
            <div className={"py-1.5 rounded-lg bg-white self-center"}>
              <BoxWithToggle
                isActive={galleryFilter}
                label="Only players from my gallery"
                setActive={() => setGalleryFilter(!galleryFilter)}
              />
            </div>
            <div className={"py-1.5 rounded-lg self-center"}>
              <ShowPreferences preferences={{ showValo, showBmp }} onChange={onChangePrefs} />
            </div>
          </div>
        )}
        {sport === sorareBaseball && (
          <div className={"flex flex-row justify-center space-x-4"}>
            <div>
              <SelectSearch options={positions} value={positionValue} onChange={(option) => onChangePositionValue(option)} />
            </div>
            <div className={"text-left space-y-2"}>
              <p className={"space-x-2 text-sm text-textGrey3 font-medium"}>
                <span>Min. games played over the last 15: {minApps}</span>
              </p>
              <Slider
                trackStyle={[
                  {
                    backgroundColor: "#f45206",
                    border: "",
                  },
                ]}
                handleStyle={[{ border: "solid 1px #f45206" }, { border: "solid 1px #f45206" }]}
                step={1}
                min={0}
                max={15}
                value={minApps}
                onChange={(value) => setMinApps(value)}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <PlayerRankingNewLook
          newMatrix={false}
          position={positionValue}
          u23={u23}
          league={leagueValue}
          eligibilityCompetitions={eligibilityCompetitions}
          daAvg={true}
          sport={sport}
          aaAvg={true}
          minApps={minApps}
          l40={false}
          showValo={showValo}
          galleryFilter={galleryFilter}
          gw={gwValue}
          showBmp={showBmp}
          filter={[]}
          new={window.location.pathname === "/rankings"}
        />
      </div>
      <ReactTooltip />
    </div>
  );
});

const ShowPreferences = (props) => {
  const { preferences, onChange, widthPanel } = props;
  const width = props.width || "w-full";
  const maxWidth = props.maxWidth || "";
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);
  const [refParent, { width: widthParent }] = useElementSize();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onChangeFocus = () => {
    const newFocus = !focus;
    if (newFocus) {
      onFocus();
    } else {
      onBlur();
    }
  };

  const onFocus = () => {
    setFocus(true);
    props.onFocus && props.onFocus();
  };

  const onBlur = () => {
    setFocus(false);
    props.onBlur && props.onBlur();
  };

  return (
    <div className={`relative h-full ${width} ${maxWidth}`} ref={ref}>
      <div ref={refParent} className={"w-full h-full"}>
        <button
          onClick={onChangeFocus}
          className={`h-full ${width} text-md my-auto bg-surface-container px-3 py-2 rounded shadow flex flex-row justify-between gap-2 hover:outline hover:outline-1 hover:outline-primary`}
        >
          <div className={"my-auto w-5/6 truncate text-left text-on-surface flex flex-row gap-1"}>
            <TuneIcon className={`${focus ? "fill-primary" : "fill-on-surface"} h-5 w-5`} />
            Preferences
          </div>
          <div className={"flex flex-row my-auto gap-2 w-1/6 justify-end"}>
            <div className={"flex flex-col justify-center"}>
              <SortArrowIcon className={`fill-on-surface h-3 w-3 ${focus ? "transform rotate-180" : ""}`} />
            </div>
          </div>
        </button>
      </div>
      {isMobile && (
        <MobileModale open={focus} close={onBlur}>
          <div className={"h-full bg-surface-container rounded-t-lg"}>
            <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
              <div className={"flex flex-row justify-between px-4 py-4"}>
                <h3 className={"font-headers text-on-surface font-semibold m-0"}>Divisions</h3>
                <div className={"flex flex-row gap-3"}>
                  <XIcon
                    className="my-auto h-6 w-6 hover:opacity-80 cursor-pointer fill-on-surface"
                    aria-hidden="true"
                    onClick={() => setFocus(false)}
                  />
                </div>
              </div>
            </div>
            <div className={"max-h-[80vh] overflow-y-auto flex flex-col"}>
              <PreferencesItems preferences={preferences} onChange={onChange} />
            </div>
          </div>
        </MobileModale>
      )}
      {focus && !isMobile && (
        <div
          style={widthPanel ? {} : { width: (widthParent || 200) + "px" }}
          className={`absolute bg-surface-container rounded-md mt-2 shadow overflow-hidden ${widthPanel ? widthPanel : ""}`}
        >
          <div className={"h-full overflow-y-scroll w-full flex flex-col"} style={{ maxHeight: "23em" }}>
            <PreferencesItems preferences={preferences} onChange={onChange} />
          </div>
        </div>
      )}
    </div>
  );
};

const PreferencesItems = (props) => {
  const { preferences, onChange } = props;
  const { showValo, showBmp } = preferences;
  return (
    <>
      <div className={"py-2 rounded-lg bg-white"}>
        <BoxWithToggle
          isActive={showValo}
          label="Show latest valuation"
          setActive={() => onChange({ ...preferences, showValo: !showValo })}
        />
      </div>
      <div className={"py-2 rounded-lg bg-white"}>
        <BoxWithToggle isActive={showBmp} label="Show floor price" setActive={() => onChange({ ...preferences, showBmp: !showBmp })} />
      </div>
    </>
  );
};
