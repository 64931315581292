import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../util/button";
import { ReactComponent as PlayerCircle } from "@material-design-icons/svg/filled/play_circle.svg";
import { ReactComponent as CloseIcon } from "../../img/icons-close-no-color.svg";

export const LearnMoreAboutLUBTiers = (props) => {
  const { onClose } = props;
  const [open, setOpen] = useState(false);

  const close = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, []);
  return (
    <div>
      {open && <Modale close={close} open={open} />}
      <div onClick={() => setOpen(true)} className={"h-10 flex items-center py-1.5 px-1 cursor-pointer hover:opacity-80"}>
        <div className={"font-semibold text-sm self-center text-primary flex flex-row gap-1 px-2"}>
          <span>Learn more</span>
          <div className={"w-5 h-5 self-center inline-flex"}>
            <PlayerCircle className={"w-full h-full fill-primary object-contain"} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Modale = (props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.open);
    ReactTooltip.rebuild();
  }, [props.open]);
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-100 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={"inline-block transform transition-all sm:my-8 sm:align-middle"}>
                <div className={"flex flex-row gap-4 justify-center"}>
                  <div
                    className={
                      "max-w-[100vw] sm:max-w-3xl relative align-bottom bg-surface-container-high rounded-lg pt-5 pb-4 text-left shadow-xl"
                    }
                  >
                    <div className="absolute top-0 right-0 pt-4 pr-4">
                      <div
                        className={"flex flex-row cursor-pointer"}
                        onClick={() => {
                          props.close();
                          setOpen(false);
                        }}
                      >
                        <CloseIcon className="fill-on-surface h-6 w-6  hover:opacity-80" />
                      </div>
                    </div>
                    <div className={"font-sans flex flex-col gap-2 text-on-surface"}>
                      <div className="font-headers px-5 border-b border-transparent-inverse-surface-low border-opacity-10 pb-4 flex flex-col justify-center items-center gap-2">
                        <p className="text-xl font-headers font-semibold m-0 text-center">Learn more</p>
                      </div>
                      <div className={"flex flex-col gap-5 justify-center px-8 w-full sm:max-w-[50vw]"}>
                        <div className={"flex flex-col gap-3"}>
                          <h4 className={"m-0 font-semibold font-header text-sm"}>Explore features of the lineup builder</h4>
                          <div className={"flex flex-row justify-center"}>
                            <div className={"flex justify-center px-8"}>
                              <iframe
                                src={"https://www.youtube.com/embed/KlmTfqsLNJo"}
                                style={{ aspectRatio: "16/9" }}
                                className={"rounded-xl sm:[70vw] md:[50vw] lg:w-[20vw]"}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                        <div className={"flex flex-col gap-3"}>
                          <h4 className={"m-0 font-semibold font-header text-sm"}>Learn about differences between subscription tiers</h4>
                          <div className={"flex flex-row justify-center"}>
                            <div className={"flex justify-center px-8"}>
                              <iframe
                                src={"https://www.youtube.com/embed/kYLPKrHArtE"}
                                style={{ aspectRatio: "16/9" }}
                                className={"rounded-xl sm:[70vw] md:[50vw] lg:w-[20vw]"}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                        <div className={"flex justify-center items-center"}>
                          <Button
                            onClick={() => {
                              props.close();
                              setOpen(false);
                            }}
                            label={"Got it!"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
