import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import SelectSearch from "react-select-search";
import { withUser } from "../../userContext";
import { withRouter } from "react-router-dom";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { isSportFree } from "../util/sports";

function EditWatchlistModal(props) {
  const privacyLabels = [
    { value: "private", name: "Private" },
    { value: "unlisted", name: "Unlisted" },
    { value: "public", name: "Public" },
  ];

  const privacyDescription = {
    private: "The watchlist will be visible only by you",
    unlisted: "The watchlist will visible by anyone with direct link, but will not be displayed in community listing",
    public: "The watchlist will be visible by anyone and will be displayed in community listing",
  };

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const canValidate = !saving && name !== undefined && name !== "" && privacy !== undefined && privacy !== "";

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setName(props.watchlist?.name || "");
    setPrivacy(props.watchlist?.privacy || "");
  }, [props.watchlist]);

  const onCancel = () => {
    props.onCancel();
    setOpen(false);
    setError("");
  };

  const onSave = () => {
    props.onSave();
    setOpen(false);
    setError("");
  };

  const editWatchlist = () => {
    if (props.watchlist) {
      setSaving(true);
      props
        .fetch(`/apiv2/watchlists/id/${props.watchlist.id}`, {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            privacy: privacy,
            name: name,
          }),
        })
        .then((response) => {
          if (response.status >= 400) {
            throw Error(response);
          } else {
            return response.json();
          }
        })
        .then((res) => {
          onSave();
          setSaving(false);
        })
        .catch((res) => {
          if (res.status >= 500) {
            setError("Internal error, please retry later");
          } else {
            setError(res.json().error);
          }
          setSaving(false);
        });
    }
  };
  const privacyChangeEnabled = t1OrAbove(props.user.tier) || isSportFree(props.watchlist.sport);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-500 inset-0 overflow-y-auto" onClose={onCancel}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-grey-f8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-0 overflow-visible">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={onCancel}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={"w-auto mt-8"}>
                  <div className={"w-full border-b-2 border-grey-e5 pb-4"}>
                    <h2 className={"text-center font-headers text-brand-black text-3xl font-bold mt-0"}>Edit watchlist</h2>
                  </div>
                  <div className={"mx-full mb-8 px-6 pt-6"}>
                    <div className={"flex flex-row space-x-2 self-center justify-center gap-2"}>
                      <div className={"w-full"}>
                        <p className={"text-sm font-semibold"}>
                          Name<span className={"text-live-red"}>*</span>
                        </p>
                        <input
                          className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
                          type={"text"}
                          placeholder={"EU best forwards..."}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className={"w-full"}>
                        <p className={"text-sm font-semibold"}>
                          Privacy<span className={"text-live-red"}>*</span>
                        </p>
                        <SelectSearch
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          closeOnSelect={false}
                          options={privacyLabels}
                          value={privacy}
                          printOptions={"on-focus"}
                          disabled={!privacyChangeEnabled}
                          onChange={privacyChangeEnabled ? (v) => setPrivacy(v) : undefined}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm">{privacyDescription[privacy]}</p>
                    </div>
                    <div className={"flex flex-row mt-4"}>
                      <div className={"ml-auto mr-0 inline"}>
                        <p className={"inline align-middle text-sm mr-3"}>
                          <span className={"text-live-red"}>*</span> indicates a required field
                        </p>
                        <button
                          disabled={!canValidate}
                          title={canValidate ? "Create watchlist" : "Needs a name, a type and a privacy"}
                          className={
                            "text-white p-2 px-16 text-sm font-medium rounded-lg " +
                            (canValidate
                              ? "bg-brand focus:outline-none hover:bg-brand-light cursor-pointer"
                              : "bg-brand-light cursor-default")
                          }
                          onClick={editWatchlist}
                        >
                          {saving ? <span>Saving...</span> : <span>Save</span>}
                        </button>
                      </div>
                    </div>
                    {error && <p className={"text-live-red"}>{error}</p>}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default withRouter(withUser(EditWatchlistModal));
