import React from "react";
import StartIcon from "../../img/starter.svg";
import Bench from "../../img/benchNotCameIn.svg";
import NotInSquad from "../../img/notInSquad.svg";
import Doubtful from "../../img/doubtful.svg";

function UserPrediction(props) {
  const userPred = props.status;

  const changeUserPred = (newPred) => {
    if (newPred === userPred) {
      props.changeStatus("NO_STATUS");
    } else {
      props.changeStatus(newPred);
    }
  };

  const activeClass = "w-7 h-7 cursor-pointer";
  const inactiveClass = "w-7 h-7 opacity-40 cursor-pointer";

  const imgClass = "w-full h-full";

  return (
    <div>
      <div className={"flex flex-row space-x-2"}>
        <div className={"flex flex-col items-center"}>
          <div onClick={() => changeUserPred("STARTER")} className={userPred === "STARTER" ? activeClass : inactiveClass}>
            <img className={imgClass} src={StartIcon} />
          </div>
        </div>
        <div>
          <div onClick={() => changeUserPred("MIGHT_NOT")} className={userPred === "MIGHT_NOT" ? activeClass : inactiveClass}>
            <img className={imgClass} src={Doubtful} />
          </div>
        </div>
        <div>
          <div onClick={() => changeUserPred("NOT_STARTER")} className={userPred === "NOT_STARTER" ? activeClass : inactiveClass}>
            <img className={imgClass} src={Bench} />
          </div>
        </div>
        <div>
          <div onClick={() => changeUserPred("OUT")} className={userPred === "OUT" ? activeClass : inactiveClass}>
            <img className={imgClass} src={NotInSquad} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPrediction;
