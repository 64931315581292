import { formatPrice } from "./formatMoney";

export function getDynamicValuation(s, currency, lowestValuation) {
  if (s === "DYN_M20") {
    return formatPrice(lowestValuation - lowestValuation * 0.2, currency);
  } else if (s === "DYN_M15") {
    return formatPrice(lowestValuation - lowestValuation * 0.15, currency);
  } else if (s === "DYN_M10") {
    return formatPrice(lowestValuation - lowestValuation * 0.1, currency);
  } else if (s === "DYN_M5") {
    return formatPrice(lowestValuation - lowestValuation * 0.05, currency);
  } else if (s === "DYN_LAT") {
    return formatPrice(lowestValuation, currency);
  } else if (s === "DYN_5") {
    return formatPrice(lowestValuation + lowestValuation * 0.05, currency);
  } else if (s === "DYN_10") {
    return formatPrice(lowestValuation + lowestValuation * 0.1, currency);
  } else if (s === "DYN_15") {
    return formatPrice(lowestValuation + lowestValuation * 0.15, currency);
  } else if (s === "DYN_20") {
    return formatPrice(lowestValuation + lowestValuation * 0.2, currency);
  }
}
