import React, { useState } from "react";
import { ReactTooltip } from "../util/tooltip";
import SDLoading from "../util/SDLoading";
import RewardCard from "../rewards/rewardCard";
import SO5PlayersOverview from "./SO5PlayersOverview";
import CarretUpWhite from "../../img/carret-vector-up-white.svg";
import { SO5LiveDivision } from "./SO5LiveDivision";
import { LongTermLeagueResultTiles } from "../longTermLeagues/longTermLeagueResultTiles";
import { Box } from "grommet";
import { withUser } from "../../userContext";
import { Dropdown } from "../util/dropdown";

export const LiveLineups = withUser((props) => {
  const { onSortChange, sortSelected, sortOptions, loading, children, sport, gw, rewards, username } = props;

  const [showOverview, setShowOverview] = useState(true);
  const [showHiddenLineups, setShowHiddenLineups] = useState(false);
  const [showLongTerm, setShowLongTerm] = useState(true);
  const canHideLineup = props.lineupHidable !== undefined ? props.lineupHidable : false;
  const canHideLongTermLeague = props.longTermLeagueHidable !== undefined ? props.longTermLeagueHidable : false;

  const hideLineup = (id) => {
    if (!canHideLineup) return;
    props
      .fetch("/apiv2/SO5/hideLineup", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lineupId: id,
          sport: sport,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        props.onHideLineup && props.onHideLineup(id);
      });
  };

  const unhideLineup = (id) => {
    if (!canHideLineup) return;
    props
      .fetch("/apiv2/SO5/unhideLineup", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lineupId: id,
          sport: sport,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        props.onUnhideLineup && props.onUnhideLineup(id);
      });
  };

  const hideLongTermLeague = (id) => {
    if (!canHideLongTermLeague) return;
    props
      .fetch(`/apiv2/longTermLeagues/${sport}/id/${id}/hide`, {
        method: "POST",
      })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        props.onHideLongTermLeague && props.onHideLongTermLeague(id);
      });
  };

  const unhideLongTermLeague = (id) => {
    if (!canHideLongTermLeague) return;
    props
      .fetch(`/apiv2/longTermLeagues/${sport}/id/${id}/unhide`, {
        method: "POST",
      })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        props.onUnhideLongTermLeague && props.onUnhideLongTermLeague(id);
      });
  };

  if (loading) {
    return (
      <div className={"bg-black-14 min-h-screen min-vh-100"}>
        <div className={"bg-black-14 flex flex-row justify-center"}>
          <SDLoading dark />
        </div>
      </div>
    );
  }
  const info = props.info ? { ...props.info } : {};
  sortInfo(info, sortSelected);
  let hiddenLineups = 0;
  if (info.lineups !== undefined && info.lineups !== null) {
    info.lineups.map((lineup) => {
      if (lineup.hidden) hiddenLineups++;
    });
  }
  let hiddenLongTermLeague = 0;
  if (info.long_term_leagues !== undefined && info.long_term_leagues !== null) {
    info.long_term_leagues.map((l) => {
      if (l.hidden) hiddenLongTermLeague++;
    });
  }
  const hiddenItems = hiddenLineups + hiddenLongTermLeague;
  const hiddenTitles = [];
  if (hiddenLineups > 0) hiddenTitles.push(hiddenLineups + " hidden lineups");
  if (hiddenLongTermLeague > 0) hiddenTitles.push(hiddenLongTermLeague + " hidden month-long competitions");

  return (
    <div className={"bg-black-14 min-h-screen min-vh-100"}>
      <ReactTooltip />
      {loading ? (
        <div className={"bg-black-14 flex flex-row justify-center"}>
          <SDLoading dark />
        </div>
      ) : info.lineups !== undefined && info.lineups !== null && info.lineups.length > 0 ? (
        <div className={"flex flex-col gap-3"}>
          <div className={"flex flex-row space-x-8 h-reward-card my-6"}>
            <div className={"w-6/12 text-center self-center justify-start z-0"}>
              <RewardCard info={info} sport={sport} />
            </div>
            <div className={"w-6/12"}>
              <SO5PlayersOverview gw={gw} username={username} sport={sport} />
            </div>
          </div>
          <Accordion
            title={"Summary"}
            show={showOverview}
            onChangeShow={() => setShowOverview(!showOverview)}
            sortOptions={sortOptions}
            sortSelected={sortSelected}
            onSortChange={onSortChange}
          >
            <div className={"mx-auto"}>
              <div className={"grid gap-4 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2.5xl:grid-cols-4 grid-rows-auto"}>
                {showOverview &&
                  info.lineups.map((lu) => {
                    if (!lu.hidden) {
                      return (
                        <SO5LiveDivision
                          key={lu.lineup.LineupId}
                          dark
                          anchor
                          hideLineup={canHideLineup ? hideLineup : undefined}
                          unhideLineup={canHideLineup ? unhideLineup : undefined}
                          info={info}
                          rewards={rewards?.[lu.lineup.LineupId]}
                          lu={lu}
                          sport={sport}
                        />
                      );
                    } else return null;
                  })}
              </div>
            </div>
          </Accordion>
          <LongTermLeagueSummary
            leagues={info.long_term_leagues?.filter((league) => league.hidden === undefined || !league.hidden)}
            hideCompetition={canHideLongTermLeague ? hideLongTermLeague : undefined}
            unhideCompetition={canHideLongTermLeague ? unhideLongTermLeague : undefined}
            user={username}
            show={showLongTerm}
            onChangeShow={() => setShowLongTerm(!showLongTerm)}
            sport={sport}
          />
          {hiddenItems > 0 && (
            <Accordion
              title={hiddenTitles.join(" & ")}
              onChangeShow={() => setShowHiddenLineups(!showHiddenLineups)}
              show={showHiddenLineups}
            >
              <div className={"mx-auto mb-8"}>
                <div
                  className={"grid gap-4 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2.5xl:grid-cols-4 grid-rows-auto flex"}
                >
                  {showHiddenLineups &&
                    info.lineups.map((lu) => {
                      if (lu.hidden) {
                        return (
                          <SO5LiveDivision
                            key={lu.lineup.LineupId}
                            dark
                            anchor
                            hideLineup={canHideLineup ? hideLineup : undefined}
                            unhideLineup={canHideLineup ? unhideLineup : undefined}
                            info={info}
                            lu={lu}
                            rewards={rewards[lu.lineup.LineupId]}
                            sport={sport}
                          />
                        );
                      } else return null;
                    })}
                  {showHiddenLineups &&
                    info.long_term_leagues?.map((league) => {
                      if (league.hidden) {
                        return (
                          <LongTermLeagueResultTiles
                            key={league.league.id}
                            league={league}
                            link={`/longTermLeagues/${sport}/leaderboard?sorareSlug=${username}&leagueId=${league.league.id}`}
                            user={username}
                            hideComp={canHideLongTermLeague ? hideLongTermLeague : undefined}
                            unhideComp={canHideLongTermLeague ? unhideLongTermLeague : undefined}
                            dark
                          />
                        );
                      } else return null;
                    })}
                </div>
              </div>
            </Accordion>
          )}
          <div className={"mt-4"}>
            <div className={"pb-4"}>
              <p className={"text-xl font-headers font-semibold text-white"}>Detailed view</p>
            </div>
            <div className={"space-y-8"}>{children}</div>
          </div>
        </div>
      ) : (
        <div>
          <Box align={"center"} margin={{ vertical: "small" }}>
            <p className={"text-base text-white"}>No lineups available for this gameweek.</p>
          </Box>
        </div>
      )}
    </div>
  );
});

const LongTermLeagueSummary = (props) => {
  const { leagues, show, onChangeShow, hideCompetition, unhideCompetition, user, sport } = props;
  if (leagues === null || leagues === undefined || leagues.length === 0) return null;
  return (
    <Accordion title={"Month-long competitions"} show={show} onChangeShow={onChangeShow}>
      <div className={"mx-auto"}>
        <div className={"grid gap-4 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2.5xl:grid-cols-4 grid-rows-auto"}>
          {show &&
            leagues.map((league) => {
              return (
                <LongTermLeagueResultTiles
                  key={league.league.id}
                  hideComp={hideCompetition}
                  unhideComp={unhideCompetition}
                  link={`/longTermLeagues/${sport}/leaderboard?sorareSlug=${user}&leagueId=${league.league.id}`}
                  user={user}
                  league={league}
                  dark
                />
              );
            })}
        </div>
      </div>
    </Accordion>
  );
};

const Accordion = (props) => {
  const { title, children, show, onChangeShow, sortOptions, sortSelected, onSortChange } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <div>
      <header
        className="flex justify-between items-center py-2 mb-2 cursor-pointer select-none border-[#4D4D4D] border-b"
        onClick={onChangeShow}
      >
        <div className={"w-8/12"}>
          <span className="font-semibold text-xl text-white font-headers">{title}</span>
        </div>
        <div className={"flex flex-row space-x-4"}>
          {sortOptions && sortOptions.length > 0 && (
            <div className={`${dropdownOpen ? "z-10" : ""} w-56`}>
              <Dropdown
                dark
                displayOptions={{
                  dark: true,
                  roundedTopFocus: "rounded-t-2xl",
                  rounded: "rounded-3xl",
                  roundedBottomFocus: "rounded-b-xl",
                }}
                selected={sortSelected}
                options={sortOptionsFromEnabled(sortOptions)}
                onChange={onSortChange}
                onBlur={() => {
                  setDropdownOpen(false);
                }}
                onFocus={() => {
                  setDropdownOpen(true);
                }}
              />
            </div>
          )}
          <div
            className={`rounded-full w-7 h-7 flex items-center justify-center ${show ? "" : "transform rotate-180"}`}
            onClick={onChangeShow}
          >
            <img style={{ width: "16px", height: "16px" }} src={CarretUpWhite} />
          </div>
        </div>
      </header>
      {children}
    </div>
  );
};

const sortOptions = [
  { value: "az", name: "A-Z order" },
  {
    value: "possible_score",
    name: "Best possible score",
  },
  { value: "curr_score", name: "Best current score" },
  { value: "possible_rank", name: "Best possible rank" },
  {
    value: "best_rank",
    name: "Best current rank",
  },
  { value: "live", name: "Highest live power" },
];

const sortOptionsFromEnabled = (enabledOptionsKeys) => {
  return sortOptions.filter((option) => enabledOptionsKeys.includes(option.value));
};

export const sortInfo = (info, sortOption) => {
  if (sortOption === "az") {
    info?.lineups?.sort(function (a, b) {
      if (a.competition.DisplayName < b.competition.DisplayName) {
        return -1;
      }
      if (a.competition.DisplayName > b.competition.DisplayName) {
        return 1;
      }
      return 0;
    });
    info?.long_term_leagues?.sort(function (a, b) {
      if (a?.league?.name < b?.league?.name) {
        return -1;
      }
      if (a?.league?.name > b?.league?.name) {
        return 1;
      }
      return 0;
    });
  } else if (sortOption === "possible_score") {
    info?.lineups?.sort(function (a, b) {
      if (parseFloat(a.lineup.MaxScore) > parseFloat(b.lineup.MaxScore)) {
        return -1;
      }
      if (parseFloat(a.lineup.MaxScore) < parseFloat(b.lineup.MaxScore)) {
        return 1;
      }
      return 0;
    });
    info?.long_term_leagues?.sort(function (a, b) {
      const aScore = parseFloat(a?.currentRanking?.leaderboard?.totalScore);
      const bScore = parseFloat(b?.currentRanking?.leaderboard?.totalScore);
      if (aScore > bScore) {
        return -1;
      }
      if (aScore < bScore) {
        return 1;
      }
      if (a?.league?.name < b?.league?.name) {
        return -1;
      }
      if (a?.league?.name > b?.league?.name) {
        return 1;
      }
      return 0;
    });
  } else if (sortOption === "curr_score") {
    info?.lineups?.sort(function (a, b) {
      if (parseFloat(a.lineup.Score) > parseFloat(b.lineup.Score)) {
        return -1;
      }
      if (parseFloat(a.lineup.Score) < parseFloat(b.lineup.Score)) {
        return 1;
      }
      return 0;
    });
    info?.long_term_leagues?.sort(function (a, b) {
      const aScore = parseFloat(a?.currentRanking?.leaderboard?.totalScore);
      const bScore = parseFloat(b?.currentRanking?.leaderboard?.totalScore);
      if (aScore > bScore) {
        return -1;
      }
      if (aScore < bScore) {
        return 1;
      }
      if (a?.league?.name < b?.league?.name) {
        return -1;
      }
      if (a?.league?.name > b?.league?.name) {
        return 1;
      }
      return 0;
    });
  } else if (sortOption === "best_rank") {
    info?.lineups?.sort(function (a, b) {
      if (parseInt(a.lineup.Rank) < parseInt(b.lineup.Rank)) {
        return -1;
      } else if (parseInt(a.lineup.Rank) > parseInt(b.lineup.Rank)) {
        return 1;
      } else {
        if (a.competition.DisplayName < b.competition.DisplayName) {
          return -1;
        }
        if (a.competition.DisplayName > b.competition.DisplayName) {
          return 1;
        }
      }
      return 0;
    });
    info?.long_term_leagues?.sort(function (a, b) {
      const aRank = a?.currentRanking?.leaderboard?.rank;
      const bRank = b?.currentRanking?.leaderboard?.rank;
      if (aRank < bRank) {
        return -1;
      }
      if (aRank > bRank) {
        return 1;
      }
      if (a?.league?.name < b?.league?.name) {
        return -1;
      }
      if (a?.league?.name > b?.league?.name) {
        return 1;
      }
      return 0;
    });
  } else if (sortOption === "possible_rank") {
    info?.lineups?.sort(function (a, b) {
      if (parseInt(a.lineup.MaxRank) < parseInt(b.lineup.MaxRank)) {
        return -1;
      } else if (parseInt(a.lineup.MaxRank) > parseInt(b.lineup.MaxRank)) {
        return 1;
      } else {
        if (a.competition.DisplayName < b.competition.DisplayName) {
          return -1;
        }
        if (a.competition.DisplayName > b.competition.DisplayName) {
          return 1;
        }
      }
      return 0;
    });
    info?.long_term_leagues?.sort(function (a, b) {
      const aRank = a?.currentRanking?.leaderboard?.rank;
      const bRank = b?.currentRanking?.leaderboard?.rank;
      if (aRank < bRank) {
        return -1;
      }
      if (aRank > bRank) {
        return 1;
      }
      if (a?.league?.name < b?.league?.name) {
        return -1;
      }
      if (a?.league?.name > b?.league?.name) {
        return 1;
      }
      return 0;
    });
  } else if (sortOption === "live") {
    info?.lineups?.sort(function (a, b) {
      if (parseFloat(a.lineup.LivePower) > parseFloat(b.lineup.LivePower)) {
        return -1;
      } else if (parseFloat(a.lineup.LivePower) < parseFloat(b.lineup.LivePower)) {
        return 1;
      } else {
        if (a.competition.DisplayName < b.competition.DisplayName) {
          return -1;
        }
        if (a.competition.DisplayName > b.competition.DisplayName) {
          return 1;
        }
      }
      return 0;
    });
    info?.long_term_leagues?.sort(function (a, b) {
      if (a?.league?.name < b?.league?.name) {
        return -1;
      }
      if (a?.league?.name > b?.league?.name) {
        return 1;
      }
      return 0;
    });
  }
};
