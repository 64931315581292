import React, { useEffect, useState } from "react";
import GLOBAL_MARGIN, { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import SelectSearch from "react-select-search";
import LeagueStandings from "./leagueStandings";
import AverageAndGames from "../util/averageAndGames";
import LimitedPoint from "../../img/limited-point.png";
import RarePoint from "../../img/rare-point.png";
import { formatPrice } from "../util/formatMoney";
import TeamRoster from "./teamRoster";
import TeamLineup from "./teamLineup";
import TeamFormations from "./teamFormations";
import TeamStatsTable from "./teamStatsTable";
import Scorers from "./scorers";
import { withUser } from "../../userContext";
import BgCard from "../../img/sd-player-header.svg";
import { errorCatcher } from "../util/errors";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { Link } from "react-router-dom";
import { t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import PlayerBlockedVideo from "../players/playerBlockedVideo";
import UnknownPlayer from "../util/unknownPlayer";
import BaseballTypePicker from "../util/baseballTypePicker";
import TeamNews from "./teamNews";
import TeamOppScores from "./teamOppScore";
import UnknownClub from "../../img/unknown_club.png";
import { currentBasketballSeason } from "../util/seasons";

function NewTeamPage(props) {
  const activeTab = props.match?.params?.tab || "overview";
  const [nbGames, setNbGames] = useState(15);
  const [teamInfo, setTeamInfo] = useState([]);
  const [lineup, setLineup] = useState([]);
  const [lastGame, setLastGame] = useState([]);
  const [comps, setComps] = useState([]);
  const [comp, setComp] = useState("");
  const [bestPlayer, setBestPlayer] = useState(undefined);
  const [startingPrices, setStartingPrices] = useState(undefined);
  const [baseballType, setBaseballType] = useState("hitting");

  useEffect(() => {
    getTeamInfo();
  }, [props.match.params.id]);

  const getTeamInfo = (nb) => {
    if (nb === undefined) nb = nbGames;
    props
      .fetch("/apiv2/teams/new_info/" + props.match.params.id + "/" + nb)
      .then((response) => response.json())
      .then((res) => {
        document.title = res.team.DisplayName;
        setTeamInfo(res);
        setLastGame(res.last_lineup);
        setLineup(res.last_lineup.lineup);
        setComps(res.comps);
        setComp(res.comps[0]);
        setBestPlayer(res.best_player);
        setStartingPrices(res.starting_prices);
        if (res.team.Sport === sorareBasketball) {
          setNbGames(10);
        }
      })
      .catch(errorCatcher());
  };
  const getCompInfo = (comp) => {
    props
      .fetch("/apiv2/teams/infoComp/" + props.match.params.id + "/" + comp)
      .then((response) => response.json())
      .then((res) => {
        document.title = res.team.DisplayName;
        setTeamInfo(res);
        setLastGame(res.last_lineup);
        setLineup(res.last_lineup.lineup);
      })
      .catch(errorCatcher());
  };

  const team = teamInfo?.team;
  let colorStart = teamInfo?.header_color_start;
  let colorEnd = teamInfo?.header_color_end;
  if (colorStart === "") {
    colorStart = "#132D8F";
    colorEnd = "#1D3EBD";
  }
  const topAverages = teamInfo?.top_averages;
  const topAllAround = teamInfo?.top_all_around;
  let topMinutes = teamInfo?.top_minutes;
  const topScorers = teamInfo?.top_scorers;
  let topAssists = teamInfo?.top_assists;
  const topCorners = teamInfo?.top_corner_takers;
  const topDecisiveActions = teamInfo?.top_decisive;
  const topPK = teamInfo?.top_penalty_takers;
  const topFK = teamInfo?.direct_freekick_takers;
  const topFKA = teamInfo?.top_deadball_assists;
  const sport = team?.Sport;

  const topBattingAverages = teamInfo?.top_averages;
  const topHomeRuns = teamInfo?.top_home_runs;
  const topSingles = teamInfo?.top_singles;
  const topDoubles = teamInfo?.top_doubles;
  const topTriples = teamInfo?.top_triples;
  const topRbis = teamInfo?.top_rbis;
  const topWoba = teamInfo?.top_woba;
  const topRuns = teamInfo?.top_runs;
  const topWalks = teamInfo?.top_walks;
  const topBattingStrikeouts = teamInfo?.top_batting_strikeouts;
  const topStrikeouts = teamInfo?.top_strikeouts;
  const topStolenBases = teamInfo?.stolen_bases;
  const topWhip = teamInfo?.top_whip;
  const topWalksAllowed = teamInfo?.top_walks_allowed;
  const topFip = teamInfo?.top_fip;
  const topEra = teamInfo?.top_era;

  // basketball stats
  let basketballStats = teamInfo.l10Stats;
  if (nbGames === 10) basketballStats = teamInfo.l10Stats;
  if (nbGames === 40) basketballStats = teamInfo.l40Stats;
  if (nbGames === 2022) basketballStats = teamInfo.seasonStats;
  let topPoints = basketballStats?.topPoints;
  let topRebounds = basketballStats?.topRebounds;
  if (sport === sorareBasketball) {
    topAssists = basketballStats?.topAssists;
    topMinutes = basketballStats?.topMinutes;
  }
  let topSteals = basketballStats?.topSteals;
  let topBlocks = basketballStats?.topBlocks;
  let topTurnovers = basketballStats?.topTurnovers;
  let topScore = basketballStats?.topScore;
  let topFppm = basketballStats?.topFPPM;

  const inactiveClass =
    "text-base border-b-4 border-brand-black py-2 px-6 block font-medium text-textGrey4 hover:text-white focus:outline-none";
  const activeClass = "text-base border-b-4 border-brand py-2 px-6 font-medium focus:outline-none";

  const active =
    "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-brand-pastel text-xs md:text-sm font-medium text-gray-700 focus:outline-none";
  const inactive =
    "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-xs md:text-sm font-medium text-gray-700 hover:bg-brand-pastel focus:outline-none";
  return (
    <div className={"w-full"}>
      <div
        className={"md:h-team-header"}
        style={{
          background: "linear-gradient(to left," + colorStart + ", " + colorEnd + ")",
        }}
      >
        <div className={"absolute w-full md:h-team-header z-0 text-focus overflow-hidden"} style={{ opacity: "10%" }}>
          <div className={"h-player flex w-full md:h-team-header bg-gradient-to-b md:bg-gradient-to-r from-white to-full-black z-0"}>
            <img className={"absolute transform left-1/2 -translate-x-1/2 object-contain z-0"} src={BgCard} />
          </div>
        </div>

        <div
          className={
            GLOBAL_MARGIN_NO_BG_NO_PAD + " h-auto pb-4 md:pb-0 md:h-team-header space-x-4 mx-auto flex flex-col md:flex-row justify-between"
          }
        >
          <div className={"flex flex-row justify-center my-4 md:my-0 space-x-8 z-10"}>
            <div className={"w-16 h-16 md:w-32 md:h-32 self-center"}>
              <img className={"object-contain w-full h-full"} src={team?.PictureUrl !== "" ? team?.PictureUrl : UnknownClub} />
            </div>
            <div className={"self-center flex flex-col text-white space-y-2"}>
              <p className={"text-white text-xl md:text-4xl font-semibold"}>
                {team?.DisplayName} {team?.ClubName !== team?.DisplayName && "(" + team?.ClubName + ")"}
              </p>
              <div className={"space-y-1"}>
                {comps !== null &&
                  comps.map((c) => {
                    if (c.value !== "all") {
                      return (
                        <p className={"text-lg font-medium hover:font-semibold"}>
                          <a href={"/comp/" + c.value} target={"_blank"} rel="noreferrer">
                            {c.name}
                          </a>
                        </p>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>
          </div>
          <div className={"flex self-center flex-row space-x-8 z-10"}>
            {bestPlayer && sport !== sorareBasketball && (
              <div
                className={
                  "flex pt-2 w-40 rounded-lg h-team-header-mini flex-col justify-around items-center bg-white self-center align-middle"
                }
              >
                <div className={"w-16 h-16"}>
                  {bestPlayer.player.Avatar !== "" ? (
                    <img
                      className={
                        sport === sorareFootball
                          ? "object-contain h-full w-full rounded-full pt-1"
                          : "object-cover h-full w-full rounded-full"
                      }
                      src={bestPlayer.player.Avatar}
                    />
                  ) : (
                    <UnknownPlayer />
                  )}
                </div>
                <div className={"flex flex-col space-y-2 pt-2 pb-6 px-4"}>
                  <p className={"text-center font-semibold text-sm"}>
                    <a href={"/player/" + bestPlayer.player.PlayerId}>{bestPlayer.player.DisplayName}</a>
                  </p>
                  <div className={"flex flex-row space-x-4"}>
                    <AverageAndGames
                      avg={bestPlayer.avg5}
                      powerAdj={false}
                      totalGms={5}
                      gms={bestPlayer.l5}
                      sport={bestPlayer.player.Sport}
                    />
                    <AverageAndGames
                      avg={bestPlayer.avg15}
                      powerAdj={false}
                      totalGms={15}
                      gms={bestPlayer.l15}
                      sport={bestPlayer.player.Sport}
                    />
                    <AverageAndGames
                      avg={bestPlayer.avg40}
                      powerAdj={false}
                      totalGms={40}
                      gms={bestPlayer.l40}
                      sport={bestPlayer.player.Sport}
                    />
                  </div>
                </div>
                <div className={"text-center rounded-b-lg py-2 text-brand bg-grey-f2 w-full"}>
                  <p className={"text-sm font-semibold"}>Best player</p>
                </div>
              </div>
            )}
            {bestPlayer && sport === sorareBasketball && (
              <div
                className={
                  "flex pt-2 w-48 rounded-lg h-team-header-mini flex-col justify-around items-center bg-white self-center align-middle"
                }
              >
                <div className={"w-12 h-12"}>
                  {bestPlayer.player.Avatar !== "" ? (
                    <img className={"object-cover h-full w-full rounded-full"} src={bestPlayer.player.Avatar} />
                  ) : (
                    <UnknownPlayer />
                  )}
                </div>
                <div className={"flex flex-col space-y-2 pt-2 pb-2 px-4"}>
                  <p className={"text-center font-semibold text-sm"}>
                    <a href={"/player/" + bestPlayer.player.PlayerId}>{bestPlayer.player.DisplayName}</a>
                  </p>
                  <div className={"flex flex-row space-x-4"}>
                    <AverageAndGames
                      avg={bestPlayer.avg5}
                      powerAdj={false}
                      totalGms={5}
                      gms={bestPlayer.l5}
                      sport={bestPlayer.player.Sport}
                    />
                    <AverageAndGames
                      avg={bestPlayer.avg15}
                      powerAdj={false}
                      totalGms={15}
                      gms={bestPlayer.l15}
                      sport={bestPlayer.player.Sport}
                    />
                    <AverageAndGames
                      avg={bestPlayer.avg40}
                      powerAdj={false}
                      totalGms={40}
                      gms={bestPlayer.l40}
                      sport={bestPlayer.player.Sport}
                    />
                  </div>
                </div>
                <div className={"text-center rounded-b-lg py-2 text-brand bg-grey-f2 w-full"}>
                  <p className={"text-sm font-semibold"}>Best player</p>
                </div>
              </div>
            )}
            {startingPrices && sport === sorareFootball && (
              <div className={"flex pt-2 w-40 h-team-header-mini rounded-lg flex-col justify-around items-center bg-white self-center "}>
                <div className={"p-4"}>
                  <p className={"text-sm font-semibold text-textGrey3"}>Total valuation</p>
                </div>
                <div className={"flex flex-col space-y-2 pt-1 pb-5 px-4"}>
                  <div className={"flex flex-row space-x-2"}>
                    <img className={"w-3 h-3 self-start mt-2"} src={LimitedPoint} />
                    <div className={"flex flex-col"}>
                      <p className={"text-limited font-semibold text-lg"}>{formatPrice(startingPrices.limited, "eth")}</p>
                      <p className={"font-semibold text-sm"}>{formatPrice(startingPrices.limited_eur, "eur")}</p>
                    </div>
                  </div>
                  <div className={"flex flex-row space-x-2"}>
                    <img className={"w-3 h-3 self-start mt-2"} src={RarePoint} />
                    <div className={"flex flex-col"}>
                      <p className={"text-rare font-semibold text-lg"}>{formatPrice(startingPrices.rare, "eth")}</p>
                      <p className={"font-semibold text-sm"}>{formatPrice(startingPrices.rare_eur, "eur")}</p>
                    </div>
                  </div>
                </div>
                <div className={"flex flex-row justify-center text-center self-end rounded-b-lg py-2 text-brand bg-grey-f2 w-full"}>
                  <p className={"text-sm font-semibold"}>Starting XI price</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={"bg-brand-black"}>
        <div className={GLOBAL_MARGIN_NO_BG_NO_PAD + " mb-6 text-focus"}>
          <nav className="lg:flex lg:flex-row lg:block grid grid-cols-2">
            <Link to={`/team/${props.match.params.id}/sections/overview`}>
              <button className={activeTab === "overview" ? activeClass : inactiveClass}>Overview</button>
            </Link>
            <Link to={`/team/${props.match.params.id}/sections/roster`}>
              <button className={activeTab === "roster" ? activeClass : inactiveClass}>Roster</button>
            </Link>
            {sport === sorareFootball && (
              <Link to={`/team/${props.match.params.id}/sections/formations`}>
                <button className={activeTab === "formations" ? activeClass : inactiveClass}>Formations</button>
              </Link>
            )}
            {sport === sorareFootball && (
              <Link to={`/team/${props.match.params.id}/sections/opp-scores`}>
                <button className={activeTab === "opp-scores" ? activeClass : inactiveClass}>Opponent scores</button>
              </Link>
            )}
            {sport !== sorareBasketball && (
              <Link to={`/team/${props.match.params.id}/sections/stats`}>
                <button className={activeTab === "stats" ? activeClass : inactiveClass}>Stats</button>
              </Link>
            )}
            {sport === sorareBasketball && (
              <Link to={`/team/${props.match.params.id}/sections/news`}>
                <button className={activeTab === "news" ? activeClass : inactiveClass}>News</button>
              </Link>
            )}
          </nav>
        </div>
      </div>

      {activeTab === "overview" && (
        <div className={GLOBAL_MARGIN + " flex 1.5xl:flex-row flex-col space-y-4 1.5xl:space-x-8"}>
          <div className={sport === sorareBaseball || sport === sorareBasketball ? "w-full space-y-4" : "1.5xl:w-6/12 space-y-4 w-full"}>
            {sport === sorareFootball && (
              <div className={"flex flex-col justify-center items-center md:flex-row space-y-2 md:space-y-0 md:justify-between z-20"}>
                <div className={"z-20"}>
                  {comps !== null && (
                    <SelectSearch
                      options={comps}
                      value={comp}
                      closeOnSelect={true}
                      onChange={(v) => {
                        getCompInfo(v);
                        setComp(v);
                      }}
                    />
                  )}
                </div>

                <div>
                  <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                      onClick={() => {
                        setNbGames(5);
                        getTeamInfo(5);
                      }}
                      type="button"
                      className={nbGames === 5 ? "rounded-l-lg " + active : "rounded-l-lg " + inactive}
                    >
                      Last 5
                    </button>
                    <button
                      onClick={() => {
                        setNbGames(15);
                        getTeamInfo(15);
                      }}
                      type="button"
                      className={nbGames === 15 ? active : inactive}
                    >
                      Last 15
                    </button>
                    <button
                      onClick={() => {
                        setNbGames(40);
                        getTeamInfo(40);
                      }}
                      type="button"
                      className={nbGames === 40 ? "rounded-r-lg " + active : "rounded-r-lg " + inactive}
                    >
                      Last 40
                    </button>
                  </span>
                </div>
              </div>
            )}
            {sport === sorareBasketball && (
              <div className={"flex flex-col justify-center items-center md:flex-row space-y-2 md:space-y-0 md:justify-between z-20"}>
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    onClick={() => {
                      setNbGames(10);
                    }}
                    type="button"
                    className={nbGames === 10 ? "rounded-l-lg " + active : "rounded-l-lg " + inactive}
                  >
                    Last 10
                  </button>
                  <button
                    onClick={() => {
                      setNbGames(40);
                    }}
                    type="button"
                    className={nbGames === 40 ? active : inactive}
                  >
                    Last 40
                  </button>
                  <button
                    onClick={() => {
                      setNbGames(currentBasketballSeason);
                    }}
                    type="button"
                    className={nbGames === currentBasketballSeason ? "rounded-r-lg " + active : "rounded-r-lg " + inactive}
                  >
                    {currentBasketballSeason}
                  </button>
                </span>
              </div>
            )}
            {sport === sorareFootball && (
              <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-2 gap-4"}>
                <Scorers scorers={topAverages} title={"Top SO5 averages"} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topAllAround} title={"All-around averages"} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topDecisiveActions} title={"Top net decisive actions"} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topMinutes} title={"Top minutes played"} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topScorers} title={"Top goalscorers"} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topAssists} title={"Top assists"} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topPK} title={"Penalty kicks"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topFK} title={"Freekick scorers"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topFKA} title={"Freekick assists"} more={true} bgStart={colorStart} bgEnd={colorEnd} />
                <Scorers scorers={topCorners} title={"Corner takers"} bgStart={colorStart} bgEnd={colorEnd} />
              </div>
            )}
            {sport === sorareBaseball && (
              <div className={"flex flex-row justify-end"}>
                <BaseballTypePicker type={baseballType} change={(t) => setBaseballType(t)} />
              </div>
            )}
            {sport === sorareBaseball && baseballType === "hitting" && (
              <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 gap-4"}>
                <Scorers
                  scorers={topBattingAverages}
                  title={"Batting averages"}
                  bgStart={colorStart}
                  bgEnd={colorEnd}
                  baseball={true}
                  showPct={true}
                  sport={sport}
                />
                <Scorers scorers={topWoba} title={"wOBA"} bgStart={colorStart} bgEnd={colorEnd} baseball={true} sport={sport} />
                <Scorers scorers={topHomeRuns} title={"Home runs"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topRbis} title={"RBIs"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topStolenBases} title={"Stolen Bases"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topRuns} title={"Runs"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topBattingStrikeouts} title={"Strikeouts"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topWalks} title={"Walks"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topTriples} title={"Triples"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topDoubles} title={"Doubles"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
                <Scorers scorers={topSingles} title={"Singles"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
              </div>
            )}
            {sport === sorareBaseball && baseballType === "pitching" && (
              <div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 gap-4"}>
                <Scorers scorers={topEra} title={"ERA"} bgStart={colorStart} bgEnd={colorEnd} precision={2} sport={sport} />
                <Scorers scorers={topFip} title={"FIP"} bgStart={colorStart} bgEnd={colorEnd} precision={2} sport={sport} />
                <Scorers scorers={topWhip} title={"WHIP"} bgStart={colorStart} bgEnd={colorEnd} precision={3} sport={sport} />
                <Scorers scorers={topStrikeouts} title={"Strikeouts"} bgStart={colorStart} bgEnd={colorEnd} sport={sport} />
              </div>
            )}
            {sport === sorareBasketball && (
              <div className={"w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 gap-4"}>
                <Scorers scorers={topScore} title={"Fantasy pts/game"} bgStart={colorStart} bgEnd={colorEnd} precision={1} sport={sport} />
                <Scorers scorers={topFppm} title={"Fantasy pts/minute"} bgStart={colorStart} bgEnd={colorEnd} precision={2} sport={sport} />
                <Scorers scorers={topPoints} title={"Points/game"} bgStart={colorStart} bgEnd={colorEnd} precision={1} sport={sport} />
                <Scorers scorers={topRebounds} title={"Rebounds/game"} bgStart={colorStart} bgEnd={colorEnd} precision={1} sport={sport} />
                <Scorers scorers={topMinutes} title={"Minutes/game"} bgStart={colorStart} bgEnd={colorEnd} precision={1} sport={sport} />
                <Scorers scorers={topAssists} title={"Assists/game"} bgStart={colorStart} bgEnd={colorEnd} precision={1} sport={sport} />
                <Scorers
                  scorers={topTurnovers}
                  title={"Turnovers/game"}
                  bgStart={colorStart}
                  bgEnd={colorEnd}
                  precision={1}
                  sport={sport}
                />
              </div>
            )}
          </div>
          {sport === sorareFootball && (
            <div
              className={
                "1.5xl:w-6/12 1.5xl:ml-8 ml-0 md:space-x-8 1.5xl:space-y-4 md:space-y-0 1.5xl:space-x-0 w-full flex flex-col md:flex-row 1.5xl:flex-col"
              }
            >
              <div className={"w-full md:w-6/12 1.5xl:w-full"}>
                <TeamLineup lineup={lineup} game={lastGame} sport={sorareFootball} />
              </div>
              {teamInfo?.standings !== undefined && teamInfo?.standings !== null && (
                <div className={"w-full 1.5xl:mt-8 md:w-6/12 1.5xl:w-full"}>
                  <LeagueStandings standings={teamInfo.standings} hover={team.TeamId} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {activeTab === "roster" && (
        <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
          <TeamRoster teamId={props.match.params.id} sport={sport} />
        </div>
      )}
      {activeTab === "news" && (
        <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
          <TeamNews teamId={props.match.params.id} sport={sport} />
        </div>
      )}
      {activeTab === "formations" && t2OrAbove(props.user.tier) && (
        <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
          <TeamFormations teamId={props.match.params.id} />
        </div>
      )}
      {activeTab === "formations" && t1OrBelow(props.user.tier) && (
        <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
          <PlayerBlockedVideo
            title="Want more insights?"
            description="Become a Star member to unlock formations tab"
            videoLink="https://www.youtube.com/embed/ko_m7bQ3ZX8"
          />
        </div>
      )}
      {activeTab === "stats" && (t2OrAbove(props.user.tier) || sport === sorareBaseball) && (
        <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
          <TeamStatsTable teamId={props.match.params.id} comps={comps} sport={sport} />
        </div>
      )}
      {activeTab === "stats" && t1OrBelow(props.user.tier) && sport !== sorareBaseball && (
        <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
          <PlayerBlockedVideo
            title="Want more insights?"
            description="Become a Star member to unlock stats tab"
            videoLink="https://www.youtube.com/embed/w4_Yplk6z_k"
          />
        </div>
      )}
      {activeTab === "opp-scores" &&
        sport === sorareFootball &&
        (t1OrBelow(props.user.tier) ? (
          <PlayerBlockedVideo
            title="Want more insights?"
            description="Become a Star member to unlock team opp scores tab"
            videoLink="https://www.youtube.com/embed/w4_Yplk6z_k"
          />
        ) : (
          <div className={"w-11/12 md:w-10/12 mx-auto lg:mx-auto lg:w-11/12 2xl:w-10/12 2xl:mx-auto 4xl:w-8/12 bg-background-grey"}>
            <TeamOppScores team={props.match.params.id} />
          </div>
        ))}
    </div>
  );
}

export default withUser(NewTeamPage);
