import { sorareBaseball, sorareBasketball, sorareFootball } from "./sports";
import { nbaDivisionsObjects, nbaDivisionsObjectsWithAll } from "./nbaDivisions";

export const leagues_with_divisions = [
  "All leagues",
  "Global All-Star",
  "Champion Europe",
  "Challenger Europe",
  "Champion America",
  "Champion Asia",
  "Eredivisie",
  "Jupiler Pro League",
  "K League 1",
  "Major League Soccer",
  "Bundesliga",
  "French League 1",
  "Serie A",
  "LaLiga Santander",
  "Primeira Liga",
  "Austrian Bundesliga",
  "English Premier League",
  "Russian Premier League",
  "J1 League",
  "Superliga Argentina de Fútbol",
  "Liga MX",
  "Spor Toto Süper Lig ",
  "Campeonato Brasileiro Série A",
  "Super League",
  "Superliga",
  "1. HNL",
  "Premiership",
  "Eliteserien",
  "2. Bundesliga",
  "French League 2",
  "Serie B",
  "Football League Championship",
  "LaLiga SmartBank",
];

export const leagues_with_divisions_objects = [
  { name: "All leagues", value: "all" },
  { name: "Global All-Star", value: "Global All-Star" },
  { name: "Champion Europe", value: "Champion Europe" },
  { name: "Challenger Europe", value: "Challenger Europe" },
  { name: "America", value: "Champion America" },
  { name: "Asia", value: "Champion Asia" },
  { name: "Second Division Europe", value: "Second Division Europe" },
  { name: "Eredivisie", value: "Eredivisie" },
  { name: "Jupiler Pro League", value: "Jupiler Pro League" },
  { name: "K League 1", value: "K League 1" },
  { name: "Major League Soccer", value: "Major League Soccer" },
  { name: "Bundesliga", value: "Bundesliga" },
  { name: "French League 1", value: "French League 1" },
  { name: "LaLiga Santander", value: "LaLiga Santander" },
  { name: "Primeira Liga", value: "Primeira Liga" },
  { name: "Austrian Bundesliga", value: "Austrian Bundesliga" },
  { name: "English Premier League", value: "English Premier League" },
  { name: "Russian Premier League", value: "Russian Premier League" },
  { name: "J1 League", value: "J1 League" },
  {
    name: "Superliga Argentina de Fútbol",
    value: "Superliga Argentina de Fútbol",
  },
  { name: "Liga MX", value: "Liga MX" },
  { name: "Serie A", value: "Serie A" },
  {
    name: "Campeonato Brasileiro Série A",
    value: "Campeonato Brasileiro Série A",
  },
  { name: "Spor Toto Süper Lig ", value: "Spor Toto Süper Lig " },
  { name: "Super League", value: "Super League" },
  { name: "Superliga", value: "Superliga" },
  { name: "Primera División del Perú", value: "Primera División del Perú" },
  { name: "1. HNL", value: "1. HNL" },
  { name: "Premiership", value: "Premiership" },
  { name: "Chinese Super League", value: "Chinese Super League" },
  { name: "Primera División de Chile", value: "Primera División de Chile" },
  { name: "Liga Pro", value: "Liga Pro" },
  { name: "Division Profesional", value: "Division Profesional" },
  { name: "Eliteserien", value: "Eliteserien" },
  { name: "2. Bundesliga", value: "2. Bundesliga" },
  { value: "French League 2", name: "French League 2" },
  { value: "Serie B", name: "Serie B" },
  {
    name: "Football League Championship",
    value: "Football League Championship",
  },
  { value: "LaLiga SmartBank", name: "LaLiga SmartBank" },
];

export const leagues_with_divisions_objects_not_all_star = [
  { name: "Champion Europe", value: "Champion Europe" },
  { name: "Challenger Europe", value: "Challenger Europe" },
  { name: "America", value: "Champion America" },
  { name: "Asia", value: "Champion Asia" },
  { name: "Second Division Europe", value: "Second Division Europe" },
  { name: "Eredivisie", value: "Eredivisie" },
  { name: "Jupiler Pro League", value: "Jupiler Pro League" },
  { name: "K League 1", value: "K League 1" },
  { name: "Major League Soccer", value: "Major League Soccer" },
  { name: "Bundesliga", value: "Bundesliga" },
  { name: "French League 1", value: "French League 1" },
  { name: "LaLiga Santander", value: "LaLiga Santander" },
  { name: "Primeira Liga", value: "Primeira Liga" },
  { name: "Austrian Bundesliga", value: "Austrian Bundesliga" },
  { name: "English Premier League", value: "English Premier League" },
  { name: "Russian Premier League", value: "Russian Premier League" },
  { name: "J1 League", value: "J1 League" },
  {
    name: "Superliga Argentina de Fútbol",
    value: "Superliga Argentina de Fútbol",
  },
  { name: "Liga MX", value: "Liga MX" },
  { name: "Serie A", value: "Serie A" },
  {
    name: "Campeonato Brasileiro Série A",
    value: "Campeonato Brasileiro Série A",
  },
  { name: "Spor Toto Süper Lig ", value: "Spor Toto Süper Lig " },
  { name: "Super League", value: "Super League" },
  { name: "Superliga", value: "Superliga" },
  { name: "Primera División del Perú", value: "Primera División del Perú" },
  { name: "1. HNL", value: "1. HNL" },
  { name: "Premiership", value: "Premiership" },
  { name: "Chinese Super League", value: "Chinese Super League" },
  { name: "Primera División de Chile", value: "Primera División de Chile" },
  { name: "Liga Pro", value: "Liga Pro" },
  { name: "Division Profesional", value: "Division Profesional" },
  { name: "Eliteserien", value: "Eliteserien" },
  { name: "2. Bundesliga", value: "2. Bundesliga" },
  { value: "French League 2", name: "French League 2" },
  { value: "Serie B", name: "Serie B" },
  {
    name: "Football League Championship",
    value: "Football League Championship",
  },
  { value: "LaLiga SmartBank", name: "LaLiga SmartBank" },
];

export const leagues_with_divisions_objects_not_all = [
  { name: "Global All-Star", value: "Global All-Star" },
  ...leagues_with_divisions_objects_not_all_star,
];

export const leagues_champions = [
  { name: "English Premier League", value: "English Premier League" },
  { name: "Bundesliga", value: "Bundesliga" },
  { name: "LaLiga Santander", value: "LaLiga Santander" },
  { name: "French League 1", value: "French League 1" },
  { name: "Serie A", value: "Serie A" },
];

export const leagues_challengers = [
  { name: "Eredivisie", value: "Eredivisie" },
  { name: "Jupiler Pro League", value: "Jupiler Pro League" },
  { name: "Spor Toto Süper Lig ", value: "Spor Toto Süper Lig " },
  { name: "Primeira Liga", value: "Primeira Liga" },
  { name: "Major League Soccer", value: "Major League Soccer" },
  { name: "Superliga", value: "Superliga" },
  {
    name: "Football League Championship",
    value: "Football League Championship",
  },
  { name: "K League 1", value: "K League 1" },
];

export const leagues_contenders = [
  { name: "2. Bundesliga", value: "2. Bundesliga" },
  { value: "LaLiga SmartBank", name: "LaLiga SmartBank" },
  { value: "French League 2", name: "French League 2" },
  { value: "Serie B", name: "Serie B" },
  { name: "Austrian Bundesliga", value: "Austrian Bundesliga" },
  { name: "Russian Premier League", value: "Russian Premier League" },
  { name: "J1 League", value: "J1 League" },
  {
    name: "Superliga Argentina de Fútbol",
    value: "Superliga Argentina de Fútbol",
  },
  { name: "Liga MX", value: "Liga MX" },
  {
    name: "Campeonato Brasileiro Série A",
    value: "Campeonato Brasileiro Série A",
  },
  { name: "Super League", value: "Super League" },
  { name: "Primera División del Perú", value: "Primera División del Perú" },
  { name: "1. HNL", value: "1. HNL" },
  { name: "Premiership", value: "Premiership" },
  { name: "Chinese Super League", value: "Chinese Super League" },
  { name: "Primera División de Chile", value: "Primera División de Chile" },
  { name: "Liga Pro", value: "Liga Pro" },
  { name: "Division Profesional", value: "Division Profesional" },
  { name: "Eliteserien", value: "Eliteserien" },
];

export const leagues_no_div_objects_not_all = [...leagues_champions, ...leagues_challengers, ...leagues_contenders];

export const leagues_no_div_objects = [{ name: "All leagues", value: "all" }, ...leagues_no_div_objects_not_all];

/**
 * @deprecated
 */
export const champion_europe_leagues_objects = [
  { name: "Champion Europe", value: "Champion Europe" },
  { name: "Bundesliga", value: "Bundesliga" },
  { name: "French League 1", value: "French League 1" },
  { name: "LaLiga Santander", value: "LaLiga Santander" },
  { name: "English Premier League", value: "English Premier League" },
  { name: "Serie A", value: "Serie A" },
];

/**
 * @deprecated
 */
export const second_division_europe_objects = [
  { name: "2. Bundesliga", value: "2. Bundesliga" },
  { value: "French League 2", name: "French League 2" },
  { value: "Serie B", name: "Serie B" },
  {
    name: "Football League Championship",
    value: "Football League Championship",
  },
  { value: "LaLiga SmartBank", name: "LaLiga SmartBank" },
];

/**
 * @deprecated
 */
export const challenger_europe_leagues_objects = [
  { name: "Challenger Europe", value: "Challenger Europe" },
  { name: "Eredivisie", value: "Eredivisie" },
  { name: "Jupiler Pro League", value: "Jupiler Pro League" },
  { name: "Primeira Liga", value: "Primeira Liga" },
  { name: "Austrian Bundesliga", value: "Austrian Bundesliga" },
  { name: "Superliga", value: "Superliga" },
  { name: "Spor Toto Süper Lig ", value: "Spor Toto Süper Lig " },
  { name: "Russian Premier League", value: "Russian Premier League" },
  { name: "Super League", value: "Super League" },
  { name: "1. HNL", value: "1. HNL" },
  { name: "Premiership", value: "Premiership" },
  { name: "Eliteserien", value: "Eliteserien" },
];
/**
 * @deprecated
 */
export const champion_asia_leagues_objects = [
  { name: "Asia", value: "Champion Asia" },
  { name: "K League 1", value: "K League 1" },
  { name: "J1 League", value: "J1 League" },
  { name: "Chinese Super League", value: "Chinese Super League" },
];
/**
 * @deprecated
 */
export const champion_america_leagues_objects = [
  { name: "America", value: "Champion America" },
  { name: "Major League Soccer", value: "Major League Soccer" },
  {
    name: "Superliga Argentina de Fútbol",
    value: "Superliga Argentina de Fútbol",
  },
  { name: "Liga MX", value: "Liga MX" },
  {
    name: "Campeonato Brasileiro Série A",
    value: "Campeonato Brasileiro Série A",
  },
  { name: "Primera División del Perú", value: "Primera División del Perú" },
  { name: "Primera División de Chile", value: "Primera División de Chile" },
  { name: "Liga Pro", value: "Liga Pro" },
  { name: "Division Profesional", value: "Division Profesional" },
];

// Keys don't change, but name can change depending on the gameweek
// So we associate keys with generic names
export const generic_so5_divisions = {
  "global-all_star-division-1": "All Star Unique",
  "global-all_star-division-2": "All Star Super Rare",
  "global-all_star-division-3": "All Star Rare Pro",
  "global-all_star-division-4": "All Star Rare",
  "global-all_star-division-5": "All Star Limited",
  "global-under_twenty_one-division-1": "U23 Unique",
  "global-under_twenty_one-division-2": "U23 Super Rare",
  "global-under_twenty_one-division-3": "U23 Rare Pro",
  "global-under_twenty_one-division-4": "U23 Rare",
  "global-under_twenty_one-division-5": "U23 Limited",
  "global-unique_only-division-1": "Unique only",
  "champion-europe-division-1": "Champion Europe Unique",
  "champion-europe-division-2": "Champion Europe Super Rare",
  "champion-europe-division-3": "Champion Europe Rare Pro",
  "champion-europe-division-4": "Champion Europe Rare",
  "champion-europe-division-5": "Champion Europe Limited",
  "challenger-europe-division-1": "Challenger Europe Unique",
  "challenger-europe-division-2": "Challenger Europe Super Rare",
  "challenger-europe-division-3": "Challenger Europe Rare Pro",
  "challenger-europe-division-4": "Challenger Europe Rare",
  "challenger-europe-division-5": "Challenger Europe Limited",
  "champion-asia-division-1": "Champion Asia Unique",
  "champion-asia-division-2": "Champion Asia Super Rare",
  "champion-asia-division-3": "Champion Asia Rare Pro",
  "champion-asia-division-4": "Champion Asia Rare",
  "champion-asia-division-5": "Champion Asia Limited",
  "america-division-1": "Champion America Unique",
  "america-division-2": "Champion America Super Rare",
  "america-division-3": "Champion America Rare Pro",
  "america-division-4": "Champion America Rare",
  "america-division-5": "Champion America Limited",
  "special-weekly-division-1": "Special Weekly n°1",
  "special-weekly-division-2": "Special Weekly n°2",
  "special-weekly-division-3": "Special Weekly n°3",
  "special-weekly-division-4": "Special Weekly n°4",
  "special-weekly-division-5": "Special Weekly n°5",
  "special-weekly-division-6": "Legends Challenge",
  "starter-rookie-division-1": "Casual League",
  "starter-academy-division-1": "The Academy",
};

export const so5_leagues_objects = [
  { name: "Global All-Star", value: "Global All-Star" },
  { name: "Global U23", value: "Global U23" },
  { name: "Champion Europe", value: "Champion Europe" },
  { name: "Challenger Europe", value: "Challenger Europe" },
  { name: "America", value: "America" },
  { name: "Asia", value: "Asia" },
  { name: "Second Division Europe", value: "Second Division Europe" },
];

export const so5_leagues_objects_no_U23 = [
  { name: "Global All-Star", value: "Global All-Star" },
  { name: "Champion Europe", value: "Champion Europe" },
  { name: "Challenger Europe", value: "Challenger Europe" },
  { name: "America", value: "America" },
  { name: "Asia", value: "Asia" },
  { name: "Second Division Europe", value: "Second Division Europe" },
];

export const so5_division_types_objects = [
  { name: "Limited", value: "Limited" },
  { name: "Rare", value: "Rare" },
  { name: "Rare Pro", value: "Rare Pro" },
  { name: "Super rare", value: "Super rare" },
  { name: "Unique", value: "Unique" },
];

export const so5_division_types_objects_no_rp = [
  { name: "Limited", value: "Limited" },
  { name: "Rare", value: "Rare" },
  { name: "Super rare", value: "Super rare" },
  { name: "Unique", value: "Unique" },
];

export const baseball_leagues_objects = [{ name: "Major League Baseball", value: "Major League Baseball" }];

export function getLeaguesBasedOnSport(s) {
  if (s === sorareBaseball) {
    return baseball_leagues_objects;
  } else return leagues_with_divisions_objects;
}

export function getLeaguesBasedOnSportNotAll(s) {
  if (s === sorareBaseball) {
    return baseball_leagues_objects;
  } else return leagues_with_divisions_objects_not_all;
}

export const covered_leagues_objects = [
  { value: "1-hnl", name: "1. HNL" },
  { value: "2-bundesliga", name: "2. Bundesliga" },
  { value: "afc-champions-league", name: "AFC Champions League" },
  { value: "africa-cup-of-nations", name: "Africa Cup of Nations" },
  { value: "austrian-bundesliga", name: "Austrian Bundesliga" },
  { value: "bundesliga-de", name: "Bundesliga" },
  { value: "caf-champions-league", name: "CAF Champions League" },
  {
    value: "campeonato-brasileiro-serie-a",
    name: "Campeonato Brasileiro Série A",
  },
  { value: "chinese-super-league", name: "Chinese Super League" },
  { value: "concacaf-champions-league", name: "CONCACAF Champions League" },
  { value: "conmebol-sudamericana", name: "CONMEBOL Sudamericana" },
  { value: "conmebol-libertadores", name: "CONMEBOL Libertadores" },
  { value: "copa-america", name: "Copa America" },
  { value: "eliteserien", name: "Eliteserien" },
  { value: "eredivisie", name: "Eredivisie" },
  {
    value: "football-league-championship",
    name: "Football League Championship",
  },
  { value: "j1-league", name: "J1 League" },
  { value: "jupiler-pro-league", name: "Jupiler Pro League" },
  { value: "k-league-1", name: "K League 1" },
  { value: "laliga-santander", name: "LaLiga Santander" },
  { value: "liga-mx", name: "Liga MX" },
  { value: "liga-pro", name: "Liga Pro" },
  { value: "ligue-1-fr", name: "French League 1" },
  { value: "ligue-2-fr", name: "French League 2" },
  { value: "mlspa", name: "Major League Soccer" },
  { value: "premier-league-gb-eng", name: "English Premier League" },
  { value: "premiership-gb-sct", name: "Premiership" },
  { value: "primeira-liga-pt", name: "Primeira Liga" },
  { value: "primera-a", name: "Primera A" },
  { value: "primera-division-cl", name: "Primera División de Chile" },
  { value: "primera-division-pe", name: "Primera División del Perú" },
  { value: "russian-premier-league", name: "Russian Premier League" },
  { value: "segunda-division-es", name: "LaLiga Smartbank" },
  { value: "serie-a-it", name: "Serie A" },
  { value: "serie-b-it", name: "Serie B" },
  { value: "spor-toto-super-lig", name: "Spor Toto Süper Lig " },
  { value: "super-league-ch", name: "Super League" },
  {
    value: "superliga-argentina-de-futbol",
    name: "Superliga Argentina de Fútbol",
  },
  { value: "superliga-dk", name: "Superliga" },
  { value: "uefa-champions-league", name: "UEFA Champions League" },
  {
    value: "uefa-europa-conference-league",
    name: "UEFA Europa Conference League",
  },
  { value: "uefa-europa-league", name: "UEFA Europa League" },
  { value: "uefa-nations-league", name: "UEFA Nations League" },
  { value: "wc-qualification-africa", name: "WC Qualification Africa" },
  { value: "wc-qualification-asia", name: "WC Qualification Asia" },
  { value: "wc-qualification-concacaf", name: "WC Qualification Concacaf" },
  { value: "wc-qualification-europe", name: "WC Qualification Europe" },
  {
    value: "wc-qualification-south-america",
    name: "WC Qualification South America",
  },
  { value: "world-cup", name: "World Cup" },
];
