import React from "react";
import OrangeLockerOpen from "../../img/orange-locker-open.svg";

function Locker(props) {
  const containerStyle = "flex justify-center items-center bg-brand-pastel rounded-full ";
  const defaultSize = " w-12 h-12 ";
  const imgStyle = "w-1/2 h-1/2 ";

  return (
    <div className={props.containerStyle ? containerStyle + props.containerStyle : containerStyle + defaultSize}>
      <img className={props.imgStyle ? imgStyle + props.imgStyle : imgStyle} src={OrangeLockerOpen} alt="locker" />
    </div>
  );
}

export default Locker;
