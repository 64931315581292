export const watchlistTypes = [
  { value: "manager", name: "Manager" },
  { value: "player", name: "Player" },
];
export const watchlistTypeOptions = [{ value: "", name: "All types" }, ...watchlistTypes];

export const orderLabels = [
  { value: "name asc", name: "Name - A to Z" },
  { value: "name desc", name: "Name - Z to A" },
  { value: "nbItems asc", name: "Content - Least to most" },
  { value: "nbItems desc", name: "Content - Most to least" },
  { value: "nbFollowers asc", name: "Followers - Least to Most" },
  { value: "nbFollowers desc", name: "Followers - Most to least" },
  { value: "created desc", name: "Newest" },
  { value: "created asc", name: "Oldest" },
  { value: "updated asc", name: "Least recently updated" },
  { value: "updated desc", name: "Most recently updated" },
  { value: "relevance", name: "Relevance (search query)" },
];

export const orderMenuItems = (selectedOrder, onClick) => {
  return orderLabels.map((obj) => {
    const label = obj.name;
    const key = obj.value;
    return {
      key: key,
      label: label,
      className: "w-full sm:w-72 cursor-pointer " + (selectedOrder === key ? "font-semibold" : ""),
      onClick: () => onClick && onClick(key, label),
    };
  });
};
