export function formatValuationOrigin(valo) {
  if (valo === "3d") {
    return "3-day average";
  } else if (valo === "7d") {
    return "7-day average";
  } else if (valo === "14d") {
    return "2-week average";
  } else if (valo === "30d") {
    return "1-month average";
  } else if (valo === "lifetime") {
    return "Lifetime average";
  } else if (valo === "bmp") {
    return "Floor price";
  } else if (valo === "starting_price") {
    return "No sale";
  } else if (valo === "last_sale") {
    return "Last public sale";
  }
}

export const formatValuationOriginV2 = (valo) => {
  if (valo === "3d") {
    return "Last sales average (3d)";
  } else if (valo === "7d") {
    return "Last sales average (7d)";
  } else if (valo === "14d") {
    return "Last sales average (14d)";
  } else if (valo === "30d") {
    return "Last sales average (30d)";
  } else if (valo === "lifetime") {
    return "Lifetime average";
  } else if (valo === "starting_price") {
    return "No recent sale";
  } else if (valo === "last_sale") {
    return "Last public sale";
  }
};
