import React, { Component, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import OfferCard from "./offerCard";
import { ReactTooltip } from "../util/tooltip.js";
import { playing_status_objects, status } from "../util/playingStatus";
import { withUser } from "../../userContext";
import SDLoading from "../util/SDLoading";
import { errorCatcher } from "../util/errors";
import SearchFilters from "../search/searchFilters";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { isSportFree, sorareFootball } from "../util/sports";
import AbortController from "abort-controller";
import { DEFAULT_AGE_RANGE } from "../util/ageRange";
import { allStarDefaultCompetition, initialEligibilityCompetitionValue } from "../competitions/eligibilityCompetitionsPicker";
import { getPaginatedJSONResponse, withJSONPagination } from "../util/pagination";

const initialFilters = {
  filter: "endingAt",
  order: "desc",
  scarcityValue: [],
  positionValue: [],
  leagueValue: [],
  eligibilityValue: initialEligibilityCompetitionValue,
  playingStatusValue: playing_status_objects[0].value,
  availabilityStatus: [],
  filterPlayers: [],
  nextGw: false,
  u23Only: false,
  gws: [],
  gwFilter: false,
  gwValue: 0,
  bmpFilter: false,
  avgFilter: false,
  current: false,
  currentRange: [0, 1],
  bmpRange: [0, 1],
  avgRange: [0, 1],
  ageRange: DEFAULT_AGE_RANGE,
  scoreFilters: {
    l5: {
      enabled: false,
      scoreRange: [0, 100],
      daRange: [0, 100],
      aaRange: [-20, 100],
      gmsPlayed: [0, 100],
      gmsStarted: [0, 100],
    },
    l15: {
      enabled: false,
      scoreRange: [0, 100],
      daRange: [0, 100],
      aaRange: [-20, 100],
      gmsPlayed: [0, 100],
      gmsStarted: [0, 100],
    },
    l40: {
      enabled: false,
      scoreRange: [0, 100],
      daRange: [0, 100],
      aaRange: [-20, 100],
      gmsPlayed: [0, 100],
      gmsStarted: [0, 100],
    },
  },
  watchlists: {},
  bmp: false,
  intlTrip: false,
  teamsValue: [],
  underAvg: false,
  showFilters: true,
  sport: "all",
  scoring: true,
  jerseyMint: false,
  oneSerialNumber: false,
  searchQuery: "",
  seasons: [],
};

const limitAPI = 80;

export const PublicOffersWrapper = withUser((props) => {
  const [abortController, setAbortController] = useState(new AbortController());
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [priceInfo, setPriceInfo] = useState({});
  const [gwGames, setGwGames] = useState({});
  const [displayedOffers, setDisplayedOffers] = useState(undefined);
  const [filters, setFilters] = useState({ ...initialFilters, sport: props.sport || "all" });

  useEffect(() => {
    document.title = "Ongoing public offers";
    getNextGameweeks();
    getOffers(filters, true);
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  const getNextGameweeks = () => {
    props
      .fetch("/apiv2/gw/next")
      .then((response) => response.json())
      .then(async (res) => {
        let gws = [];
        res.map((gw) => {
          gws.push({ value: gw.GwNumber, name: gw.DisplayName });
          return null;
        });
        let gwValue = gws[0].value;
        await setFilters({ ...filters, gws, gwValue });
      })
      .catch(errorCatcher());
  };

  const getOffers = (filters, reset) => {
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const positionString = filters.positionValue;
    const ll = filters.leagueValue;
    const eligibilityComps = filters.eligibilityValue;
    const scarcityString = filters.scarcityValue;
    const filter = filters.filter ? filters.filter : "endingAt";
    const asc = filters.order ? filters.order : "desc";
    let playingStatus = filters.playingStatusValue.toLowerCase();
    if (playingStatus === status[0].toLowerCase()) {
      playingStatus = "all";
    } else if (playingStatus === status[3].toLowerCase()) {
      playingStatus = "not_playing";
    }
    let scoringValue = "old";
    if (filters.scoring) scoringValue = "new";
    let o = offset;
    if (reset) {
      o = 0;
    }
    setLoading(true);
    console.log(filters);
    props
      .fetch("/apiv2/offers/ongoingOffers", {
        method: "POST",
        headers: withJSONPagination(o, limitAPI),
        body: JSON.stringify({
          order: filter,
          asc: asc,
          league: ll,
          competitionsEligible: Object.keys(eligibilityComps).filter((k) => k !== allStarDefaultCompetition.id),
          scarcity: scarcityString,
          playing_status: playingStatus,
          availability_status: filters.availabilityStatus,
          position: positionString,
          age_range: filters.ageRange,
          current_price: filters.current
            ? [
                parseFloat(filters.currentRange[0].toString().replace(",", ".")),
                parseFloat(filters.currentRange[1].toString().replace(",", ".")),
              ]
            : [],
          best_market_price: filters.bmpFilter
            ? [parseFloat(filters.bmpRange[0].toString().replace(",", ".")), parseFloat(filters.bmpRange[1].toString().replace(",", "."))]
            : [],
          average_price: filters.avgFilter
            ? [parseFloat(filters.avgRange[0].toString().replace(",", ".")), parseFloat(filters.avgRange[1].toString().replace(",", "."))]
            : [],
          watchlists: Object.keys(filters.watchlists),
          u23: filters.u23Only,
          bmp: filters.bmp,
          gw: filters.gwFilter ? filters.gwValue : 0,
          teams: filters.teamsValue,
          last_5: filters.scoreFilters.l5,
          last_15: filters.scoreFilters.l15,
          last_40: filters.scoreFilters.l40,
          under_avg: filters.underAvg,
          sport: filters.sport,
          scoring: scoringValue,
          jersey_mint: filters.jerseyMint,
          one_serial_number: filters.oneSerialNumber,
          search_query: filters.searchQuery,
          seasons: filters.seasons,
          seasonality_filter: filters.seasonality,
        }),
        signal: newAbortController?.signal,
      })
      .then((response) => getPaginatedJSONResponse(response))
      .then(({ res, hasNext }) => {
        if (reset || displayedOffers == undefined) {
          setDisplayedOffers(res.offers || []);
        } else {
          const newOffers = res.offers || [];
          setDisplayedOffers([...displayedOffers, ...newOffers]);
        }
        setGwGames(res.team_map);
        setPriceInfo(res.price_info);
        setLoading(false);
        setHasNext(hasNext);
        setOffset(o + limitAPI);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const filter = async (f) => {
    const newFilters = {
      ...filters,
      scarcityValue: f.scarcities,
      positionValue: f.positions,
      teamsValue: f.teams,
      eligibilityValue: f.eligibilityCompetitions,
      leagueValue: f.leagues,
      ageRange: f.age,
      playingStatusValue: f.playingStatus?.length > 0 ? f.playingStatus : "all",
      availabilityStatus: f.availabilityStatus || [],
      u23Only: f.u23,
      current: f.currentPrice.enabled,
      currentRange: [f.currentPrice.min, f.currentPrice.max],
      avgFilter: f.average.enabled,
      avgRange: [f.average.min, f.average.max],
      bmpFilter: f.floor.enabled,
      bmpRange: [f.floor.min, f.floor.max],
      scoreFilters: {
        l5: f.l5Filter,
        l15: f.l15Filter,
        l40: f.l40Filter,
      },
      watchlists: f.watchlists,
      bmp: f.underBmp,
      underAvg: f.underAvg,
      gwFilter: f.filterByGw,
      gwValue: f.gw,
      filter: f.sortOption,
      order: f.sortOrder,
      sport: f.sport,
      jerseyMint: f.jerseyMint,
      oneSerialNumber: f.oneSerialNumber,
      searchQuery: f.searchQuery,
      seasons: f.seasons,
      seasonality: f.seasonality,
    };
    setFilters(newFilters);
    getOffers(newFilters, true);
  };

  const onMore = () => {
    getOffers(filters, false);
  };

  return (
    <div className={"flex flex-col mx-16 pb-8"}>
      <div className={"flex flex-col space-y-4"}>
        {filters.filter && filters.showFilters && (
          <div className={"w-full flex flex-col space-y-4"}>
            <SearchFilters
              sport={filters.sport}
              vertical={true}
              filter={filter}
              mode={"offers"}
              loading={loading && displayedOffers !== undefined}
            />
          </div>
        )}
        <div className={"flex flex-col w-full"}>
          {isFree(props.user.tier) && (filters.sport === "all" || !isSportFree(filters.sport)) && (
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-advanced-search bg-cover h-upgrade-banner-l w-full mb-4"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock players status, premium filters and unlimited presets"}
            />
          )}
          {displayedOffers == undefined && loading && <SDLoading />}
          {displayedOffers !== undefined && displayedOffers?.length > 0 ? (
            <div className={filters.showFilters ? "flex flex-col w-full items-start" : "flex flex-col w-full items-center"}>
              <ReactTooltip />
              <InfiniteScroll dataLength={displayedOffers.length} next={onMore} hasMore={hasNext} onMore={onMore} loader={<SDLoading />}>
                <div
                  className={
                    "justify-start grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 1.5xl:grid-cols-5 2xl:grid-cols-6 2.5xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-8 gap-4"
                  }
                >
                  {displayedOffers.map((o, i) => (
                    <OfferCard
                      key={o.offer.OfferId}
                      offer={o}
                      filter={filters.filterPlayers}
                      priceInfo={priceInfo}
                      u23Only={filters.u23Only}
                      gwNumber={filters.gwFilter ? filters.gwValue : 0}
                      gwGames={gwGames}
                      scarcity={filters.scarcityValue}
                      position={filters.positionValue}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          ) : (
            !loading && <p className={"text-center font-semibold mx-auto text-sm"}>No offer available.</p>
          )}
        </div>
      </div>
    </div>
  );
});
