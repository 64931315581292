import React, { useCallback, useState } from "react";
import { abbrv_football_positions } from "./positions";
import PopHoverWrapper from "./popHoverWrapper";
import CirclePct, { getColorFromPct } from "./circlePct";
import { LockerButton } from "./button";
import { MatchuIndicatorModaleLegend } from "../decision/footballLineupTableLegend";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";
import { useTheme } from "../../themeContext";
import GoalIcon from "../../img/ICN_Ballon.svg";
import OwnGoal from "../../img/own-goal.svg";
import CleanSheet from "../../img/cleansheet.svg";

function MatchupScore(props) {
  let s = props.score;
  let label = props.label;
  let bg = "bg-amazing-score";
  if (s < 20) {
    bg = "bg-great-score";
  }
  if (s < 10) {
    bg = "bg-good-score";
  }
  if (s < 5) {
    bg = "bg-ok-score";
  }
  if (s < -5) {
    bg = "bg-bad-score";
  }
  if (s < -20) {
    bg = "bg-horrible-score";
  }
  return (
    <div className={"flex flex-col items-center space-y-1 text-sm"}>
      {label !== undefined && <p className={"text-xxs text-textGrey2"}>{label}</p>}
      <div
        data-tip={props.tip ? "Matchup score" : undefined}
        className={"flex flex-col justify-center self-center " + "items-center w-8 h-8 rounded-full text-white " + bg}
      >
        <p className={"flex justify-center self-center font-semibold"}>{s.toFixed(0)}</p>
      </div>
    </div>
  );
}

const matchupOddsMapping = {
  gk: "CS",
  def: "CS",
  mid: "3GS",
  fwd: "3GS",
  leftDef: "CS",
  rightDef: "CS",
  centreDef: "CS",
  leftDefMid: "3GA",
  rightDefMid: "3GA",
  centreDefMid: "3GA",
  leftMid: "3GS",
  rightMid: "3GS",
  centreMid: "3GS",
  leftAttMid: "3GS",
  rightAttMid: "3GS",
  centreAttMid: "3GS",
  rightFwd: "3GS",
  leftFwd: "3GS",
  centreFwd: "3GS",
};

const matchupOddsMappingToDescription = {
  CS: "Clean sheet probability",
  "3GA": "Conceding 3 goals probability",
  "3GS": "Scoring 3 goals probability",
};

const matchupOddsMappingToIcon = {
  CS: CleanSheet,
  "3GA": OwnGoal,
  "3GS": GoalIcon,
};

export const MatchupOddsIcon = (props) => {
  const { type, size } = props;
  return <img className={size} src={matchupOddsMappingToIcon[type]} />;
};

export const getMatchupOddsInfo = (odds, isHome, detailedPositions, cardPosition) => {
  let cs_pct;
  let three_gs_pct;
  let three_ga_pct;
  if (odds !== undefined) {
    cs_pct = odds["home_goals500"]?.away;
    if (isHome) cs_pct = odds["away_goals500"]?.away;
    three_gs_pct = odds["away_goals2500"]?.home;
    if (isHome) three_gs_pct = odds["home_goals2500"]?.home;
    three_ga_pct = odds["home_goals2500"]?.home;
    if (isHome) three_ga_pct = odds["away_goals2500"]?.home;
  }
  let position;
  if (detailedPositions !== undefined && detailedPositions !== null && detailedPositions.length > 0) {
    position = detailedPositions[0].Position;
  } else {
    position = abbrv_football_positions[cardPosition]?.toLowerCase();
  }
  const type = matchupOddsMapping?.[position] || "CS";
  const value = type === "CS" ? cs_pct : type === "3GS" ? three_gs_pct : three_ga_pct;
  return {
    type,
    value,
    cs_pct,
    three_gs_pct,
    three_ga_pct,
  };
};

export const MatchupOdds = (props) => {
  const { odds, isHome, detailedPositions, cardPosition } = props;
  const { type, value, cs_pct, three_gs_pct, three_ga_pct } = getMatchupOddsInfo(odds, isHome, detailedPositions, cardPosition);
  const version = props.version || "circle";
  const { theme } = useTheme();
  const getColors = useCallback((pct) => getColorFromPct(pct, false, theme), [theme]);
  const valuePct = value !== undefined && value !== 0 ? 100 / value : 0;
  return (
    <PopHoverWrapper
      hoverTitle={"Key event probabilities"}
      hover={
        <>
          <div className={"flex justify-center gap-2"}>
            <div className={"flex flex-col gap-1 justify-center self-center"}>
              <CirclePct value={odds !== undefined && cs_pct} />
              <div className={"flex flex-row gap-1 font-medium text-xs text-on-surface-variant text-center justify-center"}>
                <span title={matchupOddsMappingToDescription["CS"]}>CS</span>
              </div>
            </div>
            <div className={"flex flex-col gap-1 justify-center self-center"}>
              <CirclePct value={odds !== undefined && three_ga_pct} />
              <div className={"flex flex-row gap-1 font-medium text-xs text-on-surface-variant text-center justify-center"}>
                <span title={matchupOddsMappingToDescription["3GA"]}>3GA</span>
              </div>
            </div>
            <div className={"flex flex-col gap-1 justify-center self-center"}>
              <CirclePct value={odds !== undefined && three_gs_pct} />
              <div className={"flex flex-row gap-1 font-medium text-xs text-on-surface-variant text-center justify-center"}>
                <span title={matchupOddsMappingToDescription["3GS"]}>3GS</span>
              </div>
            </div>
          </div>
        </>
      }
    >
      {version === "circle" && (
        <>
          <CirclePct value={odds !== undefined && value} />
          <div className={"flex flex-row gap-1 font-medium text-sm text-on-surface-variant"} title={matchupOddsMappingToDescription[type]}>
            <span>{type}</span>
            <IconInfoHelp className={"fill-outline-variant my-auto"} />
          </div>
        </>
      )}
      {version === "text-icon" && (
        <div className={"flex flex-col justify-center items-center"}>
          <p className={"font-semibold text-xs"} style={{ color: getColors(valuePct).color }}>
            {value !== undefined ? valuePct.toFixed(0) + "%" : "NA"}
          </p>
          <MatchupOddsIcon size="w-5 h-5" type={type} />
        </div>
      )}
    </PopHoverWrapper>
  );
};

const MatchupIndicatorLocker = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className={"flex justify-center items-center"}>
      <LockerButton onClick={() => setOpen(true)} type={"rounded"} />
      {open && <MatchuIndicatorModaleLegend open={open} close={() => setOpen(false)} />}
    </div>
  );
};

export const MatchupIndicator = (props) => {
  const { score, locked } = props;
  if (locked) {
    return <MatchupIndicatorLocker />;
  }
  return <MatchupScore score={score} />;
};

export default MatchupScore;
