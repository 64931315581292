import HeartFilled from "../../img/heart-filled.svg";
import HeartEmpty from "../../img/heart-empty.svg";
import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "./errors";
import EditWatchlistLinkModal from "../watchlists/editWatchlistLinkModal";
import { sorareFootball } from "./sports";

function FavoriteBubble(props) {
  const type = props.type || "player";
  const sports = type == "player" ? [props.player?.Sport || sorareFootball] : [];
  const [favorited, setFavorited] = useState(props.favorite);
  const [watchlistEditOpen, setWatchlistEditOpen] = useState(false);

  useEffect(() => {
    setFavorited(props.favorite);
  }, [props.player?.PlayerId, props.manager, props.favorite]);

  const reloadFavoriteStatus = () => {
    props
      .fetch(`/apiv2/watchlists/isInWatchlists/${type}/${type === "player" ? props.player?.PlayerId : props.manager}`)
      .then((response) => response.json())
      .then((res) => {
        setFavorited(res);
      })
      .catch(errorCatcher());
  };

  return (
    <div
      className={`${props.bg ? "rounded-full w-full border border-" + props.bg : ""} ${props.className || ""} hover:opacity-80 ${
        props.shadow ? "rounded-full shadow-lg bg-white" : ""
      }`}
    >
      <img
        className={"cursor-pointer w-full h-full"}
        title={`Click to add/remove "${type === "player" ? props.player?.DisplayName : props.manager}" from watchlist(s)`}
        onClick={() => setWatchlistEditOpen(true)}
        src={favorited ? HeartFilled : HeartEmpty}
      />
      <EditWatchlistLinkModal
        type={type}
        manager={props.manager}
        sports={sports}
        playerId={props.player?.PlayerId}
        open={watchlistEditOpen}
        onClose={() => setWatchlistEditOpen(false)}
        onSaveSuccess={reloadFavoriteStatus}
      />
    </div>
  );
}

export default withUser(FavoriteBubble);
