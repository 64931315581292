/**
 * @deprecated use colors from the design system
 */
export function getSdScoreColor(score) {
  if (score < 15) {
    return "horrible-score";
  } else if (score < 30) {
    return "bad-score";
  } else if (score < 45) {
    return "ok-score";
  } else if (score < 60) {
    return "good-score";
  } else if (score < 75) {
    return "great-score";
  } else if (score <= 100) {
    return "amazing-score";
  }
}
