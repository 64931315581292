import React from "react";
import { withUser } from "../../userContext";
import { formatPrice } from "../util/formatMoney";
import { capitalize } from "../util/capitalize";
import ThreeDays from "../../img/3d-avg.svg";
import ThirtyDays from "../../img/30d-avg.svg";
import FourteenDays from "../../img/14d-avg.svg";
import SevenDays from "../../img/7d-avg.svg";
import FloorPrice from "../../img/best-price.svg";

function PlayerRollingAverage(props) {
  let img = ThreeDays;
  let period = props.period;
  let tip = "Price average over the last 3 days";
  if (period === "7d") {
    img = SevenDays;
    tip = "Price average over the last 7 days";
  } else if (period === "2w") {
    img = FourteenDays;
    tip = "Price average over the last 14 days";
  } else if (period === "1m") {
    img = ThirtyDays;
    tip = "Price average over the last 30 days";
  }
  let average = props.average;
  let displayAverage;
  if (average === null || average.Average === -1 || average.Average === 0) {
    displayAverage = "---";
  } else {
    const unit = props.primaryUnit || props.user.preferredUnit;
    if (unit === "eth") {
      displayAverage = formatPrice(average.Average, "eth");
    } else {
      displayAverage = formatPrice(average?.[capitalize(unit) + "Average"], unit);
      if (average?.[capitalize(unit) + "Average"] === -1) {
        displayAverage = "---";
      }
    }
  }

  if (props.period === "floor") {
    img = FloorPrice;
    displayAverage = props.average;
    tip = "Floor price";
  }

  let direction = props.direction || "row";
  let fontSize = props.fontSize || "text-sm";
  let imgSize = props.imgSize || "w-5 h-5";
  let justify = props.justify || "justify-between";
  return (
    <div className={`w-full flex flex-${direction} ` + justify}>
      <div className={direction === "row" ? imgSize : "self-center"} data-tip={tip}>
        <img src={img} className={"w-full h-full"} />
      </div>
      <p className={"font-semibold self-center " + fontSize} style={{ color: props.color }}>
        {displayAverage}
      </p>
    </div>
  );
}

export default withUser(PlayerRollingAverage);
