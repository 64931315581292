import React, { Component } from "react";
import ls from "local-storage";
import Spinner from "../loader/spinner";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

const queryString = require("query-string");

class MobileAppLogin extends Component {
  constructor(props) {
    super(props);
    this.state = { sorareLink: "" };
  }

  async componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.returnLink) {
      // the return link depends on the environment of the app
      ls.set("loginReturnLink", parsed.returnLink);
      this.getLoginURL();
    } else {
      // we cannot redirect the user, this should not happen
      this.props.history.push("/");
    }
  }

  getLoginURL = () => {
    this.props
      .fetch("/apiv2/user/sorare-login-url")
      .then((response) => response.json())
      .then((res) => {
        this.setState({ sorareLink: res });
      })
      .catch(errorCatcher());
  };

  render() {
    if (this.state.sorareLink !== "") {
      window.location.href = this.state.sorareLink;
    }
    return <Spinner />;
  }
}

export default withUser(MobileAppLogin);
