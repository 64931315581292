import React from "react";
import LineSeparator from "../../img/line-separator.svg";
import Ether from "../../img/ether.svg";
import Euro from "../../img/euro.svg";
import Gbp from "../../img/gbp.svg";
import Dollar from "../../img/$.svg";
import clsx from "clsx";

function CurrencyPicker(props) {
  let halfOpacity = "opacity-50";
  let style = "cursor-pointer flex flex-row space-x-4 self-center min-w-max ";
  let imgStyle = "w-full h-full object-contain";
  return (
    <div
      className={clsx(
        "flex flex-row space-x-4 justify-around mx-auto self-center bg-surface-container py-1.5 px-4 2xl:px-6 rounded-3xl",
        props.containerClassName,
      )}
    >
      <div className={props.currency === "eth" ? style : style + halfOpacity} onClick={() => props.onChange("eth")}>
        <img style={{ width: "23px", height: "26px" }} src={Ether} className={imgStyle} />
      </div>
      <img src={LineSeparator} />
      <div className={props.currency === "eur" ? style : style + halfOpacity} onClick={() => props.onChange("eur")}>
        <img style={{ width: "16px", height: "26px" }} src={Euro} className={imgStyle} />
      </div>
      <img src={LineSeparator} />
      <div className={props.currency === "usd" ? style : style + halfOpacity} onClick={() => props.onChange("usd")}>
        <img style={{ width: "14px", height: "26px" }} src={Dollar} className={imgStyle} />
      </div>
      <img src={LineSeparator} />
      <div className={props.currency === "gbp" ? style : style + halfOpacity} onClick={() => props.onChange("gbp")}>
        <img style={{ width: "15px", height: "26px" }} src={Gbp} className={imgStyle} />
      </div>
    </div>
  );
}

export default CurrencyPicker;
