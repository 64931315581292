import React, { useEffect } from "react";

function JoinSorare() {
  useEffect(() => {
    window.location.href = "https://sorare.pxf.io/m6Ld7";
  }, []);

  return <div />;
}

export default JoinSorare;
