import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import BoxWithToggle from "../util/boxWithToggle";
import { isFree } from "../util/handleSubscriptionTier";
import { ReactTooltip } from "../util/tooltip";

function PrivacySettings(props) {
  const [privacySettings, setPrivacySettings] = useState(undefined);
  const [privacySettingsLoading, setPrivacySettingsLoading] = useState({});
  const [privacySettingsMsg, setPrivacySettingsMsg] = useState({});
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPrivacySettings();
  }, []);

  const getPrivacySettings = () => {
    setLoading(true);
    props
      .fetch("/apiv2/user/privacy")
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          setMsg("Failed to get privacy settings");
        } else {
          setPrivacySettings(res);
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const updatePrivacySettings = (field, value) => {
    setPrivacySettingsMsg({ [field]: "" });
    setPrivacySettingsLoading({ privacySettingsLoading, [field]: true });
    props
      .fetch("/apiv2/user/privacy", {
        method: "PATCH",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          [field]: value,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          setPrivacySettingsMsg({
            [field]: "Privacy settings change failed. Please logout, login and try again.",
          });
        } else {
          setPrivacySettingsMsg({ [field]: "Privacy settings updated." });
          setPrivacySettings({ ...privacySettings, [field]: value });
        }
        setPrivacySettingsLoading({ privacySettingsLoading, [field]: false });
      })
      .catch(
        errorCatcher(() => {
          setPrivacySettingsLoading({ privacySettingsLoading, [field]: false });
        }),
      );
  };

  return (
    <div>
      <div className={"space-y-2"}>
        <p className={"text-left uppercase text-sm text-on-surface-variant font-semibold"}>Manager stats</p>
        <div className={"flex flex-col space-y-4"}>
          <p className={"text-sm font-medium"}>
            Choose whether you want other users to access your{" "}
            <a className={"text-brand hover:opacity-80"} href={`/manager/${props.user.sorareSlug}/sections/manager-stats`}>
              Manager stats
            </a>{" "}
            (key and advanced) and your roster size chart
          </p>
        </div>
        <div className={"bg-surface-container rounded-lg py-1.5 w-6/12"}>
          <BoxWithToggle
            disabled={isFree(props.user.tier)}
            disabledTip={isFree(props.user.tier) ? "This feature is only available to Premium users" : undefined}
            disabledTipId={"manager-stats-disabled-tip"}
            label={"Make my Manager Stats public"}
            isActive={privacySettings?.manager_stats_public || false}
            setActive={() => updatePrivacySettings("manager_stats_public", !privacySettings?.manager_stats_public)}
          />
          <ReactTooltip id={"manager-stats-disabled-tip"} />
        </div>
        {privacySettingsMsg.manager_stats_public && (
          <p className={"text-center font-semibold text-sm"}>{privacySettingsMsg.manager_stats_public}</p>
        )}
      </div>
      {msg && <p className={"text-center font-semibold text-sm"}>{msg}</p>}
    </div>
  );
}

export default withUser(PrivacySettings);
