import React, { useEffect, useState } from "react";
import FavoriteBubble from "../util/favoriteBubble";
import SDLoading from "../util/SDLoading";
import format from "date-fns/format";
import { ReactTooltip } from "../util/tooltip.js";
import BgCard from "../../img/background-card-page.svg";
import LineSeparator from "../../img/line-separator.svg";
import CardMoves from "./cardMoves";
import CardProfitability from "./cardProfitability";
import StarIcon from "../../img/star.svg";
import JerseyMint from "../../img/jersey-mint.svg";
import Link from "../../img/link.svg";
import { formatPrice, findPriceForUserUnit, checkSecondUnitShouldBeDisplayed } from "../util/formatMoney";
import PlayerPriceGraph from "../players/chartJSPlayerPriceGraph";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { withUser } from "../../userContext";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import LimitedPoint from "../../img/limited-point.png";
import RarePoint from "../../img/rare-point.png";
import SuperRarePoint from "../../img/sr-point.png";
import UniquePoint from "../../img/unique-point.png";
import CustomSeriesPoint from "../../img/custom-series-point.svg";
import Countdown from "react-countdown-now";
import { formatDistance } from "date-fns";
import ScoreAverage from "../decision/scoreAverage";
import PlayerScoresTab from "../players/playerScoreTab";
import { errorCatcher } from "../util/errors";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import CardShowcase from "../util/cardShowcase";
import { getCardSupply } from "../util/cardSupply";

function CardDetail(props) {
  return (
    <div className={"text-white space-y-2 z-10"}>
      <p className={"text-sm font-semibold text-center"}>{props.title}</p>
      <p className={"text-center text-lg font-bold"}>{props.value}</p>
    </div>
  );
}

function CardTag(props) {
  return (
    <div className={"flex flex-row space-x-2 self-center px-4 py-2 bg-white rounded-lg"}>
      <img src={props.icon} />
      <p className={"font-semibold text-sm text-left"}>{props.tag}</p>
    </div>
  );
}

function NewCardPage(props) {
  const [info, setInfo] = useState({});
  const [moves, setMoves] = useState([]);
  const [offer, setOffer] = useState([]);
  const [lastExchanges, setLastExchanges] = useState([]);
  const [activeTab, setActiveTab] = useState("history");
  const [playerFavorite, setPlayerFavorite] = useState(false);

  useEffect(() => {
    if (props.user.sorareSlug !== undefined) {
      getCardInfo();
    }
  }, [props.user]);

  const getCardInfo = () => {
    props
      .fetch("/apiv2/cards/info/" + props.match.params.id + "?manager=" + props.user.sorareSlug)
      .then((response) => response.json())
      .then((res) => {
        setInfo(res.info);
        setMoves(res.moves);
        setOffer(res.offer);
        setPlayerFavorite(res.player_favorite);
        if (res.last_exchanges.length > 0) {
          setLastExchanges(res.last_exchanges[0]?.last_exchanges);
        }
        let total = "1000";
        if (res.info.card.Scarcity === "RARE") {
          total = "100";
        } else if (res.info.card.Scarcity === "SUPER RARE") {
          total = "10";
        } else if (res.info.card.Scarcity === "UNIQUE") {
          total = "1";
        }
      })
      .catch(errorCatcher());
  };

  const player = info?.player;
  const card = info?.card;
  const team = info?.team;

  const inactiveClass = "py-4 px-6 block hover:text-blue-500 focus:outline-none text-textGrey4 cursor-pointer w-6/12 text-center";
  const activeClass = "py-4 px-6 text-blue-500 focus:outline-none cursor-pointer text-brand w-6/12 text-center";

  let theCardLink = "https://sorare.com/football/cards/" + card?.Slug + "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1";
  if (player?.Sport === sorareBaseball) {
    theCardLink = "https://sorare.com/mlb/cards/" + card?.Slug + "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1";
  } else if (player?.Sport === sorareBasketball) {
    theCardLink = "https://sorare.com/nba/cards/" + card?.Slug + "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1";
  }

  if (player !== undefined) {
    let icon = LimitedPoint;
    let total = getCardSupply(player?.Sport, card);
    if (card.Scarcity === "RARE") {
      icon = RarePoint;
    } else if (card.Scarcity === "SUPER RARE") {
      icon = SuperRarePoint;
    } else if (card.Scarcity === "UNIQUE") {
      icon = UniquePoint;
    } else if (card.Scarcity === "CUSTOM SERIES") {
      icon = CustomSeriesPoint;
    }

    document.title = player.DisplayName + " - " + card.SerialNumber + "/" + total + " (" + card.Season + ")";

    const splitName = player?.DisplayName.split(" ");
    const lastMove = moves?.length > 0 ? moves[0] : undefined;
    let lastMovePrice = findPriceForUserUnit(lastMove?.price, lastMove?.move, props.user.preferredUnit);
    const bmp = info.bmp;
    let firstName = "";
    let lastName = "";
    if (splitName.length <= 1) {
      lastName = player?.DisplayName;
    } else {
      splitName.map((n, i) => {
        if (i <= splitName.length - 2) {
          firstName += n + " ";
        }
      });
      lastName = splitName[splitName.length - 1];
    }
    const sport = player?.Sport;

    let formattedScarcity = card.Scarcity.toLowerCase().replace(" ", "-");
    let bgClass =
      "md:h-card-header h-60 bg-gradient-to-b from-card-header-" +
      formattedScarcity +
      "-start to-card-header-" +
      formattedScarcity +
      "-end";
    let collectionLink =
      "/collection?manager=" +
      lastMove?.manager_receiving.Slug +
      "&teamId=" +
      card?.CardTeamId +
      "&scarcity=" +
      card.Scarcity +
      "&season=" +
      card.Season;

    const shouldDisplaySecondUnit = checkSecondUnitShouldBeDisplayed(props.user.preferredUnit, props.user.secondUnit);

    return (
      <div className={"pb-8"}>
        <div className={bgClass}>
          <img className={"hidden md:block md:absolute transform left-1/2 -translate-x-1/2 object-contain z-0 opacity-10"} src={BgCard} />

          <div className={"h-card-header absolute flex flex-col space-y-4 mt-4 right-4"}>
            <div data-tip={"Click to favorite/unfavorite this player"}>
              <FavoriteBubble favorite={playerFavorite} player={player} />
            </div>
          </div>

          <div className={"w-full 2.5xl:w-10/12 4xl:w-8/12 mx-auto flex flex-col md:flex-row z-10"}>
            <div
              className={
                "hidden md:absolute pl-24 pr-12 w-full md:w-4/12 4xl:w-3/12 self-end md:flex flex-row space-x-6 justify-between mx-auto pb-12 "
              }
            >
              <CardDetail title={"Level"} value={card.Level} />
              <CardDetail title={"Power"} value={((card.Power - 1) * 100).toFixed(1) + "%"} />
              {sport !== sorareFootball && <CardDetail title={"Experience"} value={card.Exp} />}
              {sport === sorareFootball && <CardDetail title={"Collection score"} value={info?.collection_metadata.total} />}
            </div>
            <div className={"md:hidden w-full self-center flex flex-row justify-center"}>
              <div className={"flex flex-row justify-center z-10 pt-4"}>
                <img src={card.PictureUrl} className={"w-card-xl h-card-xl"} />
              </div>
            </div>
            <div className={"md:h-card-header w-full md:w-4/12 md:pt-8 md:pl-16 pl-8 space-y-2 flex flex-col"}>
              <div>
                <p className={"font-headers text-white text-xl "}>
                  <a href={"/player/" + player.PlayerId}>{firstName}</a>
                </p>
                <p className={"font-headers text-white font-semibold text-4xl"}>
                  <a href={"/player/" + player.PlayerId}>{lastName}</a>
                </p>
                <p className={"font-semibold text-white text-base pt-2"}>
                  <span>
                    {player.BirthDate === null ? "Unknown birth date" : "Born on " + format(new Date(player.BirthDate), "MMM do yyyy")} (
                    <span className={"font-medium"}>{player.Age}</span>){" "}
                  </span>
                </p>
              </div>
              <div className={"flex flex-row space-x-4"}>
                <div className={"w-10 h-10"}>
                  <a href={"/team/" + team.TeamId}>
                    <img src={team.AlternateLogo} className={"object-contain h-10 w-10"} data-tip={team.DisplayName} />
                  </a>
                </div>
                <div className={"flex self-center px-4 py-2 bg-white rounded-lg"}>
                  <p className={"text-sm font-semibold"}>{player.Position}</p>
                </div>
              </div>
              <div className={"flex pt-4"}>
                <div className={"flex flex-row z-0 space-x-2"}>
                  {card.SerialNumber === 1 && card.Scarcity !== "UNIQUE" && <CardTag icon={StarIcon} tag={"1/" + total + " Edition"} />}
                  {card.CardEdition !== "" && card.CardEdition !== "classic" && <CardTag icon={StarIcon} tag={card.CardEdition} />}
                  {info?.shirt_number.shirtNumber === card.SerialNumber && <CardTag icon={JerseyMint} tag={"Jersey Mint"} />}
                </div>
              </div>
            </div>

            <div className={"md:h-card-header hidden w-full md:w-4/12 self-center md:flex flex-row justify-center"}>
              <HiglightedCard card={card} />
            </div>

            <div className={"w-full md:w-3/12 flex items-start"}>
              <div className={"hidden md:flex self-end flex-row justify-between w-10/12 pb-12"}>
                <CardDetail
                  title={"Season"}
                  value={player?.Sport === sorareBaseball ? card.Season : card.Season + "-" + (parseInt(card.Season) + 1)}
                />
                <CardDetail title={"Serial number"} value={total !== undefined ? card.SerialNumber + "/" + total : card.SerialNumber} />
              </div>
            </div>
            <div className={"grid grid-cols-3 gap-4 w-11/12 mx-auto justify-between md:hidden items-center"}>
              <CardDetail title={"Level"} value={card.Level} />
              <CardDetail title={"Power"} value={((card.Power - 1) * 100).toFixed(1) + "%"} />
              <CardDetail title={"Exp"} value={card.Exp} />
              <CardDetail title={"Season"} value={card.Season + "-" + (parseInt(card.Season) + 1)} />
              <CardDetail title={"Serial number"} value={total !== undefined ? card.SerialNumber + "/" + total : card.SerialNumber} />
            </div>
          </div>
        </div>
        <div
          className={
            "w-11/12 lg:w-10/12 2.5xl:w-9/12 4xl:w-8/12 mx-auto flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 -mt-8 z-10"
          }
        >
          <div className={"rounded-lg bg-white shadow-sm p-4 w-full md:w-6/12 space-y-2 z-10"}>
            {offer?.offer?.OfferId !== undefined && offer.offer.OfferId !== "" ? (
              <div className={"w-full flex flex-col items-center"}>
                <p className={"text-center text-lg font-semibold self-center align-middle"}>
                  {findPriceForUserUnit(offer?.offer?.ReceiveAmountInWei, offer?.received_amount_fiat, props.user.preferredUnit)}
                </p>
                <p className={"text-textGrey3 font-semibold text-base"}>
                  Sold by{" "}
                  <a className={"text-brand-black"} href={"/manager/" + offer.sender.Slug}>
                    {offer.sender.Nickname}
                  </a>{" "}
                  - <Countdown date={offer?.offer?.EndingAt} />
                </p>
              </div>
            ) : (
              <div>
                {lastMove?.move.type === "on_auction" && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>{lastMovePrice}</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>On auction</p>
                  </div>
                )}
                {lastMove?.move.type === "referral" && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>Free</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Referral reward</p>
                  </div>
                )}
                {lastMove?.move.type === "auction" && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>{lastMovePrice}</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Auction</p>
                  </div>
                )}
                {(lastMove?.move.type === "reward" || lastMove?.move.type === "mint") && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>Free</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Reward</p>
                  </div>
                )}
                {lastMove?.move.type === "bundled_auction" && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>{lastMovePrice}</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Bundled auction</p>
                  </div>
                )}
                {lastMove?.move.type === "pack" && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>Pack</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Bought in a pack</p>
                  </div>
                )}
                {(lastMove?.move.type === "direct_offer" ||
                  lastMove?.move.type === "transfer" ||
                  (lastMove?.move.type === "offer" && lastMove.price === 0)) && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>Traded</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Direct offer</p>
                  </div>
                )}
                {(lastMove?.move.type === "single_buy_offer" ||
                  lastMove?.move.type === "single_sale_offer" ||
                  (lastMove?.move.type === "offer" && lastMove.price > 0)) && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>{lastMovePrice}</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Bought on the market</p>
                  </div>
                )}
                {lastMove?.move.type === "instant_buy" && lastMove.price > 0 && (
                  <div className={"w-full flex flex-col items-center"}>
                    <p className={"text-center text-lg font-semibold self-center align-middle"}>{lastMovePrice}</p>
                    <p className={"text-center text-textGrey3 font-semibold text-base"}>Instant Buy</p>
                  </div>
                )}
              </div>
            )}

            <div>
              <a href={theCardLink} target={"_blank"} rel="noreferrer">
                <div className={"flex flex-row space-x-2 justify-center"}>
                  <img src={Link} />
                  <p className={"text-brand text-center text-sm font-semibold"}>View card on Sorare</p>
                </div>
              </a>
            </div>
          </div>

          <div className={"rounded-lg bg-white shadow-sm p-4 w-full md:w-6/12 z-10 items-center justify-center flex flex-col space-y-2"}>
            {lastMove?.move.type !== "on_auction" && (
              <p className={"self-center text-center text-sm font-medium text-textGrey3 self-center align-middle"}>
                Under contract with{" "}
                <span className={"text-brand-black font-semibold"}>
                  <a href={"/manager/" + lastMove?.manager_receiving.Slug}>
                    {lastMove?.manager_receiving.TeamName} ({lastMove?.manager_receiving?.Nickname})
                  </a>
                </span>
                <br />
                since {new Date(lastMove?.move.date).toLocaleDateString()}
              </p>
            )}
            {lastMove?.move.type === "on_auction" && (
              <p className={"text-center text-sm font-medium text-textGrey3 self-center align-middle"}>
                This card is currently being sold by Sorare.
              </p>
            )}
            {lastMove?.move.type !== "on_auction" && sport === "sr_football" && (
              <a href={collectionLink} target={"_blank"} rel="noreferrer">
                <div className={"flex flex-row space-x-2 justify-center"}>
                  <img src={Link} />
                  <p className={"text-brand text-center text-sm font-semibold"}>View associated collection</p>
                </div>
              </a>
            )}
          </div>
        </div>
        <div className="bg-white mx-auto my-8 flex flex-row self-center xl:w-4/12 lg:w-5/12 md:w-7/12 w-11/12 text-base justify-center font-semibold rounded-xl">
          <div className={activeTab === "history" ? activeClass : inactiveClass} onClick={() => setActiveTab("history")}>
            <p>History</p>
          </div>
          <img src={LineSeparator} />
          <div className={activeTab === "profit" ? activeClass : inactiveClass} onClick={() => setActiveTab("profit")}>
            <p>SO5 rewards</p>
          </div>
          <img src={LineSeparator} />
          <div className={activeTab === "scores" ? activeClass : inactiveClass} onClick={() => setActiveTab("scores")}>
            <p>SO5 scores</p>
          </div>
        </div>

        {activeTab === "history" && (
          <div className={"w-11/12 lg:w-10/12 2.5xl:w-9/12 4xl:w-8/12 mx-auto space-y-4"}>
            <div className={"w-full flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0"}>
              <div className={"xl:w-9/12 md:w-8/12 w-full rounded-lg"}>
                <PlayerPriceGraph scarcity={card.Scarcity} sport={sport} player={player} noLimitBox={true} customHeight={"20rem"} />
              </div>
              <div className={"bg-white rounded-lg w-full mx-auto md:w-4/12 xl:w-3/12 pt-4 pb-4 md:pb-0 flex flex-col"}>
                <div className={"border-b pb-4 space-y-4"}>
                  <div className={"text-center space-y-2 pb-2"}>
                    <div className={"flex flex-row space-x-4 justify-center"}>
                      <ScoreAverage playerId={player.PlayerId} sport={player.Sport} period={5} played={info.gms_5} avg={info.avg_5} />
                      <ScoreAverage playerId={player.PlayerId} sport={player.Sport} period={15} played={info.gms_15} avg={info.avg_15} />
                      <ScoreAverage playerId={player.PlayerId} sport={player.Sport} period={40} played={info.gms_40} avg={info.avg_40} />
                    </div>
                  </div>
                  <div className={"text-center space-y-2"}>
                    <p className={"text-textGrey3 font-semibold text-sm"}>Floor price</p>
                    <div className={"flex flex-row justify-center space-x-2"}>
                      <img className={"w-2 h-2 self-center"} src={icon} />
                      <p className={"font-semibold text-xl text-" + formattedScarcity}>
                        {formatBestMarketPrice(info.bmp, props.user.preferredUnit)}
                      </p>
                      {shouldDisplaySecondUnit && (
                        <p className={"font-semibold text-sm self-center"}>{formatBestMarketPrice(info.bmp, props.user.secondUnit)}</p>
                      )}
                    </div>
                  </div>
                  <div className={"text-center space-y-2"}>
                    <p className={"text-textGrey3 font-semibold text-sm"}>Latest trading average</p>
                    <div className={"flex flex-row justify-center space-x-2"}>
                      <img className={"w-2 h-2 self-center"} src={icon} />
                      <p className={"font-semibold text-xl text-" + formattedScarcity}>
                        {formatRealTimeAverage(info.rt, props.user.preferredUnit)}
                      </p>
                      {shouldDisplaySecondUnit && (
                        <p className={"font-semibold text-sm self-center"}>{formatRealTimeAverage(info.rt, props.user.secondUnit)}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={"pt-4 space-y-4"}>
                  <p className={"text-textGrey3 font-semibold text-sm pl-2"}>Last 5 sales</p>
                  <div className={"space-y-4"}>
                    {lastExchanges.map((e) => {
                      let type = "Auction";
                      if (e.exchange_type === "privateOffer") {
                        type = "Direct offer";
                      } else if (e.exchange_type === "publicOffer") {
                        type = "Public offer";
                      } else if (e.exchange_type === "instant_buy") {
                        type = "Instant buy";
                      }
                      let value = e.exchange_value;
                      if (props.user.preferredUnit !== "eth") {
                        value = e[props.user.preferredUnit];
                      }
                      return (
                        <div className={"flex flex-row justify-between w-11/12 px-2 mx-auto"}>
                          <div className={"flex flex-col self-center text-left"}>
                            <p className={"text-left"}>
                              <span className={"self-center font-semibold text-lg text-" + formattedScarcity}>
                                {formatPrice(value, props.user.preferredUnit)}
                              </span>
                            </p>
                            {shouldDisplaySecondUnit && (
                              <p className={"self-center"}>
                                <span className={"text-left font-semibold text-sm"}>
                                  {formatPrice(e[props.user.secondUnit] || e.exchange_value, props.user.secondUnit)}
                                </span>
                              </p>
                            )}
                          </div>

                          <div className={"flex flex-col space-y-1 self-center text-right"}>
                            <span className={"text-sm font-semibold "}>{type}</span>
                            <span className={"text-xs font-semibold text-textGrey3"}>
                              {formatDistance(new Date(e.exchange_date), new Date(), { addSuffix: true })}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {isFree(props.user?.tier) && sport === sorareFootball && (
              <UpgradeLimitBox
                className={"bg-white bg-upgrade-bg-graph-card bg-cover h-upgrade-banner-m w-full mt-4 "}
                title={"Want more insights?"}
                description={"Become a Star member to unlock filters, valuation history, floor price, “Your sales” and “Your purchases”"}
                button={"Upgrade"}
              />
            )}
            <CardMoves moves={moves} player={info.player} />
          </div>
        )}
        {activeTab === "profit" && (
          <div className={"w-11/12 lg:w-10/12 2.5xl:w-9/12 4xl:w-8/12 mx-auto"}>
            <CardProfitability card={info?.card} active={activeTab === "profit"} sport={sport} />
          </div>
        )}
        {activeTab === "scores" && (
          <div className={"w-11/12 lg:w-10/12 2.5xl:w-9/12 4xl:w-8/12 mx-auto"}>
            <PlayerScoresTab info={info} active={activeTab === "scores"} />
          </div>
        )}
        <ReactTooltip />
      </div>
    );
  } else {
    return (
      <div>
        <SDLoading />
      </div>
    );
  }
}

const HiglightedCard = (props) => {
  const { card } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={"flex flex-row justify-center z-10 md:pt-8"}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={isHover ? { zIndex: 10000 } : {}}
    >
      <CardShowcase card={card} width={"md:w-card-in-header"} height={"md:h-card-in-header"} />
    </div>
  );
};

export default withUser(NewCardPage);
