import { ReactComponent as IconCircle } from "../../img/icons-circle.svg";
import React from "react";
import { withUser } from "../../userContext";
import PriceAlertInput from "../util/priceAlertInput";
import { scarcities_objects_not_all } from "../util/scarcities";
import { ReactComponent as IconTrash } from "../../img/icons-trash.svg";
import { useTailwindMediaQueries } from "./mediaQueries";
import { Button } from "./button";
import DisableDimmer from "../user/priceAlertDisableDimmer";

const NotificationsList = (props) => {
  const { player } = props;

  const { notifications, onChangeNotifications, onDeleteNotification } = props;
  const { limitations, onActivate, active } = props;
  const mdQueries = useTailwindMediaQueries();
  const display = mdQueries["md"] ? "horizontal" : "vertical";
  const changeNotification = (notification) => {
    onChangeNotifications && onChangeNotifications(notification);
  };

  const deletePriceAlert = (scarcities) => {
    onDeleteNotification && onDeleteNotification(scarcities);
  };

  const headers = [
    <th
      key={"limited"}
      className={`${display === "horizontal" ? "p-2" : "p-4"} bg-brand-black text-white text-center`}
      title={"Price alert for 'Limited' scarcity"}
    >
      <div className={"relative m-auto w-full"}>
        <div className={"flex flex-col gap-1"}>
          <span className={"fill-limited self-center"}>
            <IconCircle />
          </span>
          <span>Price alert</span>
        </div>
      </div>
    </th>,
    <th
      key={"rare"}
      className={`${display === "horizontal" ? "p-2" : "p-4"} bg-brand-black text-white text-center`}
      title={"Price alert for 'Rare' scarcity"}
    >
      <div className={"relative m-auto w-full"}>
        <div className={"flex flex-col gap-1"}>
          <span className={"fill-rare self-center"}>
            <IconCircle />
          </span>
          <span>Price alert</span>
        </div>
      </div>
    </th>,
    <th
      key={"super-rare"}
      className={`${display === "horizontal" ? "p-2" : "p-4"} bg-brand-black text-white text-center`}
      title={"Price alert for 'Super Rare' scarcity"}
    >
      <div className={"relative m-auto w-full"}>
        <div className={"flex flex-col gap-1"}>
          <span className={"fill-super-rare self-center"}>
            <IconCircle />
          </span>
          <span>Price alert</span>
        </div>
      </div>
    </th>,
    <th
      key={"unique"}
      className={`${display === "horizontal" ? "p-2" : "p-4"} bg-brand-black text-white text-center`}
      title={"Price alert for 'Unique' scarcity"}
    >
      <div className={"relative m-auto w-full"}>
        <div className={"flex flex-col gap-1"}>
          <span className={"fill-unique self-center"}>
            <IconCircle />
          </span>
          <span>Price alert</span>
        </div>
      </div>
    </th>,
  ];

  const inputs = scarcities_objects_not_all.map((o) => {
    const scarcity = o.value;
    return (
      <div key={scarcity} className={"px-2 py-2"} style={{ width: display === "horizontal" ? "14em" : "100%" }}>
        <div className={"w-full flex justify-center"}>
          <PriceAlertInput
            scarcity={scarcity}
            player={player}
            notification={notifications[scarcity]}
            onChange={changeNotification}
            onDelete={(playerId, scarcity) => deletePriceAlert([scarcity])}
          />
        </div>
      </div>
    );
  });
  const hasNotifications =
    scarcities_objects_not_all.find(({ _, value }) => notifications[value] && notifications[value].price) !== undefined;
  if (display === "horizontal") {
    return (
      <table className="table-auto bg-focus shadow rounded-lg h-auto overflow-hidden text-xs font-semibold w-full overflow-auto">
        <thead>
          <tr>
            {headers}
            <th className={"p-2 bg-brand-black"}></th>
          </tr>
        </thead>
        <tbody>
          <tr className={"border-b border-grey-e9 "}>
            <td colSpan={scarcities_objects_not_all.length}>
              <div className={`${active ? "" : "relative"} flex flex-row justify-evenly`}>
                <DisableDimmer
                  active={active}
                  playerId={player.PlayerId}
                  sport={player.Sport}
                  limitations={limitations}
                  onActivate={onActivate}
                />
                {inputs}
              </div>
            </td>
            <td className={"px-2"}>
              <div className={"px-1 flex flex-row justify-center m-auto hover:opacity-80"}>
                <IconTrash
                  disable={!hasNotifications}
                  title={`Click to remove all price alerts for "${player.DisplayName}"`}
                  className="h-4 w-4 cursor-pointer"
                  onClick={() => deletePriceAlert([])}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  if (display === "vertical") {
    return (
      <table className="table-auto bg-focus shadow rounded-lg h-auto overflow-hidden text-xs font-semibold overflow-auto">
        {headers.map((headerComponent, i) => {
          return (
            <tr key={i}>
              {headerComponent}
              {i == 0 ? (
                <td rowSpan={scarcities_objects_not_all.length}>
                  <div className={`${active ? "" : "relative"} flex flex-col justify-evenly`}>
                    <DisableDimmer
                      active={active}
                      playerId={player.PlayerId}
                      sport={player.Sport}
                      limitations={limitations}
                      onActivate={onActivate}
                    />
                    {inputs}
                  </div>
                </td>
              ) : (
                <td />
              )}
            </tr>
          );
        })}
        <tr>
          <th className={"p-2 bg-brand-black"}></th>
          <td className={"p-2 flex m-auto justify-center border-t border-grey-e5"}>
            <Button
              disabled={!hasNotifications}
              label={"Remove all"}
              title={hasNotifications ? `Click to remove all price alerts for "${player.DisplayName}"` : ""}
              onClick={() => deletePriceAlert([])}
            />
          </td>
        </tr>
      </table>
    );
  }

  return null;
};

export default withUser(NotificationsList);
