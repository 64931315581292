import React from "react";
import Toggle from "./toggle";

function ToggleWithLabel(props) {
  let noMargin = props.noMargin;
  let margin = "mb-2";
  if (noMargin) {
    margin = "";
  }
  return (
    <div className={"flex flex-row space-x-2 " + margin}>
      <Toggle setActive={() => props.setActive()} isActive={props.isActive} />
      <p className={"text-sm self-center"}>{props.label}</p>
    </div>
  );
}

export default ToggleWithLabel;
