import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import PreviousGw from "../../img/previous-gw.svg";
import NextGw from "../../img/next-gw.svg";
import GwBanner from "../../img/gwbanner.png";
import { errorCatcher } from "../util/errors";
import ProjGwCenterTab from "../probableStarters/projGwCenterTab";
import { sorareFootball } from "../util/sports";

function SO5CommunityLineups(props) {
  const [gwNumber, setGwNumber] = useState(0);
  const [displayGw, setDisplayGw] = useState("");
  const [sport, setSport] = useState(sorareFootball);

  useEffect(() => {
    document.title = "Projected lineups";
    getLatestGw();
  }, [sport]);

  const getLatestGw = () => {
    props
      .fetch("/apiv2/gw/latest?sport=" + sport)
      .then((response) => response.json())
      .then(async (res) => {
        getGwByNumber(res.GwNumber + 1);
      })
      .catch(errorCatcher());
  };

  const getGwByNumber = (gw) => {
    props
      .fetch("/apiv2/gw/byNumber/" + gw + "?sport=" + sport)
      .then((response) => response.json())
      .then(async (res) => {
        setGwNumber(res.GwNumber);
        setDisplayGw(res.DisplayName);
      })
      .catch(errorCatcher());
  };

  const next = async () => {
    getGwByNumber(gwNumber + 1);
    setGwNumber(gwNumber + 1);
  };

  const previous = async () => {
    getGwByNumber(gwNumber - 1);
    setGwNumber(gwNumber - 1);
  };

  const gwDisplayName = displayGw;

  return (
    <div>
      <div
        className={"bg-white flex flex-col "}
        style={{
          backgroundImage: "url('" + GwBanner + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "180px",
        }}
      >
        <div
          className={
            "flex flex-col h-full justify-evenly sm:h-[inherit] sm:flex-row sm:py-6 sm:h-[180px] sm:pb-8 " + GLOBAL_MARGIN_NO_BG_NO_PAD
          }
        >
          <div className={"flex flex-row w-full sm:w-6/12 self-center justify-start"}>
            <p className={"text-4xl font-headers font-semibold text-white text-center sm:text-left w-full"}>Projected Lineups</p>
          </div>
          <div className={"w-full sm:w-6/12 self-center flex flex-col space-y-2 items-center sm:items-end justify-center sm:justify-end"}>
            <div className={"flex flex-row space-x-4 self-center justify-center sm:self-end sm:justify-end"}>
              <div className={"self-center py-2 px-4 rounded-lg bg-white"} style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}>
                <p className={"font-semibold text-sm sm:text-base self-center"}>{gwDisplayName}</p>
              </div>
              <div
                className={"bg-white cursor-pointer px-4 py-3 flex flex-row justify-center rounded-lg"}
                onClick={() => previous()}
                style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
              >
                <img src={PreviousGw} />
              </div>
              <div
                className={"bg-white cursor-pointer px-4 py-3 flex flex-row justify-center rounded-lg"}
                onClick={() => next()}
                style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
              >
                <img src={NextGw} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"bg-brand-black"}>
        <div className={"xl:ml-16 mx-auto mb-6 text-focus " + GLOBAL_MARGIN_NO_BG_NO_PAD}></div>
      </div>
      <div className={GLOBAL_MARGIN_NO_BG_NO_PAD}>
        {sport === sorareFootball && (
          <div>
            <ProjGwCenterTab gwNumber={gwNumber} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withUser(SO5CommunityLineups);
