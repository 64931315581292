import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import SO5LineupCards from "./SO5LineupCards";
import { ReactTooltip } from "../util/tooltip.js";
import ls from "local-storage";
import { errorCatcher } from "../util/errors";
import { sorareFootball } from "../util/sports";
import { LiveLineups, sortInfo } from "./commonLiveLineups";
import AbortController from "abort-controller";

const SO5Live = withUser((props) => {
  const [SO5info, setSO5info] = useState({});
  const [refreshing, setRefreshing] = useState(false);
  const [gw, setGw] = useState(null);
  const [username, setUsername] = useState(null);
  const [sortOption, setSortOption] = useState(ls.get("so5_football_sort") || "best_rank");
  const [abortController, setAbortController] = useState(new AbortController());

  useEffect(() => {
    init();
    ReactTooltip.rebuild();
  }, [props.gw, props.username, props.match]);

  const init = () => {
    let gw = props.gw;
    let username = props.username;
    if (gw === undefined && username === undefined) {
      gw = props.match.params.gw;
      username = props.match.params.username;
    }
    setGw(gw);
    setUsername(username);
    if (gw !== 0) {
      setRefreshing(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      props
        .fetch("/apiv2/SO5/players/" + username + "/" + gw, {
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then(async (res) => {
          setSO5info(res);
          setRefreshing(false);
        })
        .catch(
          errorCatcher(() => {
            setRefreshing(false);
          }),
        );
    }
  };

  const hideLineup = (id) => {
    props
      .fetch("/apiv2/SO5/hideLineup", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lineupId: id,
          sport: sorareFootball,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        onHideLineup(id);
      });
  };

  const onHideLineup = (id) => {
    let newInfo = { ...SO5info };
    newInfo.lineups.map((lineup) => {
      if (lineup.lineup.LineupId === id) {
        lineup.hidden = true;
      }
    });
    setSO5info(newInfo);
  };

  const onUnhideLineup = (id) => {
    let newInfo = { ...SO5info };
    newInfo.lineups.map((lineup) => {
      if (lineup.lineup.LineupId === id) {
        lineup.hidden = false;
      }
    });
    setSO5info(newInfo);
  };

  const onHideLongTermLeague = (id) => {
    let newInfo = { ...SO5info };
    newInfo.long_term_leagues.map((league) => {
      if (league.league.id === id) {
        league.hidden = true;
      }
    });
    setSO5info(newInfo);
  };

  const onUnhideLongTermLeague = (id) => {
    let newInfo = { ...SO5info };
    newInfo.long_term_leagues.map((league) => {
      if (league.league.id === id) {
        league.hidden = false;
      }
    });
    setSO5info(newInfo);
  };

  const rewards = {};
  SO5info.rewards?.map((r) => {
    rewards[r.lineupId] = {
      current: {
        cards: r.reward_cards || [],
        eth: r.eth,
        coins: r.coins,
        fiat: r.fiat,
      },
    };
  });
  Object.entries(SO5info.next_rewards || {}).map(([k, v]) => {
    if (rewards[k] === undefined) {
      rewards[k] = {};
    }
    rewards[k].next = {
      gap: v.gap,
      cards: v.reward_cards || [],
      eth: v.eth,
      coins: v.coins,
      fiat: v.fiat,
    };
  });

  sortInfo(SO5info, sortOption);
  return (
    <LiveLineups
      sport={sorareFootball}
      onHideLineup={onHideLineup}
      onUnhideLineup={onUnhideLineup}
      lineupHidable={true}
      onHideLongTermLeague={onHideLongTermLeague}
      onUnhideLongTermLeague={onUnhideLongTermLeague}
      longTermLeagueHidable={true}
      info={SO5info}
      rewards={rewards}
      loading={refreshing}
      gw={gw}
      username={username}
      sortSelected={sortOption}
      sortOptions={["az", "possible_score", "curr_score", "best_rank", "live"]}
      onSortChange={(v) => {
        ls.set("so5_football_sort", v);
        setSortOption(v);
      }}
    >
      {SO5info?.lineups?.map((lu) => {
        if (lu.hidden) {
          return null;
        }
        if (lu.players.rare_players !== null || lu.players.common_players !== null)
          return (
            <div key={lu.lineup.LineupId} className={"bg-[#1F1F1F] rounded-xl mx-auto px-4 xl:px-8 py-4"}>
              <SO5LineupCards
                players={lu.players}
                stats={SO5info.stats}
                comp={lu.competition}
                games={SO5info.games}
                lineup={lu.lineup}
                rewards={SO5info.rewards}
                rank={lu.lineup.Rank}
                nextRewards={SO5info.next_rewards}
                rewardsOverview={SO5info.rewards_overview}
                top={lu.lineup.Top}
                hideLineup={hideLineup}
                score={lu.lineup.Score}
                pointsLeftToReachTargetThreshold={lu.pointsLeftToReachTargetThreshold}
              />
            </div>
          );
        else return <div />;
      })}
    </LiveLineups>
  );
});

export default SO5Live;
