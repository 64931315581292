import React from "react";
import { ReactComponent as IconCertified } from "../../img/icons-certified.svg";
import clsx from "clsx";

export const ManagerVerifiedCheckMark = ({ managerSlug }) => {
  if (managerSlug !== "soraredata") {
    return null;
  }
  return (
    <span className="self-center">
      <IconCertified className="h-5 w-5 fill-quality-scale-excellent-quality-container-bright-fixed" />
    </span>
  );
};

export const SorareVerifiedCheckMark = ({ className, customIconSize }) => {
  let iconSize = "h-5 w-5";
  if (customIconSize !== "") {
    iconSize = customIconSize;
  }
  return (
    <span className={clsx(className, "self-center")}>
      <IconCertified className={clsx(iconSize, "fill-cost-linear-end-fixed")} />
    </span>
  );
};
