import React, { useState } from "react";
import { sorareBaseball } from "../util/sports";

const DisableDimmer = (props) => {
  const { limitations, active, playerId, onActivate, sport } = props;

  const [hover, setHover] = useState(false);

  const disabled =
    (active !== undefined && !active) || // notification is disabled
    (!limitations.creationAuthorized && !limitations.authorizedPlayers?.includes(playerId) && sport !== sorareBaseball); // notification does not exist but player is not authorized;
  const canActivate =
    disabled &&
    active !== undefined &&
    (limitations.activationAuthorized || limitations.authorizedPlayers?.includes(playerId) || sport === sorareBaseball);
  const title = disabled
    ? limitations.activationAuthorized
      ? limitations.max !== undefined
        ? `Price alerts have been disabled but you can reactivate them up to ${
            limitations.max - limitations.currentActive
          } different players`
        : "Price alerts have been disabled but you can reactivate them all"
      : `You reached the limit of ${limitations.max} players with price alerts for your membership. Either: upgrade your membership, remove some to reactivate disabled ones, remove all disabled and some active ones to add new ones`
    : "";

  if (!disabled) {
    return null;
  }
  return (
    <div
      className={`absolute z-20 w-full h-full bg-opacity-70 flex justify-center ${!canActivate ? "cursor-not-allowed" : ""}`}
      title={title}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {canActivate && hover && (
        <button className={"my-auto bg-brand text-md text-focus p-2 rounded-md"} onClick={() => onActivate(playerId)}>
          Activate
        </button>
      )}
    </div>
  );
};

export default DisableDimmer;
