import React from "react";
import { withUser } from "../../userContext";
import RewardCardEntry from "./rewardCardEntry";
import Ethereum from "../../img/ethereum-icon-white.svg";
import FingersCrossed from "../../img/fingers-crossed.svg";
import Thumb from "../../img/thumb-emoji.png";
import Flame from "../../img/flame.svg";
import Clover from "../../img/clover.svg";
import SDLogo from "../../img/sd-logo.svg";
import ScarcityCards from "../../img/cards-scarcity.png";
import EthCoinsCards from "../../img/cards-eth-coins.png";
import { findPriceForUserUnit } from "../util/formatMoney";
import Coin from "../../img/coin.png";
import Box from "../../img/icon-reward-box.svg";
import { sorareBasketball, sorareFootball } from "../util/sports";

function RewardCard(props) {
  const info = props.info;
  let nbOfRewards = 0;
  if (info.rewards !== null) {
    info.rewards.map((r) => {
      if (props.sport === sorareBasketball) {
        if (r.reward_cards !== null) {
          nbOfRewards += r.reward_cards?.length;
        }
      } else {
        if (r.scarcity !== "") {
          nbOfRewards++;
        } else if (r.eth > 0) {
          nbOfRewards++;
        }
      }
    });
  }
  if (info.rewards !== null && nbOfRewards === 0) {
    nbOfRewards = 1;
  }
  if (info.rewards_level !== "" && props.sport === sorareFootball) {
    if (info.rewards_level === "no_rewards") {
      nbOfRewards = 0;
    } else if (info.rewards_level === "low") {
      nbOfRewards = 2;
    } else if (info.rewards_level === "medium") {
      nbOfRewards = 4;
    } else if (info.rewards_level === "high") {
      nbOfRewards = 7;
    }
  }
  if (nbOfRewards > 0) {
    let img =
      "1.5xl:bg-[url('../public/images/sofarsogood-1440.png')] bg-[url('../public/images/sofarsogood-1024.png')] xl:bg-[url('../public/images/sofarsogood-1280.png')]";
    let cardImg = EthCoinsCards;
    let text = "So far, so good!";
    let textColor = "text-black";
    let emoji = Thumb;
    let bottom = "bottom-16";
    let justify = "items-start";
    let bg = "bg-[#000000] bg-opacity-40";
    if (nbOfRewards >= 6 || (props.sport === sorareBasketball && nbOfRewards >= 4)) {
      img =
        "1.5xl:bg-[url('../public/images/whatagameweek-1440.png')] bg-[url('../public/images/whatagameweek-1024.png')] xl:bg-[url('../public/images/whatagameweek-1280.png')]";
      cardImg = ScarcityCards;
      text = "What a gameweek!";
      emoji = Flame;
      bottom = "bottom-4";
      textColor = "text-white";
      justify = "items-start";
      bg = "bg-opacity-20 bg-white";
    } else if (nbOfRewards >= 3 || (props.sport === sorareBasketball && nbOfRewards >= 2)) {
      img =
        "1.5xl:bg-[url('../public/images/lookinggood-1440.png')] bg-[url('../public/images/lookinggood-1024.png')] xl:bg-[url('../public/images/lookinggood-1280.png')]";
      cardImg = ScarcityCards;
      text = "It's looking good!";
      bottom = "bottom-12";
      emoji = FingersCrossed;
      textColor = "text-white";
      justify = "items-start";
      bg = "bg-opacity-20 bg-white";
    }
    return (
      <div
        className={"relative w-full h-reward-card rounded-xl self-center bg-no-repeat bg-center bg-cover overflow-hidden " + img}
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={"w-full h-full flex flex-col space-y-2 justify-center p-8 z-10 " + justify}>
          <img src={SDLogo} className="absolute top-7 right-7 w-6" alt="" />

          {((nbOfRewards >= 3 && props.sport === sorareFootball) || (props.sport === sorareBasketball && nbOfRewards >= 2)) && (
            <div className={"flex flex-row space-x-2"}>
              <p className={"font-semibold self-center font-headers text-xl " + textColor}>{text}</p>
              <img className={"mx-auto w-6 h-6 self-center"} src={emoji} />
            </div>
          )}
          {((nbOfRewards < 3 && props.sport === sorareFootball) || (props.sport === sorareBasketball && nbOfRewards < 2)) && (
            <div className={"flex flex-col space-y-2"}>
              <img className={"w-10 h-10"} src={emoji} />
              <p className={"font-semibold  font-headers text-xl " + textColor}>{text}</p>
            </div>
          )}
          {((nbOfRewards >= 3 && props.sport === sorareFootball) || (props.sport === sorareBasketball && nbOfRewards >= 2)) && (
            <div className={"space-y-2"}>
              {info.unique_rewards.total_cards > 0 && <RewardCardEntry bg={bg} info={info.unique_rewards} scarcity={"unique"} />}
              {info.super_rare_rewards.total_cards > 0 && (
                <RewardCardEntry bg={bg} info={info.super_rare_rewards} scarcity={"super rare"} />
              )}
              {info.rare_rewards.total_cards > 0 && <RewardCardEntry bg={bg} info={info.rare_rewards} scarcity={"rare"} />}
              {info.limited_rewards.total_cards > 0 && <RewardCardEntry bg={bg} info={info.limited_rewards} scarcity={"limited"} />}
              <div className={"flex flex-row space-x-2 " + justify}>
                {info.eth_rewards > 0 && (
                  <div className={bg + " flex flex-row space-x-1 px-2 py-1 rounded-lg text-white"}>
                    <p className={"flex flex-row space-x-2 justify-center"}>
                      {props.user.preferredUnit === "eth" && <img src={Ethereum} className={"w-5"} />}
                      <span className={"font-semibold self-end text-sm"}>
                        {findPriceForUserUnit(info.eth_rewards, info.fiat_rewards, props.user.preferredUnit)}
                      </span>
                    </p>
                  </div>
                )}
                {info.coins_rewards > 0 && (
                  <div className={"bg-opacity-20 bg-white flex flex-row space-x-1 px-2 py-1 rounded-lg text-white"}>
                    <p className={"flex flex-row space-x-2 justify-center"}>
                      <img src={Coin} className={"self-center"} style={{ height: "14px" }} />
                      <span className={"font-semibold self-end text-sm"}>{info.coins_rewards}</span>
                    </p>
                  </div>
                )}
                {info.box_rewards?.length > 0 && (
                  <div className={"bg-opacity-20 bg-white flex flex-row space-x-1 px-2 py-1 rounded-lg text-white"}>
                    <p className={"flex flex-row space-x-2 justify-start"}>
                      <img src={Box} className={"self-center"} style={{ height: "15px" }} />
                      <span className={"font-semibold self-end text-sm"}>x{info.box_rewards?.length ?? 0}</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {((nbOfRewards < 3 && props.sport === sorareFootball) || (props.sport === sorareBasketball && nbOfRewards < 2)) && (
            <div className={"space-y-2 flex flex-col"}>
              {info.unique_rewards.total_cards > 0 && (
                <RewardCardEntry bg={bg} info={info.unique_rewards} textColor={"text-[#000000]"} scarcity={"unique"} />
              )}
              {info.super_rare_rewards.total_cards > 0 && (
                <RewardCardEntry bg={bg} info={info.super_rare_rewards} textColor={"text-[#000000]"} scarcity={"super rare"} />
              )}
              {info.rare_rewards.total_cards > 0 && (
                <RewardCardEntry bg={bg} info={info.rare_rewards} textColor={"text-[#000000]"} scarcity={"rare"} />
              )}
              {info.limited_rewards.total_cards > 0 && (
                <RewardCardEntry bg={bg} info={info.limited_rewards} textColor={"text-[#000000]"} scarcity={"limited"} />
              )}
              <div className={"flex flex-row space-x-2 " + justify}>
                {info.eth_rewards > 0 && (
                  <div className={"bg-[#000000] bg-opacity-40 flex flex-row space-x-1 px-2 py-1 rounded-lg text-white"}>
                    <p className={"flex flex-row space-x-2 justify-center"}>
                      {props.user.preferredUnit === "eth" && <img src={Ethereum} className={"w-5"} />}
                      <span className={"font-semibold self-end text-sm"}>
                        {findPriceForUserUnit(info.eth_rewards, info.fiat_rewards, props.user.preferredUnit)}
                      </span>
                    </p>
                  </div>
                )}
                {info.coins_rewards > 0 && (
                  <div className={"bg-[#000000] bg-opacity-40 bg-white flex flex-row space-x-1 px-2 py-1 rounded-lg text-white"}>
                    <p className={"flex flex-row space-x-2 justify-start"}>
                      <img src={Coin} className={"self-center"} style={{ height: "15px" }} />
                      <span className={"font-semibold self-end text-sm"}>{info.coins_rewards}</span>
                    </p>
                  </div>
                )}
                {info.box_rewards?.length > 0 && (
                  <div className={"bg-[#000000] bg-opacity-40 bg-white flex flex-row space-x-1 px-2 py-1 rounded-lg text-white"}>
                    <p className={"flex flex-row space-x-2 justify-start"}>
                      <img src={Box} className={"self-center"} style={{ height: "15px" }} />
                      <span className={"font-semibold self-end text-sm"}>x{info.box_rewards?.length ?? 0}</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          <img src={cardImg} className="absolute bottom-0 right-0" alt="" />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={
          "relative w-full h-reward-card rounded-xl bg-no-repeat bg-center bg-cover self-center 1.5xl:bg-[url('../public/images/default-1440.png')] bg-[url('../public/images/default-1024.png')] xl:bg-[url('../public/images/default-1280.png')]"
        }
      >
        <div className={"w-full flex flex-col h-reward-card space-y-4 self-center justify-center py-4 z-10"}>
          <img className={"mx-auto w-24"} src={Clover} />
          <p className={"font-semibold font-headers text-2xl text-center"}>No luck this time!</p>
          <img src={SDLogo} className="absolute top-3 right-7 w-6" alt="" />
        </div>
      </div>
    );
  }
}

export default withUser(RewardCard);
