import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../util/button";
import { isMobile } from "react-device-detect";
import { MobileModale } from "../util/mobileModale";

export const UnsaveChangesModal = (props) => {
  const { show, close, onDiscard, onDontAskMeAgain } = props;
  const [dontAskMeAgain, setDontAskMeAgain] = useState(false);
  const onChangeDontAskMeAgain = () => {
    const newDontAskMeAgain = !dontAskMeAgain;
    setDontAskMeAgain(newDontAskMeAgain);
    onDontAskMeAgain && onDontAskMeAgain(newDontAskMeAgain);
  };

  if (isMobile) {
    // Do not support on mobile for now
    // Due to a bug for consecutive modals opening making the screen not scrollable after close
    // Can enable back after fix of https://linear.app/soraredata/issue/SDFE-442/cant-scroll-after-two-consecutive-modals
    return null;
    /*
    return (
      <MobileModale open={show} close={close}>
        <div className={"bg-surface-container text-on-surface rounded-t-lg text-left"}>
          <div className={"border-b border-transparent-inverse-surface-low border-opacity-10"}>
            <div className={"flex flex-row justify-start px-4 py-4"}>
              <h3 className={"font-headers font-semibold m-0"}>Discard changes?</h3>
            </div>
          </div>
          <div className={"max-h-[80vh] overflow-y-auto text-sm w-full"}>
            <div className={"flex flex-col justify-center items-left w-full font-sans gap-2 font-medium text-sm p-3"}>
              <div>
                <p>The current lineup has been modified.</p>
                <p>Leaving without saving will discard all changes.</p>
              </div>
              <div className="flex items-center cursor-pointer" onClick={onChangeDontAskMeAgain}>
                <input
                  type="checkbox"
                  checked={dontAskMeAgain}
                  className="w-4 h-4 primary bg-surface-container border-transparent-inverse-surface-low border-opacity-70 rounded ring-0 focus:ring-0"
                />
                <label htmlFor="default-checkbox" className="ml-2">
                  Don’t ask me again
                </label>
              </div>
            </div>
            <div className="flex justify-end w-full p-3">
              <div className={"flex flex-row gap-2"}>
                <Button context="secondary" label={"Stay"} onClick={close} />
                <Button label={"Leave"} onClick={() => onDiscard && onDiscard()} />
              </div>
            </div>
          </div>
        </div>
      </MobileModale>
    );
     */
  }
  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-500 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={"inline-block transform transition-all sm:my-8 sm:align-middle"}>
                <div className={"flex flex-row gap-4 justify-center"}>
                  <div className={"max-w-3xl relative align-bottom bg-surface-container-high rounded-lg pt-5 text-left shadow-xl"}>
                    <div className={"font-sans flex flex-col text-on-surface"}>
                      <div className="font-headers px-5 border-b border-transparent-inverse-surface-low border-opacity-10 pb-4 flex flex-col justify-center items-center gap-2">
                        <p className="text-xl font-headers font-semibold m-0 text-center">Discard changes?</p>
                      </div>
                      <div
                        className={
                          "flex flex-col gap-3 px-4 py-4 transition-height transition-slowest ease min-w-[25em] border-b border-transparent-inverse-surface-low border-opacity-10"
                        }
                      >
                        <div className={"flex flex-col justify-center items-left w-full font-sans gap-2 font-medium text-sm"}>
                          <div>
                            <p>The current lineup has been modified.</p>
                            <p>Leaving without saving will discard all changes.</p>
                          </div>
                          <div className="flex items-center cursor-pointer" onClick={onChangeDontAskMeAgain}>
                            <input
                              type="checkbox"
                              checked={dontAskMeAgain}
                              className="w-4 h-4 primary bg-surface-container border-transparent-inverse-surface-low border-opacity-70 rounded ring-0 focus:ring-0"
                            />
                            <label htmlFor="default-checkbox" className="ml-2">
                              Don’t ask me again
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end w-full p-3">
                        <div className={"flex flex-row gap-2"}>
                          <Button context="secondary" label={"Stay"} onClick={close} />
                          <Button label={"Leave"} onClick={onDiscard} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
