import React, { useEffect, useState } from "react";
import { Box, Heading, InfiniteScroll, Select } from "grommet";
import { ReactTooltip } from "../util/tooltip.js";
import ResponsiveGrid from "../util/responsiveGrid";
import SO5SelectionLine from "./SO5SelectionLine";
import SO5SelectionTeam from "./SO5SelectionTeam";
import { cutGwNumber } from "../util/cutGwNumber";
import Spinner from "../loader/spinner";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

function SO5FullSelectionStats(props) {
  const [popularPicks, setPopularPicks] = useState([]);
  const [displayedPicks, setDisplayedPicks] = useState([]);
  const [positionValue, setPositionValue] = useState("Overall");
  const [gwIds, setGwIds] = useState([]);
  const [gwDisplayNames, setGwDisplayNames] = useState([]);
  const [gwValue, setGwValue] = useState("");

  useEffect(() => {
    props
      .fetch("/apiv2/gw/resultsList")
      .then((response) => response.json())
      .then(async (res) => {
        let gwIds = [];
        let gwDisplayNames = [];
        res.map((gw) => {
          gwIds.push(gw.Id);
          gwDisplayNames.push(gw.DisplayName);
          return null;
        });
        let gwValue = gwDisplayNames[0];
        setGwIds(gwIds);
        setGwDisplayNames(gwDisplayNames);
        setGwValue(gwValue);
      })
      .catch(errorCatcher());
  }, []);

  useEffect(() => {
    getPopularPicks();
  }, [gwValue, positionValue]);

  const getPopularPicks = () => {
    if (gwValue !== "") {
      let positionValueGet = positionValue;
      if (positionValueGet === "Overall") positionValueGet = "all";
      props
        .fetch("/apiv2/SO5/popularPicks/" + positionValueGet + "/" + cutGwNumber(gwValue) + "/" + 10000000)
        .then((response) => response.json())
        .then((res) => {
          setPopularPicks(res);
          setDisplayedPicks(res.slice(0, 100));
        })
        .catch(errorCatcher());
    }
  };

  let columns = {
    small: ["auto"],
    medium: ["auto", "auto"],
    large: ["auto", "auto"],
  };

  const rows = {
    small: ["auto"],
    medium: ["auto"],
    large: ["auto"],
  };

  const onMore = () => {
    setDisplayedPicks(popularPicks.slice(0, displayedPicks.length + 50));
  };

  return (
    <Box align={"center"} width={"auto"} margin={"auto"}>
      <Heading level={"2"} color={"brand"}>
        Gameweek popular picks
      </Heading>
      <Box width={"auto"} margin={{ bottom: "medium" }} direction={"row"} gap={"small"}>
        <Select options={gwDisplayNames} value={gwValue} onChange={({ option }) => setGwValue(option)} />
        <Select
          options={["Overall", "Goalkeeper", "Defender", "Midfielder", "Forward", "Team"]}
          value={positionValue}
          onChange={({ option }) => setPositionValue(option)}
        />
      </Box>
      {popularPicks.length === 0 && <Spinner />}
      {popularPicks.length > 0 && (
        <Box>
          <ResponsiveGrid columns={columns} rows={rows}>
            <InfiniteScroll
              dataLength={displayedPicks.length}
              scrollableTarget={"window"}
              items={popularPicks}
              next={() => setDisplayedPicks(popularPicks.slice(0, displayedPicks.length + 50))}
              hasMore={popularPicks.length !== displayedPicks.length}
              useWindow={true}
              loader={<Spinner />}
            >
              {(p) => {
                if (positionValue !== "Team" && p.player !== undefined) {
                  return (
                    <Box
                      background={{ color: "white" }}
                      key={p.player.PlayerId}
                      width={"100%"}
                      pad={{ vertical: "xsmall", horizontal: "medium" }}
                      border={{ color: "#E8E8E8", size: "xsmall" }}
                    >
                      <SO5SelectionLine key={p.player.PlayerId} player={p} />
                    </Box>
                  );
                } else if (p.team !== undefined) {
                  return (
                    <Box
                      background={{ color: "white" }}
                      width={"100%"}
                      pad={{ vertical: "xsmall", horizontal: "medium" }}
                      border={{ color: "#E8E8E8", size: "xsmall" }}
                    >
                      <SO5SelectionTeam key={p.team.TeamId} team={p} />
                    </Box>
                  );
                } else return null;
              }}
            </InfiniteScroll>
          </ResponsiveGrid>
          <ReactTooltip />
        </Box>
      )}
    </Box>
  );
}

export default withUser(SO5FullSelectionStats);
