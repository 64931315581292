import { v4 as uuidv4 } from "uuid";
export const affilatedSorareLink = "http://sorare.pxf.io/m6Ld7";

export const getHostnameFromURL = (url) => {
  if (!url) {
    return undefined;
  }
  try {
    let u = new URL(url);
    return u.hostname;
  } catch (e) {
    return undefined;
  }
};

// Useful for dealing with CORS on Chrome with cached images
// See https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
export const urlNoCache = (url) => {
  if (url) {
    return url + "?tmp-" + uuidv4();
  }
  return url;
};
