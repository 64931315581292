import React from "react";

const tierWeights = {
  free: 1,
  tier1: 2,
  tier2: 3,
  tier3: 4,
};

const tierBadgeInfo = {
  free: {
    bg: "bg-membership-free",
    text: "text-membership-text-free",
    label: "Rookie",
  },
  tier1: {
    bg: "bg-membership-t1",
    text: "text-white",
    label: "Pro",
  },
  tier2: {
    bg: "bg-membership-t2",
    text: "text-white",
    label: "Star",
  },
  tier3: {
    bg: "bg-membership-t3",
    text: "text-white",
    label: "Legend",
  },
};

function isFree(tier) {
  return tier === "free";
}

function isTier1(tier) {
  return tier === "tier1";
}

function isTier2(tier) {
  return tier === "tier2";
}

function isTier3(tier) {
  return tier === "tier3";
}

function freeOrAbove(tier) {
  return tierWeights[tier] >= tierWeights["free"];
}

function t1OrAbove(tier) {
  return tierWeights[tier] >= tierWeights["tier1"];
}

function t2OrAbove(tier) {
  return tierWeights[tier] >= tierWeights["tier2"];
}

function t3OrAbove(tier) {
  return tierWeights[tier] >= tierWeights["tier3"];
}

function t2OrBelow(tier) {
  return tierWeights[tier] <= tierWeights["tier2"];
}

function t1OrBelow(tier) {
  return tierWeights[tier] <= tierWeights["tier1"];
}

const MembershipBadge = (props) => {
  const tier = props.tier || "free";
  const info = tierBadgeInfo[tier];
  return (
    <span
      className={`my-auto text-xxs font-bold rounded-full flex justify-center uppercase px-2 py-1 hover:opacity-90 ${info.text} ${info.bg}`}
    >
      <a className={"my-auto"} href={"/profile?target=subscription"}>
        {info.label}
      </a>
    </span>
  );
};

const membershipLabel = (tier) => {
  const t = tier || "free";
  return tierBadgeInfo[t].label;
};

const isStripeAllowed = (store) => {
  return store !== "app_store" && store !== "play_store";
};

export {
  isFree,
  isTier1,
  isTier2,
  isTier3,
  freeOrAbove,
  t1OrAbove,
  t2OrAbove,
  t3OrAbove,
  t2OrBelow,
  t1OrBelow,
  MembershipBadge,
  isStripeAllowed,
  membershipLabel,
};
