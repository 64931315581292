import { withUser } from "../../userContext";
import React, { useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import { FormSearch } from "grommet-icons";
import CustomSelect from "../util/customSelect";
import SortIcon from "../../img/sort-icon-black.svg";
import ManagerCollectionBox from "./managerCollectionBox";
import FilterAlt from "../../img/filter_alt.svg";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { scarcities_objects_not_all_with_custom_series } from "../util/scarcities";
import { seasons_objects_int } from "../util/seasons";
import { leagues_with_divisions_objects_not_all_star } from "../util/leagues";

const filterOptions = [
  { name: "Collection bonus", id: "bonus", value: "Collection bonus" },
  { name: "Scarcity", id: "scarcity", value: "Scarcity" },
  { name: "Old to recent", id: "old", value: "Old to recent" },
  { name: "Recent to old", id: "recent", value: "Recent to old" },
];

function ManagerCollections(props) {
  const [collections, setCollections] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [sortOption, setSortOption] = useState(filterOptions[0]);
  const [showFilters, setShowFilters] = useState(false);
  const [scarcityFilter, setScarcityFilter] = useState([]);
  const [seasonFilter, setSeasonFilter] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);

  const getCollections = () => {
    props
      .fetch("/apiv2/manager/collections?manager=" + props.manager.Slug)
      .then((response) => response.json())
      .then((res) => {
        setCollections(res);
        filter(res);
      })
      .catch(errorCatcher(() => {}));
  };

  const filter = (data, s, so, sc, se, l) => {
    if (data === undefined) {
      data = collections;
    }
    if (s === undefined) {
      s = searchValue;
    }
    if (sc === undefined) {
      sc = scarcityFilter;
    }
    if (se === undefined) {
      se = seasonFilter;
    }
    if (l === undefined) {
      l = leagueFilter;
    }
    let newCollections = [...data];
    let filtered = newCollections.filter((collection) => {
      return collection.team.DisplayName.toLowerCase().includes(s) || collection.team.ShortName.toLowerCase().includes(s);
    });
    filtered = filtered.filter((collection) => {
      return sc.indexOf(collection.scarcity) !== -1 || sc.length === 0;
    });
    filtered = filtered.filter((collection) => {
      return se.indexOf(collection.season) !== -1 || se.length === 0;
    });
    filtered = filtered.filter((collection) => {
      return l.indexOf(collection.so5Division) !== -1 || l.indexOf(collection.team.League) !== -1 || l.length === 0;
    });
    if (so === undefined) {
      so = sortOption;
    }
    if (so.id === "bonus") {
      filtered = filtered.sort((a, b) => {
        return b.totalScore - a.totalScore;
      });
    }
    if (so.id === "scarcity") {
      filtered = filtered.sort((a, b) => {
        // compare strings scarcity
        if (a.scarcity < b.scarcity) {
          return -1;
        } else if (a.scarcity > b.scarcity) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    if (so.id === "old") {
      filtered = filtered.sort((a, b) => {
        // compare strings scarcity
        return a.season - b.season;
      });
    }
    if (so.id === "recent") {
      filtered = filtered.sort((a, b) => {
        // compare strings scarcity
        return b.season - a.season;
      });
    }
    setFilteredCollections(filtered);
  };

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <div className={"flex flex-col space-y-8"}>
      <div className={"flex flex-row justify-between"}>
        <div className={"flex flex-row space-x-4"}>
          <div
            onClick={() => setShowFilters(!showFilters)}
            className={
              "flex flex-row font-semibold text-sm space-x-2 py-2 px-4 rounded-lg cursor-pointer hover:bg-brand hover:text-white bg-white"
            }
          >
            <img src={FilterAlt} />
            <p className={"self-center"}>Filters</p>
          </div>
          <div className={"z-10 w-56 self-center"}>
            <CustomSelect
              onChange={(so) => {
                filter(collections, searchValue, so);
                setSortOption(so);
              }}
              icon={<img src={SortIcon} />}
              font={"font-semibold"}
              chevron={"text-black"}
              options={filterOptions}
            />
          </div>
        </div>

        <div className="relative self-center rounded-md">
          <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
            <FormSearch className="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            autoFocus
            type="text"
            onChange={(v) => {
              filter(collections, v.target.value);
              setSearchValue(v.target.value);
            }}
            className={"block pl-10 w-full placeholder:text-[#828282] p-2 text-sm font-medium rounded border-0 ring-0 focus:ring-0"}
            value={searchValue}
            placeholder="Search collection"
          />
        </div>
      </div>
      {showFilters && (
        <div className={"flex flex-row space-x-4"}>
          <div className={"z-1 w-56 self-center"}>
            <SelectSearch
              options={scarcities_objects_not_all_with_custom_series}
              multiple
              value={scarcityFilter}
              placeholder={"Filter by scarcity"}
              onChange={(v) => {
                filter(collections, searchValue, sortOption, v);
                setScarcityFilter(v);
              }}
              closeOnSelect={false}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
            />
          </div>
          <div className={"z-1 w-56 self-center"}>
            <SelectSearch
              options={seasons_objects_int}
              multiple
              value={seasonFilter}
              placeholder={"Filter by season"}
              onChange={(v) => {
                filter(collections, searchValue, sortOption, scarcityFilter, v);
                setSeasonFilter(v);
              }}
              closeOnSelect={false}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
            />
          </div>
          <div className={"z-1 w-56 self-center"}>
            <SelectSearch
              options={leagues_with_divisions_objects_not_all_star}
              multiple
              value={leagueFilter}
              placeholder={"Filter by league"}
              onChange={(v) => {
                filter(collections, searchValue, sortOption, scarcityFilter, seasonFilter, v);
                setLeagueFilter(v);
              }}
              closeOnSelect={false}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
            />
          </div>
        </div>
      )}

      <div className={"grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-8 gap-4"}>
        {filteredCollections !== null &&
          filteredCollections?.map((c, index) => {
            return <ManagerCollectionBox c={c} index={index} manager={props.manager.Slug} />;
          })}
      </div>
    </div>
  );
}

export default withUser(ManagerCollections);
