import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { GLOBAL_MARGIN_NO_BG_NO_PAD } from "../util/margin";
import WatchlistBanner from "../../img/watchlist-banner.png";
import { errorCatcher } from "../util/errors";
import { WatchlistPrivacyLabel } from "./privacyLabel";
import format from "date-fns/format";
import PiePlaceholder from "../../img/pie-placeholder.svg";
import LinkIconBrand from "../../img/icon-link-brand.svg";
import MenuDotIconBrand from "../../img/icon-menu-dot-brand.svg";
import NoteIconBrand from "../../img/icon-note-brand.svg";
import ArrowLeftIcon from "../../img/arrow-left-icon.svg";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Clipboard from "react-clipboard.js";
import EditWatchlistModal from "./editWatchlistModal";
import { StandaloneMenu } from "../util/standaloneMenu";
import { Link, withRouter } from "react-router-dom";
import WatchlistNoteSideBar from "./watchlistNoteSideBar";
import WatchlistPlayersOverview from "./watchlistPlayersOverview";
import WatchlistPlayersPriceEvolution from "./watchlistPlayersPriceEvolution";
import WatchlistPlayersRosterStats from "./watchlistRosterStats";
import WatchlistFollowingButton from "./watchlistFollowingButton";
import { formatDistance } from "date-fns";
import { sorareBaseball, sorareBasketball, sorareFootball, sportsLabels } from "../util/sports";
import DeleteWatchlistModal from "./deleteWatchlistModal";
import WatchlistManagersGWPerformance from "./watchlistManagersGWPerformance";
import WatchlistManagersGalleryStats from "./watchlistManagersGalleryStats";
import WatchlistAuctions from "./watchlistAuctions";
import WatchlistOffers from "./watchlistOffers";
import { ReactTooltip } from "../util/tooltip.js";
import WatchlistManagersOnSale from "./watchlistManagersOnSale";
import WatchlistManagersLastArrivals from "./watchlistManagersLastArrivals";
import CardIcon from "../util/cardIcon";
import { units_objects } from "../util/units";
import { ManagerVerifiedCheckMark } from "../util/manager";

const pieChartOptions = {
  legend: {
    display: false,
  },
  plugins: {
    ChartDataLabels,
    datalabels: {
      color: "white",
      font: {
        size: 8,
        family: "Inter",
        weight: "bold",
      },
      formatter: function (value, context) {
        if (value === 0) {
          return "";
        }
        return context.chart.data.labels[context.dataIndex];
      },
    },
  },
};

function Watchlist(props) {
  const id = props.match.params.id;
  const [watchlist, setWatchlist] = useState({ nbItems: 0 });
  const [characteristics, setCharacteristics] = useState({
    positions: [],
    competitions: [],
    ages: [],
  });
  const [loading, setLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(watchlist.isPinned);
  const [isDefault, setIsDefault] = useState(watchlist.isDefault);
  const [isFollowed, setIsFollowed] = useState(false);
  const [nbFollowers, setNbFollowers] = useState(0);
  const [showClipboardMessage, setShowClipboardMessage] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const connectedUserIsOwner = watchlist.owner === props.user.sorareSlug;
  const activeTab = props.match.params.tab || (watchlist.type === "player" ? "overview" : "gallery-stats");

  const [showNote, setShowNote] = useState(false);
  useEffect(() => {
    getWatchlist();
  }, [props.user.preferredUnit]);

  useEffect(() => {
    if (showClipboardMessage) {
      const timer = setTimeout(() => {
        setShowClipboardMessage(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showClipboardMessage]);

  const getWatchlist = () => {
    setLoading(true);
    props
      .fetch(`/apiv2/watchlists/id/${id}?currency=${props.user.preferredUnit}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          props.history.push("/");
        } else {
          const w = res.watchlist || { nbItems: 0, enabled: false };
          if (!w.enabled) {
            props.history.push("/");
          } else {
            setWatchlist(w);
            document.title = "Watchlist - " + w.name;
            setIsFollowed(w.isFollowed || false);
            setNbFollowers(w.nbFollowers || 0);
            setIsDefault(w.isDefault);
            setIsPinned(w.isPinned);
            if (res.watchlist.type === "player") {
              setCharacteristics(
                res.characteristics || {
                  positions: [],
                  competitions: [],
                  ages: [],
                },
              );
            }
            setLoading(false);
          }
        }
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const onPinWatchlist = () => {
    const action = isPinned ? "unpin" : "pin";
    props
      .fetch(`/apiv2/watchlists/id/${watchlist.id}/${action}`, {
        method: "POST",
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setIsPinned(!isPinned);
          setShowMenu(false);
        }
      })
      .catch(errorCatcher());
  };

  const onIsDefaultWatchlist = () => {
    const action = isDefault ? "unselectAsDefault" : "selectAsDefault";
    props
      .fetch(`/apiv2/watchlists/id/${watchlist.id}/${action}`, {
        method: "POST",
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === undefined) {
          setIsDefault(!isDefault);
          setShowMenu(false);
        }
      })
      .catch(errorCatcher());
  };

  const onSaveEditWatchlist = () => {
    getWatchlist();
    setOpenEditModal(false);
  };

  const onConfirmDeleteWatchlist = () => {
    setOpenDeleteModal(false);
    props.history.push("/watchlists");
  };

  const onCancelDeleteWatchlist = () => {
    setOpenDeleteModal(false);
  };

  const inactiveClass =
    "text-base border-b-4 border-brand-black py-2 px-0 block font-medium text-textGrey4 hover:text-white focus:outline-none";
  const activeClass = "text-base border-b-4 border-brand py-2 px-0 font-medium focus:outline-none";
  const getPieData = (data) => {
    const pieData = data.map((obj) => obj.amount);
    const pieLabels = data.map((obj) => obj.shortName);
    const baseColors = ["#541C0A", "#9E2F0E", "#F55205", "#FF8D36", "#FFD5A7", /* grey for unknown */ "#E5E5E5"];
    return {
      labels: pieLabels,
      datasets: [
        {
          data: pieData,
          backgroundColor: baseColors.slice(-pieData.length),
          borderWidth: 0,
        },
      ],
    };
  };

  const linkIcon = (
    <div
      className={
        "bg-focus flex rounded-md border border-grey-e9 w-8 h-8 " + (watchlist.privacy === "private" ? "opacity-50" : "cursor-pointer")
      }
      title={
        watchlist.privacy === "private"
          ? "Not possible to share watchlist because it's private"
          : "Click to copy the short link to the watchlist"
      }
    >
      <img className={"m-auto text-center self-center w-4 h-4"} src={LinkIconBrand} />
    </div>
  );

  let menuItems = [
    {
      key: "pin",
      label: isPinned ? "Unpin" : "Pin",
      className: "cursor-pointer w-36",
      onClick: onPinWatchlist,
    },
    {
      key: "default",
      label: isDefault ? "Remove from default" : "Set as default",
      className: "cursor-pointer w-36",
      onClick: onIsDefaultWatchlist,
    },
  ];
  if (connectedUserIsOwner) {
    menuItems = [
      {
        key: "edit",
        label: "Edit",
        className: "cursor-pointer w-36",
        onClick: () => setOpenEditModal(true),
      },
      ...menuItems,
      {
        key: "delete",
        label: "Delete",
        className: "cursor-pointer text-live-red w-36",
        onClick: () => setOpenDeleteModal(true),
      },
    ];
  }

  const symbol = units_objects.find(({ name, value }) => value === props.user.preferredUnit)?.name;

  return (
    <div>
      <div
        className={"bg-white flex flex-col "}
        style={{
          backgroundImage: "url('" + WatchlistBanner + "')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "180px",
        }}
      >
        <div className={"flex flex-row py-6 " + GLOBAL_MARGIN_NO_BG_NO_PAD} style={{ height: "180px" }}>
          <div className={"w-full flex flex-row self-center justify-start"}>
            <div className={"w-full grid grid-cols-1 md:grid-cols-2"}>
              {!loading && watchlist.id && (
                <div className={"justify-self-start my-auto"}>
                  <div className={"relative text-white text-3xl font-semibold flex flex-row items-center justify-start"}>
                    <span className={"mr-2"}>{watchlist.name}</span>
                    <WatchlistPrivacyLabel privacy={watchlist.privacy} />
                    <Link to={`/watchlists`}>
                      <div className={"absolute -top-6 sm:-left-6 sm:top-3 cursor-pointer"}>
                        <img src={ArrowLeftIcon} />
                      </div>
                    </Link>
                  </div>
                  <div className={"text-white text-sm mt-2"}>
                    <span className="flex gap-1">
                      <span className={"align-middle"}>
                        Created by{" "}
                        <a className={"font-semibold"} href={"/manager/" + watchlist.owner}>
                          {watchlist.ownerDisplayName || watchlist.owner}
                        </a>
                      </span>
                      <ManagerVerifiedCheckMark managerSlug={watchlist.owner} />
                      <span className={"align-middle"}>{"-"}</span>
                      <span className={"align-middle"}>{format(new Date(watchlist.created), "dd/MM/yyyy")}</span>
                      <span className={"align-middle"}>{"•"}</span>
                      <span className={"align-middle"}>Last update</span>
                      <span className={"align-middle"}>{"-"}</span>
                      <span className={"align-middle"}>
                        {formatDistance(new Date(watchlist.updated), new Date(), {
                          addSuffix: true,
                        })}
                      </span>
                    </span>
                  </div>
                  <div className={"text-white text-sm flex self-start"}>
                    <span className={"my-auto mr-1"}>{`${watchlist.nbItems} ${watchlist.type}s`}</span>
                    <span className={"my-auto mr-1"}>{"-"}</span>
                    <span className={"my-auto mr-1 flex flew-row gap-2"}>
                      {watchlist.sport === sorareBaseball && <span className={"my-auto text-sm font-semibold"}>⚾️️</span>}
                      {watchlist.sport === sorareFootball && <span className={"my-auto text-sm font-semibold"}>⚽</span>}
                      {watchlist.sport === sorareBasketball && <span className={"my-auto text-sm font-semibold"}>🏀</span>}
                      <span>{sportsLabels.find((s) => s.value === watchlist.sport)?.name}</span>
                    </span>
                    <span className={"my-auto mr-1"}>{"•"}</span>
                    <div className={"flex self-start -ml-2"}>
                      <WatchlistFollowingButton
                        labelSize={"md"}
                        labelColor={"grey-f2"}
                        id={watchlist.id}
                        enabled={watchlist.enabled}
                        connectedUserIsOwner={connectedUserIsOwner}
                        nbFollowers={nbFollowers}
                        isFollowed={isFollowed}
                        withLabel
                      />
                    </div>
                  </div>
                </div>
              )}
              {!loading && (
                <div className={"justify-self-end flex flex-col md:flex-row gap-4"}>
                  {characteristics && watchlist.type === "player" && (
                    <div className={"hidden bg-focus mx-auto rounded-xl md:grid gap-6 grid-cols-3 p-3 border border-grey-e9"}>
                      {watchlist.sport === "multi" ? (
                        <div>
                          <div className={"text-xs text-center text-textGrey3 font-semibold mb-1"}>SPORT</div>
                          {watchlist.nbItems > 0 && (
                            <Pie height={100} width={100} data={getPieData(characteristics.sports)} options={pieChartOptions} />
                          )}
                          {watchlist.nbItems === 0 && <img src={PiePlaceholder} />}
                        </div>
                      ) : (
                        <div>
                          <div className={"text-xs text-center text-textGrey3 font-semibold mb-1"}>POSITION</div>
                          {watchlist.nbItems > 0 && (
                            <Pie height={100} width={100} data={getPieData(characteristics.positions)} options={pieChartOptions} />
                          )}
                          {watchlist.nbItems === 0 && <img src={PiePlaceholder} />}
                        </div>
                      )}
                      {watchlist.sport === "multi" ? (
                        <div>
                          <div className={"text-xs text-center text-textGrey3 font-semibold mb-1 flex justify-center gap-1 h-4"}>
                            <span>
                              <CardIcon inline scarcity={"limited"} />
                            </span>
                            AVG PRICES ({symbol})
                          </div>
                          {watchlist.nbItems > 0 && (
                            <Pie height={100} width={100} data={getPieData(characteristics.prices)} options={pieChartOptions} />
                          )}
                          {watchlist.nbItems === 0 && <img src={PiePlaceholder} />}
                        </div>
                      ) : (
                        <div>
                          <div className={"text-xs text-center text-textGrey3 font-semibold mb-1"}>COMPETITION</div>
                          {watchlist.nbItems > 0 && (
                            <Pie height={100} width={100} data={getPieData(characteristics.competitions)} options={pieChartOptions} />
                          )}
                          {watchlist.nbItems === 0 && <img src={PiePlaceholder} />}
                        </div>
                      )}
                      <div>
                        <div className={"text-xs text-center text-textGrey3 font-semibold mb-1"}>AGE</div>
                        {watchlist.nbItems > 0 && (
                          <Pie height={100} width={100} data={getPieData(characteristics.ages)} options={pieChartOptions} />
                        )}
                        {watchlist.nbItems === 0 && <img src={PiePlaceholder} />}
                      </div>
                    </div>
                  )}
                  <div className={"flex flex-row md:flex-col place-content-between gap-2"}>
                    {watchlist.privacy === "private" ? (
                      <>{linkIcon}</>
                    ) : (
                      <Clipboard
                        className={"relative"}
                        onSuccess={() => setShowClipboardMessage(true)}
                        data-clipboard-text={window.location.origin + "/w/" + watchlist.shortid}
                      >
                        {linkIcon}
                        {showClipboardMessage && (
                          <div className={"p-2 rounded-md bg-light-brand-black absolute text-white top-0 left-10 text-xs w-30"}>
                            Copied!
                          </div>
                        )}
                      </Clipboard>
                    )}
                    <div
                      className={"bg-focus flex rounded-md border border-grey-e9 w-8 h-8 cursor-pointer"}
                      onClick={() => setShowNote(true)}
                    >
                      <img className={"m-auto text-center self-center w-4 h-4"} src={NoteIconBrand} />
                    </div>
                    <div
                      className={"relative bg-focus flex rounded-md border border-grey-e9 w-8 h-8 cursor-pointer"}
                      onClick={() => setShowMenu(true)}
                    >
                      <img className={"m-auto text-center self-center w-4 h-4"} src={MenuDotIconBrand} />
                      <StandaloneMenu
                        className={"top-0 -left-36 2xl:left-10 "}
                        show={showMenu}
                        onClickOutside={() => setShowMenu(false)}
                        items={menuItems}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-brand-black">
        <div className={"xl:ml-16 mx-auto mb-6 text-focus " + GLOBAL_MARGIN_NO_BG_NO_PAD}>
          {watchlist.type === "player" && (
            <>
              <nav className="lg:flex lg:flex-row lg:block grid grid-cols-2 grid-rows-2 gap-x-10 pl-2">
                <Link to={`/watchlists/id/${id}/overview`}>
                  <button className={activeTab === "overview" ? activeClass : inactiveClass}>Overview</button>
                </Link>
                <Link to={`/watchlists/id/${id}/prices`}>
                  <button className={activeTab === "prices" ? activeClass : inactiveClass}>Prices</button>
                </Link>
                {watchlist.sport !== "multi" && (
                  <Link to={`/watchlists/id/${id}/stats`}>
                    <button className={activeTab === "stats" ? activeClass : inactiveClass}>Stats</button>
                  </Link>
                )}
                <Link to={`/watchlists/id/${id}/auctions`}>
                  <button className={activeTab === "auctions" ? activeClass : inactiveClass}>Auctions</button>
                </Link>
                <Link to={`/watchlists/id/${id}/offers`}>
                  <button className={activeTab === "offers" ? activeClass : inactiveClass}>Offers</button>
                </Link>
              </nav>
            </>
          )}
          {watchlist.type === "manager" && (
            <>
              <nav className="lg:flex lg:flex-row lg:block grid grid-cols-2 grid-rows-1 gap-x-10 pl-2">
                <Link to={`/watchlists/id/${id}/gallery-stats`}>
                  <button className={activeTab === "gallery-stats" ? activeClass : inactiveClass}>Gallery stats</button>
                </Link>
                <Link to={`/watchlists/id/${id}/gw-performance`}>
                  <button className={activeTab === "gw-performance" ? activeClass : inactiveClass}>GW Performance</button>
                </Link>
                <Link to={`/watchlists/id/${id}/on-sale`}>
                  <button className={activeTab === "on-sale" ? activeClass : inactiveClass}>On Sale</button>
                </Link>
                <Link to={`/watchlists/id/${id}/last-arrivals`}>
                  <button className={activeTab === "last-arrivals" ? activeClass : inactiveClass}>Last Arrivals</button>
                </Link>
              </nav>
            </>
          )}
        </div>
      </div>
      <div>
        <div className="lg:mx-20 mx-4 md:mx-12 xl:w-11/12 2xl:w-11/12 3xl:w-10/12 xl:mx-auto 4xl:w-8/12 pb-8">
          {activeTab === "overview" && <WatchlistPlayersOverview watchlist={watchlist} onAddPlayers={() => getWatchlist()} />}
          {activeTab === "prices" && <WatchlistPlayersPriceEvolution watchlist={watchlist} />}
          {activeTab === "stats" && <WatchlistPlayersRosterStats watchlist={watchlist} />}
          {activeTab === "auctions" && <WatchlistAuctions watchlist={watchlist} />}
          {activeTab === "offers" && <WatchlistOffers watchlist={watchlist} />}
          {activeTab === "gallery-stats" && <WatchlistManagersGalleryStats watchlist={watchlist} onAddManagers={() => getWatchlist()} />}
          {activeTab === "gw-performance" && (
            <WatchlistManagersGWPerformance
              withGameweekPicker
              withManagerSearch
              withDivisionPicker
              withManagerAdder
              watchlist={watchlist}
              onAddManagers={() => getWatchlist()}
            />
          )}
          {activeTab === "on-sale" && <WatchlistManagersOnSale watchlist={watchlist} onAddManagers={() => getWatchlist()} />}
          {activeTab === "last-arrivals" && <WatchlistManagersLastArrivals watchlist={watchlist} onAddManagers={() => getWatchlist()} />}
        </div>
      </div>
      <EditWatchlistModal
        watchlist={watchlist}
        open={openEditModal}
        onSave={onSaveEditWatchlist}
        onCancel={() => setOpenEditModal(false)}
      />
      <DeleteWatchlistModal
        watchlist={watchlist}
        open={openDeleteModal}
        onDelete={onConfirmDeleteWatchlist}
        onCancel={onCancelDeleteWatchlist}
      />
      <WatchlistNoteSideBar id={watchlist.id} open={showNote} onClose={() => setShowNote(false)} readOnly={!connectedUserIsOwner} />
      <ReactTooltip />
    </div>
  );
}

export default withRouter(withUser(Watchlist));
