import React from "react";
import { withUser } from "../../userContext";
import GreenButton from "../util/greenButton";
import { useHistory } from "react-router-dom";
import { errorCatcher } from "../util/errors";

function ProductBoxes(props) {
  const history = useHistory();

  const handleSubmit = (price) => {
    props
      .fetch("/apiv2/stripe/checkoutSession", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: price,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        console.log("Res:", res);
        let url = "/subscription/cancel";
        if (res.redirectUrl != "") {
          url = res.redirectUrl;
          window.location.href = url;
          return;
        }
        history.push(url);
      })
      .catch(errorCatcher());
  };

  const customerPortal = () => {
    props
      .fetch("/apiv2/stripe/customerPortal", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.redirectUrl != "" && res.redirectUrl != undefined) {
          let url = res.redirectUrl;
          window.location.href = url;
          return null;
        }
      })
      .catch(errorCatcher());
  };

  const updateSubscription = (priceID, when) => {
    props
      .fetch("/apiv2/stripe/subscriptions/update", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price_id: priceID,
          when: when,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
      })
      .catch(errorCatcher());
  };

  const cancelSubscription = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/cancel", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
      })
      .catch(errorCatcher());
  };

  const lastInvoice = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/lastInvoice", {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.redirectUrl !== "" && res.redirectUrl !== undefined) {
          let url = res.redirectUrl;
          window.location.href = url;
          return null;
        }
      })
      .catch(errorCatcher());
  };

  if (props.user.sorareSlug === null || props.user.sorareSlug === undefined) {
    //User not connected or Sorare Account not linked
    return (
      <div className={"flex flex-row justify-center"}>
        Please be sure to be connected and to have a Sorare account linked to be able to suscribe for premium features
      </div>
    );
  }

  return (
    <div className={"flex flex-row justify-center"}>
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="T1"
        onClick={() => {
          handleSubmit("price_1KdaZmD9d6281zIoI7TzrsVs");
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="T1 Yearly"
        onClick={() => {
          handleSubmit("price_1KoczFD9d6281zIoyCvuuac8");
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="T2"
        onClick={() => {
          handleSubmit("price_1KdacgD9d6281zIobNfKHzaJ");
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="T2 Yearly"
        onClick={() => {
          handleSubmit("price_1KplHmD9d6281zIo8Q5fgvBs");
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="Customer Portal"
        onClick={() => {
          customerPortal();
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="Upgrade T2"
        onClick={() => {
          updateSubscription("price_1KdacgD9d6281zIobNfKHzaJ", "now");
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="Downgrade T1"
        onClick={() => {
          updateSubscription("price_1KdaZmD9d6281zIoI7TzrsVs", "end");
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="Cancel"
        onClick={() => {
          cancelSubscription();
        }}
      />
      <GreenButton
        margin={"auto"}
        type="submit"
        primary
        label="Last invoice"
        onClick={() => {
          lastInvoice();
        }}
      />
    </div>
  );
}

export default withUser(ProductBoxes);
