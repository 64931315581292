import React from "react";
import PentagonImg from "../../img/pentagon.svg";

function Pentagon(props) {
  let value = props.value;
  return (
    <div className={"self-center z-50 relative self-center"}>
      <div className={"w-full h-full"}>
        <img src={PentagonImg} className={"object-contain w-full h-full"} />
      </div>
      <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 justify-center self-center"}>
        <p className={"text-2xl font-bold"}>{value}</p>
      </div>
    </div>
  );
}

export default Pentagon;
