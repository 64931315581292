import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { units } from "./units";

function DropdownCurrencyPicker(props) {
  const disabledCurrencies = useMemo(() => props.disabledCurrencies || {}, [props.disabledCurrencies]);
  const initialCurrency = props.currency || "";
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const unitsObj = props.units || units;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "9999px",
      borderWidth: 0,
      boxShadow: "none",
      height: "100%",
    }),
    container: (provided) => ({
      ...provided,
      height: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      fontWeight: "600",
      backgroundColor: state.isFocused
        ? state.isSelected
          ? "#F55205 !important"
          : "#FEEEE6 !important"
        : state.isSelected
        ? "#F55205 !important"
        : "initial !important",
      color: state.isSelected ? "#FFFFFF" : "#1A1A1A",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  useEffect(() => {
    const newSelectedCurrency = {
      value: initialCurrency,
      label: unitsObj[initialCurrency],
      isDisabled: disabledCurrencies[initialCurrency],
    };

    if (JSON.stringify(newSelectedCurrency) !== JSON.stringify(selectedCurrency)) {
      setSelectedCurrency(newSelectedCurrency);
    }
  }, [initialCurrency, unitsObj, disabledCurrencies, selectedCurrency]);

  const options = Object.keys(unitsObj).map((currency) => ({
    value: currency,
    label: unitsObj[currency],
    isDisabled: disabledCurrencies[currency] !== undefined,
  }));

  const handleChange = (selectedOption) => {
    if (selectedOption && !disabledCurrencies[selectedOption.value]) {
      setSelectedCurrency(selectedOption);
      props.onChange(selectedOption.value);
    }
  };

  return (
    <div className="bg-white rounded-full border-none">
      <Select styles={customStyles} value={selectedCurrency} onChange={handleChange} options={options} isSearchable={false} />
    </div>
  );
}

export default DropdownCurrencyPicker;
