import React from "react";
import { getScarcityInfo } from "../util/scarcities";

function NewCardLink(props) {
  const card = props.card;
  const sport = props.sport;
  const { color, supply } = getScarcityInfo(card.Scarcity, sport);
  return (
    <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
      <span className={"font-medium " + color}>
        {card.SerialNumber}/{supply} ({card.Season})
      </span>
    </a>
  );
}

export default NewCardLink;
