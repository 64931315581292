import React, { useState } from "react";
import UnknownClub from "../../img/unknown_club.png";

function LeagueStandings(props) {
  const [standings, setStandings] = useState(props.standings);

  let defaultStyle = "font-semibold text-xs md:text-sm self-center";
  let defaultDivStyle = "self-center flex flex-row justify-center text-center";
  return (
    <div className={"flex flex-col rounded-xl bg-white"}>
      <div className={"grid grid-cols-12 py-1 border-b border-grey-e5 font-semibold text-xs md:text-sm text-textGrey3"}>
        <div />
        <div className={"col-span-3"}>
          <p>Clubs</p>
        </div>
        <div className={defaultDivStyle}>
          <p>P</p>
        </div>
        <div className={defaultDivStyle}>
          <p>W</p>
        </div>
        <div className={defaultDivStyle}>
          <p>D</p>
        </div>
        <div className={defaultDivStyle}>
          <p>L</p>
        </div>
        <div className={defaultDivStyle}>
          <p>GS</p>
        </div>
        <div className={defaultDivStyle}>
          <p>GC</p>
        </div>
        <div className={defaultDivStyle}>
          <p>GD</p>
        </div>
        <div className={defaultDivStyle}>
          <p>PTS</p>
        </div>
      </div>
      {standings.map((s, i) => {
        let border = "";
        if (i !== standings.length - 1) {
          border = " border-b border-grey-e5";
        }
        let hover = "";
        if (props.hover === s.team.TeamId) {
          hover = " bg-brand-pastel";
        }
        return (
          <div className={"grid grid-cols-12 py-1" + border + hover}>
            <div className={defaultDivStyle}>
              <p className={"font-semibold text-sm text-textGrey3"}>{s.standing.rank}</p>
            </div>
            <div className={"col-span-3 flex flex-row self-center space-x-2"}>
              <div className={"md:w-6 md:h-6 w-4 h-4 rounded-full"}>
                <img className={"object-contain w-full h-full"} src={s.team.PictureUrl !== "" ? s.team.PictureUrl : UnknownClub} />
              </div>
              <p className={"text-xxs md:text-xs hover:font-bold font-semibold self-center"}>
                <a href={"/team/" + s.team.TeamId}>{s.team.ShortName}</a>
              </p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle}>{s.standing.played}</p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle}>{s.standing.win}</p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle}>{s.standing.draw}</p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle}>{s.standing.loss}</p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle}>{s.standing.goals_for}</p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle}>{s.standing.goals_against}</p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle}>{s.standing.goals_for - s.standing.goals_against}</p>
            </div>
            <div className={defaultDivStyle}>
              <p className={defaultStyle + " text-brand"}>{s.standing.points}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default LeagueStandings;
