import React from "react";

function StatMeter(props) {
  let value = props.value.toFixed(0);
  let avg = "";
  if (props.avg !== undefined) {
    if (props.gms !== undefined) {
      avg = " " + ((props.avg * 100) / props.gms).toFixed(0);
      avg += "%";
    } else {
      avg = " " + props.avg.toFixed(0);
    }
  }
  let numValue = value;
  let colorTextStart;
  let colorFromStart;
  let colorToEnd;
  let SDScoreTip = "Average score " + props.tip;
  let SO5AvgTip = "% of games played " + props.tip;
  let pct = value;
  //let width = "w-" + Math.round(pct) + "/12"
  if ((props.timeline === "L40" || props.timeline === "L20" || props.timeline === "L15") && props.value === -1 && props.avg === 0) {
    colorTextStart = "text-brand-black-start";
    colorFromStart = "from-brand-black-start";
    colorToEnd = "to-brand-black-end";
    value = "NA";
    avg = "NA";
    pct = 0;
    SDScoreTip = "Not enough data for this player.";
    SO5AvgTip = "Not enough data for this player.";
  } else {
    if (value < 15) {
      colorTextStart = "text-horrible-score-start";
      colorFromStart = "from-horrible-score-start";
      colorToEnd = "to-horrible-score-end";
    } else if (value < 30) {
      colorTextStart = "text-bad-score-start";
      colorFromStart = "from-bad-score-start";
      colorToEnd = "to-bad-score-end";
    } else if (value < 45) {
      colorTextStart = "text-ok-score-start";
      colorFromStart = "from-ok-score-start";
      colorToEnd = "to-ok-score-end";
    } else if (value < 60) {
      colorTextStart = "text-good-score-start";
      colorFromStart = "from-good-score-start";
      colorToEnd = "to-good-score-end";
    } else if (value < 75) {
      colorTextStart = "text-great-score-start";
      colorFromStart = "from-great-score-start";
      colorToEnd = "to-great-score-end";
    } else if (value <= 100) {
      colorTextStart = "text-amazing-score-start";
      colorFromStart = "from-amazing-score-start";
      colorToEnd = "to-amazing-score-end";
    }
  }
  if (props.player) {
    return (
      <div className={"flex flex-row space-x-2 md:space-x-4"}>
        <p className={"space-x-2 flex flex-row"}>
          <span className={"w-6 md:w-10 text-title-grey font-medium text-sm md:text-base self-center"}>{props.timeline}</span>
          <span data-tip={SDScoreTip} className={"w-4 text-base md:text-md self-center font-semibold " + colorTextStart}>
            {value}
          </span>
        </p>
        <div data-tip={SDScoreTip} className="md:h-4 h-3 relative w-full max-w-xl rounded overflow-hidden self-center flex">
          <div className="w-full h-full bg-bar-grey absolute" />
          <div
            id="bar"
            style={{ width: pct + "%" }}
            className={"h-full bg-gradient bg-gradient-to-l rounded bg-brand " + colorFromStart + " " + colorToEnd + " relative"}
          ></div>
        </div>
        <p className={"flex flex-row"}>
          <span data-tip={SO5AvgTip} className={"md:w-10 w-6 text-sm md:text-md self-center text-right"}>
            {avg}
          </span>
        </p>
      </div>
    );
  } else if (props.searchResult) {
    return (
      <div className={"flex flex-row space-x-2"}>
        <p className={"space-x-2 flex flex-row"}>
          <span className={"w-8 text-title-grey font-medium text-sm self-center"}>{props.timeline}</span>
          <span data-tip={props.tip} className={"w-4 text-sm self-center font-semibold " + colorTextStart}>
            {value}
          </span>
        </p>
        <div data-tip={props.tip} className="h-2 relative w-full max-w-xl rounded overflow-hidden self-center flex">
          <div className="w-full h-full bg-bar-grey absolute" />
          <div
            id="bar"
            style={{ width: pct + "%" }}
            className={"h-full bg-gradient bg-gradient-to-l rounded bg-brand " + colorFromStart + " " + colorToEnd + " relative"}
          ></div>
        </div>
        <p className={"flex flex-row"}>
          <span data-tip={SO5AvgTip} className={"w-8 text-sm self-center font-medium text-right"}>
            {avg}
          </span>
        </p>
      </div>
    );
  } else {
    return (
      <div className={"flex flex-row space-x-0.5 w-20"}>
        <p className={"flex flex-row"}>
          <span data-tip={SDScoreTip} className={"w-4 text-xs self-center font-semibold " + colorTextStart}>
            {value}
          </span>
        </p>
        <div data-tip={SDScoreTip} className="h-1.5 relative w-full rounded overflow-hidden self-center flex">
          <div className="w-full h-full bg-bar-grey absolute" />
          <div
            id="bar"
            style={{ width: pct + "%" }}
            className={"h-full bg-gradient bg-gradient-to-r rounded bg-brand " + colorFromStart + " " + colorToEnd + " relative"}
          ></div>
        </div>
        <p className={"flex flex-row"}>
          <span data-tip={SO5AvgTip} className={"w-2 pl-0.5 text-xxs self-center font-medium text-right"}>
            {avg}
          </span>
        </p>
      </div>
    );
  }
}

export default StatMeter;
