import { getNavigatorLang } from "./locale";
import { units } from "./units";

export function formatPrice(price, unit, lang, precision) {
  let localeLang = lang || getNavigatorLang();
  const supportedLocales = Intl.NumberFormat.supportedLocalesOf(localeLang);
  if (supportedLocales === undefined || supportedLocales.length === 0) {
    localeLang = "en-US";
  }
  if (unit === "eth" || unit === "Ξ") {
    let floatPrice = parseFloat(price);
    let options;
    if (floatPrice <= 0.1 && floatPrice >= -0.1) {
      options = { maximumFractionDigits: 4, minimumFractionDigits: 4 };
    } else if (floatPrice >= 10 || floatPrice <= -10) {
      options = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    } else {
      options = { maximumFractionDigits: 3, minimumFractionDigits: 3 };
    }
    if (price === 0) {
      options = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    }
    if (precision !== undefined) {
      options = {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      };
    }
    options.currency = "EUR";
    options.currencyDisplay = "code";
    options.style = "currency";

    return new Intl.NumberFormat(localeLang, options).format(floatPrice).replace("EUR", "Ξ");
  } else {
    if (unit === "€") unit = "eur";
    else if (unit === "$") unit = "usd";
    else if (unit === "£") unit = "gbp";
    let max = 2;
    if (price >= 100 || price <= -100) max = 0;
    if (precision !== undefined) {
      max = precision;
    }

    try {
      return new Intl.NumberFormat(localeLang, {
        style: "currency",
        currency: unit ? unit.toUpperCase() : "USD",
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } catch (_) {
      // If currency style and options are not supported by the browser, use plain simple number format
      return (
        new Intl.NumberFormat(localeLang, {
          maximumFractionDigits: max,
          minimumFractionDigits: max,
        }).format(price) +
        " " +
        units[unit?.toLowerCase() || "$"]
      );
    }
  }
}

export const formatPriceNoCurrency = (price, unit, lang, precision) => {
  let localeLang = lang || getNavigatorLang();
  const supportedLocales = Intl.NumberFormat.supportedLocalesOf(localeLang);
  if (supportedLocales === undefined || supportedLocales.length === 0) {
    localeLang = "en-US";
  }

  if (unit === "eth") {
    let floatPrice = parseFloat(price);
    let options;
    if (floatPrice <= 0.1 && floatPrice >= -0.1) {
      options = { maximumFractionDigits: 4, minimumFractionDigits: 4 };
    } else if (floatPrice >= 10 || floatPrice <= -10) {
      options = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    } else {
      options = { maximumFractionDigits: 3, minimumFractionDigits: 3 };
    }
    if (price === 0) {
      options = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    }
    if (precision !== undefined) {
      options = {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      };
    }

    return new Intl.NumberFormat(localeLang, options).format(floatPrice);
  } else {
    if (unit === "€") unit = "eur";
    else if (unit === "$") unit = "usd";
    else if (unit === "£") unit = "gbp";
    let max = 2;
    if (price >= 100 || price <= -100) max = 0;
    if (precision !== undefined) {
      max = precision;
    }

    try {
      return new Intl.NumberFormat(localeLang, {
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    } catch (_) {
      // If currency style and options are not supported by the browser, use plain simple number format
      return new Intl.NumberFormat(localeLang, {
        maximumFractionDigits: max,
        minimumFractionDigits: max,
      }).format(price);
    }
  }
};

export const isFiat = (unit) => {
  return unit === "usd" || unit === "eur" || unit === "gbp";
};

export const checkSecondUnitShouldBeDisplayed = (preferredUnit, secondUnit) => {
  if (secondUnit === "" || secondUnit === undefined || secondUnit === null) {
    return false;
  }

  if (preferredUnit === secondUnit) {
    return false;
  }

  return !(isFiat(preferredUnit) && isFiat(secondUnit));
};

export const findPriceForUserUnit = (ethPrice, fiatPrices, unit, withoutEthSymbol, ethPrecision, fiatPrecision) => {
  let ethFormatPrice = formatPrice;
  if (withoutEthSymbol) {
    ethFormatPrice = formatPriceNoCurrency;
  }
  let price = ethFormatPrice(ethPrice, "eth", undefined, ethPrecision);
  if (unit !== "eth" && fiatPrices) {
    price = formatPrice(fiatPrices[unit], unit, undefined, fiatPrecision);
  }
  return price;
};

export const formatPriceSplit = (price, preferredUnit, zeroValue, withoutEthSymbol) => {
  if (price.eth <= 0) {
    return zeroValue;
  }
  return findPriceForUserUnit(price.eth, price, preferredUnit, false);
};

export const formatFloorPrice = (price, preferredUnit) => {
  return formatPriceSplit(price, preferredUnit, "No sale");
};

export const formatValuationPrice = (price, preferredUnit) => {
  return formatPriceSplit(price, preferredUnit, "---");
};
