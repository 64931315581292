import React from "react";
import Auction from "../../img/auction.svg";
import Reward from "../../img/reward.svg";
import Trade from "../../img/trade.svg";
import { checkSecondUnitShouldBeDisplayed, findPriceForUserUnit } from "../util/formatMoney";
import { withUser } from "../../userContext";

function CardMove(props) {
  const m = props.move;
  const receiver = m.manager_receiving;
  let sendingName = m.manager_sending.Nickname;
  let receivingName = receiver.Nickname;
  if (sendingName === "") {
    sendingName = m.manager_sending.Nickname;
  }
  if (receivingName === "") {
    receivingName = receiver.Nickname;
  }

  let icon = Auction;
  let price = "Free";
  let text = "";
  let link = "";
  let secondPrice = "";
  let date = new Date(m.move.date);
  if (m.move.type === "auction") {
    text = "Auction won by " + receivingName;
    price = findPriceForUserUnit(m.price, m.move, props.user.preferredUnit);
    secondPrice = findPriceForUserUnit(m.price, m.move, props.user.secondUnit);
    link = "/auction/" + m.move.deal_id;
  } else if (m.move.type === "bundled_auction") {
    text = "Bundle auction won by " + receivingName;
    price = findPriceForUserUnit(m.price, m.move, props.user.preferredUnit);
    secondPrice = findPriceForUserUnit(m.price, m.move, props.user.secondUnit);
    if (m.move.deal_id !== "") link = "/bundle/" + m.move.deal_id;
  } else if (m.move.type === "reward") {
    text = "Reward won by " + receivingName;
    icon = Reward;
  } else if (m.move.type === "referral") {
    text = "Referral reward won by " + receivingName;
    icon = Reward;
  } else if (m.move.type === "single_sale_offer" || m.move.type === "single_buy_offer") {
    text = sendingName + " <--> " + receivingName;
    price = findPriceForUserUnit(m.price, m.move, props.user.preferredUnit);
    secondPrice = findPriceForUserUnit(m.price, m.move, props.user.secondUnit);
    icon = Trade;
    link = "/offer/" + m.move.deal_id;
  } else if (m.move.type === "direct_offer") {
    text = sendingName + " <--> " + receivingName;
    price = "Trade";
    icon = Trade;
    link = "/offer/" + m.move.deal_id;
  } else if (m.move.type === "transfer") {
    text = sendingName + " <--> " + receivingName;
    price = "Transfer";
    icon = Trade;
  } else if (m.move.type === "on_auction") {
    text = "Currently on auction";
    price = findPriceForUserUnit(m.price, m.move, props.user.preferredUnit);
    secondPrice = findPriceForUserUnit(m.price, m.move, props.user.secondUnit);
    icon = Auction;
    link = "/auction/" + m.move.deal_id;
  } else if (m.move.type === "on_bundle_auction") {
    text = "Currently on auction in a bundle";
    price = findPriceForUserUnit(m.price, m.move, props.user.preferredUnit);
    secondPrice = findPriceForUserUnit(m.price, m.move, props.user.secondUnit);
    icon = Auction;
    link = "/bundle/" + m.move.deal_id;
  } else if (m.move.type === "pack") {
    text = "Bought in a pack";
    price = findPriceForUserUnit(m.price, m.move, props.user.preferredUnit);
    secondPrice = findPriceForUserUnit(m.price, m.move, props.user.secondUnit);
    icon = Auction;
  } else if (m.move.type === "instant_buy") {
    text = "Instant buy";
    price = findPriceForUserUnit(m.price, m.move, props.user.preferredUnit);
    secondPrice = findPriceForUserUnit(m.price, m.move, props.user.secondUnit);
    icon = Auction;
  }
  let rounded = "";
  let border = " ";
  if (props.first) {
    rounded += "rounded-t-lg";
  } else {
    border = " border-t border-grey-e5";
  }
  if (props.last) {
    rounded += " rounded-b-lg";
  }
  const shouldDisplaySecondUnit = checkSecondUnitShouldBeDisplayed(props.user.preferredUnit, props.user.secondUnit);
  return (
    <div className={"bg-focus justify-between flex flex-row py-4 px-6 space-x-4 " + rounded + border}>
      <div className={"space-x-6 flex flex-row w-9/12"}>
        <img alt="" src={icon} className={"w-10 h-10 self-center"} />
        <p className={"font-semibold text-base self-center"}>
          <a href={link !== "" && link}>{text}</a>
        </p>
      </div>
      <div className={"self-center flex flex-col space-y-1"}>
        <div>
          <p className={"font-semibold text-base text-center"}>{price}</p>
          {secondPrice !== "" && shouldDisplaySecondUnit && (
            <p className={"font-semibold text-xs text-textGrey2 text-center"}>{secondPrice}</p>
          )}
        </div>

        <p className={"text-xs text-textGrey3 font-semibold"}>{date.toLocaleDateString()}</p>
      </div>
    </div>
  );
}

export default withUser(CardMove);
