import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import GreenButton from "../util/greenButton";
import { positions_objects } from "../util/positions";
import { ReactTooltip } from "../util/tooltip.js";
import ToggleWithLabel from "../util/toggleWithLabel";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { sorareBaseball, sorareFootball } from "../util/sports";
import { convertToBaseballAverage } from "../util/baseballAverage";
import BaseballTypePicker from "../util/baseballTypePicker";

const generalStatsInit = [
  {
    statName: "Mins",
    statField: "MinsPlayed",
    enabled: "true",
    tip: "Minutes played",
  },
  { statName: "Goals", statField: "Goals", enabled: "true" },
  { statName: "Assists", statField: "AdjustedGoalAssist", enabled: "true" },
  { statName: "Yellow cards", statField: "YellowCard", enabled: "true" },
  { statName: "Red cards", statField: "RedCard", enabled: "true" },
  { statName: "Fouls", statField: "Fouls", enabled: "true" },
  { statName: "Fouled", statField: "WasFouled", enabled: "true" },
  { statName: "Clean sheets", statField: "CleanSheet", enabled: "true" },
  { statName: "Double doubles", statField: "DoubleDouble", enabled: "true" },
  { statName: "Triple doubles", statField: "TripleDouble", enabled: "true" },
  { statName: "Triple triples", statField: "TripleTriple", enabled: "true" },
  {
    statName: "Err. to shot",
    statField: "ErrorLeadToShot",
    enabled: "true",
    tip: "Errors led to shot",
  },
];

const defensiveStatsInit = [
  { statName: "Goals conceded", statField: "GoalsConceded", enabled: "true" },
  {
    statName: "Eff. clear.",
    statField: "EffectiveClearance",
    enabled: "true",
    tip: "Effective clearances",
  },
  { statName: "Won tackles", statField: "WonTackle", enabled: "true" },
  { statName: "Blocked crosses", statField: "BlockedCross", enabled: "true" },
  { statName: "Blocks", statField: "BlockedScoringAtt", enabled: "true" },
  {
    statName: "Clear. off line",
    statField: "ClearanceOffLine",
    enabled: "true",
    tip: "Clearances off line",
  },
  { statName: "Last man tackle", statField: "LastManTackle", enabled: "true" },
];

const possessionStatsInit = [
  { statName: "Poss. lost", statField: "PossLostCtrl", enabled: "true" },
  { statName: "Poss. won", statField: "PossWon", enabled: "true" },
  { statName: "Duels won", statField: "DuelWon", enabled: "true" },
  { statName: "Duels lost", statField: "DuelLost", enabled: "true" },
  {
    statName: "Int.",
    statField: "InterceptionWon",
    enabled: "true",
    tip: "Interceptions",
  },
  {
    statName: "Acc. passes",
    statField: "AccuratePass",
    enabled: "true",
    tip: "Accurate passes",
  },
  {
    statName: "Pass. 3rd",
    statField: "SuccessfulFinalThirdPasses",
    enabled: "true",
    tip: "Accurate passes in final 3rd",
  },
  {
    statName: "Acc. long balls",
    statField: "AccurateLongBalls",
    enabled: "true",
    tip: "Accurate long balls",
  },
  {
    statName: "Pass. into opp.",
    statField: "LongPassToOpposite",
    enabled: "true",
    tip: "Long passes into opposition side",
  },
  {
    statName: "Missed passes",
    statField: "MissedPass",
    enabled: "true",
    tip: "Missed passes",
  },
];

const offensiveStatsInit = [
  {
    statName: "Shots on target",
    statField: "AdjustedOnTargetScoringAtt",
    enabled: "true",
  },
  {
    statName: "Cont. won",
    statField: "WonContest",
    enabled: "true",
    tip: "Contests won",
  },
  {
    statName: "Big chances created",
    statField: "BigChanceCreated",
    enabled: "true",
  },
  {
    statName: "Att. assists",
    statField: "AdjustedTotalAttAssist",
    enabled: "true",
    tip: "Attempted assists",
  },
  {
    statName: "Pen. area entries",
    statField: "PenAreaEntries",
    enabled: "true",
    tip: "Penalty area entries",
  },
  {
    statName: "PK missed",
    statField: "PenaltyKickMissed",
    enabled: "true",
    tip: "Penalty kicks missed",
  },
  {
    statName: "Big chances missed",
    statField: "BigChanceMissed",
    enabled: "true",
  },
];

const goalkeepingStatsInit = [
  { statName: "Saves", statField: "Saves", enabled: "true" },
  {
    statName: "Saves ibox",
    statField: "SavedIbox",
    enabled: "true",
    tip: "Saves from shots inside the box",
  },
  {
    statName: "Saves obox",
    statField: "SavedObox",
    enabled: "true",
    tip: "Saves from shots outside the box",
  },
  { statName: "Good high claim", statField: "GoodHighClaim", enabled: "true" },
  { statName: "Punches", statField: "Punches", enabled: "true" },
  { statName: "Dive saves", statField: "DiveSave", enabled: "true" },
  { statName: "Dive catches", statField: "DiveCatch", enabled: "true" },
  {
    statName: "Crosses not claimed",
    statField: "CrossNotClaimed",
    enabled: "true",
  },
  { statName: "Smothers", statField: "GkSmother", enabled: "true" },
  { statName: "PK saves", statField: "PenaltySaves", enabled: "true" },
];

const hittersStatInit = [
  {
    statName: "G",
    statField: "gamesHitting",
    enabled: "true",
    tip: "Games played",
    baseballAverage: false,
  },
  {
    statName: "GS",
    statField: "startHitting",
    enabled: "true",
    tip: "Games started",
    baseballAverage: false,
  },
  {
    statName: "AVG",
    statField: "battingAverage",
    enabled: "true",
    tip: "Batting average",
    baseballAverage: true,
  },
  {
    statName: "wOBA",
    statField: "woba",
    enabled: "true",
    tip: "wOBA",
    baseballAverage: true,
  },
  {
    statName: "OBP",
    statField: "obp",
    enabled: "true",
    tip: "On-base percentage",
    baseballAverage: true,
  },
  {
    statName: "SLG",
    statField: "slugging",
    enabled: "true",
    tip: "Slugging",
    baseballAverage: false,
    precision: 3,
  },
  {
    statName: "PA",
    statField: "plateAppearances",
    enabled: "true",
    tip: "Plate appearances",
    baseballAverage: false,
  },
  {
    statName: "PA/G",
    statField: "apg",
    enabled: "true",
    tip: "Plate appearances/game",
    baseballAverage: false,
    precision: 1,
  },
  {
    statName: "H",
    statField: "hit",
    enabled: "true",
    tip: "Hits",
    baseballAverage: false,
  },
  {
    statName: "1B",
    statField: "single",
    enabled: "true",
    tip: "Singles",
    baseballAverage: false,
  },
  {
    statName: "2B",
    statField: "double",
    enabled: "true",
    tip: "Doubles",
    baseballAverage: false,
  },
  {
    statName: "3B",
    statField: "triple",
    enabled: "true",
    tip: "Triples",
    baseballAverage: false,
  },
  {
    statName: "HR",
    statField: "homeRun",
    enabled: "true",
    tip: "Home runs",
    baseballAverage: false,
  },
  {
    statName: "RBI",
    statField: "rbi",
    enabled: "true",
    tip: "Runs batted in",
    baseballAverage: false,
  },
  {
    statName: "R",
    statField: "run",
    enabled: "true",
    tip: "Runs",
    baseballAverage: false,
  },
  {
    statName: "BB",
    statField: "walk",
    enabled: "true",
    tip: "Walks",
    baseballAverage: false,
  },
  {
    statName: "HBP",
    statField: "hbp",
    enabled: "true",
    tip: "Hit by pitch",
    baseballAverage: false,
  },
  {
    statName: "K",
    statField: "battingStrikeout",
    enabled: "true",
    tip: "Batting strikeouts",
    baseballAverage: false,
  },
  {
    statName: "SB",
    statField: "stolenBase",
    enabled: "true",
    tip: "Stolen bases",
    baseballAverage: false,
  },
  {
    statName: "CS",
    statField: "caughtStealing",
    enabled: "true",
    tip: "Caught stealing",
    baseballAverage: false,
  },
];

const pitchersStatsInit = [
  {
    statName: "G",
    statField: "gamesPitching",
    enabled: "true",
    tip: "Games played",
    baseballAverage: false,
  },
  {
    statName: "GS",
    statField: "startPitching",
    enabled: "true",
    tip: "Games started",
    baseballAverage: false,
  },
  {
    statName: "IP",
    statField: "inningsPitched",
    enabled: "true",
    tip: "Innings pitched",
    baseballAverage: false,
    precision: 1,
  },
  {
    statName: "ERA",
    statField: "era",
    enabled: "true",
    tip: "Earned runs average",
    baseballAverage: false,
    precision: 2,
  },
  {
    statName: "FIP",
    statField: "fip",
    enabled: "true",
    tip: "Fielder independent pitching",
    baseballAverage: false,
    precision: 2,
  },
  {
    statName: "K",
    statField: "strikeout",
    enabled: "true",
    tip: "Strikeouts",
    baseballAverage: false,
  },
  {
    statName: "K9",
    statField: "k9",
    enabled: "true",
    tip: "Strikeouts every 9 innings",
    baseballAverage: false,
    precision: 2,
  },
  {
    statName: "WHIP",
    statField: "whip",
    enabled: "true",
    tip: "Walks+Hits allowed per inning",
    baseballAverage: false,
    precision: 3,
  },
  {
    statName: "H",
    statField: "hitAllowed",
    enabled: "true",
    tip: "Hits allowed",
    baseballAverage: false,
  },
  {
    statName: "1B",
    statField: "singleAllowed",
    enabled: "true",
    tip: "Singles allowed",
    baseballAverage: false,
  },
  {
    statName: "2B",
    statField: "doubleAllowed",
    enabled: "true",
    tip: "Doubles allowed",
    baseballAverage: false,
  },
  {
    statName: "3B",
    statField: "tripleAllowed",
    enabled: "true",
    tip: "Triples allowed",
    baseballAverage: false,
  },
  {
    statName: "HR",
    statField: "homeRunAllowed",
    enabled: "true",
    tip: "Home runs allowed",
    baseballAverage: false,
  },
  {
    statName: "W",
    statField: "win",
    enabled: "true",
    tip: "Wins",
    baseballAverage: false,
  },
  {
    statName: "L",
    statField: "loss",
    enabled: "true",
    tip: "Losses",
    baseballAverage: false,
  },
  {
    statName: "H",
    statField: "hold",
    enabled: "true",
    tip: "Holds",
    baseballAverage: false,
  },
  {
    statName: "S",
    statField: "save",
    enabled: "true",
    tip: "Saves",
    baseballAverage: false,
  },
  {
    statName: "HBP",
    statField: "hbpIssued",
    enabled: "true",
    tip: "Hit by pitch issued",
    baseballAverage: false,
  },
  {
    statName: "BB",
    statField: "walkAllowed",
    enabled: "true",
    tip: "Walks allowed",
    baseballAverage: false,
  },
];

function TeamStatsTable(props) {
  const [nbGames, setNbGames] = useState(15);
  const [generalStats, setGeneralStats] = useState(generalStatsInit);
  const [defensiveStats, setDefensiveStats] = useState(defensiveStatsInit);
  const [possessionStats, setPossessionStats] = useState(possessionStatsInit);
  const [offensiveStats, setOffensiveStats] = useState(offensiveStatsInit);
  const [goalkeepingStats, setGoalkeepingStats] = useState(goalkeepingStatsInit);
  const [hittersStats, setHittersStats] = useState(hittersStatInit);
  const [pitchersStats, setPitchersStats] = useState(pitchersStatsInit);
  const [stats, setStats] = useState([]);
  const [filteredStats, setFilteredStats] = useState([]);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortHow, setSortHow] = useState("MinsPlayed");
  const [comp, setComp] = useState(props.leagueId || props.comps?.[0]?.value);
  const [activeTab, setActiveTab] = useState("general");
  const [position, setPosition] = useState([]);
  const [showSorarePlayersOnly, setShowSorarePlayersOnly] = useState(false);
  const [baseballType, setBaseballType] = useState("hitting");

  useEffect(() => {
    setComp(props.leagueId || props.comps?.[0]?.value);
  }, [props.leagueId, props.comps, props.stats]);

  useEffect(() => {
    getAggregatedStats(nbGames);
  }, [comp]);

  const getAggregatedStats = (nb, refresh) => {
    if (nb === undefined) nb = nbGames;
    if (props.league) {
      props
        .fetch("/apiv2/comp/aggregatedStats/" + props.leagueId + "/" + nb)
        .then((response) => response.json())
        .then((res) => {
          setStats(res);
          setFilteredStats(res);
        })
        .catch(errorCatcher());
    } else if (props.manager) {
      if (refresh) {
        props
          .fetch("/apiv2/manager/aggregatedStats/" + props.manager.Slug + "?games=" + nb)
          .then((response) => response.json())
          .then((res) => {
            setStats(res.aggregated_stats);
            setFilteredStats(res.aggregated_stats);
          })
          .catch(errorCatcher());
      } else {
        setStats(props.stats);
        setFilteredStats(props.stats);
      }
    } else if (props.watchlistId) {
      if (refresh) {
        props
          .fetch(`/apiv2/watchlists/id/${props.watchlistId}/players/aggregatedStats?games=${nb}`)
          .then((response) => response.json())
          .then((res) => {
            setStats(res.aggregated_stats);
            setFilteredStats(res.aggregated_stats);
          })
          .catch(errorCatcher());
      } else {
        setStats(props.stats);
        setFilteredStats(props.stats);
      }
    } else {
      props
        .fetch("/apiv2/teams/aggregatedStats/" + props.teamId + "/" + comp + "/" + nb)
        .then((response) => response.json())
        .then((res) => {
          setStats(res);
          setFilteredStats(res);
        })
        .catch(errorCatcher());
    }
  };

  const changeStat = (stat, array, enabled) => {
    let toChange;
    if (array === "general") {
      toChange = [...generalStats];
    } else if (array === "defense") {
      toChange = [...defensiveStats];
    } else if (array === "possession") {
      toChange = [...possessionStats];
    } else if (array === "attacking") {
      toChange = [...offensiveStats];
    } else if (array === "goalkeeping") {
      toChange = [...goalkeepingStats];
    }
    toChange.map((s, i) => {
      if (stat === s.statField) {
        toChange[i].enabled = enabled;
      }
    });
    if (array === "general") {
      setGeneralStats(toChange);
    } else if (array === "defense") {
      setDefensiveStats(toChange);
    } else if (array === "possession") {
      setPossessionStats(toChange);
    } else if (array === "attacking") {
      setOffensiveStats(toChange);
    } else if (array === "goalkeeping") {
      setGoalkeepingStats(toChange);
    }
  };

  const changeAll = (array, enabled) => {
    let toChange;
    if (array === "general") {
      toChange = [...generalStats];
    } else if (array === "defense") {
      toChange = [...defensiveStats];
    } else if (array === "possession") {
      toChange = [...possessionStats];
    } else if (array === "attacking") {
      toChange = [...offensiveStats];
    } else if (array === "goalkeeping") {
      toChange = [...goalkeepingStats];
    }
    toChange.map((s, i) => {
      toChange[i].enabled = enabled;
    });
    if (array === "general") {
      setGeneralStats(toChange);
    } else if (array === "defense") {
      setDefensiveStats(toChange);
    } else if (array === "possession") {
      setPossessionStats(toChange);
    } else if (array === "attacking") {
      setOffensiveStats(toChange);
    } else if (array === "goalkeeping") {
      setGoalkeepingStats(toChange);
    }
  };

  const sortData = (how) => {
    let newStats = filteredStats;
    let howToSort = sortOrder;
    if (how === sortHow) {
      howToSort = sortOrder * -1;
    } else {
      howToSort = 1;
    }
    setSortOrder(howToSort);
    setSortHow(how);
    newStats.sort(function (a, b) {
      if (a.stats[how] < b.stats[how]) {
        return howToSort;
      } else if (a.stats[how] > b.stats[how]) {
        return -1 * howToSort;
      }
      return 0;
    });
    setFilteredStats(newStats);
  };

  const returnStat = (gs, array) => {
    if (gs.enabled) {
      return (
        <div
          onClick={() => changeStat(gs.statField, array, false)}
          className={"flex cursor-pointer bg-brand-pastel rounded-xl text-sm font-semibold px-1 py-2"}
        >
          <p className={"text-brand text-center justify-center mx-auto self-center"}>{gs.statName}</p>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => changeStat(gs.statField, array, true)}
          className={"flex cursor-pointer bg-grey-f2 rounded-xl text-sm font-semibold px-1 py-2"}
        >
          <p className={"self-center justify-center mx-auto text-brand text-center"}>{gs.statName}</p>
        </div>
      );
    }
  };

  const filterStats = (positions, sorareOnly) => {
    if (positions === undefined) positions = position;
    if (sorareOnly === undefined) sorareOnly = showSorarePlayersOnly;
    if (positions.length === 0 && !sorareOnly) {
      setFilteredStats(stats);
    } else {
      if (positions.indexOf("Goalkeeper") < 0) {
        changeAll("goalkeeping", false);
      } else {
        changeAll("goalkeeping", true);
      }
      let newFiltered = [...stats];
      if (!sorareOnly) {
        newFiltered = newFiltered.filter((p) => {
          return positions.indexOf(p.player.Position) >= 0;
        });
      } else if (positions.length > 0 && sorareOnly) {
        newFiltered = newFiltered.filter((p) => {
          return positions.indexOf(p.player.Position) >= 0 && p.player.Licensed === true;
        });
      } else if (sorareOnly) {
        newFiltered = newFiltered.filter((p) => {
          return p.player.Licensed === true;
        });
      }
      setFilteredStats(newFiltered);
    }
  };

  const sport = props.sport;

  const active = "relative inline-flex items-center px-4 py-2 border bg-brand-pastel text-sm font-medium text-gray-700 focus:outline-none";
  const inactive =
    "relative inline-flex items-center px-4 py-2 border bg-white text-sm font-medium text-gray-700 hover:bg-brand-pastel focus:outline-none";

  const activeTabClass = "rounded py-2 text-center font-semibold text-sm bg-white";
  const inactiveTabClass =
    "rounded py-2 text-center font-semibold text-sm bg-white hover:text-brand-black text-textGrey3 opacity-70 hover:opacity-100 cursor-pointer";

  let allStats = generalStats.concat(defensiveStats).concat(possessionStats).concat(offensiveStats).concat(goalkeepingStats);
  if (sport === sorareBaseball) {
    if (baseballType === "hitting") {
      allStats = hittersStats;
    } else {
      allStats = pitchersStats;
    }
  }

  return (
    <div className={"space-y-4"}>
      {sport === sorareFootball && (
        <div className={"z-20 flex md:flex-row md:justify-between flex-col items-center justify-center space-y-2 md:space-y-0"}>
          <div className={"flex flex-row space-x-4"}>
            {!props.league && (
              <SelectSearch
                options={props.comps}
                value={comp}
                closeOnSelect={true}
                onChange={(v) => {
                  setComp(v);
                }}
              />
            )}
            <SelectSearch
              multiple
              options={positions_objects}
              printOptions={"on-focus"}
              placeholder={"Select one or more positions..."}
              value={position}
              closeOnSelect={false}
              onChange={(v) => {
                setPosition(v);
                filterStats(v);
              }}
            />
            {!props.manager && (
              <div className={"self-end"}>
                <ToggleWithLabel
                  label={"Show players with Sorare cards only"}
                  isActive={showSorarePlayersOnly}
                  setActive={() => {
                    filterStats(position, !showSorarePlayersOnly);
                    setShowSorarePlayersOnly(!showSorarePlayersOnly);
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <span className="relative z-0 inline-flex rounded-md">
              <button
                onClick={() => {
                  setNbGames(5);
                  getAggregatedStats(5, true);
                }}
                type="button"
                className={nbGames === 5 ? "rounded-l-lg " + active : "rounded-l-lg " + inactive}
              >
                Last 5
              </button>
              <button
                onClick={() => {
                  setNbGames(15);
                  getAggregatedStats(15, true);
                }}
                type="button"
                className={nbGames === 15 ? active : inactive}
              >
                Last 15
              </button>
              <button
                onClick={() => {
                  setNbGames(40);
                  getAggregatedStats(40, true);
                }}
                type="button"
                className={nbGames === 40 ? "rounded-r-lg " + active : "rounded-r-lg " + inactive}
              >
                Last 40
              </button>
            </span>
          </div>
        </div>
      )}
      {sport === sorareFootball && (
        <div className={"space-y-4"}>
          <div className={"grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-2"}>
            <div onClick={() => setActiveTab("general")} className={activeTab === "general" ? activeTabClass : inactiveTabClass}>
              <p>General</p>
            </div>
            <div onClick={() => setActiveTab("defense")} className={activeTab === "defense" ? activeTabClass : inactiveTabClass}>
              <p>Defense</p>
            </div>
            <div onClick={() => setActiveTab("possession")} className={activeTab === "possession" ? activeTabClass : inactiveTabClass}>
              <p>Possession</p>
            </div>
            <div onClick={() => setActiveTab("attacking")} className={activeTab === "attacking" ? activeTabClass : inactiveTabClass}>
              <p>Attacking</p>
            </div>
            <div onClick={() => setActiveTab("goalkeeping")} className={activeTab === "goalkeeping" ? activeTabClass : inactiveTabClass}>
              <p>Goalkeeping</p>
            </div>
          </div>
          <div className={"grid grid-cols-4 md:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-x-4 gap-y-2"}>
            {activeTab === "general" &&
              generalStats.map((gs) => {
                return returnStat(gs, "general");
              })}
            {activeTab === "defense" &&
              defensiveStats.map((gs) => {
                return returnStat(gs, "defense");
              })}
            {activeTab === "possession" &&
              possessionStats.map((gs) => {
                return returnStat(gs, "possession");
              })}
            {activeTab === "attacking" &&
              offensiveStats.map((gs) => {
                return returnStat(gs, "attacking");
              })}
            {activeTab === "goalkeeping" &&
              goalkeepingStats.map((gs) => {
                return returnStat(gs, "goalkeeping");
              })}
          </div>
          <div className={"flex flex-row justify-center space-x-4"}>
            <GreenButton label={"Enable all " + activeTab + " stats"} onClick={() => changeAll(activeTab, true)} />
            <GreenButton label={"Disable all " + activeTab + " stats"} onClick={() => changeAll(activeTab, false)} />
          </div>
        </div>
      )}
      {sport === sorareBaseball && (
        <div className={"flex flex-row justify-between"}>
          <p className={"text-brand font-semibold font-headers text-xl"}>Detailed stats</p>
          <BaseballTypePicker type={baseballType} change={(t) => setBaseballType(t)} />
        </div>
      )}
      {allStats.length > 0 && (
        <div className={"bg-white rounded-lg overflow-x-scroll"}>
          <table className={"border-collapse table-fixed w-full whitespace-no-wrap bg-white mx-auto"}>
            <thead>
              <tr className="text-center">
                <th className="overflow-hidden absolute z-10 top-0 left-0 border-b border-gray-200 w-48 sticky bg-white border-b px-6 py-2 text-gray-600 w-48 font-bold uppercase text-xs">
                  Player
                </th>
                {sport === sorareBaseball && (
                  <th
                    key={"Position"}
                    onClick={() => sortData("position")}
                    className={
                      " cursor-pointer bg-white border-b border-gray-200 mx-auto py-3 px-1 text-center text-gray-600 w-1/24 font-bold uppercase text-xs"
                    }
                  >
                    Position
                  </th>
                )}
                {sport === sorareBaseball && (
                  <th
                    key={"Age"}
                    onClick={() => sortData("age")}
                    className={
                      " cursor-pointer bg-white border-b border-gray-200 mx-auto py-3 px-1 text-center text-gray-600 w-1/24 font-bold uppercase text-xs"
                    }
                  >
                    Age
                  </th>
                )}
                {allStats.map((s, i) => {
                  if (s.enabled) {
                    if (sport === sorareBaseball) {
                      return (
                        <th
                          key={s.statField}
                          onClick={() => sortData(s.statField)}
                          data-tip={s.tip}
                          className={
                            " cursor-pointer bg-white border-b border-gray-200 mx-auto py-3 px-1 text-center text-gray-600 w-1/24 font-bold uppercase text-xs"
                          }
                        >
                          {s.statName}
                        </th>
                      );
                    } else {
                      return (
                        <th
                          key={s.statField}
                          onClick={() => sortData(s.statField)}
                          data-tip={s.tip}
                          className={
                            " cursor-pointer bg-white border-b border-gray-200 mx-auto py-3 px-1 text-center text-gray-600 w-20 font-bold uppercase text-xs"
                          }
                        >
                          {s.statName}
                        </th>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {filteredStats.map((s) => {
                console.log(sport, baseballType, s.stats.gamesPitching);
                if (sport === sorareBaseball) {
                  if (baseballType === "pitching") {
                    if (s.stats.gamesPitching === 0) {
                      return null;
                    }
                  } else {
                    if (s.stats.gamesHitting === 0) {
                      return null;
                    }
                  }
                }
                return (
                  <tr key={s.player.PlayerId}>
                    <td className="absolute top-auto left-auto border-t border-gray-200 bg-white w-48">
                      <span className="font-semibold px-4 py-3 text-med flex items-center hover:font-bold">
                        <a href={"/player/" + s.player.PlayerId} target={"_blank"} rel="noreferrer">
                          {s.player.MatchName}
                        </a>
                      </span>
                    </td>
                    {sport === sorareBaseball && (
                      <td key={"Position"} className={"border-t border-gray-200"}>
                        <span className="font-semibold px-4 py-3 text-med flex flex-row justify-center items-center hover:font-bold">
                          {s.player.Position}
                        </span>
                      </td>
                    )}
                    {sport === sorareBaseball && (
                      <td key={"Age"} className={"border-t border-gray-200"}>
                        <span className="font-semibold px-4 py-3 text-med flex flex-row justify-center items-center hover:font-bold">
                          {s.player.Age}
                        </span>
                      </td>
                    )}

                    {allStats.map((st, i) => {
                      let bg = "";
                      let pad = "";
                      if (st.statField === sortHow) {
                        bg = " bg-brand-pastel text-brand font-semibold ";
                      }
                      let value = s.stats[st.statField];
                      if (st.baseballAverage) {
                        value = convertToBaseballAverage(value);
                      }
                      if (st.precision) {
                        value = value.toFixed(st.precision);
                      }
                      if (st.enabled) {
                        return (
                          <td key={st.statField} className={"border-t border-gray-200 " + bg + pad}>
                            <span className="text-med px-6 py-3 flex flex-row justify-center">{value}</span>
                          </td>
                        );
                      } else return null;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <ReactTooltip />
    </div>
  );
}

export default withUser(TeamStatsTable);
