import React, { Component } from "react";
import { Box, Heading, Select, Anchor } from "grommet";
import { ReactTooltip } from "../util/tooltip.js";
import ResponsiveGrid from "../util/responsiveGrid";
import SO5SelectionLine from "./SO5SelectionLine";
import SO5SelectionTeam from "./SO5SelectionTeam";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";

class SO5SelectionStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popularPicks: [],
      positionValue: "Overall",
    };
  }

  componentDidMount() {
    this.getPopularPicks();
  }

  getPopularPicks = () => {
    let positionValue = this.state.positionValue;
    if (positionValue === "Overall") positionValue = "all";
    if (this.props.gwNumber !== undefined && this.props.gwNumber > 0) {
      this.props
        .fetch("/apiv2/SO5/popularPicks/" + positionValue + "/" + this.props.gwNumber + "/" + this.props.limit)
        .then((response) => response.json())
        .then(async (res) => {
          this.setState({ popularPicks: res });
        })
        .catch(errorCatcher());
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.gwNumber !== this.props.gwNumber) {
      this.getPopularPicks();
    }
  }
  setPositionValue = async (positionValue) => {
    await this.setState({ positionValue });
    this.getPopularPicks();
  };

  render() {
    const popularPicks = this.state.popularPicks;
    let columns = {
      small: ["auto"],
      medium: ["auto", "auto"],
      large: ["auto", "auto"],
    };

    const rows = {
      small: ["auto"],
      medium: ["auto"],
      large: ["auto"],
    };
    const positionValue = this.state.positionValue;
    return (
      <Box align={"center"} width={"auto"} margin={"auto"}>
        <Heading level={"2"} color={"brand"}>
          This week popular picks
        </Heading>
        {popularPicks.length > 0 && (
          <Box>
            <Box align={"center"} margin={{ horizontal: "auto", bottom: "small" }}>
              <Select
                options={["Overall", "Goalkeeper", "Defender", "Midfielder", "Forward", "Team"]}
                value={positionValue}
                onChange={({ option }) => this.setPositionValue(option)}
              />
            </Box>
            <ResponsiveGrid columns={columns} rows={rows}>
              {popularPicks.map((p, i) => {
                if (positionValue !== "Team" && p.player !== undefined) {
                  return (
                    <Box
                      key={p.player.PlayerId}
                      width={"100%"}
                      pad={{ vertical: "xsmall", horizontal: "medium" }}
                      border={{ color: "#E8E8E8", size: "xsmall" }}
                      background={{ color: "white" }}
                    >
                      <SO5SelectionLine key={p.player.PlayerId + i} player={p} />
                    </Box>
                  );
                } else if (p.team !== undefined) {
                  return (
                    <Box
                      width={"100%"}
                      pad={{ vertical: "xsmall", horizontal: "medium" }}
                      border={{ color: "#E8E8E8", size: "xsmall" }}
                      background={{ color: "white" }}
                    >
                      <SO5SelectionTeam key={p.team.TeamId + i} team={p} />
                    </Box>
                  );
                } else return null;
              })}
              <ReactTooltip />
            </ResponsiveGrid>
          </Box>
        )}
        <Box align={"end"} margin={{ top: "medium" }} alignSelf={"end"}>
          <Anchor href={"/fullSelectionStats"}>Full selection stats {`->`}</Anchor>
        </Box>
      </Box>
    );
  }
}

export default withUser(SO5SelectionStats);
