import React from "react";

function NBAProjDiff(props) {
  let projDiff = props.projDiff;

  if (projDiff === undefined) {
    return null;
  }
  let bgProjDiff = "";

  if (projDiff < -10) {
    bgProjDiff = "bg-horrible-score";
  } else if (projDiff < -5) {
    bgProjDiff = "bg-bad-score";
  } else if (projDiff < 1) {
    bgProjDiff = "bg-ok-score";
  } else if (projDiff < 6) {
    bgProjDiff = "bg-good-score";
  } else if (projDiff < 11) {
    bgProjDiff = "bg-great-score";
  } else {
    bgProjDiff = "bg-amazing-score";
  }

  if (projDiff.toFixed(0) === "0" || projDiff.toFixed(0) === "-0") {
    projDiff = "0";
  } else if (projDiff > 0) {
    projDiff = "+" + projDiff.toFixed(0);
  } else {
    projDiff = projDiff.toFixed(0);
  }

  let padding = props.padding || " px-2 py-0.5 ";
  let width = props.width || " w-8 ";
  let font = props.font || " text-xs ";

  return (
    <div className={"rounded-2xl space-x-1 justify-center flex flex-row" + width + padding + bgProjDiff}>
      <p className={"font-semibold " + font + " text-white"}>{projDiff}</p>
    </div>
  );
}

export default NBAProjDiff;
