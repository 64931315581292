import React from "react";
import Started from "../../img/starter.svg";
import CameIn from "../../img/cameIn.svg";
import Bench from "../../img/benchNotCameIn.svg";
import NotInSquad from "../../img/notInSquad.svg";

export const playedStatsColors = {
  gamesStarted: "#0380F3",
  gamesEnteredFromBench: "#F2994A",
  gamesOnBench: "#E73107",
  gamesOut: "#E73107",
};

export const PlayedStatsItem = (props) => {
  const disabled = props.disabled || false;
  const horizontal = props.horizontal || false;
  return (
    <div className={`flex ${horizontal ? "flex-row" : "flex-col"} gap-1 items-center justify-center`}>
      <div className={`w-5 h-5 ${disabled ? "opacity-50" : ""}`} data-tip={props.tip}>
        <img className={"w-full h-full object-contain"} src={props.img} />
      </div>
      <p className={"text-xs text-on-surface-variant font-semibold text-center"}>{props.value}</p>
    </div>
  );
};

export const PlayedStats = (props) => {
  const stats = props.stats;
  const statsPct = {
    gamesStarted: "-",
    gamesEnteredFromBench: "-",
    gamesOnBench: "-",
    gamesOut: "-",
  };
  let disabled = false;
  if (stats !== undefined) {
    if (stats.actualGames === 0) {
      statsPct.gamesStarted = "0%";
      statsPct.gamesEnteredFromBench = "0%";
      statsPct.gamesOnBench = "0%";
      statsPct.gamesOut = "0%";
      disabled = true;
    } else {
      statsPct.gamesStarted = ((stats.gamesStarted / stats.actualGames) * 100).toFixed(0) + "%";
      statsPct.gamesEnteredFromBench = (((stats.gamesPlayed - stats.gamesStarted) / stats.actualGames) * 100).toFixed(0) + "%";
      statsPct.gamesOnBench = ((stats.gamesOnBench / stats.actualGames) * 100).toFixed(0) + "%";
      statsPct.gamesOut = ((stats.gamesOut / stats.actualGames) * 100).toFixed(0) + "%";
    }
  }
  return (
    <div className={"grid grid-cols-4 justify-evenly justify-evenly gap-1 w-full"}>
      <PlayedStatsItem
        disabled={disabled}
        img={Started}
        value={statsPct.gamesStarted}
        tip={`${stats?.gamesStarted || 0}/${stats?.actualGames || 0} game(s) started`}
      />
      <PlayedStatsItem
        disabled={disabled}
        img={CameIn}
        value={statsPct.gamesEnteredFromBench}
        tip={`${stats ? stats.gamesPlayed - stats.gamesStarted : 0}/${stats?.actualGames || 0} game(s) entered from the bench`}
      />
      <PlayedStatsItem
        disabled={disabled}
        img={Bench}
        value={statsPct.gamesOnBench}
        tip={`${stats?.gamesOnBench || 0}/${stats?.actualGames || 0} game(s) stayed on bench`}
      />
      <PlayedStatsItem
        disabled={disabled}
        img={NotInSquad}
        value={statsPct.gamesOut}
        tip={`${stats?.gamesOut || 0}/${stats?.actualGames || 0} games not in squad`}
      />
    </div>
  );
};

export const PlayedAsStarterPct = (props) => {
  const lX = props.lX;
  const stats = props.stats;
  let gamesStartedPct = "No data";
  let disabled = false;
  if (stats !== undefined) {
    if (stats.actualGames === 0) {
      gamesStartedPct = "0%";
      disabled = true;
    } else {
      const gamesStarted = (stats.gamesStarted / stats.actualGames) * 100;
      gamesStartedPct = gamesStarted.toFixed(0) + "%";
    }
  }
  if (disabled) return <div className={"flex flex-row gap-1"}>No games</div>;
  return (
    <div className={"flex flex-row gap-1 items-center"}>
      {lX && <div className={"text-xs text-on-surface-variant font-semibold"}>{lX.toUpperCase()} Starter</div>}
      <PlayedStatsItem
        horizontal={true}
        disabled={disabled}
        img={Started}
        value={gamesStartedPct}
        tip={`${stats?.gamesStarted || 0}/${stats?.actualGames || 0} game(s) started`}
      />
    </div>
  );
};
