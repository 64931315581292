import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { withRouter } from "react-router-dom";
import SDLoading from "../util/SDLoading";
import NoCardResultPlaceholder from "../../img/no-card-result-placeholder.svg";
import EmptyAvatar from "../../img/empty-avatar.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../loader/spinner";
import AddSearchableItemsModal from "../search/addSearchableItemsModal";
import FavoriteBubble from "../util/favoriteBubble";
import DeletionBubble from "../util/deletionBubble";
import { searchText } from "../util/strings";
import { FormSearch } from "grommet-icons";
import CloseOutlineIcon from "../../img/icons-close-outline.svg";
import { Form } from "grommet";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { scarcities_objects_not_all } from "../util/scarcities";
import { ReactComponent as SortArrowIcon } from "../../img/sort-arrow-icon-asc.svg";
import { ReactTooltip } from "../util/tooltip.js";
import BoxWithToggle from "../util/boxWithToggle";
import { getValuationOrigin } from "../util/valuationOrigin";
import CardLayout from "../cards/cardLayout";
import { formatRealTimeAverage } from "../util/formatRealTimeAverage";
import { formatBestMarketPrice } from "../util/formatBestMarketPrice";
import { formatPrice } from "../util/formatMoney";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import WatchlistMultiplePicker from "./watchlistMultiplePicker";
import AbortController from "abort-controller";

export const getNbElementsToDisplay = (mdQueries) => {
  if (mdQueries["4.5xl"]) {
    return 8; // 9 cols of 1 card
  }
  if (mdQueries["3.5xl"] || mdQueries["3xl"] || mdQueries["2.5xl"] || mdQueries["2.5xl"]) {
    return 7; // 8 cols of 1 card
  } else if (mdQueries["2xl"]) {
    return 6; // 7 cols of 1 card
  } else if (mdQueries["1.5xl"]) {
    return 6; // 6 cols of 1 card
  } else if (mdQueries["xl"]) {
    return 5; // 5 cols of 1 card
  } else if (mdQueries["lg"]) {
    return 4; // 4 cols of 1 card
  } else if (mdQueries["md"]) {
    return 6; // 3 cols of 2 cards
  } else if (mdQueries["sm"]) {
    return 6; // 2 cols of 3 cards
  }
  return 4; // 1 col (smartphones)
};

const WatchlistsArrivalListGroupByManager = (props) => {
  const { watchlist, currency, teamMap, managers, connectedUserIsOwner, removeManagerFromWatchlist, arrivalsGroupBy, limit } = props;
  return (
    <div className={"flex flex-col gap-2 w-full mb-8"}>
      {managers.map((res) => {
        return (
          <div key={res.manager.Slug} className={"bg-focus mx-auto mt-2 rounded-xl w-full overflow-hidden"}>
            <div className={"border-b-2 border-grey-e5"}>
              <div className={"flex flex-row place-content-between p-4"}>
                <div>
                  <span className={"flex flex-row"} title={res.manager.Nickname || res.manager.Slug}>
                    <span className={"my-auto hidden md:inline rounded-full mr-2 w-5 h-5 xl:w-7 xl:h-7"}>
                      <a href={"/manager/" + res.manager.Slug}>
                        <img
                          className={"rounded-full inline w-5 h-5 xl:w-7 xl:h-7 object-cover cursor-pointer"}
                          src={res.manager.PictureUrl || EmptyAvatar}
                        />
                      </a>
                    </span>
                    <span className={"text-xs xl:text-sm font-semibold mr-2 flex flex-col"}>
                      <a className={"truncate"} href={"/manager/" + res.manager.Slug}>
                        {res.manager.Nickname || res.manager.Slug}
                      </a>
                      <p className={"text-textGrey4 truncate text-xs"} style={{ maxWidth: "13em" }} title={res.manager.TeamName}>
                        <a href={"/manager/" + res.manager.Slug}>{res.manager.TeamName}</a>
                      </p>
                    </span>
                  </span>
                </div>
                <div className={"flex flex-row justify-center my-auto"}>
                  <div className={"h-9 w-9"}>
                    <FavoriteBubble type="manager" manager={res.manager.Slug} favorite={res.favorite} />
                  </div>
                  {connectedUserIsOwner && (
                    <div className={"h-9 w-9"}>
                      <DeletionBubble
                        onClick={() => removeManagerFromWatchlist(res.manager)}
                        title={`Click to remove "${res.manager.Nickname}" from "${watchlist.name}" watchlist`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <WatchlistArrivalsList
              limit={limit}
              arrivals={arrivalsGroupBy ? arrivalsGroupBy[res.manager.Slug] : undefined}
              currency={currency}
              teamMap={teamMap}
            />
          </div>
        );
      })}
    </div>
  );
};

const WatchlistArrivalsList = withUser((props) => {
  const { currency, teamMap, limit } = props;
  const [showAll, setShowAll] = useState(false);
  const arrivals = props.arrivals || [];
  const arrivalsToDisplay = arrivals && arrivals.length > 0 ? (showAll ? arrivals.slice(0, limit * 3) : arrivals.slice(0, limit)) : [];
  return (
    <>
      {arrivalsToDisplay.length > 0 ? (
        <div className={"flex flex-col justify-center mx-auto"}>
          <div
            className={
              "mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 1.5xl:grid-cols-6 2xl:grid-cols-6 2.5xl:grid-cols-7 4.5xl:grid-cols-8 gap-2 pb-4"
            }
          >
            <ReactTooltip />
            {arrivalsToDisplay.map((arrival, i) => {
              const rahPrice = formatRealTimeAverage(arrival.average, currency);
              const marketPrice = formatBestMarketPrice(arrival.bmp, currency);
              const lastStatus = arrival.card.LastStatus;
              let displayStatus = lastStatus;
              if (lastStatus === "Sold") {
                if (arrival.cardLastMove?.details?.price > 0) {
                  displayStatus = formatPrice(arrival.cardLastMove?.details?.price, "eth");
                  if (props.user.preferredUnit !== "eth") {
                    displayStatus = formatPrice(arrival.cardLastMove?.details?.[props.user.preferredUnit], props.user.preferredUnit);
                  }
                }
              }
              return (
                <CardLayout
                  key={i}
                  imageLink={"/card/" + arrival.card.TokenId}
                  picture={arrival.card.PictureUrl}
                  monthPrice={rahPrice}
                  marketPrice={marketPrice}
                  valuationOrigin={getValuationOrigin(arrival.average.ValuationOrigin)}
                  currentPrice={displayStatus}
                  date={false}
                  avgPrice={arrival.owner}
                  countdown={false}
                  showMoveInfo={true}
                  moveType={arrival.moveType}
                  isSold={arrival.isSold}
                  card={arrival.card}
                  player={arrival.player}
                  gwGames={teamMap}
                  l5={arrival.avg5}
                  l20={arrival.avg20}
                  gms5={arrival.l5}
                  gms20={arrival.l20}
                  availability={arrival.player_status}
                />
              );
            })}
          </div>
          {arrivals.length > limit && (
            <div className={"h-full mt-4 p-2 hover:bg-grey-f8 hover:opacity-90 cursor-pointer"} onClick={() => setShowAll(!showAll)}>
              <div className={"flex justify-center w-full"}>
                <span className={"ml-2 my-auto"}>
                  <SortArrowIcon className={"fill-brand h-6 w-6 " + (showAll ? "transform rotate-180" : "")} />
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={"text-textGrey3 text-md p-4 w-full text-center"}>No arrival</div>
      )}
    </>
  );
});

const typeOptions = [
  {
    value: "",
    name: "All types",
  },
  {
    value: "purchase",
    name: "Purchases",
  },
  {
    value: "reward",
    name: "Rewards",
  },
];

const WatchlistManagersLastArrivals = (props) => {
  const watchlist = props.watchlist;

  const mdQueries = useTailwindMediaQueries();
  const [limit, setLimit] = useState(getNbElementsToDisplay(mdQueries));

  const [loading, setLoading] = useState(false);

  const [managers, setManagers] = useState(undefined);
  const [filteredManagers, setFilteredManagers] = useState(undefined);
  const [displayedManagers, setDisplayedManagers] = useState(undefined);

  const [arrivals, setArrivals] = useState(undefined);
  const [filteredArrivals, setFilteredArrivals] = useState(undefined);
  const [displayedArrivals, setDisplayedArrivals] = useState(undefined);

  const [teamMap, setTeamMap] = useState(undefined);

  const [arrivalsGroupBy, setArrivalsGroupBy] = useState(undefined);

  const [searchQuery, setSearchQuery] = useState("");
  const [scarcities, setScarcities] = useState([]);
  const [isGroupedByManager, setIsGroupedByManager] = useState(true);
  const [type, setType] = useState("");
  const [includeSold, setIncludeSold] = useState(true);
  const [playerWatchlists, setPlayerWatchlists] = useState({});

  const [savingAddManagers, setSavingAddManagers] = useState(false);
  const [openAddManagersModal, setOpenAddManagersModal] = useState(false);

  const [abortController, setAbortController] = useState(new AbortController());

  const connectedUserIsOwner = watchlist.owner === props.user.sorareSlug;

  useEffect(() => {
    getManagerLastSignings();
  }, [watchlist, playerWatchlists, scarcities, isGroupedByManager, includeSold, type]);

  useEffect(() => {
    const newLimit = getNbElementsToDisplay(mdQueries);
    setLimit(newLimit);
  }, [mdQueries]);

  useEffect(() => {
    if (managers !== undefined) {
      if (isGroupedByManager) {
        const ms = managers
          ? Object.entries(managers)
              .map(([_, val]) => val)
              .sort((a, b) => a.manager.Nickname > b.manager.Nickname)
          : [];
        const filtered = ms.filter((m) => searchText(searchQuery, m.manager.Nickname));
        setFilteredManagers(filtered);
        setDisplayedManagers(filtered.slice(0, 20));
      } else {
        const filteredArrivals =
          searchQuery !== ""
            ? arrivals.filter((arrival) =>
                managers[arrival.managerSlug] ? searchText(searchQuery, managers[arrival.managerSlug].manager.Nickname) : false,
              )
            : arrivals;
        setFilteredArrivals(filteredArrivals);
        setDisplayedArrivals(filteredArrivals.slice(0, 50));
      }
    }
  }, [searchQuery]);

  const getManagerLastSignings = () => {
    if (watchlist.id) {
      setLoading(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      const url = isGroupedByManager
        ? `/apiv2/watchlists/id/${watchlist.id}/managers/lastSignings/groupByManager/search`
        : `/apiv2/watchlists/id/${watchlist.id}/managers/lastSignings/search`;
      props
        .fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            playerWatchlists: Object.keys(playerWatchlists),
            scarcities: scarcities || [],
            type: type,
            includeSold: includeSold,
          }),
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            setTeamMap(res.teamMap);
            const managersBySlug = res.managers || {};
            setManagers(managersBySlug);
            const ms = managersBySlug
              ? Object.entries(managersBySlug)
                  .map(([_, val]) => val)
                  .sort((a, b) => a.manager.Nickname > b.manager.Nickname)
              : [];
            const filteredMs = searchQuery !== "" ? ms.filter((m) => searchText(searchQuery, m.manager.Nickname)) : ms;
            setFilteredManagers(filteredMs);
            if (isGroupedByManager) {
              setArrivalsGroupBy(res.arrivals || {});
              setDisplayedManagers(filteredMs.slice(0, 20));
            } else {
              const arrivals = res.arrivals || [];
              setArrivals(arrivals);
              const filteredArrivals =
                searchQuery !== ""
                  ? arrivals.filter((arrival) =>
                      managersBySlug[arrival.managerSlug]
                        ? searchText(searchQuery, managersBySlug[arrival.managerSlug].manager.Nickname)
                        : false,
                    )
                  : arrivals;
              setFilteredArrivals(filteredArrivals);
              setDisplayedArrivals(filteredArrivals.slice(0, 50));
            }
          }
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const onSaveAddManagers = (playerIds) => {
    setSavingAddManagers(true);
    return props
      .fetch(`/apiv2/watchlists/id/${watchlist.id}/addItems`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(playerIds),
      })
      .then((res) => {
        setSavingAddManagers(false);
        if (res.error === undefined) {
          setOpenAddManagersModal(false);
          props.onAddManagers && props.onAddManagers();
        } else {
          console.log(res.error);
        }
      })
      .catch(
        errorCatcher(() => {
          setSavingAddManagers(false);
        }),
      );
  };

  const onRemoveManager = (managerToRemove) => {
    if (watchlist.id && managerToRemove) {
      setLoading(true);
      props
        .fetch(`/apiv2/watchlists/id/${watchlist.id}/removeItems`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify([managerToRemove.Slug]),
        })
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.error === undefined) {
            getManagerLastSignings();
          }
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  };

  const countManagers = managers ? Object.keys(managers).length : 0;

  return (
    <div className={"h-full"}>
      <div className={"relative mb-2 flex flex-col md:flex-row justify-between"}>
        {loading && <Spinner className={"absolute -right-10"} />}
        <div
          className={`grid grid-cols-1 ${connectedUserIsOwner ? "grid-rows-7" : "grid-rows-6"} sm:grid-cols-2 sm:grid-rows-3 ${
            connectedUserIsOwner ? "xl:grid-cols-7" : "xl:grid-cols-6"
          } xl:grid-rows-1 gap-2 justify-center w-full`}
        >
          <Form className="font-semibold text-sm w-full h-full">
            <div className={"m-auto relative flex h-full "}>
              <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                <FormSearch className="h-5 w-5" aria-hidden="true" />
              </div>
              {searchQuery && (
                <div
                  className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                >
                  <img src={CloseOutlineIcon} className="opacity-80 h-3 w-3" aria-hidden="true" />
                </div>
              )}
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                className={`h-full rounded-lg block w-full pl-10 text-sm border border-grey-e9 focus:border-grey-e9 focus:ring-0 ${
                  searchQuery !== "" ? "font-medium" : ""
                }`}
                placeholder="Search a manager"
              />
            </div>
          </Form>
          <div className={"flex w-full"}>
            <BoxWithToggle
              className={"border border-grey-e9 py-2 md:py-0"}
              label={"Group by manager"}
              isActive={isGroupedByManager}
              setActive={() => setIsGroupedByManager(!isGroupedByManager)}
            />
          </div>
          <div className={"flex w-full"}>
            <BoxWithToggle
              className={"border border-grey-e9 py-2 md:py-0"}
              label={"Include sold cards"}
              isActive={includeSold}
              setActive={() => setIncludeSold(!includeSold)}
            />
          </div>
          <div className={"md:z-60 lg:z-50 xl:z-50 w-full"}>
            <SelectSearch
              closeOnSelect={true}
              options={typeOptions}
              value={type}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select a type"
              onChange={(v) => setType(v)}
            />
          </div>
          <div className={"md:z-60 lg:z-50 xl:z-50 w-full"}>
            <SelectSearch
              multiple
              closeOnSelect={false}
              options={scarcities_objects_not_all}
              value={scarcities}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select one scarcity or more"
              onChange={(v) => setScarcities(v)}
            />
          </div>
          <div className={"w-full"}>
            <WatchlistMultiplePicker
              types={["player"]}
              fetchOnMount={true}
              options={{
                pinListFirst: true,
                defaultListFirst: true,
                followedListFirst: true,
                ownedListFirst: true,
              }}
              size={"100%"}
              placeholder={"Filter by watchlist(s)"}
              selected={playerWatchlists}
              onSelect={(w) => {
                let newWatchlists = { ...playerWatchlists };
                if (playerWatchlists[w.id]) {
                  delete newWatchlists[w.id];
                } else {
                  newWatchlists[w.id] = w;
                }
                setPlayerWatchlists(newWatchlists);
              }}
            />
          </div>
          {connectedUserIsOwner && (
            <button
              className={"w-full bg-brand text-white p-2 text-sm font-medium rounded-md focus:outline-none hover:bg-brand-lightWatchlist"}
              onClick={() => setOpenAddManagersModal(true)}
            >
              {`+ Add ${watchlist.type}s`}
            </button>
          )}
        </div>
      </div>
      <div className={"h-full mb-8"}>
        {managers !== undefined && countManagers === 0 && (
          <div className={"flex flex-col justify-center w-full h-full bg-focus rounded-lg text-center border border-grey-e9"}>
            <div className={"m-auto py-36"}>
              <img className={"m-auto mb-6"} src={NoCardResultPlaceholder} />
              <p className={"text-lg font-headers text-center font-bold mb-6"}>
                {connectedUserIsOwner ? "Your watchlist is empty" : "Watchlist is empty"}
              </p>
              {connectedUserIsOwner && (
                <button
                  onClick={() => setOpenAddManagersModal(true)}
                  type="button"
                  className="inline-flex items-center px-12 py-2.5 border border-transparent text-lg font-semibold rounded-lg
                shadow-sm text-white bg-brand hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-brand-light"
                >
                  {`+ Add ${watchlist.type}s`}
                </button>
              )}
            </div>
          </div>
        )}
        {managers !== undefined && countManagers > 0 && isGroupedByManager && displayedManagers !== undefined && (
          <div>
            <InfiniteScroll
              next={() => setDisplayedManagers(filteredManagers.slice(0, displayedManagers.length + 20))}
              hasMore={displayedManagers.length < filteredManagers.length}
              loader={<SDLoading />}
              dataLength={displayedManagers.length}
            >
              <WatchlistsArrivalListGroupByManager
                watchlist={watchlist}
                managers={displayedManagers}
                arrivalsGroupBy={arrivalsGroupBy}
                currency={props.user.preferredUnit}
                teamMap={teamMap}
                connectedUserIsOwner={connectedUserIsOwner}
                removeManagerFromWatchlist={onRemoveManager}
                limit={limit}
              />
            </InfiniteScroll>
          </div>
        )}
        {managers !== undefined && countManagers > 0 && !isGroupedByManager && displayedArrivals !== undefined && (
          <div>
            <InfiniteScroll
              next={() => setDisplayedArrivals(filteredArrivals.slice(0, displayedArrivals.length + 50))}
              hasMore={displayedArrivals.length < filteredArrivals.length}
              loader={<SDLoading />}
              dataLength={displayedArrivals.length}
            >
              <WatchlistArrivalsList arrivals={displayedArrivals} currency={props.user.preferredUnit} teamMap={teamMap} />
            </InfiniteScroll>
          </div>
        )}
        {managers !== undefined &&
          countManagers > 0 &&
          !isGroupedByManager &&
          displayedArrivals !== undefined &&
          displayedArrivals.length === 0 && (
            <div>
              <WatchlistArrivalsList />
            </div>
          )}
      </div>
      <AddSearchableItemsModal
        type="manager"
        title={"Add to watchlist"}
        open={openAddManagersModal}
        onSave={onSaveAddManagers}
        saving={savingAddManagers}
        onCancel={() => setOpenAddManagersModal(false)}
      />
    </div>
  );
};

export default withRouter(withUser(WatchlistManagersLastArrivals));
