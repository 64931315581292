import React from "react";
import Select, { components } from "react-select";
import clsx from "clsx";

const selectCustomClass = (containerClasses) => ({
  container: (provided) => clsx(provided, "shadow", containerClasses),
  option: (provided) => clsx(provided, "border-b border-transparent-inverse-surface-low border-opacity-10"),
  menuList: (provided) => clsx(provided, "rounded"),
});

const selectCustomStyle = {
  control: (provided) => ({
    ...provided,
    borderWidth: 0,
    boxShadow: "none",
    height: "100%",
    minHeight: 0,
  }),
  container: (provided) => ({
    ...provided,
    height: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    fontWeight: state.isSelected ? "600" : "",
    backgroundColor:
      state.isFocused || state.isSelected ? "#FEEEE6 !important" : state.isSelected ? "#F55205 !important" : "initial !important",
    color: state.isSelected ? "#F55205" : "#1A1A1A",
  }),
  menuList: (provided) => ({ ...provided, padding: "0 !important" }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
  }),
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row gap-1.5">
        {props.data.icon && (
          <span>
            <img src={props.data.icon} className="h-5 w-5" alt={props.data.label} />
          </span>
        )}
        <span className="truncate items-start my-auto text-sm">{props.data.label}</span>
      </div>
    </components.Option>
  );
};

export const SelectWithIcon = ({ options, selectedValue, onChange, isSearchable = false, containerClasses }) => {
  const selectedOption = options.find((o) => o.value === selectedValue);

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedOption && (
        <div className="flex flex-row gap-1.5">
          {selectedOption.icon && (
            <span>
              <img src={selectedOption.icon} className="h-5 w-5" alt={selectedOption.label} />
            </span>
          )}
          <span className="truncate items-start my-auto text-sm">{selectedOption.label}</span>
        </div>
      )}
    </components.SingleValue>
  );
  return (
    <Select
      placeholder={""}
      styles={selectCustomStyle}
      classNames={selectCustomClass(containerClasses)}
      components={{ Option, SingleValue }}
      isSearchable={isSearchable}
      options={options}
      value={selectedOption}
      onChange={onChange}
    />
  );
};
