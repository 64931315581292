import React, { Component } from "react";
import { Anchor, Box, Grid, Text } from "grommet";
import { withUser } from "../../userContext";
import Countdown from "react-countdown-now";
import { ReactTooltip } from "../util/tooltip.js";
import { BRAND_BLACK } from "../util/colors";
import CardLayout from "../cards/cardLayout";
import { getValuationOrigin } from "../util/valuationOrigin";
import { findPriceForUserUnit } from "../util/formatMoney";

class Bundle extends Component {
  render() {
    const bundle = this.props.bundle;
    const where = this.props.where;
    const preferredUnit = this.props.user.preferredUnit;
    let dateToDisplay = new Date(bundle.auction.EndingAt);
    let price = findPriceForUserUnit(bundle.auction.CurrentPrice, bundle.current_price_fiat, preferredUnit, false, 3);
    if (bundle.auction.Status === "ENDED") dateToDisplay = new Date(bundle.auction.EndingAt);
    const Completionist = () => {
      if (dateToDisplay.getUTCSeconds() === 0) {
        return <span>{"Ended"}</span>;
      } else return <span>{dateToDisplay.toDateString()}</span>;
    };
    return (
      <Box>
        {
          <Box gap={"medium"}>
            <Box margin={"auto"}>
              <Box align={"center"}>
                <Grid rows={["auto"]} gap={"medium"} columns={["auto", "auto", "auto", "auto", "auto"]}>
                  {bundle.cards.map((card, index) => {
                    let split = card.best_market_price_split?.Split;
                    let averagePrice = findPriceForUserUnit(card.rolling_average_30, card.rolling_average_30_fiat, preferredUnit);
                    let marketPrice = findPriceForUserUnit(card.market_price, card.market_price_fiat, preferredUnit, false, 3);
                    if (card.rolling_average_30 === -1 || card.valuation_origin === "starting_price") {
                      averagePrice = "None";
                    }
                    if (card.market_price === -1) {
                      marketPrice = "None";
                    }
                    let cardLink = "/card/" + card.card.TokenId;
                    if (where === "list") {
                      cardLink = "/bundle/" + bundle.auction.AuctionId;
                    }
                    return (
                      <Box>
                        <CardLayout
                          imageLink={cardLink}
                          picture={card.card.PictureUrl}
                          valuationOrigin={getValuationOrigin(card.valuation_origin)}
                          monthPrice={averagePrice}
                          marketPrice={marketPrice}
                          countdown={true}
                          currentPrice={"Bundle"}
                          date={dateToDisplay}
                          card={card.card}
                          player={card.player}
                          l5={card.so_5_average_5}
                          l20={card.so_5_average_20}
                          gms5={card.l5}
                          gms20={card.l20}
                          s5={card.s5Avg}
                          s15={card.s15Avg}
                          s5Apps={card.s5Apps}
                          s15Apps={card.s15Apps}
                          availability={card.player_status}
                          gwGames={this.props.nextGwPlayers}
                          split={split}
                        />
                      </Box>
                    );
                  })}
                </Grid>
              </Box>

              <Box direction={"row"} justify={"center"} margin={{ top: "small" }}>
                <Box direction={"column"}>
                  <Anchor href={"/bundle/" + bundle.auction.AuctionId}>
                    <Text textAlign={"center"} data-tip="Current bundle price" size={"150%"} weight={600} color={BRAND_BLACK}>
                      {price + " "}
                    </Text>
                  </Anchor>
                  <Text size={"60%"} textAlign={"center"} data-tip="Auction ends in" weight={400}>
                    <Countdown daysInHours={true} date={dateToDisplay}>
                      <Completionist />
                    </Countdown>
                  </Text>
                </Box>
              </Box>
              <ReactTooltip />
            </Box>
          </Box>
        }
      </Box>
    );
  }
}

export default withUser(Bundle);
