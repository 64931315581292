import React from "react";
import { getThemeColor, useTheme } from "../../themeContext";

export const NoGraphResultsImage = (props) => {
  const { theme } = useTheme();
  const outlineVariant = getThemeColor(theme, "outline-variant");
  const surfaceContainerHighest = getThemeColor(theme, "surface-container-highest");
  return (
    <svg className={props.className} width="118" height="96" viewBox="0 0 118 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.6" cx="58.8551" cy="85.5785" rx="58.8551" ry="10.4223" fill={surfaceContainerHighest} />
      <path
        d="M96.9348 9.5133C96.7003 8.14071 98.2999 7.21717 99.3714 8.10652L102.33 10.5624C102.667 10.842 103.109 10.9605 103.541 10.8867L107.331 10.2393C108.704 10.0048 109.627 11.6044 108.738 12.6759L106.282 15.6346C106.002 15.9715 105.884 16.4137 105.957 16.8452L106.605 20.6354C106.839 22.008 105.24 22.9316 104.168 22.0422L101.21 19.5864C100.873 19.3068 100.431 19.1883 99.999 19.262L96.2088 19.9095C94.8362 20.1439 93.9127 18.5443 94.802 17.4729L97.2578 14.5141C97.5375 14.1773 97.6559 13.7351 97.5822 13.3035L96.9348 9.5133Z"
        fill="#F0BBA4"
      />
      <path
        d="M16.9007 38.8361C17.4372 38.0785 18.6267 38.3972 18.7125 39.3216L18.9588 41.9735C18.9858 42.2641 19.1384 42.5284 19.3766 42.6971L21.55 44.2363C22.3076 44.7728 21.9889 45.9622 21.0646 46.0481L18.4127 46.2944C18.1221 46.3214 17.8578 46.474 17.6891 46.7122L16.1498 48.8856C15.6133 49.6432 14.4239 49.3245 14.338 48.4001L14.0917 45.7483C14.0648 45.4576 13.9122 45.1933 13.674 45.0247L11.5005 43.4854C10.7429 42.9489 11.0616 41.7595 11.986 41.6736L14.6379 41.4273C14.9285 41.4003 15.1928 41.2477 15.3615 41.0096L16.9007 38.8361Z"
        fill="#F0BBA4"
      />
      <path
        d="M45 20.3C42.4043 20.3 40.3 22.4043 40.3 25V76C40.3 78.5957 42.4043 80.7 45 80.7H76C78.5957 80.7 80.7 78.5957 80.7 76V25C80.7 22.4043 78.5957 20.3 76 20.3H45Z"
        fill={surfaceContainerHighest}
        stroke={outlineVariant}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <mask id="mask0_4655_295626" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="44" y="34" width="33" height="33">
        <rect x="44" y="34" width="33" height="33" rx="2" fill={outlineVariant} />
      </mask>
      <g mask="url(#mask0_4655_295626)">
        <path
          d="M48.7501 62.875C47.6456 62.875 46.7501 61.9796 46.7501 60.875V48.375C46.7501 47.2704 47.6456 46.375 48.7501 46.375H52.3126C53.4172 46.375 54.3126 47.2704 54.3126 48.375V60.875C54.3126 61.9796 53.4172 62.875 52.3126 62.875H48.7501ZM58.7189 62.875C57.6143 62.875 56.7189 61.9796 56.7189 60.875V40.125C56.7189 39.0204 57.6143 38.125 58.7189 38.125H62.2814C63.3859 38.125 64.2814 39.0204 64.2814 40.125V60.875C64.2814 61.9796 63.3859 62.875 62.2814 62.875H58.7189ZM68.6876 62.875C67.583 62.875 66.6876 61.9796 66.6876 60.875V51.125C66.6876 50.0204 67.583 49.125 68.6876 49.125H72.2501C73.3547 49.125 74.2501 50.0204 74.2501 51.125V60.875C74.2501 61.9796 73.3547 62.875 72.2501 62.875H68.6876Z"
          fill={outlineVariant}
        />
      </g>
    </svg>
  );
};
