import React from "react";

function BasketballTeamStatsRow(props) {
  const home = props.home;
  const away = props.away;

  const superior = "rounded-lg bg-brand px-2 py-1 text-white text-sm font-semibold";
  const inferior = "rounded-lg bg-grey-e5 px-2 py-1 text-textGrey2 text-sm font-semibold";

  let homeStat1 = 0;
  let awayStat1 = 0;
  let homeStat2 = 0;
  let awayStat2 = 0;
  home.map((p) => {
    homeStat1 += p.stats[props.stat];
    if (props.stat2) homeStat2 += p.stats[props.stat2];
  });
  away.map((p) => {
    awayStat1 += p.stats[props.stat];
    if (props.stat2) awayStat2 += p.stats[props.stat2];
  });
  let homeText = homeStat1;
  let awayText = awayStat1;
  if (props.addToStats) {
    homeText += props.addToStats;
    awayText += props.addToStats;
  }
  if (props.stat2) {
    homeText = homeStat1 + "/" + homeStat2;
    awayText = awayStat1 + "/" + awayStat2;
  }
  let homeStyle = superior;
  let awayStyle = superior;
  if (homeStat1 < awayStat1) {
    homeStyle = inferior;
  }
  if (homeStat1 > awayStat1) {
    awayStyle = inferior;
  }
  if (props.computePct || props.isPct) {
    let homePct = homeStat1 / homeStat2;
    let awayPct = awayStat1 / awayStat2;
    if (homePct < awayPct) {
      homeStyle = inferior;
      awayStyle = superior;
    }
    if (homePct > awayPct) {
      awayStyle = inferior;
      homeStyle = superior;
    }
    if (props.isPct) {
      homeText = (homePct * 100).toFixed(0) + "%";
      awayText = (awayPct * 100).toFixed(0) + "%";
      if (homeStat2 === 0) {
        homeText = "0%";
      }
      if (awayStat2 === 0) {
        awayText = "0%";
      }
    }
  }
  return (
    <div className={"flex flex-row justify-between w-full px-4"}>
      <div className={"w-2/12 flex flex-row justify-center " + homeStyle}>
        <p>{homeText}</p>
      </div>
      <div className={"w-8/12 text-center self-center"}>
        <p className={"text-textGrey3 font-semibold text-xs"}>{props.label}</p>
      </div>
      <div className={"w-2/12 flex flex-row justify-center " + awayStyle}>
        <p>{awayText}</p>
      </div>
    </div>
  );
}

function BasketballTeamStats(props) {
  const home = props.home;
  const away = props.away;
  const homeTeam = props.homeTeam;
  const awayTeam = props.awayTeam;
  return (
    <div className={"bg-white rounded-lg space-y-2 pb-4 pt-2"}>
      <div className={"flex flex-row justify-between px-3"}>
        <div className={"flex flex-row"}>
          <div className={"w-12 h-12 object-contain py-2"}>
            <img className={"w-full h-full object-contain"} src={homeTeam.team.PictureUrl} />
          </div>
        </div>
        <div className={"flex flex-row"}>
          <div className={"w-12 h-12 object-contain py-2"}>
            <img className={"w-full h-full object-contain"} src={awayTeam.team.PictureUrl} />
          </div>
        </div>
      </div>
      <div className={"flex flex-col space-y-2"}>
        <BasketballTeamStatsRow label={"Field goals"} home={home} away={away} stat={"fgMade"} stat2={"fgAtt"} computePct={true} />
        <BasketballTeamStatsRow label={"FG%"} home={home} away={away} stat={"fgMade"} stat2={"fgAtt"} isPct={true} addToStats={"%"} />
        <BasketballTeamStatsRow
          label={"Three pointers"}
          home={home}
          away={away}
          stat={"threePtMade"}
          stat2={"threePtAtt"}
          computePct={true}
        />
        <BasketballTeamStatsRow
          label={"3P%"}
          home={home}
          away={away}
          stat={"threePtMade"}
          stat2={"threePtAtt"}
          addToStats={"%"}
          isPct={true}
        />
        <BasketballTeamStatsRow label={"Free throws"} home={home} away={away} stat={"ftMade"} stat2={"ftAtt"} computePct={true} />
        <BasketballTeamStatsRow label={"FT%"} home={home} away={away} stat={"ftMade"} stat2={"ftAtt"} isPct={true} />
        <BasketballTeamStatsRow label={"Rebounds"} home={home} away={away} stat={"totalRebounds"} />
        <BasketballTeamStatsRow label={"Assists"} home={home} away={away} stat={"assists"} />
        <BasketballTeamStatsRow label={"Turnovers"} home={home} away={away} stat={"turnovers"} />
        <BasketballTeamStatsRow label={"Steals"} home={home} away={away} stat={"steals"} />
        <BasketballTeamStatsRow label={"Blocks"} home={home} away={away} stat={"blocks"} />
        <BasketballTeamStatsRow label={"Fouls"} home={home} away={away} stat={"fouls"} />
      </div>
    </div>
  );
}

export default BasketballTeamStats;
